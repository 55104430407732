import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppliedBox } from '../../../../../styles/AppliedCoupon'
import { InfoCard } from '../../../../../styles/dashboard/Main'
import CouponIcon from '../../../../../assets/coupon.png'
import CouponBlueIcon from '../../../../../assets/couponBlue.png'
import CouponModal from '../CouponModal';
import useRewardsInfo from '../../../pure-wallet/custom-hooks/useRewardsInfo'
const CouponField = ({isCouponApplied=null,setCouponApplied=()=>{},setShowCouponView=()=>{},showCouponView=false,setOldInvoice=()=>{},couponName="all"}) => {
    const openCouponModal  = (again=false) => {
        setShowCouponView(true)
    }
    const removeCoupon = () => {
        setCouponApplied(null)
        setOldInvoice(prev=>({...prev,isRemove:false}))
    }
   



    return (
        showCouponView ? <CouponModal setShowCouponView={setShowCouponView} setCouponApplied={setCouponApplied} setOldInvoice={setOldInvoice} couponName={couponName}/> : 
        <InfoCard hasborder>
            {isCouponApplied ?
            <AppliedBox>
                <div className='coupon-applied'>
                    <img src={CouponIcon} />
                    <span className='mx-2'>{isCouponApplied.name}</span>
                    <Link onClick={removeCoupon}>Remove</Link>
                </div>
                <Link onClick={()=>openCouponModal(true)}>Use different voucher</Link>
            </AppliedBox>
            :
            <Link className='d-flex flex-grow-1 justify-content-end' onClick={openCouponModal}><img src={CouponBlueIcon} className='mx-1' />Apply Voucher</Link>
            }
        </InfoCard>
        
    )
}

export default CouponField