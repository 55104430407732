import { addonConstants, constants, public_email_addresses } from '../../../tools/constants';
import { checkEvenOdd, isExpired, isWhmcsUser } from '../../../tools/tools';
import { connect, useDispatch } from 'react-redux';
import { getHelloBar } from '../../../redux/hello-bar/actions';
import { HelloBarWrapper, HelloBarContent } from '../../../styles/dashboard/Main';
import { PrimaryBtn } from '../../../styles/Generic';
import { sendEvents } from "../../../tools/mpEvents";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/close-icon.svg';
import { useEffect, useState } from 'react';

const HelloBar = ({helloBarReducer, subscriptions, helloBarStatusCheck}) => {

    const token = localStorage.getItem("token");

    const { t } = useTranslation();

    const [helloBarData, setHelloBarData] = useState(null)

    const [helloBarStatus, setHelloBarStatus] = useState(false)

    const [helloBarType, setHelloBarType] = useState('upgrade')
    
    const RandomNum = localStorage.getItem("RandomNum")
 
    const dispatch = useDispatch()
    
    const history = useHistory();

    const { search } = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    const [selectedSubscription, setSelectedSubscription] = useState();

    useEffect(() => {

        dispatch(getHelloBar())

    }, [])

    useEffect(() => {

        if (!helloBarStatus && helloBarReducer.hello_bar && subscriptions.subscriptions?.body) {
            // Default helloBar type
            let helloBarTemplate = "upgrade";

            if(isExpired(subscriptions.subscriptions?.body)){
                if(hasMultiSubscription()){
                    if(subscriptions.subscriptions?.body?.find((s) => (s?.hasPureTeams))){
                        helloBarTemplate = "b2b";
                    }
                    else{
                        helloBarTemplate = "teams";
                    }
                }
                else{
                    helloBarTemplate = 'past_due';
                }
            }
            else{
                if(hasMultiSubscription() || !checkIsPublicEmail(subscriptions?.subscriptions?.body?.[0]?.account?.email) || hasDedicatedServerAddon() || hasDedicatedIpAddon()){
                    if(subscriptions.subscriptions?.body?.find((s) => (s?.hasPureTeams))){
                        helloBarTemplate = "b2b";
                    }
                    else{
                        helloBarTemplate = "teams";
                    }
                }
                else{
                    if(RandomNum <= 50){
                        if(!hasDedicatedServerAddon() && !checkAddonInEligibility()){
                            helloBarTemplate = 'teams';
                        }else{
                            helloBarTemplate = "upgrade"
                        }
                    }else{
                        if(subscriptions.subscriptions?.body?.find((s) => (s?.familyPlanEligibility == true && s?.hasFamilyPlan != true))){
                            helloBarTemplate = "familyplan"
                        }else{
                            helloBarTemplate = "upgrade"
                        }
                    }
                }
            }
            setHelloBarType(helloBarTemplate)

            // Loog over each hellobar item and set data based on above subs condition
            helloBarReducer.hello_bar.map((item) => {
                if (item.type && item.type === helloBarTemplate) {
                    setHelloBarData(item);
                    setHelloBarStatus(true);
                    helloBarStatusCheck(true);
                    sendEvents({
                        event_name:"ma_view_hellobar",
                        event_properties:{
                            type: helloBarTemplate,
                            visitedPageURL: window.location.href ?? "N/A",
                        }
                    }, token);
                }
            });
        }
    }, [helloBarReducer])

    const hasDedicatedServerAddon = () => {
        let subs = subscriptions?.subscriptions?.body.find((sub) => sub?.add_ons?.find((addon) => addon?.code === addonConstants.dedicated_server));
        setSelectedSubscription(subs);
        return subs
    }

    const hasDedicatedIpAddon = () => {
        let subs =  subscriptions?.subscriptions?.body.find((sub) => sub?.add_ons?.find((addon) => addon?.code === addonConstants.dedicated_ip))
        setSelectedSubscription(subs);
        return subs
    }

    const checkAddonInEligibility = (subscription) => {
       return subscription?.is_trial || 
        subscription?.is_child || 
        (isWhmcsUser(subscription?.account?.billingType) && (subscription?.state == "past_due" || subscription?.state == "expired") )|| 
        (isWhmcsUser(subscription?.account?.billingType) && (subscription?.payment_gateway_offline && !subscription?.account?.has_mobile_gateway_for_whmcs)) || 
        (constants.past_due_state?.includes(subscription?.state) && subscription?.payment_gateway_offline )
    }

    const helloBarCTAClickHandle = (e) => {

        if (token) {
            sendEvents({
                event_name:"ma_click_hellobar",
                event_properties:{
                    ctaname: e.target.innerHTML,
                    type: helloBarType,
                    visitedPageURL: window.location.href ?? "N/A",
                }
            }, token);
        }

        if(helloBarData?.type === addonConstants.dedicated_server){
            history.push(`/dashboard${helloBarData.cta_one_action}&subscription_id=${selectedSubscription?.id}&viaHelloBar=true&type=${selectedSubscription?.add_ons.find((addon) => addon?.code === addonConstants.dedicated_server) ? addonConstants?.dedicated_ip : addonConstants.dedicated_server}`)
            return
        }

        if (helloBarData?.cta_one_action && history) {
            if (isValidURL(helloBarData.cta_one_action)) {
                window.open(helloBarData.cta_one_action, '_blank');
            } else {
                history.push(`/dashboard${helloBarData.cta_one_action}`)
            }
        }
    }

    const domeCTAClickHandle = (e) => {
        
        window.open(checkEvenOdd(), '_blank');

        if (token) {
            sendEvents({
                event_name:"ma_click_hellobar",
                event_properties:{
                    ctaname: e.target.innerHTML,
                    type: helloBarType,
                    visitedPageURL: window.location.href ?? "N/A",
                }
            }, token);
        }
    }
 
    const toggleHelloBar = (status) => {
        setHelloBarStatus(status)
        helloBarStatusCheck(status);
    }

    const isValidURL = (string) => {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    }

    const closeHelloBar = () => {
        if (token) {
            sendEvents({
                event_name:"ma_click_hellobar",
                event_properties:{
                    ctaname: 'close',
                    type: 'close'
                }
            }, token);
        }
        toggleHelloBar(false)
        localStorage.setItem("closeHelloBar", true)
    }

    const hasMultiSubscription = () => {
        return subscriptions.subscriptions?.body.length > 1
    }
    const checkIsPublicEmail = (email) => {
        let emails_part = email?.split('@');    
        let email_domain = emails_part?.[1];
        return public_email_addresses.includes(email_domain)
    }
    return (
        <>
            {
                !redirected_via && helloBarData && helloBarStatus ?
                (
                    <HelloBarWrapper>
                        <HelloBarContent className='justify-content-center gap-4 text-center' bgColor={( helloBarData?.bg_color ) ? helloBarData?.bg_color : ""} bgImage={( helloBarData?.bg_image ) ? helloBarData?.bg_image : ""} bgImageMobile={( helloBarData?.bg_image_mobile ) ? helloBarData?.bg_image_mobile : ""}
                        >
                            {
                                <>  
                                    {
                                        ( helloBarData?.html_text ) ? (
                                                <div className='d-flex justify-content-center align-items-center' dangerouslySetInnerHTML={{ __html: t(helloBarData?.html_text) || "" }}></div>
                                        ) : null
                                        
                                    }
                                    <div className='hello-bar-cta'>
                                    {
                                        ( helloBarData?.cta_one ) ? <PrimaryBtn className='button-one' helloBarCtaPrimary={true} onClick={helloBarCTAClickHandle}>{t(helloBarData?.cta_one)}
                                        </PrimaryBtn> : null
                                    }
                                    {
                                        ( helloBarType == "b2b" ) ? <PrimaryBtn className='button-one' helloBarCta onClick={domeCTAClickHandle} >{t(helloBarData?.cta_two)}
                                        </PrimaryBtn> : null
                                    }
                                    </div>
                                </>
                            }
                            
                            <div className='close-button' onClick={() => { closeHelloBar() }}>
                                <img src={closeIcon} />
                            </div>
                        </HelloBarContent>
                    </HelloBarWrapper>
                ) 
                : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        helloBarReducer: state.helloBarReducer,
        subscriptions: state.subscriptionsReducer,
        account:state.accountReducer
    };
};

export default connect(mapStateToProps, null)(HelloBar)