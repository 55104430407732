import React from 'react'

const Currency = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.75 7C11.75 9.62335 9.62335 11.75 7 11.75C4.37665 11.75 2.25 9.62335 2.25 7C2.25 4.37665 4.37665 2.25 7 2.25C9.62335 2.25 11.75 4.37665 11.75 7Z" fill="#F3AD14" stroke="#FED22A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.23579 9.02626L7.00296 8.90357L6.77002 9.02605L5.22959 9.83595L5.52385 8.12051L5.56834 7.8612L5.37981 7.67769L4.13234 6.46341L5.85304 6.21403L6.11331 6.17631L6.2297 5.94048L6.99994 4.37979L7.77017 5.94048L7.88656 6.17631L8.14683 6.21403L9.86753 6.46341L8.62006 7.67769L8.43153 7.8612L8.47602 8.12051L8.77008 9.83477L7.23579 9.02626Z" fill="#FED22A" stroke="#DF9019"/>
    </svg>
  )
}

export default Currency