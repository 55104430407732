
import { ERRORS, GETUPGRADEPLANS, LOADING, EMPTYUPGRADEPLANS,GETUPGRADEPLANSRESET } from "./types";

const initialStateUpgradePlanSubscriptions = {
    subscriptions: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}


export const upgradeReducer = (state = initialStateUpgradePlanSubscriptions, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETUPGRADEPLANS:
            return  {...state, subscriptions:payload, loading:false, errors:false}
            break;
        case GETUPGRADEPLANSRESET:
            return  {...state, subscriptions:null, loading:false, errors:false}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        case EMPTYUPGRADEPLANS:
            return initialStateUpgradePlanSubscriptions
            break;
        default:
            return  {...state}
            break;
    }
}
