import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Devices, HtmlBox, PopupBody } from '../../../../styles/dashboard/Main';
import { DestructBtn } from '../../../../styles/Generic';
import { APIFAILED } from '../../../../tools/responseMessages';
import qs from 'qs'
import { poster } from '../../../../tools/tools';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';


const AllDevices = ({modal, appName, selectedApp, appSlug, toggle, download, devices, sendOtherDevicesModalEvents}) => {

    const [loading, setLoading] = useState(true)

    const [otherDevices, setOtherDevices] = useState(devices)

    const { t, i18n } = useTranslation();

    const [getArticle, setgetArticle] = useState(false)

    useEffect(() => {

        const { errors } = download;

        if (appSlug !== "") {
            if(download && typeof download.devices?.body !== undefined && download.devices?.body.length > 0){
    
                download.devices?.body.map((app) => {
                    if (app.slug == appSlug) {
                        const configurable_devices = app.devices.find(device => device.abbreviation === "other_devices")
                        setOtherDevices(configurable_devices)
                        setLoading(false)
                    }
                })
            }
        }
        

    }, [])

    const [html, sethtml] = useState({})

    const handleClick =  async (e) => {
        setLoading(true)
        try {

            if (sendOtherDevicesModalEvents) {
                sendOtherDevicesModalEvents(e.target.id)
            }

            const formdata = qs.stringify({tag: e.target.id})
            const change =  await poster("devices/get-article", formdata, "")
            const {data: response} = change;
            if(response?.status){
                sethtml(response?.body)
                setgetArticle(true)
            }else{
                toast.error(t(APIFAILED))
            }
        } catch (error) {
            toast.error(t(APIFAILED))
        }
        finally{
            setLoading(false)
        }
    }

    return (
        <div>
            <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: '675px', width: '100%'}} className="modal-wrapper">
                {
                    !loading 
                    ?
                    <>
                        <ModalBody>
                            <PopupBody>
                                {
                                    !getArticle 
                                    ?
                                    <>
                                        <div className="mod-head mb-3">
                                            <h3>{appName}</h3>
                                            <p>{vsprintf(t('downloads_learn_how_to_setup_text'),`${appName}`)}</p>
                                        </div>
                                        {
                                            loading == false && otherDevices
                                            ? (
                                                <Devices>
                                                    <ul className="list-unstyled m-0 p-0">
                                                        {
                                                            otherDevices.setup_guides.map((category, key) => (
                                                                <React.Fragment key={key}>
                                                                    <li className="fw-600 mb-0">{category.category}</li>
                                                                    {
                                                                        category.items.map((link, key)=>(
                                                                            <li key={key}><a href="#javascript" id={link.tag} onClick={handleClick}>{link.name}</a></li>
                                                                        ))
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </ul>
                                                </Devices>
                                            ) : null
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="mod-head mb-3">
                                            <h3>{html.title}</h3>
                                        </div>
                                        <HtmlBox>
                                            <div dangerouslySetInnerHTML={{ __html: html?.content || "" }}></div>
                                        </HtmlBox>
                                    </>
                                }
                            </PopupBody>
                        </ModalBody>
                        <ModalFooter className="pt-0">
                            {
                                !getArticle
                                ?
                                    <DestructBtn color="secondary" onClick={toggle} type="button">{t('cancel_text')}</DestructBtn>
                                :
                                <>
                                    <DestructBtn color="secondary" onClick={() => setgetArticle(false)} type="button">{t('back_text')}</DestructBtn>
                                    <DestructBtn color="secondary" onClick={toggle} type="button">{t('cancel_text')}</DestructBtn>
                                </> 
                            }
                        </ModalFooter>
                    </>
                :
                <div className="d-flex align-items-center justify-content-center" style={{minHeight:"400px"}}>
                    <span className="spinner-border"></span>
                </div>
                }
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { download: state.devicesReducer };
};

export default connect(mapStateToProps, null)(AllDevices)
