import React, { useState } from "react";
import {
  DashboardHead,
  DownloadAllAppsNav,
  InfoCard,
  SubscriptionWrapper,
} from "../../../styles/dashboard/Main";
import Aside from "../layout/Aside";
import DomeForm from '../layout/partials/DomeForm'

const Card = (props) => {
  const [asideType] = useState("upgrade");

  const [domeModal, setDomeModal] = useState(false)

  return (
    <>
      <SubscriptionWrapper>
        <InfoCard inputWidth manualConfigurationWidth={true} >
          {props.children}
        </InfoCard>
        <div className="on-manual-config"><Aside type={asideType} setDomeModal={setDomeModal} className="mt-5"/></div>
      </SubscriptionWrapper>
      {
        domeModal && <DomeForm modal={domeModal} setDomeModal={setDomeModal}></DomeForm>
      }
    </>
  );
};

export default Card;
