import React, {useEffect, useState} from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { PForwardContainer, DashboardHead, CardBox, InfoCard, UpgradePlanContainer, PlainBoxWrapper } from '../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn, InsetBtn } from '../../../styles/Generic'
import { poster, isAddonPending, isAddon, isWhmcsUser, checkIsAddonPaid, selectSub } from '../../../tools/tools';
import qs from 'qs'
import locked from '../../../assets/locked.svg'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {sendEvents} from '../../../tools/mpEvents'
import AddonPurchase from '../subscriptions/modals/AddonPurchase'
import { Input } from 'reactstrap';
import { checkState } from '../../../tools/genericMethods';
import { addonState, signupFrom, constants } from '../../../tools/constants';
import { useTranslation } from 'react-i18next';
import { seti18Locale, checkLocale } from '../../../tools/tools';

const PortForwarding = ({history,subscriptionsReducer,onBoardingReducer}) => {

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const portForwardingAPI = async (type, url, data, token, cb, loadercb) => {
        try{
            dispatch({ type: "UPDATETOFETCH", payload: true })
            const change = await poster(url, data, token)
            const {data: response} = change;
            if(response?.status){
                cb(response)
                if(type === "status"){
                    setisPurchasedAddon(true)
                    dispatch({ type: "UPDATETOFETCH", payload: false })
                } 
            }else{
                dispatch({ type: "UPDATETOFETCH", payload: false })
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                                
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                            
                        break;
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                                
                        break; 
                    case "atom_exception":
                        toast.error(response?.message)
                        break; 
                        
                    case "validation_exception":
                        if(response?.errors?.enable_status){
                            response?.errors?.enable_status.map(error=>toast.error(error))
                        }else if(response?.errors?.ports){
                            response?.errors?.ports.map(error=>toast.error(error))
                        }
                        break;
                    default:
                        toast.error("Failed to update port forwarding settings. Please try again and if the problem persists, contact support");
                        break;
                }
                sendEvents(
                    {
                    event_name: "ma_configure_portforwarding_error",
                    event_properties: {
                        reason: response?.message,
                    }
                    }, token);
                
                
            }
        }catch(err){ 
            toast.error(t(APIFAILED));
            sendEvents(
                {
                event_name: "ma_configure_portforwarding_error",
                event_properties: {
                  reason: "Failed to update port forwarding settings. Please try again and if the problem persists, contact support",
                }
              }, token);
        }finally{
            loadercb()
        }
    }

    const dispatch = useDispatch()

    const [radio, setRadio] = useState("1");

    const token = localStorage.getItem("token")

    const [loading, setloading] = useState(true)

    const [portLoading, setportLoading] = useState(false)

    const [isPurchasedAddon, setisPurchasedAddon] = useState(false)

    const [modal, setModal] = useState(false);

    const [orderSummary, setorderSummary] = useState(true)
    
    const [subscriptionData, setSubscriptionData] = useState([])

    const [selectedSubscriptionUserName, setSelectedSubscriptionUserName] = useState("0");

    const [hasUsername, setHasUsername] = useState(false)

    const [ErrorReason, setErrorReason] = useState({})

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [addonType, setaddonType] = useState({
        type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:"",
        number:""
    })

    const widthCalculator = "lg"

    const toggle = () => setModal(!modal);

    const [ports, setports] = useState("")

    const [currentSubData, setcurrentSubData] = useState(null)

    const { search } = useLocation();

    let errorKey = false

    let ErrorMessage = {
        "expired":t('pf_error_renew_subscription'),
        "noSubscription":t('pf_error_only_family_admin_no_subs'),
        "child": t('pf_error_only_family_admin_can_purchase'),
        "volumeChild": "Only a admin can purchase an addon.",
        "offline_gateway": t('pf_error_sorry_to_inform_coingate'),
        "whmcs": t('pf_error_sorry_to_inform_option_not_available'),
        "trial": t('pf_error_sorry_to_inform_option_not_available'),
        "noUserName": t('pf_error_enabling_port_forwarding'),
        "default": t('pf_error_selected_username_does_not_have_pf'),
        "has_mobile_gateway_for_whmcs": t('notsupported_cant_purchase_addon_option_not_available'),
        'signup_from_addon':t('notsupported_cant_purchase_addon_option_not_available'),
        "pending": `${constants.pending_msg}`
    }

    const {utm_source, utm_medium, utm_campaign, getConf} = Object.fromEntries(new URLSearchParams(search));

    useEffect(()=>{
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: utm_source ?? "Port Forwarding",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            }
        }, token)
    }, [])

    const validatePortsInput =(input)=>{
        var regex = new RegExp("^[0-9]+(?:,[0-9]+)*$");
        return regex.test(input);
    };
    
    const { loading: reduxLoading, subscriptions, errors, errorType, resetState, errorMessage } = subscriptionsReducer
    useEffect(() => {
        let subData = subscriptions?.body
        if(subData?.length){
            if(reduxLoading){
                setloading(true)
            }else{
                let currentSubscription = selectSub(subData ?? [],onBoardingReducer.selectedSubs)
                setSubscriptionData(subData);
                setcurrentSubData(currentSubscription)

                subData?.map((subs, sidx) => {
                    if (subs?.vpnusernames) {
                        setHasUsername(true)
                    }
                })
                
                if(currentSubscription.hasOwnProperty("vpnusernames")){
                    setSelectedSubscriptionUserName(currentSubscription?.vpnusernames[0])
                    setHasUsername(true);
                }

                if( (currentSubscription?.state === "active" || currentSubscription?.state === "canceled")
                    ){
                    setHasUsername(true);
                    getPoertForwardingStatus(currentSubscription)
                    
                }else{
                    if(signupFrom.includes(currentSubscription?.account?.signup_from)){
                        errorKey = 'signup_from_addon';
                        setErrorReason({
                            reason: 'signup_from_addon',
                            heading: "",
                            paragraph: ErrorMessage[errorKey]
                        })

                    } else {
                        setErrorReason({
                            reason: 'expired',
                            heading: "",
                            paragraph: t('pf_error_renew_subscription')
                        })
                        errorKey = 'expired';

                    }
                    setloading(false)
                }
            }
        }else{
            if(!reduxLoading){
                setErrorReason({
                    reason: 'noSubscription',
                    heading: "",
                    paragraph: `Currently you do not have any active subscription. Please purchase a new subscription from <a href="https://www.purevpn.com/order" target="_blank" rel="noreferrer">here</a>.`
                })
                errorKey = 'noSubscription';
                setloading(false)
            }else{
                setloading(true)
            }
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})  
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                    break;
                default:
                    // setCrashed(true)
                    break;
            }
        }
        if(errorKey){
            sendEvents({
                event_name:"ma_fail_load_portforwarding",
                event_properties:{
                    reason: ErrorMessage[errorKey] ?? "N/A"
                }
            }, token)
        }

    }, [subscriptions, reduxLoading,onBoardingReducer.selectedSubs])


    const checkAddOn = (addons = [], type) => {
        const check = Array.isArray(addons) ? addons.some((addon) => addon.code === type && addon?.state === addonState.paid) : false;
        return check
    }
    
    const getPoertForwardingStatus = (selectedSub) => {
        setErrorReason({})
        let hasPFAddon = selectedSub?.add_ons?.some(addon => ((addon?.code === "port_forwarding") || (addon?.code === "port_forwarding_with_dedicated_ip")) && checkIsAddonPaid(addon))
        if(hasPFAddon && selectedSub.hasOwnProperty('vpnusernames') ) {
            setloading(true)
            const formdata = qs.stringify({
                username: selectedSub?.vpnusernames[0],
                subscription_id: selectedSub?.id
            })
            portForwardingAPI("status","port-forwarding/status", formdata, token, (data) => {
                
                setRadio(data?.body?.enabled)
                if(data?.body?.enabled === "2"){
                    const default_ports = data?.body?.default_ports.length ? `,${data?.body?.default_ports.join(",")}` : ""
                    const ports = `${data?.body?.custom_ports}${default_ports}`
                    setports(() => ports.startsWith(",") ? ports.slice(1) : ports)
                }
            }, () => setloading(false))
        }else{
            if(selectedSub?.is_child && selectedSub?.hasVolumePlan){
                setErrorReason({
                    reason: 'volumeChild',
                    heading: "",
                    paragraph: "Only an admin can purchase an addon."
                })
                errorKey = 'volumeChild';
            }else if(selectedSub?.is_child){
                setErrorReason({
                    reason: 'child',
                    heading: "",
                    paragraph: t('pf_error_only_family_admin_can_purchase')
                })
                errorKey = 'child';
            } else if(hasPFAddon && !selectedSub.hasOwnProperty('vpnusernames')){
                setErrorReason({
                    reason: 'noUserName',
                    heading: "",
                    paragraph: t('pf_error_enabling_port_forwarding')
                })
                errorKey = 'noUserName';
            } else if( !selectedSub.hasOwnProperty('vpnusernames')){
                setErrorReason({
                    reason: 'noUserName',
                    heading: "",
                    paragraph: t('pf_error_enabling_port_forwarding')
                })
                errorKey = 'noUserName';
            } 
            else if(isWhmcsUser(selectedSub?.account?.billingType) && selectedSub?.payment_gateway_offline && !selectedSub?.account?.has_mobile_gateway_for_whmcs){
                setErrorReason({
                    reason: 'offline_gateway',
                    heading: "Uh oh!",
                    paragraph: t('pf_error_sorry_to_inform_coingate')
                })
                errorKey = 'offline_gateway';
            }
            else if(selectedSub?.is_trial){
                setErrorReason({
                    reason: 'trial',
                    heading: "",
                    paragraph: t('pf_error_sorry_to_inform_option_not_available')
                })
                errorKey = 'trial';
            } else if (signupFrom.includes(selectedSub?.account?.signup_from)) {
                setErrorReason({
                    reason: 'signup_from_addon',
                    heading: "",
                    paragraph: t('pf_error_sorry_to_inform_option_not_available')
                })
                errorKey = 'signup_from_addon';
            }else if(selectedSub?.payment_gateway.toLowerCase()==="reseller"){
                setErrorReason({
                    reason: 'offline_gateway',
                    heading: "Uh oh!",
                    paragraph: 'Resellers User are not allowd to purchase from memeber area'
                })
                errorKey = 'offline_gateway';
            }
            else {

                setErrorReason({
                    reason: 'default',
                    heading: "",
                    paragraph: isAddonPending(selectedSub?.add_ons) ? `${constants.pending_msg}` : t('pf_error_selected_username_does_not_have_pf')
                })
                errorKey = isAddonPending(selectedSub?.add_ons) ? 'pending' : 'default';
            }
            setisPurchasedAddon(false)
            setloading(false)
        }
        
    } 

    const ButtonText = !portLoading ? t('portforwarding_apply_settings_cta') : ( <>{t('portforwarding_apply_settings_cta')} <span className="spinner-border text-light"></span></>);

    const changePort = async () => {
        if(radio === "2" && (ports === "" || !validatePortsInput(ports))){
            return;
        }
       
        setportLoading(true)
        const formdata = qs.stringify({
            username: selectedSubscriptionUserName,
            enable_status: radio,
            ports: radio === "2" ? ports : "",
            subscription_id: currentSubData?.id
        })
        portForwardingAPI("update", "port-forwarding/update", formdata, token, (data) => {
            toast.success("Settings Applied!")
            setRadio(data?.body?.enabled)
            if(data?.body?.enabled === "2"){
                const default_ports = data?.body?.default_ports.length ? `,${data?.body?.default_ports.join(",")}` : ""
                const ports = `${data?.body?.custom_ports}${default_ports}`
                setports(() => ports.startsWith(",") ? ports.slice(1) : ports)
            }
        }, () => setportLoading(false))
    }
    
    const getPFAddon = () => {
        setaddonType({
            type:"port_forwarding",
            subscription:currentSubData?.id,
            currency:currentSubData?.currency,
            plan:currentSubData?.plan?.code,
            gateway:currentSubData?.payment_gateway,
            number:isAddon(currentSubData?.add_ons)?.number ?? null
        })
        sendEvents({
            event_name:"ma_click_purchase_addon",
            event_properties:{
                addon_name: "port_forwarding",
                source: "port forwarding",
            }
        }, token)
        setModal(!modal)
    }

    const usernameChangeHandle = (e) => {

        let currentSubscription = subscriptionData?.find(sub => sub?.vpnusernames[0] === e.target.value);
        currentSubscription.hasOwnProperty("vpnusernames") && setSelectedSubscriptionUserName(currentSubscription?.vpnusernames[0]) 
        setcurrentSubData(currentSubscription)
        
            if(currentSubscription?.state === "active" || currentSubscription?.state === "canceled"){
                
                getPoertForwardingStatus(currentSubscription)
            }else{
                if(signupFrom.includes(currentSubscription?.account?.signup_from)){
                    errorKey = 'signup_from_addon';
                    setErrorReason({
                        reason: 'signup_from_addon',
                        heading: "",
                        paragraph: ErrorMessage[errorKey]
                    })
                } else if (currentSubscription?.account?.has_mobile_gateway_for_whmcs){
                    errorKey = 'has_mobile_gateway_for_whmcs';
                    setErrorReason({
                        reason: 'has_mobile_gateway_for_whmcs',
                        heading: "",
                        paragraph: ErrorMessage[errorKey]
                    })
                } else {
                    setErrorReason({
                        reason: 'expired',
                        heading: "",
                        paragraph: t('pf_error_renew_subscription')
                    })
                    errorKey = 'expired';
                }
                setisPurchasedAddon(false)
                setloading(false)
            }
            if(errorKey){
                sendEvents({
                    event_name:"ma_fail_load_portforwarding",
                    event_properties:{
                        reason: ErrorMessage[errorKey] ?? "N/A"
                    }
                }, token)
            }
        
    }

    const getDataFromAddonPopup = (status) =>{
        if(status){
            setloading(true)
        }
    }

    const reCallApi = () =>{
        dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
    }

    const UsernameComponent = ({subscriptionData}) => {
        return (
                <div className='pb-2 grey-text'>
                    {t('tbl_username')} <strong>{subscriptionData?.['vpnusernames'] ? subscriptionData?.vpnusernames[0] : "---"}</strong>
                </div>
        )
    }
    
    return (
        <>
        <DashboardHead>
            <h2>{t('portforwarding_title')}<span className='smallerBody'>{t('portforwarding_sub_title')}</span></h2>
            <p style={{maxWidth:"675px"}}>{t('portforwarding_title_para')}</p>
        </DashboardHead>
        {
            loading
            ?
            <CardBox open={true} style={{maxWidth:"645px"}}>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </CardBox> 
            :
            <>
                <UpgradePlanContainer noborder className='align-left-mob'>
                    <div className=" d-flex dropdown-row">

                        {
                            hasUsername ?
                            <InfoCard noflex hasborder className='p-0 medium-width d-flex pf-userdetails pf-detailstext' isPF='portforwarding'>

                                {   
                                    (currentSubData) ?
                                    <>
                                        {
                                            <>                                                
                                                <UsernameComponent subscriptionData={currentSubData} />
                                                    { !isPurchasedAddon && ErrorReason 
                                                        ? <div className={subscriptionData.length == 1 ? "pb-2":"py-2"}><p className='red-text' dangerouslySetInnerHTML={{ __html: ErrorReason?.paragraph }} /></div>
                                                        : <></>
                                            }
                                            </>
                                        }
                                        
                                    </>
                                    
                                    :
                                    <></>
                                }

                            </InfoCard>
                            :
                            <></>
                        }
                    </div>
                    <InfoCard noflex hasborder className='p-0 medium-width' > 
                        {
                            <div className=' my-3 grey-text'>{t('subscription_lable_status')} 
                             {
                                !isPurchasedAddon && ErrorReason ? 
                                 <span className="red">
                                     <strong className='pf-detailstext'> {ErrorReason?.reason == "noUserName" ? t('portforwarding_inprogress'): t('portforwarding_inactive')}</strong>
                                 </span>
                                 : 
                                 <span className="green">
                                     <strong className='pf-detailstext'>{t('subscription_lable_status_active')}</strong>
                                 </span>

                             }
                            </div>
                        }
                    </InfoCard>
                    {
                        !isPurchasedAddon &&
                        <InfoCard noflex hasborder className='p-0 medium-width addon-list-item my-2'  isExpired={checkState(currentSubData)} isPF='portforwarding'> 
                            {
                                <>
                                    <div className= "float-start">
                                        <label className="me-2 d-inline-flex my-3 grey-text">
                                            {t('addon_lable')}<strong className='ms-2'>Port Forwarding</strong>
                                            {checkAddOn(currentSubData?.add_ons, "port_forwarding") || <div className='locked-image'><img src={locked} height={20} width={66} /></div>}
                                        </label>
                                    </div>
                                    <div className="float-end">
                                    {
                                        ErrorReason?.reason == "default" ?
                                            <InsetBtn onClick={getPFAddon}>{t('get_this_addon_text')}</InsetBtn>
                                        : 
                                        (
                                            (
                                                currentSubData?.state == "paused" || (ErrorReason?.reason == "expired" && !(currentSubData?.renew_invoice === undefined)) ?
                                                <PrimaryBtn onClick={e=> history.push(`/dashboard/subscriptions?update_subscription=true&renewId=${currentSubData?.id}`)} className="my-2">{t('renew_now_text')}</PrimaryBtn>
                                                :
                                                (
                                                    ErrorReason?.reason == "noUserName" ?
                                                    <InsetBtn onClick={reCallApi}>{t('refresh_now_text')}</InsetBtn> :
                                                    null
                                                )
                                            )
                                        )
                                        
                                    }
                                    </div>
                                    <div className="float-none"></div>
                                </>
                            }
                        </InfoCard>
                    }
                </UpgradePlanContainer>
                <PlainBoxWrapper style={{maxWidth:"645px"}} disabledCard={!isPurchasedAddon ? "true" : "false" } noflex>
                    <PForwardContainer noborder>
                    <p className='label-text mt-4'>{t('portforwarding_settings_heading')}</p>
                    </PForwardContainer>
                    <PForwardContainer className='ms-5'>

                        <div className="d-flex py-3 mt-4">
                            <input
                                type="radio"
                                name="enable"
                                value={"0"}
                                id="enable"
                                checked={radio === "0"}
                                onChange={(e)=>{setRadio(e.target.value)}}
                            />
                            <label htmlFor="enable">
                                {t('portforwarding_enable_ports')}<span className='smallerBody'>{t('portforwarding_not_recommended')}</span>
                                <p className="mt-2 mb-1">{t('portforwarding_enable_ports_para')}</p>
                                {/* <p className=""></p> */}
                                {
                                    radio === "0" && <p className="danger mt-2 mb-0">{t('portforwarding_enable_ports_radio_text')}</p>
                                }
                            </label>
                        </div>
                    </PForwardContainer>
                    <PForwardContainer className='ms-5'>
                    <div className="d-flex py-3 mt-3">
                    <input 
                        type="radio"     
                        name="disable" 
                        value={"1"}
                        id="disable"
                        checked={radio === "1"}
                        onChange={(e)=>{setRadio(e.target.value)}}
                    />
                    <label htmlFor="disable">
                        {t('portforwarding_disable_ports')}<span className='smallerBody'>{t('portforwarding_highly_recommended')}</span>
                        <p className="mt-2">{t('portforwarding_disable_ports_para')}</p>
                    </label>
                    </div>
                    </PForwardContainer>
                    <PForwardContainer noborder className='ms-5'>
                    <div className="d-flex py-3 mt-2">
                    <input 
                        type="radio" 
                        name="block" 
                        value={"2"}
                        id="block"
                        checked={radio === "2"}
                        onChange={(e)=>{setRadio(e.target.value)}}
                    />
                    <label htmlFor="block">
                    {t('portforwarding_enable_specific_port')}
                    <p className="mt-3">{t('portforwarding_enable_specific_port_para')}</p>
                    <div className="d-block mt-3">
                        <p className="enport-h mb-1">{t('portforwarding_enable_specific_port_para_two')}</p>
                        <p className="">{t('portforwarding_enable_specific_port_para_three')}</p>
                        <InfoCard noflex>
                            <input 
                                className="tinput"
                                type="text" 
                                name="form-control" 
                                placeholder="80,8080" 
                                value={ports}
                                onChange={(e) => setports(e.target.value)}
                            />
                        {
                            (radio === "2" && (!validatePortsInput(ports) || !ports)) && <p className="danger red-text mt-2">{t('portforwarding_enable_specific_valid_ports')}</p> 
                        }
                        {
                            (radio === "2") && <p className="low-visiblity mt-3">Note* To implement these configurations, the OS Firewall must either be deactivated, or the identical ports need to be permitted via the OS Firewall.</p>
                        }
                        </InfoCard>
                    </div>
                    </label>
                    </div>
                    </PForwardContainer>
                    <div className="mt-5">
                        <PrimaryBtn onClick={changePort} pointer={portLoading}>
                            {ButtonText}
                        </PrimaryBtn>
                    </div>
                </PlainBoxWrapper>
                {
                    (modal) && <AddonPurchase backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} onPageClose={true} getDataFromChild={getDataFromAddonPopup}/>
                }
            </>
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
     };
};

export default connect(mapStateToProps)(PortForwarding)