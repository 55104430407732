import { useState, useEffect } from "react";
import { getByParam, getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

const useTeamsServers = (page, per_page, searchText, selectedSubscriptionData, teamsCounter) => {
  const token = getTokenFromLocalStorage();
  const [totalRows, setTotalRows] = useState(0);
  const [teamServers, setTeamServers] = useState([]);
  const [isTeamServersLoaded, setIsTeamServersLoaded] = useState(true);
  const [teamServersErrorMsg, setTeamServersErrorMsg] = useState(null);
  const [fetchTeamServersAgain, setFetchTeamServersAgain] = useState(false);

  useEffect(() => {
    async function fetchTeamServers() {
      try {
        setIsTeamServersLoaded(false);
        setTeamServersErrorMsg(false);
        const apiResponse = await getByParam(`pure-teams/team-servers${selectedSubscriptionData?.is_child ? '?is_child=1' : ''}${searchText? `?search=${searchText}` : ''}`, {
          sub_id: selectedSubscriptionData?.id,
          page,
          per_page,
        }, token);
        const { data: teamServers } = apiResponse;
        setTeamServersErrorMsg(false);
        if (teamServers?.status) {
          setTeamServers(teamServers?.body?.data);
          setTotalRows(teamServers?.body?.total);
        } else {
          setTeamServersErrorMsg(teamServers?.message);
          sendEvents({
            event_name: "ma_fail_load_team_server_details",
            event_properties: {
                reason: teamServers?.message
            },
        }, token);
        }
      } catch (error) {
        setTeamServersErrorMsg('An error occurred while fetching team servers');
      }
      finally{
        setIsTeamServersLoaded(true);
      }
    }

    if(teamsCounter?.team_server) {
      fetchTeamServers();
    }
}, [fetchTeamServersAgain, selectedSubscriptionData?.id, page, per_page, searchText]);

  return { teamServers, totalRows, isTeamServersLoaded, teamServersErrorMsg, setFetchTeamServersAgain };
};

export default useTeamsServers;
