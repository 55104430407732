import { HasDedicatedIpViewStyles } from "../../../../styles/dashboard/pure-teams/checkoutLicenses";
import { FlagIcon } from "../../../../styles/flags/Flag";
import { countriesList } from "../../../../tools/countries";

const HasDedicatedIpView = ({addon}) => {
    return <HasDedicatedIpViewStyles>
        {addon?.ip && addon?.host && <div className="addon-details">
            <FlagIcon>
                <span className={`bg-${addon?.country?.toLowerCase()}`}></span>
            </FlagIcon>
            <span className='dedicated-ip-address-text'>{`${countriesList[addon.country]}`}</span>

            <span className='seperator'></span>
            <span>{addon?.ip}</span>
        </div>}
        <p>Your existing Dedicated IP will also be migrated to Team plan</p>
    </HasDedicatedIpViewStyles>
}
export default HasDedicatedIpView;