import styled, { css } from "styled-components";
import Sidebar from "../../component/dashboard/layout/Sidebar";
import { SidebarWrapper } from "./SideBar";

export const NavbarWrapper = styled.div`

  z-index:999;
  background: white;
  /* -- media query for all devices -- */

  /* Large Tablets and Mobile Devices */

  @media only screen and (min-width: 1201px) {
    display: none !important;
  }
  ${props => props.hide && css`
      display: none;
  `}

  @media only screen and (max-width: 1200px) and (min-width: 750px) {
    width: 100% !important;
    height: 60px;
    margin-bottom: 40px !important;
    box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
    border-radius: 6px 0px 0px 0px;
    padding: 12px 35px;
    position: relative;
    ${props=>props.isNoPaddingView && css`
      margin-bottom: 0 !important;
    `};
    .hamberg-icon {
      width: 25px !important;
      height: 3px !important;
      color: #43225b !important;
      background: #43225b !important;
      margin: 5px 0 !important;
      border: 0.2px solid #43225b !important;
    }

    .hamberg-div {
      align-items: center;
      margin: auto 0;
      border: none;
    }

    .sidebar-menu {
      background-color: rgba(246, 244, 247);
      box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
      width: 235px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      z-index: 1;
      top: 0;
      left: -100%;
      transition: 350ms !important;
    }

    .sidebar-menu.active {
      left: 0;
      transition: 350ms !important;
    } 
  }

  @media only screen and (max-width: 750px) and (min-width: 270px) {
    width: 100% !important;
    height: 50px;
    margin-bottom: 25px !important;
    ${props=>props.isNoPaddingView && css`
      margin-bottom: 0 !important;
    `};
    box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
    border-radius: 6px 0px 0px 0px;
    padding: 5px 20px;
    position: relative;

    .nav-logo{
      width:120px;
    }
    .hamberg-icon {
      width: 20px !important;
      height: 2px !important;
      color: #43225b !important;
      background: #43225b !important;
      margin: 4px 0 !important;
      border: 0.5px solid #43225b !important;
    }

    .hamberg-div {
      align-items: center;
      margin: auto 0;
      border: none;
    }

    .sidebar-menu {
      background-color: rgba(246, 244, 247);
      box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.2);
      width: 235px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      z-index: 9999999999;
      top: 0;
      left: -100%;
      transition: 350ms !important;
    }

    .sidebar-menu.active {
      left: 0;
      transition: 350ms !important;
    }
  }

`;
