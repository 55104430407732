export const suggestedPrompts = [
    "How to stay secure online?",
    "Search similar movies/titles/shows like Friends",
    "How to play a multiplayer game online using VPN?",
    "How can a password manager help me?",
    "How to watch The Office in UK?",
    "How can I find the best deals on purchasing Call of Duty game?",
    "Can my Internet Service Provider (ISP) detect when I am using a VPN?",
    "I am feeling {bored}. Suggest me the best movie",
    "Which platforms are supported by PureVPN?"
];


export const suggestedPromptsLess = [
    "How to stay secure online?",
    "Search similar movies/titles/shows like Friends",
    "How to watch The Office in UK?",
    "Which platforms are supported by PureVPN?"
];

export const PopularPrompts = [
    {
        "category": "Security",
        "prompt": "How to stay secure online?"
    },
    {
        "category": "Gaming",
        "prompt": "How to play a multiplayer game online using VPN?"
    },
    {
        "category": "Privacy",
        "prompt": "How can I check my IP after connecting to a VPN?"
    },
    {
        "category": "General",
        "prompt": "Which platforms are supported by PureVPN?"
    },
    {
        "category": "Gaming",
        "prompt": "How can I find the best deals on purchasing Call of Duty game?"
    },
    {
        "category": "Privacy",
        "prompt": "Can my Internet Service Provider (ISP) detect when I am using a VPN?"
    },
    {
        "category": "Security",
        "prompt": "What are the advantages of using a password manager?"
    },
    {
        "category": "Streaming",
        "prompt": "Search similar movies/titles/shows like Friends"
    },
    {
        "category": "Streaming",
        "prompt": "How to watch The Office in UK?"
    }
];