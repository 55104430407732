import {  CardNumber, CredentialsInput, DashboardHead, InfoCard, Input, SubscriptionBox, SubscriptionAddonGroup, PopupBody, OrderSummary, SubscriptionWrapper, WireGuardKeysCard, NewTag } from '../../../styles/dashboard/Main'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages'
import { checkState, hasPureMaxAddons, validatePurchasedApps } from '../../../tools/genericMethods'
import { connect, useDispatch } from 'react-redux'
import { countriesList } from '../../../tools/countries' 
import { dateFormat, fetcher, getCurrentSubscription, isOfflineGateway, poster, filterSlug, isWhmcsUser, isResellerUser, getPaymentMethods, allowPurchaseForManualGateways, isManualGateway, isAddon, checkIsAddonPaid, selectSub, getEligibleSubToUpsell, checkAccountType } from '../../../tools/tools'
import { DestructBtn, InsetBtn, LinkBtn, PrimaryBtn, RLink } from '../../../styles/Generic'
import { FlagIcon } from '../../../styles/flags/Flag'
import { getReferRequested } from '../../../redux/refer/actions'
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import { Link, useLocation } from 'react-router-dom'
import { sendEvents } from '../../../tools/mpEvents'
import { toast } from 'react-toastify'
import {addonConstants, addonData, addonState, constants, puremaxProducts, signupFrom, pureVPNPurchasePageURL } from '../../../tools/constants'
import AddonPurchase from './modals/AddonPurchase'
import Aside from '../layout/Aside'
import BillingCycle from './modals/BillingCycle'
import Cancellation from './cancellation/cancellation'
import CancelSubscription from './modals/CancelSubscription'
import check from '../../../assets/check.svg'
import classNames from 'classnames'
import copy from '../../../assets/copy.svg'
import DomeForm from '../layout/partials/DomeForm'
import ErrorBoundary from '../../ErrorBoundary'
import GeneratePassword from './modals/GeneratePassword'
import hide from '../../../assets/hide.svg'
import locked from '../../../assets/locked.svg'
import NotSupported from './modals/NotSupported'
import PauseSubscription from './modals/PauseSubscription'
import PaymentMethod from './modals/PaymentMethod'
import qs from 'qs'
import React, { useState, useEffect, useRef } from 'react'
import ReactivateSubscription from './modals/ReactivateSubscription'
import RenewalModal from './modals/RenewalModal'
import ResumeSubscription from './modals/ResumeSubscription'
import show from '../../../assets/show.svg'
import Skeleton from 'react-loading-skeleton';
import TransactionModal from './modals/TransactionModal'
import UpdateCountry from './modals/UpdateCountry'
import AddonView from './utils/AddonView'
import MultiLogin from './utils/MultiLogin'
import { useTranslation } from 'react-i18next'
import { getLocaleFromUrl, checkLocale, seti18Locale, engagementLoyaltyApi } from '../../../tools/tools';

import BundleField from './child/BundleField'
import RefundModal from './modals/RefundModal'
import {getTicketsRequested} from '../../../redux/tickets/actions'
import PaymentIcon from '../../../utils/PaymentIcon/PaymentIcon'
import { isMax } from '../upgrade/upgrade-tools'
import { checkBundleEligibility } from './tools/SubscriptionTools'
import ProxyDedicatedIp from './modals/ProxyDedicatedIp'
import QRcodeGenerator from '../on-boarding/QRcodeGenerator';
import { browserName, isWindows, isMacOs, osName } from 'react-device-detect';
import CircularLoader from '../../generic/CircularLoader'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TeamsInfoModal from './modals/partials/TeamsInfoModal';


const Subscriptions = ({history, subscriptions, getSubscriptionsRequest, getReferRequested, onBoardingReducer, getTicketsRequested}) => {

    const isStorUsereAllowed = process.env.REACT_APP_ALLOW_UPSELL_APPSTORE_PLAYSTORE === "true" ? true : false;

    const { search, hash, pathname} = useLocation();
    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isPaymentModel = queryParams.get('isPaymentModel');
    const [data, setdata] = useState(null)
    useEffect(() => {
        if(isPaymentModel && !subscriptions?.subscriptions?.body[0]?.is_child && !isResellerUser(subscriptions?.subscriptions?.body[0]?.payment_gateway)){
            openModal('payment-sm', subscriptions?.subscriptions?.body[0])
        }
    }, [data])

    const {redirected_via, update_subscription, manual_transaction_status, renewal, renewId, renew_addon, type, subscription_id, id, add_on, viaProxy, isProxyCampaign, viaHelloBar, plan, features_metric} = Object.fromEntries(new URLSearchParams(search));

    const [asideType] = useState("refer")
    const [authorId, setAuthorId] = useState("")

    const [showQR , setShowQR] = useState(false);

    const [deepLinkURL , setDeepLink] = useState('');

    const [newAdded, setnewAdded] = useState(false)
    
    const [crashed, setCrashed] = useState(false)

    const dispatch = useDispatch()

    const [loading, setloading] = useState(subscriptions?.subscriptions?.body?.length ? false : true)

    const [ctaLoading, setctaLoading]  = useState(false);

    const token = localStorage.getItem("token");

    const [state, setState] = useState("")
    
    const [expired, setExpired] = useState(true);

    const [orderSummary, setorderSummary] = useState(true)

    const [modal, setModal] = useState(false);

    const [modalType, setmodalType] = useState('')

    const [domeModal, setDomeModal] = useState(false)

    const [multiLogindomeModal, setmultiLogindomeModal] = useState(false)

    const toggleModal = () => setmultiLogindomeModal(true)

    const [transactionStatus, setTransactionStatus] = useState(false);

    const [noSub, setNoSub] = useState(false);

    const [isFree,setIsFree] = useState(true);

    const [openOrderSummary, setOpenOrderSummary] = useState();

    const isMultiLoginHide = process.env.REACT_APP_MULTILOGIN_VIEW === "true" ? true : false;

    const [isUser30DaysOld, setIsUser30DaysOld] = useState(false);

    const [apiEndPoint, setApiEndPoint] = useState('subscription/change');


    const [purchasedApps, setPurchasedApps] = useState({
        purekeep: false,
        purescan: false,
        purecrypt: false,
        pureprivacy: false,
        purevpn: false,
    });

    const [hasViaProxy, setHasViaProxy] = useState();

    const [hasCountry,setHasCountry] = useState(true)

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [addonType, setaddonType] = useState({
        type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:""
    })

    const [notsupportedState, setNotsupportedState] = useState({
        type:"",
        gateway:""
    })
    const widthCalculator = modalType.slice(-2)

    const [taxInfo, setTaxInfo] = useState(null)

    const toggle = () => setModal(!modal);

    const [selectedUsername, setselectedUsername] = useState("")

    const [isMultipleActiveSubscription, setIsMultipleActiveSubscription] = useState(0)

    const [openTeamModalMetric, setOpenTeamModalMetric] = useState(false);
    const [selectedSubscription, setselectedSubscription] = useState("")

    const shouldShowDedicatedServer = process.env.REACT_APP_SHOW_DEDICATED_SERVER === '1';

    const toggleQRTooltip = () => {
        setShowQR(!showQR);
      };

    const elementRef = useRef(true);
    const copyAddress = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success(t('copied_to_clipboard_text'))
        } catch (error) {
            toast.success(t('unable_to_copy_text'))    
        }
    }

    const openModal = (modalName, selected="", plan="", currency="", subscriptionPlan="", service_origin="", end_date="", state="",gateway="",number=null) => {
        setIsFree(true)
        setHasCountry(true)
        if(modalName === "notsupported-sm"){
            setNotsupportedState({
                type:plan,
                gateway:selected
            })
        }
        
        if(modalName === "password-sm"){
            setcopiedpassusername({username:"",password:"", type:""})
            setselectedUsername(selected)
            sendEvents({
                event_name:"ma_click_regenerate_vpnpassword",
                event_properties:{
                    source: "Member Area",
                    vpnusername: selected
                }
            }, token)
        }
        if(modalName === "cancel-sm" || modalName === "pause-sm" || modalName === "resume-sm" || modalName === "reactivate-sm" || modalName === "cancellation-sm"){
            if(!modalName == "cancellation-sm"){
                setselectedSubscription(selected)
            }
            else{
                setselectedSubscription({
                    id: selected, 
                    service_origin, 
                    end_date,
                    state
                })
            }
        }
        if(modalName === "addon-lg"){
            setOpenOrderSummary(false);
            setaddonType({
                type: plan,
                subscription:selected,
                currency:currency,
                plan:subscriptionPlan,
                gateway,
                number
            })
            sendEvents({
                event_name:"ma_click_purchase_addon",
                event_properties:{
                    addon_name: plan,
                    source: "subscription",
                }
            }, token)
        }
        if(modalName === "billing-lg"){
            setselectedSubscription({
                plan: plan,
                subscription:selected,
                currency:currency
            })
            const currentSubscription = getCurrentSubscription(subscriptions, selected)
            sendEvents({
                event_name:"ma_click_upgradesubscription",
                event_properties:{
                    current_billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                    payment_gateway: getPaymentMethods(currentSubscription)
                }
            }, token)
        }
        if(modalName === "renewal-lg" || modalName === "renewal-lg-v2"){
            
            const currentSubscription = getCurrentSubscription(subscriptions, selected)
            
            sendEvents({
                event_name:"ma_click_renew_subscription",
                event_properties:{
                    current_billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                    payment_gateway: getPaymentMethods(currentSubscription),
                    productid: currentSubscription?.code  ,
                    current_status: currentSubscription?.state ,
                    invoice_id: currentSubscription?.renew_invoice,
                    source: "subscriptions"
                }
            }, token)

            let subscriptionData = {
                subscription:selected,
                invoice:plan,
                service_origin: currentSubscription?.service_origin, 
                currency: currentSubscription?.currency
            }
            if (modalName === "renewal-lg-v2") {
                /* we are not displaying another modal for renewal but are using the same modal, just updating the expired bit to change the edn point (for canceled/refunded users) */
                modalName = "renewal-lg";
                subscriptionData.expiredSubscription = true
                subscriptionData.invoice             = 1
            }
            setselectedSubscription(subscriptionData)
        }
        if(modalName === "payment-sm"){
            setselectedSubscription(selected)
        }
        setmodalType(modalName)
        toggle()
    }

    const showSubscription = ({target:{id}}) => {
        const checkState = state !== id ? id : ""
        setState(checkState)
    }

    const handleClickCancelSubsButton = (payload) => {

        const {id, service_origin, end_date, state} = payload

        let showPopup = false;

        data?.map((subscription,key) => {
            if (subscription.id == id) {
                showPopup = (subscription.payment_gateway_offline)
            }
        })

        if (!showPopup) {
            sendEvents({
                event_name:"ma_click_cancel_subscription", // changed as per cancellation flow revamp // BA-591
                event_properties:{
                    source: window.location.pathname
                }
            }, token)
            if(service_origin === constants.purevpn){
                openModal('cancellation-sm', id, null, null, null, service_origin, end_date, state)
                setModal(true)
            }
            else{
                history.push(`/dashboard/subscriptions/cancel/${id}`)
            }
            
        } 
        else {
            openModal("cancel-sm", id)
        }

    }

    const  initOpenModel = (subscription, addOnSlug, cta="add-on",isAddonRenew=false,src="subscription") => {
        if(isWhmcsUser(subscription?.account?.billingType)){
            if(cta != "add-on" && constants.payment_not_support_gateways.includes(subscription?.payment_gateway)){
                let messageKey = "whmcs_mobile_payment_gateway"
                    sendEvents({
                        event_name: "ma_expiry_popup_shown",
                        event_properties: {
                          cta,
                          billing_type: subscription?.account?.billingType ?? "whmcs",
                          billing_cycle: subscription?.plan?.type ?? "N/A",
                          gateway: subscription?.payment_gateway ?? "N/A",
                        }
                    }, token);
                    messageKey = "payment_method_not_support"
                openModal('notsupported-sm', null, messageKey)
                return;
            }
            if(signupFrom.includes(subscription?.account?.signup_from)) {
                let error = "signup_from_addon"
                switch(cta) {
                    case "add-on":
                        error = "signup_from_addon"
                    break;
                    case "renew":
                        error = "signup_from_renew"
                    break;
                }
                openModal('notsupported-sm', null, error)
                return;
            } else if (subscription?.state == "past_due" || subscription?.state == "expired" ){
                openModal('notsupported-sm', null, 'past_due_whmcs')
                return;
            }
            else if(subscription?.payment_gateway_offline && !subscription?.account?.has_mobile_gateway_for_whmcs){
                openModal('notsupported-sm', subscription?.payment_gateway, 'addons')
                return;
            }
        }
        if(constants.past_due_state?.includes(subscription?.state) && subscription?.payment_gateway_offline ){
            openModal('notsupported-sm', subscription?.id, 'past_due_manual_gateway')
            return;
        }

        const filterAddon = subscription?.add_ons?.find(addon => addon?.code === addOnSlug)
        if(isAddonRenew){
            setIsFree(false)
            setmodalType("addon-lg")
            setModal(true)
            setHasCountry(false)
                setaddonType({
                    type: addOnSlug,
                    subscription: subscription?.id,
                    currency: subscription?.currency,
                    plan: subscription?.plan?.code,
                    gateway:subscription?.payment_gateway
                })
                sendEvents({
                    event_name:"ma_click_purchase_addon",
                    event_properties:{
                        addon_name: addOnSlug,
                        source: src,
                        cta
                    }
                }, token)
        }
        else{
            openModal('addon-lg', subscription?.id, addOnSlug, subscription?.currency, subscription?.plan?.code, "","","",subscription?.payment_gateway,filterAddon?.number)
        }

    }

    useEffect(()=>{
        if(redirected_via && window.Intercom){
            window.Intercom('shutdown')
        }
    }, [])

    useEffect(() => {
        
        // if (!subscriptions.subscriptions) {
                // Removed this condition *IF* because the subscriptions refresh API was not getting called and the CTA button changes were not being reflected.
            getSubscriptionsRequest(token)
        // }

        if(manual_transaction_status){
            setTransactionStatus(manual_transaction_status);
            openModal('transaction-sm');
            setloading(true)
        }
        // if(asideType === "refer"){
        //     getReferRequested(token)
        // }
        sendEvents({
            event_name:"ma_account_see_subscriptions",
            event_properties:{
                source: "subscriptions"
            }
        }, token)

    }, [])

    const removeParams = (param) => {
        const queryParams = new URLSearchParams(search)
        if (queryParams.has(param)) {
            queryParams.delete(param)
            history.replace({
                search: queryParams.toString(),
            })
        }
    }

    const removeAllParams = (path="subscriptions") => {
        history.replace({ pathname: path, search: ''})
    }

    const upsellCart = (params) => {
        const {subscriptions,name,id} = params
        if(name){
            //* Filter subscriptions wrt provided id else find Eligible sub which can do the upsell
            const sub = id ? selectSub(subscriptions?.body,id) : getEligibleSubToUpsell(subscriptions?.body,name) 

            //* if in url it is provided pureprivacy or purescan store purescan else store what ever is passed in the url (addon name)
            const addon_name = constants.purprivacy_addon.includes(name) ? constants.purprivacy_addon : name
            const addonSlug = Array.isArray(addon_name) ?  constants.purescan : addon_name;

            if(viaProxy){
                setHasViaProxy(true)
            }

            if(!redirected_via){
                removeAllParams()
            }
            if(constants.applicationAddons.includes(addonSlug)){
                setHasCountry(false)
            }
            //* if user dont have addon binded with the sub else open the unelgible pop up
            if(!isAddon(sub?.add_ons,addon_name) && sub?.id){
                //* upsell modal params
                setaddonType({
                    type: addonSlug,
                    subscription:sub?.id,
                    gateway:sub?.payment_gateway,
                    currency:sub?.currency,
                    plan:sub?.plan?.code,
                })
                sendEvents({
                    event_name:"ma_click_purchase_addon",
                    event_properties:{
                        addon_name: addonSlug,
                        source: viaProxy ? "Proxy" : viaHelloBar ? "Hello bar" : "Apps",
                    }
                }, token)

                // redirected_via ? initOpenModel(sub,name) : setmodalType("addon-lg")
                setmodalType("addon-lg")
                
                
            }
            else{
                openModal('notsupported-sm', null, "purchased")
            }
            toggle()
        }
    }

    const renewalChecker = (reduxData) => {
        /* Addon Upsell Cart for Free Add-ons */
        if(typeof renew_addon !== 'undefined' && renew_addon.length>4 && typeof reduxData !== 'undefined'){
            let sub = null;
            if(renewId){
                sub = reduxData.body.find( sub => sub.id == renewId);
            }
            let addon_code= renew_addon;
            removeParams("renew_addon")
            if(typeof sub !== 'undefined'){
                if(sub.state === "expired"){
                    return
                }
                else{
                    renewFreeAddon(addon_code,sub,"dashboard")
                }
            }
        }
        /* Renewal Cart for Subscription */
        if((typeof update_subscription !== 'undefined' && update_subscription === 'true' && typeof reduxData !== 'undefined')){

            let subIndex = null;
            if(renewId){
                subIndex = reduxData.body.find( sub => sub.id == renewId);
            }else{
                subIndex = reduxData.body.find(sub=> ( sub.state === 'past_due') || (sub.state === 'expired' && typeof sub?.renew_invoice !== 'undefined') || (sub?.state === "canceled") || (sub.state === 'paused' || sub.is_paused === true));
            }         

            if (signupFrom.includes(subIndex?.account?.signup_from) ) {
                initOpenModel(subIndex,"","renew");
            } else {
                removeParams("update_subscription")
                if(typeof subIndex !== 'undefined'){
                    var subId = renewId ?? subIndex?.id;
                    if((subIndex?.state === 'past_due') || (subIndex?.state === 'expired' && typeof subIndex?.renew_invoice !== 'undefined')){
                        let renew_invoice = subIndex?.renew_invoice;
                        if (isWhmcsUser(subIndex?.account?.billingType) && renew_invoice == "") {
                            renew_invoice = 1;
                        }
                        openModal('renewal-lg', subId, renew_invoice)
                    }else if(subIndex?.state === 'paused' || subIndex?.is_paused === true){
                        openModal('resume-sm', subId)
                    }else if(subIndex?.state === "canceled"){
                        openModal('reactivate-sm', subId)
                    }
                }
            }

        }
    }

    const showProxyModal = () => {
        setModal(true)
        setmodalType('dedip-proxy')
    }
    useEffect(() => {
        getTicketsRequested(token)
    }, [])

    useEffect(()=>{
        if(add_on && data){
            setOpenOrderSummary(true)
            initOpenModel(...data?.filter((subscription)=> subscription.id === id), add_on)
        }
        if(hash === '#paymentMethod'){
            openModal('payment-sm', ...data?.filter((subscription)=> subscription.id === id))
        }
    }, [id, add_on, hash, data])

    useEffect(() => {
        // Get the start_date from the subscriptions
            const start_date = subscriptions?.subscriptions?.body?.[0]?.start_date;

        // Calculate whether the user is 30 days old or not
            const user30DaysOld = () => {
            const startDate = new Date(start_date);
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - startDate.getTime();
            const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
            return daysDiff >= 30;
            }
        // Set the state of isUser30DaysOld
           setIsUser30DaysOld(user30DaysOld());
        const { loading: reduxLoading, subscriptions: reduxData, errors, errorType, resetState, errorMessage, tickets} = subscriptions;
        if (tickets && !loading) {
            setdata(tickets?.body || [])
            setAuthorId(tickets?.body[0]?.author_id || "")
            setloading(false)
        }
        if(resetState){
            setloading(true);
        }
        if(reduxData?.body?.length){
            setdata(reduxData?.body);
            setloading(false);
            renewalChecker(reduxData)
            if(isProxyCampaign){
                showProxyModal()
            }
            if(elementRef.current){
                elementRef.current=false;
                if(plan && features_metric == 1){
                    toggleTeamMetricModal();
                }
                else upsellCart({subscriptions:reduxData,name:type,id:subscription_id ?? id})
            }
            if(viaHelloBar){
                // opening single subscription in case of multi sub
                if(reduxData?.body.length > 1){
                    setState(`sub${reduxData?.body?.findIndex((sub)=> sub?.id == subscription_id)}`)
                }
                setOpenOrderSummary(false)
                upsellCart({subscriptions:reduxData,name:type,id:subscription_id ?? id})
            }

            let purchasedApps = validatePurchasedApps(reduxData?.body)

            setPurchasedApps(purchasedApps)

        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})  
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                    break;  
                case "no_subscription_exception":
                    setNoSub(true)
                    setCrashed(true)
                    triggerNoSubEvent(errorMessage)
                    break;
                case "APIFAILED":
                    setCrashed(true)
                    triggerNoSubEvent(errorMessage)
                    break;
                default:
                    setCrashed(true)
                    triggerNoSubEvent(errorMessage)
                    break;
            }
        }
    }, [subscriptions, viaHelloBar])

    const toggleTeamMetricModal = () => {
        setOpenTeamModalMetric(!openTeamModalMetric)
        
    }
    const triggerNoSubEvent = (errorMessage) => {
        sendEvents({
            event_name:"ma_fail_subscriptiondetails",
            event_properties:{
                reason: errorMessage ?? "Unable to fetch subscriptions."
            }
        }, token)
    }

    const remainingDays = (endDate) => {
        if(endDate === undefined){
            return 0
        }
        const date1 = new Date();
        const date2 = new Date(endDate);
        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return Math.round(Difference_In_Days)
    }

    const checkAddOn = (sub, type) => {
        const {add_ons ,account} = sub
        const check = Array.isArray(add_ons) ? add_ons.some((addon) => addon.code === type && checkIsAddonPaid(addon)) : false;
        return check
    }
    
    const checkAddOnIpAndHost = (addons = []) => {
        const check = Array.isArray(addons) ? addons.some((addon) => addon?.ip && addon?.host) : false;
        return check
    }

    const addonField = (addons = [], type) => {
        const addon = addons.find((addon) => addon.code === type);
        const addonStatus = addon.status;
        const ip_address = addon?.ip;
        const host_address = addon?.host;
        if (ip_address && host_address) {
            return (
                <div className='dedicated-ip-details'>
                     <div className='dedicated-ip-flag'>
                        <FlagIcon>
                            <span className={`bg-${addon?.country?.toLowerCase()}`}></span>
                        </FlagIcon>
                        <span className='dedicated-ip-address-text'>{`${countriesList[addon.country]}`}</span>
                        <span className='dedicated-ip-address-seperator'></span>
                        <span className='dedicated-ip-address-text'>{ip_address}</span>
                        <button className={`btn btn-link p-0 btn-tooltip ${ip_address === copiedipaddress && "copied"}`} >
                            <img src={copy}  onClick={copyAddress} id={ip_address}/>
                            <span className="tooltiptext" id="myTooltip">{t('copied_text')}</span>
                         </button>
                    </div>
                    <div className='dedicated-ip-host'>
                        <span className='dedicated-ip-address-seperator'></span>
                        <span className='dedicated-ip-address-text host-address'>{host_address}</span>
                        <button className={`btn btn-link p-0 btn-tooltip ${host_address === copiedipaddress && "copied"}`}  >
                            <img src={copy} onClick={copyAddress} id={host_address}/>
                            <span className="tooltiptext" id="myTooltip">{t('copied_text')}</span> 
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
              <div className='purchased-image'>
                <img src={check} height="18" width="18" />
                <span className='ms-1'>{t('subscription_addon_purchased')}</span>
                <div className='status-div'>
                {addon.code === "dedicated_server" && addonStatus === "deploying" ? <p>Deploying. Hang tight it will take about 10 minutes <span className="spinner-border text-dark spinner-border-sm ms-1 "></span></p> : null}
                </div>
              </div>
            );
          }

    }

    //Check if the subscription end date has been passed or not. 
    const endDatePassed = (date) => {
        const enddate = new Date(date)
        const current = new Date()
        const status = current > enddate ? {status:"Expired", message:t('subscription_status_your_subscription_has_been_expired')} : {status:"Past Due", message:t('subscription_status_your_subscription_has_been_expired_as_we_didnt_recived_payment')}
        return status
    }

    const [copiedusername, setcopiedusername] = useState("")

    const [copiedipaddress, setcopiedipaddress] = useState("")

    const [copiedpassusername, setcopiedpassusername] = useState({
        username:"",
        password:"",
        type:""
    })

    const [showCopied, setShowCopied] = useState(false)

    const [passwordLoaders, setpasswordLoaders] = useState({
        show:false,
        copy:false,
        username:""
    })

    const copyUsername = async (e) => {
        try {
            setcopiedusername(e.currentTarget.id)
            await navigator.clipboard.writeText(e.currentTarget.id)
            setTimeout(() => {
                setcopiedusername("")
            }, 1500);
        } catch (error) {
            toast.error("Unable to copy!")
        }
    }

    const showPassword = async (name, type) => {
        if(copiedpassusername.username && copiedpassusername.password && type === "show" && copiedpassusername.username === name){
            return setcopiedpassusername({username:"",password:"",type:""})
        }
        const username = name;
        setpasswordLoaders({
            ...passwordLoaders, show:type==="show" ? true : false, copy:type==="copy" ? true : false, username:username
        })
        try {
            const formdata = qs.stringify({username: username})
            const password =  await poster("vpn/get-password", formdata, token)
            const {data: response} = password;
            if(response?.status){
                setcopiedpassusername({
                    password:response?.body?.password, username: response?.body?.username, type:type
                })
                if(type==="copy"){
                    try {
                        await navigator.clipboard.writeText(response?.body?.password)
                        setShowCopied(true)
                    } catch (error) {
                        toast.error(t('unable_to_copy_text'))
                    }
                }
                
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break;  
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break; 
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
                
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            
        }
        finally{
            setpasswordLoaders({
                ...passwordLoaders, username:""
            })
            
            setTimeout(() => {
                // setcopiedpassusername({...copiedpassusername, type:""})
                setShowCopied(false)
            }, 2000);
        }
        
    }

    const trialDays = (data) => {
        let trial_started_at = new Date(data?.trial_started_at);
        let trial_ends_at = new Date(data?.trial_ends_at);
        const time = trial_ends_at.getTime() - trial_started_at.getTime();
        const days = time / (1000 * 3600 * 24);
        const text = days > 1 ? "Days Trial" : "Day Trial"
        return `${days} ${text}`
    }

    const checkIsFreeAddon = (subscription,name="") => {
        //check if addon is purchased/assigned as a free service
        return subscription?.add_ons.some(addon=>[name,"pureprivacy"].includes(addon?.code))
    }

    const checkIsPending = (sub,slug="") => {
        //* To check if the addon is pending state or due for manual gateway users
        const addon = isAddon(sub?.add_ons,[slug,"pureprivacy"])
        return  addon && addon?.state != addonState.paid && isManualGateway(sub?.payment_gateway) && !isWhmcsUser(sub?.account?.billingType) &&
        <p className="danger mt-1">{constants.pending_msg}</p>
    }

    const checkIsFreeExp = (subscription,name) => {
        return subscription?.add_ons.some(addon=>[name,"pureprivacy"].includes(addon?.code) && !addon?.is_purchased && addon?.status === "expired")
    }

    if(crashed){
        if(noSub){
            return <ErrorBoundary heading={t('addon_purchasemodal_uhoh')} text={t('subscription_status_dont_have_active_subscription')} noSubscription></ErrorBoundary>
        }
        return <ErrorBoundary></ErrorBoundary>
    }

    const resumeSelectedSubscription = async (selectedSubscriptionId, endPointName) => {
        setctaLoading(true)
        
        const currentSubscription = getCurrentSubscription(subscriptions, selectedSubscriptionId);
        
        sendEvents({
            event_name: currentSubscription.state === "paused" ? "ma_renew_subscription" : "ma_resume_subscription",
            event_properties:{
                gateway: currentSubscription?.payment_gateway ?? "N/A",
                biling_cycle: currentSubscription?.plan?.type ?? "N/A"
            }
        }, token)

        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscriptionId
            })
            const cancel =  await poster(`subscription/${endPointName}`, formdata, token)
            const {data: response} = cancel;
            if(response?.status){
                dispatch(getSubscriptionsRequest(token))
                dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                toast.success(t('inapp_purchase_subscription_resumed'))
                
                sendEvents({
                    event_name:currentSubscription.state === "paused" ? "ma_renew_subscription" : "ma_resume_subscription",
                    event_properties:{
                        billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                        payment_method: currentSubscription?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: "active"                     
                    }
                }, token)
                engagementLoyaltyApi(JSON.stringify({ renew_subscription: {} }))
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;   
                    case "recurly_exception":
                        toast.error(response?.message)
                        break;   
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;  
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break; 
                    default:
                        toast.error(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_resume_subscription",
                    event_properties:{
                        reason: response?.message,
                        billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                        payment_method: currentSubscription?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: currentSubscription?.state ?? "N/A"            
                    }
                }, token)
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_resume_subscription",
                event_properties:{
                    reason: "API break or Server not responding",
                    billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                    payment_method: currentSubscription?.billing_info?.last_four ? "stripe" : "Paypal",
                    current_status: currentSubscription?.state ?? "N/A"                      
                }
            }, token)
        }
        finally{
            setctaLoading(false)
        }

    }

    const buttonText = (text) => {
        return !ctaLoading ? `${text}` : ( <>{text} <span className="spinner-border mt-0 text-light spinner-border-sm ms-1"></span></>);
    } 

    const changePaymentMethod = () => {
        setmodalType('payment-sm')
        setModal(true)
    }

    const onClickGetAppAddOn = (appSlug, e, subscription) => {

        if (appSlug) {
            sendEvents(
                {
                event_name: `ma_click_view_addon`,
                event_properties: {
                    source: "subscription",
                    cta: filterSlug(e.target.innerHTML),
                }
                }, token
            );

            history.push(`/dashboard/plans`)
        }
    }

    const cardlessRenew = (id) => {
        const currentSubscription = getCurrentSubscription(subscriptions, id)
        sendEvents({
            event_name:"ma_click_renew_subscription",
            event_properties:{
                current_billing_cycle: currentSubscription?.plan?.type ?? "N/A",
                payment_gateway: currentSubscription?.payment_gateway ?? "-",
                productid: currentSubscription?.code ?? '-'  ,
                current_status: currentSubscription?.state ?? '-' ,
                invoice_id: '-',
                source: "subscriptions"
            }
        }, token)
        window.open(`${process.env.REACT_APP_CARDLESS_REDIRECT}`)
    }

    const renewFreeAddon = (addon_name,subscription,src="subscription") => {
        initOpenModel(subscription,addon_name,"renew-addon",true,src)
    }

    const multiLoginCTAHandle = (id) => {
        const currentSubscription = getCurrentSubscription(subscriptions, id)
        sendEvents({
            event_name:"ma_request_increase_multiloginlimit",
            event_properties:{
                billing_type: currentSubscription?.account?.billingType ?? "N/A",
                selected_interface_screen: "subscriptions",
                email: currentSubscription?.account?.email  ?? "N/A"

            }
        }, token)
        toggleModal();
    }

    const showAddonView = (param) => {
        let isAddonViewEligible = true;
        const {subscription,type} = param
        switch(type){
            case addonConstants.dedicated_ip:
                isAddonViewEligible= !checkAddOn(subscription, "port_forwarding_with_dedicated_ip") && !checkAddOn(subscription, "ddos_protection")
                break
            case addonConstants.ddos_protection:
                // Show DDoS Protection only if the user already has it as an addon
                isAddonViewEligible = checkAddOn(subscription, "ddos_protection");
                break;
            case addonConstants.port_forwarding_with_dedicated_ip:
                isAddonViewEligible= (!checkAddOn(subscription, "dedicated_ip") && !checkAddOn(subscription, "port_forwarding")  && !checkAddOn(subscription, "ddos_protection")) || checkAddOn(subscription, "port_forwarding_with_dedicated_ip")
                break
            case addonConstants.port_forwarding:
                isAddonViewEligible= !checkAddOn(subscription, "port_forwarding_with_dedicated_ip")
                break
            case addonConstants.multi_login:
                isAddonViewEligible = isMultiLoginHide ? false : true;
                break
            case addonConstants.dedicated_server:
                isAddonViewEligible = shouldShowDedicatedServer ||  checkAddOn(subscription, "dedicated_server");
        }   
        return isAddonViewEligible
    } 

    const refundModalCTAHandle = () => {
        sendEvents({
            event_name:"ma_click_refundform",
            event_properties:{
            }
        }, token)
        openModal('refund-lg');
    } 

    const UpgradeToTeamsCTAHandle = (sub) => {
        setselectedSubscription({
            id: sub?.id
        })
        sendEvents({
            event_name:"ma_view_account_comparision_table",
            event_properties:{
                plan_type: sub?.plan_type ?? 'N/A',
                billingcycle: sub?.type ?? 'N/A',
                payment_method: getPaymentMethods(sub) ?? 'N/A',
                current_status: sub?.state ?? 'N/A',
                account_type: checkAccountType(sub) ?? 'N/A',
                selected_account: 'teams',
                platform_from: redirected_via ? 'Apps' : 'Member Area'
            }
        }, token)
        toggleTeamMetricModal();
    } 

    const getMoreSubscriptionCTAHandle = () => {
        history.push("/dashboard/teams")
        sendEvents({
            event_name:"ma_click_get_multiple_subscription_cta",
            event_properties: {
                get_volume_via: 'subscription page',
                number_of_subscriptions: data?.length,
            }
        }, token)
    }

    const openDeepLinkCta = (appType) => {
        let deepLink;
        let eventName;
        let shouldOpenLink = true;
      
        switch (appType) {
          case 'purevpn':
            deepLink = process.env.REACT_APP_PUREVPN_DEEPPLINK;
            eventName = 'ma_subscriptions_click_download_purevpn';
            break;
          case 'pureencrypt':
            deepLink = process.env.REACT_APP_PUREENCRYPT_DEEPPLINK;
            eventName = 'ma_subscriptions_click_download_pureencrypt';
            break;
          case 'purekeep':
            deepLink = process.env.REACT_APP_PUREKEEP_APP_LINK;
            eventName = 'ma_subscriptions_click_launch_purekeep';
            break;  
          case 'pureprivacy':
            shouldOpenLink = false;
            eventName = 'ma_subscriptions_click_download_pureprivacy';
            toggleQRTooltip(); 
            break;
          default:
            return; 
        }
        if (shouldOpenLink) {
          window.open(deepLink, '_blank');
        }
        sendEvents({
            event_name: eventName,
            event_properties: {},
          }, token);
      };

    const getAccountTypeFromSubscription = (sub) => {
        return sub?.hasFamilyPlan ? 'Family' : sub?.hasVolumePlan ? 'Volume' : sub?.hasPureTeams ? 'Teams' : 'Individual'
    }

    const sendRequestToOpenFpCart = () => {
        setmodalType('billing-lg');
        setModal(true)
        setOpenTeamModalMetric(false)
        setApiEndPoint('familyplan/change')
    }

    const backToMetricTable = () => {
        setModal(false)
        setOpenTeamModalMetric(true)
    }

    const appEventHandler = (message, response) => {
        let _nativeHandler = () => window.chrome ? window.chrome.webview : (window.webkit ?? {messageHandlers: {}}).messageHandlers.addonEventHandler;
        _nativeHandler() && _nativeHandler().postMessage({
            'message': message,
            'response': response
        });
    }

    return (

        <>
            {
                redirected_via ? <div style={{display: 'flex', height: 'calc(100vh - 60px)', alignItems: "center", justifyContent: "center", overflow: "hidden"}}>
                    <CircularLoader />
                </div>
                : 
                <div>
                    <DashboardHead>
                        <h2>Subscriptions</h2>
                        <p style={{maxWidth:"675px"}}>{t('subscription_title_para')}</p>
                    </DashboardHead>
                    <SubscriptionWrapper>
                        {/*  Subscription - Annually (Free Trial 7 Days : (Zerofttp)) */}
                    <div className="sub-parent">
                    {
                        (data?.length > 1) && 
                        <div className='getMoreSubsCTA'>
                        <LinkBtn getMoreSubscription={true} onClick={getMoreSubscriptionCTAHandle}>{t('subscription_get_more_subs_text')}</LinkBtn>
                        </div>
                    }
                    {
                        !loading
                        ?
                        data?.map((subscription,key) => (
                            <SubscriptionBox open={state===`sub${key}` || data.length === 1} key={key} single={data.length === 1}>
                                {
                                    (data.length > 1) &&
                                    <div className="subscription-head">
                                        <h3 id={`sub${key}`} onClick={showSubscription}><span className={classNames(subscription?.state, 'statusdot')}></span> {subscription?.vpnusernames?.[0] ?? "Subscription"} - {(subscription?.is_trial || (subscription?.trial_ends_at === subscription?.end_date)) ? "Trial" : `${subscription?.plan?.interval_length} ${subscription?.plan?.interval_length === 1 ? t('upgradeplan_month') : t('months_text')}`}</h3>
                                    </div>
                                }
                                <div className={classNames({'show': state === `sub${key}` || data.length === 1}, 'subscription-body')} id={`sub${key}`}>
                                    {
                                        subscription?.renew_invoice_state === "pending"
                                        ?
                                            <div className='py-5'>
                                                <ErrorBoundary heading={t('addon_purchasemodal_uhoh')} text={t('subscription_status_dont_have_active_subscription')} noSubscription />
                                            </div>
                                        :
                                        <>
                                            <InfoCard hasborder>
                                                <div className="float-start">
                                                    <label className="me-2">{t('subscription_lable_billing_cycle')} <strong>{(subscription?.is_trial || (subscription?.trial_ends_at === subscription?.end_date)) ? trialDays(subscription) : (`${subscription?.plan?.interval_length} ${subscription?.plan?.interval_length === 1 ? t('upgradeplan_month') : t('months_text')}` ?? undefined) || "-"}</strong></label>
                                                </div>
                                                <div className="float-end">
                                                    {
                                                        (subscription?.state !== "expired" && !subscription?.is_trial &&  !subscription?.is_child)
                                                        ?
                                                        <>
                                                            {

                                                                signupFrom.includes(subscription?.account?.signup_from)
                                                                ?
                                                                <LinkBtn onClick={e=>openModal('notsupported-sm', subscription?.payment_gateway, 'signup_from_upgrade')}>{t('subscription_upgrade_cta_text')}</LinkBtn>
                                                                : (
                                                                    subscription?.account?.has_mobile_gateway_for_whmcs
                                                                    ?
                                                                        <LinkBtn onClick={e=>openModal('notsupported-sm', subscription?.payment_gateway, 'whmcs_mobile_payment_gateway')}>{t('subscription_upgrade_cta_text')}</LinkBtn>
                                                                    :
                                                                    (
                                                                        allowPurchaseForManualGateways(subscription?.payment_gateway) || !subscription?.payment_gateway_offline
                                                                        ?
                                                                        <LinkBtn onClick={e=>history.push("/dashboard/upgrade")}>{t('subscription_upgrade_cta_text')}</LinkBtn>
                                                                        :
                                                                        <LinkBtn onClick={e=>openModal('notsupported-sm', subscription?.payment_gateway, 'upgrade')}>{t('subscription_upgrade_cta_text')}</LinkBtn>
                                                                    )
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="float-none"></div>
                                            </InfoCard>
                                            {
                                                <BundleField subscription={subscription} initOpenModel={initOpenModel}/>
                                            }
                                            <InfoCard hasborder>
                                                <div className="float-start">
                                                    <label className="me-2">{t('subscription_lable_account_type')} <strong>{getAccountTypeFromSubscription(subscription)}</strong></label>
                                                </div>
                                               {!subscription?.hasPureTeams && <div className="float-end">
                                                <LinkBtn onClick={()=>UpgradeToTeamsCTAHandle(subscription)} >{t('change_account_type')}</LinkBtn>
                                                </div>}
                                                <div className="float-none"></div>
                                            </InfoCard>
                                            <InfoCard hasborder>
                                                <div className="float-start">
                                                <label className="me-2">{t('subscription_lable_subscription_type')} <strong>{((subscription?.is_trial || (subscription?.trial_ends_at === subscription?.end_date)) ? t('subscription_lable_subscription_type_trial') : t('subscription_lable_subscription_type_paid')) || "-"}</strong></label>
                                                </div>
                                                <div className="float-none"></div>
                                            </InfoCard>
                                            <InfoCard hasborder>
                                                <div className="float-start">
                                                    <label className="me-2">{t('subscription_lable_status')}  <strong>{
                                                        subscription?.state === "active" ?  t('subscription_lable_status_active')
                                                        : (subscription?.state === "expired" && subscription?.renew_invoice === undefined) ? t('subscription_lable_status_expired')
                                                        : subscription?.state === "canceled" ? t('subscription_lable_status_active')
                                                        : subscription?.state === "paused" ? t('subscription_lable_status_expired')
                                                        : (subscription?.state === "expired" && subscription?.renew_invoice) ? t('subscription_lable_status_expired')
                                                        : (subscription?.state === "past_due" && subscription?.renew_invoice) ? t('subscription_lable_status_expired')
                                                        : ((subscription?.state === "past_due" || subscription?.state === "expired" ) && isWhmcsUser(subscription?.account?.billingType)) ? t('subscription_lable_status_expired')
                                                        : "-"
                                                        }</strong></label>
                                                </div>
                                                <div className="float-end">
                                                    {
                                                        (subscription?.state === "expired" && (subscription?.renew_invoice === undefined)) ? <><p className="text-end danger">{t('subscription_status_expired_messsage')}</p></> :
                                                        subscription?.state === "canceled" ? <p className="grace">{t('subscription_status_canceled_messsage')} {dateFormat(subscription?.end_date ?? undefined)}</p> :
                                                        null
                                                    }
                                                </div>
                                                <div className="float-none"></div>
                                            </InfoCard>
                                            <MultiLogin subscription={subscription} multiLoginCTAHandle={multiLoginCTAHandle} initOpenModel={initOpenModel} isMultiLoginHide={isMultiLoginHide}/>
                                        {/* IMPROVEMENT NEEDED: The current expiry flow is implemented in a way that is not scalable or maintainable. TICKET BA-874*/}
                                            <InfoCard hasborder expired={expired}>
                                                <div className="float-start">
                                                    <label className="me-2"> 
                                                        {
                                                            subscription?.state === "active" || subscription?.state === "canceled" ?  <>{t('subscription_status_expiry')} <strong>{(dateFormat(subscription?.end_date) ?? undefined) || "-"}</strong> - {remainingDays(subscription?.end_date ?? undefined) <= 0 ? 0 : remainingDays(subscription?.end_date ?? undefined)} days remaining</>
                                                            : (subscription?.state === "expired" && (subscription?.renew_invoice === undefined )) ? <>{t('subscription_status_expiry')} <strong className="danger">{t('subscription_status_account_expired_on')} {dateFormat(subscription?.expires_at ?? undefined)}</strong></>
                                                            // : subscription?.state === "canceled" ? <>Expiry: <strong className="danger">Subscription Cancelled</strong></>
                                                            : (subscription?.state === "expired" && subscription?.renew_invoice) || (isWhmcsUser(subscription?.account?.billingType) && subscription?.state === "expired") ? <>{t('subscription_status_expiry')} <strong className="danger">{t('subscription_status_account_has-been-expired')}</strong></>
                                                            : (subscription?.state === "paused" && subscription?.renew_invoice === undefined) ? <>{t('subscription_status_expiry')} <strong className="danger">{t('subscription_status_account_has-been-expired')}</strong></>
                                                            : (subscription?.state === "past_due" && subscription?.renew_invoice) ? <><p className="danger">{endDatePassed(subscription?.end_date ?? new Date()).message}</p></>
                                                            : (subscription?.state === "past_due" && isWhmcsUser(subscription?.account?.billingType)) ? <><p className="danger">{endDatePassed(subscription?.end_date ?? new Date()).message}</p></>
                                                            : null
                                                        }
                                                    </label>
                                                </div>
                                                <div className="float-end">
                                                    {
                                                        subscription?.state === "expired" && subscription?.renew_invoice === undefined &&  constants.recurring_gateways.includes(subscription?.payment_gateway) && !subscription?.trial_ends_at?.length>0 && !subscription?.hasFamilyPlan && !subscription?.hasVolumePlan && !subscription.hasPureTeams && !isWhmcsUser(subscription?.account?.billingType) ? <PrimaryBtn  onClick={e=>openModal('renewal-lg-v2', subscription?.id)}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : subscription?.state === "expired" && subscription?.renew_invoice === undefined && subscription?.payment_gateway !== "cardless" ? null
                                                        : subscription?.state === "expired" && subscription?.renew_invoice === undefined && subscription?.payment_gateway === "cardless" ? <PrimaryBtn onClick={() => cardlessRenew(subscription?.id)}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : subscription?.state === "canceled" && !isWhmcsUser(subscription?.account?.billingType) ? <PrimaryBtn onClick={e=>resumeSelectedSubscription(subscription?.id, "reactivate")}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : (subscription?.state === "expired" && subscription?.renew_invoice)  ? <PrimaryBtn onClick={e=>openModal('renewal-lg', subscription?.id, subscription?.renew_invoice)} >{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : (subscription?.state === "past_due" && subscription?.renew_invoice && !isWhmcsUser(subscription?.account?.billingType) && !isOfflineGateway(subscription?.payment_gateway)) && !subscription.is_child ? <PrimaryBtn  onClick={e=>openModal('renewal-lg', subscription?.id, subscription?.renew_invoice)}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        // : (subscription?.state === "paused" || subscription?.is_paused)  ? <PrimaryBtn onClick={e=>openModal('resume-sm', subscription?.id)}>{subscription?.state === "paused" ? "Renew subscription" : "Resume subscription"}</PrimaryBtn>
                                                        // : (subscription?.state === "past_due" && subscription?.renew_invoice)  ? <PrimaryBtn onClick={e=>openModal('renewal-lg', subscription?.id, subscription?.renew_invoice)}>Renew subscription</PrimaryBtn>
                                                        : (subscription?.state === "paused" || subscription?.is_paused) && !isWhmcsUser(subscription?.account?.billingType) ? <PrimaryBtn pointer={ctaLoading} onClick={e=> resumeSelectedSubscription(subscription?.id, "resume")}>{subscription?.state === "paused" ? buttonText(t('subscription_renew_sub_cta')) : buttonText(t('resume_subscription'))}</PrimaryBtn>
                                                        : ( ( signupFrom.includes(subscription?.account?.signup_from)  ) && isWhmcsUser(subscription?.account?.billingType) && (subscription?.state === "past_due" || subscription?.is_about_to_churn) )  ? <PrimaryBtn  onClick={e=>initOpenModel(subscription, "","renew")}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : ((subscription?.state === "past_due" || subscription?.is_about_to_churn || subscription?.state === "expired") && !subscription.is_trial && isWhmcsUser(subscription?.account?.billingType) )  ? <PrimaryBtn  onClick={e=>openModal('renewal-lg', subscription?.id, 1)}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                                                        : null
                                                    }
                                                </div>
                                                <div className="float-none"></div>
                                            </InfoCard>
                                            {
                                                (subscription?.state !== "expired" || subscription?.payment_gateway === "cardless")
                                                &&
                                                <>
                                                    {
                                                        !subscription?.is_child &&
                                                        <InfoCard  noflex isExpired={checkState(subscription)}>
                                                            <div className="float-start">
                                                                {
                                                                    <label className="me-2 d-flex paymentMethod">{t('subscription_payment_method')}       
                                                                        <strong className='mx-2'><PaymentIcon lastFour={subscription?.billing_info?.last_four} gateway={subscription?.payment_gateway}/></strong>
                                                                    </label>
                                                                }
                                                            </div>
                                                            {
                                                                signupFrom.includes(subscription?.account?.signup_from)  ? <></> :
                                                                ( isWhmcsUser(subscription?.account?.billingType) || !subscription?.payment_gateway_offline ) 
                                                                && !constants.allow_mobile_gateway.includes(subscription?.payment_gateway) 
                                                                ?
                                                                <div className="float-end">
                                                                    <ul className="m-0 list-unstyled text-end">
                                                                        <li><LinkBtn onClick={e=>openModal('payment-sm', subscription)}>{t('subscription_change_payment_method_cta')}</LinkBtn></li>
                                                                        {
                                                                            !subscription?.payment_gateway_offline && <li className="mt-3 mb-4"><RLink><Link to={`payment-history/${subscription?.id}`}>{t('subscription_see_payment_history_cta')}</Link></RLink></li>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                : <></>
                                                            }
                                                            <div className="float-none"></div>
                                                        </InfoCard>
                                                    }
                                                    {

                                                        <SubscriptionAddonGroup hasMarginTop hasMarginBottom>
                                                            <div className='group-title'>
                                                                <div className='title'>
                                                                    <span>{t('subscription_other_product_heading')}</span>
                                                                </div>
                                                            </div>

                                                            <div className='addon-list'>
                                                                {
                                                                    <InfoCard hasborder isExpired={checkState(subscription)} className={`addon-list-item ${!(onBoardingReducer.onBoardings.find(o => [constants?.purevpn, constants?.purevpn].includes(o.slug))) ? "d-none" : ""}`}>
                                                                        <div className="float-start">
                                                                            <label className="me-2 d-block">
                                                                                <strong>PureVPN</strong> {(!purchasedApps.purevpn) && <NewTag>{t('subscription_addone_new_tag')}</NewTag>}
                                                                                {
                                                                                    (!purchasedApps.purevpn) && <div className='locked-image'><img src={locked} height={20} width={66} /></div>
                                                                                }
                                                                                {
                                                                                    (purchasedApps.purevpn) && <><img src={check} height="18" width="18"/> {t('subscription_addon_purchased')}</>
                                                                                }
                                                                            </label>
                                                                            {checkIsPending(subscription, "purevpn")}
                                                                        </div>
                                                                        <div className="float-end">
                                                                            {
                                                                            purchasedApps.purevpn ? (
                                                                                <InsetBtn onClick={() => openDeepLinkCta('purevpn')}>
                                                                                {t('download_text')} PureVPN
                                                                                </InsetBtn>
                                                                            ) : !purchasedApps.purevpn && !subscription?.is_trial ? (
                                                                                <InsetBtn onClick={e => onClickGetAppAddOn(constants?.purevpn, e, subscription)}>
                                                                                {t('get_text')} PureVPN
                                                                                </InsetBtn>
                                                                            ) : !subscription?.plan?.add_ons.some(addon => [constants?.purevpn].includes(addon.code)) ? (
                                                                                <p className="danger">{t('subscription_vpn_not_available')}</p>
                                                                            ) : null
                                                                            }
                                                                        </div>
                                                                        <div className="float-none"></div>
                                                                    </InfoCard>
                                                                }
                                                                {
                                                                    <InfoCard hasborder isExpired={checkState(subscription)} className={`addon-list-item ${!(onBoardingReducer?.onBoardings.find(o => o?.slug === constants?.purekeep)) ? "d-none" : ""}`}>
                                                                        <div className="float-start">
                                                                            <label className="me-2 d-block">
                                                                                <strong>PureKeep</strong> {(!purchasedApps.purekeep) && <NewTag>{t('subscription_addone_new_tag')}</NewTag>}
                                                                                {(purchasedApps.purekeep) ||  <div className='locked-image'><img src={locked} height={20} width={66} /></div>}
                                                                                {purchasedApps.purekeep && <><img src={check} height="18" width="18"/> {t('subscription_addon_purchased')}</>}
                                                                            </label>
                                                                            {checkIsPending(subscription, "purekeep")}
                                                                        </div>
                                                                        <div className="float-end">
                                                                            {
                                                                                checkIsFreeExp(subscription,constants?.purekeep) ?
                                                                                <InsetBtn renew={true}  onClick={()=>renewFreeAddon(constants.purekeep,subscription)}>{t('renew_now_text')}</InsetBtn>:
                                                                                (
                                                                                    (purchasedApps.purekeep)
                                                                                    &&
                                                                                    <InsetBtn onClick={() => openDeepLinkCta('purekeep')}>
                                                                                        {t('launch_text')} PureKeep
                                                                                    </InsetBtn>
                                                                                )

                                                                                ||

                                                                                (
                                                                                    (!subscription?.is_trial && !isResellerUser(subscription?.payment_gateway) && ( subscription?.plan?.add_ons.some(addon=>addon.code === constants.purekeep) || isWhmcsUser(subscription?.account?.billingType)))
                                                                                    ?
                                                                                    (checkIsFreeExp(subscription,constants?.purekeep) ?
                                                                                    <InsetBtn renew={true}  onClick={()=>renewFreeAddon(constants.purekeep,subscription)}>{t('renew_now_text')}</InsetBtn>:
                                                                                    <InsetBtn onClick={e => onClickGetAppAddOn(constants?.purekeep, e, subscription)}>{t('get_text')} PureKeep</InsetBtn>)
                                                                                    : 
                                                                                    !checkIsFreeAddon(subscription,constants?.purekeep) &&
                                                                                    (subscription?.is_trial ?
                                                                                        <p className="danger">{t('subscription_keep_not_available_during_trial')}</p>
                                                                                    : (isResellerUser(subscription?.payment_gateway)?
                                                                                    <p className="danger">{t('subscription_keep_not_available')}</p> 
                                                                                    : (!subscription?.plan?.add_ons.some(addon=>addon.code === constants?.purekeep) ?
                                                                                        <p className="danger">{t('subscription_keep_not_available_during_trial')}</p>
                                                                                    :
                                                                                    <></>)))
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="float-none"></div>
                                                                    </InfoCard>
                                                                }
                                                                {/* PURE ENCRYPT */}
                                                                {
                                                                    <InfoCard hasborder isExpired={checkState(subscription)} className={`addon-list-item ${!(onBoardingReducer.onBoardings.find(o => o.slug === constants?.purecrypt)) ? "d-none" : ""}`}>
                                                                        <div className="float-start">
                                                                            <label className="me-2 d-block">
                                                                                <strong>PureEncrypt</strong> {(!purchasedApps.purecrypt) && <NewTag>{t('subscription_addone_new_tag')}</NewTag>}
                                                                                {!purchasedApps.purecrypt && <div className='locked-image'><img src={locked} height={20} width={66} /></div>}
                                                                                {purchasedApps.purecrypt && <><img src={check} height="18" width="18"/> {t('subscription_addon_purchased')}</>}
                                                                            </label>
                                                                            {checkIsPending(subscription, "purecrypt")}
                                                                        </div>
                                                                        <div className="float-end">
                                                                            {   
                                                                                checkIsFreeExp(subscription,constants?.purecrypt)  ?
                                                                                <InsetBtn renew={true}  onClick={()=>renewFreeAddon(constants.purecrypt,subscription)}>{t('renew_now_text')}</InsetBtn>:
                                                                                (
                                                                                    (purchasedApps.purecrypt && (isMacOs || isWindows))
                                                                                    &&

                                                                                    <InsetBtn onClick={() => openDeepLinkCta('pureencrypt')}>
                                                                                        {t('download_text')} PureEncrypt
                                                                                    </InsetBtn>
                                                                                )

                                                                                ||
                                                                                (
                                                                                    (!purchasedApps.purecrypt && !isResellerUser(subscription?.payment_gateway) && !subscription?.is_trial && ( subscription?.plan?.add_ons.some(addon=>addon.code === constants?.purecrypt) || isWhmcsUser(subscription?.account?.billingType) ))
                                                                                    ?
                                                                                    checkIsFreeExp(subscription,constants?.purecrypt)?
                                                                                    <InsetBtn renew={true} onClick={()=>renewFreeAddon(constants.purecrypt,subscription)}>{t('subscription_renew_now_cta')}</InsetBtn>:
                                                                                    <InsetBtn onClick={e => onClickGetAppAddOn(constants?.purecrypt, e, subscription)}>{t('get_text')} PureEncrypt</InsetBtn>
                                                                                    
                                                                                    : !checkIsFreeAddon(subscription,constants?.purecrypt) &&
                                                                                        (subscription?.is_trial ?
                                                                                        <p className="danger">{t('subscription_encrypt_not_available_during_trial')}</p>
                                                                                        :(!subscription?.plan?.add_ons.some(addon=>addon.code === constants?.purecrypt)?
                                                                                        <p className="danger">{t('subscription_encrypt_not_available')}</p>
                                                                                        :   
                                                                                        (isResellerUser(subscription?.payment_gateway)?
                                                                                        <p className="danger">{t('subscription_encrypt_not_available')}</p>
                                                                                        :
                                                                                        <></>
                                                                                        ))
                                                                                    )
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="float-none"></div>
                                                                    </InfoCard>
                                                                }

                                                                {
                                                                    <InfoCard subsQr hasborder isExpired={checkState(subscription)} className={`addon-list-item ${!(onBoardingReducer.onBoardings.find(o => [constants?.pureprivacy, constants?.purescan].includes(o.slug))) ? "d-none" : ""}`}>
                                                                        <div className="float-start">
                                                                            <label className="me-2 d-block">
                                                                                <strong>PurePrivacy</strong> {(!purchasedApps.pureprivacy) && <NewTag>{t('subscription_addone_new_tag')}</NewTag>}
                                                                                {
                                                                                    (!purchasedApps.pureprivacy) && <div className='locked-image'><img src={locked} height={20} width={66} /></div>
                                                                                }
                                                                                {
                                                                                    (purchasedApps.pureprivacy) && <><img src={check} height="18" width="18"/> {t('subscription_addon_purchased')}</>
                                                                                }
                                                                            </label>
                                                                            {checkIsPending(subscription, "purescan")}
                                                                        </div>
                                                                        <div className="float-end">
                                                                            {   
                                                                                checkIsFreeExp(subscription,constants?.pureprivacy)  ?
                                                                                <InsetBtn renew={true}  onClick={()=>renewFreeAddon(constants.pureprivacy,subscription)}>{t('subscription_renew_now_cta')}</InsetBtn>:
                                                                                (
                                                                                    (purchasedApps.pureprivacy)
                                                                                    &&

                                                                                    <InsetBtn
                                                                                        className={`tooltipp ${showQR && 'myclass'} d-none d-lg-block`}
                                                                                        onClick={() => openDeepLinkCta('pureprivacy')}
                                                                                    >
                                                                                        {t('download_text')} PurePrivacy
                                                                                        {showQR && (
                                                                                        <span className="tooltipptext">
                                                                                            <p>Scan QR Code</p>
                                                                                            <div className="my-3">
                                                                                            <QRcodeGenerator value={process.env.REACT_APP_PUREPRIVACY_DEEPPLINK} />
                                                                                            </div>
                                                                                        </span>
                                                                                        )}
                                                                                    </InsetBtn>
                                                                                )

                                                                                ||
                                                                                (
                                                                                    (!purchasedApps.pureprivacy  && !isResellerUser(subscription?.payment_gateway) && !subscription?.is_trial && ( subscription?.plan?.add_ons.some(addon=>addon.code === constants?.purecrypt) || isWhmcsUser(subscription?.account?.billingType) ))
                                                                                    ?
                                                                                    checkIsFreeExp(subscription,constants?.pureprivacy)?
                                                                                    <InsetBtn renew={true} onClick={()=>renewFreeAddon(constants.pureprivacy,subscription)}>{t('renew_now_text')}</InsetBtn>:
                                                                                    <InsetBtn onClick={e => onClickGetAppAddOn(constants?.pureprivacy, e, subscription)}>{t('get_text')} PurePrivacy</InsetBtn>
                                                                                    
                                                                                    : !checkIsFreeAddon(subscription,constants?.pureprivacy) &&
                                                                                        (subscription?.is_trial ?
                                                                                        <p className="danger">{t('subscription_privacy_not_available_during_trial')}</p>
                                                                                    : !subscription?.plan?.add_ons.some(addon=>addon.code === constants?.pureprivacy)?
                                                                                        <p className="danger">{t('subscription_privacy_not_available')}</p>
                                                                                    : isResellerUser(subscription?.payment_gateway)?
                                                                                        <p className="danger">{t('subscription_privacy_not_available')}</p>
                                                                                    :
                                                                                    <></>)
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="float-none"></div>
                                                                    </InfoCard>
                                                                }
                                                            </div>
                                                        </SubscriptionAddonGroup>
                                                        
                                                    }
                                                    {
                                                        /**
                                                         * Show only when user has purevpn purchased also when that plan has purevpn add-on.
                                                         * or user has purevon and and purevpn sub addons
                                                         */
                                                        purchasedApps.purevpn &&  (subscription?.plan?.add_ons.some(addon => (constants?.purevpnAddonsNames.includes(addon.code))) || isWhmcsUser(subscription?.account?.billingType))
                                                        ? (
                                                            <SubscriptionAddonGroup>
                                                                <div className='group-title'>
                                                                    <div className='title'>
                                                                        <span>{t('subscription_vpn_add_ons')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='addon-list'>
                                                                {/* Purvpn component */}
                                                                {addonData?.map((addon_info)=>{
                                                                    return  showAddonView({type:addon_info?.code,subscription:subscription}) ? <AddonView name={addon_info.name} code={addon_info.code} subscription={subscription} initOpenModel={initOpenModel} addonField={addonField} /> 
                                                                    : null})}
                                                                </div>
                                                            </SubscriptionAddonGroup>
                                                        ) : (<></>)
                                                    }

                                                    {
                                                    /* User VPN Accounts  */
                                                    subscription?.vpnusernames &&
                                                        <>
                                                            <div className={`vpnAccountsHead ${checkState(subscription) ? 'd-none' : ''}`}>
                                                            <p className="pt-4">{t('subscription_creds_para')}</p>
                                                            </div>
                                                            {
                                                                subscription?.vpnusernames.map((username, key)=>(
                                                                    <InfoCard hasborder noflex key={key} isExpired={checkState(subscription)}>
                                                                        <div className="row mb-4">
                                                                            <div className="col-md-12">
                                                                                <div className="float-start">
                                                                                    <label className="me-2 user-creds"><span className="username">{t('subscription_username')}</span> <strong><CredentialsInput type="text" readOnly value={username}/></strong>
                                                                                        <button className={`btn btn-link p-0 btn-tooltip ${username === copiedusername && "copied"}`} id={username} onClick={copyUsername}>
                                                                                            <img src={copy}/>
                                                                                            <span className="tooltiptext" id="myTooltip">{t('copied_text')}</span>
                                                                                        </button>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="float-none"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="float-start">
                                                                                    <label className="me-2 user-creds"><span className="username">{t('subscription_passsword')}</span> <strong><CredentialsInput type="text" readOnly value={(copiedpassusername.username === username && copiedpassusername.password !== "") ? copiedpassusername.password : "***************"}/></strong>
                                                                                        <button className="btn btn-link btn-tooltip p-0" id={username}  onClick={() => {showPassword(username,"show")}}>
                                                                                            {
                                                                                                passwordLoaders.show && (passwordLoaders.username === username) ?
                                                                                                <span className="spinner-border spinner-border-sm"></span>
                                                                                                :
                                                                                                <img src={copiedpassusername.username === username ? hide : show} />
                                                                                            }
                                                                                        </button>
                                                                                        <button className={`btn btn-link p-0 btn-tooltip ${showCopied && "copied"}`} id={username} onClick={() => {showPassword(username,"copy")}}>
                                                                                            {
                                                                                                (passwordLoaders.copy && passwordLoaders.username === username) ?
                                                                                                <span className="spinner-border spinner-border-sm"></span>
                                                                                                :
                                                                                                <>
                                                                                                    <img src={copy} />
                                                                                                    <span className="tooltiptext" id="myTooltip">{t('copied_text')}</span>
                                                                                                </>
                                                                                            }
                                                                                        </button>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="float-end">
                                                                                    <LinkBtn onClick={e=>openModal('password-sm', username)}>{t('subscription_generate_new_password_cta')}</LinkBtn>
                                                                                </div>
                                                                                <div className="float-none"></div>
                                                                            </div>
                                                                        </div>
                                                                    </InfoCard>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        (isWhmcsUser(subscription?.account?.billingType) || subscription?.state === "canceled" || subscription?.state === "paused" || subscription?.auto_renew === false || subscription?.is_paused === true || subscription?.state === "past_due" || subscription?.is_child)
                                                        ?
                                                        null
                                                        :
                                                        <InfoCard className="mt-2" isExpired={checkState(subscription)}>
                                                            <div className="float-start">
                                                            {
                                                                !subscription?.payment_gateway_offline
                                                                ?
                                                                <>
                                                                    {
                                                                        !subscription?.is_trial && <DestructBtn className="me-2" onClick={(e) => handleClickCancelSubsButton({id: subscription?.id, service_origin: subscription?.service_origin, end_date: subscription?.end_date, state: subscription?.state})} >Cancel Subscription</DestructBtn>
                                                                    }
                                                                </>
                                                                :
                                                                <DestructBtn className="me-2" onClick={(e) => openModal('notsupported-sm', subscription?.payment_gateway, 'cancel')} >{t('subscription_cancle_subscription_cta')}</DestructBtn>
                                                            }
                                                            {
                                                                !isUser30DaysOld 
                                                                ?
                                                                <DestructBtn className="me-2" onClick={refundModalCTAHandle} >{t('subscription_file_a_refund')}</DestructBtn>
                                                                :
                                                                null
                                                            }
                                                            
                                                            </div>
                                                        </InfoCard>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </SubscriptionBox>
                        )) : (
                            <SubscriptionBox open={true}>
                                <InfoCard hasborder noflex className="mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard noflex className="pt-0">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                            </SubscriptionBox>
                        )
                    }
                    
                    </div>
                    <Aside type={asideType} setDomeModal={setDomeModal}/>
                    </SubscriptionWrapper>
                </div>
            }
            
            {/* <------------------- MODALS ------------------> */}
            <>
            {
                openTeamModalMetric && <TeamsInfoModal redirected_via={redirected_via} appEventHandler={appEventHandler} sendRequestToOpenFpCart={sendRequestToOpenFpCart} currentSub={getCurrentSubscription(subscriptions, id ?? selectedSubscription?.id)} backdrop={false} selectedSubscription={selectedSubscription} setselectedSubscription={setselectedSubscription} modalType={modalType} modal={openTeamModalMetric} toggle={toggleTeamMetricModal}/>
            }        
            {
                (modalType === "renewal-lg" && modal) && <RenewalModal backdrop={false} selectedSubscription={selectedSubscription} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary}/>
            }  
            {
                (modalType === "password-sm" && modal) && <GeneratePassword backdrop={false} selectedUsername={selectedUsername} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary}/>
            } 
            {
                (modalType === "billing-lg" && modal)  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={selectedSubscription} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary}  redirectedFrom={plan ? 'familyPlan' : ''} showContentModalForApp={backToMetricTable} apiEndPoint={apiEndPoint}/>
            }
            {
                (modalType === "payment-sm" && modal) && <PaymentMethod backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} selectedSubscription={selectedSubscription}/>
            }
            {
                (modalType === "addon-lg" && modal) && <AddonPurchase backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} showInfo={openOrderSummary ? false :isFree} hasCountry={hasCountry} openOrderSummary={openOrderSummary} setOpenOrderSummary={setOpenOrderSummary} viaProxy={hasViaProxy}/>
            }
            {
                (modalType === "cancel-sm" && modal) && <CancelSubscription backdrop={false} selectedSubscription={selectedSubscription} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary}/>
            }
            {
                (modalType === "pause-sm" && modal) && <PauseSubscription backdrop={false} selectedSubscription={selectedSubscription} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary}/>
            }
            {
                (modalType === "notsupported-sm" && modal) && <NotSupported backdrop={false} changePaymentMethod={changePaymentMethod} notsupportedState={notsupportedState} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle}/>
            }
            {
                (modalType === "resume-sm" && modal) && <ResumeSubscription backdrop={false} selectedSubscription={selectedSubscription} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle}/>
            }
            {
                (modalType === "reactivate-sm" && modal) && <ReactivateSubscription backdrop={false} selectedSubscription={selectedSubscription} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle}/>
            }
            {
                (modalType === "refund-lg" && modal) && <RefundModal backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setnewAdded={setnewAdded} authorId={authorId}/>
            }
            {
                (modalType === "transaction-sm" && modal) && <TransactionModal transactionStatus={transactionStatus} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle}/>
            }
            {
                (modalType === "updatecountry-sm" && modal) && <UpdateCountry backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle}/>
            }
            {
                (modalType === 'cancellation-sm' &&  modal) && <Cancellation toggle={toggle} modal={modal} selectedSubscription={selectedSubscription} resumeSelectedSubscription={resumeSelectedSubscription}/>
            }
            {
                domeModal && <DomeForm modal={domeModal} setDomeModal={setDomeModal}></DomeForm>
            }
            {
                <DomeForm modal={multiLogindomeModal} setDomeModal={setmultiLogindomeModal} formId={"e903b7a9-d8ee-4600-abf2-7b4dcb663268"}></DomeForm>
            }
            {
                (modalType === "dedip-proxy" && modal) && <ProxyDedicatedIp backdrop={false} notsupportedState={notsupportedState} modal={modal} toggle={toggle} removeAllParams={removeAllParams} isProxyCampaign={isProxyCampaign}/>
            }
            </>
        
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, {getSubscriptionsRequest, getReferRequested, getTicketsRequested})(Subscriptions)