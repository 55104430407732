import en from "../lang/en.json";
import de from "../lang/de.json";
import es from "../lang/es.json";
import fr from "../lang/fr.json";
import nl from "../lang/nl.json";
import it from "../lang/it.json";
import ko from "../lang/ko.json";
import pt from "../lang/pt.json";
import ru from "../lang/ru.json";
import tr from "../lang/tr.json";
import zh from "../lang/zh.json";
import ja from "../lang/ja.json";


export const devicesMultiLang = {
    "fr":fr['devices'],
    "de":de['devices'],
    "es":es['devices'],
    "en":en['devices'],
    "nl":nl['devices'],
    "it":it['devices'],
    "ko":ko['devices'],
    "pt":pt['devices'],
    "ru":ru['devices'],
    "tr":tr['devices'],
    "zh":zh['devices'],
    "ja":ja['devices'],

}