import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { getByParam, getTokenFromLocalStorage } from "../../../../tools/tools";

const useTeamsCounter = (selectedSubscriptionData) => {
  const { t } = useTranslation();
  const [teamsCounter, setTeamsCounter] = useState(null);
  const [isCounterLoading, setIsCounterLoading] = useState(false);
  const [counterErrorMsg, setCounterErrorMsg] = useState(null);
  const [fetchTeamsCounterAgain, setFetchTeamsCounterAgain] = useState(false);

  useEffect(() => {
    async function fetchTeamsCounter() {
      try {
        setIsCounterLoading(true);
        const apiResponse = await getByParam(`pure-teams/counter${selectedSubscriptionData?.is_child ? '?is_child=1' : ''}`, {
          sub_id: selectedSubscriptionData?.id
        }, getTokenFromLocalStorage());
        const { data: teamsCounter } = apiResponse;
        setCounterErrorMsg(false);
        if (teamsCounter?.status) {
          setTeamsCounter(teamsCounter?.body);
        } else {
          setCounterErrorMsg(teamsCounter?.message);
        }
      } catch (error) {
        setCounterErrorMsg(t('refer_errorbox_heading'));
      }
      setIsCounterLoading(false);
    }

    fetchTeamsCounter();
  }, [fetchTeamsCounterAgain, selectedSubscriptionData?.id]);
  
  return { teamsCounter, isCounterLoading, counterErrorMsg, setFetchTeamsCounterAgain };
};

export default useTeamsCounter;
