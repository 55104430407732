import { ERRORS, GETREMOVEMYDATA, GETREMOVEMYDATAREQUEST, LOADING } from "./types"

export const getRemoveMyDataRequested = (payload) => {

    return{
        type:GETREMOVEMYDATAREQUEST,
        payload
    }
}

export const getRemoveMyDataType = (payload) => {
    return{
        type:GETREMOVEMYDATA,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
