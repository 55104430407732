import React, { useState } from 'react'
import { CouponBpx,EarnView, RedeemChip, RedeemView, ReferralButton } from '../../../../styles/dashboard/pure-rewards/PureWallet'
import { api, canShowRule, getTokenFromLocalStorage, parsedJson, poster, renewalChecker, selectSub } from '../../../../tools/tools'
import ErrorBoundary from '../../../ErrorBoundary'
import useRewardsInfo from '../custom-hooks/useRewardsInfo'
import Currency from '../partial/Currency'
import EarnRedeemLoader from '../partial/EarnRedeemLoader'
import EarnLoader from '../partial/EarnRedeemLoader'
import ProgressBar from '../partial/ProgressBar'
import TooltipView from '../partial/Tooltip'
import { getIsCategoryRenewed } from '../tools/tools'
import AvailableComponent from './AvailableComponent'
import qs from 'qs';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { APIFAILED } from '../../../../tools/responseMessages'
import { connect } from 'react-redux'
import { addonName, addonNames, addonConstants } from '../../../../tools/constants'
import { isMax, isPlus } from '../../upgrade/upgrade-tools'
import { checkAddOn } from '../../subscriptions/tools/SubscriptionTools'
import { sendEvents } from '../../../../tools/mpEvents'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

const Redeem = ({pointsInfo={},setTglCustHist,isDataLoaded,onBoardingReducer}) => {
    const token = localStorage.getItem("token");

    const { errors : onboaridngError , selectedSubs, subscriptions,  loading:onboardingLoading } = onBoardingReducer
    const [status, setStatus] = useState('available');

    const [tglState,setTglState] = useState(false)

    const [redeemCtaLoader,setRedeemCtaLoader] = useState(false)

    const [selectId,setSelectId] = useState('')

    const [selectedSubscription,setSelectedSubscription] = useState(subscriptions?.length && subscriptions[0]) 

    const { rewardsInfo, isRewardLoaded, rewardErrorMsg,setRewardLoaded } = useRewardsInfo(true,tglState);
    const handleChipClick = (chipStatus) => {
      setStatus(chipStatus);
    };
    const earnedCredits = isRewardLoaded ? 0 : pointsInfo?.points_earned;

    const calcPercentage = (categoryPoints,earnedPoints=earnedCredits) => {
        const percentage = earnedPoints/categoryPoints;
        return categoryPoints<earnedPoints || (earnedPoints === 0 && categoryPoints === 0) ? 100 : +(percentage*100).toFixed(2);
    }
    const renderProgressBar = (categoryPoints,isRedeemed=false) => {
        const totalPercentage = calcPercentage(categoryPoints,earnedCredits)
        return  <ProgressBar earnedPoints={earnedCredits} categoryPoints={categoryPoints} percentage={totalPercentage} /> 
    }

    const renderNoDataText = (text="used",isNoVoucher=true)=> {
        return <div className='no-voucher-view'>{!isNoVoucher ? `You currently don't have any vouchers` : `You currently don't have any vouchers in your ${text} section`}</div>
    }

    const toDisable = (amt) => {
        return  pointsInfo?.points_earned < amt
    }

    const redeemCoupon = async (data,cta=false) => {
        sendEvents({
            event_name:"ma_loyalty_click_spend",
            event_properties:{}
        }, token)
        const {id} = data
        let rewardInfo = parsedJson(data?.coupon?.product?.productDisplayNames[0]?.name)
        let allowToPass = true
        if(rewardInfo?.code === "renew"){
            if(renewalChecker(selectedSubscription)){
                toast.error("Your subscription is currently active")
                allowToPass = false
            }
        }
        // as it can be upgrade_1 or upgrade_12 or other so its prefered to do an include check instead of 5' && checks
        if(rewardInfo?.code?.includes('upgrade')){
            let getBillingCycle = rewardInfo?.code?.split('_')[1] 
            if(getBillingCycle && getBillingCycle === selectedSubscription?.plan?.interval_length){
                toast.error("Your cant upgrade to same billing cycle")
                allowToPass = false
            }
        }
        if(rewardInfo?.code === "vpn_max"){
            if(isMax(selectedSubscription?.plan_type)){
                toast.error('Your subscription type is already PureMax')
                allowToPass = false
            }
        }
        if(rewardInfo?.code === "vpn_plus"){
            if(isMax(selectedSubscription?.plan_type)){
                toast.error('You cannot downgrade from PureMax to PurePlus')
                allowToPass = false
            }else if(isPlus(selectedSubscription?.plan_type)){
                toast.error('Your subscription is already on PurePlus')
                allowToPass = false
            }
        }
        if(rewardInfo?.code === "family_plan"){
            if(selectedSubscription?.is_familyplan){
                toast.error(`You have already purchased ${addonNames[rewardInfo?.code]}`)
                allowToPass = false
            }
        }

        if(rewardInfo?.code === addonConstants['port_forwarding_with_dedicated_ip'] && (checkAddOn(selectedSubscription, addonConstants['dedicated_ip']) || checkAddOn(selectedSubscription, addonConstants['port_forwarding']))){
            toast.error(`You have already purchased Port Forwarding / Dedicated IP`)
            allowToPass = false
        }

        if(addonNames[rewardInfo?.code] && checkAddOn(selectedSubscription, rewardInfo?.code)){
            toast.error(`You have already purchased ${addonNames[rewardInfo?.code]}`)
            allowToPass = false
        }

        if(allowToPass && cta){
            if(!toDisable(data?.amount)){
                try {
                    setSelectId(id);
                    setRedeemCtaLoader(true);
                    const formdata = qs.stringify({
                        reward_id: id
                    });
                    const redeemPostCallApi = await poster("loyalty/customer/reward", formdata, getTokenFromLocalStorage());
                    const redeemPostCall = redeemPostCallApi?.data
                    if (redeemPostCall?.status) {
                        setTglState(!tglState);
                        setTglCustHist(prev => !prev);
                        toast.success("Reward redeemed successfully")
                        sendEvents({
                            event_name:"ma_loyalty_spent",
                            event_properties:{}
                        }, token)
                    }else{
                        toast.error(redeemPostCall?.message ?? APIFAILED)
                        sendEvents({
                            event_name:"ma_loyalty_fail_spent",
                            event_properties:{}
                        }, token)
                    }
                    setRedeemCtaLoader(false);
                    setSelectId("");
                } catch (error) {
                    toast.error("An error occurred while redeeming the coupon");
                }
            }
        }
    };

    useEffect(()=>{
        setSelectedSubscription(selectSub(subscriptions,selectedSubs))
    },[selectedSubs])
    
    return (
    <RedeemView>
        <p>Transform your points into vouchers and redeem them for incredible rewards!</p>
        <Nav tabs className='navbar-view'>
            <NavItem>
                <NavLink className={status === 'available' && "active" } onClick={()=>handleChipClick('available')}>
                Available
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={status === 'redeemed' && "active"} onClick={()=>handleChipClick('redeemed')}>
                Redeemed
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={status === 'used' && "active"} onClick={()=>handleChipClick('used')}>
                    Used
                </NavLink>
            </NavItem>
        </Nav>
        {(!isRewardLoaded && !isDataLoaded && !onboardingLoading) ? 
            !rewardErrorMsg && !onboaridngError?
                rewardsInfo?.Available ?
                        <>
                            {status === 'available' && (rewardsInfo?.Available ? rewardsInfo?.Available?.map((item)=>{
                                return (item.ruleType === 'free_product_settings' && canShowRule(selectedSubscription, parsedJson(item?.coupon?.product?.productDisplayNames[0]?.name)?.code)) && <AvailableComponent renderProgressBar={renderProgressBar} isAvailable={true} data={item} redeemCoupon={redeemCoupon} redeemCtaLoader={redeemCtaLoader} selectId={selectId} toDisableState={toDisable(item?.amount)} selectedSubscription={selectedSubscription} earnedCredits={earnedCredits}/>
                            }):renderNoDataText("available"))}
                            {status === 'redeemed' && (rewardsInfo?.Redeemed ? rewardsInfo?.Redeemed?.map((item)=>{
                                return item?.status === "Redeemed" && <AvailableComponent  renderProgressBar={renderProgressBar} isRedeem={true} data={item} redeemCtaLoader={redeemCtaLoader} selectId={selectId} selectedSubscription={selectedSubscription}/>
                            }):renderNoDataText("redeemed"))}
                            {    status === 'used' && (rewardsInfo?.Used ? rewardsInfo?.Used?.map((item)=>{
                                return item?.status?.toLowerCase() === "used" && <AvailableComponent  renderProgressBar={renderProgressBar} isUsed={true} data={item} redeemCtaLoader={redeemCtaLoader} selectId={selectId} selectedSubscription={selectedSubscription}/>
                            }):renderNoDataText("used"))}
                        </>
                    : renderNoDataText("",false)
            : <ErrorBoundary heading={rewardErrorMsg ?? "Oops!!"} tglCartApi={setTglState} isCartError={true}/> 
        : <EarnRedeemLoader/>}
    </RedeemView>
    )
}

const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer
     };
};

export default connect(mapStateToProps)(Redeem)
