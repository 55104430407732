import { ERRORS, GETHELLOBAR, SETHELLOBAR, LOADING } from "./types"


export const getHelloBar = () => {
    return{
        type:GETHELLOBAR
    }
}

export const setHelloBar = (payload) => {
    return{
        type:SETHELLOBAR,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
