import styled, { css } from "styled-components";
import collapse from "../../assets/arrow-down-icon.svg";

export const ListingStyles = styled.div`

    box-shadow: 4px 4px 30px 4px rgba(101,54,204,0.12);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .rmd-header{
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
        border: 1px solid #ECE7FD;
        background: #FFF;
        padding: 24px;

        @media only screen and (max-width: 810px) {
            display: none;
        }    

        .column-wrapper{
            display: grid;
            grid-template-columns: 4fr 2fr 3fr 2fr 1fr;
            grid-gap: 20px;
            gap: 20px;
            align-items: center;

            .title{
                color: #001219;
                font-size: 14px;
                font-weight: 700;
                line-height: 17.5px;
                letter-spacing: 0.15px;
                margin: 0;

            }

            .show-info{
                display: flex;
                align-content: ;
                align-items: center;
                gap: 6px;
                img{
                    cursor: pointer;
                }
            }
        }
    }

    .rmd-content{

        .redirect_link{
            position: absolute;
            content: '';
            z-index: 1;
            cursor: pointer;
            top: 20px;
            left: 25px;

            @media only screen and (max-width: 810px) {
                top: 53px;
            }   
            
        }

        .pl-4{
            padding-left: 35px;
        }
        
        .column-wrapper{
            display: grid;
            grid-template-columns: 4fr 2fr 3fr 2fr 1fr;
            grid-gap: 20px;
            gap: 20px;
            align-items: center;
            padding: 24px 24px;
            position: relative;

            @media only screen and (max-width: 810px) {
                grid-template-columns: 6fr 6fr;
            }   
            @media only screen and (max-width: 420px) {
                grid-template-columns: 6fr;
            }   

            &::before{
                position: absolute;
                content: '';
                top: 30px;
                right: 25px;
                background: url(${collapse}) no-repeat;
                background-size: 15px 15px;
                width: 14px;
                height: 14px;
            }

            &.open{
                &::before{
                    transform: rotate(180deg)
                }
            }

            &.question{
                border-bottom: 1px solid #ECE7FD;
                border-left: 1px solid #ECE7FD;
                border-right: 1px solid #ECE7FD;
                cursor: pointer;

                .progress-meter{
                    position: relative;

                    h5{
                        padding-left: 60px;
                    }

                    .multigraph {
                        position: absolute;
                        width: 0px;
                        height: 0px;
                        content: '';
                        top: -7px;
                        left: 0px;
                        margin: 0px;
                        transform: scale(0.175);
                        &::before {
                            position: absolute;
                            content: '';
                            width: 300px;
                            height: 150px;
                            border: 40px solid rgba(0,0,0,0.1);
                            border-radius: 300px 300px 0 0;
                            border-bottom: none;
                          }
                          .graph {
                            width: 300px;
                            height: 150px;
                            position: absolute;
                            overflow:hidden;
        
                            &::before, &::after {
                                width: 300px;
                                height: 150px;
                                position: absolute;
                                top: 100%;
                                border: 40px solid #E34D2F;
                                content: '';
                                border-radius:0 0 300px 300px;
                                border-top: none;
                                animation: demo 3s;
                                transform-origin: center top;
                            }
                            &::after {
                                transform: rotate(150deg);
                            }
                        }
                    }
                }
            }

            .column-title{

                @media only screen and (max-width: 810px) {
                    margin-bottom: 20px;
                }
                @media only screen and (max-width: 420px) {
                    margin-bottom: 10px;
                }   
                h4{
                    display: none;
                    @media only screen and (max-width: 810px) {
                        display: block;
                        color: #001219;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17.5px;
                        letter-spacing: 0.15px;
                        margin-bottom: 15px;

                        img{
                            margin-left: 5px;
                            width: 15px;
                        }
                    }  
                }

                h5{
                    color: #001219;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.5px;
                    margin:0;
                    letter-spacing: 0.5px; 
                }

            }
        }

        .answer{
            padding: 0px 56px 16px;
            background: rgba(192, 175, 247, 0.10);
            border: 2px solid #E3E3E382;

            h6{
                color: #001219;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.25px;
                margin-bottom: 8px;
                padding-top: 15px;
            }

            p{
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.5px;
                letter-spacing: 0.5px;
            }
        }
    }

    .listing-header{
        position: relative;
        padding: 15px;
        background: #3B108E;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-top-right: 1px solid #DDD4E2;
        border-top-left: 1px solid #DDD4E2;

        @media only screen and (max-width: 380px){
            padding: 10px 10px;
        }

        .column-wrapper{
            display: grid;
            grid-template-columns: 6fr 1fr 1fr;
            grid-gap: 20px;
            gap: 20px;
            align-items: center;

            @media only screen and (max-width: 640px){
                grid-template-columns: 5fr 1.5fr 1fr;
            }

            @media only screen and (max-width: 430px){
                grid-template-columns: 4fr 2fr 1fr;
            }

            @media only screen and (max-width: 380px){
                grid-template-columns: 3fr 1fr 0.5fr;
            }

            .title{
                color: #FFF !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.24px; 
                margin: 0;

                @media only screen and (max-width: 820px){
                    font-size: 10px;
                }
            }
        }
    }
    .listing-content{
        position: relative;
        background: #FBFBFC;
        .column-wrapper{
            display: grid;
            grid-template-columns: 6fr 1fr 1fr;
            grid-gap: 20px;
            gap: 20px;
            align-items: center;
            border-bottom: 1px solid rgba(221, 212, 226, 0.50);
            padding: 15px;
            background: #F6F4F7;
            

            @media only screen and (max-width: 640px){
                grid-template-columns: 5fr 1.5fr 1fr;
            }

            @media only screen and (max-width: 430px){
                grid-template-columns: 4fr 2fr 1fr;
            }

            @media only screen and (max-width: 380px){
                padding: 10px 10px;
                grid-template-columns: 3fr 1fr 0.5fr;
            }

            &.admin{
                background: #fff;
            }

            .column-title{
                display: flex;
                align-items: center;

                .email{
                    @media only screen and (max-width: 820px){
                        max-width: 250px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    @media only screen and (max-width: 600px){
                        max-width: 200px;
                    }
                    @media only screen and (max-width: 490px){
                        max-width: 150px;
                    }
                    
                }
            }
            h5{
                color: #001219;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.5px;
                letter-spacing: 0.5px; 
                margin: 0;
                display: flex;
                align-items: center;

                @media only screen and (max-width: 430px){
                    font-size: 11px;
                    display: block;
                }
    
                span{
                    color: #001219 !important;
                    font-size: 10px !important;
                    font-weight: 400;
                    line-height: 12.5px;
                    letter-spacing: 0.2px;
                    text-transform: uppercase; 
                    border-radius: 4px;
                    background: rgba(130, 96, 239, 0.25);
                    padding: 4px 8px;
                    margin-left: 10px;

                    @media only screen and (max-width: 430px){
                       margin: 0;
                       padding: 2px 4px;
                       font-size: 8px !important;
                    }
                }
            }
            .delete-icon{
                display: flex;
                cursor: pointer;
                img{
                    margin-left: 15px;
                    
                }
            }
            
        }
    }
`

export const Graph = styled.div`

    ${props => props.score && css`
        &::after {
            border-color: ${props.score > 7 ? '#E34D2F' : props.score > 3 ? '#FF9B00' : '#FFE300'} !important;
            transform: rotate(${(180*props.score)/10}deg) !important;
        }

    `}


   
`