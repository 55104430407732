import { addonsOnPlans, constants, inEligibleUserOnPlanUpgrade, eventsConstantsMessaging, bundleType} from "../../../tools/constants";
import { APIFAILED } from '../../../tools/responseMessages';
import { checkAccountType, dispatchError, getTokenFromLocalStorage } from "../../../tools/tools";
import { isMax } from "../upgrade/upgrade-tools";
import { planTypeUpgradeFaqs } from "../../../utils/FAQs/faq_list";
import { PlanTypeUpgradeInfo } from "../../../utils/planTypeUpgrade/info";
import { PlanTypeUpgradeStyles, PlanTypeUpgradeInfoStyles } from "../../../styles/dashboard/PlanTypeUpgrade";
import { poster, isWhmcsUser, getPaymentMethods} from '../../../tools/tools'
import { toast } from 'react-toastify';
import { useDispatch, connect } from 'react-redux';
import { useRef } from "react";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {sendEvents} from '../../../tools/mpEvents'
import BillingCycle from "../subscriptions/modals/BillingCycle";
import Error from "./modals/error";
import ErrorBoundary from "../../ErrorBoundary";
import GenericFaqs from "../../../utils/FAQs/genericFaqs";
import Info from "./modals/info";
import PlanTypeInfoCard from "./planTypeInfoCard";
import PlanTypeUpgradeCard from "./planTypeUpgradeCard";
import purekeepLogo from "../../../assets/purekeepLogo.png";
import purencryptLogo from "../../../assets/purencryptLogo.png";
import pureprivacyLogo from "../../../assets/pureprivacyLogo.png";
import purevpnLogo from "../../../assets/purevpnLogo.png";
import qs from 'qs'
import Skeleton from 'react-loading-skeleton';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CircularLoader from "../../generic/CircularLoader";
import ModalContent from "../../../generic-components/ModalContent";
import { useTranslation } from 'react-i18next'


const PlanTypeUpgrade = ({onBoardingReducer, history}) => {

    const { search, pathname } = useLocation();
    const { plan_type, type, redirected_via, id, locale_code } = Object.fromEntries(new URLSearchParams(search));

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(true);

    //contains all plans including current with their billing cycle
    const [plans, setPlans] = useState();

    // current plan billing cycle 
    const [selectedBillingCycle, setSelectedBillingCycle] = useState();

    // selected subscription globally
    const [selectedSubscription, setSelectedSubscription] = useState();

    // state to store central plans
    const [centralPlans, setCentralPlans] = useState(JSON.parse(localStorage.getItem('central-plans')));

    //state to store selected plan info
    const [selectedPlanInfo, setSelectedPlanInfo] = useState();

    //selected Plan type
    const [selectedPlanType, setSelectedPlanType] = useState();

    const [crashed, setCrashed] = useState();

    const [orderSummary,setorderSummary] = useState(true);

    const [openInvoiceSummary, setOpenInvoiceSummary] = useState(redirected_via ? false : true);

    const [afterContentModal, setAfterContentModal] = useState();

    const [isLoaded, setIsLoaded] = useState();

    const [modal, setModal] = useState({
        open: false,
        state: ''
    });

    const [subEligibility, setSubEligibility] = useState({
        isEligible: '', 
        reason: ''
    });

    const [upgradePlanData, setUpgradePlanData] = useState();

    const [infoModal, setInfoModal] = useState({
        open: false,
        content: ''
    });

    const [modalWidth] = useState({
        lg: "737px",
        sm: "440px"
    });

    const [modalWidthForError] = useState({
        lg: "560px",
        sm: "400px"
    });

    const [modalWidthForBillling] = useState({
        lg: "675px",
        sm: "440px"
    });

    // in order to handle dynamic scrolling
    const vpnRef = useRef();
    const keepRef = useRef();
    const encryptRef = useRef();
    const privacyRef = useRef();

    const scrollToTop = useRef(false);

    const widthCalculator = "lg";

    const planTypeImages = {
        'purevpn': purevpnLogo,
        'purekeep': purekeepLogo,
        'purencrypt': purencryptLogo,
        'pureprivacy': pureprivacyLogo
    }

    const heading = t('securitysuite_all_in_one_heading');

    const token = getTokenFromLocalStorage();

    useEffect(()=>{
        if(redirected_via && window.Intercom){
            window.Intercom('shutdown')
        }
    }, [])

    useEffect(()=>{
        if(!scrollToTop.current){
            scrollToTop.current = true;
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
        }
    }, [])

    useEffect(()=>{
        const { subscriptions: subs, selectedSubs } = onBoardingReducer;

        if(onBoardingReducer?.subscriptions){
            let userSubscription = redirected_via ? id : (selectedSubs ?? subs?.[0]?.id)
            let subscrip = subs.find((sub => sub.id === userSubscription))
            getCheckoutData(subscrip);
        }

        sendEvents({
            event_name: "ma_pagevisited",
            event_properties: {
                visitedPageURL: window.location.href ?? "N/A",
            },
        }, token);
    },[onBoardingReducer?.subscriptions])

    useEffect(()=>{
        if(centralPlans){

            const { errors, subscriptions: subs, selectedSubs, errorType } = onBoardingReducer;

            if(onBoardingReducer?.subscriptions && centralPlans){
                let selectSub = redirected_via ? id : selectedSubs;
                setSelectedSubscription(subs.find((sub => sub.id === selectSub)) ?? onBoardingReducer?.subscriptions?.[0]);
                setInfoAccToSelectedSubscription(redirected_via ? id : (selectedSubs ?? selectedSubscription?.id));
                setLoading(false)
            }

            if(errors){
                dispatchError(errorType, dispatch);
            }
        }
        
        
    }, [onBoardingReducer, centralPlans])

    // only show content when as soon as user lands
    useEffect(()=>{
        if(redirected_via && type && !afterContentModal){
            showUpgradePlanContentModalForApp()
        }
    }, [])

    //once we have fetched all relevant data, show cart preview
    useEffect(()=>{
        if(redirected_via && type && afterContentModal && plans && !isLoaded){
            setIsLoaded(true)
            showPlanPreview(plans[selectedBillingCycle][plan_type], redirected_via)
        }
    }, [afterContentModal, plans])

    const onClickShowPlanPreview = () => {
        setAfterContentModal(true)
    }

    const showUpgradePlanContentModalForApp = () => {
        setAfterContentModal(false)
    }

    const getCheckoutData = async (sub) => {
        try{
            const formdata = qs.stringify({'upgrade': 'current'})
            const checkoutData = await poster(`upgrade/central-plans`, formdata, token);
            const {data: response} = checkoutData;
    
            if(response?.status){
                localStorage.setItem('central-plans', JSON.stringify(response?.body))
                setCentralPlans(response?.body)
            }
            else{
                dispatchError(response?.error_code, dispatch);
                sendEvents({
                    event_name:"ma_fail_load_plan",
                    event_properties: {
                        account_type: checkAccountType(sub),
                        current_plantype: sub?.plan_type ?? 'N/A',
                        billingcycle: sub?.type ?? 'N/A',
                        amount : '' ?? 'N/A',
                        payment_gateway: getPaymentMethods(sub) ?? 'N/A',
                        reason: response?.message ?? 'Oops, something went wrong',
                        current_status: sub?.state ?? 'N/A',
                    }
                }, token);
                throwErrorBoundary(response?.error_code)
            }
        }
        catch{
            toast.error(t(APIFAILED));
            sendEvents({
                event_name:"ma_fail_load_plan",
                event_properties: {
                    account_type: checkAccountType(sub),
                    current_plantype: sub?.plan_type ?? 'N/A',
                    billingcycle: sub?.type ?? 'N/A',
                    amount : '',
                    payment_gateway: getPaymentMethods(sub) ?? 'N/A',
                    reason: 'Oops, something went wrong',
                    current_status: sub?.state ?? 'N/A',
                }
            }, token);
            throwErrorBoundary(t(APIFAILED))
        }
        
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED || error === 'upgrade_exception'){
            setCrashed(true)
        }
    }

    const setInfoAccToSelectedSubscription = (subscription_id) => {
        //since we have different payload for subscription in onBoarding reducer and central plans
        const currentSubscription = centralPlans.find((sub) => sub.id === subscription_id) ?? centralPlans?.[0];

        // ? WE ARE NO LONGER READING BILLING CYCLE FOR APPLINKS FROM APPS END, INSTEAD
        // ? WE WILL BE READING CURRENT SUBSCRIPTION'S BILLING CYCLE

        // ? REMOVED DUE TO STACK USERS
        // const currentInterval = `${currentSubscription?.plan?.interval_length+`_`+ currentSubscription?.plan?.interval_unit}`;
        const currentInterval = currentSubscription?.plan_key_match;

        setSelectedBillingCycle(currentInterval)

        setSubEligibility({
            isEligible: currentSubscription?.eligibility,
            reason: currentSubscription?.reason
        });

        setPlanForDisplay(currentSubscription?.plans, currentInterval, currentSubscription?.plan, currentSubscription?.plan_type)
    }

    const setPlanForDisplay = (all_plans, current_billing_cycle, current_plan, current_plan_type) => {
        // adding current plan in our plans with 'current' key as true
        const currentPlanWithType = {[current_plan_type] : {...current_plan, current: true, plan_type: current_plan_type}}

        if(Object.keys(all_plans).length !== 0 && all_plans.hasOwnProperty(current_billing_cycle)){
            setPlans({[current_billing_cycle] : {...currentPlanWithType, ...all_plans[current_billing_cycle]}})
        }
        else{
            setPlans({[current_billing_cycle] : currentPlanWithType})
        }
    }

    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }

    const showPlanPreview = (planInfo) => {

        let todayDate = new Date().toJSON();
        setSelectedPlanInfo(planInfo);

        sendEvents({
            event_name:"ma_click_plan",
            event_properties: {
                account_type: checkAccountType(selectedSubscription),
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle: selectedSubscription?.type ?? 'N/A',
                amount : (planInfo?.currency?.price/planInfo?.interval_length).toFixed(2) ?? 'N/A',
                plan_type: planInfo?.plan_type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                current_status: selectedSubscription?.state ?? 'N/A',
            }
        }, token);

        if(!subEligibility?.isEligible){
            setModal((modal)=>({
                open: !modal.open,
                state: 'error'
            }));

            sendEvents({
                event_name:"ma_view_plan_popup",
                event_properties: {
                    account_type: checkAccountType(selectedSubscription),
                    current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                    billingcycle: selectedSubscription?.type ?? 'N/A',
                    amount : (planInfo?.currency?.price/planInfo?.interval_length).toFixed(2) ?? 'N/A',
                    plan_type: planInfo?.plan_type ?? 'N/A',
                    payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                    current_status: selectedSubscription?.state ?? 'N/A',
                    message: eventsConstantsMessaging[subEligibility.reason]?.message
                }
            }, token);
            
        }
        else{
            setUpgradePlanData({
                plan: planInfo?.code,
                subscription:selectedSubscription?.id,
                service_origin:selectedSubscription?.service_origin,
                isWhmcsUser:isWhmcsUser(selectedSubscription?.account?.billingType),
                currency:selectedSubscription?.currency,
                interval_length: planInfo?.interval_length,
                interval_unit: planInfo?.interval_unit,
                start_date: todayDate,
                expiry_date: planInfo?.end_date, 
                sub_plan_type: planInfo?.plan_type, 
                current_plan: selectedSubscription?.plan_type, 
                state: selectedSubscription?.state,
                billingcycle: selectedSubscription?.type,
                account_type: checkAccountType(selectedSubscription),
            });
    
            setModal((modal)=>({
                open: !modal.open,
                state: 'billing'
            }));
        }
        
    }

    const toggle = () => {
        setModal((modal)=>({
            open: !modal.open,
            state: ''
        }))
    }

    const onErrorClick = (cta) => {
        cta === 'Renew now' ? routeToRenew() : toggle();

        sendEvents({
            event_name:"ma_click_plan_popup",
            event_properties: {
                account_type: checkAccountType(selectedSubscription),
                action: eventsConstantsMessaging[subEligibility.reason]?.cta,
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle: selectedSubscription?.type ?? 'N/A',
                amount : (selectedPlanInfo?.currency?.price/selectedPlanInfo?.interval_length).toFixed(2) ?? 'N/A',
                plan_type: selectedPlanInfo?.plan_type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                current_status: selectedSubscription?.state ?? 'N/A',
                message: eventsConstantsMessaging[subEligibility.reason]?.message
            }
        }, token);

    }

    const routeToRenew = () => {
        history.push('/dashboard/subscriptions')
    }

    const getDynamicRef = (name) => {
        return name === constants['pureprivacy'] ? privacyRef : name === constants['purekeep'] ? keepRef :  name === constants['purencrypt'] ? encryptRef : name === constants['purevpn'] ? vpnRef : encryptRef ;
    }

    const handleScroll = (productName) => {
        let ref = getDynamicRef(productName);
        window.scrollTo({
            // since we have hello bar, we need to stop hiding the content under it
          top: ref.current.offsetTop - 40,
          left: 0,
          behavior: "smooth",
        });
    };

    const getProductType = () => {
        return Object.keys(plans?.[selectedBillingCycle])
    }
    const closeForMobile = () => {
        history.replace(pathname, '');
        setAfterContentModal();
    }

    return crashed ? 
        <ErrorBoundary /> : 
        <PlanTypeUpgradeStyles>

                    <>

                        {
                            redirected_via ? <div style={{display: 'flex', height: 'calc(100vh - 60px)', alignItems: "center", justifyContent: "center", overflow: "hidden"}}>
                                <CircularLoader />
                            </div> : 
                            <>
                                {/* as per product's call */}
                                {!loading ? 
                                    !isMax(selectedSubscription?.plan_type) && <>
                                        <h2>{heading}</h2>

                                        <div className="plan-type-cards">
                                            {plans && getProductType().map((plantype)=>{
                                                return <PlanTypeUpgradeCard
                                                    className={plantype.split('_')?.[1]}
                                                    getProductType={getProductType}
                                                    planInfo={plans[selectedBillingCycle][plantype]}
                                                    selectedPlanType={selectedPlanType}
                                                    selectedSubscription={selectedSubscription}
                                                    setSelectedPlanType={setSelectedPlanType}
                                                    showPlanPreview={showPlanPreview}
                                                    toggleInfoModal={toggleInfoModal}
                                                    type={plantype.split('_')?.[1]}
                                                />
                                            })}
                                        </div>
                                    </>: 
                                    <div className="mt-5">
                                        <Skeleton className="d-block mb-5 m-auto text-center w-50" height={30}/>
                                        <div className="m-auto loading justify-content-center mt-5 w-100 d-flex gap-5">
                                                <Skeleton className="d-block mb-3" width={260} height={600}/>
                                                <Skeleton className="d-block mb-3" width={260} height={600}/>
                                                <Skeleton className="d-block mb-3" width={260} height={600}/>
                                        </div>
                                    </div>
                                }

                                {/* INFO */}
                                {!loading && <PlanTypeUpgradeInfoStyles>

                                    <h1>{selectedPlanType === bundleType['plus'] ? t('securitysuite_threeX_your_privacy') : t('securitysuite_fourX_your_privacy')}</h1>
                                    <ul className="plan-info">
                                        {addonsOnPlans?.[selectedPlanType ?? 'all'].map((plan)=>{
                                            return <li className={plan} onClick={()=>handleScroll(plan)}>
                                                <img src={planTypeImages[plan]} alt={plan} />
                                            </li>
                                        })}
                                    </ul>
                                    {addonsOnPlans?.[selectedPlanType ?? 'all'].map((plan)=>{
                                        return <PlanTypeInfoCard planType={PlanTypeUpgradeInfo[plan]} getDynamicRef={getDynamicRef} hasMax={isMax(selectedSubscription?.plan_type)}/>
                                    })}

                                </PlanTypeUpgradeInfoStyles>}

                                <GenericFaqs faqs={planTypeUpgradeFaqs}/>
                            </>
                        }
                    
                    </>
                    
            {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={infoModal.open} toggle={toggleInfoModal} />}

            {afterContentModal == false && <ModalContent closeForMobile={closeForMobile} backdrop={false} modalWidth={modalWidthForBillling} widthCalculator={widthCalculator} modal={modal} toggle={toggle} locale_code={locale_code} type={type} onClickShowPlanPreview={onClickShowPlanPreview} redirected_via={redirected_via} />}

            {modal.open && modal.state === 'billing'  && <BillingCycle backdrop={false} modalWidth={modalWidthForBillling} selectedSubscription={upgradePlanData} widthCalculator={widthCalculator} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} redirectedFrom="plantypeUpgrade" showContentModalForApp={showUpgradePlanContentModalForApp}/>}
            
            {modal.open && modal.state === 'error'  && <Error backdrop={false} modalWidth={modalWidthForError} widthCalculator={widthCalculator} modal={modal.open} toggle={toggle} message={t(inEligibleUserOnPlanUpgrade[subEligibility.reason]?.message)} cta={t(inEligibleUserOnPlanUpgrade[subEligibility.reason]?.cta)} onErrorClick={onErrorClick}/>}
        </PlanTypeUpgradeStyles>
}

const mapStateToProps = (state) => {
    return {
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(withRouter(PlanTypeUpgrade));