import React from 'react'
import { Link } from 'react-router-dom'
import { AddonImg } from '../../../../styles/dashboard/Main'
import { DeviceIcon, NewProductsDevices, ThankYouDisplay, VPNDevices } from '../../../../styles/dashboard/ThankYouStyles'
import { addonInfo, addonName, constants } from '../../../../tools/constants';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useState } from 'react';
import { api, getTokenFromLocalStorage, isPurpose } from '../../../../tools/tools';
import ErrorBoundary from '../../../ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { sendEvents, sendGuestEvents } from '../../../../tools/mpEvents';

const ThankYouView = ({setShowThankyouPage=()=>{},downloadClickCallback=()=>{},extensionDevice,appName,URL="download",backPage="Download Page",selectStepCallback=()=>{},isOB=false,notToShowProxy,formData}) => {

    const token = getTokenFromLocalStorage()

    const { t, i18n } = useTranslation();

    const [recommendedData,setRecommendedData] = useState([])

    const [isLoading,setIsLoading] = useState(true)

    const [isError,setIsError] = useState(false)


    const fireEventForThankYouPage = (event_name="",event_prop={}) => {
        if (token) {
            sendEvents(
                {
                    event_name,
                    event_properties: {
                        ...event_prop,
                        isViaOnboarding:isOB
                    }
                }, token
            );
    
        } else {
            sendGuestEvents(
                {
                    event_name,
                    event_properties: {
                        ...event_prop,
                        isViaOnboarding:isOB
                    }
                },
            );
        }
    }

    useEffect(()=>{
        fireEventForThankYouPage("ma_visit_thank_you_page")
        async function getRecommendedDevices(formdata={}) {
            const url = 'devices/get-recommended-devices';      
            try {
                setIsLoading(true)
                const response = await api(url,formdata);
                const download = response.data;
                if(download?.status){
                    setRecommendedData(download.body)
                    setIsLoading(false)
                    setIsError(false)
                }else{
                    setIsError(true)
                    setIsLoading(false)
                    fireEventForThankYouPage("ma_thankyou_page_crash",{error:download?.message,code:download?.error_code})
                }
            } catch (error) {
                setIsError(true)
                fireEventForThankYouPage("ma_thankyou_page_crash",{error:error,code:"catch block"})
            }
        }
        getRecommendedDevices(formData)
    },[])


    const backCta = () => {
        setShowThankyouPage(false)
        selectStepCallback(prev=>prev+1)
        fireEventForThankYouPage("ma_click_go_back")
    }
    
    const downloadDevice = (device) => {
        fireEventForThankYouPage("ma_download_thankyou_page",{name:device?.name,abbreviation:device?.abbreviation})
        downloadClickCallback(device?.versions?.[0]?.download_url, device?.name, device?.versions?.[0]?.name , device?.abbreviation)
    }
    
    //* The function displays vpn devices
    const renderVpnDevice = (device,isExt=false) => {
        return  <VPNDevices onClick={()=>{downloadDevice(device,isExt)}} data-device-id={device?.id}>
                    <DeviceIcon className={` device-icon icon-${device.abbreviation}`}>
                        <p className='text-align-center'>{device.abbreviation === "iphone_ios"? "iPhone/iPad":device?.name ?? device?.heading}</p>
                    </DeviceIcon>
                </VPNDevices>
    }

    /* The function evaluates the suggested devices data and see if there is any extension device and
      if found it will evaluate it according to the user's browser */
    const displayVPNdevices = (data) => {
        const extensions =  new Set(['google_chrome', 'firefox','edge','brave']);
        return data?.reverse()?.map((device,index)=>(
            device?.app === constants?.purevpn ? 
            extensions.has(device?.slug) ? !isPurpose(notToShowProxy) && renderVpnDevice(extensionDevice,true) :
            renderVpnDevice(device)  : null
        ))
        
    }
    if(isError){
        return <ErrorBoundary></ErrorBoundary>
    }

    //* The function displays new products devices
    const displayNewProductsDevices = (data) => {
        return data?.map((device,index)=>(
            device?.app != constants?.purevpn ? 
            <NewProductsDevices onClick={()=>{downloadDevice(device)}} data-device-id={device?.id}>
                <AddonImg dashboardView thankYouView addonName={device?.app}/>
                <h6>{addonName[device?.app]} {t('for_text')} {device?.name}</h6>
                <p>{addonInfo[device?.app]}</p>
                <Link>{t('thankyou_download_on')} {device?.name}</Link>
            </NewProductsDevices> : null
        ))
    }
    return (
        <ThankYouDisplay isOB={isOB}>
            <span></span>
            <h1>{t('thankyou_thanks_for_downloading')} {appName}</h1>
            <p>{t('thankyou_download_vpn')}</p>
            <span>
                {isLoading ? <Skeleton height={140} width={400} />: (recommendedData?.length>0 ?  displayVPNdevices(recommendedData) : <>{t('thankyou_nodata')}</>)}
            </span>
            <h2>{t('thankyou_digital_security')}</h2>
            <span>
                {isLoading ? <Skeleton height={150} width={600} />: (recommendedData?.length>0 ?  displayNewProductsDevices(recommendedData) : <>{t('thankyou_nodata')}</>)}

            </span>
            <Link to={`/dashboard/${URL}`} onClick={backCta}>{t('thankyou_back_to')} {backPage}</Link>
        </ThankYouDisplay>
    )
}

export default ThankYouView