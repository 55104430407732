import ManualConnectionFlags from "../../assets/flagIcons.png";
import styled, { css } from "styled-components";

export const FlagIcon = styled.div`
	width: 36px;
	block-size: 36px;
	margin-right:10px;
    ${props=>(props.city || props.modalView) && css`margin-right:0px;`}
	span {
		background-image: url(${ManualConnectionFlags});
		transform	: scale(50%);
		transform-origin: 0 0;
		border-radius: 50%;


		${({modalView}) => modalView && css`
			transform: scale(40%);
			transform-origin: 0px 0px;

        `};

		${({city}) => city && css`
			transform: scale(30%);
			transform-origin: 10px 10px;
			@media only screen and (max-width: 415px) and (min-width: 270px) {
				transform-origin: 10px 1px;
  			}
        `};

	}
	@media only screen and (max-width: 400px) and (min-width: 290px) {
		margin-right:0px;
		span{
		transform: scale(40%);
		transform-origin: 0px 0px;
		}
    }
	.bg-ad {
		width: 72px;
		height: 72px;
		background-position:  -196px -103px;
		display: block;
	}

	.bg-ae {
		width: 72px;
		height: 72px;
		background-position:  -103px -196px;
		display: block;
	}

	.bg-af {
		width: 72px;
		height: 72px;
		background-position:  -195px -196px;
		display: block;
	}

	.bg-ag {
		width: 72px;
		height: 72px;
		background-position:  -288px -10px;
		display: block;
	}

	.bg-al {
		width: 72px;
		height: 72px;
		background-position:  -288px -102px;
		display: block;
	}

	.bg-am {
		width: 72px;
		height: 72px;
		background-position:  -288px -194px;
		display: block;
	}

	.bg-ao {
		width: 72px;
		height: 72px;
		background-position:  -10px -288px;
		display: block;
	}

	.bg-aq {
		width: 72px;
		height: 72px;
		background-position:  -102px -288px;
		display: block;
	}

	.bg-ar {
		width: 72px;
		height: 72px;
		background-position:  -194px -288px;
		display: block;
	}

	.bg-at {
		width: 72px;
		height: 72px;
		background-position:  -286px -288px;
		display: block;
	}

	.bg-au {
		width: 72px;
		height: 72px;
		background-position:  -380px -10px;
		display: block;
	}

	.bg-aw {
		width: 72px;
		height: 72px;
		background-position:  -380px -102px;
		display: block;
	}

	.bg-az {
		width: 72px;
		height: 72px;
		background-position:  -380px -194px;
		display: block;
	}

	.bg-ba {
		width: 72px;
		height: 72px;
		background-position:  -380px -286px;
		display: block;
	}

	.bg-bb {
		width: 72px;
		height: 72px;
		background-position:  -10px -380px;
		display: block;
	}

	.bg-bd {
		width: 72px;
		height: 72px;
		background-position:  -102px -380px;
		display: block;
	}

	.bg-be {
		width: 72px;
		height: 72px;
		background-position:  -194px -380px;
		display: block;
	}

	.bg-bf {
		width: 72px;
		height: 72px;
		background-position:  -286px -380px;
		display: block;
	}

	.bg-bg {
		width: 72px;
		height: 72px;
		background-position:  -378px -380px;
		display: block;
	}

	.bg-bh {
		width: 72px;
		height: 72px;
		background-position:  -472px -10px;
		display: block;
	}

	.bg-bi {
		width: 72px;
		height: 72px;
		background-position:  -472px -102px;
		display: block;
	}

	.bg-bj {
		width: 72px;
		height: 72px;
		background-position:  -472px -194px;
		display: block;
	}

	.bg-bm {
		width: 73px;
		height: 72px;
		background-position:  -103px -103px;
		display: block;
	}

	.bg-bn {
		width: 72px;
		height: 72px;
		background-position:  -472px -286px;
		display: block;
	}

	.bg-bo {
		width: 72px;
		height: 72px;
		background-position:  -472px -378px;
		display: block;
	}

	.bg-br {
		width: 72px;
		height: 72px;
		background-position:  -10px -472px;
		display: block;
	}

	.bg-bs {
		width: 72px;
		height: 72px;
		background-position:  -102px -472px;
		display: block;
	}

	.bg-bt {
		width: 72px;
		height: 72px;
		background-position:  -194px -472px;
		display: block;
	}

	.bg-bw {
		width: 72px;
		height: 72px;
		background-position:  -286px -472px;
		display: block;
	}

	.bg-by {
		width: 72px;
		height: 72px;
		background-position:  -378px -472px;
		display: block;
	}

	.bg-bz {
		width: 72px;
		height: 72px;
		background-position:  -470px -472px;
		display: block;
	}

	.bg-ca {
		width: 72px;
		height: 72px;
		background-position:  -564px -10px;
		display: block;
	}

	.bg-cd {
		width: 72px;
		height: 72px;
		background-position:  -564px -102px;
		display: block;
	}

	.bg-cf {
		width: 72px;
		height: 72px;
		background-position:  -564px -194px;
		display: block;
	}

	.bg-cg {
		width: 72px;
		height: 72px;
		background-position:  -564px -286px;
		display: block;
	}

	.bg-ch {
		width: 72px;
		height: 72px;
		background-position:  -564px -378px;
		display: block;
	}

	.bg-ci {
		width: 72px;
		height: 72px;
		background-position:  -564px -470px;
		display: block;
	}

	.bg-cl {
		width: 72px;
		height: 72px;
		background-position:  -10px -564px;
		display: block;
	}

	.bg-cm {
		width: 72px;
		height: 72px;
		background-position:  -102px -564px;
		display: block;
	}

	.bg-cn {
		width: 72px;
		height: 72px;
		background-position:  -194px -564px;
		display: block;
	}

	.bg-co {
		width: 72px;
		height: 72px;
		background-position:  -286px -564px;
		display: block;
	}

	.bg-cr {
		width: 72px;
		height: 72px;
		background-position:  -378px -564px;
		display: block;
	}

	.bg-ct {
		width: 72px;
		height: 72px;
		background-position:  -470px -564px;
		display: block;
	}

	.bg-cu {
		width: 72px;
		height: 72px;
		background-position:  -562px -564px;
		display: block;
	}

	.bg-cv {
		width: 72px;
		height: 72px;
		background-position:  -656px -10px;
		display: block;
	}

	.bg-cy {
		width: 72px;
		height: 72px;
		background-position:  -656px -102px;
		display: block;
	}

	.bg-cz {
		width: 72px;
		height: 72px;
		background-position:  -656px -194px;
		display: block;
	}

	.bg-de {
		width: 72px;
		height: 72px;
		background-position:  -656px -286px;
		display: block;
	}

	.bg-dj {
		width: 72px;
		height: 72px;
		background-position:  -656px -378px;
		display: block;
	}

	.bg-dk {
		width: 72px;
		height: 72px;
		background-position:  -656px -470px;
		display: block;
	}

	.bg-dm {
		width: 72px;
		height: 72px;
		background-position:  -656px -562px;
		display: block;
	}

	.bg-do {
		width: 72px;
		height: 72px;
		background-position:  -10px -656px;
		display: block;
	}

	.bg-dz {
		width: 72px;
		height: 72px;
		background-position:  -102px -656px;
		display: block;
	}

	.bg-ec {
		width: 72px;
		height: 72px;
		background-position:  -194px -656px;
		display: block;
	}

	.bg-ee {
		width: 72px;
		height: 72px;
		background-position:  -286px -656px;
		display: block;
	}

	.bg-eg {
		width: 72px;
		height: 72px;
		background-position:  -378px -656px;
		display: block;
	}

	.bg-eh {
		width: 72px;
		height: 72px;
		background-position:  -470px -656px;
		display: block;
	}

	.bg-er {
		width: 72px;
		height: 72px;
		background-position:  -562px -656px;
		display: block;
	}

	.bg-es {
		width: 72px;
		height: 72px;
		background-position:  -654px -656px;
		display: block;
	}

	.bg-et {
		width: 72px;
		height: 72px;
		background-position:  -748px -10px;
		display: block;
	}

	.bg-eu {
		width: 72px;
		height: 72px;
		background-position:  -748px -102px;
		display: block;
	}

	.bg-fi {
		width: 72px;
		height: 72px;
		background-position:  -748px -194px;
		display: block;
	}

	.bg-fj {
		width: 72px;
		height: 72px;
		background-position:  -748px -286px;
		display: block;
	}

	.bg-fm {
		width: 72px;
		height: 72px;
		background-position:  -748px -378px;
		display: block;
	}

	.bg-fo {
		width: 72px;
		height: 72px;
		background-position:  -748px -470px;
		display: block;
	}

	.bg-fr {
		width: 72px;
		height: 72px;
		background-position:  -748px -562px;
		display: block;
	}

	.bg-ga {
		width: 72px;
		height: 72px;
		background-position:  -748px -654px;
		display: block;
	}

	.bg-gb {
		width: 72px;
		height: 72px;
		background-position:  -10px -748px;
		display: block;
	}

	.bg-gb_eng {
		width: 72px;
		height: 72px;
		background-position:  -102px -748px;
		display: block;
	}

	.bg-gb_inr {
		width: 72px;
		height: 72px;
		background-position:  -194px -748px;
		display: block;
	}

	.bg-gb_sct {
		width: 72px;
		height: 72px;
		background-position:  -286px -748px;
		display: block;
	}

	.bg-gb_wls {
		width: 72px;
		height: 72px;
		background-position:  -378px -748px;
		display: block;
	}

	.bg-gd {
		width: 72px;
		height: 72px;
		background-position:  -470px -748px;
		display: block;
	}

	.bg-ge {
		width: 72px;
		height: 72px;
		background-position:  -562px -748px;
		display: block;
	}

	.bg-gh {
		width: 72px;
		height: 72px;
		background-position:  -654px -748px;
		display: block;
	}

	.bg-gi {
		width: 72px;
		height: 72px;
		background-position:  -746px -748px;
		display: block;
	}

	.bg-gl {
		width: 72px;
		height: 72px;
		background-position:  -840px -10px;
		display: block;
	}

	.bg-gm {
		width: 72px;
		height: 72px;
		background-position:  -840px -102px;
		display: block;
	}

	.bg-gn {
		width: 72px;
		height: 72px;
		background-position:  -840px -194px;
		display: block;
	}

	.bg-gq {
		width: 72px;
		height: 72px;
		background-position:  -840px -286px;
		display: block;
	}

	.bg-gr {
		width: 72px;
		height: 72px;
		background-position:  -840px -378px;
		display: block;
	}

	.bg-gt {
		width: 72px;
		height: 72px;
		background-position:  -840px -470px;
		display: block;
	}

	.bg-gw {
		width: 72px;
		height: 72px;
		background-position:  -840px -562px;
		display: block;
	}

	.bg-gy {
		width: 72px;
		height: 72px;
		background-position:  -840px -654px;
		display: block;
	}

	.bg-hk {
		width: 72px;
		height: 72px;
		background-position:  -840px -746px;
		display: block;
	}

	.bg-hn {
		width: 72px;
		height: 72px;
		background-position:  -10px -840px;
		display: block;
	}

	.bg-hr {
		width: 72px;
		height: 72px;
		background-position:  -102px -840px;
		display: block;
	}

	.bg-ht {
		width: 72px;
		height: 72px;
		background-position:  -194px -840px;
		display: block;
	}

	.bg-hu {
		width: 72px;
		height: 72px;
		background-position:  -286px -840px;
		display: block;
	}

	.bg-id {
		width: 72px;
		height: 72px;
		background-position:  -378px -840px;
		display: block;
	}

	.bg-ie {
		width: 72px;
		height: 72px;
		background-position:  -470px -840px;
		display: block;
	}

	.bg-il {
		width: 72px;
		height: 72px;
		background-position:  -562px -840px;
		display: block;
	}

	.bg-im {
		width: 73px;
		height: 73px;
		background-position:  -10px -10px;
		display: block;
	}

	.bg-in {
		width: 72px;
		height: 72px;
		background-position:  -654px -840px;
		display: block;
	}

	.bg-iq {
		width: 72px;
		height: 72px;
		background-position:  -746px -840px;
		display: block;
	}

	.bg-ir {
		width: 72px;
		height: 72px;
		background-position:  -838px -840px;
		display: block;
	}

	.bg-is {
		width: 72px;
		height: 72px;
		background-position:  -932px -10px;
		display: block;
	}

	.bg-it {
		width: 72px;
		height: 72px;
		background-position:  -932px -102px;
		display: block;
	}

	.bg-jm {
		width: 72px;
		height: 72px;
		background-position:  -932px -194px;
		display: block;
	}

	.bg-jo {
		width: 72px;
		height: 72px;
		background-position:  -932px -286px;
		display: block;
	}

	.bg-jp {
		width: 72px;
		height: 72px;
		background-position:  -932px -378px;
		display: block;
	}

	.bg-ke {
		width: 72px;
		height: 72px;
		background-position:  -932px -470px;
		display: block;
	}

	.bg-kg {
		width: 72px;
		height: 72px;
		background-position:  -932px -562px;
		display: block;
	}

	.bg-kh {
		width: 72px;
		height: 72px;
		background-position:  -932px -654px;
		display: block;
	}

	.bg-ki {
		width: 72px;
		height: 72px;
		background-position:  -932px -746px;
		display: block;
	}

	.bg-km {
		width: 72px;
		height: 72px;
		background-position:  -932px -838px;
		display: block;
	}

	.bg-kn {
		width: 72px;
		height: 72px;
		background-position:  -10px -932px;
		display: block;
	}

	.bg-kp {
		width: 72px;
		height: 72px;
		background-position:  -102px -932px;
		display: block;
	}

	.bg-kr {
		width: 72px;
		height: 72px;
		background-position:  -194px -932px;
		display: block;
	}

	.bg-kw {
		width: 72px;
		height: 72px;
		background-position:  -286px -932px;
		display: block;
	}

	.bg-ky {
		width: 73px;
		height: 73px;
		background-position:  -103px -10px;
		display: block;
	}

	.bg-kz {
		width: 72px;
		height: 72px;
		background-position:  -378px -932px;
		display: block;
	}

	.bg-la {
		width: 72px;
		height: 72px;
		background-position:  -470px -932px;
		display: block;
	}

	.bg-lb {
		width: 72px;
		height: 72px;
		background-position:  -562px -932px;
		display: block;
	}

	.bg-lc {
		width: 72px;
		height: 72px;
		background-position:  -654px -932px;
		display: block;
	}

	.bg-li {
		width: 72px;
		height: 72px;
		background-position:  -746px -932px;
		display: block;
	}

	.bg-lk {
		width: 72px;
		height: 72px;
		background-position:  -838px -932px;
		display: block;
	}

	.bg-lr {
		width: 72px;
		height: 72px;
		background-position:  -930px -932px;
		display: block;
	}

	.bg-ls {
		width: 72px;
		height: 72px;
		background-position:  -1024px -10px;
		display: block;
	}

	.bg-lt {
		width: 72px;
		height: 72px;
		background-position:  -1024px -102px;
		display: block;
	}

	.bg-lu {
		width: 72px;
		height: 72px;
		background-position:  -1024px -194px;
		display: block;
	}

	.bg-lv {
		width: 72px;
		height: 72px;
		background-position:  -1024px -286px;
		display: block;
	}

	.bg-ly {
		width: 72px;
		height: 72px;
		background-position:  -1024px -378px;
		display: block;
	}

	.bg-ma {
		width: 72px;
		height: 72px;
		background-position:  -1024px -470px;
		display: block;
	}

	.bg-mc {
		width: 72px;
		height: 72px;
		background-position:  -1024px -562px;
		display: block;
	}

	.bg-md {
		width: 72px;
		height: 72px;
		background-position:  -1024px -654px;
		display: block;
	}

	.bg-me {
		width: 72px;
		height: 72px;
		background-position:  -1024px -746px;
		display: block;
	}

	.bg-mg {
		width: 72px;
		height: 72px;
		background-position:  -1024px -838px;
		display: block;
	}

	.bg-mh {
		width: 72px;
		height: 72px;
		background-position:  -1024px -930px;
		display: block;
	}

	.bg-mk {
		width: 72px;
		height: 72px;
		background-position:  -10px -1024px;
		display: block;
	}

	.bg-ml {
		width: 72px;
		height: 72px;
		background-position:  -102px -1024px;
		display: block;
	}

	.bg-mm {
		width: 72px;
		height: 72px;
		background-position:  -194px -1024px;
		display: block;
	}

	.bg-mn {
		width: 72px;
		height: 72px;
		background-position:  -286px -1024px;
		display: block;
	}

	.bg-mo {
		width: 72px;
		height: 72px;
		background-position:  -378px -1024px;
		display: block;
	}

	.bg-mr {
		width: 72px;
		height: 72px;
		background-position:  -470px -1024px;
		display: block;
	}

	.bg-ms {
		width: 73px;
		height: 73px;
		background-position:  -10px -103px;
		display: block;
	}

	.bg-mt {
		width: 72px;
		height: 72px;
		background-position:  -562px -1024px;
		display: block;
	}

	.bg-mu {
		width: 72px;
		height: 72px;
		background-position:  -654px -1024px;
		display: block;
	}

	.bg-mv {
		width: 72px;
		height: 72px;
		background-position:  -746px -1024px;
		display: block;
	}

	.bg-mw {
		width: 72px;
		height: 72px;
		background-position:  -838px -1024px;
		display: block;
	}

	.bg-mx {
		width: 72px;
		height: 72px;
		background-position:  -930px -1024px;
		display: block;
	}

	.bg-my {
		width: 72px;
		height: 72px;
		background-position:  -1022px -1024px;
		display: block;
	}

	.bg-mz {
		width: 72px;
		height: 72px;
		background-position:  -1116px -10px;
		display: block;
	}

	.bg-na {
		width: 72px;
		height: 72px;
		background-position:  -1116px -102px;
		display: block;
	}

	.bg-ne {
		width: 72px;
		height: 72px;
		background-position:  -1116px -194px;
		display: block;
	}

	.bg-ng {
		width: 72px;
		height: 72px;
		background-position:  -1116px -286px;
		display: block;
	}

	.bg-ni {
		width: 72px;
		height: 72px;
		background-position:  -1116px -378px;
		display: block;
	}

	.bg-nl {
		width: 72px;
		height: 72px;
		background-position:  -1116px -470px;
		display: block;
	}

	.bg-no {
		width: 72px;
		height: 72px;
		background-position:  -1116px -562px;
		display: block;
	}

	.bg-np {
		width: 72px;
		height: 72px;
		background-position:  -1116px -654px;
		display: block;
	}

	.bg-nr {
		width: 72px;
		height: 72px;
		background-position:  -1116px -746px;
		display: block;
	}

	.bg-nz {
		width: 72px;
		height: 72px;
		background-position:  -1116px -838px;
		display: block;
	}

	.bg-om {
		width: 72px;
		height: 72px;
		background-position:  -1116px -930px;
		display: block;
	}

	.bg-pa {
		width: 72px;
		height: 72px;
		background-position:  -1116px -1022px;
		display: block;
	}

	.bg-pe {
		width: 72px;
		height: 72px;
		background-position:  -10px -1116px;
		display: block;
	}

	.bg-pg {
		width: 72px;
		height: 72px;
		background-position:  -102px -1116px;
		display: block;
	}

	.bg-ph {
		width: 72px;
		height: 72px;
		background-position:  -194px -1116px;
		display: block;
	}

	.bg-pk {
		width: 72px;
		height: 72px;
		background-position:  -286px -1116px;
		display: block;
	}

	.bg-pl {
		width: 72px;
		height: 72px;
		background-position:  -378px -1116px;
		display: block;
	}

	.bg-pr {
		width: 72px;
		height: 72px;
		background-position:  -470px -1116px;
		display: block;
	}

	.bg-ps {
		width: 72px;
		height: 72px;
		background-position:  -562px -1116px;
		display: block;
	}

	.bg-pt {
		width: 72px;
		height: 72px;
		background-position:  -654px -1116px;
		display: block;
	}

	.bg-pw {
		width: 72px;
		height: 72px;
		background-position:  -746px -1116px;
		display: block;
	}

	.bg-py {
		width: 72px;
		height: 72px;
		background-position:  -838px -1116px;
		display: block;
	}

	.bg-qa {
		width: 72px;
		height: 72px;
		background-position:  -930px -1116px;
		display: block;
	}

	.bg-ro {
		width: 72px;
		height: 72px;
		background-position:  -1022px -1116px;
		display: block;
	}

	.bg-rs {
		width: 72px;
		height: 72px;
		background-position:  -1114px -1116px;
		display: block;
	}

	.bg-ru {
		width: 72px;
		height: 72px;
		background-position:  -1208px -10px;
		display: block;
	}

	.bg-rw {
		width: 72px;
		height: 72px;
		background-position:  -1208px -102px;
		display: block;
	}

	.bg-sa {
		width: 72px;
		height: 72px;
		background-position:  -1208px -194px;
		display: block;
	}

	.bg-sb {
		width: 72px;
		height: 72px;
		background-position:  -1208px -286px;
		display: block;
	}

	.bg-sc {
		width: 72px;
		height: 72px;
		background-position:  -1208px -378px;
		display: block;
	}

	.bg-sd {
		width: 72px;
		height: 72px;
		background-position:  -1208px -470px;
		display: block;
	}

	.bg-se {
		width: 72px;
		height: 72px;
		background-position:  -1208px -562px;
		display: block;
	}

	.bg-sg {
		width: 72px;
		height: 72px;
		background-position:  -1208px -654px;
		display: block;
	}

	.bg-si {
		width: 72px;
		height: 72px;
		background-position:  -1208px -746px;
		display: block;
	}

	.bg-sk {
		width: 72px;
		height: 72px;
		background-position:  -1208px -838px;
		display: block;
	}

	.bg-sl {
		width: 72px;
		height: 72px;
		background-position:  -1208px -930px;
		display: block;
	}

	.bg-sm {
		width: 72px;
		height: 72px;
		background-position:  -1208px -1022px;
		display: block;
	}

	.bg-sn {
		width: 72px;
		height: 72px;
		background-position:  -1208px -1114px;
		display: block;
	}

	.bg-so {
		width: 72px;
		height: 72px;
		background-position:  -10px -1208px;
		display: block;
	}

	.bg-sr {
		width: 72px;
		height: 72px;
		background-position:  -102px -1208px;
		display: block;
	}

	.bg-st {
		width: 72px;
		height: 72px;
		background-position:  -194px -1208px;
		display: block;
	}

	.bg-sv {
		width: 72px;
		height: 72px;
		background-position:  -286px -1208px;
		display: block;
	}

	.bg-sy {
		width: 72px;
		height: 72px;
		background-position:  -378px -1208px;
		display: block;
	}

	.bg-sz {
		width: 72px;
		height: 72px;
		background-position:  -470px -1208px;
		display: block;
	}

	.bg-tc {
		width: 72px;
		height: 73px;
		background-position:  -196px -10px;
		display: block;
	}

	.bg-td {
		width: 72px;
		height: 72px;
		background-position:  -562px -1208px;
		display: block;
	}

	.bg-tg {
		width: 72px;
		height: 72px;
		background-position:  -654px -1208px;
		display: block;
	}

	.bg-th {
		width: 72px;
		height: 72px;
		background-position:  -746px -1208px;
		display: block;
	}

	.bg-tj {
		width: 72px;
		height: 72px;
		background-position:  -838px -1208px;
		display: block;
	}

	.bg-tl {
		width: 72px;
		height: 72px;
		background-position:  -930px -1208px;
		display: block;
	}

	.bg-tm {
		width: 72px;
		height: 72px;
		background-position:  -1022px -1208px;
		display: block;
	}

	.bg-tn {
		width: 72px;
		height: 72px;
		background-position:  -1114px -1208px;
		display: block;
	}

	.bg-to {
		width: 72px;
		height: 72px;
		background-position:  -1206px -1208px;
		display: block;
	}

	.bg-tr {
		width: 72px;
		height: 72px;
		background-position:  -1300px -10px;
		display: block;
	}

	.bg-tt {
		width: 72px;
		height: 72px;
		background-position:  -1300px -102px;
		display: block;
	}

	.bg-tv {
		width: 72px;
		height: 72px;
		background-position:  -1300px -194px;
		display: block;
	}

	.bg-tw {
		width: 72px;
		height: 72px;
		background-position:  -1300px -286px;
		display: block;
	}

	.bg-tz {
		width: 72px;
		height: 72px;
		background-position:  -1300px -378px;
		display: block;
	}

	.bg-ua {
		width: 72px;
		height: 72px;
		background-position:  -1300px -470px;
		display: block;
	}

	.bg-ug {
		width: 72px;
		height: 72px;
		background-position:  -1300px -562px;
		display: block;
	}

	.bg-us {
		width: 72px;
		height: 72px;
		background-position:  -1300px -654px;
		display: block;
	}

	.bg-uy {
		width: 72px;
		height: 72px;
		background-position:  -1300px -746px;
		display: block;
	}

	.bg-uz {
		width: 72px;
		height: 72px;
		background-position:  -1300px -838px;
		display: block;
	}

	.bg-va {
		width: 72px;
		height: 72px;
		background-position:  -1300px -930px;
		display: block;
	}

	.bg-vc {
		width: 72px;
		height: 72px;
		background-position:  -1300px -1022px;
		display: block;
	}

	.bg-ve {
		width: 72px;
		height: 72px;
		background-position:  -1300px -1114px;
		display: block;
	}

	.bg-vg {
		width: 73px;
		height: 72px;
		background-position:  -10px -196px;
		display: block;
	}

	.bg-vn {
		width: 72px;
		height: 72px;
		background-position:  -1300px -1206px;
		display: block;
	}

	.bg-vu {
		width: 72px;
		height: 72px;
		background-position:  -10px -1300px;
		display: block;
	}

	.bg-ws {
		width: 72px;
		height: 72px;
		background-position:  -102px -1300px;
		display: block;
	}

	.bg-ye {
		width: 72px;
		height: 72px;
		background-position:  -194px -1300px;
		display: block;
	}

	.bg-za {
		width: 72px;
		height: 72px;
		background-position:  -286px -1300px;
		display: block;
	}

	.bg-zm {
		width: 72px;
		height: 72px;
		background-position:  -378px -1300px;
		display: block;
	}

	.bg-zw {
		width: 72px;
		height: 72px;
		background-position:  -470px -1300px;
		display: block;
	}
`;
