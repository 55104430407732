import styled, { css } from "styled-components";
import productIcon from "../../../assets/security-tools/product-icons.png";
import featureIcon from "../../../assets/security-tools/security-tools.png";
import downloadStepForTrackerBlocker from "../../../assets/security-tools/tracker_blocker/download_steps.png";
import downloadStepForRmd from "../../../assets/security-tools/rmd/download_steps.svg";
import downloadStepForFile from "../../../assets/security-tools/file_encryption/download_step.png";
import downloadStepForPasswordManager from "../../../assets/security-tools/password_manager/download_step.png";
import downloadStepForSpm from "../../../assets/security-tools/spm/download_step.png";
import check from "../../../assets/tick.svg";
import info from "../../../assets/info.svg";
import gridIcons from "../../../assets/security-tools/grid_icons.svg";
import checkmark from "../../../assets/checkmark.svg";

export const SecurityToolsStyles = styled.div`

.security-tools{
    margin-bottom: 45px;

    h3{
        color: #001219;
        font-size: 24px;
        font-weight: 700;
    }
    
    p{
        color: var(--text-light-secondary, #637381);
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        letter-spacing: 0.5px;
        padding-bottom: 15px;
        border-bottom: 1px solid #EEEDF0;
        
    }
}

.security-tools-boxes{

    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(550px, 1fr));
    grid-gap: 30px;

    @media only screen and (max-width: 1424px) {
        grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    }

    @media only screen and (max-width: 890px) {
        grid-template-columns: repeat(auto-fit,minmax(300px, 1fr));
    }
    @media only screen and (max-width: 690px) {
        grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    }
    @media only screen and (max-width: 560px) {
        grid-template-columns: repeat(auto-fit,minmax(300px, 1fr));
    }
    @media only screen and (max-width: 350px) {
        grid-template-columns: 1fr;
    }
    

    .box{
        border-radius: 16px;
        border: 1px solid #ECE7FD;
        background: #FFF;
        min-height: 133px;
        padding: 24px 24px 16px 120px;
        position: relative;

        @media only screen and (max-width: 920px) {
            padding: 24px 24px 16px 76px;
        }

        @media only screen and (max-width: 890px) {
            text-align: center;
            padding: 96px 24px 16px 24px;
        }

        @media only screen and (max-width: 690px){
            padding: 86px 10px 10px 10px
        }

        &:hover{
            border: 1px solid #8260EF;
            cursor: pointer;
            box-shadow: 0px 12px 24px 0px rgba(101, 54, 204, 0.16);
            transition: 0.2s all ease-in-out;
        }

        &::before{
            position: absolute;
            content: '';
            width: 72px;
            height: 72px;
            background: url(${featureIcon}) no-repeat -228px 0px;
            top: 16px;
            left: 20px;

            @media only screen and (max-width: 920px) {
                transform: scale(0.8);
                left: 0px;
            }

            @media only screen and (max-width: 890px) {
                text-align: center;
                left: calc(50% - 37.6px);
            }
        }

        &.password_manager{
            &::before{
                background-position: -364px 0px !important;
            }
        }

        &.remove-my-data{
            &::before{
                background-position: 0px 0px !important;
            }
        }

        &.password-generator{
            &::before{
                background-position: -85px 0px !important;
            }
        }

        &.tracker-blocker{
            &::before{
                background-position: -184px 0px !important;
            }
        }

        &.social-privacy-manager{
            &::before{
                background-position: -270px 0px !important;
            }
        }

        &.secure-vaults{
            &::before{
                background-position: -288px 0px !important;
            }
        }

        &.file-encryption{
            &::before{
                background-position: -364px 0px !important;
            }
        }

        h4{
            color: #001219;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.5px;
            margin-bottom: 8px;
            @media only screen and (max-width: 350px) {
                font-size: 14px;
            }
        }

        h6{
            color: #637381;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            letter-spacing: 0.5px;
            min-height: 52.5px;

            @media only screen and (max-width: 1230px) {
                font-size: 12px;
            }
            @media only screen and (max-width: 890px) {
                min-height: 70px;
            }
            @media only screen and (max-width: 560px) {
                min-height: 50px;
            }
        }

        .power-icon{
            position: relative;
            float: right;

            @media only screen and (max-width: 690px) {
                float: none;
            }

            span{
                margin-left: 20px;
                color: #001219 !important;
                font-size: 11px  !important;
                font-weight: 600;
                line-height: 13.75px;
                letter-spacing: 0.22px;

            }

            &::before{
                position: absolute;
                content: '';
                width: 16px;
                height: 16px;
                background: url(${productIcon}) no-repeat 0px 0px;
                top: 6px;
                
            }

            &.purevpn{
                &::before{
                    background-position: 0px 0px !important;
                }
            }
            &.purekeep{
                &::before{
                    background-position: -16px 0px !important;
                }
            }
            &.purescan{
                &::before{
                    background-position: -32px 0px !important;
                }
            }
            &.purecrypt{
                &::before{
                    background-position: 0px 0px !important;
                }
            }
        }
    }
}

`

export const SecurityToolsFeatureStyles = styled.div`

    margin-bottom: 20px;

    .security-tools{
        margin-bottom: 60px;

        h3{
            color: #001219;
            font-size: 24px;
            font-weight: 700;
        }
        
        p{
            color: var(--text-light-secondary, #637381);
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            letter-spacing: 0.5px;
        }
        img{
            width: 100%;
        }
    }

`

export const DownloadStyles = styled.div`
    border-radius: 24px;
    border: 0.5px solid #DDD4E2;
    background: #FFF;
    padding: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 910px;
    margin: auto;
    margin-bottom: 72px;

    @media only screen and (max-width: 840px) {
        display: block;
        text-align: center;
        .download-image{
            margin-bottom: 20px;
        }
    }

    ${props => props.isPrivacy && css`
       box-shadow: 0px 10px 30px 0px rgba(255, 177, 10, 0.12), 0px 4px 10px 0px rgba(255, 177, 10, 0.05);
    `}
    ${props => props.isKeep && css`
        box-shadow: 0px 10px 30px 0px rgba(46, 196, 182, 0.12), 0px 4px 10px 0px rgba(46, 196, 182, 0.05);
    `}
    ${props => props.isEcrypt && css`
        box-shadow: 0px 10px 30px 0px rgba(67, 97, 238, 0.12), 0px 4px 10px 0px rgba(67, 97, 238, 0.08);
    `}

    h3{
        color: #001219;
        font-size: 32px;
        font-weight: 700;
        line-height: 45px;
        margin-bottom: 15px;

        @media only screen and (max-width: 720px) {
            font-size: 26px;
            line-height: 32px;
        }

        @media only screen and (max-width: 550px) {
            font-size: 24px !important;
            line-height: 32px;
        }

        @media only screen and (max-width: 420px) {
            font-size: 20px !important;
            line-height: 24px;
        }
    }

    p{
        color: #637381;
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;
        word-wrap: break-word;
        @media only screen and (max-width: 720px) {
            font-size: 16px;
            line-height: 18px;
        }
        @media only screen and (max-width: 550px) {
            font-size: 14px !important;
            line-height: 18px;
        }
    }

    ul{
        list-style: none;
        padding: 0;
        margin-bottom: 45px;

        @media only screen and (max-width: 740px) {
            margin-bottom: 10px;
        }

        li{
            display: inline;
            cursor: pointer;

            img{
                width: auto;
                margin-right: 10px;
                @media only screen and (max-width: 830px) {
                    margin-bottom: 12px;
                }
                @media only screen and (max-width: 550px) {
                    width: 120px;
                }
            }

        }
    }

    span{
        color: #637381 !important;
        font-size: 18px !important;
        font-weight: 600;
        line-height: 25.2px;

        @media only screen and (max-width: 720px) {
            font-size: 16px;
            line-height: 18px;
        }
        @media only screen and (max-width: 550px) {
            font-size: 14px !important;
            line-height: 18px;
        }
    }

    .download-image{
        width: 100%;

        @media only screen and (max-width: 840px) {
            width: 480px !important;
        }

        @media only screen and (max-width: 640px) {
            width: 350px !important;
        }

        @media only screen and (max-width: 560px) {
            width: 250px !important;
        }

        @media only screen and (max-width: 360px) {
            width: 180px !important;
        }
    }

    .download-btn{
        margin-right: 10px;
    }

`

export const FeatureIntroStyles = styled.div`
    margin: auto;

    h3{
        color: #001219;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        margin: auto;
        margin-bottom: 10px;
        max-width: 910px;

        @media only screen and (max-width: 720px) {
            font-size: 26px;
            line-height: 32px;
        }

        @media only screen and (max-width: 550px) {
            font-size: 24px !important;
            line-height: 32px;
        }

        @media only screen and (max-width: 420px) {
            font-size: 20px !important;
            line-height: 24px;
        }
    }
    h6{
        color: #637381;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.5px;
        margin-bottom: 0;
        margin: auto;
        max-width: 910px;
        margin-bottom: 24px !important;

        @media only screen and (max-width: 720px) {
            font-size: 16px;
            line-height: 18px;
        }
        @media only screen and (max-width: 550px) {
            font-size: 14px !important;
            line-height: 18px;
        }
    }
    .intro-img{
        margin: auto;
        display: flex;
        margin-bottom: 32px !important;
        max-width: 1145px;
        width: 100%;
    }
    .discover{
        p{
            text-align: center;
            color: #8E7A9D;
            font-size: 15px;
            font-weight: 600;
            width: max-content;
            margin: auto;
            text-decoration: underline;
            cursor: pointer;
        }
    }

`

export const HowItWorksStyles = styled.div`

    color: #001219;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin: 96px auto 0px;

    @media only screen and (max-width: 800px) {
        margin: 30px auto 0px;
    }

    h3{
        color: #001219;
        font-size: 32px;
        font-weight: 700;
        line-height: 45px;
        margin-bottom: 15px;

        @media only screen and (max-width: 720px) {
            font-size: 26px;
            line-height: 32px;
        }

        @media only screen and (max-width: 550px) {
            font-size: 24px !important;
            line-height: 32px;
        }

        @media only screen and (max-width: 420px) {
            font-size: 20px !important;
            line-height: 24px;
        }
    }

    h5{
        color: #6536CC;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        margin-top: 24px;
        margin-bottom: 10px;

        @media only screen and (max-width: 720px) {
            font-size: 22px;
            line-height: 16px;
        }
        @media only screen and (max-width: 550px) {
            font-size: 18px !important;
            line-height: 14px;
        }
    }

    .step-boxes{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin: 24px auto;

        @media only screen and (max-width: 970px) {
            gap: 10px;
        }

        @media only screen and (max-width: 800px) {
            display: block;
            text-align: center;
            margin: auto;
        }


        .works-step{
            padding: 114px 24px 24px;
            position: relative;
            border-radius: 24px;
            border: 1px solid #ECE7FD;
            background: #FFF;
            max-width: 283px;

            @media only screen and (max-width: 970px) {
                width: 240px;
            }

            @media only screen and (max-width: 800px) {
                padding: 94px 24px 24px;
                width: 299px;
                margin: auto auto 20px;
            }
    
            p{
                color: #000;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.25px;
                min-height: 63px;
                margin: 0;

                @media only screen and (max-width: 720px) {
                    font-size: 16px;
                    line-height: 18px;
                }
                @media only screen and (max-width: 550px) {
                    font-size: 14px !important;
                    line-height: 18px;
                }
            }

            &::before{
                position: absolute;
                content: '';
                width: 96px;
                height: 96px;
                background: url(${downloadStepForRmd}) no-repeat 0px 0px;
                top: 24px;
                left: calc(50% - 48px);

                @media only screen and (max-width: 800px) {
                    top: 10px;
                }    
            }

            &.tracker_blocker{
                &::before{
                    background: url(${downloadStepForTrackerBlocker}) no-repeat 0px 0px;
                }
            }

            &.file_encryption{
                &::before{
                    background: url(${downloadStepForFile}) no-repeat 0px 0px;
                }
            }

            &.password_manager{
                &::before{
                    background: url(${downloadStepForPasswordManager}) no-repeat 0px 0px;
                }
            }
            &.social-privacy-manager{
                &::before{
                    background: url(${downloadStepForSpm}) no-repeat 0px 0px;
                }
            }
            

            &.one{
                &::before{
                    background-position: 0px 0px !important;
                }
            }
            &.two{
                &::before{
                    background-position: -96px 0px !important;
                }
            }
            &.three{
                &::before{
                    background-position: -192px 0px !important;
                }
            }
        }
    }


`

export const FeatureInfoStyles = styled.div`

    margin: 60px auto;

    @media only screen and (max-width: 860px) {
        margin: 20px auto 0px;
    }

    h3{
        color: #001219;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 8px;
        text-align: inherit;
        
        @media only screen and (max-width: 720px) {
            font-size: 26px;
            line-height: 32px;
        }

        @media only screen and (max-width: 550px) {
            font-size: 24px !important;
            line-height: 32px;
        }

        @media only screen and (max-width: 420px) {
            font-size: 20px !important;
            line-height: 24px;
        }
    }

    p{
        color: #637381;
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;

        @media only screen and (max-width: 720px) {
            font-size: 16px;
            line-height: 18px;
        }
        @media only screen and (max-width: 550px) {
            font-size: 14px !important;
            line-height: 18px;
        }
    }

    .feature-box{
        display: flex;
        align-items: center;
        gap: 48px;
        margin-bottom: 72px;
        margin: auto;
        max-width: 920px;
        text-align: left;

        @media only screen and (max-width: 860px){
            display: block;
            max-width: 500px;
            margin: auto;
            text-align: center;
            div{
                margin-bottom: 40px;
                @media only screen and (max-width: 420px) {
                    margin-bottom: 40px;
                }
            }
        }

        img{
            @media only screen and (max-width: 990px) {
                width: 300px;
            }
            @media only screen and (max-width: 620px) {
                width: 240px;
            }

        }

        p{
            margin: 0
        }

        &.left{
            div{
                order: 2;
            }
        }
        
        &.right{
            img{
                order: 2;
            }
        }
    }

`

export const RMDFormStyles = styled.div`
    position: relative;
    padding-top: 34px;

    .loader-in-form{
        margin: 180px auto;
    }

    .form{
        border-radius: 16px;
        border: 1px solid #ECE7FD;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(101, 54, 204, 0.32), 0px 12px 12px 0px rgba(0, 0, 0, 0.06);
        padding: 30px;
        min-height: 492px;
        max-width: 675px;
        margin: auto;

        button{
            margin: auto;
        }

        .form-for-info{
            margin: 0px auto;

            .privacy-check{
                height: 16px;
                width: 16px;
                border: 1px solid #480CA8;
                position: relative;

                &:checked {
                    background-color: #480CA8 !important;
                    border-color: #480CA8 !important;
                    background-image: url(${check}) no-repeat;

                    &::before{
                        position: absolute;
                        background-image: url(${check}) no-repeat;
                        width: 100%;
                        height: 100%;
                        content: '';
                        top: 0px;
                        left: 1px;
                        background-size: 12px 15px;
                        filter: grayscale(0%);
                    }
                }

                &:focus {
                    border-color: #480CA8;
                    outline: 0;
                    box-shadow: none;
                }
            }

            .info_consent{
                color: #480CA8 !important;
                font-size: 12px !important;
                font-weight: 400;
                letter-spacing: 0.24px;
                text-decoration-line: underline;
                cursor: pointer;
            }

            h6{
                color: #001219 !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.24px;
                text-align: left;
                margin-left: 5px;

                @media only screen and (max-width: 860px) {
                    font-size: 12px !important;
                }
            }

            .warning{
                border-radius: 8px;
                border: 1px solid #6536CC;
                background: rgba(130, 96, 239, 0.08);
                padding: 8px;
                padding-left: 44px;
                color:  #5831AC;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.24px;
                position: relative;
                margin: 15px auto 30px;

                .info{
                    position: absolute;
                    content: '';
                    background: url(${info}) no-repeat;
                    top: 4px;
                    left: 12px;
                    width: 100%;
                    height: 24px;
                }
            }

            .invalid-feedback{
                margin-top: -0.75rem;
                margin-bottom: 15px;
            }
        }

        .stepper{
            list-style: none;
            padding: 0;
            max-width: 400px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            @media only screen and (max-width: 860px) {
                display: block;
                justify-content: start;
            }

            ::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                border-bottom: 1px solid rgba(130, 96, 239, 0.25);

                @media only screen and (max-width: 860px) {
                    border-left: 1px solid rgba(130, 96, 239, 0.25);
                    height: 33px;
                    width: 5px;
                    border-bottom: none;
                    top: 7px;
                    left: 10px;
                }
            }
            li{
                color: #637381 !important;
                text-align: center;
                font-size: 14px  !important;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                position: relative;
                padding-left: 26px;
                letter-spacing: -0.4px;
                background: #fff;

                @media only screen and (max-width: 860px) {
                    text-align: left;
                }

                &:nth-child(1){
                    padding-right: 6px;

                    @media only screen and (max-width: 860px) {
                        padding-right: 0px;
                        margin-bottom: 20px;
                    }

                    &::before{
                        left: 0px;
                    }
                }
                &:nth-child(2){
                    padding-left: 32px;

                    @media only screen and (max-width: 860px) {
                        padding-left: 26px;
                    }
                }

                &::before{
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    border: 1px solid #8260EF;
                    border-radius: 20px;
                    content: '';
                    top: 0px;
                    left: 6px;
                    opacity: 0.25;

                    @media only screen and (max-width: 860px) {
                        left: 0px;
                    }

                }
                &.active{
                    color: #001219  !important;

                    &::before{
                        border: 1px solid #001219;
                        opacity: 1;
                    }
                    
                }
                &.done{
                    
                    &::before{
                        border: none;
                        opacity: 1;
                        background: #FFB10A;
                        
                    }

                    &::after{
                        position: absolute;
                        background: url(${check}) no-repeat;
                        width: 100%;
                        height: 100%;
                        content: '';
                        top: 2px;
                        left: 3px;
                        background-size: 15px 17px;
                    }
                }
            }
        }
    }
    
    .label{
        color: #637381;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.24px;
        margin-bottom: 5px;
    }
    .input, .form-select{
        border-radius: 8px;
        border: 1px solid #ECE7FD;
        color: #001219 !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        letter-spacing: 0.5px;
        height: 48px;
        padding: auto 15px;
        margin-bottom: 15px;
    }

`

export const DataGridStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(263px, 1fr));
    grid-gap: 30px;
    margin-bottom: 30px;

    @media only screen and (max-width: 1439px) {
        grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    }

    @media only screen and (max-width: 1345px) {
        grid-gap: 10px;
    }

    @media only screen and (max-width: 980px) {
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit,minmax(300px, 1fr));
    }

    @media only screen and (max-width: 690px) {
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    }

    .data-broker{
        display: flex;
        gap: 30px;
        min-height: 247px;

        @media only screen and (max-width: 660px) {
            display: block;
            min-height: auto;
        }

        .reclaim, .reclaim-tips{
            border-radius: 16px;
            background: #FFF;
            box-shadow: 4px 4px 30px 4px rgba(101, 54, 204, 0.12);
            padding: 30px;
            width: 100%;
            height: 100%;

            @media only screen and (max-width: 660px) {
                min-height: max-content;
                height: max-content;
            }
        }

        .reclaim{
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 62.91%;

            @media only screen and (max-width: 940px) {
                min-width: 40%;
                align-items: center;
                justify-content: center;
            }

            @media only screen and (max-width: 660px) {
                margin-bottom: 20px;
            }

            .reclaim-img{
                max-width: 344px;

                @media only screen and (max-width: 1439px) {
                    max-width: 265px;
                }

                @media only screen and (max-width: 1100px) {
                    max-width: 265px;
                }

                @media only screen and (max-width: 940px) {
                    display: none;
                }
            }

            .reclaim-heading{
                min-width: 47%;
                text-align: center;

                h3{
                    color: #252A31;
                    font-size: 48px;
                    font-weight: 700;

                    @media only screen and (max-width: 550px) {
                        font-size: 48px !important;
                    }
                    @media only screen and (max-width: 420px) {
                        font-size: 36px !important;
                    }
                }
                h6{
                    color: #252A31;
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 420px) {
                        font-size: 14px !important;
                    }
                }
                button{
                    width: calc(100% - 100px);
                    justify-content: center;
                    font-size: 18px;

                    @media only screen and (max-width: 1640px) {
                        width: calc(100% - 59px) !important;
                    }

                    @media only screen and (max-width: 1500px) {
                        width: 100% !important;
                    }

                    @media only screen and (max-width: 1300px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .reclaim-tips{
            color: #252A31;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.2px;

            h4{
                color: #252A31;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.2px;
            }

            ul{
                padding: 0;

                li{
                    padding: 10px 0px;
                    padding-left: 25px;
                    position: relative;
                    color: #848E9C;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.16px;
                    list-style: none;
    
                    ::before{
                        position: absolute;
                        content: '';
                        width: 24px;
                        height: 24px;
                        background: url(${checkmark}) no-repeat;
                        left: 0px;
                        cursor: pointer;
                        top: 15px;
                        transform: scale(1.4);
                    }

                    &:last-child{
                        border-bottom: none;
                        padding-bottom: 0px;
                    }
                }
                
            }

        }
    }

    .grid-box{
        border-radius: 16px;
        background: #FFF;
        box-shadow: 4px 4px 30px 4px rgba(101, 54, 204, 0.12);
        padding: 20px 30px;
        text-center;
        min-height: 244px;

        @media only screen and (max-width: 1345px) {
            padding: 20px;
        }

        @media only screen and (max-width: 460px) {
            min-height: max-content;
        }

        h3{
            color: #001219;
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 15px;

            @media only screen and (max-width: 550px) {
                font-size: 24px !important;
            }
        }

        p{
            color: #4F5E71;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;

            @media only screen and (max-width: 550px) {
                font-size: 12px !important;
            }
        }

        h6{
            color: #001219;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.5px;
            margin-bottom: 15px;
        }

        ul{
            list-style: none;
            padding: 0;
            li{
                display: inline-block;
                position: relative;
                color: #001219;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.5px;
                letter-spacing: 0.5px;
                padding-left: 38px;
                height: 26px;
            
                &::before{
                    position: absolute;
                    content: '';
                    top: -3px;
                    left: 0px;
                    width: 26px;
                    height: 26px;
                    background: url(${gridIcons}) no-repeat 0px 0px;
                }

                &.total{
                    ::before{
                        background: url(${gridIcons}) no-repeat -52px 0px;
                    }
                }
                &.in-progress{
                    ::before{
                        background: url(${gridIcons}) no-repeat -26px 0px;
                    }
                }
                span{
                    color: #001219;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.5px;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
`

export const CircleStyles = styled.div`

        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    
        width: 167px;
        height: 167px;
        border-radius: 50%;

        ${props => props.request && css`
            background: 
            radial-gradient(closest-side, white 88%, transparent 80% 100%),
            conic-gradient(#6536CC ${props.request}%, rgba(130, 96, 239, 0.25) 0);    
        `}

        h3{
            color: #001219 !important;
            font-size: 30px !important;
            font-weight: 700 !important;
            margin-bottom: 2px !important;
        }

        span{
            color: #637381 !important;
            font-size: 12px !important;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.24px;
            display: block;
        }
`

export const PasswordGeneratorStyles = styled.div`
    margin-top: 60px;
    &>div{
        border-radius: 24px;
        border: 1px solid #DDD4E2;
        background: #FBFBFC;
        box-shadow: 0px 10px 30px 0px rgba(101, 54, 204, 0.12), 0px 4px 10px 0px rgba(101, 54, 204, 0.05);
        max-width: 910px;
        margin: auto;
        height: auto;

        @media only screen and (max-width: 380px) {
            padding: 24px 10px;
        }

        h2{
            color: #001219;
            font-weight: 700;
            margin-bottom: 10px !important;
            @media only screen and (max-width: 720px) {
                font-size: 24px;
                line-height: 32px;
            }
            @media only screen and (max-width: 720px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        p{
            color: #637381;
            line-height: 20px;
            margin-bottom: 45px !important;
            @media only screen and (max-width: 720px) {
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 24px !important;
            }
            @media only screen and (max-width: 720px) {
                font-size: 14px;
                line-height: 16px;
            }

        }
        input{
            accent-color: #6536CC;
        }
        .d-flex{
            margin: 0px auto 30px;
        }
        .randomPswView{
            border-radius: 12px;
            background: rgba(192, 175, 247, 0.16);
            width: 436px;
            margin: auto;
            margin-bottom: 30px;
            color: #001219  !important;
            font-size: 25px !important;
            font-weight: 700;
            padding: 30px 0px;
            @media only screen and (max-width: 720px) {
                width: 300px;
            }
            @media only screen and (max-width: 390px) {
                width: 200px;
            }
        }
        
    }
`