export const suggestedPrompts = [
    "How to watch The Office in UK",
    "I am feeling {bored}. Suggest me the best movie",
    "How can I find the best deals on purchasing Call of Duty game?",
    "How to stay secure online",
    "Can my Internet Service Provider (ISP) detect when I am using a VPN?",
    "How can a password manager help me?",
    "I'm having trouble connecting to PureVPN, can you help me troubleshoot?",
    "What protocols does PureVPN use?",
    "Can you explain what PureVPN Dedicated IP is and how it functions?"
];


export const suggestedPromptsLess = [
    "How to stay secure online",
    "Search similar movies/titles/shows like Friends",
    "How to watch The Office in UK",
    "Which platforms are supported by PureVPN?"
];