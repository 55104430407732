import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const PurePrivacyIconOnlySVG = ({ fillColorOne, fillColorTwo, fillColorThree, fillColorFour, width, height, style, className }) => (
    <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} className={className} viewBox="0 0 97 96" width={width}>
      <Path
          fillColor={fillColorOne}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.7037 34.6619C99.8752 53.8581 93.4586 71.9531 80.8647 83.4425C68.2714 94.9314 49.5966 99.8144 30.2035 92.6334C18.041 88.0857 8.36839 78.464 3.86729 66.3044C-3.31532 46.9168 1.56885 28.1993 13.0611 15.6092C24.5532 3.0192 42.6534 -3.3955 61.8548 1.82242C77.9441 6.13081 90.3941 18.5772 94.7037 34.6619Z"
      />
      <Path
          fillColor={fillColorTwo}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8717 21.2093C30.4909 12.1617 43.6111 7.51825 57.7369 10.9171C70.8571 14.0766 81.0563 24.2252 84.1213 37.3418C87.6167 52.2297 82.2059 66.0642 72.0545 74.5378C70.0429 76.2128 67.8881 77.6491 65.5418 78.8939C64.6321 79.3725 63.7702 79.9471 62.956 80.4257C55.2469 84.8779 45.3828 87.7022 34.7525 84.2072C34.561 84.1594 34.3695 84.064 34.1779 84.0162C24.6011 80.952 17.227 73.2452 13.9708 63.7184C11.5766 56.6817 11.1457 44.714 12.678 38.5864C12.8695 37.6291 13.1089 36.7195 13.3483 35.81C14.9764 30.2569 17.9452 25.2783 21.8717 21.2093Z"
      />
      <Path
          fillColor={fillColorThree}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6345 26.7642C36.3327 21.2112 44.5688 18.339 53.571 20.0144C63.7224 21.9292 71.719 29.8758 73.5384 40.0245C75.406 50.604 70.9526 60.2257 63.1954 65.5873C61.7115 66.6406 60.0831 67.55 58.3594 68.2684C57.689 68.5555 57.0665 68.9863 56.6834 69.5132C52.9963 74.3002 45.9574 77.6031 38.4875 75.4966C38.3438 75.4489 38.2001 75.4489 38.0565 75.4012C31.0654 73.7733 26.0376 68.0769 24.0264 61.1835C22.973 57.641 22.5899 43.8542 23.3561 40.7904C23.4519 40.0245 23.5955 39.2585 23.787 38.5404C24.8884 33.9928 27.3784 29.9716 30.6345 26.7642Z"
      />
      <Path
          fillColor={fillColorFour}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9082 42.8004C62.525 35.5718 56.6355 29.6359 49.4049 29.205C45.6221 28.9657 42.1266 30.2103 39.4451 32.4124C36.7636 34.6144 34.8482 37.726 34.2736 41.3164C34.1299 41.8908 34.082 42.4653 34.082 43.0397V58.6935C34.082 63.1932 37.7212 66.8792 42.2702 66.8792C46.7713 66.8792 50.4584 63.2415 50.4584 58.6935C50.4584 58.2626 50.7936 57.8318 51.2246 57.7839C58.1198 56.4436 63.2914 50.1725 62.9082 42.8004Z"
      />
    </SVG>
)
PurePrivacyIconOnlySVG.propTypes = {
  fill: PropTypes.string,
}
PurePrivacyIconOnlySVG.defaultProps = {
  width: '97',
  height: '96',
  fillColorOne: '#FFB10A',
  fillColorTwo: '#FFCB5C',
  fillColorThree: '#FFE5AD',
  fillColorFour: 'white',
  className: 'svg-icon'
}
export default PurePrivacyIconOnlySVG