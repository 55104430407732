import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga';
import { loadFromLocalStorage, saveToLocalStorage } from './persistantState';
import axios from 'axios';
import { api, logoutApi } from '../tools/tools';

const initialState = {};

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware];

const persistedState = loadFromLocalStorage();

const appReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    logoutApi()
    return rootReducer({}, action)
  }
  else if(action.type === "EMPTYSTORE"){
    // TODO : we'll enhance with caching
    localStorage.removeItem('central-plans')
    localStorage.removeItem('member-accounts')
    localStorage.removeItem('volume')
    localStorage.removeItem("store")
    localStorage.removeItem('data-brokers-list')
    localStorage.removeItem('guest-data-broker-list')
    localStorage.removeItem('pureteams_counter')
    return rootReducer({}, action)
  }
  return rootReducer(state, action)
}

const store = createStore(
  appReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga)

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;