import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Step1 from '../../../../assets/step1.svg';
import Step2 from '../../../../assets/step2.svg';
import Step3 from '../../../../assets/step3.svg';
import { ReferralModalBody } from '../../../../styles/dashboard/Main';

const ReferralProcessModal = ({showReferralProcessModal}) => {
    const { t } = useTranslation();

    const [modal, setModal] = useState(true);

    const [modalWidth] = useState({
        lg: "435x",
        sm: "435px"
    })
    const widthCalculator = "lg"

    const toggle = () => showReferralProcessModal(false);

    return (
        <Modal backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} modal={modal.toString()} toggle={toggle} isOpen={modal} scrollable>
            <ModalBody className="pb-0">
                <ModalHeader toggle={toggle}></ModalHeader>
                <ReferralModalBody>
                    <h1>{t('how_referral_works')}</h1>
                    <div className='referral-steps'>
                        <div className='step'>
                            <div className='step-count'>1</div>
                            <img src={Step1} />
                            <div className='step-name'>{t('refer_step1')}</div>
                            <div className='step-desc'>{t('refer_step1_desc')}</div>
                        </div>
                        <div className='step'>
                            <div className='step-count'>2</div>
                            <img src={Step2} />
                            <div className='step-name'>{t('refer_step2')}</div>
                            <div className='step-desc'>{t('refer_step2_desc')}</div>
                        </div>
                        <div className='step'>
                            <div className='step-count'>3</div>
                            <img src={Step3} />
                            <div className='step-name'>{t('refer_step3')}</div>
                            <div className='step-desc'>{t('refer_step3_desc')}</div>
                        </div>
                    </div>
                </ReferralModalBody>
            </ModalBody>
        </Modal>
    )
}

export default ReferralProcessModal