import styled from "styled-components";

export const CancellationStyles = styled.div`
    
    .content{
        &.extra-padding{
            margin-top: 20px;
        }
    }
    .heading{
        padding-bottom: 30px;
    }
    h2{
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        color: #001219;
        margin-bottom: 0px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        color: #001219;
        margin-top:10px;
        margin-bottom: 0px;
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #001219;
    }
    h5{
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #637381;
    }
    .action-btns{
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 20px;
        &.center{
            width: 100%;
            justify-content: center;
        }
    }
    .primary-btn{
        border-radius: 100px;
        font-weight: 600;
        font-size: 13px;
        width: 190px;
        padding: 9px inherit 11px inherit;

        &:focus{
            box-shadow: none;
            outline: none;
        }


        &.solid-btn{
            background: #550CCB;
            color: #F2F4F4;
            border: 1px solid #550CCB;
            &:hover{
                border: 1px solid #480CA8;
                transition: 0.3s all ease-in;
                background: linear-gradient(0deg,#480CA854,#480CA899),#480CA8 !important;
            }
        }
        &.outline-btn, &.inner-outline-btn{
            background: #FFFFFF;
            color: #637381;
            border: 1px solid #637381;
            &:hover{
                transition: 0.3s all ease-in;
                background: linear-gradient(0deg,rgba(67,34,91,0.05),rgba(67,34,91,0.05)),#FFFFFF;
            }
        }
        &.inner-outline-btn{
            width: 210px;
        }
    }   
    ul{
        list-style: none;
        padding: 0;
    }
`

export const CancellationFomoStyles = styled.div`

    .content-body{
        height: calc(100vh - 210px);
        overflow-y: auto;
    }
    li.fomo{
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 10px;
        margin-bottom: 6px;
        border-bottom: 1px solid #EEEDF0;
        p{
            font-size: 18px;
            color: #001219;
            font-weight: 600;
            margin: 0;
        }
        &:last-child{
            border-bottom: none;
        }
    }
    figure{
        margin: 0;
        width: 96.39px;
        height: 95.48px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    
`;

export const CancellationOptionsStyles  = styled.div`
    .list_without_cta{
        li.fomo{
            display: flex;
            align-items: center;
            gap: 20px;
            padding-bottom: 10px;
            margin-bottom: 6px;
            border-bottom: 1px solid #EEEDF0;
            p{
                font-size: 14px;
                color: #001219;
                font-weight: 400;
                margin: 0;
            }
            &:last-child{
                border-bottom: none;
            }
            figure{
                margin: 0;
                width: 112px;
                height: 112px;
                min-width: 112px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .content-body{
        height: calc(100vh - 220px);
        overflow-y: auto;

        &.no-para-content{
            height: calc(100vh - 200px);
        }
    }
    .cancelled-content{
        height: calc(100vh - 130px);
        overflow-y: scroll;
        h2{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #001219;
            margin-bottom: 10px;
        }
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.5px;
            color: #637381;
            margin-bottom: 30px;
        }
    }
    li{
        margin-bottom: 10px;
    }
    div.options{
        border: 1px solid #EEEDF0;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        &:last-child{
            margin-bottom: 0px;
        }

        &.hide{
            display: none !important;
        }

        &.no-hover{
            &:hover{
                border: 1px solid #EEEDF0;
                box-shadow: none;
                transition: none;
                background: none;
            }
        }

        &.image-options{
            display: flex;
            align-items: center;
            gap: 30px;
            

            figure{
                margin: 0;
                width: 112px;
                height: 112px;
                min-width: 112px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

        }

        .qr-box{
            height: 112px !important;
            margin-left: 54px;
            margin-bottom: 15px;
            display: table;
            background: #FFFFFF;
            border: 1px solid #EEEDF0;
            text-align: center;
            position:relative;
            width: max-content;
            .arrow{
                content: "";
                position: absolute;
                left: 45%;
                bottom: -5.5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 7px 0px 7px;
                border-color: #fff transparent transparent transparent;
                z-index: 9999;
            }
            .arrow-outline{
                content:"";
                position: absolute;
                left: 45%;
                bottom: -7px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 7px 0px 7px;
                border-color: #EEEDF0 transparent transparent transparent;
                z-index:9998;
            }
            .qr-heading{
                font-weight: 700 !important;
                font-size: 10px !important;
                letter-spacing: 0.02em;
                color: #001219 !important;
                margin-bottom: 5px;
                margin-top: 10px;
                display: block;
            }
            .qr{
                margin: 10px 11.5px 0px;
                width: 72px;
                height: 72px;
                display: block;
            }
        }

        &:hover{
            transition: 0.3s all ease-in;
            background: rgba(237, 237, 237, 0.3);
            border: 1px solid #8260EF;
            -webkit-box-shadow: 0px 5px 5px rgba(59, 16, 142, 0.1);
            -moz-box-shadow: 0px 5px 5px rgba(59, 16, 142, 0.1);
            box-shadow: 0px 5px 5px rgba(59, 16, 142, 0.1);
        }

        h6{
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: #001219;
            margin-bottom: 5px;
            line-height: 18px;
        }

        p{
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.02em;
            color: #637381;
            margin: 0;
        }

    }
    textarea{
        border: 1px solid #EEEDF0;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        resize: none;
        height: 84px;
        font-size: 14px;
        background: #FBFBFC;

        &.error{
            border: 1px solid red;
        }

        &:focus, :active{
            outline: none;
        }
        &::placeholder{
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #001219;
            opacity: 0.2;
        }
    }
    .warning{
        display: flex;
        align-items: top;
        gap: 10px;
        margin-top: 30px;
        span{
            display: block;
            width: 100%;
            color: #001219 !important;
            font-size: 16px;
        }
        p{
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #637381;
            margin: 0;
        }
    }
`;