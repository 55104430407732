import { addonConstants } from '../../../../../tools/constants';
import { currencySymbol } from '../../../../../tools/tools';
import { DestructBtn, PrimaryBtn } from '../../../../../styles/Generic';
import { PopupBody } from '../../../../../styles/dashboard/Main';
import { sendEvents } from '../../../../../tools/mpEvents';
import { useHistory } from "react-router-dom";
import check from '../../../../../assets/check.svg';
import React, { useState, useEffect } from 'react';
import { dedicatedServerContent } from '../../../../../tools/AppModalContent/dedicated_server';
import { useTranslation } from 'react-i18next';
import { Mark, Cross } from "../../../../../styles/dashboard/pure-teams/prePurchase";

const DedicatedInfoModal = ({ addonType, selectedSubscriptionData, appInfoModal, onClickFeatureMetric, appEventHandler, redirectToBusiness}) => {

  const token = localStorage.getItem("token");
  const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);

  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {

    sendEvents({
      event_name:"ma_view_addondetails",
      event_properties:{
          source: "Member Area",
      }
     }, token)

  }, [addonType]);


  const [comboAddons] = useState({
    dedicated_ip:"dedicated_ip",
    dedicated_ip_ddos:"dedicated_ip_ddos",
    ddos_protection:"ddos_protection",
    dedicated_ip_portforwarding_combo:"dedicated_ip_portforwarding_combo",
    port_forwarding_with_dedicated_ip:"port_forwarding_with_dedicated_ip",
  })

  const checkAddonPurchased = (addonsArray, comboAddons) => {
    return Object.keys(comboAddons).some(comboAddonKey =>
        addonsArray.some(addon => addon.code === comboAddons[comboAddonKey])
    );
  };

  const getPrice = (slug) => {
    const addon = selectedSubscriptionData?.plan?.add_ons.find((add) => add.code === slug);
    const currency = addon?.currency?.currency;
    const price = addon?.currency?.price / selectedSubscriptionData?.plan?.interval_length;

    // Check if price is NaN and use static value 1.99 if NaN
    const formattedPrice = isNaN(price) ? (slug == addonConstants.dedicated_server ? parseFloat(process.env.REACT_APP_DEDICATED_SERVER_PRICE) : parseFloat(process.env.REACT_APP_DEDICATED_IP_PRICE)) : price.toFixed(2);
    
    return `${currencySymbol[currency] ?? '$'}${formattedPrice}`;
  }

  const domeCtaHandle = () => {
    sendEvents({
      event_name:"ma_select_addon",
      event_properties:{
          addon_name: "dedicated_team",
          source: "features_metric",
      }
    }, token)
    if(redirectToBusiness){
      history.push(`/dashboard/business`)
      
    }else{
      appEventHandler("show_dome_on_ma", { cta: "pureDome" });
    }
  }

  return (
    <PopupBody className='is-dedicated-info-modal'>
      <div className="mod-head">
        <h3 className="text-center heading-text">{t('dedicated_server_modal_heading')}</h3>
        <p className="text-center heading-para">{t('dedicated_server_modal_paragraph')}</p>
      </div>

      <div className="mod-body mt-4">
        <div className="table-container">
          <table>
            <tr>
              {['dedicated_server_modal_table_heading_one', 'dedicated_server_modal_table_heading_two', 'dedicated_server_modal_table_heading_three', 'dedicated_server_modal_table_heading_four', 'dedicated_server_modal_table_heading_five'].map((addon, index)=> {
                  return <th className={`${index === 0 ? 'featcolumn-padding' : null}`}>{t(addon)} </th>
              })}
            </tr>

            {dedicatedServerContent?.map((feature) => 
              <tr>
                <td className='features-column'>{t(feature?.heading)}</td>
                {feature?.provision?.map((prov) => 
                  <td className={`column-spacing`}>
                    {feature?.hasImage ? prov ? <Mark /> : <Cross /> : prov}
                  </td>
                )}
              </tr>
            )}
            <tr>
              <td className={`features-column`}>{t('price')}</td>
              <td className={`column-spacing`}><span className='yellow-text'>{t('current_plan')}</span></td>
              <td className={`column-spacing`}>{t('your_plan', {price: getPrice('dedicated_ip')})}</td>
              <td className={`column-spacing`}>{t('your_plan', {price: getPrice('dedicated_server')})}</td>
              <td className={`column-spacing`}>{`${currencySymbol[selectedSubscriptionData?.plan?.currency?.currency_code] ?? '$'}${pricing?.[selectedSubscriptionData?.plan?.interval_length]} ${t('upgradeplan_slash_mo')} ${t('upgradeplan_per_member')}`}</td>
            </tr>
            {appInfoModal ?
            <tr>
              <td className={`features-column`}></td>
              <td className={`column-spacing`}></td>
              <td className={`column-spacing`}>
              {checkAddonPurchased(selectedSubscriptionData?.add_ons, comboAddons) ? <DestructBtn className='d-flex gap-2 align-items-center info-modal purchased'><img src={check} height="15" width="15" />{t('subscription_addon_purchased')}</DestructBtn> : <PrimaryBtn  onClick={() => onClickFeatureMetric("dedicated_ip")} dsInfoModal>{t('get_addon')}</PrimaryBtn>}
              
              </td>
              <td className={`column-spacing`}>
                {selectedSubscriptionData?.add_ons.find((add)=> add.code == addonConstants.dedicated_server) ? <DestructBtn className='d-flex gap-2 align-items-center purchased'><img src={check} height="15" width="15" />{t('subscription_addon_purchased')}</DestructBtn> : <PrimaryBtn  onClick={() => onClickFeatureMetric("dedicated_server")} dsInfoModal>{t('get_addon')}</PrimaryBtn>}
              </td>
              <td className={`column-spacing`}><PrimaryBtn dsInfoModal onClick={domeCtaHandle}>{t('get_started_text')}</PrimaryBtn></td>
            </tr>
             :null}
          </table>
        </div>
      </div>
    </PopupBody>
  );
};

export default DedicatedInfoModal;
