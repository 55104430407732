import { useEffect } from "react";
import { checkAccountType, getPaymentMethods, getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

const ExpiredView = ({selectedSubscriptionData}) => {

    const token = getTokenFromLocalStorage();
    useEffect(()=>{
            sendEvents({
                event_name:"ma_fail_preview_teams",
                event_properties:{
                    plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                    billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                    payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                    current_status: selectedSubscriptionData?.state ?? 'N/A',
                    account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                }
            }, token)
    }, [])
    return <h6 style={{borderTop: '1px solid #EBE6FF', paddingTop: '30px'}}>Your PureVPN account has expired. To maintain uninterrupted access to our services, kindly renew your account.</h6>
}
export default ExpiredView;