import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher, getFirebase, get } from '../../tools/tools';
import { errorsType, getRemoveMyDataType, loadingType } from './actions';
import i18n from "i18next";
import qs from 'qs';

function* removeMyDataAsync(action){
    
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const subscriptions =  yield get("integrated-experience/user-details", action.payload, cancelTokenSource);
        const {data: response} = subscriptions;
        if(response?.status){
            yield put(getRemoveMyDataType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: 'Something went wrong. Please try again' }))
    }
    finally{
        if(yield cancelled()){
        cancelTokenSource.cancel();
        }
    }
}

export function* watchRemoveMyDataRequest(){

    yield takeLatest('GETREMOVEMYDATAREQUEST', removeMyDataAsync)
}