import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { PrimaryBtn } from '../../../../styles/Generic';
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = ({backdrop, modal, toggle, modalWidth, widthCalculator, message, cta, onErrorClick}) => {

    const { search } = useLocation();
    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    const { t, i18n } = useTranslation();

    return <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
        <>
            {!redirected_via && <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>}
            <ModalBody className="pb-0">
                <PopupBody>
                    <div className=''>
                        <h3 className='mb-2 text-center'>{t('addon_purchasemodal_uhoh')}</h3>
                        <p className='mb-0 text-center'>{message ?? 'Sorry! You can\'t upgrade your plan type!'}</p>
                    </div>
                </PopupBody>
            </ModalBody>
            {!redirected_via && <ModalFooter>
                <div className='m-auto mt-2'>
                    <PrimaryBtn onClick={()=>onErrorClick(cta ?? 'Ok')}>{cta ?? 'Close'}</PrimaryBtn>
                </div>
            </ModalFooter>}
        </>
    </Modal>
}

export default Error;