import { GETWALLETDATA, LOADING, ERRORS, SUCCESSWALLETDATA } from "./types";

export const getWalletData = (payload) => {
    return {
      type: GETWALLETDATA,
      payload
    };
  };

export const loadingType = () => {
  return{
      type:LOADING
  }
}
export const errorsType = (payload) => {
  return{
      type:ERRORS,
      payload
  }
}

export const getSuccessWalletData = (payload) =>{
  return {
    type:SUCCESSWALLETDATA,
    payload
  }
}

