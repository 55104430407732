import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { getTokenFromLocalStorage } from '../../../../tools/tools';
import { sendEvents } from '../../../../tools/mpEvents';

const ProxyDedicatedIp = ({backdrop, toggle, modal,removeAllParams,isProxyCampaign=null}) => {
    const token = getTokenFromLocalStorage()

    useEffect(()=>{
        sendEvents({
            event_name: isProxyCampaign ? "ma_dedicated_ip_wait_list_campaign" : "ma_view_advance_ip_proxy_popup",
            event_properties:{}
        },token)
    },[])

    const ctaClick = (close=true) => {
        removeAllParams()
        if(close){
            sendEvents({
                event_name:isProxyCampaign ? "ma_close_wait_list_dedicated_ip_modal" : "ma_close_proxy_dedicated_ip_modal",
                event_properties:{
                }
            }, token)
        }else{
            if(window?.Intercom){
                window.location.hash="showDedicatedIpChat"
                window.Intercom('shutdown')
                window.Intercom('boot')
            }
            sendEvents({
                event_name:"ma_talk_support_proxy_dedicated_ip_modal",
                event_properties:{
                }
            }, token)
        }
        toggle()
    }
    return (
        <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `725px`, width: '100%'}} className="modal-wrapper">
            <ModalBody>
                <PopupBody>
                    <div class="mod-head mb-0"> 
                        <h3 class="">{isProxyCampaign ? 'Thank you for joining the waitlist!' : `Want to enable your IP in Proxy?`}</h3>
                    </div>
                    <div class="mod-body mt-3">
                        <ul class="list-unstyled p-0 m-0 addonInfo">
                            {isProxyCampaign ? <li>We will notify you once the new dedicated IP offering is launched.</li>
                            :
                            <>
                                <li>To connect to your Dedicated IP in Proxy you need to upgrade to the advanced network.</li>
                                <li>To know more details you can talk to our support team and they'll provide you with all the relevant information.</li>
                            </>
                            }
                        </ul> 
                    </div>
                </PopupBody>
            </ModalBody>
            <ModalFooter className="pt-0">
                {!isProxyCampaign && <PrimaryBtn onClick={()=>ctaClick(false)}>Talk to Support</PrimaryBtn>}
                <DestructBtn color="secondary" onClick={()=>ctaClick()}>Close</DestructBtn>
            </ModalFooter>
        </Modal>
    )
}

export default ProxyDedicatedIp