import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { InfoStyles } from "../../../../styles/dashboard/Info"
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";
import { PrimaryBtn } from '../../../../styles/Generic';
import { useState } from 'react';
import { sendEvents } from '../../../../tools/mpEvents';
import { getTokenFromLocalStorage } from '../../subscriptions/cancellation';

const AddMoreLimit = ({ backdrop, modal, toggle, modalWidth, widthCalculator, className, teamsCounter}) => {
    const contactNumber = process.env.REACT_APP_PUREDOME_CONTACT_NUMBER;
    const token = getTokenFromLocalStorage();
    const [showNumber, setShowNumber] = useState();

    const onClickTalkToSupport = () => {
        sendMaxExceedEvent('Talk to support');
        if(window.Intercom){
            window.Intercom('show')
        }
        toggle();
    }
    const toggleShowNumber = () => {
        sendMaxExceedEvent('Call me');
        setShowNumber(!showNumber)
    }

    const sendMaxExceedEvent = (cta) => {
        sendEvents({
			event_name: "ma_click_add_team_members_popup",
			event_properties: {
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
				cta : cta
			},
		  }, token);
    }
    return <Modal backdrop={backdrop} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
        <>
        <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
            <ModalBody className={`${className} pb-0`}>
                <PopupBody>
                    
                    <InfoStyles>
                        <div className="info-head mb-3 text-center">
                            <div className="info-modal">
                                <h1>Oops! </h1>
                                <h4>It seems you’ve reached the maximum member limit. Don’t worry, upgrading to PureDome unlocks a world of possibilities!</h4>
                            </div>
                        </div>
                    </InfoStyles>
                </PopupBody>
            </ModalBody>
            <ModalFooter>
                <div className='m-auto mt-2 d-flex gap-2'>
                    <PrimaryBtn isTeams2 onClick={onClickTalkToSupport}>Talk to support</PrimaryBtn>
                    {/* <PrimaryBtn isTeams2 onClick={toggleShowNumber}>{showNumber ? contactNumber : 'Call me'}</PrimaryBtn> */}
                </div>
            </ModalFooter>
        </>
    </Modal>
}

export default AddMoreLimit;