import React, { useEffect, useState } from 'react';
import CustomBanner from './CustomBanner';
import { sendEvents, sendGuestEvents} from "../../../tools/mpEvents";
import { constants } from '../../../tools/constants';
import { getTokenFromLocalStorage } from '../../../tools/tools';

const SmartBanner = ({isAndroid, isIOS, setShowBanner }) => {

  const token = getTokenFromLocalStorage()

  const androidDeepLink= process.env.REACT_APP_ANDROID_DEEP_LINK 

  const iosDeepLink= process.env.REACT_APP_IOS_DEEP_LINK

  useEffect(() => {
    setTimeout(hideChat, constants.hideChatTimer);  
    if (localStorage.getItem('bannerClosed').toLowerCase() === "true") {
      setShowBanner(false);
    }


    sendEvents(
      {
          event_name: `ma_view_smartbanner`,
          event_properties: {
          }
      }, token
  );
  }, []);

  const handleOpenAppClick = () => {
    if (isAndroid) {
      window.location.href = androidDeepLink ;
    } else if (isIOS) {
      window.location.href = iosDeepLink ;
    }
    setShowBanner(false);
    localStorage.setItem('bannerClosed', 'true');

    sendEvents(
      {
          event_name: `ma_click_smartbanner`,
          event_properties: {
            cta:'Open App'
          }
      }, token
  );

  }; 
  const handleDismissButtonClick = () => {
    setShowBanner(false);
    localStorage.setItem('bannerClosed', 'true'); 
    sendEvents(
      {
          event_name: `ma_click_smartbanner`,
          event_properties: {
            cta:'Not now'
          }
      }, token
  );
  };

  const hideChat = () => {
    if( document.querySelector(".intercom-launcher") != null ){
        document.querySelector(".intercom-launcher").remove();
    }
  };

  return (
    <CustomBanner
    onDismissButtonClick={handleDismissButtonClick}
    onOpenAppButtonClick={handleOpenAppClick}
    />
  );
};

export default SmartBanner;
