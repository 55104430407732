import React from 'react'
import { isFreeAddonSignle } from '../../../../../tools/tools'
import { useState } from 'react';
import { AddonImg, FreeAddonModalCard, FreeAddonModalContainer, ProductIcon } from '../../../../../styles/dashboard/Main';
import { constants } from '../../../../../tools/constants';
import { useTranslation } from 'react-i18next';

const FreeAddonModalInfo = ({addOnData,slug,interval,modalSource=null,isUpgraded}) => {
  const addonsInfo = [
    {
      type: 'purekeep',
      heading: 'securitysuite_secure_password',
      desc: 'upgrade_purekeep_desc'
    },
    {
      type: 'pureprivacy',
      heading: 'upgrade_purescan_heading',
      desc: 'upgrade_purescan_desc'
    },
    {
      type: 'purecrypt',
      heading: 'upgrade_purecrypt_heading',
      desc: 'upgrade_purecrypt_desc'
    }
  ]
  const { t } = useTranslation();
  const [intervalDesc] = useState(interval ? addOnData[slug]?.postdesc[interval]: addOnData[slug]?.postdesc[0])
  const isPureKeepViaChangePsw = () => {
    return modalSource === constants.isChangePsw ? 
    <>
      <AddonImg dashboardView addonName="purekeep"/>
      <h1>Never forget your passwords again!</h1>
      <p>{t(addOnData[constants.isChangePsw]?.postdesc)}</p>
    </>
    :   
    <>
      <img className='img-fluid bannerHead'src={addOnData[slug]?.image}/>
      <h1>Try {addOnData[slug]?.name ?? "Add-on"} <br/> free for 30 days</h1>
      <p>{t(addOnData[slug]?.postdesc)}</p>
    </>
  }
  return (
    isFreeAddonSignle(slug) ? 
      isPureKeepViaChangePsw()
    :
    <>
        <h1 className='mb-4' style={{ "font-size": "28px"}}>{isUpgraded ? t('free_trial_heading') : "You're just one step away!"}</h1>
        <p className='mt-3 mb-5' dangerouslySetInnerHTML={{ __html: t(intervalDesc) }} />

        <FreeAddonModalContainer>
          {
            addonsInfo.map((addon) => 
              <FreeAddonModalCard>
                <div className={addon.type}></div>
                <h1>{t(addon.heading)}</h1>
                <p>{t(addon.desc)}</p>
              </FreeAddonModalCard>
            )
          }
        </FreeAddonModalContainer>
    </>
  )
}

export default FreeAddonModalInfo