import React, { useState } from 'react'
import {  PopupBody } from '../../../../styles/dashboard/Main'
import { CancelBtn, DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { constants } from '../../../../tools/constants';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';

const NotSupported = ({backdrop, toggle, modal, modalWidth, widthCalculator, notsupportedState}) => {
    
    const [state] = useState({
        'upgrade':`We're sorry to inform you that currently you can't upgrade your plan on <strong>${notsupportedState?.gateway}</strong>, since this option is not supported.`,
        'addons':`We're sorry to inform you that currently you can't purchase addon on <strong>${notsupportedState?.gateway}</strong>, since this option is not supported.`,
        'addons_multiple':`pf_error_sorry_to_inform_option_not_available`,
        'past_due_whmcs':`notsupported_unable_to_upsell_addon`,
        'cancel':`It's sad to see you cancel. <a href="javascript:void(0)" onclick="window.Intercom('show')">Let's talk</a> and maybe there's something we can help you out with. In case you just want to proceed with your cancellation, please cancel it from your device/store settings.`,
        'whmcs_mobile_payment_gateway':`notsupported_cant_upgrade/purchase_addon`,
        'payment_method_not_support':`notsupported_respective_mobile_store`,
        'waitlist':`notsupported_waitlist`,
        'fpExpired': `notsupported_renew_to_add_family_member`,
        'signup_from_addon':`notsupported_cant_purchase_addon_option_not_available`,
        'signup_from_renew':`notsupported_cant_renew_subscription`,
        'signup_from_upgrade':`notsupported_cant_purchase_upgrade_option_not_available`,
        'past_due_manual_gateway':`Unable to up sell add-on. Your account is currently in expired state, please renew your subscription to continue.`,
        'purchased':`Addon is already purchased`,
        'trial':`<strong>${notsupportedState?.gateway}</strong> is not available during trial peiod.`,
        'puremax':`This option is exclusively accessible to our <strong>PureMax</strong> users. To proceed, please consider purchasing <strong>PureMax</strong>.`,
        'renew': `securitycart_eligible_renew`,
        "familyPlanChild": `notsupported_child`,
        "volumeLicensingChild": `notsupported_child`,
        "hasVolumeLicensing": `not_supported_manager`,
        "trial_user": `securitycart_eligible_trial`,
        "hasFamilyPlan": `not_supported_manager`,
        "reseller": `notsupported_reseller`,
    })

    const [primaryCta] = useState({
        "puremax":"Get PureMax"
    })

    const { t, i18n } = useTranslation();

    const isWaitList = notsupportedState?.type === "waitlist" ? true : false;
    const isFp = notsupportedState?.type === "fpExpired" ? true : false;
    const heading = isFp ? t('subscription_status_your_subscription_has_been_expired') : t('addon_purchasemodal_uhoh');

    const { search } = useLocation();
    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[redirected_via ? 'lg' : widthCalculator]}`, width: '100%'}} className={`modal-wrapper ${redirected_via ? 'white-bg' : null}`}>
                <ModalBody style={redirected_via ? {height: 'calc(100vh - 40px)', overflow: "scroll", textAlign: 'center'} : null}>
                    <PopupBody>
                        <div className="mod-head">
                            <h3>{isWaitList ? t('inapp_purchase_thank_you'): heading}</h3>
                            <p className="m-0" dangerouslySetInnerHTML={{ __html: t(state[notsupportedState?.type]) || "" }}></p>
                        </div>
                    </PopupBody>
                    {!redirected_via && <ModalFooter className="p-0 m-0">
                        {primaryCta?.[notsupportedState?.type] && <PrimaryBtn onClick={()=>toggle(false)}>{primaryCta?.[notsupportedState?.type]}</PrimaryBtn>}
                        <DestructBtn color="secondary" onClick={toggle}>{isWaitList ? `Okay`: `Cancel`}</DestructBtn>
                    </ModalFooter>}
                </ModalBody>
                
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, null)(withRouter(NotSupported))