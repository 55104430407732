
import React, { useState, useEffect } from 'react'
import { render } from 'react-dom'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { CardNumber, CredentialsInput, DashboardHead, InfoCard, Input, SubscriptionBox, PopupBody, OrderSummary, SubscriptionCancelWrapper, SubscriptionCancelReasons, CancelReason } from '../../../../styles/dashboard/Main'
import { DestructBtn, InsetBtn, LinkBtn, PrimaryBtn, RLink } from '../../../../styles/Generic'
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions'
import ErrorBoundary from '../../../ErrorBoundary'
import SubscriptionCancelConfirmationModal from './modals/SubscriptionCancelConfirmationModal'

import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages'

import { useParams } from "react-router";
import { sendEvents } from '../../../../tools/mpEvents'
import { useTranslation } from 'react-i18next'

const CancelationForm = ({history, subscriptions, getSubscriptionsRequest}) => {

    const { t, i18n } = useTranslation();
    const [crashed, setCrashed] = useState(false)
    const [loading, setloading] = useState(subscriptions.subscriptions ? false : true)
    const [data, setdata] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [activeReason, setActiveReason] = useState(null)
    const [activeReasonError, setActiveReasonError] = useState(false)
    const [selectedSubscription, setselectedSubscription] = useState("")
    const [modal, setModal] = useState(false);
    const [modalType, setmodalType] = useState('')
    const [otherReasonActive, setOtherReasonActive] = useState(false)
    const [otherReasonError, setOtherReasonError] = useState(false)
    const [otherReasonValue, setOtherReasonValue] = useState("")
    const queryStringData = useParams();
    const dispatch = useDispatch()
    const widthCalculator = modalType.slice(-2)
    const token = localStorage.getItem("token");
    const reasons = [
        t('cancle_subscription_vpn_does_not_work'),
        t('cancle_subscription_difficulty_in_use'),
        t('cancle_subscription_temporary_use'),
        t('cancle_subscription_cant_afford_it'),
        t('cancle_subscription_another_subscription'),
        t('cancle_subscription_others')
    ]

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const renderReasons = () => {
        return reasons.map((reason, key) => {
            return (
                <div className='col-md-4'>
                    <CancelReason key={key} data-reason-id={key} className={`reason-box text-center ${ activeReason == key ? "active" : "" }`} onClick={ (e) => { handleClickSelectReason(e) } }>
                        <div className='icon'></div>
                        <span dangerouslySetInnerHTML={{ __html: reason }} />
                    </CancelReason>
                </div>
            )
        });
    }

    const handleClickTakeBack = () => {
        history.goBack();
    }

    const handleClickSelectReason = (event) => {

        let selectedReasonId = event.target.parentNode.getAttribute("data-reason-id")

        if (selectedReasonId === null) {
            selectedReasonId = event.target.getAttribute("data-reason-id")
        }
        setActiveReason(selectedReasonId)
        setActiveReasonError(false)
        setOtherReasonError(false)

        if (reasons[selectedReasonId] === "Others") {
            setOtherReasonActive(true)
        } else {
            setOtherReasonActive(false)
            if (typeof window.Intercom != "undefined") {
                window.Intercom('show')
                sendEvents({
                    event_name:"ma_initiate_chatbot",
                    event_properties:{
                        source: window.location.pathname
                    }
                }, token)
            }
        }
    }

    const toggle = () => setModal(!modal);

    const openModal = (modal, selected="", plan="", currency="", subscriptionPlan="") => {
        setmodalType(modal)
        toggle()
    }

    const onChangeOtherReasonTextArea = (e) => {
        setOtherReasonValue(e.target.value)
        setActiveReasonError(false)
    }

    const openCancelSubscriptionModal = () => {

        if (otherReasonActive && otherReasonValue == "") {
            setOtherReasonError(true)
        } else {

            if (activeReason === null) {
                setActiveReasonError(true)
            } else {
                setActiveReasonError(false)
                setOtherReasonError(false)
                openModal('cancel-sm')
                sendEvents({
                    event_name:"ma_click_cancel_subscription",
                    event_properties:{
                        source: window.location.pathname,
                        reason: reasons[activeReason]
                    }
                }, token)
            }
        }
    }

    useEffect(() => {
        getSubscriptionsRequest(token)

        window.scrollTo(0, 0);
        
        sendEvents({
            event_name:"ma_view_cancel_subscription_form",
            event_properties:{ }
        }, token)
    }, [])

    useEffect(() => {

        const { loading: reduxLoading, subscriptions: reduxData, errors, errorType } = subscriptions;
        if(reduxData){
            setdata(reduxData.body);

            reduxData.body?.map((subs,key) => {
                if (subs.id === queryStringData.id) {
                    setselectedSubscription({
                        subscription: subs.id,
                        plan: subs.plan.id,
                        currency: subs.currency
                    })
                    setSubscription(subs)
                }
            })

            setloading(false);
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                    history.push("/login");       
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                    history.push("/login");       
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                    history.push("/login");       
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                    history.push("/login");       
                    break;  
                case "APIFAILED":
                    setCrashed(true)
                    break;     
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [subscriptions])

    if (crashed){
        return <ErrorBoundary></ErrorBoundary>
    }

    return (
        <div>
            {
                !loading
                ?
                (

                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <DashboardHead>
                                <h2>{t('cancle_subscription_heading')}</h2>
                                <p style={{maxWidth:"675px"}}>{t('cancle_subscription_para_one')}</p>
                                <p style={{maxWidth:"675px", marginTop: 0}}>{t('cancle_subscription_para_two')}</p>
                            </DashboardHead>
                            <SubscriptionCancelWrapper>
                                <h3 className='heading'>{t('cancle_subscription_help_us')}</h3>
                                <p className='para'>{t('cancle_subscription_help_us_para')}</p>
                                <SubscriptionCancelReasons>
                                    <div className='row'>
                                        {
                                            renderReasons()
                                        }

                                        {
                                            activeReasonError
                                            ?
                                            <p className='error-text'>{t('cancle_subscription_select_a_reason')}</p>
                                            : <></>
                                        }
                                    </div>        
                                </SubscriptionCancelReasons>
                                {
                                    otherReasonActive
                                    ?
                                    <>

                                        <InfoCard noflex className='other-reason-wrapper'>
                                            <p className='question-hdg fw-bold '>{t('cancle_subscription_state_what_problem')}</p>
                                            <textarea value={otherReasonValue} name="problem" onChange={(e) => onChangeOtherReasonTextArea(e) } placeholder="Please state your problem here" className="form-control" style={{"minHeight":"80px"}}/>
                                            {
                                                otherReasonError
                                                ?
                                                <p className='error-text'>{t('cancle_subscription_state_problem')}</p>
                                                : <></>
                                            }
                                        </InfoCard>
                                    </>
                                    :
                                    <></>
                                }
                                <InfoCard>
                                    <div className='warning-box'>
                                        <span className='warning-icon'></span>
                                        <p className='warning-text' dangerouslySetInnerHTML={{__html:(t('cancle_subscription_warning_text'))}}></p>
                                    </div>
                                </InfoCard>
                                <InfoCard>
                                    <div className='cancle-subs-cta'>
                                        <PrimaryBtn onClick={() => handleClickTakeBack() }>{t('cancle_subscription_take_me_back_cta')}</PrimaryBtn>
                                        <LinkBtn className='cancel-btn' onClick={e => openCancelSubscriptionModal() }>{t('subscription_cancle_subscription_cta')}</LinkBtn>
                                    </div>
                                </InfoCard>
                            </SubscriptionCancelWrapper>
                            {

                                (modalType === "cancel-sm" && modal) && <SubscriptionCancelConfirmationModal selectedSubscriptionEndDate={subscription?.end_date} selectedSubscription={selectedSubscription} selectedReason={reasons[activeReason]} otherReason={otherReasonValue} history={history} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} />

                            }
                        </div>
                    </div>
                )
                : (<div></div>)
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { subscriptions: state.subscriptionsReducer };
};

export default connect(mapStateToProps, {getSubscriptionsRequest})(CancelationForm)