export const ddos_protection = {
  en:{
      heading_1:'appmodalcontent_ddos_heading_1',
      para_1:"",
      heading_2:"",
      para_2:"",
      desc:[
        '<strong> Blocks Unwarranted Traffic </strong> With unwarranted traffic, a drop in internet speeds and lags are to be expected. But that will no longer be the case with our DDoS protected VPN.',
        '<strong> Is Vigilant at all Times </strong> Rather than having to manually activate our DDoS protected VPN each and every time, it will be active at all times. As soon as an attack is detected, our DDoS protected VPN will neutralize it immediately.',
        '<strong> Perfect for Gamers too </strong> Our DDoS protected VPN serves as an extra layer of protection for gamers, who are regularly targeted by cybercriminals and competing gamers. Rest assured; you will have a competitive and safe gaming experience.' 
      ],
      cta:"Get this Add-on",
  },
  fr:{
    heading_1:"Pourquoi ai-je besoin d'un VPN protégé contre les DDoS ?",
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      `<strong>Bloque le trafic injustifié </strong> Avec un trafic injustifié, il faut s'attendre à une baisse des vitesses Internet et des retards. Mais ce ne sera plus le cas avec notre VPN protégé par DDoS. `,
      `<strong>Est vigilant à tout moment </strong> Plutôt que d'avoir à activer manuellement notre VPN protégé par DDoS à chaque fois, il sera actif à tout moment. Dès qu'une attaque est détectée, notre VPN protégé par DDoS la neutralise immédiatement. `,
      `<strong>Parfait aussi pour les joueurs </strong> Notre VPN protégé contre les attaques DDoS sert de couche de protection supplémentaire pour les joueurs, qui sont régulièrement ciblés par les cybercriminels et les joueurs concurrents. Repos assuré; vous aurez une expérience de jeu compétitive et sûre. `,

    ],
    cta:"Obtenez ce module complémentaire",
  },
  de:{
    heading_1:'Warum brauche ich ein DDoS-geschütztes VPN?',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> Blockiert unerwünschten Datenverkehr </strong> Bei unerwünschtem Datenverkehr ist mit einem Rückgang der Internetgeschwindigkeit und Verzögerungen zu rechnen. Aber das wird mit unserem DDoS-geschützten VPN nicht mehr der Fall sein.',
      '<strong> Ist jederzeit wachsam </strong> Anstatt unser DDoS-geschütztes VPN jedes Mal manuell aktivieren zu müssen, ist es jederzeit aktiv. Sobald ein Angriff erkannt wird, neutralisiert unser DDoS-geschütztes VPN diesen sofort.',
      '<strong> Perfekt auch für Gamer </strong> Unser DDoS-geschütztes VPN dient als zusätzliche Schutzebene für Gamer, die regelmäßig von Cyberkriminellen und konkurrierenden Spielern angegriffen werden. Seien Sie versichert; Sie werden ein wettbewerbsfähiges und sicheres Spielerlebnis haben.'
    ],
    cta:"Holen Sie sich dieses Add-on",
  },
  zh:{
    heading_1:"為什麼我需要 DDoS 保護的 VPN？",
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> 阻止未經授權的流量 </strong> 對於未經授權的流量，網絡速度和延遲的下降是可以預料的。但我們的 DDoS 保護 VPN 將會保護您免受DDoS 攻擊。',
      '<strong> 時刻保持警惕 </strong> 無需每次都手動激活我們受 DDoS 保護的 VPN，它將始終處於活動狀態。一旦檢測到攻擊，我們受 DDoS 保護的 VPN 將立即將其中和。',
      '<strong> 也非常適合遊戲玩家 </strong> 我們受 DDoS 保護的 VPN 為遊戲玩家提供了額外的保護層，他們經常成為網絡犯罪分子和競爭遊戲玩家的目標。放心;您將擁有競爭激烈且安全的遊戲體驗。' 
    ],
    cta:"獲取此插件",
  },
  es:{
    heading_1:'¿Por qué necesito VPN protegido por DDOS?',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> bloquea el tráfico injustificado</strong> con tráfico injustificado, se espera una caída en las velocidades de Internet y los retrasos. Pero ese ya no será el caso con nuestra VPN protegida por DDOS.',
      '<strong> está vigilante en todo momento</strong> en lugar de tener que activar manualmente nuestra VPN protegida DDOS cada vez, estará activo en todo momento. Tan pronto como se detecta un ataque, nuestra VPN protegida DDOS lo neutralizará de inmediato.',
      '<strong> Perfecto para los jugadores también</strong> Nuestra VPN protegida por DDOS sirve como una capa adicional de protección para los jugadores, a quienes son atacados regularmente por cibercriminales y jugadores competidores. Está seguro; Tendrás una experiencia de juego competitiva y segura.' 
    ],
    cta:"Obtenga este complemento",
  },
  ru:{
    heading_1:'Зачем мне нужен DDOS, защищенный VPN?',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> блокирует необоснованный трафик</strong> с необоснованным трафиком, следует ожидать падения скоростей в Интернете и лагов. Но это больше не будет иметь место с нашими DDO, защищенными VPN.',
      '<strong> Бдительна всегда</strong> вместо того, чтобы вручную активировать нашу DDO, защищенную VPN каждый раз, он будет активен в любое время. Как только атака будет обнаружена, наш DDOS, защищенный VPN, немедленно нейтрализует его.',
      '<strong> Идеально идеально подходит для геймеров</strong> наш DDOS, защищенный VPN, служит дополнительным уровнем защиты для геймеров, которые регулярно подвергаются нападанию киберпреступников и конкурирующих геймеров. Будьте уверены; У вас будет конкурентоспособный и безопасный игровой опыт.' 
    ],
    cta:"Получите это дополнение",
  },
  tr:{
    heading_1:'Neden DDOS korumalı VPN\'ye ihtiyacım var?',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> Bloklar Garip Trafiği Bloklar</strong> Garip trafik ile internet hızlarında ve gecikmelerindeki bir düşüş beklenir. Ancak DDOS korumalı VPN\'imizde artık böyle olmayacak.',
      '<strong> Her zaman uyanıktır</strong> DDOS korumalı VPN\'imizi her seferinde manuel olarak etkinleştirmek yerine, her zaman aktif olacaktır. Bir saldırı tespit edilir edilmez, DDOS korumalı VPN\'imiz hemen nötralize edecektir.',
      '<strong> Oyuncular için de mükemmel</strong> DDOS korumalı VPN\'imiz, düzenli olarak siber suçlular ve rakip oyuncular tarafından hedeflenen oyuncular için ekstra bir koruma katmanı olarak hizmet eder. Emin olabilirsiniz; Rekabetçi ve güvenli bir oyun deneyiminiz olacak.'
    ],
    cta:"Bu eklentiyi alın",
  },
  pt:{
    heading_1: 'Por que preciso de VPN protegido por DDoS?',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> Bloqueia o tráfego injustificado</strong> com tráfego injustificado, uma queda nas velocidades e atrasos na Internet é esperada. Mas esse não será mais o caso da VPN protegida por DDoS.',
      '<strong> está vigilante o tempo todo</strong> em vez de ter que ativar manualmente nossa VPN protegida por DDoS toda e sempre, estará ativa o tempo todo. Assim que um ataque é detectado, nossa VPN protegida por DDoS neutralizá-lo imediatamente.',
      '<strong> Perfeito para os jogadores também</strong> nossa VPN protegida por DDOs serve como uma camada extra de proteção para os jogadores, que são regularmente direcionados por criminosos cibernéticos e jogadores concorrentes. Tenha certeza; Você terá uma experiência de jogo competitiva e segura.'
    ],
    cta:"Obtenha este complemento",
  },
  ja:{
    heading_1:'なぜDDOS保護されたVPNが必要なのですか？',
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> 不当なトラフィックをブロックします </strong> 不当なトラフィックでは、インターネットの速度と遅延の低下が予想されます。しかし、DDOS保護されたVPNにはもはや当てはまりません。',
      '<strong> 常に警戒しています </strong> 毎回DDOS保護されたVPNを手動でアクティブにする必要があるのではなく、常にアクティブになります。攻撃が検出されるとすぐに、DDOS保護されたVPNはすぐにそれを中和します。',
      '<strong> ゲーマーにも最適です。</strong> DDOS保護されたVPNは、サイバー犯罪者や競合するゲーマーの定期的に標的にされているゲーマーの追加の保護層として機能します。安心してください;競争力のある安全なゲーム体験ができます。' 
    ],
    cta:"このアドオンを取得します",
  },
  it:{
    heading_1:"Perché ho bisogno di VPN protetto da DDoS?",
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> Blocchi il traffico ingiustificato</strong> con traffico ingiustificato, è prevedibile un calo delle velocità e dei ritardi su Internet. Ma non sarà più il caso della nostra VPN protetta DDOS.',
      '<strong> è sempre vigile</strong> piuttosto che dover attivare manualmente la nostra VPN protetta DDOS ogni volta, sarà sempre attivo. Non appena viene rilevato un attacco, la nostra VPN protetta DDOS lo neutralizzerà immediatamente.',
      '<strong> Perfetto anche per i giocatori</strong> La nostra VPN protetta DDOS funge da ulteriore livello di protezione per i giocatori, che sono regolarmente presi di mira da criminali informatici e giocatori in competizione. Stia tranquillo; Avrai un\'esperienza di gioco competitiva e sicura.',
    ],
    cta:"Ottieni questo componente aggiuntivo",
  },
  nl:{
    heading_1:"Waarom heb ik DDOS-beschermde VPN nodig?",
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> Blokkeert ongerechtvaardigd verkeer</strong> met ongerechtvaardigd verkeer is een daling van internetsnelheden en vertragingen te verwachten. Maar dat zal niet langer het geval zijn met onze DDOS-beschermde VPN.',
      '<strong> is te allen tijde waakzaam</strong> in plaats van onze DDoS-beschermde VPN elke keer handmatig te activeren, zal het te allen tijde actief zijn. Zodra een aanval wordt gedetecteerd, zal onze DDoS-beschermde VPN deze onmiddellijk neutraliseren.',
      '<strong> Perfect voor gamers ook</strong> Onze DDoS-beschermde VPN dient als een extra beschermingslaag voor gamers, die regelmatig het doelwit zijn van cybercriminelen en concurrerende gamers. Wees gerust; Je hebt een competitieve en veilige gaming -ervaring.'
    ],
    cta:"Koop deze add-on",
  },
  ko:{
    heading_1:"DDOS 보호 VPN이 필요한 이유는 무엇입니까?",
    para_1:"",
    heading_2:"",
    para_2:"",
    desc:[
      '<strong> 보증되지 않은 트래픽 차단</strong> 부당한 트래픽으로 인터넷 속도와 지연이 감소 할 것으로 예상됩니다. 그러나 더 이상 DDOS 보호 VPN의 경우에는 해당되지 않습니다.',
      '<strong> 항상 경계합니다</strong> 매번 DDOS 보호 VPN을 수동으로 활성화하지 않고 항상 활성화됩니다. 공격이 감지 되 자마자 DDOS Protected VPN은 즉시 중화합니다.',
      '<strong> 게이머에게도 적합합니다</strong> 우리의 DDOS Protected VPN은 사이버 범죄자와 경쟁 게이머가 정기적으로 목표로하는 게이머를위한 추가 보호 계층 역할을합니다. 안심하십시오. 경쟁력 있고 안전한 게임 경험이 있습니다.'
    ],
    cta:"이 애드온을 얻으십시오",
  },
}