import React from 'react'
import { InfoCard } from '../../../../styles/dashboard/Main'
import  Skeleton  from 'react-loading-skeleton';

const EarnRedeemLoader = () => {
  return (
    <>
        <InfoCard hasborder noflex className="mb-3">
          <Skeleton height={20} width={300} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={300} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={300} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={300} />
        </InfoCard>
        <InfoCard noflex className="pt-0">
          <Skeleton height={20} width={300} />
        </InfoCard>
    </>
  )
}

export default EarnRedeemLoader