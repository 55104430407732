import React from 'react'
import { PasswordGeneratorCTA, PswGeneratorCta, PswGeneratorIcon, PureKeepInfo, RandomGenView, RandomPswGenerator, RangeInput } from '../../../styles/dashboard/ChangePassword'
import { AddonImg } from '../../../styles/dashboard/Main'
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { sendEvents } from '../../../tools/mpEvents';
import { digitChars, lowercaseChars, specialChars, uppercaseChars } from './change_password_constants';
import { useTranslation } from 'react-i18next';

const PasswordGenerator = ({token}) => {
    const { t, i18n } = useTranslation();
    const [randomPsw,setRandomPsw] = useState('')

    const generateRandomPassword = (length) => {        
        const availableCharacters = [
            lowercaseChars,
            uppercaseChars,
            digitChars,
            specialChars
            ];
            
        let password = '';
        
        // Loop through the character sets to include at least one character from each set
        for (const avlChar of availableCharacters) {
            password += getRandomCharacter(avlChar);
        }
        
        // Generate remaining characters randomly
        while (password.length < length) {
            const selectRandomIndex = Math.floor(Math.random() * availableCharacters.length);
            const randomCategory = availableCharacters[selectRandomIndex];
            password += getRandomCharacter(randomCategory);
        }
        
        return password;
    }
      
    const getRandomCharacter = (characters) => {
        const randomIndex = Math.floor(Math.random() * characters.length);
        return characters[randomIndex];
    }      
      
    const handleRangeChange = (event) => {
        const rangeValue = event.target.value;
        setRandomPsw(generateRandomPassword(rangeValue));
    };

    const copyPsw = async (e) => {
        try {
            sendEvents({
                event_name:"ma_account_click_copy_generated_password",
                event_properties:{}
            }, token)
            await navigator.clipboard.writeText(e.target.id)
            toast.success("Copied to Clipboard!")
        } catch (error) {
            toast.success("Unable to copy!")
        }
    }

    const genRandomPsw = () => {
        setRandomPsw(generateRandomPassword(randomPsw.length))
        sendEvents({
            event_name:"ma_account_click_generate_random_password",
            event_properties:{}
        }, token)
    }

    useEffect(()=>{
        setRandomPsw(generateRandomPassword(25))
    },[])
    return (
        <RandomPswGenerator>
            <h2>{t('onboarding_purekeep_signup_generate_strong_pw')}</h2>
            <p>{t('generate_passwords_para')}</p>
            <RandomGenView>
                <div className='d-flex align-items-center'>
                    <label>{t('generate_passwords_length')}</label>
                    <RangeInput type="range" className='mx-3 rangeInput' min="8" max="52" value={randomPsw.length} onChange={handleRangeChange} step="1"/>
                    <span>{randomPsw.length}</span>
                </div>
                <span className='randomPswView'>
                    {randomPsw}
                </span>
            </RandomGenView>
            <div className='d-flex justify-content-center'>
                <PasswordGeneratorCTA className='me-2' onClick={genRandomPsw}>
                    <PswGeneratorIcon className='refresh'/>
                    {t('generate_passwords_regenerate')}
                </PasswordGeneratorCTA>
                <PasswordGeneratorCTA onClick={copyPsw} id={randomPsw}>
                    <PswGeneratorIcon className='copy'/>
                    {t('copy_text')}
                </PasswordGeneratorCTA>
            </div>
            <PureKeepInfo>
                <label>{t('generate_passwords_powered')} PureKeep</label>
            </PureKeepInfo>
            

        </RandomPswGenerator>
    )
}

export default PasswordGenerator