import FileEncryption1 from "../../../assets/security-tools/file_encryption/file1.png";
import FileEncryption2 from "../../../assets/security-tools/file_encryption/file2.png";
import FileEncryptionDownload from "../../../assets/security-tools/file_encryption/file_download.png";

import PasswordManager1 from "../../../assets/security-tools/password_manager/pm1.png";
import PasswordManager2 from "../../../assets/security-tools/password_manager/pm2.png";
import PasswordManagerDownload from "../../../assets/security-tools/password_manager/pm_download.png";

import SocialMediaPrivacy1 from "../../../assets/security-tools/spm/spm1.png";
import SocialMediaPrivacy2 from "../../../assets/security-tools/spm/spm2.png";
import SocialMediaPrivacyDownload from "../../../assets/security-tools/spm/spm_download.png";

import TrackerBlocker1 from "../../../assets/security-tools/tracker_blocker/tracker_blocker1.png";
import TrackerBlocker2 from "../../../assets/security-tools/tracker_blocker/tracker_blocker2.png";
import TrackerBlockerDownload from "../../../assets/security-tools/tracker_blocker/tracker_blocker_download.png";

import PasswordGenerator1 from "../../../assets/security-tools/password_generator/pg1.png";
import PasswordGenerator2 from "../../../assets/security-tools/password_generator/pg2.png";

import { userOs } from "../../../tools/genericMethods";

export const SecurityToolsContent = {

    security_tools_heading: 'Security Tools',
    security_tools_para: "security_tools_para",

    features : [
        {
            slug: 'remove-my-data',
            heading: "Remove My Data",
            paragraph: "feature_one_paragraph",
            app: 'purescan',
            powered_by: true
        },
        
        {
            slug: 'password_manager',
            heading: "Password Manager",
            paragraph: "feature_five_paragraph",
            app: 'purekeep'
        },
        
        {
            slug: 'password-generator',
            heading: "Password Generator",
            paragraph: "feature_two_paragraph",
            app: 'purekeep',
            powered_by: true
        },
        
        {
            slug: 'tracker-blocker',
            heading: "Tracker Blocker",
            paragraph: "feature_three_paragraph",
            app: 'purescan'
        },
        
        {
            slug: 'social-privacy-manager',
            heading: "Social Privacy Manager",
            paragraph: "feature_four_paragraph",
            app: 'purescan'
        },
        
        {
            slug: 'file-encryption',
            heading: "File Encryption",
            paragraph: "feature_six_paragraph",
            app: 'purecrypt'
        }
    ]
    
}

export const featureContent = {
    'remove-my-data': {
        slug: 'remove-my-data',
        heading: "remove_my_data_heading",
        paragraph: "remove_my_data_paragraph",
        purchase_btn: "remove_my_data_purchase_btn",
        product: 'purescan',
        pre_purchase: {
            heading: 'remove_my_data_pre_purchase',
            list: {
                heading: "remove_my_data_pre_purchase_helpful",
                items: [
                        {heading:'remove_my_data_pre_purchase_helpful_one_heading', description: 'remove_my_data_pre_purchase_helpful_one_description'},
                        {heading:'remove_my_data_pre_purchase_helpful_two_heading', description: 'remove_my_data_pre_purchase_helpful_two_description'},
                        {heading:'remove_my_data_pre_purchase_helpful_three_heading', description: 'remove_my_data_pre_purchase_helpful_three_description'},
                        {heading:'remove_my_data_pre_purchase_helpful_four_heading', description: 'remove_my_data_pre_purchase_helpful_four_description'},
                    ]
            }
        },
        how_it_works: {
            heading: 'remove_my_data_how_it_works', 
            steps: [
                {
                    count: 'one',
                    content: "remove_my_data_how_it_works_one"
                },
                {
                    count: 'two',
                    content: "remove_my_data_how_it_works_two"
                },
                {
                    count: 'three',
                    content: "remove_my_data_how_it_works_three"
                }
            ]
        },
        form: {
            step_one: {
                heading: "remove_my_data_form_step_one_heading",
                paragraph: "remove_my_data_form_step_one_paragraph",
                form_content: {
                    email: {
                        label: "email_label",
                        placeholder: "email_placeholder"
                    },
                    firstname: {
                        label: "firstname_label",
                        placeholder: "firstname_placeholder"
                    },
                    lastname: {
                        label: "lastname_label",
                        placeholder: "lastname_placeholder"
                    },
                    country: {
                        label: "country_label",
                    },
                    state: {
                        label: "state_label",
                    }
                }, 
                cta: "remove_my_data_form_step_two_cta"
            },
            step_two: {
                heading: "remove_my_data_form_step_two_heading",
                paragraph: "remove_my_data_form_step_two_paragraph",
                cta: "remove_my_data_form_step_two_cta"
            },
            stepper_info: {
                step_one: 'remove_my_data_form_stepper_info_step_one',
                step_two: 'remove_my_data_form_stepper_info_step_two',
            }
        },
        post_purchase:{
            grid: [
                {
                    slug: 'completed',
                    heading: 'post_purchase_grid_heading_one',
                    paragraph: "post_purchase_grid_paragraph_one"
                },
                {
                    slug: 'in-progress',
                    heading: 'post_purchase_grid_heading_two',
                    paragraph: "post_purchase_grid_paragraph_two"
                },
                {
                    slug: 'total',
                    heading: 'post_purchase_grid_heading_three',
                    paragraph: "post_purchase_grid_paragraph_three"
                },
                {
                    slug: 'activity',
                    heading: 'post_purchase_grid_heading_four',
                    paragraph: "post_purchase_grid_paragraph_four"
                },
            ],
            info: [
                {slug: 'in-progress', heading: "post_purchase_info_content_in_progress_heading", description: "post_purchase_info_content_in_progress"},
                {slug: 'completed', heading: "post_purchase_info_content_completed_heading", description: "post_purchase_info_content_completed"}
            ],
            risk: {
                slug: 'risk', heading: "post_purchase_info_content_risk_score_heading", description: "post_purchase_info_content_risk_score"
            }, 
            header: ['broker_heading', 'risk_score_heading', 'type_heading', 'status_heading']
        }
    },
    'password-generator': {
        slug: 'password-generator',
        heading: "password_generator_heading",
        paragraph: "password_generator_paragraph",
        purchase_btn: "password_generator_purchase_btn",
        product: 'purekeep',
        features: [
            {
                heading: "password_generator_feature_one_heading",
                paragraph: "password_generator_feature_one_paragraph",  
                align: 'right',
                image: PasswordGenerator1
            },
            {
                heading: "password_generator_feature_two_heading",
                paragraph: "password_generator_feature_two_paragraph",
                align: 'left',
                image: PasswordGenerator2
            },
        ],
    },
    'tracker-blocker': {
        slug: 'tracker-blocker',
        heading: "tracker_blocker_heading",
        paragraph: "tracker_blocker_paragraph",
        purchase_btn: "tracker_blocker_purchase_btn",
        product: 'purescan',
        features: [
            {
                heading: "tracker_blocker_feature_one_heading",
                paragraph: "tracker_blocker_feature_one_paragraph",  
                align: 'right',
                image: TrackerBlocker1
            },
            {
                heading: "tracker_blocker_feature_two_heading",
                paragraph: "tracker_blocker_feature_two_paragraph",
                align: 'left',
                image: TrackerBlocker2
            },
        ],
        how_it_works: {
            heading: 'tracker_blocker_how_it_works', 
            steps: [
                {
                    count: 'one',
                    content: "tracker_blocker_how_it_works_one"
                },
                {
                    count: 'two',
                    content: "tracker_blocker_how_it_works_two"
                },
                {
                    count: 'three',
                    content: "tracker_blocker_how_it_works_three"
                }
            ]
        },
        download: {
            image: TrackerBlockerDownload,
            deeplink_url: process.env.REACT_APP_PUREPRIVACY_DEEPPLINK,
            heading: "tracker_blocker_download_heading",
            description: [
                'tracker_blocker_download_1', 'tracker_blocker_download_2'
            ],
            content: "tracker_blocker_download_qr",
            store_links: {
                appstore_link: "http://www.purevpn.com/applinks/pureprivacy/download-app/store/apple",
                playstore_link: "https://play.google.com/store/apps/details?id=com.purevpn.pureprivacy&hl=en&gl=US"
            }
        }
    },
    'social-privacy-manager': {
        slug: 'social-privacy-manager',
        heading: "social_privacy_manager_heading",
        paragraph: "social_privacy_manager_paragraph",
        purchase_btn: "social_privacy_manager_purchase_btn",
        product: 'purescan',
        features: [
            {
                heading: "social_privacy_manager_feature_one_heading",
                paragraph: "social_privacy_manager_feature_one_paragraph",  
                align: 'right',
                image: SocialMediaPrivacy1
            },
            {
                heading: "social_privacy_manager_feature_two_heading",
                paragraph: "social_privacy_manager_feature_two_paragraph",
                align: 'left',
                image: SocialMediaPrivacy2
            },
        ],
        how_it_works: {
            heading: 'social_privacy_manager_how_it_works', 
            steps: [
                {
                    count: 'one',
                    content: "social_privacy_manager_how_it_works_one"
                },
                {
                    count: 'two',
                    content: "social_privacy_manager_how_it_works_two"
                },
                {
                    count: 'three',
                    content: "social_privacy_manager_how_it_works_three"
                }
            ]
        },
        download: {
            image: SocialMediaPrivacyDownload,
            deeplink_url: process.env.REACT_APP_PUREPRIVACY_DEEPPLINK,
            heading: "social_privacy_manager_download_heading",
            description: [
                'social_privacy_manager_download_1', 'social_privacy_manager_download_2'
            ],            
            content: "social_privacy_manager_download_qr",
            store_links: {
                appstore_link: "http://www.purevpn.com/applinks/pureprivacy/download-app/store/apple",
                playstore_link: "https://play.google.com/store/apps/details?id=com.purevpn.pureprivacy&hl=en&gl=US"
            }
        }
    },
    'password_manager': {
        slug: 'password_manager',
        heading: "password_manager_heading",
        paragraph: "password_manager_paragraph",
        purchase_btn: "password_manager_purchase_btn",
        product: 'purekeep',
        features: [
            {
                heading: "password_manager_feature_one_heading",
                paragraph: "password_manager_feature_one_paragraph",  
                align: 'right',
                image: PasswordManager1
            },
            {
                heading: "password_manager_feature_two_heading",
                paragraph: "password_manager_feature_two_paragraph",
                align: 'left',
                image: PasswordManager2
            },
        ],
        how_it_works: {
            heading: 'password_manager_how_it_works', 
            steps: [
                {
                    count: 'one',
                    content: "password_manager_how_it_works_one"
                },
                {
                    count: 'two',
                    content: "password_manager_how_it_works_two"
                },
                {
                    count: 'three',
                    content: "password_manager_how_it_works_three"
                }
            ]
        },
        download: {
            image: PasswordManagerDownload,
            heading: "password_manager_download_heading",
            description: [
                'password_manager_download_1', 'password_manager_download_2', 'password_manager_download_3', 'password_manager_download_4'
            ],
            download_app: userOs(window) == 'windows' ? 'https://www.purevpn.com/applinks/download-app/purekeep-windows' : 'https://www.purevpn.com/applinks/download-app/purekeep-mac',
            web_app: 'https://keep.purevpn.com/start'
        }
    },
    'file-encryption': {
        slug: 'file-encryption',
        heading: "file_encryption_heading",
        paragraph: "file_encryption_paragraph",
        purchase_btn: "file_encryption_purchase_btn",
        product: 'purecrypt',
        features: [
            {
                heading: "file_encryption_feature_one_heading",
                paragraph: "file_encryption_feature_one_paragraph",  
                align: 'right',
                image: FileEncryption1
            },
            {
                heading: "file_encryption_feature_two_heading",
                paragraph: "file_encryption_feature_two_paragraph",
                align: 'left',
                image: FileEncryption2
            },
        ],
        how_it_works: {
            heading: 'file_encryption_how_it_works', 
            steps: [
                {
                    count: 'one',
                    content: "file_encryption_how_it_works_one"
                },
                {
                    count: 'two',
                    content: "file_encryption_how_it_works_two"
                },
                {
                    count: 'three',
                    content: "file_encryption_how_it_works_three"
                }
            ]
        },
        download: {
            image: FileEncryptionDownload,
            heading: "file_encryption_download_heading",
            description: [
                'file_encryption_download_1', 'file_encryption_download_2'
            ],
            download_app: userOs(window) == 'windows' ? 'https://www.purevpn.com/applinks/download-app/purecrypt-windows' : 'https://www.purevpn.com/applinks/download-app/purecrypt-mac'
        }
    },
}

export const SecurityToolsErrorMsg = {
    'securitycart_eligible_trial': 'You can upgrade your plan after converting to premium.',
    'not_supported_manager': 'PureMax is only available for limited users!',
    'notsupported_child': 'Unfortunately, you are ineligible to make this purchase. Only the manager has the authority to do so.',
    'not_supported_manager': 'PureMax is only available for limited users!',
    'notsupported_child': 'Unfortunately, you are ineligible to make this purchase. Only the manager has the authority to do so.',
    'securitycart_eligible_renew': 'Please consider renewing your subscription before proceeding with the purchase.',
    'notsupported_reseller' : 'Since you acquired your subscription through a reseller, you are ineligible to make this purchase.'
}