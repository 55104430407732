export const pure_ai_faqs = [
    {
        question:"pureai_faq_one_heading",
        answer:"pureai_faq_one_para",
        open:true
    },
    {
        question:"pureai_faq_two_heading",
        answer:"pureai_faq_two_para",
        open:true
    },
    {
        question:"pureai_faq_three_heading",
        answer:`pureai_faq_three_para`,
        open:true
    },
]

export const planTypeUpgradeFaqs = [
    {
        question: "securitysuite_faq_one",
        answer: "securitysuite_faq_one_para",
    },
    {
        question: "securitysuite_faq_two",
        answer: "securitysuite_faq_two_para",
    },
    {
        question: "securitysuite_faq_three",
        answer: "securitysuite_faq_three_para",
    },
    {
        question: "securitysuite_faq_four",
        answer: "securitysuite_faq_four_para",
    },
]

export const VolumeLicensingFaqs = [
    {
        question: "Will opting for a Volume Licensing subscription cancel my existing subscription?",
        answer: "No. If you are an existing customer, your current subscription will not be canceled after purchasing a Volume Licensing subscription. Instead, any remaining credit from your current subscription will be carried forward to your new Volume Licensing subscription.",
    },
    {
        question: "Is it feasible to have different renewal dates for licenses that fall under the same Volume Licensing subscription?",
        answer: "No, it is not possible. All licenses associated with a Volume Licensing subscription will share the same subscription plan and renewal date.",
    },
    {
        question: "Can I switch from a Volume Licensing subscription to a regular subscription?",
        answer: "No, downgrading from a Volume Licensing subscription to a regular subscription is not allowed.",
    },
    {
        question: "Is it possible to re-assign a license to another user?",
        answer: "Yes, you have the option to reassign a license to a different user by first deleting the previous user's assignment.",
    },
    {
        question: "What happens if I decide to cancel my Volume Licensing subscription?",
        answer: "If you choose to cancel your Volume Licensing subscription, it will remain active until the end of your current subscription cycle, but it will not be renewed afterward.",
    },
    {
        question: "I'm already an existing customer with a subscription. How much more do I need to pay for a Volume Licensing subscription?",
        answer: "The system will automatically calculate the payment required after applying any applicable discounts."
    },
    {
        question: "What if I already have a Volume Licensing subscription but want to purchase more licenses?",
        answer: "At the moment, this option isn't available, but we're working on offering it soon."
    },
    {
        question: "Can each licensed user create their own password to sign in to the PureVPN app?",
        answer: "Yes, each licensed user will have the option to create their own password for signing in to the PureVPN app."
    },
    {
        question: "Can a Family Plan user purchase Volume licensing?",
        answer: "Currently, the ability for Family Plan users to buy Volume Licensing is not accessible."
    },
]

export const teamsFaqs = [
    {
        question: "If I upgrade to Teams will my current subscription be canceled?",
        answer: "No. If you are an existing customer, your current subscription will not be canceled after purchasing a Teams subscription. Instead, any remaining credit from your current subscription will be carried forward to your new Teams subscription.",
    },
    {
        question: "How can I assign a same IP address to my entire team?",
        answer: "To assign a same IP to all your team members you’d need to upgrade to Teams and buy the Team Server add-on. Once purchased, you can then assign it to all the members.",
    },
    {
        question: "Is it feasible to have different renewal dates for licenses that fall under the same Teams subscription?",
        answer: "No, it is not possible. All licenses will share the same subscription plan and renewal date.",
    },
    {
        question: "If I bought 50 licenses, can I replace one team member with another?",
        answer: "Yes. You can remove a member from the team and add another one through the admin dashboard.",
    },
    {
        question: "What happens if I decide to cancel my Teams subscription?",
        answer: "If you choose to cancel your Teams subscription, it will remain active until the current subscription period ends, but it will not be renewed afterward.",
    },
    {
        question: "I'm already an existing customer with a PureVPN subscription. How much more do I need to pay for a Teams subscription?",
        answer: "Simply proceed to checkout and the system will automatically calculate the payment required after applying any applicable discounts.",
    },
    {
        question: "Can each licensed user create their own password to sign in to the PureVPN app?",
        answer: "Yes, each licensed user will have the option to create their own password for signing in to the PureVPN app."
    },
]








