import React,{useState} from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { BusinessCta } from '../../../../styles/dashboard/Business'
import { PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn } from '../../../../styles/Generic'
import Skeleton from 'react-loading-skeleton';
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

function TrialModal({modal,toggle,setDomeModal=()=>{}, handleGetStarted=()=>{}, handleCTAEvents = () => {}, loading}) {

  const skeleton = (html) => {
    return loading ? <Skeleton height={20} width={300} className="account-loading"/> : html
  }

  const onClick = () => {
    handleGetStarted();
    handleCTAEvents("Get Started");
  }

  const { t, i18n } = useTranslation();

  return (
    <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: `675px`, width: '100%'}} className="modal-wrapper">
      <ModalBody>
      {skeleton(<PopupBody>
            <h3>{t('business_trialmodal_heading')}</h3>
            <p>{t('business_trialmodal_para')}</p>
            <div>
              <ol>
                <li>{t('business_trialmodal_li_one')}</li>
                <li>{t('business_trialmodal_li_two')}</li>
                <li>{t('business_trialmodal_li_three')}</li>
                <li>{t('business_trialmodal_li_four')}</li>
                <li>{t('business_trialmodal_li_five')}</li>
                <li>{t('business_trialmodal_li_six')}</li>
                <li>{t('business_trialmodal_li_seven')}</li>
              </ol>
              <p>
                {t('business_trialmodal_any_question')}
              </p>
            </div>
          </PopupBody>)}
      </ModalBody>
      <ModalFooter>
          <BusinessCta color="primary" onClick={onClick} disabled={loading}>{t('get_started_text')}</BusinessCta>
          <DestructBtn color="secondary" onClick={ () => setDomeModal(false)}>{t('close_text')}</DestructBtn>
      </ModalFooter>
  </Modal>
  )
}

export default TrialModal