import { GETCOUNTRIES,LOADING,ERRORS,SUCCESS_COUNTRIES } from "./types";

export const getCountry = (payload) => {
    return {
      type: GETCOUNTRIES,
      payload
    };
  };

export const loadingType = () => {
  return{
      type:LOADING
  }
}
export const errorsType = (payload) => {
  return{
      type:ERRORS,
      payload
  }
}

export const getSuccessCountry = (payload) =>{
  return {
    type:SUCCESS_COUNTRIES,
    payload
  }
}

