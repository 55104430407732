import React, { useState } from 'react'
import {  InfoCard, PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../../tools/responseMessages';
import qs from 'qs'
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { getCurrentSubscription, poster, seti18Locale, checkLocale } from '../../../../tools/tools';
import { withRouter } from 'react-router-dom';
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions';
import { useEffect } from 'react';
import { sendEvents } from '../../../../tools/mpEvents';
import { useTranslation } from 'react-i18next';

const ResumeSubscription = ({history, backdrop, toggle, modal, modalWidth, widthCalculator, selectedSubscription, subscriptionsReducer}) => {

    const [content, setContent] = useState("Resume")

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();
    
    const currentSubscription = subscriptionsReducer.subscriptions.body.find(sub=>  sub.id === selectedSubscription)

    useEffect(() => {
        if(currentSubscription?.state === "paused"){
            setContent("Renew")
        }
    }, [])

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const token = localStorage.getItem("token")

    const pauseSubscription = async () => {
        setloading(true)
        const currentSub = getCurrentSubscription(subscriptionsReducer, selectedSubscription)
        sendEvents({
            event_name: currentSubscription?.state === "paused" ? "ma_click_renew_subscription" : "ma_click_resume_subscription",
            event_properties:{
                gateway: currentSub?.payment_gateway ?? "N/A",
                biling_cycle: currentSub?.plan?.type ?? "N/A"
            }
        }, token)
        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscription
            })
            const cancel =  await poster("subscription/resume", formdata, token)
            const {data: response} = cancel;
            if(response?.status){
                dispatch(getSubscriptionsRequest(token))
                dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                toast.success("Your subscription has been successfully resumed")
                toggle()
                sendEvents({
                    event_name:currentSubscription?.state === "paused" ? "ma_renew_subscription" : "ma_resume_subscription",
                    event_properties:{
                        billing_cycle: currentSub?.plan?.type ?? "N/A",
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: "active"                     
                    }
                }, token)
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;   
                    case "recurly_exception":
                        toast.error(response?.message)
                        break;   
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;  
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))    
                        dispatch({type:"LOGOUT"})
                               
                        break; 
                    default:
                        toast.error(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_resume_subscription",
                    event_properties:{
                        reason: response?.message,
                        billing_cycle: currentSub?.plan?.type ?? "N/A",
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: currentSub?.state ?? "N/A"            
                    }
                }, token)
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_resume_subscription",
                event_properties:{
                    reason: "API break or Server not responding",
                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                    payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                    current_status: currentSub?.state ?? "N/A"                      
                }
            }, token)
        }
        finally{
            setloading(false)
        }
    }

    const buttonText = () => {
        return !loading ? t('resume_subscription') : ( <>{t('resume_subscription')} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
                <ModalBody className="pb-0">
                    <PopupBody>
                        <div className="mod-head">
                            <h3>{t('resume_subscription')}</h3>
                            <p>{t('resume_subscription_are_you_sure')}</p>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <PrimaryBtn onClick={pauseSubscription} pointer={loading} color="primary">{buttonText()}</PrimaryBtn>
                    <DestructBtn color="secondary" onClick={toggle}>{t('cancel_text')}</DestructBtn>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, null)(withRouter(ResumeSubscription))