export const port_forwarding = {
    en:{
      heading_1:"Port Forwarding",
      para_1:'The Port Forwarding feature comes handy when you need to access an internet-connected device/service from anywhere in the world.',
        heading_2:'How does Port Forwarding benefit me?',
        para_2:"",
        desc:[
          'Let\'s say you want to access your PC, laptop or server from anywhere in the world. Our Port Forwarding add-on will allow you to do just that',
          'If you want to share access to your website/FTP or any other service then you will need our Port Forwarding add-on',
          'If you want to play an online game with your friends then you will need our Port Forwarding add-on to allow your friends to join your gaming server.',
          'If you are using PureVPN on your router then you will need the Port Forwarding add-on to allow any specific port on PureVPN IP' 
        ],
        cta:"Get this Add-on",
    },
    fr:{
      heading_1:"Redirection de port",
      para_1:"La fonction de Redirection de port est pratique lorsque vous devez accéder à un appareil/service connecté à Internet depuis n'importe où dans le monde.",
      heading_2:"Comment la redirection de port m'est-elle bénéfique ?",
      para_2:"",
      desc:[
        `Supposons que vous souhaitiez accéder à votre PC, ordinateur portable ou serveur depuis n'importe où dans le monde. Notre add-on Redirection vous permettra de faire exactement cela`,
        `Si vous souhaitez partager l'accès à votre site Web/FTP ou à tout autre service, vous aurez besoin de notre module complémentaire de Redirection de port.`,
        `Si vous souhaitez jouer à un jeu en ligne avec vos amis, vous aurez besoin de notre module complémentaire de redirection de port pour permettre à vos amis de rejoindre votre serveur de jeu.`,
        `Si vous utilisez PureVPN sur votre routeur, vous aurez besoin du module complémentaire Redirection pour autoriser n'importe quel port spécifique sur PureVPN IP`,
      ],
      cta:"Obtenez ce module complémentaire",
    },
    de:{
      heading_1:"Portweiterleitung",
      para_1:"Die Portweiterleitungsfunktion ist praktisch, wenn Sie von überall auf der Welt auf ein mit dem Internet verbundenes Gerät/Dienst zugreifen müssen.",
      heading_2:"Welche Vorteile bringt mir die Portweiterleitung?",
      para_2:"",
      desc:[
        `Angenommen, Sie möchten von überall auf der Welt auf Ihren PC, Laptop oder Server zugreifen. Unser Add-on für die Portweiterleitung ermöglicht Ihnen genau das`,
        `Wenn Sie den Zugriff auf Ihre Website/FTP oder einen anderen Dienst freigeben möchten, benötigen Sie unser Portweiterleitungs-Add-on`,
        `Wenn Sie mit Ihren Freunden ein Online-Spiel spielen möchten, benötigen Sie unser Portweiterleitungs-Add-On, damit Ihre Freunde Ihrem Gaming-Server beitreten können.`,
        `Wenn Sie PureVPN auf Ihrem Router verwenden, benötigen Sie das Portweiterleitungs-Add-On, um einen bestimmten Port auf PureVPN IP zuzulassen`,
      ],
      cta:"Holen Sie sich dieses Add-on",
    },
    zh:{
      heading_1:"連接追轉送",
      para_1:'當您需要從世界任何地方訪問網絡連接的裝置/服務時，連接追轉送功能會派上用場。',
      heading_2:'連接追轉送對我有什麼好處？',
      para_2:"",
      desc:[
        '假設您想從世界任何地方訪問您的 電腦、筆記本電腦或伺服器。我們的連接追轉送附加組件將使您能夠做到這一點',
        '如果您想共享對您的網站/FTP 或任何其他服務的訪問權限，那麼您將需要我們的連接追轉送附加元件',
        '如果您想和朋友一起玩在線遊戲，那麼您將需要我們的連接追轉送插件來允許您的朋友加入您的遊戲伺服器。',
        '如果您在路由器上使用 PureVPN，那麼您將需要連接追轉送附加組件以允許 PureVPN IP 上的任何特定端口' 
      ],
      cta:"獲取此插件",
    },
    es:{
      heading_1:"Reenvío de puertos",
      para_1: 'La función de reenvío de puertos es útil cuando necesita acceder a un dispositivo/servicio conectado a Internet desde cualquier lugar del mundo.',
      heading_2:'¿Cómo me beneficia el reenvío de puertos?',
      para_2:"",
      desc:[
        'Supongamos que desea acceder a su PC, computadora portátil o servidor desde cualquier lugar del mundo. Nuestro complemento de reenvío de puertos le permitirá hacer exactamente eso',
        'Si desea compartir acceso a su sitio web/FTP o cualquier otro servicio, necesitará nuestro complemento de reenvío de puertos',
        'Si quieres jugar un juego en línea con tus amigos, necesitarás nuestro complemento de reenvío de puertos para permitir que tus amigos se unan a tu servidor de juegos.',
        'Si está utilizando PureVPN en su enrutador, necesitará el complemento de reenvío de puerto para permitir cualquier puerto específico en PureVPN IP'
      ],
      cta:"Obtenga este complemento",
    },
    ru:{
      heading_1:"Перенаправление порта",
      para_1:'Функция пересылки порта пригодится, когда вам нужно получить доступ к устройству/сервису, подключенному к Интернету, из любой точки мира.',
      heading_2:'Как меня приносит пользу?',
      para_2:"",
      desc:[
        'Допустим, вы хотите получить доступ к своему компьютеру, ноутбуку или серверу из любой точки мира. Наше дополнение пересылки порта позволит вам сделать именно это',
        'Если вы хотите поделиться доступом к своему веб-сайту/FTP или любой другой сервисе, вам понадобится дополнение к пересылке порта',
        'Если вы хотите играть в онлайн-игру со своими друзьями, вам понадобится надстройка для пересылки порта, чтобы позволить друзьям присоединиться к вашему игровому серверу.',
        'Если вы используете PureVPN на своем маршрутизаторе, вам понадобится надстройка для пересылки порта, чтобы разрешить какой-либо конкретный порт на IP PureVPN'
      ],
      cta:"Получите это дополнение",
    },
    tr:{
      heading_1:"Bağlantı noktası yönlendirme",
      para_1:'Port yönlendirme özelliği, dünyanın herhangi bir yerinden internete bağlı bir cihaza/hizmete erişmeniz gerektiğinde kullanışlıdır.',
      heading_2:'Liman yönlendirme bana nasıl fayda sağlıyor?',
      para_2:"",
      desc:[

        'Diyelim ki dünyanın herhangi bir yerinden PC\'nize, dizüstü bilgisayarınıza veya sunucunuza erişmek istiyorsunuz. Port yönlendirme eklentimiz, tam da bunu yapmanıza izin verecektir.',
        'Web sitenize/FTP\'nize veya başka bir hizmete erişimi paylaşmak istiyorsanız, bağlantı noktası yönlendirme eklentimize ihtiyacınız olacaktır.',
        'Arkadaşlarınızla çevrimiçi bir oyun oynamak istiyorsanız, arkadaşlarınızın oyun sunucunuza katılmasına izin vermek için bağlantı noktası yönlendirme eklentimize ihtiyacınız olacaktır.',
        'Yönlendiricinizde PureVPN kullanıyorsanız, PureVPN IP\'de belirli bir bağlantı noktasına izin vermek için bağlantı noktası yönlendirme eklentisine ihtiyacınız olacaktır.'
      ],
      cta:"Bu eklentiyi alın",
    },
    pt:{
      heading_1:"Encaminhamento de porta",
      para_1: 'O recurso de encaminhamento de portas é útil quando você precisa acessar um dispositivo/serviço conectado à Internet de qualquer lugar do mundo.',
      heading_2:'Como o encaminhamento de portas me beneficia?',
      para_2:"",
      desc:[
        'Digamos que você queira acessar seu PC, laptop ou servidor de qualquer lugar do mundo. Nosso complemento de encaminhamento de portas permitirá que você faça exatamente isso',
        'Se você deseja compartilhar acesso ao seu site/FTP ou qualquer outro serviço, precisará do nosso complemento de encaminhamento de porta',
        'Se você deseja jogar um jogo on-line com seus amigos, precisará do nosso complemento de encaminhamento de porta para permitir que seus amigos se juntem ao seu servidor de jogos.',
        'Se você estiver usando o PureVPN no seu roteador, precisará do complemento de encaminhamento da porta para permitir qualquer porta específica no PureVPN IP'
      ],
      cta:"Obtenha este complemento",
    },
    ja:{
      heading_1:"ポート転送",
      para_1:'ポート転送機能は、世界中のどこからでもインターネットに接続されたデバイス/サービスにアクセスする必要がある場合に便利です。',
      heading_2:'ポート転送は私にどのように利益をもたらしますか？',
      para_2:"",
      desc:[
        '世界中のどこからでもPC、ラップトップ、サーバーにアクセスしたいとしましょう。私たちのポート転送アドオンを使用すると、まさにそれを行うことができます',
        'ウェブサイト/FTPまたはその他のサービスへのアクセスを共有したい場合は、ポート転送アドオンが必要になります',
        '友達とオンラインゲームをプレイしたい場合は、友達がゲームサーバーに参加できるようにするために、ポート転送アドオンが必要になります。',
        'ルーターでPureVPNを使用している場合は、PureVPN IPで特定のポートを許可するためにポート転送アドオンが必要になります' 
      ],
      cta:"このアドオンを取得します",
    },
    it:{
      heading_1:"Port forwarding",
      para_1:'La funzione di inoltro di porte è utile quando è necessario accedere a un dispositivo/servizio connesso da Internet da qualsiasi parte del mondo.',
      heading_2:'Come mi avvantaggia l\'inoltro a porte?',
      para_2:"",
      desc:[
        'Supponiamo che tu voglia accedere al tuo PC, laptop o server da qualsiasi parte del mondo. Il nostro componente aggiuntivo per inoltrare a porte ti permetterà di fare proprio questo',
        'Se si desidera condividere l\'accesso al tuo sito Web/FTP o a qualsiasi altro servizio, avrai bisogno del nostro componente aggiuntivo di inoltro di porte',
        'Se vuoi giocare a un gioco online con i tuoi amici, avrai bisogno del nostro componente aggiuntivo per consentire ai tuoi amici di unirsi al tuo server di gioco.',
        'Se stai utilizzando PUREVPN sul tuo router, avrai bisogno del componente aggiuntivo per inoltrare la porta per consentire qualsiasi porta specifica su PUREVPN IP'
      ],
      cta:"Ottieni questo componente aggiuntivo",
    },
    nl:{
      heading_1:"Port forwarding",
      para_1:'De functie Port forwarding is handig wanneer u overal ter wereld toegang hebt tot een apparaat/service op internetverbinding.',
      heading_2:'Hoe komt het doorsturen van haven mij ten goede?',
      para_2:"",
      desc:[
        'Stel dat u overal ter wereld toegang wilt krijgen tot uw pc, laptop of server. Met onze add-on voor de poortstuur kunt u precies dat doen',
        'Als u toegang wilt delen tot uw website/FTP of een andere service, heeft u onze add-on voor het doorsturen nodig',
        'Als je een online game met je vrienden wilt spelen, heb je onze add-on voor het doorsturen nodig om je vrienden toe te staan ​​lid te worden van je gaming-server.',
        'Als u PureVPN op uw router gebruikt, heeft u de add-on van de poort doorsturen nodig om een ​​specifieke poort op PureVPN IP toe te staan'
      ],
      cta:"Koop deze add-on",
    },
    ko:{
      heading_1:"포트 전달",
      para_1:'포트 전달 기능은 전 세계 어디에서나 인터넷 연결 장치/서비스에 액세스해야 할 때 편리합니다.',
      heading_2:'포트 전달은 나에게 어떤 도움이됩니까?',
      para_2:"",
      desc:[
        '전 세계 어디에서나 PC, 노트북 또는 서버에 액세스하고 싶다고 가정 해 봅시다. 우리의 포트 전달 애드온',
        '웹 사이트/FTP 또는 기타 서비스에 대한 액세스를 공유하려면 포트 전달 애드온이 필요합니다.',
        '친구들과 온라인 게임을하려면 친구가 게임 서버에 가입 할 수 있도록 포트 전달 애드온이 필요합니다.',
        '라우터에서 PureVPN을 사용하는 경우 PureVPN IP의 특정 포트를 허용하려면 포트 전달 애드온이 필요합니다.' 
      ],
      cta:"이 애드온을 얻으십시오",
    },
  }