import { toTitleCase } from '../../../../tools/tools';
import { customStyles } from '../table/tableCustomStyles';
import { FlagIcon } from '../../../../styles/flags/Flag';
import { PrimaryBtn } from '../../../../styles/Generic';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ErrorBoundary from '../../../ErrorBoundary';
import TableLoader from '../table/tableLoader';

const ServersTable = ({ openAddMoreModal, totalRows, teamServers, isTeamServersLoaded, teamServersErrorMsg, perPage, setPage, setPerPage, selectedSubscriptionData,fetchCountAndMembers, setFetchTeamServersAgain, openConfigureModal }) => {
    const { t } = useTranslation();
    const columns = [
        {
            name: 'hostname',
            cell: row => <div className='server-ip-cell'>
                <h5 title={row?.hostname ?? '---'}>{row?.hostname}</h5>
                {row?.hostname && <button onClick={copyHost} data-server={row?.hostname ?? '---'}></button>}
            </div> 
            ,
            grow: 4,
            minWidth: '265px',
            fixed: true
        },
        {
            name: 'IP address',
            cell: row => <div className='server-ip-cell'>
                <h5 title={row.ip}>{row.ip ?? '---'}</h5>
                {row.ip && <button onClick={copyHost} data-server={row.ip}></button>}
            </div>,
            grow: 2,
            minWidth: '170px',
        },
        {
            name: 'region',
            cell: row => <div className='server-ip-cell'>
                    <FlagIcon className='m-0'>
                        <span className={`bg-${row.country_ios2?.toLowerCase()}`}></span>
                    </FlagIcon>
                    <div className='info'>
                        <h5>{row.city}, {row.country}</h5>
                    </div>
                </div>,
            grow: 2,
            minWidth: '200px',
        },
        {
            name: 'status',
            cell: row => <span className={`pill ${row.status}`}>{toTitleCase(row.status)}</span>,
            grow: 1,
            minWidth: '130px',
        },
    ];

    const NoTeamServers = () => {
        return (
            <div className='waitlist-container'>
                <div className='waitlist-box-table team-server'>
                <span></span>
                {
                    !selectedSubscriptionData?.is_child ? 
                        <h4>{`${selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'team_server') ? 'Configure your Team Server Within Minutes' : 'You haven\'t purchased Team Server'}`}</h4>
                    : <h4>Upgrade Your Team's Connectivity: Request a Team Server Now!</h4>
                }
                {
                    !selectedSubscriptionData?.is_child ? 
                        <p>{`${selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'team_server') ? 'Configure the server to enable secure access to the shared network for your team.' : 'Get the Team Server to connect multiple devices with a single IP address simultaneously.'}`}</p>
                    : <p>Enhance your team's online experience by asking the admin to secure a Team server for seamless connectivity.</p>
                }
                {!selectedSubscriptionData?.is_child && <PrimaryBtn className="primary teams w-auto" onClick={()=>selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'team_server') ? openConfigureModal() : openAddMoreModal()}>{selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'team_server') ? 'Configure Team Server' : 'Get Team Server Now'}</PrimaryBtn>}
                </div>
            </div>
        )
    }
    const copyHost = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.getAttribute('data-server'))
            toast.success('Copied to clipboard')
        } catch (error) {
            toast.error(t('unable_to_copy_text'))       
        }
    }
    const handlePageChange = page => {
        setPage(page)
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }
    const paginationComponentOptions = {
        noRowsPerPage: true
    };

  return (
        <DataTable
          columns={columns}
          data={teamServers}
          pagination
          paginationServer
          responsive
          persistTableHead
          progressPending={!isTeamServersLoaded}
          progressComponent={<TableLoader />}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationComponentOptions={paginationComponentOptions}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[perPage]}
          customStyles={customStyles}
          noDataComponent={
                    teamServersErrorMsg ? <ErrorBoundary
                    heading={teamServersErrorMsg ?? "Oops!"}
                    className="mt-5 mb-5"
                    tglCartApi={setFetchTeamServersAgain}
                    isCartError={true}
                /> : <NoTeamServers />
            }
        />
    )
}
export default ServersTable;





