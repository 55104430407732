import { APIFAILED } from '../../../../tools/responseMessages'
import { checkAccountType, dateFormat, dispatchError, get, getPaymentMethods, getTokenFromLocalStorage } from "../../../../tools/tools";
import { connect, useDispatch } from "react-redux";
import { featureContent } from "../../content";
import { FeatureIntroStyles, RMDFormStyles } from "../../../../styles/dashboard/security-tools/securityTools";
import { Form, FormGroup, Label, Input, Row, Col, FormFeedback } from "reactstrap";
import { powerOfAttorney } from "../../content/poa"
import { PrimaryBtn } from "../../../../styles/Generic";
import { sendEvents } from '../../../../tools/mpEvents';
import { toast } from 'react-toastify'
import { useEffect } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Info from '../../../dashboard/plan-type-upgrade/modals/info';

const InfoForm = ({slug, type, proceedToConsent, proceedOnConsentFail, userDetails, selectedSubscription, btnloading, setCrashed}) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const token = getTokenFromLocalStorage();

    const [loading, setLoading] = useState(true);

    const [countriesList, setCountriesList] = useState();

    const [consentChecked, setConsentChecked] = useState();

    const [firstnameInput, setFirstnameInput] = useState({
        value: userDetails ? userDetails?.firstName : '',
        field: '',
        message: '',
        show: userDetails?.firstName ? false : true
    });

    const [lastnameInput, setLastnameInput] = useState({
        value: userDetails ? userDetails?.lastName : '',
        field: '',
        message: '',
        show: userDetails?.lastName ? false : true
    });

    const [emailInput, setEmailInput] = useState({
        value: selectedSubscription?.account?.email ?? userDetails.email,
        field: '',
        message: '',
        show: false
    });

    const [countriesInput, setCountriesInput] = useState({
        id: userDetails?.countryDetail?.id ?? 33,
        name: userDetails?.countryDetail?.country ?? 'United States',
        state: userDetails?.countryDetail ? userDetails?.countryDetail?.state : 'New Hampshire'
    });

    const [infoModal, setInfoModal] = useState({
        open: false,
        content: ''
    });

    const [modalWidth] = useState({
        lg: "737px",
        sm: "440px"
    });

    const widthCalculator = "lg";
        
    useEffect(()=>{
        sendEvents({
            event_name: "ma_initiate_rmd",
            event_properties: {
                account_type: checkAccountType(selectedSubscription) ?? 'N/A',
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle : selectedSubscription?.type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                current_status: selectedSubscription?.state ?? 'N/A',
            },
        }, token);
        getCountriesRequest();
    }, []);

    const getCountriesRequest = async () => {
        try{
            const {data: response} = await get("integrated-experience/countries", token)

            if(response?.status){
                setCountriesList(response?.body?.countries);
                setCountriesInput({
                    id: userDetails?.countryDetail?.id ?? response?.body?.countries?.['US']?.states?.[0]?.id,
                    name: userDetails?.countryDetail?.country ?? 'United States',
                    state: userDetails?.countryDetail?.state ?? response?.body?.countries?.['US']?.states?.[0]?.state
                })
            }
            else{
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code);
                
            }
        }
        catch{
            toast.error(t(APIFAILED));
            throwErrorBoundary(APIFAILED)
            
        }
        finally{
            setLoading(false)
        }
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED){
            setCrashed(true)
        }
    }
    
    const setEmail = (e) => {
        if(!e.target.validity.valid){
            setEmailInput({
                value: e.target.value,
                field: 'email',
                message: e.target.validity.valueMissing ? t('empty_field_error') : t('email_field_error'),
                show: true
            })
        }
        else{
            setEmailInput({
                value: e.target.value,
                field: 'email',
                message: '',
                show: false
            })
        }
    }

    const setFirstName = (e) => {
        
        if(!e.target.validity.valid){
            setFirstnameInput({
                value: e.target.value,
                field: 'firstname',
                message: e.target.validity.valueMissing ? t('empty_field_error') : t('firstname_field_error'),
                show: true
            })
        }
        else{
            setFirstnameInput({
                value: e.target.value,
                field: 'firstname',
                message: '',
                show: false
            })
        }
    }

    const setLastName = (e) => {

        if(!e.target.validity.valid){
            setLastnameInput({
                value: e.target.value,
                field: 'lastname',
                message: e.target.validity.valueMissing ? t('empty_field_error') : t('lastname_field_error'),
                show: true
            })
        }
        else{
            setLastnameInput({
                value: e.target.value,
                field: 'lastname',
                message: '',
                show: false
            })
        }
    }

    const setCountry = (e) => {

        setCountriesInput({
            ...countriesInput,
            id: e.target.value,
            name: e.target[e.target.selectedIndex]?.text,
            state: countriesList?.['US']?.states?.[0]?.state
        })
    }

    const setStates = (e) => {
        setCountriesInput({
            ...countriesInput,
            id: e.target.value,
            state: e.target[e.target.selectedIndex]?.text
        })

    }

    const onSubmitInfo = (e) => {

        e.preventDefault();

        if(!(firstnameInput.show || lastnameInput.show || emailInput.show) && !btnloading && consentChecked){
            proceedToConsent({
                firstname:firstnameInput.value,
                lastname:lastnameInput.value,
                email:emailInput.value,
                country: countriesInput?.id,
                consent: consentChecked
            })
        }
        
    }

    const submitInfoOnFail = (e) => {

        e.preventDefault();

        if(!btnloading && consentChecked){
            proceedOnConsentFail(consentChecked)
        }
    }

    const showAttorney = () => {
        sendEvents({
            event_name: "ma_click_view_rmd_consent",
            event_properties: {
                email: emailInput.value ?? 'N/A',
            },
        }, token);
        toggleInfoModal('attorney');
    }

    const toggleInfoModal = (title) => {
        title ? 
            setInfoModal({open: true, content: 
               <div className='form-for-consent'>
                    <div className="form-content">
                        {
                            Object.keys(powerOfAttorney).map((content)=>{
                                return <>
                                    {powerOfAttorney[content].type == 'h' && <h4>{dateFormat(new Date())}</h4>}
                                    {powerOfAttorney[content].type == 'p' && <p>{t(powerOfAttorney[content].content, {'name': firstnameInput.value.length ? `${firstnameInput.value} ${lastnameInput.value}` : t('poa_full_name'), 'email': emailInput.value.length ? emailInput.value : t('poa_email_address'), 'address': countriesInput?.name.length ? `${countriesInput?.name === 'United States' ? `${countriesInput.state} ,` : ''}  ${countriesInput?.name}` : t('poa_address')})}</p>}
                                    {powerOfAttorney[content].type == 'ol' && <ol><span className={powerOfAttorney[content].containerStyle ? 'inner-list' : null}>{t(powerOfAttorney[content].number)}.</span>{t(powerOfAttorney[content].content)}</ol>}
                                </>
                            })
                        }
                    </div>
               </div>
            }) : 
        setInfoModal({open: false, content: ''})
    }

    const continueText = btnloading ? <>{`${t(featureContent[slug]?.form?.[type]?.cta)}`}<span className="spinner-border text-light"></span></> : `${t(featureContent[slug]?.form?.[type]?.cta)}` ;

    return <>
    
    <FeatureIntroStyles>

    <h3>{t(featureContent[slug]?.form?.[type]?.heading)}</h3>
    <h6>{t(featureContent[slug]?.form?.[type]?.paragraph)}</h6>

        <RMDFormStyles>

                <div className="form">

                <Form className="form-for-info">

                    <Row>
                        <FormGroup>
                            <Label className="label" for={'email'}>{t(featureContent[slug]?.form?.[type]?.form_content?.['email']?.label)}</Label>
                            <Input defaultValue={userDetails?.address || emailInput.value} disabled={userDetails?.address} className="input" required={true} invalid={emailInput.show && emailInput.message} value={emailInput.value} id={'email'} name={'email'} placeholder={t(featureContent[slug]?.form?.[type]?.form_content?.['email']?.placeholder)} type={'email'} onChange={setEmail}/>
                            <FormFeedback>
                                {emailInput.message}
                            </FormFeedback>
                        </FormGroup>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="label" for={'firstname'}>{t(featureContent[slug]?.form?.[type]?.form_content?.['firstname']?.label)}</Label>
                                <Input defaultValue={userDetails?.firstName} disabled={userDetails?.firstName} className="input" value={firstnameInput.value} required={true} invalid={firstnameInput.show && firstnameInput.message} id={'firstname'} name={'firstname'} placeholder={t(featureContent[slug]?.form?.[type]?.form_content?.['firstname']?.placeholder)} type={'firstname'} onChange={setFirstName}/>
                                <FormFeedback>
                                    {firstnameInput.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="label" for={'lastname'}>{t(featureContent[slug]?.form?.[type]?.form_content?.['lastname']?.label)}</Label>
                                <Input defaultValue={userDetails?.lastName} disabled={userDetails?.lastName} className="input" value={lastnameInput.value} required={true} invalid={lastnameInput.show && lastnameInput.message} id={'lastname'} name={'lastname'} placeholder={t(featureContent[slug]?.form?.[type]?.form_content?.['lastname']?.placeholder)} type={'lastname'} onChange={setLastName}/>
                                <FormFeedback>
                                    {lastnameInput.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="label" for="country">{t(featureContent[slug]?.form?.[type]?.form_content?.country?.label)}</Label>
                                {loading ? <Skeleton height={48} width={300}/>  : 
                                    <Input disabled={userDetails?.countryDetail} className="form-select" id="country"name="country"type="select" defaultValue={countriesList?.['US']?.states?.[0]?.id} onChange={setCountry}>
                                        {Object.keys(countriesList).map((countries)=>{
                                            return <option id={countriesList?.[countries]?.iso2} value={countriesList?.[countries]?.states?.[0]?.id}>{userDetails?.countryDetail?.country ?? countriesList?.[countries]?.country}</option>
                                        })}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>
                        {countriesInput?.name === 'United States' && <Col md={6}>
                            <FormGroup>
                                <Label className="label" for="state">{t(featureContent[slug]?.form?.[type]?.form_content?.state?.label)}</Label>
                                {loading ? <Skeleton height={48} width={300}/>  : 
                                    <Input disabled={userDetails?.countryDetail} className="form-select" id="state"name="state"type="select" onChange={setStates}>
                                        {Object.entries(countriesList).find((country) => country?.[1]?.country === countriesInput?.name)?.[1]?.states.map((states)=>{
                                            return <option value={states?.id}>{userDetails?.countryDetail?.state ?? states?.state}</option>
                                        })}
                                    </Input>
                                }
                            </FormGroup>
                        </Col>}
                    </Row>

                    <div className="warning"><span className="info"></span>{t('info_error_rmd')}</div>

                    <FormGroup check>
                        <Input type="checkbox" className="privacy-check" defaultChecked={consentChecked} checked={consentChecked} onChange={(e)=> {setConsentChecked(e.target.checked)}}/>
                        <Label check>
                            <h6>
                                <Trans i18nKey="info_consent_check">Yes, I understand this agreement and PurePrivacy has my permission to act within this Limited <span className="info_consent" onClick={showAttorney}>Power of Attorney</span>.</Trans>
                            </h6>
                        </Label>
                    </FormGroup>
                    <PrimaryBtn className="d-flex m-auto" disable={firstnameInput.show || lastnameInput.show || emailInput.show || !consentChecked || btnloading} isPrivacy={true} onClick={userDetails ? submitInfoOnFail : onSubmitInfo}>{continueText}</PrimaryBtn>
                
                </Form>

                </div>

        </RMDFormStyles>

    </FeatureIntroStyles>

    {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={infoModal.open} toggle={toggleInfoModal} className='consent'/>}

    </>
}

const mapStateToProps = (state) => {
    return { 
        removeMyDataReducer: state.removeMyDataReducer
    };
};

export default connect(mapStateToProps , {})(InfoForm);