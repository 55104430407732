import styled, { css, keyframes } from "styled-components";
import collapse from '../../assets/collapse.png'
import familyFeatures from "../../assets/sprite_for_family.png"
import familyBannerBg from "../../assets/family_plan_bg.png"
import familyBannerAvatar from "../../assets/family_plan_avatar.png"
import familyPlans from "../../assets/fp_plans.png"
import check from "../../assets/check_purple.png"
import fpBg from "../../assets/fp_bg.png"
export const FamilyPlanHead = styled.div`
    margin-bottom: 45px;

    p{
        font-size:${({theme:{typography}}) => typography.smallBody};
        margin:10px 0 0;
    }

    /* Mobile Devices */  
    @media only screen and (max-width: 600px) and (min-width: 270px) {

        /* Dashboard Head */
        margin: 5px 0px 15px 0px;

        h1,
        h2,
        h3 {
            font-size: 16px !important;
        }

        p {
            font-size: 12px !important;
            line-height: 160%;
        }
    }

    @media only screen and (max-width: 992px) and (min-width: 601px) {

        margin-bottom: 30px !important;
        
    }
`

export const FamilyPlanWrapper = styled.div`

    // max-width: 1140px;
    width: auto;
    margin: auto;
    margin-top: 60px;

    .family_plan_header{
        margin-bottom: 25px;
        
        h2{
            font-weight: 700;
            font-size: 36px;
            line-height: 49px;
            text-align: center;
            color: #001219;
            width: auto;
            max-width: 910px;
            margin: auto;
            margin-bottom: 10px;

            @media only screen and (max-width: 1280px) {
                font-size: 28px;
                line-height:36px;
            }

            @media only screen and (max-width: 600px) {
                font-size: 20px;
                line-height:24px;
            }
        }

        p{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #637381;
            max-width: 910px;
            margin: auto;

            @media only screen and (max-width: 1280px) {
                font-size: 14px;
            }

            @media only screen and (max-width: 600px) {
                font-size: 12px;
            }
        }
    }

    .family_plan_imp{

        .family-plan-points{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
            max-width: 1146px;
            margin: auto;

            @media only screen and (max-width: 640px) {
                    
                display: block;
            }

            figure{
                margin-bottom: 0;

                img{
                    width: 487px;
                    height: 449px;
                    object-fit: contain;
                }

                @media only screen and (max-width: 1280px) {
                    
                    img{
                        width: 300px;
                        height: 300px;
                    }
                }
                @media only screen and (max-width: 750px) {
                    
                    img{
                        width: 200px;
                        height: 200px;
                    }
                }

                @media only screen and (max-width: 640px) {
                    
                    img{
                        display: none;
                    }
                }
            }
        }
        h4{
            font-weight: 600 !important;
            font-size: 18px !important;
            line-height: 22px;
            color: #001219;
            margin: 0 !important;
            text-transform: none;

            @media only screen and (max-width: 850px) {
                font-size: 14px !important;
            }

            @media only screen and (max-width: 400px) {
                font-size: 12px !important;
            }
        }
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #637381;
            margin: 0;
            margin-top: 12px;
            letter-spacing: 0 !important;

            @media only screen and (max-width: 700px) {
                font-size: 12px !important;
              }
        }

        article{
            @media only screen and (max-width: 640px) {
                    
                width: 100%;
            }
        }
    }

    .family_plan_experience{

        text-align: center;
        margin-bottom: 90px;
        
        h2{
            margin-top: 36px;
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            text-align: center;
            color: #001219;

            @media only screen and (max-width: 1280px){
                font-size: 28px;
                line-height: 36px;
            }

            @media only screen and (max-width: 600px){
                font-size: 20px;
                line-height: 24px;
            }
        }

        .experience_content{
            display: flex;
            align-items: center;
            flex-gap: 28px;
            gap: 28px;
            margin: 30px auto;
            width: max-content;

            @media only screen and (max-width: 820px){
                display: block;
            }
    
            h5{
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #001219;
                margin-bottom: 10px;
                font-weight: 600;

                @media only screen and (max-width: 1280px){
                    font-size: 16px;
                }

            }
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #637381;
                margin: 0;

                @media only screen and (max-width: 1280px){
                    font-size: 12px;
                }
            }
    
            figure{
                margin-bottom: 15px;
                text-align: center;

                @media only screen and (max-width: 1290px){
                    img{
                        width: 90px;
                        height: 90px;
                    }
                }

                @media only screen and (max-width: 970px){
                    img{
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }

        button{
            padding: 18px 91px;

            @media only screen and (max-width: 360px){
                padding: 18px 60px;
            }
        }
    }

    .family_plan_works{

        figure{
            img{
                @media only screen and (max-width: 1280px){
                    width: 400px;
                }
                @media only screen and (max-width: 1100px){
                    width: 360px;
                }
                @media only screen and (max-width: 960px){
                    width: 280px;
                }
                @media only screen and (max-width: 380px){
                    width: 240px;
                }
            }
        }
        h4{
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #000000;
            margin: 0;
            margin-bottom: 10px;
            max-width: 528px;

            @media only screen and (max-width: 1280px){
                font-size: 18px !important;
                line-height: 28px;
            }
            @media only screen and (max-width: 860px){
                font-size: 16px !important;
                line-height: 20px;
                margin-top:10px;
            }
            @media only screen and (max-width: 820px){
                max-width: inherit;
            }
        }

        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #637381;
            margin: 0;
            margin-bottom: 43px;
            max-width: 528px;

            @media only screen and (max-width: 1280px){
                font-size: 14px !important;
            }
            @media only screen and (max-width: 860px){
                font-size: 12px !important;
            }
            @media only screen and (max-width: 820px){
                max-width: inherit;
                margin-bottom: 30px;
            }
        }

        .works_btns{
            display: flex;
            align-items:center;
            gap: 10px;

            @media only screen and (max-width: 820px){
                justify-content: center;
            }

            @media only screen and (max-width: 560px){
                display: block;
            }

            .btn-grp{
                position: relative;
                width: max-content;

                @media only screen and (max-width: 560px){
                    margin: auto;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
    
            span{
                background: linear-gradient(0deg, #FFBA24, #FFBA24), linear-gradient(0deg, #F89862, #F89862), #28C675;
                border-radius: 8.45041px;
                position: absolute;
                top: -10px;
                font-weight: 700;
                font-size: 12px !important;
                line-height: 15px;
                color: #000 !important;
                padding: 6px 12px;
                left: 0;
                right: 0;
                margin: auto;
                width: max-content;

                @media only screen and (max-width: 560px){
                    font-size: 10px !important;
                    padding: 4px 10px;
                }
            }
        }

        figure{
            margin: 0;
        }
    }

    .how_it_works{
        text-align: center;
        margin-top: 120px;
        margin-bottom: 90px;

        h4{
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            text-align: center;
            color: #001219;
            margin: 0;
            margin-bottom: 20px;

            @media only screen and (max-width: 1280px){
                font-size: 28px;
                line-height: 36px;
            }
            @media only screen and (max-width: 600px){
                font-size: 20px;
                line-height: 24px;
            }
        }

        p{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #637381;
            margin: 0;
            margin-bottom: 40px;

            @media only screen and (max-width: 1280px){
                font-size: 16px;
            }
            @media only screen and (max-width: 600px){
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
    }

    .compare_plans{

        text-align: center;

        .compare-boxes{
            display: inline-flex;
            align-items: center;
            gap: 30px;

            @media only screen and (max-width: 1250px){
                display: block;
            }
        }

        .compare-box{
            text-align: left;
            background: #ffffff;
            position: relative;

            span.persons{
                background: #FFFFFF;
                border-radius: 16px;
                padding: 10px;
                position: absolute;
                top: -12px;
                right: 10px;
                figure{
                    
                    margin: 0;
                }

                @media only screen and (max-width: 1250px){
                    top: -14px;
                }
            }

            @media only screen and (max-width: 1250px){
                margin: auto;
                margin-bottom: 30px;
            }

            &.two-member-box{
                background: #6536CC;
            }

            &.five-member-box{
                background: #3B108E;
            }

            &.white{
                h3, h5{
                    color: #ffffff;
                }

                h5{
                    span{
                        color: rgba(255, 255, 255, 0.7) !important;
                    }
                }
                li{
                    span{
                        color: rgba(255, 255, 255, 0.7) !important;
                    }
                    figure{
                        svg{
                            path{
                                stroke: #ffffff;
                            }
                        }
                    }
                }
            }

            ul{
                li{
                    span{
                        font-size: 12px !important;
                        line-height: 15px !important;

                        @media only screen and (max-width: 280px){
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }

        h4{
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            text-align: center;
            color: #001219;
            margin: 0;
            margin-bottom: 44px;

            @media only screen and (max-width: 1280px){
                font-size: 28px;
                line-height: 36px;
            }

            @media only screen and (max-width: 600px){
                font-size: 20px;
                line-height: 24px;
            }
        }

        .percent{
            background: linear-gradient(0deg, #FFBA24, #FFBA24), linear-gradient(0deg, #F89862, #F89862), #28C675;
            border-radius: 8.45041px;
            font-weight: 700;
            font-size: 12px !important;
            line-height: 14px;
            color: #000 !important;
            padding: 6px 8px;
            margin:0;
            margin-left: 0;
            width: max-content;
            margin-top: 5px;
            display: inline-block;
        }

        h3{
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #001219;
            margin: 0;

            @media only screen and (max-width: 1380px){
                font-size: 20px;
            }

            @media only screen and (max-width: 280px){
                font-size: 18px;
            }
        }

        h5{
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            display: flex;
            align-items: center;
            color: #001219;
            position: relative;
            width: max-content;
            margin-top: 30px;
            margin-bottom: 0;

            @media only screen and (max-width: 1440px){
                margin-top: 10px;
            }

            @media only screen and (max-width: 1380px){
                font-size: 24px;
                margin-top: 20px;
                line-height: 28px;
            }

            @media only screen and (max-width: 360px){
                display: block;
            }

            @media only screen and (max-width: 280px){
                font-size: 16px;
                margin-top: 10px;
                line-height: 20px;
            }

            span{
                font-weight: 400;
                font-size: 11px !important;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #001219 !important;
                margin-left: 5px;
                margin-top: 10px;

                @media only screen and (max-width: 360px){
                    display: block;
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }

        h6{
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.25px;
            text-decoration-line: line-through;
            color: rgba(255, 255, 255, 0.7);
            opacity: 0.5;
            margin: 0
        }

        figure{
            margin: 0;
        }

        ul{

            padding-left: 0;
            list-style: none;
            margin-top: 30px;
            margin-bottom: 30px;
            li{
                font-weight: 400;
                font-size: 12px !important;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #78747B !important;
                display: flex;
                margin-bottom: 10px;

                figure{
                    margin: 0;
                }

                span{
                    padding-left: 10px;
                }
            }
        }
    }

    .fp_faqs{
        max-width: 1146px;
        margin: auto;

        margin-top: 90px;
        h3{
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            text-align: center;
            color: #001219;
            margin:0;
            margin-bottom: 30px;

            @media only screen and (max-width: 1280px){
                font-size: 28px;
                line-height: 36px;
            }

            @media only screen and (max-width: 600px){
                font-size: 20px !important;
                line-height: 24px;
            }
        }
        h4{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #43225B;
            margin:0;
            margin-bottom: 15px;
            @media only screen and (max-width: 600px){
                font-size: 16px;
                line-height: 20px;
            }
        }
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #7B648C;
            margin:0;
            @media only screen and (max-width: 600px){
                font-size: 12px;
            }
        }
    }

    .fp_invite{
        position: relative;
        max-width: 1145px;
        height: 309px;
        margin: auto;
        margin-bottom: 90px;
        width: 100%;

        .invite-header{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;

            figure{
                position: absolute;
                margin: 0;
                width: inherit;
                
                img{
                    border-radius: 16px;
                    width: inherit;

                    @media only screen and (max-width: 740px){
                        height: 200px;
                        object-fit: cover;
                    }
                }
            }

            article{
                position: absolute;

                @media only screen and (max-width: 360px){
                    padding: 10px 10px;
                }
            }

            h3{
                font-weight: 700;
                font-size: 33px;
                line-height: 49px;
                text-align: center;
                color: #FCFCFC;
                margin-bottom: 10px;

                @media only screen and (max-width: 1400px){
                    font-size: 28px;
                    line-height: 36px;
                }

                @media only screen and (max-width: 1260px){
                    font-size: 24px;
                    line-height: 28px;
                }

                @media only screen and (max-width: 960px){
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            p{
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FCFCFC;
                margin-bottom: 48px;
                max-width: 590px;
                @media only screen and (max-width: 1400px){
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 36px;
                }
                @media only screen and (max-width: 1260px){
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 24px;
                }
                @media only screen and (max-width: 960px){
                    font-size: 12px;
                    line-height: 18px;
                    margin-bottom: 12px;
                }
            }
            .copyLinkBox{
                border: 1px solid #DDD4E2 ;
                border-radius: 5px ;
                background: #FFFFFF ;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.5px;
                color: #43225B;
                max-width: 390px !important;
                margin: auto;
                margin-bottom: 10px;

                @media only screen and (max-width: 830px){
                    font-size: 12px;
                    line-height: 16px;

                    input{
                        padding: 12px 0 12px 10px !important;
                        height: 30px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
            .shareLinks{
                span{
                    color: #FFFFFF !important;
                    margin-bottom: 10px;

                    @media only screen and (max-width: 830px){
                        margin: 0;
                    }
                }
            }
            li{
                filter: brightness(0) invert(1); 
            }
            
        }
    }

    .fp-invites{
        h5{
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: #001219;
            width: 200px;
            margin: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;

        }
        span.manager{
            background: #2CDC82;
            border-radius: 50px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FCFCFC !important;
            width: max-content;
            margin: auto;
        }
        p.name{
            color: #43225B;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
        }
    }
    .invite-boxes{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
    }
    
    .plan_works{
        max-width: 1140px;
        margin: auto;
        text-align: center;
        width: 100%;
        h3{
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            color: #001219;

            @media only screen and (max-width: 1280px){
                font-size: 28px;
                line-height: 36px;
            }

            @media only screen and (max-width: 600px){
                font-size: 20px !important;
                line-height: 24px;
            }
        }
        p{
            margin-top: 30px;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #637381;
            margin-bottom: 45px;

            @media only screen and (max-width: 1280px){
                font-size: 16px;
            }
            @media only screen and (max-width: 600px){
                font-size: 12px;
                margin-bottom: 20px;
            }
        }

        .video-frame{
            position: relative;
            height: 549px;

            iframe{
                border-radius: 12px;
                z-index: 1;
                position: relative;
                @media only screen and (max-width: 860px){
                    height: 400px;
                }
                @media only screen and (max-width: 520px){
                    height: 320px;
                }
                @media only screen and (max-width: 380px){
                    height: 280px;
                }
            }
      
            @media only screen and (max-width: 860px){
                height: 400px;
            }
            @media only screen and (max-width: 520px){
                height: 320px;
            }
            @media only screen and (max-width: 380px){
                height: 280px;
            }
      
            ul{
              width: 100%;
              height: 100%;
              z-index: 0;
              top: 0px;
              left: 0px;
              position: absolute;
            }
        }

    }
`

export const PrePurchaseCampaignStyles = styled.div`

    .row{
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .pre-purchase-header{
        margin-top: 93px;
        margin-bottom: 23px;
        h2{
            color: #001219;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            line-height: 46px;
            margin: auto;
            margin-bottom: 27px;
            max-width: 840px;
            width: 100%;
            @media only screen and (max-width: 480px){
                font-size: 28px;
                line-height: 30px;
            }
        }
        p{
            color: #637381;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            max-width: 651px;
            margin: auto;
            width: 100%;
        }
    }
    .pre-purchase-features{
        margin-bottom: 40px;

        .features-container{
            max-width: 934px;
            width: 100%;
            margin: auto;

            .row{
                gap: 15px;
                margin-bottom: 13px;
            }
        }
        .benefit-box{
            border-radius: 10.438px;
            border: 1px solid rgba(85, 12, 203, 0.10);
            background: linear-gradient(180deg, #F8F5FF 0%, rgba(248, 245, 255, 0.40) 100%);
            height: 294px;
            padding: 0px 37px;

            @media only screen and (max-width: 410px){
                padding: 0px 10px;
            }

            &.full{
                width: 515px;
                @media only screen and (max-width: 1210px){
                    width: 515px;
                }
                @media only screen and (max-width: 960px){
                    width: 515px;
                    margin: auto;
                }
            }
            &.half{
                width: 394px;
                @media only screen and (max-width: 960px){
                    width: 515px;
                    margin: auto;
                }
            }

            span{
                height: 70px;
                width: 70px;
                text-align: center;
                position: relative;
                display: block;
                margin: auto;
                margin-top: 40px;
                margin-bottom: 36px;
                @media only screen and (max-width: 360px){
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                &:before{
                    position: absolute;
                    top: 0px;
                    background: url(${familyFeatures}) no-repeat;
                    background-size: cover;
                    height: 70px;
                    content: '';
                    width: 70px;
                    left: 0px;
                    background-position: 0px 0px;
                }
            }

            h4{
                color: #001219;
                text-align: center;
                font-size: 22px;
                font-weight: 700;
                line-height: 24.2px;
                letter-spacing: -0.44px;
                margin-bottom: 14px;
                @media only screen and (max-width: 480px){
                    font-size: 20px;
                }
            }
            p{
                color: #637381;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                max-width: 336px;
                width: 100%;
                margin: auto;
            }
        }
        button{
            margin: auto;
            margin-top: 37px;
            text-align: center;
            display: block;
            width: 250px;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
             @media only screen and (max-width: 570px){
                font-size: 14px !important;
            }
        }
    }
    .pre-purchase-banner{
        padding-top: 40px;
        background: url(${familyBannerBg}) no-repeat;
        padding-bottom: 50px;
        padding-left: 56px;
        max-width: 1112px;
        width: 100%;
        margin: auto;
        position: relative;
        margin-bottom: 186px;
        background-size: contain;
        

        @media only screen and (max-width: 850px){
            padding-top: 20px;
        }

        @media only screen and (max-width: 830px){
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
            margin-bottom: 40px;
        }

        @media only screen and (max-width: 740px){
            padding-top: 40px;
            padding-left: 10px;
            padding-right: 10px;
            background-size: cover;
            border-radius: 20px;
        }

        &:before{
            position: absolute;
            background: url(${familyBannerAvatar}) no-repeat;
            content: '';
            max-width: 557px;
            width: 100%;
            height: 100%;
            right: 17px;
            left: auto;
            bottom: -147px;
        }
        @media only screen and (max-width: 850px){
            &:before{
                display: none;
            }
        }
        h3{
            color: #FFF;
            font-size: 36px;
            font-weight: 700;
            line-height: 39.6px;
            letter-spacing: -0.72px;
            margin-bottom: 19px;
            @media only screen and (max-width: 980px){
                font-size: 30px;
            }
            @media only screen and (max-width: 830px){
                max-width: 100%;
                text-align: center
            }
            @media only screen and (max-width: 550px){
                font-size: 30px !important;
                line-height: 32px;
            }
        }
        p{
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            margin-bottom: 45px;
            max-width: 635px;
            width: 100%;
            @media only screen and (max-width: 980px){
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 830px){
                max-width: 100%;
                text-align: center
            }
        }
        button{
            background: transparent;
            border-radius: 25.756px;
            border: 2px solid #FFF;
            padding: 12px 15px;
            margin-bottom: 5px;
            width: 231px;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            @media only screen and (max-width: 830px){
                text-align: center;
                margin: auto;
            }
            @media only screen and (max-width: 570px){
                font-size: 14px !important;
            }
        }
    }
    .pre-purchase-plans{
        max-width: 1105px;
        width: 100%;
        margin: auto;
        margin-bottom: 25px;
        .row{
            gap: 20px;
            justify-content: center;
            @media only screen and (max-width: 1400px){
                gap: 5px;
            }
        }
        h5.head5{
            color: #550CCB;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.48px;
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        h2{
            color: #001219;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            max-width: 542px;
            width: 100%;
            margin: auto;
            text-align: center;
            margin-bottom: 30px;

            @media only screen and (max-width: 750px){
                text-align: center;
                max-width: 100%;
            }
            @media only screen and (max-width: 550px){
                font-size: 30px !important;
                line-height: 32px;
            }
        }
        p.head{
            color: #637381;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.4px;
            margin-bottom: 58px;
        }
        .plans-box{
            border-radius: 12px;
            border: 2px solid #EBE6FF;
            background: #FFF;
            padding: 29px 20px;
            max-width: 354px;
            width: 100%;
            position: relative;
            min-height: 572px;
            max-height: 572px;

            &.individual-plan-box{
                filter: grayscale(1);
                opacity: 0.6;
                cursor: not-allowed;
                .plans-pricing{
                    span.price, span.price-two{
                        color: #A6B2BA !important;
                    }
                }
                    button{
                    cursor: not-allowed;
                    }
            }
            
            &.hover{
                &:hover{
                    box-shadow: rgba(67, 34, 91, 0.15) 0px 0px 14px;
                }
                .plan-offering{
                    padding-bottom: 8px !important;
                }
            }

            @media only screen and (max-width: 1400px){
                max-width: 300px;
            }
            @media only screen and (max-width: 950px){
                margin-bottom: 30px;
            }

            &.selected{
                border: 2px solid var(--Color-Iris-Iris-600, #7320FF);
            }
            .value{
                position: absolute;
                height: 31px;
                width: 140px;
                background: rgb(101, 16, 241);
                top: -27px;
                content: "";
                right: 0px;
                color: rgb(255, 255, 255) !important;
                font-weight: 500;
                line-height: 13px;
                border-radius: 300px 20px 40px;
                font-size: 13px !important;
                z-index: -1;
                text-align: right;
                padding: 5px 12px 2px 7px;
            }
            .plan-offering{
                border-bottom: 1px dashed #929DA7;
                margin-bottom: 31px;
                padding-bottom: 39px;

                @media only screen and (max-width: 550px){
                    margin-bottom: 21px;
                    padding-bottom: 29px;
                }

                
                .plans-heading{
                    display: flex;
                    align-items: center;
                    margin-bottom: 9px;
                    position: relative;
                    h3{
                        color: #001219;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 22px;
                        letter-spacing: -0.44px;
                        margin: 0;
                        margin-left: 5px;
                    }
                    span.percentage{
                        border-radius: 10px;
                        background: #FFBA24;
                        color: #001219 !important;
                        text-align: center;
                        font-size: 14px !important;
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: -0.28px;
                        padding: 6px 10px;
                        float: right;
                        position: absolute;
                        content: '';
                        left: auto;
                        right: 0px;
                        top: 0px;
                    }
                    span.img{
                        width: 24px;
                        height: 24px;
                        position: relative;
                        &:before{
                            content:'';
                            top: 0px;
                            left: 0px;
                            width: 24px;
                            height: 24px;
                            background-position: 0px 0px;
                            position: absolute;
                            background: url(${familyPlans}) no-repeat;
                        }
                        &.duo{
                            &:before{
                                background-position: -24px 0px;
                            }
                        }
                        &.family{
                            &:before{
                                background-position: -48px 0px;
                            }
                        }
                    }
                }
                p{
                    color: #637381 !important;
                    font-size: 16px !important;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 43px;
                    &.ind{
                        margin-bottom: 15px;
                    }
                }
                h6{
                    color: #637381;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-decoration: line-through;
                    opacity: 0.5;
                }
                .plans-pricing{
                    display: flex;
                    align-items: start;
                    margin-bottom: 50px;
                    .price{
                        color: #001219 !important;
                        font-size: 65.49px !important;
                        font-weight: 700;
                        line-height: 45.49px;
                        letter-spacing: 1.31px;
                        margin-bottom: 0px;
                    }
                    .dollar{
                        color: #001219 !important;
                        font-size: 30.226px !important;
                        font-weight: 700;
                        line-height: 30.226px;
                        margin: 0;
                    }
                    .price-two{
                        .decimal{
                            color: #637381 !important;
                            font-size: 30.226px !important;
                            font-weight: 700;
                            line-height: 30.226px;
                            margin-bottom: 2px;
                            display: block;
                        }
                        .month{
                            color: #637381 !important;
                            font-size: 18px !important;
                            font-weight: 400;
                            line-height: 18px;
                            display: block;
                        }
                    }
                }
                .plans-btn{
                    .billed{
                        color: rgb(99, 115, 129);
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 22.4px;
                        width: 100%;
                        margin-top: 10px;
                    }
                        button{
                        display: flex;
                        width: 100%;
                        max-width: 300px;
                        height: 48px;
                        padding: 3px 44px 5px 44px;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        border-radius: 49px;
                        background: #A6B2BA;
                        border: 1px solid #A6B2BA;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 16px;

                        &.duo{
                            background: transparent;
                            color: #550CCB;
                            border: 1px solid #550CCB;
                        }
                        &.family{
                            background: #550CCB;
                            color: #fff;
                            border: 1px solid #550CCB;

                        }
                    }
                }
            }
            .plans-features{
                h5{
                    color: #001219;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 13px;
                    margin-bottom: 26px;
                }
                ul{
                    padding: 0px;
                    li{
                        color: #637381 !important;
                        font-size: 14px !important;
                        font-weight: 400;
                        line-height: 19.6px;
                        position: relative;
                        padding-left: 28px;
                        list-style: none;
                        margin-bottom: 21px;
                        &:before{
                            position: absolute;
                            content: '';
                            background: url(${check}) no-repeat;
                            width: 18px;
                            height: 18px;
                            top: 0px;
                            left: 0px;
                            
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .pre-purchase-benefits{
        padding-top: 120px;
        padding-bottom: 100px;
        max-width: 990px;
        width: 100%;
        margin: auto;

        @media only screen and (max-width: 1050px){
            padding-top: 40px;
        }
        .row{
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 41px;

            .benefits-img{
                @media only screen and (max-width: 1230px){
                    width: 400px !important;
                }
                @media only screen and (max-width: 1050px){
                    display: none;
                }
            }
            .benefits-list{
                width: 516px;
                border: 1.5px solid rgba(95, 56, 200, 0.20);
                background: #FFF;
                border-radius: 0px 30px 30px 0px;
                padding: 0;

                @media only screen and (max-width: 1305px){
                    width: 450px;
                }

                @media only screen and (max-width: 1050px){
                    width: auto;
                }
                
                .benefits-box{
                    padding: 32px 42px;
                    border-bottom: 1.5px solid rgba(95, 56, 200, 0.20);
                    cursor: pointer;

                    &:last-child{
                        border-bottom: none;
                        border-radius: 0px 0px 30px 0px;
                    }
                    &:first-child{
                        border-radius: 0px 30px 0px 0px;
                    }
                    &.open{
                        padding: 34px 42px 31px 42px;
                        border-left: 2px solid #7320FF;
                        box-shadow: 0px 26px 7px 0px rgba(0, 0, 0, 0.00), 0px 17px 7px 0px rgba(0, 0, 0, 0.01), 0px 9px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
                        h4{
                            margin-bottom: 10px;
                        }
                    }
                    &:hover{
                        box-shadow: 0px 26px 7px 0px rgba(0, 0, 0, 0.00), 0px 17px 7px 0px rgba(0, 0, 0, 0.01), 0px 9px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
                    }
                }
                h4{
                    color: #001219;
                    font-size: 18.41px;
                    font-weight: 600;
                    line-height: 18.41px;
                    margin-bottom: 0px;
                }
                p{
                    color: #637381;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    margin: 0;
                }
            }
        }
    }
    .pre-purchase-how-it-works{
        max-width: 932px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid #550CCB;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: auto;
        margin-bottom: 45px;
        background: url(${fpBg}) no-repeat;
        background-size: cover;
        h4{
            color: #001219;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 18px;
        }
        p{
            color: #637381;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 46px;
        }
        button{
            border-radius: 39px;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            width: 267px;
            @media only screen and (max-width: 570px){
                font-size: 14px !important;
            }
            @media only screen and (max-width: 320px){
                width: 240px;
            }
        }
    }
`