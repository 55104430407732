import { addonState, constants, puremaxProducts } from "../../../../tools/constants";
import { hasPureMaxAddons } from "../../../../tools/genericMethods";
import { checkIsAddonPaid, isAddon, isManualGateway, isWhmcsUser } from "../../../../tools/tools";
import { isMax } from '../../upgrade/upgrade-tools';

export const checkAddOn = (sub, type) => {
    const {add_ons ,account} = sub
    const check = Array.isArray(add_ons) ? add_ons.some((addon) => addon.code === type && checkIsAddonPaid(addon)) : false;
    return check
}

export const checkAddOnIpAndHost = (addons = [],code="") => {
    const check = Array.isArray(addons) ? addons.find((addon) => addon?.ip && addon?.host && addon?.code === code) : false;
    return check
}

export const checkState = (subscription) => {
    return (constants?.cardless_state.includes(subscription?.state) && subscription?.payment_gateway === "cardless") ? true : false
}

export const checkIsPending = (sub,slug="") => {
    //* To check if the addon is pending state or due for manual gateway users
    const addon = isAddon(sub?.add_ons,[slug,"pureprivacy"])
    return  addon && addon?.state != addonState.paid && isManualGateway(sub?.payment_gateway) && !isWhmcsUser(sub?.account?.billingType) &&
    <p className="danger mt-1">{constants.pending_msg}</p>
}

export const checkBundleEligibility = (subscription) => {
    return (!hasPureMaxAddons(subscription?.add_ons) && !subscription?.hasFamilyPlan && !subscription?.payment_gateway_offline && !isMax(subscription?.plan_type)
    && puremaxProducts?.validIntervals[subscription?.plan?.interval_length ?? 0])
}