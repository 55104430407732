import {all} from 'redux-saga/effects'
import { watchAccountRequest, watchSubscription, watchUsernameRequest } from '../account/saga'
import { watchInvoicesRequest } from '../invoices/saga'
import { watchUpgradePlansRequest } from '../upgrade/saga'
import { watchResetSubscriptionRequest, watchSubscriptionsRequest } from '../subscriptions/saga'
import { watchDeviceRequest } from '../downloads/saga'
import { watchOnBoardingRequest } from '../on-boarding/saga'
import { watchTicketsRequest, watchTicketDetailsRequest } from '../tickets/saga'
import { watchInvitesRequest } from '../refer/saga'
import { watchHelloBarRequest } from '../hello-bar/saga'
import { watchFamilyPlanRequest } from '../family-plan/saga'
import { watchRemoveMyDataRequest } from '../rmd//saga'
import { watchTeamPlanAsync } from "../pureteams/saga"
import { watchChannelRequest } from '../channels/saga'
import watcherCountrySaga from '../country/saga'
import watcherWgDeviceSaga from '../wgDevices/saga'
import watcherWalletDataSaga from '../pure-wallet/saga'

function* rootSaga(){
    yield all([
        watchAccountRequest(),
        watchSubscription(),
        watchUsernameRequest(),
        watchSubscriptionsRequest(),
        watchInvoicesRequest(),
        watchUpgradePlansRequest(),
        watchDeviceRequest(),
        watchOnBoardingRequest(),
        watchTicketsRequest(),
        watchTicketDetailsRequest(),
        watchInvitesRequest(),
        watchResetSubscriptionRequest(),
        watchHelloBarRequest(),
        watcherCountrySaga(),
        watcherWgDeviceSaga(),
        watchFamilyPlanRequest(),
        watchChannelRequest(),
        watcherWalletDataSaga(),
        watchRemoveMyDataRequest(),
        watchTeamPlanAsync()
    ])
}

export default rootSaga