import React from 'react'
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TierIcon, TiersModalBody } from '../../../../styles/dashboard/Main';
import { parsedJson } from '../../../../tools/tools';
import { referralTierStatus } from '../../../../tools/constants';

const ReferralTiersModal = ({setShowTiersModal, progress}) => {
    const { t } = useTranslation();

    const [modal, setModal] = useState(true);

    const [modalWidth] = useState({
        lg: "933px",
        sm: "435px"
    })
    const widthCalculator = "lg"

    const toggle = () => setShowTiersModal(false);

    return (
        <Modal backdrop={false} modalWidth={modalWidth[widthCalculator]} widthCalculator={widthCalculator} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} modal={modal.toString()} toggle={toggle} isOpen={modal}>
            <ModalBody>
                <ModalHeader toggle={toggle}></ModalHeader>
                <TiersModalBody>
                  <h1>{t('tiers_modal_heading')}</h1>
                  <div className='tiers-section'>
                    {
                      progress?.map((tier) => {
                        return (
                          <div className={`tier ${tier.status === referralTierStatus.progress ? 'active' : 'inactive'}`} key={tier.name} >
                            <TierIcon className={`img ${tier.name}`} status={tier.status} ><span></span></TierIcon>
                            <p className='name'>{tier.name}</p>
                            <span className='total-referrals'>{t('total_referral')}</span>
                            <p className='count'>{tier.invited}/{tier.requiredInvites}</p>
                            <span className='rewards'>{t('tiers_modal_rewards')}</span>
                            <ul className='rewards-desc'>
                              {
                                parsedJson(tier.description)?.map((li, i) => <li key={i}>{li}</li>)
                              }
                            </ul>
                          </div>
                        )
                      })
                    }
                  </div>
                </TiersModalBody>
            </ModalBody>
        </Modal>
    )
}

export default ReferralTiersModal