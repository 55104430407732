import { PrimaryBtn } from "../../../styles/Generic";
import { CartStyle } from "../../../styles/dashboard/VolumeLicensing";
import { VolumeLicensingContent } from "./content";
import { currencySymbol, dateFormat } from "../../../tools/tools"

const DynamicCard = ({accountNumber, data, maxAccountValue, purchaseVL}) => {

    const maxCountAgainstSliderValue = 999999999;

    let symbol = currencySymbol[data.currency.currency_code];

    const getCalculatedPriceAccToAccounts = () => {
        let items = data.items['purevpn'];
        let slab = 5;

        for(let i=0; i< Object.keys(items).length; i++){
            if(accountNumber <= +Object.keys(items)?.[i]){
                slab = Object.keys(items)?.[i];
                break;
            }
        }
        
        return Object.keys(items).length != 0 && items.hasOwnProperty(slab) ? 
                (items[slab]?.unit_amount * accountNumber) : 
                (items[maxCountAgainstSliderValue]?.unit_amount * maxAccountValue)
    }

    const getDiscountedPercentage = () => {
        return (100-(getCalculatedPriceAccToAccounts()/(data.currency.actual_price * accountNumber))*100).toFixed(0)
    }

    return  <CartStyle>
        <h4>{VolumeLicensingContent.interactive_content.sub_heading}<span>{`SAVE ${getDiscountedPercentage()}%`}</span></h4>
        <h5>{`${data?.interval_length} ${data?.interval_length == 1 ? 'Month' : data?.interval_unit } Plan for ${accountNumber} members`}</h5>

        <h2>{`${symbol}${(getCalculatedPriceAccToAccounts()/data.interval_length)?.toFixed(2)}`}<span className="white"> / Month</span></h2>

        <h6>{`${symbol}${((data.currency.actual_price * accountNumber)/data?.interval_length).toFixed(2)}`} /mo</h6>

        <PrimaryBtn className="w-100 mt-4" onClick={purchaseVL}>Purchase Volume License</PrimaryBtn>
        <p>{`After upgrade your plan will expire on ${dateFormat(data.end_date)}`}</p>
    </CartStyle>
}
export default DynamicCard;