import styled from "styled-components";

export const DowngradeStyles = styled.div`
    .content-body-for-downgrade{
        height: calc(100vh - 90px);
        overflow-y: scroll;
    }
    .heading{
        padding: 5px 0px;
        h3{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #43225B;
            margin: 0;
            margin-bottom: 23px;
        }
    }
    .info{
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        label{
            width: 110px;
        }
    }
    .addon-purchase{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            color: #0D85E8 !important;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            cursor: pointer;
        }
    }
    
`
