import styled from "styled-components"
import pswCta from '../../assets/pswCta.png'
import productIcon from "../../assets/security-tools/product-icons.png";

export const RandomPswGenerator = styled.div`
    height: 390px;
    max-width: 550px;
    min-width: 360px;
    border: 2px solid black;
    text-align: center;
    flex: 1;
    background: #FBFBFC;
    border: 1px solid #DDD4E2;
    margin-left: 30px;
    border-radius: 25px;
    align-items: flex-start !important;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center !important;
    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 125%;
        text-align: center;
        color: #43225B;
        margin-bottom: 0px !important;
    }
    p{
        font-size: 15px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #7B648C;
        margin-bottom: 0px !important;
    }
    @media only screen and (max-width: 900px) {
        max-width: 100%;
        min-height: 290px;
        margin: 20px auto;
        padding: 24px;
        min-width: auto;
        h2{
            font-size: 18px;
        }
        p{
            font-size: 12px;
        }
    }
`
export const RandomGenView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .randomPswView{
        justify-content: center;
        align-items: center;
        background: rgba(238, 233, 244, 0.35);
        border-radius: 12px;
        font-weight: 600;
        font-size: 22px !important;
        line-height: 125%;
        color: #43225B !important;
        overflow: auto;
        text-align: center;
        padding: 30px 20px;
        white-space: nowrap;
        /* Customizing the scroll bar */
        &::-webkit-scrollbar {
        height: 4px;
        background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
        background-color: #43225B;
        border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
        background-color: #543c6b;
        }

        &::-webkit-scrollbar-thumb:active {
        background-color: #3a2446;
        }

        /* Removing the arrows at the corners of the horizontal scroll bar */
        &::-webkit-scrollbar-button {
        display: none;
        }
    }
    @media only screen and (max-width: 900px) {
        .randomPswView{
            font-size: 14px !important;
            padding: 10px 20px;
        }
    }
`
export const RangeInput = styled.input`
    width: -webkit-fill-available;
    accent-color: #361B49;
`;

export const PswGeneratorIcon = styled.span`
    background-image: url(${pswCta});
    display: block;
    &.copy {
        width: 16px; height: 16px;
        background-position: -10px -10px;
    }
    &.refresh {
        width: 16px; height: 16px;
        background-position: -46px -10px;
    }
`

export const PasswordGeneratorCTA = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 5px;
    max-width:150px;
    height: 30px;
    color: #8E7A9D;
    background: #FFFFFF;
    border: 1px solid #DDD4E2;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    @media only screen and (max-width: 900px) {
        font-size:12px !important;
    }
`

export const PureKeepInfo = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: relative;

::before{
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background: url(${productIcon}) no-repeat 0px 0px;
    background-position-x: -16px;
    background-position-y: 0px;
    top: 0px;
    left: -20px;
}
label{
    color: #001219;
    font-size: 11px;
    font-weight: 600;
    line-height: 13.75px;
    letter-spacing: 0.22px;
}
`