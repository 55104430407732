import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
	DashboardHead,
	InfoCard,
	SubscriptionWrapper,
	UpgradeNA,
	WireGuardKeysCard,
} from "../../../styles/dashboard/Main";
import Aside from "../layout/AsideCampaign";
import GenerateWireGuardKey from "./child/GenerateWireGuardKey";
import { getSubscriptionsRequest } from "../../../redux/subscriptions/actions";
import {
	APIFAILED,
	INVALIDTOKEN,
	TOKENEXPIRED,
} from "../../../tools/responseMessages";
import { sendEvents } from "../../../tools/mpEvents";
import { toast } from "react-toastify";
import { poster } from "../../../tools/tools";
import { getReferRequested } from "../../../redux/refer/actions";
import qs from "qs";
import WireGuadWrapper from "./child/WireGuadWrapper";
import { Input, ModalBody } from "reactstrap";
import ErrorBoundary from "../../ErrorBoundary";
import { DestructBtn, PrimaryBtn } from "../../../styles/Generic";
import Skeleton from "react-loading-skeleton";
import ErrorScreen from "../manual-connection/modal/ErrorScreen";
import { useTranslation } from 'react-i18next'

const Wireguard = ({ countryInfo, subscriptions, onBoardingReducer,closeModal,devicesData,selectedUser,setDownloadWGDisplayableData,setHideSelect ,setSelectState,serverType,deviceError,crashedStatus,currentSubData={}, multiLoginLimitExceededError}) => {
	const {name,iso,city} = countryInfo
	const [asideType] = useState("refer");

	const [selectedSubscription, setselectedSubscription] = useState({});

	const [addonType, setaddonType] = useState({
		type: "",
		subscription: "",
		currency: "",
		plan: "",
	});

	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();

	const token = localStorage.getItem("token");

	const [wgDisplayableData, setwgDisplayableData] = useState(false);

	const [wgStoredData, setwgStoredData] = useState(false);

	const [endWGTimer, setEndWGTimer] = useState(false);

	const [WGLoading, setWGLoading] = useState(false);

	const [WGErrorMessage, setWGErrorMessage] = useState(false);

	const [wgConfigs, setwgConfigs] = useState("");

	const [loading, setloading] = useState(false);
	const [data, setdata] = useState(null);

	const [noSub, setNoSub] = useState(false);

	const [crashed, setCrashed] = useState(false);

	const [isCancel, setIsCancel] = useState(false);

	const [resetTimer, isResetTimer] = useState(false);

	const [xTimeAdd, setxTimeAdd] = useState(false);
	const [reGen, setRegGen] = useState(false);
	const [resetSelect, setResetSelect] = useState(false);

	const sendWGDataToSubsComponent = (wgChildData) => {
		generateWGKey(wgChildData);
		setWGLoading(true);
		setwgStoredData(wgChildData);
		if(wgChildData.reGenereated){
			setHideSelect(true)
		}
	};

	if(WGLoading){
		setSelectState(true)
	}

	const generateWGKey = async (data) => {
		const {
			country,
			city,
			device,
			wgEncrypPass,
			selectedSubscriptionData,
			wgPublicKey,
			reGenereated,
		} = data;
		try {
			isResetTimer(false);
			const formdata = qs.stringify({
				sUsername: selectedSubscriptionData,
				sPassword: wgEncrypPass,
				sCountrySlug: iso,
				sDeviceType: device,
				sClientPublicKey: wgPublicKey,
				iCityId: city?.id,
			});
			const wgKey = await poster(
				"wireguard/get-wg-server",
				formdata,
				token
			);
			const { data: response } = wgKey;
			if (response?.status) {
				setEndWGTimer(false);
				setWGErrorMessage(false);

				setwgConfigs(response?.body[0]?.wireguard_configuration);

				setwgDisplayableData({
					username: selectedSubscriptionData,
					countryName: name,
					cityName: city?.name,
					WGKey: response?.body[0],
				});
				setWGLoading(false);
				setIsCancel(true);
				setRegGen(false);
				setDownloadWGDisplayableData(true)
				setHideSelect(true)
			} else {
				setSelectState(false)
				const customMessage =
					"The servers on this location are not available at the moment. Please try again with another location.";
				switch (response?.error_code) {
					case "token_expired":
						toast.error(t(TOKENEXPIRED));
						dispatch({ type: "LOGOUT" });
						break;
					case "token_invalid":
						toast.error(t(INVALIDTOKEN));
						dispatch({ type: "LOGOUT" });
						break;
					case "jwt_default_exception":
						toast.error(t(INVALIDTOKEN));
						dispatch({ type: "LOGOUT" });
						break;
					case "atom_exception":
						if(response?.errors?.code===1008){
							// show multi login error popup
							multiLoginLimitExceededError();
						}else{
							setWGErrorMessage(
								response?.errors?.code === 1034
									? customMessage
									: response?.message
							);
						}
						break;
					case "validation_exception":
						setWGErrorMessage(response?.message);
						break;
					default:
						toast.error(t(APIFAILED));
						break;
				}
				sendEvents(
					{	
						event_name: "ma_fail_generate_wgconfig",
						event_properties: {
							protocol:"Wireguard",
							country: country.name,
							city: city?.name,
							username:selectedUser?.username,
							reason: response?.message ?? "N/A",
							is_p2p_server_requested:serverType.includes("p2p"),
							is_obfuscated_server_requested:serverType.includes("OVPN_OBF"),
							generated_via: reGenereated
								? "Regenerate Configuration"
								: "Generate configuration",
						},
					},
					token
				);
			}
		} catch (error) {
			setWGLoading(false);
			setRegGen(false);
			toast.error(t(APIFAILED));
			setSelectState(false)
		} finally {
			setSelectState(false)
			setWGLoading(false);
			let timeDuration = 60 * 30,
				display = document.querySelector("#time");

			document.body.contains(display) &&
				startTimer(timeDuration, display, false) &&
				setRegGen(false);
		}
	};
	function startTimer(duration, display, checkIsCancel) {
		var timer = duration,
			minutes,
			seconds;
		var xTime = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			display.textContent = minutes + ":" + seconds;
			if (timer === duration) {
				setxTimeAdd(xTime);
			}

			if (--timer < 0) {
				setEndWGTimer(true);
				clearInterval(xTime);
			}
		}, 1000);
		return xTime;
	}
	if (resetTimer) {
		clearInterval(xTimeAdd);
	}
	const setAgain = (param) => {
		setIsCancel(param);
		setwgStoredData(false);
		setDownloadWGDisplayableData(false);
		setHideSelect(false);
		setSelectState(param)
	};

	return (
		<div className="">
			<div className="">
				<div className="d-flex">
					<div className="">
						{(!isCancel && !loading ) || crashed ? (
							<GenerateWireGuardKey
								isCancel={isCancel}
								setAgain={setAgain}
								selectedSubscription={
									selectedUser?.username
								}
								loading={loading}
								addonType={addonType}
								sendWGDataToSubsComponent={
									sendWGDataToSubsComponent
								}
								WGLoading={WGLoading}
								setWGLoading={setWGLoading}
								WGErrorMessage={WGErrorMessage}
								reGen={reGen}
								setWGErrorMessage={setWGErrorMessage}
								subLoading={loading}
								status={selectedUser?.isRefuneded}
								crashed={crashedStatus}
								countryInfo={countryInfo}
								closeModal={closeModal}
								devicesData={devicesData}
								setSelectState={setSelectState}
								serverType={serverType}
								deviceError={deviceError}
								currentSubData={currentSubData}
							/>
						) : (
							!WGLoading &&
							wgStoredData &&
							isCancel && (
								<>
									<WireGuadWrapper
										selectedSubscription={
											selectedUser?.username
										}
										WGLoading={WGLoading}
										WGErrorMessage={WGErrorMessage}
										wgDisplayableData={wgDisplayableData}
										subscription={
											selectedUser?.username
										}
										endWGTimer={endWGTimer}
										wgStoredData={wgStoredData}
										sendWGDataToSubsComponent={
											sendWGDataToSubsComponent
										}
										setAgain={setAgain}
										setWGLoading={setWGLoading}
										isResetTimer={isResetTimer}
										reGen={reGen}
										setRegGen={setRegGen}
										serverType={serverType}
									/>
								</>
							)
						)}
						{(selectedUser?.isRefuneded === "true" || crashedStatus)
							 && (
								<ErrorScreen crashed={crashed} closeModal={closeModal} protocol="WireGuard" crashedStatus={crashedStatus}/>
							)}
						{
							loading && !crashed &&
							<ModalBody>
							<div className="mod-body" style={{minHeight:"200px"}}>
								<Skeleton className="d-block mb-3" width={300}/>
								<Skeleton className="d-block mb-3" width={280}/>
								<Skeleton className="d-block mb-3" width={260}/>
								<Skeleton className="d-block mb-3" width={240}/>
								<Skeleton className="d-block mb-3" width={220}/>
								<Skeleton className="d-block mb-3" width={50}/>
							</div>
							</ModalBody>
					
						}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: state.subscriptionsReducer,
		onBoardingReducer: state.onBoardingReducer,
		
	};
};

export default connect(mapStateToProps, {
	getSubscriptionsRequest,
	getReferRequested,
})(Wireguard);
