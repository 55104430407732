import { connect } from 'react-redux';
import { Stream } from '../../../../styles/dashboard/PureAI';
import { FlagIcon } from '../../../../styles/flags/Flag';

const StreamDetails = ({ pureAIStateReducer, showDetails, resetShowDetails }) => {
    return (
        <Stream>
            <div className="details-wrapper">
                <div className="cnt show-details col-md-12">
                    <div className="row">
                        <p className="back-btn" onClick={() => { resetShowDetails() }}>Back</p>
                        <div className="col-md-2 banner col-xs-12">
                            <img className="provider-image-detail" src={showDetails.thumbnail} alt={showDetails.title} />
                        </div>
                        <div className="col-md-10 col-xs-12">
                            <div className="container">
                                <div className="row description-div">
                                    <div className="col-md-9 col-xs-12">
                                        <div className="title">{showDetails.title}</div>
                                        <div className="description">{showDetails.short_description}</div>
                                    </div>
                                    <div className="col-md-3 col-xs-12 ratings">
                                        <div className="rating-heading">Rating</div>
                                        <div className="rating-imdb"><span>IMDB:</span><span>{showDetails?.ratings?.imdb_score?.value ?? "N/A"}/10</span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-align-center stream-on">Stream On</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="platforms">
                                            {
                                                showDetails.platforms.map((platform) => {
                                                    const countries = platform.countries.splice(0,9);
                                                    const divType = countries.length < 4 ? "one-column" : countries.length < 7 ? "two-column" : '';
                                                    return (
                                                        (countries.length  > 0 && platform?.providerInfo?.short_name ) && <div className={`col-md-4 text-align-center ${divType}`} key={'providermaindiv-'+platform.providerInfo.short_name} >
                                                            <div className="platform-item" key={'providerdiv-'+platform.providerInfo.short_name} >
                                                                <img className="provider-image" src={platform.providerInfo.icon_url} alt={platform.providerInfo.name}></img>
                                                                <div className={`country-list ${divType}`} key={'countrydiv-'+platform.providerInfo.short_name}>
                                                                    {
                                                                        countries.map((country) => {
                                                                            return (
                                                                            <a target="_blank" rel='noreferrer' href={country.standard_web} key={'anchor-'+platform.providerInfo.short_name+"-"+country.iso_code} title={country.iso_code}>
                                                                                <FlagIcon className="flag-div" key={'flagdiv-'+platform.providerInfo.short_name+"-"+country.iso_code} >
                                                                                    <span className={`bg bg-${country.iso_code.toLocaleLowerCase()}`} />
                                                                                </FlagIcon>
                                                                                <span className="country-title">{country.iso_code}</span>
                                                                            </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Stream>
    )
}


const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(StreamDetails);