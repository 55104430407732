import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { TabContent, TabPane } from 'reactstrap'
import { useDispatchAsyncApi } from '../../../custom-hooks/useDispatchAsyncApi'
import { useDispatchAsyncCall } from '../../../custom-hooks/useDispatchAsyncCall'
import { getWalletData } from '../../../redux/pure-wallet/action'
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import { WalletDashboardView } from '../../../styles/dashboard/Main'
import { constants, creditsRouting } from '../../../tools/constants'
import { credits } from './constants'
import Earn from './Earn'
import WalletNav from './partial/WalletNav'
import Redeem from './Redeem/index'
import { intialiseRedemeedData, intialRedemeedData, setRedeemInfo } from './tools/tools'
import walletDataReducer from '../../../redux/pure-wallet/reducer';
import Loader from '../manual-connection/Loader';
import { selectSub } from '../../../tools/tools'
import {sendEvents} from '../../../tools/mpEvents'

const WalletDashboard = ({pointsInfo,onBoardingReducer,isSpentLoading,rewardsInfo,spentErrorMsg,setTglCustHist,isDataLoaded}) => {
    const history = useHistory()

    const token = localStorage.getItem("token")

    const {search} = useLocation();   

    const {utm_source, utm_medium, utm_campaign, query, code, id} = Object.fromEntries(new URLSearchParams(search));

    const [isEarnActive,setIsEarnActive] = useState(1)

    const [subLoading,setSubLoading] = useState(true)


    useEffect(() => {
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: (query ? query : utm_source) ?? "Rewards",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            }
        }, token)
        const { loading: reduxLoading, subscriptions: reduxData, errors:ob_error, errorType:ob_errorType, resetState, errorMessage:ob_errorMessage,selectedSubs:selectedSubs } = onBoardingReducer;

        if(!reduxLoading) {
            setSubLoading(false)
        }
    }, [onBoardingReducer])


    return (
    !subLoading ? <WalletDashboardView>
        <WalletNav setIsEarnActive={setIsEarnActive}/>
        <TabContent activeTab={`${isEarnActive}`}>
            <TabPane tabId="1">
                {/* WAYS TO EARN SCREEN */}
                <Earn />
            </TabPane>
            <TabPane tabId="2">
                {/* WAYS TO REDEEM SCREEN */}
                <Redeem rewardsInfo={rewardsInfo} spentErrorMsg={spentErrorMsg} isSpentLoading={isSpentLoading} pointsInfo={pointsInfo} setTglCustHist={setTglCustHist} isDataLoaded={isDataLoaded}/>
            </TabPane>
        </TabContent> 
    </WalletDashboardView> : <div className='mt-3'><Loader isPromptLoader/></div>
    )
}
const mapStateToProps = (state) => {
    return {
        subscriptionsReducer: state.subscriptionsReducer,
        walletDataReducer:state.walletDataReducer,
        onBoardingReducer: state.onBoardingReducer,
    }
}
export default connect(mapStateToProps,{getWalletData}) (WalletDashboard)