import { BunddlePopup } from '../../../../styles/dashboard/puremax';
import { bundleType, constants } from '../../../../tools/constants';
import { getSubPlanTypeToDisplay } from '../../../../tools/tools';
import { Modal } from 'reactstrap'
import { sendEvents } from '../../../../tools/mpEvents';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CloseIconSVG from '../../../../assets/svg/CloseIconSVG';
import coonect_to_vpn from '../../../../assets/bundle/connect_to_vpn_icon.svg'
import hand_wave from '../../../../assets/bundle/hand_wave.svg'
import React from 'react'
import rmd from '../../../../assets/bundle/rmd-icon.svg'
import pm from '../../../../assets/bundle/pm-icon.svg'

const BundleModal = ({backdrop, toggle, modal, modalWidth, widthCalculator, plan_type=bundleType.standard,modalSource="Email Campaign",interval=null,subscription}) => {
    const history = useHistory()
    const slug = plan_type ? plan_type : bundleType.standard;

    const bundle_values = {
        max:[
            {name:"Connect to VPN",bg_img:coonect_to_vpn,slug: 'purevpn', url: `/dashboard/onboarding/${constants.purevpn}`},
            {name:"Remove My Data",bg_img:rmd,slug: 'remove-my-data', url: `/dashboard/security-tools/remove-my-data`},
            {name:"Password Manager",bg_img:pm,slug: 'password-manager', url: `https://keep.purevpn.com/start`}
        ], 
        plus: [
            {name:"Connect to VPN",bg_img:coonect_to_vpn,slug: 'purevpn', url: `/dashboard/onboarding/${constants.purevpn}`},
            {name:"Password Manager",bg_img:pm,slug: 'password-manager', url: `https://keep.purevpn.com/start`}
        ]
    }
    const token = localStorage.getItem('token')

    useEffect(()=>{
        sendEvents({
            event_name:"ma_view_onboarding_purpose_popup",
            event_properties:{
                plan_type:subscription?.plan_type ?? "N/A"
            }
        }, token)
    },[])

    const goToOnboarding = (slug,isClose=false,url='/dashboard/onboarding/purevpn') => {
        slug == 'password-manager' ? window.open(url)  : history.push(url);
        sendEvents({
            event_name:"ma_click_onboarding_purpose_popup",
            event_properties:{
                feature_name: slug ?? "N/A",
            }
        }, token)
    }
    return (
        <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[slug]}`, width: '100%'}} className="modal-wrapper bundle-modal">
                {/* <span className='close-icon' onClick={()=>goToOnboarding("purevpn",true)}><CloseIconSVG width={24} height={24}/></span> */}
                <BunddlePopup gridVal={bundle_values[slug]?.length}>
                    <h1>Welcome! Let's get you started <img src={hand_wave}/></h1>
                    <p>Thank you for subscribing to {getSubPlanTypeToDisplay(subscription?.plan_type)}! Please choose the product you wish to try first so we can take you through the step-by-step guide.</p>
                    <div className="d-flex align-items-center gap-3 pb-4">
                        {bundle_values[slug]?.map((item,key)=>(<div className='bundle-box' onClick={()=>goToOnboarding(item.slug, false, item.url)}><div><img src={item.bg_img}></img><h3 className='name'>{item.name}</h3></div></div>)) }
                    </div>
                </BunddlePopup>
        </Modal>
    )
}

export default BundleModal