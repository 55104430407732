import { GETDEVICES,LOADING,ERRORS,SUCCESS_DEVICES } from "./types";

export const getDevices = (payload) => {
    return {
      type: GETDEVICES,
      payload
    };
  };

export const loadingType = () => {
  return{
      type:LOADING
  }
}
export const errorsType = (payload) => {
  return{
      type:ERRORS,
      payload
  }
}

export const getSuccessDevices = (payload) =>{
  return {
    type:SUCCESS_DEVICES,
    payload
  }
}

