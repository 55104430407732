import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import { DashboadWrapper, DashboardContent } from '../../styles/dashboard/Main'
import ProtectedRoute from '../../tools/routes/ProtectedRoute';
import {constants} from '../../tools/constants';
import Affiliate from './affiliate/Index';
import Business from './business/Index';
import Download from './download/Index';
import HelpDesk from './help/Index';
import TicketDetails from './help/TicketDetails';
import Sidebar from './layout/Sidebar'
import ChangePassword from './manage-account/ChangePassword';
import ManageAccount from './manage-account/Index';
import PaymentHistory from './payment-history/Index';
import PaymentDetails from './payment-history/PayementDetails';
import PortForwarding from './port-forwarding/Index';
import Refer from './refer/Index';
import FamilyPlan from './family-plan/Index';
import Subscriptions from './subscriptions/Index';
import CancelationForm from './subscriptions/cancelation-form/index';
import Navbar from "../dashboard/layout/Navbar"
import AppPurchase from './app-purchase/Index';
import HelloBar from './hello-bar/Index';
import UpgradeSubscription from './upgrade/Index';
import OnBoarding from './on-boarding/Index';
import OnBoardingWrapper from './on-boarding/Wrapper';
import AppPurchaseUpgrade from './app-purchase/upgrade/Index';
import { RandomNumGenarator, isPurchasedAddOn, userVisitCountUpdate } from '../../tools/tools'
import { connect } from 'react-redux'
import ManualConnection from './manual-connection';
import AddonsDashboard from './addon-dashboard';
import PureWallet from './pure-wallet';
import { sendGuestEvents } from '../../tools/mpEvents'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

import DomeForm from './layout/partials/DomeForm';
import PureBuddy from './pure-buddy';
import PureAI from './pureai';
import FAQView from '../../utils/FAQs';
import { pure_ai_faqs } from '../../utils/FAQs/faq_list';
import PlanTypeUpgrade from './plan-type-upgrade/planTypeUprade';
import EmailFeedback from './email-feedback/Index';
import VolumeLicensing from './volume-licensing/Index';
import SecurityTools from '../security-tools/Index';
import Features from '../security-tools/featureTemplate/Index';
import PureTeams from './teams/Index';

const Dashboard = ({history, helloBarReducer, onBoardingReducer}) => {
    
    const closeHelloBar = localStorage.getItem("closeHelloBar"); 

    const { t, i18n } = useTranslation();
        
    const {path} = useRouteMatch();

    const {search, pathname} = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    const [domeModal, setDomeModal] = useState(false)

    const [isNoPaddingView,setIsnoPAddingView] = useState(false);

    const hideNav = search.includes("redirect_from");

    const hideHelloBar = constants.hide_hello_bar.includes(pathname);

    const [helloBarStatus, setHelloBarStatus] = useState(false)

    const [hideHelloBarFromMobile,setHideHelloBarFromMobile] = useState(false)

    const helloBarStatusCheck = (status) => {
        setHelloBarStatus(status)
    }

    useEffect(() => {

        // This function sets/update userVisitCount number in localStroage
        userVisitCountUpdate()

        if(window.innerWidth<1201 && pathname?.includes('pure-ai')){
            setHideHelloBarFromMobile(true)
            setHelloBarStatus(false)
        }

        //adding token in localStorage by reading cookies
        setTokenInLocalStorageFromCookies()
        setIsnoPAddingView(pathname.includes('pure-ai'))

        // generate random number 
        RandomNumGenarator();

    }, [pathname])

    const setTokenInLocalStorageFromCookies = () => {

        if(!localStorage.getItem('token')){

            let token = document.cookie.match("(^|;)\\s*fa_token\\s*=\\s*([^;]+)")?.[2];
            token ? localStorage.setItem('token', token) : 
            sendGuestEvents({
                event_name:"ma_fail_auth_token",
                event_properties: {}
            })

        }

    }

    return (
        <>
            { (!hideHelloBar && closeHelloBar !== "true") && !(hideHelloBarFromMobile) && <HelloBar helloBarStatusCheck={helloBarStatusCheck} setDomeModal={setDomeModal}/>}
            
            <DashboadWrapper className={`${helloBarStatus  && !redirected_via ? (!hideHelloBar ? "has-hello-bar" : "") : ""}`} url={pathname}> 
                { (!hideNav && !redirected_via) && <Sidebar url={pathname}/> }
                <DashboardContent className="d-flex flex-column" width={!hideNav ? 'true' : 'false'} isNoPaddingView={isNoPaddingView}>
                    { !hideNav && <Navbar isNoPaddingView={isNoPaddingView}/> } 
                    <Switch>
                        <ProtectedRoute exact path={`${path}/home`} component={AddonsDashboard} title="Dashboard"/>
                        <ProtectedRoute exact path={`${path}/account`} component={ManageAccount} title="Accounts"/>
                        <ProtectedRoute exact path={`${path}/account/change-password`} component={ChangePassword} title="Change Password"/>
                        <ProtectedRoute exact path={`${path}/subscriptions`} component={Subscriptions} title="Subscriptions"/>
                        <ProtectedRoute exact path={`${path}/family-plan`} component={FamilyPlan} title="Family Plan"/>
                        <ProtectedRoute exact path={`${path}/pure-ai2`} component={PureBuddy} title="PureAI"/>
                        <ProtectedRoute exact path={`${path}/pure-ai`} component={PureAI} title="PureAI"/>
                        
                        <ProtectedRoute exact path={`${path}/subscriptions/cancel/:id`} component={CancelationForm} title="CancelationForm"/>
                        <ProtectedRoute exact path={`${path}/port-forwarding`} component={PortForwarding} title="Port Forwarding"/>
                        <ProtectedRoute exact path={`${path}/payment-history/:id`} component={PaymentHistory} title="Payment History"/>
                        <ProtectedRoute exact path={`${path}/:subid/invoice/:id`} component={PaymentDetails} title="Payment History"/>
                        <Route exact path={`${path}/download`} component={Download}/>
                        <Route exact path={`${path}/affiliate`} component={Affiliate}/>
                        <Route exact path={`${path}/business`} component={Business}/>
                        <ProtectedRoute exact path={`${path}/manual-config`} component={ManualConnection} title="Manual Configuration"/>
                        <ProtectedRoute exact path={`${path}/refer`} component={Refer} title="Earn free credits"/>
                        <ProtectedRoute exact path={`${path}/tickets`} component={HelpDesk} title="Support"/>
                        <ProtectedRoute exact path={`${path}/ticket/:id`} component={TicketDetails} title="Support"/>
                        <ProtectedRoute exact path={`${path}/app-purchase`} component={AppPurchase} title="AppPurchase"/>
                        <ProtectedRoute exact path={`${path}/upgrade`} component={UpgradeSubscription} title="Upgrade Plan"/>
                        <ProtectedRoute exact path={`${path}/app-purchase-upgrade`} component={AppPurchaseUpgrade} title="App Purchase Upgrade"/>
                        <ProtectedRoute exact path={`${path}/plans`} component={PlanTypeUpgrade} title="Plan Type Upgrade"/>
                        <ProtectedRoute exact path={`${path}/volume-licensing`} component={VolumeLicensing} title="Volume Licensing"/>
                        <ProtectedRoute exact path={`${path}/email-feeback`} component={EmailFeedback} title="Email Feedback"/>

                        <ProtectedRoute exact path={`${path}/onboarding`} component={OnBoardingWrapper} title="Get Started"/>
                        <ProtectedRoute exact path={`${path}/rewards`} component={PureWallet} title="PureRewards"/>
                        <ProtectedRoute exact path={`${path}/pure-ai-faqs`} component={FAQView} heading="PureAI" faq_list={pure_ai_faqs} title="Get Started"/>
                        <ProtectedRoute exact path={`${path}/security-tools`} component={SecurityTools} heading="Security Tools" title="Security Tools"/>
                        <ProtectedRoute exact path={`${path}/security-tools/:slug`} component={Features} heading="Security Tools" title="Security Tools"/>
                        <ProtectedRoute exact path={`${path}/teams`} component={PureTeams} heading="PureVPN for Teams" title="PureVPN for Teams"/>

                        {
                            onBoardingReducer?.onBoardings?.map((onBoarding, idx) => {
                                return (<ProtectedRoute exact key={idx} path={`${path}/onboarding/${onBoarding.slug}`} isLocked={!isPurchasedAddOn(onBoardingReducer.accountDetails, onBoardingReducer.selectedSubs, onBoarding.slug, onBoardingReducer.subscriptions)} component={OnBoarding} title={t('get_started_text')} />)
                            })
                        }

                        <Route exact path="*">
                            <Redirect to={`${path}${process.env.REACT_APP_DEFAULT_LOGIN_URL}`} />
                        </Route>
                    </Switch>
                </DashboardContent>    
            </DashboadWrapper>
            {
                domeModal && <DomeForm modal={domeModal} setDomeModal={setDomeModal}></DomeForm>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer,
        helloBarReducer: state.helloBarReducer
    };
};

export default connect(mapStateToProps, null)(Dashboard)