import { APIFAILED } from "../../../../tools/responseMessages";
import { CircleStyles, DataGridStyles } from "../../../../styles/dashboard/security-tools/securityTools";
import { dispatchError, displayError, get, getTokenFromLocalStorage } from "../../../../tools/tools";
import { featureContent } from "../../content";
import { Graph } from "../../../../styles/dashboard/Listing";
import { InfoCard, SubscriptionBox } from "../../../../styles/dashboard/Main";
import { useDispatch} from 'react-redux';
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ExternalLink from "../../../../assets/external-link.svg";
import info from "../../../../assets/info.svg";
import Info from "../../../dashboard/plan-type-upgrade/modals/info";
import Listing from "../../../../generic-components/listing";
import Pagination from "../../../generic/Pagination";
import Skeleton from "react-loading-skeleton";
import { sendEvents } from "../../../../tools/mpEvents";

const PostPurchase = ({slug, setCrashed}) => {

    let pageSize = 15;

    const { t } = useTranslation();

    const token = getTokenFromLocalStorage();

    const dispatch = useDispatch();

    const [modalWidth] = useState({
        lg: "737px",
        sm: "440px"
    });

    const widthCalculator = "lg";

    const [infoModal, setInfoModal] = useState({
        open: false,
        content: ''
    });

    const [index, setIndex] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(true);

    const [listInfo, setListingInfo] = useState(JSON.parse(localStorage.getItem('data-brokers-list')));

    useEffect(()=> {
        if(!listInfo){
            getDataBrokers()
        }
    }, [])

    const getDataBrokers = async () => {
        try {
            const accountInfo =  await get("integrated-experience/details-listing", token);
            const {data: response} = accountInfo;

            if(response?.status){
                setListingInfo(response?.body)
                localStorage.setItem('data-brokers-list', JSON.stringify(response?.body))
                sendEvents({
                    event_name: "ma_view_data_broker_lisiting",
                    event_properties: {},
                }, token);
            } else {
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code);
                sendEvents({
                    event_name: "ma_fail_load_rmd_dashboard",
                    event_properties: {
                        type: 'data broker',
                        reason: response?.message ?? 'Oops, something went wrong',
                    },
                }, token);
            }
        } catch (error) {
            displayError(error);
            throwErrorBoundary(APIFAILED);
            sendEvents({
                event_name: "ma_fail_load_rmd_dashboard",
                event_properties: {
                    type: 'data broker',
                    reason: 'Oops, something went wrong',
                },
            }, token);
        }
        finally{
            setLoading(false)
        }
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED || error === 'upgrade_exception'){
            setCrashed(true)
        }
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return listInfo?.data_deletion_details.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, listInfo?.data_deletion_details]);

    const goToLink = (link) => {
        window.open(link,'_blank');
    }

    const renderDynamicItems = (list, idx) => {
        return <div className="position-relative">
            <img className="redirect_link" src={ExternalLink} alt="" onClick={()=> goToLink(list?.website)}/>
            <div key={idx} className={`item ${ (index === idx) ? "open" : "" } column-wrapper question`} onClick={() => index !=idx ? setIndex(idx) : setIndex()}>
            {/* HEADING */}
                <div className="column-title">
                    <h4>{t(featureContent[slug]?.post_purchase?.header[0])}</h4>
                    <div className="d-flex align-items-center gap-2 pl-4"><h5>{list?.name}</h5></div>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header?.[1])}<img src={info} alt="info" onClick={()=>toggleInfoModal('Risk Score')}></img></h4>
                    <div className="progress-meter">
                        <h5>{`${list?.riskScore}/10`}</h5>
                        <div class="multigraph">
                            <Graph score={list?.riskScore} className="graph"/>
                        </div>
                    </div>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header[2])}</h4>
                    <h5>{list?.category}</h5>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header?.[3])}<img src={info} alt="info" onClick={()=>toggleInfoModal('Status')}></img></h4>
                    <h5>{list?.status ? t('post_purchase_grid_heading_one') : t('post_purchase_grid_heading_two')}</h5>
                </div>
            </div>
            {/* BODY  */}

            {index === idx && <div className='answer'>
                <h6>{`${t('types_of_data_broker')} ${list?.category}`}</h6>
                <p>{list?.categoryDetails}</p>
            </div>}
        </div>
    }

    const renderHeaderItems = () => {
        return featureContent[slug]?.post_purchase?.header.map((title, index)=>{
            return <div className={`${index === 1 || index === 3 ? 'show-info' : null} column-title`}>
            <h6 className="title">{t(title)}</h6>
            {(index === 1 || index === 3) && <img src={info} alt="info" onClick={()=>toggleInfoModal(title == 'risk_score_heading' ? 'Risk Score' : 'Status')}></img>}
        </div>
        })
    }

    const getScore = () => {
        let value = listInfo?.total_summary?.completed_request > 0 ? ((listInfo?.total_summary?.completed_request/listInfo?.total_summary?.total_request)*100)?.toFixed(2): listInfo?.total_summary?.completed_request+0.1
        return value
    }

    const toggleInfoModal = (title) => {
        title ? 
            setInfoModal({open: true, content: 
                title === 'Status' ?  <div className="grid-box">
                    <ul className="rmd">
                        {featureContent[slug]?.post_purchase?.info.map((item)=>{
                            return <>
                            <li className={`${item?.slug}`}>
                                <h3 className="mb-1">{t(item?.heading)}</h3>
                            </li>
                            <p className="mb-4 mt-1">{t(item?.description)}</p>
                            </>
                        })}
                    </ul>
                </div> : 
                <div className="grid-box">
                    <ul className="rmd warning">
                        <li className={`${featureContent[slug]?.post_purchase?.risk?.slug}`}>
                            <h3 className="mb-1">{t(featureContent[slug]?.post_purchase?.risk?.heading)}</h3>
                        </li>
                        <p className="mb-4 mt-1">{t(featureContent[slug]?.post_purchase?.risk?.description)}</p>
                    </ul>
                    <div className="progress-meter">
                        <h5>{t('low_risk')} (1 - 3)<p>{t('low_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph three"></span>
                        </div>
                    </div>
                    <div className="progress-meter">
                        <h5>{t('medium_risk')} (4 - 7)<p>{t('medium_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph seven"></span>
                        </div>
                    </div>
                    <div className="progress-meter">
                        <h5>{t('high_risk')} (8 - 10)<p>{t('high_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph"></span>
                        </div>
                    </div>
                </div>
            }) : 
        setInfoModal({open: false, content: ''})
    }

    return <>
        {
            loading && !listInfo?.data_deletion_details ? 
            <SubscriptionBox open={true}>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </SubscriptionBox> 
            : 
            <>
                <DataGridStyles>
                    <div className="grid-box text-center">
                        <h6>{t(featureContent[slug]?.post_purchase?.grid?.[3]?.heading)}</h6>
                        <CircleStyles request={getScore()}>
                            <div>
                                <h3>{`${listInfo?.total_summary?.completed_request} of ${listInfo?.total_summary?.total_request}`}</h3>
                                <span>{t(featureContent[slug]?.post_purchase?.grid?.[0]?.heading)}</span>
                            </div>
                        </CircleStyles>
                    </div>
                    {[listInfo?.total_summary?.completed_request, listInfo?.total_summary?.pending_request, listInfo?.total_summary?.total_request].map((items, index) => {
                        return <div className="grid-box">
                            <h3>{items}</h3>
                            <ul>
                                <li className={featureContent[slug]?.post_purchase?.grid?.[index]?.slug}>{t(featureContent[slug]?.post_purchase?.grid?.[index]?.heading)}</li>
                            </ul>
                            <p>{t(featureContent[slug]?.post_purchase?.grid?.[index]?.paragraph)}</p>
                        </div>
                    })}
                </DataGridStyles>

                <Listing renderHeaderItems={renderHeaderItems} currentTableData={currentTableData} renderDynamicItems={renderDynamicItems} isRmd={true}/>

                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={listInfo?.data_deletion_details.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </>
        }

        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={infoModal.open} toggle={toggleInfoModal} />}
    </>
}

export default PostPurchase;