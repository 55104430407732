import { toast } from "react-toastify";
import { constants, puremaxProducts } from "./constants";
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from "./responseMessages";
import { poster, hasPureVPNAddOn, checkIsAddonPaid } from "./tools";
import { osName, deviceType, browserName, isMobile } from 'react-device-detect';

export const postMethod = async (url, data, token = "", responsecb, loadingcb, logoutcb, showToaster = true, errorcb) => {
    try {
        const apicall =  await poster(url, data, token)
        const {data: response} = apicall;
        if(response?.status){
            responsecb(response)
        }else{
            switch (response?.error_code) {
                case "token_expired":
                    showToaster && toast.error(TOKENEXPIRED);
                    logoutcb()
                    break;
                case "token_invalid":
                    showToaster && toast.error(INVALIDTOKEN)
                    logoutcb()
                    break;   
                case "jwt_default_exception":
                    showToaster && toast.error(INVALIDTOKEN)
                    logoutcb()
                    break; 
                default:
                    // showToaster && toast.error(response?.message);
                    errorcb(response)
                    break;
            }
        }
    } catch (error) {
        showToaster && toast.error(APIFAILED)
    }
    finally{
        loadingcb()
    }
}

export const buttonText = (loading, text) => loading ? <>{text} <span className="spinner-border text-light"></span></> : text

export const imageLoaded = (e) => e.target.classList.add('loaded')

export const checkState = (subscription) => {
    return (constants?.cardless_state.includes(subscription?.state) && subscription?.payment_gateway === "cardless") ? true : false
}

export const validateResponse = (params) => {
    const {addons,addon_original_name,addon_discounted_name} = params
    return addons?.some(addon=> (addon?.code === addon_original_name || addon?.code === addon_discounted_name) && checkIsAddonPaid(addon) && !(!addon?.is_purchased && addon?.status === "expired"))
}

export const validatePurchasedApps = (subs) => {

    let response = {
        purevpn: false,
        purekeep: false,
        purescan: false,
        purecrypt: false,
        pureprivacy: false,
    };

    if (subs && subs.length > 0) {
        subs.some(sub => {

            // Get subscription state if expired
            let stateExpired = (sub?.state === "expired")
            let hasUsePeriod = true;

            // If expired state check if subscription still have use period
            if (stateExpired && sub?.expires_at !== "") {
                let expiresAt = new Date(sub?.expires_at); //YYYY-mm-dd
                let today = new Date();
                hasUsePeriod = (expiresAt > today)
            }

            if (hasUsePeriod) {
                if (!response.purekeep) {
                    response.purekeep = validateResponse({addons:sub?.add_ons,addon_original_name:"purekeep",addon_discounted_name:"purekeep_discounted"})
                }
                if (!response.purescan) {
                    response.purescan = validateResponse({addons:sub?.add_ons,addon_original_name:"purescan",addon_discounted_name:"purescan_discounted"})
                }
                if (!response.purecrypt) {
                    response.purecrypt = validateResponse({addons:sub?.add_ons,addon_original_name:"purecrypt",addon_discounted_name:"purecrypt_discounted"})
                }
                if (!response.pureprivacy) {
                    response.pureprivacy = validateResponse({addons:sub?.add_ons,addon_original_name:"pureprivacy",addon_discounted_name:"pureprivacy_discounted"})
                }
                if (!response.purevpn) {
                    response.purevpn = hasPureVPNAddOn(subs)
                }
            }
        })
    }

    if (response.purescan || response.pureprivacy) {
        response.purescan = true;
        response.pureprivacy = true;
    }

    return response

}

export const tryRequire = (appSlug, path, secondaryPath, defaultPath = "") => {
    let imgSrc
    defaultPath = defaultPath == "" ? 'devices/purevpn/mac.jpg' : defaultPath;
    try {
        imgSrc = require(`../assets/${path}`);
    } catch(err) {
        try {
            imgSrc = require(`../assets/${secondaryPath}`);
        } catch(err){
            if(appSlug?.toLowerCase() == browserGenerics() || appSlug?.toLowerCase() == userOs(window)){
                imgSrc = "showIcon";
            } else {
                imgSrc = require(`../assets/${defaultPath}`);
            }
        }
    }
    return imgSrc;
}

export const userOs = (window) => { 
    let operatingSystem = 'Not known';
    if (window.navigator.platform.indexOf('Win') !== -1) { operatingSystem = 'windows'; }
    if (window.navigator.platform.indexOf('Mac') !== -1) { operatingSystem = 'mac'; }
    if (window.navigator.platform.indexOf('X11') !== -1) { operatingSystem = 'unix'; }
    if (window.navigator.platform.indexOf('Linux') !== -1) { operatingSystem = 'linux'; }

    return operatingSystem;
};

export const browserGenerics = () => {
    var browser = browserName.toLowerCase();
    switch (browserName.toLowerCase()) {
        case 'chrome':
            browser = 'google_chrome';
            break;
        case 'firefox':
            browser = 'firefox';
            break;
        case 'edge':
        case 'internet explorer':
        case 'internetexplorer':
            browser = 'edge';
            break;
        default:
            break;
    }
    return browser;
}

export const setActiveDevices = (download, allDevices, data, setData, appSlug, selectedApp, exclusive = false, deviceAbbr = "", versionIndex = 0, slug, selectedStep) => {
    const { errors } = download;

    let browser = browserGenerics();

    let selectedExtensionDevice = false;

    if(download && download.devices?.body && download.devices?.body.length > 0){
        var selectedDevices = {}
        if(exclusive && deviceAbbr != ""){
            let downloadDevices= selectedStep ? download.devices?.body[selectedApp].devices.filter((dev) => !dev?.content_details?.beta) : download.devices?.body[selectedApp].devices
            selectedDevices = [downloadDevices.find(device => device.abbreviation === deviceAbbr)]
            selectedExtensionDevice = downloadDevices.find(device => ((appSlug === "purekeep"  &&  device.slug === `web`) || ( appSlug !== "purekeep" && device.slug === browser)))
        } else {
            let downloadDevices= selectedStep ? download.devices?.body[selectedApp].devices.filter((dev) => !dev?.content_details?.beta) : download.devices?.body[selectedApp].devices

                selectedDevices = downloadDevices.filter(device => {
                let deviceSlug = device.slug;
                let uos = userOs(window);

                if (device.abbreviation == "ipad_ios" && deviceType === "tablet") {
                    deviceSlug = "ios"
                } else if (device.abbreviation == "iphone_ios" && deviceType !== "tablet") {
                    deviceSlug = "ios"
                }

                if((appSlug === "purekeep"  &&  deviceSlug === `web`) || ( appSlug !== "purekeep" && deviceSlug === browser)){
                    selectedExtensionDevice = device;
                }

                if(slug ? deviceSlug == slug :(deviceSlug === osName.toLowerCase() || (deviceSlug === uos && !isMobile)) && device?.versions && device?.versions.length > 0) {
                    return device;
                }
            });
        }

        // If system perfered device not found use the default first in the list
        if (selectedDevices.length == 0) {
            selectedDevices = [download.devices?.body[selectedApp].devices[0]];
        }
        var iteratedDevice = selectedDevices.map(selectedDevice => refactorSelectedDevice(selectedDevice, appSlug, versionIndex, deviceAbbr));

        setData({...data, allDevices: allDevices, devices:iteratedDevice, extensionDevice: refactorSelectedDevice(selectedExtensionDevice, appSlug, versionIndex, deviceAbbr),slug:iteratedDevice[0]?.slug});
    } else {
        setData({...data, allDevices: allDevices})
    }
}

const refactorSelectedDevice = (selectedDevice, appSlug, versionIndex, deviceAbbr) => {
    let version = versionIndex > 0 && deviceAbbr == selectedDevice.abbreviation ? versionIndex : 0;
    if(selectedDevice){
        let ext = "jpg"
        if( selectedDevice.abbreviation === "linux" && selectedDevice.slug === "linux"){
            ext = "png"
        }
        return ({
            id:selectedDevice?.id,
            img:tryRequire(selectedDevice.slug, `devices/${appSlug}/${selectedDevice.abbreviation}.${ext}`,`devices/${appSlug}/${selectedDevice.slug}.${ext}`, `devices/${appSlug}/mac.jpg`),
            heading: selectedDevice.name,
            osx:selectedDevice?.versions?.[version]?.name || "-",
            hash:selectedDevice?.versions?.[version]?.hash,
            link:selectedDevice?.versions?.[version]?.download_url || "-",
            setup_guides:selectedDevice?.setup_guides || [],
            release_notes:selectedDevice?.versions?.[version]?.release_note || "-",
            versions:selectedDevice?.versions || [],
            abbreviation:selectedDevice?.abbreviation || "",
            slug:selectedDevice?.slug,
            content_details:selectedDevice?.content_details,
            suggested_devices: selectedDevice?.suggested_device
        });
    } else {
        return false;
    }
}

export const determineOnBoardingActivationRemaining = (onBoardings, currentOnBoardingSlug, subscriptions) => {
    let response = null

    if (onBoardings) {
        let orderedArr = onBoardings.sort((a, b) => a.order > b.order ? 1 : -1);
        let orderedOnBoardings = {}
        
        for (let i = 0; i < orderedArr.length; i++) {
            if (orderedArr[i]?.name && orderedArr[i]?.slug) {
                let slug = orderedArr[i]?.slug.replace(/\s/g,'');

                orderedOnBoardings[slug] = {
                    name: orderedArr[i]?.name,
                    slug: orderedArr[i]?.slug,
                    activation: (currentOnBoardingSlug === slug) ? 1 : null
                }
            }
        }

        let userOnboardingHistory = JSON.parse(localStorage.getItem('userOnboardingHistory'));

        // Get next onboarding item based on the history.
        if (userOnboardingHistory && userOnboardingHistory.length > 0) {
            userOnboardingHistory.map((his, idx) => {

                let activation = his?.activation;

                if (orderedOnBoardings[his.slug] && !orderedOnBoardings[his.slug]?.activation) {
                    orderedOnBoardings[his.slug].activation = (activation) ? activation : null;
                }

                if (his.slug !== currentOnBoardingSlug && !orderedOnBoardings[his.slug]?.activation) {

                    if (orderedOnBoardings[his.slug]) {
                        response = orderedOnBoardings[his.slug]
                    }
                }
            })
        }

        // If no history is found look for next purchased app.
        if (!response && subscriptions) {
            if (subscriptions.length > 0) {
    
                // Look for next purchased app based on the order.
                let purchasedApps = validatePurchasedApps(subscriptions)

                for (let j = 0; j < orderedArr.length; j++) {
                    if (orderedArr[j]?.name && orderedArr[j]?.slug) {
                        let slug = orderedArr[j]?.slug.replace(/\s/g,'');

                        if (
                            response === null &&
                            purchasedApps[slug] === true && 
                            orderedOnBoardings[slug] && 
                            slug !== currentOnBoardingSlug &&
                            orderedOnBoardings[slug]?.activation !== 1
                        ) {
                            response = orderedOnBoardings[slug]
                        }
                    }
                }
            }
        }

    }

    return response;
}

export const hasPureMaxAddons = (addons) => {
    let count = 0;
    addons.forEach(addon => {
        if(puremaxProducts[addon?.code]){
            count++;
        }
    })
    return count === puremaxProducts?.length ? true : false;
}