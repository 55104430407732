import React, { useEffect, useState } from 'react'
import { StepBarWrapper, OnBoardingNavIcon, StepBarMobileController } from '../../../styles/dashboard/Main'
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { BrowserView, MobileView } from 'react-device-detect';
import {ReactComponent as ArrowLeft} from '../../../assets/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../../../assets/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';


const StepBar = ({history, accountReducer, isLocked, isRenewal, inSessionPurchased, appSlug, onBoardingReducer, steps, selectedStep, selectStepCallback, subscriptionData, completedSteps, notEligibleSignup}) => {

    const token = localStorage.getItem("token");

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const dispatch = useDispatch()

    const loading = onBoardingReducer?.onBoardings ? false : true;

    useEffect(() => {
        steps.map( (step, idx) => {
            if (step.slug == "signup" && !isLocked && selectedStep == idx) {
                selectStepCallback(selectedStep + 1)
            }
        })
        
    }, [onBoardingReducer.selectedSubs])

    const skeleton = (html) => {
        return loading ? (
            <Skeleton height={20} width={300} className="onboarding-wrapper-loading"/>
        ) : html
    }

    const controllerHandler = (option) => {

        if (option === "left" && steps[selectedStep].slug != "signup") {
            if ((selectedStep - 1) != 0) {
                selectStepCallback(selectedStep - 1)
            }
        }
        if (option === "right" && steps[selectedStep].slug != "signup") {
            if (selectedStep != (steps.length - 1)) {
                selectStepCallback(selectedStep + 1)
            }
        }
    }

    const checkStepCompleted = (data, slug) => {
        return (data.includes(slug));
    }

    return skeleton(
        <div className='row'>
            <BrowserView>
                <StepBarWrapper className={`col-md-8 desktop-view ${(appSlug) ? "steps-" + appSlug : ""}`}>
                    {
                        steps.map( (step, idx) => {
                            return (
                                <li key={idx} className={` col-md-3 col-xs-3 ${ 
                                    (step.slug == "signup" && !inSessionPurchased) ? ( !isLocked ) ? "d-none" : "" : (isLocked) ? "locked" : ""
                                } ${ 
                                    (selectedStep == idx) ? "current" : "" 
                                } ${ ( checkStepCompleted(completedSteps, step.slug) ) ? "completed" : "" } ${ 
                                    (idx + 1 == steps.length) ? "last-step" : "" 
                                } ${ 
                                    ( step.type == "media_object" && step.data.length > 1 ) ? "media-object" : "" 
                                }`} onClick={() => {
                                    if (!isLocked && step.slug != "signup") {
                                        selectStepCallback(idx)
                                    }
                                }}>
                                    <span className='bar'></span>
                                    {
                                        idx + 1 == steps.length ? (<OnBoardingNavIcon icon={`last-step`} selected={( selectedStep >= idx )} className={`icon`} current={(selectedStep == idx)} width={24} height={24} />) : null
                                    }
                                    {
                                        (step.type == "media_object" && step.data.length > 2) ? (<OnBoardingNavIcon icon={`media-object`} selected={( selectedStep >= idx )} completed={(selectedStep > idx)} width={24} height={24} />) : null
                                    }
                                    {   notEligibleSignup  && step.slug == "signup" 
                                        ? <span className='text'>{t('dashboard_get_new_subscription')}</span>
                                        : (
                                            isRenewal && step.slug == "signup" ? 
                                            (<span className='text'>{t('subscription_renew_sub_cta')}</span>) : 
                                            (<span className='text'>{step.name}</span>)
                                        )
                                    }
                                    
                                </li>
                            )
                        })
                    }
                </StepBarWrapper>
            </BrowserView>
            <MobileView>
                <StepBarWrapper className='col-md-12 mobile-view' stepsLength={steps.length} isLocked={isLocked}>
                    {
                        steps.map( (step, idx) => {
                            return (
                                <li key={idx} className={`${ 
                                    (step.slug == "signup" && !inSessionPurchased) ? ( !isLocked ) ? "d-none" : "" : (isLocked) ? "locked" : ""
                                } ${ ( selectedStep > idx ) ? "completed" : "" } ${ 
                                    (selectedStep == idx) ? "current" : "" 
                                }`}></li>
                            )
                        })
                    }
                </StepBarWrapper>
                    {
                        steps.length > 0 && (
                            <StepBarMobileController>
                                <span className={`control-left ${
                                    selectedStep == 0 || (!isLocked && selectedStep == 1) ? "disabled" : ""
                                }`} onClick={(e) => controllerHandler('left')}>
                                    <ArrowLeft />
                                </span>
                                <span className={`control-right ${ 
                                    ((steps && selectedStep == steps.length - 1) || steps[selectedStep].slug == "signup") ? "disabled" : ""}
                                `} 
                                onClick={(e) => controllerHandler('right')}>
                                    <ArrowRight />
                                </span>
                                {
                                    steps.map( (step, idx) => {
                                        if (selectedStep == idx){
                                            return <span key={idx} className='step-name'>{step.name}</span>
                                        }
                                    })
                                }
                            </StepBarMobileController>
                        )
                    }
            </MobileView>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(StepBar)