import { CancellationFomoStyles } from "./index";

const CancellationFomo = () => {

    let FOMO_IMAGE_URL = 'https://images.purevpn-tools.com/public/images/'
    
    const options = [
        {image: `${FOMO_IMAGE_URL}Privacy & anonimity.png`, text: "Complete online privacy and anonymity"},
        {image: `${FOMO_IMAGE_URL}Data Encryption.png`, text: "Encrypted network for data protection"},
        {image: `${FOMO_IMAGE_URL}Bypass Geo restriction to access content.png`, text: "Bypass geo-restrictions to access content."},       
    ]

    return <CancellationFomoStyles>
        <div className="content-body">
            <ul>
                {options.map((option)=>{
                    return <li className="fomo">
                            <figure>
                                <img src={option.image} alt={option.text} width={100} height={40}/>
                            </figure>
                            <p>{option.text}</p>
                    </li>
                })}
            </ul>
        </div>
        </CancellationFomoStyles>
        
}
export default CancellationFomo;