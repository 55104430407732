import styled, { css } from "styled-components";
import GridIcons from "../../../assets/pure-teams/grid-icons.svg";
import TeamServer from "../../../assets/pure-teams/team-server.svg";
import DedicatedIp from "../../../assets/pure-teams/dedicated-ip.svg";
import ArrowLeft from "../../../assets/arrow-right.svg";
import BackArrow from "../../../assets/back-arrow.svg";
import Search from "../../../assets/search.png";
import copy from "../../../assets/copy.svg";

export const PostPurchaseStyles = styled.div`
    
    .heading{
        display: flex;
        margin-bottom: 32px;
        h1{
            color: ${({theme:{teams:{colors: {primary}}}})=>primary};
            font-size: ${({theme:{teams:{typography: {h1}}}})=>h1};
            font-weight: 700;
            line-height: 30px;
            display: inline-block;
        }
        p{
            color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
            font-size: ${({theme:{teams:{typography: {p}}}})=>p};
            font-weight: 400;
            line-height: 14px;
    
            span{
                color: ${({theme:{teams:{colors: {links}}}})=>links} !important;
                padding-left: 4px;  
            }
        }
        span.back-arrow{
            display: inline-block;
            cursor: pointer;
            content: '';
            background: url(${BackArrow}) 0px 0px no-repeat;
            width: 24px;
            height: 24px;
            margin: 4px 12px 0px 0px;
        }
        ${props => props.activeTab !== 'team_members' && css`
            border-bottom: 1px dashed ${({theme:{teams:{colors: {border}}}})=>border};
            margin-bottom: 40px;
            padding-bottom: 20px;
        `}
        .info{
            cursor: pointer;
        }
    }
    .grids{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px dashed ${({theme:{teams:{colors: {border}}}})=>border};

        @media only screen and (max-width: 960px) {
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 720px) {
            display: block;
        }
        .grid-box{
            flex-grow: 1;
            padding: 16px 12px 16px 16px; 
            border-radius: 16px;
            border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
            background: ${({theme:{teams:{colors: {white}}}})=>  white};

            @media only screen and (max-width: 720px) {
                margin-bottom: 10px;
            }

            &:hover{
                transition: 0.5s all ease-in-out;
                border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight};
                cursor: pointer;
                box-shadow: 0px 12px 24px 0px rgba(129, 70, 255, 0.08); 
            }
            .grid-content{
                padding-left: 56px;
                position: relative;
                &:before{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    content: '';
                    background: url(${GridIcons}) 0px 0px no-repeat;
                    width: 48px;
                    height: 48px;
                }
                &.arrow:after{
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    content: '';
                    background: url(${ArrowLeft}) 0px 0px no-repeat;
                    width: 24px;
                    height: 24px;
                }
                &.hide{
                    &:after{
                        display: none !important;
                    }
                }
                &.team_server{
                    &:before{
                        background: url(${GridIcons}) -48px 0px no-repeat !important;
                    }
                }
                &.dedicated_ip{
                    &:before{
                        background: url(${GridIcons}) -96px 0px no-repeat !important;
                    }
                }
                h4{
                    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                    font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
                    font-weight: 600;
                    line-height: 20px;
                }
                h5{
                    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                    font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
                    font-weight: 600;
                    line-height: 14px;
                }
            }
        }
    }
    .listing-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        @media only screen and (max-width: 960px) {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 960px) {
            display: block;
            text-align: center;
            margin: auto;
        }
        .listing-btns{
            display: flex;
            gap: 12px;

            &.text-align-right{
                text-align: right;
            }
            .ml-2{
                margin-left: 12px;
            }
            @media only screen and (max-width: 960px) {
                width: max-content;
                margin: auto;
                margin-bottom: 20px;
            }

            @media only screen and (max-width: 482px) {
                text-align: center !important;
                .ml-2{
                    margin-left: 0px;
                    margin-top: 12px;
                }
            }
        }

        .search-box{
            position: relative;
            max-width: 440px;
            width: 447px;

            @media only screen and (max-width: 960px) {
                display: block;
                text-align: center;
                margin: auto;
                margin-bottom: 20px;
            }
            input.search{
                width: 100%;
                height: 44px;
                border-radius: var(--Radius-Radius-Small, 8px);
                border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
                background: ${({theme:{teams:{colors: {white}}}})=>white};
                &::placeholder{
                    color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
                    font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody} !important;
                    font-weight: 400 !important;
                }
            }
            
            .search-icon{
                position: absolute;
                display: block;
                padding: 10px;
                border-radius: 8px;
                background: ${({theme:{teams:{colors: {background}}}})=>background};
                width: 36px;
                height: 36px;
                top: 4px;
                right: 4px;
                position: absolute; 
                &:before{
                    position: absolute;
                    background: url(${Search}) 0px 0px no-repeat;
                    top: 20%;
                    right: 5px;
                    content: '';
                    width: 24px;
                    height: 24px;
                }
            }
            
        }

        
    }

    .team-table{
        .member {
            display: flex;
            gap: 8px;
            align-items: center;
            img {
                border-radius: 50%;
            }
            .email {
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
                font-weight: 400;
                line-height: 100%;
                margin-bottom: 4px;
            }
            .name {
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
                font-weight: 400;
            }
        }
        .server-ip-cell {
            display: flex;
            gap: 8px;
            align-items: center;
            h5 {
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
                font-weight: 400;
                line-height: 100%;
                margin-bottom: 4px;
            }
            h4 {
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: ${({theme:{teams:{typography: {body}}}})=>body};
                strong {
                    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                }
            }
            .details {
                display: flex;
            }
            p {
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
                font-weight: 400;
                margin: 0;
                line-height: 22px;
            }
            .host::before {
                content: '|';
                color: ${({theme:{teams:{colors: {border}}}})=>border};
                padding: 0px 8px;
            }
            button {
                background-image:url(${copy});
                background-position: center;
                background-color: transparent;
                border: none;
                outline: none;
                width: 50px;
                height:26px;
                vertical-align:middle;
                margin-left: 4px;
                scale: 0.8;
            }
        }
        .action{
            position: relative;
            overflow: inherit;
            cursor: pointer;
            ul{
                display: none;
                position: absolute;
                top: 30px;
                padding: 0;
                right: 0px;
                background: ${({theme:{teams:{colors: {white}}}})=>white};
                border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
                border-radius: 8px;
                transition: 0.5s all ease-in-out;
                cursor: pointer;
                box-shadow: 0px 12px 24px 0px rgba(129,70,255,0.08);
                padding: 4px 0px;
                z-index: 999;
                cursor: pointer;
                &.show{
                    display: block;
                }
                li{
                    list-style: none;
                    padding: 4px 10px;
                    &:hover{
                        background: ${({theme:{teams:{colors: {background}}}})=>background};
                    }
                }
            }
        }

        .pill {
            &.active {
                padding-left: 20px;
                border: none;
                background-color: #fff;
                color: #001219 !important;
                position: relative;
                margin-left: 10px;
                &:before{
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #2EC4A0;
                    border-radius: 20px;
                    top: 5px;
                    left: 0px;
                }     
            }
            &.deploying {
                padding-left: 20px;
                border: none;
                background-color: #fff;
                color: #001219 !important;
                position: relative;
                margin-left: 10px;
                &:before{
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #ffb142;
                    border-radius: 20px;
                    top: 5px;
                    left: 0px;
                    animation: pulse-animation 2s infinite;
                }              
            }
            background-color: #F0FDF9;
            border-width: 1px;
            border-style: solid;
            border-radius: 8px;
            padding: 0px 6px;
            font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody} !important;
            font-weight: 600;
        }
        
        .rdt_TableHeadRow{
            background: ${({theme:{teams:{colors: {background}}}})=>background}; 
            color: ${({theme:{teams:{colors: {primary}}}})=>primary};
            font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
            font-weight: 600;
            line-height: 14px;
            text-transform: capitalize;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            border-bottom: none;
        }
        .rdt_TableCol{
            border-color: ${({theme:{teams:{colors: {border}}}})=>border};
            border-style: solid;
            border-width: 1px 1px 0 1px;
            :first-child{
                border-radius: 16px 0 0 0;
            }
            :last-child{
                border-radius: 0 16px 0 0;
            }
            :not(:first-child){
                border-left-width: 0;
            }
        }
        .rdt_Table{
            border-bottom: none;
            border-radius: 16px;
        }
        .rdt_TableRow{
            border-bottom: none;
            .rdt_TableCell:not(:first-child){
                border-left-width: 0px;
                border-left-style: solid;
            }
        }
        .rdt_TableCell{
            border-color: ${({theme:{teams:{colors: {border}}}})=>border};
            border-style: solid;
            border-width: 0 1px 1px 1px;
            & > div{
                width: 100%;
                overflow: inherit;
            }
            padding: 12px 15px;

            .role{
                font-size: 10px !important;
                font-weight: 400;
                line-height: 12.5px;
                text-transform: capitalize;
                padding: 4px 8px;
                margin-left: 10px;
                border-radius: 7px;
                border: 0.796px solid #550CCB;
                background: #F4F1FF;
                color: #550CCB !important;


                &.child{
                    background: rgb(255, 237, 197);
                    border: 0.796px solid rgb(187, 130, 2) !important;
                    color: rgb(187, 130, 2) !important;
                }
            }
        }
        .rdt_Pagination{
            border-color: ${({theme:{teams:{colors: {border}}}})=>border};
            border-style: solid;
            border-width: 0px 1px 1px 1px;
            border-radius: 0 0 16px 16px;
        }
    }
    .enterprise{
        border-radius: 24px;
        border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        padding: 24px 48px;
        .enterprise-box{
            display: flex;
            align-items:center;
            gap:48px;
            h4{
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
                font-weight: 700;
                line-height: 20px;
                padding-bottom: 8px;
            }
            p{
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: ${({theme:{teams:{typography: {p}}}})=>p};
                font-weight: 400;
                line-height: 20px;
                padding-bottom: 32px;
            }
        }
    }
    
    .pulse {
        animation: pulse-animation 2s infinite;
    }
      
    @keyframes pulse-animation {
            0% {
                box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
            }
            100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
            }
        }

    .loader-line {
        width: 100%;
        height: 3px;
        position: relative;
        overflow: hidden;
        background-color:  ${({theme:{teams:{colors: {links}}}})=>links};
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        margin-top: 10px;

        &:before{
            content: "";
            position: absolute;
            left: -50%;
            height: 3px;
            width: 40%;
            background-color:  ${({theme:{teams:{colors: {border}}}})=>border};
            -webkit-animation: lineAnim 2s linear infinite;
            -moz-animation: lineAnim 2s linear infinite;
            animation: lineAnim 2s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }
    }

    @keyframes lineAnim {
        0% {
            left: -40%;
        }
        50% {
            left: 20%;
            width: 80%;
        }
        100% {
            left: 100%;
            width: 100%;
        }
    }

    .waitlist-container{
        display: flex;
        height: 400px;
        align-items: center;
    }
    .waitlist-box-table {
        margin: 24px 0 32px 0;
        text-align: center;
        span {
            display: block;
            width: 72px;
            height: 72px;
            background: url(${DedicatedIp}) no-repeat;
            margin: auto;
        }
        &.dedicated-ip{
            span{
                background: url(${DedicatedIp}) no-repeat;
            }
        }
        &.team-server{
            span{
                background: url(${TeamServer}) no-repeat;
            }
        }
        h4 {
            color: ${({theme:{teams:{colors: {primary}}}})=>primary};
            font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
            font-weight: 600;
            line-height: 100%;
            margin: 16px 0 10px;
        }
        p{
            color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
            font-size: ${({theme:{teams:{typography: {p}}}})=>p};
            font-weight: 400;
            line-height: 14px;
        }
    }
`