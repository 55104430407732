import styled, { css } from "styled-components";

export const RightSideBar = styled.div`
    padding:30px 15px 30px;
    width:220px;
    height:auto;
    position:fixed;
    background: #FFFFFF;
    border: 0.5px solid #DDD4E2;
    box-sizing: border-box;
    border-radius: 15px;
    overflow:hidden;
    right:30px;
    ${props => props.isB2Baside && css`
        padding:0px 4px 30px;

    `}
    button{
        width: 178px;
        height: 32px;
        background: #FEB029;
        border-radius: 100px;
        color:#001219;
        font-weight:600;
        font-size:12px;
        border:none;
        
    }
    .loader{
        position:absolute;
        height:100%;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        top:0;
        left:0;
        background:white;
        opacity:0;
        visibility:hidden;
        ${({loading}) => loading == "true" && css`
            opacity:1;
            visibility:visible; 
        `}
        .spinner-border{
            height:15px; 
            width:15px;
            border-width:2px;
        }
    }
    h4{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.25px;
        color: #43225B;
        margin:25px 0 14px;
        ${props => props.isB2Baside && css`
            font-size:18px;
            color:#001219;  
            margin:-50px 18px 14px;

    `}
    }
    p{
        font-size: 12px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #7B648C;
        margin:0 0 30px;
        ${props => props.isB2Baside && css`
            font-size:14px;
            color:#637381;
            letter-spacing:0.5px;  
            padding: 0px 12px 0px;

    `}
    }
    a{
        padding:6px 4px;
        font-weight:500;
        display:block;
        margin-top:17px;
        font-size: 13px;
        background: #550CCB;
        border-radius: 5px;
        color:white;
        ${props => props.isB2Baside && css`
            background:#FEB029;
            color:#001219;
            border-radius:100px;
            font-weight:600;
    `}
        :hover,:focus{
            color:white;
            ${props => props.isB2Baside && css`
            color:#001219;

    `}
        }
    }
`;

export const InviteBox = styled.div`
    background: rgba(236, 233, 239, 0.5);
    border-radius: 14px;
    padding:16px;
    h2{
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #43225B;
    }
    h5{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #7B648C;
    }
`;