import React, { useState } from 'react'

import { BusinessCard, BusinessFAQSection, PrimaryFaqHeading, PrimaryHeading } from '../../../../styles/dashboard/Business';
import { NewSignupViewWrapper } from '../../../../styles/dashboard/Main';
import content from '../content/content.json';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';

function Faqs() {
  const { t, i18n } = useTranslation();
  const { faqs } = content;
  const [faq, setFaq] = useState([...faqs])

  const handleChat = () => {
    window.Intercom("show");
  }
  const faqToggle = (index) => {

    let updatedFaqs = []

    faq.map((faq, idx) => {
      if (idx === index) {
        updatedFaqs.push({
          ...faq,
          open: (!faq.open)
        })
      } else {
        updatedFaqs.push({ ...faq })
      }
    })

    setFaq(updatedFaqs)
  }
  return (
    <BusinessFAQSection>
      <PrimaryHeading>{t('business_faqs_heading')}</PrimaryHeading>
      <NewSignupViewWrapper>
        <section className='row faqs variant-2'>
          <div className=''>
            {
              faq.map((faq, idx) => {
                return (

                  <BusinessCard key={idx} className={`item faq-card ${(faq.open) ? "open" : ""}`}>
                    <div className='question' onClick={() => faqToggle(idx)}>
                      <PrimaryFaqHeading>{t(faq.heading)}</PrimaryFaqHeading>
                      <span className='icon'></span>
                    </div>
                    <div className='faq-answer'>
                      <p>
                        {faqs.length - 3 === idx ?
                          <p>{t('business_faqs_six_para')}</p> :
                          faqs.length - 1 === idx ?
                            <p>{t('business_faqs_eight_para')}</p> : t(faq.text)}
                      </p>
                    </div>
                  </BusinessCard>

                )
              })
            }
          </div>
        </section>

      </NewSignupViewWrapper>
    </BusinessFAQSection>
  )
}

export default Faqs