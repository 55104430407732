import Enterprise from '../../../../assets/pure-teams/enterprise.svg'
import { PrimaryBtn } from '../../../../styles/Generic'
import { checkAccountType, getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

export const EnterpriseCTA = ({fullWidth, selectedSubscriptionData, className}) => {
  const token = getTokenFromLocalStorage();

  const onRedirectToBusiness = () => {
    sendEvents({
      event_name:"ma_click_explore_puredome",
      event_properties: {
        account_type: checkAccountType(selectedSubscriptionData),
      }
    }, token);
    window.open(process.env.REACT_APP_DOME_HUBSPOT_LINK, '_blank')
  }
  return (
    <section className={`enterprise-section ${className ? 'has-width' : ''}`} style={{width: fullWidth? '100%' : 'auto'}}>
      <img src={Enterprise} alt="enterprise"></img>
      <div className="enterprise-info">
        <h4>Looking for an enterprise solution?</h4>
        <p>Embark on a journey with PureDome: Our Business VPN solution that goes beyond the ordinary, offering features such as Device Posture Check, Remote User Configurations, SSO Login, and the ability to create multiple teams with assigned roles.</p>
        <div className="enterprise-action"><PrimaryBtn type="button" className="primary-btn" onClick={onRedirectToBusiness} isTeams3>Explore PureDome</PrimaryBtn></div>
      </div>
    </section>
  )
}