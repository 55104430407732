export const powerOfAttorney = [
    {
        "type": "h",
        "id": "date_str",
        "contentType": "str",
        "content": "September 25, 2023"
    },
    {
        "type": "p",
        "id": "main_p_1",
        "contentType": "str",
        "content": "poa1"
    },
    {
        "type": "p",
        "id": "main_p_2",
        "contentType": "str",
        "content": "poa2"
    },
    {
        "type": "ol",
        "id": "ol1_1",
        "contentType": "str",
        "content": "poa3",
        "number": "1"
    },
    {
        "type": "ol",
        "id": "ol1_2",
        "contentType": "str",
        "content": "poa4",
        "number": "2"
    },
    {
        "type": "ol",
        "id": "ol1_3",
        "contentType": "str",
        "content": "poa5",
        "number": "3"
    },
    {
        "type": "ol",
        "id": "ol1_4",
        "contentType": "str",
        "content": "poa6",
        "number": "4"
    },
    {
        "type": "ol",
        "id": "ol2_1",
        "contentType": "str",
        "content": "poa7",
        "number": "a",
        "containerStyle": {
            "paddingLeft": 28
        }
    },
    {
        "type": "ol",
        "id": "ol2_2",
        "contentType": "str",
        "content": "poa8",
        "number": "b",
        "containerStyle": {
            "paddingLeft": 28
        }
    },
    {
        "type": "ol",
        "id": "ol2_3",
        "contentType": "str",
        "content": "poa9",
        "number": "c",
        "containerStyle": {
            "paddingLeft": 28
        }
    },
    {
        "type": "ol",
        "id": "ol1_5",
        "contentType": "str",
        "content": "poa10",
        "number": "5"
    },
    {
        "type": "ol",
        "id": "ol1_6",
        "contentType": "str",
        "content": "poa11",
        "number": "6"
    },
    {
        "type": "p",
        "id": "main_p_3",
        "contentType": "str",
        "content": "poa12"
    }
]