import React, { useEffect, useState } from 'react'
import { CardBox, DownloadBox, GuideBox, InfoCard, SingleAppDownloadBox } from '../../../styles/dashboard/Main'
import { getDeviceRequested } from '../../../redux/downloads/actions'
import { sendEvents, sendGuestEvents} from "../../../tools/mpEvents"
import { DestructBtn, LinkBtn, PrimaryBtn, SecondryBtn } from '../../../styles/Generic'
import { connect, useDispatch } from 'react-redux'
import { browserName, isMobile, isChrome, isFirefox, isSafari, isOpera, isIE, isEdge, isChromium, isWindows, isMacOs, isIOS, isAndroid, osName } from 'react-device-detect';
import { getCurrentDeviceType, isPurpose, getUserOperatingSystem, engagementLoyaltyApi } from '../../../tools/tools';

import chromeImage from '../../../assets/chrome-icon.png';
import firefoxImage from '../../../assets/chrome-icon.png';
import edgeImage from '../../../assets/chrome-icon.png';

import macImage from '../../../assets/mac-icon.png';
import windowsImage from '../../../assets/mac-icon.png';
import linuxImage from '../../../assets/mac-icon.png';
import macos from '../../../assets/device_mac.jpg';

import { Link } from 'react-router-dom';
import { Input } from 'reactstrap'
import Skeleton from 'react-loading-skeleton';
import { browserGenerics, setActiveDevices } from '../../../tools/genericMethods';
import copy from "../../../assets/copy.svg";
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';
import qs from 'qs'

const DownloadAppBox = ({download, selectedApp, history, key, appName, accountReducer, onBoardingReducer, data, devices = [], setData, setDevice, purpose = null, isOnBoarding = false,  releaseNotesClickCallback, downloadClickCallback, versionClickCallback, sendGuidesEvents,setShowThankyouPage=()=>{},setFormData=()=>{}}) => {

    const token = localStorage.getItem("token");

    const { t, i18n } = useTranslation();
    

    const userOs = (window) => { 
        let operatingSystem = 'Not known';
        if (window.navigator.platform.indexOf('Win') !== -1) { operatingSystem = 'windows'; }
        if (window.navigator.platform.indexOf('Mac') !== -1) { operatingSystem = 'mac'; }
        if (window.navigator.platform.indexOf('X11') !== -1) { operatingSystem = 'unix'; }
        if (window.navigator.platform.indexOf('Linux') !== -1) { operatingSystem = 'linux'; }

        return operatingSystem;
    };

    const dispatch = useDispatch();

    const [linuxConfig] = useState("curl https://purevpn-dialer-assets.s3.amazonaws.com/cross-platform/linux-cli/production/cli-install.sh | sudo bash")
    const currentDevice = osName

    const processedDeviceName = getUserOperatingSystem().toLowerCase().replace(" ", "-");

    const envOsIconList = JSON.parse(process.env.REACT_APP_ENV_OS_BASED_ICON);
    const envBrowserIconList = JSON.parse(process.env.REACT_APP_ENV_BROWSER_EXTENSION_ICON);


    const downloadButtonClickHandle = (device = {},app = "") => {
        if(device?.abbreviation != "linux"){
            downloadClickCallback(device?.link, device?.heading, device?.osx, device?.abbreviation)
        }        
        if(device?.suggested_devices){
            setShowThankyouPage(true)
            const data= {id:device?.id,os:processedDeviceName,browser:browserGenerics() }
            const formdata = qs.stringify(data);
            setFormData(formdata)
        }
        engagementLoyaltyApi(JSON.stringify({ download: { product: appName?.toLowerCase() } }))
    }
    const betaButtonClickHandle = (device = {}) => {
        let os = device.abbreviation.includes("android")
        sendEvents({
            event_name:"ma_downloads_click_purevpn",
            event_properties:{
                cta: `download_${os?'android':'ios'}_beta`
            }
        }, token)
        window.open(device?.content_details?.betaCtaLink);
    }

    const versionClickHandle = (e,device) => {
        const selectedVersion = device?.versions.findIndex(version => version.name === e.target.value)
        setActiveDevices(download, devices, data, setData, download.devices?.body[selectedApp].slug, selectedApp, false, device.abbreviation, selectedVersion)
        
        if (versionClickCallback) {
            versionClickCallback("version_" + device?.versions[selectedVersion].name)
        }
    }

    const releaseNotesClickHandle = () => {
        releaseNotesClickCallback("release_notes_" + data.devices?.[0].abbreviation)
    }

    const openSetupGuides = (link, e) => {
        sendGuidesEvents(e);
        window.open(link)
    }

    const copyLinux = async (configuration,device) => {
        downloadButtonClickHandle(device)
		try {
			await navigator.clipboard.writeText(configuration);
			toast.success("Copied Successfully!");
			sendEvents(
				{
					event_name: "ma_downloads_click_purevpn",
					event_properties: {
						cta: `copy_command_cli`
					},
				},
				token
			);
		} catch (error) {
			toast.error("Unable to copy!");
		}
	};
    var i = 0;
    var browserNameinLowerCase = browserName.toLowerCase();
    let pureprivacyImgRatio = ['pureprivacy']
    const [multipleChild, setMultipleChild] = useState(false)
    let NumberOfDevice = 1;
    let deviceTemplate = data.devices.map((device, key) => {
        i = i+1;
        NumberOfDevice = data.devices.length + 1;
        let twoDevices = isPurpose(purpose) ? 6 : 4 
        let oneDevice = isPurpose(purpose) ? 12 : 6 
        var columnProportion = (data.devices.length == 2 || i > 1) ? twoDevices : oneDevice;
        columnProportion = (data.devices.length > 2 && i > 1) ? 6 : columnProportion;
        columnProportion = ((data.devices.length == 1 && (!data.extensionDevice || data.extensionDevice.abbreviation == device.abbreviation)) 
            || (data.devices.length > 2 && data.devices.length == i && i%2 == 0)) 
            ? 12 : columnProportion;
        if(!multipleChild && columnProportion == '4'){
            setMultipleChild(true)
        }
        return (
            <React.Fragment key={key}>
            {
                columnProportion >= 6 && i%2 == 0 && !isPurpose(purpose) ? ( <div className="col-sm-12">&nbsp;</div> ) : null
            }
            <div className={`col-lg-${columnProportion} col-md-12 col-sm-12`}>
                <SingleAppDownloadBox isPrivacy={pureprivacyImgRatio.includes(appName?.toLowerCase())} data-device-id={device?.id}>
                    {   
                        device.versions?.length > 0
                        ? (
                            <>
                                {
                                    device.content_details?.badge
                                    ? (<div className={`badge ${device.content_details?.beta ? "beta" : ""}`}>{device.content_details?.badgeText}</div>) : null
                                }
                                <div className='inner'>
                                    {
                                        device.img && device.img != "showIcon"?
                                        (
                                            <>
                                            <div className={`app-img ${data.extensionDevice ? "mtop" : ""}`}>
                                                <img src={device.img.default} className={`img-fluid ${data.extensionDevice?.slug != device.slug ? "hasExt" : ""}`} width={675} height={200}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <span className={`app-img icon ${userOs(window) == "linux" ? "icon-linux" : envOsIconList.processedDeviceName}`}></span>
                                            </>
                                        )
                                    }
                                    <h3 className='text-justify heading'>{device.slug === `web` ? `${appName} ${device.heading}` : `${appName} ${t('downloads_for')} ${device.heading}` || "-" }</h3>
                                    <p className='para'>{device?.content_details?.para}</p>
                                    {
                                        data.extensionDevice?.abbreviation != device?.abbreviation ? (
                                        <>
                                        <p className={device.abbreviation == 'apple_tv' ? null : "text-capitalize"}>{`OS: ${device?.osx}`}</p>
                                        {
                                            device.hash != "" ? (
                                                <p className="mb-0 para1">{`MD5 hash: ${device.hash}`}</p>
                                            ) : (
                                                <p className="mb-0 para1">&nbsp;</p>
                                            )
                                        }
                                    
                                        </>
                                        ) : null
                                    }
                                </div>
                                {device.abbreviation === "linux" ? 
                                    <>
                                            <p>{t('downloads_run_this_command_in_terminal')}</p>
                                        <div className='command-box'>
                                            <p>curl <span className='text-underline'>https://purevpn-dialer-assets.s3.amazonaws.com/cross-platform/linux-cli/production/cli-install.sh</span> | sudo bash</p>
                                        </div>
                                        <div className='copy-box mt-3' onClick={()=> copyLinux(linuxConfig,device)}>
                                            <p>{t('downloads_copy_command')} <img src={copy} className=""/></p>
                                        </div>
                                    </>
                                :
                                <div className='button-wrapper mt-0'>
                                        <ul className="list-unstyled list-inline">
                                        {
                                            device.content_details && device.content_details.beta_mob === true 
                                            && (
                                                    <li className="list-inline-item d-lg-none d-sm-block d-sm-none">
                                                        <SecondryBtn onClick={e => betaButtonClickHandle(device)}>{device?.content_details?.betaCtaText ? device?.content_details?.betaCtaText : t('downloads_download_beta')}</SecondryBtn>
                                                    </li>
                                            )
                                        }
                                        {
                                                device.versions && device.versions.length > 1 
                                                && (
                                                <li className="list-inline-item">
                                                    <Input type="select" name="select" className="deviceSelect" onChange={(e) => versionClickHandle(e,device, data.devices)}>
                                                        {
                                                            device.versions.map((version, key)=>(
                                                                <option key={key} value={version?.name}>{version?.name}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </li>
                                                )
                                            }
                                        <li className="list-inline-item"><PrimaryBtn onClick={e => downloadButtonClickHandle(device)}>{device?.content_details?.ctaText ? device?.content_details?.ctaText : `${t('downloads_download_applicatiion')}`}</PrimaryBtn></li>
                                        </ul>   
                                    </div>}
                            </>
                        ) : (
                            <>
                                <h3>{appName} {t('downloads_for')} {device.heading} {t('downloads_coming_soon')}</h3>
                            </>
                        )
                    }
                </SingleAppDownloadBox>
            </div>
            </React.Fragment>
        )
    });
    let column = 12/NumberOfDevice
    
    return (
        <div className={`row ${multipleChild && 'download-row'}`}>
            {
              data.extensionDevice && data.devices?.findIndex(device => device.abbreviation == data.extensionDevice?.abbreviation) == -1 && !isPurpose(purpose)
              ? (
                <div className={`col-lg-${column} col-md-12 col-sm-12`}>
                    <SingleAppDownloadBox data-device-id={data?.extensionDevice?.id}>
                        {
                            data.extensionDevice?.content_details?.badge
                            ? (<div className={`badge ${data.extensionDevice.content_details?.beta ? "beta" : ""}`}>{data.extensionDevice?.content_details?.badgeText}</div>) : null
                        }
                        <div className='inner'>
                            <div className={`app-img ${data.extensionDevice ? "mtop" : ""}`}>
                                <img src={data.extensionDevice.img.default} className={`img-fluid ${data.extensionDevice?.slug != data["devices"][0].slug ? "hasExt" : ""}`} width={675} height={200}/>
                            </div>
                            <h3 className='heading'>{appName === 'PureKeep' ? `${appName}  ${data.extensionDevice?.heading}` : `${appName} ${t('downloads_for')} ${data.extensionDevice?.heading}` || "-" }</h3>
                            <p className='para'>{data.extensionDevice?.content_details?.para}</p>
                            <p className="text-capitalize">&nbsp;</p>
                            <p className="mb-0 para1">&nbsp;</p>
                        </div>
                        <div className='button-wrapper mt-0'>
                            <PrimaryBtn onClick={e => downloadButtonClickHandle(data.extensionDevice,"extension")}>{data.extensionDevice?.content_details?.ctaText}</PrimaryBtn>
                        </div>
                        <LinkBtn onClick={(e) => openSetupGuides(data.extensionDevice?.content_details?.setup_guide_link, e)}>{data.extensionDevice?.content_details?.setup_guide_text}</LinkBtn>
                    </SingleAppDownloadBox>
                </div>
              ) : null
            }
            {deviceTemplate}
        </div>
    );
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(DownloadAppBox)