import { ERRORS, GETDEVICES, GETDEVICEREQUEST, SETDEVICESVIAONBOARDING, LOADING, GETDEVICESLOCALE } from "./types"

export const getDeviceRequested = (payload) => {
    return{
        type:GETDEVICEREQUEST,
        payload
    }
}

export const getDeviceType = (payload) => {
    return{
        type:GETDEVICES,
        payload
    }
}
export const getDeviceTypeLocale = (payload) => {
    return{
        type:GETDEVICESLOCALE,
        payload
    }
}

export const setDeviceViaOnBoarding = (payload) => {
    return{
        type:SETDEVICESVIAONBOARDING,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
