import axios from 'axios';
import { takeLatest, put, cancelled, call } from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { get, fetcher } from '../../tools/tools';
import { errorsType, getUpgradePlansType, getUpgradableSubscriptionsType, loadingType, upgradableSubscriptionsLoadingType, upgradableSubscriptionsErrorsType } from './actions';
import i18n from "i18next";

function* upgradePlansAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const responseData =  yield fetcher("upgrade/plans", action.payload, cancelTokenSource);
        const {data: response} = responseData;
        if(response?.status){
            yield put(getUpgradePlansType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (i18n.t(APIFAILED)) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchUpgradePlansRequest(){
    yield takeLatest("GETUPGRADEPLANSREQUEST", upgradePlansAsync)
}