import axios from 'axios';
import {takeLatest, put, cancelled} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher } from '../../tools/tools';
import { errorsType, getOnBoardingType, loadingType } from './actions';
import i18n from "i18next";

function* onBoardingAsync(action){
    const {token,locale} = action;
    const cancelTokenSource = axios.CancelToken.source();
    
    yield put(loadingType())
    try{
        const download = yield fetcher("on-boarding", token, cancelTokenSource)
        const {data: response} = download;
        if(response?.status){
            yield put(getOnBoardingType({...response,locale}))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (i18n.t(APIFAILED)) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
    
}

export function* watchOnBoardingRequest(){
    yield takeLatest("GETONBOARDINGREQUEST", onBoardingAsync)
}