import axios from 'axios';
import {takeLatest, put, cancelled} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster } from '../../tools/tools';
import { errorsType, getInvoicesType, loadingType } from './actions';
import i18n from "i18next";
import qs from 'qs';

function* invoiceAsync(action){
    const {token, data} = action.payload;
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const formdata = qs.stringify(data);
        const invoices = yield poster("subscription/invoices", formdata, token, cancelTokenSource);
        const {data: response} = invoices;
        response.subid = data.subscription_id;
        if(response?.status){
            yield put(getInvoicesType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({ code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchInvoicesRequest() {
    yield takeLatest('GETINVOICEREQUESTED', invoiceAsync)
}