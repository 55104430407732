import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GroupListWrapper } from '../../../styles/dashboard/Main'
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { determineOnBoardingActivationRemaining } from '../../../tools/genericMethods';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';

const GroupListView = ({accountReducer, onBoardingReducer, step, selectedStep, selectStepCallback, setActivation, appSlug, appName}) => {

    const history = useHistory()

    const token = localStorage.getItem("token");

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const loading = onBoardingReducer?.onBoardings ? false : true;

    useEffect(() => {

        setActivation(1)

    }, [])

    useEffect(() => {

        if (!loading) {

            let remainingOnBoarding = determineOnBoardingActivationRemaining(onBoardingReducer?.onBoardings, appSlug, onBoardingReducer.subscriptions)

            dispatch({ type: "NEXTONBOARDING", payload: remainingOnBoarding });
        }

    }, [onBoardingReducer?.onBoardings, loading])

    const skeleton = (html) => {
        return loading ? (
            <Skeleton height={20} width={300} className="onboarding-wrapper-loading"/>
        ) : html
    }

    return skeleton(
        <div className='row'>
            <GroupListWrapper className='col-lg-8'>
                {
                    typeof step.data.heading != undefined && step.data.heading != "" ?
                    (
                        <div className="top-heading"><h1 className='heading'>{step.data.heading}</h1></div>
                    ) : (<></>)
                }
                {

                    typeof step?.data?.list != undefined ?
                    (
                        <ul className='group-list-wrapper'>
                            {
                                step?.data?.list.map( (item, idx) => {
                                    return (
                                        <li className='item' key={idx}>
                                            <h2 className='sub-heading'>{item.sub_heading}</h2>
                                            {
                                                typeof item.text != undefined 
                                                ? (
                                                    <p className='para'>{item.text}</p>
                                                ) : null
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    ) : null
                }
            </GroupListWrapper>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(GroupListView)