import { ERRORS, GETFAMILYPLAN, GETFAMPLANREQUEST, LOADING,GETFAMILYPLANSRESET } from "./types"

export const getFamilyPlanRequested = (payload) => {
    return{
        type:GETFAMPLANREQUEST,
        payload
    }
}

export const getFamilyPlanType = (payload) => {
    return{
        type:GETFAMILYPLAN,
        payload
    }
}

export const getFamilyPlanReset = (payload) => {
    return{
        type:GETFAMILYPLANSRESET,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
