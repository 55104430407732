import React, { useState, useRef } from 'react';
import { connect, useDispatch} from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { sendEvents } from '../../../../tools/mpEvents';
import { poster } from '../../../../tools/tools';
import qs from 'qs'
import { ACTIONS } from '../../../../redux/pureai/reducer';

const WaitListModal = ({ pureAIStateReducer }) => {

    const token = localStorage.getItem("token");

    const dispatch = useDispatch();

    const { currentState } = pureAIStateReducer;

    const [isQ1Active, setIsQ1Active] = useState(false);
    const [isQ2Active, setIsQ2Active] = useState(false);

    const inputRef = useRef(null);

    const Spacer = () => <div className={!isQ1Active ? 'line disabled' : 'line'}></div>;
    
    const handleCheckboxChange = () => {
        setIsQ1Active(false);
        checkboxesRef.current.forEach((item) => {
            if (item.checkbox.checked) {
                setIsQ1Active(true);
            }
        });
    };
    
    const checkboxesRef = useRef([
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) }
    ]);
    
    const toggleWaitlistModal = () => {
        dispatch({type: ACTIONS.WAITLIST_MODAL, visibility: !currentState.showWaitlistModal});
    }
    
    const disableForm = (e) => {
        e.preventDefault()
    }

    const toggleQ2Active = (e) => {
        setIsQ2Active(e.target.value)
    }

    const joinAlphaCommunity = (e) => {
        dispatch({type: ACTIONS.CALL_API});
        dispatch({type:ACTIONS.WAITLIST_MODAL, visibility: false});
        e.preventDefault();
        let reason = { query: {} };
        reason.query.Q1 = "What specific motivations or needs have led you to consider using PureAI?";
        reason.query.A1 = [];
        checkboxesRef.current.forEach((item) => {
            if (item?.checkbox?.checked) {
                reason['query'].A1.push(item.label.innerText);
            }
        });
        reason.query.Q2 = "Q2: What specific features or benefits of PureAI sparked your interest in wanting to use it?";
        reason.query.A2 = inputRef.current.value;
        sendEvents({
            event_name: "ma_join_waitlist",
            event_properties: {
                "source": "Pure AI",
                "reason": reason
            }
        }, token)
        updateAudienceType("alpha");
    }

    const updateAudienceType = async (type) => {
        poster('public/setAudienceType', qs.stringify({ type: type }), token).then(response => {
            if (!response.status) {
                dispatch({type: ACTIONS.AUDIENCE_TYPE, audienceType: "Normal"});
            }
            return response;
        })
            .then(data => {
                dispatch({type: ACTIONS.AUDIENCE_TYPE, audienceType: "Alpha"});
            })
            .catch(error => {
                dispatch({type: ACTIONS.AUDIENCE_TYPE, audienceType: "Normal"});
            })
    }
    
    return (
        <Modal backdrop={currentState.showWaitlistModal} isOpen={currentState.showWaitlistModal} toggle={toggleWaitlistModal} wrapClassName="waitlistWrapper">
            <ModalHeader className='modal-header-waitlist' toggle={toggleWaitlistModal}>
                <span className='question-header' disabled={!isQ1Active} >Question 1</span>
                <Spacer />
                <span className='question-header' disabled={!isQ2Active}>Question 2</span>
            </ModalHeader>
            <ModalBody>
                <h3 className='modal-heading'>Submit this form to get immediate access!</h3>
                <form onSubmit={!isQ1Active || !isQ2Active ? disableForm : joinAlphaCommunity} className='searchBar'>
                    <span className='question'>1. What specific motivations or needs have led you to consider using PureAI?</span>
                    <div className='question-1-1 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[0].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[0].checkbox = el)} className='checkbox' name='q1-1[0]' onChange={handleCheckboxChange} />
                            I dont have access to ChatGPT in my region.
                        </label>
                    </div>
                    <div className='question-1-2 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[1].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[1].checkbox = el)} className='checkbox' name='q1-1[1]' onChange={handleCheckboxChange} />
                            I don't want to share my IP address with ChatGPT.
                        </label>
                    </div>
                    <div className='question-1-3 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[2].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[2].checkbox = el)} className='checkbox' name='q1-1[2]' onChange={handleCheckboxChange} />
                            Difficulty in finding the supported regions/platforms for a movie.
                        </label>
                    </div>
                    <div className='question-1-4 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[3].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[3].checkbox = el)} className='checkbox' name='q1-1[3]' onChange={handleCheckboxChange} />
                            I am a game enthusiast, always looking for cheap game prices.
                        </label>
                    </div>
                    <div className='question-1-5 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[4].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[4].checkbox = el)} className='checkbox' name='q1-1[4]' onChange={handleCheckboxChange} />
                            Just want to experience it. No particular need
                        </label>
                    </div>
                    <div className='question-1-6 question-checkbox'>
                        <label className='checkbox-label' ref={(el) => (checkboxesRef.current[5].label = el)}>
                            <input type='checkbox' ref={(el) => (checkboxesRef.current[5].checkbox = el)} className='checkbox' name='q1-1[5]' onChange={handleCheckboxChange} />
                            Other
                        </label>
                    </div>
                    <span className='question'>2. What specific features or benefits of PureAI sparked your interest in wanting to use it</span>
                    <textarea ref={inputRef} name='waitlistuse' onInput={toggleQ2Active} placeholder='Type here'></textarea>
                    <button disabled={!isQ1Active || !isQ2Active} className="joinButton">Unlock PureAI Access</button>
                </form>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(WaitListModal);