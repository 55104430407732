import en from "../lang/en.json";
import de from "../lang/de.json";
import es from "../lang/es.json";
import fr from "../lang/fr.json";
import nl from "../lang/nl.json";
import it from "../lang/it.json";
import ko from "../lang/ko.json";
import pt from "../lang/pt.json";
import ru from "../lang/ru.json";
import tr from "../lang/tr.json";
import zh from "../lang/zh.json";
import ja from "../lang/ja.json";


export const onBoardingMultiLang = {
    "fr":fr['onBoardings'],
    "de":de['onBoardings'],
    "es":es['onBoardings'],
    "en":en['onBoardings'],
    "nl":nl['onBoardings'],
    "it":it['onBoardings'],
    "ko":ko['onBoardings'],
    "pt":pt['onBoardings'],
    "ru":ru['onBoardings'],
    "tr":tr['onBoardings'],
    "zh":zh['onBoardings'],
    "ja":ja['onBoardings'],

}