import { EMPTYACCOUNT, ERRORS, GETACCOUNT, LOADING, UPDATESUBSCRIPTION, USERNAMEUPDATE } from "./types";

const initialState = {
    accounts: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const accountReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true, errors:false, errorType:"", errorMessage:""}
            break;
        case EMPTYACCOUNT:
            return initialState
            break;
        case GETACCOUNT:
            return  {...state, accounts:payload, loading:false, errors:false}
            break;
        case USERNAMEUPDATE: 
            return {...state, accounts:{...state.accounts, body:{...state.accounts.body, fullName: payload.fullName, firstName:payload.first, lastName:payload.last}}}
            break;
        case UPDATESUBSCRIPTION:
            return {...state, accounts:{...state.accounts, body:{...state.accounts.body, email_subscription_status: payload}}}
            break;    
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}