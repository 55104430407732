export const port_forwarding_with_dedicated_ip =
{
  en: {
    heading_1: "Dedicated IP With Portforwarding",
    para_1:  'Save more money and get a "Dedicated IP + Port Forwarding" Add-on at slashed rates. With this unique add-on, you are not only using a dedicated IP on your device but managing ports based on your tailored needs. This package is best suited for those individuals and companies that require remote access to web servers, computers and need to install P2P files.',
    heading_2:      'Top Benefits of this add-on',
    para_2: "",
    desc: [
      'Access remote web servers with bank-grade encryption',
      'Open ports on your computer without any security risks',
      'Get excess control over your company servers',
      'Use two add-ons at low rates and with no hidden fees' 
    ],
    cta: "Get this Add-on",
  },
  fr: {
    heading_1: "Redirection de port avec IP dédiée",
    para_1: "Économisez plus d'argent et obtenez un module complémentaire \"IP dédiée + redirection de port\" à des tarifs réduits. Avec ce module complémentaire unique, vous n'utilisez pas seulement une adresse IP dédiée sur votre appareil, mais vous gérez les ports en fonction de vos besoins personnalisés. Ce forfait est le mieux adapté aux particuliers et aux entreprises qui ont besoin d'un accès à distance aux serveurs Web, aux ordinateurs et qui ont besoin d'installer des fichiers P2P.",
    heading_2: "Principaux avantages de ce module complémentaire",
    para_2: "",
    desc: [
      `Accédez à des serveurs Web distants avec un cryptage de niveau bancaire`,
      `Ouvrez les ports de votre ordinateur sans aucun risque de sécurité`,
      `Obtenez un contrôle excessif sur les serveurs de votre entreprise`,
      `Utilisez deux modules complémentaires à bas prix et sans frais cachés`,
    ],
    cta: "Obtenez ce module complémentaire",
  },
  de: {
    heading_1: "Portweiterleitung mit dedizierter IP",
    para_1:  'Sparen Sie mehr Geld und erhalten Sie das Add-on "Dedicated IP + Port Forwarding" zu reduzierten Preisen. Mit diesem einzigartigen Add-on verwenden Sie nicht nur eine dedizierte IP auf Ihrem Gerät, sondern verwalten Ports basierend auf Ihren individuellen Anforderungen. Dieses Paket eignet sich am besten für Personen und Unternehmen, die Fernzugriff auf Webserver und Computer benötigen und P2P-Dateien installieren müssen.',
    heading_2: 'Top-Vorteile dieses Add-ons',
    para_2: "",
    desc: [
      'Greifen Sie auf entfernte Webserver mit banktauglicher Verschlüsselung zu',
      'Öffnen Sie Ports auf Ihrem Computer ohne Sicherheitsrisiken',
      'Erhalten Sie eine umfassende Kontrolle über Ihre Unternehmensserver',
      'Verwenden Sie zwei Add-Ons zu günstigen Preisen und ohne versteckte Gebühren'
    ],
    cta: "Holen Sie sich dieses Add-on",
  },
  zh: {
    heading_1: "一起使用專用 IP 和连接追转送",
    para_1: '節省更多錢並以大幅優惠的價格獲得“專用 IP + 連接追轉送”附加元件。有了這個獨特的附加組件，您不僅可以在裝置上使用專用 IP，還可以根據您的定制需求管理端口。此軟件包最適合那些需要遠程訪問網絡伺服器、計算機並需要安裝 P2P 文件的個人和公司。',
    heading_2: '此附加元件的主要好處',
    para_2: "",
    desc: [
      '使用銀行級加密訪問遠程網絡伺服器',
      '在您的計算機上打開端口沒有任何安全風險',
      '獲得對公司伺服器的過度控制',
      '以低價使用兩個附加元件，無隱藏費用'
    ],
    cta: "獲取此插件",
  },
  es: {
    heading_1: "Reenvío de puertos con IP dedicada",
    para_1:  'Ahorre más dinero y obtenga un complemento de "reenvío de puertos IP + dedicado" a tarifas cortadas. Con este complemento único, no solo usa una IP dedicada en su dispositivo, sino que administra puertos en función de sus necesidades personalizadas. Este paquete es el más adecuado para aquellas personas y empresas que requieren acceso remoto a servidores web, computadoras y necesitan instalar archivos P2P.',
    heading_2: 'Los principales beneficios de este complemento',
    para_2: "",
    desc: [
      'Acceder a servidores web remotos con cifrado de grado bancario',
      'Abra puertos en su computadora sin ningún riesgo de seguridad',
      'Controle en exceso sobre los servidores de su empresa',
      'Use dos complementos a tasas bajas y sin tarifas ocultas'
    ],
    cta: "Obtenga este complemento",
  },
  ru: {
    heading_1: "Переадресация порта с выделенным IP",
    para_1:  'Сэкономьте больше денег и получите надстройку «Выделенные IP + порта» по срезанным ставкам. С помощью этого уникального дополнения вы используете не только выделенный IP на своем устройстве, но и управлять портами на основе ваших адаптированных потребностей. Этот пакет лучше всего подходит для тех людей и компаний, которым требуется удаленный доступ к веб -серверам, компьютерам и необходимо установить файлы P2P.',
    heading_2: 'Главные преимущества этого дополнения',
    para_2: "",
    desc: [
      'Доступ к удаленным веб-серверам с шифрованием банковского уровня',
      'Откройте порты на вашем компьютере без каких -либо рисков безопасности',
      'Получите избыточный контроль над серверами вашей компании',
      'Используйте два дополнения по низким показателям и без скрытых сборов' 
    ],
    cta: "Получите это дополнение",
  },
  tr: {
    heading_1: "Özel IP ile bağlantı noktası yönlendirme",
    para_1: 'Daha fazla para tasarruf edin ve eğimli oranlarda "özel IP + bağlantı noktası yönlendirme" eklentisi alın. Bu benzersiz eklenti ile, yalnızca cihazınızda özel bir IP kullanmazsınız, aynı zamanda özel ihtiyaçlarınıza göre bağlantı noktalarını yönetirsiniz. Bu paket, web sunucularına, bilgisayarlara uzaktan erişim gerektiren ve P2P dosyaları yüklemesi gereken bireyler ve şirketler için en uygun olanıdır.',
    heading_2: 'Bu eklentinin en iyi avantajları',
    para_2: "",
    desc: [
      'Banka sınıfı şifrelemeyle uzak web sunucularına erişin',
      'Herhangi bir güvenlik riski olmadan bilgisayarınızda bağlantı noktalarını açın',
      'Şirket sunucularınız üzerinde aşırı kontrolü alın',
      'Düşük oranlarda iki eklenti kullanın ve gizli ücretler yok'
    ],
    cta: "Bu eklentiyi alın",
  },
  pt: {
    heading_1: "Encaminhamento de porta com IP dedicado",
    para_1: 'Economize mais dinheiro e obtenha um complemento "encaminhamento de porta IP + dedicada a taxas com corte. Com este complemento exclusivo, você não está apenas usando um IP dedicado no seu dispositivo, mas também de gerenciamento de portas com base nas suas necessidades personalizadas. Este pacote é mais adequado para os indivíduos e empresas que exigem acesso remoto a servidores da Web, computadores e precisam instalar arquivos P2P.',
    heading_2: 'Os principais benefícios deste complemento',
    para_2: "",
    desc: [
      'Acesse servidores remotos da Web com criptografia de nível bancário',
      'Abra as portas no seu computador sem riscos de segurança',
      'Obtenha excesso de controle sobre os servidores da sua empresa',
      'Use dois complementos a taxas baixas e sem taxas ocultas' 
    ],
    cta: "Obtenha este complemento",
  },
  ja: {
    heading_1: "専用のIPを使用したポート転送",
    para_1: 'より多くのお金を節約し、スラッシュレートで「専用のIP +ポート転送」アドオンを取得します。このユニークなアドオンを使用すると、デバイスで専用のIPを使用するだけでなく、カスタマイズされたニーズに基づいてポートを管理しています。このパッケージは、Webサーバー、コンピューターへのリモートアクセスを必要とする個人や企業に最適です。P2Pファイルをインストールする必要があります。',
    heading_2: 'このアドオンの最高の利点',
    para_2: "",
    desc: [
      'バンクグレードの暗号化を備えたリモートWebサーバーにアクセスします',
      'セキュリティリスクなしにコンピューターにポートを開きます',
      '会社サーバーを過剰に制御します',
      '2つのアドオンを低料金で、隠された料金なしで使用します' 
    ],
    cta: "このアドオンを取得します",
  },
  it: {
    heading_1: "Port Forwarding con IP dedicato",
    para_1: 'Risparmia di più e ottieni un componente aggiuntivo "dedicato IP + Port Forwarding" a tariffe. Con questo componente aggiuntivo unico, non stai solo utilizzando un IP dedicato sul tuo dispositivo, ma gestendo le porte in base alle tue esigenze su misura. Questo pacchetto è più adatto a quelle persone e aziende che richiedono accesso remoto a server Web, computer e devono installare file P2P.',
    heading_2: 'I migliori vantaggi di questo componente aggiuntivo',
    para_2: "",
    desc: [
      'Accedi ai server Web remoti con crittografia di livello bancario',
      'Apri le porte sul computer senza rischi per la sicurezza',
      'Ottieni il controllo in eccesso sui server dell\'azienda',
      'Utilizzare due componenti aggiuntivi a tassi bassi e senza commissioni nascoste'
    ],
    cta: "Ottieni questo componente aggiuntivo",
  },
  nl: {
    heading_1: "Port Forwarding con IP dedicato",
    para_1:  'Bespaar meer geld en ontvang een "toegewijde IP + Port Forwarding" add-on tegen gesneden tarieven. Met deze unieke add-on gebruikt u niet alleen een speciaal IP op uw apparaat, maar beheert u poorten op basis van uw op maat gemaakte behoeften. Dit pakket is het meest geschikt voor personen en bedrijven die externe toegang tot webservers, computers nodig hebben en P2P -bestanden moeten installeren.',
    heading_2: 'Topvoordelen van deze add-on',
    para_2: "",
    desc: [
      'Toegang tot externe webservers met bankcodering',
      'Open poorten op uw computer zonder beveiligingsrisico\'s',
      'Krijg overtollige controle over uw bedrijfsservers',
      'Gebruik twee add-ons tegen lage tarieven en zonder verborgen kosten'
    ],
    cta: "Koop deze add-on",
  },
  ko: {
    heading_1: "전용 IP로 포트 전달",
    para_1:  '더 많은 비용을 절약하고 슬래시 요금으로 "전용 IP + 포트 전달"애드온을 받으십시오. 이 고유 한 애드온을 사용하면 장치에 전용 IP를 사용 할뿐만 아니라 맞춤형 요구 사항을 기반으로 포트를 관리합니다. 이 패키지는 웹 서버, 컴퓨터에 원격 액세스하고 P2P 파일을 설치 해야하는 개인 및 회사에 가장 적합합니다.',
    heading_2: '이 애드온의 최고 이점',
    para_2: "",
    desc: [
      '은행 등급 암호화로 원격 웹 서버에 액세스하십시오',
      '보안 위험없이 컴퓨터에 포트를 열어줍니다',
      '회사 서버에 대한 초과 제어를 받으십시오',
      '낮은 속도로 두 개의 애드온을 사용하여 숨겨진 수수료가 없습니다.'
    ],
    cta: "이 애드온을 얻으십시오",
  },
}