import React, { useState } from 'react'
import {  PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import qs from 'qs'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { poster, seti18Locale , checkLocale } from '../../../../tools/tools';
import { withRouter } from 'react-router-dom';
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions';
import { useTranslation } from 'react-i18next';

const ReactivateSubscription = ({history, backdrop, toggle, modal, modalWidth, widthCalculator, selectedSubscription}) => {

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const token = localStorage.getItem("token")

    const pauseSubscription = async () => {
        setloading(true)
        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscription
            })
            const reactivate =  await poster("subscription/reactivate", formdata, token)
            const {data: response} = reactivate;
            if(response?.status){
                dispatch(getSubscriptionsRequest(token))
                dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                toast.success("Your subscription has been successfully resumed")
                toggle()
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "declined":
                        toast.error(response?.message)
                        break;
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;
                    case "recurly_exception":
                        toast.error(response?.message)
                        break;
                    case "three_d_secure_action_required":
                        toast.error(response?.message)
                        break; 
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;  
                    default:
                        toast.error(response?.message);
                        break;
                }
            }
        } catch (error) {
            toast.error(t(APIFAILED))
        }
        finally{
            setloading(false)
        }
    }

    const buttonText = () => {
        return !loading ? "Resume Subscription" : ( <>Resume Subscription <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
                <ModalBody className="pb-0">
                    <PopupBody>
                        <div className="mod-head">
                            <h3>Resume Subscription</h3>
                            <p>Are you sure you want to Resume Subscription?</p>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <PrimaryBtn onClick={pauseSubscription} pointer={loading} color="primary">{buttonText()}</PrimaryBtn>
                    <DestructBtn color="secondary" onClick={toggle}>Cancel</DestructBtn>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default withRouter(ReactivateSubscription)