import { GETDEVICES,ERRORS,SUCCESS_DEVICES } from "./types";

const initialState = {
  devices: null,
  loading: false,
  errors:false,
  errorType:"",
  errorMessage:""
};

const wgDevices = (state=initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case GETDEVICES:
      return { ...state, loading: true, errors:false };
      break;
    case SUCCESS_DEVICES:
      return { ...state, loading: false, devices: payload.devices ,errors:false };
      break;
    case ERRORS:
      return { ...state, loading: false, errors:true, errorMessage: payload.message , errorType:payload.code };
      break;
    default:
      return {...state};
      break;
  }
};

export default wgDevices;
