import { ListingStyles } from "../styles/dashboard/Listing";
import Checkbox from "./checkbox";
import trash from "../assets/trash.png";
import { dateFormat } from "../tools/tools"
import { useTranslation } from "react-i18next";

const Listing = ({
    headerList,
    currentTableData,
    onClickDelete,
    renderDynamicItems,
    isRmd,
    renderHeaderItems
}) => {

    const { t } = useTranslation();

    const renderListItem = (list) => {
        let isParent = list.type != 'parent' ? true : false;
        return <>
            {/* <div className="column-title">
                <Checkbox className="purple" disabled={!isParent} onChange={onSelectCheckbox} data={[list.uuid]} checked={checkedItems.includes(list.uuid)}/>
            </div> */}
            <div className="column-title">
                <h5><div className="email">{list.email ?? '--'}</div>{!isParent && <span className="status">ADMIN</span>}</h5>
            </div>
            <div className="column-title">
                { isParent && <h5>{dateFormat(list.created_at) ?? '--'}</h5>}
            </div>
            <div className="column-title">
                {isParent && <div className="delete-icon" onClick={()=>onClickDelete(list.uuid, list.email)}>
                    <img src={trash} alt="delete icon" />
                </div>}
            </div>
        </>
    }
    return <ListingStyles>
        <div className={`${isRmd ? 'rmd-header' : 'listing-header'}`}>
            <div className="column-wrapper">
                {/* <div className="column-title">
                    <Checkbox disabled={currentTableData.length <= 0 ? true : false} onChange={onSelectAll} data={currentTableData.map(a => a?.uuid)} checked={checkedItems.length > 0}/>
                </div> */}
                {headerList ? headerList.map((title)=>{
                    return <div className="column-title">
                    <h6 className="title">{title}</h6>
                </div>
                }) : renderHeaderItems()}
            </div>
        </div>
        <div className={`${isRmd ? 'rmd-content' : 'listing-content'}`}>
            {currentTableData.length ? currentTableData.map((list, index)=> {
                return <div className={`${!isRmd ? `${list.role} column-wrapper`: null} `}>{!headerList ? renderDynamicItems(list, index) : renderListItem(list)}</div>
            }) : <h6 className="text-center pt-4 pb-4">{isRmd ? t('data_broker_not_found') : 'Members not found'}</h6>}
        </div>
    </ListingStyles>
}

export default Listing;