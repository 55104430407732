import { withRouter } from "react-router-dom";
import CancellationGenericOptions from "./cancellationGenericOptions";
import CancellationNewPurchaseOptions from "./cancellationNewPurchaseOptions";
import { 
    constants, CancellationFlowActions, CancellationFlowConditionalActions, CancellationOptionsStyles, dispatchError, displayError, getSubscriptionsRequest, getTokenFromLocalStorage, getUserOperatingSystem, Link, poster, QRcodeGenerator, qs, sendEvents, Textarea, toast, useDispatch, useState, useEffect, Warning,
} from "./index"

const CancellationOptions = ({
    commentChangeHandler,
    component,
    emptyFieldError,
    history,
    initialReason,
    optionsList,
    selectedSubscription,
    sendMixPanelEvents,
    setCta,
    setLoading,
    setPlatformInfo,
    setRelatedQuestionId,
    setSelectedActionOnOption,
    setSelectedOption,
    setShowTextarea,
    setSubmitReasonButton,
    showTextarea,
    stopSubscriptionRequest,
    toggle,
    triggerReasoningEvents,
    viewDownGradeSummary,
    setInitialReason,
    sendMixPanelEventsOnView,
    cancellationArray,
    selectionOption,
    para
}) => {
    
    const [options, setOptions] = useState();
    const dispatch = useDispatch();

    // show qrCode on selecting troubleshoot button
    const [showQrCode, setShowQrCode] = useState(false);

    useEffect(()=>{
        setOptions(optionsList)

        if(optionsList?.some(option => option['action'] === CancellationFlowActions.validatePlatform )){
            sendEvents({
                event_name:"ma_view_platfrom_cancellation",
                event_properties:{}
            }, getTokenFromLocalStorage())
        }
        else if(optionsList?.some(option => option['action'] === CancellationFlowActions.runTroubleshoot) || optionsList?.some(option => option['action'] === CancellationFlowActions.firebaseTroubleshoot)){
            sendEvents({
                event_name:"ma_view_troubleshoot_cancellation",
                event_properties:{
                    'initialReason' : initialReason ? initialReason : cancellationArray.length === 1 ? initialReason : "",
                    'solutionReason': cancellationArray.length === 1 ? "" : selectionOption,
                }
            }, getTokenFromLocalStorage())
        }
        else{
            if(initialReason){
                sendMixPanelEventsOnView()
            }
        }

        if(showQrCode){
            setShowQrCode(!showQrCode)
        }
    }, [optionsList])

    const onSelectingAnOption = (params) =>{
        
        let {name, action, relatedQuestionId, optionId, value} = params;

        setSelectedOption(name)
        setSelectedActionOnOption(action);
        setShowTextarea({
            show: false,
            option: '',
            id: ''
        });
        setSubmitReasonButton(false)

        if(cancellationArray.length === 1){
            setInitialReason(name)
        }

        // * since we won't be passing mix panel on events that open a text book or on validate platform or troubleshoot
        if( !CancellationFlowConditionalActions.includes(action) && action !== CancellationFlowActions.validatePlatform && !action && action !== CancellationFlowActions.firebaseTroubleshoot
        // ! IMPORTANT : Add the restrictive action in the "CancellationFlowConditionalActions" array
        ) {
            sendMixPanelEvents({name});    
        }

        if(action !== CancellationFlowActions.stopSubscription){

            switch(action){

                case CancellationFlowActions.validatePlatform:
                    onValidatePlatform({name, action, optionId});
                    sendEvents({
                        event_name:"ma_click_platform_cancellation",
                        event_properties:{
                            "platform" : getUserOperatingSystem().toLowerCase(),
                            "reason" : name
                        }
                    }, getTokenFromLocalStorage())
                    break;

                case CancellationFlowActions.openLiveChat:
                    triggerReasoningEvents({name, relatedQuestionId});
                    break;

                case CancellationFlowActions.cancelSubscriptionWithReason: 
                case CancellationFlowActions.getUserReason:
                case CancellationFlowActions.askReasonForNotSatisfiedWithAddon:
                    triggerReasoningEvents({name, relatedQuestionId});
                    break;

                case CancellationFlowActions.cancelSubscriptionRequest:
                    setCta({
                        extra_params: {
                            cta: 'cancel'
                        }
                    })
                    break;

                case CancellationFlowActions.redirectToFirebaseBlog:
                    openExternalUrl(value);
                    break;

                case CancellationFlowActions.openLiveChatWithoutText:
                    window.Intercom('show');
                    break;

                case CancellationFlowActions.purchaseFreeAddons:
                    sendPurchaseRequest(value)
                    break;

                case CancellationFlowActions.purchaseVPNAddons:
                    showOrderSummaryModal(value)
                    break;

                case CancellationFlowActions.familyPlanUpgrade:
                    showFamilyPlanSummary(value)
                    break;

                case CancellationFlowActions.upgradePlans:
                    showUpgradePlanSummary(value)
                    break;

                case CancellationFlowActions.requestForCredit:
                    sendRequestForCredit(value)
                    break;

                case CancellationFlowActions.purchaseMonthlyForFiveDollar:
                    sendRequestForFiveDollar(value)
                    break;
                
                case CancellationFlowActions.requestForAccountExtension:
                    sendAccountExtensionRequest(value)
                    break;

                case CancellationFlowActions.downgradePlans:
                    viewDownGradeSummary(value)
                    break;

                case CancellationFlowActions.firebaseTroubleshoot:
                    sendEvents({
                        event_name:"ma_click_troubleshoot_cancellation",
                        event_properties:{}
                    }, getTokenFromLocalStorage())
                    break;

                case CancellationFlowActions.openChatbot:
                    const values = value.split('|')
                    window.location.hash = values[1];
                    window.Intercom('shutdown')
                    window.Intercom('boot', { content: { id: values[0] }, message: true })
                    break;

            }

            // ! in order to prevent re-render, not updating the related question id here
            if(relatedQuestionId != null && !CancellationFlowConditionalActions.includes(action)){
                setRelatedQuestionId(relatedQuestionId)
            }
            
        }
        else{
            sendEvents({
                event_name:"ma_cancel_subscription",
                event_properties:{}
            }, getTokenFromLocalStorage())
            stopSubscriptionRequest()
        }
    }

    const onValidatePlatform = (payload) => {

        const { name, action, optionId } = payload;

        let isPlatformSame = checkPlatform(name.toLowerCase());
        setPlatformInfo({
            action_response: isPlatformSame === false ? 0 : 1, 
            OS: getUserOperatingSystem().toLowerCase(),
            action: action,
            selected_option_id: optionId
        })

    }

    const checkPlatform = (name) => {
        return name.includes(getUserOperatingSystem())
    }

    const openExternalUrl = (link) => {
        window.open(link, '_blank')
    }

    const onOptionButtonClick = (payload) => {

        let {btn, option} = payload;

        if(btn?.redirection_url ){

            sendEvents({
                event_name:"ma_click_troubleshoot_cancellation",
                event_properties:{}
            }, getTokenFromLocalStorage())

            // * In order to unhide the other options on troubleshoot, after some time
            setTimeout(()=>{
                const modifiedOptions = options.map(o => ({ ...o, is_hide: 0}));
                setOptions(modifiedOptions);
            }, process.env.REACT_APP_CANCELLATION_TROUBLESHOOT_TIMEOUT)

            if(!btn?.generate_qr_code){
                openExternalUrl(btn?.redirection_url);
            }

            if(btn?.generate_qr_code){
                setShowQrCode(!showQrCode)
            }
        }
        else if(option?.action === CancellationFlowActions.talkToSupport){
            window.Intercom('show')
        }
        else if(
                option?.action === CancellationFlowActions.purchaseAllNewAddons || 
                option?.action === CancellationFlowActions.upgradePlans || 
                option?.action === CancellationFlowActions.downgradePlans || 
                option?.action === CancellationFlowActions.purchaseFreeAddons || 
                option?.action === CancellationFlowActions.purchaseBundles || 
                option?.action === CancellationFlowActions.purchaseVPNAddons
            ){
           let {name, action, related_question_id:relatedQuestionId, option_id:optionId, value} = option;
            onSelectingAnOption({name, action, relatedQuestionId, optionId, value});
        }
        else{
            window.Intercom('show')
        }
    }

    // in case of get new product
    const sendPurchaseRequest = async (value) => {
        try{
            setLoading(true)
            let params = {
                name: value.split(",")
            }
            const requestData = qs.stringify(params);
            const response =  await poster("campaign/addons", requestData, getTokenFromLocalStorage());
            if(response?.data?.status){
                toast.success(response?.data?.message)
            }
            else{
                dispatchError(response?.error_code, dispatch);
            }
        }
        finally{
            toggle();
            setLoading(false)
        }
    }

    // incase of dedicated ip and port forwarding, open cart summary
    const showOrderSummaryModal = (value) => {
        history.push({
            search: `?id=${selectedSubscription?.id}&add_on=${value}`
        })
        toggle();
    }

    const showFamilyPlanSummary = (value) =>{
        history.push({
            pathname: '/dashboard/family-plan',
            search: `?id=${selectedSubscription?.id}&code=${value}`
        })
        toggle();
    }

    const showUpgradePlanSummary = (value) => {
        history.push({
            pathname: "/dashboard/upgrade",
            search: `?id=${selectedSubscription?.id}&plan_code=${value}`
        })
        toggle();
    }

    const sendRequestForCredit = async (value) => {
        try {
            setLoading(true)
            const formdata = qs.stringify({
                subscription_id: selectedSubscription?.id,
                billingCycle: value,
            })

            const pause =  await poster("cancel/credit-amount", formdata, getTokenFromLocalStorage());
            const {data: response} = pause;

            if(response?.status){
                toast.success('success')
            } else {
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            displayError(error);
        }
        finally{
            setLoading(false)
            toggle()
        }
    }

    const sendRequestForFiveDollar = async (value) => {
        try {
            setLoading(true)
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.id,
                plan_code: value,
                preview: 0,
                isPastDue: constants.past_due_state.includes(selectedSubscription?.state),
                avoidStacking: true
            })

            const pause =  await poster("subscription/change", formdata, getTokenFromLocalStorage());
            const {data: response} = pause;

            if(response?.status){
                toast.success(response?.message ?? 'success')
            } else {
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            displayError(error);
        }
        finally{
            setLoading(false)
            toggle()
        }
    }

    const sendAccountExtensionRequest = async (value) => {
        try {
            setLoading(true)
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.id,
                billingCycle: value
            })

            const pause =  await poster("cancel/account-extension", formdata, getTokenFromLocalStorage());
            const {data: response} = pause;

            if(response?.status){
                toast.success(response?.message ?? 'success')
            } else {
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            displayError(error);
        }
        finally{
            setLoading(false)
            toggle()
        }
    }
    
    return  <CancellationOptionsStyles>
        <div className={`${!para ? 'no-para-content' : null} content-body`}>
            {component !== 'readonly_with_new_purchase' ? 
                <CancellationGenericOptions 
                    commentChangeHandler={commentChangeHandler}
                    emptyFieldError={emptyFieldError}
                    showTextarea={showTextarea}
                    showQrCode={showQrCode}
                    options={options}
                    onSelectingAnOption={onSelectingAnOption}
                    onOptionButtonClick={onOptionButtonClick}
                /> : 
                <CancellationNewPurchaseOptions 
                    onSelectingAnOption={onSelectingAnOption}
                    onOptionButtonClick={onOptionButtonClick}
                    showQrCode={showQrCode}
                    options={options}
                />
            }
            {component === 'cancel_component' ? <div className="warning">
                <Warning />
                <p>Once cancelled, your subscription will expire on <span>{selectedSubscription?.end_date}</span>However, you'll still be able to use PureVPN till then.</p>
            </div> : null}
        </div>
    </CancellationOptionsStyles>
}
export default withRouter(CancellationOptions);