import { connect, useDispatch } from 'react-redux';
import { checkAccountType, deletion, getPaymentMethods } from '../../../../tools/tools';
import { FamilyPlanWrapper } from '../../../../styles/dashboard/Main';
import { getByParam, getTokenFromLocalStorage, dispatchError } from '../../../../tools/tools';
import { getTicketsRequested } from '../../../../redux/tickets/actions';
import { sendEvents } from '../../../../tools/mpEvents';
import { SubscriptionBox, InfoCard } from '../../../../styles/dashboard/Main';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { vsprintf } from 'sprintf-js';
import CopyLinkPopup from '../modal/CopyLinkPopup';
import FamilyPlanInvite from '../child/FamilyPlanInvite';
import FPAccount from "../../../../assets/fp-account.png";
import FPLink from "../../../../assets/fp-link.png";
import NotSupported from '../../subscriptions/modals/NotSupported';
import qs from 'qs';
import React, { useState, useEffect } from 'react';
import Removal from '../modal/removal';
import Skeleton from 'react-loading-skeleton';
import trash from "../../../../assets/trash.png";
import { withRouter } from 'react-router-dom';

const FamilyPlanUserDetail = ({selectedSubscriptionData, history, hasFamilyPlan, onClickScroll, parentDetails, extractEventProp, planCode, planGroup, ticketsReducer, getTicketsRequested}) => {
    const [existingAccount, setExistingAccount] = useState();
    const [loading, setLoading] = useState(true);
    const [maxAccount, setMaxAccount] = useState();
    const [membersDetails, setMembersDetails] = useState({});
    const [modal, setModal] = useState(false);
    const [removalModal, setRemovalModal] = useState();
    const [removalUuid, setRemovalUuid] = useState();
    const [waitModal,showWaitModal] = useState(false);
    const { t } = useTranslation();
    const [notsupportedState, setNotsupportedState] = useState({
        type:"fpExpired",
        gateway:""
    })

    const dispatch = useDispatch();

    const token = getTokenFromLocalStorage();

    const [modalWidth] = useState({lg: "675px",sm: "440px"});
    const [removalModalWidth] = useState({lg: "460px",sm: "340px"});

    const widthCalculator = "lg";
    const eligibleToInvite = ['active'];

    const toggle = () => setModal(!modal);
    const toggleWaitModal = () => showWaitModal(!waitModal);
    const toggleRemovalModal = () => setRemovalModal(!removalModal);

    useEffect(()=>{
        getFamilyPackage()
    }, [planCode])

    const getEmptyLinkBox = (details, max) => {
        return max ? [...Array(max-details).keys()] : []
    }

    async function getFamilyPackage(){
        setLoading(true)
        const membersDetail = await getByParam(`familyplan/get-child-accounts`, {
            plan_code: planCode,
            plan_group: planGroup,
        }, token)
        const {data: response} = membersDetail;
        if(response?.status){
            setMembersDetails(response?.body)
            setMaxAccount(response?.body?.max_child_count)
            setExistingAccount(response?.body?.details?.length)

        }else{
            dispatchError(response?.error_code, dispatch);
        }
        setLoading(false)
    }

    const removeChild = async () => {

        toggleRemovalModal();

        const formdata = qs.stringify({child_code: removalUuid})
        const removeResponse =  await deletion("familyplan/del-child-account", formdata, token)
        const {data: response} = removeResponse;
        if(response?.status){
            toast.success(response?.message)
            getFamilyPackage();
        }else{
            dispatchError(response?.error_code, dispatch);
        }
        sendEvents({
            event_name:"ma_delete_child",
            event_properties:{}
        }, token)
    }

    const openRemovalModal = (uuid) => {
        sendEvents({
            event_name:"ma_click_delete_child",
            event_properties:{}
        }, token)
        setRemovalUuid(uuid)
        toggleRemovalModal();
    } 

    const checkHasFamilyPlan = (type) => {
        let currentDate = new Date();
        
        if(type !== 'parent' && type !== 'child'){
            
            if(!hasFamilyPlan){
                onClickScroll(true)
            }else{
                let eventsProp = extractEventProp(parentDetails)
                if(eligibleToInvite.includes(parentDetails?.current_plan?.state)){
                    setModal(!modal)  
                    sendEvents({
                        event_name:"ma_click_add_child",
                        event_properties:{
                            ...eventsProp,
                            plan_type: membersDetails?.max_child_count == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : 'family'
                        }
                    }, token)
                }
                else{
                    showWaitModal(true)
                    sendEvents({
                        event_name:"ma_fail_add_child",
                        event_properties:{
                            ...eventsProp,
                            plan_type: membersDetails?.max_child_count == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : 'family'
                        }
                    }, token)
                }

            }
            
        }
    }

    const onClickAddMoreMembers = () => {
        sendEvents({
            event_name:"ma_click_explore_teams",
            event_properties:{
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                payment_method: selectedSubscriptionData?.current_plan?.payment_gateway ?? 'N/A',
                current_status: selectedSubscriptionData?.current_plan?.state ?? 'N/A',
                state: 'post-purchase',
            }
        }, token)
        history.push('/dashboard/teams')
    }
    return (
        <div>
            <FamilyPlanWrapper>

            {!loading ? 
               <>
                    <FamilyPlanInvite disable={!eligibleToInvite.includes(parentDetails?.current_plan?.state)} existingAccount={existingAccount} maxAccount={maxAccount} parentDetails={parentDetails} extractEventProp={extractEventProp}/>

                    <section className="family_mambers_detail text-center mt-2">
                        <h3>{t('family_family_member')}</h3>
                        
                        <p className="para">{vsprintf(t('family_family_para'),`${getEmptyLinkBox(membersDetails?.details?.length, membersDetails?.max_child_count).length+1}`)}</p>
                        <h6 className='mb-4 add-member' onClick={onClickAddMoreMembers}>{t('add_more_members')}</h6>
                        
                        <div className='invite-boxes'>
                            {membersDetails?.details?.length > 0 && membersDetails?.details.map((details)=>{
                                return <SubscriptionBox className='fp-invites'>
                                    {details?.type !== 'parent' ? <div className="delete-fp" onClick={()=>{openRemovalModal(details?.uuid)}}><img src={trash} alt="remove" /></div>: null}
                                    <figure>
                                        <img src={FPAccount} alt="details" />
                                    </figure>
                                    <h5>{details.email}</h5>
                                    {details?.type !== 'parent' ? <p className='name'>{details.name}</p>: <span className='manager'>{t('familyplan_userdetails_family_manager')}</span>}
                                </SubscriptionBox>
                            })}
                            {getEmptyLinkBox(membersDetails?.details?.length, membersDetails?.max_child_count).map((details)=>{
                                return <SubscriptionBox className='fp-invites'>
                                    <figure onClick={()=>checkHasFamilyPlan()}>
                                        <img style={{cursor: 'pointer'}} src={FPLink} alt="invite" />
                                    </figure>
                                    <h5>{t('familyplan_userdetails_invite_family_member')}</h5>
                                    <p className='name'>{`${t('only_text')} ${getEmptyLinkBox(membersDetails?.details?.length, membersDetails?.max_child_count).length} ${t('family_invites_left')}`}</p>
                                </SubscriptionBox>
                            })}
                        </div>
                    </section>
               </>

                :
                <SubscriptionBox open={true}>
                    <InfoCard hasborder noflex className="mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton hei   ght={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                </SubscriptionBox> 
            }
            </FamilyPlanWrapper>
            {
                modal && <CopyLinkPopup maxAccount={maxAccount} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={modal} toggle={toggle} parentDetails={parentDetails} extractEventProp={extractEventProp}/>
            }
            {
                removalModal && <Removal backdrop={false} modalWidth={removalModalWidth} widthCalculator={widthCalculator} modal={removalModal} toggle={toggleRemovalModal} removeChildRequest={removeChild}/>
            }
            {
                waitModal && <NotSupported backdrop={false} notsupportedState={notsupportedState} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={showWaitModal} toggle={toggleWaitModal}/>
            }
        </div>
    )
    
}

const mapStateToProps = (state) => {
    return {
        ticketsReducer: state.ticketsReducer
    };
};

export default connect(mapStateToProps, {getTicketsRequested})(withRouter(FamilyPlanUserDetail));