import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PureAIChatView, AvatarDiv, StopGeneratingBtn } from '../../../../styles/dashboard/PureAI';
import TextLoader from './TextLoader';
import InnerHTML from 'dangerously-set-html-content'
import PureAIChatHistory from './PureAIChatHistory';
import MessageBox from './MessageBox';
import SuggestionsBar from './SuggestionsBar';
import { ACTIONS } from '../../../../redux/pureai/reducer';

const PureAIChat = ({ pureAIStateReducer, setAblyObject, setAblyChannel, ablyChannel, ablyObject, evtSource, setEvtSource }) => {
    
    const { currentState } = pureAIStateReducer;

    const inputRef = useRef(null);

    const [ scrollButton, setScrollButton ] = useState(false);

    const responseDisplay = useRef(null);

    let cursor = currentState.streaming ? `<div class="cursor"></div>` : "";

    const dispatch = useDispatch()

    const handleAutoScroll = () => {
        if(!scrollButton){
            if(responseDisplay?.current && responseDisplay.current.scrollHeight > responseDisplay.current.clientHeight) {
                responseDisplay.current.scrollTop = responseDisplay.current.scrollHeight;
            }
        }
    }

    const handleScroll = () => {
        let element = responseDisplay?.current;
        return setScrollButton(!(element?.scrollHeight - element?.scrollTop <= element?.clientHeight+50));
    }

    const handleStopGenerating = () => {
        dispatch({ type:ACTIONS.STREAM_STOP })
    }

    useEffect(() => {
        handleAutoScroll();
    });

    return (
        <>
            <PureAIChatView className='response-display' >
                <div className='chatBox' ref={responseDisplay} onScroll={handleScroll}>
                    <div className='chatBoxBody'>
                        <PureAIChatHistory />
                        {
                            currentState?.prompt !== "" ? (
                                <div className='prompt-response-view'>
                                    <div className='sub-prompt'>
                                        <h6 className='prompt-text'>
                                            <AvatarDiv className='avatarSize'>{currentState?.email?.slice(0, 1) ?? "?"}</AvatarDiv>
                                            { currentState.prompt }
                                        </h6>
                                    </div>
                                    <div className='sub-response'>
                                        <div className={`response-text ${currentState.response === "" ? "loading pb-3" : ""}`}>
                                            <div className="avatar avatarSize"></div>
                                            {
                                                currentState.response !== "" ? <InnerHTML html={currentState.response + cursor} className='completion-response' /> : (
                                                    <>
                                                        Thinking<TextLoader />
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                    <MessageBox inputRef={inputRef} setAblyObject={setAblyObject} setAblyChannel={setAblyChannel} ablyChannel={ablyChannel} ablyObject={ablyObject} evtSource={evtSource} setEvtSource={setEvtSource} />
                    { (currentState.streaming && currentState.response !== "") && <StopGeneratingBtn onClick={handleStopGenerating}>Stop Generating</StopGeneratingBtn> }
                </div>
                <SuggestionsBar inputRef={inputRef} />
            </PureAIChatView>
        </>
    )

};

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(PureAIChat);