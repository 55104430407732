import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import { InfoCard, DiscoverViewWrapper, AppFancyBar, DiscoverMoreBanner } from '../../../styles/dashboard/Main'
import { constants, featuresNameConstants, nextOnBoardingContent, securityToolsPlans } from '../../../tools/constants'
import { sendEvents } from '../../../tools/mpEvents';
import PurePrivacyIconSVG from '../../../assets/svg/PurePrivacySVG'
import PureEncryptIconSVG from '../../../assets/svg/PureEncryptSVG'
import PureKeepIconSVG from '../../../assets/svg/PureKeepSVG'
import PureVPNIconSVG from '../../../assets/svg/PureVPNSVG'
import FamilyBannerImage from '../../../assets/DMFamilyBanner.png'
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';
import { SecurityToolsContent, featureContent } from '../../security-tools/content';
import rmd from '../../../assets/bundle/rmd-icon.svg';
import pm from '../../../assets/bundle/pm-icon.svg';
import { PrimaryBtn } from '../../../styles/Generic';

const DiscoverView = ({onBoardingReducer, appSlug}) => {

    const token = localStorage.getItem("token");

    const { t, i18n } = useTranslation();
    
    const history = useHistory()

    const [loading , setLoading] = useState(0);

    const [remainingOnboardings, setRemainingOnboardings] = useState([]);
    
    useEffect(() => {

        if (onBoardingReducer?.onBoardings) {
            let d = [];
            onBoardingReducer?.onBoardings.map((onboarding) => {
                if (nextOnBoardingContent[onboarding?.slug] && appSlug !== onboarding?.slug) {
                    d.push({
                        ...nextOnBoardingContent[onboarding?.slug],
                        slug: onboarding?.slug
                    })
                }
            })
            setRemainingOnboardings(d)
        }

    }, [])

    const redirectToSecurityTools = (slug) => {

        if (slug && history) {

            sendEvents(
                {
                    event_name: "ma_click_security_tools",
                    event_properties: {
                        source: "onboarding",
                        feature_name: slug
                    }
        
                }, token
            );
            let route_to_keep_app = onBoardingReducer?.subscriptions.filter((sub)=> sub?.addonsPurchased.includes(featureContent[slug]?.product) || securityToolsPlans[sub?.plan_type].includes(featureContent[slug]?.product))?.length > 0 ? true : false;

            if(route_to_keep_app && slug === featuresNameConstants['password_manager']){
                window.open(constants.purekeepWebDownloadLink)
            }else{
                history.push(`/dashboard/security-tools/${slug}`)
            }
        }
    }

    const redirectToFamilyPlan= () => {

            sendEvents(
                {
                    event_name: `ma_click_familyplan_banner`,
                    event_properties: {
                        cta: "Upgrade to Family Plan",
                        source: 'onboarding'
                    }
        
                }, token
            );

            history.push(`/dashboard/family-plan`)
    }
    
    const skeleton = (html) => {
        return loading ? (
            <>
                <Skeleton height={20} width={300} className="onboarding-discover-view-wrapper-loading"/>
            </>
        ) : html
    }


    return skeleton(
        <DiscoverViewWrapper>
            <DiscoverMoreBanner>
                    <div className='DMBanner'> 
                            <div className='content'>
                                <h1 className='heading'>{t('onboarding_discover_more_get_unlimted_vpn_access')}</h1>
                                <p className='para'>{t('onboarding_discover_more_get_unlimted_vpn_access_para')}</p>
                                <PrimaryBtn onClick={() => { redirectToFamilyPlan()}}>{t('familyplan_banner_cta')}</PrimaryBtn>
                            </div>
                        <div className='fpimg'>
                        <img src={FamilyBannerImage}/>
                        </div>
                    </div>
            </DiscoverMoreBanner>
            <div className='top-heading'>
                <h2 className='heading mt-3'>{t('onboarding_discover_more_add_security_beyond_vpn')}</h2>
            </div>
            <div className='all-on-boardings'>

                {SecurityToolsContent.features.filter((item)=> item.slug === featuresNameConstants['remove-my-data'] || item.slug === featuresNameConstants['password_manager']).map((item, idx)=>{
                    return <AppFancyBar key={idx} className={item}>
                    <div className={` box app-icon ${item.app}`}>
                        <div className='image'>
                            <img src={item.slug === featuresNameConstants['remove-my-data'] ? rmd : pm} alt={item.slug} />
                        </div>
                        <div className='content'>
                            <h6 className='heading'>{t(item?.heading)}</h6>
                            <p className='para'>{t(item?.paragraph)}</p>
                            <PrimaryBtn onClick={() => { redirectToSecurityTools(item.slug) }}>{t('get_started_text')}</PrimaryBtn>
                        </div>
                    </div>
                </AppFancyBar>
                })}
            </div>
        </DiscoverViewWrapper>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer,
    };
};

export default connect(mapStateToProps, null)(DiscoverView)