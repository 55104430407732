import React from "react";
import { FlagIcon } from "../../../styles/flags/Flag";
const CountryFlag = ({iso,city,modalView}) => {
  return (
    <FlagIcon city={city} modalView={modalView}>
      <span className={`bg-${iso}`}></span>
    </FlagIcon>
  );
};

export default CountryFlag;
