import { useState, useEffect } from "react";
import { get } from "../../../../tools/tools";

const useEarningInfo = (token) => {
  const getEarningDataFromLocalStorage = JSON.parse(
    localStorage.getItem("earn_options")
  );
  const [earningInfo, setEarningInfo] = useState(null);
  const [isEarnLoading, setIsEarnLoading] = useState(getEarningDataFromLocalStorage?.length ? false : true);
  const [earnErrorMsg, setEarnErrorMsg] = useState(null);
  const [fetchEarnAgain, setfetchEarnAgain] = useState(false);

  useEffect(() => {
    async function fetchEarningInfo() {
      try {
        getEarningDataFromLocalStorage?.length && setEarningInfo(getEarningDataFromLocalStorage);
        const userDetailsApi = await get("loyalty/earning-rules", token);
        const { data: userDetails } = userDetailsApi;
        setEarnErrorMsg(false);
        if (userDetails?.status) {
          const filteredChallenges = userDetails?.body?.challenges.filter(
            (challenge) => challenge.name !== "Referral"
          );
          setEarningInfo(filteredChallenges);
          localStorage.setItem("earn_options", JSON.stringify(filteredChallenges));
        } else {
          setEarnErrorMsg(userDetails?.message);
        }
      } catch (error) {
        setEarnErrorMsg("An error occurred while fetching earning info.");
      }
      setIsEarnLoading(false);
    }

    fetchEarningInfo();
  }, [fetchEarnAgain]);
  
  return { earningInfo, isEarnLoading, earnErrorMsg, setfetchEarnAgain };
};

export default useEarningInfo;
