import { bundleType } from "../../../tools/constants";
import { sendEvents } from "../../../tools/mpEvents";
import { getTokenFromLocalStorage, isBundleHasFollowing } from "../../../tools/tools";

export const getVPNplanType = (data) => {
    let type = bundleType.standard;
    if(data){
        let explode_ = data?.split('_');
        type = bundleType[explode_[explode_?.length-1]];
    }
    return type
}

export const isStandard = (type) => {
    return getVPNplanType(type) === bundleType.standard
}

export const isMax = (type) => {
    return getVPNplanType(type) === bundleType.max
}

export const isPlus = (type) => {
    return getVPNplanType(type) === bundleType.plus
}

export const getSubscriptionType = (subscriptions) => {
    for (let i = 0; i < subscriptions.length; i++) {
      if (getVPNplanType(subscriptions[i].plan_type) === bundleType.max) {
        return bundleType.max;
      } else if (getVPNplanType(subscriptions[i].plan_type) === bundleType.plus) {
        return bundleType.plus;
      }
    }
    return false;
}

export const filteredArrayAddon = (selectedSubscriptionData={},addons=[],isOrder=false) => {
    let itemToIterate = isOrder ? addons :selectedSubscriptionData?.add_ons
    return itemToIterate?.filter(
    addon => 
     (!isStandard(selectedSubscriptionData?.plan_type)) ? 
        !isBundleHasFollowing(addon?.code,getVPNplanType(selectedSubscriptionData?.plan_type)) 
    : true);
}

export const onClickGetSS = (get_plan_via, history) => {
    sendEvents({
        event_name:"ma_click_get_plan",
        event_properties: {
            get_plan_via
        }
    }, getTokenFromLocalStorage());

    get_plan_via == 'addon cart' ? window.open("/v2/dashboard/plans") : history.push("/dashboard/plans");
}

export const isMaxPlan = (sub) => {
    return sub?.plan_type === 'vpn_max'
}

const basePriceAddons = JSON.parse(process.env.REACT_APP_ENV_ADD_ONS_BASE_PRICE);

export const addons_data = [
    {
        name:"upgrade_purescan_heading",
        addon_code:"purescan",
        features: [
            "upgrade_purescan_feature1", 
            "upgrade_purescan_feature2", 
            "upgrade_purescan_feature3", 
        ],
        oldPrice: basePriceAddons.purescan
    },
    {
        name:"upgrade_purekeep_heading",
        addon_code:"purekeep",
        features: [
            "upgrade_purekeep_feature1", 
            "upgrade_purekeep_feature2", 
            "upgrade_purekeep_feature3", 
        ],
        oldPrice: basePriceAddons.purekeep
    },
    {
        name:"upgrade_purecrypt_heading",
        addon_code:"purecrypt",
        features: [
            "upgrade_purecrypt_feature1",
            "upgrade_purecrypt_feature2",
            "upgrade_purecrypt_feature3",
        ],
        oldPrice: basePriceAddons.purecrypt   
    },
]

let ErrorMessage = {
    "mobile_gateway":`We see that you're interested in upgrading your premium plan. Please note that since you purchased your current subscription from , you're required to upgrade your subscription from the store.`,
    "payment_gateway_offline": `We see that you're interested in upgrading your premium plan. Please note that since you purchased your current subscription from , you're required to change your payment method.`,
    "trial":`We see that you're interested in upgrading to our premium plan. Please note that since you're in your trial you can't upgrade but what you can do is get a new subscription in the same price and keep enjoying PureVPN in the same manner. Get a new subscription`,
    "cancelled_or_refunded":`We see that you're interested in upgrading to your plan. Please note that since your subscription has been cancelled/refunded, you can't upgrade your plan. However, you can buy a new subscription and enjoy PureVPN once again. Get a new subscription`,
    "whmcs_mobile_gateway":`We're sorry to inform you that currently you can't upgrade your plan, since this option is not available right now.`,
    "hasFamilyPlan": `We see that you're interested in purchasing plan upgrade but plan upgrade is not supported right now.`,
    "familyPlanChild": `Uh oh! Only a family manager can do plan upgrade`,
    "hasVolumeLicensing": `We see that you're interested in purchasing plan upgrade but plan upgrade is not supported right now.`,
    "volumeLicensingChild": `Uh oh! Only an admin can do plan upgrade`,
    "fpdr_eligible": `We're sorry to inform you that currently you can't upgrade your plan, since this option is not available right now.`,
    "reseller":`We see that you're interested in upgrading to your plan. Please note that since you purchased your current subscription from Reseller, you can't upgrade your plan. However, you can buy a new subscription and enjoy PureVPN once again. Get a new subscription`,
    "default":`Sorry! no upgrade offer is available for your plan, you can buy new subscription from here`,
}

export const checkErrorEligibilityAndErrorForUpgrade = (subscription, setInEligibleMessage, subscriptionsData) => {
    let errorKey = false;
    // if (!subscription?.is_eligable_for_upgrade) {
    //     errorKey = "fpdr_eligible";
    //     setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    // }
    // else
    if(subscription?.payment_gateway_offline && ['AMAZON'].includes(subscriptionsData?.subs?.[0]?.payment_gateway)){
        errorKey = "mobile_gateway";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    } 
    else if(subscriptionsData?.subs?.[0]?.vpnusername == ""){
        errorKey = "manual_gateway_unpaid";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
    else if((subscription?.state == "expired" || subscription?.state == "active") && subscription?.is_trial && subscription?.hasOwnProperty('trial_ends_at') && subscription?.trial_ends_at !== ''){
        errorKey = "trial";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    } 
    else if(subscription?.state == "expired" && !subscription?.is_trial){
        errorKey = "cancelled_or_refunded";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    } 
    else if(subscription?.hasFamilyPlan && !subscription?.is_child){
        errorKey = "hasFamilyPlan";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    } 
    else if(subscription?.hasVolumePlan && !subscription?.is_child){
        errorKey = "hasVolumeLicensing";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
    else if(subscription?.hasVolumePlan && subscription?.is_child){
        errorKey = "volumeLicensingChild";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
    else if(subscription?.hasPureTeams && !subscription?.is_child){
        errorKey = "hasTeamPlan";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
    else if(subscription?.hasPureTeams && subscription?.is_child){
        errorKey = "hasTeamPlanChild";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    } 
    else if(subscription?.is_child){
        errorKey = "familyPlanChild";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
    else if(subscription?.payment_gateway.toLowerCase() =="reseller"){
        errorKey = "reseller";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }  
    else{
        errorKey = "default";
        setInEligibleMessage(ErrorMessage[errorKey], `upgrade_error_${errorKey}`)
    }
}
