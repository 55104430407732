import React from 'react'
import { UpgradePlanContainer } from '../../../../styles/dashboard/Main'
import { addonNames, bundleTypeNaming } from '../../../../tools/constants'
import { getSubPlanTypeToDisplay } from '../../../../tools/tools'
import { filteredArrayAddon } from '../upgrade-tools'
import { useTranslation } from 'react-i18next'

const UpgradeHeaderContainer = ({selectedSubscriptionData={}, subscriptions=[]}) => {
    const { t } = useTranslation();

  return (

    <UpgradePlanContainer>
            <div className="mt-5 mb-4 d-flex dropdown-row justify-content-center">


                <div className={`px-4 dropdown-monthly no-border `}>
                    <p className="upgrade-p2">
                        {t('upgradeplan_current_plan_type')}
                    </p>
                    {
                        selectedSubscriptionData
                        ?
                        <p className="upgrade-p3 mt-2 mb-0"> {selectedSubscriptionData.is_trial ? selectedSubscriptionData?.plan?.name : `${selectedSubscriptionData?.plan?.interval_length} ${selectedSubscriptionData?.plan?.interval_length === 1 ? t('upgradeplan_month') : t('months_text')}`} </p>
                        :
                        <p className="upgrade-p3 mt-2 mb-0">-</p>
                    }
                </div>
                <div className="px-4 dropdown-monthly ">
                    <p className="upgrade-p2">
                        {t('upgradeplan_current_subs')}
                    </p>
                    {
                        selectedSubscriptionData
                        ?
                        <p className="upgrade-p3 mt-2 mb-0"> {selectedSubscriptionData?.plan_type ? getSubPlanTypeToDisplay(selectedSubscriptionData?.plan_type) : bundleTypeNaming?.standard}</p>
                        :
                        <p className="upgrade-p3 mt-2 mb-0">-</p>
                    }
                </div>

                <div className="px-4 dropdown-monthly">
                    <p className="upgrade-p2">{t('upgradeplan_connected_addon')}</p>
                        {filteredArrayAddon(selectedSubscriptionData,subscriptions)?.length > 0
                        ?
                            (<ul className="connected-addons mb-0">
                                {
                                    filteredArrayAddon(selectedSubscriptionData,subscriptions)?.map((addon, index, arr) => (
                                        <li key={index} className="mt-2">
                                            {`${(addon?.code.includes("dedicated") || addon?.code.includes("ddos")) ? `${(addon?.country) ? addon?.country : ""} ` : ""}${addonNames[addon?.code]}`}{(arr.length - 1 !== index) ? "," : ""}
                                        </li>
                                    ))
                                }
                            </ul>)
                        :
                        <p className="upgrade-p3 mt-2 mb-0">-</p>}
                </div>

            </div>
    </UpgradePlanContainer>
  )
}

export default UpgradeHeaderContainer