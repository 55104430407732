export const dedicated_ip = {
    en:{
        heading_1:"Dedicated IP",
        para_1: 'With PureVPN\'s Dedicated IP VPN, you can do much more than you can imagine. This add-on serves as a great solution for those individuals and businesses that wish to restrict access to their networks.',
        heading_2:'Why Do I need Dedicated IP VPN?',
        para_2:"",
        desc:[
          '<strong> Remotely Access Servers with Encryption </strong> No matter what remote access server you connect to, you can easily do so without having to worry about being compromised, especially since you are backed by military-grade encryption.',
          '<strong> Perfect for Businesses </strong> Individuals and businesses can connect to restricted networks and servers. Rather than just about anyone with a shared IP accessing a network, only those with a specific IP address can do so, thus bolstering security by leaps and bounds.',
          '<strong> The Possibilities are Limitless </strong> Whether you wish to access CCTV cameras, bank accounts or wish to view confidential information located on a secure hospital network, the possibilities are limitless.' 
        ],
        cta:"Get this Add-on",
    },
    fr:{
      heading_1:"IP dédié",
      para_1:"Avec le VPN IP dédié de PureVPN, vous pouvez faire bien plus que vous ne pouvez l'imaginer. Ce module complémentaire est une excellente solution pour les particuliers et les entreprises qui souhaitent restreindre l'accès à leurs réseaux.",
      heading_2:"Pourquoi ai-je besoin d'un VPN IP dédié ?",
      para_2:"",
      desc:[
        `<strong>Serveurs d'accès à distance avec cryptage</strong> Quel que soit le serveur d'accès à distance auquel vous vous connectez, vous pouvez facilement le faire sans avoir à craindre d'être compromis, d'autant plus que vous bénéficiez d'un cryptage de niveau militaire. `,
        `<strong>Parfait pour les entreprises  </strong> Les particuliers et les entreprises peuvent se connecter à des réseaux et serveurs restreints. Plutôt que n'importe qui avec une adresse IP partagée accédant à un réseau, seuls ceux qui ont une adresse IP spécifique peuvent le faire, renforçant ainsi la sécurité à pas de géant. `,
        `<strong>Les possibilités sont illimitées </strong> Que vous souhaitiez accéder à des caméras de vidéosurveillance, à des comptes bancaires ou à des informations confidentielles situées sur un réseau hospitalier sécurisé, les possibilités sont illimitées. `,

      ],
      cta:"Obtenez ce module complémentaire",
    },
    de:{
      heading_1:"Dedizierte IP",
      para_1: 'Mit dem dedizierten IP-VPN von PureVPN können Sie viel mehr tun, als Sie sich vorstellen können. Dieses Add-on ist eine großartige Lösung für Einzelpersonen und Unternehmen, die den Zugriff auf ihre Netzwerke einschränken möchten.',
      heading_2:'Warum brauche ich ein dediziertes IP-VPN?',
      para_2:"",
      desc:[
        '<strong> Fernzugriffsserver mit Verschlüsselung </strong> Egal zu welchem Fernzugriffsserver Sie sich verbinden, Sie können dies ganz einfach tun, ohne sich Sorgen machen zu müssen, dass Sie kompromittiert werden, zumal Sie durch eine militärische Verschlüsselung unterstützt werden.',
        '<strong> Perfekt für Unternehmen </strong> Einzelpersonen und Unternehmen können sich mit eingeschränkten Netzwerken und Servern verbinden. Anstatt dass jeder mit einer gemeinsamen IP auf ein Netzwerk zugreift, können nur diejenigen mit einer bestimmten IP-Adresse dies tun, wodurch die Sicherheit sprunghaft erhöht wird.',
        '<strong> Die Möglichkeiten sind grenzenlos </strong> Ob Sie auf CCTV-Kameras oder Bankkonten zugreifen oder vertrauliche Informationen aus einem sicheren Krankenhausnetzwerk einsehen möchten, die Möglichkeiten sind grenzenlos.' 
      ],
      cta:"Holen Sie sich dieses Add-on",
    },
    zh:{
      heading_1:"專用IP",
      para_1: '使用 PureVPN 的專用 IP VPN，您可以做的比您想像的要多得多。對於那些希望限制對其網絡的訪問的個人和企業，此附加元件是一個很好的解決方案。	',
      heading_2:'為什麼我需要專用 IP VPN？',
      para_2:"",
      desc:[
        '<strong> 遠程訪問加密伺服器</strong> 無論您連線到遠程訪問伺服器，您都可以輕鬆做到這一點，而不必擔心受到威脅，因為您擁有的 VPN 支援軍事級加密。',
        '<strong> 非常適合企業 </strong> 個人和企業可以連線到受限制的網絡和伺服器。擁有共享 IP 的任何人都無法問網絡，只有擁有特定 IP 地址的人才能訪問網絡，從而突飛猛進地增強了安全性。',
        '<strong> 可能性是無限的 </strong> 無論您是想訪問閉路電視攝像機、銀行賬號，還是想查看位於安全醫院網絡上的機密信息，可能性都是無限的。' 
      ],
      cta:"獲取此插件",
    },
    es:{
      heading_1:"IP dedicada",
      para_1:'Con la VPN IP dedicada de PureVPN, puede hacer mucho más de lo que puede imaginar. Este complemento sirve como una gran solución para aquellas personas y empresas que desean restringir el acceso a sus redes.',
      heading_2:'¿Por qué necesito VPN IP dedicado?',
      para_2:"",
      desc:[
        '<strong>Servidores de acceso remoto con cifrado</strong>sin importar a qué servidor de acceso remoto se conecte, puede hacerlo fácilmente sin tener que preocuparse por estar comprometido, especialmente porque está respaldado por un cifrado de grado militar.',
        '<strong> Perfecto para empresas</strong> las personas y las empresas pueden conectarse a redes y servidores restringidos. En lugar de casi cualquier persona con una IP compartida que acceda a una red, solo aquellos con una dirección IP específica pueden hacerlo, reforzando así la seguridad a pasos agigantados.',
        '<strong> Las posibilidades son ilimitadas</strong> ya sea que desee acceder a cámaras de CCTV, cuentas bancarias o que desee ver información confidencial ubicada en una red de hospital segura, las posibilidades son ilimitadas.' 
      ],
      cta:"Obtenga este complemento",
    },
    ru:{
      heading_1:"Выделенный IP",
      para_1:'С выделенным IP VPN PureVPN вы можете сделать гораздо больше, чем вы можете себе представить. Это дополнение служит отличным решением для тех людей и предприятий, которые хотят ограничить доступ к своим сетям.',
      heading_2:     'Зачем мне специализированный IP VPN?',
      para_2:"",
      desc:[
        '<strong>Серверы с удаленным доступом с шифрованием-независимо от того</strong> к какому серверу удаленного доступа вы подключаетесь, вы можете легко сделать это, не беспокоясь о скомпрометировании, тем более что вас поддерживает шифрование военного класса.',
        '<strong> Идеально подходит для предприятий</strong> отдельные лица и предприятия могут подключаться к ограниченным сетям и серверам. Вместо того, чтобы только кто -либо, у кого есть общий IP -адрес, доступ к сети, могут сделать это только те, у кого есть конкретный IP -адрес, что поддерживает безопасность в скачках и границах.',
        '<strong> Возможности безграничны</strong> хотите ли вы получить доступ к камерам видеонаблюдения, банковских счетов или хотите просмотреть конфиденциальную информацию, расположенную в безопасной больничной сети, возможности безграничны.' 
      ],
      cta:"Получите это дополнение",
    },
    tr:{
      heading_1:"Özel IP",
      para_1:'PureVPN\'nin özel IP VPN\'si ile hayal edebileceğinizden çok daha fazlasını yapabilirsiniz. Bu eklenti, ağlarına erişimi kısıtlamak isteyen bireyler ve işletmeler için harika bir çözüm görevi görür.',
      heading_2:'Neden özel IP VPN\'ye ihtiyacım var?',
      para_2:"",
      desc:[
        '<strong>Şifreleme ile sunuculara uzaktan erişin</strong>Hangi uzaktan erişim sunucusuna bağlanırsanız bağlantı kurun, özellikle askeri sınıf şifrelemesiyle desteklendiğiniz için, tehlikeye girme konusunda endişelenmenize gerek kalmadan kolayca yapabilirsiniz.',
        '<strong> İşletmeler için mükemmel</strong> Bireyler ve işletmeler kısıtlı ağlara ve sunuculara bağlanabilir. Bir ağa erişen paylaşılan IP\'ye sahip olan herkesten ziyade, yalnızca belirli bir IP adresine sahip olanlar bunu yapabilir, böylece güvenliği sıçrama ve sınırlarla artırır.',
        '<strong> Olanaklar sınırsızdır</strong> CCTV kameralarına, banka hesaplarına veya güvenli bir hastane ağında bulunan gizli bilgileri görüntülemek isteyin, olasılıklar sınırsızdır.'
      ],
      cta:"Bu eklentiyi alın",
    },
    pt:{
      heading_1:"IP dedicado",
      para_1:'Com a VPN IP dedicada da PureVPN, você pode fazer muito mais do que pode imaginar. Este complemento serve como uma ótima solução para os indivíduos e empresas que desejam restringir o acesso às suas redes.',
      heading_2:'Por que preciso de IP Dedicated IP VPN?',
      para_2:"",
      desc:[
        '<strong>Acesse os servidores remotamente com criptografia</strong>independentemente do servidor de acesso remoto para o qual você se conecte, você pode fazê-lo facilmente sem precisar se preocupar em ser comprometido, especialmente porque você é apoiado pela criptografia de nível militar.',
        '<strong> Perfeito para empresas</strong> indivíduos e empresas podem se conectar a redes e servidores restritos. Em vez de praticamente qualquer pessoa com um IP compartilhado acessando uma rede, apenas aqueles com um endereço IP específico podem fazê -lo, reforçando a segurança por trancos e barrancos.',
        '<strong> As possibilidades são ilimitadas</strong> se você deseja acessar câmeras de CFTV, contas bancárias ou desejar ver informações confidenciais localizadas em uma rede hospitalar segura, as possibilidades são ilimitadas.'
      ],
      cta:"Obtenha este complemento",
    },
    ja:{
      heading_1:"専用のIP",
      para_1:'PureVPNの専用IP VPNを使用すると、想像以上に多くのことができます。このアドオンは、ネットワークへのアクセスを制限したい個人や企業にとって優れたソリューションとして機能します。',
      heading_2:'専用のIPVPNが必要なのはなぜですか？',
      para_2:"",
      desc:[
        '<strong> 暗号化付きのリモートアクセスサーバー </strong> 特に軍用グレードの暗号化に裏打ちされているため、侵害されることを心配することなく、どのリモートアクセスサーバーに接続しても、簡単にそれを行うことができます。',
        '<strong> 企業に最適 </strong> 個人や企業は、制限付きネットワークとサーバーに接続できます。ネットワークにアクセスするIPを共有している人だけでなく、特定のIPアドレスを持っている人だけがそうすることができるため、飛躍的にセキュリティを強化します。',
        '<strong> 可能性は無限です </strong>  CCTVカメラ、銀行口座にアクセスしたい場合でも、安全な病院ネットワークにある機密情報を表示したい場合でも、可能性は無限です。' 
      ],
      cta:"このアドオンを取得します",
    },
    it:{
      heading_1:"IP dedicato",
      para_1:'Con la VPN IP dedicata di PureVPN, puoi fare molto più di quanto tu possa immaginare. Questo componente aggiuntivo funge da ottima soluzione per quegli individui e le aziende che desiderano limitare l\'accesso alle loro reti.',
      heading_2:'Perché ho bisogno di VPN IP dedicato?',
      para_2:"",
      desc:[
        '<strong>Accesso a distanza i server con crittografia</strong>Indipendentemente dal server di accesso remoto a cui ti connetti, puoi facilmente farlo senza doversi preoccupare di essere compromesso, soprattutto perché sei sostenuto dalla crittografia di livello militare.',
        '<strong> Perfetto per le aziende</strong> Le persone e le aziende possono connettersi a reti e server limitati. Piuttosto che chiunque abbia un IP condiviso che accede a una rete, solo quelli con un indirizzo IP specifico possono farlo, rafforzando così la sicurezza a passi da gigante.',
        '<strong> Le possibilità sono illimitate</strong> Se desideri accedere alle telecamere CCTV, ai conti bancari o desideri visualizzare informazioni riservate situate su una rete ospedaliera sicura, le possibilità sono illimitate.' 
      ],
      cta:"Ottieni questo componente aggiuntivo",
    },
    nl:{
      heading_1:"Toegewijd IP",
      para_1:'Met PureVPN\'s speciale IP VPN kunt u veel meer doen dan u zich kunt voorstellen. Deze add-on dient als een geweldige oplossing voor personen en bedrijven die de toegang tot hun netwerken willen beperken.',
      heading_2:'Waarom heb ik toegewijde IP VPN nodig?',
      para_2:"",
      desc:[
        '<strong>op afstand toegang tot servers met codering</strong> ongeacht met welke externe toegangsserver u verbinding maakt, u kunt dit gemakkelijk doen zonder zich zorgen te maken dat u wordt gecompromitteerd, vooral omdat u wordt ondersteund door codering van militaire kwaliteit.',
        '<strong> Perfect voor bedrijven</strong>  individuen en bedrijven kunnen verbinding maken met beperkte netwerken en servers. In plaats van zowat iedereen met een gedeeld IP -toegang tot een netwerk, kunnen alleen degenen met een specifiek IP -adres dit doen, waardoor de beveiliging door sprongen en grenzen wordt versterken.',
        '<strong> De mogelijkheden zijn onbeperkt</strong>  of u nu toegang wilt tot CCTV-camera\'s, bankrekeningen of vertrouwelijke informatie wilt bekijken die zich op een beveiligd ziekenhuisnetwerk bevindt, de mogelijkheden zijn onbeperkt.'
      ],
      cta:"Koop deze add-on",
    },
    ko:{
      heading_1:"전용 IP",
      para_1: 'PureVPN의 전용 IP VPN을 사용하면 상상할 수있는 것보다 훨씬 더 많은 일을 할 수 있습니다. 이 애드온은 네트워크에 대한 액세스를 제한하려는 개인 및 기업에게 훌륭한 솔루션 역할을합니다.',
      heading_2:'전용 IP VPN이 필요한 이유는 무엇입니까?',
      para_2:"",
      desc:[
        '<strong>암호화로 서버를 원격으로 액세스 할 수 있습니다</strong> 연결하는 원격 액세스 서버에 관계없이, 특히 군사적 암호화에 의해 뒷받침되기 때문에 타협 할까 걱정하지 않고도 쉽게 할 수 있습니다.',
        '<strong> 비즈니스에 적합합니다</strong> 개인과 비즈니스는 제한된 네트워크 및 서버에 연결할 수 있습니다. 공유 IP가 네트워크에 액세스하는 사람이 아니라 특정 IP 주소를 가진 사람만이 그렇게 할 수 있으므로 도약과 경계에 의한 보안을 강화할 수 있습니다.',
        '<strong> 가능성은 무한합니다</strong> CCTV 카메라, 은행 계좌에 액세스하거나 보안 병원 네트워크에있는 기밀 정보를보고 싶든 가능성은 무한합니다.'
      ],
      cta:"이 애드온을 얻으십시오",
    },
  }