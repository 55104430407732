import React, {useEffect, useState} from 'react'
import { CardBox, ImageDiv, DashboardHead, FormDiv, InfoCard, Input, PasswordVerifier, AccountWrapper, ButtonLink } from '../../../styles/dashboard/Main'
import { PrimaryBtn, DestructBtn } from '../../../styles/Generic'
import Aside from '../layout/Aside'
import qs from 'qs'
import { toast } from 'react-toastify'
import { poster, filterSlug } from '../../../tools/tools'
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../tools/responseMessages'
import { sendEvents } from '../../../tools/mpEvents'
import { connect, useDispatch } from 'react-redux'
import DomeForm from '../layout/partials/DomeForm'
import { useTranslation } from 'react-i18next';
import PasswordGenerator from './PasswordGenerator'
import { constants } from '../../../tools/constants'
import FreeAddonModal from '../subscriptions/modals/FreeAddonModal'
import { checkAddOn } from '../subscriptions/tools/SubscriptionTools'
import { pswRegEx } from './change_password_constants'

const ChangePassword = ({history,onBoardingReducer}) => {

    const dispatch = useDispatch()

    const token = localStorage.getItem("token");

    const [domeModal, setDomeModal] = useState(false)

    const [asideType] = useState("refer")

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [modalWidth] = useState({
        lg: "700px",
        sm: "440px"
    })

    const widthCalculator = "lg"

    useEffect(() => {
        sendEvents({
            event_name:"ma_account_click_change_password",
            event_properties:{}
        }, token)
    }, [])
    const [loading, setloading] = useState(false)

    const { t, i18n } = useTranslation();

    const [firstTime, setfirstTime] = useState(true)

    const [data, setData] = useState({
        old_password: "",
        password: "",
        password_confirmation: ""
    })

    const [errors, setErrors] = useState({
        currentPass:true,
        minchars: true,
        capitalize: true,
        letnnum: true,
        special:true,
        passwordMatch: true
    })

    const errorMessages = {
        currentPass: 'change_password_current_password_validation',
        minchars: 'change_password_eight_character_validation',
        capitalize: 'change_password_upperlower_validation',
        letnnum: 'change_password_mixture_of_letter_and_number_validation',
        special: 'change_password_special_character_validation',
        passwordMatch: 'change_password_password_matched_validation',
        passwordLimit: 'change_password_valid_password_validation'
      };

    const [isPswValidationError, setIsPswValidationError] = useState(false)

    const [showPasswordNew, setShowPasswordNew] = useState(false);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const verifyCases = (updatedData) => {
        setfirstTime(false);
        const { old_password, password, password_confirmation } = updatedData;
        let cases = {
            currentPass:old_password !== "" ? false : true,
            minchars: password_confirmation.length >= 8 ? false : true,
            capitalize:(pswRegEx.lowercase.test(password_confirmation) && pswRegEx.uppercase.test(password_confirmation)) ? false : true,
            letnnum: pswRegEx.numeric.test(password_confirmation) ? false : true,
            special: pswRegEx.specialChar.test(password_confirmation) ? false : true,
            passwordMatch: (password !== "" && password_confirmation !== "") && password === password_confirmation ? false : true,
            passwordLimit: (password.length >= 8 && password.length <=52) && (password_confirmation.length >= 8 && password_confirmation.length <=52) ? false : true
        }
        return cases;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...data, [name]: value };
        setData(updatedData);
    }

    const handleBlur = (e) => {
        const updatedErrors = verifyCases(data);
        setErrors(updatedErrors);
    }

    const handleFocus = () => {
        setIsPswValidationError(false)
    }

    const handleTogglePassword = (field) => {
        if(field==="new"){
            setShowPasswordNew(!showPasswordNew)
        }else{
            setShowPasswordConfirm(!showPasswordConfirm)
        }
    };

    const handleSubmit = async (e) => {
        setloading(true);
        const cases = verifyCases(data)
        const casesFailed = Object.values(cases).some(c=>c===true);
        if(casesFailed){
            setIsPswValidationError(true)
            setErrors(cases);
            setloading(false)
            return;
        }
        try{
            const formdata = qs.stringify(data);
            const changepass = await poster("account/change-password", formdata, token);
            const {data: response} = changepass;

            if(response?.status){
                toast.success(t(SUCCESS))
                // If user dont has purekeep
                if(!checkAddOn(onBoardingReducer?.subscriptions?.[0],constants.purekeep)){
                    setModal(true)
                }
            }else{
                switch (response?.error_code) {
                    case "validation_exception":
                        Object.values(response?.errors).map(e=>toast.error(e[0]))
                        break;
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                                 
                        break;
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN)) 
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "account_exception":
                        toast.error(response?.message)
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
        }catch(err){ 
            toast.error(t(APIFAILED));
        }
        finally{
            setloading(false)
            setData({
                old_password: "",
                password: "",
                password_confirmation: ""
            })
            setErrors({
                currentPass:true,
                minchars: true,
                capitalize: true,
                letnnum: true,
                special:true,
                passwordMatch: true,
                passwordLimit:true
            });
            setfirstTime(true);
        }
    }

    const ButtonText = !loading ? t('change_password_cta'): ( <>Changing Password <span className="spinner-border text-light"></span></>);

    return (
        <>
            <DashboardHead>
                <h2 className="m-0">{t('change_password_heading')}</h2>
                <p>{t('change_password_para')}</p>
            </DashboardHead>
            <AccountWrapper>
                <CardBox>
                    <div className='change-password-wrapper'>
                        <FormDiv>
                            <InfoCard hasborder className="pt-0">
                                <div className="float-start">
                                    <div className="clearfix">
                                        <label className="me-2">{t('change_password_current_password')}</label>
                                        <Input type="password" placeholder={t('change_password_current_pw_placeholder')} name="old_password" onBlur={handleBlur} value={data.old_password} onChange={handleChange}/>
                                    </div>
                                </div>
                            </InfoCard>
                            <InfoCard hasborder>
                                <div className="float-start">
                                    <div className="clearfix">
                                        <label className="me-auto">{t('change_password_new_password')}</label>
                                        <Input type={showPasswordNew ? 'text' : 'password'} placeholder={t('change_password_new_pw_placeholder')} name="password" onBlur={handleBlur} onFocus={handleFocus} value={data.password} onChange={handleChange}/>
                                        <span onClick={()=>handleTogglePassword('new')} className={showPasswordNew ? 'hide' : 'view'} ></span>
                                    </div>
                                    <div className="mt-3 clearfix">
                                        <label className="me-2">{t('change_password_confirm_password')}</label>
                                        <Input type={showPasswordConfirm ? 'text' : 'password'} placeholder={t('change_password_confirm_pw_placeholder')} name="password_confirmation" onBlur={handleBlur} onFocus={handleFocus} value={data.password_confirmation} onChange={handleChange}/>
                                        <span onClick={()=>handleTogglePassword('confirm')} className={showPasswordConfirm ? 'hide' : 'view'} ></span>

                                    </div>

                                {isPswValidationError  && <p className="danger text-end mt-2">Please enter the password that meets the following criteria:</p>}
                                </div>
                            </InfoCard>
                            <InfoCard className="mt-4">
                                <div className="float-start">
                                    <ul className="list-unstyled mb-0"> 
                                    {
                                        Object.keys(errors).map((errorKey) => (
                                            <PasswordVerifier key={errorKey} firstTime={firstTime} verified={errors[errorKey]}>
                                              {t(errorMessages[errorKey])}
                                            </PasswordVerifier>))
                                    }
                                    </ul>
                                </div>
                            </InfoCard>
                            <InfoCard className="pb-0">
                                <div className="float-start">
                                    <PrimaryBtn className="me-2" onClick={handleSubmit}>{ButtonText}</PrimaryBtn>
                                    <DestructBtn onClick={()=>history.push("/account")}>{t('cancel_text')}</DestructBtn>
                                </div>
                            </InfoCard>
                        </FormDiv>
                        <PasswordGenerator token={token}/>
                    </div>
                </CardBox>
                <Aside type={asideType} modal={domeModal} setDomeModal={setDomeModal}/>
            </AccountWrapper>
            {
                domeModal && <DomeForm modal={domeModal} setDomeModal={setDomeModal}></DomeForm>
            }
            {
                modal && <FreeAddonModal backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={modal} toggle={toggle} slug={constants.purekeep} accountData={"recurly"} onBoardingReducer={onBoardingReducer} modalSource={constants.isChangePsw}/>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer
    };
};
export default connect(mapStateToProps)(ChangePassword)
