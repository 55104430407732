import React from 'react'
import ReferralBackground from '../../../assets/ReferralBackground.svg'
import { useState } from 'react'
import { ReferralsCover, ShareLink } from '../../../styles/dashboard/Main'
import { toast } from 'react-toastify'
import { sendEvents } from '../../../tools/mpEvents';
import ReferralProcessModal from './modals/ReferralProcessModal'
import { referralTierStatus } from '../../../tools/constants'
import { useTranslation } from 'react-i18next';
import { getCurrentLevel, getCurrentTier } from './tools/tools'
import { sendBiEvent } from '../../../tools/biEvents'

const ReferralsBanner = ({ link, progress, selectedSub }) => {
    const { t } = useTranslation();
    const token = localStorage.getItem("token")
    const [isReferralProcess, showReferralProcess] = useState(false)

    const ShareLinkPlatforms = [
        "facebook",
        "twitter",
        "whatsapp",
        "telegram",
        "reddit",
        "line",
    ]

    const copyLink = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success(t('copied_to_clipboard_text'))
        } catch (error) {
            toast.error(t('unable_to_copy_text'))       
        }
        sendEvents(
            {
            event_name: "ma_send_invite",
            event_properties: {
              action:"copy",  
              platform: "member_area",
              url:e.target.id,
              current_level: getCurrentLevel(progress),
              current_tier: getCurrentTier(progress),
            }
          }, token);
        sendBiEvent({
            event_name: "ma_send_invite",
            event_properties: {
              current_level: getCurrentLevel(progress),
              current_tier: getCurrentTier(progress),
            }
        }, token)
    }

    const shareToSocial = (url, platform) => {
        switch (platform) {
            case "facebook":
                /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            ?
                window.location.href = `http://www.facebook.com/dialog/send?app_id=${process.env.REACT_APP_FB_ID}&link=${url}`
            :
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`,'popup','width=600,height=600')
                break;
            case "whatsapp":
                window.open(`https://wa.me/?text=${url}`, '_blank','width=600,height=600')
                break;  
            case "telegram":
                window.open(`https://t.me/share/url?url=${url}&text=Get PureVPN for free`, '_blank','width=600,height=600')
                break;
            case "reddit":
                window.open(`https://reddit.com/submit?url=${url}&title=Get PureVPN for free`, '_blank','width=600,height=600')
                break;
            case "line":
                window.open(`https://line.me/R/msg/text/?${url}`, '_blank','width=600,height=600')
                break;
            case "twitter":
                window.open(`https://twitter.com/intent/tweet?url=${url}`,'popup','width=600,height=600')
                break;
            default:
                break;
        }
        sendEvents({
            event_name: "ma_send_invite",
            event_properties: {
              action:"share",  
              platform: platform,
              url:url,
              current_level: getCurrentLevel(progress),
              current_tier: getCurrentTier(progress),
            }
        }, token);
          sendBiEvent({
            event_name: "ma_send_invite",
            event_properties: {
              current_level: getCurrentLevel(progress),
              current_tier: getCurrentTier(progress),
            }
        }, token)
    }

    const clickReferralProcess = () => {
        showReferralProcess(true)
        sendEvents(
            {
            event_name: "ma_click_referral_steps",
            event_properties: {
              action:"click",  
              current_level: getCurrentLevel(progress),
              current_tier: getCurrentTier(progress),
              billing_cycle: selectedSub?.plan?.type ?? "N/A",
              email: selectedSub?.account?.email ?? "N/A",
              username: selectedSub?.vpnusernames?.[0] ?? "N/A",
            }
          }, token);
    }

    return (
        <ReferralsCover bgImage={ReferralBackground}>
            <div className='header'>
                    <div className='cta'>
                        <h1>{t('share_to_win_free_vpn')}</h1>
                    </div>
                    <div className='copyLinkBox'>  
                        <input value={link} disabled/>
                        <button onClick={copyLink} id={link}></button>
                    </div>
                    <ul className="list-unstyled list-inline pt-3 m-0">
                        {ShareLinkPlatforms.map((platform, index) => {
                            return (
                                <ShareLink key={index} className="list-inline-item"><button id={link} className="copyBtn" onClick={() => shareToSocial(link, platform)}></button></ShareLink>
                            )
                        })}
                    </ul>
                    <span className="referral-process" onClick={clickReferralProcess}>{t('how_does_referral_work')}</span>
            </div>
            {isReferralProcess && <ReferralProcessModal showReferralProcessModal={showReferralProcess} />}
        </ReferralsCover>
  )
}

export default ReferralsBanner