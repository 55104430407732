import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster } from '../../tools/tools';
import { errorsType, getReferType, loadingType } from './actions';
import i18n from "i18next";

function* inviteAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const tickets = yield poster("invites", {}, action.payload)
        const {data: response} = tickets;
        if(response?.status){
            yield put(getReferType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (i18n.t(APIFAILED)) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchInvitesRequest(){
    yield takeLatest("GETREFERREQUEST", inviteAsync)
}