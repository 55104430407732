import React from 'react'
import { InfoCard, NewTag } from '../../../../styles/dashboard/Main'
import { InsetBtn, LinkBtn } from '../../../../styles/Generic'
import { checkAddOn, checkAddOnIpAndHost, checkIsPending, checkState } from '../tools/SubscriptionTools'
import locked from '../../../../assets/locked.svg'
import { Link } from 'react-router-dom'
import { constants } from '../../../../tools/constants'
import { useTranslation } from 'react-i18next'
import { donotShowTeamsToTeamUser, isResellerUser } from '../../../../tools/tools'

const AddonView = ({name="",code="",subscription={},initOpenModel=()=>{},addonField=()=>{}}) => {
    const { t, i18n } = useTranslation();

    const checkIsPFAddon = (param) => {
        const {subscription,type } = param
        return constants.port_forwarding.includes(type) ?  (
            subscription?.state === "active" || subscription?.state === "canceled"
                ?
                <Link to={`/dashboard/port-forwarding/`}>{t('configure_text')}</Link>
                :
                <a className="dis-link" href="#" onClick={(e) => e.preventDefault()}>{t('configure_text')}</a>
        ): <label><strong></strong></label>
    }

    return (
        <InfoCard isExpired={checkState(subscription)} className="addon-list-item">
            <div className="float-start">
                <label className="me-2">
                {t('addon_lable')} <strong>{name}</strong>
                    {
                       code == "dedicated_server" && !checkAddOn(subscription, "dedicated_server") ?
                            <NewTag className='new' isDedicatedServer>{t('sidebar_new')}</NewTag>
                            :
                            null
                    }
                    {checkAddOn(subscription, code) || <div className='locked-image'><img src={locked} height={20} width={66} /></div>}
                    {
                        (checkAddOn(subscription, code) && <label className={checkAddOnIpAndHost(subscription?.add_ons,code) ? "purchased-block":"purchased"}>{addonField(subscription?.add_ons, code)}</label>)
                    }
                    
                </label>
            {checkIsPending(subscription, code)}
            </div>
            <div className="float-end">
                {
                    (checkAddOn(subscription, code) && checkIsPFAddon({subscription:subscription,type:code}) ) ||
                    (
                        subscription?.is_trial?
                            <p className="danger">{name} {t('subscription_not_avaialable_during_trial')}</p>
                            :
                            isResellerUser(subscription?.payment_gateway)?
                            <p className="danger">{name} {t('addon_not_available_in_your_subscription')}</p>
                            :
                            <InsetBtn
                                onClick={e=>{
                                    initOpenModel(subscription,code)
                                }}
                            >
                                {t('get_this_addon_text')}
                            </InsetBtn>
                            
                    )
                }
            </div>
            <div className="float-end">
                {
                    code === 'multi_login' && !donotShowTeamsToTeamUser(subscription) && checkAddOn(subscription, code) ? <LinkBtn onClick={e=>{
                        initOpenModel(subscription,code)
                    }}>{t('increase_login_limit')}</LinkBtn> : null
                }
            </div>
            <div className="float-none"></div>
        </InfoCard>
    )
}

export default AddonView