import { AvatarDiv } from "../../../../styles/dashboard/PureAI"
import InnerHTML from "dangerously-set-html-content"
import { sendMPEvents } from './helper';
import { connect, useDispatch } from 'react-redux';
import { useState } from "react";
import { ACTIONS } from '../../../../redux/pureai/reducer';
import ThirdPartyTools from "./ThirdPartyTools";

const PureAIChatHistory = ({ pureAIStateReducer }) => {
    const { previousStates } = pureAIStateReducer;
    const [ ratingHistory, setRatingHistory ] = useState(JSON.parse(localStorage.getItem('rating-history', {})));

    const dispatch = useDispatch();

    const rateResponse = (type, index) => {
        if(!ratingHistory?.[previousStates?.[index]?.messageId]){
            sendMPEvents('ma_pureai_rate_response', {
                rating: type,
                prompt: previousStates?.[index]?.prompt,
                response: previousStates?.[index]?.response
            })
            let history = ratingHistory??{};
            history[previousStates?.[index]?.messageId] = type;
            localStorage.setItem('rating-history', JSON.stringify(history));
            setRatingHistory(history);
        }
    }

    const triggerPlatformCTA = (state, platform, topic) => {
        sendMPEvents("ma_pureai_click_suggesstion_cta", {
            suggestion_type: 'platform',
            topic: topic,
            suggestion: platform
        });
        const promptTime = new Date();
        dispatch({type: ACTIONS.SEND_PROMPT, prompt: state.prompt, promptTime: promptTime.getTime(), promptType: "platform", platform: platform});
    }
    const triggerSuggestionCTA = (cta, index) => {
        sendMPEvents("ma_pureai_click_suggesstion_cta", {
            suggestion_type: 'product',
            topic: index,
            suggestion: cta.name
        });
        window.open(cta.link, '_blank', 'noreferrer');
    }
    return (
        previousStates.length > 0 && previousStates.map((state, index) => {
            return (
                <div className='prompt-response-view' key={index}>
                    <div className='sub-prompt'>
                        <h6 className='prompt-text'>
                            <AvatarDiv className='avatarSize'>{state?.email?.slice(0, 1) ?? "?"}</AvatarDiv>
                            { state.prompt }
                        </h6>
                    </div>
                    <div className='sub-response'>
                        <div className='sub-response-sub-div'>
                            <div className='response-text'>
                                <div className="avatar avatarSize"></div>
                                {
                                    state.source === ACTIONS.RECV_RESPONSE && state?.response ?
                                    <ThirdPartyTools details={JSON.parse(state?.response)} /> : 
                                    <div>
                                        <InnerHTML html={state.response} className='completion-response' />
                                        {
                                            state?.cta && Object.keys(state?.cta).map((item) => {
                                                return state?.cta[item].map((ctaItem, ctaIndex) => {
                                                    return (
                                                        <div key={ctaIndex} onClick={() => triggerSuggestionCTA(ctaItem, item)} className='sub-response-sub-div-ctas-item'>{ctaItem.cta}</div>
                                                    )
                                                })
                                            })
                                        }
                                        {
                                            (state?.platformSpecificAnswers && state?.promptType === "NONE") && Object.keys(state?.platformSpecificAnswers).map((item) => {
                                                const [ topic, platform ] = item.split('---');
                                                return (
                                                    <div key={platform} onClick={() => triggerPlatformCTA(state, platform, topic)} className='sub-response-sub-div-ctas-item'>{platform}</div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <span className="reactionArea">
                                <span className={`reaction up ${ratingHistory?.[state.messageId] === "like" ? "active mx-0" : ratingHistory?.[state.messageId] === "dislike" ? "d-none" : ""}`} onClick={() => rateResponse("like", index)} />
                                <span className={`reaction down ${ratingHistory?.[state.messageId] === "dislike" ? "active" : ratingHistory?.[state.messageId] === "like" ? "d-none" : ""}`} onClick={() => rateResponse("dislike", index)} />
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(PureAIChatHistory);