import React, {useEffect} from 'react'
import { AutoLoginWrapper } from '../styles/Generic'
import loginlogo from '../assets/loginlogo.png'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import qs from 'qs'
import { api, AsideConditionalRendering, RandomNumGenarator } from '../tools/tools'
import { APIFAILED, INVALIDTOKEN, SUCCESS } from '../tools/responseMessages'
import { getSubscriptionsRequest } from '../redux/subscriptions/actions'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

const AutoLogin = ({history, onBoardingReducer, getSubscriptionsRequest}) => {

    const { t, i18n } = useTranslation();

    const { search } = useLocation();
    const {uuid, redirect_to, type, subscription_id, app,slug, currency, redirect_from,code , update_subscription, open_cart, utm_source, utm_medium, external_link, utm_campaign, redirect_uri, modal, locale,platform,subscriptionId, client_answer, campaign_name, plan_type ,billing_cycle, features_metric, feature_name, id} = Object.fromEntries(new URLSearchParams(search));

    const formdata = qs.stringify({
        "uuid": uuid ?? undefined,
        "code": code ?? undefined,
        "id": id ?? undefined,
    });
    
    const pagesWithOutSuccessToast = ["apppurchase", "appupgrade"];

    const dispatch = useDispatch()

    const redirectWithParams = (route, params) => {
        const defaultParams = (utm_source || utm_medium || utm_campaign) ? `${params ? '&' : '?'}utm_source=${utm_source ?? ''}&utm_medium=${utm_medium ?? ''}&utm_campaign=${utm_campaign ?? ''}` : ""
        history.push({
            pathname:route,
            search: `${params}${defaultParams}`
        })
    }

    const utmCampaign = (addon_code,subscriptionId=null) => {
        return subscriptionId ? `?app=${addon_code ?? ''}&slug=${addon_code ?? ''}&subscriptionId=${subscriptionId}` : `?app=${addon_code ?? ''}&slug=${addon_code ?? ''}`
    }

    useEffect(() => {
        localStorage.setItem("closeHelloBar", false)
        localStorage.setItem("bannerClosed", false)
        async function apifunc(){
            try {
                //get locale from url and save it in local storage
                localStorage.setItem("locale", i18n.language)
                const login = await api("fusionauth/auto-login", formdata);
                const {data:{status, error_code}} = login;
            if(status){
                
                !pagesWithOutSuccessToast.includes(redirect_to) && toast.success(t(SUCCESS));

                const {data:{body:{token}}} = login;
                dispatch({type:"EMPTYSTORE"})
                localStorage.setItem("token", token)
                AsideConditionalRendering();
                RandomNumGenarator();
                //getSubscriptionsRequest(token)
                switch (redirect_to) {   
                    case "apps":
                    case "appsextension":
                    case "browserextension":
                    case "otherdevices":
                        redirectWithParams("/dashboard/download", `?slug=${slug ?? ''}`)
                        break;
                    case "tickets":
                    case "helpdesk":
                        redirectWithParams("/dashboard/tickets", '')
                        break;
                    case "invite":
                        redirectWithParams("/dashboard/refer", '')
                        break;
                    case "home":
                        redirectWithParams("/dashboard/home", '')
                        break; 
                    case "businessPage":
                        redirectWithParams("/dashboard/business", `?redirect_from=${redirect_from ?? 'apps'}`)
                        break; 
                    case "apppurchase":
                        redirectWithParams("/dashboard/app-purchase", `?type=${type ?? ''}&subscription_id=${subscription_id ?? ''}&currency=${currency ?? ''}&redirect_from=${redirect_from ?? ''}&redirect_uri=${redirect_uri ?? ''}&platform=${platform ?? ''}&locale=${locale ?? 'en'}&plan_type=${plan_type ?? ''}&billing_cycle=${billing_cycle ?? ''}&features_metric=${features_metric ?? ''}&feature_name=${feature_name ?? ''}`)
                        break;
                    case "billing":    
                        redirectWithParams("/dashboard/subscriptions", `?update_subscription=true`)
                        break;
                    case "subscriptions":  
                    case "addons":
                        redirectWithParams("/dashboard/subscriptions", `?update_subscription=${update_subscription ?? ''}`)
                        break;
                    case "addons":
                        redirectWithParams("/dashboard/subscriptions",'')
                        break;
                    case "upsell":
                        redirectWithParams("/dashboard/subscriptions",`?type=${type ?? ''}&subscription_id=${subscription_id ?? ''}`)
                        break;
                    case "appupgrade":
                        redirectWithParams("/dashboard/app-purchase-upgrade", `?subscription_id=${subscription_id ?? ''}&redirect_from=${redirect_from ?? ''}`)
                        break;
                    case "onboarding":
                        redirectWithParams("/dashboard/onboarding", utmCampaign('purevpn',subscriptionId))
                        break;
                    case "purekeep":
                        redirectWithParams("/dashboard/onboarding", utmCampaign('purekeep'))
                        break;
                    case "addons/purekeep":
                        redirectWithParams("/dashboard/onboarding", '?app=purekeep')
                        break;
                    case "pureprivacy":
                        redirectWithParams("/dashboard/onboarding",  utmCampaign('pureprivacy'))
                        break;
                    case "addons/pureprivacy":
                        redirectWithParams("/dashboard/onboarding", '?app=pureprivacy')
                        break;
                    case "purecrypt":
                        redirectWithParams("/dashboard/onboarding",  utmCampaign('purecrypt'))
                        break;
                    case "addons/purecrypt":
                        redirectWithParams("/dashboard/onboarding", "?app=purecrypt")
                        break;   
                    case "allProducts":
                        redirectWithParams("/dashboard/onboarding",  utmCampaign('allProducts'))
                        break; 
                    case "external_redirection":
                        external_link ? window.location = external_link : redirectWithParams("/dashboard/subscriptions", "")
                        break;
                    case "accountupgrade":
                        redirectWithParams("/dashboard/upgrade", ``)
                        break;
                    case "affiliates":
                        redirectWithParams("/dashboard/affiliate", '')
                        break;
                    case "business":
                        redirectWithParams("/dashboard/business", `?redirect_from=${redirect_from ?? ''}&modal_for=${modal ?? ''}`)
                        break;
                    case "familyplan":
                        redirectWithParams("/dashboard/family-plan", '')
                        break;
                    case "volumelicensing":
                        redirectWithParams("/dashboard/volume-licensing", '')
                        break;
                    case "portforwarding":
                            redirectWithParams("/dashboard/port-forwarding", '')
                            break;
                    case "plans":
                            redirectWithParams("/dashboard/plans", '');
                            break;
                    case "security_tools":
                        redirectWithParams("/dashboard/security-tools", `${slug ? `?slug=${slug}` : null}`);
                        break;
                    case "teams":
                        redirectWithParams("/dashboard/teams", '');
                        break;
                    case "via_proxy":
                            redirectWithParams("/dashboard/subscriptions", "?viaProxy=true&type=dedicated_server&features_metric=1")
                            break;
                    case "via_dip_campaign":
                            redirectWithParams("/dashboard/subscriptions", "?isProxyCampaign=true")
                            break;
                    case "email_feedback": 
                            redirectWithParams("/dashboard/email-feeback", `?client_answer=${client_answer ?? ''}&campaign_name=${campaign_name ?? ''}`)
                            break;
                    default:
                        redirectWithParams(`/${(redirect_to && redirect_to !== '') ? redirect_to : process.env.REACT_APP_DEFAULT_AUTOLOGIN_LINK}`, "")
                        break;
                }
            }else{
                switch (error_code) {
                    case "login_exception":
                        toast.error("Account doesn't found.");
                        history.push("/login")
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        history.push("/login")
                        break;
                }
            }
            } catch (error) {
                toast.error(t(APIFAILED));
                history.push("/login")
            }
        }
        apifunc()
    }, [])

    return (
        <AutoLoginWrapper> 
            <div className="text-center">
                <img src={loginlogo} className="img-fluid" />
                <h1>{t('login_text_member_area')}</h1>
                <p>{t('login_text_opening_member_area_text')}</p>
                <p className="mt-5 mb-3">{t('login_text_please_wait')}</p>
                <span className="spinner-border"></span>
            </div>
        </AutoLoginWrapper>
    )
}
const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};
export default connect(mapStateToProps, {getSubscriptionsRequest})(AutoLogin)