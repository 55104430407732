import { addonConstants } from '../../../../../tools/constants';
import { currencySymbol } from '../../../../../tools/tools';
import { InfoModalMobileWrapper } from '../../../../../styles/dashboard/Main';
import { PrimaryBtn } from "../../../../../styles/Generic";
import { sendEvents } from '../../../../../tools/mpEvents';
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import haveAddon from '../../../../../assets/AddonChecker.png';
import { withRouter } from 'react-router-dom';
import { isAndroid } from 'react-device-detect';

const featuresContent = ['dedicated_server_modal_features_one', 'dedicated_server_modal_features_two', 'dedicated_server_modal_features_three', 'dedicated_server_modal_features_five', 'dedicated_server_modal_features_six', 'dedicated_server_modal_features_seven', 'dedicated_server_modal_features_eight', 'dedicated_server_modal_features_nine', 'dedicated_server_modal_features_ten', 'dedicated_server_modal_features_four'];

const addonsInfo = [
    {
        name:"dedicated_server_modal_table_heading_two",
        slug:"shared_network",
        addonSlug:"",
        content: ['refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'All' ],
    },
    {
        name:"dedicated_server_modal_table_heading_three",
        slug:"dedicated_ip",
        addonSlug:"dedicated_ip",
        content: ['refundform_yes', 'refundform_no', 7, 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', "IKEV,  OPENVPN"],
    },
    {
        name:"dedicated_server_modal_table_heading_four",
        slug:"dedicated_server",
        addonSlug:"dedicated_server",
        content: ['refundform_yes', 'refundform_yes', 30, 'refundform_yes', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', 'refundform_no', "IKEV, OPENVPN"],
    },
    {
        name:"dedicated_server_modal_table_heading_five",
        slug:"dedicated_team_server",
        addonSlug:"",
        content: ['refundform_yes', 'refundform_yes', 30, 'refundform_yes', 'refundform_yes', 'refundform_yes', 'refundform_yes', 'refundform_yes', 'refundform_yes', "IKEV, OPENVPN"], 
    }
]

const DedicatedInfoModalMobile = ({ history, addonType, selectedSubscriptionData, forMobile, onClickFeatureMetric, appEventHandler}) => {
    const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);

    const { t } = useTranslation();

    const [btnText, setBtnText] = useState(t('get_addon'));
    const [selectedAddon, setSelectedAddon] = useState(
        selectedSubscriptionData?.add_ons.find(addon => addon.code === addonConstants.dedicated_ip) ? addonConstants.dedicated_server : addonConstants.dedicated_ip
    );


    const token = localStorage.getItem("token");

    const [comboAddons] = useState({
        dedicated_ip:"dedicated_ip",
        dedicated_ip_ddos:"dedicated_ip_ddos",
        ddos_protection:"ddos_protection",
        dedicated_ip_portforwarding_combo:"dedicated_ip_portforwarding_combo",
        port_forwarding_with_dedicated_ip:"port_forwarding_with_dedicated_ip",
      })


    const isComboAddonSelected = Object.values(comboAddons).some(comboAddon =>
        selectedSubscriptionData?.add_ons.some(addon => addon.code === comboAddon)
    );

    // Addon Selection Function
    const handleAddonClick = (index, slug, selectedSubscriptionData) => {
        // Prevent selecting "Shared Network"
        if (slug === addonConstants.shared_network) {
          return;
        }
      
        if (selectedAddon === slug) {
          return;
        }
        
        setSelectedAddon(slug);
        setBtnText(slug === addonConstants.dedicated_team_server ? t('get_started_text') : t('get_addon'));
      };

    // Open cart on CTA Click
    const handleGetAddonClick = () => {
        if (selectedAddon !== null) {
            const selectedAddonInfo = addonsInfo.find(addon => addon.slug === selectedAddon);
            if (selectedAddonInfo) {
                const selectedAddonType = selectedAddonInfo.slug;
                if (selectedAddonType === addonConstants.dedicated_team_server) {
                    appEventHandler("show_dome_on_ma", { cta: "pureDome" });
                    if(isAndroid){
                        history.push("/dashboard/teams");
                    }
                } else {
                    onClickFeatureMetric(selectedAddonType);
                }
            }
        }
    };

    const getPrice = (slug) => {
        const addon = selectedSubscriptionData?.plan?.add_ons.find((add) => add.code === slug);
        const currency = addon?.currency?.currency;
        const price = addon?.currency?.price / selectedSubscriptionData?.plan?.interval_length;

      // Check if price is NaN and use static value 1.99 if NaN
        const formattedPrice = isNaN(price) ? (slug == addonConstants.dedicated_server ? parseFloat(process.env.REACT_APP_DEDICATED_SERVER_PRICE) : parseFloat(process.env.REACT_APP_DEDICATED_IP_PRICE)) : price.toFixed(2);
        return `${currencySymbol[currency] ?? '$'}${formattedPrice}`;
    }

    useEffect(() => {
        sendEvents({
            event_name:"ma_view_addondetails",
            event_properties:{
                source: "Member Area",
            }
           }, token)
	}, []);
    

    return (
        
        <InfoModalMobileWrapper>
            <span className="d-header">
                <h1 className="heading">{t('dedicated_server_modal_heading')}</h1>
            </span>
            <div className="dashboardView ">
                {addonsInfo.map((addon,key)=>(
                    <div key={key} className={`addonBox ${selectedAddon === addon.slug && addon.slug !== "shared_network" ? "selected" : ""}`} onClick={() => handleAddonClick(key, addon.slug, selectedSubscriptionData)}>
                        <span className="addon-header">
                                <h6>{t(addon.name)}{selectedSubscriptionData?.add_ons.find((add)=> add.code == addon.addonSlug) || (isComboAddonSelected && addon.addonSlug == addonConstants.dedicated_ip) ?<span><img src={haveAddon} /></span>:""}</h6>
                                {   addon.slug === addonConstants.shared_network ?
                                    <span className={`purchaseTag text-capitalize`}>{t('current_plan')}</span>:
                                    <span>
                                        <p className="priceTag">{addon.slug === addonConstants.dedicated_team_server ? `${currencySymbol[selectedSubscriptionData?.plan?.currency?.currency_code] ?? '$'}${pricing?.[selectedSubscriptionData?.plan?.interval_length]}${t('upgradeplan_slash_mo')}` :`${getPrice(addon.addonSlug)}${t('upgradeplan_slash_mo')}`}</p>
                                    </span>
                                }
                            
                        </span>
                        <div className="addon-data">
                            <ul>
                                {addon?.content.map((content, index) => (
                                    <li key={index}>
                                        <span className='desc'>{`${t(featuresContent?.[index])}:`}</span>
                                        <span className='purpleText'>{t(content)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div> 
                ))}
            </div>
            <div className="text-center addonCTA">
                <PrimaryBtn onClick={handleGetAddonClick} isDedicatedMobile disabled={
                        selectedSubscriptionData?.add_ons.find((add) => add.code === selectedAddon)
                    } className={
                        selectedSubscriptionData?.add_ons.find((add) => add.code === selectedAddon)
                            ? "disabled-cta"
                            : ""
                    }> {btnText} </PrimaryBtn>
            </div>
        </InfoModalMobileWrapper>
    )
}

export default withRouter(DedicatedInfoModalMobile)

