import { minDisabledForTeamServer, maxDisabledForTeamServer, getMaximumForTeamServer, getMinimumForTeamServer, hasErrorForTeamServer, getValueForTeamServer, isDisabledForTeamServer, getTotalAmountForTeamServer, onChangeCounterForTeamServer, isNotEmptyForTeamServer, teamServerPricing } from "../tools/teamServer";
import { pureTeamsPostPurchaseStates } from "../../../../tools/constants";
import { TeamServerReducer } from '../reducer/teamServerCounter';
import { useReducer } from 'react';
import { useState, useEffect } from "react";
import AddMoreAddons from "../modals/addMoreAddons";
import AddonPurchase from '../../subscriptions/modals/AddonPurchase';
import ConfigureServer from "../modals/configureServer";
import Info from "../modals/info";
import ServersTable from "../tables/serversTable";
import useDebounce from "../../../../custom-hooks/useDebounce";
import useTeamsServers from "../custom-hooks/useTeamServers";
import { sendEvents } from "../../../../tools/mpEvents";
import { getTokenFromLocalStorage } from "../../../../tools/tools";

const TeamServers = ({ selectedSubscriptionData, plansInfo, teamsCounter, fetchCountAndMembers}) => {
   
    const token = getTokenFromLocalStorage();

    // listing states
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [debouncedSearch, setDebouncedSearch] = useDebounce('', 1000);
    const { teamServers, totalRows, isTeamServersLoaded, teamServersErrorMsg, setFetchTeamServersAgain } = useTeamsServers(page, perPage, debouncedSearch, selectedSubscriptionData, teamsCounter);

    // modal states
	const [modalType, setmodalType] = useState("sm");
    const [orderSummary, setorderSummary] = useState(true);
    const [configureModal, setConfigureModal] = useState();
    const [modalWidth] = useState({
        lg: "684px",
        sm: "440px"
    });
    const [infoModal, setInfoModal] = useState({
        show: '',
        open: ''
    });
	const [addonType, setAddonType] = useState({
        type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:"",
    });

    // add more states
    const [addMoreAddonModal, setAddMoreAddonModal] = useState();
    const [openAddonModal, setOpenAddonModal] = useState();
    const [teamServerState, dispatchForTeamServer] = useReducer(TeamServerReducer, {
        min: 1,
        max: 1,
        current: 1,
        price: teamServerPricing(selectedSubscriptionData, plansInfo),
        previous: 0
    });
    
    useEffect(() => {
        setDebouncedSearch(search);
    }, [search]);

  
    const toggleConfigureModal = () => {
        setConfigureModal(!configureModal)
    }
    const openConfigureModal = () => {
        sendEvents({
            event_name:"ma_click_configure_team_server",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0
            }
        }, token)
        toggleConfigureModal()
    }
    const toggleAddMoreAddonModal = () => {
        setAddMoreAddonModal(!addMoreAddonModal)
    }
    const toggleAddonModal = () => {
		setOpenAddonModal(!openAddonModal)
	}
    const proceedToAddonPurchase = () => {
        toggleAddMoreAddonModal();
        setAddonType({
            subscription: selectedSubscriptionData?.id,
			currency: selectedSubscriptionData?.currency,
			plan: selectedSubscriptionData?.plan?.code,
			gateway:selectedSubscriptionData?.payment_gateway,
            type: [
                {
                    code: 'team_server',
                    quantity: teamServerState.current
                }
            ],
            source: 'teams'
        });
        sendEvents({
            event_name:"ma_click_get_team_server",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                current_team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
                team_server_count: teamServerState.current
            }
        }, token)
        toggleAddonModal();
    }
    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }

    const openInfoModal = () => {
        toggleInfoModal(pureTeamsPostPurchaseStates.find((state)=> state.name  === 'team_server')?.info)
    }

    const refreshAllApis = () => {
        fetchCountAndMembers()
        setFetchTeamServersAgain(prev=>!prev)
    }

    const disabledCtaForTeamServer = () => {
        return isNotEmptyForTeamServer(teamServerState) || isDisabledForTeamServer(teamServerState);
    }

    const openAddMoreModal = () => {
        sendEvents({
            event_name:"ma_click_add_team_server",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
            }
        }, token)
        dispatchForTeamServer({type: 'initial', min: 1, current: 1, previous: 0, price: teamServerPricing(selectedSubscriptionData, plansInfo)})
        toggleAddMoreAddonModal();
    }

    return <>        

        {/* ---TABLE--- */}
        <section className="team-table">
            <ServersTable
                fetchCountAndMembers={refreshAllApis}
                isTeamServersLoaded={isTeamServersLoaded}
                perPage={perPage}
                selectedSubscriptionData={selectedSubscriptionData}
                setPage={setPage}
                setPerPage={setPerPage}
                teamServers={teamServers}
                teamServersErrorMsg={teamServersErrorMsg}
                totalRows={totalRows}
                openAddMoreModal={openAddMoreModal}
                setFetchTeamServersAgain={setFetchTeamServersAgain}
                openConfigureModal={openConfigureModal}
            />
        </section>

        {/* ---MODAL--- */}
        {configureModal && <ConfigureServer teamsCounter={teamsCounter} toggle={toggleConfigureModal} modal={configureModal} modalWidth={modalWidth} widthCalculator={'lg'} selectedSubscriptionData={selectedSubscriptionData} refreshAllApis={refreshAllApis}/>}
        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={infoModal.open} toggle={toggleInfoModal} toggleLicensesModal={toggleAddMoreAddonModal}/>}
        {openAddonModal && <AddonPurchase showInfo={false} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={"lg"} modalType={modalType} modal={openAddonModal} toggle={toggleAddonModal} setorderSummary={setorderSummary} orderSummary={orderSummary} endpoint={'pure-teams/addon-upsell'} redirectedFrom={'teams'} setIsConverted={refreshAllApis}/>}

        {addMoreAddonModal && <AddMoreAddons 
            type={'team_server'}
            state={teamServerState}
            dispatch={dispatchForTeamServer}
            selectedSubscriptionData={selectedSubscriptionData}
            toggle={toggleAddMoreAddonModal}
            modal={addMoreAddonModal}
            modalWidth={modalWidth}
            openInfoModal={openInfoModal}
            proceedToAddonPurchase={proceedToAddonPurchase}
            onTotalAmount={getTotalAmountForTeamServer}
            onDisableCta={disabledCtaForTeamServer}
            minDisabled={minDisabledForTeamServer}
            maxDisabled={maxDisabledForTeamServer}
            onChangeCounter={onChangeCounterForTeamServer}
            getMinimum={getMinimumForTeamServer} 
            getMaximum={getMaximumForTeamServer}
            hasError={hasErrorForTeamServer}
            getValue={getValueForTeamServer}>
        </AddMoreAddons>}
    </>
}
export default TeamServers;