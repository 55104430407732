import { UpgradePbox } from "../../../styles/dashboard/Main";
import { useTranslation } from 'react-i18next'
import { isMaxPlan, isStandard} from "./upgrade-tools";
import { ProductIcon } from '../../../styles/dashboard/Main'
import { currencySymbol, getPriceByCurrency } from '../../../tools/tools'
import { constants } from "../subscriptions/cancellation";

const UpgradePlansBox = ({index, upgradePlan, getDiscountedText, selectedSubscriptionData, onClickUpgradeBox, GotoDiv, selectedPlanInfo}) => {
   
    const { t } = useTranslation();
    let price = 0;
    
    upgradePlan?.add_ons.forEach(addon => {
        if(addon?.code === constants.purevpn) price += getPriceByCurrency(selectedSubscriptionData?.currency, addon?.currencies)
    });
    
    let planPrice = upgradePlan?.currency?.price + price;
    let discountedText = getDiscountedText(upgradePlan, planPrice, upgradePlan?.old_price)
   
    return <UpgradePbox
        className={`mt-3  ${index === 0 ? "firstBox" : 'mx-3'}`}
        key={upgradePlan.id}
        onClick={() => { onClickUpgradeBox(upgradePlan , index); GotoDiv();}}
        isActive={upgradePlan?.id === selectedPlanInfo?.id}
        data-plan-code={upgradePlan?.code}
        orderBox={upgradePlan?.id === selectedPlanInfo?.id}
    >

        {/* Discounted price percentage */}
        <p className="pbox-p1 mt-3">{discountedText}</p>

        {/* Puremax heading for campaign */}

        {/* Plan name */}
        <p className="pbox-p2 mt-3">{upgradePlan.name}</p>

        {/* discounted Price */}
        <p className="mt-2 pbox-p3">{currencySymbol[selectedSubscriptionData?.currency]}{upgradePlan?.old_price?.toFixed(2)}</p>

        {/* original Price */}
        <h1 className="pbox-h1">{currencySymbol[selectedSubscriptionData?.currency]}{planPrice.toFixed(2)}</h1>

        <button className="pbox-btn">{t('upgradeplan_upgradeto_cta_text')} {upgradePlan.name}</button>

        <p className="pbox-p4">{t('upgradeplan_after_upgrade_text')} {upgradePlan.expiry_date}</p>
  </UpgradePbox>
}

export default UpgradePlansBox;