import { addonConstants, constants, addonNames, filter_coupons, featureWrtProduct, redirectedFromPage, multiLoginTiers } from '../../../../tools/constants'
import { addonContent } from '../../../../tools/addonContent';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { connect, useDispatch } from 'react-redux';
import { DestructBtn, PrimaryBtn, LinkBtn } from '../../../../styles/Generic'
import { getUpgradePlansRequested, getUpgradePlansReset } from '../../../../redux/upgrade/actions';
import { InfoCard, PopupBody, OrderSummary } from '../../../../styles/dashboard/Main'
import { Input } from 'reactstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { sendEvents } from '../../../../tools/mpEvents';
import { setSubscriptionType } from '../../../../redux/subscriptions/actions';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import { useRecurly, ThreeDSecureAction } from '@recurly/react-recurly';
import { withRouter } from 'react-router-dom';
import {dateFormat, get, getCurrentSubscription, poster, currencySymbol, isWhmcsUser, isManualGateway,addToData, goToPaymentLink, itemUnitAmount, getTotalAmount, getProrataAmount,getPaymentMethods, toNotDisplayRefreshCart, isRemovedGateway, isResellerUser, checkAccountType} from '../../../../tools/tools'
import classNames from 'classnames'
import MultiLoginContent from './partials/MultiLoginContent';
import paymentFailed from '../../../../assets/payment-failed.svg'
import PaymentIcon from '../../../../utils/PaymentIcon/PaymentIcon';
import paymentSuccess from '../../../../assets/payment-success.svg'
import PopupPurchaseUtil from '../../../../utils/PopupPurchase';
import qs from 'qs'
import React, { useState, useEffect } from 'react'
import RecurlyRoute from '../../../../tools/routes/RecurlyRoute';
import Skeleton from 'react-loading-skeleton';
import SubmitConsent from '../SubmitConsent';
import { onClickGetSS } from '../../upgrade/upgrade-tools';
import CouponField from './partials/CouponField';
import CouponModal from './CouponModal';
import DiscountAndTotalField from './partials/TotalField';
import DedicatedInfoModal from './partials/DedicatedInfoModal';
import { isAndroid, isIOS } from 'react-device-detect';
import DedicatedInfoModalMobile from './partials/DedicatedInfoModalMobile';
import { useTranslation } from 'react-i18next';
import { seti18Locale, checkLocale, checkIsAddonPaid } from '../../../../tools/tools';
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";


const AddonPurchase = ({endpoint, history, backdrop, toggle, setorderSummary, setCrashed, orderSummary, openOrderSummary, setOpenOrderSummary, modal, modalWidth, widthCalculator, addonType, subscriptions:reduxSubs, showInfo = true, redirectRoute = "", hasCountry = true, purchasedAddOnCallback, appSlug = "", onPageClose = false, getDataFromChild, getUpgradePlansRequested, getUpgradePlansReset, viaProxy, couponApplied=null, redirectedFrom, setIsConverted}) => {
    const dispatch = useDispatch()

    const { pathname } = useLocation();

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const [loading, setloading] = useState(true)

    const [threedSecure, setThreedSecure] = useState({
        enabled:false,
        three_d_secure_action_token_id: "",
        oldToken:""
    })

    const recurly = useRecurly();

    const selectedSub = reduxSubs?.subscriptions?.body?.find(sub=>sub?.id === addonType?.subscription)

    const [purchaseLoading, setpurchaseLoading] = useState(false)

    const [infoModal, setinfoModal] = useState(showInfo)

    const [isPurchased, setIsPurchased] = useState(false)

    const [isChildUser, setisChildUser] = useState(false)
    const [hasVolumePlan, setHasVolumePlan] = useState(false)
    const [hasTeams, setHasTeams] = useState(false)
    const [declineState, setDeclineState] = useState(false)

    const [whmcsUser, setWhmcsUser] = useState(false)
    const [multiLimit, setMultiLimit] = useState();
    const [isAllowToUseParamMethod, setIsAllowToUseParamMethod] = useState(false)

    const [endPoint, setEndPoint] = useState("account/change-payment-method")

    const [afterTokenData, setAfterTokenData] = useState({})

    const [afterPurchaseRes, setAfterPurchaseRes] = useState()

    const token = localStorage.getItem("token")

    const [invoiceData, setinvoiceData] = useState({
        countries:[],
        orderSummary:{},
        response:{}
    })

    const [isEligible, setIsEligible] = useState(true);
    const [unelgibleMsg, setUneligibleMsg] = useState(null)

    const donotSendCountryFor = ['port_forwarding', addonConstants['multi_login']]

    const isMultiLoginHide = +process.env.REACT_APP_MULTILOGIN_VIEW;

    const shouldShowDedicatedServer = +process.env.REACT_APP_SHOW_DEDICATED_SERVER;

    const [isAddonPending] = useState(addonType?.number > 0 ? true : false)

    const [data, setData] = useState({
        subscription_id: addonType.subscription,
        add_ons: addonType.type,
        country: donotSendCountryFor?.includes(addonType.type) ? "" : "US",
        city: "",
        preview:0,
        validate:1,
        gateway:addonType.gateway,
        login_limit: addonType.type === addonConstants['multi_login']? multiLimit : null
    })

    const [state, setstate] = useState({
        plan_type:"",
        username:"",
        email: "",
        period:"",
        last_four:"",
        is_paused:false
    })

    const [totalAmountWithCreditAdjustment, setTotalAmountWithCreditAdjustment] = useState();
    const [getAddonName] = useState({
        team_server:`${t('get_text')} Team Server`,
        residential_network:`${t('get_text')} Residential Network`,
        port_forwarding:`${t('get_text')} Port Forwarding`,
        ddos_protection:`${t('get_text')} DDos Protection`,
        dedicated_ip:`${t('get_text')} Dedicated IP`,
        purekeep:`${t('get_text')} Purekeep`,
        purevpn:`${t('get_text')} PureVPN`,
        purescan:`${t('get_text')} PurePrivacy`,
        purecrypt:`${t('get_text')} PureEncrypt`,
        pureprivacy:`${t('get_text')} PurePrivacy`,
        port_forwarding_with_dedicated_ip:`${t('get_text')} Dedicated IP With Portforwarding` ,
        puremax: `${t('get_text')} PureMax`,
        multi_login:`${t('get_text')} Multi Login`,
        dedicated_server:`${t('get_text')} Dedicated Server`,
        teams: 'Upgrade to Teams',
    })

    const [paymentData, setPaymentData] = useState({
        paymentStatus : false,
        image: paymentFailed,
        heading:t('payment_failed_text'),
        description:"",
        ctaText:t('payment_details_go_back_cta'),
        type: '',
        showInfo: false
    })

    const [showCouponView,setShowCouponView] = useState(false)

    const [isCouponApplied,setCouponApplied] = useState(couponApplied ? couponApplied :null)

    let gateway;

    const [oldInvoice, setOldInvoice] = useState({...invoiceData,isRemove:true})

    const { search } = useLocation();

    const { redirected_via,  platform, features_metric, redirect_uri} = Object.fromEntries(new URLSearchParams(search));

    const [messagesForApps, setMessagesForApps] = useState({

        message: '',

        show: false

    })
    const [dedicatedServerOptions, setDedicatedServerOptions] = useState([]);

    const [selectedDedicatedServerOption, setSelectedDedicatedServerOption] = useState("");

    const [selectedCountry, setSelectedCountry] = useState({
        name: '', 
        code: ''
    });

    const [selectedCity, setSelectedCity] = useState({
        name: '', 
        code: ''
    });

    const appEventHandler = (message, response) => {
        let _nativeHandler = () => window.chrome ? window.chrome.webview : (window.webkit ?? {messageHandlers: {}}).messageHandlers.addonEventHandler;
        _nativeHandler() && _nativeHandler().postMessage({
            'message': message,
            'response': response
        });
    }

    const dontCloseModalBit = (error_code="") => {
        return new Set(constants.dontCloseModalErrorCode).has(error_code)
    }

    useEffect(() => {

        async function apifunc(gateway=null){
            setloading(true)
            let allPromises = [];

            if (constants.hasCountryAddons.includes(addonType.type)) {
                const formData = qs.stringify({
                    add_on: (addonType.type === "port_forwarding") ? addonConstants.dedicated_ip : addonType.type
                })

                const {data: countries} = await poster("addon/countries", formData, token)
                
                allPromises.push(countries)
            }
            else if (addonType.type === addonConstants.dedicated_server) {
                const dedicatedServerOptionsUrl ="addon/regions";
                const  response = await get(dedicatedServerOptionsUrl, token);
                allPromises.push(response.data)
            }

            let apiUrl = endpoint ?? "addon/upsell";
            const addon_upsell_data = {
                subscription_id: addonType.subscription,
                add_ons: addonType.type,
                country: donotSendCountryFor?.includes(addonType.type) ? "" : "US",
                preview: 1,
                validate: 0,
                gateway,
                login_limit: addonType.type === addonConstants['multi_login']? multiLimit : null
            }
            if(isCouponApplied){
                addon_upsell_data['coupon_code'] = isCouponApplied["reward.coupon_code"]
            }

            const previewData_1 = addToData(addon_upsell_data,addonType?.number)
            const previewData = qs.stringify(previewData_1);
            const {data:preview} = await poster(apiUrl, previewData, token)

            if(preview?.body?.account){
                if(isWhmcsUser(preview?.body?.account?.billingType)){
                    setWhmcsUser(true)
                    setIsAllowToUseParamMethod(true)
                    setEndPoint(endpoint ?? "addon/upsell")
                }
                if(isCouponApplied){
                    data['coupon_code'] = isCouponApplied["reward.coupon_code"]
                }
            }

            allPromises.push(preview)

            //TODO: improve the performance
            //issue: array 'allPromises' don't have any promise.
            Promise.allSettled(allPromises)
            .then(response => {
                const success = response.every(res=>res?.value?.status === true)
                if(success){
                    if(addonType.type == addonConstants.dedicated_server) {
                        const regions   = response[0]?.value?.body ?? [];
                        const countries = [], cities = {}; 
                        regions.map(async region => {
                            countries.push({
                                value: region.country_code,
                                label: region.country
                            }) 

                            cities[region.country_code] = region.cities.map((cityInfo) => {
                                return {
                                    value: cityInfo.city_code,
                                    label: cityInfo.city,
                                };
                            })  
                        });

                        setData({...data, country:"USA", city:cities.USA[0].value, add_ons: addonType.type })
                        setinvoiceData({
                            countries,
                            cities,
                            orderSummary:response[1]?.value?.body?.items ?? [],
                            response:response[1]?.value?.body ?? [],
                        })
                        setTotalAmountWithCreditAdjustment(getTotalAmount(response[1]?.value?.body))

                    } else if (!constants.hasCountryAddons.includes(addonType.type)) {
                        setinvoiceData({
                            countries: [],
                            orderSummary:response[0]?.value?.body?.items ?? [],
                            response:response[0]?.value?.body ?? [],
                        })
                        setOldInvoice({
                            countries: [],
                            orderSummary:response[0]?.value?.body?.items ?? [],
                            response:response[0]?.value?.body ?? [],
                        })
                        setTotalAmountWithCreditAdjustment(getTotalAmount(response[0]?.value?.body))
                    } else {
                        setData({...data, add_ons: addonType.type, country:"US", city:""})
                        setinvoiceData({
                            countries: response[0]?.value?.body ?? [],
                            orderSummary:response[1]?.value?.body?.items ?? [],
                            response:response[1]?.value?.body ?? [],
                        })
                        setOldInvoice({
                            countries: [],
                            orderSummary:response[0]?.value?.body?.items ?? [],
                            response:response[1]?.value?.body ?? [],
                        })
                        setTotalAmountWithCreditAdjustment(getTotalAmount(response[1]?.value?.body))
                    }

                }else{
                    if(response?.some(res=>res?.value?.error_code === "token_expired")){
                        toast.error(TOKENEXPIRED);
                        toggle()
                        dispatch({type:"LOGOUT"})
                    }else if(response?.some(res=>res?.value?.error_code === "token_invalid")){
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                    }else if(response?.some(res=>res?.value?.error_code === "jwt_default_exception")){
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                    }
                    else if(response?.some(res=>res?.value?.error_code === "recurly_exception")){
                        if(redirected_via){
                            showErrorMessage(response?.find(res=>res?.value?.error_code === "recurly_exception")?.value?.message)
                        }else{
                            toast.error(response[0]?.value?.message)
                            toggle()
                        }
                    }
                    else if(response?.some(res=>res?.value?.error_code === "add_on_up_sell_exception")){
                        let error_message = response?.find(res=>res?.value?.error_code === "add_on_up_sell_exception")?.value?.message
                        sendEvents({
                            event_name: redirectedFrom === redirectedFromPage['teams'] ? "ma_fail_load_team_addon" : "ma_fail_load_addon",
                            event_properties: {
                                purchaseType: `Addon - ${redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.code : addonType.type}`,
                                billing_cycle: selectedSub?.plan?.type ?? "N/A",
                                platform_from: viaProxy ? "Proxy" : redirected_via ? 'Apps' : 'Member Area',
                                payment_method: getPaymentMethods(selectedSub), 
                                reason : error_message,
                            }
                          }, token);

                        let message = `You have already purchased the addon. Please relogin to start using the add-on.`;
                        response?.some(res=>res?.value?.error_code === "add_on_up_sell_exception" ? showErrorMessage((error_message ?? message), redirectedFrom === redirectedFromPage['teams'] ? false : true) : '');
                        setCouponApplied(null)
                    }
                    else{
                        toast.error(t(APIFAILED));
                        toggle()
                    }
                }
            }).catch(e=>{
                toast.error(t(APIFAILED))
                toggle()
            }).finally(()=>setloading(false))
        }

        !oldInvoice.isRemove && setinvoiceData({loading:false,...oldInvoice})
        
        gateway = selectedSub?.payment_gateway
        !selectedSub?.is_child && !infoModal && apifunc(gateway)

        setisChildUser(selectedSub?.is_child)
        setHasVolumePlan(selectedSub?.hasVolumePlan)
        setHasTeams(selectedSub?.hasPureTeams)

        setstate({
            plan_type: `${selectedSub?.plan?.interval_length} ${selectedSub?.plan?.interval_length === 1 ? "Month" : "Months"}` ,
            username: selectedSub?.vpnusernames?.[0] ?? "-",
            email: selectedSub?.account?.email ?? "-",
            period: `${dateFormat(selectedSub?.start_date)} - ${dateFormat(selectedSub?.end_date)}`,
            last_four:selectedSub?.billing_info?.last_four,
            is_paused: selectedSub?.is_paused,
            intervalLength: selectedSub?.plan?.interval_length,
            payment_gateway_offline: selectedSub?.payment_gateway_offline,
            hasFamilyPlan: selectedSub?.hasFamilyPlan,
            gateway,
        })
    }, [infoModal, isCouponApplied])

    useEffect(()=>{
        if(redirected_via && window.Intercom){
            window.Intercom('shutdown')
        }
    }, [])
    
    const handleCountryChange = (ele) => {
        setData({...data, country:ele.target.value, city:invoiceData?.cities[ele.target.value][0]?.value, add_ons: addonType.type, })
    };

    const handleCityChange = (ele) => {
        setData({...data, city:ele.target.value, add_ons: addonType.type,})
    };

    const addonPrice = (addon) => {
    try {
        let price = getTotalAmount(addon);
        return price ?? 0;
    } catch (error) {
        toast.error("Something went wrong - PURE-115");
        toggle();
    }
};


    const buttonText = () => {
        return !purchaseLoading ? t('addon_purchase_modl_pay_now') : ( <>{t('addon_purchase_modl_pay_now')} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    const changeDeclineState = (status) => {
        if (constants.applicationAddons.includes(addonType?.type)) {
            setDeclineState(status)
        }
    }

    const showErrorMessage = (message, showInfo) => {
        setPaymentData({...paymentData, description : message, image: false, heading: '', showInfo: showInfo})
        setIsPurchased(true)
    }

    const calculateMrr = (orderSumm) => {

        let mrr = orderSumm.reduce((acc, obj) => {
            // Check if the key exists in the object and the value is a number
            if (obj.hasOwnProperty('unit_amount') && typeof obj['unit_amount'] === 'number' && (obj?.origin === 'plan' || obj?.origin === "add_on")) {
                return acc + (obj['unit_amount'] * obj?.quantity);
            }
            return acc;
        }, 0);

        return (mrr && selectedSub?.plan?.interval_length) ? (mrr/selectedSub?.plan?.interval_length)?.toFixed(2) : 'N/A'
    }

    const addonPurchaseAsync = async (data) => {
        if(isAllowToUseParamMethod){
            setAfterTokenData({...data});
        }
        if(redirected_via){
            appEventHandler("pay_now", {});
        }
        let threedSecureStatus = false;
        const currentSub = getCurrentSubscription(reduxSubs, addonType.subscription)
        sendEvents(
            {
            event_name: redirectedFrom === redirectedFromPage['teams'] ? "ma_click_team_addon_checkout" : "ma_click_addon_checkout",
            event_properties: {
              addon_name: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.code : addonType.type,
              addon_count: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.quantity : addonType.type === addonConstants['multi_login']? multiLimit : 0,
              new_mrr: calculateMrr(invoiceData.orderSummary),
              amount: totalAmountWithCreditAdjustment ?? "N/A",
              vat: getVat(invoiceData.orderSummary) ?? "N/A",
              billing_cycle: currentSub?.plan?.type ?? "N/A",
              payment_method: addonType?.gateway,
              isAddonPending,
              platform_from: viaProxy ? "Proxy" : redirected_via ? 'Apps' : 'Member Area',
              source: addonType?.source ?? "N/A",
              feature_name: addonType?.feature_name ?? "N/A"
            }
          }, token);
        try {
            const apiUrl = endpoint ?? "addon/upsell";
            data = {
                ...data,
                login_limit: addonType.type === addonConstants['multi_login']? multiLimit : null
            }
            const previewData_0 = addToData(data,addonType?.number)
            const formdata = qs.stringify(previewData_0)
            const purchase = await poster(apiUrl, formdata, token)
            const {data: response} = purchase;
            if(response?.status){
                setAfterPurchaseRes(response?.body)
                if(isManualGateway(data?.gateway)){
                    !redirected_via && dispatch(setSubscriptionType(response?.body?.subscription))
                    goToPaymentLink(response?.body,toggle())
                }else{
                    if(!onPageClose && !redirected_via){
                        dispatch(setSubscriptionType(response?.body))
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                        getUpgradePlansReset()
                    }
                    setIsPurchased(true);
                    setPaymentData({
                        paymentStatus : true,
                        image: paymentSuccess,
                        heading:t('payment_successful_text'),
                        description:`<span class="addonNameOnPopup"><b>${redirectedFrom === redirectedFromPage['teams'] ? addonNames[addonType.type?.[0]?.code === 'purevpn' ? 'team_members' : addonType.type?.[0]?.code] : addonNames[addonType.type]}</b></span> ${t('payment_successful_text_para')}`,
                        ctaText: redirectedFrom === redirectedFromPage['teams'] ? 'Redirect to Home' : t('continue_text')
                    })
                    sendEvents(
                        {
                            event_name: redirectedFrom === redirectedFromPage['teams'] ? "ma_purchase_team_addon" : "ma_purchase_addon",
                            event_properties: {
                              addon_name: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.code : addonType.type,
                              addon_count: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.quantity : addonType.type === addonConstants['multi_login']? multiLimit : 0,
                              amount: totalAmountWithCreditAdjustment ?? "N/A",                 
                              new_mrr: calculateMrr(invoiceData.orderSummary),
                              vat: getVat(invoiceData.orderSummary) ?? "N/A",
                              billing_cycle: currentSub?.plan?.type ?? "N/A",
                              payment_method: data ? data?.gateway : addonType?.gateway,
                              isAddonPending,
                              platform_from: viaProxy ? "Proxy" : redirected_via ? 'Apps' : 'Member Area',
                              source: addonType?.source ?? "N/A",
                              feature_name: addonType?.feature_name ?? "N/A"
                            }
                        }, token);
                }


                if (redirectRoute !== "" && !redirected_via) {
                    addonType.type == 'purekeep' ? window.location = redirectRoute : history.push(redirectRoute)
                }

                if(redirected_via){
                    appEventHandler("addon_purchase_success", {});
                }

                if(isAndroid && redirect_uri && redirect_uri != undefined){
                    let redirectURI = window.atob(redirect_uri)
                    setTimeout(() => {
                        window.location.href = redirectURI;
                    }, 5000);
                }

                if(!redirected_via){
                    clearPath()
                }

            }else{
                let AppEventsData = {
                    type: response?.error_code,
                    message: "addon_purchase_error",
                    data:{
                        isPurchasedSucessFully : false,
                        dontCloseModal: dontCloseModalBit(response?.error_code)
                    }
                }
                appEventHandler("addon_purchase_error", AppEventsData);
                switch (response?.error_code) {
                    case "token_expired":
                        setPaymentData({...paymentData, description : TOKENEXPIRED})
                        setIsPurchased(true)
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        setPaymentData({...paymentData, description : (t(INVALIDTOKEN))})
                        setIsPurchased(true)
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;      
                    case "declined":
                        setPaymentData({...paymentData, description : response?.message})
                        setIsPurchased(true)
                        setDeclineState(true)
                        break;  
                    case "validation_exception":
                        response?.errors?.add_ons.map(error=>setPaymentData({...paymentData, description : error}))
                        setIsPurchased(true)
                        break;  
                    case "add_on_up_sell_exception":
                        setPaymentData({...paymentData, description : response?.message})
                        setIsPurchased(true)
                        setDeclineState(true)
                        break; 
                    case "recurly_exception":
                        setPaymentData({...paymentData, description : response?.message})
                        setIsPurchased(true)
                        setDeclineState(true)
                        break;
                    case "jwt_default_exception":
                        setPaymentData({...paymentData, description : (t(INVALIDTOKEN))})
                        setIsPurchased(true)
                        dispatch({type:"LOGOUT"})

                        break;
                    case "three_d_secure_action_required":
                        setThreedSecure({
                            enabled: true,
                            three_d_secure_action_token_id: response?.errors?.three_d_secure_action_token_id,
                            oldToken: ""
                        })
                        setIsPurchased(false)
                        threedSecureStatus = true;
                        break;
                    default:
                        setPaymentData({...paymentData, description : response?.message});
                        setIsPurchased(true)
                        break;
                }
                if(!redirected_via){
                    clearPath()
                }
                sendEvents(
                    {
                    event_name: redirectedFrom === redirectedFromPage['teams'] ? "ma_fail_purchase_team_addon" : "ma_fail_purchase_addon",
                    event_properties: {
                        reason: response?.message,
                        addon_name: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.code : addonType.type,
                        addon_count: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.quantity : addonType.type === addonConstants['multi_login']? multiLimit : 0,
                        payment_method: addonType?.gateway,
                        isAddonPending,
                        platform_from: viaProxy ? "Proxy" : redirected_via ? 'Apps' : 'Member Area',
                        source: addonType?.source ?? "N/A",
                        feature_name: addonType?.feature_name ?? "N/A"
                    }
                }, token);
            }
        } catch (error) {
            setIsPurchased(true)
            setPaymentData({...paymentData, description : APIFAILED})
            sendEvents(
                {
                event_name: "ma_fail_purchase_addon",
                event_properties: {
                  reason: "API break or Server not responding",
                  addon_name: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.code : addonType?.type,
                  addon_count: redirectedFrom === redirectedFromPage['teams'] ? addonType.type?.[0]?.quantity : addonType.type === addonConstants['multi_login']? multiLimit : 0,
                  payment_method: addonType?.gateway,
                  isAddonPending,
                  platform_from: viaProxy ? "Proxy" : redirected_via ? 'Apps' : 'Member Area',
                  source: addonType?.source ?? "N/A",
                  feature_name: addonType?.feature_name ?? "N/A"
                }
              }, token);
        }
        finally{
            if(!threedSecureStatus){
                setpurchaseLoading(false)
            }
        }
    }

    const onCloseChangePath = (orderOpenSummary) => {
        if(!orderOpenSummary && setOpenOrderSummary){
            setOpenOrderSummary(!openOrderSummary)
        }
        toggle()
        clearPath()
    }

    const clearPath = () => {
        history.replace(pathname, '')
    }

    const purchaseAddon = async (paramsData = {}) => {
        setpurchaseLoading(true)
        let payload = {...data, ...paramsData};
        addonPurchaseAsync(payload);
    }

    const handle3dToken = (token) => {
        
        if(token){
            let formData = {};
            if(Object.keys(afterTokenData).length > 0 ){
                formData = {...afterTokenData, threed_secure_id: token.id};
            }else{
                formData = {
                    subscription_id: addonType.subscription,
                    add_ons: addonType.type,
                    country: donotSendCountryFor?.includes(addonType.type) ? "" : "US",
                    preview:0,
                    threed_secure_id: token.id,
                    validate: 0,
                    gateway,
                    login_limit: addonType.type === addonConstants['multi_login']? multiLimit : null
                }
            }
            addonPurchaseAsync(formData)
        }else{
            toast.error("Something went wrong!!!")
            setpurchaseLoading(false)
        }
        setThreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        
        setPaymentData({...paymentData, description : error?.message || "Something went wrong!!!"})
        setIsPurchased(true)
        setpurchaseLoading(false)
        setThreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const continueToNextStep = () =>{

        if(paymentData.showInfo){
            setinfoModal(!infoModal);
            return;
        }

        if(paymentData.ctaText == 'Go Back'){
            continueToGoBack()
        }
        if (typeof purchasedAddOnCallback != "undefined") {
            if(paymentData?.paymentStatus){
                purchasedAddOnCallback()
            }else{
                setIsPurchased(!isPurchased)
            }
        } else {
            if(paymentData?.paymentStatus){
                getUpgradePlansReset()

                if(redirectedFrom === "featureUpgrade"){

                    setIsConverted(true);
                    toggle();
                    return;

                }
                if(redirectedFrom === "teams"){
                    setIsConverted();
                    toggle();
                    return;
                }

                if(addonType.type == 'purekeep'){
                    window.location = process.env.REACT_APP_PUREKEEP_THANKYOU_LINK
                }else if(addonType.type == 'port_forwarding' || addonType.type == 'port_forwarding_with_dedicated_ip'){
                    if(onPageClose){
                        dispatch(setSubscriptionType(afterPurchaseRes))
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token , locale:checkLocale()})
                        getUpgradePlansRequested(token);
                        getDataFromChild(true)
                        toggle()
                    }else{
                        history.push("/dashboard/port-forwarding?getConf=true")
                    }
                }else{
                    if(redirectedFrom === 'manualConfig'){
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token , locale:checkLocale()})
                        toggle()
                        return;
                    }
                    //   Redirect to security tools
                    history.push("/dashboard/security-tools")
                }
                toggle()
            }else{
                // Close the Failure pop-up
                setIsPurchased(false)
            }
        }
    }

    const continueToGoBack = () =>{
        sendEvents({
            event_name: "ma_click_go_back",
            event_properties: {
                platform: viaProxy ? "Proxy" : platform ?? 'Member area'
            }
        }, token)
    }

    const showAddonName = (name) => getAddonName[addonType.type].replace(/get /gi, "").trim()

    const chargeAttempt = (paramsData = {}) => {
        const response = isAllowToUseParamMethod > 0 ? {...paramsData, ...data} : data;

        if(whmcsUser){
            response.gateway = response.paymentMethodWhmcs;
        }
        if(isRemovedGateway(data?.gateway)) {
            response.gateway = paramsData?.change_pm
        }
        setpurchaseLoading(true)
        purchaseAddon(response)
    }

    const getProratePrice = (data) => {
        try {
            let proratedPrice = 0;
            let proratedText = '';
            if(data?.unit_amount > data?.sub_total){
                proratedPrice = data?.unit_amount - data?.sub_total
                proratedText = `-${currencySymbol[addonType.currency]}${proratedPrice.toFixed(2)}`
            }else{
                proratedPrice = data?.sub_total - data?.unit_amount
                proratedText = `+${currencySymbol[addonType.currency]}${proratedPrice.toFixed(2)}`
            }
            return proratedText
        } catch(err) {
            if(setCrashed){
                setCrashed(true);
            }    
        }  
    }
    const getSubTotal = (addon) => {
        try {
            let SubTotal = addon.reduce((a, item) => a + item?.sub_total, 0)
            return SubTotal.toFixed(2) ?? 0
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
            toggle()
        }
    }
    const getVat = (addon) => {
        try {
            let VatPrice = addon.reduce((a, item) => a + item.tax, 0)
            return VatPrice.toFixed(2) ?? 0
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
            toggle()
        }
    }
    const getProrate = (addon) => {
        try {
            let proratePrice = getProrataAmount(addon)
            proratePrice = proratePrice.toFixed(2)
            return  (proratePrice < 0 ? (-1 * proratePrice) : proratePrice) ?? 0
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
            toggle()
        }
    }
    const addonUnitPrice = (addon) => {
        try {
            let unitprice = addon.reduce((a, item) => a + item.unit_amount, 0)
            return unitprice.toFixed(2) ?? 0
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
            toggle()
        }
    }
    const prorateSign = (data) => {
        return parseFloat(addonUnitPrice(data)) > parseFloat(addonPrice(data)) ?  "-":"+"
    }
    const toggleInfoModal = () => {
        appEventHandler("get_this_addon", {});
        setinfoModal(!infoModal)
    }

    const getProratedAmount = (items, currency) => {

        let sub_total = subtotalForProrata(items);
        let unit_amount = unitAmountlForProrata(items);

        let proratedPrice = sub_total - unit_amount;
        let arithmeticSymbol = sub_total > unit_amount ? '+' : '-';

        return `${arithmeticSymbol}${currency}${Math.abs(proratedPrice)?.toFixed(2)}`
    }

    const subtotalForProrata = (addon={}) => {
        return Object.keys(addon).length !== 0 && addon?.reduce((a, item) => a + item?.sub_total, 0)
    }

    const unitAmountlForProrata = (addon={}) => {
        return Object.keys(addon).length !== 0 && addon?.reduce((a, item) => a + (item?.unit_amount * item?.quantity), 0)
    }
    const onClickFeatureMetric = (slug) => {
        setData({
            ...data,
            add_ons:slug
        });
        addonType.type = slug;
        toggleInfoModal();
        setloading(true)
        appEventHandler("metric_cta_clicked", { cta: slug })

        sendEvents({
            event_name:"ma_select_addon",
            event_properties:{
                addon_name: slug,
                source: "features_metric",
            }
        }, token)
    }

    const mobileCss = () => {
        let paddingCss = {}
        if(redirected_via) {
            paddingCss['height'] = 'calc(100vh - 40px)'
            paddingCss['overflowY'] = "auto"
        }
        if(!(isAndroid || isIOS) && features_metric == 1){
            paddingCss['padding'] = '0rem'
        }
        return paddingCss
    }

    const isAddonUnavailable = (subscription) => {
        return subscription?.is_trial || 
            subscription?.is_child ||
            isResellerUser(subscription?.payment_gateway) || 
            subscription?.state === "expired" ||
            isWhmcsUser(subscription?.account?.billingType) && (subscription?.state == "past_due" || subscription?.state == "expired") || 
            isWhmcsUser(subscription?.account?.billingType) && (subscription?.payment_gateway_offline && !subscription?.account?.has_mobile_gateway_for_whmcs) || 
            constants.past_due_state?.includes(subscription?.state) && subscription?.payment_gateway_offline
    }

    const checkAddOn = (sub, type) => {
        const {add_ons ,account} = sub
        const check = Array.isArray(add_ons) ? add_ons.some((addon) => addon.code === type && checkIsAddonPaid(addon)) : false;
        return check
    }

    const showAddonView = (subscription) => {
        let isAddonViewEligible = true
        switch(addonType.type){
            case addonConstants.dedicated_ip:
                isAddonViewEligible= !checkAddOn(subscription, "dedicated_ip")
                break
            case addonConstants.ddos_protection:
                isAddonViewEligible= !checkAddOn(subscription, "port_forwarding_with_dedicated_ip") && !checkAddOn(subscription, "dedicated_ip")
                break
            case addonConstants.port_forwarding_with_dedicated_ip:
                isAddonViewEligible= (!checkAddOn(subscription, "dedicated_ip") && !checkAddOn(subscription, "port_forwarding")  && !checkAddOn(subscription, "ddos_protection")) || checkAddOn(subscription, "port_forwarding_with_dedicated_ip")
                break
            case addonConstants.port_forwarding:
                isAddonViewEligible= !checkAddOn(subscription, "port_forwarding")
                break
            case addonConstants.multi_login:
                isAddonViewEligible = isMultiLoginHide ? false : true;
                break
            case addonConstants.dedicated_server:
                isAddonViewEligible = shouldShowDedicatedServer ||  checkAddOn(subscription, "dedicated_server");
        }
        return isAddonViewEligible
    }

    const redirectToTeams = () => {
        sendEvents({
                event_name: "ma_click_redirect_to_teams",
                event_properties: {
                  addon_name: addonType.type,
                  addon_count: selectedSub?.add_ons?.find((addon)=> addon.code === 'multi_login') ? selectedSub?.add_ons?.find((addon)=> addon.code === 'multi_login')?.quantity : 10,
                  billing_cycle: selectedSub?.plan?.type ?? "N/A",
                  payment_method: getPaymentMethods(selectedSub), 
                  plan_type: selectedSub?.plan_type ?? 'N/A',
                  current_status: selectedSub?.state ?? 'N/A',
                  account_type: checkAccountType(selectedSub) ?? 'N/A',
                }
            }, token);
        history.push('/dashboard/teams');
    }

    const getCreditAdjustment = (invoice) => {
        return `${invoice?.credit_amount ? `-${currencySymbol[selectedSub?.currency]}${invoice?.credit_amount?.toFixed(2)}` : `${currencySymbol[selectedSub?.currency]}0.00`}`
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${features_metric || (addonType.type == addonConstants.dedicated_server) ? "852px" : modalWidth[widthCalculator]}`, width: '100%'}} className={`modal-wrapper new-cart ${redirected_via ? 'white-bg' : null}`}>
            {!redirected_via && <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>}
                <ModalBody style={mobileCss()}>
                {
                    (isAddonUnavailable(selectedSub) || !showAddonView(selectedSub)) && !isPurchased ?
                        <>
                            <ModalBody>
                                <PopupBody>
                                    <div className="mod-head">
                                        <h3>{t('addon_purchasemodal_uhoh')}</h3>
                                    </div>
                                    <div className="mod-body">
                                        <p className="m-0">{isChildUser ? (hasVolumePlan ? 'Only an admin can purchase an addon.' : hasTeams ? 'Only a team manager can purchase an addon in the Teams plan.' : 'Only a family admin can purchase an addon in the Family plan.') : (isResellerUser(selectedSub?.payment_gateway) ? "Reseller's user can't purchase this addon" : 'You\'re not eligible to buy this addon!') }</p>
                                    </div>
                                </PopupBody>
                            </ModalBody>
                            <ModalFooter>
                                <DestructBtn color="secondary" onClick={toggle}>{t('cancel_text')}</DestructBtn>
                            </ModalFooter>
                        </>
                    :
                    <>
                        {
                            infoModal ?
                            <>
                                {
                                    addonType.type === addonConstants.multi_login ? <MultiLoginContent selectedSub={selectedSub} multiLimit={multiLimit} setMultiLimit={setMultiLimit} redirectToTeams={redirectToTeams}/> : 
                                    (
                                        features_metric == 1 && constants.features_addons.includes(addonType.type) ? 
                                        (
                                            (isAndroid || isIOS) ?
                                              <><DedicatedInfoModalMobile addonType={addonType} appEventHandler={appEventHandler} forMobile={true} Mobile onClickFeatureMetric={onClickFeatureMetric} selectedSubscriptionData={selectedSub}/></>
                                            : <DedicatedInfoModal addonType={addonType} appInfoModal appEventHandler={appEventHandler} onClickFeatureMetric={onClickFeatureMetric} selectedSubscriptionData={selectedSub}/> 
                                        ) 
                                        : 
                                        (
                                            constants.ma_features_addons.includes(addonType.type) && process.env.REACT_APP_FEATURES_METRIC == 1 ?
                                             <DedicatedInfoModal addonType={addonType} appInfoModal appEventHandler={appEventHandler} redirectToBusiness onClickFeatureMetric={onClickFeatureMetric} selectedSubscriptionData={selectedSub}/> 
                                           : <PopupBody dangerouslySetInnerHTML={{ __html: t(addonContent[addonType.type]) || "" }} ></PopupBody>
                                        )
                                    )
                                }
                                {   // Modal footer For CTAS of Get This Addon and Cancle
                                    // Had to hide modal footer for dedicated server mobile view
                                    !(features_metric == 1 && constants.features_addons.includes(addonType.type) && (isAndroid || isIOS)) &&
                                    <ModalFooter className='p-0 pt-2'>
                                        {
                                            // Get This Addon CTA Logic to not render the CTA in Metric table view
                                            ((features_metric  && !constants.features_addons.includes(addonType.type) ) || (!features_metric && addonType.type !== addonConstants.dedicated_server )) && 
                                            <PrimaryBtn color="primary" 
                                                onClick={e => !selectedSub?.plan?.add_ons?.find(addon => addon.code === addonConstants.multi_login)?.tiers?.length > 0 ? redirectToTeams() : toggleInfoModal()}
                                            >
                                                {(!selectedSub?.plan?.add_ons?.find(addon => addon.code === addonConstants.multi_login)?.tiers?.length > 0 && addonType.type === addonConstants.multi_login) ? t('get_teams') : t('get_this_addon_text')}
                                            </PrimaryBtn> 
                                        }
                                        {
                                            addonType.type == "purekeep" && !redirected_via &&
                                            <div className="ms-auto">
                                                <LinkBtn onClick={e=> window.location = process.env.REACT_APP_PUREKEEP_PAGE_LINK}>learn more...</LinkBtn>
                                            </div>
                                        }
                                    </ModalFooter>
                                
                                }
                                
                            </>
                            :
                            <>
                                {
                                    !loading
                                    ?
                                        !showCouponView ? 
                                            <>
                                                {
                                                    !isPurchased
                                                    ?
                                                    <>
                                                        <PopupBody>
                                                                <div className="mod-head">
                                                                    <h3>{redirectedFrom === redirectedFromPage['teams'] ? getAddonName[addonType.type?.[0]?.code] : getAddonName[addonType.type]}</h3>
                                                                    {redirectedFrom === 'featureUpgrade' && <span className='feature_name'>{t('include_etc_text_for_security_tools', {feature_names: featureWrtProduct[addonType.type]})}</span>}
                                                                </div>
                                                                <div className={`${redirected_via ? 'no-scroll' : null} mod-body`}>
                                                                    <InfoCard hasborder noflex className="pt-0">
                                                                        <div>
                                                                        <label>{t('subscription_lable_billing_cycle')} <strong className="text-capitalize">{state.plan_type || "-"}</strong></label>
                                                                        </div>
                                                                        {
                                                                            (constants.applicationAddons.includes(addonType.type) || addonType?.type === 'purevpn') ? (
                                                                                <div>
                                                                                    <label>{t('payment_history_email')} <strong>{state.email || "-"}</strong></label>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <label>{t('tbl_username')} <strong>{state.username || "-"}</strong></label>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            <div>
                                                                                <label>{t('service_period')} <strong>{state.period || "-"}</strong></label>
                                                                            </div>
                                                                        }
                                                                    </InfoCard>
                                                                    {
                                                                        addonType.type == "port_forwarding" ?
                                                                        <InfoCard hasborder noflex>
                                                                            <label>{t('selected_addon')} <strong>Port Forwarding</strong></label>
                                                                        </InfoCard>
                                                                        :
                                                                        <>
                                                                            {
                                                                                invoiceData?.countries.length > 0  && addonType.type !== addonConstants.dedicated_server ?
                                                                                <InfoCard hasborder noflex>
                                                                                    <label className="mb-2">{t('select_text')} {showAddonName(addonType.type)} Location:</label>
                                                                                    <Input type="select" name="select" id="exampleSelect" onChange={e=>setData({...data, country:e.target.value})}>
                                                                                        {
                                                                                            invoiceData?.countries?.map((country, index)=>(
                                                                                                <option value={country.iso_code} key={index}>{country.name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </Input>
                                                                                </InfoCard>
                                                                                : <></>
                                                                            }
                                                                        </>
                                                                    }
                                                                    
                                                                    {addonType.type === addonConstants.dedicated_server && (
                                                                        <InfoCard hasborder noflex>
                                                                            {invoiceData?.countries.length > 0 && <div className="mb-2">
                                                                                <label>Select Country:</label>
                                                                                <Input type="select" value={data?.country} name="country" onChange={(e)=>handleCountryChange(e)}>
                                                                                    {invoiceData?.countries.map((countryOption, index) => {
                                                                                        return <>
                                                                                            <option key={index} value={countryOption.value} id={countryOption.label}>
                                                                                            {countryOption.label}
                                                                                            </option>
                                                                                        </>
                                                                                    })}
                                                                                </Input>
                                                                            </div>}
                                                                            {invoiceData?.cities && <div>
                                                                                <label>Select City:</label>
                                                                                <Input type="select" name="city" value={data.city} onChange={(e) => handleCityChange(e)}>
                                                                                    {invoiceData?.cities[data?.country].map((city, index) => (
                                                                                        <option key={index} value={city.value}>
                                                                                            {city.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </Input>
                                                                            </div>}
                                                                        </InfoCard>
                                                                    )}
                                                                    <InfoCard hasborder noflex>
                                                                        <OrderSummary open={orderSummary}>
                                                                            <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">Order summary</strong></label>
                                                                            <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                                                                                {
                                                                                    Object.keys(invoiceData?.orderSummary).length !== 0 && invoiceData?.orderSummary && invoiceData?.orderSummary?.map((summary, key)=>{
                                                                                        if(summary?.origin === "add_on" || summary?.origin === "plan"){
                                                                                            return (
                                                                                                <>
                                                                                                    <li key={key}>
                                                                                                        {/* Individual Item unit_amount is get by itemUnitAmount => tools.js */}
                                                                                                        {summary.description.toLowerCase() === constants.purescan ? "PurePrivacy" : summary.description} {`${summary?.quantity > 1 ? `x ${summary?.quantity}`: ''}`}<strong className="text-end">{currencySymbol[addonType.currency]}{Object.keys(invoiceData.orderSummary).length !== 0 ? itemUnitAmount(summary)?.toFixed(2) : "-"}</strong>
                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {
                                                                                    !whmcsUser && (
                                                                                        <>
                                                                                            <li>
                                                                                            {t('prorated_adjustment')}<strong className="text-end">
                                                                                                {invoiceData?.orderSummary && getProratedAmount(invoiceData?.orderSummary,currencySymbol[addonType.currency])}
                                                                                                {/* {prorateSign(invoiceData.orderSummary)}
                                                                                                {currencySymbol[addonType.currency]}{getProrate(invoiceData.orderSummary)} */}
                                                                                                </strong>
                                                                                            </li>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    invoiceData?.response?.credit_amount ? <li>
                                                                                        {t('credit_adjustment_text')} <strong className='text-end'>{getCreditAdjustment(invoiceData?.response)}</strong>
                                                                                    </li> : null
                                                                                }
                                                                                <li>
                                                                                {t('vat_text')} <strong className="text-end">{currencySymbol[addonType.currency]}{getVat(invoiceData.orderSummary)}</strong>
                                                                                </li>
                                                                                {
                                                                                    constants.productNames.includes(addonType?.type) && !redirected_via &&
                                                                                    <li className='suggested'>
                                                                                        {t('security_suite_text_para')}
                                                                                        <strong className="text-end">
                                                                                            <LinkBtn onClick={(e)=> onClickGetSS('addon cart')}>{t('get_security_suite_text')}</LinkBtn>
                                                                                        </strong>
                                                                                    </li>
                                                                                }
                                                                            </ul>
                                                                        </OrderSummary>
                                                                    </InfoCard>

                                                                    <CouponField isCouponApplied={isCouponApplied} setCouponApplied={setCouponApplied} setShowCouponView={setShowCouponView} showCouponView={showCouponView} setOldInvoice={setOldInvoice} couponName={filter_coupons[addonType?.type]}/>
                                                                    <DiscountAndTotalField invoiceData={invoiceData} currency={currencySymbol[addonType.currency]} totalAmtFunc={addonPrice} isCouponApplied={isCouponApplied}/>
                                                            
                                                                    <InfoCard>
                                                                        <div className="float-start addon-purchase">
                                                                            {
                                                                                (declineState || whmcsUser || isRemovedGateway(addonType?.gateway))  ? (
                                                                                    <PopupPurchaseUtil isAllowToUseParamMethod={isAllowToUseParamMethod} endPoint={endPoint} selectedData={data} currentSub={selectedSub} chargeAttempt={chargeAttempt} toggle={()=>redirected_via ? toggleInfoModal() : toggle()} subData={getCurrentSubscription(reduxSubs, addonType.subscription)}/>
                                                                                ) : (
                                                                                    <label>
                                                                                        <strong className="ms-0 text-bold">
                                                                                            <PaymentIcon lastFour={state.last_four} payment_gateway_offline={state?.payment_gateway_offline} gateway={addonType?.gateway}/>
                                                                                        </strong>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </InfoCard>
                                                                    {
                                                                        state?.is_paused &&
                                                                        <InfoCard className="pb-0">
                                                                            <p className="grace m-0 fw-bold">{t('addon_purchasemodal_recurring_sub')}</p>
                                                                        </InfoCard>
                                                                    }

                                                                </div>
                                                        </PopupBody>
                                                        {
                                                            !(declineState || whmcsUser || isRemovedGateway(addonType?.gateway))  && (
                                                                <ModalFooter className='p-0 pt-2'>
                                                                    <PrimaryBtn color="primary" pointer={purchaseLoading} onClick={() => purchaseAddon({})}>{buttonText()}</PrimaryBtn>
                                                                    <DestructBtn color="secondary" onClick={()=>redirected_via ? toggleInfoModal() : onCloseChangePath()} pointer={purchaseLoading}>{t('cancel_text')}</DestructBtn>
                                                                    {!redirected_via && <SubmitConsent/>}
                                                                </ModalFooter>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <PopupBody className='text-center'>
                                                            <div className="mod-head mb-0">
                                                                {paymentData.image!= false && <img src={paymentData.image} height="60px" width="60px" className="img-responsive mb-4"/>}
                                                                <h3>{paymentData.heading ?? "Ooopss!!!"}</h3>
                                                                <p dangerouslySetInnerHTML={{ __html: paymentData.description || "You are not supposed to be here." }}></p>
                                                            </div>
                                                        </PopupBody>
                                                        {((declineState && redirected_via && !toNotDisplayRefreshCart(platform) && paymentData.ctaText != 'Continue') || !redirected_via) && <ModalFooter className='p-0 pt-2'>
                                                            <DestructBtn className='mx-auto' color="secondary" onClick={continueToNextStep}>{paymentData.ctaText}</DestructBtn>
                                                        </ModalFooter>}
                                                    </>
                                                }
                                            </>
                                        :
                                        // <CouponModal setShowCouponView={setShowCouponView} setCouponApplied={setCouponApplied} isCouponApplied={isCouponApplied}/>
                                        <CouponField isCouponApplied={isCouponApplied} setCouponApplied={setCouponApplied} setShowCouponView={setShowCouponView} showCouponView={showCouponView} setOldInvoice={setOldInvoice} couponName={filter_coupons[addonType?.type]}/>
                                    
                                    :
                                    <div className="mod-body" style={{minHeight:"200px"}}>
                                        {/* <span className="spinner-border"></span> */}
                                        <Skeleton className="d-block mb-3" width={300}/>
                                        <Skeleton className="d-block mb-3" width={280}/>
                                        <Skeleton className="d-block mb-3" width={260}/>
                                        <Skeleton className="d-block mb-3" width={240}/>
                                        <Skeleton className="d-block mb-3" width={220}/>
                                        <Skeleton className="d-block mb-3" width={50}/>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
                </ModalBody>
            </Modal>
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                    actionTokenId={threedSecure.three_d_secure_action_token_id}
                    onToken={handle3dToken}
                    onError={handle3dError}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { subscriptions: state.subscriptionsReducer };
};

export default connect(mapStateToProps,{ getUpgradePlansRequested, getUpgradePlansReset })(withRouter(RecurlyRoute(AddonPurchase)))