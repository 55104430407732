import styled, { css, keyframes } from "styled-components";

import hamBurgerIcon from '../../assets/hamBurgerIcon.svg'
import menuIcon from '../../assets/menuIcon.svg'
import search from '../../assets/search.png'
import chatBot from '../../assets/bot_chat.svg'
import arrowDown from '../../assets/down_arrow.svg'
import star from '../../assets/star.svg'
import thumbs from '../../assets/thumbs.png'
import EnterIcon from '../../assets/EnterMsg.svg'
import DisabledEnterIcon from '../../assets/disableEnter.svg'
import PureAILogoNav from '../../assets/pure-ai-logo.svg'


// ITS BEING CHANGED FROM HERE

export const PureBuddyView = styled.div`
text-align: center;
display:flex;
flex-direction: column;
justify-content: space-between;
position: relative;
height: 100%;
${props => !props.chatView && css`
  margin:auto;
`} 
width: calc(100% - 350px);
@media (max-width: 1700px) {
  width: calc(100% - 20px) !important;
  /* with Sidebar change */
  /* width: calc(100% - 300px) !important; */
}
@media (max-width: 1000px){
  width: calc(100% - 250px) !important;
}
@media (max-width: 1200px){
height: calc(100% - 40px);
}
@media (max-width: 750px) {
  width:100% !important;
}
.headingDiv {
  display: inline-flex;
}
.pure-ai-logo > img {
  width: 50px;
  margin: 4px 8px 8px -6px;
}
.content-display{
  padding: 25px;
  width: 80%;
  margin: 0 auto;
  p.tagline {
    margin-bottom: 3rem;
  }
  @media (max-width: 1700px) {
    width:100%;
  }
}
.content-display, .response-display{
  max-height: calc(100% - 70px); 
  overflow-y: auto;
  overflow-x: hidden;

}

.waitListText{
  h2{
    font-size: 24px;
    color: #003566;
  }
  p{
    font-size: 16px !important;
    color: #7B648C !important;
    line-height: 150% !important;
  }
}
.content-main-section{
  display: flex;
  h2{
    font-size: 32px;
    font-weight: 600;
    color: #000
  }
  
}
.content-display-waitlist{
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
      background-color:rgb(210, 207, 215);
      outline: none;
      border-radius:3px;
      opacity: 0.2;
  }
  .waitListPanel{
    flex: auto;
    margin: 20px 25px 53px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(67,34,91,0.05), 0px 10px 30px rgba(67,34,91,0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 30px;
  }
  .waitListEarlyAccess{
    flex-direction: column;
    width: 40%;
    margin-right: 30px;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    .w-content-div{
      display: flex;
      align-items: center;
      padding: 30px 36px;
      box-shadow: 0px 4px 10px rgba(67,34,91,0.05), 0px 10px 30px rgba(67,34,91,0.1);
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      margin: 20px 0;
    }
    .w-content-text {
      width: 75%;
      text-align: left;
      margin-right: 35px;
      span{
        color: #003566 !important;
      }
      h3{
        color: #003566;
      }  
    }
    .w-content-img{
      flex: auto;
    }
  }
}

.pureaiSocialShare{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  color: #003566 !important;
}

.fpErrorBox{
  margin-top: 30px;
  button{
      border:none;
      border-radius: 30px;
      padding: 20px 60px;
      background: #003566;
      color:#fff;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.5px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  margin:0 auto;
}
.grid-item{
    padding: 20px 30px;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 18px;
    text-align: center;
    color: #001219 !important;
    background: #FFFFFF;
    border: 1 solid #DDD4E2;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 7px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
}
.grid-item:hover{
  background: #EDE9F3;
}

.grid-first-div {
  height: 65px;
  margin-bottom: 1.5rem;
}

.grid-heading {
  color: #003566;
  font-weight: 600;
  font-size: 18px !important;
}

.grid-heading-desc {
  color: #003566;
  font-style: normal;
  font-weight: 400;
  font-size: 17px !important;
  text-align: center;
  line-height: 19px;
}

@media only screen and (max-width: 1000px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item{
    font-size: 14px !important;
  }
  .grid-item:only-child {
    grid-column: 1 / span 2; 
    text-align: center; 
  }
  .grid-item:nth-child(9) {
    grid-column: 1 / span 2;
    text-align: center;
  }

}

@media only screen and (max-width: 768px) {
  .grid-item{
    padding: 20px 30px;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 18px;
    text-align: center;
    color: #001219 !important;
    background: #FFFFFF;
    border: 1 solid #DDD4E2;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 7px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 65px;
  }
  .grid-first-div {
    height: 70px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .grid-heading {
    color: #003566;
    font-weight: 600;
    font-size: 16px !important;
  }
  .grid-heading-desc {
    color: #003566;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    text-align: center;
    line-height: 19px;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 580px) {
  .grid-item{
    font-size: 13px !important;
  }
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.response-display::-webkit-scrollbar {
  width: 7px;
  background-color: #fff;
}

.response-display::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 50px;
}

.heading{
  display:flex;
  align-items:center;
  justify-content:center;
  h1{
    font-weight: 200;
    font-size: 44px;
    line-height: 56px;
    color: #001219;
    strong{
      font-weight: 600;
    }
  }
  span{
    margin-left:15px;
    width: 70px;
    height: 22px;
    background: #fff;
    border-radius: 5px;
    color:#000 !important;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:4px !important;

  }

}
.videoContainer{
  position: relative;
  .videoPlay{
    box-shadow: 0px 4px 10px rgb(0 65 255 / 5%), 0px 10px 30px #656565eb;
    box-sizing: border-box;
    border-radius: 10px;
    width:770px;
  }
  .alphaTag {
    position: absolute;
    left: 0;
    width: 70px;
    height: 22px;
    background: #fff;
    border-radius: 5px;
    color:#000 !important;
    font-weight: 700;
    font-size: 14px !important;
    margin: auto;
    align-items: center;
    border: 1px solid #3B108E;
  }
}

p{
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #333333;
}
h2{
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3B108E;
  margin:20px 0 30px 0;
}
.prompt-response-view{
  text-align: left !important;
  .sub-prompt{
    background: #fff;
    padding:25px 0;

  }
  .sub-response{
    background: #F3EFFD;
    padding:25px 0;
    .sub-response-sub-div{
      width:100%;
      .sub-response-sub-div-ctas{
        margin:0 auto;
        width:75%;
        .sub-response-sub-div-ctas-item{
          display:inline-flex;
          border: 1px solid #003566;
          border-radius: 5px;
          padding: 10px 34px;
          margin: 0 10px;
          text-transform: capitalize;
          background: #fff;
          cursor: pointer;
          :hover{
            background: #003566;
            color:#fff;
          }
        }
      }
    }
  }
  .sub-prompt,.sub-response{
    display: flex;
    align-items: center;
  }
  .loading{
    display:flex !important;
    align-items:center;
    font-size:17px !important;
    font-weight:600;
    letter-spacing:1px !important;
    
  }
  .prompt-text,.response-text{
    width:87%;
    margin: 0 70px 0 auto;
    display:flex
  }

  .prompt-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #333333;
  }
  .response-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    .completion-response {
      width: 100%;
      .cursor {
        height: 1rem;
        width: 6px;
        margin-left: 3px;
        background-color: #00000091;
        animation: cursor-blink 2s infinite;
        display: inline-block;
      }
      
      @keyframes cursor-blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  .reactionArea{
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    margin-right:120px;
    padding:20px 0;
    @media(max-width: 1100px) {
        padding:10px 0;

    }
    @media (max-width: 768px) {
        margin-right:16px;
        padding:10px 0 0 0;
    }
  }
  .reaction{
    cursor:pointer;
    background-image: url(${thumbs});
    width:19px;height:18px;
  }
  .reaction.up{
    background-position:-10px -10px;
    display:block;
    margin:0 15px 0 15px;
  }
  .reaction.down{
    background-position:-50px -8px;
    display:block;
  }
  .reaction.up.active{
    background-position: -48px -50px;
    display:block;

  }
  .reaction.down.active{
    background-position:-10px -50px;
    display:block;

  }


  .avatar{
    background-image: url(${chatBot});
    background-size: cover;
    background-position: center;
  }
  .avatarSize{
    min-width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  @media (max-width: 1200px) {
    .reactionArea{
      margin-right:64px;
    }
    .prompt-text,.response-text,.loading{
      width:80%;
      margin:0 32px!important;
      font-size:15px !important;
      line-height:20px;
      .avatarSize{
        height:28px;min-width:28px;
      }
    }
    .sub-prompt,.sub-response{
        padding:15px 0;
    }
  }
  @media (max-width: 768px) {
    .prompt-text,.response-text,.loading{
      width:90%;
      margin:0 16px!important;
      font-size:14px !important;
      line-height:20px;
      .avatarSize{
        height:24px;min-width:24px;
      }
    }
    .sub-prompt,.sub-response{
        padding:15px 0;
    }
  }
}
.promptView{
  margin: 70px auto 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 890px;
  span{
    padding: 20px 30px;
    margin: 0 0px 20px 0;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    text-align: center;
    color: #001219 !important;
    background: #FFFFFF;
    border: 1 solid #DDD4E2;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 7px;
    cursor: pointer;

    width: calc(32.33% - 10px);
    text-align: center;
  }
  @media (max-width: 1400px) {
      width: revert;
      justify-content: center;
      gap:20px;
    span{
      width: auto;
      
    }
  }
  @media (max-width: 550px) {
    flex-direction:column;
    span{
      width:revert !important;      
    }
  }
}
.bottomMenu{
  display:block;
  width:100%;
  margin:0 auto 10px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  
  @media (max-width:750px){
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
  margin:0 auto;
  align-items:center;
  bottom: 0;
  position: fixed;
  background: #f2f4f4;
  form{
    width:100%;
  }
  }
}

.searchBar{
  img{
    position: relative;
    left:-40px;
    cursor: pointer;
  }
  input {
    vertical-align: middle;
  }

  @media (max-width: 890px) {
    display: flex;
    }
}
.menu-icon{
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 37px;
    background-image: url(${hamBurgerIcon});
    background-size: cover;
    background-position: center;
    @media (min-width: 750px) {
      display:none;
    }
    
  }
  .menu-icon:hover{
    background-image: url(${menuIcon});
  }
@media (max-width: 1477px) and (min-width: 1200px){
.content-display{
    h1{
        font-size:32px;
    }p{
        font-size:20px;
    }
    .promptView{
        margin-top:30px;
        gap:5px;
        width:750px;
        span{
            padding:10px 20px;
            width:calc(32.33%);
            font-size:13px !important;
            margin-bottom:10px;
        }
    }
}
}
@media (max-width: 1700px) and (min-width: 1000px) {
  width: calc(100% - 150px) !important;
  /* with sidebar change 150-300 */
  .content-display{
    padding:25px 100px;
  }
  .grid-item{
    font-size:14px !important;
    padding: 15px;
  }
}
@media (max-width: 1570px) and (min-width: 1000px){
.content-display{
    h1{
        font-size:32px;
    }p{
        font-size:20px;
    }
    .promptView{
        margin-top:30px;
        gap:5px;
        width:750px;
        span{
            padding:10px 20px;
            width:calc(32.33%);
            @media (max-width: 1570px){
              width:calc(40%);

            }
            font-size:13px !important;
            margin-bottom:10px;
        }
    }
}
}
@media (max-width: 980px) {
.promptView{

    span{
      width: calc(50.33% - 20px);
    }
}
}
@media (max-width: 550px){
.content-display{
    h1{
        font-size:32px;
    }p{
        font-size:20px;
    }
    .promptView{
        margin-top:40px;
        gap:10px;
        span{
            padding:15px;
        }
    }
}
}
`
export const InputBox = styled.input`
    background: #FFFFFF;
    border: 1px solid #ECE7FD;
    border-radius: 8px;
    min-width: 880px;
    height: 50px;
    font-size: 17px;
    padding: 0 10px;
    background-repeat: no-repeat;
    text-indent: 10px;
    margin: 10px 0;
    ::placeholder{
      font-size: 14px;
    }

    :focus{
      border: 1px solid #4FA1D1;
    }
    @media (max-width: 1700px) {
      min-width: 95%;
      height: 50px;
      font-size: 12px !important;
      padding: 0 5px;
  }
  @media (max-width: 740px) {
      min-width: 95%;
      height: 35px !important;
      font-size: 12px !important;
      padding: 0 5px;
      display: flex;
      flex:1;
  }
${props => props.inputWidth && css`
    margin: 15px 0;
    width:100%;
    min-width: auto;
    height: 38px !important;
    font-size: 14px;
    background-image: url(${search});
    background-repeat: no-repeat;
    text-indent: 10px;
    background-position:center;
    background-position-x:right;
    background-origin:content-box;
`} `

export const MenuBody = styled.div`

.tabView{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .tabCtas{
    button{
      border:none;
      font-weight: 400;
      font-size: 18px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.2px;
      color: #637381;
      width:120px;
      background: none;
      :first-child:after {
          content: 'Suggested';
        }
      @media (max-width:1000px){
        :first-child:after {
          content: 'Suggested';
        }
      }
    }
    button.active{
      border-bottom: 3px solid #6746A7;
      font-weight: 600;
      font-size: 18px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.2px;
      color: #43225B;
      padding-bottom: 8px;
    }
  }
  .chatCta{
    padding: 7px 26px;
    width: 165px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 67px;
    ${props => props.toDisableCta && css`
      cursor:default;
      border-color: #bfbfbf !important;
    `}
  }
}
.nav-cta{
  width:90%;
  margin: 10px auto;
  background: #fff;
  border:1px solid #6536CC; 
  border-radius: 117px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  :hover{
    background: #6536CC;
    color:#fff;
  }
  color:#6536CC;
  ${props => props.toDisableCta && css`
    opacity:0.6;
    cursor:default;
  `}
}

.filterDiv{
  padding: 20px;
  position: sticky;
  top: 0px;
  background: #FBFBFC;
}

.filters {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #003566;
}

.filterBtnDiv{
  display: flex;
  width: 320px;
  margin-top: 3%;
  justify-content: flex-start;
  align-content: stretch;
  flex-wrap: wrap;
}

.promptFilterBtn{
  margin: 5px;
  padding: 5px;
  font-size: 13px;
  background: transparent;
  border: 1px solid #003566;
  color: #003566;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}


.promptFilterBtnactive{
  margin: 5px;
  padding: 5px;
  font-size: 13px;
  background: #003566;
  border: 1px solid #003566;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.fliterBtnIcon{
  width: 20px;
  margin-right: 5px
}

.popular-prompt-view{
  min-height:250px;
  width:100%;
  overflow-y: scroll;
  max-height: 250px;
  margin:0 auto;
  ::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
  }

  .prompt{
    color:#003566;
  }

  @media (max-width: 767px) {
    .filterDiv{
      padding: 20px;
      position: relative;
      top: 0px;
      background: #FBFBFC;
    }
    .filterBtnDiv{
      display: flex;
      width: 275px;
      margin-top: 3%;
      justify-content: flex-start;
      align-content: stretch;
      flex-wrap: wrap;
    }
  }


  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 50px;
  }
  table{
    width: 100%;
    border: 1px solid #E8E8E9;
    border-radius: 12px;
    tr{
        cursor: pointer;
        background: white;
        th{
            position: sticky;
            top: 0px;
            background-color: white;
            z-index: 1;
            cursor:default;
        }        
      th,td{
        padding: 20px;
        border-bottom: 1px solid #E8E8E9;
      }
      td:first-child{
        min-width: 155px;
        }
    }
    tr.tr-error{
        border-top:1px solid #E8E8E9;
        cursor:default;
    }
    tr.hoverable:hover{
      background: #EDE9F3;        
    }
    .no-search-result{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 200px;
        flex-direction: column;
        word-wrap: break-word;
        text-align: center;
        strong{
            display: contents;
        }
    }
    td div {
        padding: 0px;
        display: inline-block;
    }
  }
  table.layout-fixed{
    table-layout: fixed;
  }

  @media (max-width: 767px) {
    table{
      width: 100%;
      border: 1px solid #E8E8E9;
      border-radius: 12px;
      tr{
          cursor: pointer;
          background: white;
          th{
              position: sticky;
              top: 0;
              background-color: white;
              z-index: 1;
              cursor:default;
          }        
        th,td{
          padding: 20px;
          border-bottom: 1px solid #E8E8E9;
        }
        td:first-child{
          min-width: 155px;
          }
      }
      tr.tr-error{
          border-top:1px solid #E8E8E9;
          cursor:default;
      }
      tr.hoverable:hover{
        background: #EDE9F3;        
      }
      .no-search-result{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: 200px;
          flex-direction: column;
          word-wrap: break-word;
          text-align: center;
          strong{
              display: contents;
          }
      }
      td div {
          padding: 0px;
          display: inline-block;
      }
    }
    table.layout-fixed{
      table-layout: fixed;
    }
  }
}

.bottom-section {
    max-height: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FBFBFC;
    z-index: 9999;
    display: flex;
    flex-direction: column;
      .star-rating{
        display: flex;
        justify-content: space-between;
        margin: 15px auto 10px;
        width: 85%;
        align-items: center;
        .star {
          background-image: url(${star});
          background-size: contain;
          height: 20px;
          width: 20px;
          display: inline-block;
          margin: 0;
          background-repeat: no-repeat
        }
        button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          position: relative;
        }
        .on {
          &:after {
            color: #fff;
            content: "★";
            position: absolute;
            top: -1px;
            left: 1px;
            font-size: 21px;
            line-height: 1;
          }
        }
      }
      span{
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color:#000 !important;
        font-size: 18px !important;
        margin-bottom: 10px;
        width:100%;
        min-height:30px;
        img{
          width: 29px;height:29px;
          margin-right: 10px;
        }
      }
      .feedback-btn{
          img{
            height:21px !important;
            } 
        }
        .review-box {
          background: #003566;
          box-shadow: 0px 4px 8px rgba(0,53,102,0.12);
          border-radius: 8px;
          position: absolute;
          right: 122px;
          bottom: 45px;
          padding: 20px 16px;
          width: 280px;
          .hed {
            color: #fff;
            margin: 0;
            font-size: 17px;
            font-weight: 600;
            display: inline-block;
          }
          .close-icon {
            float: right;
            cursor: pointer;
          }
          .feedBackErr {
            font-size: 14px;
            color: #fff;
            margin: 6px 0 0;
            text-align: center;
          }
          .textarea {
              background: #fff;
              border: 1px solid rgba(0,53,102,0.4);
              border-radius: 8px;
              width: 100%;
              min-height: 60px;
              padding: 10px;
              resize: none;
              font-size: 14px;
              
              &::-webkit-input-placeholder {
                color: rgb(0 53 102 / 50%);
              }
              &:-ms-input-placeholder {
                color: rgb(0 53 102 / 50%);
              }
              &::placeholder {
                color: rgb(0 53 102 / 50%);
              }
          }
          .review-submit{
            background: #fff;
            border: 1px solid #003566;
            border-radius: 117.647px;
            color: #003566;
            outline: none;
            width: 100%;
            padding: 8px;
            margin: 13px 0 0;
            font-weight: 600;
            font-size: 16px;
          }
        }
      span:hover{
        cursor:pointer;
        box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
      }
      ${props => !props.openModalView && css`
        position: static;
        height: 100px;
        margin-top: 10px;
        span{
          font-size: 14px !important;
          margin: 0px;
          min-height:34px;
          img{
            width:24px;height:24px;
            margin-right: 8px;
          }
        }
      `}
      @media(max-width:1200px){
        span{
          font-size: 14px !important;
          margin:7px 5px 0px 10px;
          min-height:34px;
          img{
            width:24px;height:24px;
            margin-right: 8px;
          }
        }
      }
    }

@media (max-width: 690px) {
.tabView{
    .tabCtas{
        button{
            font-size: 16px !important;
            width:150px;
        }
    }
    .chatCta{
        padding: 5px 20px;
        width:120px;
        font-size: 12px !important;
    }
}
.popular-prompt-view{
    table{
        tr{
            th,td{
                padding: 10px;
                border-bottom: 1px solid #E8E8E9;
                font-size: 13px !important;
            }
            td:first-child{
                min-width: 120px;
            }

        }
    } 
}   
}
@media (max-width: 490px) {
.tabView{
    .tabCtas{
        min-width:200px;
        flex:1;
        button{
            font-size: 14px !important;
            max-width:105px;
        }
    }
    .chatCta{
        padding: 2px 10px;
        width:80px;
        font-size: 10px !important;
    }
}    
}

${props => props.openModalView && css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative; 
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.1);
  hr{
    display: block !important;
    border :1px solid #b7b7b7;
    margin: 0px auto;
    width:90%;
    margin-bottom: 10px;
  }
  .tabCtas{
    margin: 20px auto 10px auto;
    button{
      width: 150px !important;
      margin: 0 5px;
    }

  }
  div.input-search{
      width:90%;
      margin:0 auto
  }
  .popular-prompt-view{
    min-height:calc(100vh - 409px);
    ${props => props.iscloseHelloBar === "true" && css`
      min-height:calc(100vh - 295px) !important;
    `} 
    width:100%;
    display: flex;
    flex-direction: column;
    position: relative;

    table{
      max-height: calc(50vh - 300px);
      border:none;
      flex: 1;
      overflow: auto;
      td{
        padding: 20px 30px !important;
        padding-right:50px !important;
      }
    }

  }


  @media (max-width: 1700px) and (min-width: 750px) {
    .nav-cta{
      height: 35px;
    }
    .popular-prompt-view{
      min-height:calc(100vh - 403px) ;
      ${props => props.iscloseHelloBar === "true" && css`
          min-height:calc(100vh - 289px)!important;
      `} 
       @media (max-width:1200px){
        min-height:calc(100vh - 324px);
       }
    }
    .tabCtas{
    margin: 20px auto 10px auto;
    button{
      font-size: 16px !important;
      width: 140px !important;
      @media (max-width: 1000px){
        width: 120px !important;
      }
      margin: 0 0px;
    }
  }
  } 
  .popular-prompt-view{
    table{
      min-height: revert !important;
    }
    td{
      padding: 10px 15px !important;
      padding-left:20px !important;
      font-size: 16px !important;
    }
  }
`}

`

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  color: white;
  border: none;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: -130px;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px -8px 16px 0px rgba(0,0,0,0.2);
  & > * {
    padding: 12px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
    }
  }
`;

export const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Dot = styled.div`
  background-color: #838181;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 4px;
  animation: ${bounce} 1s infinite ease-in-out;
  animation-delay: ${props => props.delay}ms;
`;

export const AvatarDiv = styled.div`
  background-color: #6536CC; /* Set a default background color */
  color: #fff; /* Set a default text color */
  text-transform: uppercase;
  font-size: 18px;
  border:1.6px solid #003566;
  font-weight: bold;
  border-radius: 50%; /* Make the div round */
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const ScrollButton = styled.div`
    cursor: pointer;
    position: fixed;
    bottom: 100px;
    right: 50%;
    z-index: 999;
    background-image: url(${arrowDown});
    background-size: cover;
    background-position: center;
    width: 24px;
    height: 24px;
    @media (max-width: 600px) {
        bottom: 110px;
    }
`;

export const StopGeneratingBtn = styled.div`
    cursor: pointer;
    position: fixed;
    bottom: 83px;
    right: 50%;
    z-index: 999;
    background-color: #e0e0e0;
    padding: 10px 18px;
    border-radius: 8px;
    @media (max-width: 600px) {
        bottom: 110px;
    }
`;

export const SideBuddyView = styled.div`
min-width: 350px;
max-width: 250px;
background-color: #FBFBFC !important;
${props => !props.chatView && css`
  display:none;
`} 
@media (max-width: 1700px) {
  min-width: 300px;
}
@media (max-width: 1000px){
    min-width: 250px !important;
}
@media (max-width: 750px) {
  display: none;
}
`

export const SideCardBuddyView = styled.div`
min-width: 350px;
max-width: 250px;
background-color: #F2F4F4 !important;
border-left: 1px solid #d9d9d9;
${props => !props.chatView && css`
  display:none;
`} 
@media (max-width: 1700px) {
  min-width: 300px;
}
@media (max-width: 1000px){
    min-width: 250px !important;
}
@media (max-width: 750px) {
  display: none;
}

.card-container {
  display: flex;
  width: 100%;
  padding: 5%;
}

.card-item{
    padding: 20px 30px;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 18px;
    text-align: center;
    color: #001219 !important;
    background: #FFFFFF;
    border: 1 solid #DDD4E2;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 7px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    display: block;
    align-items: center;
}

.card-item:hover{
  background: #EDE9F3;
}

.firstdiv {
  display: flex;
  flex-direction: row;
}

.cardIcon {
  
}

.cardHeading {
  font-size: 16px;
  margin-left: 5%;
  padding-top: 2%;
  color: #003566;
}

.cardDesc {
  padding-top: 5%;
  font-size: 0.9em;
  color: #003566;
  text-align: start;
}

.puretools {
  padding: 5%;
}

.puretools h2{
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #003566;
}
`
export const FAQViewScreen = styled.div`
width:300px;
height: 700px;
justify-content: center;

margin: 30px auto;
max-width: 912px;

.heading {
  display: flex;
  flex-direction: column;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #43225B  !important;
  margin-bottom: 30px !important;
  text-align: center;

  span {
    font-size: 36px !important;
    font-weight: 700 !important;
    color: #43225B  !important;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #DDD4E2;
  border-radius: 12px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  &.open {
    .icon {
      cursor: pointer;
      &:before{ transform: rotate(90deg); }
      &:after{ transform: rotate(180deg); }
    }
    .answer {
      display: flex;
      a{
        cursor:pointer;
        text-decoration:underline;
        font-size:14px !important;
      }
    }
  }

  .question {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    p {
      font-weight: 700;
      font-size: 16px !important;
      line-height: 125%;
      color: #43225B;
      margin-bottom:0;
      max-width:770px;
    }

    .icon {
      position: relative;
      width: 20px;
      height: 20px;
      flex-shrink: 0;

      &:before,
      &:after{
        content: "";
        position: absolute;
        background-color: #8A8398;
        transition: transform 0.25s ease-out;
      }

      &:before{
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        margin-left: -1px;
      }

      &:after{
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -1px;
      }
      
      &:hover{
        cursor: pointer;
      }
    }
  }

  .answer {
    display: none;
    margin-top: 24px;

    p {
      font-weight: 400;
      font-size: 14px !important;
      color: #7B648C  !important;
      margin-bottom:0;
    }
  }
}
`
export const WaitlistModal = styled.div`
.waitlistWrapper .modal-header{
  position: unset !important;
}
`

export const CardlistModal = styled.div`
.cardHead {
  color: yellow;
}
.waitlistWrapper .modal-header{
  position: unset !important;
}

`