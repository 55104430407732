import QueryString from 'qs';
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Alert, ModalBody } from 'reactstrap';
import { PrimaryBtn } from '../../../../styles/Generic';
import { sendEvents } from '../../../../tools/mpEvents';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { poster } from '../../../../tools/tools';
import { useTranslation } from 'react-i18next';


const GenerateConfigurationServer = ({countryInfo,protocol,setMcDisplayableData,handleSubmit,selectedUser,setSelectState,serverType,setMCError,getServer,setHideCancel,currentSubData={}, multiLoginLimitExceededError}) => {

const [MCLoading, setMCLoading] = useState(false);

const { t, i18n } = useTranslation();

const [MCErrorMessage, setMCErrorMessage] = useState(false);

const [MCConfigs, setMCConfigs] = useState("");

const [isCancel, setIsCancel] = useState(false);


const [reGen, setRegGen] = useState(false);

const [serverInfo, setServerInfo] = useState(false);

const [noQRError] = useState("QR servers aren't available on the selected protocol. Please switch the protocol")

const token = localStorage.getItem("token");
const dispatch = useDispatch();
  const generateMCServer = async (countryInfo,protocol) => {
    const {
      iso,name,city
    } = countryInfo;
    try {
      setHideCancel(true)
      setMcDisplayableData(null)
      setMCLoading(true)
      setSelectState(true)
      const formdata = QueryString.stringify({
        sUsername: selectedUser.username,
        sCountrySlug: iso,
        sProtocolSlug1: protocol,
        iCityId:city?.id,
        aTagsFilter: ["OVPN_OBF","QR","PF"].includes(getServer) ? ['TAG_'+getServer] : null
      });
      const MCKey = await poster(
        "manual/get-mc-server",
        formdata,
        token
      );
      const { data: response } = MCKey;
      if (response?.status) {
        setMCErrorMessage(false);
        setMCError(null);
  
        setMCConfigs(response?.body?.wireguard_configuration);
        sendEvents(
          {
            event_name: "ma_generate_manual_config",
            event_properties: {
              protocol:protocol,
              country: name,
              city: city?.name,
              username:selectedUser.username,
              is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
              is_p2p_server_requested: serverType?.includes("p2p")
            },
          },
          token
        );
        setMcDisplayableData({
          countryName: name,
          cityName:city?.name,
          MCHost: response?.body['host'],
          MCConfigs:response?.body['configuration'],
          MCProtocol:response?.body['protocol'],
          MCErrorOBF: ["OVPN_OBF","QR"].includes(getServer) && !response?.body['ovpn_obf']?true:false,
          MCErrorQR: ["OVPN_OBF","QR"].includes(getServer) && !response?.body['qr_server']?true:false
        });
        if(response?.body['configuration']){
          handleSubmit(response?.body['configuration'],iso);
          setServerInfo({
            isObf:response?.body['ovpn_obf'],
            isQr:response?.body['qr_server']
          })
        }
        setMCLoading(false);
        setIsCancel(true);
        setRegGen(false);
        setHideCancel(false)

      } else {
        switch (response?.error_code) {
          case "token_expired":
            toast.error(t(TOKENEXPIRED));
            dispatch({ type: "LOGOUT" });
            break;
          case "token_invalid":
            toast.error(t(INVALIDTOKEN));
            dispatch({ type: "LOGOUT" });
            break;
          case "jwt_default_exception":
            toast.error(t(INVALIDTOKEN));
            dispatch({ type: "LOGOUT" });
            break;
          case "atom_exception":
            if(response?.errors?.code===1008){

              // show multi login error popup
              multiLoginLimitExceededError();
            }
            else{
              setMCErrorMessage(response?.errors?.code===40009 ? noQRError : response?.message);
              setMCError(response?.errors?.code===40009 ? noQRError : response?.message);
            }
            break;
          case "validation_exception":
            setMCErrorMessage(response?.message);
            setMCError(response?.message);
            break;
          default:
            !response?.errors?.code===40009 && toast.error(APIFAILED);
            setMCError(response?.errors?.code===40009 ? noQRError : response?.message);
            break;
        }
        sendEvents(
          {
            event_name: "ma_fail_generate_manual_config",
            event_properties: {
              
              protocol:protocol,
              country: name,
              city: city?.name,
              username:selectedUser.username,
              reason:response?.message ?? "N/A",
              is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
              is_p2p_server_requested: serverType?.includes("p2p")
            },
          },
          token
        );
      }
    } catch (error) {
      setMCLoading(false);
      toast.error(t(APIFAILED));
      setMCErrorMessage(error);
      setSelectState(false)
    } finally {
      setHideCancel(false)
      setMCLoading(false);
      setSelectState(false);
    }
  };

	const buttonText = () => {
		return !MCLoading ? (
			protocol ==="IKEV" || protocol ==="IPSEC" ? t('manualconf_generate_text') : t('download_text')
		) : (
			<>
				{protocol ==="IKEV" || protocol ==="IPSEC"   ? t('manualconf_generating_conf_text'): t('manualconf_downloading_conf')}
				<span className="spinner-border text-light spinner-border-sm ms-1"></span>
			</>
		);
	};
  const onClickToGenConfig = () => {
    generateMCServer(countryInfo,protocol)
  }
  return (
    <>
      <PrimaryBtn pointer={MCLoading} onClick={()=> onClickToGenConfig()}>{buttonText()}</PrimaryBtn>
    </>
  )
}

export default GenerateConfigurationServer