import { constants } from "../../../../tools/constants";
import { featureContent } from "../../content";
import { PrimaryBtn } from "../../../../styles/Generic";
import { useTranslation } from "react-i18next";
import prePurchaseImage from "../../../../assets/security-tools/rmd/pre_purchase.svg";
import { APIFAILED } from "../../../../tools/responseMessages";
import { CircleStyles, DataGridStyles } from "../../../../styles/dashboard/security-tools/securityTools";
import { dispatchError, displayError, get, getTokenFromLocalStorage } from "../../../../tools/tools";
import { Graph } from "../../../../styles/dashboard/Listing";
import { InfoCard, SubscriptionBox } from "../../../../styles/dashboard/Main";
import { useDispatch} from 'react-redux';
import { useEffect, useMemo, useState } from "react";
import ExternalLink from "../../../../assets/external-link.svg";
import info from "../../../../assets/info.svg";
import Info from "../../../dashboard/plan-type-upgrade/modals/info";
import Listing from "../../../../generic-components/listing";
import Pagination from "../../../generic/Pagination";
import Skeleton from "react-loading-skeleton";
import { sendEvents } from "../../../../tools/mpEvents";

const PrePurchase = ({slug, onClickPurchaseCta, proceedToAccountInfo, hasPurchasedAddon, setCrashed, userLoading, selectedSubscription}) => {

    const { t } = useTranslation();

    let pageSize = 15;

    const token = getTokenFromLocalStorage();

    const dispatch = useDispatch();

    const [modalWidth] = useState({
        lg: "737px",
        sm: "440px"
    });

    const widthCalculator = "lg";

    const [infoModal, setInfoModal] = useState({
        open: false,
        content: ''
    });

    const [index, setIndex] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(true);

    const [listInfo, setListingInfo] = useState(JSON.parse(localStorage.getItem('guest-data-broker-list')));

    useEffect(()=> {
        if(!listInfo){
            getGuestDataBrokerList();
            sendEvents({
                event_name: "ma_view_rmd_dashboard",
                event_properties: {
                    plan_type: selectedSubscription?.plan_type,
                    billing_cycle: selectedSubscription?.type ?? 'N/A',
                },
            }, token);
        }
    }, [])

    const getGuestDataBrokerList = async () => {
        try {
            const accountInfo =  await get("integrated-experience/guest/brokers-listing", token);
            const {data: response} = accountInfo;

            if(response?.status){
                setListingInfo(response?.body)
                localStorage.setItem('guest-data-broker-list', JSON.stringify(response?.body))
                setLoading(false);
            } else {
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code);
                sendEvents({
                    event_name: "ma_fail_load_rmd_dashboard",
                    event_properties: {
                        type: 'guest',
                        reason: response?.message ?? 'Oops, something went wrong',
                    },
                }, token);
                
            }
        } catch (error) {
            displayError(error);
            throwErrorBoundary(APIFAILED);
            sendEvents({
                event_name: "ma_fail_load_rmd_dashboard",
                event_properties: {
                    type: 'guest',
                    reason: 'Oops, something went wrong',
                },
            }, token);
        }
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED || error === 'upgrade_exception'){
            setCrashed(true)
        }
    }

    const toggleInfoModal = (title) => {
        title ? 
            setInfoModal({open: true, content: 
                title === 'Status' ?  <div className="grid-box">
                    <ul className="rmd">
                        {featureContent[slug]?.post_purchase?.info.map((item)=>{
                            return <>
                            <li className={`${item?.slug}`}>
                                <h3 className="mb-1">{t(item?.heading)}</h3>
                            </li>
                            <p className="mb-4 mt-1">{t(item?.description)}</p>
                            </>
                        })}
                    </ul>
                </div> : 
                title === 'Risk Score' ? <div className="grid-box">
                    <ul className="rmd warning">
                        <li className={`${featureContent[slug]?.post_purchase?.risk?.slug}`}>
                            <h3 className="mb-1">{t(featureContent[slug]?.post_purchase?.risk?.heading)}</h3>
                        </li>
                        <p className="mb-4 mt-1">{t(featureContent[slug]?.post_purchase?.risk?.description)}</p>
                    </ul>
                    <div className="progress-meter">
                        <h5>{t('low_risk')} (1 - 3)<p>{t('low_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph three"></span>
                        </div>
                    </div>
                    <div className="progress-meter">
                        <h5>{t('medium_risk')} (4 - 7)<p>{t('medium_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph seven"></span>
                        </div>
                    </div>
                    <div className="progress-meter">
                        <h5>{t('high_risk')} (8 - 10)<p>{t('high_risk_detail')}</p></h5>
                        <div class="multigraph">
                            <span class="graph"></span>
                        </div>
                    </div>
                </div> : <div className="grid-box">
                    <h1 className="text-center mb-4">{t(featureContent[slug]?.pre_purchase?.list?.heading)}</h1>
                    <div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div>
                                <h4>{t(featureContent[slug]?.pre_purchase?.list?.items[0]?.heading)}</h4>
                                <p>{t(featureContent[slug]?.pre_purchase?.list?.items[0]?.description)}</p>
                                <h4>{t(featureContent[slug]?.pre_purchase?.list?.items[1]?.heading)}</h4>
                                <p>{t(featureContent[slug]?.pre_purchase?.list?.items[1]?.description)}</p>
                            </div>
                        </div>
                    </div>
                   <div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div>
                                <h4>{t(featureContent[slug]?.pre_purchase?.list?.items[2]?.heading)}</h4>
                                <p>{t(featureContent[slug]?.pre_purchase?.list?.items[2]?.description)}</p>
                                <h4>{t(featureContent[slug]?.pre_purchase?.list?.items[3]?.heading)}</h4>
                                <p>{t(featureContent[slug]?.pre_purchase?.list?.items[3]?.description)}</p>
                            </div>
                        </div>
                   </div>
                </div>
            }) : 
        setInfoModal({open: false, content: ''})
    }

    const renderDynamicItems = (list, idx) => {
        return <div className="position-relative">
            <img className="redirect_link" src={ExternalLink} alt="" onClick={()=> goToLink(list?.website)}/>
            <div key={idx} className={`item ${ (index === idx) ? "open" : "" } column-wrapper question`} onClick={() => index !=idx ? setIndex(idx) : setIndex()}>
            {/* HEADING */}
                <div className="column-title">
                    <h4>{t(featureContent[slug]?.post_purchase?.header[0])}</h4>
                    <div className="d-flex align-items-center gap-2 pl-4"><h5>{list?.name}</h5></div>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header?.[1])}<img src={info} alt="info" onClick={()=>toggleInfoModal('Risk Score')}></img></h4>
                    <div className="progress-meter">
                        <h5>{`${list?.riskScore}/10`}</h5>
                        <div class="multigraph">
                            <Graph score={list?.riskScore} className="graph"/>
                        </div>
                    </div>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header[2])}</h4>
                    <h5>{list?.category}</h5>
                </div>
                <div className="column-title">
                <h4>{t(featureContent[slug]?.post_purchase?.header?.[3])}<img src={info} alt="info" onClick={()=>toggleInfoModal('Status')}></img></h4>
                    <h5>--</h5>
                </div>
            </div>
            {/* BODY  */}

            {index === idx && <div className='answer'>
                <h6>{`${t('types_of_data_broker')} ${list?.category}`}</h6>
                <p>{list?.categoryDetails}</p>
            </div>}
        </div>
    }

    const renderHeaderItems = () => {
        return featureContent[slug]?.post_purchase?.header.map((title, index)=>{
            return <div className={`${index === 1 || index === 3 ? 'show-info' : null} column-title`}>
            <h6 className="title">{t(title)}</h6>
            {(index === 1 || index === 3) && <img src={info} alt="info" onClick={()=>toggleInfoModal(title == 'risk_score_heading' ? 'Risk Score' : title == 'status_heading' ? 'Status' : 'Helpful')}></img>}
        </div>
        })
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return listInfo?.data_deletion_details.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, listInfo?.data_deletion_details]);

    const goToLink = (link) => {
        window.open(link,'_blank');
    }

    const reclaimData = userLoading ? <>{`${t(featureContent[slug]?.purchase_btn)}`}<span className="spinner-border text-light"></span></> : `${t(featureContent[slug]?.purchase_btn)}` ;

    return <>
        {
            loading && !listInfo?.data_deletion_details ? 
            <SubscriptionBox open={true}>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </SubscriptionBox> 
            : 
            <>
                <DataGridStyles>
                    <div className="data-broker">
                        <div className="reclaim">
                            <div className="reclaim-heading">
                                <h3>{listInfo?.total_summary?.total_request}</h3>
                                <h6>{t(featureContent[slug]?.pre_purchase?.heading)}</h6>
                                <PrimaryBtn disabled={userLoading} onClick={!userLoading ? !hasPurchasedAddon ? onClickPurchaseCta : proceedToAccountInfo : null} className="m-auto d-flex" isPrivacy={featureContent[slug]?.product === constants['purescan']} isKeep={featureContent[slug]?.product === constants['purekeep']} isEcrypt={featureContent[slug]?.product === constants['purecrypt']}>{reclaimData}</PrimaryBtn>  
                            </div>
                            <img src={prePurchaseImage} alt="pre purchase" className="reclaim-img"/>
                        </div>
                        <div className="reclaim-tips">
                            <h4>{t(featureContent[slug]?.pre_purchase?.list?.heading)} <img src={info} alt="info" style={{cursor: 'pointer'}} onClick={()=>toggleInfoModal('helpful')}></img></h4>
                            <ul>
                                {featureContent[slug]?.pre_purchase?.list?.items?.map((item)=> <li>{t(item?.heading)}</li>)}
                            </ul>
                        </div>
                    </div>
                </DataGridStyles>

                <Listing renderHeaderItems={renderHeaderItems} currentTableData={currentTableData} renderDynamicItems={renderDynamicItems} isRmd={true}/>

                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={listInfo?.data_deletion_details.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </>
        }

        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={infoModal.open} toggle={toggleInfoModal} />}
    </>
}

export default PrePurchase;