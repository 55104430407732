import { ReactComponent as Tick } from "../../assets/checkmark.svg";

export const MultiLogin = () => {
    return <>
        <h2 className="multilogins">Multi Logins</h2>
        <p>If you want to connect to PureVPN on more than 10 devices at the same time, then here’s a solution for you:</p>

        <h5>Get 10 additional Multi-login:</h5>
        <p>You can now increase your multi-login limit by up to 10 additional devices, ensuring that they all remain secure and protected online.</p>
    </>
}

export const PortForwarding = () => {
    return <>
        <h2 className="portforwarding">Port Forwarding</h2>
        <p>The Port Forwarding feature comes handy when you need to access an internet-connected device/service from anywhere in the world.</p>

        <h4>How does Port Forwarding benefit me?</h4>
        <p>Let's say you want to access your PC, laptop or server from anywhere in the world. Our Port Forwarding add-on will allow you to do just that.</p>
        <p>If you want to share access to your website/FTP or any other service then you will need our Port Forwarding add-on.</p>
        <p>If you want to play an online game with your friends then you will need our Port Forwarding add-on to allow your friends to join your gaming server.</p>
        <p>If you are using PureVPN on your router then you will need the Port Forwarding add-on to allow any specific port on PureVPN IP.</p>
    </>
}

export const DedicatedIp = () => {
    return <>
        <h2 className="dedicatedip">Dedicated IP VPN</h2>
        <p>With PureVPN's Dedicated IP VPN, you can do much more than you can imagine. This add-on serves as a great solution for those individuals and businesses that wish to restrict access to their networks.</p>

        <h4>Why Do I need Dedicated IP VPN?</h4>

        <h5>Remotely Access Servers with Encryption</h5>
        <p>No matter what remote access server you connect to, you can easily do so without having to worry about being compromised, especially since you are backed by military-grade encryption.</p>
        
        <h5>Perfect for Businesses</h5>
        <p>Individuals and businesses can connect to restricted networks and servers. Rather than just about anyone with a shared IP accessing a network, only those with a specific IP address can do so, thus bolstering security by leaps and bounds.</p>

        <h5>The Possibilities are Limitless</h5>
        <p>Whether you wish to access CCTV cameras, bank accounts or wish to view confidential information located on a secure hospital network, the possibilities are limitless.</p>
    </>
}

export const PureVpn = () => {
    return <>
        <h2 className="purevpn">PureVPN</h2>

        <p>Practice safe browsing and protect your network from unwanted access.</p>
        <p>PureVPN is perfect for daily use in your home or enterprise.</p>
        <p>Plus, we don’t store any data that can identify you.</p>
        <p>With powerful privacy features and even stronger add-ons, you can be at peace knowing your network is uncompromised.</p>

        <ul>
            <li><Tick /> Reliable VPN access anywhere</li>
            <li><Tick /> Ultra-fast download speeds</li>
            <li><Tick /> Use on all your devices</li>
        </ul>

        <p className="highlight purevpn">Over 3 million people use PureVPN to protect networks for their homes, enterprises, businesses, and loved ones.</p>
    </>
}

export const PureKeep = () => {
    return <>
        <h2 className="purekeep">PureKeep</h2>

        <p>PureKeep gives you a safe place to store all of your passwords, login details, and private documents.</p>
        <p>Use PureKeep on up to 10 devices to create hard-to-crack passwords while defending your private details from hackers and scammers.Create endless secure vaults for any purpose like work or personal use.</p>
        <p>Enjoy easy access to your passwords without having to type them in every time.</p>

        <ul>
            <li><Tick /> Store unlimited passwords</li>
            <li><Tick /> Generate strong passwords</li>
            <li><Tick /> Access on all your devices</li>
        </ul>

        <p className="highlight purekeep">Protect as many of your login details as you want, so you can log in faster.</p>
    </>
}

export const PureEncrypt = () => {
    return <>
        <h2 className="purencrypt">PureEncrypt</h2>

        <p>House your sensitive documents in a protected place.</p>
        <p>PureEncrypt helps you share your important files while keeping them safe behind the strongest encryption in the world.</p>
        <p>Your files can go anywhere you go with the added confidence and ease of secure cloud storage.</p>

        <ul>
            <li><Tick /> Secure your files</li>
            <li><Tick /> Enjoy bank-grade security</li>
            <li><Tick /> Convenient cloud storage</li>
        </ul>

        <p className="highlight purencrypt">Secure your important files behind bank-grade encryption so you can share them with peace of mind.</p>
    </>
}

export const PurePrivacy = () => {
    return <>
        <h2 className="pureprivacy">PurePrivacy</h2>

        <p>PurePrivacy is your personal privacy manager that empowers you to ensure your digital well-being.</p>

        <h4>How does it work?</h4>
        <p>PurePrivacy acts on your behalf by sending automated requests to companies to remove any data they might have about you. It then lets you know when the data has been removed.</p>
        <p>PurePrivacy analyzes your social media account’s settings. It lets you select the level of security you wish to maintain for your accounts, and provides you with recommendations on which you can apply with a single tap.</p>

        <ul>
            <li><Tick /> Protect your social media</li>
            <li><Tick /> Block targeted ads</li>
            <li><Tick /> Reclaim your data</li>
        </ul>

        <p className="highlight pureprivacy">Get recommendations about your Social Media privacy and security. Reclaim your data by revoking bad actors' access to it.</p>
    </>
}