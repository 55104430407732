import { APIFAILED } from "../../../../tools/responseMessages";
import { checkAccountType, getPaymentMethods, getTokenFromLocalStorage, poster } from '../../../../tools/tools';
import { ConfigureStyles } from '../../../../styles/dashboard/pure-teams/configure';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { PrimaryBtn } from '../../../../styles/Generic';
import { sendEvents } from "../../../../tools/mpEvents";
import { toast } from "react-toastify";
import { useState } from 'react';
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";
import qs from 'qs';
import Skeleton from 'react-loading-skeleton';
import useRegionsHook from '../custom-hooks/useRegions';
import Select from 'react-select';
import { FlagIcon } from "../../../../styles/flags/Flag";

const ConfigureServer = ({ modal, backdrop, modalWidth, toggle, widthCalculator, selectedSubscriptionData, className, teamsCounter, refreshAllApis}) => {
    const token = getTokenFromLocalStorage();
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [isDeploying, setIsDeploying] = useState(false);
    const { countries, cities, loadRegions, error, setLoadRegions, setError } = useRegionsHook("addon/regions?addon=team_server", "regions");
    const onSelectCountry = (country) => {
        setSelectedCountry(country)
        setSelectedCity({label: cities[country]?.[0]?.label, value: cities[country]?.[0]?.value})
    }
    const onClickConfigureServer = async () => {
        let payload = {
            country: selectedCountry,
            city: selectedCity?.value,
            sub_id: selectedSubscriptionData?.id,
        };        
        try{
            setLoadRegions(true)
            const formdata = qs.stringify(payload)
            const {data: response} = await poster(`pure-teams/configure-team-server`, formdata, token);
            if(response?.status){
                toast.success(response?.message);
                sendEvents({
                    event_name: "ma_configure_team_server",
                    event_properties: {
                        plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                        billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                        payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                        current_status: selectedSubscriptionData?.state ?? 'N/A',
                        account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                        city: selectedCity?.value,
                        country: selectedCountry,
                        team_server_count: teamsCounter?.team_server?.limit ?? 0,
                    },
                }, token);
                setLoadRegions(false)
                setIsDeploying(true);
                refreshAllApis();
            }
            else{
                sendErrorOnFailure(response?.message)
            }
        }
        catch{
            sendErrorOnFailure('Something went wrong. Please try again')
        }
    }

    const sendErrorOnFailure = (reason) => {
        toast.error(reason);
        sendEvents({
            event_name: "ma_fail_configure_team_server",
            event_properties: {
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                current_status: selectedSubscriptionData?.state ?? 'N/A',
                account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                city: selectedCity?.value,
                country: selectedCountry,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                reason: reason
            },
        }, token);
        setError(reason)
    }

    return <Modal backdrop={backdrop} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
        <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
        {!loadRegions ? <>
            <ConfigureStyles>
                <ModalBody className={`${className} pb-0`}>
                    {error ? <PopupBody>
                                <h2>Oops. Something went wrong</h2>
                                <p>{error}</p>
                    </PopupBody> : 
                    !isDeploying ? <PopupBody>
                        <h4>Create Team Server</h4>
                        <div className='details-container'>
                            <p>{`Available licenses:${teamsCounter?.team_server?.limit ?? 1}`}</p>
                        </div>

                        <div className='options-container'>
                            {/* COUNTRIES SELECTION */}
                            {countries?.length > 0 && <div className='option-item'>
                                <label className="mb-2">Country<span className='red'>*</span></label>
                                <Select
                                    options={
                                        countries?.map((country, index) => ({ 
                                            value: country.value, label: 
                                            <>
                                                <FlagIcon className='flags'>
                                                    <span className={`bg-${country.id?.toLowerCase()} pulse`}></span>
                                                </FlagIcon>
                                                <p>{country.label}</p>
                                            </> 
                                        }))
                                    }
                                    placeholder="Select Country"
                                    className="basic-single"
                                    classNamePrefix="basic-single"
                                    unstyled={true}
                                    onChange={(e)=>{
                                        onSelectCountry(e.value)
                                    }}
                                />
                            </div>}
                            {cities && selectedCountry && Object.entries(cities) && <div className='option-item'>
                                <label className="mb-2">City<span className='red'>*</span></label>
                                <Select
                                    name="colors"
                                    options={cities?.[selectedCountry]?.map((city, index) => ({ value: city.value, label: <div className="multi-option">
                                            <p className="m-0">{city.label}</p>
                                        </div> 
                                    }))}
                                    className="basic-single"
                                    classNamePrefix="basic-single"
                                    unstyled={true}
                                    onChange={(e)=>{
                                        setSelectedCity({label: e?.label, value: e?.value})
                                    }}
                                    value={{value: selectedCity?.value, label: <div className="multi-option">
                                            <p className="m-0">{selectedCity?.label}</p>
                                        </div> }}
                                />
                            </div>}
                        </div>
                        </PopupBody> :
                    <PopupBody>
                                <h2>Deploying your Team Server</h2>
                            
                                <div class="loader-line mt-4"></div>
                                <span className='note'>Your team server is under deployment. It might take 20 mins.</span>
                    </PopupBody> 
                    }
                </ModalBody>
                <ModalFooter>
                    {!isDeploying && !error && <PrimaryBtn disabled={!selectedCountry || !selectedCity} isTeams2 onClick={onClickConfigureServer}>Create server</PrimaryBtn>}
                    <PrimaryBtn isTeams3 onClick={toggle}>{isDeploying || error ? 'Close' : 'Back'}</PrimaryBtn>
                </ModalFooter>
            </ConfigureStyles>
        </> : <>
        <div className="mod-body mt-3" style={{minHeight:"200px"}}>
            <Skeleton className="d-block mb-3" width={300}/>
            <Skeleton className="d-block mb-3" width={280}/>
            <Skeleton className="d-block mb-3" width={260}/>
            <Skeleton className="d-block mb-3" width={240}/>
            <Skeleton className="d-block mb-3" width={220}/>
            <Skeleton className="d-block mb-3" width={50}/>
        </div>
        </>}
    </Modal>
}
export default ConfigureServer;

