import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from "./assets/lang/en.json";
import de from "./assets/lang/de.json";
import es from "./assets/lang/es.json";
import fr from "./assets/lang/fr.json";
import nl from "./assets/lang/nl.json";
import it from "./assets/lang/it.json";
import ko from "./assets/lang/ko.json";
import pt from "./assets/lang/pt.json";
import ru from "./assets/lang/ru.json";
import tr from "./assets/lang/tr.json";
import zh from "./assets/lang/zh.json";
import ja from "./assets/lang/ja.json";



const resources = {
   en: {
     translation: en
   },
   de: {
     translation: de
   },
   es: {
    translation: es
  },
   fr: {
    translation: fr
  },
   nl: {
    translation: nl
  },
   it: {
    translation: it
  },
   ko: {
    translation: ko
  },
   pt: {
    translation: pt
  },
   ru: {
    translation: ru
  },
   tr: {
    translation: tr
  },
   zh: {
    translation: zh
  },
   ja: {
    translation: ja
  },
   
 };
 const options = {
    order: ['querystring','navigator'],
    lookupQuerystring: 'locale'
  }

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // it should be managed from env 
    detection: options,
    fallbackLng: 'en', // Fallback language if a translation is missing
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;