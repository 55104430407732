import React from 'react'
import { useHistory } from 'react-router-dom';
import { CouponBpx, ReferralButton } from '../../../../styles/dashboard/pure-rewards/PureWallet'
import { dateFormat, parsedJson } from '../../../../tools/tools';
import { useState } from 'react';
import AddonModal from '../modals/AddonModal';
import CheckIconSVG from '../../../../assets/svg/CheckIconSVG';
import AddonPurchase from '../../subscriptions/modals/AddonPurchase';

const AvailableComponent = ({renderProgressBar=()=>{},isAvailable=false,isRedeem=false,isUsed=false,data={},isCartView=false,redeemCoupon=()=>{},redeemCtaLoader=false,selectId="",toDisableState=false,selectedSubscription,earnedCredits=0}) => {
    const [openModal,setOpenModal] = useState(false)
    const history = useHistory()
    const buttonText = () => {
        return (selectId === data?.id && redeemCtaLoader) ?  ( <>{isCartView ? "Use" : "Redeem"} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>) : isCartView ? "Use" : "Redeem" ;
    }
    const executeCart = (data) => {
        if(data?.action === "redirect"){
            history.push(`/dashboard/${data?.redirect}`)
        }else if(data?.action === "open_cart"){
            setOpenModal(data?.open_cart)
        }
    }
    return (
        <>
            <CouponBpx isAvailable={isAvailable} isCartView={isCartView} data-coupon-id={data?.id} toDisable={toDisableState}>
                <div className="innerDiv">
                    <div className='circle up-l'></div>
                    <div className='circle down-l'></div>
                    <div className={isAvailable ? 'd-none': `circle up-r`}></div>
                    <div className={isAvailable ? 'd-none' : `circle down-r`}></div>
                    <div className="progressSec d-flex justify-content-center align-items-center">
                        <div className='d-none circle up-m-l'></div>
                        <div className='d-none circle down-m-l'></div>
                        {renderProgressBar(data?.pointsToRedeem)}
                    </div>
                    <div className="infoSec d-flex align-items-center">
                        <span className="infoSpan">
                            <h2>{isAvailable ? data?.coupon?.product?.productName : data?.name }</h2>
                            <p>{isAvailable ? data?.coupon?.product?.description : data['reward.description']} </p>
                        </span>
                        {isRedeem ?
                            parsedJson(data?.config)?.code === "all" ? 
                                <p className="checkout-text">Voucher can only be used on Checkout</p>                            
                            :
                                <ReferralButton disabled={redeemCtaLoader} marginLeft={true} onClick={()=>executeCart(parsedJson(data?.config))}>{"Use"}</ReferralButton>
                        :
                            isUsed ?
                                <p className="used-text"><CheckIconSVG width={15} fillColor={'#2CDC82'} /> Used on {dateFormat(data.updatedAt)}</p> 
                            : 
                                <ReferralButton disabled={redeemCtaLoader || earnedCredits < data?.pointsToRedeem} marginLeft={true} onClick={()=>redeemCoupon(data,true)}>{buttonText()}</ReferralButton>}
                    </div>
                </div>
            </CouponBpx>
            
            {
                openModal && <AddonModal setOpenModal={setOpenModal} addon={openModal} couponApplied={data} selectedSubscription={selectedSubscription}/>
            }
        </>
    
    )
    }

export default AvailableComponent