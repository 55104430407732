import { useEffect, useState } from "react";
import { PrimaryBtn } from "../../../../styles/Generic";
import { InfoCard, Input } from "../../../../styles/dashboard/Main";
import { CouponViewStyles } from "../../../../styles/AppliedCoupon";
import Skeleton from "react-loading-skeleton";

const CouponView = ({ getPreviewData, setIsCouponCodeApplied=()=>{}, couponCode, setCouponCode, setIsCouponCodeAppliedLoading=()=>{}}) => {
    

    const sendCouponRequest = () => {
        getPreviewData({coupon: couponCode})
        setIsCouponCodeApplied(true);
        setIsCouponCodeAppliedLoading(true);
    }
    return <CouponViewStyles>
            <>
                <div className="coupon-field">
                    <Input placeholder="Enter Coupon Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                    <PrimaryBtn disabled={couponCode.length <= 0 ? true : false} onClick={()=>sendCouponRequest()}>Apply Coupon</PrimaryBtn>
                </div>
            </>
        </CouponViewStyles>
}
export default CouponView;