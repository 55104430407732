import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const WindowsIconSVG = ({ fillColor, width, height, style, className, onClick }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} onClick={onClick} className={className} viewBox="0 0 18 18" width={width}>
    <Path
        fill={fillColor}
        fillRule="evenodd" clipRule="evenodd"
        d="M2.55005 5.54958C2.55005 4.54583 3.34731 3.77545 4.2994 3.67741L13.2942 2.55306C13.3004 2.55229 13.3065 2.55165 13.3127 2.55114C14.4527 2.45613 15.4501 3.31442 15.4501 4.42458V13.4996C15.4501 14.5684 14.5604 15.4073 13.5637 15.3956C13.482 15.4397 13.3879 15.4578 13.2942 15.4461L4.2994 14.3218C3.34731 14.2237 2.55005 13.4533 2.55005 12.4496V5.54958ZM13.3959 3.44735L9.45005 3.94058V8.54974H14.55V4.42458C14.55 3.88707 14.0517 3.39727 13.3959 3.44735ZM14.55 9.44974H9.45005V14.0586L13.1507 14.5212C13.2365 14.4787 13.3345 14.4644 13.4305 14.4818C13.9718 14.5803 14.55 14.1265 14.55 13.4996V9.44974ZM8.55005 8.54974V4.05308L4.40586 4.57111L4.39483 4.57235C3.84951 4.62688 3.45005 5.0554 3.45005 5.54958V8.54974H8.55005ZM3.45005 9.44974H8.55005V13.9461L4.40586 13.4281L4.39483 13.4268C3.84951 13.3723 3.45005 12.9438 3.45005 12.4496V9.44974Z"
    />
  </SVG>
)
WindowsIconSVG.propTypes = {
  fill: PropTypes.string,
}
WindowsIconSVG.defaultProps = {
  width: '18',
  height: '18',
  fillColor: '#6536CC',
  className: 'svg-icon windows-icon'
}
export default WindowsIconSVG