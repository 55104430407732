import { useState } from 'react';
import { connect } from 'react-redux';
import { Stream } from '../../../../styles/dashboard/PureAI';
import { getTokenFromLocalStorage, poster } from '../../../../tools/tools';
import StreamDetails from './StreamDetails';
import qs from 'qs';

const StreamView = ({ pureAIStateReducer, details }) => {
    const movieDetails = details.message;

    const [ showDetails, setShowDetails ] = useState(JSON.parse(localStorage.getItem('show-details')));

    const [ loader, setLoader ] = useState(null);

    const errorHandler = (error) => {
        console.log("Error Occurred: ", error)
    }

    const updateShowDetails = (data) => {
        let updatedShowDetails = showDetails?.length > 0 ? showDetails : {};
        updatedShowDetails[details.message_id] = data.body;
        setShowDetails(updatedShowDetails);
        localStorage.setItem('show-details', JSON.stringify(updatedShowDetails));
    }

    const getShowDetails = (movie) => {
        setLoader({message_id: details.message_id})
        const data = {
            id: movie['api-details'].id, 
            type: movie['api-details'].objectType
        };
        poster('public/getMovieDetails', qs.stringify(data), getTokenFromLocalStorage()).then(response => {
            return response.data;
        })
        .then(updateShowDetails)
        .catch(errorHandler)
    }

    const updateShowDetailsState = (message_id) => {
        let updatedShowDetails = showDetails.length > 0 ? showDetails : {};
        updatedShowDetails[message_id] = null;
        setShowDetails(updatedShowDetails);
        setLoader({message_id: null});
    }
    return (
        <Stream>
            {
            showDetails?.[details.message_id] ? 
                <StreamDetails showDetails={showDetails[details.message_id]} resetShowDetails={ () => { updateShowDetailsState(details.message_id) }}/>
            :
            <div>
                <span className="inquiry-context show">Did you mean..</span>

                <div className="movie-container show">
                    {
                        loader?.message_id === details.message_id && 
                        <div className="loader-wrapper">
                            <div className="loader"></div>
                        </div>
                    }
                    {
                        movieDetails.map((movie, index) => {
                            return (
                                <div className='movie-card' key={movie['api-details'].id} onClick={() => { getShowDetails(movie) }}>
                                    <img className="provider-image" src={movie.thumbnail} alt={movie.title} />
                                    <h3 className='thumb-title'>{movie.title}</h3>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            }
        </Stream>
    )
}


const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(StreamView);