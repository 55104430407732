import { featureContent } from "../content/index";
import { FeatureInfoStyles } from "../../../styles/dashboard/security-tools/securityTools";
import { useTranslation } from "react-i18next";

const FeatureInfo = ({slug}) => {

    const { t } = useTranslation();

    return <FeatureInfoStyles>
        {featureContent[slug]?.features.map((feature)=>{
            return <div className={`feature-box ${feature?.align}`}>
                <img src={feature?.image} alt="feature-image"/>
                <div>
                    <h3>{t(feature?.heading)}</h3>
                    <p>{t(feature?.paragraph)}</p>
                </div>
            </div>
        })}
    </FeatureInfoStyles>
}
export default FeatureInfo;