import { CustomBannerWrapper } from '../../../styles/dashboard/Main'

const CustomBanner = ({  onDismissButtonClick, onOpenAppButtonClick }) => {
  return (
    <CustomBannerWrapper>
    <div className="cont-box">
        <div className="cont-content">
            <div className="cont-upper">
                <img src="https://images.purevpn-tools.com/public/images/app-pvpn-logo.png" alt="pvpn-logo"
                    loading="lazy" width="43px" height="43px"/>
                <p>Get the Best of PureVPN with Our App!</p>
            </div>
            <div className="cont-lower">
                <div className="cont-btn1" id="close-bar">
                <button onClick={onDismissButtonClick}>Not now</button>
                </div>
                <div className="cont-btn2">
                  <button onClick={onOpenAppButtonClick}>Open App</button>
                </div>
            </div>
        </div>
    </div>
    </CustomBannerWrapper>
  );
};

export default CustomBanner;
