import React, {useEffect, useState} from 'react'
import { CardBox, DashboardHead, DownloadBox, GuideBox, InfoCard, DownloadAllAppsNav } from '../../../styles/dashboard/Main'
import macos from '../../../assets/device_mac.jpg'
import {LinkBtn, PrimaryBtn} from '../../../styles/Generic'
import classNames from 'classnames'
import qs from 'qs'
import Skeleton from 'react-loading-skeleton';
import AllDevices from './modal/AllDevices'
import { Input } from 'reactstrap'
import { getDeviceRequested, getDeviceTypeLocale } from '../../../redux/downloads/actions'
import { connect, useDispatch } from 'react-redux'
import ErrorBoundary from "../../ErrorBoundary"
import {Helmet} from "react-helmet";
import {sendEvents, sendGuestEvents} from "../../../tools/mpEvents"
import { getCurrentDeviceType, filterSlug, isPurchasedAddOn } from '../../../tools/tools';
import { appNameObj, constants } from '../../../tools/constants';
import { setActiveDevices, browserGenerics } from '../../../tools/genericMethods';
import DownloadAppBox from './DownloadAppBox';
import DownloadSidebarBox from './DownloadSidebarBox';
import { osName, deviceType, mobileVendor, browserName } from 'react-device-detect';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, seti18Locale } from '../../../tools/tools';
import { devicesMultiLang } from "../../../assets/lang/devices_lang";

import ThankYouView from './thank-you-page'

const Download = ({getDeviceRequested, download, onBoardingReducer}) => {

    const {search} = useLocation();

    const {slug} = Object.fromEntries(new URLSearchParams(search));

    const token = localStorage.getItem("token") ?? undefined;

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const [crashed, setCrashed] = useState(false)

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({
        devices:[{
            img:macos,
            heading: "",
            osx:"",
            hash:"",
            setup_guides:[],
            link:"",
            release_notes:"",
            abbreviation:"",
            versions:[]
        }],
        extensionDevice: false,
        slug:false,
        loaded:false
    })
    const location = useLocation();

    const [devices, setdevices] = useState({})

    const [device, setDevice] = useState("device1")

    const [modal, setModal] = useState(false);

    const urlData = qs.parse(location.search);

    const isQuery = urlData["?query"] ?? null;

    const [appName, setAppName] = useState(isQuery ? appNameObj[isQuery] : "PureVPN");

    const [appSlug, setAppSlug] = useState(isQuery ? isQuery :"purevpn");

    const [selectedApp, setSelectedApp] = useState(null);

    const [otherDevices, setOtherDevices] = useState(null);

    const [apps, setApps] = useState([]);

    const [showThankyouPage,setShowThankyouPage] = useState(false)

    const [formData,setFormData] = useState({})

    const dispatch = useDispatch()

    const get_locale = seti18Locale();
    // const currentDevice = getCurrentDeviceType()
    const currentDevice = slug ? slug : osName

    const toggle = () => setModal(!modal);

    useEffect(() => {
        if(get_locale === 'en'){ //locale is - en
            getDeviceRequested(token)
        }else{
            let responseToSaveInLocalStorage = {body:devicesMultiLang[get_locale]['body'],status:true,message:"Data Fetched Successfully"}
            dispatch(getDeviceTypeLocale(responseToSaveInLocalStorage))
        }
    }, [])

    useEffect(() => {

        if (token) {
            sendEvents(
              {
                event_name: `${constants.eventPrefixDownloads}view_${appSlug}`,
                event_properties: {}
              }, token);
      
          } else {
            sendGuestEvents(
              {
                event_name: `${constants.eventPrefixDownloads}view_${appSlug}`,
                event_properties: {}
              }, data);
          }
    }, [appSlug])

    useEffect(() => {

        const { errors } = download;

        if(download && download.devices?.body && download.devices?.body.length > 0){

            let selectedAppTemp = selectedApp;

            if (!selectedApp && selectedApp === null && download.devices?.body) {
                download.devices?.body.map(((apps, idx) => {
                    if (apps?.slug == appSlug) {
                        selectedAppTemp = idx;
                        setSelectedApp(idx)
                    }
                }))
            }

            setApps(download.devices?.body)

            if (download.devices?.body[selectedAppTemp].slug == appSlug) {
                if(slug){
                    setActiveDevices(download, download.devices?.body[selectedAppTemp].devices, data, setData, appSlug, selectedAppTemp, false, slug, 0, slug)
                }else{
                    setActiveDevices(download, download.devices?.body[selectedAppTemp].devices, data, setData, appSlug, selectedAppTemp, false)
                }

                const configurable_devices = download.devices?.body[selectedAppTemp].devices.find(device => device.abbreviation === "other_devices")

                if (configurable_devices !== undefined) {
                    setOtherDevices(configurable_devices)
                } else {
                    setOtherDevices(null)
                }

                setDevice(currentDevice)
                setTimeout(() => {
                    setLoading(false);
                }, 600);

            } else {
                setCrashed(true)
                setLoading(false);
            }

            if(errors) {
                setCrashed(true)
                setLoading(false);
            }
        }
    }, [download, selectedApp]);

    const downloadDevice = (link, name, version, abbreviation) => {
        if (localStorage.getItem("token")) {

            sendEvents(
                {
                  event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                  event_properties: {
                    cta: `download_${abbreviation}`,
                  }
        
                }, token
            );

        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: `download_${abbreviation}`,
                    }
                }, token
            );
        }
        window?.open(link, '_blank');
    }


    const versionClickEvents = (name) => {

        let slug = filterSlug(name)

        if (localStorage.getItem("token")) {

            sendEvents(
                {
                event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                event_properties: {
                    cta: slug,
                }
        
                }, token
            );
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: slug,
                    }
                }, data
            );
        }
    };

    const sendGuidesEvents = (e) => {

        let slug = filterSlug(e.target.innerHTML)

        if (localStorage.getItem("token")) {
            sendEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: slug,
                    }
                }, token
            );
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: slug,
                    }
                }, data
            );
        }
    };

    const sendOtherDevicesEvents = (name) => {
        let slug = filterSlug(name)

        if (localStorage.getItem("token")) {

            sendEvents(
                {
                event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                event_properties: {
                    cta: `other_devices_${slug}`,
                }
        
                }, token
            );
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: `other_devices_${slug}`,
                    }
                }, data
            );
        }

    }

    const sendReleaseNotesEvents = (name) => {

        let slug = filterSlug(name)

        if (localStorage.getItem("token")) {
            sendEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: `setup_guide_${slug}`,
                    }
                }, token
            );
    
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: `setup_guide_${slug}`,
                    }
                }, data
            );
        }
    };

    const moreAppsCallback = (e) => {

        let slug = filterSlug(e.target.innerHTML)

        if (localStorage.getItem("token")) {
            sendEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: slug,
                    }
                }, token
            );
    
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: slug,
                    }
                }, data
            );
        }
        toggle();
    };

    const sendOtherDevicesModalEvents = (name) => {

        let slug = filterSlug(name)

        if (localStorage.getItem("token")) {

            sendEvents(
                {
                event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                event_properties: {
                    cta: `other_devices_${slug}`,
                }
        
                }, token
            );
        } else {
            sendGuestEvents(
                {
                    event_name: `${constants.eventPrefixDownloads}click_${appSlug}`,
                    event_properties: {
                        cta: `other_devices_${slug}`,
                    }
                }, data
            );
        }
    };

    const selectedClickHandle = (index, locked) => {
        // Temp logic for slug name purecrypt and purescan should be reomved later.
        let appNameSlug = "";
        apps.map((app, idx) => {
            if (idx == index) {
                appNameSlug = app.slug;
                if (typeof app.slug !== undefined && !locked) {
                    setAppSlug(app.slug)
                }
                if (typeof app.name !== undefined && !locked) {
                    setAppName(app.name)
                }
            }
        })
        if (!locked) {
            setSelectedApp(index)
        }
        
    };


    if(crashed){
        return <ErrorBoundary></ErrorBoundary>
    }
    return (
        <div>
            <Helmet>
                <title>Member Area | Apps</title>
                <meta name="description" content="PureVPN offers proprietary apps for all leading platforms so you can enjoy complete internet freedom on any device you want!" />
            </Helmet>
            <DashboardHead className='download-page'>
                <h2>Downloads</h2>
                <p>{t('downloads_download_purevpn')}</p>
            </DashboardHead>
            {
                (!loading && data?.devices?.length > 0) ?
               !showThankyouPage ? 
                <div className="row">
                    {
                        apps.length > 0
                        ? (
                            <div className="col-md-8">
                                <DownloadAllAppsNav className="all-apps-wrapper">
                                    {
                                        apps.map((app, idx) => {
                                            let isLocked = (app?.active !== 1)
                                            if (app.devices.length > 0) {
                                                return <li key={idx} className={`col-sm-3 col-xs-6 ${ (idx == selectedApp) ? "active" : "" } ${ (app.is_new === 1) ? "is-new" : "" } ${ (isLocked) ? "locked" : "" }`} onClick={() => selectedClickHandle(idx, isLocked)}><span>{app.name}</span></li>
                                            }
                                        })  
                                    }
                                </DownloadAllAppsNav>
                            </div>
                        ) : null
                    }
                    <div className="col-md-8">
                        <DownloadAppBox setShowThankyouPage={setShowThankyouPage} selectedApp={selectedApp} download={download} data={data} setData={setData} appName={appName} setDevice={setDevice} devices={data.allDevices} downloadClickCallback={downloadDevice} releaseNotesClickCallback={sendReleaseNotesEvents} versionClickCallback={versionClickEvents} sendGuidesEvents={sendGuidesEvents} setFormData={setFormData}/>
                    </div>
                    <div className="col-md-4">
                        <DownloadSidebarBox selectedApp={selectedApp} download={download} data={data} setData={setData} appSlug={appSlug} device={device} setDevice={setDevice} devices={data.allDevices} sendGuidesEvents={sendGuidesEvents} moreAppsCallback={moreAppsCallback} sendOtherDevicesEvents={sendOtherDevicesEvents} />
                    </div>
                </div> : <ThankYouView setShowThankyouPage={setShowThankyouPage} downloadClickCallback={downloadDevice} extensionDevice={data.extensionDevice} appName={appName} formData={formData}/>
                :
                <div className="row">
                    <div className="col-md-8">
                        <CardBox open={true}>
                            <InfoCard hasborder noflex className="mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard noflex className="pt-0">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                        </CardBox>
                    </div>
                    <div className="col-md-4">
                        <InfoCard hasborder noflex className="mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard noflex className="pt-0">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                    </div>
                </div> 
            }
            {
                modal && otherDevices ? ( <AllDevices modal={modal} appSlug={appSlug} appName={appName} toggle={toggle} otherDevices={otherDevices} sendOtherDevicesModalEvents={sendOtherDevicesModalEvents}></AllDevices> ) : null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        download: state.devicesReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, {getDeviceRequested})(Download)
