import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NewSignupViewWrapper, UpgradeFAQ } from '../../../../styles/dashboard/Main'
import { useTranslation } from 'react-i18next';
import { sendEvents } from '../../../../tools/mpEvents';

 const FamilyPlansFaqs = ({accountEmail}) => {

    const history = useHistory()

    const { t, i18n } = useTranslation();

    const [faqs, setFaqs] = useState([
        {
            question: t('familyplan_faqs_number_one_question'),
            answer: t('familyplan_faqs_number_one_answer'),
            open: false,
        },
        {
            question:t('familyplan_faqs_number_two_question'),
            answer: t('familyplan_faqs_number_two_answer'),
            open: false,
        },
        {
            question: t('family_faq_number_nine'),
            answer: "",
            open: false,
        },
        {
            question: t('familyplan_faqs_number_three_question'),
            answer: t('familyplan_faqs_number_three_answer'),
            open: false,
        },
        {
            question: t('familyplan_faqs_number_four_question'),
            answer: t('familyplan_faqs_number_four_answer'),
            open: false,
        },
        {
            question: t('familyplan_faqs_number_five_question'),
            answer: t('familyplan_faqs_number_five_answer'),
            open: false,
        },
        {
            question: t('familyplan_faqs_number_six_question'),
            answer: t('familyplan_faqs_number_six_answer'),
            open: false,
        },
        {
            question: t('familyplan_faqs_number_seven_question'),
            answer: t('familyplan_faqs_number_seven_answer'),
            open: false,
        },
        {
            question: t('familyplan_faqs_number_eight_question'),
            answer: t('familyplan_faqs_number_eight_answer'),
            open: false,
        },
    ]);
    const faqToggle = (index) => {

        let updatedFaqs = []

        faqs.map((faq, idx) => {
            if (idx === index) {
                updatedFaqs.push({
                    ...faq,
                    open: (!faq.open)
                })
            } else {
                updatedFaqs.push({...faq})
            }
        })

        setFaqs(updatedFaqs)
    }

    const token = localStorage.getItem("token");

    const redirectToDome = () =>{
        sendEvents(
            {
                event_name: "ma_route_to_busniess_page",
                event_properties: {
                    cta: "PureDome - our VPN solution designed for teams and businesses.",
                    email:accountEmail.email 
                    
                },
            },
            token)
            history.push("/dashboard/business");
      }

  return (
    <NewSignupViewWrapper>
        <section className='row faqs variant-2'>
                <div className='content'>
                    <h2 className='heading'><span>{t('upgradeplan_faq_heading')}</span></h2>
                    {
                        faqs.map((faq, idx) => {
                            return (
                                <div key={idx} className={`item ${ (faq.open) ? "open" : "" }`}>
                                    <div className='question' onClick={() => faqToggle(idx)}>
                                        <p>{faq.question}</p>
                                        <span className='icon'></span>
                                    </div>
                                    <div className='answer'>
                                        <p>
                                        { faqs.length-7 === idx ? 
                                            <p>{t('family_faq_number_nine_para')} <a className='chat' onClick={redirectToDome}>{t('family_faq_number_nine_para_part_two')}</a></p>
                                          : faq.answer}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>

    </NewSignupViewWrapper>
   
  )
}

export default FamilyPlansFaqs;