import React from 'react'
import crashed from '../assets/crashed.png'
import { DestructBtn, ErrorBox, PrimaryBtn, SecondryBtn } from '../styles/Generic'
import { useTranslation } from 'react-i18next'

const ErrorBoundary = ({setCrashed, logoutSubmit, heading, text, noSubscription, isCartError=false, tglCartApi=()=>{}, className, hideHeading}) => {

    const { t, i18n } = useTranslation();

    const handleRefresh = () => {
        if(isCartError){
            tglCartApi(prev=>!prev)
        }else{
            window.location.reload()
        }
    }

    const openChat = () => {
        if(window.Intercom){
            window.Intercom('show')
        }
    }

    const getNewSubscription = () => {
        window.location.href = 'https://www.purevpn.com/order'
    }

    return (
        <ErrorBox className={className}>
            <div>
                <img src={crashed} className="img-fluid" height={213} alt="Crashed"/>
                {!hideHeading && <h2 className="mt-3">{t(heading)}</h2>}
                <p className={`${hideHeading ? 'mt-4' : ''}`}>{t(text)}</p>
                {
                    !noSubscription
                    ?
                    <PrimaryBtn isTeams2 onClick={handleRefresh}>{t('errorscreen_oops_refresh')}</PrimaryBtn>
                    :
                    <DestructBtn onClick={getNewSubscription}>{t('dashboard_get_new_subscription')}</DestructBtn>
                }
                <PrimaryBtn isTeams3 onClick={openChat} className="ms-2">{t('errorscreen_talk_to_support')}</PrimaryBtn>
                {logoutSubmit && <DestructBtn className="ms-2" onClick={logoutSubmit}>{t('manage_account_logout')}</DestructBtn>}
            </div>
        </ErrorBox>
    )
}

ErrorBoundary.defaultProps = {
     heading: 'errorscreen_oops_text',
     text: 'errorscreen_oops_para',
     noSubscription: false,
     logoutSubmit: false,
}

export default ErrorBoundary