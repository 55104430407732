import React from 'react'
import { BusinessImg, BussinessImgContainer } from '../../../../styles/dashboard/Business';
import HelloRache from '../../../../assets/HelloRache.svg';
import OX from '../../../../assets/ox.svg';
import DAZN from '../../../../assets/DAZN.svg';
import Appinventiv from '../../../../assets/Appinventiv.svg';
import Samsung from '../../../../assets/Samsung.svg';
import OLG from '../../../../assets/olg.svg';

function BusinessLogo() {
  return (
    <BussinessImgContainer>
      <BusinessImg src={HelloRache} alt='Hello Rache' />
      <BusinessImg src={DAZN} alt='DAZN' />
      <BusinessImg src={Samsung} alt='Samsung' />
      <BusinessImg src={OX} alt='OX' />
      <BusinessImg src={Appinventiv} alt='Appinventiv' />
      <BusinessImg src={OLG} alt='OLG' />
    </BussinessImgContainer>
  )
}

export default BusinessLogo