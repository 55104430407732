import styled from "styled-components"
import { css } from "styled-components"
import ThankYouDevices from '../../assets/thankyou_device_sprite.png'


export const ThankYouDisplay = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1{
    font-weight: 750;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #43225B;
  }
  p{
    font-weight: 400;
    font-size: 20px !important;
    line-height: 27px;
    text-align: center;
    color: #43225B;
    margin: 30px 0;
  }
  h2{
    font-weight: 700;
    font-size: 24px;
    max-width:750px;
    line-height: 27px;
    text-align: center;
    color: #43225B;
    margin: 30px 0;
  }
  a{
    margin-top: 25px;
  }
  span{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  ${props => props.isOB && css`
      margin-top:20px;
      padding-top: 0 !important;
      h1{
        font-size: 24px;
        margin:5px 0 0 0;
      }p{
        font-size: 16px !important;
        margin: 10px 0 !important;
      }h2{
        font-size: 18px;
        margin: 12px 0;
      }
  `}
  @media screen and (max-width: 555px) {
    h1{
      font-size: 26px;
    }
    p{
      margin: 15px 0;
      font-size: 16px;
    }
    h2{
      font-size: 20px;
      margin: 15px 0;
    }
    span{
      gap:20px
    }
  }
`
export const VPNDevices = styled.div`
  width: 132px;
  height: 132px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* :not(:first-child){ */
    margin-left: 20px;
  /* } */
  cursor: pointer;
  :hover{
    border: 1px solid #6536CC;
  }
  p{
    margin: 0;
    margin-top: 80px !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 18px;
    text-align: center;
    color: #3B108E;
  }
  @media screen and (max-width: 360px) {
  margin-bottom: 20px;
  :last-child {
    margin-top: 20px !important;
    order: 3;
    margin-left: 0 !important;
  }
}
`
export const NewProductsDevices = styled.div`
  width: 204px;
  height: 200px;
  background: #FFFFFF;
  border: 0.5px solid #DDD4E2;
  border-radius: 12px;
  text-align: center;
  padding: 30px 0;
  margin-left: 20px;
  :not(:first-child){
  }
  cursor: pointer;
  :hover{
    border: 1px solid #6536CC;
  }
  h6{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #43225B;
    margin-top: 10px;
  }
  p{
    font-weight: 400;
    font-size: 10px !important;
    line-height: 15px;
    text-align: center;
    color: #43225B;
    margin: 10px;
    min-height: 45px;
  }
  a{
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
  }
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  margin-bottom: 20px;
  :nth-child(3) {
    order: 3;
  }
}
`
export const DeviceIcon = styled.span`
position:relative;
justify-content: center;
::after{
  position:absolute;
  content:"";
  width:70px;
  height:70px;
  background:url(${ThankYouDevices}) no-repeat;
}
&.active{
  color: #43225B;
  font-weight:bold;
}
&.icon-mac::after,&.icon-macos::after{
background-position: -10px -190px;
}

&.icon-windows::after{
background-position: -100px -190px;
}

&.icon-linux::after{
background-position: -190px -100px;
}

&.icon-linux_gui::after{
background-position: -190px -100px;
}

&.icon-android::after{
  left:0px;
background-position: -280px -10px;
}

&.icon-android_tv::after{
background-position: -10px -10px;
}

&.icon-iphone_ios::after,&.icon-ipad_ios::after{
height: 35px;
top:20px;
background-position: -10px -280px;
}

&.icon-google_chrome::after{
background-position: -100px -10px;
}

&.icon-firefox::after{
background-position: -100px -100px;
}

&.icon-edge::after{
background-position: -10px -100px;
}

&.icon-brave::after{
background-position:-190px -190px;
}

&.icon-firestick::after{
background-position: -190px -10px;
}

&.icon-web::after{
background-position: -100px -10px;
}

&.icon-chromeos::after{
background-position: -100px -10px;
}

`