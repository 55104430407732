import { useEffect } from 'react'

const HubspotContactForm = (props) => {
  const { region, portalId, formId, onSubmit = () => { } } = props;
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubspotContactForm',
          onFormSubmit: function($form) {
          },
          // onSubmitted:
          onFormSubmitted: onSubmit
        })
      }
    })
  }, [])

  return (
    <div id="hubspotContactForm" className='domeForm' />
  )
}

export default HubspotContactForm