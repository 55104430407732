export const dedicatedIpPricing = (selectedSubscriptionData, selectedSubscriptionPlans) => {
    const addon = selectedSubscriptionPlans?.items.dedicated_ip;
    const price = addon?.unit_amount / selectedSubscriptionData?.plan?.interval_length;

    // Check if price is NaN and use static value 1.99 if NaN
    const formattedPrice = isNaN(price) ? parseFloat(process.env.REACT_APP_DEDICATED_IP_PRICE) : price.toFixed(2);
    return formattedPrice;
}
// TEAM MEMBER FUNCTIONS
export const minDisabledForDedicatedIp = (state) => {
    return !state?.current || state?.current <= state?.min
}
export const maxDisabledForDedicatedIp = (state, max) => {
    return max ? state?.current >= max : state?.current >= state?.max
}
export const getMaximumForDedicatedIp = (state) => {
    return state.max
}
export const getMinimumForDedicatedIp = (state) => {
    return state.min
}
export const hasErrorForDedicatedIp = (state, max) => {
    return max ? (state?.current < state.min || state?.current > max) : state?.current < state.min || state?.current > state.max
}
export const getValueForDedicatedIp = (state) => {
    return state?.current
}
export const onChangeCounterForDedicatedIp = (payload) => {
    let {type, dispatch, value, max} = payload;
    dispatch({ type: type, value: value, limit: max });
}
export const isDisabledForDedicatedIp = (state) => {
    return !state?.current || state?.current < state.min ||
    state?.current > state.max
}
export const getTotalAmountForDedicatedIp = (state) => {
    return (
        (state?.current ? +state?.current * state?.price: 0)
    )
}