import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MultiloginStyles, PopupBody } from '../../../../../styles/dashboard/Main'
import { multiLoginTiers } from '../../../../../tools/constants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { currencySymbol, donotShowTeamsToTeamUser } from '../../../../../tools/tools';
import { withRouter } from 'react-router-dom';

const MultiLoginContent = ({history, selectedSub, multiLimit, setMultiLimit, redirectToTeams}) => {
    const { search } = useLocation();
    const tiers = selectedSub?.plan?.add_ons?.find(addon => addon.code === "multi_login")?.tiers;
    const limit = useRef(false);

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    const { t } = useTranslation();

    useEffect(()=>{
        if(!limit.current){
            setMultiLimit(multiLoginTiers[selectedSub?.plan?.add_ons?.find(addon => addon.code === "multi_login")?.tiers?.[0]?.ending_quantity]);
            limit.current = true;
        }
    }, [])

    const openAddmore = () => {
        history.push('/dashboard/teams?openAddMoreMembersModal=true')
    }
    
    return (
        <PopupBody>
            <MultiloginStyles>
                <div class="mod-head"> 
                    <h3>{tiers?.length > 0 ? t('multi_heading_one') : t('multi_heading_two')}</h3>
                </div>
                <div class="mod-body mt-4">
                    <h4>{t('current_limit')}<strong>{`${selectedSub?.add_ons?.find((addon)=> addon.code === 'multi_login')? selectedSub?.add_ons?.find((addon)=> addon.code === 'multi_login')?.quantity : 10} ${t('concurrent_sessions')}`}</strong></h4>
                    
                    {tiers?.length > 0 ? <>
                        <p>{t('session_question', {num: `${selectedSub?.add_ons?.find((addon)=> addon.code === 'multi_login')?.quantity ?? 10}`})}</p>

                        <div className='mod-boxes-container'>
                            {tiers?.map((tier)=>{
                            return <div className={`mod-boxes ${multiLoginTiers[tier?.ending_quantity] === multiLimit ? 'selected' : ''}`} onClick={()=>setMultiLimit(multiLoginTiers[tier?.ending_quantity])}>
                                    <h4>{multiLoginTiers[tier?.ending_quantity]}</h4>
                                    <p>{t('sessions')}</p>
                                    <h3>{`${currencySymbol[tier?.currency_code]}${tier?.unit_amount}`}</h3>
                                </div>
                            })}
                        </div>
                        <div className='total-amount'>
                            <h4>{t('total_amount')}
                            <strong>{`${currencySymbol[tiers?.find(tier => multiLoginTiers[tier?.ending_quantity] == multiLimit)?.currency_code]}${tiers?.find(tier => multiLoginTiers[tier?.ending_quantity] == multiLimit)?.unit_amount?.toFixed(2)}`}</strong>
                            </h4>
                        </div>
                    </> : 
                    <>
                        <h4 className='mt-4' style={{lineHeight: '20px'}}>{!redirected_via ? t('multi_para_three') : 'Uh ho!'}</h4>
                        <h4 className='mt-4' style={{lineHeight: '20px'}}>{!redirected_via ? 'With up to 200 accounts, shield unlimited devices of your team members from online threats.' : 'You have reached the max multi login limit'}</h4>
                    </>
                    }
                </div>
            </MultiloginStyles>
        </PopupBody>
  )
}

export default withRouter(MultiLoginContent)



