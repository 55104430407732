import styled, { css } from "styled-components";

export const CouponBpx = styled.div`
position: relative;
margin-bottom: 20px;
width: 978px;
${props=>props.toDisable && css`
  opacity:0.65;
  cursor: default;
  pointer-events:none;
  button{
    cursor: default;
  }
`}
.innerDiv{
    height: 170px;
    border: 2px solid black;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #DDD4E2;
    display: flex;
}
.circle{
    position: absolute;
    background-color: #fff;
    border: 1px solid #DDD4E2;
    border-radius: 0 0 10rem 10rem;
    width:36px;
    height: 20px;
    left: 181.5px;
    border-top: none;
}
.down-l{
    transform:rotate(180deg);
    bottom: 0;
}
.up-l{
    top: 0;
}
.up-r{
    left: auto !important;
    right: 0;
    top: 0;
    border-radius: 0 0 0 100%;
    border-right: none;
}
.down-r{
    left: auto !important;
    right:0;
    border-radius: 100% 0 0 0 !important;
    border-top: 1px solid #DDD4E2 !important;
    bottom: 0;
    border-bottom: none !important;
    border-right: none !important;
}

.progressSec{
    border-right: 1px dashed #DDD4E2;
    min-width: 200px;
    height: 100%;
}
.infoSec{
  flex: 1;
  justify-content: space-between;
  margin: 0 50px;
  h2{
    color: #43225B;
    font-size: 16px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.25px;
  }
  p{
    color: #43225B;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.24px;
  }
>span{
      width: 500px ;
}
.checkout-text{
    width: 130px;
    border:1px dashed;
    padding:10px 5px
}
}
.quarter-circle {
  width: 100px;
  height: 100px;
  border-radius: 100px 0 0 0;
  background-color: #f00;
}

.bottom-right {
  transform: rotate(45deg);
}

.top-right {
  transform: rotate(-45deg);
}
/* Responsive */
@media only screen and (max-width: 1335px){
  width:auto;
}
@media only screen and (max-width: 1000px){
.progressSec{}
.infoSec{
  flex-direction:column !important;
  align-items:flex-start !important;
  justify-content:center;
  margin: 0 25px;
  >span{
    width:auto
  }
  >button{
    padding:10px;
    font-size: 14px;
  }
}
}
@media only screen and (max-width: 730px){
  width:auto;
  .innerDiv{
    .infoSec{
      padding:10px
    }
  }
}

${props=>props.isAvailable && css`
@media only screen and (max-width: 700px){
  position: initial;
  margin-top:10px;
  .innerDiv{
    height:auto;
    flex-direction:column;
    .progressSec{
      border:none;
      border-bottom:1px dashed #DDD4E2;
      min-height:150px;
      max-height:150px;
      position: relative;
    }
    .infoSec{
      padding:10px
    }
    .circle{
      display:none;
      left:0;
      border-radius:0 10rem 10rem 0;
      width: 20px;
      height: 32px;
    }
    .up-m-l{
      display: block !important;
      left:-1px;
      right: 0;
      border-top: 1px solid #DDD4E2;
      border-left: none;
      top:91%;
      bottom:0;
    }
    .down-m-l{
      display: block !important;
      border-top: 1px solid #DDD4E2;
      border-left: none;
      right:-1px;
      left: auto;
      top:91%;
      bottom:0;
      transform:rotate(180deg);
    }
  }
}`}

/* FOR REDEEM AND USED SECTION */
${props=>!props.isAvailable && css`

.circle{
    left: -4px;
    border-radius: 0 0 0 100%;
    width: 20px;
    height: 16px;
    background: #fff;
    z-index: 1;
}
.up-l{
    border-radius: 0 0 100% 0;
    border-left: none;
    left:-0.1px;
    top:0.1px;
}
.down-l{
    border-right: none;
    left: 0px;
}
.progressSec{
    display: none !important;
}
`}

/* FOR CART SECTION */
${props=>props.isCartView && css`
width: auto;
.innerDiv{
  max-width:none;
  width:auto;
  height: auto;
  padding: 30px 0;
}
.infoSec{
  margin: 0 15px;
  .infoSpan{
    width: auto;
  }
}

`}
`
export const ReferralButton = styled.button`
padding: 10px 15px;
border-radius: 5px;
background:${({theme:{branding:{primaryCta}}}) => primaryCta.bg};
color:${({theme:{branding:{primaryCta}}}) => primaryCta.color};
font-size:${({theme:{branding:{primaryCta}}}) => primaryCta.fontSize};
border: none;
color:#fff;
width:200px;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
&:hover{
  opacity: 0.8;
}
${props => props.disabled && css`
  opacity: 0.2;
  cursor: not-allowed;
  &:hover{
    opacity: 0.2;
  }
`}
` 
export const RedeemChip = styled.button`
    display: flex;
    padding: 5px 10px;
    align-items: flex-start;
    border-radius: 100px;
    background: #EEE9F4;
    color: #000;
    border: none;
    ${props => props.active && css`
        background: #43225B;
        color: #fff;
    `}
`
export const RewardsChip = styled.button`
    display: flex;
    font-size: 20px;
    font-weight: 400;
    padding: 12px 24px;
    align-items: flex-start;
    border-radius: 100px;
    background: none;
    color: #6F6F6F;
    border: 1px solid #637381;
    ${props => props.active && css`
      background: #EDE4FF;
      border: 1px solid #3B108E;
      color: #3A3A3A;
      font-weight: 700;
    `}
`
export const RedeemView = styled.div`
.no-voucher-view{
    text-align: center;
    padding: 70px 0;
    color: #001219;
    font-size: 20px;
    font-weight: 700;
    line-height: 125%;
}
`
export const EarnView = styled.div`
.earn-view{
  display: flex;
  padding: 17px 0 17px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEDF0;
  .box-1{
    max-width: 850px;
  }
  .info-block{
    h2{
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.25px;
      color: #001219;
      margin-bottom: 10px !important;
    }
    p{
      font-weight: 400;
      font-size: 12px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #637381;
    }
  }
  .box-2{
    display: flex;
  }
  .points-block{
    p{
      font-size: 12px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #001219;
      min-width: 25px;
    }
  }

@media only screen and (max-width: 560px){
  flex-direction:column;
  align-items:flex-start;
    .box-2{
      margin-top:5px;
    .cta{
      width:120px;
      height:26px;
      font-size:12px !important;
    }
  }

}
}

>div:last-child{
  border-bottom:none;
}
`