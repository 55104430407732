import React,{memo, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import gift from '../../../assets/gift.svg'
import b2baside from '../../../assets/b2b-aside-gift.png'
import teamaside from '../../../assets/pure-teams/sidebar.jpg'
import upgradeside from '../../../assets/upgradeside.png'
import fpside from '../../../assets/BA-629-fp-aside-banner.png'
import { RightSideBar } from '../../../styles/dashboard/Aside'
import { sendEvents } from "../../../tools/mpEvents"
import { checkEvenOdd } from '../../../tools/tools'
import { useTranslation } from 'react-i18next';
import { constants } from '../../../tools/constants'
import campaign from '../../../assets/fp_campaign.png'

const Aside = ({history, type, refer, onBoardingReducer , setDomeModal}) => {

    const token = localStorage.getItem("token");
    
    const { t } = useTranslation();

    const toggleModal = () => setDomeModal(true)

    const aside = localStorage.getItem("aside") ?? 'fp'

    const RandomNum = localStorage.getItem("RandomNum")

    const [isB2Baside, setIsB2Baside] = useState(false)

    const [showAsideBar, setShowAsideBar] = useState(false)
    
    const [asideTemplate, setAsideTemplate] = useState("refer")
    const [link, setLink] = useState(checkEvenOdd);

    const asideCTAClickHandle = (e) => {
        let type = data[asideTemplate]
        let para = data[asideTemplate]?.para
        if (token) {
            sendEvents({
                event_name:"ma_click_side_banner",
                event_properties:{
                    cta: e.target.innerHTML,
                    type: asideTemplate ,
                    para: para
                }
            }, token);
        }

        if(asideTemplate == constants.b2b){
           window.open(link, '_blank');
        }
    }

    const [data, setData] = useState({
        refer: {
            img: gift,
            heading: "aside_refer",
            para: "aside_refer_para",
            cta:"aside_refer_cta",
            linkTo:"/dashboard/refer"
        },
        upgrade: {
            img: upgradeside,
            heading: "aside_upgrade",
            para: "aside_upgrade_para",
            cta:"aside_upgrade_cta",
            linkTo:"/dashboard/upgrade"
        },
        b2b: {
            img: b2baside,
            heading: "aside_b2b",
            para: "aside_b2b_para",
            cta:"aside_b2b_cta",
            linkTo:{toggleModal}  
        },
        fp: {
            img: fpside,
            heading: "aside_familyplan",
            para: "aside_familyplan_para",
            cta:"aside_familyplan_cta",
            linkTo:"/dashboard/family-plan"  
        },
        teams: {
            img: teamaside,
            heading: "aside_teams",
            para: "aside_teams_para",
            cta:"aside_teams_cta",
            linkTo:"/dashboard/teams"  
        },
        loading:false,
        onMobile:/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    });

    useEffect(() => {
        if (onBoardingReducer?.subscriptions) {

            if (RandomNum <= 50) {
                setAsideTemplate("teams")
                setShowAsideBar(true)
            } else {
                setAsideTemplate(aside)
                setShowAsideBar(true)
            }            
        }

    }, [onBoardingReducer,type])

    return (
        (!data.onMobile) && showAsideBar && (
            <>
            
                <RightSideBar className="text-center" isB2Baside={isB2Baside} loading={data.loading.toString()}>
                    <>
                        <div className="loader">
                            <span className="spinner-border"></span>
                            <p className="mb-0 ms-2">{t('aside_loading')}</p>
                        </div>
                        <img src={data[asideTemplate].img} className="img-fluid" height={88}/>
                        <h4>{t(data[asideTemplate].heading)}</h4>
                        <p>{t(data[asideTemplate].para)}</p>
                        {data[asideTemplate].cta==="Book Demo" ? <button onClick={(e) => { asideCTAClickHandle(e);}}>{t('aside_b2b_cta')}</button>:<Link onClick={asideCTAClickHandle} className="d-block" to={data[asideTemplate].linkTo}>{t(data[asideTemplate].cta)}</Link>}
                    </>
                </RightSideBar>

            </>
        )

    )
}

const mapStateToProps = (state) => {
    return {
        refer: state.referReducer,
        onBoardingReducer: state.onBoardingReducer,
     };
};

export default connect(mapStateToProps, null)(memo(withRouter(Aside)))