import { InputSliderStyles } from "../styles/Generic";
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';


const InputSlider = ({value, setValue, minAccountValue, maxAccountValue}) => { 
        
    return <InputSliderStyles>

    <RangeSlider
        value={value}
        onChange={(e)=> e.target.value >= minAccountValue && setValue(e.target.value)}
        min={0}
        max={maxAccountValue}
        step={1}
        tooltip='on'
        tooltipPlacement='top'
    />
    </InputSliderStyles>
}
export default InputSlider;