import { combineReducers } from "redux";
import { accountReducer } from "./account/reducer";
import { upgradeReducer } from "./upgrade/reducer";
import { subscriptionsReducer } from "./subscriptions/reducer";
import { invoicesReducer } from "./invoices/reducer";
import { devicesReducer } from "./downloads/reducer"
import { ticketsReducer } from "./tickets/reducer"
import { referReducer } from "./refer/reducer";
import { onBoardingReducer } from "./on-boarding/reducer";
import { helloBarReducer } from "./hello-bar/reducer";
import { familyplanReducer } from "./family-plan/reducer";
import { channelsReducer } from "./channels/reducer";
import { removeMyDataReducer } from "./rmd/reducer";
import { pureTeamsReducer } from "./pureteams/reducer";

import { pureAIReducer } from "./pureai/reducer";
import country from "./country/reducer";
import wgDevices from "./wgDevices/reducer";
import walletDataReducer from './pure-wallet/reducer';
export default combineReducers({
    accountReducer,
    onBoardingReducer,
    helloBarReducer,
    subscriptionsReducer,
    invoicesReducer,
    devicesReducer,
    ticketsReducer,
    upgradeReducer,
    referReducer,
    channelsReducer,
    familyplanReducer,
    pureAIReducer,
    country,
    wgDevices,
    walletDataReducer,
    removeMyDataReducer,
    pureTeamsReducer
});