import React from 'react'
import { InfoCard, SubscriptionCancelWrapper } from '../../../../../styles/dashboard/Main'

const ErrorMsg = ({errorMsg=""}) => {
  return (
    <SubscriptionCancelWrapper coloredVal>
        <InfoCard className="d-block py-0 pt-2">
            <div className="warning-box">
                <span className="warning-icon"></span>
                <p className="warning-text">
                    {errorMsg}
                </p>
            </div>
        </InfoCard>
    </SubscriptionCancelWrapper>
  )
}

export default ErrorMsg