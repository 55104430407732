import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher, getFirebase } from '../../tools/tools';
import { errorsType, getFamilyPlanType, loadingType } from './actions';
import i18n from "i18next";
import qs from 'qs';

function* familyPlanAsync(action){
    
    const cancelTokenSource = axios.CancelToken.source();
    
    const {token, data} = action.payload;
    yield put(loadingType())
    try{
        const formdata = qs.stringify(data);
        const familyPlan = yield poster("familyplan/get-family-plans", formdata, token, cancelTokenSource)
        const {data: response} = familyPlan;
        if(response?.status){
            yield put(getFamilyPlanType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchFamilyPlanRequest(){
    yield takeLatest('GETFAMPLANREQUEST', familyPlanAsync)
}