import React, {useState, lazy, useEffect, Suspense} from 'react'
import { AutoLoginWrapper, PrimaryBtn, SecHead } from '../styles/Generic'
import { LoginWrapper, LoginBody, LoginForm, LoginInput } from '../styles/login/LoginStyle' 
import loginlogo from '../assets/loginlogo.png'
import { useLocation } from 'react-router-dom'
import { api } from '../tools/tools'
import qs from 'qs'
import { toast } from 'react-toastify'
import { APIFAILED, CAPTCHAVALIDATION, INVALIDTOKEN } from '../tools/responseMessages'
import { fireGAEvent } from '../tools/gaEvents'
import { sendEvents } from '../tools/mpEvents'
import { useTranslation } from 'react-i18next';

const V3Recaptcha = lazy(()=>import(/* webpackChunkName: "v3Recaptcha" */"../tools/v3Recaptcha"))
const V2Recaptcha = lazy(()=>import(/* webpackChunkName: "v2Recaptcha" */"../tools/v2Recaptcha"))

const ResetPassword = ({history}) => {

    const [resetCaptcha, setresetCaptcha] = useState(false)

    const [v3regenerate, setv3regenerate] = useState(false)

    const handleVerify = (token) => {
        if(token){
            localStorage.setItem("resetToken", token)
            if(data.captchaType === "v2"){
                setErrors({
                    ...errors, token:false
                })
            }
        }else{
            localStorage.setItem("resetToken", "")
        }
    }

    const { search } = useLocation();
    const { t, i18n } = useTranslation();

    const token = new URLSearchParams(search).get('token'); 

    const [data, setData] = useState({
        password_key:"",
        password:"",
        password_confirmation: "",
        email: "",
        token:"",
        captchaType:"v3"
    })

    useEffect(() => {
        fireGAEvent("memberAreaPasswordResetKeyProcessStart", "email")
        async function apifunc(){
            if(!token){
                toast.error("Invalid token.")
                history.push("/forgot-password")
                return;
            }
            try {
                const formdata = qs.stringify({
                    "verify-token": token
                });
                const login = await api("auth/forget-password-verify", formdata);
                const {data:{status}} = login;
            if(status){
                const {data:{body}} = login;
                setData({...data, password_key:body['password-key'], email:body["email"]});
            }else{
                fireGAEvent("memberAreaPasswordResetKeyValidationError", "Invalid token provided.")
                toast.error(t(INVALIDTOKEN));
                history.push("/forgot-password")
            }
            }catch (error) {
                fireGAEvent("memberAreaPasswordResetKeyValidationError", "API break or Server is not responding.")
                toast.error(t(APIFAILED));
                history.push("/forgot-password")
            }
        }
        apifunc()
        return () => {
            localStorage.removeItem("resetToken")
        }
    }, [])

    const [errors, setErrors] = useState({
        password_key:false,
        password:false,
        password_confirmation:false,
        passwordMatch:false,
        token:false,
        captchaType:false
    })

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const handleBlur = (e) => {
        setErrors({...errors, [e.target.name]: e.target.value ? false : true, passwordMatch: (data.password && data.password_confirmation !== "") && data.password !== data.password_confirmation})
    }

    const captchaReset = () => {
        if(data.captchaType === "v2"){
            setresetCaptcha(true)
        }else{
            setv3regenerate(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let err = {password:false, password_confirmation:false, token:false, captchaType:false}
        for (const key in data) {
            key === 'token' ? 
            err[key] = localStorage.getItem("resetToken") ? false : true 
            :
            err[key] = data[key] ? false : true
        }
        if(err.password || err.password_confirmation || err.token){
            setErrors({...errors, ...err})
            setLoading(false);
            return;
        }
        if(errors.passwordMatch === true){
            setLoading(false);
            return;
        }
        setErrors(err)
        if(data.captchaType === "v3"){
            const token = await window.grecaptcha.execute(process.env.REACT_APP_V3_SITE_KEY, { action: "reset_password" })
            localStorage.setItem("resetToken", token);
        }
        try {
            fireGAEvent("memberAreaChangePasswordFormSubmit", "Success");
            const formdata = qs.stringify({
                "password_key": data.password_key,
                "password":data.password,
                "password_confirmation":data.password_confirmation,
                "email": data.email,
                "type": "reset",
                "token": localStorage.getItem("resetToken") ?? "",
                "captcha-type":data.captchaType
            });
            const forgot = await api("auth/reset-password", formdata);
            const {data: response} = forgot;
            if(response?.status){
                toast.success("Password reset successfully.")
                history.push("/login")
            } 
            else{
                switch (response?.error_code) {
                    case "captcha_error":
                        setData({...data, captchaType:"v2"})
                        localStorage.setItem("resetToken", "")
                        toast.error(CAPTCHAVALIDATION);
                        captchaReset()
                        break;
                    case "account_exception":
                        toast.error(response?.message);
                        captchaReset()
                        break; 
                    case "validation_exception":
                        response?.errors?.password.map(e=>toast.error(e))
                        captchaReset()
                        break; 
                    default:
                        toast.error(t(APIFAILED));
                        break;
                } 
                fireGAEvent("memberAreaChangePasswordFormError", response?.message || "Something went wrong")
            }
        } 
        catch (error) {
            fireGAEvent("memberAreaChangePasswordFormError", "API break or server is not responding")
            toast.error('Something went wrong please try again!');
            captchaReset()
        }
        finally{
            //setData({email:""})
            setLoading(false);
        }
        
    }

    const [loading, setLoading] = useState(false)

    const ButtonText = !loading ? 'Submit' : ( <>Submitting <span className="spinner-border text-light"></span></>);

    if(data.password_key === ""){
        return (
            <AutoLoginWrapper> 
                <div className="text-center">
                    <img src={loginlogo} className="img-fluid" />
                    <h1>PUREVPN</h1>
                    <p className="mt-5 mb-3">Please wait, it just take a second or two.</p>
                    <span className="spinner-border"></span>
                </div>
            </AutoLoginWrapper>
        )
    }

    return (
        <LoginWrapper>
            <LoginBody>
                <SecHead className="text-center">
                    <img src={loginlogo} className="img-responsive" alt="PureVPN Logo"/>
                    <h1>Reset Password</h1>
                    <p>A few clicks away from customizing your VPN user experience.</p>
                </SecHead>
                <LoginForm autoComplete={0}>
                    <div className="form-group">
                        <LoginInput validation={errors.password} autoComplete="off" password type="password" name="password" value={data.password} onChange={handleChange} onBlur={handleBlur} placeholder="Password" className="form-control"/>
                        {
                            errors.password && <p className="danger smallerBody mt-2 mb-3">Password is required.</p>
                        }
                    </div>
                    <div className="form-group">
                        <LoginInput validation={errors.password_confirmation} autoComplete="off" password type="password" name="password_confirmation" value={data.password_confirmation} onChange={handleChange} onBlur={handleBlur} placeholder="Confirm Password" className="form-control"/>
                        {
                            errors.password_confirmation && <p className="danger smallerBody mt-2 mb-3">Confirm password is required.</p>
                        }
                        {
                            errors.passwordMatch && <p className="danger smallerBody mt-2 mb-3">Password does not match.</p>
                        }
                    </div>
                    <div className="mt-2">
                        {
                            data.captchaType === "v3"
                            ?
                                <Suspense fallback="">
                                    <V3Recaptcha data={data} v3regenerate={v3regenerate} setv3regenerate={setv3regenerate} handleVerify={handleVerify} errors={errors.token}></V3Recaptcha>
                                </Suspense>
                            :
                                <Suspense fallback={<div className="text-center p-2"><span className="spinner-border"></span></div>}>
                                    <V2Recaptcha setresetCaptcha={setresetCaptcha} reset={resetCaptcha} handleVerify={handleVerify} errors={errors.token}></V2Recaptcha>
                                </Suspense>
                        }
                    </div>
                    <div className="text-center mt-4">
                        <PrimaryBtn onClick={handleSubmit}>{ButtonText}</PrimaryBtn>
                    </div>
                </LoginForm>
            </LoginBody>

        </LoginWrapper>

        

    )
}

export default ResetPassword