import { featureContent } from "../content/index";
import { FeatureIntroStyles } from "../../../styles/dashboard/security-tools/securityTools";
import { useTranslation } from "react-i18next";

const FeatureIntro = ({slug, noimg}) => {

    const { t } = useTranslation();

    return <FeatureIntroStyles>
        <h3>{t(featureContent[slug]?.heading)}</h3>
        <h6>{t(featureContent[slug]?.paragraph)}</h6>
        {featureContent[slug]?.image && !noimg && <img className="intro-img" src={featureContent[slug]?.image} alt={slug} />}
        
    </FeatureIntroStyles>
}
export default FeatureIntro;