import { ERRORS, GETONBOARDING, SETONBOARDINGDATA, SETONBOARDINGHISTORY, UPDATESELECTEDSUBSCRIPTION, NEXTONBOARDING, LOADING,RESETONBOARDINGDATA, UPDATELOCALEONBOARDINGDATA,UPDATETOFETCH } from "./types";
import { poster } from '../../tools/tools';
import { getSubscriptionsType, setSubscriptionType } from '../subscriptions/actions';
import qs from 'qs'
import { frenchOnboarding, onBoardingMultiLang } from "../../assets/lang/onboarding_lang";

const initialState = {
    onBoardings: [],
    accountDetails: [],
    histories: [],
    subscriptions: null,
    devices: null,
    selectedSubs: null,
    data: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:"",
    nextOnBoardingApp: null,
    toFetch:false,
    is_billing_information:false
}

export const onBoardingReducer = (state=initialState, action) => {
    const {type, payload } = action

    let locale = payload?.locale ? ( payload?.locale in onBoardingMultiLang ?  payload?.locale : "en") : "en";
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETONBOARDING:
            return  {
                ...state, 
                onBoardings:locale != "en" ? onBoardingMultiLang[locale] : payload.body.on_boardings,
                onBoardings_en:payload.body.on_boardings,
                accountDetails:payload.body.account_details, 
                histories:payload.body.histories,
                subscriptions:payload.body.subscriptions, 
                is_billing_information:payload.body.is_billing_information,
                devices:payload.body.devices, 
                loading:false, errors:false
            }
            break;
        case UPDATESELECTEDSUBSCRIPTION:
            return  {
                ...state, 
                selectedSubs:payload
            }
            break;
        case UPDATETOFETCH:
            return  {
                ...state, 
                toFetch:payload
            }
            break;
        case SETONBOARDINGDATA:
            return  {
                ...state, 
                onBoardings:locale != "en" ? onBoardingMultiLang[locale] : payload.body.on_boardings,
                onBoardings_en:payload.body.on_boardings,
                accountDetails:payload.body.account_details, 
                histories:payload.body.histories,
                is_billing_information:payload.body.is_billing_information,
                loading:false, 
                errors:false
            }
            break;
        case UPDATELOCALEONBOARDINGDATA:
            let onboardingLang = action?.locale == 'en' ? state?.onBoardings_en : onBoardingMultiLang[action?.locale]
            return  {
                ...state, 
                onBoardings: onboardingLang 
                
            }
            break;
        case NEXTONBOARDING:
            return  {
                ...state,
                nextOnBoardingApp: payload
            }
            break;
        case SETONBOARDINGHISTORY:

            const { appSlug, subscription_id, on_boarding_id, step_id, sub_step_id, stepNames, isLocked } = payload;

            if (subscription_id && appSlug) {
                let addNew = true;

                let data = JSON.parse(localStorage.getItem('userOnboardingHistory'));
                let userOnboardingHistory = (data && data.length) ? data : [];
                let cSteps = [];
                let activation = 0;
    
                if (userOnboardingHistory && userOnboardingHistory.length > 0) {
                    userOnboardingHistory.map((his, idx) => {
                        if (his.subscription_id == subscription_id && his.slug == appSlug) {
                            cSteps = (userOnboardingHistory[idx].completedSteps) ? userOnboardingHistory[idx].completedSteps : [];
                            userOnboardingHistory[idx].step_id = step_id;
                            userOnboardingHistory[idx].sub_step_id = sub_step_id;
                            if(stepNames.length > 0 && stepNames[step_id] && !cSteps.includes(stepNames[step_id])) {
                                cSteps.push(stepNames[step_id]);
                            }
                            userOnboardingHistory[idx].completedSteps = cSteps;

                            // Check if activation is done based on all steps completed.
                            if (userOnboardingHistory[idx]?.activation != 1) {
                                userOnboardingHistory[idx].activation = activationCheck(stepNames, cSteps, isLocked);
                            }

                            addNew = false;
                        }
                    })
                }

                if (addNew) {
                    if (stepNames.length > 0 && stepNames[step_id]) {
                        cSteps.push(stepNames[step_id]);
                    }
                    userOnboardingHistory.push({
                        subscription_id: subscription_id,
                        slug: appSlug,
                        step_id: step_id,
                        sub_step_id: sub_step_id,
                        completedSteps: cSteps,
                        activation: activation,
                    })
                }

                localStorage.setItem('userOnboardingHistory', JSON.stringify(userOnboardingHistory));
            }

            return  {...state }
            break;
        case RESETONBOARDINGDATA:
            return initialState
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}

const activationCheck = (allSteps, completedSteps, isLocked) => {

    let completedStep = false;

    if (!isLocked) {
        const index = allSteps.indexOf('signup');
        if (index > -1) { // only splice array when item is found
            allSteps.splice(index, 1); // 2nd parameter means remove one item only
        }
    }

    for (let i = 0; i < allSteps.length; i++) {
        if (!completedSteps.includes(allSteps[i])) {
            completedStep = false;  
            return;
        } else {
            completedStep = true;
        }
    }

    return (completedStep) ? 1 : 0;

}