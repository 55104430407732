import React, { useState, useEffect } from 'react'
import {  CardNumber, CredentialsInput, DashboardHead, InfoCard, SubscriptionBox, PopupBody, OrderSummary, SubscriptionWrapper, PackageBox } from '../../../../styles/dashboard/Main'
import { DestructBtn, InsetBtn, LinkBtn, PrimaryBtn, RLink } from '../../../../styles/Generic'
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import SubmitConsent from '../../subscriptions/SubmitConsent';
import { sendEvents } from '../../../../tools/mpEvents';
import classNames from 'classnames'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import {dateFormat, get, getByParam ,  poster} from '../../../../tools/tools'
import { toast } from 'react-toastify';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../../tools/responseMessages';
import qs from 'qs'
import {  ThreeDSecureAction } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next'

const AppPurchaseUpgrade = ({history}) => {
    const { search } = useLocation();

    const {subscription_id} = Object.fromEntries(new URLSearchParams(search));
    
    const [upgradeSubscription, setUpgradeSubscription] = useState({
        subscription: subscription_id ?? false
    })

    const dispatch = useDispatch()

    const [orderSummary, setorderSummary] = useState(true)

    const [selected, setSelected] = useState({
        subscription_id: subscription_id ?? false,
        plan_code: false
    })
    
    const [stateSubs, setstateSubs] = useState({})

    const { t, i18n } = useTranslation();

    const [loading, setloading] = useState(false)

    const [planSelectedForPurchase, setplanSelectedForPurchase] = useState(false)

    const [threedSecure, setthreedSecure] = useState({
        enabled:false,
        three_d_secure_action_token_id: "",
        oldToken:""
    })

    const currencies = {
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "$",
        CAD: "$",
        NOK: "kr"
    }

    const [selectedPlanData, setSelectedPlanData] = useState({
        name:"",
        price:0,
        code:""
    })
    

    const [isUpgradedMessage, setisUpgradedMessage] = useState(false)

    const token = localStorage.getItem("token")

    const [invoiceData, setinvoiceData] = useState({
        orderSummary:{}
    })

    const [data, setData] = useState({
        response:null,
        loading:true
    })

    
    useEffect(() => {
        
        if(upgradeSubscription.subscription){
            
            async function apifunc(){
                
                const {data: plans} = await get("plans", token)

                const {data:subscriptionData} = await getByParam(`subscription/${upgradeSubscription.subscription}`, "", token)

                Promise.allSettled([plans, subscriptionData])
                .then(response => {
                    const success = response.every(res=>res?.value?.status === true)
                    
                    if(success){
                        
                        setstateSubs(subscriptionData?.body, plans?.body);

                        setSelected({
                            subscription_id: subscriptionData?.body?.id,
                            plan_code: subscriptionData?.body?.plan?.code
                        })
                        
                        setinvoiceData({
                            orderSummary:subscriptionData?.body ?? {}
                        }) 
                        setplanSelectedForPurchase(false)

                        subscriptionData?.body?.payment_gateway_offline ? setisUpgradedMessage(`We're sorry to inform you that currently you can't upgrade your plan since this option is not supported on the ${subscriptionData?.body?.payment_gateway}.`) : setisUpgradedMessage(false);

                        setData({
                            response:plans?.body,
                            loading:false
                        })
                    }else{

                        if(response?.some(res=>res?.value?.error_code === "token_expired")){
                            setisUpgradedMessage(TOKENEXPIRED);

                        }else if(response?.some(res=>res?.value?.error_code === "token_invalid")){
                            setisUpgradedMessage(t(INVALIDTOKEN))

                        }else if(response?.some(res=>res?.value?.error_code === "jwt_default_exception")){
                            setisUpgradedMessage(t(INVALIDTOKEN))
                            
                        }else if(response?.some(res=>res?.value?.error_code === "plan_exception")){
                            response?.some(res=>res?.value?.error_code === "plan_exception" ? setisUpgradedMessage(res?.value?.message) : '');

                        }else if(response?.some(res=>res?.value?.error_code === "recurly_exception")){
                            response?.some(res=>res?.value?.error_code === "recurly_exception" ? setisUpgradedMessage(res?.value?.message) : '');

                        }else{
                            setisUpgradedMessage(t(APIFAILED));
                        }
                        setData({
                            loading:false
                        })
                    }
                }).catch(e=>{
                    setisUpgradedMessage(t(APIFAILED))
                })
                
                
            }
            apifunc()
        }else{
            history.push("/dashboard/account");
        }
    }, [])

    const buttonText = (text) => {
        return !loading ? text : ( <> {text} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }
    
    const appEventHandler = (response) => {
        
        let _nativeHandler = () => window.chrome ? window.chrome.webview : (window.webkit ?? {messageHandlers: {}}).messageHandlers.upgradeEventHandler;

        _nativeHandler() && _nativeHandler().postMessage({
            response: response
        });
    }

    const showOrderSummary = () => {
        if(selected.plan_code === stateSubs.plan.code){
            toast.error("You are already on this plan.")
            return;
        }
        
        sendEvents({
            event_name:"ma_select_plan_upgradesubscription",
            event_properties:{
                selectedplan: selectedPlanData?.name ?? "N/A"
            }
        }, token)
        setplanSelectedForPurchase(true)
    }

    const changePlanAsync = async (data) => {
        let threedSecure = false;
        sendEvents({
            event_name:"ma_click_upgrade_checkout",
            event_properties:{
                selectedplan: selectedPlanData?.name ?? "N/A",
            }
        }, token)
        try {
            const formdata = qs.stringify(data)
            const change =  await poster("subscription/change", formdata, token)
            const {data: response} = change;
            if(response?.status){
                setisUpgradedMessage(`Your plan has been upgraded to ${response?.body?.plan?.interval_length} Months`)
                
                sendEvents({
                    event_name:"ma_upgradesubscription",
                    event_properties:{
                        selectedplan: selectedPlanData?.event_name ?? "N/A",
                        payment_method:response?.body?.billing_info?.last_four ? "stripe" : "Paypal",
                        billing_cycle:response?.body?.plan?.type
                    }
                }, token)
                let AppEventsData = {
                    type: "uppgrade",
                    message: `Your plan has been upgraded to ${response?.body?.plan?.interval_length} Months`,
                    data:{
                        isPurchasedSucessFully : true
                    }
                }
                appEventHandler(AppEventsData)

            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        setisUpgradedMessage(TOKENEXPIRED);   
                        break;
                    case "token_invalid":
                        setisUpgradedMessage(t(INVALIDTOKEN))   
                        break; 
                    case "declined":
                        setisUpgradedMessage(response?.message)
                        break;  
                    case "recurly_exception":
                        setisUpgradedMessage(response?.message)
                        break;
                    case "subscription_exception":
                        setisUpgradedMessage(response?.message)
                        break;    
                    case "jwt_default_exception":
                        setisUpgradedMessage(t(INVALIDTOKEN))   
                        break;
                    case "three_d_secure_action_required":
                        setthreedSecure({
                            three_d_secure_action_token_id:response?.errors?.three_d_secure_action_token_id,
                            enabled:true,
                            oldToken:""
                        })
                        threedSecure = true;
                        break;      
                    default:
                        setisUpgradedMessage(response?.message);
                        break;
                }
                let AppEventsData = {
                    type: "uppgrade",
                    message: response?.message ?? "N/A",
                    data:{
                        isPurchasedSucessFully : false
                    }
                }
                appEventHandler(AppEventsData)

                setplanSelectedForPurchase(false)
                sendEvents({
                    event_name:"ma_fail_upgradesubscription",
                    event_properties:{
                        reason: response?.message ?? "N/A"
                    }
                }, token)
            }
        } catch (error) {
            setisUpgradedMessage(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_upgradesubscription",
                event_properties:{
                    reason: "API break or Server is not responding."
                }
            }, token)
        }
        finally{
            if(!threedSecure){
                setData({...data, loading:false})
            }
        }
    }

    const changePlans = async (e) => {
        // if(selected.plan_code === selectedSubscription.plan){
        //     toast.error("You are already on this plan.")
        //     return;
        // }
        setloading(true)
        
        changePlanAsync(selected)
    }
    const showPlan = (data) => {
        
        return data?.response?.map((plan, key)=>{
            // showPlan = plan?.code === selectedSubscription.plan ? showPlan += 1 : showPlan
            if(plan?.interval_length >= stateSubs?.plan.interval_length){
                if(plan?.interval_length === stateSubs?.plan.interval_length){
                    return stateSubs?.plan
                }else{
                    return plan
                }
            }else{
                return null
            }
        }).filter(plan => plan !== null)
    }

    const showAddonPrice = (data) => {
        const getAddon = data.currencies.find(price => price.currency === stateSubs.currency);
        return getAddon.price
    } 

    const totalPrice = () => {
        let addonPrice = 0;
        invoiceData.orderSummary.add_ons.forEach(currentAddon=>{
            data?.response.find(plan=>plan.code === selected.plan_code).add_ons.forEach(addon => {
                if(currentAddon.name === addon.name){
                    addonPrice+=showAddonPrice(addon)
                }
            })
        })
        const total = selectedPlanData.price + addonPrice;
        return total.toFixed(2)
    }
    const planPrice = (plan) => {
        const priceIndex = plan?.currencies.findIndex(curr=> curr.currency === stateSubs.currency)
        return plan?.currencies[priceIndex].price
    }

    const handle3dToken = (token) => {
        if(token){
            const formdata = {
                subscription_id:selected.subscription_id,
                plan_code:selected.plan_code,
                threed_secure_id: token.id
            }
            changePlanAsync(formdata)
        }else{
            setisUpgradedMessage("Something went wrong!!!")
            setData({...data, loading:false})
        }
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        setisUpgradedMessage(error?.message || "Something went wrong!!!")
        setData({...data, loading:false})
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    return (
        <div>
            {
                !data.loading 
                ?
                    <>
                        {
                            !isUpgradedMessage?
                            <>
                                <ModalBody>
                                    {
                                        !planSelectedForPurchase
                                        ?
                                        <PopupBody>
                                            <div className="mod-head">
                                                <h3>Upgrade your plan</h3>
                                            </div>
                                            <div className="mod-body">
                                                {
                                                    showPlan(data).map((plan, key) => 
                                                    (
                                                        <PackageBox className='appPkgBox' key={key} onClick={e => {setSelected({...selected, plan_code:plan?.code});setSelectedPlanData({name: plan?.name, price:planPrice(plan), code:plan?.code, interval_length:plan?.interval_length})}} selected={selected.plan_code === plan?.code}>
                                                            <div className="float-start">
                                                                <span className="text-capitalize">
                                                                    {`${plan?.interval_length} ${plan?.interval_length === 1 ? "Month" : "Months"}`} {
                                                                        plan?.code === stateSubs.plan.code && <small>(Current Plan)</small>   
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="float-end">
                                                                <span>{currencies[stateSubs.currency] || "$"} {planPrice(plan)}</span>
                                                            </div>
                                                        </PackageBox>
                                                    )
                                                    )
                                                } 
                                            </div>
                                        </PopupBody>
                                        :
                                        <PopupBody>
                                            <div className="mod-head">
                                                <h3>Upgrade your plan</h3>
                                            </div>
                                            <div className="mod-body">
                                                <InfoCard hasborder noflex className="pt-0">
                                                    <label>Selected plan:</label>
                                                    <div className="d-flex justify-content-between selected-plan mt-2">
                                                        <span>{`${selectedPlanData?.interval_length} ${selectedPlanData?.interval_length === 1 ? "Month" : "Months"}`}</span>
                                                        <strong>{currencies[stateSubs.currency]}{selectedPlanData.price}</strong>
                                                    </div>
                                                    <div className="text-end">
                                                        <LinkBtn onClick={() => setplanSelectedForPurchase(false)}>Change plan</LinkBtn>
                                                    </div>
                                                </InfoCard>
                                                <InfoCard hasborder noflex>
                                                    <OrderSummary open={orderSummary}>
                                                        <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">Order summary</strong></label>
                                                        <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                                                            <li>
                                                                {`${selectedPlanData?.interval_length} ${selectedPlanData?.interval_length === 1 ? "Month" : "Months"} Plan`} <strong className="text-end">{currencies[stateSubs.currency]}{selectedPlanData.price}</strong>
                                                            </li>
                                                            
                                                            {
                                                                
                                                                invoiceData.orderSummary?.add_ons.map(currentAddon=>(
                                                                    data?.response.find(plan=>plan.code === selected.plan_code)?.add_ons.map((addon, key) => (
                                                                        currentAddon.name === addon?.name 
                                                                        ?
                                                                        <li key={key}>
                                                                            {addon.name} <strong className="text-end">{currencies[stateSubs.currency]}{showAddonPrice(addon) || "0"}</strong>
                                                                        </li>
                                                                        :
                                                                        null
                                                                    ))
                                                                ))
                                                            }
                                                        </ul>
                                                    </OrderSummary>
                                                </InfoCard>
                                                <InfoCard hasborder>
                                                    <div className="float-start">
                                                        <label><strong className="ms-0">Total amount</strong> </label>
                                                    </div>
                                                    <div className="float-end">
                                                        <label><strong>{currencies[stateSubs.currency]}{Object.keys(invoiceData.orderSummary).length !== 0 ? totalPrice(invoiceData.orderSummary) : "-"}</strong> </label>
                                                    </div>
                                                    <div className="float-none"></div>
                                                </InfoCard>
                                                <InfoCard>
                                                    <div className="float-start">
                                                        <label>
                                                            <strong className="ms-0 text-bold">
                                                                {
                                                                    invoiceData.orderSummary.billing_info.last_four
                                                                    ?
                                                                    <CardNumber card="up">**** **** **** {invoiceData.orderSummary.billing_info.last_four || "****"}</CardNumber>
                                                                    :
                                                                    <CardNumber card="paypal">Paypal</CardNumber>
                                                                }
                                                            </strong> 
                                                        </label>
                                                    </div>
                                                    {/* <div className="float-end">
                                                        <LinkBtn>Change payment method</LinkBtn>
                                                    </div>
                                                    <div className="float-none"></div> */}
                                                </InfoCard>
                                            </div>
                                        </PopupBody>
                                    }
                                    
                                </ModalBody>
                                <ModalFooter>
                                    {
                                        !planSelectedForPurchase
                                        ?
                                        <PrimaryBtn onClick={showOrderSummary} color="primary">{buttonText("Proceed to checkout")}</PrimaryBtn>
                                        :
                                        <PrimaryBtn onClick={changePlans} pointer={loading} color="primary">{buttonText("Confirm order")}</PrimaryBtn>
                                    }
                                </ModalFooter>
                            </>
                            :
                            <ModalBody>
                                <PopupBody>
                                <div className="mod-head">
                                            <h3>{isUpgradedMessage}</h3>
                                        </div>
                                </PopupBody>
                            </ModalBody>
                        }
                    </>
                
                :
                <ModalBody>
                    <div className="mod-body" style={{minHeight:"200px"}}>
                        {/* <span className="spinner-border"></span> */}
                        <Skeleton className="d-block mb-3" width={300}/>
                        <Skeleton className="d-block mb-3" width={280}/>
                        <Skeleton className="d-block mb-3" width={260}/>
                        <Skeleton className="d-block mb-3" width={240}/>
                        <Skeleton className="d-block mb-3" width={220}/>
                        <Skeleton className="d-block mb-3" width={50}/>
                    </div>
                </ModalBody>

            }
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                actionTokenId={threedSecure.three_d_secure_action_token_id}
                onToken={handle3dToken}
                onError={handle3dError}
                />
            }
        </div>
    )
}

export default AppPurchaseUpgrade;