import { UpgradePInclude, UpgradeOrderConfirm, UpgradeButton, InfoCard } from '../../../styles/dashboard/Main'
import { useTranslation } from 'react-i18next'
import { allowPurchaseForManualGateways, currencySymbol, getPriceByCurrency, getSubPlanTypeToDisplay, isWhmcsUser } from '../../../tools/tools'
import { addons_data, filteredArrayAddon, isMax, isPlus, isStandard } from './upgrade-tools';
import PaymentIcon from '../../../utils/PaymentIcon/PaymentIcon';
import { constants } from '../subscriptions/cancellation';
import { securityToolsPlans } from '../../../tools/constants';

const UpgradeOrderSummary = ({getDiscountedText, hasAddonsArray, selectedPlanInfo, selectedSubscriptionData, changePlans}) => {
    const { t } = useTranslation();

    var upgradeableTotalPrice = [];

    const addonWithCountries = ['dedicated_ip', 'ddos', 'dedicated_server', 'port_forwarding_with_dedicated_ip']

    let price = 0;
    
    selectedPlanInfo?.add_ons?.forEach(addon => {
        if(addon?.code === constants.purevpn) price += getPriceByCurrency(selectedSubscriptionData?.currency, addon?.currencies)
    });
    
    let planPrice = selectedPlanInfo?.currency?.price + price;
    let discountedText = getDiscountedText(selectedPlanInfo, planPrice, selectedPlanInfo?.old_price)

    const getAddonListInOrderSummary = () => {

        let upradeableAddons = [];
        upradeableAddons.push(
            ...removeAddonsBasedOnSubscriptionType(),
            // this is implemented as to cater those users who don't have purevpn binded as an addon to their subscription
            // selectedPlanInfo?.add_ons.find((purchasedAddons) => purchasedAddons?.code === 'purevpn'),
            ...hasAddonsArray.map((addon) => selectedPlanInfo?.add_ons.find(upgradeAddons => upgradeAddons.code === addon))
        )
        return upradeableAddons

    }

    const removeAddonsBasedOnSubscriptionType = () => {
        let addons = selectedPlanInfo?.add_ons?.filter((purchasedAddons)=> selectedSubscriptionData?.add_ons?.find((addon)=> addon?.code === purchasedAddons?.code));
        return addons.filter((addon) => !securityToolsPlans[selectedSubscriptionData?.plan_type].includes(addon?.code))
    }

    const showCardDetails = () => {
        return !(isWhmcsUser(selectedSubscriptionData?.account?.billingType)  || allowPurchaseForManualGateways(selectedSubscriptionData?.payment_gateway))
    }

    const totalPriceForOrderSummary = () => {
        return +(upgradeableTotalPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) + +planPrice
    }

    const getNameOfAddonsOnOrderSummary = (addon) => {
        return <>

            {/* Add name of the country associated with addonWithCountries addons  */}
            {
                addonWithCountries.includes(addon?.code) ? `${selectedSubscriptionData?.add_ons?.find((purchasedAddons)=> purchasedAddons?.code === addon?.code)?.country} ` : null
            }

            {/* Add mapped names of the product addons */}
            {
                addons_data.find((addon_data) => addon_data?.addon_code === addon?.code) ? t(addons_data.find((addon_data) => addon_data?.addon_code === addon?.code)?.name) : addon?.name
            }
        </>
    }

    return <div className="mt-5 mb-5 order-confirm-row">
        <h2 className='d-flex justify-content-center'>{t('addon_purchasemodal_order_summary')}</h2>

        <div className="d-flex justify-content-center order-confirm-row">

            {/* UPGRADE ORDER SUMMARY - START */}
            <UpgradeOrderConfirm className="py-3 px-4 mt-2">
                <div className="orderbox1 mt-1 d-flex justify-content-between">

                    {/* SUMMARY HEADING */}
                    <p className="orderbox-p1">
                         {selectedPlanInfo.name} {t('upgradeplan_at')} {discountedText}
                        <span className="orderbox-p2 p-styled-span d-block">
                            ({t('upgradeplan_plan_expire_after_text')} {selectedPlanInfo.expiry_date})
                        </span>
                    </p>
                    {
                        planPrice > 0 ? (<p className="orderbox-p4">{currencySymbol[selectedSubscriptionData?.currency]}{planPrice?.toFixed(2)}</p>) : null
                    }
                </div>
                {
                    getAddonListInOrderSummary()?.length > 0 ? (
                        <div className="order-confirmation-box d-flex justify-content-between">
                            {/* <p className="orderbox-p3">{t('addon_purchasemodal_order_summary')}</p> */}
                            <div className="plan-items-box mt-2 d-flex">
                                <ul className='plan-items'>
                                    {
                                        getAddonListInOrderSummary()?.map((addon) => {
                                            upgradeableTotalPrice.push(getPriceByCurrency(selectedSubscriptionData?.currency , addon?.currencies));
                                                return (
                                                    <li className='plan-item d-flex align-items-start justify-content-between' key={addon?.code}>
                                                        <div>
                                                            <span className='planName p-styled-span'>
                                                                {getNameOfAddonsOnOrderSummary(addon)}
                                                            </span>
                                                            <span className='planPeriod p-styled-span'>- {selectedPlanInfo?.name.replace('Plan', '')}</span>
                                                            <span className='addonPricePerMonth p-styled-span'>{currencySymbol[selectedSubscriptionData?.currency]}{(getPriceByCurrency(selectedSubscriptionData?.currency , addon?.currencies)/selectedPlanInfo?.interval_length).toFixed(2)}/{t('upgradeplan_month')}</span>
                                                        </div>
                                                        <div>
                                                            <p className="orderbox-p6">
                                                                {currencySymbol[selectedSubscriptionData?.currency]}{getPriceByCurrency(selectedSubscriptionData?.currency , addon?.currencies)?.toFixed(2)}
                                                            </p>
                                                        </div>
                                                    </li>
                                                )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    ) : null
                }

                {/* TOTAL  */}
                {totalPriceForOrderSummary() > 0 && <div className="orderbox3 d-flex justify-content-between">
                    <p className="orderbox-p3">{t('upgradeplan_total_amount')}</p>
                    <p className="orderbox-p5">{currencySymbol[selectedSubscriptionData?.currency]}{totalPriceForOrderSummary()?.toFixed(2)}</p>
                </div>}
                {
                    showCardDetails() && <InfoCard>
                        <PaymentIcon lastFour={selectedSubscriptionData?.billing_info?.last_four} gateway={selectedSubscriptionData?.payment_gateway}/>
                    </InfoCard>
                }
                <UpgradeButton className="mt-4 mb-3 pbox-btn order-summary d-block" onClick={changePlans}>
                    {`${t('upgradeplan_upgradeto_cta_text')} ${selectedPlanInfo.name}`}
                </UpgradeButton>
            </UpgradeOrderConfirm>
            {/* UPGRADE ORDER SUMMARY - END */}


            <div className="mt-2 upgrade-sum">
                <UpgradePInclude className="mx-4">
                    <p className="pinclude-p1">{getSubPlanTypeToDisplay(selectedSubscriptionData?.plan_type)} {t('upgradeplan_vpn_includes')}</p>

                    <div className="mt-2">
                        {!isStandard(selectedSubscriptionData?.plan_type) && <p className="pinclude-p2">{isPlus(selectedSubscriptionData?.plan_type) ? `PureVPN, PureKeep ${t('and_text')} PureEncrypt` : isMax(selectedSubscriptionData?.plan_type) && `PureVPN, PurePrivacy, PureKeep ${t('and_text')} PureEncrypt`}</p>}
                        <p className="pinclude-p2">{t('upgradeplan_vpn_premium_features')}</p>
                        <p className="pinclude-p2">{t('upgradeplan_vpn_updates')}</p>
                        <p className="pinclude-p2">{t('upgradeplan_vpn_multi_login')}</p>
                        <p className="pinclude-p2">{t('upgradeplan_vpn_support')}</p>
                        <p className="pinclude-p2">{t('upgrade_plan_servers')}</p>
                        <p className="pinclude-p3">{t('upgrade_plan_full_servers')}</p>

                    </div>
                </UpgradePInclude>
            </div>
        </div>
    </div>
}
export default UpgradeOrderSummary;