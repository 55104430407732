import { useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '../../../../redux/pureai/reducer';
import { sendMPEvents } from './helper';
import { getTokenFromLocalStorage, poster } from '../../../../tools/tools';
import { pureaiConstants } from '../../../../tools/constants';
import * as Ably from 'ably';
import qs from 'qs';

function AblyConnector({
    pureAIStateReducer,
    setAblyObject,
    setAblyChannel,
    ablyChannel,
    ablyObject,
    evtSource,
    setEvtSource,
  }) {
    
    const dispatch = useDispatch()

    const { previousStates } = pureAIStateReducer;

    const currentState = useSelector((state) => state.pureAIReducer.currentState);

    const errorHandler = (error) => {
        console.log("Error Occurred: ", error)
    }

    const getLastState = () => {
        const lastState = previousStates?.[previousStates.length - 1]
        return lastState
    }

    const calculateResponseTime = (responseTime, promptTime) => {
        return (responseTime - promptTime) / 1000
    }

    const handleStateChange = (state) => {
        const { current, previous, reason } = state;
        if(currentState.prompt !== "" && currentState.action === ACTIONS.SEND_PROMPT && reason?.code){
            dispatch({
                type:ACTIONS.FAIL_LOAD_RESPONSE, 
                response: "Some error occurred while connecting to our system, please try again in a while.", 
                errorType: "Unable to connect to ably", 
                errorDetails: {
                    code: reason.code,
                    reason: reason.message
                }
            })
        }
    }

    const prepareAblyConnection = () => {
        const ably = connectToAbly()
        dispatch({type: ACTIONS.ABLY_CONNECTED, connected: true});
        setAblyObject(ably)
        return ably;
    }

    const connectToAbly = () => {
        const token = getTokenFromLocalStorage();
        const authHeaders = {
            'content-type':'application/x-www-form-urlencoded',
            'accept':'application/json',
            'Authorization': `Bearer ${token}`
        }
        const ably = new Ably.Realtime({ echoMessages: false, recover: true, authUrl: '/api/public/ablyAuthUrl', authMethod: "POST", authHeaders: authHeaders });
        ably.connection.once("connected");
        if(ablyObject !== null && typeof ablyObject !== "undefined") {
            ablyObject.close()
        }
        ably.connection.on(handleStateChange)
        return ably
    }

    const setupAblyChannel = () => {
        if(ablyChannel && ablyChannel.name === currentState.chatId) {
            return ablyChannel
        } else {
            const ably = ablyObject ?? prepareAblyConnection()
            const channel = ably.channels.get(currentState.chatId)
            setAblyChannel(channel)
            return channel
        }
    }

    const clearTimeoutState = () => {
        let timeOutState = localStorage.getItem("timeoutState", null);
        clearTimeout(timeOutState);
        localStorage.setItem("timeoutState", null);
    }

    const validateMessage = (message) => {
        let payload = JSON.parse(message.data.message);
        if(payload.message_id !== currentState.messageId){
            throw "Invalid Message ID";
        }
        return ;
    }

    const responseListener = (message) => {
        try{
            validateMessage(message);
            clearTimeoutState();
            const responseTime = new Date();
            sendMPEvents("ma_pureai_recieve_response", {
                prompt: currentState.prompt ?? "N/A",
                response: message.data.message,
                streaming: false,
                responseStartTime: calculateResponseTime(responseTime.getTime(), currentState.promptTime),
                streamingCompleteTime: calculateResponseTime(responseTime.getTime(), currentState.promptTime),
            });
            dispatch({type: ACTIONS.RECV_RESPONSE, response: message.data.message, responseTime: responseTime.getTime()});
        } catch(e) {
            errorHandler(e);
        }
    }

    const errorListener = (message) => {
        try {
            validateMessage(message);
            clearTimeoutState();
            const responseTime = new Date();
            dispatch({type: ACTIONS.FAIL_LOAD_RESPONSE, response: pureaiConstants.failLoadResponse, responseTime: responseTime.getTime(), errorType: "Backend Service",
                errorDetails: {
                    code: 1001,
                    reason: message
                }
            });
        } catch (e) {
            errorHandler(e);
        }
    }

    const streamListener = (message) => {
        try{
            validateMessage(message);
            getResponse(JSON.parse(message.data.message))
        } catch(e) {
            errorHandler(e);
        }
    }

    const getResponse = (response) => {
        if(currentState.streaming)
            return ;
        let msg = response?.message ?? "";
        if(msg === ""){
            dispatch({type:ACTIONS.FAIL_LOAD_RESPONSE, response: pureaiConstants.failLoadResponse, errorType: "Ably sent empty msg payload", errorDetails: {
                code: 1005,
                reason: "Invalid/Empty Msg field"
            }})
        }
        const data = {
            message: msg,
            messageId: currentState.messageId
        }
        dispatch({type: ACTIONS.STREAM_START, openAIPrompt:msg, cta: response.cta, platformSpecificAnswers: response.platformSpecificAnswers });
        poster('public/storePrompt', qs.stringify(data), getTokenFromLocalStorage()).then(response => {
            return response;
        })
        .then(fetchResponse)
        .catch(errorHandler)
    }

    const fetchResponse = () => {
        const eventSource = new EventSource('/api/public/getAnswer?messageId='+currentState.messageId, {
            withCredentials: true
        })
        eventSource.onopen = (event) => {
            clearTimeoutState();
        }

        let function_call = {
            "name": null,
            "arguments": ""
        }
        eventSource.onmessage = (event) => {
            if(event.data === "[DONE]"){
                if(function_call.name !== null){
                    dispatch({type: ACTIONS.FUNCTION_CALL, response: function_call})
                    eventSource.close();
                } else {
                    dispatch({type: ACTIONS.STREAM_COMPLETE});
                }
                eventSource.close()
            } else {
                let data = JSON.parse(event.data)
                let message = data?.choices?.[0]?.delta?.content?.replace("\n", "<br />");
                if(data?.choices?.[0]?.delta?.function_call) {
                    if(data?.choices?.[0]?.delta?.function_call?.name){
                        function_call["name"] = data?.choices?.[0]?.delta?.function_call?.name
                    }
                    if(data?.choices?.[0]?.delta?.function_call?.arguments){
                        function_call["arguments"] += data?.choices?.[0]?.delta?.function_call?.arguments
                    }
                }
                if(message){
                    dispatch({type: ACTIONS.RECV_STREAM, response: message});
                }
            }
        }
        eventSource.onerror = (event) => {
            errorHandler(event);
            dispatch({type:ACTIONS.FAIL_LOAD_RESPONSE, response: pureaiConstants.failLoadResponse, errorType: "OpenAI response malfunction",
                errorDetails: {
                    code: 1002,
                    reason: event
                }
            })
            eventSource.close();
        }
        setEvtSource(eventSource)
    }

    const stopResponseStreaming = () => {
        evtSource.close()
        identifyToken()
    }

    const identifyToken = () => {
        const lastState = getLastState();
        sendMPEvents("ma_pureai_recieve_response", {
            prompt: lastState.prompt ?? "N/A",
            response: lastState.response,
            streaming: true,
            responseStartTime: calculateResponseTime(lastState.responseTime, lastState.promptTime),
            streamingCompleteTime: calculateResponseTime(lastState.streamingCompleteTime, lastState.promptTime),
        });
        const channel = ablyChannel ?? setupAblyChannel()
        const data = {
            "event": "identify_token",
            "message": lastState.response,
            "channel": lastState.channelId,
            "chat_id": lastState.chatId,
            "source": "socket",
            "prompt": JSON.stringify(lastState.openAIPrompt),
            "message_id": lastState.messageId
        }
        channel.publish("identify_token", JSON.stringify(data))
    }

    const setMessageTimeOut = (err) => {
        if(err) {
            errorHandler(err);
            dispatch({type:ACTIONS.FAIL_LOAD_RESPONSE, response: pureaiConstants.failLoadResponse, errorType: "Unable to Publish message to ably",
                errorDetails: {
                    code: 1003,
                    reason: err
                }
            })
        }
        const timeout = setTimeout(() => {
            dispatch({type:ACTIONS.FAIL_LOAD_RESPONSE, response: pureaiConstants.failLoadResponse, errorType: "Timeout Occurred",
                errorDetails: {
                    code: 1004,
                    reason: "Message Timeout exceeded"
                }
            })
        }, 15000);
        localStorage.setItem("timeoutState", timeout)
        dispatch({type:ACTIONS.PROMPT_SENT})
    };

    const attachChannelListeners = (channel) => {
        if(!channel)
            return;
        channel.unsubscribe();
        channel.subscribe("response", responseListener)
        channel.subscribe("error", errorListener)
        channel.subscribe("stream", streamListener)
    }

    const publishMessage = () => {
        if(currentState.streaming || currentState.prompt === "")
            return ;
        const channel = ablyChannel ?? setupAblyChannel()
        let prompt = (currentState.promptType === "platform" && currentState.platform !== "" ? currentState.platform+"-" : "") + currentState.prompt
        const data = {
            "event": "new_message",
            "message": prompt,
            "channel": currentState.channelId,
            "chat_id": currentState.chatId,
            "source": "socket",
            "prompt": currentState.prompt,
            "message_id": currentState.messageId,
            "isFunction": 1
        }
        channel.publish("message", JSON.stringify(data), setMessageTimeOut)
    }

    const thirdPartyApis = () => {
        const channel = ablyChannel ?? setupAblyChannel()
        const data = {
            "event": "third-party-apis",
            "message": currentState.prompt,
            "custom_function": currentState.function_call,
            "channel": currentState.channelId,
            "chat_id": currentState.chatId,
            "source": "socket",
            "prompt": currentState.prompt,
            "message_id": currentState.messageId,
            "isFunction": 1
        }
        channel.publish("third-party-apis", JSON.stringify(data), setMessageTimeOut)
        dispatch({type: ACTIONS.PROMPT_SENT})
    }

    const sendFailureEvents = () => {
        let state = previousStates[previousStates.length - 1];
        sendMPEvents("ma_pureai_recieve_response_failure", {
            prompt: state.prompt ?? "N/A",
            response: "",
            streaming: false,
            error: state.errorDetails,
            errorType: state.errorType
        });
        dispatch({type:ACTIONS.FAILURE_EVENT_SENT})
    }

    const removeAblyChannel = () => {
        if(ablyChannel !== null){
            ablyChannel.detach()
        }
    }

    useEffect(() => {
        attachChannelListeners(ablyChannel);
    },[ablyChannel, currentState.action])

    useEffect(() => {
        switch (currentState.action) {
            case ACTIONS.NEW_CHAT:
                removeAblyChannel()
                break;
            case ACTIONS.SEND_PROMPT:
                publishMessage()
                break;
            case ACTIONS.STREAM_STOP:
                stopResponseStreaming()
                break;
            case ACTIONS.STREAM_COMPLETE:
                identifyToken()
                break;
            case ACTIONS.FUNCTION_CALL:
                thirdPartyApis()
                break;
            case ACTIONS.FAIL_LOAD_RESPONSE:
                sendFailureEvents()
                break;
            default:
                break;
        }
    })

    const handleBeforeUnload = (event) => {
        // Perform any necessary cleanup or actions before the page is unloaded
        if(ablyChannel !== null){
          ablyChannel.detach()
          ablyChannel.release()
        }
        if(ablyObject !== null){
          ablyObject.close()
        }
        return ; // Chrome requires a return value
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      });

    return null

}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(AblyConnector);