import { useState, useEffect } from 'react';
import { getByParam, getTokenFromLocalStorage } from '../../../../tools/tools';

const useRewardsInfo = (toFetchAll=true,tglState) => {
  const [rewardsInfo, setRewardsInfo] = useState(null);
  const [isRewardLoaded, setRewardLoaded] = useState(true);
  const [rewardErrorMsg, setRewardErrorMsg] = useState(null);
  
  useEffect(() => {
    async function fetchRewardsInfo() {
      try {
        setRewardErrorMsg(null)
        setRewardLoaded(true)
        // in order to fetch Redeem, Available and Used Coupons pass toFetchAll as true and if you want to fetch only thsose rewards/coupons that are redeemable on carts pass toFetchAll as false wherever you are importing the custom hook
        const pointsInfoResponseApi = await getByParam("loyalty/customer/rewards", toFetchAll ? { all: true } : "", getTokenFromLocalStorage());
        const { data: pointsInfoResponse } = pointsInfoResponseApi;
        if (pointsInfoResponse?.status) {
          setRewardsInfo(pointsInfoResponse?.body);
        } else {
          setRewardErrorMsg(pointsInfoResponse?.message);
        }
      } catch (error) {
        setRewardErrorMsg("An error occurred while fetching rewards info.");
      }
      setRewardLoaded(false);
    }

    fetchRewardsInfo();
  }, [tglState]);

  return { rewardsInfo, isRewardLoaded, rewardErrorMsg,setRewardLoaded };
};

export default useRewardsInfo;
