import { ERRORS, GETHELLOBAR, SETHELLOBAR, LOADING } from "./types";

const initialState = {
    hello_bar: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const helloBarReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETHELLOBAR:
            return  {...state, hello_bar:payload, loading:false, errors:false}
            break;
        case SETHELLOBAR:
            return  {...state, hello_bar:payload, loading:false, errors:false}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}