import React from "react";
import { InsetBtn } from "../../../styles/Generic";
import CountryFlag from "./Flag";
import { useTranslation } from 'react-i18next';

const CitiesBox = ({
    data,
    iso,
    getCityInfo,
    country,
    openSub,
    handleClick,
}) => {
    const { name, protocols, id, feature } = data;
    const { t, i18n } = useTranslation();
    return (
        <>
            <hr></hr>
            <div className={`country countryCity`}>
                <CountryFlag city={true} iso={iso.toLowerCase()} />
                <div
                    className={`citiesData ${feature.length === 0 && "my-1"
                        }`}
                >
                    <div className="content">
                        <div className="clickCountry ml-0 d-flex ">
                            <h3 className="mx-1">{name}</h3>
                            {/* FEATURE TAGS */}
                            <div className="d-flex d-none ">
                                {feature.length > 0 &&
                                    feature?.map((ft, city) => (
                                        <p className="my-2">
                                            <span
                                                onClick={
                                                    !openSub && handleClick
                                                }
                                                className="feature me-2"
                                                id={
                                                    ft.toUpperCase() ===
                                                        "OVPN_OBF"
                                                        ? "OBF"
                                                        : ft.toUpperCase()
                                                }
                                            >
                                                {ft.toUpperCase() === "OVPN_OBF"
                                                    ? "OBF"
                                                    : ft.toUpperCase()}
                                            </span>
                                        </p>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <InsetBtn
                        className="cityButton"
                        onClick={() =>
                            getCityInfo(
                                {
                                    name: country,
                                    cityName: name,
                                    id: id,
                                    iso: iso,
                                    protocols: protocols,
                                    feature: feature,
                                },
                                "city"
                            )
                        }
                    >
                        {t('download_text')}
                    </InsetBtn>
                </div>
            </div>
        </>
    );
};

export default CitiesBox;
