import { CircularLoaderStyles } from "../../styles/generic/CircularLoaderStyles";

const CircularLoader = () => {
    return <CircularLoaderStyles>
        <ul class="loader-list">
            <li>
                <div class="loader-1 center"><span></span></div>
            </li>
        </ul>
    </CircularLoaderStyles>
}

export default CircularLoader;