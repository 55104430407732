import { useState } from 'react'
import { FAQStyles } from '../../styles/generic/FaqsStyles';
import { useTranslation } from 'react-i18next'

 const GenericFaqs = ({faqs, theme}) => {

    const [open, setOpen] = useState();

    const { t, i18n } = useTranslation();
    
    const toggle = (id) => {
        id === open ? setOpen() : setOpen(id)
    }

  return (
    <FAQStyles>
        <section className={`row faqs ${theme ? 'dark' : ''}`}>
            <div className='content'>
                <h2>{t('upgradeplan_faq_heading')}</h2>
                {
                    faqs.map((faq, idx) => {
                        return (
                            <div key={idx} className={`item ${idx === open  ? "open" : "" }`}>
                                <div className='question' onClick={() => toggle(idx)}>
                                    <p>{t(faq.question)}</p>
                                    <span className='icon'></span>
                                </div>
                                <div className={`${idx === open  ? "open" : "close" } answer`}>
                                    <p>{t(faq.answer)}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    </FAQStyles>
  )
}

export default GenericFaqs;