import React from 'react'
import { useState } from 'react';
import { FAQViewScreen } from '../../styles/dashboard/PureBuddy';
import { pure_ai } from './faq_list';
import { useTranslation } from 'react-i18next'

const FAQView = ({heading="",faq_list=[]}) => {
    const { t, i18n } = useTranslation();
    const [faqs, setFaqs] = useState(faq_list);

    const faqToggle = (index) => {

        let updatedFaqs = []

        faqs.map((faq, idx) => {
            if (idx === index) {
                updatedFaqs.push({
                    ...faq,
                    open: (!faq.open)
                })
            } else {
                updatedFaqs.push({...faq})
            }
        })

        setFaqs(updatedFaqs)
    }

    return (
        <FAQViewScreen>
            <h2 className='heading'>{t('faqs_heading')} <span>{heading}</span></h2>
            <div className='item'>
                <p>{t('pureai_faq_disclaimer')}</p>
            </div>
            {
                faqs.map((faq, idx) => {
                    return (
                        <div key={idx} className={`item ${ (faq.open) ? "open" : "" }`}>
                            <div className='question' onClick={() => faqToggle(idx)}>
                                <p>{t(faq.question)}</p>
                                <span className='icon'></span>
                            </div>
                            <div className='answer'>
                                <p dangerouslySetInnerHTML={{__html: t(faq.answer)}}/>
                            </div>
                        </div>
                    )
                })
            }
        </FAQViewScreen>
    )
    }

export default FAQView