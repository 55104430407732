import { APIFAILED } from '../../../tools/responseMessages';
import { checkAccountType, deletion, dispatchError, getByParam, getPaymentMethods, getTokenFromLocalStorage, isWhmcsUser, poster } from '../../../tools/tools'
import { ErrorBox } from "../../../styles/Generic";
import { InfoCard, SubscriptionBox } from "../../../styles/dashboard/Main";
import { sendEvents } from "../../../tools/mpEvents";
import { toast } from 'react-toastify';
import { useDispatch, connect } from 'react-redux';
import { useEffect, useRef, useState } from "react";
import { VolumeLicensingContent } from "./content";
import { VolumeLicensingFaqs } from "../../../utils/FAQs/faq_list";
import { VolumeLicensingStyles } from "../../../styles/dashboard/VolumeLicensing";
import { withRouter } from "react-router-dom";
import BillingCycle from "../subscriptions/modals/BillingCycle";
import ErrorBoundary from "../../ErrorBoundary";
import fpCrashed from '../../../assets/fpCrashed.png'
import GenericFaqs from "../../../utils/FAQs/genericFaqs";
import Invite from "./invite";
import NotSupported from "../subscriptions/modals/NotSupported";
import PostPurchase from "./postPurchase";
import Prepurchase from "./prePurchase";
import qs from 'qs'
import Removal from "../family-plan/modal/removal";
import Skeleton from "react-loading-skeleton";

const VolumeLicensing = ({onBoardingReducer}) => {

    const scrollToCart = useRef();

    const showExitPopupRef = useRef(true);

    const token = getTokenFromLocalStorage();

    const [inviteModal, setInviteModal] = useState();

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    });

    const [hasSentWidth] = useState({
        lg: "460px",
        sm: "300px"
    });

    const [deleteModalWidth] = useState({lg: "500px",sm: "340px"});

    const widthCalculator = "lg";

    const [volumeData, setVolumeData] = useState(JSON.parse(localStorage.getItem('volume')));

    const [postPurchaseData, setPostPurchaseData] = useState(JSON.parse(localStorage.getItem('member-accounts')));

    const [selectedPrePurchaseData, setSelectedPrePurchaseData] = useState();

    const [crashed, setCrashed] = useState();

    const [selectedSubscription, setSelectedSubscription] = useState();

    const [loading, setLoading] = useState(true);

    const [upgradeToVLData, setUpgradeToVLData] = useState();

    const [hasAddonsArray, sethasAddonsArray] = useState();

    const [sliderValue, setSliderValue] = useState();

    const [upgradeModal, setUpgradeModal] = useState();

    const [orderSummary, setorderSummary] = useState(true);

    const [deleteMember, setDeleteMember] = useState(false);

    const [deleteMemberUuid, setDeleteMemberUuid] = useState();

    const [showError, setShowError] = useState();

    const [hasSent, setHasSent] = useState();

    const [inviteLink, setInviteLink] = useState();

    const [covertedId, setConvertedId] = useState();

    const [notsupportedState, setNotsupportedState] = useState({
        type:"waitlist",
        gateway:""
    });

    const [waitModal,showWaitModal] = useState(false)

    const [email, setEmail] = useState({
        items: [],
        value: "",
        error: null
    });
 
    const dispatch = useDispatch();


    useEffect(()=>{
        const { errors, selectedSubs, subscriptions: subs, errorType } = onBoardingReducer;

        if(onBoardingReducer?.subscriptions){

            let userSubscription = covertedId ?? selectedSubs ?? subs?.[0]?.id;
            let subscrip = subs.find((sub => sub.id === userSubscription))            
            sendRequestForVolumeLicensing(subscrip);
        }
        
        if(errors){
            dispatchError(errorType, dispatch);
        }
    }, [onBoardingReducer?.subscriptions])

    useEffect(()=>{

        const { selectedSubs, subscriptions: subs } = onBoardingReducer;
        
        if(volumeData && onBoardingReducer?.subscriptions){

            let selectSub = covertedId ?? selectedSubs ?? subs?.[0]?.id;
            let subscription = subs.find((sub => sub.id === selectSub)) ?? subs?.[0]
            setSelectedSubscription(subscription);
            setSliderValue(getMinimumSliderValue(subscription));
            setInviteLink();
            setPlanAccordingToSubscription(selectSub, volumeData)
            // setLoading(false)
            
        }
    }, [onBoardingReducer, volumeData])

    useEffect(()=>{
        setConvertedId()
    }, [onBoardingReducer?.selectedSubs])

    useEffect(()=>{
        sendEvents({
            event_name: "ma_pagevisited",
            event_properties: {
                visitedPageURL: window.location.href ?? "N/A",
            },
        }, token);
    }, [])

    useEffect(() => {
        document.addEventListener("mouseout", (e) => {
            if (showExitPopupRef.current && !e.relatedTarget && e.clientY < 10) {
                wisepopEvent();
            }
        });

        return () => showExitPopupRef.current && wisepopEvent();
    }, [])

    const wisepopEvent = () => {
        const email = onBoardingReducer?.subscriptions?.[0]?.account?.email;
        window.wisepops('properties', {email});
        window.wisepops('event', 'volume-licensing-event');
        hideExitPopup();
    }

    const hideExitPopup = () => {
        showExitPopupRef.current = false;
    }

    const sendRequestForVolumeLicensing = async (sub) => {
        
        try{
            const formdata = {'type': 'upsell'}
            const {data: response} = await getByParam(`volume-licensing/plans`, formdata, token);

            if(response?.status){
                setVolumeData(response?.body)
                localStorage.setItem('volume', JSON.stringify(response?.body))
                
            }
            else{
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code);
                sendEvents({
                    event_name: "ma_fail_load_volume",
                    event_properties: {
                        payment_method:getPaymentMethods(sub) ?? 'N/A',
                        reason: 'Unfortunately, Volume Licensing is currently not available for you!'
                    },
                }, token);
            }
        }
        catch{
            toast.error(APIFAILED);
            throwErrorBoundary(APIFAILED)
            sendEvents({
                event_name: "ma_fail_load_volume",
                event_properties: {
                    payment_method:getPaymentMethods(sub) ?? 'N/A',
                    reason: 'Unfortunately, Volume Licensing is currently not available for you!'
                },
            }, token);
        }
    }

    const sendPostPurchaseRequest = async (sub) => {

        try{
            const formdata = qs.stringify({
                'subscription_id': sub?.id, 
            })
            const {data: response} = await poster(`volume-licensing/member-accounts`, formdata, token);

            if(response?.status){
                setPostPurchaseData(response?.body);
                localStorage.setItem('member-accounts', JSON.stringify(response?.body))
            }
            else{
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code)
            }
        }
        catch{
            toast.error(APIFAILED);
            throwErrorBoundary(APIFAILED)
        }
        finally{
            setLoading(false)
        }
    }

    const sendFailLoadMsg = (sub) => {
        sendEvents({
            event_name: "ma_fail_load_volume",
            event_properties: {
                payment_method:getPaymentMethods(sub) ?? 'N/A',
                reason: 'Unfortunately, Volume Licensing is currently not available for you!'
            },
        }, token);
    }
    

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED || error === 'volume_licensing_exception'){
            setCrashed(true)
        }
    }

    const setPlanAccordingToSubscription = (id, subs) => {

        const currentSub = subs?.filter((sub) => sub.id === id)?.[0] ?? subs?.[0];

        if(currentSub?.is_child){
            hideExitPopup();
            setShowError('child')
            setLoading(false)
            sendFailLoadMsg(currentSub)
        }else if(currentSub?.hasVolumePlan){
            hideExitPopup();
            if(!postPurchaseData){
                setLoading(true)
            }
            else{
                setLoading(false)
            }
            setShowError(false)
            sendPostPurchaseRequest(currentSub);
        }else if (!currentSub?.hasVolumePlan && currentSub?.volumePlanEligibility){
            setShowError(false)

            // getting wrong plan in multi plans, even if there is no plans
            setSelectedPrePurchaseData(currentSub?.plans?.[0] ?? volumeData?.filter((sub) => sub.id === id)?.[0]?.plans?.[0]);
            setLoading(false)
        }else{
            hideExitPopup();
            setLoading(false)
            setShowError(true)
            sendFailLoadMsg(currentSub)
        }
    }

    const onClickPurchaseVolumeLicensing = () => {

        let todayDate = new Date().toJSON();

        setUpgradeToVLData({
            plan: selectedPrePurchaseData?.code,
            subscription:selectedSubscription?.id,
            service_origin:selectedSubscription?.service_origin,
            isWhmcsUser:isWhmcsUser(selectedSubscription?.account?.billingType),
            currency:selectedSubscription?.currency,
            interval_length: selectedPrePurchaseData?.interval_length,
            interval_unit: selectedPrePurchaseData?.interval_unit,
            start_date: todayDate,
            expiry_date: selectedPrePurchaseData?.end_date, 
            current_plan: selectedSubscription?.plan_type, 
            state: selectedSubscription?.state,
            billingcycle: selectedSubscription?.type,
            account_type: checkAccountType(selectedSubscription),
            sub_plan_type: selectedSubscription?.plan_type
        });

        sethasAddonsArray([
            {code: 'purevpn', quantity: sliderValue}, 
        ]);

        toggleUpgradeModal();

        sendEvents({
            event_name: "ma_get_volume",
            event_properties: {
                payment_method:getPaymentMethods(selectedSubscription) ?? 'N/A',
                billingcycle: selectedSubscription?.type ?? 'N/A',
                member_count: sliderValue
            },
        }, token);
    }

    const sendDeleteMemberRequest = async () => {
        try{
            const formdata = qs.stringify({child_code: deleteMemberUuid?.id})
            const {data: response} = await deletion(`volume-licensing/del-child-account`, formdata, token);
            if(response?.status){
                toast.success(response?.message);

                // in order to trigger useEffect, that will re-fetch data
                sendEvents({
                    event_name: "ma_delete_volume_member",
                    event_properties: {
                        recipient_email: deleteMemberUuid?.email ?? 'N/A',
                        member_count: postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0]?.max_slots,
                    },
                }, token);
                setLoading(true)
                sendPostPurchaseRequest(selectedSubscription);
            }
            else{
                toast.error(response?.message)
            }
        }
        catch{
            toast.error(APIFAILED);
        }
        finally{
            toggleDeleteModal()
        }
    }

    const toggleUpgradeModal = () => {
        setUpgradeModal(!upgradeModal)
    }

    const onClickDelete = () => {
        sendDeleteMemberRequest()
    }

    const toggleDeleteModal = () => {
        setDeleteMember(!deleteMember)
    }

    const onAddMoreUsers = () => {

        sendEvents({
            event_name: "ma_click_add_volume_member",
            event_properties: {
                member_count: postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0]?.max_slots,
            },
        }, token);


        toggleInviteModal()
        setEmail({
            items: [],
            value: "",
            error: null
        })
        
    }

    const toggleInviteModal = () => {
        setInviteModal(!inviteModal)
    }

    const closeHasSentModal = () => {
        toggleInviteModal();
        toggleSentModal()
    }

    const deleteSelectedMembers = (id, email) => {
        setDeleteMemberUuid({id, email})
        toggleDeleteModal()
    }

    const clickWaitList = () => {
        toggleWaitModal()
        let eventsProp = extractEventProp(selectedSubscription)
        sendEvents({
            event_name:"ma_waitlist_volume",
            event_properties:{
                ...eventsProp
            }
        }, token)
    }

    const extractEventProp = (selectedSubscription) =>{
        const event= {
            username: selectedSubscription?.vpnusername ?? "N/A",
            payment_method: getPaymentMethods(selectedSubscription) ?? "N/A",
            billing_cycle: selectedSubscription?.type ?? "N/A",
            account_status:selectedSubscription?.state ?? "N/A",     
        }
        return event
    }

    const toggleWaitModal = () => showWaitModal(!waitModal);

    const copyLink = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success("Copied to Clipboard!")
            
            sendEvents({
                event_name: "ma_send_volume_member_invite",
                event_properties: {
                  get_plan_via: "copy",  
                  recipient_email: ''
                }
            }, token);

        } catch (error) {
            toast.error("Unable to copy!")       
        }
    }

    const onClickInvite = () => {
        sendInviteRequest()
    }

    const sendInviteRequest = async () => {

        try{
            const formdata = qs.stringify({
                emails: email.items.toString(), 
                id: selectedSubscription?.id,
                plan_code: selectedSubscription?.plan?.code,
                current_plan_currency: selectedSubscription?.currency,
                current_plan_expiry_date: selectedSubscription?.expires_at_timestamp,
                max_account: postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0]?.max_slots
            })
            const inviteResponse = await poster(`volume-licensing/send-email`, formdata, token);
            const {data: response} = inviteResponse;    

            if(response?.status){
                toggleSentModal();
                sendEvents({
                    event_name: "ma_send_volume_member_invite",
                    event_properties: {
                      get_plan_via: "email", 
                      recipient_email:  email.items.toString(), 
                    }
                }, token);
            }
            else{
                toast.error(response?.message);
                toggleInviteModal();
                sendEvents({
                    event_name: "ma_fail_volume_member_invite",
                    event_properties: {
                      get_plan_via: "email", 
                      reason: response?.message,
                      recipient_email:  email.items.toString(), 
                    }
                }, token);
            }
        }
        catch{
            toast.error(APIFAILED);
            toggleInviteModal();
            sendEvents({
                event_name: "ma_fail_volume_member_invite",
                event_properties: {
                    get_plan_via: "email", 
                    reason: 'Oops! Something went wrong.',
                    recipient_email:  email.items.toString(), 
                }
            }, token);
        }
    }

    const toggleSentModal = () => {
        setHasSent(!hasSent)
    }

    const onInviteMoreUsers = () => {
        setEmail({
            items: [],
            value: "",
            error: null
        });
        sendEvents({
            event_name: "ma_click_add_volume_member",
            event_properties: {
                member_count: postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0]?.max_slots,
            },
        }, token);
        toggleSentModal()
    }
    
    const sendMemberAccountsRequest = (id) => {
        setConvertedId(id)
        setVolumeData()
        setLoading(true)
        toast.success(`You have successfully upgraded to Volume Licensing.`);
        localStorage.removeItem('volume');
    }

    const gotoDiv = () => {
        scrollToDiv(scrollToCart)
        sendEvents({
            event_name: "ma_get_volume",
            event_properties: {
                payment_method:getPaymentMethods(selectedSubscription) ?? 'N/A',
                billingcycle: selectedSubscription?.type ?? 'N/A',
                member_count: sliderValue
            },
        }, token);
    };

    const scrollToDiv = (ref) => window.scrollTo((0, 50), ref.current.offsetTop);

    const getMinimumSliderValue = (sub) => {
        return sub?.account?.child_count === 0 ? 2 : sub?.account?.child_count + 1 
    }

    return crashed ? 
        <ErrorBoundary /> : 
        <VolumeLicensingStyles>
                <div className="sub-content">
                    <h2>{VolumeLicensingContent.sub_heading}</h2>
                    <p>{VolumeLicensingContent.sub_para}</p>
                </div>
    
           {!loading ? <>
                
                {!showError && selectedSubscription?.hasVolumePlan && postPurchaseData && <PostPurchase onAddMoreUsers={onAddMoreUsers} data={postPurchaseData.details} deleteSelectedMembers={deleteSelectedMembers} postPurchaseData={postPurchaseData} setInviteLink={setInviteLink} inviteLink={inviteLink} selectedSubscription={selectedSubscription}/>}
                {!showError && !selectedSubscription?.hasVolumePlan && volumeData && <Prepurchase childCount={getMinimumSliderValue(selectedSubscription)} data={selectedPrePurchaseData} sliderValue={sliderValue} setSliderValue={setSliderValue} purchaseVL={onClickPurchaseVolumeLicensing} gotoDiv={gotoDiv} scrollToCart={scrollToCart}/>}
                {!showError && <div className="faqs">
                    <GenericFaqs faqs={VolumeLicensingFaqs}/>
                </div>}
                {showError && 
                    <ErrorBox>
                        <div className='errorBox fpErrorBox'>
                            <img src={fpCrashed} className="img-fluid" height={213} alt="Crashed"/>
                            <div className='mt-5'>
                                <span>
                                    <h2>Unfortunately, Volume Licensing is currently not available for you!</h2>
                                    {
                                        showError != 'child' && <p className="mt-3">Get on the waitlist to know once it is available</p>
                                    }
                                </span>
                                <span>
                                    {showError != 'child' && <button onClick={clickWaitList}>Join Waitlist</button>}
                                </span>
                            </div>
                        </div>
                    </ErrorBox>
                }
            </> :
            <SubscriptionBox open={true}>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </SubscriptionBox> 
            }

            {/* <----- MODALS -----> */}
            <>
                {inviteModal && <Invite toggle={hasSent ? closeHasSentModal : toggleInviteModal} modal={inviteModal} modalWidth={hasSent ? hasSentWidth : modalWidth} widthCalculator={widthCalculator} copyLink={copyLink} setEmail={setEmail} email={email} onClickInvite={onClickInvite} hasSent={hasSent} onInviteMoreUsers={onInviteMoreUsers} inviteLink={inviteLink} selectedSubscription={selectedSubscription}/>}
                {upgradeModal  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={upgradeToVLData} widthCalculator={widthCalculator} modal={upgradeModal} toggle={toggleUpgradeModal} setorderSummary={setorderSummary} orderSummary={orderSummary} additionalAddons={hasAddonsArray} redirectedFrom={'volumeLicensing'} apiEndPoint={'volume-licensing/change'} sendMemberAccountsRequest={sendMemberAccountsRequest} />}
                {deleteMember && <Removal backdrop={false} modalWidth={deleteModalWidth} widthCalculator={widthCalculator} modal={deleteMember} toggle={toggleDeleteModal} removeChildRequest={onClickDelete}/>}
                {waitModal && <NotSupported backdrop={false} notsupportedState={notsupportedState} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={showWaitModal} toggle={toggleWaitModal}/>}
            </>
        </VolumeLicensingStyles>

}

const mapStateToProps = (state) => {
    return {
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(withRouter(VolumeLicensing));
