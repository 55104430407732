import React from 'react'
import { Modal, ModalFooter } from 'reactstrap'
import { InfoCard, PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn } from '../../../../styles/Generic'
import ErrorBoundary from '../../../ErrorBoundary';
import useRewardsInfo from '../../pure-wallet/custom-hooks/useRewardsInfo';
import EarnRedeemLoader from '../../pure-wallet/partial/EarnRedeemLoader';
import AvailableComponent from '../../pure-wallet/Redeem/AvailableComponent';
import { useState } from 'react';
import { RedeemView } from '../../../../styles/dashboard/pure-rewards/PureWallet';
import { fetchEligibleVoucher, parsedJson } from '../../../../tools/tools';
import { ModalBody } from '../cancellation'
import  Skeleton  from 'react-loading-skeleton';


const CouponModal = ({setShowCouponView=()=>{},showCouponView=true,setCouponApplied=()=>{},setOldInvoice=()=>{},couponName}) => {

    const [tglState,setTglState] = useState(false)

    const toggle = () => {
        setShowCouponView(false)
    }

    const applyCoupon = (data) => {
        setOldInvoice(prev=>({...prev,isRemove:true}))
        setCouponApplied(data)
        setShowCouponView(false)
    }
    const { rewardsInfo, isRewardLoaded, rewardErrorMsg, setRewardLoaded } = useRewardsInfo(false,tglState);

    const Vouchers = ({rewards}) => {
        const eligibleVouchers = rewards?.Redeemed?.filter((voucher) => fetchEligibleVoucher(voucher?.config, couponName))
        return eligibleVouchers?.length ? 
            <div className="p-0 scroll-view">
                { eligibleVouchers.map((voucher, index) =>
                    <AvailableComponent isCartView={true} data={voucher} redeemCoupon={applyCoupon} key={index}/>
                ) }
            </div>
        : <RedeemView><div className='no-voucher-view'>You don't have any vouchers to Redeem</div></RedeemView>
    }

    return (
        !isRewardLoaded ? 
            rewardErrorMsg ? 
            <ErrorBoundary heading={rewardErrorMsg ?? "Oops"} isCartError={true} tglCartApi={setTglState}/>
            :
            <>
                <ModalBody>
                    <PopupBody>
                        <div className="mod-head">
                            <h3>Available Vouchers</h3>
                        </div>
                        <div className="mod-body">
                            <Vouchers rewards={rewardsInfo} />
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <DestructBtn onClick={toggle}>Close</DestructBtn>
                </ModalFooter>
            </>
        :
        <ModalBody>
            <div className="mod-body" style={{minHeight:"200px"}}>
                <Skeleton className="d-block mb-3" width={300}/>
                <Skeleton className="d-block mb-3" width={280}/>
                <Skeleton className="d-block mb-3" width={260}/>
                <Skeleton className="d-block mb-3" width={240}/>
                <Skeleton className="d-block mb-3" width={220}/>
                <Skeleton className="d-block mb-3" width={50}/>
            </div>
        </ModalBody>
    )
}

export default CouponModal