import styled, { css } from "styled-components";
import background from '../../assets/bundle/on_boarding-bg.png'

export const PureMaxWrapper = styled.div`

`
export const Button = styled.button`
    margin:0 !important;
    padding:20px;
    outline:none;
    background: #FFFFFF;
    border: 1px solid #EEEDF0;
    border-radius: 10px;
    & .pmax-head, & .pmax-body{
        display:flex;
        justify-content: space-between;
        align-items:center;
    }
    display:block;
    width:100%;
    &:not(:last-child){
        margin:0 0 10px !important;
    }
    ${props => props.active && css`
        border:1px solid #2CDC82;
    `}
`
export const Title = styled.h3`
    font-weight: 700 !important;
    font-size: ${props => props.size} !important;
    line-height: 125% !important;
    letter-spacing: 0.15px;
    color: #001219;
    margin:${props => props.margin} !important;
`

export const Text = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #637381;
    margin:0;
    max-width:480px;
    text-align:left;
`
export const Discount = styled.span`
    font-weight: 700 !important;
    font-size: 10px !important;
    line-height: 125% !important;
    text-align: right;
    letter-spacing: 0.02em;
    padding: 3px 10px;
    background: #2CDC82;
    border-radius: 100px;
    color: #FFFFFF !important;

`
export const IncludedBox = styled.div`
    margin:20px 0 0;
    display:flex;
    align-items:center;
    gap:10px;
`

export const BunddlePopup = styled.div`
margin:10px;
h1{
font-weight: 700;
font-size: 18px;
line-height: 25px;
color: #43225B;
display: flex;
align-items: center;
img{
    width:28px;
    margin-left: 5px;
}
}
p{
font-weight: 400;
font-size: 12px;
line-height: 125%;
letter-spacing: 0.5px;
color: #7B648C;
}
h4{
    color: #6536CC;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.24px;
    cursor: pointer;
}
.head{
    display: flex;
    flex-wrap: wrap;
    min-width: 310px;
    gap:10px;       
    margin-bottom:16px;

    img{
        flex-basis: calc(95%/(${props => props.gridVal}));
        border: 1px solid rgba(130, 96, 239, 0.24);
        border-radius: 18px;
        width:100%;
    }
    @media (max-width:360px){
        min-width: 270px !important;
        img{
            width:120px !important;
        }
    }
    img:hover{
        cursor: pointer;
        border:1px solid #6536CC;
        box-shadow: 0px 4px 16px rgba(0, 180, 216, 0.14);
    }

}
.bundle-box{
    border-radius: 16px;
    border: 1px solid rgba(130, 96, 239, 0.24);
    background: linear-gradient(182deg, rgba(101, 54, 204, 0.19) -40.66%, rgba(255, 255, 255, 0.00) 98.58%);
    width: 302px;
    height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
    text-align: center;
    margin:auto;
    cursor: pointer;

    @media only screen and (max-width: 680px){
        width: 200px;
    }
    @media only screen and (max-width: 460px){
        width: 150px;
    }

    &:hover{
        border-radius: 16px;
        border: 1px solid #8260EF;
        background: linear-gradient(182deg, rgba(101, 54, 204, 0.19) -40.66%, rgba(255, 255, 255, 0.00) 98.58%);
        box-shadow: 0px 12px 24px 0px rgba(130, 96, 239, 0.32);
        transition: 0.5s all ease-in-out;
    }
    &::before{
        position: absolute;
        content: '';
        background: url(${background}) no-repeat center;
        top: -10px;
        width: 100%;
        height: 100%;
        left: auto;
    }
    h3{
        color: #001219;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.5px;
        letter-spacing: 0.15px;
        margin-top: 15px;
    }
    img{
        min-height: 60px;
    }
    
}
button{
    height: 40px;
    padding: 0 20px;
}
.col{

}
@media (max-width:700px){
.head{
    justify-content:center;
    img{
        width:auto;
        :last-child{
            grid-row: 2;
            grid-column: 1 / span 2;
        }
    }
}

}
`

// For Release 2
export const BundleItem = styled.span`
  display: inline-block;
  background-image: url(${props => props.bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 160px;
  img{
    border: 1px solid rgba(130, 96, 239, 0.24);
    border-radius: 16px;
    @media (max-width:360px){
        width: 120px;
    }
  }
    :hover{
    cursor: pointer;
    border:1px solid #6536CC;
    border-radius: 16px;
    box-shadow: 0px 4px 16px rgba(0, 180, 216, 0.14);
  }
`