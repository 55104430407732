import { ERRORS, GETREFERS, LOADING, REDUCEREMAINING } from "./types";

const initialState = {
    invites: null,
    details:null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const referReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETREFERS:
            return  {...state, invites:payload, loading:false, errors:false}
            break;
        case REDUCEREMAINING:
            return  {...state, invites:{...state.invites, body:{...state.invites.body, invites_remaining: state.invites.body.invites_remaining - 1}}, loading:false, errors:false}
            break;    
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}