import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { get } from "../../../../tools/tools";

const useReferralProgress = (token) => {
  const { t } = useTranslation();
  const getReferralProgressFromLocalStorage = JSON.parse(
    localStorage.getItem("referral_progress")
  );
  const [referralProgress, setReferralProgress] = useState(null);
  const [isReferralLoading, setIsReferralLoading] = useState(getReferralProgressFromLocalStorage ? false : true);
  const [progressErrorMsg, setProgressErrorMsg] = useState(null);
  const [fetchProgressAgain, setFetchProgressAgain] = useState(false);
  const [newFetchSuccess, setNewFetchSuccess] = useState(false);

  useEffect(() => {
    async function fetchReferralProgress() {
      try {
        getReferralProgressFromLocalStorage ? setReferralProgress(getReferralProgressFromLocalStorage) : setIsReferralLoading(true);
        const apiResponse = await get("loyalty/refer/progress", token);
        const { data: userProgress } = apiResponse;
        setProgressErrorMsg(false);
        if (userProgress?.status) {
          setReferralProgress(userProgress?.body);
          setNewFetchSuccess(true);
          localStorage.setItem("referral_progress", JSON.stringify(userProgress?.body));
        } else {
          setProgressErrorMsg(userProgress?.message);
        }
      } catch (error) {
        setProgressErrorMsg(t('refer_errorbox_heading'));
      }
      setIsReferralLoading(false);
    }

    fetchReferralProgress();
  }, [fetchProgressAgain]);
  
  return { referralProgress, isReferralLoading, progressErrorMsg, setFetchProgressAgain, newFetchSuccess };
};

export default useReferralProgress;
