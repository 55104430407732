import React from 'react'
const ProgressBar = ({ strokeWidth=8, percentage,categoryPoints,earnedPoints }) => {
	const radius = (50 - strokeWidth / 2);
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
        stroke: percentage === 100 ? "#2EC4B6" : '#6536CC',
        strokeLinecap: 'round',
        strokeDasharray: `${diameter}px ${diameter}px`,
        strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
    };

    return (
      <svg
        className={'CircularProgressbar'}
        viewBox="0 0 100 100"
        width={100}
        height={100}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={{
            stroke: '#d6d6d6',
          }}
        />
    
        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
    
        <text
          className="CircularProgressbar-text"
          x={50}
          y={40}
          style={{
            fill: '#000',
            fontSize: '15px',
            dominantBaseline: 'central',
            textAnchor: 'middle',
            fontWeight: 700,
          }}
        >
          {(earnedPoints >= categoryPoints ? categoryPoints : earnedPoints) + `/` + categoryPoints}
        </text>
        <text
          className="CircularProgressbar-text"
          x={50}
          y={60}
          style={{
            fill: '#000',
            fontSize: '14px',
            dominantBaseline: 'central',
            textAnchor: 'middle',
          }}
        >
          {`Points`}
        </text>
      </svg>
    );
    
};


export default ProgressBar
