import React, { useState, useEffect , Suspense, lazy, useRef} from 'react'
import {  CardNumber, InfoCard, PopupBody, OrderSummary, PurchaseBox, PlanCard, ExistingPlan } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import classNames from 'classnames'
import { Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { toast } from 'react-toastify';
import { dateFormat, get, getByParam, generateCouponEvent, getCurrentSubscription, isManualGateway, isWhmcsUser, itemUnitAmount, poster, seti18Locale, checkLocale, getTotalAmount, getPlanProRatedAdjustment, getCreditAdjustment } from '../../../../tools/tools';
import qs from 'qs'
import Skeleton from 'react-loading-skeleton';
import { Link, withRouter } from 'react-router-dom';
import SubmitConsent from '../SubmitConsent'
import { sendEvents } from '../../../../tools/mpEvents';
import { constants, currencies } from '../../../../tools/constants';
import RecurlyRoute from '../../../../tools/routes/RecurlyRoute';
import { useTranslation } from 'react-i18next';
import { useRecurly, ThreeDSecureAction, RecurlyProvider, Elements } from '@recurly/react-recurly';
import RenewalCouponField from './partials/RenewalCouponField';
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions'
const Payment = lazy(()=>import(/* webpackChunkName: "recurlycard" */"../recurly/Payment"))
const PaypalBtn = lazy(()=>import(/* webpackChunkName: "recurlypaypal" */"../recurly/PaypalBtn"))

const RenewalModal = ({history, backdrop, toggle, setorderSummary, orderSummary, modal, modalWidth, widthCalculator, selectedSubscription, subscriptions:reduxSubs}) => {

    const [isCouponApplied,setIsCouponApplied] = useState(null)

    const [firstTimeCouponEntered,setFirstTimeCouponEntered] = useState(false)

    const [isCouponLoading,setIsCouponLoading] = useState(false)

    const recurly = useRecurly();

    const [endPoint, setEndPoint] = useState("subscription/change-pm-and-renew");

    const [icon, seticon] = useState("card");

    const [threedSecure, setthreedSecure] = useState({
        enabled:false,
        three_d_secure_action_token_id: "",
        oldToken:""
    })

    const dispatch = useDispatch()

    const { t } = useTranslation();

    const token = localStorage.getItem("token")

    const [loading, setloading] = useState(true)

    const [maxRenewLoading, setMaxRenewLoading] = useState(true)

    const [disableSelection, setDisableSelection] = useState(false)

    const [purchaseLoading, setpurchaseLoading] = useState(false)

    const [whmcsUser, setWhmcsUser] = useState(false)

    const [isAllowToUseParamMethod, setIsAllowToUseParamMethod] = useState(false)

    const [data, setdata] = useState({})

    const [isError,setIsError] = useState(false)

    const [currentData, setCurrentData] = useState({})

    const [maxData, setMaxData] = useState(null)

    const [planType, setPlanType] = useState(null)

    const [state, setstate] = useState({
        plan_type:"",
        username:"",
        period:"",
        last_four:""
    })
    
    const [maxState, setMaxState] = useState({})
    
    useEffect(() => {
    async function apifunc(){
            try {
                if (selectedSubscription?.expiredSubscription) {
                    setEndPoint("subscription/renew-expired-subscritpion");
                }
                setIsError(false)
                const invoicedata = await getByParam(`subscription/${selectedSubscription.subscription}/${selectedSubscription.invoice}`, isCouponApplied ? {coupon_code: isCouponApplied} : {}, token)
                const {data: response} = invoicedata;
                if(response?.status){
                    setCurrentData(response?.body)
                    setdata(response?.body)
                    const subIndex = reduxSubs.subscriptions.body.findIndex(sub=>sub.id === selectedSubscription.subscription)
                    let stateData = {
                        plan_type: reduxSubs?.subscriptions?.body[subIndex]?.type ?? "-",
                        username: reduxSubs?.subscriptions?.body[subIndex]?.vpnusernames?.[0] ?? "-",
                        email: reduxSubs?.subscriptions?.body[subIndex]?.account?.email ?? "-",
                        period: `${dateFormat(reduxSubs.subscriptions.body[subIndex].start_date)} - ${dateFormat(reduxSubs.subscriptions.body[subIndex].end_date)}`,
                        last_four:reduxSubs.subscriptions.body[subIndex]?.billing_info?.last_four
                    };
                    if(isWhmcsUser(reduxSubs?.subscriptions?.body[subIndex]?.account?.billingType)){
                        stateData = {
                            plan_type: reduxSubs?.subscriptions?.body[subIndex]?.type ?? "-",
                            username: reduxSubs?.subscriptions?.body[subIndex]?.vpnusernames?.[0] ?? "-",
                            period: `${dateFormat(response?.body?.items[0]?.start_date)} - ${dateFormat(response?.body?.items[0]?.end_date)}`,
                            last_four:reduxSubs.subscriptions.body[subIndex]?.billing_info?.last_four
                        };
                        setWhmcsUser(true)
                        setIsAllowToUseParamMethod(true)
                    }
                    setstate(stateData)
                    if(isCouponApplied){
                        setIsCouponLoading(false)
                    }
                }else{
                    setIsCouponLoading(false)
                    switch (response?.error_code) {
                        case "token_expired":
                            toast.error(t(TOKENEXPIRED));
                            toggle()
                            dispatch({type:"LOGOUT"})
                            break;
                        case "token_invalid":
                            toast.error(t(INVALIDTOKEN))
                            toggle()
                            dispatch({type:"LOGOUT"})
                            break;  
                        case "token_invalid":
                            toast.error(t(INVALIDTOKEN))
                            dispatch({type:"LOGOUT"})
                            break;   
                        case "jwt_default_exception":
                            toast.error(t(INVALIDTOKEN))
                            toggle()
                            dispatch({type:"LOGOUT"})
                            break;
                        case "renew_exception":
                            toast.error(response?.message ?? APIFAILED)    
                            break;   
                        case "offline_gateway_exception":
                            toast.error(response?.message ?? APIFAILED)   
                            break;
                        case "coupon_code_exception":
                            setIsError(response?.message)
                            sendEvents({
                                event_name:"ma_renew_coupon_exception",
                                event_properties:{
                                    ...couponEvent
                                }
                            }, token)
                            setIsCouponApplied(null)
                            return;
                        default:
                            toast.error(t(APIFAILED));
                            break;
                    }
                    toggle();
                }
            } catch (error) {
                toast.error(t(APIFAILED))
                toggle();
            }
            finally{
                setloading(false)
            }
        }
        apifunc()
    }, [firstTimeCouponEntered])

    useEffect(async () => {
        try {
            if (currentSub?.plan_type !== constants.vpn_max && !currentSub?.hasFamilyPlan && !currentSub?.hasVolumePlan && currentSub?.state !== 'expired') {
                const previewData = qs.stringify({
                    subscription_id: selectedSubscription?.subscription,
                    plan_code: currentSub?.max_upgrade?.plan_code,
                    preview:1,
                    isPastDue: constants.past_due_state.includes(currentSub?.state),
                    plan_type: constants.vpn_max,
                });
                const {data: response} = await poster("subscription/change", previewData, token)
                if(response?.status){
                    setMaxData({ 
                        invoice_tax: response?.body?.tax_total || 0,
                        plan_type: constants.vpn_max,
                        ...response?.body
                    })
                    const subIndex = reduxSubs?.subscriptions?.body?.findIndex(sub=>sub?.id === selectedSubscription?.subscription) || 0
                    const stateData = {
                        plan_type: reduxSubs?.subscriptions?.body[subIndex]?.type ?? "-",
                        username: reduxSubs?.subscriptions?.body[subIndex]?.vpnusernames?.[0] ?? "-",
                        email: reduxSubs?.subscriptions?.body[subIndex]?.account?.email ?? "-",
                        period: `${dateFormat(reduxSubs.subscriptions.body[subIndex].start_date)} - ${dateFormat(response?.body?.end_date)}`,
                        last_four:reduxSubs.subscriptions.body[subIndex]?.billing_info?.last_four
                    };
                    setMaxState(stateData)
                }
            }
        } catch (error) {
            setMaxData(null)
        }
        finally{
            setMaxRenewLoading(false)
        }
    }, [])

    const buttonText = () => {
        return !purchaseLoading 
        ? "Confirm order" 
        : 
        ( <>Confirm order <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    const couponEvent = generateCouponEvent(isCouponApplied, data)

    const currentSub = getCurrentSubscription(reduxSubs ,selectedSubscription.subscription)

    const changePlan = (plan) => {
        if (plan === constants.vpn_max) {
            setdata(maxData)
            setPlanType(constants.vpn_max)
            setIsCouponApplied(null)
            setIsError(false)
            setEndPoint("account/change-payment-method")
        } else {
            setdata(currentData)
            setPlanType(null)
            if (selectedSubscription?.expiredSubscription) {
                setEndPoint("subscription/renew-expired-subscritpion");
            } else {
                setEndPoint("subscription/change-pm-and-renew");
            }
        }
    }

    const renewNowAsync = async (formdata) => {
        let threedSecure = false;
        sendEvents({
            event_name:"ma_checkout_renew_subscription",
            event_properties:{
                billing_cycle: currentSub?.plan?.type ?? "N/A",
                payment_gateway: currentSub?.payment_gateway ?? "N/A",
                productid: planType === constants.vpn_max ? currentSub?.max_upgrade?.plan_code : currentSub?.plan?.code ?? "N/A",
                current_status: "Past Due",
                invoice_id: selectedSubscription.invoice 
            }
        }, token)
        try {
            let purchase;
            if (planType === constants.vpn_max) {
                purchase = await poster("subscription/change", formdata, token)
            } else {
                purchase = await poster("subscription/change-pm-and-renew", formdata, token)
            }
            const {data: response} = purchase;
            
            if(response?.status){
                toast.success(t(SUCCESS))
                dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                toggle()
                sendEvents({
                    event_name:"ma_renew_subscription",
                    event_properties:{
                        billing_cycle: currentSub?.plan?.type ?? "N/A",
                        payment_gateway: currentSub?.payment_gateway,
                        productid: planType === constants.vpn_max ? currentSub?.max_upgrade?.plan_code : currentSub?.plan?.code ?? "N/A",
                        current_status: "Active",
                        invoice_id: selectedSubscription.invoice,
                        old_plantype: currentSub?.plan_type,
                        plan_type: planType === constants.vpn_max ? constants.vpn_max : currentSub?.plan_type
                    }
                }, token)
                history.push("/dashboard/security-tools");
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;   
                    case "validation_exception":
                        response?.errors?.add_ons.map(error=>toast.error(error))
                        break;    
                    case "declined":
                        toast.error(response?.message)
                        break;   
                    case "renew_exception":
                        toast.error(response?.message)
                        break; 
                    case "three_d_secure_action_required":
                        setthreedSecure({
                            three_d_secure_action_token_id:response?.errors?.three_d_secure_action_token_id,
                            enabled:true,
                            oldToken:""
                        })
                        threedSecure = true;
                        break;   
                    case "recurly_exception":
                        toast.error(response?.message)
                        break;  
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;  
                    default:
                        toast.error(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_renew_subscription",
                    event_properties:{
                        reason: response?.message,
                        billing_cycle: currentSub?.plan?.type ?? "N/A",
                        payment_gateway: currentSub?.payment_gateway,
                        productid: currentSub?.plan?.id ?? "N/A",
                        current_status:currentSub?.state,
                        invoice_id: selectedSubscription.invoice
                    }
                }, token)
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_renew_subscription",
                event_properties:{
                    reason: "API break or Server not responding.",
                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                    payment_gateway: currentSub?.payment_gateway,
                    productid: currentSub?.plan?.id ?? "N/A",
                    current_status: currentSub?.state,
                    invoice_id: selectedSubscription.invoice
                }
            }, token)
        }
        finally{
            if(!threedSecure){
                setpurchaseLoading(false)
            }
        }
    }

    const renewNow = (data = {}) => {
        setpurchaseLoading(true)
        let formdata;
        if (planType === constants.vpn_max) {
            formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription,
                plan_code: currentSub?.max_upgrade?.plan_code,
                preview:0,
                isPastDue: constants.past_due_state.includes(currentSub?.state),
                plan_type: constants.vpn_max,
                ...data
            });
        } else {
            formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription,
                invoice_id: selectedSubscription.invoice,
                coupon_code:isCouponApplied,
                ...data
            })
        }
        renewNowAsync(formdata)
    }

    const handle3dToken = (token) => {
        if(token){
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription,
                invoice_id: selectedSubscription.invoice,
                threed_secure_id: token.id,
                coupon_code:isCouponApplied
            })
            renewNowAsync(formdata)
        }else{
            toast.error("Something went wrong!!!")
            setpurchaseLoading(false)
            sendEvents({
                event_name:"ma_fail_renew_subscription",
                event_properties:{
                    reason: "Something went wrong, while 3d Token Authorization",
                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                    payment_gateway: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                    productid: currentSub?.plan?.id ?? "N/A",
                    current_status:currentSub?.state,
                    invoice_id: selectedSubscription.invoice
                }
            }, token)
        }
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        toast.error(error?.message || "Something went wrong!!!")
        setpurchaseLoading(false)
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
        sendEvents({
            event_name:"ma_fail_renew_subscription",
            event_properties:{
                reason: error?.message,
                billing_cycle: currentSub?.plan?.type ?? "N/A",
                payment_gateway: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                productid: currentSub?.plan?.id ?? "N/A",
                current_status:currentSub?.state,
                invoice_id: selectedSubscription.invoice
            }
        }, token)
    }

    const paymentGateway = (gateway) => {
        switch (gateway) {
            case "paypal":
                return <CardNumber card="paypal">Paypal</CardNumber>
                break;
            case "coingate":
                return <CardNumber card="up">Coingate</CardNumber>
                break;
            case "paymentwall":
                return <CardNumber card="up">Paymentwall</CardNumber>
                break;
            default:
                return <CardNumber card="up">Payment Gateway</CardNumber>
                break;
        }
    }

    const goToPaymentLink = async () => {

        sendEvents({
            event_name:"ma_checkout_renew_subscription",
            event_properties:{
                billing_cycle: currentSub?.plan?.type ?? "N/A",
                payment_gateway: currentSub?.payment_gateway ?? "N/A",
                productid: currentSub?.plan?.id ?? "N/A",
                current_status: "Past Due",
                invoice_id: selectedSubscription.invoice 
            }
        }, token)

        setpurchaseLoading(true);
        let url = data?.external_payment_url?.url;
        let allowRelocate = true;

        toast.success(`Redirecting you to ${data?.payment_gateway} for checkout`)

        if(isWhmcsUser(currentSub?.account?.billingType)){

            const formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription,
                type: currentSub?.payment_gateway
            })

            const purchase = await poster("subscription/renew-manual-subscription", formdata, token)
            const {data: response} = purchase;
            if(response?.status){
                url = response?.body?.external_payment_url?.url;
            }else{
                allowRelocate  = false;
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;
                    case "validation_exception":
                        response?.errors?.add_ons.map(error=>toast.error(error))
                        break;
                    case "renew_exception":
                        toast.error(response?.message)
                        break;
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        toggle()
                        dispatch({type:"LOGOUT"})
                        break;
                    default:
                        toast.error(response?.message);
                        break;
                }
            }
        }

        if(allowRelocate){
            setTimeout(() => {
                window.location.href = url
                toggle();
            }, 1500);
        }
    }

    const resetstate = () => {
        seticon("")
        toggle()
    }

    const getAppNameHeading = () => {
        let name = "PureVPN";

        switch (selectedSubscription?.service_origin) {
            case constants.purevpn:
                name = "PureVPN"
                break;
            case constants.purescan:
            case constants.pureprivacy:
                name = "PurePrivacy"
                break;
            case constants.purecrypt:
            case constants.pureencrypt:
                name = "PureEncrypt"
                break;
            case constants.purekeep:
                name = "PureKeep"
                break;
            case constants.puresquare:
                name = "PureMax"
                break;
            default:
                break;
        }

        return name;
    }

    const canReqStopCollection = () => {
        if (planType === constants.vpn_max && !isWhmcsUser(currentSub?.account?.billingType)) {
            return true
        }
        return false
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper" scrollable>
                {
                    !loading 
                    ?
                    <>
                    <div style={{ padding: '15px 0px 0px 15px'}}>
                        <h3>{t('renewal_plan_renewal')}</h3>
                    </div>
                    <ModalBody>
                        <PopupBody>
                            <div className="mod-body">
                                <InfoCard noflex>
                                    <div>
                                        <label>{t('plan_type_text')} <strong className="text-capitalize">{planType === constants.vpn_max ? maxState?.plan_type : state.plan_type || "-"}</strong></label>
                                    </div>
                                    {
                                        (constants.applicationAddons.includes(selectedSubscription?.service_origin)) ? (
                                            <div>
                                                <label>{t('payment_history_email')} <strong>{planType === constants.vpn_max ? maxState?.email : state.email || "-"}</strong></label>
                                            </div>
                                        ) : (
                                            <div>
                                                <label>{t('tbl_username')} <strong>{planType === constants.vpn_max ? maxState?.username : state.username || "-"}</strong></label>
                                            </div>
                                        )
                                    }
                                    <div>
                                        <label>{t('service_period')} <strong>{planType === constants.vpn_max ? maxState?.period : state.period || "-"}</strong></label>
                                    </div>
                                </InfoCard>
                                { (currentSub?.plan_type !== constants.vpn_max && !currentSub?.hasFamilyPlan && !currentSub?.hasVolumePlan && !currentSub?.hasPureTeams ) && 
                                <InfoCard noflex disabled={disableSelection}>
                                    <PlanCard selected={planType === null} className='mb-2' onClick={() => changePlan('')}>
                                        <div className="renew-plan">
                                            <div className="title">
                                                <b>{t('subscription_renew_to_existing_plan')}</b>
                                            </div>
                                            <div className="pricing">
                                                <b className="price">{currencies[currentData?.currency]}{currentData?.is_credit_applied ? currentData?.balance_amount : currentData?.paid?.toFixed(2) || "-"}</b>
                                            </div>
                                        </div>
                                    </PlanCard>
                                    { !maxRenewLoading ?
                                        <PlanCard selected={planType === constants.vpn_max} onClick={() => changePlan(constants.vpn_max)}>
                                            <div className="renew-plan">
                                                <div>
                                                    <div className="title">
                                                        <b>{t('subscription_renew_with_max')}</b>
                                                        <div className="desc">{t('securitysuite_fourX_your_privacy')}</div>
                                                    </div>
                                                    <div className="whats-included-container">
                                                        <b>{t('subscription_whats_included')}</b>
                                                        <div className="icon-container">
                                                            <div className='vpn'></div>
                                                            <div className='keep'></div>
                                                            <div className='crypt'></div>
                                                            <div className='privacy'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing">
                                                    <b className="price">{currencies[maxData?.items[0]?.currency]}{maxData?.items[0]?.unit_amount}</b>
                                                </div>
                                            </div>
                                        </PlanCard>
                                    :
                                    <ModalBody>
                                        <div className="mod-body" style={{minHeight:"50px"}}>
                                            <Skeleton className="d-block mb-3" width={150}/>
                                            <Skeleton className="d-block mb-3" width={300}/>
                                            <Skeleton className="d-block mb-3" width={250}/>
                                        </div>
                                    </ModalBody>
                                    }
                                </InfoCard>
                                }
                                <InfoCard hasborder noflex>
                                    <OrderSummary open={!orderSummary}>
                                        <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">{t('addon_purchasemodal_order_summary')}</strong></label>
                                        <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                                            {
                                                data?.items.map((item,key)=>(
                                                    <li key={key}>
                                                        {`${item?.description}`} {`${item?.quantity > 1 ? `x ${item?.quantity}`: ''}`} <strong>{currencies[selectedSubscription.currency]}{itemUnitAmount(item)?.toFixed(2)}</strong>
                                                    </li>
                                                ))
                                            }
                                            {
                                                (planType === constants.vpn_max && !currentSub?.hasFamilyPlan && !currentSub?.hasVolumePlan) &&
                                                <li>
                                                    {t('prorated_adjustment')} <strong className="text-end">{getPlanProRatedAdjustment(currencies[selectedSubscription.currency], data)}</strong>
                                                </li>
                                            }
                                            <li>VAT <strong>{currencies[selectedSubscription.currency]}{data?.invoice_tax?.toFixed(2) || "-"}</strong></li>
                                        </ul>
                                    </OrderSummary>
                                </InfoCard>
                                <RenewalCouponField setIsCouponApplied={setIsCouponApplied} data={data} setFirstTimeCouponEntered={setFirstTimeCouponEntered} isCouponApplied={isCouponApplied} isError={isError} isCouponLoading={isCouponLoading} setIsCouponLoading={setIsCouponLoading} planType={planType} currency={currencies[selectedSubscription.currency]}/>
                            </div>
                        </PopupBody> 
                    </ModalBody>
                    {
                        isManualGateway(data?.payment_gateway) && planType !== constants.vpn_max
                        ?
                        <ModalFooter> 
                            <PrimaryBtn color="primary" onClick={goToPaymentLink} pointer={purchaseLoading}>{buttonText()}</PrimaryBtn>
                            <DestructBtn color="secondary" onClick={toggle} pointer={purchaseLoading}>{t('cancel_text')}</DestructBtn>        
                        </ModalFooter>
                        :
                        <ModalFooter>
                            <InfoCard noflex className="p-0">
                                    <PurchaseBox type="payment" icon={icon}>
                                        <Input type="select" name="select" onChange={e=>seticon(e.target.value)}>
                                            <option selected={icon===""} value="">{t('payment_method_text')}</option>
                                            <option selected={icon==="card"} value="card">{t('credit_card_text')}</option>
                                            <option selected={icon==="paypal"} value="paypal">Paypal</option>
                                        </Input>
                                    </PurchaseBox>
                                </InfoCard>
                                <InfoCard className="py-0">
                                    <div className="recurly-wrapper" disabled={isCouponLoading}>
                                        <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
                                            <Elements>
                                                {
                                                    icon === "card" 
                                                    ?
                                                    <>
                                                        <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                            <Payment resetstate={resetstate} isAllowToUseParamMethod={isAllowToUseParamMethod} endPoint={endPoint} renewSubscription={planType !== constants.vpn_max && "true"} selectedSubscription={selectedSubscription} currentSub={currentSub} chargeAttempt={renewNow} seticon={seticon} popupPurchase={planType ? true : false} canReqStopCollection={canReqStopCollection()} isCouponApplied={isCouponApplied} setFirstTimeCouponEntered={setFirstTimeCouponEntered} setIsCouponApplied={setIsCouponApplied} couponEvent={couponEvent} toggle={resetstate} setDisableSelection={setDisableSelection} subscription_id={currentSub?.id} />
                                                        </Suspense>
                                                    </>
                                                    : icon === "paypal" ?
                                                    <>
                                                        <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                            <PaypalBtn resetstate={resetstate} isAllowToUseParamMethod={isAllowToUseParamMethod} chargeAttempt={renewNow} endPoint={endPoint} renewSubscription={planType !== constants.vpn_max && "true"} selectedSubscription={selectedSubscription} currentSub={currentSub} seticon={seticon} popupPurchase={planType ? true : false} canReqStopCollection={canReqStopCollection()} isCouponApplied={isCouponApplied} couponEvent={couponEvent} toggle={resetstate} setDisableSelection={setDisableSelection} subscription_id={currentSub?.id} />
                                                        </Suspense>
                                                    </>
                                                    :
                                                    <DestructBtn type="button" onClick={resetstate} className="mt-3">{t('cancel_text')}</DestructBtn>
                                                }
                                            </Elements>
                                        </RecurlyProvider>
                                    </div>
                                </InfoCard>
                        </ModalFooter>
                    }
                    {/* <ModalFooter>
                        <PrimaryBtn color="primary" onClick={renewNow} pointer={purchaseLoading}>{buttonText()}</PrimaryBtn>
                        <DestructBtn color="secondary" onClick={toggle}>Cancel</DestructBtn>
                    </ModalFooter> */}

                    </>
                    :
                    <ModalBody>
                        <div className="mod-body" style={{minHeight:"200px"}}>
                            <Skeleton className="d-block mb-3" width={300}/>
                            <Skeleton className="d-block mb-3" width={280}/>
                            <Skeleton className="d-block mb-3" width={260}/>
                            <Skeleton className="d-block mb-3" width={240}/>
                            <Skeleton className="d-block mb-3" width={220}/>
                            <Skeleton className="d-block mb-3" width={50}/>
                        </div>
                    </ModalBody>
                }
                
            </Modal>
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                actionTokenId={threedSecure.three_d_secure_action_token_id}
                onToken={handle3dToken}
                onError={handle3dError}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { subscriptions: state.subscriptionsReducer };
};

export default connect(mapStateToProps, {getSubscriptionsRequest})(withRouter(RecurlyRoute(RenewalModal)))