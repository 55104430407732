import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const PureVPNIconOnlySVG = ({ fillColorOne, fillColorTwo, fillColorThree, fillColorFour, width, height, style, className }) => (
    <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} className={className} viewBox="0 0 120 121" width={width}>
      <Path
          fillColor={fillColorOne}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60 120.5C93.1371 120.5 120 93.6372 120 60.5C120 27.3629 93.1371 0.5 60 0.5C26.8629 0.5 0 27.3629 0 60.5C0 93.6372 26.8629 120.5 60 120.5Z"
      />
      <Path
          fillColor={fillColorTwo}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.6556 13.2578C65.6556 13.0164 70.5176 13.3268 75.1384 14.2923C83.2761 15.9819 91.0693 19.6371 96.3795 26.2923C103.897 35.6716 106.897 47.4647 106.139 60.0854C105.207 76.2237 97.2764 90.6716 85.2421 99.8442C78.3104 105.12 69.7245 108.672 60.2418 108.672C46.4487 108.706 34.897 100.74 26.5521 91.3959C18.6556 82.5335 13.4487 71.0164 12.759 58.4992C12.759 58.3613 12.759 58.1888 12.7245 58.0509C12.5866 54.844 12.828 51.6371 13.5521 48.5681C15.6556 39.3613 21.2763 31.913 28.0694 26.1544C36.828 18.6371 48.2418 13.8785 60.6556 13.2578Z"
      />
      <Path
          fillColor={fillColorThree}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.3099 26.0516C65.1031 25.5688 68.7927 25.2585 72.4134 25.4309C78.6548 25.7068 85.3442 27.3274 88.9654 33.1896C93.9304 41.224 93.7581 50.362 92.2752 59.7413C90.4822 71.0171 85.3442 81.4652 77.6203 89.0513C73.172 93.3966 67.3445 96.9137 60.4824 96.9484C50.172 96.983 42.5858 88.3623 36.8272 81.2927C31.1031 74.2932 26.9306 65.7068 25.4824 56.5343C25.4824 56.4309 25.4479 56.3275 25.4479 56.1896C25.1376 53.9136 25.172 51.6033 25.7237 49.3965C27.4134 42.7757 32.5514 38.4309 37.7237 34.9826C44.4824 30.3964 52.6203 27.1896 61.3099 26.0516Z"
      />
      <Path
          fillColor={fillColorFour}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.1392 54.5689C40.3806 60.3619 43.5531 66.0172 47.0358 71.1551C50.2427 75.8789 53.8289 85.2239 60.6564 85.1554C64.8978 85.1208 67.9668 81.6376 69.8978 78.2244C73.3461 72.224 75.8291 65.7758 78.3459 59.3619C80.7602 53.2241 83.9669 46.7068 81.5186 40.0516C79.6565 34.9827 74.0016 35.4309 69.6565 36.5344C67.0703 37.1895 64.4841 38.0171 61.9323 38.8102C57.0013 40.362 52.1737 42.224 47.2427 43.8792C43.7254 45.0861 39.0013 46.2585 37.76 50.293C37.3462 51.6033 37.5531 53.0516 38.0358 54.3619C38.1048 54.4309 38.1048 54.4999 38.1392 54.5689Z"
      />
    </SVG>
)
PureVPNIconOnlySVG.propTypes = {
  fill: PropTypes.string,
}
PureVPNIconOnlySVG.defaultProps = {
  width: '120',
  height: '121',
  fillColorOne: '#3B108E',
  fillColorTwo: '#6536CC',
  fillColorThree: '#8260EF',
  fillColorFour: 'white',
  className: 'svg-icon'
}
export default PureVPNIconOnlySVG