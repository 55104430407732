import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import React, {Suspense, useState} from 'react'
import { Input, ModalFooter } from 'reactstrap'
import Payment from '../component/dashboard/subscriptions/recurly/Payment'
import PaypalBtn from '../component/dashboard/subscriptions/recurly/PaypalBtn'
import { InfoCard, PurchaseBox, WhmcsSubscriptionNote } from '../styles/dashboard/Main'
import { DestructBtn } from '../styles/Generic'

 const PopupPurchaseUtil = ({...props}) => {

    const [icon, seticon] = useState("card");

    const {currentSub, chargeAttempt, toggle, endPoint, selectedData, isAllowToUseParamMethod,subData={}, isWhmcsUser} = props
    
    
    return (
        <>
            <ModalFooter className={`${isWhmcsUser ? 'p-0 display-block w-100' : 'p-0 pt-3'} payment-modal`}>
                <InfoCard noflex className="p-0 m-0">
                    <PurchaseBox type="payment" icon={icon}>
                        <Input className={isWhmcsUser ? 'custom-for-whmcs' : ''} type="select" name="select" onChange={e=>seticon(e.target.value)}>
                            <option selected={icon===""} value="">Select payment gateway</option>
                            <option selected={icon==="card"} value="card">Credit card</option>
                            <option selected={icon==="paypal"} value="paypal">Paypal</option>
                        </Input>
                    </PurchaseBox>
                </InfoCard>
                <InfoCard className="py-0 mx-0">
                    <div className="recurly-wrapper">
                    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
                        <Elements>
                            {
                                icon === "card" 
                                ?
                                <>
                                    <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                        <Payment isAllowToUseParamMethod={isAllowToUseParamMethod} selectedData={selectedData} seticon={seticon} endPoint={endPoint} currentSub={currentSub} popupPurchase chargeAttempt={chargeAttempt} toggle={toggle} subData={subData} subscription_id={currentSub.id} />
                                    </Suspense>
                                </>
                                : icon === "paypal" ?
                                <>
                                    <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                        <PaypalBtn isAllowToUseParamMethod={isAllowToUseParamMethod} selectedData={selectedData} seticon={seticon} endPoint={endPoint} currentSub={currentSub} popupPurchase chargeAttempt={chargeAttempt} toggle={toggle} subData={subData} subscription_id={currentSub.id} />
                                    </Suspense>
                                </>
                                :
                                <DestructBtn type="button" onClick={toggle} className="mt-3">Cancel</DestructBtn>
                            }
                        </Elements>
                    </RecurlyProvider>
                    </div>
                </InfoCard>
            </ModalFooter>
        </>
        
  )
}

export default PopupPurchaseUtil
