import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from "../tools/responseMessages"

export const useDispatchAsyncApi = (dispatcher, reducer, key) => {

    const [crashed, setCrashed] = useState(false)
    
    const [data, setData] = useState(null)

    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState('')
   
    const dispatch = useDispatch()

    const token = localStorage.getItem("token")

    useEffect(() => {
      dispatcher(token)
    }, [])
    
    useEffect(() => {
        if(!reducer['loading']){
            setLoading(false)
        }
        if(reducer[key] && !reducer['loading']){
            setData(reducer[key]);
        }
        if(reducer['error']){
            setErrorMsg(reducer['errorMessage'])
            setLoading(false)
        }
    }, [reducer])

    useEffect(() => {
        const { errors, errorType } = reducer;
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(TOKENEXPIRED);
                    dispatch({type:"LOGOUT"})
                    break;
                case "jwt_exception":
                    toast.error(APIFAILED)
                    dispatch({type:"LOGOUT"})
                    break; 
                case "token_invalid":
                case "jwt_default_exception":
                    toast.error(INVALIDTOKEN)
                    dispatch({type:"LOGOUT"})
                    break;   
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [reducer])
    
    return {data, errorMsg,loading}
}