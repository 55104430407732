import React, { useEffect, useState } from 'react'
import { GridIconViewWrapper, GridIcon } from '../../../styles/dashboard/Main'
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';

const GridIconView = ({history, accountReducer, onBoardingReducer, step, appSlug, selectedStep, selectStepCallback, next, purpose, setPurposeCallback}) => {

    const token = localStorage.getItem("token");

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const loading = step?.data.length > 0 ? false : true;

    useEffect(() => {


    }, [onBoardingReducer, step])

    const skeleton = (html) => {
        return loading ? (
            <>
                <Skeleton height={20} width={300} className="onboarding-grid-view-wrapper-loading"/>
            </>
        ) : html
    }

    const gridIconClickHandle = (index, data) => {

        setPurposeCallback(data.slug,data.connection_type)

        selectStepCallback(selectedStep + 1)
    }

    return skeleton(
        <GridIconViewWrapper>
            {
                step.data.length > 0 ? (
                    <>
                    <div id='enhanced-conversion-linker' data-useremail={onBoardingReducer?.subscriptions?.[0]?.account?.email ?? ""}></div>
                    <div className='purposes'>
                        <h2 className='heading'>{t('onboarding_purevpn_purpose_para')}</h2>
                        <div className='row boxes'>
                            {
                                step.data.map( (data, idx) => {
                                    return (
                                        <div key={idx} className={`box col-md-3 col-sm-6 col-xs-12`} >
                                            <div className={`inner-box ${ (data.slug == purpose) ? "active" : "" }`} onClick={ () => gridIconClickHandle(idx, data) }>
                                                <GridIcon icon={data.icon} />
                                                <h2 className='heading'>{data.heading}</h2>
                                                <p className='para'>{data.text}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </>
                ) : null
                
            }
        </GridIconViewWrapper>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(GridIconView)