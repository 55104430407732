import React, { useState } from "react";
import {
	InfoCard,
	PopupBody,
	PurchaseBox,
	SubscriptionCancelWrapper,
} from "../../../../styles/dashboard/Main";
import { Modal, ModalBody, Input, ModalFooter } from "reactstrap";
import { CancelBtn, DestructBtn, InsetBtn, PrimaryBtn } from "../../../../styles/Generic";
import { useLocation, withRouter } from "react-router-dom";
import { countriesList } from "../../../../tools/countries";
import qs from "qs";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { buttonText, postMethod } from "../../../../tools/genericMethods";
import CountryFlag from "../Flag";
import Wireguard from "../../wireguard";
import { sendEvents, sendGuestEvents } from "../../../../tools/mpEvents";
import { getDevices } from "../../../../redux/wgDevices/action";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import GenerateConfigurationServer from "./GenerateConfigurationServer";
import UsernameInput from "./UsernameInput";
import ErrorScreen from "./ErrorScreen";
import copy from "../../../../assets/copy.svg";
import NoPFBox from "../NoPFBox";
import { useDispatchAsyncCall } from '../../../../custom-hooks/useDispatchAsyncCall';
import { getOnBoardingRequested } from '../../../../redux/on-boarding/actions';
import { browserName, isWindows, isMacOs, osName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const DownloadModal = ({
	backdrop,
	toggle,
	modal,
	modalWidth,
	widthCalculator,
	data,
	fileData,
	availableProtocols,
	iso,
	wgDevicess,
	serverType,
	getServer,
	pfAndActive,
	isPfSub,
	currentSubData,
	onBoardingReducer,
	isPFAppliedAndActive,
	multiLoginLimitExceededError
}) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	const [protocolArray] = useState([
		"WireGuard",
		"TCP",
		"UDP",
		"IKEV",
		"IPSEC",
	]);


	const [protocol, setProtocol] = useState("");

	const [isLoading, setLoading] = useState(true);

	const [error, setError] = useState(false);

	const token = localStorage.getItem("token");

	const [selectedUser, setSelectedUsername] = useState(null);

	const [reduxData, setReduxData] = useState();

	const [MCDisplayableData, setMcDisplayableData] = useState(null);

	const [DownloadWGDisplayableData, setDownloadWGDisplayableData] =
		useState(false);
	
	const [hideSelect,setHideSelect] = useState(false)

	const [selectState,setSelectState] = useState(false);

	const [crashedStatus,setCrashedStatus] = useState(false);

	const [protocolError,setProtocolError] = useState(false);

	const [ProtocolErrorMessage] = useState('The protocol you selected does not have any servers available at the moment. Try another location')

	const [MCError,setMCError] = useState(false);
	
	const [deviceError,setDeviceError] = useState(false);

	const [hideCancel,setHideCancel] = useState(false)

	useEffect(() => {
		dispatch({ type: "GET_DEVICES_REQUESTED", token: token });
		
		sendEvents(
			{
			  event_name: "ma_click_download_manual_config",
			  event_properties: {
				country: data?.name,
				city: data?.city?.name,
				is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
				is_p2p_server_requested: serverType?.includes("p2p")
			  },
			},
			token
		  );
	}, []);
	useEffect(() => {
		const { loading, errors, devices, errorMessage, errorType } =
			wgDevicess;

		if (!loading && devices?.length) {
			setLoading(false);
		}
		if (devices?.length) {
			setReduxData(devices);
			setLoading(false);
			setDeviceError(false);
		}
		if (errors) {
			setLoading(false);
			setDeviceError(errors);
		}
	}, [wgDevicess]);

	const handleChange = (e) => {
		setError(() => (e.target.value ? false : true));
		setProtocol(e.target.value);
		if(!availableProtocols.includes(e.target.value)){
			sendEvents(
				{
				  event_name: "ma_fail_generate_manual_config",
				  event_properties: {
					protocol:protocol,
					country: data?.name,
					city: data?.city?.name,
					reason:ProtocolErrorMessage,
					is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
					is_p2p_server_requested: serverType?.includes("p2p")
				  },
				},
				token
			  );
			setProtocolError(true)
			return
		}
		setProtocolError(false)
		setMCError(false);
	};
	const handleSubmit = (configuration, fileName) => {
		let isProtocolValid = protocol != "IKEV" || protocol != "IPSEC";
		if (protocol != "WireGuard") {
			try {
				if (!configuration && !fileName) {
					throw new Error("No configuration version was found");
				}

				let fileNameIso = fileName;

				if(protocol==="UDP"){
					let reValue = / proto udp/i;
					configuration = configuration.replace(reValue,'proto udp')
				}

				var blob = new Blob([configuration], {
					type: "application/conf",
				});
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);

				link.download = `${fileNameIso}-ovpn-${protocol.toLowerCase()}.ovpn`;
				link.click();
			} catch (e) {
				toast.error("Unable to Download the file");
					sendEvents(
						{
							event_name: "ma_fail_download_openvpnconfig",
							event_properties: {
								country: iso ?? "N/A",
								protocol: e.message,
							},
						},
						token
					);
				
			}
		}

	};
	const closeModal = () => {
		setMcDisplayableData(null);
	};
	const copyWGConfigurations = async (configuration) => {
		try {
			await navigator.clipboard.writeText(configuration);
			toast.success("Configurations Copied Successfully!");
			sendEvents(
				{
					event_name: "ma_copy_manual_config",
					event_properties: {
						protocol:protocol
					},
				},
				token
			);
		} catch (error) {
			toast.error("Unable to copy!");
		}
	};
	const cancelModal = () =>{
		toggle()
		sendEvents(
			{
			  event_name: "ma_click_cancel_manual_config",
			  event_properties: {
				protocol:protocol ? protocol : "Not Selected",
				country: data?.name,
				city: data?.city?.name,
				is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
				is_p2p_server_requested: serverType?.includes("p2p")
			  },
			},
			token
		  );

	}
	return (
		<div>
			<Modal
				backdrop={backdrop}
				isOpen={modal}
				toggle={toggle}
				style={{
				maxWidth: `${modalWidth[widthCalculator]}`,
				width: "100%",
				}}
				className="modal-wrapper"
			>
				{!isLoading ? (
					<>
					{
					/*
						Show CONFIGURATION if user :
						(isPfSub && !pfAndActive) => if the user doesnot has PF add-on and and filter is not applied
						OR
						(!pfAndActive) => PF filter is not enforced 
						OR 
						isPfSub => subscription has PF addon
						ELSE:
						Ask user to purchase PF add-on	
					*/
					}
					{isPFAppliedAndActive() ? 
						<>
						<ModalBody className="pb-0">
							<div className="closeButton float-right"></div>
							<PopupBody className="">
								<div className={`mod-head ${protocol==="WireGuard" && "mb-3" }`}>
									{!MCDisplayableData ? 
									<>
									{DownloadWGDisplayableData || hideSelect ? 
									<>
									<h3 className="my-0">{t('manualconf_wiregaurd_title')}</h3>
									</>
									
									:
									<>
									<h3>{t('manualconf_wiregaurd_title_two')}</h3>
									<p>
									{t('manualconf_wiregaurd_title_para')}
									</p>
									</>
									
									}
									</>:

									['TCP','UDP'].includes(protocol) ? 
									<h3>{protocol + " Server Configuration"}</h3> : 
									<h3>{protocol + " Server Address"}</h3>
									}
								</div>
								<div className="mod-body">
									{!MCDisplayableData ? (
										!hideSelect && (
											<InfoCard noflex className="p-0">
												<div className="country align-items-center">
													<CountryFlag
														iso={data.iso.toLowerCase()}
														modalView={true}
													/>
													<h3>
														<strong>
															{`${
																data?.city
																	? data?.city
																			?.name +
																	  ", "
																	: ""
															}`}
															{data?.name
																? data?.name
																: data?.city
																		?.countryName}
														</strong>
													</h3>
												</div>
												<p className="mt-2">{t('manualconf_wiregaurd_select_protocol')}</p>
												<Input
													type="select"
													name="select"
													value={protocol}
													onChange={handleChange}
													className="mt-2"
													disabled={selectState}
												>
													<option value="">
														{t('manualconf_wiregaurd_select_protocol')}
													</option>
													{protocolArray.map((protocol, key) => (
														<option
															key={key}
															value={protocol}
														>
															{protocol}
														</option>
													))}
												</Input>
												{(error) && (
													<p className="danger mt-2 ps-1">
														{t('manualconf_wiregaurd_protocol_required')}
													</p>
													
												)}
												
												{protocol && !protocolError && (
													<UsernameInput
														setSelectedUsername={
															setSelectedUsername
														}

														setCrashedStatus={
															setCrashedStatus
														}
														setProtocolError={setProtocolError}
													/>
												)}
											</InfoCard>
										)
									) : (
										<InfoCard className="py-0 d-flex flex-column  align-items-start">
											<table className="configurationTable">
												<tr>
													<label>
													<td>{t('manualconf_wiregaurd_location')} </td>
													<td>
														<strong>
															{`${
																MCDisplayableData?.cityName
																	? MCDisplayableData?.cityName +
																	  ", "
																	: ""
															}`}
															{
																MCDisplayableData?.countryName
															}
														</strong>
													</td>
													</label>
												</tr>
												<tr>

												</tr>
												<tr>
													<label className="d-flex align-items-center">
													<td>{t('manualconf_wiregaurd_server')}</td>
													<td>
														<strong className="mx-0">
															{
																MCDisplayableData?.MCHost
															}
														</strong>
													</td>
													<img
												src={copy}
												className="copyButton mt-1"
												onClick={(e) =>
													copyWGConfigurations(
														MCDisplayableData?.MCHost
													)
												}
											/>
													</label>
												</tr>
												{(MCDisplayableData?.MCErrorOBF || MCDisplayableData.MCErrorQR) 
												&& <tr>
													<label>
													{/* <td> */}			
														<SubscriptionCancelWrapper coloredVal>
														<InfoCard className="d-block py-1">
															<div className="warning-box">
																<span className="warning-icon"></span>
																<p className="warning-text">
																{(MCDisplayableData.MCErrorOBF && getServer=== "OVPN_OBF") ? "OBF" : (MCDisplayableData?.MCErrorQR ? "QR" : "OBF")} {t('manualconf_wiregaurd_switch_protocol_para')}
																</p>
															</div>
														</InfoCard>
														</SubscriptionCancelWrapper>
													</label>
												</tr>}
											</table>

											<div className="float-none"></div>
											{/* </table> */}
										</InfoCard>
									)}
								</div>
							</PopupBody>
						</ModalBody>

						<ModalFooter
							className={protocol === "WireGuard" ? " py-0 " : ""}
						>
							
							{
							(protocolError || MCError) && protocol &&
								<SubscriptionCancelWrapper coloredVal fullWidth>
								<InfoCard className="d-block">
									<div className="warning-box">
										<span className="warning-icon"></span>
										<p className="warning-text">
											{MCError ? MCError : ProtocolErrorMessage}
										</p>
									</div>
								</InfoCard>
								</SubscriptionCancelWrapper>
							}
							{!protocolError &&  protocol === "WireGuard" ? (
								<>
									{
										<Wireguard
											countryInfo={data}
											closeModal={toggle}
											devicesData={reduxData}
											selectedUser={selectedUser}
											setHideSelect={
												setHideSelect
											}
											setDownloadWGDisplayableData={setDownloadWGDisplayableData}
											setSelectState={setSelectState}
											serverType={serverType}
											deviceError={deviceError}
											crashedStatus={crashedStatus}
											currentSubData={currentSubData}
											multiLoginLimitExceededError={multiLoginLimitExceededError}
										/>
									}
								</>
							) : (
								<>
									{protocol && !protocolError &&
										!MCDisplayableData &&
										(selectedUser?.isRefuneded ===
										"false" ? (
											<>
												<GenerateConfigurationServer
													setMcDisplayableData={
														setMcDisplayableData
													}
													countryInfo={data}
													protocol={protocol}
													handleSubmit={handleSubmit}
													selectedUser={selectedUser}
													setDownloadWGDisplayableData={setDownloadWGDisplayableData}
													setSelectState={setSelectState}
													serverType={serverType}
													setMCError={setMCError}
													getServer={getServer}
													setHideCancel={setHideCancel}
													currentSubData={currentSubData}
													multiLoginLimitExceededError={multiLoginLimitExceededError}
												/>
											</>
										) : (
											<ErrorScreen closeModal={toggle} protocol={protocol} crashedStatus={crashedStatus} />
										))}

									{(!protocol || protocolError || selectedUser?.isRefuneded === "false" )&& !hideCancel && (
										<>
										<DestructBtn color="primary" onClick={cancelModal}>
											Cancel
										</DestructBtn>
										{
										(['TCP', 'UDP'].includes(protocol) && isWindows) &&
											<p className="low-visiblity mt-3 smallerBody">
												Note* Place the downloaded file as mentioned below.<br></br>
												For 64bit OS: <b>C:\Program Files\OpenVPN\config</b><br></br>
												For 32Bit OS: <b>C:\Program Files(x86)\OpenVPN\config</b><br></br>
											</p>
										}
										</>
									)}
								</>
							)}
						</ModalFooter></> : 
						<div className="mx-2">
							<NoPFBox currentSubData={currentSubData} isPfSub={isPfSub} cancelModal={toggle}/>
							<DestructBtn className="low-visiblity" onClick={cancelModal}>
								Close
							</DestructBtn>
							
						</div>
						}
					</>
				) : (
					<ModalBody>
						<div
							className="mod-body"
							style={{ minHeight: "200px" }}
						>
							<Skeleton className="d-block mb-3" width={300} />
							<Skeleton className="d-block mb-3" width={280} />
							<Skeleton className="d-block mb-3" width={260} />
							<Skeleton className="d-block mb-3" width={240} />
							<Skeleton className="d-block mb-3" width={220} />
							<Skeleton className="d-block mb-3" width={50} />
						</div>
					</ModalBody>
				)}
			</Modal>
		</div>
	);
};

const mapStatetoProps = (state) => {
	return {
		wgDevicess: state.wgDevices,
		onBoardingReducer: state.onBoardingReducer
	};
};
export default connect(mapStatetoProps)(DownloadModal);
