import React, { useState, Suspense, lazy } from 'react'
import {  InfoCard, PopupBody, PurchaseBox } from '../../../../styles/dashboard/Main'
import { Modal, ModalBody, Input } from 'reactstrap';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { DestructBtn } from '../../../../styles/Generic';
import { useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Payment = lazy(()=>import(/* webpackChunkName: "recurlycard" */"../recurly/Payment"))
const PaypalBtn = lazy(()=>import(/* webpackChunkName: "recurlypaypal" */"../recurly/PaypalBtn"))

const PaymentMethod = ({backdrop, toggle, modal, modalWidth, history, widthCalculator, selectedSubscription}) => {

    const { pathname } = useLocation()
    const [icon, seticon] = useState("card");

    const { t, i18n } = useTranslation();

    const resetstate = () => {
        clearPath()
        seticon("")
        toggle()
    }

    const clearPath = () => {
        history.replace(pathname, '')
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
                <ModalBody>
                    <PopupBody>
                        <div className="mod-head">
                            <h3>{t('subscription_change_payment_method_cta')}</h3>
                        </div>
                        <div className="mod-body">
                            <InfoCard noflex className="p-0">
                                <PurchaseBox type="payment" icon={icon}>
                                    <Input type="select" name="select" onChange={e=>seticon(e.target.value)}>
                                        <option selected={icon===""} value="">{t('payment_method_text')}</option>
                                        <option selected={icon==="card"} value="card">{t('credit_card_text')}</option>
                                        <option selected={icon==="paypal"} value="paypal">Paypal</option>
                                    </Input>
                                </PurchaseBox>
                            </InfoCard>
                            <InfoCard className="py-0">
                                <div className="recurly-wrapper">
                                    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
                                        <Elements>
                                            {
                                                icon === "card" 
                                                ?
                                                <>
                                                    <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                        <Payment subscription_id={selectedSubscription?.id} resetstate={resetstate} seticon={seticon} />
                                                    </Suspense>
                                                </>
                                                : icon === "paypal" ?
                                                <>
                                                    <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                        <PaypalBtn subscription_id={selectedSubscription?.id} resetstate={resetstate} seticon={seticon} />
                                                    </Suspense>
                                                </>
                                                :
                                                <DestructBtn type="button" onClick={resetstate} className="mt-3">{t('cancel_text')}</DestructBtn>
                                            }
                                        </Elements>
                                    </RecurlyProvider>
                                </div>
                            </InfoCard>
                        </div>
                    </PopupBody>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default withRouter(PaymentMethod)