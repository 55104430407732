import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { PureAIWaitlistView } from "../../../../styles/dashboard/PureAI";
import demoVideo from "../../../../assets/pure-ai-waitlist.mp4";
import EarlyAccessCard from "./EarlyAccessCard.js";
import WaitListModal from "./WaitListModal";
import aiLogoVector from '../../../../assets/ai-logo-vector.svg'
import tvVector from '../../../../assets/pure-ai-waitlist-img-2.svg'
import gameVector from '../../../../assets/pure-ai-waitlist-img-3.svg'
import PureAILogo from '../../../../assets/pure-ai-logo-large.svg'
import { ACTIONS } from '../../../../redux/pureai/reducer';
import { sendEvents } from '../../../../tools/mpEvents';
import { skeleton, videoSkeleton } from './helper';


const PureAIWaitList = ({ pureAIStateReducer }) => {

    const dispatch = useDispatch();

    const token = localStorage.getItem("token");

    const { currentState } = pureAIStateReducer;

    const openWaitlistModal = () => {
        return (
            <WaitListModal/>
        );
    }

    const clickJoinWaitList = () => {
        dispatch({type:ACTIONS.WAITLIST_MODAL, visibility: true});
        sendEvents({
            event_name: "ma_click_join_waitlist",
            event_properties: {
                "source": "Pure AI"
            }
        }, token);
    }

    const paDescription = "ChatGPT logs user IP address and identity to provide services, making sensitive data (like IP address) at risk. Our product offers a solution to this problem, as PureSquare(previously PureAI) will be interacting with OpenAI on behalf of our users, providing peace of mind for users.";
    const streamDescription = "PureAI can help users discover the best movies or titles to watch based on their moods, and find which platform supports the content they want to watch in which region/country";
    const gameDescription = "PureAI can assist users in finding cheap game licenses and playing multiplayer games, and also provide solutions for remote access to home systems while abroad.";
    return (
        <>
            <PureAIWaitlistView>
                <div className="main-section">
                    <div className="logo">
                        {
                            skeleton (
                            <>
                            <img src={PureAILogo} alt="PureAI Logo"/>pure<span>AI</span>
                            <div className="tagline">Your Virtual Assistant</div>
                            </>
                            , currentState.loading, 60)
                        }
                    </div>
                    <div className="demo-video">
                    {
                        videoSkeleton (
                            <video autoPlay loop muted playsInline src={demoVideo} className='videoPlay'></video>
                        , currentState.loading)
                    }
                    </div>
                    {skeleton(<div className="tagline">Get Early Access</div>, currentState.loading)}
                    <div className="waitlist">{skeleton(<button onClick={clickJoinWaitList}>Join Waitlist</button>, currentState.loading)}</div>
                </div>
                <div className="side-section">
                    <EarlyAccessCard title="Privacy and anonymity" description={paDescription} image={aiLogoVector} extraClass="pa"/>
                    <EarlyAccessCard title="Watch Any Show From Anywhere" description={streamDescription} image={tvVector} extraClass="streaming"/>
                    <EarlyAccessCard title="Buy Cheap Games!" description={gameDescription} image={gameVector} extraClass="gaming last"/>
                </div>
            </PureAIWaitlistView>
            { currentState.showWaitlistModal ? openWaitlistModal() : null }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(PureAIWaitList);