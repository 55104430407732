export const teamsContent = {
  features: [
    {
      name: "Team Management",
      desc: "Easily add up to 200 members and manage them accordingly through our centralized admin dashboard.",
      icon: "team-members"
    },
    {
      name: "Team Server",
      desc: "Assign a single IP address to all your team members and enable seamless collaboration.",
      icon: "team-servers"
    },
    {
      name: "Static Dedicated IP",
      desc: "Allocate a static dedicated IP address to each member from any of the 10 different locations.",
      icon: "ded-ip"
    }
  ]
}

export const tableContent = [
  {
    title: "Centralized Billing ",
    description: ['No', 'Yes', 'Yes', 'Yes']
  },
  {
    title: "# of VPN Accounts",
    description: ['1', '5', '200', 'No Limit']
  },
  {
    title: "# of Dedicated IPs allowed",
    description: ['1', '1', 'No Limit', 'No Limit']
  },
  {
    title: "Team Server/Single IPs for Teams",
    description: ['No', 'No', 'Yes', 'Yes']
  },
  {
    title: "# of Team Server allowed",
    description: ['0', '0', '1', 'No Limit']
  },
  {
    title: "Two-factor Authentication",
    description: ['Yes', 'Yes', 'Yes', 'Yes']
  },
  {
    title: "Ability to Create Teams",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Ability to define Roles of Members",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Ability to assign specific IPs to specific teams/users",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Admin Panel",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Reporting of VPN Sessions",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Device Posture Check",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "Remote User Configuration",
    description: ['No', 'No', 'No', 'Yes']
  },
  {
    title: "No Additional Setup",
    description: ['Yes', 'Yes', 'Yes', 'No']
  },
  {
    title: "Who should use?",
    description: ['Individuals such as Consumers, Home Users, Students, Gamers, etc', 'Families', ' Small & Medium Businesses, etc', 'Enterprises, etc']
  }, 
]

// temporary
export const tableContentForApps = [
  {
    title: "pre_purchase_table_heading_one",
    description: ['no', 'yes', 'yes', 'yes']
  },
  {
    title: "pre_purchase_table_heading_two",
    description: ['1', '5', '200', 'no_limit']
  },
  {
    title: "pre_purchase_table_heading_three",
    description: ['1', '1', 'no_limit', 'no_limit']
  },
  {
    title: "pre_purchase_table_heading_four",
    description: ['no', 'no', 'yes', 'yes']
  },
  {
    title: "pre_purchase_table_heading_five",
    description: ['0', '0', '1', 'no_limit']
  },
  {
    title: "pre_purchase_table_heading_six",
    description: ['yes', 'yes', 'yes', 'yes']
  },
  {
    title: "pre_purchase_table_heading_seven",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_eight",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_nine",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_ten",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_eleven",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_twelve",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_thirteen",
    description: ['no', 'no', 'no', 'yes']
  },
  {
    title: "pre_purchase_table_heading_fourteen",
    description: ['yes', 'yes', 'yes', 'no']
  },
  {
    title: "pre_purchase_table_heading_fifteen",
    description: ['Individuals such as Consumers, Home Users, Students, Gamers, etc', 'Families', ' Small & Medium Businesses, etc', 'Enterprises, etc']
  }, 
]

export const benefits = [
  {
    heading: "Teams admin panel",
    paragraph: "Easily add and manage up to 200 members through centralized teams admin panel.",
    slug: 'panel'
  },
  {
    heading: "Secure unlimited devices",
    paragraph: "With 200 separate accounts, your team members can shield unlimited devices from online threats.",
    slug: 'devices'
  },
  {
    heading: "Safer connectivity",
    paragraph: "Your team can experience safer remote access and connectivity with complete peace of mind.  ",
    slug: 'connectivity'
  },
]

export const upgradeTeamBenefits = [
  {
    heading: "Servers for Entire Team",
    paragraph: "Team Servers offers a special hosting solution that lets 50 team members work together smoothly, sharing a single IP address.",
    slug: 'team-servers'
  },
  {
    heading: "Dedicated IP for Individuals",
    paragraph: " Effortlessly allocate static dedicated IP addresses from a selection of 30 different locations to each member of your team.",
    slug: 'ded-ip'
  }
]

export const tableContentRevised = [
  "Centralized Billing ",
  "# of VPN Accounts",
  "# of Dedicated IPs allowed",
  "Team Server/Single IPs for Teams",
  "# of Team Server allowed",
  "Two-factor Authentication",
  "Who should use?",
  "Price"
]
 export const individualContent = [
  'No',
  '1',
  '1',
  'No',
  '0',
  'Yes',
  'Individuals',
 ]
export const familyContent = [
  'Yes',
  '5',
  '1',
  'No',
  '0',
  'Yes',
  'Families'
]
export const teamContent = [
  'Yes',
  'Up to 200',
  'No Limit',
  'Yes',
  '1',
  'Yes',
  'Small & Medium Businesses,etc',
]