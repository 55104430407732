import React, {useEffect} from 'react'
import { AutoLoginWrapper } from '../styles/Generic'
import loginlogo from '../assets/loginlogo.png'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import qs from 'qs'
import { api, AsideConditionalRendering, RandomNumGenarator } from '../tools/tools'
import { APIFAILED, INVALIDTOKEN, SUCCESS } from '../tools/responseMessages'
import { getSubscriptionsRequest } from '../redux/subscriptions/actions'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

const OauthAuthorization = ({history, onBoardingReducer, getSubscriptionsRequest}) => {

    const { t, i18n } = useTranslation();

    const { search } = useLocation();
    const {uuid, redirect_to, type, subscription_id, app,slug, currency, redirect_from,code , update_subscription, open_cart, utm_source, utm_medium, external_link, utm_campaign, redirect_uri, modal, locale,platform,subscriptionId, client_answer, campaign_name, plan_type ,billing_cycle, features_metric, feature_name, id} = Object.fromEntries(new URLSearchParams(search));

    const formdata = qs.stringify({
        "uuid": uuid ?? undefined,
        "code": code ?? undefined,
        "id": id ?? undefined,
    });
    
    const pagesWithOutSuccessToast = ["apppurchase", "appupgrade"];

    const dispatch = useDispatch()

    const redirectWithParams = (route, params) => {
        const defaultParams = (utm_source || utm_medium || utm_campaign) ? `${params ? '&' : '?'}utm_source=${utm_source ?? ''}&utm_medium=${utm_medium ?? ''}&utm_campaign=${utm_campaign ?? ''}` : ""
        history.push({
            pathname:route,
            search: `${params}${defaultParams}`
        })
    }

    const utmCampaign = (addon_code,subscriptionId=null) => {
        return subscriptionId ? `?app=${addon_code ?? ''}&slug=${addon_code ?? ''}&subscriptionId=${subscriptionId}` : `?app=${addon_code ?? ''}&slug=${addon_code ?? ''}`
    }

    useEffect(() => {
        localStorage.setItem("closeHelloBar", false)
        localStorage.setItem("bannerClosed", false)
        async function apifunc(){
            try {
                //get locale from url and save it in local storage
                localStorage.setItem("locale", i18n.language)
                const login = await api("fusionauth/login-v2", formdata);
                const {data:{status, error_code}} = login;
            if(status){
                
                !pagesWithOutSuccessToast.includes(redirect_to) && toast.success(t(SUCCESS));

                const {data:{body:{token}}} = login;
                dispatch({type:"EMPTYSTORE"})
                localStorage.setItem("token", token)
                AsideConditionalRendering();
                RandomNumGenarator();
                switch (redirect_to) {
                    default:
                        redirectWithParams(`/${(redirect_to && redirect_to !== '') ? redirect_to : process.env.REACT_APP_DEFAULT_AUTOLOGIN_LINK}`, "")
                        break;
                }
            }else{
                switch (error_code) {
                    case "login_exception":
                        toast.error("Account doesn't found.");
                        history.push("/beta-login")
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        history.push("/beta-login")
                        break;
                }
            }
            } catch (error) {
                toast.error(t(APIFAILED));
                history.push("/login")
            }
        }
        apifunc()
    }, [])

    return (
        <AutoLoginWrapper> 
            <div className="text-center">
                <img src={loginlogo} className="img-fluid" />
                <h1>{t('login_text_member_area')}</h1>
                <p>{t('login_text_opening_member_area_text')}</p>
                <p className="mt-5 mb-3">{t('login_text_please_wait')}</p>
                <span className="spinner-border"></span>
            </div>
        </AutoLoginWrapper>
    )
}
const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};
export default connect(mapStateToProps, {getSubscriptionsRequest})(OauthAuthorization)