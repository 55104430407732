import { customStyles } from '../table/tableCustomStyles';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Delete from '../../../../assets/trash.png';
import ErrorBoundary from '../../../ErrorBoundary';
import TableLoader from '../table/tableLoader';
import { FlagIcon } from '../../../../styles/flags/Flag';
import { countriesList } from '../../../../tools/countries';
import { toast } from "react-toastify";

const TeamTable = ({ totalRows, teamMembers, deleteSelectedMembers, isTeamMembersLoaded, teamMembersErrorMsg, fetchCountAndMembers, setPage, perPage, setPerPage, selectedSubscriptionData }) => {
  const { t } = useTranslation();
  
  const columns = [
      {
          name: 'Member',
          selector: row => row.vpnusername,
          cell: row => <div className='member'>
            <img src={`https://ui-avatars.com/api/?background=D9D0FF&color=001219&name=${row.email?.substring(0, 2)}`} width={36} height={36} alt={row.name}></img>
            <div className='info'>
              <div className='email'>{row.email}</div>
            </div>
          </div>,
          grow: 1,
          minWidth: '300px',
      },
      {
        name: 'Username',
        selector: row => row.vpnusername,
        cell: row => <div className='member'>
          <div className='info'>
            <div className='email'>{row.vpnusername}</div>
          </div>
        </div>,
        grow: 0.5,
        minWidth: '200px',
      },
      {
        name: 'Role',
        selector: row => row.vpnusername,
        cell: row => <div className='member'>
          <div className='info'>
            <div className={`role ${!row.is_parent ? 'child' : null}`}>{row.is_parent ? 'Team Admin' : 'Member'}</div>
          </div>
        </div>,
        grow: 0.5,
        minWidth: '200px',
      },
      {      
        name: 'dedicated IP',
        cell: row => row.dedicated_ip_details ?
         <div className='server-ip-cell'>
             <FlagIcon className='m-0'>
               <span className={`bg-${row.dedicated_ip_details.country_code?.toLowerCase()}`}></span>
             </FlagIcon>
             <div className='info'>
               <h5>{row.dedicated_ip_details.name ?? countriesList[row.dedicated_ip_details.country_code]}</h5>
               <div className='details'>
                 <p className='ip'>{row.dedicated_ip_details.ip}</p>
                 <p className='host' title={row.dedicated_ip_details.hostname}>{row.dedicated_ip_details.hostname?.substring(0, 25)+(row.dedicated_ip_details.hostname.length>25 && '...')}</p>
                 <button onClick={copyHost} data-hostname={row.dedicated_ip_details.hostname}></button>
               </div>
             </div>
           </div> : <p>---</p>,
        selector: row => row.vpnusername,
        grow: 2,
        minWidth: '400px',
      },
      {
          name: 'Action',
          selector: row => <div className='action'>
            {(!row.is_parent && !selectedSubscriptionData?.is_child) ? <img className="dots" src={Delete} alt={row.name} onClick={()=>deleteSelectedMembers(row.subscription_id, row.email)}></img> : null}
          </div>,    
          grow: 1,
          width: '100px'
      },
  ];

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }

  const paginationComponentOptions = {
    noRowsPerPage: true
  };

  const copyHost = async (e) => {
    try {
        await navigator.clipboard.writeText(e.target.getAttribute('data-hostname'))
        toast.success('Host copied to clipboard')
    } catch (error) {
        toast.error(t('unable_to_copy_text'))       
    }

  }
  return (
      !teamMembersErrorMsg ? (
        <DataTable
          columns={columns}
          data={teamMembers}
          pagination
          paginationServer
          responsive
          persistTableHead
          progressPending={!isTeamMembersLoaded}
          progressComponent={<TableLoader />}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationComponentOptions={paginationComponentOptions}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[perPage]}
          customStyles={customStyles}
        />
      ) : (
        <ErrorBoundary
          heading="Unable to get team members"
          className="mt-5"
          tglCartApi={fetchCountAndMembers}
          isCartError={true}
        />
      )
  )
}
export default TeamTable;





