import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../styles/dashboard/Main';
import ProductAppModal from '../tools/AppModalContent/ModalTemplate/ProductAppModal';
import { useState } from 'react';
import { constants } from '../tools/constants';
import { PrimaryBtn } from '../styles/Generic';
import { isIOS, isAndroid } from 'react-device-detect';
import CloseIconSVG from "../assets/svg/CloseIconSVG";

const ModalContent = ({backdrop, modal, toggle, modalWidth, widthCalculator, onClickShowPlanPreview, locale_code, type, redirected_via, closeForMobile}) => {

    const [getThisAddon,setGetThisAddon] = useState("");

    const renderProductModal = (type) => {
        return constants.inAppModals.includes(type) ? 
        <ProductAppModal locale={locale_code} setGetThisAddon={setGetThisAddon} type={type}/> :
        null
    }

    return <Modal backdrop={backdrop} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className={`modal-wrapper ${redirected_via ? 'white-bg' : null}`} scrollable>
        {(redirected_via && (isAndroid || isIOS)) && <span className='close-icon' onClick={()=>closeForMobile()}><CloseIconSVG width={24} height={24}/></span>}
        <>
            <ModalBody className="p-2" style={redirected_via && !isIOS? {height: 'calc(100vh - 40px)', overflowY: "auto"} : null}>
                <PopupBody>
                    <>
                            <PopupBody className='addonContent'>
                                {renderProductModal(type)}
                            </PopupBody>
                        <ModalFooter className='p-0'>
                            <PrimaryBtn color="primary" data-qamarker="getAddon" onClick={onClickShowPlanPreview}>{getThisAddon}</PrimaryBtn>
                        </ModalFooter>     
                    </>
                    
                </PopupBody>
            </ModalBody>
        </>
    </Modal>
}

export default ModalContent;