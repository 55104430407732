import { Link } from "react-router-dom";
import { Input } from "../../../../styles/dashboard/Main";
import { withRouter } from "react-router-dom";
import { getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

const Counter = ({
    minDisabled,
    maxDisabled,
    onChangeCounter,
    getMinimum, 
    getMaximum,
    hasError,
    getValue,
    state,
    dispatch,
    type,
    max,
    history,
    hideLiner
}) => {

    const token = getTokenFromLocalStorage();
    const onBlur = (state, type) => {
        if(state.current !== 0 && !state.current) {
            dispatch({ type: type, value: state.min });
        }
    }

    const routeToDome = () => {
        sendEvents({
            event_name:"ma_click_explore_puredome",
            event_properties:{
                account_type: 'teams',
                route_via: 'checkout'

            }
        }, token)
        window.open(process.env.REACT_APP_DOME_HUBSPOT_LINK, '_blank')
    }

    const getErrorMsg = () => {
        if(type === 'team_server'){
            if(getMinimum(state) === getMaximum(state) ){
                return `Your purchase allowance extends to only ${getMaximum(state)} Team server`
            }else{
                return `Team server size is restricted to a minimum of ${getMinimum(state)} and a maximum of ${getMaximum(state)} team server.`
            }
        }
        else if(type === 'dedicated_ip'){
            if(getMinimum(state) === getMaximum(state)){
                return `Your purchase allowance extends to only ${getMaximum(state)} more Dedicated IP`
            }else{
                return `Dedicated IP size is restricted to a minimum of ${getMinimum(state)} and a maximum of ${max ?? getMaximum(state)} dedicated IPs.`
            }
        }
        else{
           return maxDisabled(state) ? ( <>{`Team size is restricted to a minimum of ${getMinimum(state)} and a maximum of ${getMaximum(state)} members. If you need more than ${getMaximum(state)} accounts, please upgrade to`} <Link style={{color: '#001219', fontSize: '11px', cursor: 'pointer', textDecoration: 'underline'}} onClick={(e)=> routeToDome(history)}>PureDome</Link></>) : `Team size is restricted to a minimum of ${getMinimum(state)} and a maximum of ${getMaximum(state)} members.`
        }
        

    }
    return <>
        <div className="counter-container">
            <div className='counter'>
                <button disabled={minDisabled(state)} className='counter-btns' onClick={()=>max ? onChangeCounter({type:'decrease', dispatch, max}) : onChangeCounter({type:'decrease', dispatch})}>-</button>
                <Input onBlur={()=>onBlur(state, 'focus')} type={'number'} pattern="[0-9]" min={getMinimum(state)} max={max ?? getMaximum(state)} value={getValue(state)} onChange={(e)=> max ? onChangeCounter({type:'change', dispatch, value:e.target.value, max}) : onChangeCounter({type:'change', dispatch, value:e.target.value})} />
                <button disabled={max ? maxDisabled(state, max) : maxDisabled(state)} className='counter-btns' onClick={()=>max ? onChangeCounter({type:'increase', dispatch, max}) : onChangeCounter({type:'increase', dispatch})}>+</button>
            </div>
            {!hideLiner && <div className="seats">{`Empty seats: ${state?.current && !hasError(state, max)  ? state?.current - state?.previous : 0}`}</div>}
        </div>
        {hasError(state, max) && <p className='error'>{getErrorMsg()}</p>}

    </>
}
export default withRouter(Counter);