import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const CloseIconSVG = ({ fillColor, width, height, style, className, onClick }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} onClick={onClick} className={className} viewBox="0 0 12 13" width={width}>
    <g clipPath="url(#clip0_12761_91319)">
      <Path
        stroke={fillColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 3.61328L3 9.61328"
      />
      <Path
        stroke={fillColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3 3.61328L9 9.61328"
      />
    </g>  
    <defs>
      <clipPath id="clip0_12761_91319">
        <rect width="12" height="12" fill="white" transform="translate(0 0.613281)"/>
      </clipPath>
    </defs>
  </SVG>
)
CloseIconSVG.propTypes = {
  fill: PropTypes.string,
}
CloseIconSVG.defaultProps = {
  width: '12',
  height: '13',
  fillColor: '#637381',
  className: 'svg-icon close-icon'
}
export default CloseIconSVG