import ProductIcons from "../../assets/ACSD-30300-onboaridng-icons-color.svg"
import PureVpn from "../../assets/plantype/purevpn.png";
import PureKeep from "../../assets/plantype/purekeep.png";
import PureEncrypt from "../../assets/plantype/purencrypt.png";
import PurePrivacy from "../../assets/plantype/pureprivacy.png";
import FeatureIcon from "../../assets/plantype/security-suite-sprite.png";

export const PlanTypeUpgradeInfo = {
    'pureprivacy' : {
        image: PurePrivacy,
        styles: {background: `url(${ProductIcons}) -96px 0px`},
        heading: 'PurePrivacy',
        paragraph: 'securitysuite_privacy_heading',
        features: [
            {style: {background: `url(${FeatureIcon}) 0px 0px`}, heading: 'securitysuite_keep_media_auditor', paragraph: 'securitysuite_keep_media_auditor_para'},
            {style: {background: `url(${FeatureIcon}) -32px 0px`}, heading: 'securitysuite_keep_data_removal_tool', paragraph: 'securitysuite_keep_data_removal_tool_para'}
        ],
        order: 1
    },
    'purekeep' : {
        image: PureKeep,
        styles: {background: `url(${ProductIcons}) -48px 0px`},
        heading: 'PureKeep',
        paragraph: 'securitysuite_keep_heading',
        features: [
            {style: {background: `url(${FeatureIcon}) -64px 0px`}, heading: 'securitysuite_keep_secure_vaults', paragraph: 'securitysuite_keep_secure_vaults_para'},
            {style: {background: `url(${FeatureIcon}) -96px 0px`}, heading: 'securitysuite_keep_password_generator', paragraph: 'securitysuite_keep_password_generator_para'}
        ],
        order: 2
    },
    'purencrypt' : {
        image: PureEncrypt,
        styles: {background: `url(${ProductIcons}) -72px 0px`},
        heading: 'PureEncrypt',
        paragraph: 'securitysuite_encrypt_heading',
        features: [
            {style: {background: `url(${FeatureIcon}) -128px 0px`}, heading: 'securitysuite_encrypt_file_encryption', paragraph: 'securitysuite_encrypt_file_encryption_para'},
            {style: {background: `url(${FeatureIcon}) -160px 0px`}, heading: 'securitysuite_encrypt_support_all_form_data', paragraph: 'securitysuite_encrypt_support_all_form_data_para'}
        ],
        order: 1
    },
    'purevpn' : {
        image: PureVpn,
        styles: {background: `url(${ProductIcons}) -24px 0px`},
        heading: 'PureVPN',
        paragraph: 'securitysuite_vpn_heading',
        features: [
            {style: {background: `url(${FeatureIcon}) -192px 0px`}, heading: 'securitysuite_vpn_wide_range', paragraph: 'securitysuite_vpn_wide_range_para'},
            {style: {background: `url(${FeatureIcon}) -224px 0px`}, heading: 'securitysuite_vpn_multiple_accounts', paragraph: 'securitysuite_vpn_multiple_accounts_para'}
        ],
        order: 2
    }
}