import React from 'react'
import { BusinessLicence, BusinessLicencesContainer, BusinessSubHeading, BusinessText } from '../../../../styles/dashboard/Business'
import dedicatedIp from '../../../../assets/single-dedicated-ip.png';
import multipleDedicatedIP from '../../../../assets/business-gateway.svg';
import gateway from '../../../../assets/Gateways.png';
import assignTeam from '../../../../assets/assign-team.svg';
import { useTranslation } from 'react-i18next';

import content from '../content/content.json'

function BusinessLicences() {
  const { t, i18n } = useTranslation();
  const images = {
    dedicatedIp,
    multipleDedicatedIP,
    gateway,
    assignTeam
  }
  const { business_licences } = content;
  return (
    <BusinessLicencesContainer>{business_licences.map((licence, index) => (
      <BusinessLicence className={index % 2 !== 0 && 'reverse'}>
        <div className='w-full flex-1'>
          <BusinessSubHeading className='f24 text-left'>{t(licence.heading)}</BusinessSubHeading>
          <BusinessText className='text-left'>{t(licence.description)}</BusinessText>
        </div>
        <img className='flex-1' src={images[licence.img]} alt='liceneces' width={"100%"} />
      </BusinessLicence>

    ))}</BusinessLicencesContainer>
  )
}

export default BusinessLicences