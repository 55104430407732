import { 
    ERRORS, 
    GETUPGRADEPLANS, 
    GETUPGRADEPLANSREQUEST, 
    LOADING, 
    GETUPGRADEPLANSRESET
} from "./types"

export const getUpgradePlansRequested = (payload) => {
    return{
        type:GETUPGRADEPLANSREQUEST,
        payload
    }
}
export const getUpgradePlansReset = (payload) => {
    return{
        type:GETUPGRADEPLANSRESET,
        payload
    }
}

export const getUpgradePlansType = (payload) => {
    return{
        type:GETUPGRADEPLANS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
