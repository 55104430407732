import {
    cancelled,
    put,
    takeLatest,
  } from "redux-saga/effects";
  import { GETDEVICES, LOADING, ERRORS, SUCCESS_DEVICES } from "./types";
  import axios from "axios";
  import { errorsType, getSuccessDevices, loadingType } from "./action";
  import { APIFAILED } from "../../tools/responseMessages";
  import { useTranslation } from 'react-i18next';
  import { get } from "../../tools/tools";
  import i18n from "i18next";
  
  function* handleGetDevices(action) {
    const cancelTokenSource = axios.CancelToken.source();
    // yield put(loadingType())
    try {
      const devices = yield get("manual/devices",action.token);

      const { body: response } = devices.data;
      if (devices?.data?.status) {
        yield put(getSuccessDevices({ devices: response }));
      } else {
        yield put(
          errorsType({
            code: devices?.data?.error_code || "APIFAILED",
            message: devices?.data?.message,
          })
        );
      }
    } catch (err) {
      yield put(errorsType({ code: "APIFAILED", message: (i18n.t(APIFAILED)) }));
    } finally {
      if (yield cancelled()) {
        cancelTokenSource.cancel();
      }
    }
  }
  
  function* watcherWgDeviceSaga() {
    yield takeLatest("GET_DEVICES_REQUESTED", handleGetDevices);
  }
  
  export default watcherWgDeviceSaga;
  