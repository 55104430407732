import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { getByParam } from '../../tools/tools';
import { errorsType, getTeamPlans, loadingType } from './actions';


function* teamPlanAsync(action){
    
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const subscriptions =  yield getByParam("pure-teams/plans", {type: 'upsell'} , action.payload, cancelTokenSource);
        const {data: response} = subscriptions;
        
        if(response?.status){
            yield put(getTeamPlans(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: 'Something went wrong. Please try again' }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchTeamPlanAsync(){
    yield takeLatest('GETTEAMPLANSREQUEST', teamPlanAsync)
}