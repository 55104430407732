import React, {useState} from 'react';
import logo from '../../../assets/logo_white_new.svg'
import { NavbarWrapper } from '../../../styles/dashboard/Navbar';
import Sidebar from './Sidebar';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation, withRouter } from 'react-router-dom';

const Navbar = ({ isNoPaddingView, history }) => {

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const { search } = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    /*
    Added toggleSidebar function so to make it active and inactive accordingly
    Like if a username clicks on selection menu for username when he selects a username then the sidebar should hide
    */
    const toggleSideBar = (isToggle=true) => {
        if(isToggle){
            showSidebar()
        }
        else{
            setSidebar(true)
        }
    }

    const redirectTohome = () => {
      history.push("/dashboard/security-tools");
    }

    return (
      <>
  
     <OutsideClickHandler onOutsideClick={() => setSidebar(false)}>
          <NavbarWrapper className="navbar-wrapper" isNoPaddingView={isNoPaddingView} hide={redirected_via ? true : false}>
            <div className="d-flex justify-content-between">
              <div>
                <img src={logo} onClick={redirectTohome} className="img-fluid nav-logo" width={120} height={36} alt="PureVPN Logo"/>
              </div>
              <button onClick={showSidebar} className="hamberg-div">
                <div className="hamberg-icon"></div>
                <div className="hamberg-icon"></div>
                <div className="hamberg-icon"></div>
              </button> 
              </div>
              <div className={sidebar ? 'sidebar-menu active' : 'sidebar-menu'}>
                <Sidebar toggleSideBar={toggleSideBar}/>
              </div>  
          </NavbarWrapper>
      </OutsideClickHandler>

      </>
    )
}

export default withRouter(Navbar);
