import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { sendEvents } from '../../../tools/mpEvents'
import { BusinessHead, BusinessHeading, BusinessSubHeading, BusinessHeadLogo, BusinessCard, Businesshr, BusinessContainer, BusinessDesc, PrimaryHeading, BusinessBtnFlex } from '../../../styles/dashboard/Business';
import Faqs from './partials/Faqs';
import { connect } from 'react-redux';
import { getAccountRequested } from '../../../redux/account/actions';
import { getByParam, getTokenFromLocalStorage, scrollToTop,checkEvenOdd } from '../../../tools/tools';
import { useLocation } from 'react-router-dom';
import PurDomeCover from '../../../assets/business-cover.svg';
import DomeForm from '../layout/partials/DomeForm';
import Logo from './partials/Logo';
import PurDomeLogo from '../../../assets/PureDome-Icon.svg';
import Table from './partials/Table';
import { useTranslation } from 'react-i18next';
import Tabs from './partials/Tabs';
import TrialModal from './partials/TrialModal';
import { PrimaryBtn, SecondryBtn } from '../../../styles/Generic';
import BusinessLicences from './partials/BusinessLicences';
import BusinessLogo from './partials/BusinessLogo';
import BusinessBadges from './partials/BusinessBadges';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import Error from '../plan-type-upgrade/modals/error';

const Business = ({subscriptionsReducer,accountReducer,getAccountRequested,onBoardingReducer,history}) => {

    const showExitPopupRef = useRef(true);

    useEffect(()=> {
        history.push('/dashboard/teams')
    }, [])

    // useEffect(() => {
    //     document.addEventListener("mouseout", (e) => {
    //         if (showExitPopupRef.current && !e.relatedTarget && e.clientY < 10) {
    //             wisepopEvent();
    //         }
    //     });

    //     return () => showExitPopupRef.current && wisepopEvent();
    // }, [])


    const wisepopEvent = () => {
        const email = subscriptionsReducer?.subscriptions?.body?.[0]?.account?.email
        window.wisepops('properties', {email});
        window.wisepops('event', 'business-vpn-event');
        hideExitPopup();
    }
    const { t, i18n } = useTranslation();

    const { search } = useLocation();

    const { modal_for, from } = Object.fromEntries(new URLSearchParams(search));

    const token = getTokenFromLocalStorage();

    const [modal, setModal] = useState(false);

    const [getInTouchModal, setGetInTouchModal] = useState(false);

    const [trialModal, setTrialModal] = useState(false);

    const [link, setLink] = useState(checkEvenOdd);

    const [isSourceApp] = useState(modal_for?.length ? "dome_upsell_page" : (from ? from : "member_area"));

    const [modalWidthForError] = useState({
        lg: "560px",
        sm: "400px"
    });

    const widthCalculator = "lg";

    useEffect(() => {
        getAccountRequested(token);
        scrollToTop()
        if (modal_for == "businessmodal") {
            getInTouchEvent()
        }
        sendEvents({
            event_name: "ma_pagevisited",
            event_properties: {
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: isSourceApp
            }
        }, token)
    }, [])

    const getInTouchEvent = () => {
        openBookDemoFormInNewTab("Get in Touch");
    }

    const handleCTAEvents = (cta) => {
        sendEvents({
            event_name: "ma_click_business_cta",
            event_properties: {
                cta,
                utm_source: isSourceApp
            }
        }, token)
    }

    const handleCTAEventGeneral = (name='',data={}) => {
        sendEvents({
            event_name: name,
            event_properties: {
                ...data,
                utm_source: isSourceApp
            }
        }, token)
    }

    const handleFormSubmitEvents = (form) => {
        sendEvents({
            event_name: "ma_submit_business_form",
            event_properties: {
                form,
                cta: 'Submit',
                utm_source: isSourceApp,
                selected_inteface_screen: 'business'
            }
        }, token)
    }

    const hideExitPopup = () => {
        showExitPopupRef.current = false;
    }

    const toggleModal = () => setModal(!modal)

    const handleGetInTouchModal = () => setGetInTouchModal(!getInTouchModal)

    const handleTrialModal = () => setTrialModal(!trialModal)

    const [loading, setLoading] = useState(false);

    const [modalError, setModalError] = useState({
        open: false,
        state: ''
    });

    const toggle = () => {
        setModalError((modal)=>({
            open: !modal.open,
            state: ''
        }))
    }

    const handleGetStarted = async () => {
        hideExitPopup();

        if(!onBoardingReducer?.is_billing_information){
            toggle();
            return false;
        }

        
        if (accountReducer.accounts) {
            let uuid  = accountReducer?.accounts?.body?.code;
            let registerations = accountReducer?.accounts?.body?.registrations;
            const applicationIds = registerations.map(item => item.applicationId);
            
            setLoading(true);

            if (!applicationIds.includes(process.env.REACT_APP_DOME_FUSION_AUTH_APPLICATION)) {
                await getByParam("fusionauth/register/application", { uuid }, token);
            }

            await redirectToDome();
            setLoading(false);
        }
    }
    
    const redirectToDome = async () => {
        const email = accountReducer?.accounts?.body?.email;
        const { data: { code } } = await getByParam("fusionauth/get-dome-code", { loginId: email }, token);

        if (!code) {
            toast.error("Something went wrong");
            return;
        };

        const query = new URLSearchParams({
            postMethod: true,
            client_id: process.env.REACT_APP_DOME_FUSION_AUTH_APPLICATION,
            redirect_uri: process.env.REACT_APP_DOME_REDIRECT_URL,
            response_type: "code",
            rememberDevice: true,
        }).toString();

        window.open(process.env.REACT_APP_PASSWORDLESS_URL + `${code}?${query}`, "_blank");
    }
    
    const openBookDemoFormInNewTab = (name) => {
        hideExitPopup();
        let payload = {
            name:name,
            link:link
        }
        handleCTAEventGeneral('ma_click_business_demo',payload);
        window.open(link, '_blank');
    };

    const buttonText = (text) => {
        return !loading ? `${text}` : ( <>{text} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    return (
        <div>
            <Helmet>
                
                <title>Member Area | PureDome</title>
                <meta name="description" content="PureVPN offers proprietary apps for all leading platforms so you can enjoy complete internet freedom on any device you want!" />
            </Helmet>
            <BusinessContainer>
                <BusinessHeading>{t('business_para_one')}</BusinessHeading>
                <BusinessHeadLogo src={PurDomeCover} width={"100%"} className='img-fluid' loading="lazy" alt="pure dome" />
                <BusinessDesc>{t('business_new_vpn_solution')}</BusinessDesc>
                <BusinessHead>
                    <BusinessBtnFlex>
                        <SecondryBtn isBusiness onClick={() => { openBookDemoFormInNewTab("Request Demo");  }}>{t('request_demo_text')}</SecondryBtn>
                        { loading ? <PrimaryBtn onClick={() => { handleGetStarted(); handleCTAEvents("Start 30-Day Free Trial") }} disabled>{buttonText(t('business_thirthydays_text'))}</PrimaryBtn> 
                            : accountReducer?.loading ? <Skeleton width={200} height={45}/> : <PrimaryBtn onClick={() => { handleGetStarted(); handleCTAEvents("Start 30-Day Free Trial") }}>{buttonText(t('business_thirthydays_text'))}</PrimaryBtn>
                        }
                    </BusinessBtnFlex>
                </BusinessHead>
                <BusinessHeading className='mb-0'>{t('business_secure_business_with_dome')}</BusinessHeading>
                <BusinessSubHeading>{t('business_customized_protection')}</BusinessSubHeading>
                <BusinessLicences />
                <BusinessHead>
                    <BusinessBtnFlex>
                        <SecondryBtn isBusiness onClick={() => { openBookDemoFormInNewTab("Request Demo");  }}>{t('request_demo_text')}</SecondryBtn>
                        {accountReducer?.loading ?  <Skeleton width={100} height={50}/>:
                        <PrimaryBtn isBusiness onClick={() => { handleGetStarted(); handleCTAEvents("Get PureDome") }}>{buttonText(t('business_thirthydays_text'))}</PrimaryBtn>}
                    </BusinessBtnFlex>
                        <div className='note-Section mt-3'>
                                <span className="note-para"><strong>{t('whmcs_note')} </strong>{t('business_note_vpn')}</span>
                        </div>
                </BusinessHead>
                <BusinessCard>
                    <BusinessSubHeading className='f24'>{t('business_securing_teams')}</BusinessSubHeading>
                    <BusinessLogo />
                    <Businesshr />
                    <BusinessBadges />
                </BusinessCard>
                <PrimaryHeading className='mb-30'>{t('business_how_dome_is_different_from_purevpn')}</PrimaryHeading>
                <Table getInTouchEvent={getInTouchEvent} handleGetStarted={handleGetStarted} handleCTAEvents={handleCTAEvents} loading={loading} accountDataLoader={accountReducer?.loading}/>
                <Faqs />
                <DomeForm modal={modal} setDomeModal={setModal} toggle={toggleModal} onSubmit={() => handleFormSubmitEvents("Book Demo")} />
                <DomeForm modal={getInTouchModal} setDomeModal={setGetInTouchModal} toggle={handleGetInTouchModal} formId={"07e282a5-dfe2-45f9-8f0b-483cd14416d9"} onSubmit={() => handleFormSubmitEvents("Get in Touch")} />
                <TrialModal modal={trialModal} setDomeModal={setTrialModal} toggle={handleTrialModal} handleGetStarted={handleGetStarted} handleCTAEvents={handleCTAEvents} loading={loading}/>
            </BusinessContainer>
            <Error backdrop={false} modalWidth={modalWidthForError} widthCalculator={widthCalculator} modal={modalError.open} toggle={toggle} message={'This offer is not valid for Cardless USERS !'} cta={"Goto Payment Page"} onErrorClick={()=>{ 
                history.push(`/dashboard/subscriptions?isPaymentModel=${true}`)
            }}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer:state.subscriptionsReducer,
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, { getAccountRequested })(Business)