import { CancellationOptionsStyles } from "./index";

const CancelledContent = () => {
    
    const content = [
        {heading: 'Your subscription has been cancelled', description: 'To avoid losing your online freedom and security resume your subscription and go care-free on the internet.'},
        {heading: 'Would you like to resume your subscription?', description: 'Renew your subscription today to ensure you are completely protected every time you go online.'}
    ]


    return <CancellationOptionsStyles>
            <div className="cancelled-content">
                {content.map((value)=>{
                    return <>
                        <h2>{value?.heading}</h2>
                        <p>{value?.description}</p>
                    </>
                })}
            </div>
    </CancellationOptionsStyles>
    
}
export default CancelledContent;