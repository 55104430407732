import React from "react";
import { Redirect, Route } from "react-router-dom";
import {Helmet} from "react-helmet";

function LoginRoutes({ component: Component, title, ...restOfProps }) {

  const token = localStorage.getItem("token");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !token
          ?
          <>
            <Helmet>
                <title>Member Area | {title}</title>
                <meta name="description" content="PureVPN offers proprietary apps for all leading platforms so you can enjoy complete internet freedom on any device you want!" />
            </Helmet>
            <Component {...props} />
          </> 
          : 
          <Redirect to={`/dashboard${process.env.REACT_APP_DEFAULT_LOGIN_URL}`} />
      }
    />
  );
  
}

export default LoginRoutes;