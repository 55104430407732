import React from 'react'
import { InfoCard } from '../../../../styles/dashboard/Main'
import { InsetBtn, LinkBtn } from '../../../../styles/Generic'
import { addonConstants, constants } from '../../../../tools/constants'
import { checkAddOn } from '../tools/SubscriptionTools'
import { useTranslation } from 'react-i18next'
import { donotShowTeamsToTeamUser } from '../../../../tools/tools'

const MultiLogin = ({subscription,multiLoginCTAHandle=()=>{},initOpenModel=()=>{},isMultiLoginHide=true}) => {
    const { t, i18n } = useTranslation();
    const isMultiLoginPurchased = checkAddOn(subscription,addonConstants.multi_login);
    return (
        <InfoCard hasborder>
            <div className="float-start">
                <label className="me-2">{t('subscription_status_multilogin')} <strong>{isMultiLoginPurchased ? subscription?.add_ons?.find((addon) => addon?.code === addonConstants?.multi_login)?.quantity : subscription?.multi_login} Sessions</strong></label>
            </div>
            <div className="float-none"></div>

        </InfoCard>
    )
}

export default MultiLogin