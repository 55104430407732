import { useState, useEffect } from "react";
import { getByParam, getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

const useTeamsMembers = (page, per_page, searchText, selectedSubscriptionData) => {
  const token = getTokenFromLocalStorage();
  const [totalRows, setTotalRows] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isTeamMembersLoaded, setIsTeamMembersLoaded] = useState(false);
  const [teamMembersErrorMsg, setTeamMembersErrorMsg] = useState(null);
  const [fetchTeamMembersAgain, setFetchTeamMembersAgain] = useState(false);

  useEffect(() => {
    async function fetchTeamMembers() {
      try {
        setIsTeamMembersLoaded(false);
        setTeamMembersErrorMsg(false);
        const apiResponse = await getByParam(`pure-teams/team-members?search=${searchText}${selectedSubscriptionData?.is_child ? '&is_child=1' : ''}`, {
          sub_id: selectedSubscriptionData?.id,
            page,
            per_page,
          }, token);
        const { data: teamMembers } = apiResponse;
        if (teamMembers?.status) {
          setTeamMembers(teamMembers?.body?.data);
          setTotalRows(teamMembers?.body?.total);
        } else {
          setTeamMembersErrorMsg(teamMembers?.message);
          sendEvents({
            event_name: "ma_fail_load_team_member_details",
            event_properties: {
                reason: teamMembers?.message
            },
        }, token);
        }
      } catch (error) {
        setTeamMembersErrorMsg('An error occurred while fetching team members');
      }
      setIsTeamMembersLoaded(true);
    }

    fetchTeamMembers();
  }, [fetchTeamMembersAgain, selectedSubscriptionData?.id, page, per_page, searchText]);

  return { teamMembers, totalRows, isTeamMembersLoaded, teamMembersErrorMsg, setFetchTeamMembersAgain };
};

export default useTeamsMembers;
