import { GETWALLETDATA, LOADING, ERRORS, SUCCESSWALLETDATA } from "./types";

const initialState = {
  walletData: null,
  loading: false,
  errors:false,
  errorType:"",
  errorMessage:""
};

const walletDataReducer = (state=initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case GETWALLETDATA:
      return { ...state, loading: true, errors:false };
      break;
    case SUCCESSWALLETDATA:
      return { ...state, loading: false, walletData: payload.walletData ,errors:false };
      break;
    case ERRORS:
      return { ...state, loading: false, errors:true, errorMessage: payload.message , errorType:payload.code };
      break;
    default:
      return {...state};
      break;
  }
};

export default walletDataReducer;
