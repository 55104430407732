import React,{memo, useEffect, useState} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import gift from '../../../assets/gift.svg'
import upgradeside from '../../../assets/upgradeside.png'
import { InviteBox, RightSideBar } from '../../../styles/dashboard/AsideCampaign'
import { PrimaryBtn } from '../../../styles/Generic'
import { isStandard } from '../upgrade/upgrade-tools'
import arrowRightSvg  from '../../../assets/arrow-rightupgrade.svg'

const Aside = ({history, type, refer, subscriptions}) => {

    const dispatch = useDispatch()
    const [layoutAside, setLayoutAside] = useState("");
    const userType = subscriptions?.subscriptions?.body?.[0]?.type;
    const planType = subscriptions?.subscriptions?.body?.[0]?.plan_type;
    const [data, setData] = useState({
        refer: {
            img: gift,
            heading: "Earn Free Credit",
            para: "Invite your friend and get $10 on each invite.",
            cta:"Upgrade my plan now",
            linkTo:"/dashboard/upgrade"
        },
        upgrade: {
            img: upgradeside,
            heading: "Upgrade Your VPN Package Plan",
            para: "Switch your existing PureVPN package plan to a higher one at a Discounted price.",
            cta:"Upgrade my plan now",
            linkTo:"/dashboard/upgrade"
        },
        
        loading:false,
        onMobile:/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    })

    useEffect(() => { 
        // Default helloBar type
        let layout = "earlybf";

        // Condiitonal Check: Subscriptions that will be getting expired/renewed 
        // before 31 December 2022 only show hellobar to thoses users.
        subscriptions.subscriptions?.body?.map((subs) => {
            if(!subs?.hasFamilyPlan && subs?.familyPlanEligibility && subs?.plan?.interval_length != 60){
                layout = "familybf";
            }else if(subs?.plan?.interval_length != 60 ){
                layout = "referal"
            }
        });  
        setLayoutAside(layout);
    })

    return (
        <RightSideBar className="text-center" loading={data.loading.toString()}>
            <div className="loader">
                <span className="spinner-border"></span> 
                <p className="mb-0 ms-2">Loading...</p>
            </div>
            <div className='asidecontent'>
            <p className='aside-hdg'>Grab 85% discount</p>
            <p className="aside-text">on PureVPN's 5-year plan for just <span className='yellow-text'>$99.99</span></p>
            <Link className="d-block mx-auto upgradebf" isBF={true} to='/dashboard/upgrade'>Upgrade today<img src={arrowRightSvg} height="18" width="14"/></Link>
            </div>
        </RightSideBar>
    )
}

const mapStateToProps = (state) => {
    return { 
        refer: state.referReducer,
        subscriptions: state.subscriptionsReducer
    };
};

export default connect(mapStateToProps, null)(memo(withRouter(Aside)))