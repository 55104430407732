import {  ErrorBox } from '../../../styles/Generic'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages'
import { FamilyPlanHead, FamilyPlanWrapper } from '../../../styles/dashboard/FamilyPlan'
import { getFamilyPlanRequested } from '../../../redux/family-plan/actions'
import { Input } from 'reactstrap';
import { isWhmcsUser,selectSub } from '../../../tools/tools'
import { SubscriptionBox,InfoCard} from '../../../styles/dashboard/Main'
import { toast } from 'react-toastify'
import { useDispatch, connect} from 'react-redux'
import { useLocation } from 'react-router-dom'
import {sendEvents} from '../../../tools/mpEvents'
import BillingCycle from '../subscriptions/modals/BillingCycle'
import ErrorBoundary from "../../ErrorBoundary"
import FamilyPlansFaqs from './child/FamilyPlansFaqs'
import FamilyPlanUserDetail from './child/FamilyPlanUserDetail'
import FamilyPlanWorks from './child/FamilyPlanWorks'
import fpCrashed from '../../../assets/fpCrashed.png'
import NotSupported from '../subscriptions/modals/NotSupported'
import PrePurchase from './prePurchase';
import React, { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton';
import WatchVideoPopup from './modal/watchVideoPopup'
import { useTranslation } from 'react-i18next';
import PrePurchaseCampaign from './prePurchaseCampaign';

const FamilyPlan = ({history, familyplanReducer, getFamilyPlanRequested,subscriptionsReducer,onBoardingReducer}) => {

    const { t, i18n } = useTranslation();

    const [modal, setModal] = useState(false);

    const [waitModal,showWaitModal] = useState(false)

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [modalWidthForVideo] = useState({
        lg: "737px",
        sm: "440px"
    })
    const [customErrorMessage] = useState({
        "child":"Oops, you weren't supposed to see this...Uh oh! Only a family manager can upgrade the Family plan.",
        "notEligible":"Oops, you weren't supposed to see this...Upgrading to Family Plan is not supported for your account right now. We have noticed your interest and will let you know as soon as its available for you!"
    })

    const widthCalculator = "lg"

    const toggle = () => setModal(!modal);
    
    const toggleWaitModal = () => showWaitModal(!waitModal);

    const dispatch = useDispatch()

    const {search} = useLocation();   
    
    const token = localStorage.getItem("token")

    const [crashed, setCrashed] = useState(false);

    const [selectedSubscriptionData, setselectedSubscriptionData] = useState({})

    const [loading, setLoading] = useState(familyplanReducer?.familyplan?.status ? false : true)

    const [billingData, setBillingData] = useState("")

    const [orderSummary, setorderSummary] = useState(true)

    const [updateParent, setUpdateParent] = useState(false)

    const [isUpgraded, setIsUpgrade] = useState(false)

    const [notsupportedState, setNotsupportedState] = useState({
        type:"waitlist",
        gateway:""
    })

    const [error,setError] = useState({
        error_type:"",
        error_message:""
    })

    const [subscriptionsData, setsubscriptionsData] = useState({
        subscriptions: {},
        subLoading: familyplanReducer?.current_plan ? false : true,
        familyPlanEligibility:false,
        hasFamilyPlan:false
    })

    const [accountEmail] = useState({email: subscriptionsReducer?.subscriptions?.body?.[0]?.account?.email})

    const {utm_source, utm_medium, utm_campaign, query, code, id} = Object.fromEntries(new URLSearchParams(search));
    const {subscriptions, subLoading,familyPlanEligibility,hasFamilyPlan} = subscriptionsData;

    const [showVideoModal, setShowVideoModal] = useState();

    const scrollToDiv = (ref) => window.scrollTo((0, 50), ref.current.offsetTop);

    const apiData = {
        token:token,
        data:{
            plan: "current"
        }
    }

    const scrollOrderConfirmation = useRef(null);

    const formAlreadyDisplayed = useRef(false);

    const dataFetchedRef = useRef(false);

    const mounted = useRef(false);

    const extractEventProp = (selectedSubscriptionData) =>{
        const event=
        {
            username: selectedSubscriptionData?.vpnusername ?? "N/A",
            payment_method: selectedSubscriptionData?.current_plan?.payment_gateway ?? "N/A",
            billing_cycle: selectedSubscriptionData?.current_plan?.name ?? "N/A",
            account_status:selectedSubscriptionData?.current_plan?.state ?? "N/A",     
        }
        return event
    }

    const fireEvent = (selectedUser) => {
        let eventsProp = extractEventProp(selectedUser)
        let reason = selectedUser.current_plan?.is_child ? "child" : "notEligible";
        sendEvents({
            event_name:"ma_fail_load_familyplan",
            event_properties:{
                reason: customErrorMessage[reason] ?? "N/A",
                ...eventsProp
            }
        }, token)
    }

    const GotoDiv = (ctaText = "", plan) => {
        let eventsProp = extractEventProp(selectedSubscriptionData)
        scrollToDiv(scrollOrderConfirmation)
        sendEvents({
            event_name:"ma_get_familyplan",
            event_properties:{
                cta: ctaText,
                plan_type: plan == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : plan == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null,
                ...eventsProp
            }
        }, token)
    };

    /*
    * The function is use to fire the wisepop's window method to display the pop-up
    * It'll be executed once the components is mounted and all the data is loaded
    */
    const isComponentMounted = (params) => {
        const {selectedSubscriptionData, trigger_point} = params
        if(!mounted.current && window.wisepops){
            window.wisepops('properties', accountEmail);
            window.wisepops('event', 'family-plan-event') 
            let eventsProp = extractEventProp(selectedSubscriptionData)
            sendEvents({
                event_name:"ma_family_plan_survey_form",
                event_properties:{
                    type: trigger_point ?? "page change",
                    ...eventsProp
                }
            }, token)
        }
    }
    
    // *This function tracks mouse pointer's position nad once user hover over the browser navigation bar the event is fired   
    const  handleMouseMove = (event,selectedSubscriptionData) => {
        const mouseY = event.clientY-20;
        if(mouseY<0 && !mounted.current && !formAlreadyDisplayed.current){
            formAlreadyDisplayed.current = true
            isComponentMounted({selectedSubscriptionData,trigger_point:"page exit"})
        }
    }

    
    // *this function checks the eligiblty criteria and enable the popup functionality accordingly
    const isParentAndEligible = (selectedSub) => {
        const {hasFamilyPlan,familyPlanEligibility} = selectedSub
        const toShowSurveyForHover = !hasFamilyPlan && familyPlanEligibility
        if(toShowSurveyForHover){
            mounted.current = false;
            document.addEventListener('mousemove', function(event) {
                handleMouseMove(event, selectedSub);
            });
        }else{
            return 
        }
    }

    useEffect(()=>{
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: (query ? query : utm_source) ?? "Family Plan",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            }
        }, token)
        getFamilyPlanRequested(apiData);
        dataFetchedRef.current = false;
        const queryParams = new URLSearchParams(search)
        if (queryParams.has('query')) {
            setIsUpgrade(true)
            queryParams.delete('query')
            history.replace({
                search: queryParams.toString(),
            })
        }
        /*
         *By default keep the wisepops display always off only make it visible once the components in unmount or once it is loaded
            *-> also once the data is loaded and if then the comp is unmounted only then fire the event
            *-> else once the data is loaded and user does not has family plan then the hover pop up will be displayed via wisepops sdk
        */
        if(window.wisepops){
            window.wisepops('options', {
                autoPageview: false
            });
        }
        mounted.current = true; 

        // *this callback exec when component is unmounted 
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            if(!mounted.current){
                isComponentMounted({selectedSubscriptionData,trigger_point:"page change"})
            }
        };
    }, [])

    useEffect(()=>{
        if(id && code && selectedSubscriptionData && !loading){
            if(Object.keys(selectedSubscriptionData).length) upgradeToFamilyPlan()
        }
    },[selectedSubscriptionData])

    useEffect(()=>{

        const  {familyplan: response, loading: reduxLoading, errors, resetState,  errorType, errorMessage} = familyplanReducer;
        if(reduxLoading || resetState){
            setLoading(true);
        }
        
        if(response?.status &&  !resetState){  
            setsubscriptionsData({
                subscriptions: response?.body,
                subLoading: false,
            });

            const isFamilyManager = response?.body?.find(subscription=>subscription.familyPlanEligibility && subscription.hasFamilyPlan);
            const isFamilyPlanEligible = response?.body?.find(subscription=>subscription.familyPlanEligibility);
            const selectedUserFromUrl = id ? response?.body?.filter((subs)=> subs?.current_plan?.subscription_id === id)?.[0] : isFamilyManager ?? isFamilyPlanEligible ?? response?.body?.[0] ?? false;

            const selectedUser = id ? selectedUserFromUrl : selectSub(response?.body,onBoardingReducer.selectedSubs,"fp")

            //* If user does not own family plan and is not eligible only then display wisepops 
            setselectedSubscriptionData(selectedUser);
            isParentAndEligible(selectedUser)
            
            if(!dataFetchedRef.current){
                if(!(selectedUser.familyPlanEligibility))
                {
                    fireEvent(selectedUser)
                }
                dataFetchedRef.current = true;
            }
            setLoading(false)

        }else if(errors){
            setError({
                error_type:errorType ?? "N/A",
                error_message:errorMessage ?? "N/A"
            })
            switch (response?.error_code) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type:"LOGOUT"}) 
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type:"LOGOUT"})
                    break; 
                case "APIFAILED":
                    setCrashed(true)
                    break;     
                default:
                    setCrashed(true)
                    break;
            }
        }
        
    }, [familyplanReducer,onBoardingReducer.selectedSubs])

    const changeSelectedSubscription = (e) => {
        let value = e.target.value
        let subscriptionData = subscriptions.find(subscription=>subscription.vpnusername === value);
        setselectedSubscriptionData(subscriptionData ?? false)
        if(!subscriptionData?.familyPlanEligibility){
            fireEvent(subscriptionData)
        }
        //* If selected user is eligible show the form for that selected subscription
        isParentAndEligible(subscriptionData)
    }

    const upgradeToFamilyPlan = (plan) => {
        let todayDate = new Date().toJSON()
        setBillingData({
            plan: id && code ? selectedSubscriptionData?.family_plans.find((fp)=> fp.code === code)?.code: plan?.code,
            subscription:selectedSubscriptionData?.current_plan?.subscription_id,
            currency:selectedSubscriptionData?.current_plan?.currency,
            interval_length: plan?.interval_length,
            interval_unit: plan?.interval_unit,
            start_date: todayDate,
            expiry_date: selectedSubscriptionData?.current_plan?.expiry_date,
            isWhmcsUser: isWhmcsUser(selectedSubscriptionData?.current_plan?.account?.billingType),
            familyPlanPrice: id && code ? selectedSubscriptionData?.family_plans.find((fp)=> fp.code === code)?.unit_amount : selectedSubscriptionData?.family_plans[0]?.unit_amount,
            plan_type: plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO : plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY : null,
            sub_plan_type:selectedSubscriptionData?.plan_type
        })

        setModal(!modal)
    }

    const scrollToDivFromChild = (status) =>{
        if(status){
            GotoDiv()
        }
    }
    const getDataFromBillingPopup = (status) =>{
        if(status){
            setUpdateParent(true)
        }
    }
    const clickWaitList = () => {
        showWaitModal(true)
        let eventsProp = extractEventProp(selectedSubscriptionData)
        sendEvents({
            event_name:"ma_waitlist_familyplan",
            event_properties:{
                ...eventsProp
            }
        }, token)
    }
    if(crashed){
        if(!dataFetchedRef.current){
            if (token) {
                sendEvents(
                    {
                        event_name: "ma_fail_family_plan",
                        event_properties: {
                            ...error
                        },
                    },
                    token
                    );
            }
            dataFetchedRef.current=true;
		}
        return <ErrorBoundary></ErrorBoundary>
    }

    const getErrorMsg = (selectedSubscriptionData) => {
        if (selectedSubscriptionData?.hasVolumePlan) {
            return "Uh oh! Only a manager can upgrade the Family Plan."
        } else if (selectedSubscriptionData?.hasFamilyPlan) {
            return "Uh oh! Only a family manager can upgrade the Family Plan."
        } else {
            return "Uh oh! this action is not avaialble for you."
        }
    }

    const toggleVideo = () => setShowVideoModal(!showVideoModal);

    return (
        <>
            {!selectedSubscriptionData?.hasFamilyPlan ? null : <FamilyPlanHead>
                <h2>Family Plan</h2>
                <p>{selectedSubscriptionData?.hasFamilyPlan ? t('familyplan_title_para_two') : t('familyplan_title_para')}
                </p>
            </FamilyPlanHead>}
            {
                !loading ? 
                <>

                <FamilyPlanWrapper>
                    {
                        (selectedSubscriptionData?.familyPlanEligibility || (selectedSubscriptionData?.hasFamilyPlan && !selectedSubscriptionData?.current_plan?.is_child)) ? 
                            <>

                                {!selectedSubscriptionData?.hasFamilyPlan && 
                                    <>
                                        <PrePurchaseCampaign GotoDiv={GotoDiv} scrollOrderConfirmation={scrollOrderConfirmation} selectedSubscriptionData={selectedSubscriptionData} toggleVideo={toggleVideo} upgradeToFamilyPlan={upgradeToFamilyPlan}/>
                                    </>
                                }

                                {selectedSubscriptionData?.hasFamilyPlan &&
                                    <>
                                        <FamilyPlanUserDetail selectedSubscriptionData={selectedSubscriptionData} planCode={selectedSubscriptionData?.current_plan?.code} planGroup={selectedSubscriptionData?.current_plan?.group} hasFamilyPlan={selectedSubscriptionData?.hasFamilyPlan} parentDetails={selectedSubscriptionData} onClickScroll={scrollToDivFromChild} extractEventProp={extractEventProp}/>
                                        <FamilyPlanWorks />
                                    </>
                                }
                                <FamilyPlansFaqs  accountEmail={accountEmail}/>
                            </>
                            : 
                            (selectedSubscriptionData?.familyPlanEligibility !== undefined && !selectedSubscriptionData?.familyPlanEligibility) ? 
                            <ErrorBox>
                                <div className='errorBox fpErrorBox'>
                                    <img src={fpCrashed} className="img-fluid" height={213} alt="Crashed"/>
                                    <div className='mt-5'>
                                        <span>
                                            <h2>{t('familyplan_errorbox_heading')}</h2>
                                            {
                                                selectedSubscriptionData?.current_plan?.is_child ?
                                                    <p className="mt-3">{getErrorMsg(selectedSubscriptionData)}</p>
                                                :
                                                <p className="mt-3">
                                                    {t('familyplan_errorbox_para_two')}                                      
                                                </p>
                                            }
                                        </span>
                                        <span>
                                            <button onClick={clickWaitList}>{t('familyplan_errorbox_waitlist_cta')} </button>
                                        </span>
                                    </div>
                                </div>
                            </ErrorBox>
                            : null
                        }
                </FamilyPlanWrapper>

                
                </>
                :
                <SubscriptionBox open={true}>
                    <InfoCard hasborder noflex className="mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard noflex className="pt-0">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                </SubscriptionBox> 
                

            }
            {showVideoModal && <WatchVideoPopup backdrop={false} modalWidth={modalWidthForVideo} widthCalculator={widthCalculator} modal={showVideoModal} toggle={toggleVideo} />}
            {
                modal  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={billingData} widthCalculator={widthCalculator} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} apiEndPoint={"familyplan/change"} redirectedFrom="familyPlan" getDataFromChild={getDataFromBillingPopup} dataFetchedRef={dataFetchedRef}/>
            }
            {
                waitModal && <NotSupported backdrop={false} notsupportedState={notsupportedState} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={showWaitModal} toggle={toggleWaitModal}/>
            }
        </>
        

    )
}


const mapStateToProps = (state) => {
    return { 
        familyplanReducer: state.familyplanReducer,
        subscriptionsReducer:state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, {getFamilyPlanRequested})(FamilyPlan)