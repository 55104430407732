import { APIFAILED } from "../../../tools/responseMessages";
import { checkAccountType, dispatchError, getPaymentMethods, getTokenFromLocalStorage, isWhmcsUser, poster, selectSub } from '../../../tools/tools';
import { connect, useDispatch } from "react-redux";
import { dedicatedIpPricing } from "./tools/dedicatedIp";
import { DedicatedIpReducer } from "./reducer/dedicatedIpCounter";
import { getTeamPlanRequested } from "../../../redux/pureteams/actions";
import { InfoCard, SubscriptionBox } from "../../../styles/dashboard/Main";
import { PureTeamsStyles } from "../../../styles/dashboard/pure-teams/pureTeams";
import { sendEvents } from "../../../tools/mpEvents";
import { setTicketsType, getTicketsRequested } from "../../../redux/tickets/actions";
import { TeamMemberReducer } from "./reducer/teamMemberCounter";
import { TeamServerReducer } from "./reducer/teamServerCounter";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useReducer } from "react";
import BillingCycle from "../subscriptions/modals/BillingCycle";
import CheckoutLicenses from "./modals/checkoutLicenses";
import Error from "./modals/error";
import ErrorBoundary from "../../ErrorBoundary";
import Info from "./modals/info";
import PostPurchase from "./postPurchase";
import PrePurchase from "./prePurchase";
import Skeleton from "react-loading-skeleton";
import { teamServerPricing } from "./tools/teamServer";
import { useLocation } from "react-router-dom";

const PureTeams = ({subscriptionsReducer, onBoardingReducer, getTeamPlanRequested, pureTeamsReducer, ticketsReducer, getTicketsRequested}) => {
    const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);
    const dispatch = useDispatch();
    const token = getTokenFromLocalStorage();
    const [authorId, setAuthorId] = useState();
    const [ticketsLoading, setTicketsLoading] = useState(false);
    const [crashed, setCrashed] = useState(false);
    const [dataForLicensingPurchase, setDataForLicensingPurchase] = useState();
    const [hasAddonsArray, setHasAddonsArray] = useState([]);
    const [licensesModal, setLicensesModal] = useState(false);
    const [orderSummary, setorderSummary] = useState(true);
    const [selectedSubscriptionData, setSelectedSubscriptionData] = useState(false);
    const [selectedSubscriptionPlans, setSelectedSubscriptionPlans] = useState("0");
    const [upgradeModal, setUpgradeModal] = useState();
    const [createTicketCount, setCreateTicketCount] = useState(localStorage.getItem("ticketCount") ?? 0);
    const [inviteLink, setInviteLink] = useState();
    const { search } = useLocation();
    const { utm_medium, utm_campaign, redirect_from } = Object.fromEntries(new URLSearchParams(search));

    const [plansData, setPlansData] = useState({
        plans: [],
        planLoading: true
    });
    const [showError, setShowError] = useState({
        show: false,
        type: '',
        reason: '',
        cta: ''
    });
    const [infoModal, setInfoModal] = useState({
        open: false,
        content: ''
    });

    const [teamServerState, dispatchForTeamServer] = useReducer(TeamServerReducer, {
        min: 0,
        max: 1,
        current: 0,
        price: 0,
        previous: 0
    });

    const [teamMemberState, dispatchForTeamMember] = useReducer(TeamMemberReducer, {
        min: 2,
        max: 200,
        current: 0,
        price: pricing?.[selectedSubscriptionData?.plan?.interval_length],
        previous: 0
    });

    const [dedicatedIpState, dispatchForDedicatedIp] = useReducer(DedicatedIpReducer, {
        min: 0,
        max: 200,
        current: 0,
        price: 0,
        previous: 0
    });

    const [modalWidthForError] = useState({
        lg: "560px",
        sm: "400px"
    });

    const [modalWidth] = useState({
        lg: "684px",
        sm: "440px"
    });

    const [subscriptionsData, setsubscriptionsData] = useState({
        subs: {},
        subLoading: true
    });

    // GET TEAMS REQUEST
    useEffect(() => {
        getTeamPlanRequested(token)
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page",
                redirect_from: redirect_from ?? "Member Area"
            }
        }, token)
    }, [])

    // GET AND SET SUBSCRITPIONS DATA
    useEffect(()=>{
        const { loading: reduxLoading, subscriptions: reduxData, errorType, errors} = subscriptionsReducer;

        if(reduxData?.body?.length){
            setsubscriptionsData({
                ...subscriptionsData,
                subs:reduxData.body,
                subLoading: subscriptionsData?.subs ? false : true
            }); 
        }
        if(!reduxLoading){
            if(errors) {
                dispatchError(errorType, dispatch);
                throwErrorBoundary(errorType)
                setsubscriptionsData({
                    subs: [],
                    subLoading: false
                }); 
            }
        }
        
    }, [subscriptionsReducer])

    // GET AND SET TEAM DATA
    useEffect(() => {
        const { loading: reduxLoading, planInfo, errors, errorType, resetState} = pureTeamsReducer;
        if(resetState){
            setPlansData({
                ...plansData,
                planLoading: true,
            })
        }

        if(planInfo){
            setPlansData({
                ...plansData,
                plans:planInfo?.body,
            })
        }

        if(!reduxLoading){
            if(errors) {
                dispatchError(errorType, dispatch);
                throwErrorBoundary(errorType)
                setPlansData({
                    plans: [],
                    planLoading: false
                })
            }
        }
        
        
    }, [pureTeamsReducer, subscriptionsReducer])

    useEffect(()=>{
        if(pureTeamsReducer?.planInfo && subscriptionsData?.subs?.length > 0){

            // set plans data with all subscritpions
            setPlansData({
                ...plansData,
                plans:pureTeamsReducer?.planInfo?.body,
                planLoading: false
            })

            // set selection subscription data
            let currentSubscription = selectSub(subscriptionsData?.subs ?? [],onBoardingReducer.selectedSubs)
            setSelectedSubscriptionData(currentSubscription ?? false);
            setDataAccordingToSelectedSubscriptionAndPlans({currentSubscription: currentSubscription, allPlans: pureTeamsReducer?.planInfo?.body})
        }
    }, [pureTeamsReducer, onBoardingReducer.selectedSubs])


    useEffect(()=> {
        // clear invite link on subscription change
        setInviteLink()
    }, [onBoardingReducer.selectedSubs])

    useEffect(()=> {
        getTicketsRequested(token)
    }, [])

    useEffect(() => {
        const { tickets, loading } = ticketsReducer;
        if (tickets && !loading) {
            setAuthorId(tickets?.body[0]?.author_id || "")
        }
        setTicketsLoading(loading)
    }, [ticketsReducer])

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED || error === 'pure_teams_exception'){
            setCrashed(true)
        }
    }
    const setDataAccordingToSelectedSubscriptionAndPlans = (payload) => {

        const {currentSubscription, allPlans} = payload;
        let plans = allPlans?.find(sub => sub?.id === currentSubscription?.id)?.plans?.[0];
        setSelectedSubscriptionPlans(plans)

    }
    const minLimit = (currentSubscription) => {
        // read max account bit from plan in order to fetch child count incase of family
        let plan = pureTeamsReducer?.planInfo?.body?.find(sub => sub?.id === currentSubscription?.id);

        // either family plan child count OR vpn quantity
        return plan?.plan?.current_max_accounts ? plan?.plan?.current_max_accounts : currentSubscription?.add_ons.find((addon) => addon.code === 'purevpn')?.quantity > 1 ? currentSubscription?.add_ons.find((addon) => addon.code === 'purevpn')?.quantity : 2
    }
    const currentForMembers = (currentSubscription) => {
        // read max account bit from plan in order to fetch child count incase of family
        let plan = pureTeamsReducer?.planInfo?.body?.find(sub => sub?.id === currentSubscription?.id);

        // either family plan child count OR vpn quantity
        return plan?.plan?.current_max_accounts ? plan?.plan?.current_max_accounts : currentSubscription?.add_ons.find((addon) => addon.code === 'purevpn')?.quantity > 1 ? currentSubscription?.add_ons.find((addon) => addon.code === 'purevpn')?.quantity : 1
    }

    const minLimitIP = () => {
        // read max account bit from plan in order to fetch child count incase of family
        return selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip' || add.code === 'port_forwarding_with_dedicated_ip') ? 1 : 0
    }
    const openLicensingSelectionModal = (payload) => {
        const { type } = payload;

        if (selectedSubscriptionData?.state === 'canceled'){
            setInEligibleError({
                type: 'cancelled',
                reason: 'Kindly renew your account with the purchase Team.',
                cta: 'waitlist'
            });
        }
        else if (selectedSubscriptionData?.is_trial){
            setInEligibleError({
                type: 'trial',
                reason: 'You can purchase the Team account after your trial period.',
                cta: 'waitlist'
            });
        }
        else if (!plansData?.plans?.find(sub => sub?.id === selectedSubscriptionData?.id)?.plans.length) {
            setInEligibleError({
                type: 'no_plans',
                reason: 'Unfortunately, Teams is not currently available for you.',
                cta: 'waitlist'
            });
        }
        else if(selectedSubscriptionData?.pureTeamsEligibility && !selectedSubscriptionData?.hasPureTeams){
            //initial state of the modal
            dispatchForTeamMember({type: 'initial', min: minLimit(selectedSubscriptionData), current: minLimit(selectedSubscriptionData), previous: currentForMembers(selectedSubscriptionData), price: pricing?.[selectedSubscriptionData?.plan?.interval_length]});
            dispatchForTeamServer({type: 'initial', min: 0, current: 0, previous: 0, price: teamServerPricing(selectedSubscriptionData, selectedSubscriptionPlans)})
            dispatchForDedicatedIp({type: 'initial', current: minLimitIP(), min:  minLimitIP(), previous:minLimitIP(),  price: dedicatedIpPricing(selectedSubscriptionData, selectedSubscriptionPlans), max: minLimit(selectedSubscriptionData)})
            setHasAddonsArray([])
            sendEvents({
                event_name:"ma_click_get_account",
                event_properties:{
                    plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                    billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                    payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                    current_status: selectedSubscriptionData?.state ?? 'N/A',
                    account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                    platform_from: 'Member Area',
                    selected_account: 'teams', 
                    click_via: type
                }
            }, token)
            toggleLicensesModal()
        }
        else{
            setInEligibleError({
                type: 'trial',
                reason: 'Get on the waitlist to know once it is available.',
                cta: 'waitlist'
            });
        }
  
    }
    const setInEligibleError = (payload) => {
        const {type, reason, cta} = payload;
        sendEvents({
            event_name:"ma_view_team_popup",
            event_properties: {
                account_type: checkAccountType(selectedSubscriptionData),
                action: cta,
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                current_status: selectedSubscriptionData?.state ?? 'N/A',
                message: reason
            }
        }, token);
        setShowError({
            show: true,
            type: type,
            reason: reason,
            cta: cta
        });
    }
    const proceedAfterLicensingSelection = () => {

        let addons = [];
        if (dedicatedIpState?.current > 0) {
            addons.push('dedicated_ip')
        }
        if(teamServerState?.current > 0){
            addons.push('team_server')
        }
        toggleLicensesModal();
        proceedToPurchase();
        sendEvents({
            event_name:"ma_click_get_teams",
            event_properties:{
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                current_status: selectedSubscriptionData?.state ?? 'N/A',
                account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                team_addons: addons ?? 'N/A',
                team_ip_count: dedicatedIpState?.current ?? 0,
                team_server_count: teamServerState?.current ?? 0,
                team_count: teamMemberState?.current ?? 0,
            }
        }, token)
    }
    const proceedToPurchase = () => {
        let todayDate = new Date().toJSON();

        setDataForLicensingPurchase({
            plan: selectedSubscriptionPlans?.code,
            subscription:selectedSubscriptionData?.id,
            service_origin:selectedSubscriptionData?.service_origin,
            isWhmcsUser:isWhmcsUser(selectedSubscriptionData?.account?.billingType),
            currency:selectedSubscriptionData?.currency,
            interval_length: selectedSubscriptionPlans?.interval_length,
            interval_unit: selectedSubscriptionPlans?.interval_unit,
            start_date: todayDate,
            expiry_date: selectedSubscriptionPlans?.end_date, 
            current_plan: selectedSubscriptionData?.plan_type, 
            state: selectedSubscriptionData?.state,
            billingcycle: selectedSubscriptionData?.type,
            account_type: checkAccountType(selectedSubscriptionData),
            sub_plan_type: selectedSubscriptionData?.plan_type
        });
        const addonsServer = [];
        const addonsIp = [];
        
        if(teamMemberState.current >= teamMemberState?.min){
            hasAddonsArray.push({code: 'purevpn', quantity: teamMemberState?.current});
            if(teamServerState?.current){
                addonsServer.push({code: 'team_server', quantity: teamServerState?.current});
            }
            if(dedicatedIpState?.current){
                // remove dedicated ip quantity if the user has already purchased the combo addon as it's a part of combo addon which will be converted to teams
                addonsIp.push({code: 'dedicated_ip', quantity: selectedSubscriptionData?.add_ons.find((add) => add.code === 'port_forwarding_with_dedicated_ip') ? dedicatedIpState?.current - 1 : dedicatedIpState?.current});
            }
            setHasAddonsArray([...hasAddonsArray,...addonsServer,...addonsIp])
        }
    
        setUpgradeModal(!upgradeModal)
    }
    const clickWaitList = (reason, cta) => {
        sendEvents({
            event_name:"ma_click_team_popup",
            event_properties: {
                account_type: checkAccountType(selectedSubscriptionData),
                action: cta ?? showError.cta,
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                current_status: selectedSubscriptionData?.state ?? 'N/A',
                message: reason ?? showError.reason
            }
        }, token);
        createTicket();
        toggleIneligibleModal();
    }
    const onErrorClick = (cta) => {
        if(cta === 'waitlist'){
            clickWaitList()
        }
        else{
            toggleIneligibleModal();
        }

    }
    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }
    const toggleLicensesModal = () => {
        setLicensesModal(!licensesModal);
    }
    const toggleUpgradeModal = () => {
        setUpgradeModal(!upgradeModal)
    }
    const toggleIneligibleModal = () => {
        setShowError({
            show: false,
            type: '',
            reason: '',
            cta: ''
        })
    }
    const createTicket = async () => {
        setTicketsLoading(true)
        try {
            const formData = new FormData();
            formData.append("title", "Teams waitlist")
            formData.append("content", "Update me when this feature will be available for me")
            formData.append("author_id", authorId)
            const create = await poster("tickets/create", formData, token)
            const {data: response} = create;
            if(response?.status){
                dispatch(setTicketsType(response?.body));
                setCreateTicketCount(+createTicketCount + 1);
                localStorage.setItem("ticketCount", +createTicketCount + 1)
                if (response?.body[0]?.author_id) {
                    localStorage.setItem("ticket_author_id", response?.body[0]?.author_id)
                }
                toast.success('Your ticket has been created successfully.');
            }else{
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            setTicketsLoading(false)
            toast.error(APIFAILED);
        }
        finally{
            setTicketsLoading(false)
        }
    }


    return <>
        {
            crashed ?  
            <ErrorBoundary /> : 
            !subscriptionsData?.subLoading && !plansData?.planLoading ? <PureTeamsStyles>
                { selectedSubscriptionData?.hasPureTeams ? 
                    <PostPurchase setInviteLink={setInviteLink} inviteLink={inviteLink} selectedSubscriptionData={selectedSubscriptionData} plansInfo={selectedSubscriptionPlans}/> 
                : <PrePurchase selectedSubscriptionData={selectedSubscriptionData} openLicensingSelectionModal={openLicensingSelectionModal} plansInfo={selectedSubscriptionPlans}/> 
                }

            </PureTeamsStyles> :  
            <SubscriptionBox open={true}>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </SubscriptionBox>
        }
    
        {licensesModal && <CheckoutLicenses
            toggleInfoModal={toggleInfoModal}
            toggleLicensesModal={toggleLicensesModal}
            selectedSubscriptionData={selectedSubscriptionData}
            plansInfo={selectedSubscriptionPlans}
            toggle={toggleLicensesModal}
            modal={licensesModal}
            proceedAfterLicensingSelection={proceedAfterLicensingSelection}

            teamServerState={teamServerState}
            dispatchForTeamServer={dispatchForTeamServer}

            teamMemberState={teamMemberState} 
            dispatchForTeamMember={dispatchForTeamMember}

            dedicatedIpState={dedicatedIpState} 
            dispatchForDedicatedIp={dispatchForDedicatedIp}
            />
        }
        {upgradeModal  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={dataForLicensingPurchase} widthCalculator={'lg'} modal={upgradeModal} toggle={toggleUpgradeModal} setorderSummary={setorderSummary} orderSummary={orderSummary} additionalAddons={hasAddonsArray} apiEndPoint={'pure-teams/change'} redirectedFrom={'teams'}/>}
        {showError.show && <Error backdrop={false} modalWidth={modalWidthForError} widthCalculator={'lg'} modal={showError.show} toggle={toggleIneligibleModal} message={showError?.reason} cta={showError?.cta} onErrorClick={onErrorClick}/>}
        {infoModal.open && <Info toggleLicensesModal={toggleLicensesModal} content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={infoModal.open} toggle={toggleInfoModal} />}

    </>
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer,
        pureTeamsReducer: state.pureTeamsReducer,
        onBoardingReducer:state.onBoardingReducer,
        ticketsReducer: state.ticketsReducer
    };
};
export default connect(mapStateToProps, {getTeamPlanRequested, getTicketsRequested})(PureTeams);