import styled, { css } from "styled-components";
import emailIcon from '../../assets/emailicon.png'
import lockIcon from '../../assets/lockicon.png'

export const LoginWrapper = styled.div`
    *:not(a){
        color:white;
    }
    background:#361B49;
    min-height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    .loader{
        position:fixed;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background:rgba(255,255,255,0.1);
        z-index:2;
        display:${props=>props.loading ? "flex" : "none"} !important;
    }
`;

export const LoginBody = styled.div`
    max-width:450px;
    p{
        font-size:14px;
        color: #B4A7BD;
    }
    /* -- media query for all devices -- */

    /* Mobile Devices */
    /* Login Screen */

    @media only screen and (max-width: 480px) and (min-width: 270px) {
        padding: 0px 15px;

        h1 {
        font-size: 22px !important;
        }

        p {
        font-size: 12px !important;
        }
    }
`;

export const LoginForm = styled.form`
    .form-group{
        margin-bottom:10px;
    }
    .checkbox label{
        color: #B4A7BD;
        font-size: 12px;
        font-weight:400;
    }
    .checkbox input{
        position: relative;
        top: -2px;
    }
    .rememberBox{
        margin: 18px 0 25px;
        display: table;
        width: 100%;
    }
`;

export const LoginInput = styled.input`
    border-radius: 5px;
    border:1px solid #ECE9EF !important;
    height:50px !important;
    letter-spacing: 0.25px !important;
    color:#0D85E8;
    font-size: 16px;
    font-weight:400;
    padding-left:45px;
    outline:none;
    background: #ECE9EF url(${props=>props.email ? emailIcon : lockIcon}) no-repeat;
    background-position: 12px 50%;
    ${(props) => props.validation && css`
        border:1px solid ${({theme:{branding:{danger}}})=>danger} !important;
    `}
    :focus, :active{
        color:#0D85E8;
        outline:none;
        border:0;
        box-shadow: none;
    }
`;