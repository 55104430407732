import React, {useState, useRef} from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody, TicketFields } from '../../../../styles/dashboard/Main';
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic';
import { toast } from 'react-toastify';
import {poster} from '../../../../tools/tools'
import { useDispatch } from 'react-redux';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { setTicketsType } from '../../../../redux/tickets/actions';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';


const CreateTicket = ({history, modal, toggle, setnewAdded, authorId}) => {

    const inputEl = useRef(null);

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch()

    const token = localStorage.getItem("token")

    const [data, setData] = useState({
        subject: "",
        message: "",
        file: null
    })

    const [loading, setLoading] = useState(false)

    const {subject, message} = data;

    const [errors, setErrors] = useState({
        subject: false,
        message: false
    })

    const resetState = () => {
        setData({subject:"", message:"", file:null})
        setErrors({subject:"", message:""})
        toggle()
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]:e.target.value})
    }

    const handleBlur = (e) => {
        setErrors({...errors, [e.target.name]: e.target.value ? false : true})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        let err = {subject:false, message:false}
        for (const key in data) {
           if(key !== 'file'){
               err[key] = data[key] ? false : true
           }
        }
        if(err.subject || err.message){
            setErrors(err)
            setLoading(false);
            return;
        }
        try {
            const formData = new FormData();
            formData.append("title",data.subject)
            formData.append("content",data.message)
            formData.append("author_id",authorId)
            if(data.file){
                formData.append("file",data.file)
            }
            const create = await poster("tickets/create", formData, token)
            const {data: response} = create;
            if(response?.status){
                dispatch(setTicketsType(response?.body));

                if (response?.body[0]?.author_id) {
                    localStorage.setItem("ticket_author_id", response?.body[0]?.author_id)
                }
                
                toast.success(`${t('your_ticket_text')} #${response?.body[0]?.id} ${t('has_been_created_text')}`)
                setnewAdded(true)
                toggle()
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN)) 
                        dispatch({type:"LOGOUT"})
                               
                        break;  
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN)) 
                        dispatch({type:"LOGOUT"})
                               
                        break; 
                    case "validation_exception":
                        let err = "";
                        Object.values(response?.errors).map(error=>{
                            error.map(e=>err+=`${e} \n`)
                        })   
                        toast.error(err)
                        break;      
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
        } catch (error) {
            toast.error(t(APIFAILED))
        }
        finally{
            setLoading(false)
        }
    }

    const [sizeError, setsizeError] = useState(false)

    const handleFile = (e) => {
        if(e.target.files[0]){
            const size = e.target.files[0].size/1000000
            if(size > 2){
                setsizeError(true)
                inputEl.current.value = "";
                setTimeout(() => {
                    setsizeError(false)
                }, 3000);
            }else{
                setData({...data, file: e.target.files[0]})
            }
        }
    }

    const ButtonText = !loading ? t('helpdesk_createticket_cta_text') : ( <>{t('helpdesk_createticket_cta_text_load')} <span className="spinner-border text-light"></span></>);

    return (
        <div>
            <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: '675px', width: '100%'}} className="modal-wrapper">
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <PopupBody>
                            <div className="mod-head mb-3">
                                <h3>{t('helpdesk_createticket_heading')}</h3>
                            </div>
                            <TicketFields validation={errors.subject}>
                                <label>{t('helpdesk_createticket_subject')}</label>
                                <input onBlur={handleBlur} autoComplete="off" type="text" name="subject" className="form-control" placeholder="Enter subject" onChange={handleChange} value={subject}/>
                                {
                                    errors.subject && <p className="danger">{t('helpdesk_createticket_subjectplaceholder')}</p>
                                }
                            </TicketFields>
                            <TicketFields validation={errors.message}>  
                                <label>{t('helpdesk_createticket_message')}</label>
                                <textarea onBlur={handleBlur} autoComplete="off" type="text" name="message" className="form-control" placeholder="Enter Message" onChange={handleChange} value={message}/>
                                {
                                    errors.message && <p className="danger">{t('helpdesk_createticket_message_placeholder')}</p>
                                }
                            </TicketFields>
                            {/* <TicketFields className="mt-3">
                                <input type="file" name="file" ref={inputEl} accept="image/png, image/gif, image/jpeg" className="form-control" onChange={handleFile}/>
                                {
                                    sizeError && <p className="danger">File size is too large, maximum 2MB</p>
                                }
                            </TicketFields> */}
                        </PopupBody>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                        <PrimaryBtn color="primary" type="submit">{ButtonText}</PrimaryBtn>
                        <DestructBtn color="secondary" onClick={resetState} type="button">{t('cancel_text')}</DestructBtn>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default withRouter(CreateTicket)
