import { createGlobalStyle } from 'styled-components'
import checkMark from '../assets/check-mark.svg'

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin:0;
        padding:0;
        font-family: 'Open Sans', sans-serif;
    }
    html,body,#root{
        height:100%;
        width:100%;
        background:#F6F4F7;
    }
    h1,h2,h3{
        color:${({ theme: { branding } }) => branding.heading};
        font-weight:700;
    }
    h1{
        font-size:${({ theme: { typography } }) => typography.h1};
    }
    h2{
        font-size:${({ theme: { typography } }) => typography.h2};
    }
    h3{
        font-size:${({ theme: { typography } }) => typography.h3};
    }
    p, ul, li{
        font-size:${({ theme: { typography } }) => typography.body};
        font-weight:400;
        color:#7B648C;
        line-height: 150%;
        letter-spacing: 0.5px;
    }
    p{
        line-height: 125%;
        letter-spacing: 0.02em;
    }
    .smallBody{
        font-size:${({ theme: { typography } }) => typography.smallBody} !important;
    }
    .smallerBody{
        font-size:${({ theme: { typography } }) => typography.smallerBody} !important;
    }
    .danger{
        color:${({ theme: { branding } }) => branding.danger} !important
    }
    .lh-150{
        line-height:150% !important;
    }
    span, li{
        font-size:${({ theme: { typography } }) => typography.smallBody} !important;
        font-weight:400;
        color:#7B648C !important;
        line-height: 150%;
        letter-spacing: 0.5px;
    }
    input, select, .form-control{
        border: 1px solid #EBE6FF;
        border-radius: 8px;
        height:48px;
        color:#43225B !important;
        font-size: 14px;
        ::placeholder{
            color: #637381 !important;
        }
        :focus, :active{
            border: 0.5px solid #43225B;
            outline:none;
            box-shadow:none;
        }
    }
    a{
        font-size:${({ theme: { typography } }) => typography.link};
        font-weight:600;
        color:#0D85E8;
        text-decoration: none;
        :hover{
            color:#0D85E8;
        }
    }
    p a{
        font-size:14px;
    }
    .smallerBody{
        font-size:${({ theme: { typography } }) => typography.smallerBody};
    }
    .fw-600{
        font-weight:600;
    }
    .modal-body{
        .is-dedicated-info-modal{
            max-height: calc(100vh - 40px) !important;
            overflow-y: auto !important;
        }
        .is-team-comparison-modal{
            max-height: calc(100vh - 40px) !important;
            overflow-y: auto !important;
            .yellow-text{
                padding: 2px 6px;
                font-size: 12px !important;

                @media only screen and (max-width: 650px) {
                    font-size: 10px !important;
                    padding: 2px 2px;
                }
            }
            button{
                font-size: 11px !important;
            }
        }
    }
    .modal-footer{
        justify-content:flex-start;
        border-top:0;
    }
    .modal-content{
        background: #FBFBFC;
        box-shadow: 0px 24px 24px rgb(0 0 0 / 10%);
        border-radius: 10px;
        padding: 15px;
    }
    .cancellation-content{
        height: 100% !important;
        border-radius: 0px;
        position: relative;
    }
    .modal-header{
        padding: 0;
        border-bottom: none;
        position: absolute;
        top: -9px;
        right: 8px;
        .close{
            border: none;
            background: none;
            span{
                color:#637381 !important;
                font-size: 30px !important;
                font-weight: 400;
            }
        }
    }
    .waitlistWrapper .modal-header{
        position: unset;
        display: flex;
        flex-direction: row;
        .en-ds-div {
           margin-bottom: 5%;
        }
        h5{
            color: #003566;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            margin:auto;
            span:disabled, span[disabled]{
                color: #a9a9a9 !important;
                margin-right: 0px;
            }
            span:disabled::before, span[disabled]::before{
                display: none;
            }
            span {
                color: #003566 !important;
                font-weight: 600;    
                position:relative;
                margin-right: 15px;
                font-size: 12px;
            }
            span::before {
                content: "";
                position: absolute;
                background-image: url(${checkMark});
                width: 13px;
                left: 75px;
                top: 3px;
                height: 14px;
            }
            .line.disabled {
                border: 1px solid #a9a9a9;
            }
            .line {
                height: 0px;
                width: 77px;
                display: inline-block;
                vertical-align: middle;
                margin: 0px 5px;
                border: 1px solid #003566;
                border-radius: 8px;
            }
            .cardHead{
                font-size: 18px !important;
                font-weight: 600;
                color: #003566 !important;
            }
        }
    }
    .waitlistWrapper .modal-dialog{
        max-width: 700px;
    }
    .waitlistWrapper .modal-content{
        width: 676px;
        padding: 13px 46px 13px 33px;
    }
    .waitlistWrapper .modal-body{
        padding: 0px;
        .modal-heading{
            margin: 15px 0;
            color: #003566;
        }
        form {
            display: flex;
            flex: auto;
            flex-direction: column;
            align-items: center;
            textarea {
                height: 154px;
                resize: none;
                margin: 14px 0px;
                padding: 8px;
                width: 100%;
            }
            button {
                border: none;
                border-radius: 30px;
                padding: 10px 30px;
                background: #003566;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                width: 242px;
            }
            button:disabled, button[disabled]{
                background: #a9a9a9;
            }
            .question-1-6 {
                margin-bottom: 30px;
            }
            .question {
                font-size: 18px !important;
                font-weight: 600;
                color: #003566 !important;
                align-self: flex-start;
                margin-bottom: 10px;
            }
            .question-checkbox {
                align-self: flex-start;
                vertical-align: middle;
                input[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                    border: 1px solid #003566;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .checkbox:checked {
                    background-color: #003566 !important;
                    border-color: #003566 !important;
                }
                .checkbox-label {
                    vertical-align: middle;
                    font-size: 16px;
                    font-weight: 500;
                    color: #003566;
                    line-height:150%;
                }
            }
        }
    }

    .waitlistCardWrapper .modal-header{
        position: unset;
        display: flex;
        flex-direction: row;
        h5{
            color: #003566;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            margin:auto;
            span:disabled, span[disabled]{
                color: #a9a9a9 !important;
                margin-right: 0px;
            }
            span:disabled::before, span[disabled]::before{
                display: none;
            }
            span {
                color: #003566 !important;
                font-weight: 600;    
                position:relative;
                margin-right: 15px;
                font-size: 12px;
            }
            span::before {
                content: "";
                position: absolute;
                background-image: url(${checkMark});
                width: 13px;
                left: 75px;
                top: 3px;
                height: 14px;
            }
            .line.disabled {
                border: 1px solid #a9a9a9;
            }
            .line {
                height: 0px;
                width: 77px;
                display: inline-block;
                vertical-align: middle;
                margin: 0px 5px;
                border: 1px solid #003566;
                border-radius: 8px;
            }
            .cardHead{
                font-size: 18px !important;
                font-weight: 600;
                color: #003566 !important;
            }
        }
    }

    .waitlistCardWrapper .modal-dialog{
        max-width: 700px;
    }
    .waitlistCardWrapper .modal-content{
        width: 676px;
        padding: 13px 46px 13px 33px;
    }
    .waitlistCardWrapper .modal-body{
        padding: 0px;
        .modal-heading{
            margin: 20px 0 25px;
            color: #003566;
        }
        form {
            display: flex;
            flex: auto;
            flex-direction: column;
            align-items: center;
            textarea {
                height: 120px;
                resize: none;
                margin: 14px 0px 20px;
                padding: 8px;
                width: 100%;
                border: 1px solid rgba(0, 53, 102, 0.4);
                border-radius: 8px;
                color: #003566;
            }
            textarea:focus {
                outline: none !important;
                border: 1px solid rgba(0, 53, 102, 0.4);
                box-shadow: 1px 1px 1px 1px #c9c9c9;
            }
            textarea::placeholder {
                color: #003566;
            }
            button {
                border: none;
                border-radius: 30px;
                padding: 15px 30px;
                background: #003566;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                width: 242px;
                margin: 0px auto;
            }
            button:disabled, button[disabled]{
                background: #a9a9a9;
            }
            .question-1-6 {
                margin-bottom: 30px;
            }
            .question {
                font-size: 18px !important;
                font-weight: 600;
                color: #003566 !important;
                align-self: flex-start;
            }
            .question-checkbox {
                align-self: flex-start;
                vertical-align: middle;
                input[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                    border: 1px solid #003566;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .checkbox:checked {
                    background-color: #003566 !important;
                    border-color: #003566 !important;
                }
                .checkbox-label {
                    vertical-align: middle;
                    font-size: 16px;
                    font-weight: 500;
                    color: #003566;
                    line-height:150%;
                }
            }
        }
    }
    .buddy-modal-header{
        top: -19px;
        right: 0px;
    }
    .noPad-modal{
        padding: 0px !important;
        background: none;
    }

    .modal.fade .modal-purebuddy-transition {
        right: -600px;
        opacity: 1;
        transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .modal.show .modal-purebuddy-transition {
        right: 0px;  
        opacity: 1;
        transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .modal {        
        .modal-body::-webkit-scrollbar {
            width: 4px !important;
        }
    
        .modal-body::-webkit-scrollbar-thumb {
            background: rgba(99, 115, 129, 0.5);
            border-radius: 40px;
        }
    }

    .modal-v2 {
        .modal-content {
            padding: 0px;
            display: flex;
            width: 100%;
            flex-direction: row;

            .modal-body {
                width: 55%;

                .body-box {
                    padding: 30px 30px 0px;
                }
            }
            .modal-footer {
                padding: 15px 30px 30px;
            }
            .box {
                width: 45%;
                min-width: 300px;
                padding: 20px 30px 30px;

                button {
                    font-weight: 600;
                }
            }
        }
        .modal-body {
            padding: 0px;
        }

        @media only screen and (max-width:600px) and (min-width: 270px) {
            .modal-content {
                flex-direction: column;
                & .box, 
                & .modal-body {
                    width: 100%;
                }
            }
        }
    }

    .Toastify__toast-container{
        max-width:440px;
        width:100%;
        @media only screen and (max-width:600px) and (min-width: 270px) {
            width: 80%;
            min-height: auto;
            margin: 15px 0px 5px 10px;
            left:auto;
        }
    }

    .Toastify__toast-body{
        font-size:12px !important;
        color:#43225B;
        letter-spacing: 0.5px;
        line-height: 125%;
        font-weight:400;
        @media only screen and (max-width:600px) and (min-width: 270px) {
            letter-spacing: 0.6px;
            line-height: 145%;
        }
    }
    
    .Toastify__toast--error {
        background: #FFE8EC;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .Toastify__close-button{
        color: #43225B
    }

    .Toastify__toast--success{
        background: #CAF6E0;
        border-radius: 10px;
    }
    
    .modal {
        background: rgb(0 0 0 / 50%);
    }

    body.modal-open {
        padding: 0 !important;
    }
    
    .table-wrapper{
        box-shadow: 0px 12px 24px rgba(67, 34, 91, 0.05);
        border-radius: 10px;
        overflow:hidden;
        background: #EEE9F4;
        width:100%;
        // max-width:910px;
    }

    .hasScroll tbody {
        display: block;
        overflow: auto;
        width:100%;
        @media (min-width:800px){
            max-height: 334px;
        }
    }
    .hasScroll thead, .hasScroll tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
    }
    .hasScroll thead {
        width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
    }
    /* @media (min-width: 576px){
        .waitlistCardWrapper .modal-dialog {
            height: auto;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
        }

        .modal-dialog {
            height: 100%;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
        }
    } */
    .dis-link{
        cursor: not-allowed !important;
        color:#a3a3a3;
        :hover{
            color:#a3a3a3;
        }
    }
    .affiliate-wrapper{
        height:100%;
        ::after{
            content:'';
            position:absolute;
            right:15px;
            background:${({ theme: { backgrounds } }) => backgrounds.affiliate};
            background-size:contain;
            width:40%;
            height:100%;
            top:10%;
            background-position: top;
            /* Mobile Devices */
            @media only screen and (max-width: 767px) {
                display: none;
            } 
            z-index:1;
        }
    }
    .business-wrapper{
        height:100%;
        ::after{
            content:'';
            position:absolute;
            right:15px;
            background:${({ theme: { backgrounds } }) => backgrounds.business};
            background-size:contain;
            width:40%;
            height:100%;
            top:10%;
            background-position: top;
            @media only screen and (max-width: 767px) {
                display: none;
            } 
        }
    }
    .spinner-border.text-dark{
        color:#43225B !important;
    }
    .btn-tooltip {
        position: relative;
        display: inline-block;
        height:34px;
        width:34px;
    }

    .btn-tooltip img{
        margin:0 !important;
        height:34px;
        width:34px;
    }

    .btn-tooltip .spinner-border{
        
    }

    .dedicated-ip-details{
        display: flex;
        margin-left: 5px;
        margin-top: 5px
    }
    .dedicated-ip-details img{
        margin-right: 10px;
    }
    .dedicated-ip-details .dedicated-ip-address-text{
        font-size: 14px !important;
        color: #43225B !important;
    }
    
    .btn-tooltip .tooltiptext {
        visibility: hidden;
        width: 80px;
        font-size:12px !important;
        background-color: #555;
        color: #fff !important;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s;
    }
    
    .btn-tooltip.copied .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    /* -- media query for all devices -- */

    /* Small Tablets and Mobile Devices */
    /* Modal Responsive */
    .bundle-modal{
        max-width:710px !important;
    }
    @media only screen and (max-width: 700px) and (min-width: 576px) {
       .modal-wrapper .modal-content{
        margin: 10px;
       }
       .bundle-modal{
        max-width: min-content !important;
       }
    } 

    .modal-wrapper{
        &.white-bg{
            background: #fff !important;
            .modal-content{
                box-shadow: none !important;
                border: none !important;
                height: inherit !important;
            }
        }
        &.new-cart{
            .mod-head{
                h3{
                    color: #001219 !important;
                }
                p{
                    color: #637381 !important;
                }
            }
            .mod-body{
                label, li{
                    color: #637381 !important;
                    strong{
                        color: #001219 !important;
                    }
                }
                span, h3{
                    color: #001219 !important;
                }
                .summary{
                    color: #7320FF !important;
                }
            }
            .modal-footer{
                select, input, .recurly-element{
                    color: #001219 !important;
                    border: 1px solid #BDABFF !important;
                }
            }
        }
        span.close-icon{
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
            z-index: 1
        }
        .consent{
            height: 493px;
            overflow: auto;
            padding: 15px !important;
        }
    }

    @media only screen and (max-width: 570px) {

        .modal-wrapper .modal-content{
        padding: 4px 0px;
       }
       .bundle-modal{
        max-width: min-content !important;
       }

       /* Modal Body Scroll */
        
       .modal-wrapper .mod-body{
        max-height: 270px !important;
        overflow-y: auto !important;
        padding: 0;
        scroll-padding: 0px !important;

        &.no-scroll{
            max-height: none !important;
            overflow-y: inherit !important;
        }
       
       }

       .modal-footer{
       padding: 10px 12px;
       }

       .payment-footer{
        font-size: 12px !important;
        margin-top: 15px;
       }

       select{
        font-size: 10px !important;
        padding: 0 3px;
       }
      

       .mod-head{
           margin-bottom: 5px !important;
           h3{
               font-size: 14px !important;
           }
           p{
               font-size: 12px !important;
               line-height: 145% !important;
               letter-spacing: 0.2px !important;
           }
       }
    
       .vpnAccountsHead {
         p{
             font-size: 12px !important;
         }
       }

       h3{
           font-size: 14px;
       }
    }  

     /* All Devices - Modal Center */
     @media (min-width: 270px){
        .waitlistCardWrapper .modal-dialog {
            height: auto;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
        }
        .modal-dialog {
            height: 100%;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
        }

        .modal-content{
            margin: 10px;
        }
    }

     /* Table row space */

    @media only screen and (max-width: 768px){

       .table-wrapper{
           margin-top: 20px !important;
       }

       .table-wrapper .list-unstyled{
        text-align: left !important;
        margin-top: 25px !important;
        padding-left: 2px !important;
       }
    }

     /* Refer Box width adjust */

     @media only screen and (max-width: 1398px) and (min-width: 768px){
         .refer .col-md-9{
          width: 65% !important;
         }

         .col-md-3{
          width: 35% !important;
         }
     }

     /* Download and Guide Box width adjust */

     @media only screen and (max-width: 1398px) and (min-width: 1023px){
         .col-md-8{
          width: 65% !important;
         }

         .col-md-4{
          width: 35% !important;
         }
     }

     @media only screen and (max-width: 1022px) and (min-width: 768px){
         .col-md-8{
          width: 55% !important;
         }

         .col-md-4{
          width: 45% !important;
         }
     }

      /* Payment History All Screens and Help / Ticket Screen Table width adjust */

      @media only screen and (max-width: 1200px) and (min-width: 768px){
        .table-row .col-md-11{
          width: 100% !important;
         }

         .table-row .col-md-10{
          width: 100% !important;
         }

         .table-row .col-md-12{
          width: 100% !important;
         } 
     }  

      /* All headings, paragraph and list */

     @media only screen and (max-width: 550px) and (min-width: 270px) {
        
        h3{
            font-size: 14px !important;
        }

        .smallBody{
            font-size: 12px !important;
        }

        ul{
            margin-bottom: 0 !important;
        }

        li{
            font-size: 12px !important;
            line-height: 155% !important;
        }

        .lh-150 {
            line-height: 165% !important;
        }

        input, select, .form-control{
            font-size: 12px !important;
        }

        .table-wrapper{
            margin-bottom: 15px !important;
        }

        a{
            font-size: 11px !important;
        }

        .account-loading{
            width: 210px !important;
        }

        p{
            line-height: 163% !important;
        }
    }
    @media (min-width:1024px) and (max-width:1300px){
        .download-row{
            display:flex;
            justify-content:center;
        }
        .download-row:not(.col-lg-8) > .col-lg-4 {
            width: 50%;
            margin-bottom:30px;
            text-align:center;
        }
    }

    .cardSubBtn{
        display: flex;
        width: 100%;
        justify-content: end;
    }

    .radioBtn {
        width: 26px;
        height: 19px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #003566 !important;
        margin-top: 10px;
      }
      
      .radioSpan {
        color: #003566 !important;
        width: 26px;
        height: 19px;
        position: absolute;
        margin-top: 8px;
        margin-left: 8px;
        font-size: 16px !important;
      }

    .signature{
        font-family: 'Pinyon Script', cursive;
    }
`
