import styled, { css, keyframes } from "styled-components";
import search from '../../assets/search.png'
import hamBurgerIcon from '../../assets/hamBurgerIconBlue.svg'
import menuIcon from '../../assets/menuIconBlue.svg'
import thumbs from '../../assets/thumbs.png'
import chatBot from '../../assets/bot_chat.svg'

export const PureAIView = styled.div`
    color: #003566;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color:rgb(210, 207, 215);
        outline: none;
        border-radius:3px;
        opacity: 0.2;
    }
    span {
      color: #003566 !important;
    }
    * {
      color: #003566;
    }
    @media (max-width: 1060px) {
      padding: 20px 0 0;
      overflow: auto;
    }
    .disp-blk {
      display: block;
    }
`;

export const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Dot = styled.div`
  background-color: #838181;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 4px;
  animation: ${bounce} 1s infinite ease-in-out;
  animation-delay: ${props => props.delay}ms;
`;

export const PureAIChatView = styled.div`
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color:rgb(210, 207, 215);
        outline: none;
        border-radius:3px;
        opacity: 0.2;
    }
    .chatBox {
      width: 100%;
      overflow-y: auto;
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
          background-color:rgb(210, 207, 215);
          outline: none;
          border-radius:3px;
          opacity: 0.2;
      }
      .chatBoxBody {
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color:rgb(210, 207, 215);
            outline: none;
            border-radius:3px;
            opacity: 0.2;
        }
        @media (max-width: 1200px) {
          margin-bottom: 100px;
        }
      }
    }
    .prompt-response-view{
      text-align: left !important;
      max-height: calc(100% - 100px);
      .sub-prompt {
        background: #fff;
        padding:25px 0;
    
      }
      .sub-response {
        background: #DCF0FB;
        padding:25px 0;
        .sub-response-sub-div{
          width:100%;
          .sub-response-sub-div-ctas-item{
            display:inline-flex;
            border: 1px solid #003566;
            border-radius: 5px;
            padding: 3px 34px;
            margin: 0 10px;
            text-transform: capitalize;
            background: #fff;
            cursor: pointer;
            :hover{
              background: #003566;
              color:#fff;
            }
          }
        }
      }
      .sub-prompt,.sub-response{
        display: flex;
        align-items: center;
      }
      .loading{
        display:flex !important;
        align-items:center;
        font-size:17px !important;
        font-weight:600;
        letter-spacing:1px !important;
        
      }
      .prompt-text,.response-text{
        width:87%;
        margin: 0 70px 0 auto;
        display:flex
      }
    
      .prompt-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #333333;
      }
      .response-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #333333;
        .completion-response {
          width: 100%;
          color: #333333;
          .cursor {
            height: 1rem;
            width: 6px;
            margin-left: 3px;
            background-color: #00000091;
            animation: cursor-blink 2s infinite;
            display: inline-block;
          }
          
          @keyframes cursor-blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
      .reactionArea{
        display:flex;
        justify-content:flex-end;
        align-items:flex-end;
        margin-right:120px;
        padding:20px 0;
        @media(max-width: 1100px) {
            padding:10px 0;
    
        }
        @media (max-width: 768px) {
            margin-right:16px;
            padding:10px 0 0 0;
        }
      }
      .reaction{
        cursor:pointer;
        background-image: url(${thumbs});
        width:19px;height:18px;
      }
      .reaction.up{
        background-position:-10px -10px;
        display:block;
        margin:0 15px 0 15px;
      }
      .reaction.down{
        background-position:-50px -8px;
        display:block;
      }
      .reaction.up.active{
        background-position: -48px -50px;
        display:block;
    
      }
      .reaction.down.active{
        background-position:-10px -50px;
        display:block;
    
      }
    
    
      .avatar{
        background-image: url(${chatBot});
        background-size: cover;
        background-position: center;
      }
      .avatarSize{
        min-width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      @media (max-width: 1200px) {
        .reactionArea{
          margin-right:64px;
        }
        .prompt-text,.response-text,.loading{
          width:80%;
          margin:0 32px!important;
          font-size:15px !important;
          line-height:20px;
          .avatarSize{
            height:28px;min-width:28px;
          }
        }
        .sub-prompt,.sub-response{
            padding:15px 0;
        }
      }
      @media (max-width: 768px) {
        .prompt-text,.response-text,.loading{
          width:90%;
          margin:0 16px!important;
          font-size:14px !important;
          line-height:20px;
          .avatarSize{
            height:24px;min-width:24px;
          }
        }
        .sub-prompt,.sub-response{
            padding:15px 0;
        }
      }
    }
    .promptView{
      margin: 70px auto 0 auto;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 890px;
      span{
        padding: 20px 30px;
        margin: 0 0px 20px 0;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 18px;
        text-align: center;
        color: #001219 !important;
        background: #FFFFFF;
        border: 1 solid #DDD4E2;
        box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
        border-radius: 7px;
        cursor: pointer;
    
        width: calc(32.33% - 10px);
        text-align: center;
      }
      @media (max-width: 1400px) {
          width: revert;
          justify-content: center;
          gap:20px;
        span{
          width: auto;
          
        }
      }
      @media (max-width: 550px) {
        flex-direction:column;
        span{
          width:revert !important;      
        }
      }
    }
    }  
`;

export const AvatarDiv = styled.div`
  background-color: #4FA1D1;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  border:1.6px solid #003566;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PureAIRecommendationsView = styled.div`
    display: flex;
    height: 100%;
    .content-display {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
          background-color:rgb(210, 207, 215);
          outline: none;
          border-radius:3px;
          opacity: 0.2;
      }
      padding: 25px;
      width: 75%;
      margin: 0 auto;
      text-align: center;
      overflow-y: auto;
      overflow-x: hidden;
      float:left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .bottomMenu {
        background: none;
        @media only screen and (max-width: 580px) {
          background: #fff;
        }
      }
      @media (max-width: 1700px) {
        width:100%;
      }
      .tagLine {
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        color: #333333;
      }
      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin: 20px 0 30px 0;
      }
      .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        max-width: 1200px;
        margin:0 auto;
        width: 80%;
      }
      .grid-item{
          padding: 20px 30px;
          font-weight: 400;
          font-size: 15px !important;
          line-height: 18px;
          text-align: center;
          color: #001219 !important;
          background: #FFFFFF;
          border: 1 solid #DDD4E2;
          box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
          border-radius: 7px;
          cursor: pointer;
          text-align: center;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      .grid-item:hover{
        background: #EDE9F3;
      }
      @media only screen and (max-width: 1000px) {
        .grid-container {
          grid-template-columns: repeat(2, 1fr);
        }
        .grid-item{
          font-size: 14px !important;
        }
        .grid-item:only-child {
          grid-column: 1 / span 2;
          text-align: center;
        }
        .grid-item:nth-child(9) {
          grid-column: 1 / span 2;
          text-align: center;
        }
      }

      @media only screen and (max-width: 580px) {
        .grid-item{
          font-size: 13px !important;
          min-width: 300px;
        }
        .grid-container {
          grid-template-columns: repeat(1, 1fr);
          width: 100%;
          margin-bottom: 100px;
        }
        .grid-item:nth-child(9) {
          grid-column: 1;
          text-align: center;
        }
      }

      .headingDiv {
        display: inline-flex;
        .pure-ai-logo {
          font-size: 2.2vw;
          font-weight: 600;
          color: #000;
          text-align: center;
          img {
            width: 50px;
            margin: 4px 8px 8px -6px;
          }
          span {
            font-size: 2.5vw !important;
            color: inherit !important;
            font-weight: 200;
            margin-left: 4px;
          }
        }
        .heading{
          display:flex;
          align-items:center;
          justify-content:center;
          color: #000;
          h1{
            font-weight: 200;
            font-size: 44px;
            line-height: 56px;
            color: #000;
            strong{
              font-weight: 600;
              color: #000;
            }
          }
          span{
            margin-left:15px;
            width: 70px;
            height: 22px;
            background: #fff;
            border-radius: 5px;
            color:#000 !important;
            font-weight: 700;
            font-size: 14px !important;
            line-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:4px !important;
            box-shadow: 0px 4px 10px rgba(67,34,91,0.05), 0px 10px 30px rgba(67,34,91,0.1);
            box-sizing: border-box;
          }
        }
      }
    }
    @media only screen and (max-width: 580px) {
      .side-bar {
        display:none;
      }
    }
    .side-bar {
      width: 25%;
      float:left;
      background-color: #F2F4F4 !important;
      border-left: 1px solid #d9d9d9;
      .puretools {
        padding: 5%;
        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          color: #003566;
        }
      }
      .card-container {
        display: flex;
        width: 100%;
        padding: 5%;
      }
      
      .card-item{
          padding: 20px 20px;
          font-weight: 400;
          font-size: 0.75vw;
          line-height: 18px;
          text-align: center;
          color: #001219 !important;
          background: #FFFFFF;
          border: 1 solid #DDD4E2;
          box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
          border-radius: 7px;
          cursor: pointer;
          text-align: center;
          vertical-align: middle;
          display: block;
          align-items: center;
      }
      
      .card-item:hover{
        background: #EDE9F3;
      }
      
      .firstdiv {
        display: flex;
        flex-direction: row;
      }
      
      .cardIcon {
        
      }
      
      .cardHeading {
        font-size: 16px;
        margin-left: 5%;
        padding-top: 2%;
        color: #003566;
      }
      
      .cardDesc {
        padding-top: 5%;
        font-size: 0.9em;
        color: #003566;
        text-align: start;
      }
      @media only screen and (max-width: 1200px) {
        display:none;
      }
    }
`;

export const PureAIWaitlistView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    .main-section {
        display: flex;
        width: 60%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin:auto 20px;
        padding: 20px 30px;
        background: #fff;
        box-shadow: 0px 4px 10px rgba(67,34,91,0.05), 0px 10px 30px rgba(67,34,91,0.1);
        box-sizing: border-box;
        border-radius: 10px;
        .logo {
          font-size: 2.2vw;
          font-weight: 600;
          color: #000;
          text-align: center;
          margin: 0 0 30px;
          img {
            width: 50px;
            margin-right: 10px;
          }
          span {
            font-size: 2.5vw !important;
            color: inherit !important;
            font-weight: 200;
            margin-left: 4px;
          }
          .tagline {
            display: block;
            font-size: 1.3vw;
            color: #000;
            font-weight: 350;
          }
        }
        .demo-video {
          width: 90%;
          margin: 0 0 30px;
          video {
            width: 100%;
            box-shadow: 0px 4px 10px rgb(0 65 255 / 5%), 0px 10px 30px #656565eb;
            box-sizing: border-box;
            border-radius: 10px;
          }
          .account-loading {
            text-align: center;
          }
        }
        .tagline {
          display: block;
          font-size: 1.3vw;
          font-weight: 400;
          margin: 0 0 30px;
        }
        .waitlist button {
          border: none;
          border-radius: 30px;
          padding: 12px 60px;
          background: #003566;
          margin: 0 0 30px;
          color: #fff;
          font-weight: 700;
          font-size: 1vw;
          -webkit-letter-spacing: 0.5px;
          -moz-letter-spacing: 0.5px;
          -ms-letter-spacing: 0.5px;
          letter-spacing: 0.5px;
        }
    }
    .side-section {
        display: flex;
        width: 40%;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 14px;
        margin: 0 3% 0 0;
        .side-section-heading {
          font-size: 24px;
          font-weight: 600;
          margin: 0 auto 20px;

        }
    }
    @media (max-width: 1060px) {
      flex-flow: wrap;
      justify-content: center;
      .main-section {
        align-items: center;
        width: 80%;
        margin: 20px auto;
        .logo {
          font-size: 4.5vw;
          span {
            font-size: 4.5vw !important;
          }
          .tagline {
            font-size: 3.5vw;
            margin-top: 10px;
          }
        }
        .tagline {
          font-size: 3.5vw;
        }
        .waitlist button {
          font-size: 2vw;
        }
      }
      .side-section {
        align-items: center;
        width: 80%;
        margin: 20px auto;
      }
    }
`;

export const EarlyAccessCardView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 36px;
    box-shadow: 0px 4px 10px rgba(67,34,91,0.05), 0px 10px 30px rgba(67,34,91,0.1);
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    margin: 0 0 20px;
    &.last {
      margin: 0;
    }
    .card-content {
        width: 70%;
        display: flex;
        flex-direction: column;
        font-size: 0.8vw;
        .card-title {
          font-size: 0.9vw;
          font-weight: 600;
        }
    }
    .card-image {
      width: 25%;
      img {
        width: 100%
      }
    }
    @media (max-width: 1199px) {
      .card-content {
        width: 79%;
      }
      .card-image {
        width: 20%;
        img {
          width: 100%
        }
      }
    }
    @media (max-width: 1060px) {
      justify-content: flex-start;
      &.last {
        margin: 0 0 20px;
      }
      .card-content {
        font-size: 2vw;
        width:80%;
        .card-title {
          font-size: 2.4vw;
          font-weight: 600;
        }
      }
      .card-image {
        display:none;
      }
    }
`;

export const MessageBoxView = styled.div`
    padding: 15px;
    height: 100px;
    background: #fff;
    display:flex;
    @media only screen and (max-width: 580px) {
      position: fixed;
      min-width: 90%;
      width: 90%;
      bottom: 0;
      &.chat-view {
        width: 100%;
        min-width: 100%;
      }
    }
    .menu-icon{
      cursor: pointer;
      display: inline-block;
      width: 40px;
      height: 37px;
      background-image: url(${hamBurgerIcon});
      background-size: cover;
      background-position: center;
      vertical-align: middle;
      margin: auto 0px;
      @media (min-width: 750px) {
        display:none;
      }
      &:hover{
        background-image: url(${menuIcon});
      }
    }
    .messageBox {
      width: 80%;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid rgba(0, 53, 102, 0.4);
      border-radius: 8px;
      height: 55px;
      font-size: 17px;
      padding: 0 10px;
      background-repeat: no-repeat;
      text-indent: 10px;
      margin: 10px auto;
      @media only screen and (max-width: 890px) {
        display: flex;
      }
      &.disabled{
        img {
          filter: grayscale(100%) brightness(10%) opacity(50%);
        }
      }
      &:focus{
        border: 1px solid #4FA1D1;
      }
      img {
        position: relative;
        cursor: pointer;
        @media only screen and (max-width: 740px) {
          left:-5px;
        }
      }
      .test {
        vertical-align: middle;
        border: none;
        min-width: calc(100% - 45px);
        height: 100%;
        padding: 0 5px;
        font-size: 15px;
        @media only screen and (max-width: 740px) {
          height: 35px;
          display: flex;
          flex:1;
        }
      }
    }
`;

export const SuggestionsBarView = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 30%;
  max-width: 350px;
  box-shadow: 0px 24px 24px rgba(0,0,0,0.1);
  background-color: #fff;
  flex-direction: column;
  overflow-y: auto;
  .nav-cta{
    width:90%;
    margin: 25px auto 10px;
    background: #fff;
    border:1px solid #003566; 
    border-radius: 117px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    :hover{
      background: #003566;
      color:#fff;
    }
    color:#003566;
    ${props => props.toDisableCta && css`
      opacity:0.6;
      cursor:default;
    `}
  }
  .filterDiv {
    border-top: 1px solid #E8E8E9;
    width: 100%;
    margin: 10px 0;
    span.filters {
      margin: 0 15px;
    }
    .filterBtnDiv {
      margin: 0 10px;
      .promptFilterBtn {
        margin: 5px;
        background: transparent;
        padding: 5px;
        border: 1px solid #003566;
        border-radius: 4px;  
        font-size: 13px;
        img {
          margin-right: 5px;
          width: 20px;
        }
      }
      .promptFilterBtnActive {
        margin: 5px;
        background: #003566;
        color: #fff;
        padding: 5px;
        border: 1px solid #003566;
        border-radius: 4px;  
        font-size: 13px;
        img {
          margin-right: 5px;
          width: 20px;
        }
      }
    }
  }
  .input-search{
    width: 90%;
    margin: 0 auto;
  }

  .bottom-section {
    max-height: 230px;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    .star-rating{
      display: flex;
      justify-content: space-between;
      margin: 15px auto 20px;
      width: 85%;
      align-items: center;
      font-size: 21px;
      button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;
        &:after {
          content: "\\2606";
          color:#fff;
        }
        &.on:after {
          content: "\\2605";
        }
      }
    }
    span{
      display: flex;
      align-items: center;
      padding: 5px 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color:#000 !important;
      font-size: 18px !important;
      width:100%;
      min-height:60px;
      img{
        width: 29px;height:29px;
        margin-right: 10px;
      }
    }
    .feedback-btn{
      img{
        height:21px !important;
      } 
    }
    .review-box {
      background: #003566;
      box-shadow: 0px 4px 8px rgba(0,53,102,0.12);
      border-radius: 8px;
      position: absolute;
      right: 122px;
      bottom: 45px;
      padding: 20px 16px;
      width: 280px;
      .hed {
        color: #fff;
        margin: 0;
        font-size: 17px;
        font-weight: 600;
        display: inline-block;
      }
      .close-icon {
        float: right;
        cursor: pointer;
      }
      .feedBackErr {
        font-size: 14px;
        color: #fff;
        margin: 6px 0 0;
        text-align: center;
      }
      .textarea {
          background: #fff;
          border: 1px solid rgba(0,53,102,0.4);
          border-radius: 8px;
          width: 100%;
          min-height: 60px;
          padding: 10px;
          resize: none;
          font-size: 14px;
          
          &::-webkit-input-placeholder {
            color: rgb(0 53 102 / 50%);
          }
          &:-ms-input-placeholder {
            color: rgb(0 53 102 / 50%);
          }
          &::placeholder {
            color: rgb(0 53 102 / 50%);
          }
      }
      .review-submit{
        background: #fff;
        border: 1px solid #003566;
        border-radius: 117.647px;
        color: #003566;
        outline: none;
        width: 100%;
        padding: 8px;
        margin: 13px 0 0;
        font-weight: 600;
        font-size: 16px;
      }
    }
    span:hover{
      cursor:pointer;
      box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
    }
    @media(max-width:1200px){
      span{
        font-size: 14px !important;
        margin:7px 5px 0px 10px;
        min-height:34px;
        img{
          width:24px;height:24px;
          margin-right: 8px;
        }
      }
    }
  }
  .popular-prompt-view{
    min-height:150px;
    width:100%;
    margin:0 auto;
    height: calc(100% - 323px);
    display: inline-block;
    overflow-y: auto;
    border-top: 1px solid #E8E8E9;
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #CCCCCC;
      border-radius: 50px;
    }
    table{
      width: 100%;
      /*height: 515px;*/
      max-height: 100%;
      border-top: 1px solid #E8E8E9;
      border-radius: 12px;
      table-layout: fixed;
      thead {
        border-top: 1px solid #E8E8E9;
        background-color: white;
        z-index: 1;
        cursor:default;
        position: sticky;
        top: 0;
        th {
          padding: 20px;
          border-bottom: 1px solid #E8E8E9;
        }
      }
      tbody {
        overflow-y: auto;
        tr{
          cursor: pointer;        
          td {
            padding: 20px;
            border-bottom: 1px solid #E8E8E9;
          }
          td:first-child{
            min-width: 155px;
          }
        }
        tr.tr-error{
          border-top:1px solid #E8E8E9;
          cursor:default;
          td {
            border-bottom: none;
          }
        }
        tr.hoverable:hover{
          background: #EDE9F3;        
        }
      }
      .no-search-result{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: 200px;
          flex-direction: column;
          word-wrap: break-word;
          text-align: center;
          strong{
              display: contents;
          }
      }
      td div {
          padding: 0px;
          display: inline-block;
      }
    }
    table.layout-fixed{
      table-layout: fixed;
    }
  }
  @media(max-width:1200px){
    display: none;
    &.on-modal {
      display: block;
      max-width: 100%;
      width:100%;
      .popular-prompt-view {
        height:200px;
      }
    }
  }
`;

export const InputBox = styled.input`
    background: #FFFFFF;
    border: 1px solid #ECE7FD;
    border-radius: 8px;
    min-width: 880px;
    height: 50px;
    font-size: 17px;
    padding: 0 10px;
    background-repeat: no-repeat;
    text-indent: 10px;
    margin: 10px 0;
    ::placeholder{
      font-size: 14px;
    }

    :focus{
      border: 1px solid #4FA1D1;
    }
    @media (max-width: 1700px) {
      min-width: 95%;
      height: 50px;
      font-size: 12px !important;
      padding: 0 5px;
    }
    @media (max-width: 740px) {
      min-width: 95%;
      height: 35px !important;
      font-size: 12px !important;
      padding: 0 5px;
      display: flex;
      flex:1;
    }
    ${props => props.inputWidth && css`
      margin: 15px 0;
      width:100%;
      min-width: auto;
      height: 38px !important;
      font-size: 14px;
      background-image: url(${search});
      background-repeat: no-repeat;
      text-indent: 10px;
      background-position:center;
      background-position-x:right;
      background-origin:content-box;
  `}`;

  export const StopGeneratingBtn = styled.div`
    cursor: pointer;
    position: fixed;
    bottom: 83px;
    right: 50%;
    z-index: 999;
    background-color: #e0e0e0;
    padding: 10px 18px;
    border-radius: 8px;
    @media (max-width: 600px) {
        bottom: 110px;
    }
  `;

  export const Game = styled.div`
    width: 100%;
    .container-main {
      width: 100%;
      margin: 15px 0;
      background: #fff;
      padding: 30px;
      border: 0.5px solid #ECE9EE;
      box-shadow: 0px 12px 24px rgb(76 73 78 / 40%);
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 40px;
      position: relative;
      align-items: center;
      .content-wrapper {
        width: 37%;
        @media (max-width: 1400px) {
          width: 100%;
        }
        .details {
          border:5px;
        }
        table {
          border-collapse: separate;
          border-spacing: 0 8px;
          tr {
            td {
              color: #003566CC;
              line-height: 1.5;
              &:first-child {
                width: 120px;
              }
              &:last-child {
                font-weight: 600;
                color: #003566;
              }
            }
          }
        }
      }
      .deal-wrapper {
        width: 27%;
        text-align: center;
        @media (max-width: 1400px) {
          width: 100%;
        }
        .deal-btn {
          text-decoration: none;
          padding: 10px 10px;
          display: inline-block;
          border-radius: 7px;
          line-height: 1;
          color: #003566;
          text-align: center;
          border: 2px solid #003566;
          width: 100%;
          cursor: pointer;
          margin: 10px 0 0;
          transition: .3s;
          &:hover {
            color: #fff;
            background-color: #003566;
          }
        }
        .pricing {
          .new-price {
            color: #003566;
            font-size: 30px;
            font-weight: 600;
            display: block;
          }
          .old-price {
            color: #003566;
            font-weight: 500;
            font-size: 18px;
            display: inline-block;
            position: relative;
            line-height: 1;
            margin: 10px 0 0;
            &:after {
              left: 0;
              right: 0;
              top: 9px;
              content: "";
              height: 1.3px;
              width: 97%;
              background-color: #FF0000;
              display: block;
              position: absolute;
              transform: rotate(10deg);
              margin: 0 auto;
            }
          }
        }
      }
      .image-wrapper {
        width: 27%;
        @media (max-width: 1400px) {
          width: 100%;
        }
        img {
          object-fit: cover;
          height: 110px;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  `;
  export const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;
  export const Stream = styled.div`
    .inquiry-context {
      display: none;
      font-size: 16px;
      .show {
        display: block;
      }
    }
    .movie-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1%;
      row-gap: 40px;
      position: relative;
      margin-top:20px;
      .loader-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: #ffffffd1;
      }
      .loader {
          border: 5px solid #b7b7b7;
          border-radius: 50%;
          border-top: 5px solid #003566;
          width: 30px;
          height: 30px;
          -webkit-animation: ${spin} 2s linear infinite;
          animation: ${spin} 2s linear infinite;
          position: fixed;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 45%;
          transform: translate(0, -50%);
      }
      .movie-card {
        width: 32.33%;
        display: inline-block;
        cursor: pointer;
        background: #FFFFFF;
        border-width: 0px 1.19643px 1.19643px 1.19643px;
        border-style: solid;
        border-color: #CCD7E0;
        box-shadow: 0px 4.78571px 12.79px rgba(0, 0, 0, 0.15);
        border-radius: 9.57143px;
        padding: 20px;
        img {
          max-width: 100%;
          border-radius: 10px;
        }
        .thumb-title {
          color: #003566;
          text-align: center;
          margin: 20px 0 0;
        }
      }
    }
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    .text-align-center {
        text-align: center;
    }
    .details-wrapper {
      background-color: #fff;
      padding: 10px;
      height: 630px;
      overflow-y: scroll;
      border-radius: 8px;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
          background-color:rgb(210, 207, 215);
          outline: none;
          border-radius:3px;
          opacity: 0.2;
      }
      .show-details {
        .row {
          display: flex;
          flex-wrap: wrap;
          .banner {
              justify-content: center;
              padding: 0 10px;
            .provider-image-detail {
              width: 100%;
              height: auto;
              border-radius: 10px;
              img{
                width: 80%;
              }
            }
          }
          .container {
            .description-div {
              .title {
                font-size: 16px;
                font-weight: 600;
                margin: 10px 0 12px;
              }
              .ratings {
                font-size: 16px;
                font-weight: 600;
                margin: 10px 0 12px;
              }
            }
            .stream-on {
                font-size: 18px;
                font-weight: 600;
                margin: 10px 0 0;
            }
            .platforms {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .col-md-4 {
                flex: 0 0 33.33%;
                max-width: 33.33%;
                min-width: 298px;
                max-width: 298px;
                justify-content: space-between;
                &.one-column {
                  min-width: 176px;
                  max-width: 176px;
                }
                &.two-column {
                  min-width: 217px;
                  max-width: 217px;
                }
              }
              .platform-item {
                margin: 25px 0;
                padding: 25px;
                min-height: 382px;
                box-shadow: 1px 1px 8px rgb(26 26 26 / 57%);
                border-radius: 8px 8px;
                .provider-image {
                  margin: 0 auto 30px;
                  display: block;
                  max-width: 70px;
                }
                .country-list {
                  display: grid;
                  grid-template-columns: repeat(3, minmax(50px, 1fr)); /* Adjust the column width as needed */
                  grid-gap: 10px; /* Adjust the gap between items as needed */
                  justify-items: center;
                  &.two-column {
                    grid-template-columns: repeat(2, 1fr); /* Adjust the column width as needed */
                  }
                  &.one-column {
                    grid-template-columns: repeat(1, 1fr); /* Adjust the column width as needed */
                  }
                  a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-decoration: none;
                    color: #000;
                  }
                  .flag-div{
                    margin:0 auto;
                    .bg {
                      font-size: 32px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;