import styled, { css, keyframes } from "styled-components";
import collapse from '../../assets/collapse.png'
// import selectcaret from '../../assets/selectcaret.png'
import selectcaret from '../../assets/menu-down.png'
import share from '../../assets/share2.svg'
import star from '../../assets/refer/star.svg'
import TierBG from '../../assets/refer/TierBG.svg'
import tiers from '../../assets/refer/tiers.svg'
import tierLocked from '../../assets/refer/locked.svg'
import familyPlanShare from '../../assets/family_plan_social_share_icon.svg'
import locked from '../../assets/locked.svg'
import social from '../../assets/social.svg'
import caret from "../../assets/caret.png";
import helpdesk from '../../assets/helpbox.svg'
import cancelationIcons from '../../assets/cancellation-icons.svg';
import notcheck from '../../assets/notcheck.png'
import check from '../../assets/check.png'
import GreenCheckMark from '../../assets/GreenCheckMark.svg'
import alertTriangle from '../../assets/alert-triangle.png'
import devices from '../../assets/devices.svg'
import devicesLarge from '../../assets/devices-large-icons-sprite.svg'
import purekeepWeb from '../../assets/web_icon_purekeep.png'
import purekeepVideoSecionBG from '../../assets/onboarding-sprites/purekeep/video-bg-pattern.svg';
import pureprivacySocialIcons from '../../assets/onboarding-sprites/pureprivacy/BA-324-social-list-icons.svg';
import externalArrowIcon from '../../assets/external_blue.svg';
import { RightSideBar } from "./Aside";
import { ErrorBox } from "../Generic";
import copy from '../../assets/copy.svg'
import member_detail_icons from '../../assets/member_detail_icons.svg'
import family_plan_features_icons from '../../assets/family_plan_features_icons.svg'
import search from '../../assets/search.png'
// For Christams-Campaing
import FamilyPlanBanner_lg from '../../assets/fpHome_lg.png'
import FamilyPlanBanner_md from '../../assets/fpHome_md.png'
import FamilyPlanBanner_sm from '../../assets/fpHome_sm.png'
import hamBurgerIcon from '../../assets/hamBurgerIcon.svg'
import menuIcon from '../../assets/menuIcon.svg'
import DiscoverMoreFamilyPlanBanner from '../../assets/DiscoverMoreFamilyPlanBanner.png'
import wallet_cover from '../../assets/wallet_cover.png'
import { constants } from "../../tools/constants";
import pswEye from "../../assets/pswEye.png";
import FlatIcons from "../../assets/flat_icons_32.svg";
import CheckMark from "../../assets/checkmark.svg";
import CheckMarkBlack from "../../assets/tick.svg";
import productIcons from '../../assets/flat-icons.svg';
import ProductFullIcons from "../../assets/ACSD-30300-onboaridng-icons-color.svg"

export const HelloBarWrapper = styled.div`
    position: fixed;
    background: #F9F8FC;
    z-index: 6;
    width: 100%;
    display:flex;
    padding:0px;

    @media only screen and (max-width: 992px) {
      position: fixed;
      background: transparent;
    }
    @media only screen and (min-width: 1100px) {
      z-index: 3;
    }
`;

export const HelloBarContent = styled.div`
  position:relative;
  display:flex;
  width: 100%;
  border-radius: 0px;
  padding: 8px;

  .campaign{
    font-weight: 700;
    letter-spacing: -0.44px; 
  }

  @media only screen and (max-width: 1200px) {
    display: block !important;

    .twelve{
      display: none;
    }
  }

  @media only screen and (max-width: 587px) {
    font-size: 14px;
  }

  ${({bgColor}) => bgColor !== "" && css`
    background: ${bgColor};
  `}

  ${({bgImage}) => bgImage !== "" && css`
    background-image: url(${bgImage});
    background-size:cover;
    background-repeat:no-repeat;
  `}

  .hello-bar-cta{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media only screen and (max-width: 1200px) {
      margin-top: 6px;
    }
  }

  p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
    @media only screen and (max-width: 760px) {
      font-size: 12px !important;
    }
  }

  .small-text{
    color:white;
    font-size:10px;
    margin-left:10px;
  }

  .close-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    @media only screen and (max-width: 1200px) {
      top: 30%;
      right: 0px;
    }
  }
  .bf-textbox{
    display:flex;
    align-items: center;
  }
  .bf-bannertext{
    display:flex;
    justify-content:flex-end;
    align-items: center;
  }
  .yellow-text{
    font-weight: 700;
    font-size: 22px;
    line-height: 114.5%;
    text-align: center;
    color: #FFBA24;
    padding-right:15px;
    padding-left:8px;
  }
  .bf-whitetext{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-align:center;
  }
  .bf-img{
    margin-right:10px;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    & > div:not(.close-button) {
      margin-right: 20px;
    }
    .small-text{
    margin-right:30px;
    }

  }

  @media only screen and (max-width: 992px) {
    p {
      font-size: 14px;
      text-align: center;
    }

  }
  @media only screen and (max-width: 480px){

    .bf-whitetext{
      font-size:14px;
    }
    flex-direction:column;
    ${({bgImageMobile}) => bgImageMobile !== "" && css`
    background-image: url(${bgImageMobile});
    background-repeat:no-repeat;
    background-position-y: center;
  `}
  }
`

export const DashboadWrapper = styled.div`
    height:100%;
    width:100%;

    &.has-hello-bar {

      padding-top: 47px;

      @media only screen and (max-width: 1200px) {
        padding-top: 70px;
      }
      @media only screen and (max-width: 550px) {
        padding-top: 90px;
      }
      @media only screen and (max-width: 450px) {
        padding-top: 100px;
      }
      @media only screen and (max-width: 320px) {
        padding-top: 110px;
      }
        
    }
`;

export const DashboardContent = styled.div`
    margin-left:${props => props.width === 'true' ? "255px" : "0"};
    min-height: 100%;
    background: #FBFBFC;
    box-shadow: 0px 0px 36px rgba(67, 34, 91, 0.1);
    border-radius: 0px;
    z-index: 2;
    position: relative;
    padding:30px;
    overflow: hidden;
    ${props=>props?.viewURL?.includes("faqs") && css`
      overflow:scroll;
    `};
    > div{
        width:100%;
    }
    /* -- media query for all devices -- */
    /* Large Tablets */
    /* Sidebar width small*/
    @media only screen and (max-width: 1260px) and (min-width: 1201px) {
        margin-left: 240px;
    }
    /* Mobile Devices and Small Tablets */
    /* Remove Sidebar */
    @media only screen and (max-width: 1200px) {
        margin-left: 0;
        border-radius: 0px;
    }
    @media only screen and (max-width: 600px) {
        padding: 15px;
        ${props=>props.isNoPaddingView && css`
          padding: 0px;
        `};
    }
    ${props=>props.isNoPaddingView && css`
        padding: 0px;
        background: #F2F4F4;
        height: 100% !important;
        .buddyView{
        height: 100% !important;
         }
    `};
`;

export const DashboardHead = styled.div`
    margin-bottom:30px;

    h2{
      margin: 0 !important;
    }

    &.download-page {
      margin-bottom:50px;
    }

    &.business-head{
      & h2{
        color: #000C0F;
      }
      & p{
        color: #7D858F; 
      }
    }

    p{
        font-size:${({theme:{typography}}) => typography.smallBody};
        margin:10px 0 0;
    }
    span.badge{
        background: #DCD3E9;
        border-radius: 15px;
        font-size: 12px;
        letter-spacing: 0.02em;
        font-weight:400;
        font-size:12px !important;
    }
    &.beta{
        background: #FDE944 !important;
    }
    ${({hasBorder})=> hasBorder && css`
        border-bottom: 1px solid #DDD4E2;
        padding-bottom:30px;
    `}
     /* -- media query for all devices -- */

    /* Mobile Devices */  
    @media only screen and (max-width: 600px) and (min-width: 270px) {

    /* Ticket Detail Head and Close Button */
    .d-flex {
        flex-direction: column-reverse;
    }

    .tdetail-btn {
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 8px !important;
        font-size: 10px !important;
    }

    /* --- */

    /* Dashboard Head */
    margin: 5px 0px 15px 0px;

    h1,
    h2,
    h3 {
    font-size: 16px !important;
    }

    p {
    font-size: 12px !important;
    line-height: 160%;
    }
    }

    @media only screen and (max-width: 992px) and (min-width: 601px) {
    margin-bottom: 30px !important;
    }
`;

export const FormDiv = styled.div`
    max-width:500px;
    .clearfix{
        display:flex;
        align-items:center;
        position: relative;
        label{
            flex:1;
        }
        span{
          position: absolute;
          left: 92%;
          cursor: pointer;
          transform	: scale(80%);
          background: url(${pswEye}) no-repeat top left; 
          width: 24px; height: 24px;
          &.hide { background-position: 0 0; } 
          &.view { background-position: -34px 0; }  
          @media only screen and (max-width: 450px){
            bottom: 10px;
          }
        }
    }
`;
export const ImageDiv = styled.div`
    text-align: center;
    flex: 1;

    &.bg-gray {
      background: #ECE9EF;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    img {
      max-width: 100%;
    }

    p { 
      color: #43225B;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    p,button {
      font-size: 14px;
    }

    .clearfix{
        display:flex;
        align-items:center;
        label{
            flex:1;
        }
    }
    @media only screen and (max-width: 768px) {
      img {
        margin-top: 20px;
      }
    }
`;

export const CardBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding:30px;
    ${props=>props.disabledCard === "true" && css`
      pointer-events: none;
      opacity: 0.5;
    `};
    ${props=>props.noShadow && css`
      box-shadow: none !important;
      background: transparent;
    `};
    /* -- media query for all devices -- */
    /* Small Tablets */
    @media only screen and (max-width: 921px) and (min-width: 270px) {
      min-width: 100%;
      padding: 15px;
    }
    &.email-feedback{
      display: flex;
      background: #fbfbfc !important;
      justify-content: center;
    }
`;

export const AccountWrapper = styled.div`
    position:relative;
    display:flex;
    ${CardBox}{
      ${props=>props.fullWidth && css`
        width:calc(100% - 340px);
      `}
    }
    ${RightSideBar}{
      right:30px;
    }


    .change-password-wrapper {
      display: flex;
      justify-content: stretch;
      align-items: flex-start;
    }

    /* -- media query for all devices -- */
    /* Small Tablets */
    /* Sidebar hide */
    @media only screen and (max-width: 1630px) {
      ${CardBox}{
        ${props=>props.fullWidth && css`
          width:calc(100% - 340px);
        `}
        flex-direction:column-reverse;
      }
      ${RightSideBar} {
        display: none;
      }
    }
    @media only screen and (max-width: 900px) {
      .change-password-wrapper {
        flex-direction: column-reverse;
      }
    }
`;


export const InfoCard = styled.div`
  ${props => props.manualConfigurationWidth && css`width: calc(100% - 235px);`}
    padding: 16px 0;
    clear: both;
    .red-text{
      color: #FF1744 !important;
      font-size:14px !important;
    }
    .grey-text{
      color: #637381 !important;
    }
    ${props => !props.inputWidth && css` width: 100%;`}
    ${props => props.inputWidth && css`
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration{ 
      display: none;
    }
    .saerchBar{
      border:1px solid #ECE9EE;
      height:50px;
      font-size:18px;
      /* position: sticky; */
      background-image: url(${search});
      background-repeat: no-repeat;
      text-indent: 10px;
      background-position:center;
      background-position-x:right;
      background-origin:content-box;
    }

    `}

    ${props => props.subsQr && css`

    /* tooltipp container */
    .tooltipp {
      position: relative;
      display: inline-block;
      /* border-bottom: 1px dotted black; */
      /* If you want dots under the hoverable text */
    }

    //* tooltipp text */
    .tooltipp .tooltipptext {
      opacity: 0; /* Set opacity to 0 initially */
      visibility: hidden;
      width: 180px;
      background-color: #fff;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
    
      /* Position the tooltipp text */
      position: absolute;
      z-index: 1;
      top: 125%;
      left: 50%;
      margin-left: -90px;
    
      /* Fade in tooltipp */
      transition: opacity 0.3s;
      p {
        font-size: 16px !important;
        font-weight: 700 !important;
      }
      border: 1px solid #ece7fd; /* Mobile/Shadow/Light/1 */
      box-shadow: 0px 12px 24px rgba(59, 16, 142, 0.07);
    }

    /* tooltipp arrow */
    .tooltipp .tooltipptext::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #555 transparent;
    }

    /* Show the tooltipp text when you click the tooltipp container */
    .myclass .tooltipptext {
      visibility: visible;
      opacity: 1; /* Set opacity to 1 when visible */
    }
    `}
    
    .pf-detailstext{
      font-size:1.1em;
    }
    &.imgTextView{
      padding-top:0px !important;
      flex-direction:column;
      align-items:flex-start;
      img{
        height:50px;
        margin-bottom:10px;
      }
    }
    &.pf-userdetails{
      flex-direction: column !important;
      align-items: start !important;
    }
    button{
      margin: 5px;
      font-size: 14px;
    }
    textarea{
      min-height: 130px !important;
    }
    .selectUsername{
        text-transform:lowercase;
        color: #001219 !important;
        border: 1px solid #DDD4E2 !important;
    }
    .selectUsername:disabled{
      background-color:#fff
    }
    .pfSelectStrong{
      font-weight:bold;
      text-transform:lowercase;
    }
    ${props => !props.noflex && css`
    display: flex;

    .purchased strong{
      color: #7B648C !important;
      font-weight: normal !important;
      font-size:12px !important;
    }
    span.statusdot{
      height: 8px;
      width: 8px;
      background: #2CDC82;
      display: inline-block;
      border-radius: 100%;
      margin-left:5px;
      margin-right: 5px;
    }
    label{
        img{
          cursor: pointer;
        }
      }

    @media only screen and (max-width: 991px) and (min-width: 270px) {
      width: 100%;
      &.imgTextView{
        flex-direction: column !important;
        img{
          height: auto;
        }
      }
    }
    /* -- media query for all devices -- */

    /* Mobile Devices */
    @media only screen and (max-width: 460px) and (min-width: 270px) {
        /* Manage Account and Subscription Form */
        flex-direction: row !important;
        align-items: baseline !important;

        :not(:nth-last-child(2)) {
            flex-direction: column;
        }
        /* Manage Account Loader */
        :nth-last-child(2) {
            .float-end {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: auto;
            }
          }
        }
    `}
    align-items: center;
    ${props=>props.hasborder && css`
        border-bottom: 1px solid #EBE6FF;
    `};
    ${props=>props.opacity && css`
        opacity:0.5
    `};
    .float-start{
        flex:1;
        padding-right:20px;
    }
    label{
        color:${props => props.isPF === "portforwarding" ? "#212529": "#7B648C"}!important;
        font-size: ${props => props.isPF === "portforwarding" ? "16px": "14px"}!important;
        td{
          min-width:64px;
        }
        img{
            position:relative;
            top:-2px;
            margin-left: 5px;
        }
    }
    strong{ 
        color:#43225B;
        font-weight:700;
        /* margin-left:5px; */
    }
    strong.discount{
      color:#2CDC82;
    }
    strong.strike-out{ 
      text-decoration: line-through;
      font-weight: 600;
    }

    strong.danger, p.danger{
        color:${({theme:{branding:{danger}}})=>danger} !important;
    }
    span.danger{
        font-size:12px !important;
        text-transform:none;
    }
    p.grace{
        color:${({theme:{branding:{grace}}})=>grace} !important;
        font-size:14px;
    }
    .checkbox label span, p{
        color:#637381 !important;
        font-size:12px !important;
        margin-bottom:0 !important;
    }
    .float-start .checkbox label{
        padding-left:25px;
        position:relative;
        
    }
    .float-start .checkbox{
        position:relative;
        max-width:436px;
    }
    .float-start .checkbox label input {
        position: absolute;
        left: 0;
        display:none;
    }
    span.username {
        width: 110px;
        display: inline-block;
    }
    .btn-link{
        outline:0;
            box-shadow:none;
            border:0;
        :focus, :active{
            outline:0;
            box-shadow:none;
            border:0;
        }
    }
    .input-actions{
        margin-top:-35px;
        padding-right:10px;
    }
    .emailCopyField{
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
    }
    select{
        background:#FFFFFF url(${selectcaret}) no-repeat;
        background-position: 98% center;
        text-transform: capitalize;
        width:${props => props.isPF === "portforwarding" ? "205px": "none"}!important;
    }
    .country{
      display: flex;
      h3{
        font-size:16px !important;
        font-weight:700
      }

    }
    option{
      text-transform: capitalize;
    }
    .recurly-wrapper{
        width:100%;
    }
    .payment-footer{
        margin-top:30px;
    }
    .recurly-element, .recurly-hosted-field {
      border-radius: 8px;
      height: 48px;
      border: 1px solid #EBE6FF;
    }
    ${(props) => props.validation && css`
        input, textarea, select{
            border:1px solid ${({theme:{branding:{danger}}})=>danger} !important;
        }
    `}
    .deviceSelect{
        max-width: 320px;
        width: 190px;
        height: auto;
        padding: 13px 15px;
        background-position: 95% center;
    }
    .devicesSelect{
        text-transform:none;
        option{
          text-transform:none;
        }
    }
    .usernameOpt{
        text-transform: none;
    }
    p a{
        font-size:12px;
        color: #7320FF;
    }
    /* -- media query for all devices -- */

    /* Tablet Devices */
     
     @media only screen and (max-width: 1100px) and (min-width: 921px) {

    /* Subscription Purchased and Addon */

    .subscription-body .float-start {
        label {
            img:nth-child(1) {
            margin-left: -5px !important;
        }
    }

        img:nth-child(2) {
            margin-left: 10px;
        }
    }
    }

    /* Mobile Devices */

    @media only screen and (max-width: 550px) and (min-width: 270px) {
    .float-start {

    p {
        font-size: 12px !important;
        line-height: 180%;
        letter-spacing: 0.04em !important;
    }

    input[type="email"] {
        font-size: 12px !important;
    }

    input[placeholder] {
        font-size: 12px !important;
    }

    label {
        line-height: 200%;

        img:nth-child(1) {
        display: flex;
        flex-direction: column;
        top: ${props => props.isPF === "portforwarding" ? "5px": "-2px"}!important;
        }

        img:nth-child(2) {
        margin-left: 10px;
        }
    }

    img,
    svg {
        vertical-align: -webkit-baseline-middle !important;
    }
    }
    .paymentMethod{
      display:block !important;
    }
    .float-end ul {
    margin-top: 5px !important;
    }

    .float-end {
    float: left !important;
    margin-top:10px;
    }

    /* Subscription Purchased and Addon */

    .float-end label {
    strong img {
        margin-left: -5px !important;
    }
    }

    input[placeholder] {
    font-size: 12px !important;
    }

    textarea[placeholder] {
    font-size: 12px !important;
    }
    }

    @media only screen and (max-width: 500px) and (min-width: 270px) {

    /* Subscription Purchased and Addon */

    .float-end label {
    strong img {
        margin-left: -5px !important;
    }
    }

    /* Change Password - Password fields */
    .clearfix {
    display: block;
    }
    }

    .payment-footer {
    margin-top: 24px;
     button {
      margin: 0;
     }
    }

    @media only screen and (max-width: 526px) and (min-width: 270px) {
    .float-end .text-end {
    text-align: left !important;
    }

    label {
      font-size:${props => props.isPF === "portforwarding" ? "15px": "12px"}!important;
    }

    span,
    li {
    font-size: 12px !important;
    }
    }

    @media only screen and (max-width: 992px) and (min-width: 551px) {
    p {
    line-height: 190% !important;
    }
    }
    &.small-width{
      max-width:270px;
      width: 100%;
      &.new-width{
        max-width: 324px;
      }
    }
    &.medium-width{
      max-width:850px;
      width: 100%;
    } 

    .on-boarding {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;

      @media only screen and (min-width: 992px){
        &.on-boarding-purevpn,
        &.on-boarding-purekeep,
        &.on-boarding-purescan,
        &.on-boarding-purecrypt,
        &.on-boarding-pureprivacy{
          align-items: flex-start;
        }
      }
    }
    
    ${props => props.isExpired && css`
      position:relative;
      &::before{
        position:absolute;
        background:#ffffff;
        opacity:0.8;
        content:'';
        height:100%;
        width:100%;
        top:0;
        left:0;
        z-index:999;
      }
    `}

    ${props => props.disabled && css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Input = styled.input`
    background: #FFFFFF;
    box-sizing: border-box;
    font-size:14px;
    width:${props=>props.fullwidth ? '100%' : '320px'};
    padding:10px 11px;
    color:#43225B;
    letter-spacing: 0.15px;
    :focus, :active{
        border: 0.5px solid #43225B;
        outline:none;
        box-shadow:none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
        font-weight:400;
    }
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 431px) and (min-width: 270px) {
        width: 100%;
    }
    @media only screen and (max-width: 431px) and (min-width: 270px) {
        font-size: 12px;
        ::placeholder,
        ::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 12px;
        }
    }
`;

export const ButtonLink = styled.button`
    border:0;
    background:none;
    font-size: 13px;
    font-weight: 400;
    color: #0D85E8;
    -webkit-text-decoration: none;
    text-decoration: none;
    position: relative;
    :focus,:active{
        outline:none;
        box-shadow:none;
    }
    ${({externalRedirectionIcon}) => externalRedirectionIcon == "true" && css`
      &::after{
        content: " ";
        position: absolute;
        background-image: url(${externalArrowIcon});
        width: 14px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: 0px 1px;
        margin: 0 0 0 5px;
      }
    `}
`;

export const PasswordVerifier = styled.li`
    font-size: 12px !important;
    color: #7B648C;
    letter-spacing: 0.03em;
    margin-bottom:15px;
    padding-left:25px;
    background:url(${({verified, theme:{passwordChecker}}) => verified === false ? passwordChecker.verify : passwordChecker.notVerify}) no-repeat;
    background-position-y:center;
     /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 500px) and (min-width: 270px) {
        font-size: 10px;
        padding-left: 28px;
    }
`;

export const CredentialsInput = styled.input`
    border:none;
    background:none;
    font-size:14px;
    font-weight:700;
    color:#43225B;
    :focus, :active{
        border:0;
        outline:0;
        box-shadow:none;
    }
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (min-width: 599px) {
        max-width: 140px;
    }
`;

export const Dashboard = styled.div`
.d-header{
    text-align: center;
    .heading{
        margin-top: 25px;
        @media only screen and (max-width: 599px) {
          font-size: 26px;
        }
        span{
          font-weight: 650;
        }
    }
}
.dashboardView{
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    .addonBox{
    background: #fff;
    padding:30px;
    display: flex; 
    max-width: 500px;
    min-width: 500px;
    flex-direction: column;
    margin: 15px;
    border-radius: 40px; 
    border:2px solid  rgba(37, 36, 34, 0.4);
    min-height: 300px;
    max-height: 300px;
    justify-content: space-between;
    cursor: pointer;
    .addon-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h6{
          color:#000;
          font-size: 22px;
          margin-left: 33px;
          margin-bottom: 0px;
        }
        .purchaseTag{
          padding: 3px 7px;
          border-radius: 48px;
          background: #08A253;
          color:#fff !important;
          font-weight: 600;
          letter-spacing: 0.7px;
          text-transform: uppercase;
          font-size: 12px !important;
        }
        .notPurchased{
          background: rgba(37, 36, 34, 0.4);
        }
        .expired, .past_due, .FreeRenew, .paused {
          background: red;
        }
      }
      .addon-data{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 130px;
        h1{
          font-size: 19px;
          color:#000C0F;
        }
        p{
          margin-top: 20px;
          font-size: 15px;
        }
      }
      a{
          padding: 10px 24px;
          background: #6536CC;
          border-radius: 26px;
          width: max-content;
          font-size: 16px;
          color:#fff;
      }
      a.notPurchased,.FreeRenew{
          border: 1px solid #DDD4E2;
          color: #000;
          background: #fff;
      }
      a.notPurchased:hover,a.FreeRenew:hover{
        background: #6536CC;
        color:#fff;
      }
      }
      .addonBox:hover{
          border:2px solid #6536CC;
          box-shadow: 0px 4px 16px rgba(0, 180, 216, 0.14);
      }

      @media only screen and (max-width: 599px) {
      .addonBox{
        min-width: 0;
        max-height: 400px;
        .addon-header{
          @media only screen and (max-width: 430px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 12px;
          .purchaseTag{
              margin-top: 30px;
          }
          }
          .purchaseTag{
            padding: 5px 10px;
            font-size: 11px !important;
          } 
        }
        .addon-data{
          h1{
            margin-top: 10px;
            font-size: 22px;
            @media only screen and (max-width: 434px) {
              margin-top: 0;
            }
          }
          p{
              font-size: 14px;
              margin: 5px 0 ;
          }
        }
      }
  }
}
@media only screen and (max-width: 1700px) and (min-width: 1200px)  {
.d-header{
      span{
        h1{
          font-size: 24px;
        }
      }
      text-align: center;
      .heading{
          margin-top: 8px;
          font-size: 22px;
      }
  }
.dashboardView{
  max-width: 1100px;
  .addonBox{
    min-width: 410px;
    max-width: 410px;
    min-height: 230px;
    padding:25px;
    margin: 10px;
    .addon-header{
      margin-bottom: 15px;
      h6{
        font-size:19px;
        margin-left: 20px;
      }
      .purchaseTag{
        font-size: 10px !important;
      }
    }
    .addon-data{
      min-height: 100px;
      h1{
          font-size: 15px;
          /* for next phase */
          min-height: fit-content;
      }
      p{
          font-size: 12.5px;
          margin-top: 15px;
          margin-right: 10px;
      }
    }
    a{
      padding: 8px 12px;
      width: max-content;
      font-size: 13px;
    }
  }
}
}
`;
export const InfoModalMobileWrapper = styled.div`
.d-header{
    text-align: center;
    .heading{
        margin-top: 25px;
        color:#001219;
        @media only screen and (max-width: 599px) {
          font-size: 17px;
        }
        span{
          font-weight: 650;
        }
    }
}
.disabled-cta{
  background: linear-gradient(
    0deg,
    rgba(101, 54, 204, 0.20) 0%,
    rgba(101, 54, 204, 0.20) 100%
  ), #FFF;
  cursor:not-allowed;
}
.addonCTA{
   position: sticky;
    bottom: 0;
    left: 0; 
    right: 0; 
    background-color: white;
    padding: 30px 10px 10px 10px;
    z-index: 999;
    background: linear-gradient(0deg,rgb(255, 255, 255) 65%,rgba(255,255,255,0) 100%)
}
.dashboardView{
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    .addonBox{
    background: #fff;
    padding:30px;
    display: flex; 
    max-width: 500px;
    min-width: 500px;
    flex-direction: column;
    margin: 15px;
    border-radius: 16px; 
    border: 1px solid #ECE7FD;
    justify-content: space-between;
    cursor: pointer;
    
    .addon-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h6{
          color:#000;
          font-size: 18px;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          max-width: 200px;
          img{
            width:20px;
            margin-left:5px;
          }
          @media only screen and (max-width: 430px){
            width:150px;
            font-size:17px;
          }
        }
        .purchaseTag{
          border-radius: 4px;
          background: #FFB10A;
          color:#001219 !important;
          padding: 2px 8px; 
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 9px !important;
          font-weight: 600;
        }
        .priceTag{
          color:#001219;
          font-size: 18px; 
          margin-bottom: 0;
          text-align: right;
          @media only screen and (max-width: 430px){
            font-size:14px !important;
            font-weight: 600;
          }
        }
      }
      .addon-data{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h1{
          font-size: 19px;
          color:#000C0F;
        }
        p{
          margin-top: 20px;
          font-size: 15px;
        }
        ul{
          list-style: disc;
          padding-left:0;

          span, li{
            @media only screen and (max-width: 430px){
              font-size:13px !important;
            }
          }
        }
        ul li {
          font-size:13px !important;
          display: flex;
          align-items: start;
          justify-content: space-between;

          .desc{
            max-width: 260px;
          }
          
          margin:5px 0px;
          .purpleText{
            color:#5831AC !important;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.25px; 
            max-width: 220px;
            text-align: right;
          }
          li span{
            font-size:11px
            display: block;
          }
          span img{
            padding-right:3px;
          }
        }
      }
      }
      .selected{
        box-shadow: 0px 12px 24px 0px #6536CC3D;
        border: 1px solid #6536CC;
      }

      @media only screen and (max-width: 599px) {
      .addonBox{
        min-width: 400px;
        max-width: 400px;
        
        @media only screen and (max-width: 430px) {
          margin:5px 7px;
          padding: 15px 15px;
        }
        .addon-header{
          @media only screen and (max-width: 430px) {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 0px;
          pa
          .purchaseTag{
              margin-top: 30px;
            }
          }
          .purchaseTag{
            padding: 3px 7px;
            font-size: 9px !important;
          } 
        }
        .addon-data{
          h1{
            margin-top: 10px;
            font-size: 22px;
            @media only screen and (max-width: 434px) {
              margin-top: 0;
            }
          }
          p{
              font-size: 11px;
              margin: 5px 0 ;
          }
        }
      }
    }
    @media only screen and (max-width: 440px) {
      .addonBox{
        min-width: 320px;
        max-width: 320px;
      }
    }
    @media only screen and (max-width: 360px) {
      .addonBox{
        min-width: 280px;
        max-width: 280px;
      }
    }
}

}
`;


export const SubscriptionBox = styled.div`

    background: #FFFFFF;
    border: 0.5px solid #ECE9EE;
    box-sizing: border-box;
    border-radius: 10px;
    padding:20px 30px;
    margin-bottom:15px;
    ${({open}) => open && css`
      box-shadow: 0px 12px 24px rgba(67, 34, 91, 0.15);
    `};
    ${({isPromptLoader}) => isPromptLoader && css`
      width: 100%;
      border:none;
      box-shadow: none;
    `};
    ${({isPadding}) => isPadding && css`
      width: 50% !important;
      min-width: 340px;
      margin-top: 40px !important;
    `};

    &.family_plan_box{
      border: 1px solid #DDD4E2;
      box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
      border-radius: 12px;
      max-width: 362px;
      width: auto;
      padding: 30px 46px;
      min-height: 290px;
      margin-bottom: 0px;

      @media only screen and (max-width: 1440px){
        max-width: 312px;
        padding: 30px 16px;
        min-height: 290px;
      }

      @media only screen and (max-width: 1290px){
        max-width: 280px;
        padding: 20px 16px;
        min-height: 260px;
      }

      @media only screen and (max-width: 970px){
        max-width: 230px;
      }

      @media only screen and (max-width: 820px){
        margin-bottom: 10px;
      }
    }

    &.fp-invites{
      width: 287px;
      height: 245px;
      background: #FFFFFF;
      border: 1px solid #DDD4E2;
      box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
      border-radius: 12px;
      position: relative;

      div.delete-fp{
        position: absolute;
        content: '';
        right: 0;
        top: 0px;
        cursor: pointer;
        img{
          position: absolute;
          top: 15px;
          right: 15px;
          content: '';
        }
      }
    }

    &.fp-works{
      background: #FCFCFC;
      box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
      border-radius: 24px;
      padding: 0;
      max-width: 1146px;
      margin: auto;

      @media only screen and (max-width: 820px){
        display: block !important;
        text-align: center;
        padding: 20px 40px;
      }

      @media only screen and (max-width: 320px){
        padding: 20px 10px;
      }
    }

    &.compare-box{
      background: #FFFFFF;
      outline: 3px solid rgba(130, 96, 239, 0.25);
      border-radius: 24px;
      padding: 45px 26px 30px;
      width: 362px;
      height: 482px;

      @media only screen and (max-width: 1439px){
        width: 320px;
        padding: 25px 20px 30px;
        height: 440px;
      }

      @media only screen and (max-width: 1360px){
        width: 300px;
        padding: 25px 20px 30px;
        height: 440px;
      }

      @media only screen and (max-width: 340px){
        width: 260px;
      }

      @media only screen and (max-width: 280px){
        width: 240px;
      }
      
    }

    &.fp-faq-box{
      background: #FFFFFF;
      border: 1px solid #DDD4E2;
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 30px;
    }

    &.fp-imp{
      background: #FFFFFF;
      border: 0.5px solid #DDD4E2;
      box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
      border-radius: 15px;
      width: 644px;

      @media only screen and (max-width: 960px) {
          width: 500px;
      }

      @media only screen and (max-width: 850px) {
        width: 400px;
        padding: 16px 20px !important;
      }

      @media only screen and (max-width: 640px) {
        width: 100%;
        padding: 12px 16px !important;
      }

      .subscription-body{
        margin-top: 12px !important;
        border-top: 0.5px solid #e0dae4 !important;
      }

      .subscription-head{
        cursor: pointer;

        .content{
           display: flex;
           align-items: center;
           justify-content: space-between;
          
        }
      }
      .accordianBtn{
        position:relative;
        padding:9px 10px;
        ::after{
            transition:all 0.3s linear;
            background:url(${collapse}) no-repeat center center;
            height:24px; 
            width:24px;
            content:'';
            position:absolute;
            top:-2px;
            right:0;
            ${props => props.open && css`
                transform: rotate(180deg);
            `}
        }
        ::before{
            height:24px; 
            width:24px;
            content:'';
            position:absolute;
            top:-2px;
            right:0;
            background-color:#EEE9F4;
            border-radius: 5px;
        }
      }
    }

    .vpnAccountsHead {
      margin-top: 20px;
      p{
        font-size:12px;
        font-weight:400;
      }
    }
    .subscription-body,
    .subscription-head{
          h4{
        font-weight:650;
        font-size:16px;
        position:relative;
        text-transform:capitalize;
        margin:0 0 4px 0;
    }
    hr{
      /* color: rgba(221,212,226,1) */
      opacity:0.15;
      margin-top:7px;
      margin-bottom:7px;
      :first-child{
        margin-top:10px;
      /* margin-bottom:10px; */
      }
    }
    }
    ${({hover}) => hover && css`
    :hover{
        background-color: rgba(242, 241, 245, 0.5);
    }
      `};

      ${({open}) => open && css`
      :hover{
        background-color: #fff;
        }
        `};



    .subscription-head h3{
        font-weight:400;
        font-size:16px;
        position:relative;
        cursor:pointer;
        margin:0;
        span.statusdot {
            height: 8px;
            width: 8px;
            background: #FF1744;
            display: inline-block;
            border-radius: 100%;
            margin-right: 5px;
        }
        span.statusdot.active{
            background:#2CDC82;
        }
        span.statusdot.pastdue{
            background:#C46715;
        }
        span.statusdot.canceled{
            background:#ED7D3E
        }
        span.statusdot.paused{
            background:#f1da53
        }
        ${({open}) => open && css`
            margin:0 0 30px;
            @media only screen and (max-width: 460px) and (min-width: 270px) {
                margin: 0 0 5px;
            }
        `};
        ::after{
            transition:all 0.3s linear;
            background:url(${collapse}) no-repeat center center;
            height:24px; 
            width:24px;
            content:'';
            position:absolute;
            top:-2px;
            right:0;
            ${props => props.open && css`
                transform: rotate(180deg);
            `}
        }
        ::before{
            height:24px; 
            width:24px;
            content:'';
            position:absolute;
            top:-2px;
            right:0;
            background-color:#EEE9F4;
            border-radius: 5px;
        }
    }
    
    .wireGuardConfiguration{
      width:100%;
      height:10px;
      border:2px solid black;
    }

    .horizontalRow{
      margin:2px 0 2px 0;
      border: 2px solid #DDD4E2;
      width: 100%;
    }

    .country{ 
      display: flex;
      align-items: center ;
    .flagPointer{
      cursor:pointer;
    }
      .content{
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        flex-grow: 1;

        /* new */
        justify-content:space-between;
        align-items:flex-start;
        .clickCountry{
        flex-basis:100%;
        h4{
          cursor:pointer;
        }
        .feature{
          font-size:14px;
          padding: 2px 8px;
          width: 34px;
          /* height: 1px; */
          background:#EEE9F4;
          border-radius: 100px;
          cursor:pointer;
          @media only screen and (max-width: 400px) and (min-width: 290px) {
            font-size:10px !important;  
          }
        }
      }
      h3{
        font-weight:650;
        font-size:16px;
        position:relative;
        text-transform:capitalize;
        margin:0 0 0 0;
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-basis:100%;
        ::after, ::before{
          display:none
        /* margin-top: 10px; */
      } 
      .downloadBtn{
        display: flex;
        margin-right:35px;
      }
      }
      }
      .flag {
      margin-right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      justify-content:center;
      }
      .countryData, .cityData,.citiesData{
        display: flex;
        flex-grow: 12;
        /* justify-content: space-between; */
        align-items:center;
        .cityButton{
          margin-right:37px;
        }
        h4{
          color:#43225B;
        }
        .protocolBlock{
          display:flex;
          p:nth-child(2){
            padding-left:10px
          }
        }
        .downloadBtn{
          margin-right:35px;
        }
        .countryButton{
          /* h3::after, ::before{
            display:block;
          } */
          position:relative;
          padding:9px 10px;
          ::after{
          transition:all 0.3s linear;
          background:url(${collapse}) no-repeat center center;
          height:24px; 
          width:24px;
          content:'';
          position:absolute;
          top:-2px;
          right:0;
          ${props => props.open && css`
              transform: rotate(180deg);
          `}
          }
          ::before{
          height:24px; 
          width:24px;
          content:'';
          position:absolute;
          top:-2px;
          right:0;
          background-color:#EEE9F4;
          border-radius: 5px;
          }
          }
          p{
            .seperator{
              margin-left:5px;
              margin-right:5px;
            }
          }
          @media only screen and (max-width: 772px) and (min-width: 290px) {
            .protocolBlock{
            flex-direction:column;
            p:nth-child(2){
              padding-left:0px
            }
          }
          }
          @media only screen and (max-width: 652px) and (min-width: 270px) {
            margin-bottom: 3px;
            .countryCity{
              border:2px solid black
            }
            .downloadBtn{
              display: flex;
              flex-direction:column;
              button{
                margin:3px;
                font-size: 10px;
              }
            }
            p{
            .seperator{
              display:none
            }
            }
          }
          }
          .citiesData{
          .content{
          .clickCountry{
            h3{
              flex-basis:auto;
              /* width:20% */
              min-width:103px;
              }
            }
          }
          }
          p{
            margin-bottom: 0px;
            font-size: 14px;

          }
          }
          button{
            margin: 5px;
            font-size: 14px;
          }
    
        .city{
          margin-top: 10px;
          .country{
            margin-top: 10px;
          }
          .content{
            justify-content: center;
          }

        }
        @media only screen and (max-width: 652px) and (min-width: 270px) {
        margin-bottom: 3px;
        .countryCity{
          align-items:flex-start;
          .citiesData{
            .content{
              .clickCountry{
                  flex-direction:column;
                h3{
                  margin-left:0px !important;
                }
                p{
                  margin-top:0px !important;
                  margin-bottom:0px !important;
                }
              }
            }
          }
        }
        }
    .subscription-body{
        display:none;
    }
    .subscription-body.show{
        display:block;
    }
    .subscription-body > div:last-child{
        // border-bottom:0;
    }
    label strong{
        text-transform:capitalize;
    }
    ${props=>props.single && css`
        .subscription-head h3::after, .subscription-head h3::before{
            display:none;
        }
    `}
     /* -- media query for all devices -- */

    /* Large Tablets */
    @media only screen and (max-width: 375px) and (min-width: 270px) {
        /* Subscription Form Fields and Labels */

        .subscription-body .float-end {
        flex-direction: column !important;
        margin-top: 10px;
        }

        .subscription-body strong {
        font-size: 12px;
        }

        .subscription-body .float-start label {
        font-size: 12px;
        }

        .subscription-body span {
        font-size: 12px;
        }

        .vpnAccountsHead {
          p {
              font-size: 12px;
          }
        }

        .subscription-body .float-start input[type="text"] {
        font-size: 12px;
        }
    }

    @media only screen and (max-width: 415px) and (min-width: 270px) {
        /* Subscription Form Fields and Labels */

        padding: 20px 15px;

        .subscription-body span {
        font-size: 12px !important;
        margin-top: 10px;
        }

        .subscription-body .username {
        font-size: 12px !important;
        display: block;
        margin-top: 10px;
        }
    }

    /* Small Tablets */
    @media only screen and (max-width: 387px) and (min-width: 326px) {
        padding: 10px 15px;
    }

    @media only screen and (max-width: 451px) and (min-width: 270px) {
        padding: 15px;

        .subscription-head h3 {
        font-size: 12px;

        /* Subscription Head dropdown arrow */

        ::after {
            top: -5px;
            width: 20px;
            height: 20px;
        }
        ::before {
            top: -5px;
            width: 20px;
            height: 20px;
        }
        }
    }
`;

export const FilterBtn = styled.button`
    border:none;
    /* border:${props => props.btnText==="All" && "2px solid black"}; */
    background: #EEE9F4;
    border-radius: 100px;
    padding: 5px 10px;
    /* ::after{
      content:${props => props.btnText==="All" && "All"};
    } */
  ${props=>props.btnText ==="All" && css`
        ::after{
        content:"All"
      }
  `}
  ${props=>props.btnText ==="QR" && css`
        ::after{
        content:"Quantum Resistant (QR)"
      }
  `}
  ${props=>props.btnText ==="P2P" && css`
        ::after{
        content:"Peer to Peer (P2P)"
      }
  `}
  ${props=>props.btnText ==="OBF" && css`
        ::after{
        content:"Obfuscated (OBF)"
      }
  `}
  ${props=>props.btnText ==="PF" && css`
        ::after{
        content:"Port Forwarding (PF)"
      }
  `}
  @media only screen and (max-width: 1490px) and (min-width: 990px),(max-width: 860px) and (min-width: 270px) {
    ${props=>props.btnText ==="All" && css`
        ::after{
        content:"All"
      }
  `}
  ${props=>props.btnText ==="QR" && css`
        ::after{
        content:"QR"
      }
  `}
  ${props=>props.btnText ==="P2P" && css`
        ::after{
        content:"P2P"
      }
  `}
  ${props=>props.btnText ==="OBF" && css`
        ::after{
        content:"OBF"
      }
  `}
  ${props=>props.btnText ==="PF" && css`
        ::after{
        content:"PF"
      }
  `}
  }

  
`
export const DownloadComponent = styled.div`
display: flex;
justify-content: space-between;
align-items: baseline;
.filterButton{
  label{
    color:#7B648C;
    font-size: 16px;
  }

  button:hover{
    background: #43225B;
    color:#fff;
  }
  button.active{
    background: #43225B;
    color:#fff;
  }
  @media only screen and (max-width: 1576px) and (min-width: 1490px) , (max-width: 925px) and (min-width: 860px) {
/* margin: auto !important; */
    font-size: 14px;
    } 
}
@media only screen and (max-width: 994px) and (min-width: 270px) {
/* margin: auto !important; */
width: 100%;
}
/* @media only screen and (max-width: 753px) and (min-width: 270px) { */
/* margin: auto !important; */

.selectSort{
  align-items: center;
    input{
      display:none
    }
    .sortArrow{
      background-color: #EEE9F4;
      border-radius: 5px;
    }
    .form-control{
      appearance: none;
      height:20px;
      width:5px;
      border: none;
      background-color: #EEE9F4   ;
      padding: 8px;
      background:url(${collapse}) no-repeat center center;
    }

    select input{
      font-size: 15px;
    }
    .form-control-sm{
      /* padding-top:10px */
    }
    label{
      color:#7B648C;
      font-size: 16px;
    }
    strong{
      color: #43225B;
    }
  }
@media only screen and (max-width: 564px) and (min-width: 270px) {
/* margin: auto !important; */
flex-direction: column;
.selectSort{
  margin-bottom: 10px;
}
font-size: 14px;
}
/* } */
`
export const SubscriptionAddonGroup = styled.div`
  position:relative;
  display:flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #DDD4E2 !important  ;
  border-radius: 10px;
  padding: 15px;
  ${props=>props.hasMarginTop && css`
    margin-top: 40px;
  `}
  ${props=>props.hasMarginBottom && css`
    margin-bottom: 15px;
  `}

  .group-title {
    margin-bottom: 5px;
    p {
      font-size: 14px;
      margin-bottom: 10px;

      b { 
        color: #43225B;
      }
    }
  }

  .title {
    display:flex;
    margin-bottom: 7px;
    
    span {
      font-size: 18px !important;
      color: #43225B !important;
      font-weight: 600;
    }

    .badges {
      //display:flex;
      margin-left: 5px;

      .badge-new {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 9px !important;
        line-height: 125%;
        color: #43225B !important;
        vertical-align: middle;
        background-color: #2CDC82;
        padding: 2px 3px;
        max-width: 29px;
        width: 100%;
        display: inline-block;
        text-align: center;
        border-radius: 4px;
        //margin: 0 0 0 5px;
      }
    }
  }

  .addon-list {
    display:flex;
    width: 100%;
    flex-direction: column;

    & > div {
      padding: 10px 0 !important;
      border-bottom:1px solid #ECE9EE

    }
    & > div.p-0 {
      padding: 0 !important;
    }
    & > div:last-child{
      padding-bottom: 0px;
      border-bottom: none;
      // align-items: flex-start;
    }

    .float-start {
      // width:100%;
      // padding-left: 0px;

      .locked-image {
        display: inline-block;
      }
      .status-div{
        display:inline-block;
        p{
          margin-left:10px;
          font-size:14px !important;
        }
      }
      .purchased-image {
        display: inline-block;
      }
      .dedicated-ip-flag{
          display:contents;
      }
      
      .dedicated-ip-details {
          margin-left: 0px;
          margin-top: 0px;
          align-items: center;

        img {
          margin-left: 0px;
        }
      }
      .dedicated-ip-address-seperator{
          border: 1px solid #DDD4E2;
          transform: rotate(90deg);
          margin:0 10px 0 10px;
          width: 18px;
          height: 0;
      }
      .dedicated-ip-host{
        display:contents
      }
      .host-address{
          text-transform:lowercase;
      }
      .dedicated-ip-details{
          margin-top:10px;
          position: relative;
          left:55px;
          top:0;
      }
      .purchased-block{
          display: block;
      }
    }
    @media only screen and (max-width: 1540px) and (min-width: 1250px) {
      .float-start .dedicated-ip-details {
        left: 25px;
      }
    }
    @media only screen and (max-width: 1249px){
      .float-start {
        .dedicated-ip-details{
          left:25px;
        }
      }
    }
    @media only screen and (max-width: 1020px){
      .float-start {
        .dedicated-ip-details{
          left:25px;
        }
      }
    }
    @media only screen and (max-width: 805px){
      .purchased-block {
        .dedicated-ip-address-seperator{
            display:none;
        }
        .dedicated-ip-host{
        display:block
        }
      }
      .float-start {
        .dedicated-ip-details{
            margin-top:10px;
            position: static;
            align-items: flex-start;
        }
        .dedicated-ip-address-seperator:nth-of-type(2){
            display: block;
            position: relative;
            top:0;
        }
      }

       .dedicated-ip-details {
            width:100%;
            flex-direction: column;
            align-self: flex-start;
            justify-content: flex-start;
            align-items: flex-start;

          &>div:nth-of-type(1){
              display: flex;
              flex-direction: row;
              align-items:center;
          }
       }
    }

    @media only screen and (max-width: 550px){

      .addon-list-item {
        flex-direction: column;
        align-items: flex-start;
      }   

      .float-start {
        padding-right: 0px;
        position: relative;
        width: 100%;

        label {
          width: 100%;
          flex-flow: wrap;
        } 
        .locked-image {
          margin-left: 0px;
          width: 25px;
          height: 25px;
          background: url(${locked});
          background-position: 3px 2px;
          background-repeat: no-repeat;

          img {
            display: none;
          }
        }

        .purchased-image {
          display: flex;
          margin-left: 0px;
          img {
            margin-left: 0px;
            margin-right: 5px;
          }
        }
      }
      .float-end {
        display: flex;
        margin-left: 0px;
      }

      label {
        display: flex !important;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0px;
        gap: 5px;

        span {
          margin-top: 0px;
        }
      }
    }

  }

  @media only screen and (max-width: 526px) and (min-width: 270px){

    .badge-new {
      margin-top: 0px;
    }
  }
  
`
export const EmptySearch = styled.div`
display: flex;
flex-direction: column;
align-items: center;
img{
  width:50%
}
`
export const SubscriptionWrapper = styled.div`
    position:relative;
    display:flex;
    ${RightSideBar}{
        right:30px;
    }

    .on-manual-config{
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .sub-parent{
        width:calc(100% - 235px);
        .getMoreSubsCTA{
          display:flex;
          justify-content: end;
        }
        /* Small and Large Tablets */
        @media only screen and (max-width: 1023px) and (min-width: 270px) {
            min-width: 100%;
            /* padding: 5px; */
        }
    }
    @media only screen and (max-width: 1023px) {
        ${RightSideBar} {
            display: none;
        }
    }
`;

export const SubscriptionCancelWrapper = styled.div`
    position:relative;
    ${RightSideBar}{
        right:30px;
    }
    .heading {
        font-size: 36px;
        font-weight: 400;
        line-height: 40px;
        display: inline-block;
        width: 100%;
    }
    .sub-parent{
        width:calc(100% - 235px);
        /* Small and Large Tablets */
        @media only screen and (max-width: 1023px) and (min-width: 270px) {
            min-width: 100%;
            /* padding: 5px; */
        }
    }
    .cancel-btn{
        margin: 0 0 0 30px;
    }
    .warning-icon{
        background-image:url(${alertTriangle});
        width:14px;
        height: 14px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
    }
    .warning-text{
        display: inline-block; 
        max-width: calc(100% - 30px);
        width: 100%;
        vertical-align: top;
        color:${props => props.coloredVal && "red" }!important;
    }
    .question-hdg{
        font-size:18px!important;
        margin:0 0 10px!important;
    }
    width:${props => props.fullWidth && "100%" }!important; ;
    p.error-text{
        color: red !important;
        font-size: 16px !important;
    }
    .other-reason-wrapper p.error-text{
        margin-top: 10px;
    }
    @media only screen and (max-width: 1023px) {
        ${RightSideBar} {
            display: none;
        }
    }
`;

export const SubscriptionCancelReasons = styled.div`
    position:relative;
    display:flex;
    ${RightSideBar}{
        right:30px;
    }
    .col-md-4{
        display:flex;
        ${spritesPosition(6)};
        .icon{
            height:60px;
            width:60px;
            margin:0 auto 20px;
            background:url(${cancelationIcons}) no-repeat;
        }
    }
`;

export const CancelReason = styled.div`
    background: #FFFFFF;
    border: 0.5px solid #DDD4E2;
    box-sizing: border-box;
    border-radius: 12px;
    max-width: 100%;
    width: 100%;
    flex: 1;
    padding: 30px 15px 15px;
    margin: 0 0 30px;
    &.active {
        border: 0.5px solid #43225B;
        box-shadow: 0px 10px 30px 0px #43225B1A;
    }
    
`;

export const CancelReasonOption = styled.div`
    position:relative;
    display:flex;
    p {
        font-size: 14px !important;
        border: 1px solid #DDD4E2;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px 10px;
        width: 100%;
        position: relative;
        transition: all 0.2s ease-in-out;
    }

    &.active {
        cursor: pointer;
        background-color: #ECE9EF;
        p {
            color: #43225B;
            font-weight: 700;
            border: 1px solid #43225B;
        }

        .icon{
            background-image:url(${check});
        }
    }



    .errorMessage{
        
    }

    :hover {
        cursor: pointer;
    }

    .icon {
        position: absolute;
        top: 11px;
        right: 11px;
        width: 16px;
        height: 16px;
        background-image:url(${notcheck});
        transition: all 0.2s ease-in-out;
    }
`;


export const ErrorMessage = styled.span`
    display: flex;
    width: 100%;
    p {
        color: red;
        font-size: 14px;
        margin-bottom: 0px;
    }
`;

export const CardNumber = styled.span`
    font-weight:700;
    color:#43225B !important;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1000px){
      justify-content: right;
    }
    /* Small Tablets */
    @media only screen and (max-width: 502px) and (min-width: 402px) {
        font-size: 15px !important;
    }

`;
export const FreemiumModal = styled.div`
  .hello-modal{
    border:10px solid red !important;
  }
  .modal-content{
    border:10px solid red ;
  }
`
export const PopupBody = styled.div`

  .feature_name{
    padding-left: 2px;
    display: block;
    font-size: 12px !important;
    margin-top: -5px;
  }

  .fp-head{

    h2{
      font-size: 24px !important;
      line-height: 33px;
      margin-bottom: 20px !important;
    }

    p{
      font-weight: 600;
    }

    h2{
      @media only screen and (max-width: 760px){
        font-size: 16px !important;
        margin-bottom: 8px !important;
        line-height: 18px;
      }
    }

    p{
      @media only screen and (max-width: 760px){
        font-size: 12px !important;
      }
    }

    iframe{
      height: 354px;
      z-index: 1;
      position: relative;
  
      @media only screen and (max-width: 760px){
        height: 260px;
      }

      @media only screen and (max-width: 580px){
        height: 200px;
      }
    }  

    .video-frame{
      position: relative;
      height: 354px;

      @media only screen and (max-width: 760px){
        height: 260px;
      }

      @media only screen and (max-width: 580px){
        height: 200px;
      }

      ul{
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0px;
        left: 0px;
        position: absolute;
      }
    }

    

  }

  .total-amount, .total-amount-label {
    font-size: 16px;
    color: #001219;
  }
  
  .refund-form{
    .reasonsBox input, select, .form-control{
      height:14px;
      margin:10px 0px;
    }
    strong{
      color:#43225B;
    }
    .emailBox{
      font-size: 14px !important;
      border: 1px solid #DDD4E2;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 10px;
      width: 100%;
    }
    .otherBox{
      height:40px !important;
      font-size: 14px !important;
      border: 1px solid #DDD4E2;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 10px;
      width: 100%;
    }
    .otherBox:focus{
      border:1px solid black;
    }
    .meetYourExpectations{
      height:40px !important;
      font-size: 14px !important;
      border: 1px solid #DDD4E2;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 15px;
      min-width: 350px;
      background:transparent;
      color: #43225B !important;
    }
    .feedback{
      height:40px !important;
      font-size: 14px !important;
      border: 1px solid #DDD4E2;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 10px;
      width: 100%;
      min-height:100px !important;
      color: #43225B !important;
      resize:none;
    }
    .feedback:focus{
      border:1px solid black;
    }
  }
  .heading-text{
    font-size:26px;
  }
  .heading-para{
    font-size:13px !important;
  }
  .table-container {
    border: 0.5px solid #ECE7FD;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(67,34,91,0.1);
    overflow: hidden;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
  }
  td {
    border: 1px solid #ECE7FD;
    padding: 5px 8px;
    text-align: center;
    font-size: 12px;
    margin: 0;
    font-weight: 600;
  }
  .features-column{
    text-align:left !important;
    color:#001219;
    width: 30%;
  }
  .column-spacing{
    width: 15.5%;

    img{
      width: 20px;
      height: 20px;
    }
  }
  .featcolumn-padding{
    padding: 10px 20px 10px 8px;
    text-align: left;
  }
  .purchased-text{
    color:black;
    margin-bottom:0px;
    font-size:13px;
    opacity:0.4;
  }
  .purchased{
    padding: 5px 15px 7px 15px !important;
    font-size: 12px !important;
    background: none !important
  }

  th {
    border: none;
    padding: 10px 10px;
    text-align: center;
    background-color: #3B108E;
    color: #FCFCFC;
    font-size: 12px;
    position: relative;
    font-weight: 700;
    
  }
  .yellow-text{
    color:#001219 !important;
    font-size:12px !important;
    background-color: #FFB10A;
    padding: 2px 6px;
    border-radius:2px;
    font-weight:inherit;
  }  
  .lp-colour{
    background-color: rgba(130, 96, 239, 0.25);
    font-size: 10px; 
    text-transform: uppercase; 
    font-family: Open Sans; 
    color:#001219;
    font-weight: bolder;

  }
  .selected {
    background-color: #FFFFFF;
    color: #000000;
    // border-left: 3px solid #6536CC !important; 
    // border-right: 3px solid #6536CC !important; 
  }
  td.selected, th.selected {
    border-left: 2px solid blue; 
    border-right: 2px solid blue; 
  }
  .selected th {
    background-color: #FFFFFF !important; 
    color: #000000; 
    border-top: 1px solid blue; 
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px; 
  }
  @media only screen and (max-width: 767px) and (min-width: 270px) {
    .table-container {
      border-radius: 8px;
      overflow: hidden;
    }
    
    table {
      width: 100%;
    }
  
    td {
      font-size: 10px;
    }
  
    .featcolumn-padding {
      padding: 2px 5px 10px 5px;
    }
  
    th {
      padding: 3px 8px;
      font-size: 10px;
    }
  
    .yellow-text {
      font-size: 8px !important;
      padding: 0px 3px;
    }
  }
  
  /* Large Tablets and Small Tablets*/
  
  @media only screen and (max-width: 1201px) and (min-width: 124px) {
    .table-container {
      border-radius: 8px;
      overflow: hidden;
    }
    
    table {
      width: 100%;
    }
  
    td {
      font-size: 10px;
    }
  
    .featcolumn-padding {
      padding: 2px 5px 10px 5px;
    }
  
    th {
      padding: 3px 8px;
      font-size: 10px;
    }
  
    .yellow-text {
      font-size: 8px !important;
      padding: 0px 3px;
    }
  }
  
  @media only screen and (max-width: 1023px) and (min-width: 767px) {
    .table-container {
      border-radius: 8px;
      overflow: hidden;
    }
    
    table {
      width: 100%;
    }
  
    td {
      font-size: 10px;
    }
  
    .featcolumn-padding {
      padding: 2px 5px 10px 5px;
    }
  
    th {
      padding: 3px 8px;
      font-size: 10px;
    }
  
    .yellow-text {
      font-size: 8px !important;
      padding: 0px 3px;
    }
  }
  
  @media only screen and (max-width: 766px) and (min-width: 451px) {
    .table-container {
      border-radius: 8px;
      overflow: hidden;
    }
    
    table {
      width: 100%;
    }
  
    td {
      padding: 3px 8px;
      font-size: 10px;
    }
  
    .featcolumn-padding {
      padding: 2px 5px 10px 5px;
    }
  
    th {
      padding: 3px 8px;
      font-size: 10px;
      .yellow-text {
        font-size: 7px !important;
        padding: 0px 3px;
      }
    }
  
    
  }
  
  @media only screen and (max-width: 450px) and (min-width: 270px) {
    .table-container {
      border-radius: 8px;
      overflow: hidden;
    }
    
    table {
      width: 100%;
    }
  
    td {
      padding: 7px 4px;
      font-size: 9px;
      img{
        width:15px;
      }
    }
  
    .featcolumn-padding {
      padding: 2px 5px 8px 5px;
    }
  
    th {
      padding: 3px 6px;
      font-size: 9px;
      .yellow-text {
      font-size: 7px !important;
      padding: 0px 2px;
    }
    }
  
    
  }
  
  .domeForm{
    max-height:500px;
    overflow-y:scroll;
  }
  &.addonContent{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &.expiredUser{
    /* border: 2px solid black; */
    pointer-events: none;
    opacity: 0.5;
  }
    
  .selected-plan{
    background:#ffffff;
    background: #FFFFFF;
    border: 1px solid #DDD4E2;
    padding:8px 15px;
    border-radius: 5px;
    span, strong{
      letter-spacing: 0.5px;
      line-height: 170%;
      font-size:14px;
      @media(max-width:500px){
        font-size:12px;
      }
    }
  }
  &.expiredUser{
    /* border: 2px solid black; */
    pointer-events: none;
    opacity: 0.5;
  }
  
  .mod-head{
    margin-bottom:24px;
    p{
      font-size:14px;
    }
    .addonNameOnPopup{
      text-transform: capitalize;
    }
  }
  .searchHeader{
    align-items: center;
    img{
      width: 12%;
    }
  }
  .addonInfo li{
    margin-bottom:10px;
    strong{
        display:block;
    }
    a{
      font-size: 14px;
    }
  } 
  .mod-body h3{
    font-size: 18px;
    font-weight:600;
  }
  
  .scroll-view{
      display: block !important;
      max-height: 500px !important;
      overflow-y: scroll;
    }

  .scroll-view::-webkit-scrollbar {
    width: 4px !important;
  }
  
  .scroll-view::-webkit-scrollbar-thumb {
    background: rgba(99, 115, 129, 0.1);
    border-radius: 40px;
  }

  .addon-purchase {
    padding-right: 0px;
  }
  .addon-purchase .payment-modal{
    padding: 0px;
    & > div {
      margin: 0px;
    }
  }
  iframe{
    border-radius :10px ;
  }

  /* Small Tablets */
  @media only screen and (max-width: 697px) and (min-width: 270px) {
    .mod-head {
      h3 {
        font-size: 14px !important;
        margin-bottom: 10px !important;
      }
      p {
        font-size: 12px !important;
        margin: 0 !important;
        letter-spacing: 0.5px;
      }
    }
    .mod-body {
      h3 {
        font-size: 14px !important;
      }
      p {
        font-size: 12px !important;
      }
      .addonInfo li {
        font-size: 12px !important;
        padding: 0 10px 0 0;
        a{
          font-size: 12px !important;
        }
        strong {
          font-size: 12px !important;
        }
      }
      input[placeholder] {
          font-size: 12px !important;
      }
    }
  }
  .mod-head{
    ${props => props.addon && css`
        margin-bottom: 0px !important;
    `}
  }

  .freemium{
    background: ${props => props.addon === constants.allProducts ? "#3B108E": "#FFF"};
    margin: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items:center;
    min-height: 500px;
    justify-content: ${props => props.addon === constants.allProducts ? "center": "unset"};;
    .span1{
      border:2px solid white;
      height:60px;
      width: 100px;
      padding:60px;
      margin-top:15px;

    }
    .span2{
      border:2px solid white;
      padding:20px;
      height:60px;
      width: 100px;
    }
    h1{
      text-align: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 110%;
      color:${props => props.addon === 'purekeep' ? "#2EC4B6" : props.addon === 'pureprivacy' ? "#FFB10A" : props.addon === "purecrypt" ? "#4361EE" : ""};

    }
    p{
      margin:20px 0 40px 0;
      width: 480px;
      text-align: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.25px;
      color:#43225B;
      ${props => props.addon === constants.allProducts && css`
        color: #EEE9F4;
        font-size: 20px;
      `}
      span{
        color: #F3B544 !important;
        font-weight: 700;
        font-size:20px !important;
      }
    }
    .bannerHead{
      border-radius: 10px;
    }
    .inputField{
      margin: 0 0 35px 0;
      .checkBox{
        width: 18px;
        height: 18px;
        margin-top: 0px;
      }
      .checkBox:checked {
        background-color: #2CDC82 !important;
        border-color: #2CDC82 !important;
      }
      label{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
        letter-spacing: 0.02em;
        color: ${props => props.addon === constants.allProducts ? "#6536CC":"#43225B"};
        margin-left: 5px;
      } 
    }
    @media only screen and (max-width: 697px) and (min-width: 270px) {
      h1{
        font-size: 26px;
        margin-bottom: 10px;
      }
      p{
        width: auto;
        font-size: 12px !important;
      }
      button{
        margin-top: 20px;
        margin-bottom: 10px !important;
      }
      .inputField{
        margin: 20px 10px;
        align-items: start !important;
      }
      ${props => props.addon === constants.allProducts && css`
        padding-top:25px;
        justify-content: start;
        min-height: 310px;
      p{
        span{
          font-size: 12px!important;
        }
        margin-bottom:15px !important;
      }
      img{
        padding: 0 10px;
      }

      `}
    }
    ${props => props.isChangePsw && css`
        min-height: auto;
        h1{
          color: #43225B;
          font-size: 28px;
          margin-top: 40px;
        }
        p{
          margin: 10px 0;
        }
        .inputField{
          margin-bottom: 20px;
        }
        & > ${AddonImg} {
          margin-top: 30px;
          transform: scale(250%);
        }
    `}
  }
  .upgradeCampaign{
    background: linear-gradient(180deg, #281652 0.36%, #4E3589 87.37%);
    justify-content:space-around !important;
    min-height:300px;
    padding: 50px;
    h1{
      color:#EEE9F4;
      font-size: 33px;
    }
    p{
      margin:35px 20px;
      width:auto;
      font-size:18px;
    }
    .inputField{
      margin-bottom:0;
      label{
        color:#fff;
      }
    }
    button{
      padding:15px;
      font-size:20px;
    }      
  }
  @media only screen and (max-width: 460px) and (min-width: 270px){
    .cancel-subs-btn{
      flex-direction:row !important;
      align-items:center !important;
    }
    .cancel-subs-btn .submit-btn {
      margin-top:5px;
    }
  }
  `;

export const ThankYouModalContainer = styled.div`

.modal-body {
  padding: 10px 0px;
}

.addonContent.thankyou {
  display: flex;
  flex-direction: column;
  gap: 40px;

  & > div {
    display: flex;
  }

  p {
    font-size: 12px;
  }

  .head {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .content {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: 20px !important;
      line-height: 28px;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 0px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 20px;
    h4 {
      font-size: 14px !important;
      font-weight: 600;
      margin-bottom: 0px;
    }
    ul {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      list-style-type: decimal;
      gap: 15px;
    }

    li {
      div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &.links {
          flex-direction: row;
          gap: 5px;
        }
      }
      span {
        font-size: 12px !important;
      }
      a {
        display: flex;
        flex-direction: row;
        gap: 5px;
        padding: 5px 10px;
        font-weight: 400;
        color: #001219 !important;
        background: rgba(130, 96, 239, 0.1);
        border-radius: 80px;
        font-size: 12px !important;
      }
    }
  }

  .foot {
    margin: auto 20px;
    div {
      display: flex;
      padding-top: 20px;
      width: 100%;
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.0578);
    }

    h4 {
      font-size: 14px !important;
      font-weight: 600;
    }

    p{
      font-size: 12px !important;
      margin-bottom: 0px;
    }

    a, span {
      font-size: 12px !important;
      color: #6536CC !important;
      font-weight: 400;
      cursor: pointer;
    }
  }
  
}

`
export const OrderSummary = styled.div`
    ul{
        display:none;
    }
    ul.show{
        display:block
    }
    li{
        color:#001219 !important;
        font-size:12px !important;
        margin-bottom:5px;
        clear:both;
        display:flex;
        justify-content:space-between;

        &.suggested{
          position: relative;
          border-radius: 10px;
          padding: 8px 10px;
          background: #ECE9EF;
          align-items: center;
          font-size: 11px !important;
          margin: 20px 0px 0px;

          button{
            background: #FFFFFF;
            color: #8E7A9D;
            padding: 6px 10px;
            font-size: 12px;
            border-radius: 5px;
            border: 0;
            font-weight: 700;
            border: 1px solid #ECE9EE;
            margin: auto 0;
          }
        }
    }
    label{
        display:block;
        width:100%;
        cursor:pointer;
        position:relative;
        strong {
          color: #7320FF;
        }
        ::before {
            height: 24px;
            width: 24px;
            content: '';
            position: absolute;
            top: -2px;
            right: 0;
            background-color: #EEE9F4;
            border-radius: 5px;
        }
        ::after{
            transition:all 0.3s linear;
            background:url(${collapse}) no-repeat center center;
            height:24px; 
            width:24px;
            content:'';
            position:absolute;
            top:-2px;
            right:0;
            ${props => props.open && css`
                transform: rotate(180deg);
            `}
        }
    }
`;

export const PlanCard = styled.div`
  border: ${({selected}) => selected ? "1px solid #2cdc82" : "1px solid #EEEDF0"};
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;

  .desc {
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #637381;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .icon8 {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .icon-container {
    div {
      content: "";
      position: absolute;
      top: -3px;
      width: 24px; 
      height: 24px;
    }
    .vpn {
      background:url(${productIcons}) no-repeat !important;
      background-position: -48px 0px !important;
    }
    .keep {
      left: 142px;
      background:url(${productIcons}) no-repeat !important;
      background-position: -72px 0px !important;
    }
    .crypt {
      left: 176px;
      background:url(${productIcons}) no-repeat !important;
      background-position: -96px 0px !important;
    }
    .privacy {
      left: 210px;
      background:url(${productIcons}) no-repeat !important;
      background-position: -120px 0px !important;
    }
  }
  .whats-included-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 15px;
    align-items: center;
    font-size: 12px;
    position: relative;
  }
  .b6 {
    position: relative;
    letter-spacing: 0.15px;
    line-height: 125%;
  }
  .off-frame,
  .pricing {
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
  }
  .off-frame {
    border-radius: 100px;
    background-color: #2cdc82;
    flex-direction: row;
    align-items: flex-start;
    padding: 3px 10px;
    font-size: 10px;
    color: #fff;
  }
  .pricing {
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    text-align: right;
  }
  .renew-plan {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    font-size: 14px;
    text-align: left;
  }
`;

export const PackageBox = styled.div`
    border: ${({selected}) => selected ? "1px solid #43225B" : "1px solid #ECE9EE"};
    box-sizing: border-box;
    border-radius: 5px;
    clear:both;
    display:table;
    width:100%;
    padding:7px 40px 7px 15px;
    cursor:pointer;
    background: url(${({theme:{passwordChecker}, selected}) => selected ? passwordChecker.verify : passwordChecker.notVerify}) no-repeat;
    background-position: 97% center;
    background-color:${({selected}) => selected ? "#ECE9EF" : "#FFFFFF" };
    &.appPkgBox{
      background-position: 99% center;
    }
    :not(:last-child){
        margin-bottom:10px;
    }
    span{
        color:#43225B !important;
        letter-spacing: 0.15px;
        ${({selected})=>selected && css`
            font-weight:bold;
        `}
    }
    /* Small Tablets */
    @media only screen and (max-width: 800px) {
        margin-top: 15px !important;
    }
    /* Mobile Devices  */
    @media only screen and (max-width: 690px) {
        padding-right:30px;
        margin-top: 15px !important;
        span {
        font-size: 12px !important;
        }
    }
`;

export const PurchaseBox = styled.div`
    position:relative;
    select{
        background:#FFFFFF url(${selectcaret}) no-repeat;
        background-position: 97% center;
        ${({type})=> type === "payment" && css`
            padding-left:${({theme:{payment}, icon}) => icon === "" ? "15px" : "49px"};
        `}
        transition:all 0.3s linear;
    }
    ::after{
        position:absolute;
        top:  ${({icon}) => icon ==="card" ? "50%" : "50%"};
        left: 17px;
        width: 24px;
        height: 24px;
        content: '';
        transform: translateY(-50%);
        ${({type})=> type === "payment" && css`
            background:${({theme:{payment}, icon}) => icon==="card" ? payment.card : icon==="paypal" ? payment.paypal : null};
        `}
    }
    .custom-for-whmcs.form-control{
      background-position: 99% center;
    }
`;

export const ReferBox = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 24px !important;
    text-align: center;
    font-weight: 400;
  }
  .tiers {
    display: inline-flex;
    gap: 18px;
    @media (max-width:812px){
      flex-direction: column;
    }
  }
  hr {
    border: 1px solid #EEEDF0;
    width: 55%;
    opacity: 100%;
  }
  .tiers-progress {
    text-align: center;
    display: flex;
    gap: 40px;
    div:not(:first-child):before {
      content: " ";
      border-radius: 15px;
      display: flex;
      width: 25px;
      height: 2px;
      background: #C1C7CD;
      left: -33px;
      top: 35%;
      position: relative;
    }
    p {
      padding-top: 10px;
      font-size: 12px;
    }
    .locked > p {
      opacity: 0.5;
    }
    @media (max-width:420px){
      transform: scale(0.8);
    }
    @media (max-width:318px){
      transform: scale(0.6);
    }
  }
  .invite-info {
    display: flex;
    gap: 20px;
    text-align: center;
    border: 1px solid #ECE7FD;
    border-radius: 26px;
    padding: 12px 20px;
    .section:not(:first-child) {
      border-left: 1px solid #ECE7FD;
      padding-left: 20px;
    }
    .title {
      color: #637381;
      font-size: 12px;
    }
    .count {
      padding-top: 5px;
      font-size: 20px;
    }
  }
  .show-tiers {
    font-size: 12px;
    cursor: pointer;
  }
`

export const TierIcon = styled.div`
  width: 80px;
  height: 80px;
  &.Gold::after {
    background-position: -80px;
  }
  &.Platinum::after {
    background-position: -160px;
  }
  &.Diamond::after {
    background-position: -240px;
  }
  &.sm {
    width: 40px;
    height: 40px;
    &.Gold::after {
      background-position: -40px;
    }
    &.Platinum::after {
      background-position: -80px;
    }
    &.Diamond::after {
      background-position: -120px;
    }
  }
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${tiers});
    background-size: cover;
    background-repeat: no-repeat;
    ${({status}) => (status === 'completed' || status === 'locked') && css`
      opacity: 0.5;
    `}
  }
  ${({status}) => status === 'completed' && css`
  span {
      position: absolute;
      z-index: 2;
      top: 25px;
      left: 26px;
      width: 30px;
      height: 30px;
      background-image: url(${GreenCheckMark});
    }
  `}
  ${({status}) => status === 'locked' && css`
  span {
      position: absolute;
      z-index: 2;
      top: 21px;
      left: 28px;
      width: 24px;
      height: 33.8px;
      background-image: url(${tierLocked});
    }
  `}
`

export const TierBox = styled.div`
    min-height: 255px;
    width: 260px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-light-borders, #EEEDF0);
    border-radius: 32px;
    background: #FFF;
    padding: 20px;
    gap: 12px;
    background-color: #F6F3FF;
    background-image: url(${TierBG});
    background-repeat: no-repeat;
    background-size: cover;
    ${({active}) => active && css`
      border: 1px solid var(--border-light-borders, #8260EF);
    `}

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #0E0222 !important;
        background-color: #FFB10A;
        padding: 5px 12px;
        border-radius: 32px;
        font-size: 12px !important;
        font-weight: 400;
      }
      span.completed {
        background-color: #5ED18E;
        color: #0E0222 !important;
      }
      span.locked {
        background-color: #8260EF;
        color: #FFF !important;
      }
    }
    .progress-bar {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: 
        radial-gradient(closest-side, white 69%, transparent 70% 100%),
        conic-gradient(from 180deg, #FFB10A ${({barPercentage}) => `${barPercentage}`}%, #F9F8FC 0); //75% will be replaced with calculated
    }
    .progress-bar::before {
      content: " ";
      background-image: url(${star});
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
    }
    .title {
      display: flex;
      align-items: center;
      .title-text {
        margin-left: 10px;
        color: #001219 !important;
        font-size: 14px;
      }
      ${({active}) => !active && css`
        opacity: 0.5;
      `}
    }
    .info {
      h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
      ul {
        padding-left: 25px;
      }
      li {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        color: #001219 !important;
        line-height: 100%;
      }
      li:not(:last-child) {
        margin-bottom: 8px;
      }
      ${({active}) => !active && css`
        opacity: 0.5;
      `}
    }
`

export const ShareLink = styled.li`
    background:url(${share}) no-repeat;
    height:26px;
    padding:0;
    margin:0 12px 0 0 !important;
    @media (min-width:350px){
      transform: scale(1.2);
      margin:0 24px 0 0 !important;
    }
    button{
        width:24px;
        height:26px;
        padding:0  !important;
        margin:0;
        background-color:transparent;
        outline:0;
        border:0;
    }
    :nth-child(1){
        background-position-x:0px;
    }
    :nth-child(2){
        background-position-x:-24px;
    }
    :nth-child(3){
        background-position-x:-48px;
    }
    :nth-child(4){
        background-position-x:-72px;
    }
    :nth-child(5){
        background-position-x:-96px;
    }
    :nth-child(6){
        background-position-x:-120px;
    }
`;

function spritesPosition(length){
    let style;
    for(var i=0;i<length;i++){
        let pos = 70*i;
        style+=`
            :nth-child(${i+1}){
                .icon{
                    background-position-x:-${pos}px;
                }
            }
        `;     
    }
    return style;
}

export const HelpBox = styled.div`
    display:flex;
    justify-content:space-evenly;
    border-bottom: 1px solid #DDD4E2;
    padding:0 0 30px;
    margin:0 0 30px;
    .help-box{
        background: #FFFFFF;
        border: 0.5px solid #DDD4E2;
        box-sizing: border-box;
        border-radius: 12px;
        flex: 1;
        padding: 30px 15px 15px;
        cursor: pointer;
        transition: 0.3s all ease-in-out;

        &:hover{
          box-shadow: 0px 4px 10px rgba(67,34,91,0.05),0px 10px 30px rgba(67,34,91,0.1);

        }
        :not(:first-child, :last-child){
            margin:0 15px;
        }
        :first-child{
            margin-right:15px;
        }
        :last-child{
            margin-left:15px;
        }
        ${spritesPosition(5)};
        .icon{
            height:60px;
            width:60px;
            margin:auto;
            background:url(${helpdesk}) no-repeat;
        }
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        text-align: center;
        color: #43225B;
        margin:0 0 15px;
    }
    p{
        margin:15px 0;
        font-size:${({theme:{typography}})=>typography.smallerBody};
        min-height:30px;
    }
     /* -- media query for all devices -- */
    /* Mobile Devices and Large Tablets */
    @media only screen and (max-width: 1380px) and (min-width: 1023px) {
        .help-box {
        /* Help Box - between margin */
        :not(:first-child, :last-child) {
            margin: 0px 7px;
        }
        /* Help Box - first */
        :first-child {
            margin-right: 7px;
        }
        /* Help Box - last */
        :last-child {
            margin-left: 7px;
        }
        }
    }
    /* Mobile Devices and Large Tablets */
    @media only screen and (max-width: 1023px) and (min-width: 270px) {
        margin: 0 0 30px;
        flex-direction: column;
        .help-box {
        /* Help Box - between margin */
        :not(:first-child, :last-child) {
            margin: 15px 0;
        }
        /* Help Box - first */
        :first-child {
            margin-right: 0;
            margin-bottom: 15px;
        }

        /* Help Box - last */
        :last-child {
            margin-left: 0;
            margin-top: 15px;
        }
        }
    }

    /* Mobile Devices */
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        display: inline-block !important;

        .help-box:not(:last-child) {
        display: inline-block !important;
        width: 45.5% !important;
        margin: 15px 15px 15px 15px !important;
        }

        .help-box:nth-child(5) {
        width: 50% !important;
        margin: 15px auto 0px auto !important;
        }
    }
`;

function socialSprites(){
    let style;
    for(var i=0;i<4;i++){
        let pos = 24*i;
        style+=`
            li:nth-child(${i+1}) a{
                background-position-x:-${pos}px;
            }
        `;     
    }
    return style;
}

export const TicketWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 12px;
    padding:30px;
    ${DashboardHead}{
        margin-bottom:34px;
    }
     /* Mobile Devices */
    @media only screen and (max-width: 539px) and (min-width: 270px) {
        padding: 15px !important;
    }
`;

export const TicketBox = styled.div`
    display:flex;
    margin-bottom:30px;
    h3{
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.25px;
        color: #43225B;
        margin-bottom:5px;
    }
    h5{
        font-weight: normal;
        font-size: 12px;
        line-height: 125%;
        letter-spacing: 0.02em;
        margin-bottom:5px;
    }
    p.comment-box{
        background: #F6F4F7;
        border-radius: 10px;
        padding:30px;
        font-size:${({theme:{typography}})=>typography.smallBody};
        line-height: 150%;
    }
    .comment-box .no-margin{
        margin: 0px;
    }
    .comment-box p{
        margin: 0px;
    }
    textarea{
        min-height:160px;
    }
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 539px) and (min-width: 371px) {
        /* Ticket Detail Screen */
        width: 100% !important;
        p {
        font-size: 12px !important;
        margin-top: 15px !important;
        word-wrap: break-word !important;
        width: 100% !important;
        padding: 20px 15px !important;
        margin-bottom: -20px !important;
        }
        textarea {
        margin: 15px 0px !important;
        }
        .form-control {
        font-size: 12px !important;
        margin-bottom: 12px !important;
        }
    }

    @media only screen and (max-width: 370px) and (min-width: 315px) {
        /* Ticket Detail Screen */

        width: 100% !important;

        p {
        font-size: 13px !important;
        margin-top: 15px !important;
        word-wrap: break-word !important;
        width: 100% !important;
        padding: 20px 15px !important;
        margin-bottom: -20px !important;
        }

        .form-control {
        font-size: 12px !important;
        margin-bottom: 12px !important;
        }

        .flex-grow-1 {
        margin: 0px !important;
        }

        .flex-shrink-0 .img-fluid {
        width: 30px !important;
        }

        .me-3 {
        margin-right: 10px !important;
        }
    }

    @media only screen and (max-width: 314px) and (min-width: 270px) {
        /* Ticket Detail Screen */

        width: 100% !important;
        display: block !important;

        p {
        font-size: 11px !important;
        margin-top: 15px !important;
        word-wrap: break-word !important;
        width: 100% !important;
        padding: 10px !important;
        margin-bottom: -20px !important;
        }

        textarea {
        margin: 15px 0px !important;
        }

        .form-control {
        font-size: 12px !important;
        margin-bottom: 12px !important;
        }
    }
`;

export const TicketFields = styled.div`
    margin-bottom:10px;
    label{
        font-size:${({theme:{typography}})=>typography.smallerBody};
        color: #7B648C;
        margin:0 0 10px;
    }
    textarea{
        min-height:120px;
    }
    input[type="file"]{
        height: 35px;
        border: 1px dashed #DDD4E2;
    }
    :last-child{
        padding-bottom:30px;
        border-bottom: 1px solid #DDD4E2;
    }
    .danger{
        color:${({theme:{branding}})=>branding.danger};
        margin:5px 0 0;
        font-size:${({theme:{typography}})=>typography.smallerBody};
    }
    ${(props) => props.validation && css`
        input, textarea, select{
            border:1px solid ${({theme:{branding:{danger}}})=>danger} !important;
        }
    `}
    /* -- media query for all devices -- */

    /* Mobile Devices */
    @media only screen and (max-width: 450px) and (min-width: 270px) {
        input {
        font-size: 12px !important;
        }

        label {
        font-size: 12px !important;
        }

        textarea {
        font-size: 12px !important;
        }

        input[type="file"] {
        font-size: 12px !important;
        height: auto;
        }
    }
`;

export const DownloadBox = styled.div`
    width:99%;    
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    box-sizing: border-box;
    border-radius: 12px;
    padding:30px 30px 30px;
    position:relative;

    .badge {
      position: absolute;
      background: #E5FBF0;
      padding: 5px 10px;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: row;
      font-size: 9px;
      color: #7B648C;
      border-radius: 25px;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      height: 21px;
      min-width: 80px;
    }

    .beta{
      background: #FDE944 !important;
    }
    
    h3{
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      text-align: center;
      color: #43225B;
      margin:30px auto 15px;
    }
    p{
      font-size: 12px;
      line-height: 125%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #7B648C;
      margin: 0 0 5px;
    }
    ul{
        margin:30px 0 0;
    }
    .loader{
      display:flex;
      align-items:center;
      justify-content:center;
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      background:white;
      opacity:0;
      visibility: hidden;
      border-radius:12px;
    }
    &.coming-soon {
      img {
        filter: blur(3px);
        -webkit-filter: blur(3px);
      }
    }

    ${({loading}) => loading == true && css`
      .loader{
        opacity:1;
        visibility: visible;
      }
    `}
     /* -- media query for all devices -- */
    /* Mobile Devices and Tablets */
    @media only screen and (max-width: 1398px) and (min-width: 901px) {
      padding-top: 30px;
    }
    /* Mobile Devices */
    @media only screen and (max-width: 992px) and (min-width: 768px) {
      display: block;
      padding: 65px 30px !important;

      p {
      font-size: 12px;
      }

      h3 {
      font-size: 22px !important;
      line-height: 51px;
      }

      ul {
      margin: 20px 0 0;
      }
    }

    /* Mobile Devices */
    @media only screen and (max-width: 767px) and (min-width: 270px) {
        display: block;
        padding-top: 15px;
        min-height: auto;

        min-height: auto;

        p {
        font-size: 11px;
        }

        h3 {
        font-size: 18px !important;
        line-height: 21px;
        }

        ul {
        margin: 10px 0 0;
        }
    }
`;


export const SingleAppDownloadBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
  border: 0.5px solid #DDD4E2;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 50px 25px 25px 25px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100%;
  justify-content: flex-start;

  .badge {
    position: absolute;
    background: #E5FBF0;
    padding: 5px 10px;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: row;
    font-size: 9px;
    color: #7B648C;
    border-radius: 25px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    height: 21px;
    min-width: 80px;
  }

  .beta{
    background: #FDE944 !important;
  }

  .app-img{
    min-height: 100px;
    max-width: 100%;
  }

  .app-img.icon, .app-img.mtop{
    margin-top: 10px;
  }

  .app-img > img.hasExt{
    max-height: 100px;
    ${props => props.isPrivacy && css`
        max-height:170px;
          `}
    width: auto;
    max-width: 100% !important;
  }

  p{
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7B648C;
    margin: 0 0 5px;
  }

  .minheight50{
    min-height:50px;
  }
  .command-box{
    background:#F6F4F7;
    border-radius: 5px;
    padding:10px 8px;
    max-width:270px;

    span {
      text-decoration:underline;
      color: #361B49 !important;
      font-size: 10px !important;
      font-family: 'Mono';
    }
    p {
      font-size:10px;
      color: #361B49;
      font-family: 'Mono';
    }
  }
  .copy-box{
    background: #FFFFFF;
    border: 1px solid #DDD4E2;
    border-radius: 5px;
    max-width:155px;
    padding:5px 0px 5px 8px;
    cursor:pointer;

    p {
      font-size:12px;
      font-weight:600;
      line-height: 18px;
      margin:0px;
    }
    img {
      width:30px;
      height:30px;
    }
  }
  .inner {
    display: flex;
    flex-direction: column; 
    justify-content: start;
    align-items: center;
    min-height: 300px;
    justify-content: flex-start;
    
    .icon {
      width: 100px;
      height: 100px;
      background:url(${devicesLarge}) no-repeat;
      background-position: 0px 0px;

      &.icon-google-chrome {
        background-position: -700px 0px;
      }
      &.icon-firefox {
        background-position: -600px 0px;
      }
      &.icon-edge {
        background-position: -800px 0px;
      }
      &.icon-android {
        background-position: -300px 0px;
      }
      &.icon-windows {
        background-position: -100px 0px;
      }
      &.icon-mac {
        background-position: 0px 0px;
      }
      &.icon-linux, .icon-linux_gui {
        background-position: -900px 0px;
      }
      &.icon-apple_tv {
        background-position: 0px 0px;
      }
    }

    img {
      /* Mobile Devices */
      @media only screen and (max-width: 992px) and (min-width: 270px) {
        max-width: 100%;
      }
    }

    .heading {
      margin-top: 40px;
      margin-bottom: 10px;
      ${props => props.isPrivacy && css`
        font-size: 3.00ch !important;
        `}
      font-size: 1.98ch;

      /* Mobile Devices */
      @media only screen and (max-width: 992px) and (min-width: 270px) {
        margin-top: 20px;
      }
    }
    .para {
      color: #7B648C !important;
      margin-bottom: 30px !important;
      text-align: center;
      max-width: 280px;
      font-size: 12px;

      /* Mobile Devices */
      @media only screen and (max-width: 992px) and (min-width: 270px) {
        margin-bottom: 20px !important;
      }
    }
    .para2 {
      color: #7B648C !important;
      font-size: 12px !important;
    }
    .para1 {
      color: #7B648C !important;
      font-size: 11px !important;
    }

    /* Mobile Devices */
    @media only screen and (max-width: 992px) and (min-width: 270px) {
      min-height: auto;
      margin-bottom: 10px;
    }
  }

  .non-link {
    cursor: auto;
    &:hover {
      cursor: auto;
    }
  }

  .button-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: auto !important;

    button, select {
      width: 100%;
      max-width: 200px;
    }

    select {
      background:#FFFFFF url(${selectcaret}) no-repeat;
      background-position: 95% center;
      text-transform: capitalize;
      min-height: 49px;
    }

    ul {
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        justify-content: center;
        align-items: center;
      }
      li:last-child{
        margin-bottom: 0px;
      }
    }
  }
  /* Mobile Devices */
  @media only screen and (max-width: 992px) and (min-width: 270px) {
    margin-bottom: 20px;
  }

`


export const DownloadAllAppsNav = styled.ul`

    // width: 100%;
    display: flex;
    list-style: none;
    margin-bottom: 60px;
    border-bottom: 1px solid #DDD4E2;
    padding: 0px;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin-right: 10px;
            
    @media only screen and (max-width: 768px) {
      margin-right: 0px;
    }

    
    li {
      border-bottom: 3px solid transparent;
      display: flex;
      justify-content: center;
      min-height: 45px;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 10px;
      position: relative;

      span {
        font-size: 16px !important;

        @media only screen and (max-width: 768px) {
          font-size: 12px !important;
        }
      }

      &:hover:not(.locked) {
        font-weight: 700;
        border-bottom: 3px solid #43225B;
        cursor: pointer;

        span {
          color: #43225B !important;
        }
      }

      &.active {
        border-bottom: 3px solid #43225B;

        span {
          color: #43225B !important;
          font-weight: 700;

          &::before {
            font-weight: 400px;
          }
        }
      }

      &.is-new {
        span {
          position: relative;

          &::before {
            content: "new";
            height: 16px;
            position: absolute;
            top: 5px;
            right: -35px;
            background-color: #2CDC82;
            color: #43225B !important;
            font-size: 9px !important;
            border-radius: 4px;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 1px 4px;
            text-transform: uppercase;
            
            @media only screen and (max-width: 768px) {
              top: -20px;
              right: -15px;
            }

            @media only screen and (min-width: 768px) and (max-width: 992px) {
              top: -12px;
              right: -15px;
            }
          }
        }
      }

      &.locked {
        opacity: 0.3;
        span {
          position: relative;
          &::before{
            position: absolute;
            content: "";
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            right: -20px;
            width: 16px;
            height: 16px;
            background: url(https://images.purevpn-tools.com/public/images/ACSD-30300-nav-onboarding-sprte.png) no-repeat -32px 0px;
          }
        }
      }

      @media only screen and (max-width: 768px) and (min-width: 270px) {
        flex: 2;
        font-size: 12px !important;
        justify-content: center;
      }

      @media only screen and (max-width: 400px) {
        /* flex: 0; */
        flex: ${props => props.fullWidth ? 1 : 0};
      }
    }

    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 768px) and (min-width: 270px) {
      margin-bottom: 20px !important;
    }

`

export const GuideBox = styled.div`
    padding-left:15px;
    h4{
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        color: #43225B;
        margin:0 0 15px;
    }
    li a{
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #0D85E8;
        display:block;
        margin-bottom:10px;
    }
    padding:0 0 20px;
    margin:0 0 30px;
    border-bottom:1px solid #DDD4E2;
     /* -- media query for all devices -- */

  /* Mobile Devices */
    @media only screen and (max-width: 767px) and (min-width: 270px) {
        padding: 0 0 10px;
        margin: 0 0 15px;
        
        h4 {
        font-size: 16px !important;
        margin-top: 30px;
        }

        li a {
        font-size: 11px !important;
        }
    }
`;

function deviceSprites(){
    let style;
    for(var i=0;i<12;i++){
        let pos = 24*i;
        style+=`
            :nth-child(${i+1}){
                button::after{
                    background-position-x:-${pos}px;
                }
                button.active::after{
                    background-position-x:-${pos}px;
                    background-position-y:-24px;
                }
            }
        `;     
    }
    return style;
}

export const OtherDevice = styled.div`
    padding-right:30px;
    h4{
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        color: #43225B;
        margin:0 0 15px;
    }
    ul{
        display:flex;
        flex-wrap:wrap;
        li{   
            margin-right:15px;    
            flex:calc(50% - 15px);    
            :not(:last-child, :nth-last-child(2)){
                border-bottom:1px solid rgba(198, 209, 221, 0.6);
            }
            padding:0 0 10px;
            margin:0 10px 10px 0;
            button,select{
                height:auto;
                position:relative;
                font-size: 12px;
                line-height: 125%;
                letter-spacing: 0.02em;
                color: #7B648C;
                border:0;
                background:none;
                outline:0;
                padding:4.5px 4.5px 4.5px 34px;
                ${({loading})=>loading && css`
                    pointer-events:none;
                `}
                ::after{
                    position:absolute;
                    content:"";
                    width:24px;
                    height:24px;
                    background:url(${devices}) no-repeat;
                    top:50%;
                    transform:translateY(-50%);
                    left:0;
                }
                &.active{
                    color: #43225B;
                    font-weight:bold;
                }
            }
            select{
                padding-left:29px;
            }
            button.device-icon {
              &.icon-mac::after,&.icon-macos::after{
                background-position: 0px 0px;
              }
              &.active.icon-mac::after,&.active.icon-macos::after{
                background-position: 0px -24px;
              }

              &.icon-windows::after{
                background-position: -24px 0px;
              }
              &.active.icon-windows::after{
                background-position: -24px -24px;
              }

              &.icon-linux::after{
                background-position: -216px 0px;
              }
              &.active.icon-linux::after{
                background-position: -216px -24px;
              }
              &.icon-linux_gui::after{
                background-position: -216px 0px;
              }
              &.active.icon-linux_gui::after{
                background-position: -216px -24px;
              }
              
	      &.icon-android::after{
                background-position: -72px 0px;
              }
              &.active.icon-android::after{
                background-position: -72px -24px;
              }

              &.icon-android_tv::after{
                background-position: -48px 0px;
              }
              &.active.icon-android_tv::after{
                background-position: -48px -24px;
              }

              &.icon-iphone_ios::after{
                background-position: -120px 0px;
              }
              &.active.icon-iphone_ios::after{
                background-position: -120px -24px;
              }

              &.icon-ipad_ios::after{
                background-position: -120px 0px;
              }
              &.active.icon-ipad_ios::after{
                background-position: -120px -24px;
              }

              &.icon-google_chrome::after{
                background-position: -168px 0px;
              }
              &.active.icon-google_chrome::after{
                background-position: -168px -24px;
              }

              &.icon-firefox::after{
                background-position: -144px 0px;
              }
              &.active.icon-firefox::after{
                background-position: -144px -24px;
              }

              &.icon-edge::after{
                background-position: -192px 0px;
              }
              &.active.icon-edge::after{
                background-position: -192px -24px;
              }

              &.icon-brave::after{
                background-position: -240px 0px;
              }
              &.active.icon-brave::after{
                background-position: -240px -24px;
              }

              &.icon-firestick::after{
                background-position: -264px 0px;
              }
              &.active.icon-firestick::after{
                background-position: -264px -24px;
              }

              &.icon-web::after{
                background-position: -288px 0px;
              }
              &.active.icon-web::after{
                background-position: -288px -24px;
              }

              &.icon-chromeos::after{
                background-position: -168px 0px;
              }
              &.active.icon-chromeos::after{
                background-position: -168px -24px;
              }
              &.icon-apple_tv::after{
                background-position: -312px 0px;
              }
              &.active.icon-apple_tv::after{
                background-position: -312px -24px;
              }

            }
            .soonTag{
                background: #E5FBF0;
                border-radius: 4px;
                padding: 1px 4px;
                font-size: 8px!important;
                text-transform: uppercase;
                color: #43225B!important;
            }
          &.soon{
            pointer-events:none;
          }
            
        }
        &.purekeep{
          li{
            #web{
              ::after{
                  background-image:url(${purekeepWeb})!important;
                  background-position: -2px -2px;
                  background-repeat: no-repeat;
              }
              &.active{
                ::after{
                  background-position: -26px -2px!important;
                }
              }
              
            }
          }
        }
    }
     /* -- media query for all devices -- */

    /* Mobile Devices and Small Tablets */

    @media only screen and (max-width: 992px) and (min-width: 601px) {
        ul li button {
        white-space: nowrap !important;
        }
    }

    @media only screen and (max-width: 600px) and (min-width: 270px) {
        padding-right: 0 !important;

        h4 {
        font-size: 16px !important;
        
        }

        ul li button {
        font-size: 10px !important;
        }

        ul li select {
        padding-left: 30px !important;
        font-size: 10px !important;
        }

        ul li select option {
        font-size: 10px !important;
        }

        ul li:last-child::after {
        top: 25% !important;
        }
    }
`;

export const Devices = styled.div`
    ul{
        display:flex;
        flex-wrap:wrap;
        width:100%;
        max-height: 400px;
        flex-direction: column;
        overflow:auto;
    }
    li{
        line-height:30px;
    }
    /* -- media query for all devices -- */
 
    /* All Devices Modal - list */

    @media only screen and (max-width: 651px) and (min-width: 521px) {
        li {
        flex-basis: 45%;
        line-height: 15px;
        padding: 5px 0px;
        justify-content: space-evenly;
        align-items: center;
        }
    }

    /* All Devices Modal - list */

    @media only screen and (max-width: 520px) and (min-width: 270px) {
        ul {
            display:block !important;
            flex-direction: column;
        }
        li {
        flex-basis: 38%;
        line-height: 12px;
        padding: 5px 0px;
        justify-content: space-evenly;
        align-items: center;
        }
    }
`;

export const PForwardContainer = styled.div`
       
       border-bottom: ${props => props.noborder ? "none" : "1px solid lightgrey"};
       max-width: 645px;
    
       h2
       {
         font-style: normal;
         font-weight: bold;
         font-size: 24px;
         line-height: 33px;
         color: #43225B;
       }
       label
       {
         font-style: normal;
         font-weight: normal;
         font-size: 16px;
         line-height: 125%;
         margin-top: -10px;
         padding-left: 10px;
         color: '#43225B';
         letter-spacing: 0.25px;
       }
       .label-text{
        font-size: 18px;
        color: #43225B;
        font-weight: 600;
        margin-bottom: 20px;
        .red{
          color: #FF1744 !important;
          font-size: inherit !important;
          font-weight:600;
        }
        .green{
          color: #2CDC82 !important;
          font-size: inherit !important;
          font-weight:600;
        }
       }
       [type="radio"]:checked, [type="radio"]:not(:checked) 
       {
       position: absolute;
       left: -9999px;
       }

       [type="radio"]:checked + label, [type="radio"]:not(:checked) + label
       {
         position: relative;
         padding-left: 28px;
         cursor: pointer;
         line-height: 12px;
         display: inline-block;
         color: #43225B;
       }

       [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
         content: '';
         position: absolute;
         left: 0;
         top: 0;
         width: 12px;
         height: 12px;
         border: 1px solid #43225B;
         border-radius: 100%;
         background: #fff;
       }

       [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
         content: '';
         width: 6px;
         height: 6px;
         background: #43225B;
         position: absolute;
         top: 3px;
         left: 3px;
         border-radius: 100%;
         -webkit-transition: all 0.2s ease;
         transition: all 0.2s ease;
       }

       [type="radio"]:not(:checked) + label:after {
         opacity: 0;
         -webkit-transform: scale(0);
         transform: scale(0);
       }

       [type="radio"]:checked + label:after {
         opacity: 1;
         -webkit-transform: scale(1);
         transform: scale(1);
       }

       p
       {
         color: #7B648C;
         font-size: 14px;
         letter-spacing: 0.02em;
         line-height: 125%;
         font-style: normal;
         font-weight: normal;
       }

       .tinput
       {
         width: 406px;
         height: 36px;
         font-size: 16px;
         padding-left:15px;
       }

       .enport-h
       {
         font-size: 16px;
         color: #43225B;
       }

       @media (max-width:500px){
            input{
                max-width:100% !important;
                width:100% !important;
            }
            label{
                font-size:14px;
                line-height:100% !important;
                p{
                    line-height:20px !important;
                }
            }
       }

       input::placeholder 
       {
          color: '#43225B';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.25px;
       }

`;

export const PlainBoxWrapper = styled.div`
    ${props=>props.disabledCard === "true" && css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;
export const HtmlBox = styled.div`
    max-height:400px;
    overflow:auto;
    img{
        max-width:100%;
    }
`;

export const Flag = styled.span`
    ${props => props.icon && css`
        background: url(${props.icon}) no-repeat center center;
        background-size: cover;
        height: 20px;
        width: 20px;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        top: 4px;
    `} 
`
export const UpgradeContainer = styled.div`
  background-color: #fbfbfc;
  width: 100%;
  height: 100%;
`;

export const UpgradePlanContainer = styled.div`
  background-color: #fbfbfc;  
  margin: 0;
  border-bottom: ${(props) => (props.noborder? "none" : "1px solid #ddd4e2")};

  @media only screen and (max-width: 729px) and (min-width: 270px) {
    margin: 0px !important;
  }
  
  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #43225b;
  }

  .upgrade-addons{
    font-size: 30px;
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      display: none !important;
    }
  }

  .upgrade-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #7b648c;
  }

  .upgrade-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
    margin: 0 0 5px;
  }

  .upgrade-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.15px;
    color: #43225b;
    text-transform: capitalize;
    &.purekeepErrorMsg{
      text-transform: initial;
    }
  }
  .upgrade-p4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 135%;
    color: #43225b;
    text-align: center;
  }
  .purekeepSuccessMsg{
    max-width:430px;
    font-size:18px;
    line-height: 1.6;
    .fsz-inherit{
      font-size:inherit;
    }
  }
  .purekeepErrorMsg{
    max-width:600px;
    font-size:18px;
    line-height: 1.6;
  }

  .upgrade-p5{
    font-size: 14px;
  }

  .upgrade-p4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    color: #43225b;
    a{
      font-size: inherit;
    }
  }
  .addon-cam{
    font-size: 22px;
  }
  .dropdown-monthly{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .dropdown-monthly{
    border-left: 2px solid #eee;
    height: 70px;
  }
  .no-border{
    border:none;
  }
  .upgrade-campaign{
    font-size: 24px;
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      font-size: 16px !important;
      margin-bottom: 5px;
    }
  }

  h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #43225b;
    text-align: center;
    @media only screen and (max-width: 497px){
      font-size: 26px;
      line-height: 32px;
    }
  }

  h4.upgrade-subhead {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #43225b;
    text-align: center;
    margin-top: 5px;
    @media only screen and (max-width: 497px){
      font-size: 14px;
    }
  }

  .line-items{
    flex-wrap: wrap;
    @media only screen and (max-width: 750px) {
      flex-direction: column;
      align-items: center;
      >div{
        margin-left: 0px;
      }
    }
    @media only screen and (max-width: 320px) {
      >div{
        width:230px;
      }
    }
  }
  strong{
    margin: 0;
  }
  .selectUsername{
    text-transform:lowercase;
}
  .singleSubscriptionLabel{
    padding: 8.5px 15px;
  }
  .subs-detail-box{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .connected-addons {
    list-style: none;
    padding: 0;
    line-height: 0px;

    li {
      font-size: 14px;
      line-height: 18px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.15px;
      color: #43225b !important;
      display: inline flow-root list-item;
    }
  }

  /* -- media query for all devices -- */

  /* Mobile Devices and Tablets*/

  @media only screen and (max-width: 950px) {
    
    &.align-left-mob{
      margin: 15px 15px 15px 0;
      .dropdown-row{
        align-items: start;
      }
    }
    margin: 15px;

    .plan-box-responsive {
      flex-direction: column;
      margin: 10px auto;
      justify-content: center;
      align-items: center;
    }
    
    .order-confirm-row {
      width: 100%;
      flex-direction: column;
      margin: 10px auto;
      justify-content: flex-start;
      align-items: flex-start;

      .upgrade-sum{
        @media only screen and (max-width: 497px) and (min-width: 270px) {
          width: 100%;
        }
      }
    }
    .dropdown-row{
      margin: 5px 0px !important;
    }

    .order-confirm-row{
      margin: 0 !important;
    }

    .upgrade-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
    }

  .upgrade-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.15px;
    color: #43225b;
    text-transform: capitalize;
  }

  .purekeepSuccessMsg, .purekeepErrorMsg{
    font-size:15px ;
  }
  .h-pbox{
    margin-top: 15px !important;
  }

  .plan-box-responsive {
      flex-direction: column;
      margin: 0 auto !important;
      justify-content: center;
      align-items: center;
    }

   /* li {
      list-style: none !important;
    } */
  }

  @media only screen and (max-width: 497px) and (min-width: 270px) {
    .upgrade-box{
      margin-top: 30px;
    }
  }

  /* -- media query for all devices -- */

  /* Mobile Devices */

  @media only screen and (max-width: 767px) and (min-width: 600px) {
    h2{
      font-size: 18px;
    }

    .upgrade-p1{
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 729px) and (min-width: 270px) {
    .dropdown-row {
      flex-direction: column;
      .dropdown-monthly{
        flex-direction: row;
        align-items: start;
        height: auto;
        margin-bottom: 5px;
        text-align: left;
        justify-content: start;
        padding: 0px !important;
        .upgrade-p2{
          position: relative;
          min-width: 120px;
          text-align: left !important;
          &::before{
            position: absolute;
            content: ':';
            left: auto;
            right: 5px;
            height: 2px;
            width: 2px;
          }
        }
        gap: 5px;
        p, li{
          margin: 0 !important;
        }
      }
    }

    .upgrade-p2 {
    text-align: center !important;
    }

    .dropdown-monthly {
      margin-top: 0px;
      border:none;
    }
    
    h2{
      font-size: 16px;
    }

    .upgrade-p1{
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 460px) and (min-width: 270px){
    .subs-detail-box .upgrade-p3{
      font-size:10px;
      text-align:center;
    }
  }
  


`;

export const UpgradePbox = styled.div`
  width: 283px;
  height:316px;
  border: 1px solid #ddd4e2;
  box-sizing: border-box;
  box-shadow:  ${(props) => (props.isActive ? "0px 4px 10px rgba(67, 34, 91, 0.15), 0px 10px 30px rgba(67, 34, 91, 0.2)" : "0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1)")};
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  padding: 0 30px 20px;
  background-color: ${(props) => (props.isActive ? "#43225B" : "white")};
  @media only screen and (max-width: 497px) and (min-width: 270px) {
    ${props => props.isActive && css`
      order: 1 !important; 
      margin: 10px 0 !important;
  `}
  ${props => !props.isActive && css`
      order: 2 !important; 
      margin: 10px 0 !important;
  `}
  }

  

  ${props => props.isMax && css`
    position: relative;
    height: 338px;
    margin-top: 41px !important;

    h3{
      color: #F7C24D;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }
  `}

  &.firstBox{
    margin: 0 1rem 0 0;
  }

  .pbox-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => (props.isActive ? "#43225B" : "#8e7a9d")};
    background: ${(props) => (props.isActive ? "#DCD3E9" : "#f6f4f7")};
    border: 1px solid #eee9f4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    height: auto;
    justify-content: center;
    vertical-align: middle;
    padding: 3px 0px;

    ${props => props.isMax && css`
      border-radius: 30px !important;
      border: 0.3px solid #DDD4E2 !important;
      opacity: 0.8;text-decoration-color
      background: ${(props) => (props.isActive ? "#C8B0D9" : "#f6f4f7")} !important;
      height: 23px; 
      width: max-content;
      padding: 3px 3px;
      margin: 0 auto;
      margin-bottom: 2px;
  `}
  }

  .pbox-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.15px;
    color: ${(props) => (props.isActive ? "white" : "#7B648C")};
    margin: 0;

    ${props => props.isMax && css`
      margin-top: 26px !important;
    `}
  }

  .pbox-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: line-through;
    text-decoration-color: ${(props) => (props.isActive ? "white" : "#7B648C")};;
    color: ${(props) => (props.isActive ? "white" : "#7B648C")};
    margin: 0;
    margin-top: 25px !important;
  }

  .pbox-h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    text-decoration: none;
    margin: 0;
    color: ${(props) => (props.isActive ? "white" : "#7B648C")};

    ${props => props.isMax && css`
      font-size: 50px !important;
   `}
  }

  .pbox-p4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 5px 14px;
    color: ${(props) => (props.isActive ? "white" : "#8E7A9D")};

    ${props => props.isMax && css`
      color: ${(props) => (props.isActive ? "#DDD4E2" : "#8E7A9D")};
   `}
  }

 .pbox-btn {
    width: 223px;
    height: 40px;
    font-family: Open Sans;
    background: #ffffff;
    border: ${(props) => props.isActive ? "1px solid #2CDC82" : "1px solid #DDD4E2"};
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    margin: 20px 0px;
    color: ${(props) => (props.isActive ? "white" : "#8E7A9D")};
    background-color: ${(props) => (props.isActive ? "#2CDC82" : "white")};

    ${props => props.isMax && css`
      color: ${(props) => (props.isActive ? "#43225B" : "#8E7A9D")};
   `}
  }

  @media only screen and (max-width: 966px) and (min-width: 751px) {
    height: 318px;

    .pbox-btn {
      font-size: 12px !important;
    }

    .pbox-h1{
     font-size: 28px !important;
   }

  .pbox-p2 {
    font-size: 18px;
  }

  .pbox-p3 {
    font-size: 11px;
  }

  .pbox-p4 {
    font-size: 11px;
  }
  }
  
  @media only screen and (max-width: 750px) and (min-width: 331px) {
    height: 318px;

   .pbox-btn{
    font-size: 14px !important;
    margin-top: 0 !important;
    margin-bottom: 5px !important;
   }

   .pbox-h1{
     font-size: 28px !important;
   }

  .pbox-p2 {
    font-size: 18px;
  }

  .pbox-p3 {
    font-size: 11px;
  }

  .pbox-p4 {
    font-size: 11px;
  }
  }

  @media only screen and (max-width: 330px) and (min-width: 270px) {
  width: 245px !important;
  height: 318px;

  .pbox-btn{
    width: 100% !important;
    
  }

  .pbox-h1{
    font-size: 28px !important;
  }

  .pbox-p2 {
    font-size: 18px;
  }

  .pbox-p3 {
    font-size: 11px;
  }

  .pbox-p4 {
    font-size: 11px;
  }
}
`;

export const ProductIcon = styled.div`
    content: '';
    background: url(${FlatIcons}) no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
    transform: scale(0.8);
    ${props => props.isPrivacy && css`
      background-position: -64px 0px !important;
    `}
    ${props => props.isKeep && css`
        background-position: -32px 0px !important;
    `}
    ${props => props.isEcrypt && css`
      background-position: 0px 0px !important;
    `}
    ${props => props.ml && css`
      :not(:first-child) {
        margin-left: ${props.ml};
      }
    `}
`;

export const FreeAddonModalContainer = styled.div`
  display: flex;
  gap: 18px;
  padding: 40px;

  @media only screen and (max-width: 320px) {
    flex-flow: row wrap;
    padding: 40px 0 0 0;
    p {
      padding-bottom: 20px;
    }
  }
`;

export const FreeAddonModalCard = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: -0.4px;
  text-align: center;

  h1 {
    font-size: 20px !important;
    margin-top: 50px;
    min-height: 45px;
  }
  p {
    margin-top: 12px !important;
    font-size: 14px !important;
  }
  div {
    width: 24px;
    height: 24px;
    transform: scale(3);
    align-self: center;
  }
  .purekeep {
      background: url(${ProductFullIcons}) no-repeat;
      background-position: -48px 0px;
  }
  .pureprivacy {
    background: url(${ProductFullIcons}) no-repeat;
    background-position: -96px 0px;
  }
  .purecrypt {
    background: url(${ProductFullIcons}) no-repeat;
    background-position: -72px 0px;
  }
`;

export const UpgradeOrderConfirm = styled.div`
  width: 596px;
  background: #f6f4f7;
  height: auto;
  border: 1px solid #ddd4e2;
  border-radius: 10px;

  .orderbox1 {
    border-bottom: 1px solid #ddd4e2;
  }

  .orderbox2 {
    border-bottom: 1px solid #ddd4e2;
  }

  .orderbox3 {
    border-bottom: 1px solid #ddd4e2;
  }

  .orderbox-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.5px;
    color: #43225b;
    margin: 2px;
  }

  .orderbox-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
  }

  .p-styled-span {
    margin-bottom: 10px;
    font-size: 12px !important;
    font-weight: 500;
  }

  .orderbox-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    color: #43225b;
    margin: 20px 0px;

    @media only screen and (max-width: 497px) {
      font-size: 16px;
    }
    .cart-amount{
      display: flex;
      justify-content: space-between;
      /* align-items: center; */
    }
  }

  .orderbox-p4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.15px;
    color: #43225b;
    margin-top: 30px 0px 40px 0px;
  }

  .orderbox-p6 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #43225b;
    margin: 50px 0px 20px 0px;
  }

  .orderbox-p5 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    color: #43225b;
    margin: 20px 0px;
    @media only screen and (max-width: 497px) {
      font-size: 16px;
    }
  }

  .orderbox-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
  }

  .order-confirmation-box {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
    flex-direction: column;
    gap: 10px;

    .orderbox-p3 {
      margin-bottom: 0px;
    }
  }

  .plan-items-box {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    .orderbox-p6 {
      margin: 0px;
    }
  }

  .plan-items {
    padding: 0px;
    list-style: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .plan-item {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .p-styled-span {
        margin-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    margin: 0 !important;

    .order-confirmation-box{
      padding: 0px 7px;
    }

    .orderbox1{
    /*  display: block; */
      padding: 0px 7px;
    }

    .orderbox2 {
      padding: 0px 7px;
    }

    .orderbox3 {
      padding: 0px 7px;
    }

    .pbox-btn{
    /*  display: block; */
      margin: 0px 10px;
    }

    .orderbox-p4 {
      font-size: 12px !important;
    }

    .orderbox-p6 {
      font-size: 12px !important;
    }

    .orderbox-p1 {
      text-align: left;
      justify-content: left;
    }

    .orderbox-p2 {
      font-size: 10px !important;
      margin-top: 7px;
    }

    .orderbox-p3 {
      font-size: 16px !important;
      text-align: left;
      justify-content: left;
    }

    .orderbox-p5 {
      font-size: 16px !important;
    }
  }

  @media only screen and (max-width: 497px) {
    padding: 0px 10px !important;
  }
`;

export const AddonImg = styled.span`

background: ${({theme:{onboarding}})=>onboarding.sidebar_onboarding_gradient_icons};
margin-top: 5px;
width: 24px;
height: 24px;
${({dashboardView}) => dashboardView && css`
    margin-top: 0;
    transform: scale(198%);
    transform-origin: 0px 8px;
    @media only screen and (max-width: 1700px) and (min-width: 1200px)  {
      transform: scale(148%);
      transform-origin: 0px 8px;
    }
`};
${({thankYouView}) => thankYouView && css`
    transform: scale(150%);
    display: inline-block !important;
`};
${({thankYouView}) => thankYouView && css`
    margin-top: 0;
    margin: auto;
    margin-bottom: 10px;
    transform-origin: 10px 8px;
`};
${({changePsw}) => changePsw && css`
    transform: scale(150%);
    @media only screen and (max-width:900px){
      margin: -2px 5px !important; 
      transform: scale(100%);
    }
`};
background-position: ${(props) => (props.addonName === "purevpn" && "-24px 0px !important;")};
background-position: ${(props) => (props.addonName === "purekeep" && "-48px 0px !important;")};
background-position: ${(props) => (props.addonName === "purecrypt" && "-72px 0px !important;")};
background-position: ${(props) => ((props.addonName === "pureprivacy" || props.addonName ===  "purescan") && "-96px 0px !important;")};
/* background-position: ${(props) => (props.addonName === "purecrypt" && "-72px 0px !important;")}; */
`

export const UpgradePInclude = styled.div`
  width: 253px;
  height: auto;

  @media only screen and (max-width: 497px) and (min-width: 270px) {
    width: 100%;
  }

  .pinclude-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    color: #43225b;
  }

  .pinclude-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd4e2;
    padding-bottom: 10px;
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      font-size: 14px;
    }
  }

  .pinclude-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #7b648c;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: none;
    padding-bottom: 10px;
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 570px) {
    margin-top: 20px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    height: auto;
  }

  .pinclude-p1{
    font-size: 16px;
  }
`;

export const DropdownDiv = styled.div`
  width: 270px;
  height: 40px;
  border: 1px solid #ddd4e2;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  letter-spacing: 0.15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 125%;
  color: #43225b;
  padding: 1px 2px;

  &:focus {
    outline: #ddd4e2;
  }

  select {
    width: 265px;
    height: 35px;
    border: 0px solid white;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    letter-spacing: 0.15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    color: #43225b;
    padding: 2px 12px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${caret}) no-repeat;
    /* transform: rotate(180deg); */ 
    background-position-x: 235px;
    background-position-y: 5px;
    cursor: pointer;
  }

  select:after{
    /* transform: rotate(180deg); */
  }

  select:focus {
    outline: none;
  }

  option {
    border: 1px solid #ddd4e2;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    letter-spacing: 0.15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    color: #43225b;
    margin-top: 15px;
    padding: 10px 5px;
    cursor: pointer;
    width: 180px;
  }

  option:hover {
    background-color: #43225b !important;
    color: white !important;
  }
`;

export const UpgradePDetail = styled.div`
  width: 596px;
  height: auto;

  .pdetail-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #43225b;
  }

  .pdetail-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.5px;
    color: #7b648c;
  }

  .pdetail-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #43225b;
  }
  ul{
    li{
      font-size: 16px !important;
    }
  }

  
  /* -- media query for all devices -- */

  /* Mobile Devices and Small Tablets */

  @media only screen and (max-width: 767px) {
    width: 100%;
   
    .faq-box{
      margin-top: 15px !important;
    }

      .pdetail-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
    color: #43225b;
  }

  .pdetail-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #7b648c;
  }

  .pdetail-p3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    color: #43225b;
  }

  ul{
    margin-top: 10px !important;
  }

  li{
    margin-top: 8px !important;
    /* list-style: none; */
    
  }
  }
  @media only screen and (max-width: 497px) and (min-width: 270px) {
    ul{
      li{
        font-size: 14px !important;
      }
    }
  }
`;

export const UpgradeFAQ = styled.div`
  background-color: #ffffff;
  border: 1px solid #ddd4e2;
  box-sizing: border-box;
  border-radius: 12px;

  .faq-p1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.15px;
    color: #43225b;
  }

  .faq-p2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #43225b;
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      font-size: 14px;
    }
  }


`;

export const UpgradeButton = styled.button`
  width: 223px;
  height: 40px;
  font-family: Open Sans;
  background: #550CCB;
  color: white;
  border: 1px solid #ddd4e2;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  ${({pointer})=>pointer && css`
      pointer-events:none;
  `}
  @media only screen and (max-width: 402px) {
    width: 160px;
    font-size: 11px !important;
  }
  &.order-summary{
    @media only screen and (max-width: 497px) and (min-width: 270px) {
      width: 100% !important;
      margin: 10px auto !important;
      font-size: 14px !important;
    }
  }
`;

export const UpgradeNA = styled.div`
  background-color: #fbfbfc;
  border-bottom: ${(props) => (props.noborder? "none" : "1px solid #ddd4e2")};

  h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    color: #43225b;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .upgradeErrorMsg {
    max-width: 600px;
    font-size: 16px;
    line-height: 1.6;
    font-weight: bold;
    color: #43225b;
  }
  .wireguard-p4{
    font-size: 14px;;
    color:#43225B;
    font-weight: 400;
  }
`;

export const WireGuardKeysCard = styled.div`
  padding: 0px 0px;
  /* background: #F7F6F9; */
  /* border-radius: 15px; */
  h3{
    color: #43225B;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  .copyButton{
    cursor: pointer;
  }
`;
export const NewTag = styled.span`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 9px!important;
    line-height: 125%;
    color: #fff!important;
    vertical-align: middle;
    background-color: #2EC4B6;
    padding: 1px 0;
    max-width: 29px;
    width: 100%;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin: 0 0 0 5px;
    ${props => props.isDedicatedServer && css`
        display:inline;
        padding:1px 3px;
        `}
    &.alpha{
      font-size: 10px !important;
      max-width: 36px;
      padding:1px;
    }
`;
export const CustomTabs = styled.div`
    width: 99%;
    margin:0 0 30px;
    border-bottom:1px solid #DDD4E2;
    ul{
        li{
            button{
                height: auto;
                position: relative;
                font-size: 12px;
                line-height: 125%;
                -webkit-letter-spacing: 0.02em;
                -moz-letter-spacing: 0.02em;
                -ms-letter-spacing: 0.02em;
                letter-spacing: 0.02em;
                color: #7B648C;
                border: 0;
                background: none;
                outline: 0;
                width: 100%;
                text-align: center;
                padding: 0 0 20px;
                font-size: 16px;
                &.active{
                    font-weight: 700;
                    :after{
                        position: absolute;
                        content: "";
                        background-color: #43225B;
                        height: 3px;
                        bottom: 0;
                        width: 100%;
                        left: 0;
                    }
                }
            }
          margin: 0 10px 0 0;
          display: inline-block;
          max-width: 250px;
          width: 100%;
        }
      
    }
    @media only screen and (max-width: 992px) {
      ul{
          li{
            max-width: 115px;
            width: 100%;
          }
      }
    }
`;
export const SuccessOrErrorInfoBox = styled.div`
  padding: 30px;
  background: #EEE9F4;
  border-radius: 15px;
  max-width: 760px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;

  .messageBox{
    display: flex;
    align-items:center;

    img {
      margin-right: 30px;

      @media only screen and (max-width: 767px) {
        margin-right: 10px;
        max-width: 40px;
      }
      @media only screen and (max-width: 992px) {
        max-width: 60px;
      }
    }

    .errorMsg{
      display: flex;
      align-items: left;
      flex-direction: column;

      .para{
        font-size: 20px !important;
        margin: 0px 0px 15px !important;
        max-width:600px;
        font-weight: bold;
        line-height: 27px;
        color: #43225B !important;

        @media only screen and (max-width: 992px) {
          font-size: 14px !important;
        }
      }
      button {
        max-width: 200px;
      }
    }

  }
  @media only screen and (max-width: 767px) {
    padding: 15px;
    .messageBox{
      // display: block;
    }
  }
`;
// On-Boarding CSS START
export const StepBarWrapper = styled.ul`

  &.desktop-view {
    display: flex;
    list-style: none;
    margin: 20px 0;
    padding-left: 0px;

    li {
      display: flex;
      max-width: 160px;
      text-align: center;
      flex-direction: column;
      position: relative;
  
      .text {
        margin-top: 5px;
        font-size: 12px !important;
        @media only screen and (max-width: 768px){
          margin-top: 10px;
        }
        @media only screen and (max-width: 480px){
          font-size: 10px !important;
        }
      }
  
      &:hover {
        cursor: pointer;
      }
  
      &:first-child .bar{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
  
      &:last-child .bar{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
  
      .bar {
        display: inline-block;
        background: #EEE9F4;
        width: 100%;
        height: 6px;
        @media only screen and (max-width: 768px){
          border-radius: 10px;
        }
      }
  
      &.completed, &.current {
        .bar {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &.completed {
        .bar {
          background: #2CDC82;
        }
      }
      &.current {
        .bar {
          background: #43225B;
        }
        .text {
          color: #43225B;
          font-weight: 700;
        }
      }
  
      &.last-step,
      &.media-object {
        // margin-right: 24px;
  
        .bar {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
  
      &.locked {
        .bar {
          opacity: 0.2;
        }
        .icon {
          opacity: 0.2;
        }
        .text {
          opacity: 0.2;
        }
        &:hover{
          cursor: default;
        }
      }
    }
  
    @media only screen and (max-width: 768px){
      align-items: stretch;
      justify-content: space-around;
    }
  }

  &.mobile-view {
    display: flex;
    width: 100%;
    list-style: none;
    margin: 20px 0;
    padding-left: 0px;
    line-height: 6px;
    font-size: x-small;

    li {
      background: #EEE9F4;
      height: 6px;
      display: inline-block;
      ${({stepsLength, isLocked}) => stepsLength > 0 && css`
        width: calc(100%/${stepsLength - 1});
      `}

      &:nth-child(1), &:nth-child(2){
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.current,
      &.completed {
        background: #43225B;
      }
      &.current {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  
`;
export const StepBarMobileController = styled.div`

  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 15px;

  .control-left, .control-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .step-name {
    font-weight: 700;
    font-size: 13px !important;
    color: #43225B !important;
  }

`
export const OnBoardingNavIcon = styled.span`

  display: inline-block;
  position: absolute;
  right: -24px;
  top: -9px;

  ${({width}) => width && css`
    width: ${width}px;
  `}
  ${({height}) => height && css`
    height: ${height}px;
  `}

  ${({icon}) => icon === "last-step" && css`
    background:${({theme:{onboarding}}) => onboarding.nav_last_step_icon};
  `}
  ${({icon, selected}) => icon === "last-step" && selected && css`
    background:${({theme:{onboarding}}) => onboarding.nav_last_step_active_icon};
  `}
  ${({icon}) => icon === "media-object" && css`
    background:${({theme:{onboarding}}) => onboarding.nav_media_object_icon};
  `}
  ${({icon, selected}) => icon === "media-object" && selected && css`
    background:${({theme:{onboarding}}) => onboarding.nav_media_object_active_medium_icon};
  `}
  ${({icon, completed}) => icon === "media-object" && completed && css`
    background:${({theme:{onboarding}}) => onboarding.nav_media_object_active_icon};
  `}

`

export const GridIconViewWrapper = styled.div`
  display: flex;
  width: 100%;

  .purposes {

    .heading {
      color: #43225B;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }

  .box {
    margin-bottom: 30px;
    .inner-box {
      border: 0.5px solid #DDD4E2;
      border-radius: 12px;
      text-align: center;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      height: 100%;

      &.active{
        box-shadow: 0px 2px 4px 1px #ddd4e2;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 4px 1px #ddd4e2;
      }

      .heading {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .para {
        font-size: 12px;
        margin-bottom: 0px;
      }

    }
    @media only screen and (min-width: 768px) and (max-width: 992px){
      max-width:33.33%;
    }
    @media only screen and (min-width: 375px) and (max-width: 767px){
      max-width:50%;
    }
    @media only screen and (max-width: 374px){
      max-width: 100%;
    }
    @media only screen and (min-width: 270px) and (max-width: 768px){
      // max-width:100%;
      .boxes {
        justify-content: center;
      }
    }
    @media only screen and (min-width: 992px) and (max-width: 1440px){
      max-width:235px;
    }
  }

`
export const GridIcon = styled.span`
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 15px;
  background:${({theme:{onboarding}}) => onboarding.grid_icons};
  
  ${({icon}) => icon === "streaming" && css`
    background-position: 0px 0px;
  `}
  
  ${({icon}) => icon === "security" && css`
    background-position: -60px 0px;
  `}
  
  ${({icon}) => icon === "download" && css`
    background-position: -120px 0px;
  `}
  
  ${({icon}) => icon === "dedicated_ip" && css`
    background-position: -180px 0px;
  `}
  
  ${({icon}) => icon === "portforwarding" && css`
    background-position: -240px 0px;
  `}
  
  ${({icon}) => icon === "crypto" && css`
    background-position: -300px 0px;
  `}
  
  ${({icon}) => icon === "other" && css`
    background-position: -360px 0px;
  `}
  ${({icon}) => icon === "stream_sports" && css`
    background-position: -420px 0px;
  `}

`

export const MediaObjectViewWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0px;
  background:${({theme:{onboarding}}) => onboarding.media_object_bg};

  & > div {
    @media only screen and (max-width: 768px){
      padding: 0px;
    }
  }

  .image-box {
    text-align: center;
    img {
      display: inline-block;
      max-width: 100%;
      margin: 0 auto;
    }

    @media only screen and (min-width: 992px){
      min-height: 508px;
    }
    
  }
  .box {
    border-radius: 12px;
    text-align: left;
    display: flex;
    flex-direction: column;

    .heading {
      font-size: 30px;
      margin-bottom: 25px;
    }

    .para {
      font-size: 14px;
      margin-bottom: 0px;
      max-width: 370px;
      line-height: 20px;
      color: #7B648C !important;
    }

    .bold {
      font-weight: 700;
      color: #43225B !important;
      font-size: 12px !important;
      margin-bottom: 40px;

      &.opacity-low{
        opacity: 0;
      }
    }

    .button-inner-box {
      display: flex;
      width: 100%;
      margin-top: 30px;

      button {
        color: #8E7A9D;
        text-decoration: underline;
        &::hover {
          color: #7a6c85;
        }
      }
      .DOtext{
        font-size:16px !important;
        font-weight:bold;
        color:#7B648C !important;
      }
    }

    .button-box {
      display: flex;
      width: 100%;
      margin-top: 30px;

      button:first-child {
        margin-right: 30px;
      }

      .btn-next {
        padding: 11px 20px;
      }
      
      /* tooltipp container */
      .tooltipp {
        position: relative;
        display: inline-block;
        /* border-bottom: 1px dotted black; */
        /* If you want dots under the hoverable text */
      }

      /* tooltipp text */
      .tooltipp .tooltipptext {
        visibility: hidden;
        width: 180px;
        background-color: #fff;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltipp text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -90px;

        /* Fade in tooltipp */
        opacity: 0;
        transition: opacity 0.3s;
        p{
          font-size: 16px !important;
          font-weight: 700 !important;
        }
        border: 1px solid #ECE7FD;
        /* Mobile/Shadow/Light/1 */
        box-shadow: 0px 12px 24px rgba(59, 16, 142, 0.07);
      }

      /* tooltipp arrow */
      .tooltipp .tooltipptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }

      /* Show the tooltipp text when you mouse over the tooltipp container */
      .myclass .tooltipptext {
        visibility: visible;
        opacity: 1;
      }

      .btn-skip {
        color: #8E7A9D;
      }
      .btn-modal{
        color:#0D85E8 !important;
      }
    }

    .facing-issues-wrapper {
      display: flex;
      flex-align: start;
      position: relative;

      button {
        font-size: 14px !important;
        font-weight: 400;
        position: relative;
        margin-top: 30px;

        &:hover .tooltip-box {
          display: block;
        }

        .tooltip-box {
          display: none;
          padding: 15px 14px;
          position: absolute;
          bottom: -90px;
          min-width: 195px;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #DCD3E9;
          border-radius: 8px;

          p {
            font-size: 12px !important;
            font-weight: 700;
          }

          &:after {
            content: "";
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #DCD3E9;
          }
        }
      }
    }
  }

`

export const DiscoverViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .all-on-boardings {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:20px;
    @media only screen and (max-width: 992px){
      flex-direction:column;
    }
  }
  
  .top-heading {
    .heading {
      font-size: 30px;
      margin-bottom: 25px;
    }
  }
`

export const GroupListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  
  .heading {
    font-size: 30px;
    max-width: 600px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .group-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 0px;
    list-style: none;
    counter-reset: item;

    .item {
      display: flex;
      flex-direction: column;
      padding: 30px 60px 30px 95px;
      margin-bottom: 10px;
      max-width: 640px;
      border: 0.5px solid #DDD4E2;
      border-radius: 8px;
      counter-increment: item;
      position: relative;

      ::before {
        font-size: 60px;
        font-weight: 700;
        content: counter(item);
        color: #EEE9F4;
        width: 35px;
        text-align: center;
        display: inline-block;
        position: absolute;
        left: 30px;
        top: 0px;
        bottom: 0px;
        line-height: 200%;    
      }
    }
  }
  
  .sub-heading {
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  
  .para {
    color: #7B648C !important;
    margin-bottom: 0px;
  }

`

export const AppFancyBar = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 300px;
  margin-bottom: 20px;
  position: relative;

  .box {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    border-radius: 8px;
    min-height: 60px;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 30px;
    overflow: hidden;

    .content {
      max-width: 420px;
      z-index: 1;
      text-align:center;
      min-height:100px;
      @media only screen and (max-width: 1400px){
        min-height:150px;
      }
    }

    .heading {
      font-size: 18px !important;
      font-weight: 600;
      color: #001219;
      margin-bottom: 10px;
      margin-top: 0px;
    }

    .para {
      margin-bottom: 15px !important;
      font-size: 14px !important;
      color: #637381 !important;
    }

    .btn.btn-primary{
      padding: 12px 24px;
      background: #6536CC;
      border-radius: 100px;
      font-size: 14px;
      min-width: 105px;
    }
  }

  .app-icon {
    position: relative;
    
    &.purevpn {
      background: linear-gradient(180deg, rgba(122, 67, 238, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 8px;
    }
    &.purekeep {
      background: linear-gradient(180deg, rgba(186, 236, 232, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 6px;
    }
    &.pureencrypt,
    &.purecrypt {
      background: linear-gradient(180deg, rgba(67, 97, 238, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 8px;
    }
    &.purescan,
    &.pureprivacy {
      background: linear-gradient(180deg, rgba(255, 177, 10, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 8px;
    }
  }
  }
`
export const DiscoverMoreBanner= styled.div`
  display:flex;
  width: 100%;
  padding-top: 30px;
  flex-direction:row;
  border-radius:16px;
  background-image:url(${DiscoverMoreFamilyPlanBanner})!important;
  min-height: 300px;
  background-size: cover;
  background-position: center;

  .DMBanner{
    display: flex;
    width:100%;
    flex-direction: row;
    padding: 0px 20px;
    border-radius: 8px;
    min-height: 60px;
    gap: 30px;
    padding: 30px;
    justify-content:space-between;
    @media only screen and (max-width: 768px){
      flex-direction: column-reverse;
      text-align:center;
    }

    .content {
      max-width: 630px;
      z-index: 1;
      text-align:left;
      @media only screen and (max-width: 768px){
        text-align:center;
      }
      .heading {
        font-weight: 700;
        font-size: 30px !important;
        line-height: 41px;
        color: #000000;
      }
  
      .para {
        font-weight: 400;
        font-size: 16px !important;
        line-height: 125%;
        letter-spacing: 0.25px;
        color: #000000 !important;
        margin-bottom: 15px !important;
      }
  
      .btn.btn-primary{
        padding: 12px 24px;
        background: #6536CC;
        border-radius: 100px;
        font-size: 16px;
        font-weight: 600;
        color: #FCFCFC;
      }
    }
    .fpimg{
      padding-right: 100px;
      @media only screen and (max-width: 1100px){
        padding-right:50px;
      }
      @media only screen and (max-width: 480px){
        padding-right:0px;
        img{
          width:200px;
        }
      }
    }
  }  
`;

export const DownloadViewWrapper = styled.div`
  width: 100%;
  padding-top: 30px;
`;

export const SignupViewWrapper = styled.div`
  width: 100%;
  padding-top: 15px;

  & > div {
    @media only screen and (max-width: 768px){
      padding: 0px;
    }
  }

  &.hasBG {
    position: relative;

    .sidebar-content {
      img {
        position: absolute;
        top: 0px;
        right: -40px;

        @media only screen and (max-width: 992px){
          width: 46vw;
        }
      }
    }
  }

  &.purekeep-signup-view {
    padding-top: 0px;
    flex-direction: column;
  }

  .dropdown-wrapper{
    display: flex;
    max-width: 200px;
    margin-bottom: 20px;
  }

  .content {
    .heading {
      font-size: 36px;
      margin-bottom:15px;
    }
    .header2 {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom:15px;
    }
    .heading3 {
      font-size: 14px;
      font-weight: 700;
      color: #43225B;
    }
    .para {
      font-size: 14px !important;
      max-width: 620px;
      color: #7B648C !important;
      margin-bottom: 20px !important;
    }
    .list-item {
      .para {
        margin-bottom: 15px !important;
      }
    }
    a {
      display: inline-block;
      font-weight: 400;
    }
    .button-wrapper {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;

      button {
        padding: 13px 20px;
        border-radius: 5px;

        &.learn-more-button {
          background: #FFFFFF;
          border: 1px solid #DDD4E2;
          border-radius: 5px;
          color: #8E7A9D;
          margin-left: 10px;
        }
      }
    }
  }
`;


export const NewSignupViewWrapper = styled.div`

  &.content {
    align-items: center;
    flex-direction: column; // temp
  }

  .heading {
    text-align: center;
    font-size: 30px !important;
    margin-bottom: 20px !important;
  }

  & > section {
    padding: 45px 0px;
  }

  .section-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;

    .logo {
      max-width: 295px;
      margin-bottom: 15px;
    }
    .para {
      font-size: 18px !important;
      margin-bottom: 0px !important;
      text-align: center;
      font-weight: 600;
    }
  }

  .grids {
    margin-top: 45px;
    .grid {
      margin-bottom: 15px;
  
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        background-color: #fff;
        border: 0.5px solid #DDD4E2;
        box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
        border-radius: 12px;
        height: 100%;
      }
  
      .icon {
        margin-bottom: 30px;
      }
  
      h4 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
        text-align: center;
        max-width: 253px;
        color: #43225B;
      }
  
      p {
        text-align: center;
        max-width: 250px;
      }
    }
  }

  .button-wrapper {
    display: flex  !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
    margin-top: 30px !important;
    margin-bottom: 0px !important;

    .primary-btn {
      margin-right: 10px;
    }

    .secondary-btn {
      background: #FFFFFF;
      border: 1px solid #DDD4E2;
      border-radius: 5px;
      color: #8E7A9D;
    }
  }

  .section-three {
    justify-content: center;

    .side-by-side-warpper {
      max-width: 910px;

      & > div:first-child {
        background: #F6F4F7;
        padding: 30px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      .heading {
        display: flex;
        flex-direction: column;
        font-size: 24px !important;
        align-items: flex-end;
        margin-bottom: 0px !important;
        b {
          font-size: 72px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 72px;

          @media only screen and (max-width: 768px){
            font-size: 36px;
            line-height: 36px;
          }
        }

        @media only screen and (max-width: 768px){
          align-items: center;
        }
      }

      & > div:last-child {
        background: #FFFFFF;
        padding: 30px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        .heading {
          display: flex;
          flex-direction: column;
          font-weight: 700;
          font-size: 12px !important;
          align-items: flex-start;
          margin-top: 10px;
          margin-bottom: 10px !important;      
        }

        ul {
          display: flex;
          padding: 0;
          margin-bottom: 0px;
          flex-wrap: wrap;

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            width: 46%;
            height: 40px;
            margin-right: 15px;

            span {
              color: #43225B !important;
            }

            span:not(.coming-soon) {
              padding: 4.5px 4.5px 4.5px 34px;
            }

            border-bottom: 1px solid rgba(198, 209, 221, 0.6);

            &:nth-child(5),&:nth-child(6){
              border-bottom: none;
            }

            &:nth-child(2),&:nth-child(4),&:nth-child(6){
              margin-right: 0px;
              width: 49%;
            }

            @media only screen and (max-width: 400px){
              width: 100% !important;
              border-bottom: 1px solid rgba(198, 209, 221, 0.6) !important;
            }
          }

          @media only screen and (max-width: 400px){
            flex-wrap: nowrap;
            flex-direction: column;
          }
        }
      }
      

    }
    
  }

  .section-two {
    justify-content: center;

    &.variant-2{
      padding: 30px 0px !important;
      text-align: center;
      .heading {
        margin-bottom: 10px !important;
      }
      .para {
        font-size: 18px !important;
        color: #7B648C  !important;
        font-weight: 600 !important;
        margin-bottom: 45px !important;
      }

      .button-wrapper {
        margin-top: 40px !important;

        button {
          margin-right: 0px;
        }
      }


      @media only screen and (min-width: 1100px){
        table {
          min-width: 912px;
        }
      }
    }
  }

  .section-four {
    align-items: center;
    text-align: center;
    align-self: stretch;
    justify-content: center;
    @media only screen and (max-width: 768px){
      margin: 0px 0px;
    }
  }

  .section-five {
    align-items: center;
    text-align: center;
    align-self: stretch;
    justify-content: center;

    &.variant-2{
      padding: 30px 0px !important;
      .content {
        width: 100%;
        max-width: 922px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
      }
      .heading {
        font-size: 36px !important;
        margin-bottom: 10px !important;
        @media only screen and (max-width: 400px){
          max-width:350px;
        }
      }
      .para {
        font-size: 18px !important;
        color: #7B648C  !important;
        font-weight: 600 !important;
        margin-bottom: 0px !important;
      }
      .pureprivacy-para{
        font-weight: 700;
        font-size: 24px !important;
        margin-top:50px;
        text-align: center;
        color: #43225B;
      }
      .reclaim-section{
        display:flex;
        width:100%;
        @media only screen and (max-width: 768px){
          flex-direction:column !important;

          .reclaim-text-box{
            display:flex;
            justify-content:center;
            .reclaim-text{
              text-align:center;
              margin-top:20px;
            }
          }
        }

        .reclaim-text{
          text-align:left;
          margin-top:90px;

          @media only screen and (max-width: 400px){
            text-align:center !important;
          }
          h3{
            color:#43225B !important;
            margin-top:30px;
            font-size:22px;
          }
          p{
            max-width:280px;
            font-size: 13px !important;
            color: #7B648C !important;
            margin-top:20px;
          }
          @media only screen and (max-width: 400px){
            flex-direction:column !important;
          }
        }
        
      }
      
      .grids{
        @media only screen and (min-width: 1100px){
          min-width: 942px;
        }
        .grid{
          margin-bottom: 0px;
          @media only screen and (max-width: 768px){
            margin-bottom: 15px;
          }

          .box {
            padding: 0px;
            .icon {
              margin-bottom: 15px !important;
            }
            h4 {
              font-size: 16px !important;
              font-weight: 700 !important;
              margin-bottom: 10px;
            }
            p {
              font-weight: 400;
              font-size: 14px !important;
            }
          }
        }
      }
    }

    .grids {
      .grid{
        .box {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
        .icon {
          margin-bottom: 0px;
          max-width: 100%;
        }
      }
    }
  }

  .faqs {
    justify-content: center;

    .content {
      max-width: 912px;
    }

    .heading {
      display: flex;
      flex-direction: column;
      font-size: 18px !important;
      font-weight: 600 !important;
      color: rgb(0, 18, 25)  !important;
      margin-bottom: 30px !important;

      span {
        font-size: 36px !important;
        font-weight: 700 !important;
        color: rgb(0, 18, 25)  !important;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 10px;
      background: #FFFFFF;
      border: 1px solid #DDD4E2;
      border-radius: 12px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      &.open {
        .icon {
          cursor: pointer;
          &:before{ transform: rotate(90deg); }
          &:after{ transform: rotate(180deg); }
        }
        .answer {
          display: flex;
          a{
            cursor:pointer;
            text-decoration:underline;
            font-size:14px !important;
          }
        }
      }

      .question {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:hover {
          cursor: pointer;
        }

        p {
          font-weight: 700;
          font-size: 16px !important;
          line-height: 125%;
          color: rgb(0, 18, 25) !important;
          margin-bottom:0;
          max-width:770px;
        }

        .icon {
          position: relative;
          width: 20px;
          height: 20px;
          flex-shrink: 0;

          &:before,
          &:after{
            content: "";
            position: absolute;
            background-color: #8A8398;
            transition: transform 0.25s ease-out;
          }

          &:before{
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
          }

          &:after{
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
          }
          
          &:hover{
            cursor: pointer;
          }
        }
      }

      .answer {
        display: none;
        margin-top: 24px;

        p {
          font-weight: 400;
          font-size: 14px !important;
          color: rgb(99, 115, 129)  !important;
          margin-bottom:0;
        }
      }
    }
  }

  .section-six {
    justify-content: center;

    &.variant-2 {
      .heading {
        display: flex;
        flex-direction: column;
        font-size: 18px !important;
        font-weight: 600 !important;
        color: #43225B  !important;
        margin-bottom: 30px !important;

        span {
          font-size: 36px !important;
          font-weight: 700 !important;
          color: #43225B  !important;
        }
      }
    }

    .faqs-wrapper {
      max-width: 850px;

      p {
        margin-bottom: 1rem !important;
      }
    }
  }

  .section-seven {
    color: #fff !important;
    border-radius: 12px;
    padding: 0px 0px 45px;

    &.variant-2 {
      padding: 30px 0px !important;
      padding-top: 0px !important;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: row;
      width: 100%;
    }

    .bg {
      width: 100%;
      max-width: 912px;
      background-color: ${props => props.bgColor === "purekeep" ? "#4DD5C9" : props.bgColor === "pureprivacy" ? "#F7AF13" : props.bgColor === "pureencrypt" ? "#4361EF" : "none"}  !important;
      background: url(${(props) => (props.bgimg)}) no-repeat;
      background-position: center;
      border-radius: 12px;
      display: flex;
      justify-content: center;


      @media only screen and (min-width: 480px){
        background-size: 100% 100%;
        padding: 20px 10px 40px;
      }
      @media only screen and (min-width: 768px){
        padding: 60px 0px;
      }
      @media only screen and (max-width: 560px){
        padding: 20px 20px 40px;
        background-size: cover;
      }
    }

    .content {
      button {
        margin-right: 0px;
        margin-bottom: 35px !important;
      }
      .button-white{
        background-color: #fFFFFF;
        color: #43225B;
      }
      .t-white{
        color:#FFFFFF !important; 
      }
      .banner-text {
        max-width:550px;
        font-size:14px !important;
      }
    }

    .logo{
      margin-bottom: 10px;
    }

    .heading{
      font-size: 36px !important;
      margin-bottom: 10px !important;
    }
    

    .para{
      font-size: 18px !important;
      font-weight: 600;
      margin-bottom: 25px !important;
      max-width: ${props => props.pureprivacy === true  ? "900px" : "560px"};
      color: #361B49  !important;
    }

    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      list-style: none;

      li {
        padding:${props => props.pureprivacy === true  ? "0px 25px" : "0px 10px"};
        border-left: 1px solid #001219;
        color: #361B49 !important;;
        font-size: 16px !important;
        line-height: 20px;
        font-weight: 600px !important;
        &:first-child {
          border-left: none;
        }
      }

    }

    .social-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      list-style: none;
      gap: 22px;
      @media only screen and (max-width: 570px) and (min-width: 270px) {
        gap:10px;
    }

      li {
        width: 25px;
        height: 23px;
        position: relative;
        background: url(${pureprivacySocialIcons}) no-repeat;
        &.facebook {
          background-position: -3px -4px;
        }
        &.twitter {
          background-position: -47px -4px;
        }
        &.instagram {
          background-position: -93px -4px;
        }
        &.linkdin {
          background-position: -138px -4px;
        }
        &.spotify {
          background-position: -182px -4px;
        }
        &.google {
          background-position: -228px -4px;
        }
        &.youtube {
          background-position: -272px -4px;
        }
        &.tiktok {
          background-position: -320px -4px;
        }

        &.waiting:after {
          background: url(${pureprivacySocialIcons}) no-repeat;
          background-position: -325px -34px;
          content: '';
          position: absolute;
          width: 10px;
          height: 11px;
          bottom: -20px;
          left: 5px;
        }
      }
    }
  }

  .section-eight {

    &.variant-2 {
      padding: 30px 0px;
    }

    .heading {
      font-size: 14px !important;
      font-weight: 400;
      color: #7B648C !important;
      margin-bottom: 0px !important;
    }
    .headingSecondary {
      font-size: 30px !important;
      font-weight: 400;
      color: #43225B !important;
      margin-bottom: 10px !important;
      text-align:center;
      font-weight:700;
      line-height:70px;
    }

    .button-wrapper {
      margin-top: 10px !important;
      gap: 20px;

      button {
        padding: 0px;    
      }
      .button2{
        padding:13px 20px;
      }
      .secondary-btn{
        border:none;
        background-color:transparent;
      }
      @media only screen and (max-width: 560px){
        flex-direction:column !important;
      }
    }
  }

  .section-nine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 768px){
      padding: 20px 15px;
    }

    .heading {
      max-width: 500px;
      margin-bottom: 45px !important;
    }

    .content {
      max-width: 912px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .accordion-item.open .accordion-item-content{
      height: 7em;
      @media only screen and (max-width: 570px){
        height: ${props => props.pureprivacy === true  ? "11em" : "7em"};
      }
    }

    .accordion-item-content,
    .accordion-item .accordion-item-content {
        height: 0;
        overflow: hidden;
        transition: height .25s;
    }
    
    .accordion {
      padding: 40px 0px;
      .accordion-item {
        border-radius: 0px !important;
        border: none !important;
        border-left: 3px solid #43225B !important;
        background-color: transparent !important;
        padding: 1em;
        margin-bottom: 30px;
        padding: 0px !important;
        padding-left: 27px !important;

        &:hover {
          cursor: pointer;
        }
  
        h3 {
          font-size: 20px !important;
          font-weight: 700;
          color: #43225B !important;
          margin-bottom: 0px !important;
          padding: 3px 0px;
        }
        p {
          font-size: 14px !important;
          font-weight: 400;
          color: #7B648C !important;
          margin-top: 10px !important;
          margin-bottom: 0px !important;
        }

        &:last-child{ 
          margin-bottom: 0px;
        }
  
      }
    }

    .items {

      @media only screen and (min-width: 768px){
        padding-left: 0px;
      }
    }

    .bg {
      width: 100%;
      background-color: ${props => props.accordionbgColour === "keep" ? "#E6F5F5" : props.accordionbgColour === "privacy" ? "none" : props.accordionbgColour === "encrypt" ? "none" : "none"}  !important;
      background: url(${(props) => (props.accordionimg)}) no-repeat;
      background-position: center;
      border-radius: 12px;
      display: flex;
      height: 100%;
      justify-content: center;
      background-size:${props => props.nocover === true ? "none" : "cover"};
    }
    .right-side {

      @media only screen and (max-width: 768px){
        display: none;
      }
    }
  }

  .pricing-table {
    display: flex;
    align-items: center;
    text-align: center;
    align-self: stretch;
    justify-content: center;

    padding: 20px 0px;

    table {
      border: none;
      border-radius: 12px;
      background: rgba(249, 248, 250, 0.5);
      border-collapse: separate;
      border-spacing: 0px;
      border: 1px solid #DDD4E2;
    }

    tbody > tr:last-child {
      border-bottom: none;
    }

    th {
      padding-top: 20px;
    }

    th, td {
      max-width: 275px;
      min-width: 275px;

      @media only screen and (max-width: 992px){
        max-width: 200px;
        min-width: 200px;
      }

      @media only screen and (max-width: 768px){
        max-width: 180px;
        min-width: 180px;
      }

      @media only screen and (max-width: 550px){
        max-width: 90px;
        min-width: 90px;
      }
    }
    
    tr > th:first-child{
      padding-left: 30px;

      @media only screen and (max-width: 550px){
        padding-left: 10px;
      }
    }

    tr > th:first-child, tr > td:first-child {
      text-align: left;
    }

    tr > th:nth-child(2), tr > td:nth-child(2) {
      background: #43225B;
    }

    th {
      padding: 36px 0px 10px 0px;
      font-size: 18px !important;
      font-weight: 600;
      color: #43225B !important;

      &.text {
        color: #FCFCFD !important;
      }

      @media only screen and (max-width: 550px){
        font-size: 14px !important;
      }
    }

    tr > th:nth-child(2) {
      img {
        @media only screen and (max-width: 550px){
          max-width: 100%
        }
      }

      @media only screen and (max-width: 550px){
        padding: 0px 10px;
      }
    }

    td {
      padding: 10px 30px;
      color: #43225B !important;
      font-size: 14px !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      @media only screen and (max-width: 550px){
        font-size: 12px !important;
        padding: 10px 10px;
      }
    }

    tbody > tr > td:nth-child(2) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    tr > th:nth-child(2){
      position: relative;
    }

    tr > th:nth-child(2)::before{
      position: absolute;
      content: "";
      width: 100%;
      height: 15px;
      background: #43225B;
      top: -15px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      left: 0;
    }

    tbody > tr:last-child > td {
      position: relative;
    }

    tbody > tr:last-child > td:nth-child(2)::before{
      position: absolute;
      content: "";
      width: 100%;
      height: 15px;
      background: #43225B;
      bottom: -15px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      left: 0;
    }

    tbody > tr:last-child > td {
      border-bottom: none;
    }
    
  }

  .video-wrapper {
    max-width: 720px;
    background-color: #43225B;
    color: #fff !important;
    border-radius: 12px;
    padding: 0px;

    .video-bg {
      background: url(${purekeepVideoSecionBG}) no-repeat;
      background-position: center;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      
      iframe {
        width: 100%;
        height: 405px;
        border-radius: 12px;

        @media only screen and (max-width: 550px){
          height: 245px;
        }
      }
    }

    .video-content {
      height: 405px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      .heading{
        font-size: 24px !important;
        margin-bottom: 10px !important;
      }

      p {
        color: #fff !important;
        font-size: 16px !important;
        margin-bottom: 30px !important;
        max-width: 350px;
      }

      .secondary-btn {
        padding: 11px 20px;
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #DDD4E2;
        color: #8E7A9D;
      }

      @media only screen and (max-width: 550px){
        height: 250px;
      }
    }
  }


  .device-icon{
    position: relative;
  }
  .device-icon::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    background: url(${devices}) no-repeat;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
  }

  .icon-mac::before {
    background-position: 0px 0px;
  }

  .icon-windows::before {
    background-position: -24px 0px;
  }

  .icon-web::before {
    background-position: -288px 0px;
  }

  .icon-ios::before {
    background-position: -120px 0px;
  }

  .icon-android::before {
    background-position: -72px 0px;
  }

  .coming-soon {

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    align-self: stretch;
  
    i {
      background: #E5FBF0;
      border-radius: 4px;
      padding: 0px 4px;
      font-size: 8px !important;
      line-height: 14px;
      font-style: normal;
      text-transform: uppercase;
    }

  }

`

export const OnBoardingLoadingTop = styled.div`
    display: flex;
    with: 100%;
    flex-direction: row;

    .icon {
      margin-right: 10px;
      img {
        max-width: 100%;
        margin-top: 8px;
      }
    }
    .inner-box {
      display: flex;
      with: 100%;
      flex-direction: column;

      .heading {
        font-size: 24px;
      }
      .para {
        font-size: 14px;
      }
    }
`;

export const OnBoardingLoadingCenter = styled.div`
  text-align: center;
  max-width:500px;
  margin:auto;

  .loader_cogs {
    z-index: -2;
    width: 100px;
    height: 200px;
    top: -120px !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scale(0.6);


  .loader_cogs__top {
      position: relative;
      width: 100px;
      height: 100px;
      transform-origin: 50px 50px;
      -webkit-animation: rotate 10s infinite linear;
      animation: rotate 10s infinite linear;

      div:nth-of-type(1) {
        transform: rotate(30deg);
      }
      div:nth-of-type(2) {
        transform: rotate(60deg);
      }
      div:nth-of-type(3) {
        transform: rotate(90deg);
      }
      div.top_part {
        width: 100px;
        border-radius: 10px;
        position: absolute;
        height: 100px;
        background: #43225B;
      }
      div.top_hole {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: white;
        position: absolute;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }


    .loader_cogs__left {
      position: relative;
      width: 80px;
      transform: rotate(16deg);
      top: 28px;
      transform-origin: 40px 40px;
      animation: rotate_left 10s 0.1s infinite reverse linear;
      left: -24px;
      height: 80px;

      div:nth-of-type(1) {
        transform: rotate(30deg);
      }
      div:nth-of-type(2) {
        transform: rotate(60deg);
      }
      div:nth-of-type(3) {
        transform: rotate(90deg);
      }
      div.left_part {
        width: 80px;
        border-radius: 6px;
        position: absolute;
        height: 80px;
        background: #7B648C;
      }
      div.left_hole {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: white;
        position: absolute;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .loader_cogs__bottom {
      position: relative;
      width: 60px;
      top: -65px;
      transform-origin: 30px 30px;
      -webkit-animation: rotate_left 10.2s 0.4s infinite linear;
              animation: rotate_left 10.2s 0.4s infinite linear;
      transform: rotate(4deg);
      left: 79px;
      height: 60px;

      div:nth-of-type(1) {
        transform: rotate(30deg);
      }
      div:nth-of-type(2) {
        transform: rotate(60deg);
      }
      div:nth-of-type(3) {
        transform: rotate(90deg);
      }
      div.bottom_part {
        width: 60px;
        border-radius: 5px;
        position: absolute;
        height: 60px;
        background: #361B49;
      }
      div.bottom_hole {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: white;
        position: absolute;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  
  /* Animations */
  @-webkit-keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate_left {
    from {
      transform: rotate(16deg);
    }
    to {
      transform: rotate(376deg);
    }
  }
  @keyframes rotate_left {
    from {
      transform: rotate(16deg);
    }
    to {
      transform: rotate(376deg);
    }
  }
  @-webkit-keyframes rotate_right {
    from {
      transform: rotate(4deg);
    }
    to {
      transform: rotate(364deg);
    }
  }
  @keyframes rotate_right {
    from {
      transform: rotate(4deg);
    }
    to {
      transform: rotate(364deg);
    }
  }

  .heading {
    font-size: 36px;
    color: #43225B;
    display: inline-block;
    margin-top: 130px !important;
  }
  .para {
    font-size: 18px;
    color: #7B648C;
  }
`;
// On-Boarding CSS END

const imageSkeletonLoading = keyframes`
 to {
    background-position: 793px 0, 0 0, 0 0, 0 0;
  }
`
export const LazyImg = styled.img`
  animation: ${imageSkeletonLoading} 2s ease-in infinite;
  background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-position: -793px 0, 0 0, 0 0, 0 0;
  background-size: 793px 250px;
  &.loaded{
    background:none;
  }
`

export const LazyIframe = styled.iframe`
  animation: ${imageSkeletonLoading} 2s ease-in infinite;
  background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-position: -793px 0, 0 0, 0 0, 0 0;
  background-size: 793px 550px;
  &.loaded{
    background:none;
  }
`
export const FamilyPlanCampaign = styled.div`  
  img {
    cursor: pointer;
    content:url(${FamilyPlanBanner_lg});    
    @media only screen and (max-width: 998px) and (min-width:600px) {
      content:url(${FamilyPlanBanner_md});    
    }
    @media only screen and (max-width: 600px) {
      content:url(${FamilyPlanBanner_sm});    
    }
  } 
`
export const FamilyPlanWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  .heading{
    max-width:769px;
    width:100%;
    font-size: 32px;
    color: #43225B;
    display: block;
    margin: 0 auto;
  }
  .para{
    max-width:698px;
    width:100%;
    display: block;
    margin: 10px auto 30px;
  }
  .fs-18{
    font-size:18px;
    font-weight:600;
  }

  .family_mambers_detail{
    h3{
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      text-align: center;
      color: #001219;
      margin-bottom: 10px;

      @media only screen and (max-width: 1280px){
        font-size: 28px;
        line-height: 36px;
      }

      @media only screen and (max-width: 600px){
        font-size: 20px !important;
        line-height: 24px;
      }
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #637381;
      margin-top: 0;
      margin-bottom: 30px;

      @media only screen and (max-width: 1280px){
        font-size: 16px;
      }
      @media only screen and (max-width: 600px){
          font-size: 12px;
          margin-bottom: 20px;
      }
    }

    .add-member{
      font-weight: 600;
      text-decoration: underline;
      color: #43225B;
      margin-top: -20px;
      font-size: 14px;
      cursor: pointer;
    }

    .icon{
      background-image: url(${member_detail_icons});
      width: 61px;
      height:60px;
      display:block;
      &.user-icon{
        background-position:0 0;
      }
      &.plus-icon{
        background-position: -129px 0;
        cursor: pointer;
      }
      &.hasChildUser{
        background-position: -65px 0;
      }
      
      

    }
  }
  .features_section{
    .icon{
      background-image: url(${family_plan_features_icons});
      width: 60px;
      height:60px;
      display:block;
      &.devices{
        background-position:0 0;
      }
      &.credentials{
        background-position:-60px 0;
      }
      &.stream{
        background-position:-120px 0;
      }
      &.price{
        background-position:-180px 0;
      }
    }
  }
  .packages{
    padding:30px 0 60px;
  }
  .features_section {
    padding: 60px 0;
  }
  .family_mambers_detail{
    padding: 0 0 60px;
  }
  .faq_section{
    .faq-p1{
      font-size:18px;
    }
    .faq-p2{
      font-size:16px;
    }
  }
  .errorBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:300px;
  }
  .FamilyPlanUD{
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
    border-radius: 12px;
    padding:50px;
  }
  .parentBox{
    max-width:160px;
    border: 1.80571px solid #43225B;
    border-radius: 63.4081px;
    padding: 10px;
    color:#43225B;
    background-color: #fff;
    font-weight: 700;
    font-size:16px;
  }
  .dark{
    color:#43225B !important;
  }
  .border-box-shadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 767px) {
    .heading{
      font-size: 22px;
    }
    .fs-18{
      font-size:16px;
    }
  
    .headingSecond{
      font-size: 21px;
    }
    .features_section {
      h1{
        font-size: 22px;
      }
    }
    .errorBox{
      min-height:200px;
    }
  }
  
`
export const BorderedBox = styled.div`
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding:${props => props.isFPdetails === true ? "20px 10px 10px" : "30px 30px 10px"};
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin:${props => props.isFPdetails === true ? "0 15px 30px" : "0 0 30px"}; ;
  max-width:${props => props.isFPdetails && '350px'};
  min-height:${props => props.minHeight && '200px'};
  h3{
    font-weight: 600;
  }
  p{
    font-size:12px;
  }
  .inviteMember{
    cursor: pointer;
    margin-top: 8px;
    background: #EEE9F4;
    border-radius: 46.0177px;
    max-width: 200px;
    color:#43225B;
    font-size:12px;
    font-weight:700;
    padding:5px;
  }
  }
  .dark{
    color:#43225B !important;
  }
  .parentBox{
    width: auto;
    border: 2px solid #43225B;
    border-radius: 64px;
    padding: 10px;
    color:#43225B;
    background-color: #fff;
    font-weight: 700;
    font-size:16px;
  }
`

export const InviteFamilyMemberBox = styled.div`
  .copyLinkBox{
      max-width: 370px;
      display: block;
      margin: 30px auto;
      border: 1px solid #DDD4E2;
      box-sizing: border-box;
      border-radius: 5px;
      
      button{
          background-image:url(${copy});
          background-color: transparent;
          border: none;
          outline: none;
          width: 34px;
          height:34px;
          vertical-align:middle;
      }
  }
  input{
      width:calc(100% - 34px);
      max-width:330px;
      padding:16px 0 16px 10px;
      height:50px;
      margin: 0;
      border: none;
      background-color: transparent;
      &:active{
          border: none;
      }
  }
  ul{
    display:flex;
    justify-content:space-between;
    max-width:123px;
    margin:auto !important;
  }
`

export const FamilyPlanShareLink = styled.li`

  background:url(${familyPlanShare}) no-repeat;
  height:38px;
  border-radius:100%;
  width: 36px;
  margin:0 5px 0 0 !important;
  padding:0;
  button{
      width:36px;
      height:36px;
      padding:0  !important;
      margin:0;
      background-color:transparent;
      outline:0;
      border:0;
  }
  :nth-child(1){
      background-position-x:0px;
  }
  :nth-child(2){
      background-position-x:-36px;
  }
  :nth-child(3){
      background-position-x:-109px;
  }
`;
export const WhmcsSubscriptionNote = styled.div`
  .whmcs-note {
    padding: 8px !important;
    border-radius: 5px;
  }
  .whmcs-note p, .whmcs-note strong {
    color: #43225B !important;
    font-size: 12px !important;
    font-weight:700;
  }
`
export const CampaignHelloBarWrapper = styled.div`
    position: fixed;
    background: #F9F8FC;
    z-index: 6;
    width: 100%;
    display:flex;
    padding:15px;

    @media only screen and (max-width: 992px) {
      position: relative;
      background: transparent;
    }
    @media only screen and (min-width: 1100px) {
      z-index: 3;
    }
`;

export const CampaignHelloBarContent = styled.div`
  position:relative;
  display:flex;
  width: 100%;
  border-radius: 15px;
  padding:0px 15px 0px 15px;
  align-items:center;

  ${({bgColor}) => bgColor !== "" && css`
    background: ${bgColor};
  `}

  ${({bgImage}) => bgImage !== "" && css`
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:auto;
    position: relative;
  `}

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .banner-cta{
    padding: 10px 30px 10px 30px !important;
    margin-left: 40px;
    font-weight: 600;
    ${props => props.isBF && css`
        margin-left:20px;
        `}
  }

  .small-text{
    color:white;
    font-size:10px;
    margin-left:10px;
  }

  .close-button {
    position: absolute;
    top: 50%;
    right: 15px;
    ${props => props.isRefer && css`
        top:50%;
        right:20px;
        `}
    transform: translate(-50%, -50%);
  }

  .bf-textbox{
    display:flex;
    align-items: center;
  }
  .bf-bannertext{
    display:flex;
    justify-content:flex-end;
    align-items: center;
  }
  .yellow-text{
    color: #FFBA24 !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.44px;
    margin:0px 7px 0px 7px; 
  }
  .bf-whitetext{
    font-size: 22px;
    line-height: 123%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bf-img{
    
  }
  .anniversary-box{
    display: flex;
    align-items: center;
    margin-right:5px;
  }
  .anniversary-text{
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 100%;
    color:#FFFFFF !important;
    font-size: 24.778px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 22.3px */
    letter-spacing: -0.496px;
    background: linear-gradient(90deg, #FFEAB8 0%, #FFBC33 102.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .whole-text{
    display:flex; 
  }
  
  @media only screen and (max-width:1300px) {
    .anniversary-text{
      font-size:20px !important;
    }
    .bf-whitetext{
      font-size:18px !important;
    }
    .bf-textbox .yellow-text{
      font-size:18px !important;
    }
    
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: row;
    align-items: center;
    & > div:not(.close-button) {
      margin-right: 20px;
    }

    .banner-cta{
      max-width: 140px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 5px;
      font-size: 12px !important;
      padding: 5px 15px 5px 15px !important;
    }
    .close-button{
      top:50%;
      right:10px;
    }
    .small-text{
    margin-right:30px;
    }
    height: auto;
    position: relative;
    background-position-y: bottom;
    background-color: #490ea9;
    ${props => props.isRefer && css`
            background-color:#361B49;  
        `}
    .bf-whitetext{
      font-size: 20px;
    }
     
  }
  @media only screen and (max-width:1050px) {
    .anniversary-text{
      font-size:18px !important;
    }
    .bf-whitetext{
      font-size:17px !important;
    }
    .bf-textbox .yellow-text{
      font-size:17px !important;
    }
    .banner-cta{
      max-width: 140px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 5px;
      font-size: 12px !important;
      padding: 5px 15px 5px 15px !important;
    }
    .bf-img{
      width: 110px !important;
      height: 85px !important;
    }
  }

  @media only screen and (max-width: 1010px) {
    p {
      font-size: 14px;
      text-align: center;
    }

    .banner-cta{
      padding: 7px 14px;
    }
    .close-button {
      transform: translate(0%,-50%);
    }
    .anniversary-text{
      font-size:17px !important;
    }
    .bf-whitetext{
      font-size:14px !important;
    }
    .bf-textbox .yellow-text{
      font-size:14px !important;
    }
    .bf-img{
      width: 60px !important;
      height: 65px !important;

    }
    .banner-cta{
      max-width: 140px;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 5px;
      font-size: 12px !important;
      padding: 5px 10px 5px 10px !important;
    }
    
  }

  @media only screen and (max-width: 900px){
    .bf-textbox{
      flex-direction:column;
    }
    .bf-whitetext{
      font-size: 18px;
    }
    .bf-textbox .yellow-text{
      color: #FFBA24 !important;
      font-size:18px !important;
    }
    .banner-cta{
      margin-top:10px;
      font-size:15px;
      padding: 15px 30px;
      font-size:17px;
    }
  }

  @media only screen and (max-width: 900px){

    ${({bgImageTablet}) => bgImageTablet !== "" && css`
    background-image: url(${bgImageTablet});
    padding:0px 15px 0px 15px;
    background-repeat:no-repeat;
    background-position-y: bottom;
    & > div:not(.close-button) {
      margin-right: 0px;
    }
  `}

  .bf-textbox{
    flex-direction:row;
  }
  .anniversary-text{
    font-size:14px !important;
  }
  .bf-whitetext{
    font-size:13px !important;
  }
  .bf-textbox .yellow-text{
    font-size:13px !important;
  }
  .bf-img{
    width: 90px !important;
    height: 65px !important;
  }
  .close-button{
    top: 50%;
    right: 10px;
  }
  .banner-cta{
    margin-right:0px;
    margin-left:10px;
    font-size:12px;
    margin-bottom:10px;
  }
  }

  @media only screen and (max-width: 800px){

    ${({bgImageTablet}) => bgImageTablet !== "" && css`
    background-image: url(${bgImageTablet});
    padding:0px 15px 0px 15px;
    flex-direction:column;
  `}

  .bf-textbox{
    flex-direction:column;
  }
  .anniversary-text{
    font-size:18px !important;
  }
  .bf-whitetext{
    font-size:17px !important;
  }
  .bf-textbox .yellow-text{
    font-size:17px !important;
  }
  .bf-img{
    width: 90px !important;
    height: 65px !important;
  }
  .close-button{
    top: 50%;
    right: 10px;
  }
  .banner-cta{
    margin-right:0px;
    margin-left:0px;
    font-size:12px;
    margin-bottom:10px;
    padding: 10px 15px 10px 15px !important;
  }
  }

  @media only screen and (max-width: 480px){
    flex-direction:column;
    ${({bgImageMobile}) => bgImageMobile !== "" && css`
    background-image: url(${bgImageMobile});
    background-repeat:no-repeat;
    background-position-y: bottom;
  `}
  
  .anniversary-text{
    font-size:14px !important;
    position: relative;
    right: 15px;
  }
  .bf-whitetext{
    font-size:12px !important;
  }
  .bf-textbox .yellow-text{
    font-size:12px !important;
  }
  .bf-img{
    margin-right:0px !important;
    position:relative;
    width: 70px !important;
    height: 55px !important;
    right: 15px;
  }
  .banner-cta{
    margin-right:0px;
    margin-top:10px;
    font-size:11px !important;
  }
  .close-button{
    top: 15%;
    right: 10px;
  }
  }
  @media only screen and (max-width: 360px){
    anniversary-text{
      font-size:13px !important;
      position: relative;
      right: 15px;
    }
    .bf-whitetext{
      font-size:11px !important;
    }
    .bf-textbox .yellow-text{
      font-size:11px !important;
    }
    .bf-img{
      position: relative;
      width: 62px !important;
      height: 54px !important;
      right: 8px;
    }
  }
`
export const ReferralsCover = styled.div`
  display: flex;
  min-height: 320px;
  border: none;
  border-radius: 16px;
  background: linear-gradient(94deg, #6536CC 0%, rgba(52, 11, 112, 0.00) 100%), #480CA8;
  ${({bgImage}) => bgImage !== "" && css`
    background-image: url(${bgImage});
    background-size:cover;
    background-position: center center;
    background-repeat:no-repeat;
  `}
  justify-content: center;
  text-align: center;
  .header{
    z-index: 1;
    margin: 20px;
    display: flex;
    flex-direction:column;
    justify-content:space-evenly;
    .cta {
      font-family: Open Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      h1 {
        color: white;
      }
    }
    .copyLinkBox {
      box-sizing: border-box;
      border-radius: 5px;
      background-color: white;
      input {
        font-size: 16px;
        width:calc(100% - 38px);
        max-width:344px;
        padding:16px 10px;
        height:50px;
        margin: 0;
        border: none;
        background-color: transparent;
        &:active {
            border: none;
        }
      }
      button {
          background-image:url(${copy});
          background-color: transparent;
          border: none;
          outline: none;
          width: 34px;
          height:34px;
          vertical-align:middle;
      }
    }
    .referral-process {
      font-weight: 400;
      font-size: 16px !important;
      color: #EEEEEE !important;
      cursor: pointer;
    }
  }
`

export const ReferralModalBody = styled.div`
  text-align: center;
  h1 {
    font-size: 24px;
  }
  .referral-steps {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 30px;
    @media only screen and (max-width: 480px){
      flex-direction: column;
    }
  }
  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    .step-count {
      font-size: 20px;
      align-self: center;
      border: 4px solid #EEEDF0;
      border-radius: 100%;
      width: 36px;
      height: 36px;
    }
    img {
      max-width: 100px;
      align-self: center;
    }
    .step-name {
      color: #6536CC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
    .step-desc {
      color: #637381;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: 0.24px;
    }
  }
`

export const TiersModalBody = styled.div`
  text-align: center;
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .tiers-section {
    display: flex;
    @media only screen and (max-width: 960px){
      overflow-x:scroll;
    }
    .tier {
      padding: 30px;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-width: 200px;
      .name {
        color: #001219;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
      .total-referrals {
        color: #001219 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        background-color: #EEE3FF;
        padding: 6px 14px;
        border-radius: 50px;
      }
      .count {
        font-size: 12px;
      }
      .rewards {
        color: #001219 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        background-color: #EEE3FF;
        padding: 10px 18px;
        border-radius: 50px;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .rewards-desc li {
        color: #637381 !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        margin: 10px;
      }
    }
    .tier.active {
      background: linear-gradient(180deg, #F6F3FF 0%, #fbfbfc 100%);
      border-radius: 48px;
      .img {
        transform: scale(1.4);
        margin-bottom: 15px;
      }
    }
    .tier.inactive {
      .total-referrals, .count, .rewards, .rewards-desc li {
        opacity: 0.5;
      }
      .rewards {
        background-color: #F4F4F4;
        padding: 6px 14px;
      }
    }
  }
`

export const WalletCover = styled.div`
display: flex;
min-height: 320px;
border: none;
border-radius: 16px;
background: linear-gradient(94.3deg, #6536CC 0%, rgba(52, 11, 112, 0) 100%), #340B70;
::after{
  content: " ";
  position: absolute;
  height:320px; 
  width:500px;
  right: 30px;
  ${({bgImage}) => bgImage !== "" && css`
    background-image: url(${bgImage});
    background-size:cover;
    background-repeat:no-repeat;
  `}
}
.header{
  margin:35px;
  max-width:570px;
  min-width:230px;
  display: flex;
  flex-direction:column;
  justify-content:space-evenly;
  .cta {
    background: linear-gradient(rgba(255,255,255,1), rgba(255,0,0,0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    /* identical to box height, or 20px */
    letter-spacing: 0.25px;
    color: #EEEEEE !important;
  }
  h1{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 41px;
    /* identical to box height */
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    /* or 20px */
    letter-spacing: 0.25px;
    color: #EEEEEE;
  }

}

  .info-view{
    display:flex;
    p{
      font-weight: 400;
      font-size: 22px;
      color: #EEEEEE;
      margin:20px 0 0 0px;
    }
    span.coin-amt{
      font-size: 22px !important;
      color: #EEEEEE !important;
      .gradient-style{
        background-color:#c3afee;
        background-image: linear-gradient( 90deg,#c3afee,#3f3458,#c3afee );
      }
      .earned {
        font-weight: 700 !important;
        font-size: 22px !important;
      }
    }
    svg {
      margin-top: 22px;
      font-size: 26px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .points::after{
      content:"";
      border-right: 1.5px solid #FFFFFF;
      margin:0 20px;
      font-size:16px;
      opacity:0.5;
    }
    p:last-child{
      color: #EEE;
      text-decoration-line: underline;
      cursor: pointer;
      margin-top:22px;
      font-size: 15px;
      font-weight: 100;
      align-self: center;
    }
  }
  .error-view{
    display:flex;
    p{
      font-size:14px !important;
      margin-bottom:0px;
      color:red
    }
    p:last-child{
      cursor: pointer;
      text-decoration:underline;
      margin-left:5px;
      color:#FFFF;
    }
  }

@media only screen and (max-width: 960px){
  ::after{
    content: " ";
    background:none;
  }
}
@media only screen and (max-width: 730px){
min-height:230px;
.info-view{
  flex-direction:column;
  span.coin-amt::after{
    display:none !important;
  }
  p{
    margin-top:5px;
    font-size:15px;
  }
  p:last-child{
      font-size:14px;
      margin-top:7px;
    }
}
.header{
 >h1{
  font-size:26px;
  margin:0 !important
 }
 >p{
  font-size:12px;
  margin:0 !important;
 }
 >div{
  @media only screen and (max-width: 560px){
    flex-direction:column;
  }
  div.wallet-info{
      :first-child{
        margin-right:8px !important;
      }
  }
 }
}
div.wallet-info{
  margin:0 !important;
  margin-top:10px !important;
  ::after{
    height:50px;
    width:50px;
    margin-top:15px !important;
  }
  .info-view{
    margin-left:50px;
    .coin-amt{
      font-size:26px !important;
    }
  }
  @media only screen and (max-width: 330px){
    width:200px !important;
    ::after{
    height:40px;
    width:40px;
    margin-top:25px !important;
  }
  .info-view{
    margin-left:40px;
    .coin-amt{
      font-size:22px !important;
    }
  }
  button{
    margin-left:2px !important
  }
  }

}
}

`
export const WalletDashboardView = styled.div`
border: 1px solid #EEEDF0;
border-radius: 24px;
display: flex;
flex-direction: column;
min-height: 150px;
background-color: #fff;
margin-top: 10px;
padding: 30px;
padding-bottom: 15px;
.navbar-view{
  border-bottom: 1px solid #EEEDF0;
  margin-bottom: 15px;
  .nav-item{
    color: transparent;
    .nav-link{
      color:#637381;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.25px;
      border:none
    }
    .nav-link:hover{
      cursor:pointer
    }
    .active{
      background-color: transparent;
      border:none;
      color:#000;
      border-bottom: 5px solid #6536CC;
    }
  }
}
`
export const ComboIpBox = styled.div`
.head{
background: transparent;
border: none;
padding-left: 5px;
h3{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #001219;
}
}
div{
  background: #FFFFFF;
  border: 1px solid #EEEDF0;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  h2{
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001219;
  }
  p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: #637381;
  }
}
div:hover:not(:first-child) {
  background-color: #EEEDF0;
  border: 1px solid #637381;
}
@media only screen and (max-width: 555px){
h3{
  font-size: 18px !important;
  line-height: 28px;
}
div{
  h2{
    font-size: 16px !important;
  }
  p{
    font-size: 13px;
  }
}
}
`
export const CustomBannerWrapper = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
z-index: 9999;
.cont-box {
  max-width: 360px;
  width: 100%;
  margin: auto;
  position: relative;
  background: url('https://images.purevpn-tools.com/public/images/apps-gray-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.cont-content {
  padding: 20px 17px 15px;
}

.cont-upper {
  display: flex;
  gap: 18px;
}

.cont-upper p {
  max-width: 201px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  padding-top: 5px;
}

.cont-lower {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

.cont-lower .cont-btn1 button {
  font-style: normal;
  border-radius: 83px;
  border: 1px solid #9A9A9A;
  font-weight: 700;
  max-width: 110px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #7C7C7C;
  text-decoration: none;
  padding: 0px 9px;
}

.cont-lower .cont-btn2 button {
  font-style: normal;
  border-radius: 83px;
  max-width: 150px;
  border: 1px solid #28C675;
  background: #28C675;
  width: 100%;
  height: 40px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 0px 9px;
}
@media only screen and (max-width: 1024px){
  .cont-box {
    max-width: 1024px;
    width: 100%;
  }
  .cont-upper p {
    max-width: 460px;
    font-size:22px;
  }
  .cont-content {
    padding: 20px 60px 15px;
  }
}  
@media only screen and (max-width: 768px){
  .cont-box {
    max-width: 760px;
    width: 100%;
  }
  .cont-upper p {
    max-width: 362px;
    font-size:18px;
  }
  .cont-content {
    padding: 20px 60px 15px;
  }
}

  @media only screen and (max-width: 480px){
    .cont-box {
      max-width: 480px;
    }
    .cont-upper p {
      max-width: 326px;
      font-size:20px;
    }
    .cont-content {
      padding: 20px 27px 15px;
    }
  }
`


export const HistoryTable = styled.div`
h3{
  color:#000;
}
.table-responsive::-webkit-scrollbar {
  width: 4px !important;
}
 
.table-responsive::-webkit-scrollbar-thumb {
  background: rgba(99, 115, 129, 0.1);
  border-radius: 40px;
}
.table-responsive {
  border: 1px solid #EEEDF0;
  border-radius: 10px;
  margin-bottom: 5px;

}
.table{
  border: 1px solid #EEEDF0;
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.table-responsive{
  overflow-y: auto;
  max-height: 500px;
}
.table thead th{
  top: 0;
    position: sticky;
    background-color: #43225B;
}
thead,tbody{
  background: rgba(99, 115, 129, 0.1);
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #637381;
  border-bottom: none;
  th:last-child{
    text-align: right;
  }
}
.table>:not(caption)>*>*{
  border: none;
}
tbody{
  background: transparent;
  color:#000;
  td:last-child{
    text-align: right;
  }
  tr:not(:last-child){
    border-bottom: 1px solid #EEEDF0;
  }
}
#noDataDisplay{
  text-align: center;
  margin: 40px 0;
  font-weight: 700;
}
`

export const EmailFeedbackWrapper = styled.div`
  overflow: hidden;
  .email-feedback-form{
    width: 600px;
    margin: 110px auto;

      &.teams-form{

        width: 800px;

        h2{
          margin-bottom: 20px;
        }

        p{
          font-size: 18px !important;
        }

        a{
          margin-top: 10px;
          color: rgb(67, 34, 91) !important;
          font-size: 16px !important;
          display: block;
          font-weight: 700;
          text-align: center;
          text-decoration: underline;
        }
      }

    h2{
      text-align: center;
    }
    label{
      margin: 30px auto 10px;
      color: #7B648C !important;
      font-size: 14px !important;
      display: block;
      font-weight: 400;
    }
    textarea{
      background: #FFFFFF;
      box-sizing: border-box;
      font-size: 14px;
      padding: 10px 11px;
      color: #43225B !important;
      letter-spacing: 0.15px;
      font-weight: 400;
      border: 1px solid #ECE9EE;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 20px;
      &:focus-visible{
        height: 200px;
        outline: 2px solid #3B108E !important;
      }
    }
    input{
      width: 100%;
      margin: 0px;
      outline: none !important;
      border-top: none;
      border-left: none;
      border-right: none;
      font-weight: 600;
      &:active, &:visited{
        outline: none !important;
        text-decoration: none !important;
        border: 1px solid #ECE9EE;
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
  }
  
  
`

export const SearchStyles = styled.div`
    position: relative;
    width: max-content;
    @media only screen and (max-width: 1020px){
      width: 100%;
    }
    input{
      border-radius: 8px;
      border: 1px solid #DDD4E2;
      background: #FFF;
      padding: 10px 15px;
      box-shadow: 0px 0px 36px rgba(67,34,91,0.1);
      width: 310px;

      @media only screen and (max-width: 1020px){
        width: 100%;
        margin-bottom: 20px;
      }
      &::placeholder{
        color: #43225B !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        letter-spacing: 0.5px;
      }
    }
    .icon{
      position: absolute;
      right: 15px;
      top: 8px;
      img{
        width: 20px;
        height: 20px;
      }
    }
`
export const CheckboxStyles = styled.div`
  display: flex;

  input[type="checkbox"] {
    position: relative;
    width: 16px;
    height: 16px;
    color: #fff;;
    border: 1px solid #fff;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    
    @media only screen and (max-width: 1020px){
      width: 10px;
      height: 10px;
      border-radius: 2px;
    }

    &:disabled{
      cursor: not-allowed;
      opacity: 0.1; 
    }

    &.purple{
      border: 1px solid #8A8398;

      &:checked{
        border-color: #6536CC;
        background: #6536CC;
      }
    }
    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 1px;
      left: 4px;
      width: 6px;
      height: 10px;
      border-style: solid;
      border-color: #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: #fff;
      
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
`

export const UpgradeAddons = styled.div`
  border-radius: 24px;
  border: 1px solid #DFDFDF;
  background: #FFF; 
  padding: 21px;
  display: flex;
  align-items: center;
  max-width: 92.5%;
  width: 100%;
  margin: auto;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 45px;

  @media only screen and (max-width: 1552px){
    max-width: 100%;
  }

  @media only screen and (max-width: 1350px){
    padding: 20px;
  }

  @media only screen and (max-width: 497px) and (min-width: 270px) {
    display: none !important;
  }
  
  ${props => props.isSelected && css`
    border: 1px solid #DFDFDF;
    // background: linear-gradient(90deg, #281652 0.49%, #4E3589 26.03%); 
  `}

  @media only screen and (max-width: 1540px){
    max-width: 100%;
  }

  @media only screen and (max-width: 1410px){
    display: block;
  }

  @media only screen and (max-width: 1300px){
    max-width: max-content;
    width: max-content;
  }

  .additional-addons-frame{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;

    @media only screen and (max-width: 1350px){
      gap: 10px;
    }

    @media only screen and (max-width: 1300px){
      display: block;
      max-width: 400px;
      width: 100%;
      margin: auto;
    }

    @media only screen and (max-width: 490px){
      max-width: 350px;
    }

    @media only screen and (max-width: 430px){
      max-width: min-content;
    }
  }

`

export const AdditionalAddons = styled.div`
width: 100%;
height: 255px;
  ${props => !props.isMax && css`
    border-radius: 16px; 
    border: 1px solid #DFDFDF;
    background: #FFF; 
    padding: 28px 18px;
    flex-grow: 1;
    box-shadow: ${(props) => (props.buyAddon? "0px 12px 24px rgba(59, 16, 142, 0.07)" : "none")};
    position: relative;
    cursor: pointer;
  `}

  @media only screen and (max-width: 1300px){
    margin-bottom: 20px;
  }

  ${props => props.isMax && css`

    h4{
      color: #000;
      font-size: 24px;
      font-weight: 700;
    }

    h5{
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 17.5px;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }

    p{
      color: #43225B;
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.24px;
      min-height: 107px;
    }

    

    .pricing{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      position: relative;

      @media only screen and (max-width: 430px){
        display: block;
      }

      .old-discounted-price{
        color: #A894D7;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.28px;
        text-decoration-line: line-through;
        text-transform: uppercase; 
        opacity: 0.5; 
        margin-bottom: 3px;
        position: absolute;
        top: -14px;
      }

      h3{
        color: #43225B;
        font-size: 30px;
        font-weight: 700;
        margin: 0;

        @media only screen and (max-width: 550px){
          font-size: 24px !important;
          margin-bottom: 10px;
        }

        span{
          color: #43225B !important;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0.24px; 
        }
      }

      button{
        padding: 12px; 
        width: 100px;
        border-radius: 8px;
        background: #28C675;
        cursor: pointer;
        color: #000;

        @media only screen and (max-width: 550px){
          width: 200px;
        }

        &:disabled{
          opacity: 0.36;
          background: #2CDC82; 
          cursor: not-allowed;
        }
      }
    }
  `}

  ${props => props.isDisabled && css`
      opacity: 0.5;
  `}
  
  .overlay{
    display: none;
    ${props => props.isDisabled && css`
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      background: #00000021;
      width: 100%;
      height: 100%;
      border-radius: 10px;
  `}
  }

  @media only screen and (max-width: 960px){
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 550px){
    height: max-content;
  }

  .checkBox{
    margin-top: 5px;
    margin-left:auto;
    width: 20px;
    height: 20px;
    border:${(props) => (props.buyAddon? "1.5px solid none" : "1.5px solid #DFDFDF")};
    border-radius:4px;
    background: ${(props) => (props.buyAddon ? "#28C675": "none")};
    border-radius: 5px;
  }

  //ba-308
  .title{
    color: #000;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.4px;
    position: relative;
    padding-left: 39px;

    &::before{
      position: absolute;
      content: '';
      background: url(${FlatIcons}) no-repeat;
      left: 0px;
      top: -4px;
      width: 32px;
      height: 32px;
      ${props => props.isPrivacy && css`
        background-position: -64px 0px !important;
      `}
      ${props => props.isKeep && css`
          background-position: -32px 0px !important;
      `}
      ${props => props.isEcrypt && css`
        background-position: 0px 0px !important;
      `}
    }

    @media only screen and (max-width: 1406px){
      height: 42px;
    }
    
  }
  .description{
      font-size: 12px;
      color: #43225B;
      text-align:left;
  }
  .planPrice{
    font-weight: 700;
    font-size: 20px !important;
    line-height: 17px;
    color: #43225B!important;
    letter-spacing: 0.5px; 
    margin: 0;

    span{
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000;
    }
      
  }
  .oldPrice{
    color: #78747B;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.28px;
    text-decoration-line: line-through;
    text-transform: uppercase; 
    opacity: 0.5; 
    margin-bottom: 3px;
  }

  ul{
    margin-top: 30px;
    padding-left: 0px;
    margin-bottom: 23px;

    @media only screen and (max-width: 1552px){
      min-height: 90px;
    }

    @media only screen and (max-width: 1406px){
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: 107px;
    }

    li{
      list-style: none;
      padding-left: 0px !important;
      color: #43225B !important;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 17.5px;
      letter-spacing: 0.5px;
      padding-left: 18px !important;
      position: relative;
      margin-bottom: 10px;

      @media only screen and (max-width: 1450px){
        padding-left: 14px !important;
      }

      @media only screen and (max-width: 1410px){
        padding-left: 18px !important;
      }
  

      &::before{
        position: absolute;
        content: '';
        background: url(${CheckMark}) no-repeat;
        left: 0px;
        top: 3px;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .checkBox span::before{
    ${props => props.buyAddon && css`
      content: " ";
      position: absolute;
      background-image: url(https://images.purevpn-tools.com/public/images/pcircle-listcheck-white.png);
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      margin:4px 0 0 3px;
    `}
  }
`
export const MultiloginStyles = styled.div`

  p{
    color: #637381;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    margin: 0;
    margin-bottom: 20px;
  }

  h4{
    color: rgb(0, 18, 25) !important;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 6px;
    font-weight: 501;
    strong{
      font-weight: 600;
      margin-left: 4px;
      color: rgb(85, 12, 203);
      @media only screen and (max-width: 320px) {
        margin-left: 2px;
      }
    }
    .accounts{
      display: inline;
      color: #637381 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
    }
  }
  .mod-boxes-container{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top:30px;
    padding-bottom: 32px;
    border-bottom: 1px solid #EBE6FF;

    @media only screen and (max-width: 600px) {
      gap: 10px;
    }
    @media only screen and (max-width: 530px) {
      gap: 5px;
    }

    .mod-boxes{
      border-radius: 8px;
      border: 1px solid #CFCFCD;
      background: #FFF;
      padding: 12px 12px;
      position: relative;
      cursor: pointer;
      flex-basis: 10%;
      flex-grow: 1;
      @media only screen and (max-width: 548px) {
        margin-bottom: 10px;
        flex-basis: 40%;
        max-width: 50%;
      }
      @media only screen and (max-width: 420px) {
        margin-bottom: 10px;
        flex-basis: 50%;
        max-width: 100%;
      }
  
      h4{
        color: #252422;
        font-size: 18px !important;
        font-weight: 600;
        margin: 0;
        @media only screen and (max-width: 548px) {
          display: inline-block;
        }
      }
      p{
        color: #252422;
        font-size: 12px !important;
        font-weight: 300;
        margin-bottom: 10px;
        @media only screen and (max-width: 548px) {
          display: inline-block;
          margin-left: 5px;
          margin-bottom: 0px;

        }
      }
      h3{
        color: #252422;
        font-size: 18px !important;
        font-weight: 700;
        margin: 0;
        @media only screen and (max-width: 548px) {
          display: inline-block;
          float: right;
        }

        a{
          color: rgb(72, 10, 173);
          font-size: 12px !important;
          font-weight: 600;
          text-decoration-line: underline;

        }
      }
      &:before{
        outline: 1px solid rgb(36, 36, 36);
        width: 10px;
        height: 10px;
        position: absolute;
        top: 15px;
        right: 12px;
        content: '';
        border-radius: 30px;
      }

      @media only screen and (max-width: 548px) {
        &:before{
          display: none;
        }
      }

      &.selected{
        border-radius: 8px;
        border: 1px solid #480CA8;
        background: #FFF;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04), 0px 16px 32px 0px rgba(129, 70, 255, 0.16);

        &:before{
          outline: 1px solid #480CA8;
          border: 1px solid #fff;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 15px;
          right: 12px;
          content: '';
          border-radius: 30px;
          background: #480CA8;
        }
      }
    } 
  }
  .total-amount{
    border-bottom: 1px solid #EBE6FF;
    margin-bottom: 22px;
    padding: 16px 0px;
    h4{
      color: #001219;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin: 0;
      strong{
        float: right;
        color: #001219;
      }
    }
  }
`