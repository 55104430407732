import React, { useState, useEffect } from 'react'
import {  PopupBody } from '../../../../styles/dashboard/Main'
import { CancelBtn, DestructBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import qs from 'qs'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getCurrentSubscription, poster } from '../../../../tools/tools';
import { withRouter } from 'react-router-dom';
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sendEvents } from '../../../../tools/mpEvents';

const CancelSubscription = ({history, backdrop, toggle, modal, modalWidth, widthCalculator, selectedSubscription, subscriptionsReducer}) => {

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const [offlineGateway, setOfflineGateway] = useState(false)

    const { t, i18n } = useTranslation();

    const [gatewayName, setGatewayName] = useState("")

    const token = localStorage.getItem("token")

    const pauseSubscription = async () => {
        setloading(true)
        const currentSub = getCurrentSubscription(subscriptionsReducer, selectedSubscription)
        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscription
            })
            const cancel =  await poster("subscription/cancel", formdata, token)
            const {data: response} = cancel;
            if(response?.status){
                toast.success("Your subscription has been canceled, you can reactivate it any time.")
                dispatch(getSubscriptionsRequest(token))
                sendEvents({
                    event_name:"ma_cancel_subscription",
                    event_properties:{
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        biling_cycle: currentSub?.plan?.type ?? "N/A",
                        current_status: currentSub?.state ?? "N/A"
                    }
                }, token)
                toggle()
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break;    
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;      
                    case "recurly_exception":
                        toast.error(response?.message)
                        break; 
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break;
                    default:
                        toast.error(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_cancel_subscription",
                    event_properties:{
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        biling_cycle: currentSub?.plan?.type ?? "N/A",
                        current_status: currentSub?.state ?? "N/A",
                        reason:response?.message
                    }
                }, token)
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_cancel_subscription",
                event_properties:{
                    payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                    biling_cycle: currentSub?.plan?.type ?? "N/A",
                    current_status: currentSub?.state ?? "N/A",
                    reason:"API Break or Server is not responding"
                }
            }, token)
        }
        finally{
            setloading(false)
        }
    }

    const buttonText = () => {
        return !loading ? t('subscription_cancle_subscription_cta') : ( <>{t('subscription_cancle_subscription_cta')} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    useEffect(() => {
        const { subscriptions } = subscriptionsReducer;

        subscriptions.body.map( (subscription) => {
            if (subscription.id == selectedSubscription && subscription.payment_gateway_offline) {
                setOfflineGateway(true)
                setGatewayName(subscription?.payment_gateway)
            }
        })
    }, [subscriptionsReducer])

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">

                {
                    offlineGateway?
                    <>
                        <ModalBody className="pb-0">
                            <PopupBody>
                                <div className="mod-head">
                                    <h3>{t('cancle_subscription_not_supported')}</h3>
                                    <p className="m-0">{t('cancle_subscription_we_are_sorry')}<b>{gatewayName}</b>.</p>
                                </div>
                            </PopupBody>
                        </ModalBody>
                        <ModalFooter className="pt-0">
                            <DestructBtn color="secondary" onClick={toggle} pointer={loading}>{t('cancel_text')}</DestructBtn>
                        </ModalFooter>
                    </>
                    :
                    <>
                        <ModalBody className="pb-0">
                            <PopupBody>
                                <div className="mod-head">
                                    <h3>{t('subscription_cancle_subscription_cta')}</h3>
                                    <p className="m-0">{t('cancle_subscription_without_vpn')}</p>
                                </div>
                            </PopupBody>
                        </ModalBody>
                        <ModalFooter className="pt-0">
                            <CancelBtn onClick={pauseSubscription} pointer={loading} color="primary">{buttonText()}</CancelBtn>
                            <DestructBtn color="secondary" onClick={toggle} pointer={loading}>{t('cancel_text')}</DestructBtn>
                        </ModalFooter>
                    </>
                }

            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, null)(withRouter(CancelSubscription))
