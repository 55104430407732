import React from 'react'
import { getDiscount, getTotalAmount } from '../../../../../tools/tools'
import { InfoCard } from '../../../../../styles/dashboard/Main'
import { useTranslation } from 'react-i18next';

const DiscountAndTotalField = ({invoiceData={},currency="",totalAmtFunc=()=>{},isCouponApplied=null,bCycle=false}) => {

  const { t, i18n } = useTranslation();
  const invData = bCycle ? invoiceData?.orderSummary?.body : invoiceData?.response
  
  return (
    <>
        {isCouponApplied &&  
        <InfoCard hasborder>
            <div className="float-start">
                <label><strong className="ms-0">Discount</strong> </label>
            </div>
            <div className="float-end">
                <label><strong className='discount'>{currency}{Object.keys(invoiceData.orderSummary).length !== 0 ? getDiscount(invData) : "-"}</strong> </label>
            </div>
            <div className="float-none"></div>
        </InfoCard>}
        <InfoCard hasborder>
            <div className="float-start">
                <label><strong className="ms-0 total-amount-label">{t('upgradeplan_total_amount')}</strong> </label>
            </div>
            <div className="float-end">
                {/* Total Amount is clculated by getTotalAmount => Tools.js */}
                <label className='d-flex'>
                    <strong className={isCouponApplied ? "strike-out me-1" : "total-amount"}>{currency}{Object.keys(invoiceData.orderSummary).length !== 0 ? totalAmtFunc(invData) : "-"}</strong> 
                    {isCouponApplied && <strong className="after-discount">{currency}{Object.keys(invoiceData.orderSummary).length !== 0 ? getTotalAmount(invData,isCouponApplied) : "-"}</strong>} 
                </label>
            </div>
            <div className="float-none"></div>
        </InfoCard>
    </>
    )
}

export default DiscountAndTotalField