import { CardBox, EmailFeedbackWrapper, InfoCard, Input } from '../../../styles/dashboard/Main';
import { dispatchError, displayError, fetcher, getTokenFromLocalStorage } from '../../../tools/tools';
import { PrimaryBtn } from '../../../styles/Generic';
import { sendEvents, waitForMixpanelEvent } from '../../../tools/mpEvents';
import { useDispatch} from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import Textarea from '../../../generic-components/textarea/textarea';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

const EmailFeedback = ({history}) => {

    const token = getTokenFromLocalStorage();

    const { search } = useLocation();

    const {client_answer, campaign_name} = Object.fromEntries(new URLSearchParams(search));

    const [loading, setLoading] = useState(true);

    const [email, setEmail] = useState();

    const [comments, setComments] = useState('');

    const [submit, setSubmitted] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        sendEventOnLanding(onPageLoad)
    }, [])

    const sendEventOnLanding = async () => {

        await waitForMixpanelEvent({
            event_name:"ma_campaign_email",
            event_properties:{
                client_answer: client_answer,
                campaign_name: campaign_name
            }
        }, token)

        onPageLoad()
    }

    const onPageLoad = () => {
        client_answer == 'no' ? window.location.href = 'https://support.purevpn.com/campaign-onboarding' : getAccountInfoRequest()
    }

    const getAccountInfoRequest = async () => {
        try {
            setLoading(true)

            const accountInfo =  await fetcher("account", getTokenFromLocalStorage());
            const {data: response} = accountInfo;

            if(response?.status){
                setEmail(response?.body?.email)
            } else {
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            displayError(error);
        }
        finally{
            setLoading(false)
        }
    }

    const handleSubmit = () => {
        setSubmitted(true)
        sendEvents({
            event_name:"ma_campaign_email_feedback",
            event_properties:{
                client_answer: client_answer,
                campaign_name: campaign_name,
                comment: comments.trim()
            }
        }, token)
        toast.success('Your feedback has been submitted.')
        history.push('/dashboard/refer')
    }

    const buttonText = (text) => {
        return !submit ? text : ( <span className="spinner-border text-light spinner-border-sm ms-1"></span>);
    }

    return <>
        {
            !loading ? <>
                    <EmailFeedbackWrapper>
                    <CardBox className='email-feedback'>
                                <div className={`email-feedback-form ${campaign_name === 'teams' ? 'teams-form' : ''}`}>
                                    {campaign_name === 'teams' ? <CardBox noShadow={true}>
                                        <h2>We appreciate your interest!</h2>
                                        <p className="mb-0" style={{textAlign: 'center'}}>An expert will get in touch with you shortly to schedule your personalized demo.</p>
                                        <p className="mb-0" style={{textAlign: 'center'}}>If you need assistance right away, email us at</p>
                                        <a href="mailto:help@purevpn.com" className="text-primary">help@purevpn.com</a>
                                    </CardBox> : <CardBox hasborder>
                                        <h2>Thank you for your feedback.</h2>
                                        <label>Account Email:</label>
                                        <Input disabled={true} value={email} />
                                        <label>Your suggestions:</label>
                                        <Textarea height="200px" value={comments} onChange={(e)=>setComments(e.target.value)}/>
                                        <PrimaryBtn className="me-2 w-100" onClick={handleSubmit} type="submit">{buttonText('Submit')}</PrimaryBtn>
                                    </CardBox>}
                                </div>
                        </CardBox>
                    </EmailFeedbackWrapper>
            </> : 
            <CardBox open={true}>
                {[...Array(5).keys()].map((num)=>
                    <InfoCard hasborder noflex className={`${num == 0 ? 'mb-3' : num == 4 ? 'pt-0' : 'pt-0 mb-3'}`}>
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                )}
            </CardBox>
        }
    </>

}
export default withRouter(EmailFeedback);