import { FeatureStyles } from "../../../styles/dashboard/VolumeLicensing";

const Features = ({heading, image, paragraph, align}) => {
    return <FeatureStyles left={align === 'left' ? true : false} right={align === 'right' ? true : false}>
        <img src={image}/>
        <div>
            <h3>{heading}</h3>
            <p>{paragraph}</p>
        </div>
    </FeatureStyles>
}

export default Features;