import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const PureEncryptIconOnlySVG = ({ fillColorOne, fillColorTwo, fillColorThree, fillColorFour, width, height, style, className }) => (
    <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} className={className} viewBox="0 0 97 96" width={width}>
      <Path
          fillColor={fillColorOne}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.5 48.013C96.5 61.2803 91.1314 73.2704 82.4541 81.9502C73.7768 90.6306 61.7638 96 48.5259 96C35.2882 96 23.2751 90.6306 14.5977 81.9502C5.92018 73.2704 0.5 61.2803 0.5 48.013C0.5 34.7455 5.86806 22.7553 14.5456 14.0494C23.2231 5.34346 35.2361 0 48.4999 0C61.7638 0 73.7504 5.36953 82.4283 14.0494C91.1056 22.7293 96.5 34.7455 96.5 48.013Z"
      />
      <Path
          fillColor={fillColorTwo}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3271 20.0447C30.3629 13.45 39.4834 9.3838 49.4639 9.25342C58.8971 9.12311 68.2261 12.7201 75.0273 19.1845C83.7311 27.4473 86.9101 40.6626 85.529 52.2097C84.0176 65.1122 76.4606 78.6142 64.0565 84.088C58.1414 86.6943 51.4182 87.3465 45.0078 86.3299C37.1642 85.0787 29.4248 81.4811 23.6398 76.1117C16.0307 69.0482 11.1057 59.0647 11.1317 47.6221C11.1838 36.8049 15.8483 27.0042 23.3271 20.0447Z"
      />
      <Path
          fillColor={fillColorThree}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2231 26.2999C36.1481 21.9208 42.4282 19.0797 49.1253 18.793C55.6399 18.5323 61.5811 20.9043 66.8452 24.5535C72.8127 28.6718 76.4086 36.7522 73.5161 43.6075C72.3435 46.3704 70.2849 48.7163 69.1123 51.4793C67.7312 54.7636 67.7571 58.517 69.06 61.8274C70.7276 66.05 72.6559 69.9075 67.9397 72.7491C63.8224 75.251 59.1317 76.7889 54.311 77.1798C42.9233 78.1185 31.3794 72.6447 25.4902 63.0528C22.6237 58.3867 21.0602 52.939 21.1123 47.4912C21.1644 39.463 25.1514 31.7215 31.2231 26.2999Z"
      />
      <Path
          fillColor={fillColorFour}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.9299 60.6296C59.2359 60.2127 57.8027 59.6915 56.578 59.1177C51.1057 56.4852 51.7832 48.5091 57.6204 46.8409C57.6985 46.8148 57.7767 46.7888 57.8549 46.7627C65.8029 44.469 65.4899 33 57.933 29.5854C54.7539 28.1519 51.8874 27.5784 48.9689 27.9694C46.0503 28.3604 43.0797 29.7418 39.7441 32.192C35.0015 35.6848 32.0569 41.1586 31.9526 47.0494C31.8484 54.817 35.4445 60.9946 40.7083 64.6175C43.3403 66.4165 46.363 67.5894 49.5422 68.0322C52.7213 68.4756 56.0307 68.1106 59.2359 66.9113C60.0961 66.5987 60.9299 66.2078 61.7115 65.7909C63.9527 64.5656 63.4836 61.2552 61.008 60.6556C60.9816 60.6296 60.9558 60.6296 60.9299 60.6296Z"
      />
    </SVG>
)
PureEncryptIconOnlySVG.propTypes = {
  fill: PropTypes.string,
}
PureEncryptIconOnlySVG.defaultProps = {
  width: '97',
  height: '96',
  fillColorOne: '#4361EE',
  fillColorTwo: '#8296F4',
  fillColorThree: '#C0CAF9',
  fillColorFour: 'white',
  className: 'svg-icon'
}
export default PureEncryptIconOnlySVG