import qs from 'qs'
import { poster, seti18Locale, checkAccountType } from "./tools"
import UAParser from "ua-parser-js"

function detectBrowser(){
    let userAgent = navigator.userAgent;
    let browserName;
    let edgeRegExp= /edg/i;
    let firefoxRegExp= /firefox|fxios/i;
    let operaRegExp= /opr\//i;
    let chromeRegExp= /chrome|chromium|crios/i;
    let safariRegExp= /safari/i;
    if(edgeRegExp.test(userAgent)){
        browserName = "Edge";
    }else if(firefoxRegExp.test(userAgent)){
        browserName= "firefox";
    }else if (operaRegExp.test(userAgent)) {
        browserName="opera";
    }else if (chromeRegExp.test(userAgent)) {
        browserName="chrome";
    }else if (safariRegExp.test(userAgent)) {
        browserName="safari";
    }else{
        browserName="Unknown Browser";
    }
    return browserName;
}

const generateSuperProperties = () => {
    let properties = {
        via: "member_area"
    }
    try{
        let parser = new UAParser();
        let data = parser.getResult();

        properties.os = navigator.platform;
        properties.browser = detectBrowser().toUpperCase();
        properties.generated_from = "FE";
        properties.operating_system = (data.os.name) ? data.os.name.toLowerCase() : "";
        properties.os_version = data.os.version;
        properties.platform_name = (data.browser.name) ? data.browser.name.toLowerCase() : "";
        properties.locale = seti18Locale();
    } catch (error) {}

    return properties;
}

export const sendEvents = (data, token) => {
    Object.assign(data.event_properties, generateSuperProperties())
    data.event_properties = JSON.stringify(data.event_properties);
    data.event_name = data.event_name.toLowerCase()
    const formData = qs.stringify(data)
    poster("event/send", formData, token)
}

export const sendGuestEvents = (data) => {
    Object.assign(data.event_properties, generateSuperProperties())
    data.event_properties = JSON.stringify(data.event_properties);
    data.event_name = data.event_name.toLowerCase()
    const formData = qs.stringify(data)
    poster("event/send/anonymous", formData, "")
}

export const waitForMixpanelEvent = (data, token) => {
    Object.assign(data.event_properties, generateSuperProperties())
    data.event_properties = JSON.stringify(data.event_properties);
    data.event_name = data.event_name.toLowerCase()
    const formData = qs.stringify(data)
    return poster("event/send", formData, token)
}