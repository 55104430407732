import { useTranslation } from 'react-i18next'

const UpgradeHeading = ({getDiscountValue, selectedSubscriptionPlans}) => {

    const { t } = useTranslation();

    const selectedSubscription = selectedSubscriptionPlans.find((sub) => sub?.selected) ?? selectedSubscriptionPlans?.[0];

    return <>
            <h1>{t('upgrade_head')}</h1>
            <h4 className='upgrade-subhead'>{t('upgrade_treat')}</h4>
    </>
}
export default UpgradeHeading;