import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';
import { InfoCard, UpgradeNA } from '../../../../styles/dashboard/Main';
import { sendEvents } from '../../../../tools/mpEvents';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { getReferRequested } from '../../../../redux/refer/actions';
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions';
import { selectSub } from '../../../../tools/tools';
import { constants } from '../../../../tools/constants';
import { useTranslation } from 'react-i18next';

const UsernameInput = ({onBoardingReducer,subscriptions,setSelectedUsername,setCrashedStatus,setProtocolError,setIsPfSub}) => {

	const { t, i18n } = useTranslation();
	
	const [selectedSubscription, setselectedSubscription] = useState({});

	const dispatch = useDispatch();

	const token = localStorage.getItem("token");

	const [loading, setloading] = useState(false);

	const [data, setdata] = useState(null);

	const [noSub, setNoSub] = useState(false);

	const [crashed, setCrashed] = useState(false);

	const dataFetchedRef = useRef(false);

	const firstAutoSelect = useRef(true);

	useEffect(() => {
		const {
			loading: reduxLoading,
			subscriptions: reduxData,
			errors,
			errorType,
			resetState,
			errorMessage,
		} = onBoardingReducer;

		if (resetState || reduxLoading) {
			setloading(true);
		}
		if (reduxData?.length) {
			setdata(reduxData);
			const filterSelectedSub = selectSub(reduxData,onBoardingReducer.selectedSubs);
			let activeUser = filterSelectedSub;
			const toString = (value) => {
				return value.toString();
			};
			const UserDetails =
				activeUser
					? {
							username: activeUser?.hasOwnProperty('vpnusernames') && activeUser?.vpnusernames[0],
							isRefuneded: toString(
								activeUser?.state === "expired" &&
									activeUser?.renew_invoice === undefined
							),
					  }
					: {
							username: reduxData[0]?.hasOwnProperty('vpnusernames') && reduxData[0]?.vpnusernames[0],
							isRefuneded: toString(
								reduxData[0]?.state === "expired" &&
									reduxData[0]?.renew_invoice ===
										undefined
							),
					  };
			if(!dataFetchedRef.current){
				setselectedSubscription(UserDetails);
				setSelectedUsername(UserDetails)
				setloading(false);
				dataFetchedRef.current = true;
			}

		}

		if(!reduxLoading && (reduxData?.length === 0)){
			setNoSub(true);
			setCrashed(true);
			setCrashedStatus(true)
			triggerNoSubEvent(errorMessage);
		}
		if(reduxData?.length === 1 && !reduxData[0]?.hasOwnProperty('vpnusernames') ){
			setNoSub(true);
			setSelectedUsername({
				username : null,
				isRefuneded : "true",
				recurlySvc:true
			})
		}

		if (errors) {
			switch (errorType) {
				case "token_expired":
					toast.error(t(TOKENEXPIRED));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_exception":
					toast.error(t(APIFAILED));
					dispatch({ type: "LOGOUT" });
					break;
				case "token_invalid":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_default_exception":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "no_subscription_exception":
					setNoSub(true);
					setCrashed(true);
					triggerNoSubEvent(errorMessage);
					break;
				case "APIFAILED":
					setCrashed(true);
					triggerNoSubEvent(errorMessage);
					break;
				default:
					setCrashed(true);
					triggerNoSubEvent(errorMessage);
					break;
			}
		}
	}, [subscriptions,onBoardingReducer.selectedSubs]);

	const selectedUsername = (e) => {
		setProtocolError(false);
		let refundedStatus = (e.target[e.target.selectedIndex].getAttribute('data-isrefundedexpire'))
		setselectedSubscription({
			username : e.target.value,
			isRefuneded : refundedStatus
		})
        setSelectedUsername({
            username : e.target.value,
			isRefuneded : refundedStatus
        })
	};

	const triggerNoSubEvent = (errorMessage) => {
		sendEvents(
			{
				event_name: "ma_fail_subscription_details",
				event_properties: {
					reason: errorMessage ?? "Unable to fetch subscriptions.",
				},
			},
			token
		);
	};


  return (
    <>
    {!noSub && <InfoCard noflex hasborder 
    >
        <p>{t('manualconf_selected_username')}</p>
        <Input
            type="name"
            className="mt-2 selectUsername"
            name="select"
            onChange={selectedUsername}
            id="username-wg"
			value={selectedSubscription?.username}
			disabled
            >
        </Input>
    </InfoCard>}
    </>
  )
}
const mapStateToProps = (state) => {
	return {
		subscriptions: state.subscriptionsReducer,
		onBoardingReducer: state.onBoardingReducer,
		
	};
};

export default connect(mapStateToProps, {
	getSubscriptionsRequest,
	getReferRequested,
})(UsernameInput);
