import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { InfoStyles } from "../../../../styles/dashboard/Info"
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";
import { PrimaryBtn } from '../../../../styles/Generic';

const Info = ({content, backdrop, modal, toggle, modalWidth, widthCalculator, className, toggleLicensesModal}) => {

    return <Modal backdrop={backdrop} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
        <>
        <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
            <ModalBody className={`${className} pb-0`}>
                <PopupBody>
                    
                    <InfoStyles>
                        <div className="info-head mb-3">
                        {content}
                        </div>
                    </InfoStyles>
                </PopupBody>
            </ModalBody>
            {toggleLicensesModal && <ModalFooter>
                <div className='m-auto mt-2'>
                    <PrimaryBtn isTeams3 onClick={()=>{toggle(); toggleLicensesModal()}}>Back</PrimaryBtn>
                </div>
            </ModalFooter>}
        </>
    </Modal>
}

export default Info;