import { customStyles } from '../table/tableCustomStyles';
import { PrimaryBtn } from '../../../../styles/Generic';
import { toast } from 'react-toastify';
import { toTitleCase } from '../../../../tools/tools';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ErrorBoundary from '../../../ErrorBoundary';
import TableLoader from '../table/tableLoader';
import { FlagIcon } from '../../../../styles/flags/Flag';

const DedicatedIpTable = ({ openConfigureModal, openAddMoreModal, totalRows, dedicatedIps, isDedicatedIpsLoaded, dedicatedIpsErrorMsg, perPage, setPage, setPerPage, selectedSubscriptionData, fetchCountAndMembers, setDedicatedIpsAgain }) => {
    const { t } = useTranslation();
    const columns = [
        {
            name: 'Team Member',
            selector: row => row.hostname,
            cell: row => <div className='member'>
            <img src={`https://ui-avatars.com/api/?background=D9D0FF&color=001219&name=${row?.team_member?.email?.substring(0, 2)}`} width={36} height={36} alt={row.name}></img>
            <div className='info'>
              <div className='email'>{row?.team_member?.email}</div>
            </div>
            </div>,
            grow: 3,
            minWidth: '225px',
            fixed: true
        },
        {
            name: 'Location',
            selector: row => row.hostname,
            cell: row => <div className='server-ip-cell'>
                    <FlagIcon className='m-0'>
                        <span className={`bg-${row.country_ios2?.toLowerCase()}`}></span>
                    </FlagIcon>
                    <div className='info'>
                        <h5>{row.country}</h5>
                    </div>
                </div>,
            grow: 1,
            minWidth: '225px',
            fixed: true
        },
        {
            name: 'IP address',
            selector: row => row.hostname,
            cell: row => <div className='server-ip-cell'>
                <h5 title={row.ip}>{row.ip ?? '---'}</h5>
                {row.ip && <button onClick={copyHost} data-server={row.ip}></button>}
            </div>,
            grow: 2,
            minWidth: '225px',
            fixed: true
        },
        {
            name: 'Hostname',
            selector: row => row.hostname,
            name: 'hostname',
            cell: row => <div className='server-ip-cell'>
                <h5 title={row?.hostname ?? '---'}>{row?.hostname ? row?.hostname?.substring(0, 40)+(row?.hostname.length>40 ? '...' : '') : '---'}</h5>
                {row?.hostname && <button onClick={copyHost} data-server={row?.hostname ?? '---'}></button>}
            </div> 
            ,
            grow: 2,
            minWidth: '225px',
            fixed: true
        },
    ];
    const conditionalRowStyles = [
        {
            when: row => row.status === 'deploying',
            style: {
              '.rdt_TableCell:not(:first-child)': {
                minWidth: 0,
                flexGrow: 0,
                padding: '0 !important',
                border: '0 !important',
              }
            },
        },
    ]
    const NoDedicatedIp = () => {
        return (
            <div className='waitlist-container'>
                <div className='waitlist-box-table dedicated-ip'>
                <span></span>
                {!selectedSubscriptionData?.is_child ? 
                    <h4>{`${selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'dedicated_ip' || addon?.code === 'port_forwarding_with_dedicated_ip') ? 'Configure your first Dedicated IP' : 'You haven\'t purchased Dedicated IP'}`}</h4> 
                    :<h4>Upgrade Your Team's Connectivity: Request a Dedicated IP Now!</h4>
                }
                
                {!selectedSubscriptionData?.is_child ? 
                    <p>{`${selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'dedicated_ip' || addon.code === 'port_forwarding_with_dedicated_ip') ? 'Configure and assign dedicated IP to enable secure network access.' : 'Get Dedicated IP to connect multiple devices with a single IP address simultaneously.'}`}</p>
                    :
                    <p>Enhance your team's online experience by asking the admin to secure a Dedicated IP for seamless connectivity.</p>
                }
                {!selectedSubscriptionData?.is_child && <PrimaryBtn className="primary teams w-auto" onClick={()=>selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'dedicated_ip' || addon.code === 'port_forwarding_with_dedicated_ip') ? openConfigureModal() : openAddMoreModal()}>{selectedSubscriptionData?.add_ons.find((addon) => addon?.code === 'dedicated_ip' || addon?.code === 'port_forwarding_with_dedicated_ip') ? 'Configure Now' : 'Get New Dedicated IP'}</PrimaryBtn>}
                </div>
            </div>
        )
    }
    const copyHost = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.getAttribute('data-server'))
            toast.success('Copied to clipboard')
        } catch (error) {
            toast.error(t('unable_to_copy_text'))       
        }
    }
    const handlePageChange = page => {
        setPage(page)
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }
    const paginationComponentOptions = {
        noRowsPerPage: true
    };
  return (
        <DataTable
            columns={columns}
            data={dedicatedIps}
            pagination
            paginationServer
            responsive
            persistTableHead
            progressPending={!isDedicatedIpsLoaded}
            progressComponent={<TableLoader />}
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[perPage]}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
            noDataComponent={!dedicatedIpsErrorMsg ? <NoDedicatedIp /> : 
            <ErrorBoundary
                heading={dedicatedIpsErrorMsg ?? "Oops!"}
                className="mt-5 mb-5"
                tglCartApi={setDedicatedIpsAgain}
                isCartError={true}
            />}
        />   
    )
}
export default DedicatedIpTable;





