import React, { useState } from 'react'
import {  InfoCard, PopupBody, PurchaseBox } from '../../../../styles/dashboard/Main'
import { Modal, ModalBody, Input, ModalFooter } from 'reactstrap';
import { PrimaryBtn } from '../../../../styles/Generic';
import { useLocation, withRouter } from 'react-router-dom';
import { countriesList } from '../../../../tools/countries';
import qs from 'qs'
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { getSubscriptionsRequest, resetSubscriptionRequest } from '../../../../redux/subscriptions/actions';
import { buttonText, postMethod } from '../../../../tools/genericMethods';
import { useTranslation } from 'react-i18next';

const UpdateCountry = ({backdrop, toggle, modal, modalWidth, widthCalculator, getSubscriptionsRequest, resetSubscriptionRequest}) => {

    const dispatch = useDispatch()

    const [country, setCountry] = useState("")

    const { t, i18n } = useTranslation();

    const [loading, setloading] = useState(false)

    const [error, setError] = useState(false)

    const token = localStorage.getItem("token")

    const handleChange = (e) => {
        setError(() => e.target.value ? false : true);
        setCountry(e.target.value);
    }

    const handleSubmit = async () => {
        setloading(true)
        if(error || !country){
            setError(true);
            setloading(false);
            return;
        }
        const formdata = qs.stringify({
            country: country
        })
        postMethod("account/update-country", formdata, token, 
            // This function will call on success response
            (response) => {
                toast.success(t('update_country_country_updated'))
                dispatch(resetSubscriptionRequest())
                toggle()
                dispatch(getSubscriptionsRequest(token))
            }, 
            // Loading false once API completed
            () => setloading(false), 
            // Logout function will dispatch if logout error occured
            () => dispatch({type:"LOGOUT"})
        )
    }
    

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
                <ModalBody>
                    <PopupBody>
                        <div className="mod-head">
                            <h3>{t('update_country_please_select_your_country')}</h3>
                            <p>{t('update_country_select_country_para')}</p>
                        </div>
                        <div className="mod-body">
                            <InfoCard noflex className="p-0">
                                <Input type="select" name="select" value={country} onChange={handleChange} className="mt-2">
                                    <option value="">{t('update_country_select_your_country')}</option>
                                    {
                                        Object.entries(countriesList).map((country, key)=>(
                                            <option value={country[0]}>{country[1]}</option>
                                        ))
                                    }
                                </Input>
                                {
                                    error && <p className="danger mt-2 ps-1">{t('update_country_is_required')}</p>
                                }
                            </InfoCard>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <PrimaryBtn pointer={loading} color="primary" onClick={handleSubmit}>{buttonText(loading, "Submit")}</PrimaryBtn>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default connect(null, {getSubscriptionsRequest, resetSubscriptionRequest})(withRouter(UpdateCountry))