import { CardNumber, InfoCard, OrderSummary } from '../../../../styles/dashboard/Main'
import { Button } from 'reactstrap';
import { constants } from "../../../../tools/constants";
import { DowngradeStyles } from "../../../../styles/dashboard/subscription/cancellation/downgrade"
import { poster, getTokenFromLocalStorage, dispatchError, displayError, dateFormat, currencySymbol} from "../../../../tools/tools";
import { subscriptionRequested } from '../../../../redux/account/actions'
import { toast } from "react-toastify";
import { useDispatch,connect} from 'react-redux';
import { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from 'classnames'
import qs from 'qs';
import Skeleton from 'react-loading-skeleton';
import SubmitConsent from "../SubmitConsent";
import PaymentIcon from '../../../../utils/PaymentIcon/PaymentIcon';

const Downgrade = ({
        planCode,
        id,
        state,
        toggle,
        subscriptionReducer,
        history,
        downGradePreview,
        setDowngradePreview
    }) => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [subscription, setSubscription] = useState();
    const [loading, setLoading] = useState(true);

    const [orderSummary, setorderSummary] = useState(true)
    

    useEffect(()=>{
        sendDowngradeSummaryRequest()
    }, [downGradePreview])

    const sendDowngradeSummaryRequest = async() => {
        const selectedSubscription = subscriptionReducer.subscriptions.body.find(sub=>sub.id === id)
        setLoading(true)
        try{
            let params = {
                subscription_id: id,
                plan_code: planCode,
                preview: downGradePreview,
                isPastDue: constants.past_due_state.includes(state ?? 'past_due') ? true : false
            }
            const requestData = qs.stringify(params);
            const { data } = await poster('subscription/change', requestData, getTokenFromLocalStorage())
            if(data?.status){
                if(!downGradePreview){
                  toggle();
                  toast.success(data?.message ?? 'success')  
                }
                else{
                    setData(data?.body)
                    setSubscription(selectedSubscription)
                }
            }
            else{
                dispatchError(data?.error_code, dispatch);
                toggle();
            }
        }
        catch (error) {
            displayError(error);
            toggle();
        }
        finally{
            setLoading(false)
        }
    }

    const onPaymentChange = () => {
        toggle()
        history.push({
            pathname: '/dashboard/subscriptions',
            hash: 'paymentMethod', 
            search: `?id=${id}`
        })
    }

    return loading ? 
        <>
            <InfoCard hasborder noflex className="mb-3">
                <Skeleton height={20} width={300}/>
            </InfoCard>
            <InfoCard hasborder noflex className="pt-0 mb-3">
                <Skeleton height={20} width={300}/>
            </InfoCard>
            <InfoCard hasborder noflex className="pt-0 mb-3">
                <Skeleton height={20} width={300}/>
            </InfoCard>
            <InfoCard hasborder noflex className="pt-0 mb-3">
                <Skeleton height={20} width={300}/>
            </InfoCard>
            <InfoCard noflex className="pt-0">
                <Skeleton height={20} width={300}/>
            </InfoCard>
        </>
    : <>
        <DowngradeStyles>
            <div className="content-body-for-downgrade">
                <div className="heading">
                    <h3>PureVPN Plan Renewal</h3>
                </div>
                <InfoCard hasborder noflex className="pt-0">
                    <div className="info">
                        <label>Plan Type: </label>
                        <p><strong className="text-capitalize">{`${subscription?.plan?.interval_length} ${subscription?.plan?.interval_length === 1 ? "Month" : "Months"}` || "-"}</strong></p>
                    </div>
                    <div className="info">
                        <label>Username: </label>
                        <p><strong>{subscription?.vpnusernames?.[0] || "-"}</strong></p>
                    </div>
                    {/* <div className="info">
                        <label>Service Period: </label>
                        <p><strong>{`${dateFormat(subscription?.start_date)} - ${dateFormat(subscription?.end_date)}` || "-"}</strong></p>
                    </div> */}
                </InfoCard>
                <InfoCard hasborder noflex>
                    <OrderSummary open={orderSummary}>
                        <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">Order summary</strong></label>
                        <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                            {
                                data && data?.items && data?.items.map((item, key)=>(
                                    <>
                                        <li key={key}>
                                            {item?.code?.toLowerCase() === constants.purescan ? "PurePrivacy" : item?.description} <strong className="text-end">{currencySymbol[item?.currency]}{Object.keys(data?.items).length !== 0 ? item?.unit_amount?.toFixed(2) : "-"}</strong>
                                        </li>
                                    </>
                                ))
                            }
                        </ul>
                    </OrderSummary>
                </InfoCard>
                <InfoCard hasborder>
                    <div className="float-start">
                        <label><strong className="ms-0">Total amount</strong> </label>
                    </div>
                    <div className="float-end">
                        <label><strong>{currencySymbol[data?.items?.[0]?.currency]}{data?.total.toFixed(2)}</strong> </label>
                    </div>
                    <div className="float-none"></div>
                </InfoCard>
                <InfoCard>
                    <div className="float-start addon-purchase">
                        <PaymentIcon lastFour={subscription?.billing_info?.last_four} gateway={subscription?.payment_gateway}/>
                    <p onClick={onPaymentChange}>Change payment method</p>
                    </div>
                </InfoCard>
                <InfoCard>
                    <SubmitConsent />
                </InfoCard>
            </div>
        </DowngradeStyles>
        <div>
            <Button className='primary-btn solid-btn' onClick={()=>setDowngradePreview(0)}>
                Confirm
            </Button>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return { 
        subscriptionReducer: state.subscriptionsReducer
    };
};

export default connect(mapStateToProps, {subscriptionRequested})(withRouter(Downgrade));