import React, {useState, useEffect} from 'react'
import { CardBox, CardNumber, InfoCard } from '../../../styles/dashboard/Main'
import { DestructBtn, Table } from '../../../styles/Generic'
import { useParams } from 'react-router-dom';
import logo from '../../../assets/logo_white_new.svg'
import { connect, useDispatch } from 'react-redux';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages';
import { toast } from 'react-toastify';
import { getInovicesRequested } from '../../../redux/invoices/actions';
import Skeleton from 'react-loading-skeleton';
import paypal from '../../../assets/paypal.png'
import {constants} from "../../../tools/constants";
import { useTranslation } from 'react-i18next';
import PaymentIcon from '../../../utils/PaymentIcon/PaymentIcon';

const PaymentDetails = ({history, invoices, accounts: reduxAccounts, getInovicesRequested}) => {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch()

    const { subid, id } = useParams();

    const [data, setData] = useState({
        response:{},
        loading:true
    })

    const token = localStorage.getItem("token")

    const apiData = {
        token:token,
        data:{
            subscription_id: subid,
            limit:99
        }
    }

    const currencies = {
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "$",
        CAD: "$"
    }

    useEffect(() => {
        getInovicesRequested(apiData)
    }, [])

    useEffect(() => {
        const { loading: reduxLoading, invoices: reduxData, errors, errorType } = invoices;
        
        if(reduxData?.[subid]){
            setData({
                ...data, 
                response:reduxData?.[subid]?.body?.invoices.find(invoice=>invoice.id === id),
                loading:false
            });
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                           
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break;      
                case "APIFAILED":
                    toast.error(t(APIFAILED))
                    break;     
                default:
                    toast.error(t(APIFAILED));
                    break;
            }
        }
    }, [invoices])

    return (
        <>
            <div className="table-row">
                <div className="col-md-10">
                    <InfoCard className='imgTextView'>
                        <img src={logo} className="img-fluid"/>
                        <p style={{"color":"#7B648C"}}>
                        GZ Systems Ltd. <br />
                        36/F, Tower Two, Times Square, <br />
                        1 Matheson Street, Causeway Bay, <br />
                        Hong Kong
                        </p>
                    </InfoCard>
                    <InfoCard noflex>
                        <div>
                            {/* <label className="me-2 mb-2 d-block">Name:<strong>{reduxAccounts.accounts?.body?.fullName ?? "-"}</strong></label> */}
                            <label className="me-2 mb-2 d-block">{t('tbl_email')}<strong>{reduxAccounts.accounts?.body?.email ?? "-"}</strong></label>
                            <label className="me-2 mb-2 d-block">{t('payment_details_company')}<strong>PureVPN</strong></label>
                            {/* <label className="me-2 d-block">Country:<strong>US</strong></label> */}
                        </div>
                    </InfoCard>
                    
                    {
                    !data.loading 
                    ?
                    <>
                        <div className="table-wrapper mt-4 mb-5">
                        <Table tdescription className="table" align="top">
                            <thead>
                                <tr>
                                    <th>{t('payment_details_table_invoice')}</th>
                                    <th>{t('payment_details_table_status')}</th>
                                    <th>{t('payment_details_description')}</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="Invoice #">#{data?.response?.id}</td>
                                        <td data-label="Status" className={`fw-bold text-uppercase ${data?.response.status !== "paid" && "danger"}`}>{data?.response.status === "past_due" ? "unpaid" : data?.response.status.split("_").join(" ")}</td>
                                        <td data-label="Description">
                                            <ul className="list-unstyled p-0 m-0">
                                                {
                                                    data?.response?.items.map(item=>(
                                                        <li>
                                                            <span className="text-capitalize">
                                                                {item?.description}
                                                                {item?.start_date?.length > 0 ? `- (${item?.start_date} - ${item?.end_date})` : ``}
                                                            </span>
                                                            <span className="invoice-price">{currencies[data?.response?.currency] || "$"}{item?.unit_amount.toFixed(2)}</span>
                                                        </li>
                                                    ))
                                                }
                                                <li>
                                                    <span className="text-capitalize">{t('payment_history_table_vat')}</span>
                                                    <span className="invoice-price">{currencies[data?.response?.currency] || "$"}{data?.response?.invoice_tax.toFixed(2)}</span>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="right"><h3 className="m-0">Total</h3></td>
                                        <td colSpan="1" align="right"><h3 className="m-0">{currencies[data?.response?.currency] || "$"}{data?.response?.paid.toFixed(2)}</h3></td>
                                    </tr>
                                    {data?.response?.credit>0 &&
                                    <tr>
                                        <td colSpan="2" align="right"><h3 className="m-0">Credit Applied</h3></td>
                                        <td colSpan="1" align="right"><h3 className="m-0">{currencies[data?.response?.currency] || "$"}{data?.response?.credit.toFixed(2)}</h3></td>
                                    </tr>
                                    }
                                    
                                    <tr>
                                        <td colSpan="2" align="right"><h3 className="m-0">Paid</h3></td>
                                        <td colSpan="1" align="right"><h3 className="m-0">{currencies[data?.response?.currency] || "$"}{(data?.response?.paid-data?.response?.credit).toFixed(2)}</h3></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <InfoCard className="pt-0">
                            <label className="me-2 mb-3 d-flex align-items-center">
                            {t('payment_history_table_payment_method')} 
                                <span className='mx-1'>
                                    <PaymentIcon gateway={data?.response?.payment_gateway} lastFour={data?.response?.billing_info?.last_four} hideStar={true}/>   
                                </span>
                            </label>
                        </InfoCard>
                        <DestructBtn onClick={() => history.push(`/dashboard/payment-history/${subid}`)}>{t('payment_details_go_back_cta')}</DestructBtn>
                    </>
                    :
                    <CardBox>
                        <InfoCard hasborder noflex className="mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard noflex className="pt-0">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                    </CardBox>
                }
                </div>
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        invoices: state.invoicesReducer,
        subscriptions: state.subscriptionsReducer,
        accounts:state.accountReducer
     };
};

export default connect(mapStateToProps, {getInovicesRequested})(PaymentDetails)