import { checkAccountType, getPaymentMethods, getTokenFromLocalStorage, isWhmcsUser } from "../../../tools/tools";
import { connect } from "react-redux";
import { securityToolsPlans, constants, securityToolsSlugs } from "../../../tools/constants";
import { InfoCard, SubscriptionBox } from "../../../styles/dashboard/Main";
import { PrimaryBtn } from "../../../styles/Generic";
import { SecurityToolsContent, featureContent, SecurityToolsErrorMsg } from "../content/index";
import { SecurityToolsFeatureStyles } from "../../../styles/dashboard/security-tools/securityTools";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BillingCycle from "../../dashboard/subscriptions/modals/BillingCycle";
import Download from "../featureTemplate/download";
import ErrorBoundary from "../../ErrorBoundary";
import FeatureInfo from "../featureTemplate/featureInfo";
import FeatureIntro from "../featureTemplate/featureIntro";
import HowItWorks from "../featureTemplate/howItWorks";
import NotSupported from "../../dashboard/subscriptions/modals/NotSupported";
import PasswordGenerators from "../features/password-generator/index";
import RemoveMyData from "../features/remove-my-data";
import Skeleton from "react-loading-skeleton";
import { sendEvents } from "../../../tools/mpEvents";
import { useLocation } from "react-router-dom";

const Features = ({history, onBoardingReducer}) => {

    const { t } = useTranslation();

    const token = getTokenFromLocalStorage();

    const { slug } = useParams();

    const [loading, setloading] = useState(true);

    const [modalType, setmodalType] = useState('addon-lg');

    const [openModal, setOpenModal] = useState();

    const [selectedSubscription, setSelectedSubscription] = useState();

    const [orderSummary,setorderSummary] = useState(true);

    const [upgradePlanData, setUpgradePlanData] = useState();

    const [crashed, setCrashed] = useState(false);

    const [isConverted, setIsConverted] = useState(false);
    
    const [notSupportedModal, setNotSupportedModal] = useState({
        show: '',
        type: ''
    });

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    });

    const randomNumber = localStorage.getItem('RandomNum');
    
    const widthCalculator = modalType.slice(-2);

    const { search } = useLocation();

    const { utm_medium, utm_campaign } = Object.fromEntries(new URLSearchParams(search));

    useEffect(()=>{
        sendEvents({
            event_name: "ma_pagevisited",
            event_properties: {
                visitedPageURL: window.location.href ?? "N/A",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            },
        }, token);
    }, [])

    useEffect(()=>{
        if(!securityToolsSlugs.includes(slug)) history.push('/dashboard/security-tools')
    }, [])

    useEffect(()=>{
        
        const { selectedSubs, subscriptions: subs, loading } = onBoardingReducer;

        if(!loading && subs?.length){
            setloading(false)
            setSelectedSubscription(subs.find((sub)=> sub?.id == selectedSubs) ?? subs?.[0])
        }
        if(loading){
            setloading(true)
        }
        

    }, [onBoardingReducer.selectedSubs, selectedSubscription, onBoardingReducer]);
    
    const onClickPurchaseCta = () => {

        //SINCE WE DON'T HAVE THIS FROM BE, NEED TO HAVE AN API THAT SUPPORTS BUNDLE ELIGIBILTY 

        if(selectedSubscription?.is_trial){
            toggleNotSupportedModal({content: 'trial_user'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['securitycart_eligible_trial'])
        }
        else if(selectedSubscription?.hasVolumePlan && !selectedSubscription?.is_child){
            toggleNotSupportedModal({content: 'hasVolumeLicensing'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['not_supported_manager'])
        }
        else if(selectedSubscription?.hasVolumePlan && selectedSubscription?.is_child){
            toggleNotSupportedModal({content: 'volumeLicensingChild'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['notsupported_child'])
        }
        else if(selectedSubscription?.hasFamilyPlan && !selectedSubscription?.is_child){
            toggleNotSupportedModal({content: 'hasFamilyPlan'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['not_supported_manager'])
        }
        else if(selectedSubscription?.hasFamilyPlan && selectedSubscription?.is_child){
            toggleNotSupportedModal({content: 'familyPlanChild'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['notsupported_child'])
        }
        else if(selectedSubscription?.payment_gateway.toLowerCase() == 'reseller'){
            toggleNotSupportedModal({content: 'reseller'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['notsupported_reseller'])
        }
        else if(selectedSubscription?.state == "cancelled"){
            toggleNotSupportedModal({content: 'renew'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['securitycart_eligible_renew'])
        }else if(selectedSubscription?.hasPureTeams){
            toggleNotSupportedModal({content: 'hasFamilyPlan'});
            sendEventOnIneligibityPopup(SecurityToolsErrorMsg['not_supported_manager'])
        }else{
            let todayDate = new Date().toJSON();

            sendEvents({
                event_name:"ma_click_get_security_tools",
                event_properties: {
                    account_type: checkAccountType(selectedSubscription) ?? 'N/A',
                    current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                    billingcycle : selectedSubscription?.type ?? 'N/A',
                    payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                    current_status: selectedSubscription?.state ?? 'N/A',
                    product: featureContent[slug]?.product ?? 'N/A',
                    feature_name: slug ?? 'N/A',
                    plan_offer: 'puremax',
                    probability: randomNumber
                }
            }, token);

                setUpgradePlanData({
                    plan: selectedSubscription?.max_upgrade?.plan_code,
                    subscription:selectedSubscription?.id,
                    service_origin:selectedSubscription?.service_origin,
                    isWhmcsUser:isWhmcsUser(selectedSubscription?.account?.billingType),
                    currency:selectedSubscription?.currency,
                    interval_length: selectedSubscription?.plan?.interval_length,
                    interval_unit: selectedSubscription?.plan?.interval_unit,
                    start_date: todayDate,
                    expiry_date: selectedSubscription?.end_date, 
                    sub_plan_type: selectedSubscription?.max_upgrade?.plan_type, 
                    current_plan: selectedSubscription?.plan_type, 
                    state: selectedSubscription?.state,
                    billingcycle: selectedSubscription?.type,
                    account_type: checkAccountType(selectedSubscription),
                    source: 'security-tools',
                    feature_name: slug
                });
                setOpenModal(true)
            
        }
    }

    const hasPurchasedAddon = () => {
        return onBoardingReducer?.subscriptions.filter((sub)=> sub?.add_ons?.find((addon)=> addon?.code === featureContent[slug]?.product) || securityToolsPlans[sub?.plan_type].includes(featureContent[slug]?.product))?.length > 0 ? true : false
    }

    const toggle = () => {
        setOpenModal(!openModal)
    }

    const sendEventOnIneligibityPopup = (reason) => {
        sendEvents({
            event_name:"ma_view_feature_popup",
            event_properties: {
                account_type: checkAccountType(selectedSubscription) ?? 'N/A',
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle : selectedSubscription?.type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                current_status: selectedSubscription?.state ?? 'N/A',
                product: featureContent[slug]?.product ?? 'N/A',
                feature_name: slug ?? 'N/A',
                reason: reason ?? 'N/A',
                feature_name: slug ?? 'N/A',
                product: featureContent[slug]?.product ?? 'N/A',
            }
        }, token);
    }

    const toggleNotSupportedModal = (payload) => {
        const { content } = payload;
        content ? 
        setNotSupportedModal({
            show: true,
            type : content
        }) : 
        setNotSupportedModal({
            show: false,
            type: ''
        })

    }

    return <> { 
        crashed ?  
        <ErrorBoundary /> : 
        <SecurityToolsFeatureStyles>

            {
                loading ? 
                <SubscriptionBox open={true}>
                    <InfoCard hasborder noflex className="mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard noflex className="pt-0">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                </SubscriptionBox> :
                <>
                    <section className="security-tools">
                        <h3>{t(SecurityToolsContent?.features.find((feature)=> feature?.slug === slug)?.heading)}</h3>
                        <p>{t(SecurityToolsContent?.features.find((feature)=> feature?.slug === slug)?.paragraph)}</p>
                    </section>
                
                    {slug === 'remove-my-data' && <RemoveMyData slug={slug} onClickPurchaseCta={onClickPurchaseCta} hasPurchasedAddon={hasPurchasedAddon()} setCrashed={setCrashed} selectedSubscription={selectedSubscription} isConverted={isConverted}/>}
                        
                        
                    {slug === 'password-generator' && <PasswordGenerators slug={slug}/>}

                    {slug !== 'password-generator' && slug !== 'remove-my-data' && <>

                        <FeatureIntro slug={slug} />

                        {!hasPurchasedAddon() ? <PrimaryBtn onClick={onClickPurchaseCta} className="m-auto d-flex" isPrivacy={featureContent[slug]?.product == constants['purescan']} isKeep={featureContent[slug]?.product == constants['purekeep']} isEcrypt={featureContent[slug]?.product == constants['purecrypt']}>{t(featureContent[slug]?.purchase_btn)}</PrimaryBtn> : null}                        
                        <FeatureInfo slug={slug} />

                        {hasPurchasedAddon() ? <Download slug={slug} /> : null}

                        <HowItWorks slug={slug}/>

                        {!hasPurchasedAddon() ? <PrimaryBtn onClick={onClickPurchaseCta} className="m-auto d-flex" isPrivacy={featureContent[slug]?.product == constants['purescan']} isKeep={featureContent[slug]?.product == constants['purekeep']} isEcrypt={featureContent[slug]?.product == constants['purecrypt']}>{t('get_started_text')}</PrimaryBtn> : null}
                    </>}

            </>
        
            }

        
        </SecurityToolsFeatureStyles> 
    }
    
    {/* ------------ MODALS ------------ */}

    {openModal  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={upgradePlanData} widthCalculator={widthCalculator} modal={openModal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} redirectedFrom="featureUpgrade" setIsConverted={setIsConverted} />}
    {notSupportedModal.show && <NotSupported backdrop={false} notsupportedState={notSupportedModal} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={notSupportedModal.show} toggle={toggleNotSupportedModal}/>}    
    </>
}

const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer, 
    };
};

export default connect(mapStateToProps, null)(withRouter(Features));
