import { useState, useEffect } from "react";
import { get, getTokenFromLocalStorage } from "../../../../tools/tools";

const useMemberListing = (url, payload) => {
  const token = getTokenFromLocalStorage();

  const [loadMembers, setLoadMembers] = useState();
  const [members, setMembers] = useState();
  const [listingError, setListingError] = useState();

  useEffect(() => {
    fetchMemberList();
  }, []);

   const fetchMemberList = async () => {
    setLoadMembers(true)
    try {
        const apiResponse = await get(`${url}${payload ? `?sub_id=${payload}` : ''}`, token);
        const { data: membersListing } = apiResponse;
        if (membersListing?.status) {
            setMembers(membersListing?.body?.team_members);
        } else {
            setListingError(membersListing?.message);
        }
    } catch (error) {
        setListingError(error);
    }
    finally{
        setLoadMembers(false)
    }
}
  
  return { members, listingError, loadMembers, setLoadMembers };
};

export default useMemberListing;
