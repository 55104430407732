import React, { useState } from "react";
import { InfoCard, WireGuardKeysCard } from "../../../../styles/dashboard/Main";
import { DestructBtn, InsetBtn, PrimaryBtn } from "../../../../styles/Generic";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { sendEvents } from "../../../../tools/mpEvents";
import copy from "../../../../assets/copy.svg";
import { useEffect } from "react";

const GenerateWireGuardKey = ({
	setAgain,
	reGen,
	setRegGen,
	WGLoading,
	setWGLoading,
	isResetTimer,
	WGErrorMessage,
	wgDisplayableData,
	subscription,
	endWGTimer,
	wgStoredData,
	sendWGDataToSubsComponent,
	selectedSubscription,
	serverType
	
}) => {
	const token = localStorage.getItem("token");
	const downloadWGConfiguration = (configuration) => {
		let fileName = configuration?.countryName;
		let downloadableConfigs =
			configuration?.WGKey?.wireguard_configuration.replace(
				"{clientPrivateKey}",
				wgStoredData.wgPrivateKey
			);

		const element = document.createElement("a");
		const file = new Blob([downloadableConfigs], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		element.download = `${fileName}-wg.conf`;
		document.body.appendChild(element);
		element.click();

		sendEvents(
			{
				event_name: "ma_generate_manual_config",
				event_properties: {
					protocol:"WireGuard",
					country: wgStoredData.country,
					city: wgStoredData?.city?.name,
					device: wgStoredData.device,
					is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
					is_p2p_server_requested: serverType?.includes("p2p"),
					gen_via:reGen?"Regenerated":"Generated"
				},
			},
			token
		);
	};
	useEffect(()=>{
		downloadWGConfiguration(wgDisplayableData);

	},[wgDisplayableData])
	const copyWGConfigurations = async (configuration) => {
		try {
			let copiedConfigs =
				configuration?.WGKey?.wireguard_configuration.replace(
					"{clientPrivateKey}",
					wgStoredData.wgPrivateKey
				);
			await navigator.clipboard.writeText(copiedConfigs);
			toast.success("Configurations Copied Successfully!");

			sendEvents(
				{
					event_name: "ma_copy_manual_config",
					event_properties: {
						protocol:"Wireguard",
					},
				},
				token
			);
		} catch (error) {
			toast.error("Unable to copy!");
		}
	};
	const reGenerateConfigurations = async () => {
		setRegGen(true);
		setAgain(false);
		sendWGDataToSubsComponent({
			country: wgStoredData.country,
			city: wgStoredData.city,
			device: wgStoredData.device,
			wgEncrypPass: wgStoredData.wgEncrypPass,
			selectedSubscriptionData: selectedSubscription,
			wgPublicKey: wgStoredData.wgPublicKey,
			wgPrivateKey: wgStoredData.wgPrivateKey,
			reGenereated: true,
		});
		setWGLoading(true);
	};
	const onCancel = async () => {
		setAgain(false);
		setRegGen(false);
		isResetTimer(true);
		sendEvents(
			{
				event_name: "ma_click_cancel_manual_config",
				event_properties: {
					protocol:"Wireguard",
					country: wgStoredData.country,
					city: wgStoredData.city,
					device: wgStoredData.device,
					is_obfuscated_server_requested: serverType?.includes("OVPN_OBF"),
					is_p2p_server_requested: serverType?.includes("p2p")
				},
			},
			token
		);
	};

	return (
		<InfoCard noflex className="py-0">
			{!WGErrorMessage ? (
				<>
					{wgDisplayableData &&
					wgDisplayableData?.username === subscription ? (
						<WireGuardKeysCard>
							<InfoCard className="py-0">
								<div className="float-start">
									{!endWGTimer ? (
										<label className="me-2">
											Location:{" "}
											<strong>
												{`${
													wgDisplayableData?.cityName
														? wgDisplayableData
																?.cityName + ", "
														: ""
												}`}
												{
													wgDisplayableData
														?.countryName
												}
											</strong>
										</label>
									) : (
										<label className="me-2">
											Configuration for 
											{" "}<strong>
												{`${
													wgDisplayableData?.cityName
														? wgDisplayableData
																?.cityName + ", "
														: ""
												}`}
												{	
												wgDisplayableData?.countryName
												}
											</strong>{" "}
											has expired.
										</label>
									)}
								</div>

								<div className="float-none"></div>
							</InfoCard>
							{endWGTimer ? (
								<InfoCard>
									<PrimaryBtn
										className="me-2"
										data-qamarker="regenerateWGConf"
										onClick={reGenerateConfigurations}
										color="primary"
									>
										Regenerate Configuration
									</PrimaryBtn>
									<DestructBtn
										data-qamarker="copyWGConf"
										onClick={(e) => onCancel()}
									>
										Cancel
									</DestructBtn>
								</InfoCard>
							) : (
								<></>
							)}
							{!endWGTimer ? (
								<>
									<InfoCard className="py-2">
										<div className="float-start">
											<label className="me-2">
												Expires in:{" "}
												<strong id="time"> 30:00</strong>
												<strong> min</strong>
											</label>
										</div>

										<div className="float-none"></div>
									</InfoCard>
									<InfoCard className="py-0">
										<div className="float-start align-items-center">
											<label className="me-2">
												WireGuard Configuration:{" "}
												<strong>************</strong>
											</label>
											<img
												src={copy}
												className="copyButton"
												onClick={(e) =>
													copyWGConfigurations(
														wgDisplayableData
													)
												}
											/>
										</div>
									</InfoCard>
									<InfoCard className="mt-3 d-flex flex-row align-items-center py-0 pb-3">
										<DestructBtn
											data-qamarker="copyWGConf"
											onClick={(e) => onCancel()}
										>
											Cancel
										</DestructBtn>
									</InfoCard>
								</>
							) : (
								<></>
							)}
						</WireGuardKeysCard>
					) : (
						<></>
					)}
				</>
			) : (
				<WireGuardKeysCard>
					<InfoCard>
						<h3 className="me-2">{WGErrorMessage}</h3>
					</InfoCard>
				</WireGuardKeysCard>
			)}
		</InfoCard>
	);
};

export default withRouter(GenerateWireGuardKey);
