import styled from "styled-components";

export const AppliedBox = styled.div`
display: flex;
justify-content: space-between;
flex: 1;
align-items: center;
width: 100%;
padding: 10px 15px;
border-radius: 8px;
border: 1px solid #6536CC;
background: rgba(130, 96, 239, 0.05);

    span{
        color: #001219;
        font-size: 12px;
        font-weight: 700;
        line-height: 125%; /* 15px */
        letter-spacing: 0.24px;
    }
    img {
        max-width: 20px;
    }
    .check-mark {
        width: 13px;
    }
    .coupon-applied {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
    }
    div:last-child {
        margin-left: auto;
    }
}
`

export const CouponViewStyles = styled.div`
    .coupon-field{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;
        input{
            width: 72%;
            @media only screen and (max-width: 497px) {
                height: 41px;
            }
        }
        button{
            width: 25%;
            @media only screen and (max-width: 497px) {
                border-radius: 8px;
                padding: 10px 8px !important;
                width: 35%;
            }
            @media only screen and (max-width: 380px) {
                border-radius: 8px;
                padding: 10px 8px !important;
                width: 37%;
                font-size: 10px !important;
            }
        }
    }
`