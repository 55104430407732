

const permContent = {
    questions:[
        {
        q1_ug_plan:"upgradeplan_what_is_upgrade",
        a1_ug_plan:"upgradeplan_what_is_upgrade_para",},
        {
        q1_ug_plan:"upgradeplan_how_can_i_upgrade",
        a1_ug_plan_pitch:"upgradeplan_how_can_i_upgrade_para",
        a1_ug_plan:[
            'upgradeplan_how_can_i_upgrade_select_username',
            'upgradeplan_how_can_i_upgrade_select_plan',
            'upgradeplan_how_can_i_upgrade_click_upgrade',
        ],}
    ],
    faq_heading:"Frequently Asked Questions"
}


export const content = {
    standard:{
        ...permContent,
        faqs:[
            {
                faq_q1:"upgradeplan_faq_one_heading",
                faq_a1:"upgradeplan_faq_one_para",
            },
            {
                faq_q1:"upgradeplan_faq_two_heading",
                faq_a1:"upgradeplan_faq_two_para",
            },
            {
                faq_q1:"upgradeplan_faq_three_heading",
                faq_a1:"upgradeplan_faq_three_para",
            },
            {
                faq_q1:"upgradeplan_faq_four_heading",
                faq_a1:"upgradeplan_faq_four_para",
            },
            {
                faq_q1:"upgradeplan_faq_five_heading",
                faq_a1:"upgradeplan_faq_five_para",
            },
           
        ]
    },
    plus:{
        ...permContent,
        faqs:[
            {
                faq_q1:"upgradeplan_plus_faq_one_heading",
                faq_a1:"upgradeplan_plus_faq_one_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_two_heading",
                faq_a1:"upgradeplan_plus_faq_two_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_three_heading",
                faq_a1:"upgradeplan_plus_faq_three_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_four_heading",
                faq_a1:"upgradeplan_plus_faq_four_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_five_heading",
                faq_a1:"upgradeplan_plus_faq_five_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_six_heading",
                faq_a1:"upgradeplan_plus_faq_six_para",
            },
            {
                faq_q1:"upgradeplan_plus_faq_seven_heading",
                faq_a1:"upgradeplan_plus_faq_seven_para",
            },
        
        ]
    },
    max:{
        ...permContent,
        faqs:[
            {
                faq_q1:"upgradeplan_max_faq_one_heading",
                faq_a1:"upgradeplan__max_faq_one_para",
            },
            {
                faq_q1:"upgradeplan_max_faq_two_heading",
                faq_a1:"upgradeplan_max_faq_two_para",
            },
            {
                faq_q1:"upgradeplan_max_faq_three_heading",
                faq_a1:"upgradeplan_max_faq_three_para",
            },
            {
                faq_q1:"upgradeplan_max_faq_four_heading",
                faq_a1:"upgradeplan_max_faq_four_para",
            },
            {
                faq_q1:"upgradeplan_max_faq_five_heading",
                faq_a1:"upgradeplan_max_faq_five_para",
            },
            {
                faq_q1:"upgradeplan_max_faq_six_heading",
                faq_a1:"upgradeplan_max_faq_six_para",
            },
       
        ]
    }
}

