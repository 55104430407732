import styled, {css, keyframes} from "styled-components";
import lock from '../../assets/nav_lock.svg'
import arrowDownIcon from '../../assets/arrow-down-icon.svg'
import familyIcon from '../../assets/family.png'
import familyIconHover from '../../assets/family1.png';
import dashboard from "../../assets/dashboard.svg";
import pureRewards from "../../assets/pure-rewards.svg";
import volumeLicensing from "../../assets/volume-licensing.svg";


export const SidebarWrapper = styled.div`
    position:fixed;
    width:256px;
    height:100%;
    background:#F9F8FC;
    padding:30px 0;
    overflow: auto;

    &:hover {
        overflow-y: scroll;
        scrollbar-width: thin !important;
    }
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color:rgb(210, 207, 215);
        outline: none;
        border-radius:3px;
        opacity: 0.2;
    }
    .on-boarding-nav {
        margin-bottom: 10px;
        padding-bottom: 0px;
    }
    .sidebar-head{
        margin:0 0px 40px 20px;
    }
    .cursor-pointer{
        cursor:pointer;
    }
    .sidebar-body{
        margin:0 15px 0 15px;
    }
    /* -- media query for all devices -- */
    /* Large Tablets */

    @media only screen and (min-width: 992px){  
        ${props => props.isChrome ? css`
            padding: 0px 3px 30px 0px;
        ` : ` padding: 0px 0px 30px 0px; `}
        .sidebar-body{
            padding-top: 40px;
            padding-bottom: 0px;
        }
        .sidebar-head{
            position: fixed;
            width: 100%;
            z-index: 4;
            padding-top: 20px;
            margin: 0px;
            padding-left: 20px;
            background: #F9F8FC;
            padding-bottom: 20px;
        }
    }
    /* Sidebar width adjust */
    @media only screen and (max-width: 1260px) and (min-width: 1201px){
        width: 240px;
    }
    /* Mobile Devices and Small Tablets */
    /* Sidebar hide */
    @media only screen and (max-width: 1200px) {
        padding:30px 15px !important;
        .sidebar-head{
            margin: 0;
        }
        .sidebar-body{
            margin: 0;
        } 
    }  

    .sidebar-head{
        @media only screen and (max-width: 1200px){
            display: none !important;
        }
    }

    .sidebar-body{
        @media only screen and (max-width: 990px){
            padding-top: 40px !important;
        }
        @media only screen and (max-width: 560px){
            padding-top: 60px !important;
        }
        @media only screen and (max-width: 420px){
            padding-top: 80px !important;
        }
    }
`;

export const SidebarOnBoardingPopup = styled.div`

    position: absolute;
    left: 80%;
    min-width: 250px;
    z-index: 4;
    top: 0px;
    // transform: translateY(-50%);
    background: #FBFAFF;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px rgb(59 16 142 / 7%);
    border: 1.02763px solid #EEEDF0;

    .box {

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        min-width: 315px;

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
        }

        .star-icon {
            display: inline-block;
            background: ${({theme:{onboarding}})=>onboarding.sidebar_new_icon};
            width: 16px;
            height: 16px;
        }

        p {
            font-size: 11px !important;
            max-width: 255px;
            margin-bottom: 0px;
        }

        .close-icon:hover {
            cursor: pointer;
        }
    }

`

export const SidebarOnBoardingWrapper = styled.ul`
    background: rgba(255, 255, 255, 0.65);
    padding: 10px 10px;
    border-radius: 20px;
    list-style: none;

    .dropdown-wrapper {
        margin-bottom: 10px;
        border-bottom: 1px solid #DDD4E2;
        padding: 0px 0px 10px;
        position: relative;

        select.form-control{
            height: 30px;
            padding: 0px 8px;
            background: rgba(220, 211, 233, 0.5);
            border: 1px solid #DDD4E2;
            font-size: 12px;
        }

        &::after {
            
        }

        &:focus{
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .multi-select-for-subs{
        position: relative;
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(221, 212, 226);
        padding: 0px 0px 5px;
        :hover{
            ul{
                display:block;
            }
        }
        h4{
            position: relative;
            padding: 6px 8px;
            background: rgba(220, 211, 233, 0.5);
            border: 1px solid rgb(221, 212, 226);
            font-size: 12px;
            color: rgb(67, 34, 91) !important;
            border-radius: 5px;
            cursor: pointer;
            ::before{
                position: absolute;
                content: "";
                top: 14px;
                -webkit-transform: translateY(-40%);
                -ms-transform: translateY(-40%);
                transform: translateY(-40%);
                right: 15px;
                width: 11px;
                height: 7px;
                background: url(${arrowDownIcon}) no-repeat;
            }
        }
        ul{
            position: absolute;
            background-color: #fff;
            width: 100%;
            height: auto;
            padding: 10px 0px;
            list-style: none;
            z-index: 1;
            border: 1px solid rgba(220, 211, 233, 0.5);
            border-radius: 10px;
            box-shadow: 0px 2px 14px rgba(90, 55, 157, 0.24);
            top: 28px;
            cursor: pointer;
            display: none;

            li{
                padding: 5px 12px;
                margin: 0;
                margin-bottom: 2px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                :hover{
                    background: rgb(238, 237, 245);
                    font-weight: 500;
                    color: #637381 !important;
                }
                &.active{
                    background: rgb(238,237,245);
                    font-weight: 500;span
                    color: #637381 !important;
                }
                p{
                    width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px !important;
                    margin: 0;
                }

                span{
                    font-size: 10px !important;
                    background: #c7ead0;
                    border-radius: 6px;
                    padding: 1px 5px;
                    float: right;
                    border: 1px solid #1d6d544f;
                    color: #1d6d54 !important;
                }
            }
        }
    }

    li {
        margin-bottom: 10px;

        &.non-link {
            padding: 10px 0px 10px 0px;
            position: relative;
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 10px !important;
            text-transform: uppercase !important;
            margin-bottom: 0px;
            &::after {
                display: none;
            }
        }

        &.get-started {
            &::after{
                background-position: 0px 0px !important;
                background: ${({theme:{onboarding}})=>onboarding.sidebar_onboarding_icons};
            }
            &:hover::after {
                background-position: 0px -24px !important;
            }
        }
        &.purevpn {
            a {
                &::after{
                    background-position: -48px 0px !important;
                }
                &.active::after{
                    background-position: -48px -24px !important;
                }
            }
        }
        &.purekeep {
            a {
                &::after{
                    background-position: -72px 0px !important;
                }
                &.active::after{
                    background-position: -72px -24px !important;
                }
            }
        }
        &.purecrypt {
            a {
                &::after{
                    background-position: -96px 0px !important;
                }
                &.active::after{
                    background-position: -96px -24px !important;
                }
            }
        }
        &.purescan,&.pureprivacy {
            a {
                &::after{
                    background-position: -120px 0px !important;
                }
                &.active::after{
                    background-position: -120px -24px !important;
                }
            }
        }
        &.pureai {
            a {
                &::after{
                    background-position: -24px 0px !important;
                }
                &.active::after{
                    background-position: -24px -24px !important;
                }
            }
        }
        &.security-suite {
            a {
                &::after{
                    background-position: 0px 0px !important;
                }
                &.active::after{
                    background-position: 0px -24px !important;
                }
            }
        }
        &.security-tools {
            a {
                &::after{
                    background-position: -144px 0px !important
                }
                &.active::after{
                    background-position: -144px -24px !important;
                }
            }
        }

        a {
            border-radius: 10px;
            transition: all 0.1s ease-in;
            padding: 6px 0px 6px 44px;

            ::after {
                background: ${({theme:{onboarding}})=>onboarding.sidebar_onboarding_icons};
            }
            &:hover {
                background: #F0ECF7;
                border-radius: 50px;
                color: #7B648C;
            }
            &.active{
                background: #3B108E;
                border-radius: 50px;
                font-weight: 400;
                color: #fff;
            }

            
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
const blinker = keyframes`

    0% {
      
        background: url(${familyIcon}) no-repeat;
    }

    100% {
        
        background: url(${familyIconHover}) no-repeat;
    }
`
export const SidebarList = styled.li`
    position:relative;
    margin-bottom:5px;
    line-height: inherit;
    ${props => props.locked && css`
        ::after{
            position:absolute;
            content:'';
            background-image: url(${lock});
            height:16px;
            width:13px;
            right:10px;
            top:50%;
            transform:translateY(-50%);
        }
    `}
    
    a{
        padding: 8px 0px 8px 44px;
        position:relative;
        color:#7B648C;
        font-weight:400;
        letter-spacing: 0.02em;
        display: block;
        font-size: 12px;
        ${({coloredBg}) => coloredBg === true && css`
            background-color: #43225B;
            color: #fff;
            border-radius: 50px;
            font-weight:600;
        `}
        :hover,:focus{
            background-color: #EEE9F4;
            border-radius: 50px;
            color:#43225B;
            max-width:205px;
            ${({coloredBg}) => coloredBg === true && css`
                background-color: #43225B;
                color: #fff;
            `}
        }
        ::after{
            position:absolute;
            content:"";
            top: 20%;
            transform:translateY(-50%);
            left:10px;
            width:24px;
            height:24px;
            background:url(${({theme:{backgrounds}})=>backgrounds.navIcons}) no-repeat;
            transform: scale(0.85);
        }
        ::before{
            position:absolute;
            content:"";
            top:50%;
            transform:translateY(-50%);
            right:8px;
            width:16px;
            height:16px;
        }
    }

    a.active{
        background-color: #3B108E;
        border-radius: 50px;
        font-weight:700;
        color:#fff;
        max-width:205px;
        ${({coloredBg}) => coloredBg == true && css`
        background-color: #3B108E;
        color: #fff;
        `}
    }
    }
    ${({visible}) => !visible && css`
        display:none;
    `}
    &.volume-licensing {
        a {
            &::after{
                background:url(${volumeLicensing}) no-repeat;
                background-position: 0px 0px !important;
            }
            &.active::after {
                background-position: -24px 0px !important;
            }
        }
    }
    &.addons-dashboard {
        a {
            &::after{
                background:url(${dashboard}) no-repeat;
                background-position: 0px 0px !important;
            }
            &.active::after {
                background-position: -24px 0px !important;
            }
        }
    }
    &.pure-rewards {
        a {
            &::after{
                background:url(${pureRewards}) no-repeat;
                background-position: 0px 0px !important;
            }
            &.active::after {
                background-position: -24px 0px !important;
            }
        }
    }
    &.account-manage {
        a {
            &::after{
                background-position: -25px 0px !important;
            }
            &.active::after {
                background-position: -25px -24px !important;
            }
        }
    }
    &.family-plan {
        a {
            &::after{
                background-position: -48px 0px;
            }
            &.active::after {
                background-position: -48px -24px;
            }
        }
    }
    &.subscriptions {
        a {
            &::after{
                background-position: -73px 0px !important;
            }
            &.active::after {
                background-position: -73px -24px !important;
            }
        }
    }
    &.upgrade-plan {
        a {
            &::after{
                background-position: -96px 0px !important;
            }
            &.active::after {
                background-position: -96px -24px !important;
            }
        }
    }
    &.setup-vpn{
        a {
            &.active::after {
                background-position: -382px -24px !important;
            }
            &::after{
                background-position: -382px 0px !important;
            }
        }
    }
    &.downloads {
        a {
            &::after{
                background-position: -120px 0px !important;
            }
            &.active::after {
                background-position: -120px -24px !important;
            }
        }
    }
    &.help-desk {
        a {
            &::after{
                background-position: -168px 0px !important;
            }
            &.active::after {
                background-position: -168px -24px !important;
            }
        }
    }
    &.refer-a-friend {
        a {
            &::after{
                background-position: -408px 0px !important;
            }
            &.active::after {
                background-position: -408px -24px !important;
            }
        }
    }
    &.affiliate {
        a {
            &::after{
                background-position: -240px 0px !important;
            }
            &.active::after {
                background-position: -240px -24px !important;
            }
        }
    }
    &.business {
        a {
            &::after{
                background-position: -263px 0px !important;
            }
            &.active::after {
                background-position: -263px -24px !important;
            }
        }
    }
    &.configuration {
        a {
            &::after{
                background-position: -286px 0px !important;
            }
            &.active::after {
                background-position: -286px -25px !important;
            }
        }
    }
    /* &.purekeep {
        a {
            &::after{
                background-position: -120px 0px !important;
            }
            &.active::after {
                background-position: -120px -25px !important;
            }
        }
    } */
    &.wireguard {
        a {
            &::after{
                background-position: -338px 0px !important;
            }
            &:hover::after {
                background-position: -338px -25px !important;
            }
        }
    }
    &.port-forwarding {
        a {
            &::after{
                background-position: -312px 0 !important;
            }
            &.active::after {
                background-position: -312px -24px !important;
            }
        }
    }
    &.pure-ai {
        a {
            &::after{
                background:${({theme:{pureai}}) => pureai.logo};
            }
            &.active::after {
                background:${({theme:{pureai}}) => pureai.logo};
            }
        }
    }
    &.teams {
        a {
            &::after{
                background-position: -432px 0px !important;
            }
            &.active::after {
                background-position: -432px -24px !important;
            }
        }
    }
`;

export const SidebarLine = styled.li`
    width: 100%;
    height: 1px;
    background: #DDD4E2;
    margin-bottom: 15px;
`;
