import React from 'react'
import { CardNumber } from '../../styles/dashboard/Main'
import { GatewayIcon } from '../../styles/flags/PaymentIconSprite'
import { available_payment_gateways_icon, constants } from '../../tools/constants'

const PaymentIcon = ({lastFour=null,hideStar=false,gateway=""}) => {

    const isPaypal = constants.paypal_payment_methods.includes(gateway)
    const gatewayToShow = constants.google_pay_names.includes(gateway) ? "" : gateway
    const cardOrGateway = lastFour && !constants.lastFourCards.includes(gateway) ? "card" : available_payment_gateways_icon?.[gateway]
    const classForIcon =   cardOrGateway ??  "card"
    /*
        if last four digit exist show credit card icon else paypal
    */
   const Icon = <GatewayIcon className={classForIcon} card={classForIcon}/>

    return (
        lastFour
        ?
        <CardNumber> {Icon} {!hideStar && `**** **** ****`} {lastFour || "****"}</CardNumber>
        :
        <CardNumber card={classForIcon}>{Icon} {isPaypal ? "Paypal" : gatewayToShow}</CardNumber>
    )
}

export default PaymentIcon