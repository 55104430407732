import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCountry } from "../../../redux/country/action";
import ErrorBoundary from "../../ErrorBoundary";
import Card from "./Card";
import CountryBox from "./CountryBox";
import Loader from "./Loader";
import { sendEvents, sendGuestEvents } from "../../../tools/mpEvents";
import { Input } from "reactstrap";
import {
	DashboardHead,
	DownloadComponent,
	FilterBtn,
	InfoCard,
} from "../../../styles/dashboard/Main";
import UsernameInput from "./modal/UsernameInput";
import {
	APIFAILED,
	INVALIDTOKEN,
	TOKENEXPIRED,
} from "../../../tools/responseMessages";
import NoSearchBox from "./NoSearchBox";
import { checkIsAddonPaid, selectSub } from "../../../tools/tools";
import { addonState, constants } from "../../../tools/constants";
import NoPFBox from "./NoPFBox";
import { useTranslation } from 'react-i18next';

const ManualConnection = ({ countries_data, getCountries,onBoardingReducer }) => {

	const { t, i18n } = useTranslation();

	const token = localStorage.getItem("token") ?? undefined;
	
	const [aClass, setAClass] = useState("active");
	
	const { country } = countries_data;

	const dispatch = useDispatch();

	const [item, setItem] = useState("country");
	
	const [crashed, setCrashed] = useState(false);

	const [error, setError] = useState("");
	
	const [isloading, setLoading] = useState(true);
	
	const [reduxData, setReduxData] = useState(null);
	
	const [reduxDataConfig, setReduxDataConfig] = useState("config");

	const [searchShow, setSearchShow] = useState(false);

	const [filteredSub, setFilteredSub] = useState(null);

	const [searchField, setSearchField] = useState("");
	const [showNote, setshowNote] = useState(false);
	const showNoteTags=['QR','p2p','OVPN_OBF'];
	
	const [classActive, setClassActive] = useState(false);
	
	const [allCountries, setAll] = useState(null);
	
	const [showFilters, setShowFilters] = useState(null);
	
	const [sortedBy, setSortedBy] = useState("Popularity");

	const [isPfSub,setIsPfSub] = useState(false)
	
	const childFunc = useRef(null);
	
	const dataFetchedRef = useRef(false);

	const firstRender = useRef(false);

	const showData = (data) => {
		setItem(data);
		childFunc.current();
	};
	
	useEffect(() => {
		const token = localStorage.getItem("token");
		dispatch({ type: "GET_COUNTRIES_REQUESTED", token: token });

		sendEvents(
			{
				event_name: "ma_pagevisited",
				event_properties: {
					visitedPageURL: window.location.href ?? "N/A",
				},
			},
			token
		);

	}, []);

	useEffect(() => {

		const { loading, errors, country, errorMessage, errorType } =
			countries_data;
		const { loading: reduxLoading, subscriptions: reduxData, errors:ob_error, errorType:ob_errorType, resetState, 
				errorMessage:ob_errorMessage,selectedSubs:selectedSubs } = 
			onBoardingReducer;
		
		if (!loading) {
			setLoading(false);
		}

		const filterSelectedSub = selectSub(reduxData??[],selectedSubs);
		/* 
		To Check if the selected sub has PF add-on
		*/
		const isPF = filterSelectedSub?.add_ons.find((addon)=>constants.port_forwarding.includes(addon.code) && checkIsAddonPaid(addon))
		setFilteredSub(filterSelectedSub)
		setIsPfSub(isPF?true:false)
		if (country?.length && !dataFetchedRef.current && !reduxLoading) {
			setReduxData(country);
			setAll(country);
			setLoading(false);
			dataFetchedRef.current = true;
		}

		if (errors) {
			switch (errorType) {
				case "token_expired":
					toast.error(t(TOKENEXPIRED));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_exception":
					toast.error(t(APIFAILED));
					dispatch({ type: "LOGOUT" });
					break;
				case "token_invalid":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_default_exception":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "APIFAILED":
					setCrashed(true);
					setError(errorMessage);
					break;
				default:
					setCrashed(true);
					setError(errorMessage);
					break;
			}
		}
	}, [countries_data, onBoardingReducer?.subscriptions]);
	
	if (crashed) {
		if (token) {
			sendEvents(
				{
					event_name: "ma_fail_manual_config",
					event_properties: {
						message: error ?? "N/A",
					},
				},
				token
			);
		}

		return <ErrorBoundary text={countries_data.errorMessage} />;
	}

	const sortByPopularity = (property) => {
		var sortOrder = 1;
		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return function (a, b) {

			var result =
				a[property] < b[property]
					? -1
					: a[property] > b[property]
					? 1
					: 0;
			return result * sortOrder;
		};
	}

	const handleChange = (e) => {
		if (e.target.value.length <= 1) {
			showData("qr");
		}
		setSearchField(e.target.value);
		if (e.target.value === "") {
			setSearchShow(false);
			classActive === constants.allFilter && setAClass("active");
		} else {
			setSearchShow(true);
		}
	};

	const filteredPersons = reduxData?.filter((country) => {
		return !showFilters
			? country?.name.toLowerCase().includes(searchField.toLowerCase())
			: country?.name.toLowerCase().includes(searchField.toLowerCase()) &&
					country?.feature.includes(showFilters);
	});

	const filteredCities = reduxData?.map((country) => {
		let cityArray = showFilters ? country?.filteredCities : country?.cities;
		return cityArray?.filter((city) => {
			return !showFilters
				? city?.name.toLowerCase().includes(searchField.toLowerCase())
				: city?.name
						.toLowerCase()
						.includes(searchField.toLowerCase()) &&
						city?.feature.includes(showFilters);
		});
	});

	const filteredResult =
		filteredCities && filteredPersons
			? filteredCities.flat().concat(filteredPersons)
			: filteredCities
			? filteredCities
			: filteredPersons;

	const handleClick = (e) => {
		if (sortedBy === "Name") {
			setSortedBy("Popularity");
			applySort("Popularity");
		}
		setAClass(null);
		setShowFilters(null);

		classActive &&
			document.getElementById(classActive).classList.remove("active");

		setClassActive(e.target.id);

		document.getElementById(e.target.id).classList.add("active");

		const filterValue = e.target.id ? 
							e.target.id==="P2P" ? e.target.id.toLowerCase() 
							: 
							e.target.id==="OBF" ? `OVPN_`+e.target.id
							: 
							e.target.id
							: null;
		if(filterValue){
			setshowNote(showNoteTags.includes(filterValue));
			showData("qr");
			setShowFilters(filterValue);
			var allCountries_filter = allCountries;
			var filteredCountry = allCountries_filter?.filter(
				(country, countryIndex) => {
					country["filteredCities"] = [];
					return (
						country?.feature.includes(filterValue) &&
						country?.cities?.filter((city, cityIndex) => {
							if (city?.feature?.includes(filterValue)) {
								if (
									!allCountries_filter[countryIndex][
										"filteredCities"
									].includes(
										allCountries_filter[countryIndex][
											"cities"
										][cityIndex]
									)
								) {
									allCountries_filter[countryIndex][
										"filteredCities"
									].push(
										allCountries_filter[countryIndex][
											"cities"
										][cityIndex]
									);
								}
							}
						})
					);
				}
			);

			setReduxData(filteredCountry);
		}
		if(!filterValue || filterValue===constants.allFilter)
		{
			showData("qr");
			setShowFilters(null);
			setReduxData(allCountries);
			reduxData.sort();
		}
	};
	const applySort = (e) => {
		showData("qr");

		setSortedBy(e.target?.value ? e.target.value : e);
		if (e.target?.value === "Name" || e === "Name") {
			const data = reduxData;
			data.sort(sortByPopularity("name"));
		} else {
			const data = reduxData;
			data.sort(sortByPopularity("popularity"));
		}
	};

	const tagsName = ['All',"QR","P2P","OBF","PF"];
	
	const renderTags = () => {
				return tagsName.map((tag,index)=>(
					<FilterBtn
						key={index}
						className={tag === constants.allFilter ? aClass : 
						(
							classActive &&
							classActive.toLowerCase() === tag?.toLowerCase() ? "mx-2 active":
							 "mx-2"
						)
						}
						id={tag}
						onClick={handleClick}
						btnText={tag}
					/>
				))
		
	}

	return (
		<div>
			<>
				<DashboardHead className="col-12">
					<h2>Manual Configuration</h2>
					<p style={{ maxWidth: "675px" }}>
						{t('manualconf_title_para')}
					</p>
				</DashboardHead>
			</>
			{
				<>
					{!reduxData ? (
						<Loader />
					) : (
						!crashed &&
						reduxData && (
							<>
								<Card>
									<div class="col-12">
										<div className="col-12">
											<Input
												type="search"
												name="search"
												onChange={handleChange}
												className="mb-2 saerchBar"
												placeholder={t('manualconf_location_placeholder')}
											></Input>
										</div>
										{!searchShow && (
											<DownloadComponent className="col-12">

												<div className="filterButton mb-3 mt-3">
													<label className="me-2">
														{t('manualconf_filter_by_text')}{" "}
													</label>
													{/* Filter Tags */}
													{renderTags()}
													{(showNote && isPfSub) && <p className="mt-3">{t('manualconf_note_for_port_forwarding')}</p>}
												</div>
												<div className="selectSort me-2">
													<div className="d-flex align-items-center">
													<label>
														{t('manualconf_sort_by_text')}{" "}
														<strong className="me-2">
															{sortedBy}{" "}
														</strong>
													</label>
													<div className="d-flex sortArrow">
													<Input
														className="mb-0"
														type="select"
														onChange={applySort}
														value={sortedBy}
														>
														{["Popularity  ", "Name"].map(
															(value,key) => (
																<option key={key} value={value}>
																	{t('manualconf_sort_by')} {value}
																</option>
															)
															)}
													</Input>
													</div>
													</div>
												</div>
											</DownloadComponent>
										)}
										{/* 
										Without any search show all countries
										With search input show filtered countries according to the searched result
										*/}
										
										{!searchShow ? 
										<CountryBox
											data={reduxData}
											item={item}
											openVpnConfig={reduxDataConfig}
											childFunc={childFunc}
											handleClick={handleClick}
											showFilters={showFilters} currentSubData={filteredSub} isPfSub={isPfSub} pfAndActive={classActive === "PF" ?true:false}/>
											:
											/*
											Show the filtered result according to search input value
											else when there is no result according to searched value show Error box
											*/
											(filteredResult.length ?
												<CountryBox
												data={filteredResult}
												item={item}
												openVpnConfig={reduxDataConfig}
												childFunc={childFunc}
												openSub={true}
												showFilters={showFilters} currentSubData={filteredSub} isPfSub={isPfSub}  pfAndActive={classActive === "PF" ?true:false}/>
												: <NoSearchBox showFilters={showFilters} searchContent={searchField}/>)
										}
									</div>
								</Card>
							</>
						)
					)}
				</>
			}
		</div>
	);
};

const mapStatetoProps = (state) => {
	return {
		countries_data: state.country,
		onBoardingReducer: state.onBoardingReducer,
	};
};

export default connect(mapStatetoProps)(ManualConnection);
