import { SecurityToolsContent } from "./content";
import { SecurityToolsStyles } from "../../styles/dashboard/security-tools/securityTools";
import { useTranslation } from "react-i18next";
import ToolsBox from "./toolsBox";
import { useEffect, useLayoutEffect } from "react";
import { sendEvents } from "../../tools/mpEvents";
import { getTokenFromLocalStorage } from "../../tools/tools";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";


const SecurityTools = ({history}) => {

    const { search } = useLocation();

    const { slug, utm_medium, utm_campaign } = Object.fromEntries(new URLSearchParams(search));

    const token = getTokenFromLocalStorage();
    
    const { t } = useTranslation();

    useLayoutEffect(()=>{
        if(slug){
            history.push({
                pathname: `/dashboard/security-tools/${slug}`,
                search: (utm_medium && utm_campaign) ? `?utm_medium=${utm_medium ?? ''}&utm_campaign=${utm_campaign ?? ''}` : ""
            });
        }
    }, [])

    useEffect(()=>{
        sendEvents({
            event_name: "ma_pagevisited",
            event_properties: {
                visitedPageURL: window.location.href ?? "N/A",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            },
        }, token);
    }, []);

    return <SecurityToolsStyles>
        
        <section className="security-tools">
            <h3>{t(SecurityToolsContent.security_tools_heading)}</h3>
            <p>{t(SecurityToolsContent.security_tools_para)}</p>
        </section>

        <section className="security-tools-boxes">
           {SecurityToolsContent.features.map((feature)=>{
            return <ToolsBox heading={feature?.heading} paragraph={t(feature?.paragraph)} app={feature?.app} slug={feature?.slug} removePoweredBy={feature?.powered_by}/>
           })}
        </section>

    </SecurityToolsStyles>
} 
export default withRouter(SecurityTools);