import axios from 'axios';
import {takeLatest, put, cancelled} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { fetcher } from '../../tools/tools';
import { errorsType, getAccountType, loadingType, usernameType, subscriptionType } from './actions';
import i18n from "i18next";

function* accountAsync(token){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const account = yield fetcher("account", token.payload, cancelTokenSource)
        const {data: response} = account;
        if(response?.status){
            
            response.body.fullName = typeof response?.body.firstName !== 'undefined' ? `${response?.body.firstName ?? ''} ${response?.body.lastName ?? ''}` : `${response?.body.fullName ?? ''}`;
            
            yield put(getAccountType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

function* usernameAsync(action){
    yield put(usernameType(action.payload))
}

function* subscriptionAsync(action){
    yield put(subscriptionType(action.payload))
}

export function* watchSubscription() {
    yield takeLatest('SUBSRIPTIONREQUESTED', subscriptionAsync)
}

export function* watchUsernameRequest() {
    yield takeLatest('USERNAMEREQUESTED', usernameAsync)
}

export function* watchAccountRequest() {
    yield takeLatest('GETACCOUNTREQUESTED', accountAsync)
}