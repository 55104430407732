import { ERRORS, GETCHANNELS, GETCHANNELREQUEST, LOADING } from "./types"

export const getDeviceRequested = (payload) => {
    return{
        type:GETCHANNELREQUEST,
        payload
    }
}

export const getChannelsInfo = (payload) => {
    return{
        type:GETCHANNELS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
