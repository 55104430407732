import { useState, useEffect } from "react";
import { getByParam, getTokenFromLocalStorage } from "../../../../tools/tools";
import { sendEvents } from "../../../../tools/mpEvents";

const useDedicatedIps = (page, per_page, searchText, selectedSubscriptionData, teamsCounter) => {
  const token = getTokenFromLocalStorage();
  const [totalRows, setTotalRows] = useState(0);
  const [dedicatedIps, setDedicatedIps] = useState([]);
  const [isDedicatedIpsLoaded, setIsDedicatedIpsLoaded] = useState(true);
  const [dedicatedIpsErrorMsg, setDedicatedIpsErrorMsg] = useState(null);
  const [fetchDedicatedIpsAgain, setDedicatedIpsAgain] = useState(false);

  useEffect(() => {
    async function fetchDedicatedIp() {
      try {
        setIsDedicatedIpsLoaded(false);
        setDedicatedIpsErrorMsg(false);
        const apiResponse = await getByParam(`pure-teams/dedicated-ips${selectedSubscriptionData?.is_child ? '?is_child=1' : ''}`, {
          sub_id: selectedSubscriptionData?.id,
          page,
          per_page,
          search: searchText
        }, token);
        const { data: dedicatedIps } = apiResponse;
        setDedicatedIpsErrorMsg(false);
        if (dedicatedIps?.status) {
          setDedicatedIps(dedicatedIps?.body?.data);
          setTotalRows(dedicatedIps?.body?.total);
        } else {
          setDedicatedIpsErrorMsg(dedicatedIps?.message);
          sendEvents({
            event_name: "ma_fail_load_dedicated_ip_details",
            event_properties: {
                reason: dedicatedIps?.message
            },
        }, token);
        }
      } catch (error) {
        setDedicatedIpsErrorMsg('An error occurred while fetching dedicated ip');
      }
      finally{
        setIsDedicatedIpsLoaded(true);
      }
    }

    if(teamsCounter?.dedicated_ip) {
      fetchDedicatedIp();
    }
}, [fetchDedicatedIpsAgain, selectedSubscriptionData?.id, page, per_page, searchText]);

  return { dedicatedIps, totalRows, isDedicatedIpsLoaded, dedicatedIpsErrorMsg, setDedicatedIpsAgain };
};

export default useDedicatedIps;
