import React, { useEffect, useState, useRef } from 'react'
import { SignupViewWrapper, CardBox, InfoCard, SuccessOrErrorInfoBox } from '../../../styles/dashboard/Main'
import { LinkBtn, PrimaryBtn } from '../../../styles/Generic'
import { sendEvents, sendGuestEvents} from "../../../tools/mpEvents"
import AddonPurchase from '../subscriptions/modals/AddonPurchase'
import { Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import failIcon from '../../../assets/fail_emoji_icon.svg'
import successIcon from '../../../assets/sucess_check_icon.svg'
import { useHistory, useLocation } from 'react-router-dom'
import PureKeepSignupView from './PureKeepSignupView'
import PurePrivacySignupView from './PurePrivacySignupView'
import { constants } from '../../../tools/constants';
import PureEncryptSignupView from './PureEncryptSignupView'
import { selectSub } from '../../../tools/tools'
import { getLocaleFromUrl } from '../../../tools/tools';
import { useTranslation } from 'react-i18next';

const SignupView = ({ index, subscriptions, isLocked, appSlug, appName, subscriptionData, setCrashed, inSessionPurchased = false, next, accountReducer, onBoardingReducer, getSubscriptionsRequest, step, selectedStep, selectedSubStep, subStepCallback, selectStepCallback, purchasedAddOnCallback, purechaseOrNewSubsButtonEvents, normalBtnCallback }) => {
    const token = localStorage.getItem("token");

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const location = useLocation();

    const history = useHistory();

    const dispatch = useDispatch()

    const loading = typeof step.data != "undefined" && subscriptions.subscriptions ? false : true;

    const [modal, setModal] = useState(false);

    const [modalType, setmodalType] = useState('addon-lg')

    const widthCalculator = modalType.slice(-2)

    const [manualGatewayType, setManualGatewayType] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(true)

    const [orderSummary, setorderSummary] = useState(true)

    const [addonErrorEventSent, setAddonErrorEventSent] = useState(false)

    const errorMessageBoxRef = useRef(null);

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [addonType, setaddonType] = useState({
        type: "",
        subscription: "",
        currency: "",
        plan: "",
        number:null
    })

    const toggle = () => setModal(!modal);

    useEffect(() => {

        let currentSubscription = selectSub(subscriptions?.subscriptions?.body ?? [],onBoardingReducer.selectedSubs)
        setaddonType({
            type: appSlug == constants.pureprivacy ? "purescan" : appSlug ,
            subscription: currentSubscription.id ?? subscriptionData.data.subscription,
            currency: currentSubscription.currency ?? subscriptionData.data.currency,
            plan: currentSubscription?.plan?.code ?? subscriptionData.data.plan,
            gateway: currentSubscription?.payment_gateway ?? subscriptionData.manualGatewayType,
            number: subscriptionData.data.number
        })

        if (subscriptionData.error && !addonErrorEventSent) {
            setAddonErrorEventSent(true);
            sendEvents({
                event_name:"ma_fail_load_addon",
                event_properties:{
                    reason: errorMessageBoxRef?.current?.textContent,
                    addon: appSlug,
                    account_status: subscriptionData.state
                }
            }, token);
        }

    }, [subscriptionData])

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search)

        if (subscriptionData && subscriptionData?.data?.type && queryParams.has('openPurchase')) {

            // Check subscription before opening the addon-purchase modal
            if (queryParams.get('openPurchase') === '1' && !subscriptionData.isCanceled) {
                toggle();
            }

            // Remove query string
            queryParams.delete('openPurchase');
            history.replace({
                search: queryParams.toString(),
            })
        }

    }, [addonType, subscriptionData])

    const buttonClickHandler = (e, data = {}) => {
        purechaseOrNewSubsButtonEvents(e.target.innerHTML)
        toggle()
    }

    const buttonClickHandler2 = (e, link) => {
        normalBtnCallback(e.target.innerHTML);
        window.open(`${link}`, '_blank');
    }

    const openWebsite = (e) => {
        purechaseOrNewSubsButtonEvents(e.target.innerHTML)
        window.open(`${constants.pureVPNPurchasePageURL}`, '_blank');
    };

    const buttonNextClickHandler = () => {
        next(selectedStep + 1)
    }

    const buttonRenewalHandler = () => {
        history.push("/dashboard/subscriptions?update_subscription=true")

        sendEvents({
            event_name:"ma_click_renew_subscription",
            event_properties:{
                source: appSlug
            }
        }, token)
    }

    const generateTopContent = (data, errorStatus) => {

        return (
            <>
                <h2 className="heading">{data.heading}</h2>
                {
                    !errorStatus ? (<p className="para">{data.text}</p>) : null
                }

                {data.button_two_text !== "" && !errorStatus ? (
                    <>
                        {
                            !inSessionPurchased
                                ? (
                                    <div className='button-wrapper'>
                                        <PrimaryBtn onClick={(e) => buttonClickHandler(e, data)}>{data.button_two_text}</PrimaryBtn>
                                        {data.button_one_text !== "" && !errorStatus ? (<LinkBtn className='learn-more-button' onClick={ (e) => { buttonClickHandler2(e, data.button_one_operations) }} target="_blank" >{data.button_one_text}</LinkBtn>) : null}
                                    </div>
                                ) : (
                                    <div className='button-wrapper'>
                                        <PrimaryBtn onClick={buttonNextClickHandler}>Next</PrimaryBtn>
                                    </div>
                                )
                        }
                    </>
                ) : null}
            </>
        )

    }

    const generateListContent = (data) => {

        if (data.length > 0) {
            return data.map((item, idx) => {
                return (
                    <div key={idx} className="list-item">
                        <h4 className="heading3">{item.sub_heading}</h4>
                        <p className="para">{item.text}</p>
                    </div>
                )
            })
        }

    }

    const generateMultiSubListContent = (data) => {

        if (data.length > 0) {
            return data.map((item, idx) => (
                <div key={idx} className="list-item">
                    <h3 className="header2">{item.heading}</h3>
                    <p className="para">{item.text}</p>
                    {
                        (typeof item.list != "undefined" && item.list.length > 0) ? (
                            <ul>
                                {
                                    item.list.map((list, idj) => (
                                        <li key={idj}>
                                            <h4 className="heading3">{list.heading}</h4>
                                            <p className="para">{list.text}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        ) : null
                    }
                </div>
            ))
        }
    }

    const generateMessageResponse = () => {

        let appNameHeading = (appSlug == "purevpn") ? appName + " Plan" : appName;

        if (subscriptionData.isManualGateway) {
            if (subscriptionData.manualGatewayType !== "") {

                if (subscriptionData.isExpired && appSlug == "purevpn") {
                    return (
                        <>
                            <p className="para" ref={errorMessageBoxRef}>{t('singup_your')} {appName} {t('signup_subscription_expired')}</p>
                            <PrimaryBtn onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                        </>
                    )
                } else if  (subscriptionData.manualGatewayType == "amazon") {
                    return (
                        <>
                            <p className="para" ref={errorMessageBoxRef}>
                                {t('signup_subscription_expired')} {appNameHeading}. {t('signup_please_note')} <b>{subscriptionData.manualGatewayType}</b>.
                            </p>
                        </>
                    )
                } else if (subscriptionData.manualGatewayType == "coingate" || subscriptionData.manualGatewayType == "paymentwall") {
                    if (subscriptionData.isPastDue) {
                        return (
                            <>
                                <p className="para" ref={errorMessageBoxRef}>
                                {t('singup_your')} {appName} {t('signup_subs_is_expired')} {appName}.
                                </p>
                                <PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <p className="para" ref={errorMessageBoxRef}>
                                    {t('addon_purchasemodal_uhoh')}<br></br>
                                    {t('signup_sorry_to_inform')} {appNameHeading} {t('signup_on')} <b style={{textTransform: "capitalize"}}>{subscriptionData.manualGatewayType}</b>, {t('signup_option_not_supported')}
                                </p>
                            </>
                        )
                    }
                }
            }
        }

        if (subscriptionData.isMobileGatewayForWHMCS || subscriptionData.notEligibleSignupFrom) {
            return (
                <>
                    <p className="para" ref={errorMessageBoxRef}>{t('signup_sorry_to_inform_option_not_available')}</p>
                </>
            ) 
        }

        if (subscriptionData.isTrial) {

            if (subscriptionData.isCanceled) {
                return (
                    <>
                        <p className="para" ref={errorMessageBoxRef}>{t('signup_interested_in_buying')} {appNameHeading}.<br></br>{t('signup_note_subscription_refunded')} {appNameHeading}. {t('signup_however')} {appNameHeading}.</p>
                        <PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                    </>
                )
            } else if (subscriptionData.isExpired) {
                return (
                    <>
                        <p className="para" ref={errorMessageBoxRef}>{t('signup_account_expired')}</p>
                        <PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                    </>
                )
            } else {
                return (
                    <>
                        <p className="para" ref={errorMessageBoxRef}>{t('signup_interested_in_buying')} {appNameHeading}. <br></br>{t('signup_in_your_trial')} {appNameHeading} {t('signup_get_premium_subs')} {appNameHeading}.</p>
                        <PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                    </>
                )
            }

        }

        if (subscriptionData.isExpired) {
            return (
                <>
                    <p className="para" ref={errorMessageBoxRef}>{t('signup_interested_in_buying')} {appNameHeading}. <br></br>{t('signup_note_subscription_refunded')} {appNameHeading}. {t('signup_however')} {appNameHeading}.</p>
                    <PrimaryBtn onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                </>
            )
        }

        if (subscriptionData.isCanceled) {
            if (appSlug == "purevpn") {
                return (
                    <>
                        <p className="para" ref={errorMessageBoxRef}>{t('signup_refunded_please_purchase_new')} {appName} {t('signup_protect_online')}</p>
                        <PrimaryBtn onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                    </>
                )
            } else {
                return (
                    <>
                        <p className="para" ref={errorMessageBoxRef}>{t('signup_interested_in_buying')} {appNameHeading}.<br></br>{t('signup_note_subscription_refunded')} {appNameHeading}. {t('signup_however')} {appNameHeading}.</p>
                        <PrimaryBtn onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>
                    </>
                )
            }
            
        }

        if (subscriptionData.isPastDue && !subscriptionData.isManualGateway) {
            return (
                <>
                    <p className="para" ref={errorMessageBoxRef}>{t('singup_your')} {appName} {t('signup_subs_is_expired')} {appName}.</p>
                    <PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                </>
            )
        }

        if (subscriptionData.isPaused) {
            return (
                <>
                    <p className="para" ref={errorMessageBoxRef}>{t('singup_your')} {appName} {t('signup_subs_is_expired')} {appName}.</p>
                    <PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>
                </>
            )
        }
        
    }

    const generateButtonResponse = () => {

        if (subscriptionData.isManualGateway) {
            if (subscriptionData.manualGatewayType !== "") {
                if (appSlug == "purevpn") {
                    return (<PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>)
                } else {
                    return (<></>)
                }
            }
        }
        
        if (subscriptionData.notEligibleSignupFrom) {
            return (<PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>)
        }

        if (subscriptionData.isTrial) {

            if (subscriptionData.isCanceled) {
                return (<PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>)
            } else if (subscriptionData.isExpired) {
                return (<PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>)
            } else {
                return (<PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>)
            }

        }

        if (subscriptionData.isExpired) {
            return (<PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>)
        }

        if (subscriptionData.isCanceled) {
            return (<PrimaryBtn className='d-block' onClick={e => openWebsite(e)}>{t('dashboard_get_new_subscription')}</PrimaryBtn>)
        }

        if (subscriptionData.isPastDue && !subscriptionData.isManualGateway) {
            return (<PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>)
        }

        if (subscriptionData.isPaused) {
            return (<PrimaryBtn onClick={buttonRenewalHandler}>{t('subscription_renew_sub_cta')}</PrimaryBtn>)
        }
    }

    const skeleton = (html) => {

        return loading ? (
            <></>
        ) : html
    }

    return skeleton(
        <SignupViewWrapper className={`row align-items-center ${(step?.data?.bg_image !== "") ? "hasBG" : ""}`}>
            {
                !subscriptionData.error && step?.data && constants.newSignupViewApps.includes(appSlug) ? (
                    <>
                        {
                           (() => {
                            if(appSlug == constants.purekeep) {
                                return (
                                    <>
                                        <PureKeepSignupView subscriptionData={subscriptionData} buttonClickHandler={buttonClickHandler} />
                                    </>
                                )
                            } else if (appSlug == constants.pureprivacy) {
                                return (
                                    <>
                                        <PurePrivacySignupView subscriptionData={subscriptionData} buttonClickHandler={buttonClickHandler} />
                                    </>
                                )
                            } else if (appSlug == constants.purecrypt) {
                                
                                return (
                                    <>
                                        <PureEncryptSignupView subscriptionData={subscriptionData} buttonClickHandler={buttonClickHandler} />
                                    </>
                                )
                            }
                             else {
                                return (
                                    <></>
                                )
                            }
                            })()   
                        }
                        {
                            (modal) && <AddonPurchase key={index} appSlug={appSlug} setCrashed={setCrashed} purchasedAddOnCallback={purchasedAddOnCallback} showInfo={false} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} />
                        }
                    </>
                ) : (
                    <>
                        <div className='col-md-6 content'>
                            {
                                step?.data
                                    ? (
                                        <>
                                            {(typeof step.data.top != "undefined") ? generateTopContent(step.data.top, subscriptionData.error) : null}

                                            {
                                                subscriptionData.error ? (
                                                    <SuccessOrErrorInfoBox>
                                                        <div className="messageBox">
                                                            <img src={failIcon} className="img-fluid" height={96} width={96} />
                                                            <div className='errorMsg'>
                                                                {
                                                                    generateMessageResponse()
                                                                }
                                                            </div>
                                                        </div>
                                                    </SuccessOrErrorInfoBox>
                                                ) : <></>
                                            }

                                            {(typeof step.data.heading != "undefined") ? <h3 className='header2'>{step.data.heading.heading}</h3> : null}
                                            {(typeof step.data.sub_heading != "undefined") ? generateListContent(step.data.sub_heading) : null}

                                            {(typeof step.data.content != "undefined") ? generateMultiSubListContent(step.data.content) : null}

                                            {
                                                subscriptionData.error ? (
                                                    <>
                                                        {
                                                            generateButtonResponse()
                                                        }
                                                    </>
                                                ) : (typeof step.data.bottom_button != "undefined" ) ? (
                                                    <div className='button-wrapper'>
                                                        <PrimaryBtn onClick={(e) => buttonClickHandler(e, step.data.bottom_button)}>{step.data.bottom_button.button_one_text}</PrimaryBtn>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                (modal) && <AddonPurchase key={index} appSlug={appSlug} purchasedAddOnCallback={purchasedAddOnCallback} showInfo={false} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modalType={modalType} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} />
                                            }
                                        </>
                                    ) : null
                            }
                        </div>
                        <div className={`col-md-6 sidebar-content d-none d-md-block d-lg-block`}>
                            {
                                step?.data?.bg_image
                                    ? (
                                        <>
                                            <img src={step?.data?.bg_image.image} />
                                        </>
                                    ) : null
                            }
                        </div>
                    </>
                )
            }


        </SignupViewWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, { getSubscriptionsRequest })(SignupView)