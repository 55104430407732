import { ERRORS, GETINVOICES, LOADING } from "./types";

const initialState = {
    invoices: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const invoicesReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true, errors:false, errorType:"", errorMessage:""}
            break;
        case GETINVOICES:
            return  {...state, invoices:{...state.invoices, [payload.subid]:payload}, loading:false, errors:false}
            break; 
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}