import styled from "styled-components";

export const InviteStyles = styled.div`

    .invite-img{
        margin: auto;
        display: flex;
        margin-bottom: 16px;
    }
    h2{
        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
        font-size:  ${({theme:{teams:{typography: {h1}}}})=>h1};
        font-weight: 700;
        text-align: center;

        @media only screen and (max-width: 600px){
            font-size: 24px !important;
        }

        @media only screen and (max-width: 320px){
            font-size: 20px !important;
        }
    }

    h6{
        color:  ${({theme:{teams:{colors: {secondary}}}})=>secondary};
        font-size:  ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0.24px;
        margin: 30px auto 5px;

        @media only screen and (max-width: 320px){
            font-size: 10px !important;
        }
    }

    .copy-link-box{
        position: relative;
        input{
            color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
            width: 100% !important;
            z-index: 0;
            border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight} !important;
            &::placeholder{
                color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
            }
        }
        img{
            position: absolute;
            top: 7px;
            content: '';
            right: 1px;
            z-index: 1;
            cursor: pointer;
            background: ${({theme:{teams:{colors: {white}}}})=>white};
        }
    }
    .email-link-box{
        position: relative;
        input{
            color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
            border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight} !important;
            @media only screen and (max-width: 480px){
                &::placeholder{
                    color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
                    font-size: 10px;
                }
            }
        }
        button.invite-btn{
            position: absolute;
            top: 10px;
            content: '';
            right: 4px;
            z-index: 3;
            cursor: pointer;
            padding: 6px 10px;
            font-size: 10px;
            &:disabled{
                opacity: 0.2;
            }

            @media only screen and (max-width: 460px){
                top: 1px;
            }

            @media only screen and (max-width: 420px){
                position: relative;
                right: 0px;
                display: block;
            }
        }
    }

    .input {
        width: 100%;
        height: 45px;
        padding: 0 1rem;
        margin-top: 1rem;
        box-sizing: border-box;
        font: inherit;
        border-radius: 0.2rem;
        border: 2px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight};
        color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
        -webkit-appearance: none;
      }
      
      .input:focus {
        border-color: cornflowerblue;
        outline: none;
      }
      
      .input.has-error {
        border-color: red;
      }
      
      .error {
        font-size: 11px;
        color: red;
        font-weight: 500;
        margin-top: 5px;
      }
      
      .tag-item {
        background-color: #9a75d147;
        display: inline-block;
        font-size: 11px;
        border-radius: 30px;
        height: 20px;
        padding: 0.3rem 0.2rem 0.3rem 0.5rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;
      }
      
      .tag-item > .button {
        background-color: white;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 5px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    
`