import styled from "styled-components";
import TeamsFeaturesIcons from "../../../assets/pure-teams/team-features.svg";
import MarkIcon from "../../../assets/pure-teams/mark.svg";
import teamsBenefitsIcons from "../../../assets/pure-teams/benefits.png";
import filledRight from "../../../assets/white-check-filled.svg"
import borderCross from "../../../assets/white-cross.svg"
import borderTick from "../../../assets/white-check.svg"
export const PrePurchaseStyles = styled.div`
  margin-top: 34px;

  .heading{
    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 30px;

    @media only screen and (max-width: 900px) {
      font-size: 28px;
    }
  }
  .paragraph{
    color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    

    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }
  .subheading{
    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .banner-img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 20px;
    @media only screen and (max-width: 780px) {
      border-radius: 14px;
    }
    @media only screen and (max-width: 520px) {
      border-radius: 10px;
    }
    @media only screen and (max-width: 400px) {
      border-radius: 8px;
    }
  }

  .body-paragraph{
    color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    margin: 0;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  .intro-section {
    max-width: 75%;
    margin: auto;
    text-align: center;

    @media only screen and (max-width: 900px) {
      max-width: 100%;
    }

    h4 {
      margin-top: 32px;
      margin-bottom: 16px;
    }

    button{
      border-radius: 30px !important;
      padding: 8px 30px !important;
      font-size: 16px !important;
      margin-bottom: 32px;
    }
  }

  .benefits-section{
    margin: 100px auto auto auto;
    max-width: 1008px;
    text-align: center;
    h3{
      font-size: 30px;
      margin-bottom: 10px;
      @media only screen and (max-width: 520px) {
        font-size: 24px !important;
      }
    }
    .benefits-content{
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 30px;
      @media only screen and (max-width: 771px) {
        display: block;
        margin: auto;
        margin-bottom: 10px;
      }
      .benefits{
        padding: 24px 16px 40px;
        max-width: 316px;
        max-height: 232px;
        border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight};
        border-radius: 24px;
        position: relative;
        background: #fff;
        @media only screen and (max-width: 771px) {
          margin: auto;
          margin-top: 30px;
        }
        
        .benefits-info{
          padding-top: 80px;
        }
        &::before {
          content: " ";
          background-image: url(${teamsBenefitsIcons});
          background-repeat: no-repeat;
          width: 64.33px;
          height: 64.33px;
          position: absolute;
          top: 24px;
          content: '';
          left: 50%;
          margin-left: -32px;
        }
        &.panel{
          &::before{
            background-position: 0px 0px !important;
          }
        }
        &.devices{
          &::before{
            background-position: -64.33px 0px !important;
          }
        }
        &.connectivity{
          &::before{
            background-position: -128.66px 0px !important;
          }
        }
      }
    }
  }

  .upgrade-team-benefits-section{
    margin: 100px auto auto auto;
    max-width: 1008px;
    text-align: center;
    h3{
      font-size: 34px;
      margin-bottom: 16px;
      @media only screen and (max-width: 900px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 520px) {
        font-size: 24px !important;
      }
    }
    .upgrade-team-benefits-content{
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 30px;
      @media only screen and (max-width: 771px) {
        display: block;
        width: 400px;
        margin: auto;
      }
      @media only screen and (max-width: 430px) {
        width: 100%;
      }
      .upgrade-team-benefits{
        padding: 24px 16px 40px;
        border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight};
        border-radius: 24px;
        position: relative;
        flex-grow: 1;
        min-height: 267px;
        background: #fff;
        @media only screen and (max-width: 771px) {
          margin: auto;
          margin-top: 30px;
        }
        .upgrade-team-benefits-info{
          padding-top: 90px;
          h1{
            font-size:20px;
            font-weight: 700;
            @media only screen and (max-width: 900px) {
              font-size: 18px;
            }
          }
          p{
            max-width: 285px;
            margin:auto;
          }
        }
        &::before {
          content: " ";
          background-image: url(${TeamsFeaturesIcons});
          background-repeat: no-repeat;
          width: 72px;
          height: 72px;
          position: absolute;
          top: 24px;
          content: '';
          left: 50%;
          margin-left: -20px;
        }
        &.ded-ip{
          &::before{
            background-position: -8px 0px  !important;
          }
        }
        &.team-servers{
          &::before{
            background-position: -80px 0px !important;
          }
        }
      }
    }
  }

  .upgrade-section{
    margin-top: 100px;
    max-width: 1008px;
    margin: 100px auto auto auto;
    .upgrade-banner{
      border-radius: 12px;
      background: linear-gradient(46deg, #3B108E 9.72%, #BDABFF 103.87%);
      padding: 21px 51px 32px 61px;
      display: flex;
      align-items: center;

      .upgrade-content{
        @media only screen and (max-width: 720px) {
          margin: auto;
        }
      }
      @media only screen and (max-width: 900px) {
        padding: 16px 32px;
      }

      @media only screen and (max-width: 720px) {
        text-align: center;
      }

      h2{
        color: #FFF;
        font-size: 36px;
        font-weight: 700;
        line-height: 38.88px;
        margin-bottom: 20px;
        letter-spacing: -0.72px;
        max-width: 458px;
        @media only screen and (max-width: 900px) {
          font-size: 28px;
        }
        @media only screen and (max-width: 720px) {
          margin: auto;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 520px) {
          font-size: 24px !important;
          line-height: 28px;
        }
      }
      h4{
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.48px;
        margin-bottom: 22px;
        @media only screen and (max-width: 900px) {
          font-size: 16px;
        }
      }
      button{
        border-radius: 30px;
        border: 2px solid #FFF;
        padding: 10px 20px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        background: transparent;
      }
      img{
        @media only screen and (max-width: 720px) {
          display: none;
        }
      }
    }
  }

  .get-started-section{
    border-radius: 12px;
    margin-top: 100px;
    max-width: 1008px;
    margin: 100px auto auto auto;
    height: 501px;
    background: linear-gradient(226deg, #3B108E -3.87%, #BDABFF 90.28%);
    position: relative;
    text-align: center;
    .get-started-content{
      text-align: center;
      padding-top: 40px;
      h1{
        margin-bottom: 14px;
        color: #FFF;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.72px;
        @media only screen and (max-width: 900px) {
          font-size: 28px;
        }
        @media only screen and (max-width: 520px) {
          font-size: 24px !important;
        }
      }
      p{
        max-width: 463px;
        margin: auto;
        margin-bottom: 32px;
        color: #FFF;
        font-size: 16px;
      }
      button{
        border-radius: 30px;
        border: 2px solid #FFF;
        padding: 10px 20px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        background: transparent;
      }
    }
    img{
      margin-top: 8px;
      border-radius: 8.993px;
      height: auto;
      max-width: 100%;
    }
  }
  .features-section {
    // margin: 80px auto auto auto;
    margin: 34px auto auto auto;
    max-width: 1200px;
    text-align: center;

    .feature-body{
      display: flex;
      align-items: center;
      gap: 30px;

      @media only screen and (max-width: 910px) {
        flex-direction: column;
      }

      img{
        width: 700px;

        @media only screen and (max-width: 1020px) {
          width: 400px;
        }

        @media only screen and (max-width: 910px) {
          order: 1;
          width: 400px;
        }

        @media only screen and (max-width: 720px) {
          display: none;
        }
      }

      .features {
        @media only screen and (max-width: 910px) {
          order: 2;
          display: flex;
          align-items: center;
          gap: 20px;
        }

        @media only screen and (max-width: 720px) {
          display: block;
          max-width: 300px;
        }

        .feature {
          padding-left: 110px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 10px;
          position: relative;
          text-align: left;
          border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};;
          border-radius: 24px;
          margin-bottom: 20px;
          cursor: pointer;
          max-width: 700px;

          .feature-info{
            padding-left: 10px;
            padding-right: 10px;
          }
          
          @media only screen and (max-width: 990px) {
            padding-left: 100px;
          }

          @media only screen and (max-width: 910px) {
            padding-top: 90px;
            width: auto;
            height: 280px;
            text-align: center;
            flex-basis: 100%;
            padding-left: 0px;
            padding-right: 0px;
          }

          @media only screen and (max-width: 720px) {
            height: auto;
          }

          &::before {
            content: " ";
            background-image: url(${TeamsFeaturesIcons});
            background-repeat: no-repeat;
            width: 72px;
            height: 72px;
            position: absolute;
            left: 20px;

            @media only screen and (max-width: 910px) {
              margin: auto;
              left: 0;
              right: 0;
              text-align: center;
              top: 10px;
            }
          }
          
          &.selected{
            transition: 0.5s all ease-in-out;
            border: 1px solid ${({theme:{teams:{colors: {borderLight}}}})=>borderLight};
            cursor: pointer;
            box-shadow: 0px 12px 24px 0px rgba(129, 70, 255, 0.08); 
          }

          &.team-members {
            ::before {
              background-position: 0px 0px !important;
            }
          }
          &.team-servers {
            ::before {
              background-position: -70px 0px !important;
            }
          }
          &.ded-ip {
            ::before {
              background-position: -140px 0px !important;
            }
          }
        }
      }
    }
  }

  .plans-section {
    margin: 80px auto auto auto;
    max-width: 1200px;
    text-align: center;
   
    button{
      @media only screen and (max-width: 794px) {
        padding: 16px 28px !important;
      }
    }

    h2{
      @media only screen and (max-width: 760px) {
        font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
        margin-bottom: 20px;
      }
    }

    
    table {
      width: 100%;
      --border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
      border-radius: 24px;
      border-collapse: collapse;
      
      /* Don't change these properties */
      border-spacing: 0;
      border-collapse: separate;
      border: var(--border);
      overflow: hidden;

      .t-row{
        @media only screen and (max-width: 490px) {
          display: none;
        }
      }
      .t-head{
        display: none;
        @media only screen and (max-width: 490px) {
          display: block;
          margin-bottom: 10px;
        }
      }

      thead > tr > th {
        padding: 32px;
        vertical-align: top;
        h4 {
          font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
          font-style: normal;
          font-weight: 600;
          margin-bottom: 16px;
        }
        p {
          font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
          font-style: normal;
          font-weight: 400;
          margin-bottom: 0px;
        }
        .price {
          font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
          font-weight: 400;
          margin-bottom: 16px;

          @media only screen and (max-width: 760px) {
            font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
            margin-bottom: 20px;
          }
        }
        
      }

      tbody > tr > td {
        padding: 24px;
        font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};
        font-style: normal;
        font-weight: 400;
      }

      tbody > tr > td:last-child,
      thead > tr > th:last-child {
        background-color: ${({theme:{teams:{colors: {background}}}})=>background};
      }

      tbody > tr > td:first-child,
      thead > tr > th:first-child {
        text-align: left;
        width: 24%;
      }

      thead > tr > th:not(:first-child) {
        width: 38%;
      }

      @media only screen and (max-width: 587px) {
        thead > tr > th,
        tbody > tr > td {
          padding: 14px;
          font-size: ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
          h5 {
            font-size: ${({theme:{teams:{typography: {body}}}})=>body};
          }
          .chip {
            font-size: 10px !important;
          }
          p {
            line-height: 100% !important;
          }
        }
      }
    }
    
    /* Apply a border to the right of all but the last column */
      table th:not(:last-child),
      table td:not(:last-child) {
      border-right: var(--border);
    }
    
    /* Apply a border to the bottom of all but the last row */
    table>tbody>tr:not(:last-child)>td,
    table>thead>tr>th {
      border-bottom: var(--border);
    }
  }

  @media only screen and (max-width: 370px) {
    .compare-table {
      overflow-x: auto;
    }
  }
`

export const Mark = styled.svg`
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background: url(${MarkIcon}) no-repeat;
  background-position: 0px 0px;
`

export const Cross = styled.svg`
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background: url(${MarkIcon}) no-repeat;
  background-position: -24px 0px;
`

export const FilledRight = styled.svg`
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background: url(${filledRight}) no-repeat;
  background-position: 0px 0px;
`

export const BorderCross = styled.svg`
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background: url(${borderCross}) no-repeat;
  background-position: 0px 0px;
`

export const BorderTick = styled.svg`
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background: url(${borderTick}) no-repeat;
  background-position: 0px 0px;
`

export const TableSec = styled.div`
  margin: 100px auto auto auto;
  width: 75%;

  @media only screen and (max-width: 1440px) {
    width: 100%;
  }

  button{
    border-radius: 30px !important;
    padding: 8px 30px !important;
    font-size: 16px !important;
    margin: auto;
    display: block;
    width: max-content;
    margin-bottom: 32px;
    margin-top: 48px;
  }

  h3{
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 700;

    @media only screen and (max-width: 900px) {
      font-size: 28px;
    }
  }

  .comparison-table{
    display: flex;
    align-items: end;
    position: relative;

    button{
      font-size: 12px !important;
      padding: 12px 20px !important;
      height: 40px;
      @media only screen and (max-width: 910px) {
        font-size: 10px !important;
      }
      @media only screen and (max-width: 780px) {
        font-size: 10px !important;
      }
      @media only screen and (max-width: 590px) {
        height: 30px;
        padding: 8px 10px !important;
      }
      @media only screen and (max-width: 380px) {
        font-size: 8px !important;
      }
    }

    .hide{
      opacity: 0;
    }
    .pad-head{
      height: max-content; 
    }

    h2{
      padding-top: 59px;
      color: #000;
      text-align: center;
      font-size: 26.247px;
      font-weight: 700;
      line-height: 26.247px;
      letter-spacing: -0.525px;
      margin: 0;
      padding-bottom: 35px;

      @media only screen and (max-width: 760px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 420px) {
        font-size: 14px;
      }
      &.features{
        opacity: 0;
      }
      &.absolute-head{
        position: absolute;
        top: -120px;
        margin: 0;
        content: '';
        left: auto;
        right: auto;
        width: 100%;
      }
      &.teams{
        color: #fff;
      }
    }
    .features-head{
      padding-right: 14px;
      width: 34%;

      @media only screen and (max-width: 760px) {
        width: 22%;
      }
      @media only screen and (max-width: 450px) {
        width: 25%;
      }
      @media only screen and (max-width: 390px) {
        width: 36%;
      }
      .desc{
        position: relative;
        color: #001219;
        font-size: 16px;
        font-weight: 700;
        line-height: 17.28px;
        letter-spacing: -0.16px;
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(121, 121, 121, 0.17);

        @media only screen and (max-width: 700px) {
          font-size: 12px;
        }
        :last-child, :nth-last-child(2){
          border-bottom: none;
        }
      }
    }
    .individual-head{
      border-top-left-radius: 17.78px;
      border-bottom-left-radius: 17.78px;
      border: 0.889px solid rgba(99, 115, 129, 0.20);
      background: #FFF;
      box-shadow: 0px 4.965px 17.993px 0px rgba(101, 30, 216, 0.20);
      width: 22%;
      position: relative;

      @media only screen and (max-width: 760px) {
        width: 26%;
      }

      @media only screen and (max-width: 450px) {
        width: 38%;
        &.has-fp{
          display: none;
        }
      }
      @media only screen and (max-width: 390px) {
        width: 32%;
      }
      
      .desc{
        margin: 0px 10px;
        color: #92A0AC;
        font-size: 16px;
        font-weight: 700;
        line-height: 17.28px;
        letter-spacing: -0.16px;
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(121, 121, 121, 0.17);
        text-align: center;
        justify-content: center;
        @media only screen and (max-width: 700px) {
          font-size: 12px;
        }
        :last-child, :nth-last-child(2){
          border-bottom: none;
        }
        &.first-child{
          margin-top: 28px;
        }
      }
    }
    .family-head{
      border: 0.889px solid rgba(99, 115, 129, 0.20);
      background: #FFF;
      box-shadow: 0px 4.965px 17.993px 0px rgba(101, 30, 216, 0.20);
      width: 22%;
      position: relative;
      @media only screen and (max-width: 760px) {
        width: 26%;
      }
      @media only screen and (max-width: 450px) {
        width: 38%;
        &.has-fp{
          display: none;
        }
      }
      @media only screen and (max-width: 390px) {
        width: 32%;
      }
      .desc{
        color: #92A0AC;
        font-size: 16px;
        font-weight: 700;
        line-height: 17.28px;
        letter-spacing: -0.16px;
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(121, 121, 121, 0.17);
        text-align: center;
        justify-content: center;
        margin: 0px 10px;
        @media only screen and (max-width: 700px) {
          font-size: 12px;
        }
        :last-child, :nth-last-child(2){
          border-bottom: none;
        }
        &.first-child{
          margin-top: 28px;
        }
      }
    }
    .team-head{
      outline: 10.668px solid rgba(114, 9, 183, 0.16);
      background: linear-gradient(87deg, #3D128F 0.35%, #9B7DFF 109.34%);
      border-top-right-radius: 17.78px;
      width: 22%;
      border-bottom-right-radius: 17.78px;
      border-top-left-radius: 17.78px;
      z-index: 2;
      @media only screen and (max-width: 760px) {
        width: 26%;
      }
      @media only screen and (max-width: 450px) {
        width: 38%;
        outline: none;
      }
      @media only screen and (max-width: 390px) {
        width: 32%;
      }
      
      .desc{
        color: #fff;
        margin: 0px 10px;
        font-size: 16px;
        font-weight: 700;
        line-height: 17.28px;
        letter-spacing: -0.16px;
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.49);
        text-align: center;
        justify-content: center;
        @media only screen and (max-width: 700px) {
          font-size: 12px;
        }
        &.first-child{
          margin-top: 28px;
        }
        :last-child, :nth-last-child(2){
          border-bottom: none;
        }
      }
    }
  }
  .last-block{
    .desc{
      height: 120px !important;
    }
  }
  .chip {
    padding: 2px 8px;
    color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
    border-radius: 4px;
    background: #FFCB5B;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 600;
    width: max-content;
    margin: 58px auto 42px;
  }
  
`