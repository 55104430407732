import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher } from '../../tools/tools';
import { errorsType, getDeviceType, loadingType } from './actions';
import i18n from "i18next";

function* deviceAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const download = yield fetcher("devices", action?.payload, cancelTokenSource)
        const {data: response} = download;
        if(response?.status){
            yield put(getDeviceType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchDeviceRequest(){
    yield takeLatest("GETDEVICEREQUEST", deviceAsync)
}