import { 
    Button, CancelledContent, CancellationFlowActions, CancellationFomo, CancellationOptions, CancellationStyles, CloseIconSVG, Downgrade, dispatchError, displayError, getTokenFromLocalStorage, getSubscriptionsRequest, GoBack, InfoCard, Modal, ModalBody, poster, qs, sendEvents, Skeleton, toast, useDispatch, useEffect, useState,
} from "./index"

const Cancellation = ({toggle, modal, selectedSubscription, resumeSelectedSubscription}) => {

    const dispatch = useDispatch();

    const [cancellationArray, setCancellationArray] = useState([]);
    const [cta, setCta] = useState();
    const [loading, setLoading] = useState(true);
    const [platformInfo, setPlatformInfo] = useState();
    const [relatedQuestionId, setRelatedQuestionId] = useState();
    const [response, setResponse] = useState();
    const [showFomo, setShowFomo] = useState(true);
    const [selectedActionOnOption, setSelectedActionOnOption] = useState();
    const [showDowngradeSummary, setShowDowngradeSummary] = useState(false);

    const [downGradePreview, setDowngradePreview] = useState()

    // saving selected option value in order to pass it down to the mixpanel
    const [selectionOption, setSelectedOption] = useState();

    //state to handle button in case of showing a textarea
    const [submitReasonButton, setSubmitReasonButton] = useState();

    const [comments, setComments] = useState();
    const [emptyFieldError, setEmptyFieldError] = useState();

    // show textarea on selecting certain options with 'addReason' as action
    const [showTextarea, setShowTextarea] = useState();

    const [planCode, setPlanCode] = useState();
    const [stopSubscription, setStopSubscription] = useState();

    const [initialReason, setInitialReason] = useState();

    useEffect(()=>{
        if(!showFomo){
            getCancellationOptions();
        }
        else{
            sendEvents({
                event_name:"ma_view_confirm_cancellation",
                event_properties:{}
            }, getTokenFromLocalStorage())
        }
    },[showFomo, relatedQuestionId])

    const onClickHandlerOnFomo = () => {
        setShowFomo(false);
        sendEvents({
            event_name:"ma_click_confirm_cancellation",
            event_properties:{}
        }, getTokenFromLocalStorage())
    }

    const getCancellationOptions = async () => {
        try {
            setLoading(true)
            const requestData = {
                "subscription_id": selectedSubscription.id,
                "service_origin": selectedSubscription.service_origin,
                "related_question_id": relatedQuestionId ?? "", 
                "extra_params":{
                    ...platformInfo
                },
                ...cta
            }
            const formData = qs.stringify(requestData);
            if(![...cancellationArray.filter((subs)=>subs.related_question_id === relatedQuestionId)].length){
                setCancellationArray([...cancellationArray, requestData]);
            }
            const {data: response} = await poster("cancel/subscription", formData, getTokenFromLocalStorage());
            if(response?.status){
                setResponse(response?.body);
                setLoading(false)
                setPlatformInfo()
            }
            else{
                dispatchError(response?.error_code, dispatch);
                toggle();
            }
        } 
        catch (error) {
            displayError(error);
            toggle();
        }
        finally{
            setCta()
        }
    }

    const onClickHandler = (id, action) => {
        setRelatedQuestionId(id) 
        if(action === 'cancelSubscriptionOptions'){
            setCta({
                extra_params: {
                    cta: 'cancel'
                }
             })
        }
        // if(action === 'addReason'){
        //     triggerReasoningEvents({performAction: action})
        // }
    }
    
    const onGoBackClick = () => {
        if(cancellationArray.length === 0){
            setShowFomo(!showFomo)
        }
        else{
            let tempArray = cancellationArray.slice(0, -1);
            setCancellationArray(tempArray);
            setRelatedQuestionId(tempArray[cancellationArray.length - 2]?.related_question_id)
            setPlatformInfo(tempArray[cancellationArray.length - 2]?.extra_params)
            setCta(tempArray[cancellationArray.length - 2]?.cta)
        }
    }

    const closeCancellationModal = () => {
        toggle();
        setCancellationArray([]);
        clearChatbot();
    }

    const clearChatbot = () => {
        window.location.hash = '';
        window.Intercom('shutdown')
        window.Intercom('boot')
    }

    const commentChangeHandler = (e) => {
        setComments(e.target.value)
        if(e.target.value){
            setEmptyFieldError(false)
        }
    }

    const onSubmitTextarea = () =>{
        if(comments?.length > 0){
            performReasoningActions(selectedActionOnOption)
        }
        else{
            setEmptyFieldError(!emptyFieldError)
        }
    }


    const sendMixPanelEventsOnView = () => {
        sendEvents({
            event_name:"ma_view_solution_cancellation",
            event_properties:{
                'cancellationreason': selectionOption,
            }
        }, getTokenFromLocalStorage())
    }

    const sendMixPanelEvents = (payload) => {
        const {comments, name} = payload;

        sendEvents({
            event_name:"ma_click_reason_cancellation",
            event_properties:{
                'initialReason' : initialReason ? initialReason : cancellationArray.length === 1 ? name : "",
                'solutionReason': cancellationArray.length === 1 ? "" : name ?? selectionOption,
                'comments' : comments?.trim()
            }
        }, getTokenFromLocalStorage())
    }

    const triggerReasoningEvents = (payload) => {
        setComments()
        const { name , relatedQuestionId} = payload;

        setShowTextarea({
            show: true,
            option: name, 
            id: relatedQuestionId
        });
        setSubmitReasonButton(true);

    }

    const performReasoningActions = (action) => {

        switch(action){
            case CancellationFlowActions.openLiveChat : triggerOpenLiveChat(); break;
            case CancellationFlowActions.cancelSubscriptionWithReason : triggerNextQuestion(); break;
            case CancellationFlowActions.getUserReason : triggerNextQuestion(action); break;
            case CancellationFlowActions.cancelSubscriptionRequest : triggerNextQuestion(); break;
            case CancellationFlowActions.askReasonForNotSatisfiedWithAddon : triggerNextQuestion(); break;
        }

        sendMixPanelEvents({comments, name: selectionOption});
        
        setShowTextarea({
            show: false,
            option: '',
            id: ''
        });

        setSubmitReasonButton(false);

    }

    const triggerOpenLiveChat = () => {
        window.Intercom('show');
        triggerNextQuestion()
    }

    const triggerNextQuestion = (actionName = null) => {
        if( actionName != CancellationFlowActions.getUserReason ) {
            setCta({
                extra_params: {
                    cta: 'cancel'
                }
             })
        }
        setRelatedQuestionId(showTextarea.id)
        setCancellationArray(cancellationArray=> [...cancellationArray, {relatedQuestionId:showTextarea.id, cta: 'cancel'}])
    }

    // * Implemented to preserve a history of questions accessed
    // * helps in back tracking the previous question on back
    // const updateCancellationArray = (relatedQuestionId) => {
    //     setCancellationArray(cancellationArray=> [...cancellationArray, relatedQuestionId])
    // }

    const stopSubscriptionRequest = async() => {
        
        try {
            setLoading(true)
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.id
            })
            const pause =  await poster("subscription/pause", formdata, getTokenFromLocalStorage());
            const {data: response} = pause;

            if(response?.status){
                toast.success('success')
                dispatch(getSubscriptionsRequest(getTokenFromLocalStorage()))
            } else {
                dispatchError(response?.error_code, dispatch);
            }
        } catch (error) {
            displayError(error);
        }
        finally{
            setLoading(false)
            setStopSubscription(true)
            setResponse()
        }
    }

    const onResumeClick = () =>{
        resumeSelectedSubscription(selectedSubscription?.id, selectedSubscription?.state === "canceled" ? 'reactivate' : 'resume')
        toggle()
    }

    const viewDownGradeSummary = (value) => {
        setPlanCode(value)
        setShowDowngradeSummary(true)
        setDowngradePreview(1)
    }
    

    return <Modal  backdrop={modal} isOpen={modal} toggle={closeCancellationModal} style={{maxWidth: `800px`, width: '100%', float: 'right'}} className="modal-wrapper" contentClassName="cancellation-content">
            {!showFomo && !loading && !showDowngradeSummary && <span style={{position: 'absolute', top: '21px', left: '21px', cursor: 'pointer', zIndex: 1}} onClick={onGoBackClick}><GoBack /></span>}
            <span style={{position: 'absolute', top: '21px', right: '21px', cursor: 'pointer', zIndex: 1}} onClick={closeCancellationModal}><CloseIconSVG width={24} height={24}/></span>
            <ModalBody>
            <CancellationStyles>
                {
                    showFomo ? 
                    <>
                        <div className="content fomo-content">
                            <div className='heading'>
                                <h2>Are you sure?</h2>
                                <h3>If you cancel, here's what you'll miss out on!</h3>
                            </div>
                            <CancellationFomo></CancellationFomo> 
                        </div>
                        <div className="action-btns">
                            <Button className="outline-btn primary-btn" onClick={onClickHandlerOnFomo}>
                                Continue to cancellation
                            </Button>
                            <Button className="solid-btn primary-btn" onClick={closeCancellationModal}>
                                Close
                            </Button>
                        </div>
                    </>
                    : 
                        loading ? 
                            <div className='m-2 '>
                                <InfoCard hasborder noflex className="mb-3">
                                    <Skeleton height={20} width={600}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard noflex className="pt-0">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                            </div>
                        :
                        <>
                        {showDowngradeSummary ? 
                            <Downgrade planCode={planCode} id={selectedSubscription?.id} state={selectedSubscription?.state} toggle={toggle} downGradePreview={downGradePreview} setDowngradePreview={setDowngradePreview}/> :                                        
                            <div className={`extra-padding content`}>
                            {!stopSubscription ? <>
                                    <div className='heading'>
                                        {response?.question_details?.heading && <h4>{response?.question_details?.heading}</h4>}
                                        {response?.question_details?.question && <h5>{response?.question_details?.question}</h5>}
                                    </div>
                                    <CancellationOptions 
                                        cancellationArray={cancellationArray}
                                        commentChangeHandler={commentChangeHandler}
                                        component={response?.question_details?.component} 
                                        emptyFieldError={emptyFieldError}
                                        initialReason={initialReason}
                                        optionsList={response?.question_details?.options} 
                                        para={response?.question_details?.question}
                                        selectedSubscription={selectedSubscription} 
                                        selectionOption={selectionOption}
                                        sendMixPanelEvents={sendMixPanelEvents}
                                        sendMixPanelEventsOnView={sendMixPanelEventsOnView}
                                        setCta={setCta}
                                        setInitialReason={setInitialReason}
                                        setLoading={setLoading}
                                        setPlatformInfo={setPlatformInfo} 
                                        setRelatedQuestionId={setRelatedQuestionId} 
                                        setSelectedActionOnOption={setSelectedActionOnOption}
                                        setSelectedOption={setSelectedOption}
                                        setShowTextarea={setShowTextarea}
                                        setSubmitReasonButton={setSubmitReasonButton}
                                        showTextarea={showTextarea}
                                        stopSubscriptionRequest={stopSubscriptionRequest}
                                        toggle={closeCancellationModal} 
                                        triggerReasoningEvents={triggerReasoningEvents}
                                        viewDownGradeSummary={viewDownGradeSummary}
                                    ></CancellationOptions>
                                </> : 
                                <CancelledContent /> }
                                </div>
                            }
                            {/* <div className={`${btns.length === 1 ? 'center' : ''} action-btns`}> */}
                            <div className={`action-btns`}>
                                {submitReasonButton ? <Button className='primary-btn outline-btn' onClick={onSubmitTextarea}>
                                    Continue to cancellation
                                </Button> : null}
                                {!showDowngradeSummary && response && response?.cancellation_modal_cta.map((button)=>{
                                    return <Button className={`${button?.style} primary-btn`} onClick={()=> button?.related_question_id ?  onClickHandler(button?.related_question_id, button?.action) : closeCancellationModal()}>
                                        {button?.text}
                                    </Button>
                                })}
                                {stopSubscription ? <Button className='primary-btn solid-btn' onClick={onResumeClick}>
                                    Resume Subscription
                                </Button> : null}
                            </div>
                        </>
                }
                </CancellationStyles>

            </ModalBody>
        </Modal>
}
export default Cancellation;
