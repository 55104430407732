import { currencySymbol } from '../../../tools/tools'
import { InsetBtn } from "../../../styles/Generic";
import { PrimaryBtn } from '../../../styles/Generic';
import { ReactComponent as Tick } from '../../../assets/tick.svg'
import { SubscriptionBox} from '../../../styles/dashboard/Main'
import { useState } from 'react';
import AllDevices from '../../../assets/fp-all-devices.png'
import Credentials from "../../../assets/fp-credentials.png";
import Devices from "../../../assets/fp-devices.png";
import FamilyPlanPoints from "../../../assets/fp-pts.png";
import FamilyPlanPoints2 from "../../../assets/fp-pts-2.png";
import FamilyPlanPoints3 from "../../../assets/fp-pts-3.png";
import FPWorks from "../../../assets/fp-works.png"
import Person from "../../../assets/person.png"
import Price from "../../../assets/fp-price.png";
import { useTranslation } from 'react-i18next';

const PrePurchase = ({GotoDiv, scrollOrderConfirmation, selectedSubscriptionData, toggleVideo, upgradeToFamilyPlan}) => {

    const [pointsId, setPointsId] = useState();

    const { t, i18n } = useTranslation();

    let familyPlanImp = [
        {heading: 'familyplan_stay_in_touch', paragraph: 'familyplan_stay_in_touch_para', key: 0, image: FamilyPlanPoints},
        {heading: 'familyplan_ensure_seemless', paragraph: 'familyplan_ensure_seemless_para', key: 1, image: FamilyPlanPoints3},
        {heading: 'familyplan_safegaurd', paragraph: 'familyplan_safegaurd_para', key: 2, image: FamilyPlanPoints2},
    ];

    let experienceInfo = [
        {heading: 'familyplan_individual_creds', paragraph: 'familyplan_indiviudal_para_first', image: Credentials},
        {heading: 'familyplan_feature_card3_heading', paragraph: 'familyplan_individual_creds_para', image: Price},
        {heading: 'familyplan_more_accounts', paragraph: 'familyplan_more_accounts_para', image: Devices},
    ];

    const openPoints = (id) => {
        id === pointsId ? setPointsId() : setPointsId(id)
    }  

    const getDiscountedPercentage = (currency, type, plan) =>{
        let savedValue = plan?.plan_discount;
        const planCurrency = plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]
        if(type == "PERCENTAGE" && planCurrency?.discount != 0){
            savedValue = `${t('family_save_text')} ${planCurrency?.discount}%`
        }else if(type == "FIXED" && plan?.plan_discount != 0){
            savedValue = `${t('family_save_text')} ${currencySymbol[currency]}${savedValue}`
        }else{
            savedValue = "NO SAVINGS"
        }
        return savedValue
    }

    const getPlansList = (plan) => {
        return <ul>
            {
            ["familyplan_packages_cardtwo_included_in_your_plan",
            `${plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? '2' : '5'} ${t('familyplan_packages_cardtwo_five_vpn_accounts')}`,
            "familyplan_packages_cardtwo_seperate_vpn",
            "familyplan_packages_cardtwo_ten_devices"]
            .map((list)=>{
                return <li>
                    <figure>
                        <Tick />
                    </figure>
                    <span>{t(list)}</span>
                </li>
            })}
        </ul>
    }

    const getPlanPrice = (currency, price,interval_length=1) =>{
        let returningPrice = null
        returningPrice = currencySymbol[currency] + parseFloat(price/interval_length).toFixed(2)
        return returningPrice
    }

    const getActualPlanPrice = (currency, plan, interval_length=1) =>{
        let returningPrice = null
        returningPrice = currencySymbol[currency] + parseFloat(plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]?.actual_price/interval_length).toFixed(2)
        return returningPrice
    }

    const getNumberOfPersons = (code) => {
        let arr;
        code == 2 ? arr = [1,2] : arr = [1,2,3,4,5];
        return arr
    }

    const getPlanPriceByCurrency = (currency, plan,interval_length=1) =>{
        let returningPrice = null;
        returningPrice = currencySymbol[currency] + parseFloat(plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]?.unit_amount/interval_length)?.toFixed(2)
        return returningPrice
    }

    return <>
        <section className='family_plan_header'>
            <h2>{t('familyplan_header_heading')}</h2>
            <p>{t('familyplan_header_para')}</p>
        </section>

        <section className='family_plan_imp'>
            <div className='family-plan-points'>
                    <article>
                        {familyPlanImp.map((points)=>{
                            return <SubscriptionBox className='fp-imp' hover={true} open={pointsId === points.key}>
                                <div className="subscription-head" onClick={()=>openPoints(points.key)}>
                                    <div className='content'>
                                        <h4>{t(points.heading)}</h4>
                                        <InsetBtn
                                                className="accordianBtn"
                                            >
                                        </InsetBtn>
                                    </div>
                                </div>
                                <div className={`subscription-body ${pointsId === points.key ? 'show' : ""}`}>
                                    <p>{t(points.paragraph)}</p>
                                </div>
                            </SubscriptionBox>
                        })}
                    </article>
                    <figure>
                        <img src={familyPlanImp.filter((pts)=> pts.key === pointsId)?.[0]?.image ?? FamilyPlanPoints} alt="family-plan-points" />
                    </figure>
            </div>
        </section>

        <section className='family_plan_experience'>
            <h2>{t('familyplan_family_interest')}</h2>
            <div className='experience_content'>

                {experienceInfo.map((info)=>{
                    return <SubscriptionBox open className="family_plan_box">
                        <div className='info_fig'>
                            <figure><img src={info.image} alt="experience-img" /></figure>
                        </div>
                        <div className='info-content'>
                            <h5>{t(info.heading)}</h5>
                            <p>{t(info.paragraph)}</p>
                        </div>
                    </SubscriptionBox>
                })}

            </div>
            <PrimaryBtn type="button" className="primary-btn" isFP={true} onClick={(e) => GotoDiv(e.target.innerText)}>{t('familyplan_packages_cardtwo_cta')}</PrimaryBtn>
        </section>

        <section className='family_plan_works'>
            <SubscriptionBox className="d-flex align-items-center fp-works">
                <figure>
                    <img src={FPWorks} alt="Choose the plan that works best for you and your family" />
                </figure>
                <div className='works_content'>
                    <h4>{t('familyplan_chose_plan_heading')}</h4>
                    <p>{t('familyplan_chose_plan_para')}</p>
                    <div className='works_btns'>
                        {selectedSubscriptionData?.family_plans.sort((a,b)=> a?.max_account - b?.max_account).map((plan)=>{
                            return <div className='btn-grp'>
                                <span>
                                    {
                                        getDiscountedPercentage(
                                            selectedSubscriptionData?.current_plan?.currency, 
                                            plan?.plan_discount_type,
                                            plan,
                                        )
                                    }
                                </span>
                                <PrimaryBtn type="button" className="primary-btn" isFPW2={plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? true : false} isFPW4={plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? true : false} onClick={(e) => GotoDiv(e.target.innerText, plan?.max_account)}>{`${t('familyplan_family_members', {count: plan?.max_account})}`}</PrimaryBtn>
                            </div>
                        })}
                    </div>
                </div>
            </SubscriptionBox>
        </section>

        <section className='how_it_works'>
            <h4>{t('familyplan_want_to_know_how_it_works')}</h4>
            <p>{t('familyplan_want_to_know_how_it_works_para')}</p>
            <PrimaryBtn type="button" className="primary-btn" isFP={true} isFPH={true} onClick={toggleVideo}>{t('familyplan_watch_video')}</PrimaryBtn>
        </section>

        <div ref={scrollOrderConfirmation}></div>
        
        <section className='compare_plans'>
            <h4>{t('familyplan_compare_all_plans')}</h4>

            <div className='compare-boxes'>
                <div className='compare-box-head'>
                    
                    {/* CURRENT PLAN */}
                    {selectedSubscriptionData?.current_plan && <SubscriptionBox className='compare-box'>
                        <h3>{t('familyplan_for_individual_user')}</h3>
                        <h5>{
                                getPlanPrice(
                                    selectedSubscriptionData?.current_plan?.currency, 
                                    selectedSubscriptionData?.current_plan?.unit_amount, 
                                    selectedSubscriptionData?.current_plan?.interval_length
                                )
                            }
                            <span>/ {t('upgradeplan_month')} {t('familyplan_packages_current_plan')}</span>
                        </h5>

                        <ul>
                            <li>
                                <figure>
                                    <Tick />
                                </figure>
                                <span>{t('familyplan_packages_one_vpn_account')}</span>
                            </li>
                            <li>
                                <figure>
                                    <Tick />
                                </figure>
                                <span>{t('familyplan_packages_multi_login')}</span>
                            </li>
                        </ul>

                        <figure>
                            <img src={AllDevices} alt='' />
                        </figure>
                    </SubscriptionBox>}
                </div>

                {selectedSubscriptionData?.family_plans?.length > 0 && selectedSubscriptionData?.family_plans.sort((a,b)=> a?.max_account - b?.max_account).map((plan)=>{
                    
                    return <div className='compare-box-head'>
                        <SubscriptionBox className={`compare-box white ${plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'two-member-box': 'five-member-box'}`}>
                            <span className='persons'>
                                {getNumberOfPersons(plan?.max_account).map((num)=> {return <img src={Person} alt={`${num} persons`} />})}
                            </span>
                            <h3>{`${t('familyplan_family_members', {count: plan?.max_account})}`}</h3>
                            <span className='percent'>
                                {
                                    getDiscountedPercentage(
                                        selectedSubscriptionData?.current_plan?.currency, 
                                        plan?.plan_discount_type,
                                        plan,
                                    )
                                }
                            </span>
                            <h5>
                                {
                                getPlanPriceByCurrency(
                                    selectedSubscriptionData?.current_plan?.currency, 
                                    plan,
                                    plan?.interval_length
                                )
                            }
                                <span>{`/ ${t('upgradeplan_month')} ${t('for_text')} ${plan?.max_account} ${t('familyplan_vpn_accounts')}`}</span>
                            </h5>
                            <h6>
                                {
                                    getActualPlanPrice(
                                        selectedSubscriptionData?.current_plan?.currency, 
                                        plan, 
                                        plan?.interval_length
                                    )
                                }
                                {` / ${t('upgradeplan_month')}`}


                            </h6>

                            {getPlansList(plan)}

                            <div className='text-center'>
                                <PrimaryBtn type="button" className="primary-btn compare-btn" isFP={true} onClick={()=>{upgradeToFamilyPlan(plan)}}>{t('familyplan_packages_cardtwo_cta')}</PrimaryBtn>
                            </div>

                        </SubscriptionBox>
                    </div>
                })}
                
            </div>
        </section>
    </>
}

export default PrePurchase;