import React from 'react'
import QRCode from "react-qr-code";

const QRcodeGenerator = ({value="hello world", className}) => {
  return (
    <div className={className} style={{ height: "auto", margin: "0 auto", maxWidth: 134, width: "100%" }}>
    
    <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    value={value}
    viewBox={`0 0 256 256`}
    />
</div>
  )
}

export default QRcodeGenerator