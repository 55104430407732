import { basePrice } from "../../../../tools/constants"
import { checkAccountType, currencySymbol, getPaymentMethods, getTokenFromLocalStorage } from "../../../../tools/tools"
import { getVPNplanType } from "../../upgrade/upgrade-tools"
import { Cross, TableSec, BorderCross, FilledRight, BorderTick } from "../../../../styles/dashboard/pure-teams/prePurchase"
import { PrimaryBtn } from "../../../../styles/Generic"
import { familyContent, individualContent, tableContentRevised, teamContent } from "../content"
import { sendEvents } from "../../../../tools/mpEvents"
import { withRouter } from "react-router-dom"

const PrePurchaseTable = ({history, selectedSubscriptionData, plansInfo, openLicensingSelectionModal }) => {
    const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);
    const token = getTokenFromLocalStorage();

    const onRedirectToFamily = () => {
        sendEvents({
            event_name:"ma_click_get_account",
            event_properties: {
            account_type: checkAccountType(selectedSubscriptionData),
            plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
            billingcycle: selectedSubscriptionData?.type ?? 'N/A',
            payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
            current_status: selectedSubscriptionData?.state ?? 'N/A',
            selected_account: 'family',
            platform_from: 'Member Area',
        }
        }, token);
        history.push('/dashboard/family-plan')
      }

    return (
        <TableSec>
            <h3>Why choosing Teams plan is a best fit for you</h3>
            <p className="paragraph text-center">An enhanced experience of online security for your needs.</p>
            <div className="comparison-table">
                <div className="features-head">
                <h2 className="features">Features</h2>
                    {
                        tableContentRevised.map((row, index) => {
                            return <div key={index} className="desc">{row}</div>
                        })
                    }
                    <div className="last-block">
                        <div className="desc"></div>
                    </div>
                </div>
                <div className={`individual-head pad-head ${selectedSubscriptionData?.hasFamilyPlan ? 'has-fp' : ''}`}>
                <h2 className="absolute-head">Individual</h2>
                    {
                        individualContent.map((row, index) => {
                            return <>
                                <div key={index} className={`desc ${index === 0 ? 'first-child' : ''}`}>{row === 'Yes' ? <><BorderTick /></> :  row === 'No' ? <><BorderCross /></> : row}</div>
                            </>
                        })
                    }
                    <div className="desc">{`${currencySymbol[selectedSubscriptionData?.currency]}${basePrice[getVPNplanType(selectedSubscriptionData?.plan_type)]?.[selectedSubscriptionData?.currency]}/mo`}</div>
                    <div className="last-block">
                        {!selectedSubscriptionData?.hasFamilyPlan ? <div className="chip">Current Plan</div>: <div className="desc"></div>}
                    </div>
                </div>
                
                <div className={`family-head pad-head ${!selectedSubscriptionData?.hasFamilyPlan ? 'has-fp' : ''}`}>
                <h2 className="absolute-head">Family</h2>
                    {
                        familyContent.map((row, index) => {
                            return <>
                            <div key={index} className={`desc ${index === 0 ? 'first-child' : ''}`}>{row === 'Yes' ? <><BorderTick /></> :  row === 'No' ? <><BorderCross /></> : row}</div>
                            </>
                        })
                    }
                    <div className="desc">$5.99/mo/member</div>
                    <div className="last-block">
                        {selectedSubscriptionData?.hasFamilyPlan ? <div className="chip">Current Plan</div> : <PrimaryBtn type="button" className="primary-btn" onClick={() => onRedirectToFamily()} isTeams1>Upgrade to Family</PrimaryBtn>}
                    </div>                    
                </div>
                <div className="team-head">
                <h2 className="teams">Teams</h2>
                    {
                        teamContent.map((row, index) => {
                            return <>
                            <div key={index} className={`desc ${index === 0 ? 'first-child' : ''}`}>{row === 'Yes' ? <><FilledRight /></> :  row === 'No' ? <><Cross /></> : row}</div>
                            </>
                        })
                    }
                    <div className="desc">{`${currencySymbol[selectedSubscriptionData?.currency]}${pricing?.[selectedSubscriptionData?.plan?.interval_length]}/mo/member`}</div>
                    <PrimaryBtn type="button" className="primary-btn white" onClick={() => openLicensingSelectionModal({type: 'table'})} isTeams1>Upgrade to Teams</PrimaryBtn>
                </div>
            </div>
        </TableSec>
    )
}

export default withRouter(PrePurchaseTable);