import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher, get } from '../../tools/tools';
import { errorsType, getChannelsInfo, loadingType } from './actions';
import i18n from "i18next";

function* channelAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const {token} = action;
        const channel = yield get("channels", token, cancelTokenSource)
        const {body: response} = channel.data;
        if(channel?.data?.status){
            yield put(getChannelsInfo(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message || channel?.data?.message}))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchChannelRequest(){
    yield takeLatest("GETCHANNELREQUEST", channelAsync)
}