import { SUBSRIPTIONREQUESTED, ERRORS, GETACCOUNT, GETACCOUNTREQUESTED, LOADING, UPDATESUBSCRIPTION, USERNAMEREQUESTED, USERNAMEUPDATE } from "./types"

export const getAccountRequested = (payload) => {
    return{
        type:GETACCOUNTREQUESTED,
        payload
    }
}

export const getAccountType = (payload) => {
    return{
        type:GETACCOUNT,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}

export const usernameRequested = (payload) => {
    return{
        type:USERNAMEREQUESTED,
        payload
    }
}

export const usernameType = (payload) => {
    return{
        type:USERNAMEUPDATE,
        payload
    }
}

export const subscriptionRequested = (payload) => {
    return{
        type:SUBSRIPTIONREQUESTED,
        payload
    }
}

export const subscriptionType = (payload) => {
    return{
        type:UPDATESUBSCRIPTION,
        payload
    }
}