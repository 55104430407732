import React, { useState, useEffect } from 'react'
import {  InfoCard, PopupBody, Input, ImageDiv, ButtonLink } from '../../../../styles/dashboard/Main'
import { DestructBtn, LinkBtn, PrimaryBtn } from '../../../../styles/Generic'
import classNames from 'classnames'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import copy from '../../../../assets/copy.png'
import show from '../../../../assets/show.png'
import qs from 'qs';
import { poster, filterSlug } from '../../../../tools/tools';
import { toast } from 'react-toastify';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendEvents } from '../../../../tools/mpEvents';
import { useTranslation } from 'react-i18next';

const GeneratePassword = ({history, backdrop, toggle, modal, modalWidth, widthCalculator, selectedUsername}) => {

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const { t, i18n } = useTranslation();

    const [modalWrapperWidth, setModalWrapperWidth] = useState(modalWidth[widthCalculator])

    const token = localStorage.getItem("token")

    const [data, setdata] = useState({
        username:"",
        password:"",
        passwordgenerated:false
    })

    useEffect(() => {
        if (data?.passwordgenerated) {
            setModalWrapperWidth("675px")
        } else {
            setModalWrapperWidth(modalWidth[widthCalculator])
        }
    }, [data])

    const regeneratePassword = async () => {
        setloading(true)
        try {
            const formdata = qs.stringify({
                username: selectedUsername
            })
            const password =  await poster("vpn/regenerate-password", formdata, token)
            const {data: response} = password;
            if(response?.status){
                toast.success(t(SUCCESS))
                setdata({
                    username:response?.body?.username,
                    password:response?.body?.password,
                    passwordgenerated:true
                })
                sendEvents({
                    event_name:"ma_regenerate_vpnpassword",
                    event_properties:{
                        source: "Member Area",
                        vpnusername: response?.body?.username
                    }
                }, token)
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;   
                    case "vpn_username_password_exception":
                        toast.error(response?.message)
                        break; 
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;  
                    default:
                        toast.error(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_regenerate_vpnpassword",
                    event_properties:{
                        source:"Member Area",
                        reason: response?.message
                    }
                }, token)
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_regenerate_vpnpassword",
                event_properties:{
                    source:"Member Area",
                    reason: "API break or Server not responding."
                }
            }, token)
        }
        finally{
            setloading(false)
        }
    }

    const buttonText = (text) => {
        const ButtonText = !loading ? text : ( <>{text} <span className="spinner-border text-light"></span></>);
        return ButtonText;
    }

    const [copiedusername, setcopiedusername] = useState("")

    const copyfields = async (text) => {
        setcopiedusername(text)
        try {
            await navigator.clipboard.writeText(text)
        } catch (error) {
            toast.error("Unable to copy!")
        }
        setTimeout(() => {
            setcopiedusername("")
        }, 1500);
    }

    const reset = () => {
        setdata({
            username:"",
            password:"",
            passwordgenerated:false
        })
        toggle()
    }

    const onClickImageDivCTA = (e) => {

        sendEvents(
            {
              event_name: `ma_click_view_addon`,
              event_properties: {
                source: "generate-password",
                cta: filterSlug(e.target.innerHTML),
              }
            }, token
        );

        history.push('/dashboard/onboarding/purekeep')
    }
    
    return (
        <div>
            <Modal wrapClassName={`modal-v2`} backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWrapperWidth}`, width: '100%'}} className={`modal-wrapper`}>
                <ModalBody className="pb-0">
                    <PopupBody className='body-box'>
                        {
                            data.passwordgenerated ?
                            <>
                                <div className="mod-head">
                                    <h3 className="m-0">{t('generate_passwords_modal_vpn_pw')}</h3>
                                </div>
                                <InfoCard hasborder noflex className="pt-0">
                                    <div>
                                        <label className="mb-2" style={{fontSize:"12px"}}>{t('tbl_username')}</label>
                                        <Input type="text" fullwidth value={data.username} readOnly/>
                                        <div className="text-end input-actions">
                                            <button className={`btn btn-link btn-tooltip p-0 ${data.username === copiedusername && "copied"}`} onClick={(e) => copyfields(data.username)}>
                                                <img src={copy} />
                                                <span class="tooltiptext" id="myTooltip">{t('copied_text')}</span>   
                                            </button> 
                                        </div>
                                    </div>
                                    <label className="mt-3 mb-2" style={{fontSize:"12px"}}>{t('tbl_password')}</label>
                                    <Input type="text" fullwidth value={data.password} readOnly/>
                                    <div className="text-end input-actions">
                                        <button className={`btn btn-link btn-tooltip p-0 ${data.password === copiedusername && "copied"}`} onClick={(e) => copyfields(data.password)}>
                                            <img src={copy} /> 
                                            <span class="tooltiptext" id="myTooltip">{t('copied_text')}</span>   
                                        </button> 
                                    </div>
                                </InfoCard>
                            </>
                            :
                            <>
                                <div className="mod-head">
                                    <h3>{t('generate_passwords_modal_regenerate_vpn_pw')}</h3>
                                    <p>{t('generate_passwords_modal_are_you_sure')}</p>
                                </div>
                            </>
                        }
                    </PopupBody>
                    <ModalFooter>
                        <PrimaryBtn pointer={loading} onClick={regeneratePassword} color="primary">{!data.passwordgenerated ? buttonText(t('generate_passwords_modal_regenerate_vpn_pw')) : buttonText("Generate Again")}</PrimaryBtn>
                        <DestructBtn pointer={loading} color="secondary" onClick={reset}>{!data.passwordgenerated ? t('cancel_text') : t('close_text')}</DestructBtn>
                    </ModalFooter>
                </ModalBody>
                <ImageDiv className={`box bg-gray ${(!data.passwordgenerated) ? "d-none" : null}`}>
                    <img src="https://images.purevpn-tools.com/public/images/changePassword-Image-purekeep-image-2.png" />
                    <div>
                        <p>{t('generate_passwords_modal_generate_pw')}</p>
                        <ButtonLink onClick={onClickImageDivCTA}>{t('get_text')} PureKeep</ButtonLink>
                    </div>
                </ImageDiv>
            </Modal>
        </div>
    )
}

export default withRouter(GeneratePassword)