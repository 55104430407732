import { PrePurchaseStyles } from "../../../styles/dashboard/pure-teams/prePurchase"
import { PrimaryBtn } from "../../../styles/Generic"
import { benefits, upgradeTeamBenefits } from "./content"
import { teamsFaqs } from "../../../utils/FAQs/faq_list"
import GenericFaqs from "../../../utils/FAQs/genericFaqs"
import PrePurchaseTable from "./layout/prePurchaseTable"
import TeamsBanner from "../../../assets/pure-teams/teams-bg.svg"
import started from "../../../assets/pure-teams/started.png"
import upgrade from "../../../assets/pure-teams/upgrade.png"
import { currencySymbol } from "../../../tools/tools"

const PrePurchase = ({plansInfo, openLicensingSelectionModal, selectedSubscriptionData}) => {
    const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);

  return (
    <PrePurchaseStyles>

      {/* INTRODUCTION */}
      <section className="intro-section">
        <h1 className="heading">Get your team's security to the next level</h1>
        <h4 className='paragraph'>Introducing PureVPN for Teams: Tailored to empower your team's online security, protect their devices, and manage team members with an admin panel.</h4>
        <PrimaryBtn type="button" className="primary-btn" onClick={() => openLicensingSelectionModal({type: 'header'})} isTeams1>Upgrade your plan now</PrimaryBtn>

      </section>
      <img src={TeamsBanner} alt="team img" className="banner-img" />

      {/* BENEFITS SECTION */}
      <section className="benefits-section">
            <h3 className="heading">Benefits of upgrading to the Teams plan</h3>
            <h4 className="paragraph">Take your team's internet experience to new heights!</h4>
            <div className="benefits-content">
              {benefits?.map((feature, key) => {
                return (
                  <div className={`benefits ${feature.slug}`}>
                    <div className={`benefits-info`}>
                      <h1 className="subheading text-capitalize">{feature.heading}</h1>
                      <p className="body-paragraph">{feature.paragraph}</p>
                    </div>
                  </div>
                )
              })}
            </div>
      </section>

      {/* UPGRADE BANNER */}
      <section className="upgrade-section">
        <div className="upgrade-banner">
          <div className="upgrade-content">
            <h2 className="heading">Choose to upgrade your team's online security </h2>
            <h4 className="paragraph">{`Starting from ${currencySymbol[selectedSubscriptionData?.currency]}${pricing?.[selectedSubscriptionData?.plan?.interval_length]}/mo/member compared to ${selectedSubscriptionData?.hasFamilyPlan ? `$5.99/mo/member` : `$11.95/mo`} in ${selectedSubscriptionData?.hasFamilyPlan ? 'Family' : 'Individual'} plan.`}</h4>
            <PrimaryBtn type="button" className="primary-btn" onClick={() => openLicensingSelectionModal({type: 'banner'})}>Upgrade your plan now</PrimaryBtn>
          </div>
          <div className="upgrade-image">
            <img src={upgrade} alt="upgrade" />
          </div>
        </div>
      </section>

      {/* UPGRADE BANNER */}
      <section className="upgrade-team-benefits-section">
            <h3 className="heading">Level up your upgrade with Teams add-ons</h3>
            <h4 className="paragraph">Choose top-notch add-ons to enhance your team's online security</h4>
            <div className="upgrade-team-benefits-content">
              {upgradeTeamBenefits?.map((feature, key) => {
                return (
                  <div className={`upgrade-team-benefits ${feature.slug}`}>
                    <div className={`upgrade-team-benefits-info`}>
                      <h1 className="subheading text-capitalize">{feature.heading}</h1>
                      <p className="body-paragraph">{feature.paragraph}</p>
                    </div>
                  </div>
                )
              })}
            </div>
      </section>

      {/* TABLE CONTENT */}
      <PrePurchaseTable selectedSubscriptionData={selectedSubscriptionData} plansInfo={plansInfo} openLicensingSelectionModal={openLicensingSelectionModal}/> 

      {/* GET STARTED BANNER */}
      <section className="get-started-section">
        <div className="get-started-content">
          <h1 className="heading">How to get started?</h1>
          <p className="paragraph">Upgrade to the Teams plan and simply start adding your team members to the admin panel.</p>
          <PrimaryBtn type="button" className="primary-btn" onClick={() => openLicensingSelectionModal({type: 'get started'})}>Upgrade your plan now</PrimaryBtn>
        </div>
        <img src={started} alt="started" />
      </section>

      {/* FAQS */}
      <section className="faq-section">
          <GenericFaqs faqs={teamsFaqs} theme={true}/>
      </section>

    </PrePurchaseStyles>
  )
}

export default PrePurchase