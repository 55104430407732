import React, {useEffect, useState} from 'react'
import { CardBox, DashboardHead, InfoCard } from '../../../styles/dashboard/Main'
import { InsetBtn, DestructBtn, Table, RLink } from '../../../styles/Generic'
import paypal from '../../../assets/paypal.png'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getInovicesRequested } from '../../../redux/invoices/actions'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages'
import { toast } from 'react-toastify'
import {currencySymbol, dateFormat, get, getter} from '../../../tools/tools'
import Skeleton from 'react-loading-skeleton'
import ErrorBoundary from '../../ErrorBoundary'
import { sendEvents } from '../../../tools/mpEvents'
import { constants } from "../../../tools/constants";
import { useTranslation } from 'react-i18next';
import PaymentIcon from '../../../utils/PaymentIcon/PaymentIcon'

const PaymentHistory = ({history, invoices, getInovicesRequested, subscriptions:reduxSubs}) => {

    const { t, i18n } = useTranslation();

    const [crashed, setCrashed] = useState(false)

    const [downloadLoader, setdownloadLoader] = useState({
        loader:false,
        id:""
    })

    const dispatch = useDispatch()

    const { id } = useParams();

    const [data, setData] = useState({
        response:[],
        loading:true,
        cycle: reduxSubs?.subscriptions?.body.find(sub=>sub.id === id)
    })

    const token = localStorage.getItem("token")

    const apiData = {
        token:token,
        data:{
            subscription_id: id,
            limit:99
        }
    }

    const downloadPdf = async (e) => {
        setdownloadLoader({loader:true, id:e.target.id})
        try {
            const invoicedata = await get(`subscription/${id}/${e.target.id}/download`, token)
            if(invoicedata.data.status === false){
                switch (invoicedata?.data?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})

                        break;
                    case "jwt_exception":
                        toast.error(t(APIFAILED))
                        dispatch({type:"LOGOUT"})

                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})

                        break;
                    case "invoice_exception":
                        toast.error(invoicedata?.data?.message);
                        break;
                    case "APIFAILED":
                        toast.error(t(APIFAILED))
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
            else {
                const download = await getter(`subscription/${id}/${e.target.id}/download`, token)
                var blob = new Blob([download.data], {type: "application/pdf"});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Invoice-${e.target.id}.pdf`;
                link.click();
            }

        } catch (error) {
            toast.error(t(APIFAILED));
        }
        finally{
            setdownloadLoader({loader:false, id:""})
        }
    }

    useEffect(() => {
        getInovicesRequested(apiData)
        sendEvents({
            event_name:"ma_see_paymenthistory",
            event_properties:{}
        }, token)
    }, [])

    useEffect(() => {
        const { loading: reduxLoading, invoices: reduxData, errors, errorType } = invoices;
        
        if(reduxData?.[id]){
            setData({
                ...data, 
                response:reduxData?.[id]?.body,
                loading:false
            });
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                           
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break;      
                case "APIFAILED":
                    setCrashed(true)
                    break;     
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [invoices])

    if(crashed){
        return <ErrorBoundary></ErrorBoundary>
    }

    return (
        <>
            <DashboardHead>
                <h2>{t('payment_history_title')}</h2>
            </DashboardHead>
            {
                !data.loading 
                ?
                <div className="table-row">
                    <div className="col-md-11">
                        <InfoCard className="pt-0" noflex>
                            <div>
                                {
                                    (constants.applicationAddons.includes(data?.response?.service_origin)) ? (
                                        <label className="me-2 mb-2 d-block">{t('payment_history_email')}<strong>{data?.response?.account?.email || "-"}</strong></label>
                                    ) : (
                                        <label className="me-2 mb-2 d-block">{t('tbl_username')}<strong>{data?.response?.vpnusernames?.[0] ?? "-"}</strong></label>
                                    )
                                }
                                <label className="me-2 mb-2 d-block">{t('payment_history_billing_cycle')}<strong>
                                {data?.response?.is_trial ? data?.response?.trial_days : (`${data?.cycle?.plan?.interval_length} ${data?.cycle?.plan?.interval_length === 1 ? t('upgradeplan_month') : t('months_text')}` ?? undefined) ?? "-"}
                                    </strong></label>
                                {
                                    !data?.response?.trial_started_at && <label className="me-2 mb-2 d-block">{t('payment_history_expiry')}<strong>{dateFormat(data?.response?.end_date) ?? "-"}</strong></label>
                                }
                                <p className="w-75 mw-100 mt-4">{t('payment_history_membership_para')}</p>
                            </div>
                        </InfoCard>
                        <div className="table-wrapper hasScroll mt-4 mb-5">
                            <Table className="table" align="middle">
                                <thead>
                                    <tr>
                                        <th>{t('payment_history_table_date')}</th>
                                        <th>{t('payment_history_table_status')}</th>
                                        <th>{t('payment_history_table_payment_method')}</th>
                                        <th>{t('payment_history_table_vat')}</th>
                                        <th>{t('payment_history_table_amount')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (data?.response?.invoices && data?.response?.invoices.length > 0) ?
                                    (data?.response?.invoices?.map((invoice, key)=>(
                                        invoice?.status !== "closed"
                                        &&
                                        <tr key={key}>
                                            <td data-label="Ticket">{dateFormat(invoice?.created_at) || "-"}</td>
                                            <td data-label="Status" className={`text-uppercase ${invoice.status !== "paid" && "danger"}`}>{invoice?.status === "past_due" ? "Unpaid" : invoice?.status.split("_").join(" ") ?? "-"}</td>
                                            <td data-label="Payment method">
                                                {
                                                    <PaymentIcon gateway={data?.response?.payment_gateway} lastFour={invoice?.billing_info?.last_four} hideStar={true}/>
                                                }
                                            </td>
                                            <td data-label="Amount">{currencySymbol[invoice?.currency ?? "$"]}{invoice?.invoice_tax.toFixed(2) || "0"}</td>
                                            <td data-label="Amount">{currencySymbol[invoice?.currency ?? "$"]}{invoice?.paid.toFixed(2) || "0"}</td>
                                            <td data-label="Action" className="table-buttons">
                                                <RLink>
                                                    <Link to={`/dashboard/${id}/invoice/${invoice?.id}`}>{t('payment_history_table_view')}</Link>
                                                </RLink>
                                                <InsetBtn onClick={downloadPdf} id={invoice?.id}>{downloadLoader.loader && downloadLoader.id === invoice?.id ? <span className="spinner-border spinner-border-sm"></span> : t('download_text')}</InsetBtn> 
                                            </td>
                                        </tr>
                                    )))
                                    :
                                    (<tr><td><p>No Invoice Found</p></td></tr>)
                                }
                                </tbody>
                            </Table>
                        </div>
                        <DestructBtn onClick={() => history.push("/dashboard/subscriptions")}>{t('payment_history_table_back_to_subscription')}</DestructBtn>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-md-7">
                        <CardBox>
                            <InfoCard hasborder noflex className="mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard noflex className="pt-0">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                        </CardBox> 
                    </div>
                </div>
            }
            
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        invoices: state.invoicesReducer,
        subscriptions: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps,{getInovicesRequested})(PaymentHistory)