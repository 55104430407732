import React, {useEffect} from 'react'
import { AutoLoginWrapper } from '../styles/Generic'
import loginlogo from '../assets/loginlogo.png'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

const FusionLogin = ({history}) => {

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();
    
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token){
            history.push("/dashboard/upgrade")
        }
    }, [])
    const redirectToLogin = () =>{
        window.location.href = process.env.REACT_APP_FA_URL
    }
    return (
         <>
            {
                token ?
                <AutoLoginWrapper> 
                    <div className="text-center">
                        <img src={loginlogo} className="img-fluid" />
                        <h1>{t('login_text_member_area')}</h1>
                        <p>{t('login_text_opening_member_area_text')}</p>
                        <p className="mt-5 mb-3">{t('login_text_please_wait')}</p>
                        <span className="spinner-border"></span>
                    </div>
                </AutoLoginWrapper>
                :
                redirectToLogin()
            }
        </>
    )
}

export default FusionLogin