import React from 'react'
import { Dot, LoadingContainer } from '../../../../styles/dashboard/PureAI';

const TextLoader = () => {
  return (
    <LoadingContainer>
        <Dot delay={0} />
        <Dot delay={200} />
        <Dot delay={400} />
    </LoadingContainer>
  )
}

export default TextLoader