import React from 'react'
import { InfoCard, SubscriptionCancelWrapper, UpgradeNA } from '../../../../styles/dashboard/Main';
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';

const ErrorScreen = ({crashed,closeModal,protocol,crashedStatus}) => {

    const { t, i18n } = useTranslation();

    const handleErrorButton = () => {
		window.open("https://www.purevpn.com/order",'_blank');
	};
    const expireMessage = vsprintf(t('manualconf_error_expire_message'),`${protocol}`);
	const crashedMessage= vsprintf(t('manualconf_error_crashed_message'),`${protocol}`);
  return (
       <UpgradeNA noborder>
           <div className="mt-3 h-pbox">
               <SubscriptionCancelWrapper coloredVal fullWidth>
								<InfoCard className="d-block pt-0">
									<div className="warning-box">
										<span className="warning-icon"></span>
										<p className="warning-text">
                                        {(crashed || crashedStatus)? crashedMessage:expireMessage}
										</p>
									</div>
								</InfoCard>
				</SubscriptionCancelWrapper>
               <PrimaryBtn
                   onClick={() => {
                       handleErrorButton();
                   }}
               >
                   Get a New Subscription
               </PrimaryBtn>
               <DestructBtn className="mx-2" onClick={()=>closeModal()}>
                   Go Back
               </DestructBtn>
           </div>
       </UpgradeNA>
  )
}

export default ErrorScreen