import styled from "styled-components";
import info from "../../assets/info-new.svg";

export const PlanTypeUpgradeStyles = styled.div`

    html {
        scroll-behavior: smooth;
    }

    h2{
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        color: #43225B;
        max-width: 910px;
        margin: 45px auto 60px;

        @media only screen and (max-width: 852px){
            font-size: 36px;
            line-height: 48px;
            max-width: 700px;
        }
    }

    .plan-type-cards{
        max-width: 930px;
        margin: auto auto 90px;
        border-bottom: 1px solid #EEEDF0;
        display: flex;
        align-items: end;
        padding-bottom: 90px;

        @media only screen and (max-width: 852px){
            display: block;
            padding-bottom: 30px;
        }

        @media only screen and (max-width: 740px){
            padding-bottom: 30px;
            margin-bottom: 30px;
            
        }
    }

    .loading{
        @media only screen and (max-width: 920px){
            div{
                width: 220px !important;
            }
        }
        @media only screen and (max-width: 852px){
            display: block !important;
            margin: auto;
            width: max-content !important;
        }
    }
`

export const PlanTypeUpgradeCardStyles = styled.div`

    width: 100%;
    max-width: 465px;
    margin: auto;
    margin-bottom: 0;
    position: relative;

    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        top: 0;
        right: 0;
        z-index: 1;
        border-radius: 32px 32px 24px 24px;
        cursor: pointer;

        &:hover{
            box-shadow: 0px 24px 72px rgba(67, 34, 91, 0.1);
        }

        &.std{
            background: rgba(239, 235, 242, 0.55);
            cursor: not-allowed;
            &:hover{
                box-shadow: none;
            }
        }
        
    }
    .plan-card{
        border-radius: 32px;
        padding: 45px 30px;
        border: 1px solid #ECE7FD;
        height: max-content;
        background: rgb(251, 250, 248);
        box-shadow: 0px 24px 72px rgba(67, 34, 91, 0.1);
        position: relative;

        @media only screen and (max-width: 852px){
            margin-bottom: 60px;
        }
    }

    .selected{
        background: #FFFFFF;
        border: 2px solid #6536CC;
        box-shadow: 0px 2px 4px rgba(67, 34, 91, 0.04), 0px 5px 16px rgba(67, 34, 91, 0.06), 0px 24px 72px rgba(255, 177, 10, 0.1);
        cursor: pointer;
    }

    .max, selected{
        .head{
            .title{
                background: #6536CC;
                color: #FBFBFC !important;
            }
        }
    }

    .head{

        margin-bottom: 45px;
        .title{
            border-radius: 24px 24px 0px 0px;
            position: absolute;
            top: -30px;
            right: 0;
            content: '';
            width: 100%;
            font-weight: 700;
            font-size: 13px !important;
            line-height: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 7px 0px 30px !important;
            z-index: -1;
            left: 0;
            background: #F1EEF4;
            color: #43225B !important;
        }

        h2{
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            color: #43225B;
            text-align: left;
            margin: 0px 0px 10px;

            @media only screen and (max-width: 930px){
                font-size: 26px !important;
            }
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #43225B;
            margin: 0px;
        }
        h4{
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #FBFBFC;
            background: #6536CC;
            border-radius: 100px;
            padding: 10px 20px;
            margin: 15px 0px 45px;
            width: max-content;

            @media only screen and (max-width: 930px){
                margin-bottom: 26px;
            }
        }

        .discounted{
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            text-decoration-line: line-through;
            color: #7B648C;
        }

        h3{
            font-weight: 700;
            font-size: 60px;
            line-height: 70px;
            letter-spacing: -2px;
            color: #43225B;
            position: relative;
            padding-left: 14px;
            margin: 0;
            display: flex;
            align-items: end;

            @media only screen and (max-width: 930px){
                font-size: 40px !important;
                line-height: 50px;
            }

            .sub{
                font-weight: 400;
                font-size: 14px !important;
                line-height: 14px;
                color: #7B648C !important;
                margin-bottom: 10px;
                margin-left: 10px;
            }

            .super{
                font-weight: 600;
                font-size: 20px !important;
                line-height: 20px;
                left: 0px;
                color: #43225B !important;
                top: 0;
                position: absolute;
                content: '';
            }
        }
    }

    .content{
        margin-top: 45px;

        h6{
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #43225B;
            padding-bottom: 15px;
            border-bottom: 1px solid #EEEDF0;
            margin-bottom: 15px;

            @media only screen and (max-width: 930px){
                font-size: 12px !important;
            }
        }

        ul{
            list-style: none;
            padding: 0;
            margin: 0;

            li{
                position: relative;
                padding-left: 11px;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: rgba(123, 100, 140, 1);
                padding-bottom: 24px;

                @media only screen and (max-width: 930px){
                    font-size: 12px !important;
                    padding-bottom: 10px;
                }

                :last-child{
                    padding-bottom: 0px;
                }

                ::before{
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    background: #2CDC82;
                    top: 8px;
                    left: 0px;
                }

                .info{
                    padding-left: 8px;
                    position: relative;

                    ::before{
                        position: absolute;
                        content: '';
                        background: url(${info}) 0px 0px;
                        background-repeat: no-repeat;
                        width: 12px;
                        height: 13px;
                        top: 4px;
                    }
                }
            }
        }
    }
    
`

export const PlanTypeUpgradeInfoStyles = styled.div`

    max-width: 1057px;
    margin: auto;

    h1{
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        color: #43225B;
        max-width: 820px;
        margin: auto;
        margin-bottom: 30px;

        @media only screen and (max-width: 852px){
            font-size: 36px;
            line-height: 48px;
            max-width: 700px;
        }
    }

    ul.plan-info{
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        margin-bottom: 90px;

        @media only screen and (max-width: 740px){
            display: block;
        }

        li{
            background: #FFFFFF;
            border: 1px solid #FFB10A;
            box-shadow: 0px 2px 4px rgba(67, 34, 91, 0.04), 0px 5px 16px rgba(67, 34, 91, 0.06), 0px 24px 72px rgba(255, 177, 10, 0.1);
            border-radius: 24px;
            width: max-content;
            cursor: pointer;
            position: relative;

            a{
                position: absolute;
                top: 0;
                content: '';
                width: 100%;
                height: 100%;
            }

            &:hover{
                transform: scale(1.02);
                transition: .2s all ease-in;
            }

            @media only screen and (max-width: 906px){
                border-radius: 14px;
            }

            @media only screen and (max-width: 740px){
                width: 200px;
                margin: auto;
                margin-bottom: 10px;
            }

            img{
                display: block;
                padding: 15px;
                @media only screen and (max-width: 906px){
                    transform: scale(0.7);
                    padding: 0;
                }
            }

            &.purevpn{
                border: 1px solid #3B108E;
                box-shadow: 0px 2px 4px rgba(67, 34, 91, 0.04), 0px 5px 16px rgba(67, 34, 91, 0.06), 0px 24px 72px rgba(59, 16, 142, 0.1);

            }
            &.purencrypt{
                border: 1px solid #4361EE;
                box-shadow: 0px 2px 4px rgba(67, 34, 91, 0.04), 0px 5px 16px rgba(67, 34, 91, 0.06), 0px 24px 72px rgba(67, 97, 238, 0.1);

            }
            &.purekeep{
                border: 1px solid #2EC4B6;
                box-shadow: 0px 2px 4px rgba(67, 34, 91, 0.04), 0px 5px 16px rgba(67, 34, 91, 0.06), 0px 24px 72px rgba(46, 196, 182, 0.1);
            }
        }
    }
`
export const PlanTypeInfoCardStyles = styled.div`
    display: flex;
    align-items: center;
    gap: 60px;
    margin-top: 60px;

    @media only screen and (max-width: 906px){
        display: block;
        margin-top: 30px;
        text-align: center;
    }

    @media only screen and (max-width: 740px){
        margin-bottom: 50px;
    }

    .features{
        @media only screen and (max-width: 1110px){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    
    img{
        @media only screen and (max-width: 740px){
            width: 300px;
        }
        @media only screen and (max-width: 380px){
            width: 240px;
        }
    }

    .f-head{
        display: flex;
        align-items: center;

        @media only screen and (max-width: 906px){
            justify-content: center;
            margin-top: 20px;
        }

        @media only screen and (max-width: 390px){
            display: block;
            text-align: center;
            margin-top: 40px;
        }

        span.f-img{
            background-repeat: no-repeat !important;
            height: 24px;
            width: 24px;
            display: block;
            transform: scale(2.5);

            @media only screen and (max-width: 390px){
                display: block;
                text-align: center;
                margin: auto;
            }
        }
        h4{
            padding-left: 30px;
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            color: #43225B;
            margin: 0;
            margin-bottom: 5px;

            @media only screen and (max-width: 390px){
                margin-top: 30px;
                padding-left: 0px;
            }

            &.link{
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                    transition: .4s all ease-in;
                }
            }

        }
        h6{
            padding-left: 30px;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #7B648C;
            margin: 0;
            @media only screen and (max-width: 390px){
                padding-left: 0px;
            }
            
        }
    }

    ul{
        padding: 0;
        list-style: none;
        margin-top: 45px;
        li{
            border-bottom: 1px solid #EEEDF0;
            padding-bottom: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: start;

            @media only screen and (max-width: 906px){
                max-width: 400px;
                margin: 0 auto 15px;
                text-align: center;
                justify-content: center;
            }

            .pt-icons{
                background-repeat: no-repeat;
                min-width: 32px;
                height: 32px;
                margin-right: 10px;

                @media only screen and (max-width: 906px){
                    display: none;
                }
            }

            &:last-child{
                border: none;
                padding-bottom: 0px;
                margin-bottom: 0;
            }

            h5{
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                margin: 0;
                color: #43225B;
                margin-bottom: 5px;
            }
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #7B648C;
                margin: 0;
            }
        }
    }
`