import { connect, useDispatch } from 'react-redux';
import { determineOnBoardingActivationRemaining, validatePurchasedApps } from '../../../tools/genericMethods';
import { devicesMultiLang } from "../../../assets/lang/devices_lang";
import { fetcher } from '../../../tools/tools';
import { getSubscriptionsRequest, setSubscriptionViaOnBoarding } from '../../../redux/subscriptions/actions';
import { Input } from 'reactstrap';
import { isChrome } from 'react-device-detect';
import { seti18Locale } from '../../../tools/tools';
import { NavLink, useLocation } from 'react-router-dom';
import { setDeviceViaOnBoarding } from '../../../redux/downloads/actions';
import { SidebarWrapper, SidebarList, SidebarOnBoardingWrapper } from '../../../styles/dashboard/SideBar';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { constants } from "../../../tools/constants";
import business from '../../../assets/business.png';
import download from '../../../assets/download.png';
import help from '../../../assets/help.png';
import logoWhite from '../../../assets/logo_white_new.svg';
import manage from '../../../assets/manage.png';
import React, { useEffect, useState } from 'react';
import refer from '../../../assets/refer.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import subscription from '../../../assets/subscription.png';
import upgrade from '../../../assets/upgrade_account.png';
import { NewTag } from '../../../styles/dashboard/Main';

const Sidebar = ({ accountReducer, onBoardingReducer, subscriptions, toggleSideBar=()=>{} }) => {
    const isLoggedin = localStorage.getItem("token");

    const { pathname } = useLocation();

    const { t } = useTranslation();

    const get_locale = seti18Locale();

    const [selectedSubscription, setSelectedSubscription] = useState(onBoardingReducer.selectedSubs)

    const [subs, setSubs] = useState(onBoardingReducer.subscriptions)

    const [hasVPNUsername, setHasVPNUsername] = useState(false);

    const [showOnBoardingRemainingPopup, setShowOnBoardingRemainingPopup] = useState(false)

    const [purchasedApps, setPurchasedApps] = useState(null)

    const dispatch = useDispatch()

    const history = useHistory();

    const [audienceType, setAudienceType] = useState(localStorage.getItem('audienceType'));

    const [hideOptions, setHideOptions] = useState();

    useEffect(() => {
        if (onBoardingReducer?.onBoardings?.length == 0) {
            dispatch({ type: "GETONBOARDINGREQUEST", token: localStorage.getItem("token"), locale: get_locale })
            dispatch({ type: "GETSUBSCRIPTIONSREQUEST", payload: localStorage.getItem("token") })
            dispatch({ type: "GETCHANNELREQUEST", token: localStorage.getItem("token") })
            dispatch({ type: "GET_COUNTRIES_REQUESTED", token: localStorage.getItem("token") })
            fetcher('public/getAudienceType', localStorage.getItem("token")).then(response => {
                if (!response.status) {
                    setAudienceType("Normal");
                }
                return response;
            })
            .then(data => {
                setAudienceType(data.data.body.type);
                localStorage.setItem("audienceType", data.data.body.type);
            })
            .catch(error => {
                setAudienceType("Normal");
            })
        }else{
            dispatch({ type: "UPDATELOCALEONBOARDINGDATA", locale: get_locale })

        }
    }, [])

    useEffect(() => {
        if(!(subscriptions.subscriptions?.body)){
            dispatch(setSubscriptionViaOnBoarding(onBoardingReducer.subscriptions))
        }
        setSubs(onBoardingReducer.subscriptions);

        if (onBoardingReducer?.onBoardings) {

            let remainingOnBoarding = determineOnBoardingActivationRemaining(onBoardingReducer?.onBoardings, "", onBoardingReducer.subscriptions)

            dispatch({ type: "NEXTONBOARDING", payload: remainingOnBoarding });
        }

    }, [onBoardingReducer.subscriptions])

    useEffect(() => {

        let popupStatus = (onBoardingReducer.nextOnBoardingApp && !pathname.includes("dashboard/onboarding"));

        setShowOnBoardingRemainingPopup(popupStatus)

    }, [onBoardingReducer.nextOnBoardingApp, pathname])

    useEffect(() => {
        //check for locale if english else bring mock response
        if(get_locale === 'en'){
        dispatch(setDeviceViaOnBoarding(onBoardingReducer.devices))
        }else{
            dispatch(setDeviceViaOnBoarding(devicesMultiLang[get_locale]))
        }
    }, [onBoardingReducer.devices])

    useEffect(() => {
        let hasSetDefault = false;

            if (onBoardingReducer?.subscriptions) {
                let apps = validatePurchasedApps(onBoardingReducer?.subscriptions);
                setPurchasedApps(apps)
            }

            onBoardingReducer?.subscriptions?.map((subs, idx) => {
                if (selectedSubscription == null && !hasSetDefault && (subs?.hasOwnProperty("vpnusernames") || constants.newAddonsServiceOrigin.includes(subs?.service_origin)) ) {
                    dispatch({ type: "UPDATESELECTEDSUBSCRIPTION", payload: subs.id })
                    hasSetDefault = true;


                    setSelectedSubscription(onBoardingReducer.selectedSubs ? subs.id  === onBoardingReducer.selectedSubs : subs.id)
                }
                if (typeof subs.vpnusernames != "undefined" && subs.vpnusernames.length > 0) {
                    setHasVPNUsername(true)
                }
            });

    }, [onBoardingReducer.subscriptions])

    const updateSelectedSubscription = (e) => {
        // Making the sidebar togglinf accordingly:
        // If user doesnot select the username after click the select menu keep the sidebar visible/active
        // Else once the user selects an username toggle the sidebar and make it inactive
        if(!(e)){
            toggleSideBar(false)
        }
        else{
            showOptions()
            toggleSideBar()
        }
        dispatch({ type: "UPDATESELECTEDSUBSCRIPTION", payload: e })
        setSelectedSubscription(e)
    }

    const redirectTohome = () => {
        history.push("/dashboard/security-tools");
    }

    const showOptions = () => {
        setHideOptions(!hideOptions)
    }

    return (
        <SidebarWrapper isChrome={isChrome} className={`${showOnBoardingRemainingPopup ? "popup-visible" : ""}`}>
            <div className="sidebar-head">
                <img src={logoWhite} onClick={redirectTohome} className="img-fluid cursor-pointer" width={120} height={36} alt="PureVPN Logo"/>
            </div>
            <div className="sidebar-body">

                <ul className="list-unstyled">

                    {/* On-Boarding Links */}
                    {
                        
                        onBoardingReducer.loading
                        ? (
                            <li className='on-boarding-nav'>
                                <SidebarOnBoardingWrapper className='on-boarding-nav-wrapper'>
                                    <SidebarList visible={true} className="non-link get-started">{t('sidebar_get_started')}</SidebarList>
                                    <SkeletonTheme baseColor="#EEE9F4" highlightColor="#c7aed7">
                                        <li style={{marginBottom: "25px", padding: "0px 10px"}}>
                                            <Skeleton height={20} />
                                        </li>
                                        <li style={{marginBottom: "25px", padding: "0px 10px"}}>
                                            <Skeleton height={20} />
                                        </li>
                                        <li style={{marginBottom: "25px", padding: "0px 10px"}}>
                                            <Skeleton height={20} />
                                        </li>
                                    </SkeletonTheme>
                                </SidebarOnBoardingWrapper>
                            </li>
                        ) : onBoardingReducer?.onBoardings?.length > 0
                        ? (
                            <li className='on-boarding-nav' onClick={toggleSideBar}>
                                <SidebarOnBoardingWrapper className='on-boarding-nav-wrapper'>
                                    <SidebarList visible={true} icon={manage} className="non-link get-started">{t('sidebar_get_started')}</SidebarList>
                                    {
                                        subscriptions.subscriptions?.body && subscriptions.subscriptions?.body.length > 1 && hasVPNUsername
                                        ? 
                                        (
                                            <div className='multi-select-for-subs'>
                                                <h4 onClick={showOptions}>{subscriptions?.subscriptions?.body.find((subs) =>  subs?.id === selectedSubscription)?.vpnusernames ? subscriptions?.subscriptions?.body.find((subs) =>  subs?.id === selectedSubscription)?.vpnusernames[0] : subscriptions?.subscriptions?.body?.find((subs) =>  subs?.id === onBoardingReducer.selectedSubs)?.vpnusernames ? subscriptions?.subscriptions?.body?.find((subs) =>  subs?.id === onBoardingReducer.selectedSubs)?.vpnusernames?.[0] : '---'}</h4>
                                                {hideOptions && <ul>
                                                    {
                                                        subscriptions.subscriptions?.body.map((subs, idx) => {
                                                            if (subs.vpnusernames) {
                                                                return (
                                                                    <li onClick={(e)=> updateSelectedSubscription(subs.id)}  className={(subs.id == selectedSubscription) ? 'active' : ''} key={idx}>{subs.vpnusernames ? <p>{subs.vpnusernames[0]}</p> : <p>{'---'}</p>}{subs?.state === 'active'? <span>{subs?.state}</span> : null}</li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>}
                                            </div>
                                        )
                                        : null
                                    }
                                    {
                                        <>
                                            <SidebarList isLocked={false} visible={isLoggedin} className="security-tools on-boarding-link" icon={business}><NavLink to="/dashboard/security-tools">Security Tools</NavLink></SidebarList>
                                            <SidebarList isLocked={false} visible={isLoggedin} className="security-suite on-boarding-link" icon={business}><NavLink to="/dashboard/plans">Security Suite</NavLink></SidebarList>
                                        </>
                                    }
                                    
                                </SidebarOnBoardingWrapper>
                            </li>
                        ) : null
                    }
                    {/* Wrapped the sidebar options in a div where the toggle functionality should be enable for sidebar
                     also added the funtionality to onboarding menu */}
                    <div onClick={toggleSideBar}>
                    <div className='mb-4'>
                        {onBoardingReducer?.onBoardings?.length > 0 && <SidebarList visible={true} className="setup-vpn" icon={download}><NavLink to="/dashboard/onboarding/purevpn" activeClassName="active">Setup VPN</NavLink></SidebarList>}
                        <SidebarList visible={true} className="downloads" icon={download}><NavLink to="/dashboard/download" activeClassName="active">Downloads</NavLink></SidebarList>
                        {purchasedApps?.purevpn && <SidebarList visible={isLoggedin} className="configuration" icon={business}><NavLink to="/dashboard/manual-config" activeClassName="active">Manual Configuration</NavLink></SidebarList>}
                    </div>

                    <div className='mb-4'>
                        <SidebarList visible={isLoggedin} className="subscriptions" issue={(accountReducer?.accounts?.body?.has_past_due_invoice || accountReducer?.accounts?.body?.has_canceled_subscription || !accountReducer?.accounts?.body?.has_live_subscription) || false} icon={subscription}><NavLink to="/dashboard/subscriptions" activeClassName="active">Subscriptions</NavLink></SidebarList>
                        {purchasedApps?.purevpn && onBoardingReducer?.subscriptions?.length > 0 && (onBoardingReducer?.subscriptions?.filter((subs) => subs.id === onBoardingReducer.selectedSubs)?.[0]?.hasVolumePlan) && <SidebarList visible={isLoggedin} className="volume-licensing" icon={manage}><NavLink to="/dashboard/volume-licensing" activeClassName="active">Volume Licensing</NavLink></SidebarList>}
                        {purchasedApps?.purevpn && <SidebarList visible={isLoggedin} className="family-plan" icon={manage}><NavLink to="/dashboard/family-plan" activeClassName="active">Family Plan</NavLink></SidebarList>}
                        <SidebarList visible={isLoggedin} className="teams" icon={manage}><NavLink to="/dashboard/teams" activeClassName="active">Teams (Business) <NewTag className='new'>NEW</NewTag></NavLink></SidebarList>
                        <SidebarList visible={isLoggedin} className="upgrade-plan" icon={upgrade}><NavLink to="/dashboard/upgrade" activeClassName="active">Upgrade Plan</NavLink></SidebarList>
                    </div>

                     <div className='mb-4'>
                        <SidebarList visible={isLoggedin} className="pure-rewards" icon={manage}><NavLink to="/dashboard/rewards" activeClassName="active">PureRewards</NavLink></SidebarList>
                        {purchasedApps?.purevpn && <SidebarList visible={isLoggedin} className="refer-a-friend" icon={refer}><NavLink to="/dashboard/refer" activeClassName="active">PureReferrals</NavLink></SidebarList>}
                        <SidebarList visible={isLoggedin} className="help-desk" icon={help}><NavLink to="/dashboard/tickets" activeClassName="active">Help Desk</NavLink></SidebarList>
                        <SidebarList visible={isLoggedin} className="account-manage" icon={manage}><NavLink to="/dashboard/account" activeClassName="active">Manage Account</NavLink></SidebarList>
                     </div>
                    </div>
                </ul>
            </div>
        </SidebarWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, {getSubscriptionsRequest})(Sidebar)