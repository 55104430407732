import { APIFAILED } from "../../../../tools/responseMessages";
import { checkAccountType, deletion, getPaymentMethods, getTokenFromLocalStorage } from '../../../../tools/tools';
import { EnterpriseCTA } from "../layout/enterpriseCTA";
import { Input } from "../../../../styles/dashboard/Main";
import { PrimaryBtn } from "../../../../styles/Generic";
import { sendEvents } from "../../../../tools/mpEvents";
import { toast } from "react-toastify";
import { useState, useEffect, useReducer } from "react";
import qs from 'qs';
import TeamTable from "../tables/teamTable";
import useDebounce from "../../../../custom-hooks/useDebounce";
import useTeamsMembers from "../custom-hooks/useTeamMembers";
import Info from "../modals/info";
import AddonPurchase from "../../subscriptions/modals/AddonPurchase";
import AddMoreAddons from "../modals/addMoreAddons";
import { getMaximumForTeamMember, getMinimumForTeamMember, getTotalAmountForTeamMember, getValueForTeamMember, hasErrorForTeamMember, isDisabledForTeamMember, maxDisabledForTeamMember, minDisabledForTeamMember, onChangeCounterForTeamMember } from "../tools/teamMembers";
import { TeamMemberReducer } from "../reducer/teamMemberCounter";
import { pureTeamsPostPurchaseStates } from "../../../../tools/constants";
import AddMoreLimit from "../modals/addMoreLimit";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";

const TeamListing = ({ history, selectedSubscriptionData, onAddMoreUsers, fetchCountAndMembers, teamsCounter, isCounterLoading, toggleDeleteModal, triggerDelete, setTriggerDelete }) => {
    const token = getTokenFromLocalStorage();
    const { search: locSearch, pathname } = useLocation();
    const { openAddMoreMembersModal } = Object.fromEntries(new URLSearchParams(locSearch))
    const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);
    const [deleteMemberDetails, setDeleteMemberDetails] = useState();
    const [teamMembersEvent, setTeamMembersEvent] = useState();
    const [debouncedSearch, setDebouncedSearch] = useDebounce('', 1000);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [addMoreLimit, setAddMoreLimit] = useState(false);
    const { teamMembers, totalRows, isTeamMembersLoaded, teamMembersErrorMsg, setFetchTeamMembersAgain} = useTeamsMembers(page, perPage, debouncedSearch, selectedSubscriptionData)
    // add more states
    const [addMoreAddonModal, setAddMoreAddonModal] = useState();
	const [openAddonModal, setOpenAddonModal] = useState();
    const [modalType, setmodalType] = useState("sm");
    const [orderSummary, setorderSummary] = useState(true);
    
    const [infoModal, setInfoModal] = useState({
        show: '',
        open: ''
    });
    const [modalWidth] = useState({
        lg: "684px",
        sm: "440px"
    });
    const [addonType, setAddonType] = useState({
    type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:"",
    });
    const [teamMemberState, dispatchForTeamMember] = useReducer(TeamMemberReducer, {
        min: 2,
        max: 200,
        current: 0,
        price: pricing?.[selectedSubscriptionData?.plan?.interval_length],
        previous: 0
    });

    useEffect(() => {
        setDebouncedSearch(search);
    }, [search]);

    // trigger delete request
    useEffect(()=>{
        if(triggerDelete){
            sendDeleteMemberRequest()
        }
    }, [triggerDelete])

    useEffect(()=>{
        if(!teamMembersErrorMsg && teamMembers && openAddMoreMembersModal && isTeamMembersLoaded){
            openAddMoreModal();
        }
    }, [isTeamMembersLoaded])
    
    useEffect(()=>{
        if(teamsCounter && !teamMembersEvent){
            sendEvents({
                event_name:"ma_click_team_section",
                event_properties:{
                    section: 'team_members',
                    plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                    billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                    payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                    current_status: selectedSubscriptionData?.state ?? 'N/A',
                    account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                    team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                    team_server_count: teamsCounter?.team_server?.limit ?? 0,
                    team_count: teamsCounter?.team_members?.limit ?? 0,
                    is_child: selectedSubscriptionData?.is_child
                }
            }, token)
            setTeamMembersEvent(true)
        }
    }, [teamsCounter])
    
    const deleteSelectedMembers = (id, email) => {
        setDeleteMemberDetails({id, email})
        toggleDeleteModal()
    }
    const sendDeleteMemberRequest = async () => {
        try{
            const formdata = qs.stringify({child_subscription_id: deleteMemberDetails?.id})
            const {data: response} = await deletion(`pure-teams/del-child-account`, formdata, token);
            if(response?.status){
                toast.success(response?.message);

                sendEvents({
                    event_name: "ma_delete_team_member",
                    event_properties: {
                        recipient_email: deleteMemberDetails?.email ?? 'N/A',
                        team_count: teamsCounter?.team_counter?.team_members?.limit,
                    },
                }, token);

                // in order to trigger useEffect, that will re-fetch data
                setFetchTeamMembersAgain()
            }
            else{
                toast.error(response?.message)
            }
        }
        catch{
            toast.error(APIFAILED);
        }
        finally{
            toggleDeleteModal();
            setTriggerDelete(false)
        }
    }
    const refreshAllApis = () => {
        setFetchTeamMembersAgain(prev=>!prev)
        fetchCountAndMembers()
    }
    const openAddMoreModal = () => {
        sendEvents({
            event_name:"ma_click_add_team_members",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
            }
        }, token)
        dispatchForTeamMember({type: 'add', min: 1, max: 200 - teamsCounter?.team_members?.limit, current: 1, previous: 1, price: pricing?.[selectedSubscriptionData?.plan?.interval_length]});
        toggleAddMoreAddonModal()
        if(openAddMoreMembersModal){
            history.replace(pathname, '')
        }
    }
    const toggleAddMoreAddonModal = () => {
        setAddMoreAddonModal(!addMoreAddonModal)
    }
    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }
    const openInfoModal = (type) => {
        toggleInfoModal(pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.info)
    }
    const proceedToAddonPurchase = () => {
        toggleAddMoreAddonModal();
        setAddonType({
            subscription: selectedSubscriptionData?.id,
			currency: selectedSubscriptionData?.currency,
			plan: selectedSubscriptionData?.plan?.code,
			gateway:selectedSubscriptionData?.payment_gateway,
            type: [
                {
                    code: 'purevpn',
                    quantity: teamMemberState.current + selectedSubscriptionData?.add_ons.find((add) => add.code === 'purevpn')?.quantity
                }
            ], 
            source: 'teams'
        });
        sendEvents({
            event_name:"ma_click_get_team_members",
            event_properties:{
                current_team_count: teamsCounter?.team_members?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_count: teamMemberState?.current
            }
        }, token)
        toggleAddonModal();
    }
    const toggleAddonModal = () => {
		setOpenAddonModal(!openAddonModal)
	}
    const toggleAddMoreLimit = () => {
        setAddMoreLimit(!addMoreLimit)
    }
    const openAddMoreLimit = () => {
        sendEvents({
            event_name:"ma_click_add_team_members",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
            }
        }, token);
        toggleAddMoreLimit();
    }

  return (
    <>
        {/* ---LISTING HEADER--- */}
        {!teamMembersErrorMsg && <section className="listing-header">
            <div className="search-box">
                <Input className="search" placeholder="Search by member email" value={search} onChange={e => setSearch(e.target.value)} />
                <div className="search-icon"></div>
            </div>
            <div className="listing-btns d-block w-100 text-align-right">
                {(teamsCounter?.team_members?.allocated !== teamsCounter?.team_members?.limit) && !selectedSubscriptionData?.is_child && <PrimaryBtn className="secondary teams fill" onClick={onAddMoreUsers}>Invite Team Members</PrimaryBtn>}
                {!selectedSubscriptionData?.is_child && !isCounterLoading && <PrimaryBtn className="primary teams fill ml-2" onClick={()=>teamsCounter?.team_members?.limit === 200 ? openAddMoreLimit() : openAddMoreModal()}>Add more Members</PrimaryBtn>}
            </div>
        </section>}

        {/* ---TABLE--- */}
        <section className="team-table">
            <TeamTable 
                deleteSelectedMembers={deleteSelectedMembers}
                fetchCountAndMembers={refreshAllApis}
                isTeamMembersLoaded={isTeamMembersLoaded}
                perPage={perPage}
                selectedSubscriptionData={selectedSubscriptionData}
                setPage={setPage}
                setPerPage={setPerPage}
                teamMembers={teamMembers}
                teamMembersErrorMsg={teamMembersErrorMsg}
                totalRows={totalRows}
            />
        </section>

        {/* --- ENTERPRISE --- */}
        {!teamMembersErrorMsg && <EnterpriseCTA selectedSubscriptionData={selectedSubscriptionData} fullWidth={true}/>}

        {openAddonModal && <AddonPurchase showInfo={false} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={"lg"} modalType={modalType} modal={openAddonModal} toggle={toggleAddonModal} setorderSummary={setorderSummary} orderSummary={orderSummary} endpoint={'pure-teams/addon-upsell'} redirectedFrom={'teams'} setIsConverted={refreshAllApis}/>}
        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={infoModal.open} toggle={toggleInfoModal} toggleLicensesModal={toggleAddMoreAddonModal}/>}
        {addMoreLimit && <AddMoreLimit teamsCounter={teamsCounter} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={addMoreLimit} toggle={toggleAddMoreLimit} />}

        {addMoreAddonModal && <AddMoreAddons
            type={'team_members'}
            state={teamMemberState}
            dispatch={dispatchForTeamMember}
            selectedSubscriptionData={selectedSubscriptionData}
            toggle={toggleAddMoreAddonModal}
            modal={addMoreAddonModal}
            modalWidth={modalWidth}
            openInfoModal={openInfoModal}
            proceedToAddonPurchase={proceedToAddonPurchase}
            onTotalAmount={getTotalAmountForTeamMember}
            onDisableCta={isDisabledForTeamMember}
            minDisabled={minDisabledForTeamMember}
            maxDisabled={maxDisabledForTeamMember}
            onChangeCounter={onChangeCounterForTeamMember}
            getMinimum={getMinimumForTeamMember} 
            getMaximum={getMaximumForTeamMember}
            hasError={hasErrorForTeamMember}
            getValue={getValueForTeamMember}>
        </AddMoreAddons>}
    </>
  )
}

export default withRouter(TeamListing);