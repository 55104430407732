import React, {useState, useEffect} from 'react'
import { CardBox, DashboardHead, InfoCard, Input, LazyImg, ReferBox, ShareLink, ButtonLink, TierBox, TierIcon } from '../../../styles/dashboard/Main'
import { toast } from 'react-toastify';
import { getTokenFromLocalStorage, parsedJson, poster, selectSub, toTitleCase, validateEmail } from '../../../tools/tools';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { getReferRequested } from '../../../redux/refer/actions';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages';
import Skeleton from 'react-loading-skeleton';
import ErrorBoundary from "../../ErrorBoundary"
import { sendEvents } from '../../../tools/mpEvents';
import { getAccountRequested } from '../../../redux/account/actions';
import { referralTierStatus, referralTiers } from '../../../tools/constants';
import { useTranslation, Trans } from 'react-i18next';
import ReferralsBanner from './Banner';
import ReferralTiersModal from './modals/TiersModal';
import useReferralProgress from './custom-hooks/useReferralProgress';
import { getCurrentLevel, getCurrentTier } from './tools/tools';
import { sendBiEvent } from '../../../tools/biEvents';

const Refer = ({history, invites:reduxData, getReferRequested, accountReducer, getAccountRequested, onBoardingReducer}) => {
    const { t, i18n } = useTranslation();

    const [crashed, setCrashed] = useState(false)

    const [showTiersModal, setShowTiersModal] = useState(false)
    
    const [selectedSub, setSelectedSub] = useState(null)

    const { search } = useLocation();

    const {utm_source, utm_medium, utm_campaign} = Object.fromEntries(new URLSearchParams(search));

    const dispatch = useDispatch()

    const token = localStorage.getItem("token")

    const [link, setLink] = useState(process.env.REACT_APP_REFERLINK)

    const [accountData, setAccountData] = useState({
        response:null,
        loading:true
    })

    const { referralProgress, isReferralLoading, progressErrorMsg, setFetchProgressAgain, newFetchSuccess } = useReferralProgress(getTokenFromLocalStorage())

    useEffect(() => {
        getAccountRequested(token)
    }, [])

    useEffect(() => {
        if(newFetchSuccess) {
            sendEvents({
                event_name:"ma_visit_refer_a_friend",
                event_properties:{
                    utm_source: utm_source ?? "Refer link",
                    utm_medium: utm_medium ?? "Member Area",
                    utm_campaign: utm_campaign ?? "page",
                    current_level: getCurrentLevel(referralProgress?.progress),
                    current_tier: getCurrentTier(referralProgress?.progress),
                  }
            }, token)
            sendBiEvent({
                event_name: "ma_visit_refer_a_friend",
                event_properties: {
                  current_level: getCurrentLevel(referralProgress?.progress),
                  current_tier: getCurrentTier(referralProgress?.progress),
                }
            }, token)
        }
    }, [newFetchSuccess])

    useEffect(() => {
        const { loading: reduxLoading, subscriptions: reduxData, errors:ob_error, errorType:ob_errorType, resetState, errorMessage:ob_errorMessage,selectedSubs:selectedSubs } = onBoardingReducer;
        if(!reduxLoading && reduxData && !ob_error) {
            setSelectedSub(selectSub(reduxData, selectedSubs))
        }
    }, [onBoardingReducer])

    useEffect(() => {
        const { loading: reduxLoading, accounts, errors, errorType, errorMessage } = accountReducer;
        if(accounts){
            setLink(`${process.env.REACT_APP_REFERLINK}?referrer=${accounts?.body?.encrypted_code}`);
            setAccountData({
                response: accounts.body,
                loading: false
            })
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                           
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "APIFAILED":
                    setCrashed(true)
                    break;     
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [accountReducer])

    if(crashed){
        return <ErrorBoundary></ErrorBoundary>
    }

    const calcProgressPercentage = (invites, maxInvites) => {
        return (invites*100)/maxInvites
    }

    const clickShowTierAndRewards = () => {
        setShowTiersModal(true);
        sendEvents({
            event_name: "ma_click_tiers_and_rewards",
            event_properties: {
              action:"click",  
              current_level: getCurrentLevel(referralProgress?.progress),
              current_tier: getCurrentTier(referralProgress?.progress),
            }
        }, token);
    }

    return (
        <div>
            <DashboardHead>
                <h2>Refer & Win</h2>
                <p>{t('refer_title_para')}</p>
            </DashboardHead>
            {
                !accountData.loading
                ?
                <>
                <ReferralsBanner link={link} progress={referralProgress?.progress} selectedSub={selectedSub} />
                { !isReferralLoading ?
                    !progressErrorMsg ?
                        <ReferBox>
                            <h1>
                                <Trans
                                    i18nKey={referralProgress?.headline?.key}
                                    values={{ amount: referralProgress?.headline?.amount }}
                                    components={{ italic: <i />, bold: <strong /> }}
                                />
                            </h1>
                            <div className='tiers'>
                                {
                                    referralProgress?.progress?.map((level) => {
                                        return ( 
                                        <TierBox active={(level.status === referralTierStatus.progress)} isLocked={level.status === referralTierStatus.locked} barPercentage={calcProgressPercentage(level.invites, level.maxInvites)} key={level.order}>
                                            <div className='head'>
                                                <span className={level.status}>{toTitleCase(level.status === referralTierStatus.progress ? level.level : level.status)}</span>
                                                <TierIcon className={`sm ${level.tier}`}/>
                                            </div>
                                            <div className='title'>
                                                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                <span className='title-text'>{t('successful_referral')} {`${level.invites}/${level.maxInvites}`}</span>
                                            </div>
                                            <div className='info'>
                                                <h3>{t('rewards')}</h3>
                                                <ul>
                                                    {
                                                        parsedJson(level.description)?.map((li) => <li>{li}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        </TierBox>
                                        )
                                    })
                                }
                            </div>
                            <hr />
                            <div className='tiers-progress'>
                                {
                                    referralProgress?.tiers?.map((tier) => {
                                        return (
                                            <div className={tier.status === referralTierStatus.locked && referralTierStatus.locked} key={tier.name}>
                                                <TierIcon className={tier.name} status={tier.status} ><span></span></TierIcon> <p>{tier.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='invite-info'>
                                <div className='section'>
                                    <div className='title'>{t('friends_invited')}</div>
                                    <div className='count'>{referralProgress?.friendsInvited}</div>
                                </div>
                                <div className='section'>
                                    <div className='title'>{t('friends_stayed')}</div>
                                    <div className='count'>{referralProgress?.friendsStayed}</div>
                                </div>
                                <div className='section'>
                                    <div className='title'>{t('months_earned')}</div>
                                    <div className='count'>{referralProgress?.monthsEarned}</div>
                                </div>
                            </div>
                            <p className='show-tiers' onClick={clickShowTierAndRewards}>{t('see_tiers_rewards')}</p>
                        </ReferBox>
                    :
                    <ErrorBoundary
                        heading={progressErrorMsg ?? "Oops!"}
                        className="mt-3"
                        tglCartApi={setFetchProgressAgain}
                        isCartError={true}
                    />
                :
                <>
                    <InfoCard hasborder noflex className="mb-3">
                        <Skeleton height={20} width={300} />
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300} />
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300} />
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300} />
                    </InfoCard>
                    <InfoCard noflex className="pt-0">
                        <Skeleton height={20} width={300} />
                    </InfoCard>
                </>
                }
                </>
                :
                <div className="row">
                    <div className="col-md-9">
                        <CardBox open={true}>
                            <InfoCard hasborder noflex className="mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard noflex className="pt-0">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                        </CardBox>
                    </div>
                </div>
            }
            { showTiersModal && <ReferralTiersModal setShowTiersModal={setShowTiersModal} progress={referralProgress?.tiers}/> }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        invites: state.referReducer,
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer,
     };
};

export default connect(mapStateToProps, {getReferRequested, getAccountRequested})(Refer)