import { EarlyAccessCardView } from "../../../../styles/dashboard/PureAI";
import { connect } from "react-redux";
import { skeleton } from "./helper";

const EarlyAccessCard = ({title, description, image, extraClass, pureAIStateReducer}) => {
    return (
        <EarlyAccessCardView className={`side-section-card ${extraClass}`}>
            <div className="card-content">
                {
                    skeleton(<div className="card-title">{title}</div>, pureAIStateReducer.currentState.loading)
                }
                {
                    skeleton(<div className="card-description">{description}</div>, pureAIStateReducer.currentState.loading)
                }
            </div>
            <div className="card-image">
                {
                    skeleton(<img src={image} alt={title}/>, pureAIStateReducer.currentState.loading, 100, 100)
                }
            </div>
        </EarlyAccessCardView>
    )
}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(EarlyAccessCard);