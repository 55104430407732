import React, {useEffect} from 'react'
import { DashboardHead } from '../../../styles/dashboard/Main'
import { PrimaryBtn } from '../../../styles/Generic'
import {Helmet} from 'react-helmet'
import {sendEvents} from '../../../tools/mpEvents'
import { useTranslation } from 'react-i18next';

const Affiliate = () => {

    const { t, i18n } = useTranslation();

    const token = localStorage.getItem("token");

    useEffect(() => {
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A"
            }
        }, token)
    }, [])

    const handlebecomeAff = () => { 
        sendEvents({
            event_name:"ma_click_affiliates_CTA",
            event_properties:{
            }
        }, token)

        window.open("https://www.purevpn.com/applinks/member-area/affiliates")

    }

    return (
        <div className="affiliate-wrapper">
            <Helmet>
                <title>Member Area | Affiliates</title>
                <meta name="description" content="PureVPN offers proprietary apps for all leading platforms so you can enjoy complete internet freedom on any device you want!" />
            </Helmet>
            <DashboardHead>
                <h2>Affiliates</h2>
                <p>{t('affiliates_title_para')}</p>
            </DashboardHead>
            <div className="row">
                <div className="col-md-6">
                    <ul className="mb-4 ">
                        <li>{t('affiliates_track_your_sales')}</li>
                        <li>{t('affiliates_updates')}</li>
                        <li>{t('affiliates_commision')}</li>
                        <li>{t('affiliates_inside_news')}</li>
                        <li>{t('affiliates_product_updates')}</li>
                        <li>{t('affiliates_much_more')}</li>
                    </ul>
                    <PrimaryBtn className="mt-3" onClick={handlebecomeAff}>{t('affiliates_cta_text')}</PrimaryBtn>
                </div>
            </div>
        </div>
    )
}

export default Affiliate