export const purescan = {
    en: {
        heading_1: "PurePrivacy - Digital Security",
        para_1: "PurePrivacy scans your social media accounts, assesses your current privacy settings, searches for data leaks, and provides you with optimal recommendations for you to apply in order to keep your data secure.",
        heading_2: "Benefits from PurePrivacy",
        para_2: "",
        desc: ["<strong> Ensure nothing is being tracked </strong> Block social media platforms from keeping a track of your online activity outside of their platform so that no one can view or access your personal information.",
            "<strong> Prevent unwanted interruptions </strong> Avoid letting your valuable data fall into the hands of advertisers who can use it to serve you with lengthy and annoying advertisements.",
            "<strong> Keep your privacy intact </strong> There are several security measures that are unknown to many. You can learn about them and use them with PurePrivacy to make sure you are 100% safe while you browse.",
            "<strong> Stay in control </strong> Automate regular social media security checkups and prevent search engines from revealing your sensitive data to complete strangers, all with just a single click.",
            "<strong> Sync across multiple devices </strong> Set up and safely access your stored information on all your mobile devices and desktop",
        ],
        cta: "Get this Add-on",
    },
    fr: {
        heading_1: "PurePrivacy - Sécurité numérique",
        para_1: "PurePrivacy analyse vos comptes de médias sociaux, évalue vos paramètres de confidentialité actuels, recherche les fuites de données et vous fournit des recommandations optimales à appliquer afin de protéger vos données.",
        heading_2: "Avantages de PurePrivacy",
        para_2: "",
        desc: [
            `<strong>Assurez-vous que rien n'est suivi  </strong> Empêchez les plateformes de médias sociaux de garder une trace de votre activité en ligne en dehors de leur plateforme afin que personne ne puisse voir ou accéder à vos informations personnelles. `,
            `<strong>Empêchez les interruptions indésirables </strong> Évitez de laisser vos précieuses données tomber entre les mains d'annonceurs qui peuvent les utiliser pour vous proposer des publicités longues et ennuyeuses. `,
            `<strong>Gardez votre vie privée intacte </strong> Il existe plusieurs mesures de sécurité inconnues de beaucoup. Vous pouvez en apprendre davantage sur eux et les utiliser avec PurePrivacy pour vous assurer que vous êtes en sécurité à 100 % pendant que vous naviguez. `,
            `<strong>Gardez le contrôle </strong> Automatisez les vérifications régulières de la sécurité des médias sociaux et empêchez les moteurs de recherche de révéler vos données sensibles à de parfaits inconnus, le tout en un seul clic. `,
            `<strong>Synchronisation sur plusieurs appareils </strong> Configurez et accédez en toute sécurité à vos informations stockées sur tous vos appareils mobiles et votre ordinateur de bureau `,
        ],
        cta: "Obtenez ce module complémentaire",
    },
    de: {
        heading_1: "PurePrivacy - Digitale Sicherheit",
        para_1: "PurePrivacy scannt Ihre Social-Media-Konten, bewertet Ihre aktuellen Datenschutzeinstellungen, sucht nach Datenlecks und gibt Ihnen optimale Empfehlungen für die Anwendung, um Ihre Daten zu schützen.",
        heading_2: "Vorteile von PurePrivacy",
        para_2: "",
        desc: [
            `<strong>Stellen Sie sicher, dass nichts nachverfolgt wird.  </strong>Blockieren Sie Social-Media-Plattformen daran, Ihre Online-Aktivitäten außerhalb ihrer Plattform zu verfolgen, damit niemand Ihre persönlichen Daten sehen oder darauf zugreifen kann. `,
            `<strong>Verhindern Sie unerwünschte Unterbrechungen </strong>Lassen Sie Ihre wertvollen Daten nicht in die Hände von Werbetreibenden fallen, die sie verwenden könnten, um Sie mit langwieriger und lästiger Werbung zu versorgen. `,
            `<strong>Bewahren Sie Ihre Privatsphäre intakt </strong> Es gibt mehrere Sicherheitsmaßnahmen, die vielen unbekannt sind. Sie können sich über sie informieren und sie mit PurePrivacy verwenden, um sicherzustellen, dass Sie beim Surfen 100 % sicher sind. `,
            `<strong>Behalten Sie die Kontrolle </strong> Automatisieren Sie regelmäßige Social-Media-Sicherheitsüberprüfungen und verhindern Sie, dass Suchmaschinen Ihre sensiblen Daten an völlig Fremde weitergeben, alles mit nur einem einzigen Klick. `,
            `<strong>Synchronisieren Sie auf mehreren Geräten </strong> Richten Sie Ihre gespeicherten Informationen auf all Ihren Mobilgeräten und Desktops ein und greifen Sie sicher darauf zu `,
        ],
        cta: "Holen Sie sich dieses Add-on",
    },
    zh: {
        heading_1: "PurePrivacy - 數字安全",
        para_1: "PurePrivacy 掃描您的社交媒體賬號，評估您當前的隱私設置，搜索數據洩漏，並為您提供最佳建議，以確保您的數據安全。",
        heading_2: "PurePrivacy 的好處",
        para_2: "",
        desc: [
            `<strong>確保沒有任何內容被跟踪 </strong>阻止社交媒體平台跟踪您在其平台之外的在線活動，這樣就沒有人可以查看或訪問您的個人信息。 `,
            `<strong>防止不必要的廣告 </strong>避免讓您的寶貴數據落入廣告商的手中，他們可以利用這些數據為您投放冗長而煩人的廣告。 `,
            `<strong>保持您的隱私完好無損 </strong>許多人都不知道有幾種安全措施。您可以了解它們並將它們與 PurePrivacy 一起使用，以確保您在瀏覽時 100% 安全。 `,
            `<strong>保持控制 </strong>自動定期進行社交媒體安全檢查，並防止搜索遊覽器將您的敏感數據洩露給完全陌生的人，只需單擊一下即可。 `,
            `<strong>同步跨多個裝置 </strong>在所有移動裝置和台式機上設置並安全訪問您存儲的信息 `,
        ],
        cta: "獲取此插件",
    },
    es: {
        heading_1: "PurePrivacy - Seguridad digital",
        para_1: "Pureprivacy escanea sus cuentas de redes sociales, evalúa su configuración de privacidad actual, busca fugas de datos y le proporciona recomendaciones óptimas para que solicite aplicar para mantener sus datos seguros.",
        heading_2: "Beneficios de Pureprivacy",
        para_2: "",
        desc: [
            "<strong>Asegúrese de que no se rastree nada</strong> bloquee las plataformas de redes sociales para evitar un seguimiento de su actividad en línea fuera de su plataforma para que nadie pueda ver o acceder a su información personal.",
            "<strong>Evite las interrupciones no deseadas</strong> evite dejar que sus valiosos datos caigan en manos de los anunciantes que pueden usarlos para servirle con anuncios largos y molestos.",
            "<strong>Mantenga su privacidad intacta</strong> hay varias medidas de seguridad que son desconocidas para muchos. Puede aprender sobre ellos y usarlos con Pureprivacy para asegurarse de que está 100% seguro mientras navega.",
            "<strong>Manténgase en control</strong> automatice los controles regulares de seguridad de las redes sociales y evite que los motores de búsqueda revelen sus datos confidenciales para completar a extraños, todos con un solo clic.",
            "<strong>Sincronizar a través de múltiples dispositivos</strong> configure y acceda de forma segura a su información almacenada en todos sus dispositivos móviles y escritorio",
        ],
        cta: "Obtenga este complemento",
    },
    ru: {
        heading_1: "PurePrivacy - цифровая безопасность",
        para_1: "PurePrivacy сканирует ваши учетные записи в социальных сетях, оценивает ваши текущие настройки конфиденциальности, поиск утечек данных и предоставляет вам оптимальные рекомендации для применения, чтобы обеспечить безопасность ваших данных.",
        heading_2: "Выгоды от чистой",
        para_2: "",
        desc: [
            "<strong>Убедитесь, что ничего не отслеживается </strong> блокируйте платформы социальных сетей от отслеживания вашей онлайн - деятельности за пределами своей платформы, чтобы никто не мог просматривать или получить доступ к вашей личной информации.",
            "<strong>Предотвратить нежелательные перерывы </strong> не позволяйте вашим ценным данным попасть в руки рекламодателей, которые могут использовать их, чтобы обслуживать вас с длительной и раздражающей рекламой.",
            "<strong>Сохраняйте свою конфиденциальность нетронутой </strong> есть несколько мер безопасности, которые неизвестны многим. Вы можете узнать о них и использовать их с PurePrivacy, чтобы убедиться, что вы на 100% в безопасности во время просмотра.",
            "<strong>Оставайтесь в контроле </strong> автоматизируйте регулярные проверки безопасности в социальных сетях и не позволяют поисковым системам раскрывать ваши конфиденциальные данные для завершения незнакомцев, и все это с одним щелчком.",
            "<strong>Синхронизируется на нескольких устройствах </strong> настройте и безопасно доступ к вашей хранимой информации на всех ваших мобильных устройствах и настольных компьютерах",
        ],
        cta: "Получите это дополнение",
    },
    tr: {
        heading_1: "PurePrivacy - Dijital Güvenlik",
        para_1: "PurePrivacy, sosyal medya hesaplarınızı tarar, mevcut gizlilik ayarlarınızı değerlendirir, veri sızıntıları arar ve verilerinizi güvenli tutmak için başvurmanız için en uygun öneriler sunar.",
        heading_2: "PurePrivacy'den Faydalar",
        para_2: "",
        desc: [
            "<strong>Hiçbir şey izlenmediğinden emin olun </strong> Sosyal medya platformlarının, kimsenin kişisel bilgilerinizi görüntüleyemeyeceği veya erişememesi için çevrimiçi etkinliğinizin bir izini platformlarının dışında tutmasını engelleyin.",
            "<strong>İstenmeyen kesintileri önleyin </strong> Değerli verilerinizin size uzun ve can sıkıcı reklamlarla hizmet etmek için kullanabilen reklamverenlerin eline geçmesine izin vermekten kaçının.",
            "<strong>Gizliliğinizi sağlam tutun </strong> Birçokları tarafından bilinmeyen birkaç güvenlik önlemi vardır. Onlar hakkında bilgi edinebilir ve göz atarken% 100 güvenli olduğunuzdan emin olmak için onları PurePrivacy ile kullanabilirsiniz.",
            "<strong>Kontrolde kalın </strong> Düzenli sosyal medya güvenlik kontrollerini otomatikleştirin ve arama motorlarının, hepsi tek bir tıklamayla yabancıları tamamlamak için hassas verilerinizi açığa çıkarmasını önleyin.",
            "<strong>Birden çok cihaz arasında senkronize edin </strong> depolanan bilgilerinizi tüm mobil cihazlarınızda ve masaüstünüzde ayarlayın ve güvenli bir şekilde erişin",

        ],
        cta: "Bu eklentiyi alın",
    },
    pt: {
        heading_1: "PurePrivacy - Segurança Digital",
        para_1: "O PurePrivacy verifica suas contas de mídia social, avalia suas configurações de privacidade atuais, pesquisam vazamentos de dados e fornecem recomendações ideais para você se inscrever para manter seus dados seguros.",
        heading_2: "Benefícios da Pureprivacy",
        para_2: "",
        desc: [
            "<strong> Garanta que nada esteja sendo rastreado </strong> bloqueie as plataformas de mídia social de acompanhar sua atividade on -line fora de sua plataforma, para que ninguém possa visualizar ou acessar suas informações pessoais.",
            "<strong> Prevenção de interrupções indesejadas </strong> evite permitir que seus dados valiosos caam nas mãos de anunciantes que podem usá -los para atendê -lo com anúncios longos e irritantes.",
            "<strong> Mantenha sua privacidade intacta </strong> existem várias medidas de segurança que são desconhecidas para muitos. Você pode aprender sobre eles e usá -los com a Pure Privacy para garantir que você esteja 100% seguro enquanto navega.",
            "<strong> Mantenha se no controle </strong> automatize os exames de segurança de mídia social regulares e impedem que os mecanismos de pesquisa revelem seus dados confidenciais para completar estranhos, todos com apenas um único clique.",
            "<strong> Sincronizar em vários dispositivos </strong> Configure e acesse com segurança suas informações armazenadas em todos os seus dispositivos móveis e desktop",
        ],
        cta: "Obtenha este complemento",
    },
    ja: {
        heading_1: "PurePrivacy-デジタルセキュリティ",
        para_1: "PurePrivacyは、ソーシャルメディアアカウントをスキャンし、現在のプライバシー設定を評価し、データリークを検索し、データを安全に保つために適用するための最適な推奨事項を提供します。",
        heading_2: "PurePrivacyの恩恵",
        para_2: "",
        desc: [
            "<strong> 何も追跡されていないことを確認してください </strong> ソーシャルメディアプラットフォームがプラットフォームの外でオンラインアクティビティの追跡を維持することをブロックして、誰もあなたの個人情報を表示したりアクセスしたりできないようにします。",
            "<strong> 不要な中断を防ぐ </strong> 貴重なデータが、長くて迷惑な広告であなたに役立つようにそれを使用できる広告主の手に渡ることを避けてください。",
            "<strong> プライバシーをそのままにしておく </strong> 多くの人に知られていないセキュリティ対策がいくつかあります。それらについて学び、純粋なプリバシーでそれらを使用して、閲覧中に100％安全であることを確認できます。",
            "<strong> コントロールを維持する </strong> 定期的なソーシャルメディアセキュリティの検診を自動化し、検索エンジンがあなたの機密データを明らかにして、すべてのクリックで見知らぬ人を完全にしないようにします。",
            "<strong> 複数のデバイスで同期する </strong> すべてのモバイルデバイスとデスクトップに保存された情報をセットアップして安全にアクセスする",
        ],
        cta: "このアドオンを取得します",
    },
    it: {
        heading_1: "Pure PRIVIVACE - Sicurezza digitale",
        para_1: "PurePrivacy scansiona i tuoi account sui social media, valuta le tue attuali impostazioni sulla privacy, cerca perdite di dati e ti fornisce consigli ottimali da applicare per mantenere i tuoi dati sicuri.",
        heading_2: "Benefici della pura privacy",
        para_2: "",
        desc: [
            "<strong>Assicurati che non venga monitorato nulla </strong> Blocca le piattaforme di social media di tenere traccia della tua attività online al di fuori della loro piattaforma in modo che nessuno possa visualizzare o accedere alle tue informazioni personali.",
            "<strong>Prevenire interruzioni indesiderate </strong> Evita di lasciare che i tuoi preziosi dati cadano nelle mani degli inserzionisti che possono usarli per servirti con pubblicità lunghe e fastidiose.",
            "<strong>Mantieni la tua privacy intatta </strong> Esistono diverse misure di sicurezza che sono sconosciute a molti. Puoi conoscerli e usarli con pura privacy per assicurarti di essere al sicuro al 100% mentre navighi.",
            "<strong>Resta in controllo </strong> Automatizza regolari controlli di sicurezza dei social media e impedisci ai motori di ricerca di rivelare i tuoi dati sensibili per completare gli estranei, il tutto con un solo clic.",
            "<strong>Sincronizzazione su più dispositivi </strong> Imposta e accedi in modo sicuro alle informazioni memorizzate su tutti i dispositivi mobili e il desktop",
        ],
        cta: "Ottieni questo componente aggiuntivo",
    },
    nl: {
        heading_1: "Pureprivacy - Digitale beveiliging",
        para_1: "Pureprivacy scant uw sociale media -accounts, beoordeelt uw huidige privacy -instellingen, zoekopdrachten naar gegevenslekken en geeft u optimale aanbevelingen die u kunt toepassen om uw gegevens veilig te houden.",
        heading_2: "Voordelen van pureprivacy",
        para_2: "",
        desc: [
            "<strong>Zorg ervoor dat er niets wordt gevolgd </strong> blokkeer sociale mediaplatforms om uw online activiteit buiten hun platform te behouden, zodat niemand uw persoonlijke informatie kan bekijken of toegang kunnen krijgen.",
            "<strong>Voorkom ongewenste onderbrekingen </strong> Vermijd dat uw waardevolle gegevens in handen zijn van adverteerders die het kunnen gebruiken om u te dienen met langdurige en vervelende advertenties.",
            "<strong>Houd uw privacy intact </strong> Er zijn verschillende beveiligingsmaatregelen die voor velen onbekend zijn. Je kunt erover leren en ze gebruiken met pureprivacy om ervoor te zorgen dat je 100% veilig bent terwijl je bladert.",
            "<strong>Blijf onder controle</strong>  Automeer regelmatige controles van sociale media -beveiliging en voorkom dat zoekmachines uw gevoelige gegevens onthullen om vreemden te voltooien, allemaal met slechts één klik.",
            "<strong>Synchroniseerd op meerdere apparaten </strong> Stel en open uw opgeslagen informatie op al uw mobiele apparaten en bureaublad instellen en veilig",
        ],
        cta: "Koop deze add-on",
    },
    ko: {
        heading_1: "Purefrivacy- 디지털 보안",
        para_1: "PurePrivacy는 소셜 미디어 계정을 검색하고 현재 개인 정보 설정을 평가하고 데이터 유출 검색을하며 데이터 보안을 유지하기 위해 적용 할 수있는 최적의 권장 사항을 제공합니다.",
        heading_2: "Pure -Privacy의 혜택",
        para_2: "",
        desc: [
            "<strong>아무것도 추적되지 않도록 </strong> 소셜 미디어 플랫폼이 플랫폼 외부에서 온라인 활동을 추적하여 아무도 개인 정보를 보거나 액세스 할 수 없도록 차단하십시오.",
            "<strong>원치 않는 중단 방지 </strong> 귀중한 데이터가 길고 성가신 광고를 제공하기 위해 사용할 수있는 광고주의 손에 들어 가지 않도록하십시오.",
            "<strong>개인 정보 보호를 그대로 유지하십시오 </strong> 많은 사람들에게 알려지지 않은 몇 가지 보안 조치가 있습니다. 당신은 그들에 대해 배우고 순수한 자극과 함께 사용하여 탐색하는 동안 100% 안전한 지 확인할 수 있습니다.",
            "<strong>제어 상태 유지 </strong> 정기적 인 소셜 미디어 보안 검진을 자동화하고 검색 엔진이 한 번의 클릭만으로 낯선 사람을 완성하기 위해 민감한 데이터를 공개하지 않도록합니다.",
            "<strong>여러 장치에서 동기화 </strong> 모든 모바일 장치 및 데스크탑에서 저장된 정보를 설정하고 안전하게 액세스하십시오.",
        ],
        cta: "이 애드온을 얻으십시오",
    },
}