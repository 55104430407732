import {
  cancelled,
  put,
  takeLatest,
} from "redux-saga/effects";
import { GETCOUNTRIES, LOADING, ERRORS, SUCCESS_COUNTRIES } from "./types";
import axios from "axios";
import { errorsType, getSuccessCountry, loadingType } from "./action";
import { APIFAILED } from "../../tools/responseMessages";
import { get } from "../../tools/tools";
import i18n from "i18next";

function* handleGetCountry(action) {
  const cancelTokenSource = axios.CancelToken.source();
  // yield put(loadingType())
  try {
    const country = yield get("manual/configuration",action.token);
    const { body: response } = country.data;
    if (country?.data?.status) {
      yield put(getSuccessCountry({ country: response }));
    } else {
      const { data } = country;
      yield put(
        errorsType({
          code: data?.error_code ?? "APIFAILED",
          message: data?.message,
        })
      );
    }
  } catch (err) {
    yield put(errorsType({ code: "APIFAILED", message: ((i18n.t(APIFAILED))) }));
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

function* watcherCountrySaga() {
  yield takeLatest("GET_COUNTRIES_REQUESTED", handleGetCountry);
}

export default watcherCountrySaga;
