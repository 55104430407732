import React, { useEffect, useState } from "react";
import Currency from "./partial/Currency";
import ErrorBoundary from "../../ErrorBoundary";
import EarnRedeemLoader from "./partial/EarnRedeemLoader";
import {
  EarnView,
  ReferralButton,
} from "../../../styles/dashboard/pure-rewards/PureWallet";
import useEarningInfo from "./custom-hooks/useEarningInfo";
import { getTokenFromLocalStorage, parsedJson, selectSub, isWhmcsUser, canShowRule } from "../../../tools/tools";
import { useHistory } from "react-router-dom";
import { sendEvents } from "../../../tools/mpEvents"
import AddonModal from "./modals/AddonModal";
import { connect } from "react-redux";

const Earn = ({onBoardingReducer}) => {
  const {
    earningInfo,
    isEarnLoading,
    isError,
    earnErrorMsg,
    setfetchEarnAgain,
  } = useEarningInfo(getTokenFromLocalStorage());

  const { errors : onboaridngError , selectedSubs, subscriptions,  loading:onboardingLoading } = onBoardingReducer
  const token = localStorage.getItem("token");

  const [openModal, setOpenModal] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState(subscriptions?.length && subscriptions[0]) 
  
  const history = useHistory();

  useEffect(()=>{
    setSelectedSubscription(selectSub(subscriptions,selectedSubs))
  },[selectedSubs])

  const clickToEarn = (data, points) => {
    if(data?.action === "redirect"){
      history.push(`/dashboard/${data?.redirectUrl}`)
    }else if(data?.action === "open_cart"){
      setOpenModal(data?.code)
    }
    sendEvents({
      event_name:"ma_loyalty_click_earn",
      event_properties:{
        action: data?.name,
        points: points,
      }
    }, token)
  };

  const getActionAndCode = (url) => {
    const params = new URLSearchParams(url && (new URL(url).search));
    const regex = /dashboard\/(.*?)[&?]action/g;
    const match = regex.exec(url);

    let redirectUrl = null
    if (match && match[1]) {
      redirectUrl = match[1];
    }
    return {
      code: params.get('code'),
      action: params.get('action'),
      redirectUrl
    }
  }

  return (!isEarnLoading) ? (
    !earnErrorMsg ? (
      <>
      <EarnView>
        {earningInfo?.map((challenge) => {
          const action = getActionAndCode(challenge?.redirectionButtonLink)
          if (challenge?.visibility === 'Always Visible') {
            const showRule = canShowRule(selectedSubscription, action?.code)
            if (showRule) {
              return (
                <div className="earn-view" key={challenge?.id}>
                  <div className="box-1">
                    <span className="info-block">
                      <h2 className="mb-1">{challenge?.name}</h2>
                      <p className="m-0">{challenge?.description}</p>
                    </span>
                  </div>
                  <div className="box-2">
                    <span className="points-block d-flex justify-content-center align-items-center">
                      <Currency />
                      <p className="m-0 mx-2">
                        {challenge?.rewards[0]?.walletReward} Points
                      </p>
                    </span>
                    <ReferralButton
                      className="cta"
                      onClick={() => {
                        clickToEarn(action, challenge?.rewards[0]?.walletReward);
                      }}
                    >
                      {challenge?.redirectionButtonText}
                    </ReferralButton>
                  </div>
                </div>
              )
            }
          }
        })}
      </EarnView>
      {
        openModal && <AddonModal setOpenModal={setOpenModal} addon={openModal} selectedSubscription={selectedSubscription}/>
      }
      </>
    ) : (
      // Need to configure Error State wrt local storage
      <ErrorBoundary
        heading={earnErrorMsg ?? "Oops!"}
        className="mt-5"
        tglCartApi={setfetchEarnAgain}
        isCartError={true}
      />
    )
  ) : (
    <EarnRedeemLoader />
  );
};

const mapStateToProps = (state) => {
  return { 
      onBoardingReducer: state.onBoardingReducer
   };
};

export default connect(mapStateToProps)(Earn)