
export const TeamServerReducer = (state, action) => {
    switch (action.type) {
        case 'increase': {
            return {
                ...state,
                current: +state?.current + 1 > +state.max? +state.max : +state?.current + 1
            };
        }
        case 'decrease': {
            return {
                ...state,
                current: +state?.current - 1 < +state.min? +state.min : +state?.current - 1
            };
        }
        case 'change': {
            return {
                ...state,
                current: action.value?.length < 4 ? Math.abs(parseInt(action.value)) : state.current
            };
        }
        case 'focus' : {
            return {
                ...state,
                current: action.value
            };
        }
        case 'initial': {
            return {
                ...state,
                min: action.min,
                current: action.current,
                price: action.price,
                previous: action.previous
            }
        }
    }
}
    
