import { withRouter } from "react-router-dom";
import { PlanTypeInfoCardStyles } from "../../../styles/dashboard/PlanTypeUpgrade";
import { useTranslation } from 'react-i18next'

const PlanTypeInfoCard = ({planType, getDynamicRef, hasMax, history}) => {
    
    const { t, i18n } = useTranslation();

    const redirectTo = (productName) => {
        history.push(`/dashboard/onboarding/${productName == 'pureencrypt' ? 'purecrypt': productName}`)
    }
    return <PlanTypeInfoCardStyles>

         <img src={planType?.image} alt="" style={{order: planType.order}}/>
         
         <div className="features" style={{order: planType.order == 1 ? 2 : 1}} ref={getDynamicRef(planType?.heading.toLowerCase())}>

            <div className="f-head">
                <span className="f-img" style={planType?.styles}></span>
                <div>
                    <h4 className={hasMax ? 'link' : null} onClick={hasMax ? ()=> redirectTo(planType.heading.toLowerCase()) : null}>{t(planType?.heading)}</h4>
                    <h6>{t(planType?.paragraph)}</h6>
                </div>
            </div>  

            <ul>
                {planType?.features.map((feature)=>{
                    return <li>
                        <span className="pt-icons" style={feature?.style}></span>
                        <div>
                            <h5>{t(feature?.heading)}</h5>
                            <p>{t(feature?.paragraph)}</p>
                        </div>
                    </li>
                })}
            </ul>

         </div>

    </PlanTypeInfoCardStyles>
}

export default withRouter(PlanTypeInfoCard);