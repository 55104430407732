import React, { useEffect, useRef, useReducer, useState } from 'react'
import { suggestedPrompts, suggestedPromptsLess } from './content';
import EnterIcon from '../../../assets/EnterMsg.svg'
import DisabledEnterIcon from '../../../assets/disableEnter.svg'
import PureAILogo from '../../../assets/pure-ai-logo-large.svg'
import MenuModal from './utils/MenuModal';
import { AvatarDiv, InputBox, PureBuddyView, ScrollButton, StopGeneratingBtn, SideBuddyView, WaitlistModal, SideCardBuddyView, CardlistModal } from '../../../styles/dashboard/PureBuddy';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages';
import { getCookie, setCookie } from '../../../tools/tools';
import TextLoader from './utils/TextLoader';
import { toast } from 'react-toastify'
import { connect, useDispatch } from 'react-redux';
import { getAccountRequested, subscriptionRequested } from '../../../redux/account/actions';
import Loader from '../manual-connection/Loader';
import { ACTIONS, chatGptResponseReducer, intialState } from './reducer';
import io from "socket.io-client";
import { sendEvents } from '../../../tools/mpEvents';
import { useLocation } from 'react-router-dom';
import { uuid } from 'uuidv4';
import ErrorBoundary from '../../ErrorBoundary';
import fpCrashed from '../../../assets/pure-ai-waitlist.mp4'
import aiLogoVector from '../../../assets/ai-logo-vector.svg'
import tvVector from '../../../assets/pure-ai-waitlist-img-2.svg'
import gameVector from '../../../assets/pure-ai-waitlist-img-3.svg'
import { fetcher, poster } from '../../../tools/tools'
import qs from 'qs'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import InnerHTML from 'dangerously-set-html-content'
import YouTubeIcon from '../../../assets/youtube.svg'
import DocumentIcon from '../../../assets/document.svg'
import AccountIcon from '../../../assets/account.svg'
import UpgradeCampaign from '../hello-bar/UpgradeCampaign';
import { useTranslation } from 'react-i18next'

const PureBuddy = ({ subscriptionReducer, subscriptionRequested, accountReducer, getAccountRequested }) => {


    const { t, i18n } = useTranslation();

    const token = localStorage.getItem("token");

    const [channelId, setChannelId] = useState(localStorage.getItem("channelId"));

    const [formStateDisable, setFormStateDisbaled] = useState(false)

    const [crashed, setCrashed] = useState(false)

    const [resIsPending, setResIsPending] = useState(false)

    const [cardInput1, setCardInput1] = useState('');
    const [cardInput2, setCardInput2] = useState('');
    const [cardInput3, setCardInput3] = useState('');

    const inputRef = useRef(null)

    const onceRender = useRef(false);

    const { search } = useLocation()

    const [audienceType, setAudienceType] = useState(localStorage.getItem('audienceType'));

    const { utm_source, utm_medium, utm_campaign } = Object.fromEntries(new URLSearchParams(search));

    const dispatch = useDispatch();

    const [state, dispatchGpt] = useReducer(chatGptResponseReducer, intialState)

    const [placeholderText, setPlaceholderText] = useState(t('pureai_placeholder_one'));

    const closeHelloBar = localStorage.getItem("closeHelloBar");

    const [suggestedPromptsValue, setSuggestedPrompts] = useState(suggestedPrompts)

    const userEmail = useState(subscriptionReducer?.subscriptions?.body?.[0]?.account?.email?.slice(0, 1) ?? null)

    const [showMenu, setShowMenu] = useState(false)

    const [showCursor, setShowCursor] = useState(true);
    
    const responseDisplayRef = useRef(null);
    
    const shouldAutoScrollRef = useRef(true);

    const [socket, setSocket] = useState(null);

    const [today, setDate] = useState(new Date());

    const [chatId, setChatId] = useState(uuid());

    const [tState, setTState] = useState(null)

    const [firstPrompt, setFirstPrompt] = useState(false);

    const [currentTool, setCurrentTool] = useState(null);

    const [showJoinWaitlistModal, setShowJoinWaitlistModal] = useState(false);

    const [showJoinCardlistModal, setShowJoinCardlistModal] = useState(false);

    const [showFeedbackBox, setShowFeedbackBox] = useState(false);

    const [isStreaming, setIsStreaming] = useState(true);


    const locale = 'en';

    const toggle = () => setShowMenu(!showMenu)

    const toggleWaitlistModal = () => setShowJoinWaitlistModal(!showJoinWaitlistModal)

    const toggleCardlistModal = () => setShowJoinCardlistModal(!showJoinCardlistModal)

    const [data, setData] = useState({
        response: {},
        loading: accountReducer.accounts?.body || localStorage.getItem("channelId") != null ? false : true
    })

    const { previousStates, currentState } = state

    var startTime = null;

    var endTime = null;

    const timer = useRef(null);

    const isAlreadyOnWaitlist = localStorage.getItem("isAlreadyOnWaitlist")

    const [showWaitListLoader, setShowWaitListLoader] = useState(false);

    const [logoutLoading, setLogoutLoading] = useState(false)


    const logoutSubmit = () => {
        setLogoutLoading(true)
        dispatch({ type: "LOGOUT" })
        localStorage.clear();
        // history.push('/login')
    }

    useEffect(() => {
        if (!audienceType) {
            fetcher('public/getAudienceType', localStorage.getItem("token")).then(response => {
                if (!response.status) {
                    setAudienceType("Normal");
                }
                return response;
            })
                .then(data => {
                    setAudienceType(data?.data?.body?.type ?? "Normal");
                    localStorage.setItem("audienceType", data?.data?.body?.type ?? "Normal");
                })
                .catch(error => {
                    setAudienceType("Normal");
                })
        }
        if (!channelId) {
            getAccountRequested(token);
        }
    }, [channelId, getAccountRequested, token, audienceType]);

    const handleSubmitForm = (e) => {

        let promptInput = inputRef.current.value;

        e.preventDefault()
        if (!socket || socket.disconnected || !socket.connected) {
            connectToSocket();
        }
        if(socket){
            sendMessageToSocket(promptInput, socket);
        }
    }

    const sendMessageToSocket = (promptInput, socket, item = "") => {
        dispatchGpt({ type: ACTIONS.CALL_API, response: null, prompt: promptInput })
        setIsStreaming(true);
        const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
        const msg = item !== "" ? promptInput + " - " + item : promptInput;
        const newMessage = { uuid: channelId, token: token, message: msg, time: time, prompt: promptInput, chat_id: chatId };
        socket.emit("sendMessage", newMessage);
        sendEventsCall("ma_send_prompt_first_time", {
            prompt_type: "first",
            selected_interface_screen: "chat-dashboard",
            selected_interface: "N/A",
            category: "N/A",
            prompt: promptInput ?? "N/A"
        })
        startTime = performance.now();

        inputRef.current.value = ""

        timer.current = setTimeout(() => {
            dispatchGpt({ type: ACTIONS.ERROR, response: `${t('pureai_something_went_wrong')}<br />${t('pureai_submit_feedback_para')}`, prompt: promptInput })
            sendEventsCall("ma_fail_load_prompt_response", {
                prompt: promptInput,
                response: 'Something went wrong, please try again later or provide more context so that we can better answer your queries.<br />Share your feedback with us so we can improve by clicking "Submit Feedback" on the bottom right',
                error: 'Timeout Occurred'
            });
            setResIsPending(false)
            setFormStateDisbaled(false)
        }, 240*1000);

        // LOADING STATE FOR API
        setFormStateDisbaled(true)
        setResIsPending(true)
        setTimeout(() => {
            handleScrollButtonClick()
        }, 10);
    }

    const sendEventsCall = (event_name = "", event_properties = {}) => {
        sendEvents({
            event_name,
            event_properties
        }, token)
    }

    const handlePropmt = (prompt) => {
        inputRef.current.value = prompt
        inputRef.current.focus();
        setFormStateDisbaled(false)
        sendEventsCall("ma_click_prompt", {
            prompt_type: "recommended",
            selected_interface_screen: "chat-dashboard",
            selected_interface: "N/A",
            category: "N/A",
            prompt: prompt ?? "N/A"
        })

    }

    const hideChat = () => {
        if (document.querySelector(".intercom-launcher") != null) {
            document.querySelector(".intercom-launcher").remove();
        }
    }

    const [hiddenIndices, setHiddenIndices] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);

    const hideReaction = (rating, index, data) => {
        if (!hiddenIndices.includes(index)) {
            sendEventsCall("ma_rate_response", {
                rating,
                prompt: data.prompt,
                response: data.response,
                // change:true,
            })
        }
        const newHiddenIndices = [...hiddenIndices, index];
        setHiddenIndices(newHiddenIndices);
        const newSelectedRatings = [...selectedRatings];
        newSelectedRatings[index] = rating;
        setSelectedRatings(newSelectedRatings);
    };

    const triggerPlatformCTA = (data, platform, topic) => {
        sendEventsCall("ma_pureai_click_suggesstion_cta", {
            suggestion_type: 'platform',
            suggestion: platform,
            topic: topic
        });
        if (!socket || socket.disconnected || !socket.connected) {
            connectToSocket();
        }
        if(socket){
            sendMessageToSocket(data.prompt, socket, platform);
        }
    };

    const triggerSuggestionCTA = (item, index) => {
        sendEventsCall("ma_pureai_click_suggesstion_cta", {
            suggestion_type: 'product',
            topic: index,
            suggestion: item.name
        });
        window.open(item.link, '_blank', 'noreferrer');
    };

    const returnSuggestionCTAs = (data) => {
        if(Object.keys(data?.cta).length > 0){
            return Object.keys(data?.cta).map((item) => {
                return data?.cta[item].map((ctaItem, ctaIndex) => {
                    return (
                        <div onClick={() => triggerSuggestionCTA(ctaItem, item)} className='sub-response-sub-div-ctas-item'>{ctaItem.cta}</div>
                    )
                })
            })
        }
    }

    const returnResponseText = (data, index, isHidden) => {
        const html = data?.response ? data?.response+'<div class="cursor" />' : "<span>No response available. Please try again</span>";
        return (
            <>
                <div className='sub-response-sub-div' >
                    <p className={`response-text`} ><div className="avatar avatarSize"></div>
                        {
                            <InnerHTML html={html} className='completion-response' />
                        }
                    </p>
                    <span className={'reactionArea'}>
                        <span className={`reaction up ${selectedRatings[index] === "like" ? "active mx-0" : selectedRatings[index] === "dislike" ? "d-none" : ""}`} onClick={() => hideReaction("like", index, data)} />
                        <span className={`reaction down ${selectedRatings[index] === "dislike" ? "active" : selectedRatings[index] === "like" ? "d-none" : ""}`} onClick={() => hideReaction("dislike", index, data)} />
                    </span>
                    <div className='sub-response-sub-div-ctas'>
                        {
                            (Object.keys(data?.platformSpecificAnswers).length > 0) ? Object.entries(data?.platformSpecificAnswers).map((item) => {
                                const [topic, platform] = item[0].split("---");
                                return (
                                    <div onClick={() => triggerPlatformCTA(data, platform, topic)} className='sub-response-sub-div-ctas-item'>{platform}</div>
                                )
                            }) : null
                        }
                        {
                            returnSuggestionCTAs(data)
                        }
                    </div>
                </div>
            </>
        )
    }

    const renderAllResponses = (data) => {

        return data.map(((item, index) => {
            const isHidden = hiddenIndices.includes(index);
            return (

                <div className='prompt-response-view'>
                    <div className='sub-prompt'>
                        <h6 className='prompt-text'><AvatarDiv className='avatarSize'>{userEmail ?? "?"}</AvatarDiv>{item.prompt}</h6>
                    </div>
                    <div className='sub-response'>
                        {item.loading ?
                            <p className='response-text loading pb-3'><div className="avatar avatarSize"></div>{t('pureai_thinking_text')}<TextLoader /></p>
                            :
                            item.error ? <div className='sub-response-sub-div'><p className={`response-text ${isHidden && "pb-3"}`} ><div className="avatar avatarSize"></div><InnerHTML html={item.response} className='completion-response' /></p> </div> :
                                returnResponseText(item, index, isHidden)
                        }
                    </div>

                </div>)
        }))
    }

    const typePrompt = (prompt_text) => {
        if (showMenu) {
            toggle()
        }
        inputRef.current.value = prompt_text
        inputRef.current.focus();
        if (prompt_text.length > 0) {
            setFormStateDisbaled(false)
        }
    }

    const newChatAction = () => {
        dispatchGpt({ type: ACTIONS.RESET })
        setShowScrollButton(false)
        setHiddenIndices([])
        setSelectedRatings([])
        setChatId(uuid())
        setSocket(null);
        handleStopGenerating();
    }

    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleScroll = () => {
        const responseDisplay = responseDisplayRef.current;
        if (responseDisplay?.scrollHeight - responseDisplay?.scrollTop === responseDisplay?.clientHeight) {
            setShowScrollButton(false);
        } else {
            setShowScrollButton(true);
        }
    };

    const handleScrollButtonClick = () => {
        const responseDisplay = responseDisplayRef.current;
        if (responseDisplay) {
            responseDisplay.scrollTop = responseDisplay.scrollHeight * 1000;
        }
        setShowScrollButton(false);
    };

    const handleAutoScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = responseDisplayRef.current;
        const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;

        // Disable autoscroll if user manually scrolls up
        shouldAutoScrollRef.current = isScrolledToBottom;
    }

    const removeCursor = () => {
        const cursors = document.querySelectorAll('.cursor');

        cursors.forEach(cursor => {
            cursor.remove();
        });
    }

    const handleStopGenerating = (message) => {
        let newSocket = socket;

        if(!newSocket){
            newSocket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
                query: { uuid: channelId, token: token, user: "Member Area", chatId },
            });
        }

        const prompt = message?.prompt ?? currentState.prompt;
        const response = message?.message ?? currentState.response;

        dispatchGpt({ type: ACTIONS.STREAMING_ENDED })

        removeCursor();

        newSocket.emit('stopStreaming')

        setIsStreaming(false)
        setFormStateDisbaled(false)
        setResIsPending(false)

        endTime = performance.now();

        if(!getCookie("ratingPopupShown") && getModulus()){
            setShowFeedbackBox(true)
        }
        updateUserInteraction();
        sendEventsCall("ma_recieve_response_first_time",{
            prompt: prompt,
            response:response,
            response_time: ((endTime-startTime)/1000).toFixed(2) + " seconds"
        })
    };

    const handleResize = () => {
        if (window.innerWidth <= 820) {
            setPlaceholderText(t('pureai_placeholder_two'));
            if (window.innerWidth < 650) {
                setSuggestedPrompts(suggestedPrompts);
            }
        }
        else {
            setPlaceholderText(t('pureai_placeholder_two'));
            setSuggestedPrompts(suggestedPrompts)
            if (showMenu) {
                setShowMenu(false)
            }
        }
    }
    
    const isUserTyping = () => {
        if (inputRef?.current?.value?.length != 0 && !resIsPending) {
            setFormStateDisbaled(false)
        } else {
            setFormStateDisbaled(true)
        }
    }
    const updateChannelId = (uuid) => {
        setChannelId(uuid);
        localStorage.setItem("channelId", uuid);
    }

    useEffect(() => {
        const { loading: reduxLoading, accounts, errors, errorType, errorMessage } = accountReducer;
        if (accounts?.body && channelId === null) {
            setData({
                ...data,
                response: accounts.body,
                loading: false
            });
            updateChannelId(accounts.body.code);
        }
        if (errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({ type: "LOGOUT" })

                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({ type: "LOGOUT" })

                    break;
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({ type: "LOGOUT" })

                    break;
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({ type: "LOGOUT" })

                    break;
                case "APIFAILED":
                    // toast.error(APIFAILED)
                    setCrashed(true)
                    break;
                default:
                    // toast.error(APIFAILED);
                    setCrashed(true)
                    break;
            }
        }
    }, [accountReducer])

    useEffect(() => {
        if (channelId)
            connectToSocket();
    }, [channelId]);

    useEffect(() => {
        inputRef?.current?.focus()
        const responseDisplay = responseDisplayRef.current;
        if (!onceRender.current) {
            setTimeout(hideChat, 1000);
            onceRender.current = true;
            let page_visit_prop = {
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: utm_source ?? "PureAI",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page",
                audienceType: audienceType ?? "Normal"
            }
            sendEventsCall("ma_pagevisited", page_visit_prop)
        }
        // Check if the div is scrollable
        if (responseDisplay) {
            if (responseDisplay.scrollHeight > responseDisplay.clientHeight) {
                if(shouldAutoScrollRef?.current){
                    responseDisplay.scrollTop = responseDisplay.scrollHeight * 1000;
                } else {
                    setShowScrollButton(true);

                    // Add event listener to update the state when the user scrolls
                    responseDisplay.addEventListener('scroll', handleScroll);
                }
                return () => {
                    //  component unmounts
                    responseDisplay.removeEventListener('scroll', handleScroll);
                };
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [previousStates]);

    const menuModal = (openModalView = false) => {
        return <MenuModal typePrompt={typePrompt} sendEventsCall={sendEventsCall} toggle={toggle} modal={showMenu} openModalView={openModalView} newChatAction={newChatAction} previousStates={previousStates} iscloseHelloBar={closeHelloBar} setFormStateDisbaled={setFormStateDisbaled} setResIsPending={setResIsPending} reviewPopupToggle={{ showFeedbackBox, setShowFeedbackBox }} />
    }
    const [isQ1Active, setIsQ1Active] = useState(false);
    const [isQ2Active, setIsQ2Active] = useState(false);
    const Spacer = () => <div className={!isQ1Active ? 'line disabled' : 'line'}></div>;
    const handleCheckboxChange = () => {
        setIsQ1Active(false);
        checkboxesRef.current.forEach((item) => {
            if (item.checkbox.checked) {
                setIsQ1Active(true);
            }
        });
    };
    const checkboxesRef = useRef([
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) },
        { checkbox: useRef(null), label: useRef(null) }
    ]);
    const joinWaitListModal = (openModalView = false) => {
        return <WaitlistModal>
            <div>
                <Modal backdrop={openModalView} isOpen={showJoinWaitlistModal} toggle={toggleWaitlistModal} wrapClassName="waitlistWrapper">
                    <ModalHeader className='modal-header-waitlist' toggle={toggleWaitlistModal}>
                        <span className='question-header' disabled={!isQ1Active} >{t('pureai_question_one')}</span>
                        <Spacer />
                        <span className='question-header' disabled={!isQ2Active}>{t('pureai_question_two')}</span>
                    </ModalHeader>
                    <ModalBody>
                        <h3 className='modal-heading'>{t('pureai_submit_immediate_Access')}</h3>
                        <form onSubmit={!isQ1Active || !isQ2Active ? disblaeForm : clickJoinWaitList} className='searchBar'>
                            <span className='question'>{t('pureai_specific_motivations')}</span>
                            <div className='question-1-1 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[0].checkbox = el)} className='checkbox' name='q1-1[0]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[0].label = el)}>{t('pureai_dont_have_access_to_gpt')}</label>
                            </div>
                            <div className='question-1-2 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[1].checkbox = el)} className='checkbox' name='q1-1[1]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[1].label = el)}>{t('pureai_share_my_ip')}</label>
                            </div>
                            <div className='question-1-3 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[2].checkbox = el)} className='checkbox' name='q1-1[2]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[2].label = el)}>{t('pureai_finding_support')}</label>
                            </div>
                            <div className='question-1-4 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[3].checkbox = el)} className='checkbox' name='q1-1[3]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[3].label = el)}>{t('pureai_gamer_text')}</label>
                            </div>
                            <div className='question-1-5 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[4].checkbox = el)} className='checkbox' name='q1-1[4]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[4].label = el)}>{t('pureai_want-experience')}</label>
                            </div>
                            <div className='question-1-6 question-checkbox'>
                                <input type='checkbox' ref={(el) => (checkboxesRef.current[5].checkbox = el)} className='checkbox' name='q1-1[5]' onChange={handleCheckboxChange} />
                                <label className='checkbox-label' ref={(el) => (checkboxesRef.current[5].label = el)}>{t('pureai_other_text')}</label>
                            </div>
                            <span className='question'>{t('pureai_specific_feature')}</span>
                            <textarea ref={inputRef} name='waitlistuse' onInput={toggleQ2Active} placeholder={t('pureai_placeholder_three')}></textarea>
                            <button disabled={!isQ1Active || !isQ2Active} className="joinButton">{t('pureai_unlock_access')}</button>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        </WaitlistModal>
    }

    const joinCardListModal = (openModalView = false) => {
        return <CardlistModal>
            <div>
                <Modal backdrop={openModalView} isOpen={showJoinCardlistModal} toggle={toggleCardlistModal} wrapClassName="waitlistCardWrapper">
                    <ModalHeader className='modal-header-waitlist' toggle={toggleCardlistModal}>
                        <div className='en-ds-div'>
                            <span className='question-header' disabled={!cardInput1} >{t('pureai_question_one')}</span>
                            <Spacer />
                            <span className='question-header' disabled={!cardInput2}>{t('pureai_question_two')}</span>
                            <Spacer />
                            <span className='question-header' disabled={!cardInput3}>{t('pureai_question_three')}</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <h3 className='modal-heading'>{t('pureai_working_on_pluguin')}</h3>
                        <form onSubmit={!cardInput1 || !cardInput2 || !cardInput3 ? disblaeForm : clickJoinCardList} className='searchBar'>
                            <span className='question'>{t('pureai_why_are_you_interested')}</span>
                            <textarea name='cardQ1' value={cardInput1} onChange={event => setCardInput1(event.target.value)} placeholder='Problems I am currently facing is ...'></textarea>
                            <span className='question'>{t('pureai_plan_to_use_it')}</span>
                            <textarea name='cardQ2' value={cardInput2} onChange={event => setCardInput2(event.target.value)} placeholder='I would use it to solve ...'></textarea>
                            <span className='question'>{t('pureai_similar_product')}</span>
                            <textarea name='cardQ3' value={cardInput3} onChange={event => setCardInput3(event.target.value)} placeholder=''></textarea>
                            <div className='cardSubBtn'>
                                <button disabled={!cardInput1 || !cardInput2 || !cardInput3} className="joinButton">{t('submit_text')}</button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        </CardlistModal>
    }


    const toggleQ2Active = (e) => {
        setIsQ2Active(e.target.value)
    }

    const updateUserInteraction = () => {
        let inc = localStorage.getItem('incrementer');
    
        if (inc === null) {
            localStorage.setItem('incrementer', 1);  
         }
         else {
            var count = parseInt(inc,10);
            localStorage.setItem('incrementer', count + 1);  
        }
        // setIncementer(localStorage.getItem('incrementer'));
    }

    const getModulus = () => {
        let inc = localStorage.getItem('incrementer');
        if (inc === null) {
            return true;
        } else {
            var count = parseInt(inc,10);
            return count % 5 === 0 ? true : false;
        }
    }

    const connectToSocket = () => {
        const newSocket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
            query: { uuid: channelId, token: token, user: "Member Area", chatId }
        });
        newSocket.emit('joinRoom', { room: chatId });
        newSocket.on("response", (message) => {
            clearTimeout(timer.current);
            if (message.chat_id !== chatId || message.channel !== channelId) {
                return;
            }
            if (message.source === "socket" && !message.error) {
                if(message.event === 'chat_completion_response_generated') {
                    if(isStreaming) {
                    }
                    dispatchGpt({ type: ACTIONS.STREAMING, response: message.message, prompt: message.prompt, 
                        platformSpecificAnswers: message.platformSpecificAnswers, cta: message.cta, ref_link: message.ref_link })
                } else {
                    dispatchGpt({ type: ACTIONS.SUCCESS, response: message.message, prompt: message.prompt, 
                        platformSpecificAnswers: message.platformSpecificAnswers, cta: message.cta, ref_link: message.ref_link })
                    setFormStateDisbaled(false)
                    setResIsPending(false)
                    removeCursor();
                    endTime = performance.now();

                    if(!getCookie("ratingPopupShown") && getModulus()){
                        setShowFeedbackBox(true)
                    }
                    updateUserInteraction();
                    sendEventsCall("ma_recieve_response_first_time",{
                        prompt:message.prompt,
                        response:message.message,
                        response_time: ((endTime-startTime)/1000).toFixed(2) + " seconds"
                    })
                }
            }

            if (message.error) {
                dispatchGpt({ type: ACTIONS.ERROR, response: message.message, prompt: message.prompt })
                sendEventsCall("ma_fail_load_prompt_response", {
                    prompt: message.prompt,
                    response: message.message,
                    error: JSON.stringify(message.error)
                });
                setResIsPending(false)
                setFormStateDisbaled(false)
            }
        });
        
        setSocket(newSocket);

        newSocket.on("streamingCompleted", (payload) => {
            handleStopGenerating(payload);
        })

        return newSocket;
    }

    const showMenuAction = () => {
        toggle()
        sendEventsCall("ma_click_menu")
    }
    const disblaeForm = (e) => {
        e.preventDefault()
    }

    const clickJoinWaitList = (e) => {
        e.preventDefault();
        let reason = { query: {} };
        reason.query.Q1 = "What specific motivations or needs have led you to consider using PureAI?";
        reason.query.A1 = [];
        checkboxesRef.current.forEach((item) => {
            if (item?.checkbox?.checked) {
                reason['query'].A1.push(item.label.innerText);
            }
        });
        reason.query.Q2 = "Q2: What specific features or benefits of PureAI sparked your interest in wanting to use it?";
        reason.query.A2 = inputRef.current.value;

        setShowWaitListLoader(true);
        updateAudienceType("alpha");

        sendEvents({
            event_name: "ma_join_waitlist",
            event_properties: {
                "source": "Pure AI",
                "reason": reason
            }
        }, token)
        localStorage.setItem("isAlreadyOnWaitlist", true);
        setShowJoinWaitlistModal(false)
    }

    const updateAudienceType = async (type) => {
        poster('public/setAudienceType', qs.stringify({ type: type }), localStorage.getItem("token")).then(response => {
            if (!response.status) {
                setAudienceType("Normal");
            }
            return response;
        })
            .then(data => {
                setShowWaitListLoader(false);
                setAudienceType("Alpha");
                localStorage.setItem("audienceType", "Alpha");
            })
            .catch(error => {
                setShowWaitListLoader(false);
                setAudienceType("Normal");
            })
    }

    const clickWaitList = () => {
        sendEvents({
            event_name: "ma_click_join_waitlist",
            event_properties: {
                "source": "Pure AI"
            }
        }, token);
        setShowJoinWaitlistModal(true);
    }

    // Card List Post Data Start

    const clickJoinCardList = (e) => {
        e.preventDefault();
        let reason = { query: {} };
        reason.query.Q1 = "Q1: Why are you interested in using this feature ?";
        reason.query.A1 = cardInput1;
        reason.query.Q2 = "Q2: How do you plan to use it ?";
        reason.query.A2 = cardInput2;
        reason.query.Q3 = "Q3: Are you using any similar product or tool ?";
        reason.query.A3 = cardInput3;

        // updateAudienceType("alpha");

        sendEvents({
            event_name: "ma_submit_tool_usage_form",
            event_properties: {
                "source": "Pure AI",
                "reason": reason,
                "tool": currentTool
            }
        }, token)
        // localStorage.setItem("isAlreadyOnWaitlist", true);
        setShowJoinCardlistModal(false)
    }

    const clickCardModal = (tool) => {
        sendEvents({
            event_name: "ma_click_tool",
            event_properties: {
                "source": "Pure AI",
                "tool": tool
            }
        }, token)
        setCardInput1('');
        setCardInput2('');
        setCardInput3('');
        setCurrentTool(tool);
        setShowJoinCardlistModal(true);
    }

    // Card List Post Data End



    if (crashed) {
        return <ErrorBoundary logoutSubmit={logoutSubmit}></ErrorBoundary>
    }
    return (
        audienceType === "Normal" ?
            (
                !audienceType || showWaitListLoader ? <Loader isPadding={true} /> :
                    <PureBuddyView isDisable={formStateDisable} iscloseHelloBar={closeHelloBar} chatView={previousStates.length} >
                        <div className='content-display-waitlist'>
                            <div className='waitListPanel'>
                                <div className='headingDiv'>
                                    <span className='pure-ai-logo'><img src={PureAILogo} alt='Pure AI' /></span>
                                    <span className='heading'><h1><strong>Pure</strong>AI</h1></span>
                                </div>
                                <p>{t('pureai_para_one')}</p>
                                <div className='errorBox fpErrorBox'>
                                    <span className='videoContainer'>
                                        <video autoPlay loop muted playsInline src={fpCrashed} className='videoPlay'></video>
                                        <span className='alphaTag'>{t('pureai_alpha')}</span>
                                    </span>
                                    <div className='mt-5 waitListText'>
                                        {
                                            !isAlreadyOnWaitlist ?
                                                (
                                                    <>
                                                        <span>
                                                            <h2>{t('pureai_early_access_text')}</h2>
                                                        </span>
                                                        <span>
                                                            <button onClick={clickWaitList}>{t('pureai_unlock_access')}</button>
                                                        </span>
                                                    </>
                                                ) :
                                                (
                                                    <span><h2>{t('pureai_on_the_list_text')}</h2></span>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='waitListEarlyAccess'>
                                <div className='w-content-div'>
                                    <div className='w-content-text'>
                                        <h3>{t('pureai_privacy_and_anonymity')}</h3>
                                        <span>{t('pureai_privacy_and_anonymity_para')}</span>
                                    </div>
                                    <div className='w-content-img'><img src={aiLogoVector}></img></div>
                                </div>
                                <div className='w-content-div'>
                                    <div className='w-content-text'>
                                        <h3>{t('pureai_watch_any_show')}</h3>
                                        <span>{t('pureai_watch_any_show_para')}</span>
                                    </div>
                                    <div className='w-content-img'><img src={tvVector}></img></div>
                                </div>
                                <div className='w-content-div'>
                                    <div className='w-content-text'>
                                        <h3>{t('pureai_buy_cheap_text')}</h3>
                                        <span>{t('pureai_buy_cheap_para')}</span>
                                    </div>
                                    <div className='w-content-img'><img src={gameVector}></img></div>
                                </div>
                            </div>
                        </div>
                        {
                            showJoinWaitlistModal && joinWaitListModal(true)
                        }
                    </PureBuddyView>
            ) : (
                subscriptionReducer.loading || data.loading || !audienceType ? <Loader isPadding={true} /> : <div className='d-flex buddyView'>
                    <PureBuddyView isDisable={formStateDisable} iscloseHelloBar={closeHelloBar} chatView={previousStates.length} >
                        {previousStates.length ?
                            <div className='response-display' ref={responseDisplayRef} onScroll={handleAutoScroll}>
                                {renderAllResponses(previousStates)}
                            </div> :
                            <div className='content-display'>
                                <div className='headingDiv'>
                                    <span className='pure-ai-logo'><img src={PureAILogo} alt='Pure AI' /></span>
                                    <span className='heading'><h1>PureAI</h1><span>{t('pureai_alpha')}</span></span>
                                </div>
                                <p className='tagline'>{t('pureai_ultimate_discovery_companion')}</p>
                                <div className="grid-container">

                                    <div className='d-flex flex-column'>
                                        <div className='grid-first-div'>
                                            <h3 className='grid-heading'>{t('pureai_streaming')}</h3>
                                            <p className='grid-heading-desc'>{t('pureai_prompt_key_for_gaming')}</p>
                                        </div>
                                        {suggestedPromptsValue.map((prompt, index) => {
                                            if (index <= 2) {
                                                return <div className='row p-2 justify-content-center'>
                                                    <span className="grid-item col-12" key={index} onClick={() => handlePropmt(prompt)}>{prompt}</span>
                                                </div>
                                            }
                                        })}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <div className='grid-first-div'>
                                            <h3 className='grid-heading'>{t('pureai_security')}</h3>
                                            <p className='grid-heading-desc'>{t('pureai_need_help')}</p>
                                        </div>
                                        {suggestedPromptsValue.map((prompt, index) => {
                                            if (index > 2 && index <= 5) {
                                                return <div className='row p-2 justify-content-center'>
                                                    <span className="grid-item col-12" key={index} onClick={() => handlePropmt(prompt)}>{prompt}</span>
                                                </div>
                                            }
                                        })}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <div className='grid-first-div'>
                                            <h3 className='grid-heading'>{t('pureai_ask_purevpn')}</h3>
                                            <p className='grid-heading-desc'>{t('pureai_quick_answers')}
                                            </p>
                                        </div>
                                        {suggestedPromptsValue.map((prompt, index) => {
                                            if (index > 5) {
                                                return <div className='row p-2 justify-content-center'>
                                                    <span className="grid-item col-12" key={index} onClick={() => handlePropmt(prompt)}>{prompt}</span>
                                                </div>
                                            }
                                        })}
                                    </div>

                                </div>
                            </div>}
                        <div className='bottomMenu'>
                            <form onSubmit={formStateDisable ? disblaeForm : handleSubmitForm} className='searchBar'>
                                <InputBox ref={inputRef} type="textarea" placeholder={placeholderText} onChange={isUserTyping} />
                                <img onClick={formStateDisable ? disblaeForm : handleSubmitForm} src={formStateDisable ? DisabledEnterIcon : EnterIcon} />
                            </form>
                            <span className='menu-icon' onClick={showMenuAction} />
                            {/* DropDown - for v2 */}
                            {/* <DropDownMenu toggle={toggle}>
                        <span className='menu-icon'/> 
                    </DropDownMenu> */}
                            {showScrollButton && <ScrollButton onClick={handleScrollButtonClick} />}
                            { currentState.streaming && currentState.showStopGenerating && <StopGeneratingBtn onClick={handleStopGenerating}>{t('pureai_stop_generating_text')}</StopGeneratingBtn>}
                        </div>
                        {
                            showMenu && menuModal(true) ||
                            showJoinCardlistModal && joinCardListModal(true)
                        }
                    </PureBuddyView>
                    {<SideCardBuddyView chatView={!previousStates.length}>
                        <div className='puretools'>
                            <h2>{t('pureai_plugins_text')}</h2>
                        </div>
                        <div className="card-container">
                            <div className="card-item" onClick={() => clickCardModal('youtube')}>
                                <div className='firstdiv'>
                                    <img src={YouTubeIcon} alt='card-icon' className='cardIcon'></img>
                                    <h3 className='cardHeading'>{t('pureai_youtube_summarizer')}</h3>
                                </div>
                                <div>
                                    <p className='cardDesc'>{t('pureai_youtube_summarizer_para')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-container">
                            <div className="card-item" onClick={() => clickCardModal('doc-editor')}>
                                <div className='firstdiv'>
                                    <img src={DocumentIcon} alt='card-icon' className='cardIcon'></img>
                                    <h3 className='cardHeading'>{t('pureai_chatgpt_for_your_data')}</h3>
                                </div>
                                <div>
                                    <p className='cardDesc'>{t('pureai_chatgpt_for_your_data_para')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-container">
                            <div className="card-item" onClick={() => clickCardModal('link-account')}>
                                <div className='firstdiv'>
                                    <img src={AccountIcon} alt='card-icon' className='cardIcon'></img>
                                    <h3 className='cardHeading'>{t('pureai_app_connector')}</h3>
                                </div>
                                <div>
                                    <p className='cardDesc'>{t('pureai_app_connector_para')}</p>
                                </div>
                            </div>
                        </div>
                    </SideCardBuddyView>}
                    {<SideBuddyView chatView={previousStates.length}>{menuModal()}</SideBuddyView>}
                </div>
            )
    )
}
const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptionReducer: state.subscriptionsReducer
    };
};
export default connect(mapStateToProps, { getAccountRequested, subscriptionRequested })(PureBuddy)