export const purecrypt = {
    en:{
        heading_1:'PureEncrypt - File Encryption',
        para_1:     'PureEncrypt encrypts your data and lets you safeguard it in vaults that can then be uploaded and synced with cloud storage, ensuring complete security and privacy.',
        heading_2:     'Benefits of PureEncrypt',
        para_2:"",
        desc:[
            '<strong>Secure file encryption </strong> Encrypting your data ensures that only you have the ability to access it, preventing anyone else from viewing it. Not only can you secure your files, but also secure your personal photos and videos.',
            '<strong>Avoid having your personal information gathered and sold to third parties </strong> Your personal information is yours to keep. That implies that you\'re the only one who can access and view what\'s on your device, and we don\'t store anything.',
            '<strong>Get safe from profiling via your data by advertisers </strong> Avoid unnecessary advertisements, and access the web without unnecessary interruptions to stay safe from advertisers.',
            '<strong>Access and control your data securely </strong> Use password-protected vaults to enable secure access to all your files and folders. You have the ability to sort, organize, and manage files and directories. Log in to multiple devices and securely access everything you need.',
            '<strong>Protection wherever you go, at all times </strong> Securely create, view, and access your vaults from anywhere, at any time.Experience seamless data protection while you work remotely and protect yourself from all sorts of data breaches.' 
        ],
        cta:"Get this Add-on",
    },
    fr:{
      heading_1:"PureEncrypt - Chiffrement de fichiers",
      para_1:"PureEncrypt crypte vos données et vous permet de les sauvegarder dans des coffres-forts qui peuvent ensuite être téléchargés et synchronisés avec le cloud stockage, garantissant une sécurité et une confidentialité complètes.",
      heading_2:"Avantages de PureEncrypt",
      para_2:"",
      desc:[
        `<strong>Cryptage sécurisé des fichiers </strong> Le cryptage de vos données garantit que vous seul avez la possibilité d'y accéder, empêchant quiconque de les consulter. Vous pouvez non seulement sécuriser vos fichiers, mais également sécuriser vos photos et vidéos personnelles. `,
        `<strong>Évitez que vos informations personnelles ne soient recueillies et vendues à des tiers </strong> Vos informations personnelles vous appartiennent. Cela implique que vous êtes le seul à pouvoir accéder et voir ce qui se trouve sur votre appareil, et nous ne stockons rien. `,
        `<strong>Protégez-vous du profilage via vos données par les annonceurs </strong> Évitez les publicités inutiles et accédez au Web sans interruptions inutiles pour rester à l'abri des annonceurs. `,
        `<strong>Accédez à vos données et contrôlez-les en toute sécurité  </strong> Utilisez des coffres-forts protégés par mot de passe pour permettre un accès sécurisé à tous vos fichiers et dossiers. Vous avez la possibilité de trier, d'organiser et de gérer des fichiers et des répertoires. Connectez-vous à plusieurs appareils et accédez en toute sécurité à tout ce dont vous avez besoin. `,
        `<strong>Une protection où que vous alliez, à tout moment </strong> Créez, affichez et accédez en toute sécurité à vos coffres-forts de n'importe où, à tout moment. Bénéficiez d'une protection des données transparente pendant que vous travaillez à distance et protégez-vous contre toutes sortes de violations de données. `,
      ],
      cta:"Obtenez ce module complémentaire",
    },
    de:{
      heading_1:"PureEncrypt - Dateiverschlüsselung",
      para_1:"PureEncrypt verschlüsselt Ihre Daten und ermöglicht es Ihnen, sie in Tresoren zu sichern, die dann hochgeladen und mit dem Cloud-Speicher synchronisiert werden können, um vollständige Sicherheit und Datenschutz zu gewährleisten.",
      heading_2:"Vorteile von PureEncrypt",
      para_2:"",
      desc:[
        `<strong>Sichere Dateiverschlüsselung</strong>  Die Verschlüsselung Ihrer Daten stellt sicher, dass nur Sie darauf zugreifen können, und verhindert, dass jemand anderes sie einsehen kann. Sie können nicht nur Ihre Dateien, sondern auch Ihre persönlichen Fotos und Videos sichern.`,
        `<strong>Vermeiden Sie es, dass Ihre persönlichen Daten gesammelt und an Dritte verkauft werden</strong>    Ihre persönlichen Daten gehören Ihnen. Das bedeutet, dass Sie der einzige sind, der auf die Inhalte Ihres Geräts zugreifen und diese anzeigen kann, und wir speichern nichts.`,
        `<strong>Schützen Sie sich vor der Profilerstellung anhand Ihrer Daten durch Werbetreibende. </strong> Vermeiden Sie unnötige Werbung und greifen Sie ohne unnötige Unterbrechungen auf das Internet zu, um vor Werbetreibenden sicher zu sein.`,
        `<strong>Greifen Sie sicher auf Ihre Daten zu und kontrollieren Sie sie</strong> Verwenden Sie passwortgeschützte Tresore, um einen sicheren Zugriff auf alle Ihre Dateien und Ordner zu ermöglichen. Sie haben die Möglichkeit, Dateien und Verzeichnisse zu sortieren, zu organisieren und zu verwalten. Melden Sie sich bei mehreren Geräten an und greifen Sie sicher auf alles zu.`,
        `<strong>Schutz überall und jederzeit </strong> Erstellen, sehen und greifen Sie jederzeit und überall sicher auf Ihre Tresore zu. Erleben Sie nahtlosen Datenschutz, während Sie remote arbeiten, und schützen Sie sich vor allen Arten von Datenschutzverletzungen.`,
      ],
      cta:"Holen Sie sich dieses Add-on",
    },
    zh:{
      heading_1:"PureEncrypt - 文件加密",
      para_1:"PureEncrypt 加密您的數據並讓您將其保存在保險庫中，這些數據可以上傳並同步與雲存儲，從而確保完全的安全性和隱私性。",
      heading_2:"PureEncrypt 的好處",
      para_2:"",
      desc:[
        `<strong>安全文件加密 </strong>  加密您的數據可確保只有您能夠訪問它，防止其他任何人查看。您不僅可以保護您的文件，還可以保護您的個人照片和視頻。`,
        `<strong>避免收集您的個人信息並將其出售給第三方 </strong>  您的個人信息歸您所有。這意味著您是唯一可以訪問和查看您裝置上內容的人，我們不存儲任何內容。`,
        `<strong>避免廣告商通過您的數據進行分析 </strong>  避免煩人的廣告，並在沒有不必要的中斷的情況下訪問網絡，以確保免受廣告商的侵害。`,
        `<strong>安全地訪問和控制您的數據</strong>  使用受密碼保護的保管庫來實現對所有文件和文件夾的安全訪問。您可以對文件和目錄進行排序、組織和管理。登入多台裝置並安全地訪問您需要的一切。`,
        `<strong>隨時隨地保護 </strong>  隨時隨地安全地創建、查看和訪問您的保管庫。在遠程工作時體驗無縫的數據保護，並保護自己免受各種數據洩露。`,
      ],
      cta:"獲取此插件",
    },
 
    es:{
        heading_1:"PureEncrypt - Cifrado de archivos",
        para_1:"PureEncrypt cifra sus datos y le permite salvaguardarlos en bóvedas que luego pueden cargarse y sincronizarse con el almacenamiento en la nube, asegurando la seguridad y la privacidad completa.",
        heading_2:"Beneficios de PureEncrypt",
      para_2:"",
      desc:[
        "<strong>Cifrado de archivo seguro</strong> encriptar sus datos asegura que solo usted tenga la capacidad de acceder a él, evitando que cualquier otra persona lo vea. No solo puede asegurar sus archivos, sino también asegurar sus fotos y videos personales.",
        "<strong>Evite que se recopile y venda su información personal a terceros</strong> su información personal es suya para mantener. Eso implica que usted es el único que puede acceder y ver lo que hay en su dispositivo, y no almacenamos nada.",
        "<strong>Asegúrese de perfilar a través de sus datos por parte de los anunciantes</strong> evite anuncios innecesarios y acceda a la web sin interrupciones innecesarias para mantenerse a salvo de los anunciantes.",
        "<strong>cceda y controle sus datos de forma segura</strong> use bóvedas protegidas con contraseña para habilitar el acceso seguro a todos sus archivos y carpetas. Tiene la capacidad de clasificar, organizar y administrar archivos y directorios. Inicie sesión en múltiples dispositivos y acceda de forma segura a todo lo que necesita.",
        "<strong>Protección donde quiera que vaya en todo momento </strong> cree, vea y accede de forma segura a sus bóvedas desde cualquier lugar, en cualquier momento. Experiencia Protección de datos sin problemas mientras trabaja de forma remota y se protege de todo tipo de violaciones de datos.",
    ],
      cta:"Obtenga este complemento",
    },
   
 
    ru:{
      heading_1:    "PureEncrypt - шифрование файла",
      para_1:    "PureEncrypt шифрует ваши данные и позволяет защитить их в хранилищах, которые затем можно загрузить и синхронизировать с облачным хранилищем, обеспечивая полную безопасность и конфиденциальность.",
      heading_2:    "Преимущества PureEncrypt",
      para_2:"",
      desc:[
        "<strong> Безопасное шифрование файлов</strong> шифрование ваших данных гарантирует, что только у вас есть возможность получить к ним доступ, не позволяя кому-либо просмотреть их. Вы можете не только обеспечить свои файлы, но и обеспечить свои личные фотографии и видео.",
        "<strong> Избегайте собранной и проданной вашей личной информации третьим лицам</strong> ваша личная информация вам не подходит. Это подразумевает, что вы единственный, кто может получить доступ и просматривать то, что находится на вашем устройстве, и мы ничего не храним.",
        "<strong> Получите безопасность от профилирования через ваши данные рекламодателями</strong> избегайте ненужных рекламных объявлений и получайте доступ к Интернету без ненужных перерывов, чтобы оставаться в безопасности от рекламодателей.",
        "<strong>Доступ и управляйте вашими данными безопасно </strong> используйте хранилища, защищенные паролем, чтобы обеспечить безопасный доступ ко всем вашим файлам и папкам. У вас есть возможность сортировать, организовывать и управлять файлами и каталогами. Войдите на несколько устройств и надежно получить доступ к всем необходимым.",
        "<strong> Защита, куда бы вы ни пошли, всегда</strong> надежно создавать, просматривать и получить доступ к своим хранилищам из любого места, в любое время. Экспертика бесшовная защита данных, пока вы работаете удаленно и защищаете себя от всех видов нарушений данных.",
      ],
      cta:"Получите это дополнение",
    },

 
    tr:{
      heading_1:    "PureEncrypt - Dosya Şifrelemesi",
      para_1:    "PureEncrypt verilerinizi şifreler ve daha sonra yüklenebilen ve bulut depolama ile senkronize edilebilen ve tam güvenlik ve gizlilik sağlayabilen tonozlarda korunmanıza olanak tanır.",
      heading_2:    "PureEncrypt'in faydaları",
      para_2:"",
      desc:[
        "<strong> Güvenli dosya şifrelemesi-</strong> Verilerinizi şifrelemek, yalnızca ona erişme yeteneğine sahip olmanızı sağlar ve başkalarının görüntülemesini önler. Sadece dosyalarınızı güvence altına almakla kalmaz, aynı zamanda kişisel fotoğraflarınızı ve videolarınızı da güvence altına alırsınız.",
        "<strong> Kişisel bilgilerinizin üçüncü taraflara toplanması ve satılmasından kaçının</strong>  Kişisel bilgileriniz sizindir. Bu, cihazınızda olanlara erişebilen ve görüntüleyebilen tek kişi olduğunuzu ve hiçbir şeyi saklamadığımız anlamına gelir.",
        "<strong> Reklamverenler tarafından verileriniz aracılığıyla profillemeden korunun</strong>  gereksiz reklamlardan kaçının ve reklamverenlerden korumak için gereksiz kesintiler olmadan web'e erişin.",
        "<strong>Verilerinize güvenli bir şekilde erişin ve kontrol edin</strong> Tüm dosyalarınıza ve klasörlerinize güvenli erişim sağlamak için şifre korumalı tonozları kullanın. Dosyaları ve dizinleri sıralama, düzenleme ve yönetme yeteneğine sahipsiniz. Birden çok cihazda oturum açın ve ihtiyacınız olan her şeye güvenli bir şekilde erişin.",
        "<strong> Nereye giderseniz gidin, her zaman koruma</strong>  tonozlarınızı herhangi bir yerden güvenli bir şekilde yaratın, görüntüleyin ve erişin.",
      ],
      cta:"Bu eklentiyi alın",
    },
 
 
    pt:{
      heading_1:    "Purencrypt - Criptografia de arquivo",
      para_1:    "O PureEncrypt criptografa seus dados e permite protegê -los em cofres que podem ser carregados e sincronizados com o armazenamento em nuvem, garantindo segurança e privacidade completas.",
      heading_2:    "Benefícios do PureEncrypt",
      para_2:"",
      desc:[
        "<strong> Criptografia de arquivo segura</strong> A criptografia dos seus dados garante que apenas você tenha a capacidade de acessá-lo, impedindo que alguém o visualize. Você não apenas pode proteger seus arquivos, mas também suas fotos e vídeos pessoais.",
        "<strong> Evite reunir suas informações pessoais e vendidas a terceiros</strong> suas informações pessoais são suas para manter. Isso implica que você é o único que pode acessar e visualizar o que está no seu dispositivo, e não armazenamos nada.",
        "<strong> Vá em protege com o perfil por meio de seus dados por anunciantes</strong> evite anúncios desnecessários e acesse a Web sem interrupções desnecessárias para manter a segurança dos anunciantes.",
        "<strong>Acesse e controle seus dados com segurança</strong>use cofres protegidos por senha para permitir acesso seguro a todos os seus arquivos e pastas. Você tem a capacidade de classificar, organizar e gerenciar arquivos e diretórios. Faça login em vários dispositivos e acesse com segurança tudo o que você precisa.",
        "<strong> Proteção onde quer que você vá, o tempo todo</strong> crie, crie, visualize e acesse com segurança seus cofres de qualquer lugar, a qualquer momento. Experiência de proteção de dados perfeita enquanto você trabalha remotamente e se protege de todos os tipos de violações de dados.",
      ],
      cta:"Obtenha este complemento",
    },
    ja:{
      heading_1: 'PureEncrypt-ファイル暗号化',
      para_1:     'PureEncryptはデータを暗号化し、クラウドストレージとアップロードして同期できる金庫に保護し、完全なセキュリティとプライバシーを確​​保できます。',
      heading_2:     'PureEncryptの利点',
      para_2:"",
      desc:[
        '<strong>セキュアファイル暗号化  </strong> データを暗号化することで、データのみがアクセスすることができ、他の人が表示されないようにします。ファイルを保護できるだけでなく、個人的な写真やビデオを保護できます。',
        '<strong>個人情報を収集して第三者に販売することを避けてください  </strong> あなたの個人情報はあなたのものです。それは、あなたがあなたのデバイスにアクセスして表示できるのはあなただけであり、私たちは何も保存しないことを意味します。',
        '<strong>広告主によるデータを介してプロファイリングを安全にする  </strong> 不必要な広告を避け、広告主から安全を保つために不必要な中断なしにWebにアクセスします。',
        '<strong>データに安全にアクセスして制御する  </strong> パスワードで保護されたボールトを使用して、すべてのファイルとフォルダーへの安全なアクセスを可能にします。ファイルとディレクトリを並べ替え、整理、管理する機能があります。複数のデバイスにログインし、必要なものをすべて安全にアクセスします。',
        '<strong>どこへ行っても </strong>いつでもどこへ行っても、いつでもどこからでも保管、表示、アクセスします。' 
      ],
      cta:"このアドオンを取得します",
    },
    it:{
        heading_1:'PureEncrypt - crittografia dei file',
        para_1:     'PureEncrypt crittografa i tuoi dati e ti consente di salvaguardarli in volte che possono quindi essere caricati e sincronizzati con l\'archiviazione cloud, garantendo la sicurezza e la privacy completa.',
        heading_2:     'Vantaggi di PureEncrypt',
        para_2:"",
        desc:[
            '<strong> Crittografia dei file protetti</strong>  Crittografia dei tuoi dati garantisce che solo tu abbia la possibilità di accedervi, impedendo a chiunque altro di visualizzarlo. Non solo puoi proteggere i tuoi file, ma anche proteggere le tue foto e video personali.',
            '<strong> Evita di raccogliere le tue informazioni personali e vendute a terzi</strong>  le tue informazioni personali sono tue da conservare. Ciò implica che sei l\'unico che può accedere e visualizzare ciò che è sul tuo dispositivo e non archiviamo nulla.',
            '<strong> Ottieni al sicuro dalla profilazione tramite i dati degli inserzionisti</strong>  Evita pubblicità inutili e accedi al Web senza interruzioni inutili per rimanere al sicuro dagli inserzionisti.',
            '<strong>Accesso e controlla in modo sicuro i dati</strong> Utilizza i Vault protetti da password per consentire l\'accesso sicuro a tutti i file e le cartelle. Hai la possibilità di ordinare, organizzare e gestire file e directory. Accedi a più dispositivi e accedi in modo sicuro a tutto ciò di cui hai bisogno.',
            '<strong> Protezione ovunque tu vada, in ogni momento</strong>  Crea, visualizza e accedi in modo sicuro le volte da qualsiasi luogo, in qualsiasi momento. Esperienza Protezione dati senza soluzione di continuità mentre lavori in remoto e proteggiti da ogni tipo di violazione dei dati.'
        ],
        cta:"Koop deze add-on",
      },
    nl:{
      heading_1: 'PureEncrypt - Bestandscodering',
      para_1:     'PureEncrypt codeert uw gegevens en laat u deze beschermen in kluizen die vervolgens kunnen worden geüpload en gesynchroniseerd met cloudopslag, waardoor volledige beveiliging en privacy worden gewaarborgd.',
      heading_2:     'Voordelen van PureEncrypt',
      para_2:"",
      desc:[
        '<strong> Secure File-codering</strong>  Het coderen van uw gegevens zorgt ervoor dat alleen u er toegang toe hebt, waardoor iemand anders het niet kan bekijken. U kunt niet alleen uw bestanden beveiligen, maar ook uw persoonlijke foto\'s en video\'s beveiligen.',
        '<strong> Vermijd dat uw persoonlijke informatie wordt verzameld en verkocht aan derden</strong>  uw persoonlijke informatie is van u om te bewaren. Dat houdt in dat u de enige bent die toegang heeft tot en te bekijken wat er op uw apparaat staat, en we slaan niets op.',
        '<strong> Beveilig voor profilering via uw gegevens door adverteerders</strong>  vermijd onnodige advertenties en toegang tot internet zonder onnodige onderbrekingen om veilig te blijven voor adverteerders.',
        '<strong>Toegang en controleer uw gegevens veilig</strong> gebruik wachtwoordbeveiligde kluizen om veilige toegang tot al uw bestanden en mappen mogelijk te maken. U kunt bestanden en mappen sorteren, organiseren en beheren. Log in op meerdere apparaten en toegang tot alles wat u nodig heeft.',
        '<strong> Bescherming waar u ook te allen tijde naartoe gaat</strong>  op elk gewenst moment veilig maken, bekijken en toegang krijgen tot uw kluizen, op elk gewenst moment.'
      ],
      cta:"Ottieni questo componente aggiuntivo",
    },
      ko:{
      heading_1:'PureEncrypt- 파일 암호화',
      para_1:     'PureEncrypt는 귀하의 데이터를 암호화하고 볼트로 데이터를 보호하여 클라우드 스토리지와 업로드 및 동기화하여 완전한 보안 및 개인 정보를 보장 할 수 있습니다.',
      heading_2:     'PureEncrypt의 이점',
      para_2:"",
      desc:[
        '<strong> 보안 파일 암호화 </strong>  데이터를 암호화하면 데이터 만 액세스 할 수있어 다른 사람이 보지 못하게합니다. 파일을 보호 할 수있을뿐만 아니라 개인 사진과 비디오를 보호 할 수 있습니다.',
        '<strong> 개인 정보를 수집하여 제 3 자에게 판매하지 마십시오 </strong>  귀하의 개인 정보는 귀하의 정보입니다. 그것은 당신이 당신의 장치에있는 것에 액세스하고 볼 수있는 유일한 사람이라는 것을 의미하며, 우리는 아무것도 저장하지 않습니다.',
        '<strong> 광고주의 데이터를 통해 프로파일 링을 안전하게 보호하십시오 </strong>  불필요한 광고를 피하고 불필요한 중단없이 웹에 액세스하여 광고주로부터 안전하게 유지하십시오.',
        '<strong>데이터에 대한 액세스 및 제어 </strong> 비밀번호로 보호 된 금고를 사용하여 모든 파일과 폴더에 안전하게 액세스 할 수 있습니다. 파일과 디렉토리를 정렬, 구성 및 관리 할 수 ​​있습니다. 여러 장치에 로그인하고 필요한 모든 것에 안전하게 액세스하십시오.',
        '<strong> 언제 어디서나 어디를 가든지 </strong>  언제 어디서나 볼트를 안전하게 생성,보기 및 액세스 할 수 있습니다.'
      ],
      cta:"이 애드온을 얻으십시오",
    },
  }