import { v4 } from 'uuid';

export const updatePreviousState = (previousState,updatedState) => {
    previousState.push(updatedState);
    return previousState.map((prevState, index) =>
          index === previousState.length - 1
            ? updatedState
            : { ...prevState, loading: false }
        );
}

export const pureAIReducer = (state = intialState, action) => {
    const persistentState = () => {
        return {
            ...intialState.currentState,
            chatId: action.chatId ?? state.currentState.chatId,
            channelId: state.currentState.channelId,
            email: state.currentState.email,
            audienceType: state.currentState.audienceType,
            promptTime: state.currentState.promptTime,
            messageId:v4()
        }
    }
    let updateAction = true;
    const newState = (state, action) => {
        switch (action.type) {
            case ACTIONS.ADD_CHANNEL_ID: {
                const updatedState = {
                    ...state.currentState,
                    channelId: action.channelId,
                    email: action.email
                };
                const previousStates = state.previousStates;
                return {
                    currentState: updatedState,
                    previousStates,
                }
            }
            case ACTIONS.NEW_CHAT: {
                const updatedState = persistentState();
                const previousStates = [];
                return {
                    currentState: updatedState,
                    previousStates,
                };
            }
            case ACTIONS.SEND_PROMPT: {
                if((state.currentState.prompt !== "" && state.currentState.response === "") || state.currentState.streaming) {
                    updateAction = false;
                    return state;
                } else {
                    let updatedState = {
                        ...state.currentState,
                        prompt: action.prompt,
                        promptType: action.promptType ?? "NONE",
                        platform: action.platform ?? "NONE",
                        promptTime: action.promptTime ?? new Date().getTime(),
                    };
                    let previousStates = state.previousStates;
                    if(state.currentState.response !== "") {
                        previousStates = updatePreviousState(state.previousStates,updatedState);
                        updatedState = persistentState();
                        updatedState.prompt = action.prompt;
                        updatedState.messageId = v4();
                    }
                    return {
                        currentState: updatedState,
                        previousStates,
                    }
                }
            }
            case ACTIONS.FAIL_LOAD_RESPONSE: {
                if(state.currentState.prompt === ""){
                    return state;
                }
                const updatedState = {
                    ...state.currentState,
                    response: action.response,
                    responseTime: action.responseTime ?? new Date().getTime(),
                    error:true,
                    errorType: action.errorType,
                    errorDetails: action.errorDetails ?? { code: 500, reason: "Something went wrong" },
                    source: ACTIONS.FAIL_LOAD_RESPONSE
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: persistentState(),
                    previousStates,
                };
            }
            case ACTIONS.RECV_RESPONSE: {
                if(state.currentState.prompt === ""){
                    updateAction = false;
                    return state;
                }
                const updatedState = {
                    ...state.currentState,
                    response: action.response,
                    responseTime: action.responseTime ?? new Date().getTime(),
                    source: ACTIONS.RECV_RESPONSE
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: persistentState(),
                    previousStates,
                };
            }
            case ACTIONS.STREAM_START: {
                if(state.currentState.prompt === ""){
                    updateAction = false;
                    return state;
                }

                const updatedState = {
                    ...state.currentState,
                    response: "",
                    streaming: true,
                    openAIPrompt: action.openAIPrompt,
                    responseTime: action.responseTime ?? new Date().getTime(),
                    cta: action.cta,
                    platformSpecificAnswers: action.platformSpecificAnswers
                };
                const previousStates = state.previousStates;
                return {
                    currentState: updatedState,
                    previousStates,
                }
            }
            case ACTIONS.RECV_STREAM: {
                if(state.currentState.prompt === ""){
                    updateAction = false;
                    return state;
                }

                if(state.currentState.streaming){
                    const updatedState = {
                        ...state.currentState,
                        response: (state.currentState.response + action?.response).replace(/```(.*?)```/g, '<code>$1</code>')
                    };
                    const previousStates = state.previousStates;
                    return {
                        currentState: updatedState,
                        previousStates,
                    }
                }
                return state;
            }
            case ACTIONS.STREAM_STOP:
            case ACTIONS.STREAM_COMPLETE: {
                if(state.currentState.prompt === ""){
                    updateAction = false;
                    return state;
                }

                const updatedState = {
                    ...state.currentState,
                    streaming: false,
                    streamingCompleteTime: new Date().getTime(),
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: persistentState(),
                    previousStates,
                };
            }
            case ACTIONS.FUNCTION_CALL: {
                if(state.currentState.prompt === ""){
                    updateAction = false;
                    return state;
                }

                const updatedState = {
                    ...state.currentState,
                    streaming: false,
                    streamingCompleteTime: new Date().getTime(),
                    function_call: action.response
                }
                return {
                    currentState: updatedState,
                    previousStates: state.previousStates
                }
            }
            case ACTIONS.CALL_API: {
                const updatedState = {
                    ...state.currentState,
                    loading: true
                };
                const previousStates = state.previousStates;
                return {
                    currentState: updatedState,
                    previousStates,
                }
            }
            case ACTIONS.AUDIENCE_TYPE: {
                const updatedState = {
                    ...state.currentState,
                    audienceType: action.audienceType ?? "Normal",
                    loading: false
                };
                const previousStates = state.previousStates;
                return {
                    currentState: updatedState,
                    previousStates,
                }
            }
            case ACTIONS.WAITLIST_MODAL: {
                const updatedState = {
                    ...state.currentState,
                    showWaitlistModal: action.visibility ? true : false
                };
                const previousStates = state.previousStates;
                return {
                    currentState: updatedState,
                    previousStates,
                }
            }
            case ACTIONS.SUCCESS: {
                const updatedState = {
                    ...state.currentState,
                    loading: false,
                    error: false
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: updatedState,
                    previousStates,
                };
            }
            case ACTIONS.CRASHED: {
                const updatedState = {
                    ...state.currentState,
                    loading: false,
                    crashed: true
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: updatedState,
                    previousStates,
                };
            }
            case ACTIONS.ERROR: {
                const updatedState = {
                    ...state.currentState,
                    loading: false,
                    error: action.error
                };
                const previousStates = updatePreviousState(state.previousStates,updatedState)
                return {
                    currentState: updatedState,
                    previousStates,
                };
            }
            case ACTIONS.ABLY_CONNECTED: {
                const updatedState = {
                    ...state.currentState,
                    connected: action.connected,
                    ably: action.ably
                };
                const previousStates = state.previousStates
                return {
                    currentState: updatedState,
                    previousStates,
                };
            }
            case ACTIONS.RESET: {
                return intialState;
            }
            default:
                const updatedState = Object.assign({}, state.currentState, action)
                return {
                    currentState: updatedState,
                    previousStates: state.previousStates
                };
        }
    }
    let ug = newState(state, action);
    ug.currentState.action = updateAction ? action.type : ug.currentState.action;
    ug.currentState.chatId = ug.currentState.chatId??v4();
    return ug;
}

export const intialState = {
    currentState:{
        response:"",
        loading:false,
        error:false,
        errorDetails:{},
        errorType:null,
        prompt:"",
        action:"",
        inChat:true,
        audienceType:null,
        showWaitlistModal:false,
        messageId:v4(),
        chatId:null,
        channelId:null,
        connected:false,
        ably:null,
        email:null,
        crashed:false,
        streaming:false,
        promptTime:null,
        responseTime:null,
        streamingCompleteTime:null,
        openAIPrompt:null,
        source:null,
        function_call:null
    },
    previousStates:[]
}


export const ACTIONS = {
    SEND_PROMPT:'pureai-send-prompt',
    RECV_RESPONSE:'pureai-recv-response',
    RECV_STREAM:'pureai-recv-stream',
    STREAM_START:'pureai-stream-start',
    STREAM_STOP:'pureai-stream-stop',
    STREAM_COMPLETE:'pureai-stream-complete',
    LOADING_UPDATED:'pureai-loading-updated',
    WAITLIST_MODAL:'pureai-toggle-waitlist-modal',
    CALL_API:'pureai-call-api',
    SUCCESS:'pureai-success',
    ERROR:'pureai-error',
    RESET:'pureai-reset',
    AUDIENCE_TYPE:'pureai-audience-type',
    CRASHED:'pureai-crashed',
    NEW_CHAT:'pureai-new-chat',
    ADD_CHANNEL_ID:'pureai-add-channel-id',
    ABLY_CONNECTED:'pureai-ably-connected',
    FAIL_LOAD_RESPONSE:'pureai-fail-load-response',
    FAILURE_EVENT_SENT:'pureai-fail-load-response-sent',
    PROMPT_SENT:'pureai-prompt-sent',
    FEEDBACK_SUBMITTED:'pureai-feedback-submitted',
    FUNCTION_CALL:'pureai-function_call'
}