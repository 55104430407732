export const constants = {
    stripe_payment_methods: ["stripe", "stripebilling"],
    paypal_payment_methods: ["paypal","paypalr","paypal_business"],
    recurring_gateways:["paypal","paypalr","stripe", "stripebilling"],
    app_purchasing: ["renew","familyplan","plans", "teams"],
    manual_gateways:  ["paymentwall"],
    removed_gateways: ["coingate"],
    payment_not_support_gateways:  ["appstore","playstore"],
    enable_payment_offline_gateways:  ["paymentwall", "cardless"],
    allow_mobile_gateway: ["appstore","playstore"],
    offline_gateways: ["amazon","firestore","playstore"],
    past_due_state: ['past_due'],
    hide_hello_bar: ['/dashboard/app-purchase'],
    cardless_state: ['expired','paused','past_due'],
    eventPrefixOnboarding: "ma_onboarding_",
    eventPrefixDownloads: "ma_downloads_",
    purekeepPageURL: "https://www.purevpn.com/purecircle/keep",
    purekeepWindowsDownloadLink: "https://purevpn.com/applinks/download-app/purekeep-windows",
    purekeepMacDownloadLink: "https://purevpn.com/applinks/download-app/purekeep-mac",
    purekeepWebDownloadLink: "https://keep.purevpn.com",
    pureprivacyPageURL: "https://www.purevpn.com/purecircle/privacy",
    pureVPNPurchasePageURL: "https://www.purevpn.com/order",
    pureprivacyAppleDownloadLink: "http://www.purevpn.com/applinks/pureprivacy/download-app/store/apple",
    pureprivacyGoogleDownloadLink: "http://www.purevpn.com/applinks/pureprivacy/download-app/store/google",
    pureencryptMacAppleSiliconDownloadLink: "https://purevpn.com/applinks/download-app/pureencrypt-mac-apple",
    pureencryptMacIntelSiliconDownloadLink: "https://purevpn.com/applinks/download-app/pureencrypt-mac-intel",
    pureencryptWindowsDownloadLink: "https://purevpn.com/applinks/download-app/pureencrypt-windows",
    applicationAddons:  ["purekeep", "purescan", "purecrypt", "pureprivacy", "puremax"],
    hasCountryAddons:  ["ddos_protection", "port_forwarding_with_dedicated_ip", "dedicated_ip"],
    productNames:  ["purekeep", "purescan", "purecrypt", "pureprivacy", "purevpn"],
    modalApplicationAddons:  ["purekeep", "purescan", "purecrypt", "pureprivacy","allProducts"],
    newSignupViewApps:  ["purekeep", "pureprivacy", "purecrypt"],
    purekeep:  "purekeep",
    purevpn:  "purevpn",
    purescan:  "purescan",
    purecrypt:  "purecrypt",
    puresquare:  "puresquare",
    pureencrypt:  "pureencrypt",
    pureprivacy:  "pureprivacy",
    no_of_child:4,
    purchased:"purchased",
    notPurchased: "notPurchased",
    FreeRenew:"FreeRenew",
    purevpnAddonsNames: ["port_forwarding_with_dedicated_ip", "port_forwarding", "dedicated_ip", "ddos_protection","multi_login"],
    inAppModals: ["purekeep" ,"purescan", "pureprivacy", "purecrypt", "port_forwarding_with_dedicated_ip", "dedicated_ip", 
    "port_forwarding", "ddos_protection", "familyplan","multi_login", "plans"],
    allProducts:"allProducts",
    port_forwarding:["port_forwarding","port_forwarding_with_dedicated_ip"],
    allFilter:"All",
    pending_msg:"Your invoice is in Pending state. Please continue to purchase",
    purprivacy_addon:["pureprivacy","purescan"],
    bunddleModal:["puremax","pureplus"],
    newAddonsServiceOrigin: ["purekeep", "purescan", "purecrypt"],
    multi_login_count:10,
    hideChatTimer:1000,
    dontCloseModalErrorCode:["declined","add_on_up_sell_exception","recurly_exception"],
    google_pay_names:["googlepay",'gpay','google_pay'],
    lastFourCards:['stripe','stripebilling','applepay','googlepay'],
    vpn_max:"vpn_max",
    isChangePsw:"isChangePsw",
    features_addons:["dedicated_ip", "dedicated_server", "dedicated_team"],
    ma_features_addons:["dedicated_server", "dedicated_team"],
    b2b:"b2b",
    step_one: 'step_one',
    step_two: 'step_two',
    post_purchase: 'post_purchase',
    pre_purchase: 'pre_purchase',
    individual_plan: 'individual_plan'
}

export const addonConstants = {
    purekeep : "purekeep",
    purescan : "pureprivacy",
    purecrypt : "purecrypt",
    port_forwarding_with_dedicated_ip : "port_forwarding_with_dedicated_ip",
    dedicated_ip : "dedicated_ip",
    port_forwarding : "port_forwarding",
    ddos_protection : "ddos_protection",
    multi_login : "multi_login",
    dedicated_server : "dedicated_server",
    pureprivacy: "pureprivacy",
    dedicated_team_server: "dedicated_team_server",
    shared_network: "shared_network",
    teams: "teams",
  }
 export const addonData =  [
     {name:"Dedicated IP",code:"dedicated_ip"},
     {name:"Dedicated Server",code:"dedicated_server"},
     {name:"Dedicated IP with Portforwarding",code:"port_forwarding_with_dedicated_ip"},
     {name:"Port Forwarding",code:"port_forwarding"},
     {name:"DDoS Protection",code:"ddos_protection"},
     {name:"Multi Login",code:"multi_login"},
     {name:"Residential Network",code:"residential_network"},
     
]


export const appNameObj = {
    purekeep:"PureKeep",
    pureprivacy:"PurePrivacy",
    purecrypt:"PureEncrypt"
}

export const signupFrom = [
    "stacksocial",
    "stacksocial2years",
]

export const basePrice = {
    standard:{
        AUD: 0,
        CAD: 0,
        EUR: 11.00,
        GBP: 9.44,
        INR: 0,
        JPY: 0,
        MXN: 0,
        NOK: 127.95,
        USD: 11.95,
    },
    plus:{
        AUD: 0,
        CAD: 0,
        EUR: 13.77,
        GBP: 11.81,
        INR: 0,
        JPY: 0,
        MXN: 0,
        NOK: 160.07,
        USD: 14.95,
    },
    max:{
        AUD: 0,
        CAD: 0,
        EUR: 18.37,
        GBP: 15.75,
        INR: 0,
        JPY: 0,
        MXN: 0,
        NOK: 213.61,
        USD: 19.95,
    },
}

export const referText = (url="") => {
    return `Hey there! 👋

    I've been using PureVPN for quite some time now. It has 96+ locations and it lets me browse the internet with complete security and freedom. 😃 🙌
    
    You might want to check it out. Use my link to signup and get free 30 days with your subscription.
    
    ${url}
    `
}

export const nextOnBoardingContent = {
    purevpn:  {
        heading: "constants_purevpn_heading",
        paragraph: `constants_purevpn_para`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PureVPN now to complete peace of mind at all times.`,
    },
    purekeep:  {
        heading: `constants_purekeep_heading`,
        paragraph: `constants_purekeep_para`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PureKeep now to complete peace of mind at all times.`,
    },
    purescan:  {
        heading: `securitysuite_personal_privacy`,
        paragraph: `onboarding_discovermore_pp_text`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PurePrivacy now to complete peace of mind at all times.`,
    },
    purecrypt:  {
        heading: `constants_purecrypt_heading`,
        paragraph: `constants_purecrypt_para`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PureEncrypt now to complete peace of mind at all times.`,
    },
    pureencrypt:  {
        heading: `constants_purecrypt_heading`,
        paragraph: `constants_purecrypt_para`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PureEncrypt now to complete peace of mind at all times.`,
    },
    pureprivacy:  {
        heading: `securitysuite_personal_privacy`,
        paragraph: `onboarding_discovermore_pp_text`,
        buttonText: `get_started_text`,
        sideBarNavPopupText: `Get Started with PurePrivacy now to complete peace of mind at all times.`,
    },
}

export const creditsRouting = {
    renew:"dashboard/subscription",
    upgrade:"/dashboard/upgrade",
    purekeep:"/dashboard/onboarding/purekeep",
    pureprivacy:"/dashboard/onboarding/pureprivacy",
    purecrypt:"/dashboard/onboarding/purecrypt",
    download_purekeep:"/dashboard/download?query=purekeep",
    download_pureprivacy:"/dashboard/download?query=pureprivacy",
    download_purecrypt:"/dashboard/download?query=purecrypt",

}

export const puremaxProducts = {
    purekeep:"PureKeep",
    purescan: "PurePrivacy",
    purecrypt: "PureEncrypt",
    purevpn: "PureVPN",
    length: 4,
    validIntervals: {
        1: true,
        12: true,
        24: true
    }
}

export const addonState = {
    pending:"pending",
    paid:"paid",
    past_due:"past_due",
}

export const freeAddonTenure = {
    "weekly":1,
    "monthly":2,
    "yearly":3,
    "semiannually":4
}

export const comboEligible = {
    type:["dedicated_ip"],
    platform:["windows"]
}

export const combo_options = [
    {type:"dedicated_ip",heading:"Dedicated IP",desc:"Whitelist your unique IP to securely access company resources remotely"},
    {type:"port_forwarding_with_dedicated_ip",heading:"Dedicated IP With Portforwarding",desc:"Easily host gaming servers and remotely access your devices from anywhere in the world."},
    {type:"dome",heading:"Dedicated IP for teams",desc:"Get unique IP's for your entire team to prevent unauthorized access to company resources"},
]
export const addonInfo = {purekeep:"Password manager that allows you to store all your passwords in one secure location. ",
pureprivacy:"Social media privacy tool that helps you protect your social accounts.",
purecrypt:"File encryption tool that helps you to encrypt your files so only you can access them.",}

export const addonName = {purekeep:"PureKeep",
pureprivacy:"PurePrivacy",
purecrypt:"PureEncrypt",}


export const bundleType = {
    standard:"standard",
    max:"max",
    plus:"plus"    
}

export const bundleTypeNaming = {
    standard:"Standard",
    max:"PureMax",
    plus:"PurePlus"    
}
export const bundleModal = [
'vpn_standard',   
'vpn_plus',
'vpn_max',
'keep_standard',
'keep_plus',
'keep_max',
'encrypt_standard',
'encrypt_plus',
'encrypt_max',
'privacy_standard',
'privacy_plus',
'privacy_max',
]

export const preventPurchaseBundle = [
'vpn_plus',
'vpn_max',
]

export const addonNames = {
    port_forwarding:"Port Forwarding",
    ddos_protection:"DDos Protection",
    dedicated_ip:"Dedicated IP",
    purekeep:"PureKeep",
    purevpn:"PureVPN",
    purescan:"PurePrivacy",
    purecrypt:"PureEncrypt",
    pureprivacy:"PurePrivacy",
    port_forwarding_with_dedicated_ip:"Port Forwarding with Dedicated IP",
    puremax: "PureMax",
    multi_login:"Multi Login",
    dedicated_server:"Dedicated Server",
    team_server: "Team Server",
    team_members: "Team Members",
    residential_network: "Residential Network"
}

export const CancellationFlowActions = {
    validatePlatform : 'validatePlatform',
    stopSubscription: 'stopSubscription',
    cancelSubscriptionWithReason:'cancelSubscriptionWithReason', // feature vpn doesnt have
    getUserReason: 'getUserReason', // switching to another vpn provider
    openLiveChat: 'openLiveChat',
    cancelSubscriptionRequest: 'cancelSubscriptionRequest',
    talkToSupport: 'talkToSupport',
    redirectToFirebaseBlog: 'redirectToFirebaseBlog',
    openLiveChatWithoutText: 'openLiveChatWithoutText',
    askReasonForNotSatisfiedWithAddon: 'askReasonForNotSatisfiedWithAddon',
    purchaseAllNewAddons: 'purchaseAllNewAddons',
    purchaseFreeAddons: 'purchaseFreeAddons', 
    purchaseVPNAddons: 'purchaseVPNAddons',
    familyPlanUpgrade: 'familyPlanUpgrade',
    upgradePlans: 'upgradePlans',
    requestForCredit: 'requestForCredit',
    purchaseMonthlyForFiveDollar: 'purchaseMonthlyForFiveDollar',
    requestForAccountExtension: 'requestForAccountExtension',
    downgradePlans: 'downgradePlans',
    purchaseBundles: 'purchaseBundles',
    runTroubleshoot: 'runTroubleshoot',
    openChatbot: 'openChatbot', 
    firebaseTroubleshoot: 'firebaseTroubleshoot'
}

export const CancellationFlowConditionalActions = [CancellationFlowActions.getUserReason, CancellationFlowActions.openLiveChat, CancellationFlowActions.cancelSubscriptionWithReason, CancellationFlowActions.askReasonForNotSatisfiedWithAddon]

export const bundleOnboardingEligible = [
    'vpn_plus',
    'vpn_max',
    'keep_plus',
    'keep_max',
    'encrypt_plus',
    'encrypt_max',
    'privacy_plus',
    'privacy_max',
];

export const planTypeNaming = {
    'standard': 'Standard',
    'normal': 'Standard',
    'vpn_standard': 'Standard',
    'vpn_max': 'PureMax',
    'vpn_plus': 'PurePlus'
}

const allProducts = ['purevpn', 'pureprivacy', 'purekeep', 'purencrypt']

export const addonsOnPlans = {
    'standard': ['purevpn'],
    'plus' : ['purevpn', 'purekeep', 'purencrypt'],
    'max' : allProducts,
    'all' : allProducts,
}

const standard = ['securitysuite_vpn_tenx'];
const plus = [...standard, 'securitysuite_secure_password', 'securitysuite_end_to_end'];
const max =  [...plus, 'securitysuite_personal_privacy']

export const planTypeCardContent = {
    standard,
    plus,
    max
}

export const mapFeatureToProduct = {
    'securitysuite_vpn_tenx' : 'purevpn',
    'securitysuite_secure_password': 'purekeep',
    'securitysuite_end_to_end': 'purencrypt',
    'securitysuite_personal_privacy': 'pureprivacy'
}

export const inEligibleUserOnPlanUpgrade = {
    'trial': {message: 'securitycart_eligible_trial', cta: 'close_text'},
    'waitlist': {message: 'securitycart_eligible_waitlist', cta: 'familyplan_errorbox_waitlist_cta'},
    'child': {message: 'Unfortunately, you are ineligible to make this purchase. Only the manager has the authority to do so.', cta: 'Ok'},
    'expired': {message: 'securitycart_eligible_renew', cta: 'renew_now_text'},
    'reseller': {message: 'Since you purchased your subscription from Reseller, you are ineligible to make this purchase.', cta: 'Ok'},
}

export const available_payment_gateways_icon = {
    stripe:"card",
    stripebilling:"card",
    paypal:"paypal",
    paypalr:"paypal",
    paypal_business:"paypal",
    paymentwall:"paymentwall",
    coingate:"coingate",
    applepay:"applepay",
    googlepay:"googlepay",
    gpay:"googlepay"
}

export const currencies = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    AUD: "$",
    CAD: "$",
    NOK: "kr"
}

export const filter_coupons = {
    upgrade:"upgrade",
    all:"all",
    renew:"renew",
    port_forwarding:"port_forwarding",
    ddos_protection:"ddos_protection",
    dedicated_ip:"dedicated_ip",
    purekeep:"purekeep",
    purevpn:"purevpn",
    purescan:"purescan",
    purecnrypt:"purecnrypt",
    pureprivacy:"pureprivacy",
    port_forwarding_with_dedicated_ip:"port_forwarding_with_dedicated_ip",
    vpn_max: "vpn_max",
    vpn_plus:"vpn_plus",
    multi_login:"multi_login",
    familyPlan:"family_plan"

}

export const public_email_addresses = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'hotmail.co.uk',
    'hotmail.fr',
    'msn.com',
    'yahoo.fr',
    'wanadoo.fr',
    'orange.fr',
    'comcast.net',
    'yahoo.co.uk',
    'yahoo.com.br',
    'yahoo.co.in',
    'live.com',
    'rediffmail.com',
    'free.fr',
    'gmx.de',
    'web.de',
    'yandex.ru',
    'ymail.com',
    'libero.it',
    'outlook.com',
    'uol.com.br',
    'bol.com.br',
    'mail.ru',
    'cox.net',
    'hotmail.it',
    'sbcglobal.net',
    'sfr.fr',
    'live.fr',
    'verizon.net',
    'live.co.uk',
    'googlemail.com',
    'yahoo.es',
    'ig.com.br',
    'live.nl',
    'bigpond.com',
    'terra.com.br',
    'yahoo.it',
    'neuf.fr',
    'yahoo.de',
    'alice.it',
    'rocketmail.com',
    'att.net',
    'laposte.net',
    'facebook.com',
    'bellsouth.net',
    'yahoo.in',
    'hotmail.es',
    'charter.net',
    'yahoo.ca',
    'yahoo.com.au',
    'rambler.ru',
    'hotmail.de',
    'tiscali.it',
    'shaw.ca',
    'yahoo.co.jp',
    'sky.com',
    'earthlink.net',
    'optonline.net',
    'freenet.de',
    't-online.de',
    'aliceadsl.fr',
    'virgilio.it',
    'home.nl',
    'qq.com',
    'telenet.be',
    'me.com',
    'yahoo.com.ar',
    'tiscali.co.uk',
    'yahoo.com.mx',
    'voila.fr',
    'gmx.net',
    'mail.com',
    'planet.nl',
    'tin.it',
    'live.it',
    'ntlworld.com',
    'arcor.de',
    'yahoo.co.id',
    'frontiernet.net',
    'hetnet.nl',
    'live.com.au',
    'yahoo.com.sg',
    'zonnet.nl',
    'club-internet.fr',
    'juno.com',
    'optusnet.com.au',
    'blueyonder.co.uk',
    'bluewin.ch',
    'skynet.be',
    'sympatico.ca',
    'windstream.net',
    'mac.com',
    'centurytel.net',
    'chello.nl',
    'live.ca',
    'aim.com',
    'bigpond.net.au'
]

export const redirectedFromPage = {
    volumeLicensing: 'volumeLicensing',
    plantypeUpgrade: 'plantypeUpgrade',
    familyPlan: 'familyPlan',
    featureUpgrade: 'featureUpgrade',
    teams: 'teams',
    upgrade: 'upgrade'
}

export const securityToolsSlugs = [
    'remove-my-data',
    'password-generator',
    'tracker-blocker',
    'social-privacy-manager',
    'password_manager',
    'file-encryption'
]

export const securityToolsPlans = {
    'vpn_standard': ['purevpn'],
    'vpn_plus' : ['purevpn', 'purekeep', 'purecrypt', 'purencrypt'],
    'vpn_max' : ['purevpn', 'purekeep', 'purecrypt', 'purencrypt', 'purescan', 'pureprivacy'],
}

export const pureaiConstants = {
    failLoadResponse: 'Something went wrong, please try again later or provide more context so that we can better answer your queries.<br />Share your feedback with us so we can improve by clicking "Submit Feedback" on the bottom right'
}

export const featuresNameConstants = {
    'remove-my-data': 'remove-my-data',
    'password-generator': 'password-generator',
    'tracker-blocker': 'tracker-blocker',
    'social-privacy-manager': 'social-privacy-manager',
    'password_manager': 'password_manager',
    'file-encryption': 'file-encryption'
}

export const eventsConstantsMessaging = {
    'trial': {message: 'You can upgrade your plan after converting to premium.', cta: 'Close'},
    'waitlist': {message: 'Security suite is only available for limited users!', cta: 'Join Waitlist'},
    'child': {message: 'Unfortunately, you are ineligible to make this purchase. Only the manager has the authority to do so.', cta: 'Ok'},
    'expired': {message: 'Please consider renewing your subscription before proceeding with the purchase.', cta: 'Renew now'},
}

export const featureWrtProduct = {
    pureprivacy: ['Remove My Data, Tracker Blocker, Social Privacy Manager'],
    purekeep: ['Password Generator, Password Manager'],
    purecrypt: ['File Encryption', 'Cloud Storage'],
    purescan: ['Remove My Data, Tracker Blocker, Social Privacy Manager'],
}

export const featureMapping = {
    'remove-my-data': 'Remove My Data',
    'password-generator': 'Password Generator',
    'tracker-blocker': 'Tracker Blocker',
    'social-privacy-manager': 'Social Privacy Manager',
    'password_manager': 'Password Manager',
    'file-encryption': 'File Encryption'
}

export const referralTiers = {
    silver: 'Silver',
    gold: 'Gold', 
    platinum: 'Platinum', 
    diamond: 'Diamond'
}

export const referralTierStatus = {
    locked: 'locked',
    completed: 'completed',
    progress: 'in-progress',
}

export const billingCycles = {
    monthly: 'monthly',
    quarterly: 'quarterly',
    semiannually: 'semiannually',
    annually: 'annually',
    biennially: 'biennially',
    triennially: 'triennially',
    five_year: '5 year'
}


export const pureTeamsPostPurchaseStates = [
    {
        name: 'team_members',
        heading: 'Team Members',
        description: 'Easily add, remove and manage team members.',
        checkout_liner: 'Choose the number of members you want to add to your team.',
        info: <div className="info-modal">
        <h1 className="mb-2">Who are your Team Members?</h1>
        <p>Team members refer to individuals who are part of your organization and have been granted access to the VPN. You can also choose to assign them Dedicated IP or Team Server so they can get secure and remote access to shared resources, such as files, servers, or applications.</p>
        <h5>VPN Access Management:</h5>
        <p>Streamlining remote connectivity, Teams enables the addition of team members for seamless VPN access sharing.</p>
        <h5>Dedicated IP:</h5>
        <p>Teams offers the flexibility to assign Dedicated IPs to individuals or servers, accommodating up to fifty members concurrently.</p>
        <h5>Secure Access for Team Members:</h5>
        <p>Elevate the work experience for employees, contractors, or partners with Teams, ensuring secure access to shared resources from any location.</p>
    </div>
    },
    {
        name: 'team_server',
        heading: 'Team Server',
        description: 'Create and assign Team Server to all the members of your team.',
        checkout_liner: 'Up to 50 team members can share a single IP address, eliminating the need for individual IPs for each team member.',
        info: <div className="info-modal">
        <h1 className="mb-2">What's a Team Server?</h1>
        <p>A Team Server allows you to assign a single public IP address to all the members of your team. It can be connected on multiple devices at the same time.</p>
        <h4 className="mt-3">What does it offer?</h4>
        <h5>Easy access to shared network resources:</h5>
        <p>Team Server helps organizations to enable seamless collaboration within their teams by assigning a single IP address to all the members, allowing them to easily access the shared network resources.</p>
        <h5>Advanced network security:</h5>
        <p>Team server provides a secure and encrypted connection for all data transmitted between employees and the organization's network, protecting sensitive information from interception and unauthorized access.</p>
    </div>
    },
    {
        name: 'dedicated_ip',
        heading: 'Dedicated IP',
        description: 'Create and assign Dedicated IPs to your team members.',
        checkout_liner: 'Specify the number of dedicated IP addresses you require, which can be assigned to one team member at a time.',
        info: <div className="info-modal">
        <h1 className="mb-2">What's a Dedicated IP address?</h1>
        <p>A Dedicated IP is a unique and static IP address which can only be assigned to one member at a time.</p>
        <h4 className="mt-3">What does it offer?</h4>
        <h5>Enhanced online security:</h5>
        <p>A Dedicated IP reduces your chances of facing cyber threats that are often linked to shared IPs, making your online experience safer.</p>
        <h5>Online reputation and access control:</h5>
        <p>With a Dedicated IP, you have more control over your online reputation, which is especially important for tasks like email marketing. Plus, you get consistent access to platforms that use IP-based controls.</p>
        <h5>Reduced blacklisting risks and faster access:</h5>
        <p>Since you're not sharing your IP with others, there's less chance of being blacklisted due to someone else's actions. Also, platforms that prioritize Dedicated IPs can offer faster access.</p>
        <h5>Geo-specific access and uninterrupted service:</h5>
        <p>Your Dedicated IP lets you access content that's restricted by location and ensures uninterrupted service</p>
    </div>
    },
]

export const multiLoginTiers = {
    20: 20,
    30: 30,
    40: 40,
    50: 50
}