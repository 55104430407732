import CircularLoader from '../../../generic/CircularLoader';
import { useTranslation } from 'react-i18next';

const FamilyPlanWorks = () => {
    const { t, i18n } = useTranslation();
    return <div className="plan_works">
        <h3>{t('familyplan_want_to_know_how_it_works')}</h3>
        <div className='video-frame'>
            <CircularLoader />
            <iframe src='https://www.youtube.com/embed/xWNmcASbCug'
                frameborder='0'
                allow='autoplay; encrypted-media'
                allowfullscreen
                title='video'
                width='100%'
                height='549px'
            />
        </div>
        <p>{t('familyplan_want_to_know_how_it_works_para')}</p>
    </div>
}

export default FamilyPlanWorks;