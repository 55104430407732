export const updatePreviousState = (previouState,updatedState) => {
    return previouState.map((prevState, index) =>
          index === previouState.length - 1
            ? updatedState
            : { ...prevState, loading: false }
        );
}

export const chatGptResponseReducer = (state, action) => {
    switch (action.type) {
    // Making the loading bit true and assigning the prompt that user has provided as an input search
      case ACTIONS.CALL_API: {
        const updatedState = {
          ...state.currentState,
          loading: true,
          prompt: action.prompt,
          streaming: true
        };
        const previousStates = [
          ...state.previousStates,
          { ...state.currentState, loading: true,prompt: action.prompt },
        ];
        return {
          currentState: updatedState,
          previousStates,
        };
      }
      /* In success we reset the currentSate to intial satate and add the success result to previous state data 
      also overwirting the previousState that is coming from the LOADING state as now it has been success */
      case ACTIONS.SUCCESS: {
        const updatedState = {
          ...state.currentState,
          loading: false,
          error: false,
          response: action.response,
          prompt: action.prompt,
          platformSpecificAnswers: action?.platformSpecificAnswers ?? {},
          cta: action?.cta ?? {},
          ref_link: action?.ref_link ?? ''
        };
        const previousStates = updatePreviousState(state.previousStates,updatedState)
        return {
          currentState: intialState.currentState,
          previousStates,
        };
      }
      case ACTIONS.STREAMING: {
        if(state.currentState.streaming === false) {
          return state;
        }
        const updatedState = {
          ...state.currentState,
          loading: false,
          error: false,
          ...( action.response !== undefined && { response: (state.currentState.response + action.response).replace(/```(.*?)```/g, '<code>$1</code>')}),
          prompt: action.prompt,
          showStopGenerating: true,
          platformSpecificAnswers: action?.platformSpecificAnswers ?? {},
          cta: action?.cta ?? {},
          ref_link: action?.ref_link ?? ''
        };
        const previousStates = updatePreviousState(state.previousStates,updatedState)
        return {
          currentState: updatedState,
          previousStates: previousStates,
        };
      }
      case ACTIONS.STREAMING_ENDED: {
        return {
          currentState: {...intialState.currentState, streaming: false },
          previousStates: state.previousStates,
        };
      }
      case ACTIONS.ERROR: {
        const updatedState = {
          ...state.currentState,
          loading: false,
          error: true,
          response: action.response,
          prompt: action.prompt,
          showStopGenerating: false
        };
        const previousStates = updatePreviousState(state.previousStates,updatedState)
        return {
          currentState: intialState.currentState,
          previousStates,
        };
      }
      case ACTIONS.RESET: {
        return intialState
      }
      default:
        return state;
    }
  };

export const intialState = {
    currentState:{
        response:"",
        loading:false,
        error:false,
        prompt:"",
        streaming:false,
        showStopGenerating: false,
        platformSpecificAnswers: {},
        cta: {},
        ref_link: ''
    },
    previousStates:[]
}


export const ACTIONS = {
    CALL_API:'call-api',
    SUCCESS:'success',
    STREAMING: 'streaming',
    STREAMING_ENDED: 'streaming_ended',
    START_STREAMING: 'start_streaming',
    ERROR:'error',
    RESET:"reset"
}