import React from 'react'
import { Blinking, NotSupportedBox, PrimaryBtn, SecondryBtn } from '../styles/Generic'
import notsupported from '../assets/notsupported.png'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const Unauthorized = () => {

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch({type:"LOGOUT"})
        // localStorage.clear();
        setTimeout(() => {
            window.location.href = process.env.REACT_APP_FA_URL
        }, 1200);
    },[])

    return (
        <NotSupportedBox>
            <div className="text-center" style={{maxWidth:"439px"}}>
                <img src={notsupported} className="img-fluid" height={214} alt="Not Supported"/>
                <h1>Login Authorization Failed</h1>
                <p>Seems like something went wrong while authorizing. Redirecting you to login <Blinking/><Blinking/><Blinking/><Blinking/><Blinking/></p>
            </div>
        </NotSupportedBox>
    )
}

export default Unauthorized