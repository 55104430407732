import { PrePurchaseStyle } from "../../../styles/dashboard/VolumeLicensing";
import DynamicCard from "./dynamicCard";
import { VolumeLicensingContent } from "./content";
import Features from "./features";
import InputSlider from "../../../generic-components/inputSlider";
import { useState } from "react";
import { useEffect } from "react";
import preview from "../../../assets/volume-licensing/vl.jpg";
import membersImg from "../../../assets/volume-licensing/vl-members.svg"
import { PrimaryBtn } from "../../../styles/Generic";
import Experiences from "./experiences";
const Prepurchase = ({childCount, data, sliderValue, setSliderValue, purchaseVL, gotoDiv, scrollToCart}) => {

    const [cardData, setCardData] = useState(data)

    useEffect(()=>{
        setCardData(data)
    }, [data])

    const minAccountValue = childCount;
    const maxAccountValue = +process.env.REACT_APP_VL_MAXIMUM_SLIDER_VALUE;

    return <PrePurchaseStyle>

        <div className="main-content">
            <h2>{VolumeLicensingContent.main_heading}</h2>
            <h6>{VolumeLicensingContent.main_para}</h6>
        </div>
        <div className="previewHeader">
            <img src={membersImg} alit="membersIcon" className="previewHeaderImg"/>    
            <PrimaryBtn onClick={gotoDiv} className="pHeader-cta">Add Members</PrimaryBtn>
        </div>
        <div className="preview-content m-auto text-center">
            <img src={preview} alit="preview for post purchase" className="m-auto d-block w-100"/>
            <PrimaryBtn onClick={gotoDiv}>Purchase Volume License</PrimaryBtn>
        </div>

        <div className="experiences">
            <h2>{VolumeLicensingContent.experience.heading}</h2>
            <div className="exp-boxes">
                {VolumeLicensingContent.experience.content.map((feature)=>{
                    return <Experiences heading={feature.heading} image={feature.image} paragraph={feature.paragraph} />
                })}
            </div>
        </div>

        <div className="features">
            <h2>{VolumeLicensingContent.features.heading}</h2>
            {VolumeLicensingContent.features.content.map((feature)=>{
                return <Features heading={feature.heading} image={feature.image} paragraph={feature.paragraph} align={feature.align}/>
            })}
        </div>

        <div ref={scrollToCart}></div>
        
        {cardData ? <div className="interactive-content">
            <h5>{VolumeLicensingContent.interactive_content.heading}</h5>
            <InputSlider value={sliderValue} setValue={setSliderValue} minAccountValue={minAccountValue} maxAccountValue={maxAccountValue} />
            <div>
               <DynamicCard data={cardData} accountNumber={sliderValue} maxAccountValue={maxAccountValue} purchaseVL={purchaseVL}/>
            </div>
        </div> : <h6 className="error-plans">We see that you're interested in upgrading your plan to Volume licensing. Unfortunately, Volume Licensing is currently unavailable for you!</h6>}

        

    </PrePurchaseStyle>
}
export default Prepurchase;