import { CardBox, DownloadBox, GuideBox, InfoCard, OtherDevice } from '../../../styles/dashboard/Main'
import { connect, useDispatch } from 'react-redux'
import { getDeviceRequested } from '../../../redux/downloads/actions'
import { Input } from 'reactstrap'
import { LinkBtn, PrimaryBtn } from '../../../styles/Generic'
import { sendEvents, sendGuestEvents} from "../../../tools/mpEvents"
import { setActiveDevices } from '../../../tools/genericMethods';
import { useLocation } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';
import { getLocaleFromUrl } from '../../../tools/tools';

const DownloadSidebarBox = ({download, selectedApp, history, key, accountReducer, onBoardingReducer, appSlug, data, setData, device, devices, setDevice, sendGuidesEvents, sendOtherDevicesEvents, moreAppsCallback}) => {

    const {search, pathname} = useLocation();

    const {slug} = Object.fromEntries(new URLSearchParams(search));

    const token = localStorage.getItem("token");

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const [otherDevices, setOtherDevices] = useState(null);

    useEffect(() => {

        const otherDevices = devices.find(device => device.abbreviation === "other_devices")

        if (otherDevices && typeof otherDevices !== undefined) {
            setOtherDevices(otherDevices)
        } else {
            setOtherDevices(null)
        }
        
    }, [devices, appSlug])

    const sidebarGuideClickHandler = (e) => {
        sendGuidesEvents(e)
    }

    function isNotEmpty(object) {
        for (const property in object) {
          return true;
        }
        return false;
    }

    const deviceClickHandle = (e) => {
        let imgSrc
        if(e.target.id === device){
            return;
        }
        setDevice(e.target.id)
        const selectedDevice = devices.find(device => device.abbreviation === e.target.id)
        setActiveDevices(download, devices, data, setData, appSlug, selectedApp, true, e.target.id)

        sendOtherDevicesEvents(selectedDevice?.abbreviation)

        if(slug){
            history.replace(pathname, '')
        }
    }

    const moreAppsClickHandle = (e) => {
        moreAppsCallback(e)
    }

    return (
        <>
            <GuideBox>
                <h4>{data.heading} {t('downloads_setup_guide')}</h4>
                <ul className="list-unstyled m-0 p-0">
                    {
                        data.devices?.[0].setup_guides != 0
                        ?
                        data.devices?.[0].setup_guides.map((guide, key) => (
                            <li key={key}><a href={guide.link} target="_blank" id={data.devices?.[0]?.heading} onClick={sidebarGuideClickHandler}>{guide.text}</a></li>
                        ))
                        :
                        <li>{t('downloads_no_setup_guides_available')} {data.devices?.[0].heading}</li>
                    }
                </ul>
            </GuideBox>
            <OtherDevice>
                <h4>{t('downloads_other_devices')}</h4>
                <ul className="list-unstyled m-0 p-0">
                    {
                        devices.map((d, idx) => {
                            if (d.abbreviation !== "other_devices") {
                                return (
                                    <li key={idx} className={`${(d.versions.length == 0) ? "soon" : ""}`}>
                                        <button onClick={(e) => deviceClickHandle(e)} id={`${d.abbreviation}`} className={`device-icon icon-${d.abbreviation} ${ classNames({"active": device === d.abbreviation}) }`}>{d.name}</button>
                                        {
                                            (d.versions.length == 0 || d?.content_details?.isComingSoon)
                                            ? (
                                                <span className='soonTag'>{t('downloads_coming_soon')}</span>
                                            ) : d?.content_details?.isNew ?  <span className='soonTag'>{t('downloads_new')}</span> : null
                                        }
                                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </OtherDevice>
            {
                isNotEmpty(otherDevices) ? ( <LinkBtn onClick={moreAppsClickHandle}>{vsprintf(t('downloads_see_all_configurable_devices'),`${appSlug}`)}</LinkBtn> ) : <></>
            }
            
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, null)(withRouter(DownloadSidebarBox))