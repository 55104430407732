import React,{useRef, useState} from 'react'
import {PrimaryBtn, DestructBtn} from '../../../../styles/Generic'
import { CardElement, useRecurly, ThreeDSecureAction } from '@recurly/react-recurly';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import qs from 'qs'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { getPaymentMethods, isWhmcsUser, poster, generateCouponEvent, seti18Locale, checkLocale } from '../../../../tools/tools';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SubmitConsent from '../SubmitConsent';
import {getSubscriptionsRequest} from '../../../../redux/subscriptions/actions'
import { sendEvents } from '../../../../tools/mpEvents';
import { connect } from 'react-redux';
import { countriesList } from '../../../../tools/countries';
import WhmcsSubscriptionNotes from '../../../../utils/WhmcsSubscriptionNote';
import { constants } from '../../../../tools/constants';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Payment = ({history, resetstate, subscriptionsReducer, isAllowToUseParamMethod, selectedData, endPoint, selectedSubscription, taxInfo, renewSubscription, currentSub, sendPaymentMessage, renewFromApp, popupPurchase, chargeAttempt, toggle, subscription_id,subData, canReqStopCollection=false, isCouponApplied=null, setIsCouponApplied, setFirstTimeCouponEntered,  couponEvent, setDisableSelection=()=>null}) => {

    const { search } = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));
    renewFromApp = renewFromApp === "true" ? true: false;

    renewSubscription = renewSubscription === "true" ? true : false
    
    selectedSubscription = selectedSubscription ?? {}
    subscription_id      = subscription_id ?? null

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const recurly = useRecurly();

    const formRef = useRef();

    const token = localStorage.getItem("token")

    const apiEndPoint = endPoint ?? "account/change-payment-method"

    const [cardName, setcardName] = useState({
        fullname:"",
        firstname:"",
        lastname: "",
        // need to handle this condition from BE ,for now handling it from FE 
        country: (subscriptionsReducer?.subscriptions?.body?.[0]?.account?.address?.country === "" ? "HK" : subscriptionsReducer?.subscriptions?.body?.[0]?.account?.address?.country) ?? "HK" 
    })

    const [threedSecure, setthreedSecure] = useState({
        enabled:false,
        three_d_secure_action_token_id: "",
        oldToken:""
    })

    const [errors, setErrors] = useState({
        cName:false,
        cCard: true,
        cCvv:true,
        cExpiry:true,
        initCardErrors:false,
        firstTime: true,
        cCountry:false
    })

    const {cName,cCard,cCvv,cExpiry,initCardErrors,firstTime,cCountry} = errors;

    const {fullname, firstname, lastname, country} = cardName;

    const [loading, setLoading] = useState(false)

    const recurlyStyle = {
        "fontSize":"14px",
        "outline": "none",
        "boxShadow": "none",
        "fontColor": "#7B648C",
        "fontWeight":"400",
        "fontFamily": "Open Sans,sans-serif"
    }
    const handleChange = (e) => {
        setcardName({...cardName, fullname:e.target.value})
    }

    const handleBlur = (e) => {
        const fname = fullname.split(" ");
        const [first, ...rest] = fname;
        const lastname = rest.join(" ").replace(/\s\s+/g, "");;
        if(e.target.value !== ""){
            setcardName({...cardName, firstname: first, lastname:lastname == "" ? "N/A" : lastname})
            setErrors({...errors, cName:false})
        }else{
            setErrors({...errors, cName:true})
        }
    }

    const handleCardFields = (state) => {
        const {cvv, expiry, number} = state;
        setErrors({
            ...errors,
            cCard: number.empty || false, 
            cCvv: cvv.empty || false,
            cExpiry: expiry.empty || false,
            initCardErrors: !firstTime ? ((number.empty || cvv.empty || expiry.empty) || false) : false,
            firstTime:false
        })
    }

    const handlePaymentChange = (e, type) => {
        if(type == "submit") { e.preventDefault(); }
        setLoading(true);
        if(country == "" || cCountry){
            setErrors({...errors, cCountry:true})
            setLoading(false);
            return;
        }
        if(fullname == "" || cName){
            setErrors({...errors, cName:true})
            setLoading(false);
            return;
        }
        if(cCard || cCvv || cExpiry){
            setErrors({...errors, initCardErrors:true})
            setLoading(false);
            return;
        }
        setDisableSelection(true)
        recurly.token(formRef.current, (err, token) => {
            if (err) {
                toast.error('Card Information is Invalid!');
                setLoading(false);
            } else {
                if(Object.keys(selectedSubscription).length){
                    changePaymentAsync({
                        subscription_id:selectedSubscription?.subscription ?? '',
                        invoice_id:selectedSubscription?.invoice ?? '',
                        token_id:token.id, 
                        type: "credit-card", 
                        three_d_secure: "",
                        gateway: 'stripe'
                    })
                }else{
                    changePaymentAsync({token_id:token.id, type: "credit-card", three_d_secure: "", gateway: 'stripe'})
                }
                
                
            }
        });

        if(renewSubscription){
            sendEvents({
                event_name:"ma_checkout_renew_subscription",
                event_properties:{
                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                    payment_gateway: currentSub?.payment_gateway,
                    productid: currentSub?.plan?.code ?? "N/A"  ,
                    current_status: currentSub?.state ,
                    invoice_id: currentSub?.renew_invoice,
                    ...couponEvent
                }
            }, token)
        }else{

            let eventData = {};
            if(isAllowToUseParamMethod){
                const sub_id = selectedData?.subscription_id ?? subscription_id
                let subscription = subscriptionsReducer?.subscriptions?.body.find((subscription) => subscription.id === sub_id)
                eventData = {
                    event_name:"ma_update_paymentmethod",
                    event_properties:{
                        old_payment_method: getPaymentMethods(subscription),
                        new_payment_method: "stripe",
                        ...couponEvent
                    }
                } 
            }else{
                eventData = {
                    event_name:"ma_update_paymentmethod",
                    event_properties:{
                        old_payment_method: getPaymentMethods(currentSub),
                        new_payment_method: "stripe",
                        ...couponEvent
                    }
                } 
            }
            sendEvents(eventData, token)
        }
        
    }

    const changePaymentAsync = async (data) => {
        if(isAllowToUseParamMethod){
           chargeAttempt({...selectedData , ...data,paymentMethodWhmcs:"stripe"})
        }else{
            try {
                if (canReqStopCollection) {
                    const formdata = qs.stringify({
                        subscription_id:selectedSubscription?.subscription ?? '',
                        invoice_id:selectedSubscription?.invoice ?? '',
                    })
                    await poster("subscription/stop-collection", formdata, token)
                }
                if(subscription_id){
                    data['subscription_id'] = subscription_id;
                }
                if(isCouponApplied){
                    data['coupon_code'] = isCouponApplied;
                }
                const formdata = qs.stringify(data)
                const change = await poster(`${apiEndPoint}`, formdata, token)
                const {data: response} = change;
                if(response?.status){
                    if (isWhmcsUser(subscriptionsReducer?.subscriptions?.body[0]?.account?.billingType)) {
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                    }
                    dispatch(getSubscriptionsRequest(token))
                    if(!popupPurchase) {
                        if(renewSubscription){
                            dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                            sendEvents({
                                event_name:"ma_renew_subscription",
                                event_properties:{
                                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                                    payment_gateway: "stripe",
                                    productid: currentSub?.plan?.code  ,
                                    current_status: "active" ,
                                    invoice_id: currentSub?.renew_invoice,
                                    ...couponEvent
                                }
                            }, token)
                        }
                        renewFromApp ? sendPaymentMessage({message: "Your subscription has been successfully renewed", isPurchasedSucessFully:true}) : toast.success(`${renewSubscription  ? "Your subscription has been successfully renewed" : "Payment Method has been successfully changed."}`)
                        resetstate()
                        setLoading(false);
                        if(apiEndPoint === "subscription/change-pm-and-renew" && !renewFromApp){
                            if(currentSub?.hasVolumePlan){
                                localStorage.removeItem('volume')
                                history.push("/dashboard/security-tools");
                            }
                            else if(isWhmcsUser(currentSub?.account?.billingType) || currentSub?.service_origin !== constants.purevpn){
                                history.push("/dashboard/security-tools");
                            }
                            else{
                                history.push("/dashboard/security-tools");
                            }
                        }else{
                            // *IF user renewed from in-app modal (app purchase) dont route the user
                            return
                        }
                    }else{
                        // Charge attempt will be made after change payment method successful from popups
                        chargeAttempt({change_pm:"stripe"})
                    }
                }else{
                    switch (response?.error_code) {
                        case "token_expired":
                            renewFromApp ? sendPaymentMessage({message:TOKENEXPIRED, isPurchasedSucessFully:false}) : toast.error(TOKENEXPIRED);
                            dispatch({type:"LOGOUT"})
                            
                            setLoading(false);       
                            break;
                        case "token_invalid":
                            renewFromApp ? sendPaymentMessage({message:(t(INVALIDTOKEN)), isPurchasedSucessFully:false}) : toast.error(t(INVALIDTOKEN))
                            dispatch({type:"LOGOUT"})
                             
                            setLoading(false);      
                            break; 
                        case "card_type_not_accepted":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false,isDecline:true}) : toast.error(response?.message)
                            setLoading(false);
                            break;
                        case "account_exception":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message)
                            setLoading(false);
                        break;  
                        case "recurly_exception":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false,isDecline:true}) : toast.error(response?.message)
                            setLoading(false);
                        break;
                        case "declined":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false,isDecline:true}) : toast.error(response?.message)
                            setLoading(false);
                            if (isCouponApplied) {
                                setIsCouponApplied(null)
                                setFirstTimeCouponEntered(prevState=>!prevState)
                            }
                            break; 
                        case "internal_server_error":
                            toast.error("Internal Server Error - 500")
                            setLoading(false);
                            break; 
                        case "insufficient_funds":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message)
                            setLoading(false);
                            break; 
                        case "three_d_secure_action_required":
                            setthreedSecure({
                                three_d_secure_action_token_id:response?.errors?.three_d_secure_action_token_id,
                                enabled:true,
                                oldToken:data.token_id
                            })
                            break;
                        case "jwt_default_exception":
                            renewFromApp ? sendPaymentMessage({message:(t(INVALIDTOKEN)), isPurchasedSucessFully:false}) : toast.error(t(INVALIDTOKEN))
                            dispatch({type:"LOGOUT"})
                                   
                            break;
                        default:
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message);
                            setLoading(false);
                            break;
                    }
                    if(renewSubscription){
                        sendEvents({
                            event_name:"ma_fail_renew_subscription",
                            event_properties:{
                                billing_cycle: currentSub?.plan?.type ?? "N/A",
                                payment_gateway: "stripe",
                                productid: currentSub?.plan?.code  ,
                                current_status: currentSub?.state ,
                                invoice_id: currentSub?.renew_invoice,
                                reason: response?.message,
                                ...couponEvent
                            }
                        }, token)
                    }else{
                        sendEvents({
                            event_name:"ma_fail_changepaymentmethod",
                            event_properties:{
                                reason: response?.message,
                                ...couponEvent
                            }
                        }, token)
                    }
                    
                }
            } catch (error) {
                setDisableSelection(false)
                renewFromApp ? sendPaymentMessage({message:(t(APIFAILED)), isPurchasedSucessFully:false}) :toast.error(t(APIFAILED))
                if(renewSubscription){
                    sendEvents({
                        event_name:"ma_fail_renew_subscription",
                        event_properties:{
                            billing_cycle: currentSub?.plan?.type ?? "N/A",
                            payment_gateway: "stripe",
                            productid: currentSub?.plan?.code  ,
                            current_status: currentSub?.state ,
                            invoice_id: currentSub?.renew_invoice,
                            reason: "API break or Server not responding",
                            ...couponEvent
                        }
                    }, token)
                }else{
                    sendEvents({
                        event_name:"ma_fail_changepaymentmethod",
                        event_properties:{
                            reason: "API break or Server not responding",
                            ...couponEvent
                        }
                    }, token)
                }
                
            }
        }
    }

    const handle3dToken = (token) => {
        if(token){
            if(Object.keys(selectedSubscription).length){
                changePaymentAsync({
                    subscription_id:selectedSubscription?.subscription ?? '',
                    invoice_id:selectedSubscription?.invoice ?? '',
                    token_id:threedSecure.oldToken, 
                    type: "credit-card", 
                    three_d_secure: token.id
                })
            }else{
                changePaymentAsync({token_id:threedSecure.oldToken, type: "credit-card", three_d_secure: token.id})
            }
        }else{
            console.log("Not Accepted")
        }
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        toast.error(error?.message || "Something went wrong!!!")
        setLoading(false)
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handleSelect = (e) => {
        if(e.target.value !== ""){
            setcardName({...cardName, country:e.target.value})
            setErrors({...errors, cCountry:false})
        }else{
            setcardName({...cardName, country:""})
            setErrors({...errors, cCountry:true})
        }
    }

    const ctaText = loading ? <>{`${renewSubscription || canReqStopCollection ? t('renew_now_text'): popupPurchase ? "Confirm " : t('subscription_change_payment_method_cta')}`} <span className="spinner-border text-light"></span></> : `${renewSubscription || canReqStopCollection ? t('renew_now_text'): popupPurchase ? "Confirm" : t('subscription_change_payment_method_cta')}` ;

    return (
        <div>
            <form ref={formRef} onSubmit={e=>handlePaymentChange(e, 'submit')}>
                <input type="hidden" data-recurly="first_name" placeholder="First name" value={firstname}/>
                <input type="hidden" data-recurly="last_name" placeholder="Last name"  value={lastname}/>
                <input type="hidden" data-recurly="country" placeholder="Country" value={cardName?.country ?? "HK"}/>
                {/* <Input type="select" name="select" value={cardName.country} onChange={handleSelect} className="mt-2">
                    <option value="">Select your country</option>
                    {
                        Object.entries(countriesList).map((country, key)=>(
                            <option value={country[0]}>{country[1]}</option>
                        ))
                    }
                </Input> */}
                {
                    cCountry && <p className="danger mt-2 ps-1">{t('update_country_is_required')}</p>
                }
                <Input fullwidth type="text" placeholder="Card holder name" className="mt-2" value={fullname} onChange={handleChange} onBlur={handleBlur}/>
                {
                    cName && <p className="danger mt-2 ps-1">Card name is invalid</p>
                }
                <CardElement inputType="mobileSelect" className="mt-2" style={recurlyStyle} onSubmit={(e) => handlePaymentChange('enter')} onChange={handleCardFields}/>
                {
                    initCardErrors && <p className="danger mt-2 ps-1">Card information is required</p>
                }
                {
                    <WhmcsSubscriptionNotes subscriptionDetails={typeof currentSub === "string" ? subData : currentSub} />
                }
                <div className="payment-footer">
                    <PrimaryBtn pointer={loading} className="me-2" type="submit" style={{padding: '16px 20px'}} >{ctaText}</PrimaryBtn>
                    <DestructBtn pointer={loading} type="button" onClick={() => {
                        !popupPurchase ? resetstate() : toggle()
                    }}>{t('cancel_text')}</DestructBtn>
                    {!redirected_via && <SubmitConsent/>}
                </div>    
            </form>
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                actionTokenId={threedSecure.three_d_secure_action_token_id}
                onToken={handle3dToken}
                onError={handle3dError}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, {getSubscriptionsRequest})(withRouter(Payment))