import { ERRORS, GETSUBSCRIPTIONS, LOADING, SETSUBSCRIPTIONBYPAYLOAD, RESETSUBSCRIPTIONPAYLOAD, SETSUBSCRIPTIONVIAONBOARDING } from "./types";

const initialState = {
    subscriptions: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:"",
    resetState: false
}

export const subscriptionsReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETSUBSCRIPTIONS:
            return  {...state, subscriptions:payload, loading:false, errors:false, resetState: false}
            break;
        case SETSUBSCRIPTIONBYPAYLOAD:
            const data = state?.subscriptions?.body?.map(subscription=>{
                if(subscription?.id === payload?.id){
                    return payload
                }
                return subscription 
            })
            return {...state, subscriptions:{...state.subscriptions, body: data}, loading:false, errors:false, resetState: false}
            break;
        case SETSUBSCRIPTIONVIAONBOARDING:
            return {...state, subscriptions:{...state.subscriptions, body: payload}, loading:false, errors:false, resetState: false}
            break;
        case RESETSUBSCRIPTIONPAYLOAD:
            return {...state, subscriptions: null, resetState: true}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false, resetState: false}    
        default:
            return  {...state}
            break;
    }
}