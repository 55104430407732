import { useState, useEffect } from "react";
import { get, getTokenFromLocalStorage, poster } from "../../../../tools/tools";
import { addonConstants } from "../../../../tools/constants";
import qs from "qs";
const useRegionsHook = (url, type) => {
  const token = getTokenFromLocalStorage();

  const [loadRegions, setLoadRegions] = useState();
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    type === 'countries' ? sendCountriesRequest() : sendRegionsRequest();
  }, []);

    async function sendRegionsRequest() {
    setLoadRegions(true)
    try {
        const apiResponse = await get(url, token);
        const { data: regions } = apiResponse;
        if(regions?.status){
                let country = [];
                let city = {};
                regions?.body.map(async region => {
                    country.push({
                        id: region.country_iso2_code,
                        value: region.country_code,
                        label: region.country
                    }) 
                    
                    city = { ...city, 
                            [region.country_code] : region.cities.map((cityInfo) => {
                            return {
                                value: cityInfo.city_code,
                                label: cityInfo.city,
                            };
                        })
                    }  
                });
                setCountries(country)
                setCities({...city})

        }
        else{
            setError(regions?.message)
        }
        
    } catch (error) {
        setError(error)
    }
    finally{
        setLoadRegions(false)
    }
    }

    async function sendCountriesRequest() {
        setLoadRegions(true)
        try {
            const formData = qs.stringify({
                add_on: addonConstants.dedicated_ip
            })
            const apiResponse = await poster(url, formData, token);
            const { data: regions } = apiResponse;
            if(regions?.status){
                
                let country = [];
                regions?.body.map(async region => {
                    country.push({
                        value: region.iso_code,
                        label: region.name
                    }) 
                });
                setCountries(country)
                
            }
            else{
                setError(regions?.message)
            }
            
        } catch (error) {
            setError(error)
        }
        finally{
            setLoadRegions(false)
        }
    }
  
  return { countries, cities, loadRegions, error, setError, setLoadRegions };
};

export default useRegionsHook;
