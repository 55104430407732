import React from "react";
import Skeleton from "react-loading-skeleton";
import { InfoCard, SubscriptionBox } from "../../../styles/dashboard/Main";

const Loader = ({isPadding=false,isPromptLoader=false}) => {
  const widthSize = isPromptLoader ? 200 : 300 
  return (
    <>
      <SubscriptionBox open={true} className={`col-lg-8 ${isPadding && `mt-3 mx-3`}`} isPadding={isPadding} isPromptLoader={isPromptLoader}>
        <InfoCard hasborder noflex className="mb-3">
          <Skeleton height={20} width={widthSize} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={widthSize} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={widthSize} />
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
          <Skeleton height={20} width={widthSize} />
        </InfoCard>
        <InfoCard noflex className="pt-0">
          <Skeleton height={20} width={widthSize} />
        </InfoCard>
      </SubscriptionBox>
    </>
  );
};

export default Loader;
