import React from 'react'
import { UpgradeButton, UpgradeFAQ, UpgradePDetail, UpgradePlanContainer } from '../../../../styles/dashboard/Main'
import { content } from '../upgrade-constants'
import { useTranslation } from 'react-i18next'

const UpgradeInfoSection = ({GotoDiv=()=>{},selectedPlanInfo={},hasUpgradePlans={},plan_type="standard"}) => {

    const { t, i18n } = useTranslation();

  return (
    <UpgradePlanContainer noborder>
        <UpgradePDetail className="mt-5 upgrade-box">
            {content[plan_type]["questions"].map((i,key)=>{
                return  <div className={key>0 ? `mt-4`:``}>
                <p className="pdetail-p1">{t(i.q1_ug_plan)}</p>
                <p className="pdetail-p2 mt-2">
                    {Array.isArray(i?.a1_ug_plan) ?  <ul className="mt-4">{i?.a1_ug_plan?.map((ans)=>{return <li>{t(ans)}</li>})}</ul>:t(i?.a1_ug_plan)}
                </p>
            </div>
            })}

            <div className="mt-5 faq-box">
                <p className="pdetail-p3">{t('upgradeplan_faq_heading')}</p>
                {content[plan_type]["faqs"].map((i,key)=>{
                    return  <UpgradeFAQ className="mt-3">
                                <div className="py-3 px-3 pb-0">
                                    <p className="faq-p1">
                                        {t(i?.faq_q1)}
                                    </p>
                                    <p className="faq-p2">
                                        {t(i?.faq_a1)}
                                    </p>
                                </div>
                            </UpgradeFAQ>
                })}
                {hasUpgradePlans.status
                ? 
                <UpgradeButton className="mt-4 order-summary" onClick={GotoDiv}>
                    {t('upgradeplan_upgradeto_cta_text')} {selectedPlanInfo.name}
                </UpgradeButton>
                :
                <></>
                }
                
            </div>
        </UpgradePDetail>
    </UpgradePlanContainer>
  )
}

export default UpgradeInfoSection