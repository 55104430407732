export const purekeep = {
    en:{
        heading_1:"PureKeep - Premium Password Manager",
        para_1:"With PureKeep, you can go online peacefully without the need to remember your passwords.",
        heading_2:"Why do I need a password manager?",
        para_2:"",
        desc:[`<strong>Store passwords securely</strong> Store all your passwords in one secure location and access them easily with PureKeep.`,
              `<strong>Generate strong passwords</strong> Create strong and unique passwords to keep your privacy intact. `,
              `<strong>Stay organized</strong> Add favorites, assign tags, and create multiple vaults for different purposes to keep your data organized.`,
              `<strong>Securely export your data with a click</strong> Easily export your vaults as encrypted containers to avoid data breaches and ensure maximum security.`,
              `<strong>Sync across multiple devices</strong> Set up and safely access your stored information on all your mobile devices and desktop.`
      ],
        cta:"Get this Add-on",       
    },
    fr:{
      heading_1:"PureKeep - Gestionnaire de mots de passe Premium",
      para_1:"Avec PureKeep, vous pouvez en ligne en toute tranquillité sans avoir à souvenir vos mots de passe.",
      heading_2:"Pourquoi ai-je besoin d'un gestionnaire de mots de passe ?",
      para_2:"",
      desc:[
        `<strong>Stockez les mots de passe en toute sécurité  </strong> Stockez tous vos mots de passe dans un emplacement sécurisé et accédez-y facilement avec PureKeep. `,
        `<strong>Générez des mots de passe forts </strong>et des mots de passe uniques pour garder votre vie privée intacte. `,
        `<strong>Restez organisé  </strong>Ajoutez des favoris, attribuez des balises et créez plusieurs coffres-forts à des fins différentes pour garder vos données organisées. `,
        `<strong>Exportez vos données en toute sécurité en un clic </strong>Exportez facilement vos coffres-forts sous forme de conteneurs chiffrés pour éviter les violations de données et garantir une sécurité maximale. `,
        `<strong>Synchronisation sur plusieurs appareils </strong>Configurez et accédez en toute sécurité à vos informations stockées sur tous vos appareils mobiles et votre ordinateur de bureau. `,
      ],
      cta:"Obtenez ce module complémentaire",       
    },
    
    de:{
      heading_1:"PureKeep - Premium-Passwort-Manager",
      para_1:"Mit PureKeep können Sie friedlich online gehen, ohne sich Ihre Passwörter merken zu müssen.",
      heading_2:"Warum brauche ich einen Passwort-Manager?",
      para_2:"",
      desc:[
        `<strong>Passwörter sicher speichern </strong> Speichern Sie alle Ihre Passwörter an einem sicheren Ort und greifen Sie mit PureKeep einfach darauf zu. `,
        `<strong>Generieren Sie starke Passwörter </strong> und einzigartige Passwörter, um Ihre Privatsphäre zu wahren. `,
        `<strong>Bleiben Sie organisiert </strong> Fügen Sie Favoriten hinzu, weisen Sie Tags zu und erstellen Sie mehrere Tresore für verschiedene Zwecke, um Ihre Daten zu organisieren. `,
        `<strong>Exportieren Sie Ihre Daten sicher mit einem Klick </strong> Exportieren Sie Ihre Tresore ganz einfach als verschlüsselte Container, um Datenschutzverletzungen zu vermeiden und maximale Sicherheit zu gewährleisten. `,
        `<strong>Über mehrere Geräte hinweg synchronisieren </strong> Richten Sie Ihre gespeicherten Informationen auf all Ihren Mobilgeräten und Desktops ein und greifen Sie sicher darauf zu. `,
      ],
      cta:"Holen Sie sich dieses Add-on",       
    },
    zh:{
      heading_1:"PureKeep - 高級密碼管理器",
      para_1:"使用 PureKeep，您可以安心上網，無需記住密碼。",
      heading_2:"為什麼我需要密碼管理器？",
      para_2:"",
      
      desc:[
        `<strong>安全地存儲密碼 </strong> 將所有密碼存儲在一個安全位置，並使用 PureKeep 輕鬆訪問它們。 `,
        `<strong>建強密碼 </strong> 和獨特的密碼以保護您的隱私。 `,
        `<strong>保持組織 </strong> 添加收藏夾、分配標籤並為不同目的創建多個保管庫，以保持您的數據井井有條。 `,
        `<strong>單擊即可安全導出數據 </strong> 輕鬆將保管庫導出為加密容器，以避免數據洩露並確保最大安全性。 `,
        `<strong>單擊即可安全導出數據 </strong> 在您的所有移動裝置和台式機上設置並安全地訪問您存儲的信息。 `,
      ],
      cta:"獲取此插件",       
    },
    es:{
      heading_1:    "Purekeep - Premium Password Manager",
      para_1:    "Con Purekeep, puede conectarse en línea pacíficamente sin la necesidad de recordar sus contraseñas.",
      heading_2:    "¿Por qué necesito un administrador de contraseñas?",
      para_2:    "",
      desc:[
        `<strong>Almacene las contraseñas de forma segura </strong> guarde todas sus contraseñas en una ubicación segura y accedan fácilmente a Purekeep.`,
        `<strong>Genere contraseñas seguras </strong> y contraseñas únicas para mantener intacta su privacidad.`,
        `<strong>Manténgase organizado </strong> agregue los favoritos, asigne etiquetas y cree múltiples bóvedas para diferentes fines para mantener sus datos organizados.`,
        `<strong> Exporte de forma segura sus datos con un clic</strong> exporte fácilmente sus bóvedas como contenedores encriptados para evitar violaciones de datos y garantizar la máxima seguridad.`,
        `<strong>Sincronizar a través de múltiples dispositivos </strong>  configure y acceda de forma segura a su información almacenada en todos sus dispositivos móviles y escritorio.`,
      ],
      cta:"Obtenga este complemento",       
    },

    ru:{
      heading_1:"Pure Keep - премиум -диспетчер паролей",
      para_1:"С Pure Keep вы можете мирно выходить в Интернет без необходимости запоминать свои пароли.",
      heading_2:   "Зачем мне менеджер паролей?",
      para_2:"",
      desc:[
        `<strong>Безопасно храните пароли </strong> храните все свои пароли в одном безопасном месте и легко получайте их с помощью Pure Keep.`,
        `<strong>Создайте прочные пароли </strong> и уникальные пароли, чтобы сохранить вашу конфиденциальность нетронутой.`,
        `<strong>Оставайтесь организованными </strong> добавьте фавориты, назначайте теги и создайте несколько хранилищ для различных целей, чтобы сохранить ваши данные организованы.`,
        `<strong>Безопасно экспортируйте свои данные с помощью клика </strong>легко экспортировать свои хранилища в виде зашифрованных контейнеров, чтобы избежать устранения данных и обеспечить максимальную безопасность.`,
        `<strong>Синхронизируйте на нескольких устройствах  </strong> настройте и безопасно доступ к вашей сохраненной информации на всех ваших мобильных устройствах и настольных компьютерах.`,
      ],
      cta:"Получите это дополнение",       
    },
    
 
    tr:{
      heading_1:"PureKare - Premium Parola Yöneticisi",
      para_1:"PureKow ile, şifrelerinizi hatırlamaya gerek kalmadan huzur içinde çevrimiçi olabilirsiniz.",
      heading_2:"Neden bir şifre yöneticisine ihtiyacım var?",
      para_2:"",
      desc:[
        `<strong>Şifreleri güvenli bir şekilde saklayın  </strong>Tüm şifrelerinizi tek bir güvenli konumda saklayın ve PureKeKe ile kolayca erişin. `,
        `<strong>Gizliliğinizi sağlam </strong> tutmak için güçlü şifreler ve benzersiz şifreler oluşturun.`,
        `<strong>Organize kalın  </strong>favoriler ekleyin, etiket atayın ve verilerinizi düzenli tutmak için farklı amaçlar için birden fazla tonoz oluşturun.`,
        `<strong>Veri ihlallerini önlemek ve maksimum güvenlik sağlamak için verilerinizi tıklamayla güvenli bir şekilde dışa aktarın  </strong>tonozlarınızı şifreli kaplar olarak kolayca dışa aktarın.`,
        `<strong>Birden çok cihaz arasında senkronize edin</strong>depolanan bilgilerinizi tüm mobil cihazlarınızda ve masaüstünüzde ayarlayın ve güvenli bir şekilde erişin.`,
      ],
      cta:"Bu eklentiyi alın",       
    },

  
    pt:{
      heading_1: "PureKeep - Gerenciador de senhas premium",
      para_1: "Com o Purekeep, você pode ficar on -line pacificamente sem a necessidade de lembrar suas senhas.",
      heading_2: "Por que preciso de um gerenciador de senhas?",
      para_2:"",
      desc:[
        `<strong>Armazene as senhas com segurança </strong>  armazene todas as suas senhas em um local seguro e acessá -las facilmente com a Purekeep.`,
        `<strong>Gere senhas fortes </strong> e senhas exclusivas para manter sua privacidade intacta.`,
        `<strong>Mantenha -se organizado</strong> adicione os favoritos, atribua tags e crie vários cofres para fins diferentes para manter seus dados organizados.`,
        `<strong>Export seus dados com segurança com um clique </strong> exporte facilmente seus cofres como contêineres criptografados para evitar violações de dados e garantir a máxima segurança.`,
        `<strong>Sincronizar em vários dispositivos </strong>  Configure e acesse com segurança suas informações armazenadas em todos os seus dispositivos móveis e desktop.`,
      ],
      cta:"Obtenha este complemento",       
    },


  
    ja:{
      heading_1: "PureKeep-プレミアムパスワードマネージャー",
      para_1: "PureKeepを使用すると、パスワードを覚える必要なく、平和にオンラインで行くことができます。",
      heading_2: "なぜパスワードマネージャーが必要なのですか？",
      para_2:"",
      desc:[
        "<strong>パスワードを安全に保存 </strong>  すべてのパスワードを1つの安全な場所に保存し、PureKeepで簡単にアクセスできます。",
        "<strong>強力なパスワードを生成し</strong> プライバシーをそのままに保つための一意のパスワードを生成します。",
        "<strong>整理されたまま </strong>  お気に入りを追加し、タグを割り当て、さまざまな目的で複数のボールトを作成して、データを整理します。",
        "<strong>クリックしてデータを安全にエクスポートします </strong>  データ侵害を回避し、最大限のセキュリティを確保するために、暗号化されたコンテナとしてボールトを簡単にエクスポートします。",
        "<strong>複数のデバイス間で同期 </strong>  すべてのモバイルデバイスとデスクトップに保存された情報をセットアップして安全にアクセスします。",
      ],
      cta:"このアドオンを取得します",       
    },

    
  
    it:{
      heading_1: "Purekeep - Premium Password Manager",
      para_1: "Con Pure Keeep, puoi andare online pacificamente senza la necessità di ricordare le tue password.",
      heading_2: "Perché ho bisogno di un gestore di password?",
      para_2:"",
      desc:[
        "<strong>Memorizza le password in modo sicuro </strong> memorizza tutte le password in una posizione sicura e accedi a loro facilmente con purokeep.",
        "<strong>Genera password forti e passwor </strong> uniche per mantenere intatta la tua privacy.",
        "<strong>Resta organizzato </strong> aggiungi i preferiti, assegna tag e crea più volte per scopi diversi per mantenere i dati organizzati.",
        "<strong>Esporta in modo sicuro i tuoi dati con un clic </strong> esporta facilmente le volte come contenitori crittografati per evitare violazioni dei dati e garantire la massima sicurezza.",
        "<strong>Sincronizza su più dispositivi </strong> impostare e accedere in modo sicuro alle informazioni memorizzate su tutti i dispositivi mobili e il desktop.",
      ],
      cta:"Ottieni questo componente aggiuntivo",       
    },

  
    nl:{
      heading_1: "Purekeep - Premium wachtwoordbeheerder",
      para_1: "Met PureKeep kunt u vreedzaam online gaan zonder uw wachtwoorden te onthouden.",
      heading_2: "Waarom heb ik een wachtwoordbeheerder nodig?",
      para_2:"",
      desc:[
        "<strong>Wachtwoorden veilig opslaan </strong> Bewaar al uw wachtwoorden op één beveiligde locatie en ga ze gemakkelijk toegang toe met Pure Keep.",
        "<strong>Genereer sterke wachtwoorden </strong> en unieke wachtwoorden om uw privacy intact te houden.",
        "<strong>Blijf georganiseerd </strong> voeg favorieten toe, wijs tags toe en maak meerdere kluizen voor verschillende doeleinden om uw gegevens georganiseerd te houden.",
        "<strong>Exporteer uw gegevens veilig met een klik </strong> exporteer eenvoudig uw kluizen als gecodeerde containers om datalekken te voorkomen en maximale beveiliging te garanderen.",
        "<strong>Synchroniseer op meerdere apparaten </strong> Stel en zorg voor uw opgeslagen informatie op al uw mobiele apparaten en bureaublad.",
      ],
      cta:"Koop deze add-on",       
    },


  
    ko:{
      heading_1: "PUREKEEP- 프리미엄 비밀번호 관리자",
      para_1: "PureKeep을 사용하면 비밀번호를 기억할 필요없이 평화롭게 온라인으로 갈 수 있습니다.",
      heading_2: "암호 관리자가 필요한 이유는 무엇입니까?",
      para_2:"",
      desc:[
        "<strong>비밀번호를 안전하게 저장 </strong> 모든 비밀번호를 하나의 안전한 위치에 저장하고 PureKeep으로 쉽게 액세스하십시오.",
        "<strong>개인 정보를 손상시키지</strong> 않도록 강력한 비밀번호와 고유 한 비밀번호를 생성하십시오.",
         "<strong>정리되어 있습니다 </strong> 즐겨 찾기를 추가하고 태그를 할당하며 다양한 목적으로 여러 개의 금고를 작성하여 데이터를 구성합니다.",
        "<strong>클릭으로 데이터를 안전하게 내보내십시오 </strong> 데이터 유출을 피하고 최대 보안을 보장하기 위해 암호화 된 컨테이너로 보상을 쉽게 내 보냅니다.",
         "<strong>여러 장치에서 동기화 </strong> 모든 모바일 장치 및 데스크탑에서 저장된 정보를 설정하고 안전하게 액세스하십시오.",
      ],
      cta:"이 애드온을 얻으십시오",       
    },
  }