import React, { useState, useEffect } from 'react'
import { DashboardHead, InfoCard } from '../../../styles/dashboard/Main'
import StepBar from './StepBar';
import GridIconView from './GridIconView';
import MediaObjectView from './MediaObjectView';
import GroupListView from './GroupListView';
import DownloadView from './DownloadView';
import SignupView from './SignupView';
import DiscoverView from './DiscoverView';
import ErrorBoundary from '../../ErrorBoundary'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages'
import { sendEvents, sendGuestEvents} from "../../../tools/mpEvents"
import { constants, signupFrom } from '../../../tools/constants';
import { setOnBoardingHistory } from '../../../redux/on-boarding/actions';
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatchAsyncCall } from '../../../custom-hooks/useDispatchAsyncCall';
import { isAddon, isWhmcsUser } from '../../../tools/tools';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl, checkLocale, seti18Locale } from '../../../tools/tools';
import SmartBanner from './smartBanner';
import { isAndroid, isIOS } from 'react-device-detect';

const OnBoarding = ({history, accountReducer, onBoardingReducer, isLocked, subscriptions, getSubscriptionsRequest}) => {
    const [showBanner, setShowBanner] = useState(true);

    const isStorUsereAllowed = process.env.REACT_APP_ALLOW_UPSELL_APPSTORE_PLAYSTORE === "true" ? true : false;

    const { pathname } = useLocation();

    const token = localStorage.getItem("token")

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const locale = getLocaleFromUrl();

    const [appSlug, setAppSlug] = useState("")

    const [appName, setAppName] = useState("")

    const [onBoardings, setOnBoardings] = useState([])

    const [steps, setSteps] = useState([])

    const [allHistories, setAllHistories] = useState([])

    const [selectedStep, setSelectedStep] = useState(0)

    const [onBoardingId, setOnBoardingId] = useState(0)

    const [purpose, setPrpose] = useState("")

    const [connectionType, setConnectionType] = useState("")

    const [selectedSubStep, setSelectedSubStep] = useState(0)

    const [activation, setActivation] = useState(0)

    const [inSessionPurchased, setInSessionPurchased] = useState(false)

    const [noSub, setNoSub] = useState(false)

    const [crashed, setCrashed] = useState(false)

    const [isRenewal, setIsRenewal] = useState(false)
    const [notEligibleSignup, setNotEligibleSignup] = useState(false)

    const [completedSteps, setCompletedSteps] = useState([])

    const [stepNames, setStepNames] = useState([])

    const [fireChannelEvent , setFireChannelEvent] = useState(true);

    const [selectedDevice , setSelectedDevice] = useState(false);

    // Subscription Based States Start
    const [subscriptionData, setSubscriptionData] = useState({
        id:"",
        isTrial: false,
        isPaused: false,
        isPastDue: false,
        isExpired: false,
        isCanceled: false,
        isManualGateway: false,
        manualGatewayType: "",
        isRenewalRequired: false,
        isMobileGatewayForWHMCS: false,
        notEligibleSignupFrom: false,
        vpnusernames: [],
        expiresAt: "",
        state: "",
        data: {
            type: appSlug,
            subscription: "",
            currency: "",
            plan: ""
        },
        error: false
    })
    // Subscription Based States End

    const dispatch = useDispatch()

    const hasExpiredTime = ( d1, d2 ) => {
        return (d1 > d2)
    }

    useEffect(() => {

        setDefaultConfiguration(appSlug)

    }, [onBoardingReducer.selectedSubs])

    useEffect(() => {

        if (appSlug !== "") {

            sendEvents(
                {
                    event_name: `${constants.eventPrefixOnboarding}view_${appSlug}`,
                    event_properties: {
                        type: "system",
                        cta: appSlug,
                    }
                }, token
            );

            let textRenewal = (isRenewal) ? "renew_subscription" : steps[selectedStep].slug

            sendEvents(
                {
                    event_name: `${constants.eventPrefixOnboarding}view_${appSlug}_${textRenewal}`,
                    event_properties: {
                        type: "system",
                    }
                }, token
            );

        }

    }, [appSlug])

    useEffect(() => {
        if (appSlug !== "") {
            if(steps[selectedStep].slug === "connection"){
                sendEvents(
                    {
                        event_name: `${constants.eventPrefixOnboarding}view_${appSlug}_${steps[selectedStep].slug}`,
                        event_properties: {
                            type: "user",
                            selected_purpose: purpose
                        }
                    }, token
                );
            }
            else{
                sendEvents(
                    {
                        event_name: `${constants.eventPrefixOnboarding}view_${appSlug}_${steps[selectedStep].slug}`,
                        event_properties: {
                            type: "user",
                        }
                    }, token
                    );
                }
        }

        // For completed steps check from histroy local storage
        let userOnboardingHistory = JSON.parse(localStorage.getItem('userOnboardingHistory'));
        if (userOnboardingHistory) {
            userOnboardingHistory.map((item) => {
                if (
                    item.slug === appSlug && 
                    item.subscription_id === onBoardingReducer.selectedSubs
                ) {
                    setCompletedSteps(item.completedSteps)
                }
            })
        }


    }, [selectedStep])

    useEffect(() => {

        if (appSlug !== "" && onBoardingId != 0) {

            let stepNamesArray = [];

            if (steps) {
    
                stepNamesArray = steps.map((s) => (s.slug));
    
                setStepNames(stepNamesArray);
            }

            updateSubsData()

            let subStep = 0;

            // Validate sub step
            steps.map( (step, idx) => {

                if (selectedStep == idx) {
                    if (steps[selectedStep]?.data.length > 0) {
                        subStep = ( typeof  steps[selectedStep]?.data[selectedSubStep] != "undefined") ? selectedSubStep : 0
                    }
                }
            })

            setSelectedSubStep(subStep);

            // Update user history of current on-boarding in localStorage
            dispatch({ 
                type: "SETONBOARDINGHISTORY",
                payload: {
                    appSlug: appSlug,
                    subscription_id: onBoardingReducer.selectedSubs,
                    on_boarding_id: onBoardingId,
                    step_id: selectedStep,
                    sub_step_id: subStep,
                    stepNames: stepNamesArray,
                    isLocked: isLocked,
                    token: token
                }
            })
        }
        
    }, [selectedStep, selectedSubStep, activation, appSlug])
    const updateSubsData = () => {
        // Update Subscription Data
        if (subscriptions?.subscriptions?.body) {

            subscriptions?.subscriptions?.body.map((subs, idx) => {
                if (onBoardingReducer.selectedSubs == subs.id) {

                    let d = getData(subs);

                    setSubscriptionData(d)

                    if (
                        appSlug == "purevpn" && ((d.isExpired && !d.isManualGateway) || d.isPastDue)
                    ) {
                        setIsRenewal(true)
                    } else if (
                        appSlug != "purevpn" && (d.isPastDue)
                    ) {
                        setIsRenewal(true)
                    } else {
                        setIsRenewal(false)
                    }

                    if (d.notEligibleSignupFrom) {
                        setNotEligibleSignup(true)
                    }
                    // This is based on not current subs but if any other subscription in expired or pastdue state.
                    if (!d.error && isLocked) {
                        subscriptions?.subscriptions?.body.map((subss, idx) => {
                            if ( subss?.renew_invoice && subss?.renew_invoice != ""){
                                let y = getData(subss);
                                setSubscriptionData(y)

                                if (y.error) {
                                    if (
                                        ((y.isExpired && !y.isManualGateway) || y.isPastDue)
                                    ) {
                                        setIsRenewal(true)
                                    }
                                }
                            }
                        })
                    }
                }
            })
        } else {
            // In case no subscription is found and has a error
            if(subscriptions?.errors) {
                switch (subscriptions?.errorType) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        break;
                    case "jwt_exception":
                        toast.error(t(APIFAILED))
                        dispatch({type:"LOGOUT"})
                        break; 
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break;    
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        break;  
                    case "no_subscription_exception":
                        setNoSub(true)
                        setCrashed(true)
                        triggerNoSubEvent(subscriptions?.message)
                        break;
                    case "APIFAILED":
                        setCrashed(true)
                        triggerNoSubEvent(subscriptions?.message)
                        break;
                    default:
                        setCrashed(true)
                        triggerNoSubEvent(subscriptions?.message)
                        break;
                }
            }
        }
    }

    const getData = (subs) => {
        let hasError = false;
        let isTrial = false, isPaused = false, isPastDue = false, isExpired = false, isCanceled = false, isManualGateway = false, isMobileGatewayForWHMCS = false, notEligibleSignupFrom = false ,isWHMCS = false;
        let manualGatewayType = "",id="";
        let addon = null

        if ((subs?.is_trial && subs?.is_trial === true) || (subs?.trial_ends_at === subs?.end_date)) { // Check for Trial Subscription
            isTrial = true
            hasError = true;
        }
        if (subs?.state && subs?.state === "past_due") { // Check for Expired Subscription
            isPastDue = true
            hasError = true;
        }
        if (subs?.state && subs?.state === "paused") { // Check for Paused Subscription
            isPaused = true
            hasError = false;
        }
        if (subs?.state && subs?.state === "expired") { // Check for Expired Subscription
            if (subs?.expiration_reason && (subs?.expiration_reason === "canceled" || subs?.expiration_reason === "refunded")) {
                isCanceled = true
                hasError = true;
            } else {
                isExpired = true
                hasError = true;
            }
        }
        if (subs?.state && subs?.state === "canceled") { // Check for Expired Subscription
            isCanceled = true
            hasError = false;
        }
        //Add pm gateway
        manualGatewayType = subs?.payment_gateway
        if (subs?.payment_gateway_offline && subs?.payment_gateway_offline === true) { // Check for Manual Gateway / Online Gateway
            if (subs?.payment_gateway) {
                // Set gateway name / gateway type
                manualGatewayType = subs?.payment_gateway
                if(isWhmcsUser(subs?.account?.billingType)){
                    isWHMCS = true
                    // if user has_mobile_gateway_for_whmcs then dont throw any error
                    if(!subs?.account?.has_mobile_gateway_for_whmcs && isStorUsereAllowed){
                        hasError =  true;
                        isManualGateway = true;
                    }
                }
            }
        }
        if (subs?.account?.has_mobile_gateway_for_whmcs && subs?.account?.has_mobile_gateway_for_whmcs === true && !isStorUsereAllowed) { // Condition for whmcs has_mobile_gateway_for_whmcs
            isMobileGatewayForWHMCS = true
            hasError = true; 
        }

        if (signupFrom.includes(subs?.account?.signup_from) ) { // Condition for stacksocial
            notEligibleSignupFrom = true 
            hasError = true; 
        }

        addon = isAddon(subs?.add_ons,[appSlug,"purescan"])

        return {
            isTrial: isTrial,
            isPaused: isPaused,
            isPastDue: isPastDue,
            isExpired: isExpired,
            isCanceled: isCanceled,
            isManualGateway: isManualGateway,
            isMobileGatewayForWHMCS:isMobileGatewayForWHMCS,
            notEligibleSignupFrom:notEligibleSignupFrom,
            manualGatewayType: manualGatewayType,
            state: subs?.state,
            vpnusernames: subs?.vpnusernames,
            data: {
                type: appSlug,
                subscription: subs.id,
                currency: subs.currency,
                plan: subs.plan?.id,
                number: addon?.number ?? null
            },
            error: hasError,
            expiresAt: subs?.expires_at,
            id:subs?.id
        }

    }

    const setDefaultConfiguration = (appSlug = "") => {

        if (appSlug !== "") {

            if (onBoardingReducer?.onBoardings) {

                if (onBoardings.length == 0) {
                    setOnBoardings(onBoardingReducer?.onBoardings)
                }

                onBoardingReducer?.onBoardings.map( (onBoarding, idx) => {
                    if (onBoarding.slug === appSlug) {
                        setSteps(onBoarding.steps)
                        setAppName(onBoarding.name)
                        setOnBoardingId(onBoarding.id)

                        if (isLocked) {
                            setSelectedStep(0)
                        } else {

                            let userOnboardingHistory = JSON.parse(localStorage.getItem('userOnboardingHistory'));

                            if (userOnboardingHistory) {

                                let hasHis = false;

                                userOnboardingHistory.map((item) => {
                                    if (
                                        item.slug === appSlug && 
                                        item.subscription_id === onBoardingReducer.selectedSubs
                                    ) {
                                        hasHis = true;

                                        setSelectedStep(item?.step_id)

                                        if (item?.sub_step_id) {
                                            setSelectedSubStep(item?.sub_step_id)
                                        }
                                        if (item?.activation) {
                                            setActivation(item?.activation)
                                        }
                                    }
                                })

                                if (!hasHis && onBoardingId != 0) {

                                    let defaultStartStep = (isLocked) ? 0 : 1;

                                    setSelectedStep(defaultStartStep)

                                    setActivation(0)

                                    // Update user history of current on-boarding
                                    dispatch({ 
                                        type: "SETONBOARDINGHISTORY",
                                        payload: {
                                            appSlug: appSlug,
                                            subscription_id: onBoardingReducer.selectedSubs,
                                            on_boarding_id: onBoardingId,
                                            step_id: defaultStartStep,
                                            sub_step_id: 0,
                                            stepNames: stepNames,
                                            isLocked: isLocked,
                                            token: token
                                        }
                                    })
                                }

                            } else {

                                // Update user history of current on-boarding
                                dispatch({ 
                                    type: "SETONBOARDINGHISTORY", 
                                    payload: {
                                        appSlug: appSlug,
                                        subscription_id: onBoardingReducer.selectedSubs,
                                        on_boarding_id: onBoardingId,
                                        step_id: (isLocked) ? 0 : 1,
                                        sub_step_id: 0,
                                        stepNames: stepNames,
                                        isLocked: isLocked,
                                        token: token
                                    }
                                })

                                if (isLocked) {
                                    setSelectedStep(0)
                                } else {
                                    setSelectedStep(1)
                                }
                                setSelectedSubStep(0)
                            }
                        }

                        
                    }
                })

                updateSubsData();
            }
        } else {
            let pathnameSplit = pathname.split("/")

            if (typeof pathnameSplit[3] != undefined) {
                setAppSlug(pathnameSplit[3])
                setDefaultConfiguration(pathnameSplit[3])
            }
        }

    }

    const selectStepCallback = (index) => {

        setSelectedStep(index)
        //reset Substep to zero after step is changed 
        setSelectedSubStep(0)
    }

    const sendNextOrNoOrNoButtonEvents = (index, text, operations = "", heading,shortcutName) => {

        let slug = filterSlug(text)

        if (operations.includes("question")) {
            if(shortcutName){
                sendEvents(
                    {
                    event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${steps[index].slug}`,
                    event_properties: {
                        connect_via: "shortcut",
                        selected_shortcut: shortcutName ?? "N/A",
                        selected_purpose: purpose,
                        selected_device: "Desktop",
                    }
                    }, token
                );
            }
            else{
                sendEvents(
                    {
                    event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${steps[index].slug}`,
                    event_properties: {
                        question: heading,
                        cta: slug,
                    }

                    }, token
                );
            }

        } else {
            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${steps[index].slug}`,
                event_properties: {
                    cta: slug,
                }
        
                }, token
            );
        }
    };

    const purechaseOrNewSubsButtonEvents = (text) => {

        let slug = filterSlug(text)

        sendEvents(
            {
                event_name: "ma_click_view_addon",
                event_properties: {
                    cta: slug,
                    source: "onboarding",
                    addon_name: appSlug,
                }
            }, token
        );

        sendEvents(
            {
                event_name: "ma_click_purchase_addon",
                event_properties: {
                    cta: slug,
                    source: "onboarding",
                    addon_name: appSlug,
                }
            }, token
        );
    };

    const subStepCallback = (index) => {

        setSelectedSubStep(index)
    }

    const setPurposeCallback = (value,connType) => {

        sendEvents(
            {
              event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${steps[selectedStep].slug}`,
              event_properties: {
                type: "user",
                cta: value,
              }
    
            }, token
        );

        setPrpose(value)
        setConnectionType(connType)

    }

    const next = (index) => {

        setSelectedStep(index)
    }

    const purchasedAddOnCallback = () => {
                
        // Get latest onboarding data.
        setInSessionPurchased(true);
        dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })

        // Update user history of current on-boarding
        dispatch({ 
            type: "SETONBOARDINGHISTORY", 
            payload: {
                appSlug: appSlug,
                subscription_id: onBoardingReducer.selectedSubs,
                on_boarding_id: onBoardingId,
                step_id: selectedStep,
                sub_step_id: 0,
                stepNames: stepNames,
                isLocked: isLocked,
                token: token
            }
        })

        next(selectedStep + 1)
    }

    const filterSlug = (slug) => {
        let value = slug

        if (value !== "") {
            value = value.toLowerCase()
            value = value.replace(/ /g,"_")
            value = value.replace("'", "")
            value = value.replace("\"", "")
            value = value.replace("_tag", "")
        } else {
            slug = "";
        }

        return value;
    }

    const triggerNoSubEvent = (errorMessage) => {
        sendEvents({
            event_name:"ma_fail_subscriptiondetails",
            event_properties:{
                reason: errorMessage ?? "Unable to fetch subscriptions.",
                source: appSlug
            }
        }, token)
    }

    const normalBtnCallback = (value) => {
        sendEvents(
            {
              event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${steps[selectedStep].slug}`,
              event_properties: {
                type: "user",
                cta: value,
              }
    
            }, token
        );
    }


    if(crashed){
        if(noSub){
            return <ErrorBoundary heading="Uh oh!" text="It seems like you don't have any active subscription. Get a new subscription or talk to support incase of any questions." noSubscription></ErrorBoundary>
        }
        return <ErrorBoundary></ErrorBoundary>
    } else {
        return (
            <div>
                { (isAndroid || isIOS) && !(localStorage.getItem('bannerClosed').toLowerCase() === "true") ? 
                // Show Smart Banner for iOS and Android 
                <SmartBanner isAndroid={isAndroid} isIOS={isIOS} setShowBanner={setShowBanner} ></SmartBanner> 
                :
                null
                }
                {
                    steps.length > 0 ? ( 
                        <>
                            {
                                // This is temporary for static purekeep page testing.
                                constants.newSignupViewApps.includes(appSlug) && isLocked && !subscriptionData.error ? (
                                    <>
                                        <DashboardHead>
                                            <h2>{t('onboarding_introducing')} {appName}</h2>
                                            {(() => {
                                                if (appSlug === constants.pureprivacy) {
                                                    return (
                                                        <p></p>
                                                    )
                                                } else if (appSlug === constants.purekeep) {
                                                    return (
                                                        <p>{t('onboarding_para_safely_access_passwords')}
                                                        </p>
                                                    )
                                                } else if (appSlug === constants.purecrypt) {
                                                    return (
                                                        <p>{t('onboarding_para_data_encyrption')}</p>
                                                    )
                                                }
                                                 else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })()}
                                        </DashboardHead>
                                    </>
                                ) : (
                                    <>
                                        <DashboardHead>
                                            {(() => {
                                                if (appSlug === constants.pureprivacy) {
                                                    return (
                                                        <h2>{t('onboarding_para_welcome_text')}</h2>
                                                    )
                                                }else {
                                                    return (
                                                        <h2>{t('onboarding_heading')}</h2>
                                                    )
                                                }
                                            })()}
                                            {(() => {
                                                if (appSlug === constants.pureprivacy) {
                                                    return (
                                                        <p>{t('onboarding_para_internet_life_secure')}</p>
                                                    )
                                                }else {
                                                    return (
                                                        <p>{t('onboarding_para')}</p>
                                                    )
                                                }
                                            })()}
                                        </DashboardHead>
                                        <StepBar steps={steps} appSlug={appSlug} isLocked={isLocked} completedSteps={completedSteps} isRenewal={isRenewal} notEligibleSignup={notEligibleSignup} inSessionPurchased={inSessionPurchased} subscriptionData={subscriptionData} selectedStep={selectedStep} selectStepCallback={selectStepCallback} setFireChannelEvent={setFireChannelEvent} />
                                    </>
                                )
                            } 
                        </>
                    ) : null
                }
                <InfoCard>
                    {
                        steps.length > 0 ? ( 
                            
                            <div className={`on-boarding on-boarding-${appSlug}`}>
                                {
                                    steps.map((step, idx) => {
                                        if (idx == selectedStep) {
                                            let displayData =
                                            step.slug == "connection" ?
                                            connectionType && connectionType.length > 1 ? step.data[connectionType] : step.data[step.slug.toLowerCase()]
                                            :
                                            step.data;

                                            let html = null;
                                            switch(step.type){
                                                case "signup":
                                                    if (isLocked) {
                                                        html = (<SignupView key={idx} setCrashed={setCrashed} index={idx} step={step} appSlug={appSlug} appName={appName} isLocked={isLocked} next={next} inSessionPurchased={inSessionPurchased} selectedStep={selectedStep} selectStepCallback={selectStepCallback} purchasedAddOnCallback={purchasedAddOnCallback} purechaseOrNewSubsButtonEvents={purechaseOrNewSubsButtonEvents} history={history} subscriptionData={subscriptionData} normalBtnCallback={normalBtnCallback} />)
                                                    }
                                                    break;
                                                case "grid_icon":
                                                    html = (<GridIconView key={idx} purpose={purpose} step={step} appSlug={appSlug} selectStepCallback={selectStepCallback} selectedStep={selectedStep} setPurposeCallback={setPurposeCallback} setFireChannelEvent={setFireChannelEvent} />)
                                                    break;
                                                case "download":
                                                    html = (<DownloadView key={idx} purpose={purpose} appSlug={appSlug} appName={appName} step={step} selectedStep={selectedStep} selectStepCallback={selectStepCallback} isOnBoarding={true} />) 
                                                    break;
                                                case "media_object":
                                                    html = (<MediaObjectView key={idx}
                                                        purpose={purpose}
                                                        setSelectedDevice={setSelectedDevice}
                                                        setFireChannelEvent={setFireChannelEvent}
                                                        displayData={displayData}
                                                        selectedStep={selectedStep} selectedSubStep={selectedSubStep} subStepCallback={subStepCallback} selectStepCallback={selectStepCallback} sendNextOrNoOrNoButtonEvents={sendNextOrNoOrNoButtonEvents} connectionType={connectionType} view={step.slug}/>)
                                                    break;
                                                case "discover":
                                                    html = (<DiscoverView key={idx} step={step} appSlug={appSlug} appName={appName} />)
                                                    break;
                                                case "group_list":
                                                    html = (<GroupListView key={idx} step={step} appSlug={appSlug} appName={appName} displayData={displayData} selectedStep={selectedStep} selectStepCallback={selectStepCallback} setActivation={setActivation} />)
                                                    break;
                                            }
                                            return html
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </div>
    
                        ) : null
                    }
                </InfoCard>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer,
        channels: state.channel,
        subscriptions: state.subscriptionsReducer,
    };
};

export default connect(mapStateToProps, {getSubscriptionsRequest})(OnBoarding)