import React, { useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { sendEvents } from '../../../../tools/mpEvents';

const PluginModal = ({ pluginModal, setPluginModal, currentTool }) => {

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const Spacer = () => <div className={!inputRef ? 'line disabled' : 'line'}></div>;

    const token = localStorage.getItem("token");

    const toggleCardlistModal = () => {
        setPluginModal(!pluginModal);
    }


    const clickJoinCardList = (e) => {
        e.preventDefault();
        if(!inputRef.current.value || !inputRef2.current.value || !inputRef3.current.value) {
            return;
        }
        let reason = { query: {} };
        reason.query.Q1 = "Q1: Why are you interested in using this feature ?";
        reason.query.A1 = inputRef.current.value;
        reason.query.Q2 = "Q2: How do you plan to use it ?";
        reason.query.A2 = inputRef2.current.value;
        reason.query.Q3 = "Q3: Are you using any similar product or tool ?";
        reason.query.A3 = inputRef3.current.value;

        sendEvents({
            event_name: "ma_submit_tool_usage_form",
            event_properties: {
                "source": "Pure AI",
                "reason": reason,
                "tool": currentTool
            }
        }, token)
        setPluginModal(false);
    }

    const isEmpty = (inp) => {
        return inp.current === null;
    }

    const handleChange = () => {
        let ref1 = !isEmpty(inputRef) && inputRef.current.value.trim() !== "";
        let ref2 = !isEmpty(inputRef2) && inputRef2.current.value.trim() !== "";
        let ref3 = !isEmpty(inputRef3) && inputRef3.current.value.trim() !== "";
        document.getElementById('ref1').setAttribute("disabled", "disabled");
        document.getElementById('ref2').setAttribute("disabled", "disabled");
        document.getElementById('ref3').setAttribute("disabled", "disabled");
        document.getElementById('joinPluginBtn').setAttribute("disabled", "disabled");
        if(ref1) {
            document.getElementById('ref1').removeAttribute("disabled");
        }
        if(ref2) {
            document.getElementById('ref2').removeAttribute("disabled");
        }
        if(ref3) {
            document.getElementById('ref3').removeAttribute("disabled");
        }
        if(ref1 && ref2 && ref3) {
            document.getElementById('joinPluginBtn').removeAttribute("disabled");
        }
    }

    return (
        <Modal backdrop={pluginModal} isOpen={pluginModal} toggle={toggleCardlistModal} wrapClassName="waitlistCardWrapper">
            <ModalHeader className='modal-header-waitlist' toggle={toggleCardlistModal}>
                <div className='en-ds-div'>
                    <span className='question-header' id='ref1' disabled="true" >Question1</span>
                    <Spacer />
                    <span className='question-header' id='ref2' disabled="true" >Question2</span>
                    <Spacer />
                    <span className='question-header' id='ref3' disabled="true" >Question3</span>
                </div>
            </ModalHeader>
            <ModalBody>
                <h3 className='modal-heading'>We are currently working on this Plugin! Let us know what you think about this</h3>
                <form onChange={handleChange} onSubmit={clickJoinCardList} className='searchBar'>
                    <span className='question'>1. Why are you interested in using this feature?</span>
                    <textarea name='cardQ1' ref={inputRef} placeholder='Problems I am currently facing is ...'></textarea>
                    <span className='question'>2. How do you plan to use it ?</span>
                    <textarea name='cardQ2' ref={inputRef2} placeholder='I would use it to solve ...'></textarea>
                    <span className='question'>3. Are you using any similar product or tool?</span>
                    <textarea name='cardQ3' ref={inputRef3} placeholder=''></textarea>
                    <div className='cardSubBtn'>
                        <button disabled="true" id='joinPluginBtn' className="joinButton">Submit</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}
export default PluginModal;