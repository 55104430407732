import { GETCOUNTRIES,ERRORS,SUCCESS_COUNTRIES } from "./types";

const initialState = {
  country: null,
  loading: false,
  errors:false,
  errorType:"",
  errorMessage:""
};

const country = (state=initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case GETCOUNTRIES:
      return { ...state, loading: true, errors:false };
      break;
    case SUCCESS_COUNTRIES:
      return { ...state, loading: false, country: payload.country ,errors:false };
      break;
    case ERRORS:
      return { ...state, loading: false, errors:true, errorMessage: payload.message , errorType:payload.code };
      break;
    default:
      return {...state};
      break;
  }
};

export default country;
