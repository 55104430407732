import { ERRORS, GETTICKETS, LOADING, SETTICKETSBYPAYLOAD, SETTICKETDETAILSBYPAYLOAD } from "./types";

const initialState = {
    tickets: null,
    details:null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const ticketsReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true, response:false}
            break;
        case GETTICKETS:
            if(state?.tickets?.body.length > 0 && payload.body.length === 0){
                return {...state, loading:false, errors:false}
            }else if(state?.tickets?.body.length === 0 && payload.body.length === 0){
                return {...state, tickets:payload, loading:false, errors:false}
            }else{
                return {...state, tickets:payload, loading:false, errors:false}
            }
            break;
        case SETTICKETSBYPAYLOAD:
            return {...state, tickets:{...state.tickets, body: [...payload, ...state.tickets.body]}, loading:false, errors:false}
            break;
        case SETTICKETDETAILSBYPAYLOAD:
            return {...state, details: {...state.details, [payload.body.id]: payload }}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}