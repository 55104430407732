import React from 'react'
import { useState } from 'react';
import { InputBox } from '../../../../styles/dashboard/PureBuddy';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../manual-connection/Loader';
import { fetcher } from '../../../../tools/tools';
import RatingStars from './RatingStars'
import CloseIconSVG from '../../../../assets/svg/CloseIconSVG'
import { sendEvents } from '../../../../tools/mpEvents';
import { setCookie } from '../../../../tools/tools';
import Streaming from '../../../../assets/streaming.svg'
import Gaming from '../../../../assets/gaming.svg'
import Security from '../../../../assets/security.svg'
import SecurityWhite from '../../../../assets/security-white.svg'
import GamingWhite from '../../../../assets/gaming-white.svg'
import StreamingWhite from '../../../../assets/streaming-white.svg'
import { useTranslation } from 'react-i18next'


const PopularPrompts = ({ isPopularPrompts, typePrompt, clickPrompt, redirectCta, reviewPopupToggle }) => {

    const { t, i18n } = useTranslation();
    
    const [prompts, setPrompts] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [filterLoading, setFilterLoading] = useState(false);

    const [error, setError] = useState(null);

    const { showFeedbackBox, setShowFeedbackBox } = reviewPopupToggle;


    const feedbackRef = useRef(null);
    const feedbackRatingRef = useRef(0);
    const [feedbackRatingErr, setFeedbackRatingErr] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");

    const [filteredPrompts, setFilteredPrompts] = useState(prompts);
    const [activeButton, setActiveButton] = useState(null);

    const token = localStorage.getItem("token")

    useEffect(() => {
        fetcher('public/getPrompts', token)
        .then(response => {
            if (!response.status) {
                throw new Error('Failed to fetch prompts');
            }
            return response;
        })
        .then(data => {
            setFilteredPrompts(data['data'].body);
            setPrompts(data['data'].body)
            setIsLoading(false);
        })
        .catch(error => {
            setError(error);
            setIsLoading(false);
            setFilterLoading(false);
        })
    }, [isPopularPrompts, token]);


    const handleSearch = event => {
        setSearchTerm(event.target.value);
        const filtered = filteredPrompts?.filter(prompt =>
            prompt.prompt.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setFilteredPrompts(filtered);
        if (event.target.value.length === 0) {
            setFilteredPrompts(prompts)
        }
    };

    const fireClick = () => {
        clickPrompt({ prompt: searchTerm }, true)
        setSearchTerm("")
        setFilteredPrompts(prompts)
    }


    const newFilteredDataStream = (category) => {
        if(isFiltered && isFiltered === category){
            setFilteredPrompts(prompts);
            setActiveButton(null);
            setIsFiltered(false);
        } else {
            let filteredData = prompts.filter((values) => {
                return values.category === category;
            });
            setFilteredPrompts(filteredData);
            setActiveButton(category === activeButton ? null : category);
            setIsFiltered(category);
        }
    }

    const noDisplay = () => {
        return <tr className="tr-error">
            {!filterLoading && (
                <td colSpan="2">
                    <div className='no-search-result'>
                        {t('pureai_no_result_for')}<br /> <strong>{searchTerm}</strong><br /><Link className='mt-2' onClick={() => fireClick(searchTerm)}>{t('pureai_click_to_search')}</Link>
                    </div>
                </td>
            )}

        </tr>
    }

    const submitFeedback = () => {
        if (feedbackRatingRef?.current === 0) {
            setFeedbackRatingErr('Please rate your experience');
        } else {
            if (feedbackRatingErr) setFeedbackRatingErr(null);

            sendEvents({
                event_name: "ma_pure_ai_feedback",
                event_properties: {
                    rating: feedbackRatingRef.current,
                    feedback: feedbackRef.current.value
                }
            }, token)
            setCookie('ratingPopupShown', true);
            setShowFeedbackBox(false);
        }
    }

    if (isLoading) {
        return <Loader className="mt-2" isPromptLoader={true} />;
    }
    if (error) {
        return <div>{t('pureai_prompt_error')} {error.message}</div>;
    }

    return (
        <>
            <div className="input-search">
                <InputBox placeholder={t('pureai_click_to_search')} value={searchTerm} inputWidth onChange={handleSearch} />
            </div>
            {/* prompt table view here */}
            <div className='filterDiv'>
                <span className='filters'>{t('pureai_filters')}</span>
                <div className='filterBtnDiv'>
                    <button className={activeButton === 'Streaming' ? 'promptFilterBtnactive' : 'promptFilterBtn'} onClick={() => newFilteredDataStream('Streaming')}>
                        <img src={activeButton === 'Streaming' ? StreamingWhite : Streaming} alt="streaming-icon" className='fliterBtnIcon' />
                        {t('onboarding_purevpn_purpose_streaming')}
                    </button>
                    <button className={activeButton === 'Gaming' ? 'promptFilterBtnactive' : 'promptFilterBtn'} onClick={() => newFilteredDataStream('Gaming')}>
                        <img src={activeButton === 'Gaming' ? GamingWhite : Gaming} alt="gaming-streaming-icon" className='fliterBtnIcon' />
                        {t('onboarding_purevpn_purpose_gaming')}
                    </button>
                    <button className={activeButton === 'Security' ? 'promptFilterBtnactive' : 'promptFilterBtn'} onClick={() => newFilteredDataStream('Security')}>
                        <img src={activeButton === 'Security' ? SecurityWhite : Security} alt="security-icon" className='fliterBtnIcon' />
                        {t('onboarding_purevpn_purpose_security')}
                    </button>
                </div>
            </div>
            <div className='popular-prompt-view'>
                <table className={filteredPrompts?.length ? ` ` : `layout-fixed`}>
                        <tr>
                            <th className='prompt'>{t('pureai_prompt_text')}</th>
                        </tr>
                        <ul></ul>
                        {
                            filterLoading && (
                                <Loader className="mt-2" isPromptLoader={true} />
                            )
                        }
                        {
                            !filterLoading && filteredPrompts?.length ? filteredPrompts?.map((prompt, item) => (
                                <tr className='hoverable' onClick={() => clickPrompt(prompt)} key={item}>
                                    <td>{prompt.prompt}</td>
                                </tr>
                            )) : noDisplay()

                        }
                </table>
            </div >
            <div className="bottom-section">
                <hr className={`mt-2 d-none`} />
                <span className='faq-button' onClick={() => redirectCta(true)}>
                    {t('pureai_faqs')}
                </span>
                <span className='feedback-btn' onClick={() => redirectCta()}>
                    {t('pureai_help_us_improve')}
                </span>
                <span className='review-btn' onClick={() => setShowFeedbackBox(true)}>
                    {t('pureai_rate_your_experience')}
                </span>
                {showFeedbackBox && <div className='review-box'>
                    <div className='top-bar'>
                        <h6 className='hed'>{t('pureai_pureai_working')}</h6>
                        <CloseIconSVG onClick={() => setShowFeedbackBox(false)} className='close-icon' fillColor="#fff" width={24} height={24} />
                    </div>
                    <RatingStars ratingGetter={(rating) => {
                        feedbackRatingRef.current = rating
                    }} />
                    <textarea ref={feedbackRef} className='textarea' placeholder={t('pureai_give_feedback')} />
                    {feedbackRatingErr && (<p className='feedBackErr'>{feedbackRatingErr}</p>)}
                    <button className='review-submit' onClick={submitFeedback}>{t('pureai_submit_feedback')}</button>
                </div>}
            </div>
        </>
    )
}

export default PopularPrompts

