import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { fetcher } from '../../tools/tools';
import { errorsType, getSubscriptionsType, loadingType, resetSubscriptionsPayload } from './actions';
import i18n from "i18next";

function* subscriptionsAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const subscriptions =  yield fetcher("subscription", action.payload, cancelTokenSource);
        const {data: response} = subscriptions;
        if(response?.status){
            yield put(getSubscriptionsType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (i18n.t(APIFAILED)) }))
    }
    finally{
        if(yield cancelled()){
        cancelTokenSource.cancel();
        }
    }
}

function* resetSubscription(action){
    yield put(resetSubscriptionsPayload())
}

export function* watchSubscriptionsRequest(){
    yield takeLatest("GETSUBSCRIPTIONSREQUEST", subscriptionsAsync)
}

export function* watchResetSubscriptionRequest(){
    yield takeLatest("RESETSUBSCRIPTIONREQUEST", resetSubscription)
}