import React, { useState } from "react";
import {
	PopupBody,
	DropdownDiv,
	InfoCard,
	DashboardHead,
	SubscriptionCancelWrapper,
	CardBox,
	WireGuardKeysCard,
	UpgradeNA,
} from "../../../../styles/dashboard/Main";
import { DestructBtn, PrimaryBtn } from "../../../../styles/Generic";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Input } from "reactstrap";
import {
	APIFAILED,
	INVALIDTOKEN,
	TOKENEXPIRED,
} from "../../../../tools/responseMessages";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { poster, get, dontAllowManualConfig } from "../../../../tools/tools";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import qs from "qs";
import Skeleton from "react-loading-skeleton";
import { sendEvents } from "../../../../tools/mpEvents";
import ErrorBoundary from "../../../ErrorBoundary";
import { useTranslation } from 'react-i18next'

const GenerateWireGuardKey = ({
	setWGLoading,
	history,
	WGLoading,
	WGErrorMessage,
	isCancel,
	setWGErrorMessage,
	reGen,
	status,
	selectedSubscription,
	sendWGDataToSubsComponent,
	subLoading,
	crashed,
	countryInfo,
	closeModal,
	devicesData,
	deviceError,
	setSelectState,
	currentSubData={}
}) => {

	const { t, i18n } = useTranslation();

	const { name, iso, city } = countryInfo;

	const [countries, setCountries] = useState(false);

	const [selectedDeviceData, setSelectedDeviceData] = useState(false);

	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const [devices, setDevices] = useState(false);

	const token = localStorage.getItem("token");

	const [loading, setloading] = useState(true);

	const [ctaLoading, setctaLoading] = useState(false);

	useEffect(() => {

		/*! SPDX-License-Identifier: GPL-2.0
		 *
		 * Copyright (C) 2015-2020 Jason A. Donenfeld <Jason@zx2c4.com>. All Rights Reserved.
		 */
		function gf(init) {
			var r = new Float64Array(16);
			if (init) {
				for (var i = 0; i < init.length; ++i) r[i] = init[i];
			}
			return r;
		}
		function pack(o, n) {
			var b,
				m = gf(),
				t = gf();
			for (var i = 0; i < 16; ++i) t[i] = n[i];
			carry(t);
			carry(t);
			carry(t);
			for (var j = 0; j < 2; ++j) {
				m[0] = t[0] - 0xffed;
				for (var i = 1; i < 15; ++i) {
					m[i] = t[i] - 0xffff - ((m[i - 1] >> 16) & 1);
					m[i - 1] &= 0xffff;
				}
				m[15] = t[15] - 0x7fff - ((m[14] >> 16) & 1);
				b = (m[15] >> 16) & 1;
				m[14] &= 0xffff;
				cswap(t, m, 1 - b);
			}
			for (var i = 0; i < 16; ++i) {
				o[2 * i] = t[i] & 0xff;
				o[2 * i + 1] = t[i] >> 8;
			}
		}
		function carry(o) {
			var c;
			for (var i = 0; i < 16; ++i) {
				o[(i + 1) % 16] += (i < 15 ? 1 : 38) * Math.floor(o[i] / 65536);
				o[i] &= 0xffff;
			}
		}
		function cswap(p, q, b) {
			var t,
				c = ~(b - 1);
			for (var i = 0; i < 16; ++i) {
				t = c & (p[i] ^ q[i]);
				p[i] ^= t;
				q[i] ^= t;
			}
		}
		function add(o, a, b) {
			for (var i = 0; i < 16; ++i) o[i] = (a[i] + b[i]) | 0;
		}
		function subtract(o, a, b) {
			for (var i = 0; i < 16; ++i) o[i] = (a[i] - b[i]) | 0;
		}
		function multmod(o, a, b) {
			var t = new Float64Array(31);
			for (var i = 0; i < 16; ++i) {
				for (var j = 0; j < 16; ++j) t[i + j] += a[i] * b[j];
			}
			for (var i = 0; i < 15; ++i) t[i] += 38 * t[i + 16];
			for (var i = 0; i < 16; ++i) o[i] = t[i];
			carry(o);
			carry(o);
		}
		function invert(o, i) {
			var c = gf();
			for (var a = 0; a < 16; ++a) c[a] = i[a];
			for (var a = 253; a >= 0; --a) {
				multmod(c, c, c);
				if (a !== 2 && a !== 4) multmod(c, c, i);
			}
			for (var a = 0; a < 16; ++a) o[a] = c[a];
		}
		function clamp(z) {
			z[31] = (z[31] & 127) | 64;
			z[0] &= 248;
		}
		function generatePublicKey(privateKey) {
			var r,
				z = new Uint8Array(32);
			var a = gf([1]),
				b = gf([9]),
				c = gf(),
				d = gf([1]),
				e = gf(),
				f = gf(),
				_121665 = gf([0xdb41, 1]),
				_9 = gf([9]);
			for (var i = 0; i < 32; ++i) z[i] = privateKey[i];
			clamp(z);
			for (var i = 254; i >= 0; --i) {
				r = (z[i >>> 3] >>> (i & 7)) & 1;
				cswap(a, b, r);
				cswap(c, d, r);
				add(e, a, c);
				subtract(a, a, c);
				add(c, b, d);
				subtract(b, b, d);
				multmod(d, e, e);
				multmod(f, a, a);
				multmod(a, c, a);
				multmod(c, b, e);
				add(e, a, c);
				subtract(a, a, c);
				multmod(b, a, a);
				subtract(c, d, f);
				multmod(a, c, _121665);
				add(a, a, d);
				multmod(c, c, a);
				multmod(a, d, f);
				multmod(d, b, _9);
				multmod(b, e, e);
				cswap(a, b, r);
				cswap(c, d, r);
			}
			invert(c, c);
			multmod(a, a, c);
			pack(z, a);
			return z;
		}
		function generatePresharedKey() {
			var privateKey = new Uint8Array(32);
			window.crypto.getRandomValues(privateKey);
			return privateKey;
		}
		function generatePrivateKey() {
			var privateKey = generatePresharedKey();
			clamp(privateKey);
			return privateKey;
		}
		function encodeBase64(dest, src) {
			var input = Uint8Array.from([
				(src[0] >> 2) & 63,
				((src[0] << 4) | (src[1] >> 4)) & 63,
				((src[1] << 2) | (src[2] >> 6)) & 63,
				src[2] & 63,
			]);
			for (var i = 0; i < 4; ++i)
				dest[i] =
					input[i] +
					65 +
					(((25 - input[i]) >> 8) & 6) -
					(((51 - input[i]) >> 8) & 75) -
					(((61 - input[i]) >> 8) & 15) +
					(((62 - input[i]) >> 8) & 3);
		}
		function keyToBase64(key) {
			var i,
				base64 = new Uint8Array(44);
			for (i = 0; i < 32 / 3; ++i)
				encodeBase64(base64.subarray(i * 4), key.subarray(i * 3));
			encodeBase64(
				base64.subarray(i * 4),
				Uint8Array.from([key[i * 3 + 0], key[i * 3 + 1], 0])
			);
			base64[43] = 61;
			return String.fromCharCode.apply(null, base64);
		}
		window.wireguard = {
			generateKeypair: function () {
				var privateKey = generatePrivateKey();
				var publicKey = generatePublicKey(privateKey);
				return {
					publicKey: keyToBase64(publicKey),
					privateKey: keyToBase64(privateKey),
				};
			},
		};
	}, []);

	useEffect(() => {
		setDevices(devicesData);
		setloading(false);
	}, [devicesData]);

	const dispatch = useDispatch();

	const buttonText = () => {
		return !WGLoading ? (
			`Generate Configuration`
		) : (
			<>
				Generating Configuration{" "}
				<span className="spinner-border text-light spinner-border-sm ms-1"></span>
			</>
		);
	};

	const selectedDevices = (e) => {
		let value = e.target.value;

		setSelectedDeviceData(value);
	};

	if (isCancel) {
		setSelectedDeviceData("");
	}

	const generateWGPassword = async (selected) => {
		try {		
			setWGLoading(true);
			setctaLoading(true);
			const formdata = qs.stringify({ username: selected });
			const password = await poster(
				"wireguard/get-encrypt-password",
				formdata,
				token
			);
			const { data: response } = password;
			if (response?.status) {
				const { privateKey, publicKey } =
				window.wireguard.generateKeypair();
				sendWGDataToSubsComponent({
					country: iso,
					city: city,
					device: selectedDeviceData,
					wgEncrypPass: response?.body?.encrypPass,
					selectedSubscriptionData: selectedSubscription,
					wgPublicKey: publicKey,
					wgPrivateKey: privateKey,
					reGenereated: reGen,
				});
			} else {
				switch (response?.error_code) {
					case "token_expired":
						toast.error(t(TOKENEXPIRED));
						dispatch({ type: "LOGOUT" });
						history.push("/login");
						break;
					case "token_invalid":
						toast.error(t(INVALIDTOKEN));
						dispatch({ type: "LOGOUT" });
						history.push("/login");
						break;
					case "jwt_default_exception":
						toast.error(t(INVALIDTOKEN));
						dispatch({ type: "LOGOUT" });
						history.push("/login");
						break;
					default:
						toast.error(t(APIFAILED));
						break;
				}
				setWGLoading(false);
				setSelectState(false)
			}
		} catch (error) {
			setWGLoading(false);
			setctaLoading(false);
			toast.error(t(APIFAILED));
		}
	};

	const handleGetCountryNDevice = () => {


		const deviceElement = document.getElementById("device").value;

		if (deviceElement === "") {
			setShowErrorMessage(true);
		} else {
			setctaLoading(false);
			generateWGPassword(selectedSubscription);
			setWGErrorMessage(false);
			setShowErrorMessage(false);
		}
	};
	return (
		<div>
			{(!loading && !subLoading) || crashed ? (
				<>
					<PopupBody
						className={`${
							status === "true" || crashed ? "expiredUser" : ""
						}`}
					>
						<ModalBody className="px-0 py-0 col-lg-12 col-12">
							<div className="mod-head mb-0">
								{!reGen && (
									<InfoCard noflex>
										<p>Select Device*</p>
										<Input
											type="select"
											className="mt-2 devicesSelect"
											name="select"
											onChange={selectedDevices}
											id="device"
										>
											<option value="">
												Select device
											</option>
											{devices &&
												devices?.map(
													(device, index) => (
														<option
															key={index}
															value={device.slug}
														>
															{device.name}
														</option>
													)
												)}
										</Input>
									</InfoCard>
								)}
								{showErrorMessage ? (
									<p className="danger">
										Please fill all the required fields
									</p>
								) : (
									<></>
								)}
								{WGErrorMessage && (
									<SubscriptionCancelWrapper coloredVal>
										<InfoCard className="d-block">
											<div className="warning-box">
												<span className="warning-icon"></span>
												<p className="warning-text">
													{WGErrorMessage}
												</p>
											</div>
										</InfoCard>
									</SubscriptionCancelWrapper>
								)}
							</div>
							<ModalFooter className="px-0 py-0">
								{!reGen ? (
									<PrimaryBtn
										data-qamarker="generateWGConf"
										onClick={() => {
											handleGetCountryNDevice();
										}}
										pointer={
											(ctaLoading && WGLoading) || reGen
										}
										color="primary"
									>
										{buttonText()}
									</PrimaryBtn>
								) : (
									<WireGuardKeysCard>
										<InfoCard>
											<strong className="me-2 my-0">
												Regenerating Configuration
											</strong>{" "}
											<span className="spinner-border spinner-border-md ms-1"></span>
										</InfoCard>
									</WireGuardKeysCard>
								)}
								{!WGLoading && !(status === "true") && (
									<DestructBtn onClick={() => closeModal()}>
										Go Back
									</DestructBtn>
								)}
								<InfoCard noflex className="pb-0 py-0">
									<p>
										*WireGuard Configuration will expire in
										30 minutes after that you need to
										regenerate a new configuration.
									</p>
								</InfoCard>
								{deviceError && 
									<InfoCard>
										<p className="danger mt-2 ps-1">
											{/* {!deviceError?"Protocol is required.":""} */}
											Devices not fetched Successfully. Please refresh
										</p>
									</InfoCard>
								}
							</ModalFooter>
						</ModalBody>
					</PopupBody>
				</>
			) : (

							<ModalBody>
								<div
									className="mod-body"
									style={{ minHeight: "200px" }}
								>
									<Skeleton
										className="d-block mb-3"
										width={300}
									/>
									<Skeleton
										className="d-block mb-3"
										width={280}
									/>
									<Skeleton
										className="d-block mb-3"
										width={260}
									/>
									<Skeleton
										className="d-block mb-3"
										width={240}
									/>
									<Skeleton
										className="d-block mb-3"
										width={220}
									/>
									<Skeleton
										className="d-block mb-3"
										width={50}
									/>
								</div>
							</ModalBody>
			)}
		</div>
	);
};

export default withRouter(GenerateWireGuardKey);
