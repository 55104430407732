import React from 'react'
import { useState } from 'react'
import { InfoCard } from '../../../styles/dashboard/Main'
import { InsetBtn, PrimaryBtn } from '../../../styles/Generic'
import { sendEvents } from '../../../tools/mpEvents'
import AddonPurchase from '../subscriptions/modals/AddonPurchase'
import locked from '../../../assets/locked.svg'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { constants } from '../../../tools/constants'
import { useDispatch } from 'react-redux'
import { isAddon, isAddonPending, isWhmcsUser } from '../../../tools/tools'
import { useTranslation } from 'react-i18next';
import { seti18Locale , checkLocale } from '../../../tools/tools';

const NoPFBox = ({currentSubData,isPfSub,cancelModal}) => {

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();
    
    const token = localStorage.getItem("token")

    const [modal, setModal] = useState(false);

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [addonType, setaddonType] = useState({
        type:"",
        subscription:"",
        currency:"",
        plan:""
    })

    const widthCalculator = "lg"

    const toggle = () => setModal(!modal);

    const dispatch = useDispatch()

    const [orderSummary, setorderSummary] = useState(true)

    const [ErrorReason, setErrorReason] = useState({})

    const [isBuy,setIsBuy] = useState(false)

    let ErrorMessage = {
        "expired":t('pf_error_renew_subscription'),
        "noSubscription":t('pf_error_only_family_admin_no_subs'),
        "child": t('pf_error_only_family_admin_can_purchase'),
        "volumeChild": "Only an admin can purchase an addon in the Family plan.",
        "offline_gateway": t('pf_error_sorry_to_inform_coingate'),
        "whmcs": t('pf_error_sorry_to_inform_option_not_available'),
        "trial": t('pf_error_sorry_to_inform_option_not_available'),
        "noUserName": t('pf_error_enabling_port_forwarding'),
        "default": t('pf_error_selected_username_does_not_have_pf'),
        "pending": `${constants.pending_msg}`
    }

    let errorKey = false

    const history = useHistory();

    const getPFAddon = () => {
        setaddonType({
            type:"port_forwarding",
            subscription:currentSubData?.id,
            currency:currentSubData?.currency,
            plan:currentSubData?.plan?.code,
            gateway:currentSubData?.payment_gateway,
            number:isAddon(currentSubData?.add_ons)?.number ?? null
        })
        sendEvents({
            event_name:"ma_click_purchase_addon",
            event_properties:{
                addon_name: "port_forwarding",
                source: "manual configuration",
            }
        }, token)
        setModal(!modal)
    }
    const reCallApi = () =>{
        cancelModal()
        dispatch({ type: "GETONBOARDINGREQUEST", token: token , locale: checkLocale()})
    }

    useEffect(()=>{
        let selectedSub = currentSubData;
        if(selectedSub?.hasOwnProperty("id") && selectedSub?.id.length > 1){
            if(constants.cardless_state.includes(selectedSub?.state)){
                setErrorReason({
                    reason: 'expired',
                    heading: "",
                    paragraph: t('pf_error_renew_subscription')
                })
                errorKey = 'expired';
            }
            else if(selectedSub?.is_child && selectedSub?.hasVolumePlan){
                setErrorReason({
                    reason: 'volumeChild',
                    heading: "",
                    paragraph: "Only an admin can purchase an addon."
                })
                errorKey = 'volumeChild';
            }
            else if(selectedSub?.is_child){
                setErrorReason({
                    reason: 'child',
                    heading: "",
                    paragraph: t('pf_error_only_family_admin_can_purchase')
                })
                errorKey = 'child';
            }else if(isPfSub && !selectedSub.hasOwnProperty('vpnusernames')){
                setErrorReason({
                    reason: 'noUserName',
                    heading: "",
                    paragraph: t('pf_error_enabling_port_forwarding')
                })
                errorKey = 'noUserName';
            } else if( !selectedSub.hasOwnProperty('vpnusernames')){
                setErrorReason({
                    reason: 'noUserName',
                    heading: "",
                    paragraph: t('pf_error_enabling_port_forwarding')
                })
                errorKey = 'noUserName';
            } 
            else if(isWhmcsUser(selectedSub?.account?.billingType) && selectedSub?.payment_gateway_offline && !isPfSub && !selectedSub?.account?.has_mobile_gateway_for_whmcs){
                setErrorReason({
                    reason: 'offline_gateway',
                    heading: "Uh oh!",
                    paragraph: t('pf_error_sorry_to_inform_coingate')
                })
                errorKey = 'offline_gateway';
            }
            else if(selectedSub?.is_trial){
                setErrorReason({
                    reason: 'trial',
                    heading: "",
                    paragraph: t('pf_error_sorry_to_inform_option_not_available')
                })
                errorKey = 'trial';
            }else{
                let isPending = isAddonPending(currentSubData?.add_ons)
                setErrorReason({
                    reason: 'default',
                    heading: "",
                    paragraph: isPending ? `${constants.pending_msg}` :t('pf_error_selected_username_does_not_have_pf')
                })
                errorKey = isPending ? 'pending':'default';
            }
        }
        else{
            setErrorReason({
                reason: 'noSubscription',
                heading: "",
                paragraph: `Currently you do not have any active subscription. Please purchase a new subscription from <a href="https://www.purevpn.com/order" target="_blank" rel="noreferrer">here</a>.`
            })
            errorKey = 'noSubscription';
        }
        sendEvents(
            {
                event_name: "ma_view_pf_addon",
                event_properties: {
                    username: currentSubData?.hasOwnProperty("vpnusernames") ? currentSubData?.vpnusernames[0] : null,
                    hasPf:isPfSub?true:false,
                    status:currentSubData?.state ?? "No Subscription Exist",
                    reason: ErrorMessage[errorKey] ?? "N/A",
                    error_code:errorKey
                },
            },token);
    },[currentSubData])
  return (
    <>
        <InfoCard noflex hasborder className='mt-3 p-0 medium-width addon-list-item my-2' isPF='portforwarding'>
            <div className='pb-2 grey-text'>
                {t('tbl_username')} <strong>{currentSubData?.hasOwnProperty("vpnusernames") ? currentSubData?.vpnusernames[0] : "---"}</strong>
            </div>
            <div className="pb-2 py-2"><p className='red-text' dangerouslySetInnerHTML={{ __html: ErrorReason.paragraph }} /></div>
        </InfoCard>
        <InfoCard noflex hasborder className='p-0 medium-width addon-list-item my-2' isPF='portforwarding'>  
            <div className= "float-start">
                <label className="me-2 d-inline-flex my-3 grey-text">
                    {t('addon_lable')} <strong className='ms-2'>Port Forwarding</strong>
                    {!isPfSub && <div className='locked-image'><img src={locked} height={20} width={66} /></div>}
                </label>
            </div>
            <div className="float-end">
            {
                ErrorReason?.reason == "default" ?
                    <InsetBtn onClick={getPFAddon}>{t('get_this_addon_text')}</InsetBtn>
                : 
                (
                    currentSubData?.state == "paused" || (ErrorReason?.reason == "expired" && !(currentSubData?.renew_invoice === undefined)) ?
                    <PrimaryBtn onClick={e=> history.push(`/dashboard/subscriptions?update_subscription=true&renewId=${currentSubData.id}`)} className="my-2">{t('renew_now_text')}</PrimaryBtn>
                    :
                    (
                        ErrorReason?.reason == "noUserName" ?
                        <InsetBtn onClick={reCallApi}>{t('refresh_now_text')} </InsetBtn> :
                        <></>
                    )
                )
                
            }
            </div>
            {
                (modal) && <AddonPurchase backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} onPageClose={true} getDataFromChild={cancelModal} isPfFilter={true}/>
            }
        </InfoCard>
    </>
  )
}

export default NoPFBox