import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MessageBoxView } from '../../../../styles/dashboard/PureAI';
import EnterIcon from '../../../../assets/EnterMsgBlue.svg';
import { ACTIONS } from '../../../../redux/pureai/reducer';
import AblyConnector from './AblyConnector';
import { sendMPEvents } from './helper';
import SuggesstionsModal from './SuggesstionsModal';

const MessageBox = ({ pureAIStateReducer, inputRef, setAblyObject, setAblyChannel, ablyChannel, ablyObject, evtSource, setEvtSource }) => {

    const placeholderText = 'What would you like to do?';

    const dispatch = useDispatch();

    const [ showModal, setShowModal ] = useState(false);

    let responseTimes = JSON.parse(localStorage.getItem("responseTimes"));

    const { currentState, previousStates } = pureAIStateReducer;

    const disableForm = (e) => {
        e.preventDefault()
    }

    const handleSubmitForm = (e) => {
        disableForm(e)
        if(currentState.streaming || inputRef.current.value === "") {
            return;
        }
        const promptTime = new Date();
        dispatch({type: ACTIONS.SEND_PROMPT, prompt: inputRef.current.value, promptTime: promptTime.getTime()});
        responseTimes = responseTimes ?? [];
        responseTimes[currentState.messageId] = {};
        responseTimes[currentState.messageId].promptTime = promptTime.getTime();
        localStorage.setItem("responseTimes", JSON.stringify(responseTimes));
        sendMPEvents("ma_pureai_send_prompt", {
            prompt: inputRef.current.value ?? "N/A", 
            promptTime: promptTime
        });
        inputRef.current.value = "";
    }

    const isUserTyping = () => {
        if (inputRef?.current?.value?.length !== 0 && !isDisabled()) {
            return false;
        } else {
            return true;
        }
    }

    const isDisabled = () => {
        return !currentState.channelId || !currentState.chatId || currentState.streaming || currentState.prompt !== "";
    }

    const showMenuAction = () => {
        setShowModal(!showModal);
    }

    return (
        <>
        { showModal && <SuggesstionsModal showModal={showModal} showMenuAction={showMenuAction} inputRef={inputRef} /> }
        <MessageBoxView className={`bottomMenu ${currentState.prompt !== "" || previousStates.length ? "chat-view" : ""}`}>
            <AblyConnector setAblyObject={setAblyObject} setAblyChannel={setAblyChannel} ablyChannel={ablyChannel} ablyObject={ablyObject} evtSource={evtSource} setEvtSource={setEvtSource} />
            <form onSubmit={isDisabled() ? disableForm : handleSubmitForm} className={`messageBox ${isDisabled() ? "disabled" : ""}`}>
                <input className='test' ref={inputRef} type="textarea" placeholder={placeholderText} onChange={isUserTyping} />
                <img alt="Submit Button" onClick={isDisabled() ? disableForm : handleSubmitForm} src={EnterIcon} />
            </form>
            <span className='menu-icon' onClick={showMenuAction} />
        </MessageBoxView>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(MessageBox);