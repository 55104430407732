import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { port_forwarding_with_dedicated_ip } from '../port_forwarding_with_dedicated_ip'
import { purekeep } from '../purekeep'
import { purescan } from '../pureprivacy'
import { purecrypt } from '../purecrypt'
import { dedicated_ip } from '../dedicated_ip'
import { port_forwarding } from '../port_forwarding'
import { ddos_protection } from '../ddos_protection'
import { familyplan } from '../familyplan'
import { useTranslation } from 'react-i18next';

import { multi_login } from '../multi_login'
import { puremax } from '../puremax'
import { pureplus } from '../pureplus';

const ProductAppModal = ({locale,setGetThisAddon,type}) => {

  const { search} = useLocation();

  const {plan_type, redirected_via} = Object.fromEntries(new URLSearchParams(search));



  const { t, i18n } = useTranslation();

  const contentFor = {
    "purekeep" : purekeep,
    "purescan":purescan,
    "pureprivacy":purescan,
    "purecrypt":purecrypt,
    "port_forwarding_with_dedicated_ip":port_forwarding_with_dedicated_ip,
    "dedicated_ip":dedicated_ip,
    "port_forwarding":port_forwarding,
    "ddos_protection":ddos_protection,
    "familyplan":familyplan,
    "multi_login":multi_login,
    "plans":plan_type == 'vpn_max' ? puremax : pureplus
  }


  const productName = contentFor[type]
  /*
    Translating cta according to locale passed
  */
  useEffect(()=>{
    setGetThisAddon(productName[locale]["cta"])
  },[locale])
  /*
    If locale passed in url is not available set English Content by default
  */ 
  if(!productName.hasOwnProperty(locale)){
    locale="en"
  }
  /*
    Mod-Head:
      -Main Heading -> h3 (Product/Add-on Name)
      -Description -> p (Product/Add-on Description)

    Mod-Body:
      -Sub Heading -> h3 
      -Sub Description -> p
      -Products Features -> li  (Product/Add-on Features)
  */
  return (
    <>
      <div class="mod-head mb-1">
        <h3 class="">{t(productName[locale]['heading_1'])}</h3> 
        <p>{productName[locale]['para_1']}</p> 
     </div>

    <div class={`mod-body mt-2 ${redirected_via ? 'no-scroll' : null}`}> 
        {productName[locale]['heading_2'].length>0 && <h3>{productName[locale]['heading_2']}</h3>} 
        {productName[locale]['heading_2'].length>0 && <p>{productName[locale]['para_2']}</p>}
        <ul class="list-unstyled p-0 m-0 addonInfo"> 
            {productName[locale]['desc'].map((list,index)=>{
              return <li key={index} dangerouslySetInnerHTML={{ __html: list || "" }}></li>
            })}
        </ul>
     </div>
    </>
  )
}

export default ProductAppModal