import { ERRORS, GETTICKETS, GETTICKETREQUEST, LOADING, SETTICKETSBYPAYLOAD, GETTICKETDETAILSREQUEST, SETTICKETDETAILSBYPAYLOAD } from "./types"

export const getTicketsRequested = (payload) => {
    return{
        type:GETTICKETREQUEST,
        payload
    }
}

export const getTicketsType = (payload) => {
    return{
        type:GETTICKETS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}

export const setTicketsType = (payload) => {
    return{
        type:SETTICKETSBYPAYLOAD,
        payload
    }
}

export const setTicketDetailsType = (payload) => {
    return{
        type:SETTICKETDETAILSBYPAYLOAD,
        payload
    }
}

export const getTicketDetailsRequested = (payload) => {
    return{
        type:GETTICKETDETAILSREQUEST,
        payload
    }
}