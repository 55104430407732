import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Input, PopupBody } from '../../../../styles/dashboard/Main';
import { PrimaryBtn } from '../../../../styles/Generic';
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";
import { InviteStyles } from '../../../../styles/dashboard/pure-teams/invite';
import Copy from "../../../../assets/copy.png";
import Email from '../../../../generic-components/email';
import { ReactComponent as Check } from "../../../../assets/payment-success.svg";
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import InviteImg from "../../../../assets/pure-teams/invite.svg";
import Skeleton from 'react-loading-skeleton';

const Invite = ({modal, toggle, modalWidth, widthCalculator, backdrop, copyLink, email, setEmail, onClickInvite, hasSent, onInviteMoreUsers, inviteLink, selectedSubscriptionData, history}) => {
    const [loading, setLoading] = useState(false);
    const renewStates = ['past_due', 'expired']

    const ctaText = loading ? <>Invite <span className="spinner-border text-light"></span></> : 'Invite' ;

    const inviteMembers = () => {
        setLoading(true);
        onClickInvite();
    }

    const onInviteMoreUsersClick = () => {
        setLoading(false);
        onInviteMoreUsers();
    }

    const onClickRenew = () => {
        history.push('/dashboard/subscriptions')
    }

    return <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
           <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
           {!hasSent ? <>
                <ModalBody className="pb-0 mb-3">
                    <PopupBody>
                        <InviteStyles>
                            {!renewStates.includes(selectedSubscriptionData.state) ? <>
                                <img className="invite-img" src={InviteImg} alt="invite" />
                                <h2>Invite members to your team</h2>
                                <div className='email-link link-content'>
                                    <h6>Invite with email</h6>
                                    
                                    <div className='email-link-box'>
                                    <Email email={email} setEmail={setEmail}/>
                                    <PrimaryBtn className='invite-btn' disabled={email.items.length > 0 && !email.value ? false : true} onClick={inviteMembers}>{ctaText}</PrimaryBtn>
                                    </div>
                                </div>
                                <div className='copy-link link-content'>
                                    <h6>Invite with link</h6>
                                    {
                                        inviteLink ? <div className='copy-link-box'>
                                            <Input class="referLinkInput w-100" value={inviteLink} disabled/>
                                            <img src={Copy} alt="copy" id={inviteLink} onClick={copyLink} />
                                        </div> : <Skeleton height={20} />
                                    }
                                </div>
                            </> : <div className='m-auto text-center'>
                            <h3 className='mt-4'>Your subscription has been expired.</h3>
                                <div className='mt-4 m-auto'>
                                    <PrimaryBtn onClick={()=>onClickRenew()}>Renew now</PrimaryBtn>
                                </div>
                            </div>}
                            
                        </InviteStyles>
                    </PopupBody>
                </ModalBody>
            </> : <div className='text-center'>
              <ModalBody className="pb-0">
                    <PopupBody>
                        <InviteStyles>
                          <Check />
                          <h3 style={{color: '#001219'}} className='mt-4'>Your invitation has been sent successfully!</h3>
                        </InviteStyles>
                    </PopupBody>
                </ModalBody>
                 <ModalFooter>
                    <div className='mt-2 m-auto d-flex gap-3'>
                        <PrimaryBtn onClick={()=>onInviteMoreUsersClick()}>Add More Members</PrimaryBtn>
                    </div>
                </ModalFooter>
            </div>}
        </Modal>
}
export default withRouter(Invite);