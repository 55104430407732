import React, { useState } from 'react'
import { RewardsChip } from '../../../../styles/dashboard/pure-rewards/PureWallet'

const WalletNav = ({setIsEarnActive}) => {
const [activeTab , setActiveTab] = useState(1)
const clickNav = (tabNo) => {
    setActiveTab(tabNo)
    setIsEarnActive(tabNo)
}
return (
    <span className='d-flex mb-4'>
        <RewardsChip active={activeTab === 1} onClick={() => clickNav(1)}>
            Ways to Earn
        </RewardsChip>
        <RewardsChip active={activeTab === 2} onClick={() => clickNav(2)} className="mx-3">
            Ways to Spend
        </RewardsChip>
    </span>
  )
}

export default WalletNav