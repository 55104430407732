import { t } from "i18next";
import { PrimaryBtn } from "../../../styles/Generic";
import { PrePurchaseCampaignStyles } from "../../../styles/dashboard/FamilyPlan";
import { useTranslation } from "react-i18next";
import { checkAccountType, currencySymbol, getPaymentMethods, getTokenFromLocalStorage } from "../../../tools/tools";
import benefitImgOne from "../../../assets/fp_1.png";
import benefitImgTwo from "../../../assets/fp_2.png";
import benefitImgThree from "../../../assets/fp_3.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sendEvents } from "../../../tools/mpEvents";
import devices from "../../../assets/fp-all-devices.png"
import { basePrice } from "../../../tools/constants";
import { getVPNplanType } from "../upgrade/upgrade-tools";
const PrePurchaseCampaign = ({selectedSubscriptionData, GotoDiv, scrollOrderConfirmation, upgradeToFamilyPlan, toggleVideo}) => {

    const { t } = useTranslation();

    const token = getTokenFromLocalStorage();

    const [indexImg, setIndexImg] = useState(0);

    let familyPlanImp = [
        {heading: 'familyplan_stay_in_touch', paragraph: 'familyplan_stay_in_touch_para', key: 0, image: benefitImgOne},
        {heading: 'familyplan_ensure_seemless', paragraph: 'familyplan_ensure_seemless_para', key: 1, image: benefitImgTwo},
        {heading: 'familyplan_safegaurd', paragraph: 'familyplan_safegaurd_para', key: 2, image: benefitImgThree},
    ];

    let planName = {
        2: {
            name: 'Duo',
            description: 'member_only',
            cta: 'upgrade_duo',
            features: [
                'two_accounts',
                'dashboard_to_manage',
                'set_up_purevpn'
            ]
        },
        5: {
            name: 'Family',
            description: 'member_only',
            cta: 'familyplan_banner_cta',
            features: [
                'five_accounts',
                'dashboard_to_manage',
                'set_up_purevpn'
            ]
        }
    }

    const getDiscountedPercentage = (currency, type, plan) =>{
        let savedValue = plan?.plan_discount;
        const planCurrency = plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]
        if(type == "PERCENTAGE" && planCurrency?.discount != 0){
            savedValue = `${planCurrency?.discount}%`
        }else if(type == "FIXED" && plan?.plan_discount != 0){
            savedValue = `${currencySymbol[currency]}${savedValue}`
        }else{
            savedValue = "NO SAVINGS"
        }
        return savedValue
    }

    const getPlanPrice = (currency, price,interval_length=1) =>{
        let returningPrice = null
        returningPrice = parseFloat(price/interval_length).toFixed(2)
        return returningPrice
    }

    const getPlanPriceByCurrency = (currency, plan,interval_length=1) =>{
        let returningPrice = null;
        returningPrice = parseFloat(plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]?.unit_amount/interval_length)?.toFixed(2)
        return returningPrice
    }

    const getActualPlanPrice = (currency, plan, interval_length=1) =>{
        let returningPrice = null
        returningPrice = currencySymbol[currency] + parseFloat(plan?.currencies?.filter((cur)=> cur.currency === currency)?.[0]?.actual_price/interval_length).toFixed(2)
        return returningPrice
    }

    const onClickAddMoreMembers = () => {
        sendEvents({
            event_name:"ma_click_explore_teams",
            event_properties:{
                plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                payment_method: selectedSubscriptionData?.current_plan?.payment_gateway ?? 'N/A',
                current_status: selectedSubscriptionData?.current_plan?.state ?? 'N/A',
                state: 'post-purchase',
            }
        }, token)
    }

    return <PrePurchaseCampaignStyles>
        <div ref={scrollOrderConfirmation}></div>
        <section className="pre-purchase-plans">
            <h5 className="head5">{t('peace_of_mind')}</h5>
            <h2>{t('upgrade_fp_heading')}</h2>
            <p className="head">{t('aside_head_seven')}</p>
            <div className="plans row">

                {/* CURRENT PLAN */}
                {selectedSubscriptionData?.current_plan && <div className="plans-box individual-plan-box">
                    <div className="plan-offering">
                        <div className="plans-heading">
                            <span className="img"></span>
                            <h3>Individual</h3>
                        </div>
                        <p>For single member</p>
                        <div className="plans-pricing">
                            <span className="dollar">$</span>
                            <span className="price">{`${basePrice[getVPNplanType(selectedSubscriptionData?.plan_type)]?.[selectedSubscriptionData?.current_plan?.currency]?.toString().split(".")[0]}`}</span>
                            <span className="price-two">
                                <span className="decimal">{`.${basePrice[getVPNplanType(selectedSubscriptionData?.plan_type)]?.[selectedSubscriptionData?.current_plan?.currency]?.toString().split(".")[1]}`}</span>
                                <span className="month">/ {t('upgradeplan_month')}</span>
                            </span>
                        </div>
                        <div className="plans-btn">
                            <PrimaryBtn>{t('current_plan')}</PrimaryBtn>
                        </div>
                    </div>
                    <div className="plans-features">
                        <h5>{`${t('all_features_of_individual')}:`}</h5>
                        <ul>
                            <li>{t('one_account')}</li>
                            <li>{t('setup_vpn')}<img className="mt-3" src={devices} alt="devices"/></li>
                        </ul>
                    </div>  
                </div>}

                {
                    selectedSubscriptionData?.family_plans?.length > 0 && selectedSubscriptionData?.family_plans.sort((a,b)=> a?.max_account - b?.max_account).map((plan)=>{
                        return <><div className={`plans-box hover ${plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'selected' : null}`}>
                            {plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? <span className="value">{t('value_money')}</span> : null}
                            <div className="plan-offering">
                                <div className="plans-heading">
                                    <span className={`img ${plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : 'family'}`}></span>
                                    <h3>{planName[plan?.max_account ?? 5]?.name}</h3>
                                    <span className="percentage">{`${getDiscountedPercentage(
                                        selectedSubscriptionData?.current_plan?.currency, 
                                        plan?.plan_discount_type,
                                        plan,
                                    )} off`}</span>
                                </div>
                                <p className="ind">{t(planName[plan?.max_account ?? 5]?.description, {num: plan?.max_account})}</p>
                                <h6>
                                    {
                                        getActualPlanPrice(
                                            selectedSubscriptionData?.current_plan?.currency, 
                                            plan, 
                                            plan?.interval_length
                                        )
                                    }
                                    {` /${t('upgradeplan_month')}`}
                                </h6>
                                <div className="plans-pricing">
                                    <span className="dollar">$</span>
                                    <span className="price">{`${
                                        getPlanPriceByCurrency(
                                            selectedSubscriptionData?.current_plan?.currency, 
                                            plan,
                                            plan?.interval_length
                                        ).toString().split(".")[0]
                                    }`}</span>
                                    <span className="price-two">
                                        <span className="decimal">{`.${
                                        getPlanPriceByCurrency(
                                            selectedSubscriptionData?.current_plan?.currency, 
                                            plan,
                                            plan?.interval_length
                                        ).toString().split(".")[1]
                                    }`}</span>
                                        <span className="month">/{t('upgradeplan_month')}</span>
                                    </span>
                                </div>
                                <div className="plans-btn">
                                    <PrimaryBtn className={plan?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : 'family'} onClick={()=>{upgradeToFamilyPlan(plan)}}>{t(planName[plan?.max_account ?? 5]?.cta)}</PrimaryBtn>
                                    <div className="billed">{`Billed ${selectedSubscriptionData?.current_plan?.currency} ${parseFloat(plan?.currencies?.filter((cur)=> cur.currency === selectedSubscriptionData?.current_plan?.currency)?.[0]?.unit_amount)?.toFixed(2)} for ${plan?.interval_length} months`}</div>
                                </div>
                            </div>
                            <div className="plans-features">
                                <h5>{`${t('all_features_of_individual')}, ${t('plus')}:`}</h5>
                                <ul>
                                    {planName[plan?.max_account ?? 5]?.features.map((feature)=> {return <li>{t(feature)}</li>})}
                                </ul>
                            </div>
                        </div>
                        </>
                    })
                }
                
            </div>
            <Link className='text-center mt-4 w-100 d-block' onClick={onClickAddMoreMembers} to={'/dashboard/teams'} style={{fontSize: '16px' , color: 'rgb(85, 12, 203)'}}>{t('need_more_accounts_on_fp')}</Link>
        </section>

        <section className="pre-purchase-header">
            <h2>{t('familyplan_header_heading')}</h2>
            <p>{t('familyplan_header_para')}</p>
        </section>

        <section className="pre-purchase-features">
            <div className="features-container">
                <div className="row">
                    <div className="benefit-box full">
                        <div className="benefit-box-content">
                            <span></span>
                            <h4>{t('familyplan_individual_creds')}</h4>
                            <p>{t('familyplan_indiviudal_para_first')}</p>
                        </div>
                    </div>
                    <div className="benefit-box half">
                        <div className="benefit-box-content">
                            <span></span>
                            <h4>{t('more_accounts')}</h4>
                            <p>{t('familyplan_more_accounts_para')}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="benefit-box half">
                        <div className="benefit-box-content">
                            <span></span>
                            <h4>{t('bigger_savings')}</h4>
                            <p>{t('familyplan_individual_creds_para')}</p>
                        </div>
                    </div>
                    <div className="benefit-box full">
                        <div className="benefit-box-content">
                            <span></span>
                            <h4>{t('online_safety')}</h4>
                            <p>{t('online_safety_para')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <PrimaryBtn onClick={(e) => GotoDiv(e.target.innerText)}>{t('familyplan_banner_cta')}</PrimaryBtn>
        </section>

        {/* <section className="pre-purchase-banner">
            <h3>{t('keep_online_family')}</h3>
            <p>{t('keep_online_family_para')}</p>
            <PrimaryBtn onClick={(e) => GotoDiv(e.target.innerText)}>{t('familyplan_banner_cta')}</PrimaryBtn>
        </section> */}
        
        <section className="pre-purchase-benefits">
            <div className="row">
                <div className="benefits-list">
                   {familyPlanImp.map((point, index)=>{
                        return <div className={`benefits-box ${point.key === indexImg ? 'open' : 'close'}`} onClick={()=>setIndexImg(index)}>
                            <h4>{t(point.heading)}</h4>
                            {point.key === indexImg ? <p>{t(point.paragraph)}</p> : null}
                        </div>
                    })}
                </div>
                {familyPlanImp.map((point)=>{
                    return  point.key === indexImg ? <div className="benefits-img" style={{width: 'auto'}} >
                        <img src={point.image} alt="" />
                    </div> : null
                })}
            </div>
        </section>

        {/* <section className="pre-purchase-how-it-works">
            <h4>{t('familyplan_want_to_know_how_it_works')}</h4>
            <p>{t('familyplan_want_to_know_how_it_works_para')}</p>
            <PrimaryBtn onClick={toggleVideo}>{t('familyplan_watch_video')}</PrimaryBtn>
        </section> */}
    </PrePurchaseCampaignStyles>
}
export default PrePurchaseCampaign;