import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AddonImg, Dashboard } from "../../../styles/dashboard/Main";
import { isPurchasedAddOn } from "../../../tools/tools";
import { connect } from 'react-redux'
import PureSq from "../../../assets/pure_sq_logo.svg"
import { useEffect } from "react";
import { sendEvents } from "../../../tools/mpEvents";
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from "../../../tools/responseMessages";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ErrorBoundary from "../../ErrorBoundary";
import Loader from "../../dashboard/manual-connection/Loader";
import { constants } from "../../../tools/constants";
import { validatePurchasedApps } from '../../../tools/genericMethods'
import { useRef } from "react";
import { useTranslation } from 'react-i18next'



const AddonsDashboard = ({onBoardingReducer,parentSub}) => {
    
    const history = useHistory()

    const { search } = useLocation();

    const [crashed, setCrashed] = useState(false);

	const [error, setError] = useState("");
	
	const [isloading, setLoading] = useState(true);

    const [isVpnUsernamePD,setIsVpnUsernamePD] = useState(false);

    const [isAddon,setIsAddon] = useState(false);

    const btnRef = useRef()

    const dispatch = useDispatch();

    const token = localStorage.getItem("token");

    const {utm_source, utm_medium, utm_campaign, addon} = Object.fromEntries(new URLSearchParams(search));
    
    const { t, i18n } = useTranslation();

    const addonsInfo = [
        {
            name:'vpn',
            code:"purevpn",
            pre_heading:t('dashboard_purevpn_pre_heading'),
            post_heading:t('dashboard_purevpn_post_heading'),
            pre_desc:t('dashboard_purevpn_pre_desc'),
            post_desc:t('dashboard_purevpn_post_desc'),
            cta:t('dashboard_purevpn_cta'),
    
        },
        {
            name:'privacy',
            code:"pureprivacy",
            pre_heading:t('dashboard_privacy_pre_heading'),
            post_heading:t('dashboard_privacy_post_heading'),
            pre_desc:t('dashboard_privacy_pre_desc'),
            post_desc:t('dashboard_privacy_post_desc'),
            cta:t('dashboard_privacy_cta'),
        },
        {
            name:'ncrypt',
            code:"purecrypt",
            pre_heading:t('dashboard_encrypt_pre_heading'),
            post_heading:t('dashboard_encrypt_post_heading'),
            pre_desc:t('dashboard_encrypt_pre_desc'),
            post_desc:t('dashboard_encrypt_post_desc'),
            cta:t('dashboard_encrypt_cta'),
        },
        {
            name:'keep',
            code:"purekeep",
            pre_heading:t('dashboard_keep_pre_heading'),
            post_heading:t('dashboard_keep_post_heading'),
            pre_desc:t('dashboard_keep_pre_desc'),
            post_desc:t('dashboard_keep_post_desc'),
            cta:t('dashboard_keep_cta'),
        }
    ]

    const [purchasedApps, setPurchasedApps] = useState({
        purekeep: false,
        purescan: false,
        purecrypt: false,
        pureprivacy: false,
        purevpn: false,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(search)
        if (queryParams.has('addon')) {
            setIsAddon(true)
            queryParams.delete('addon')
            history.replace({
                search: queryParams.toString(),
            })
        }
		const token = localStorage.getItem("token");
		sendEvents(
			{
				event_name: "ma_pagevisited",
				event_properties: {
					visitedPageURL: window.location.href ?? "N/A",
                    utm_source: (addon ? addon : utm_source) ?? "Dashboard",
                    utm_medium: utm_medium ?? "Member Area",
                    utm_campaign: utm_campaign ?? "page"
				},
			},
			token
		);
	}, []);

	useEffect(() => {
		const { loading, errors, data, errorMessage, errorType } =
        onBoardingReducer;
		
		if (!loading) {
			setLoading(false);
		}
        if(onBoardingReducer?.subscriptions){
            let subscription = onBoardingReducer?.subscriptions[0];

            if(subscription?.id === onBoardingReducer.selectedSubs && !subscription.hasOwnProperty('vpnusernames') && subscription?.state === "past_due" && subscription?.payment_gateway_offline){
                setIsVpnUsernamePD(true)
            }

            let purchasedApps = validatePurchasedApps(onBoardingReducer?.subscriptions)
    
            setPurchasedApps(purchasedApps)
        }

		if (errors) {
			switch (errorType) {
				case "token_expired":
					toast.error(t(TOKENEXPIRED));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_exception":
					toast.error(t(APIFAILED));
					dispatch({ type: "LOGOUT" });
					break;
				case "token_invalid":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "jwt_default_exception":
					toast.error(t(INVALIDTOKEN));
					dispatch({ type: "LOGOUT" });
					break;
				case "APIFAILED":
					setCrashed(true);
					setError(errorMessage);
					break;
				default:
					setCrashed(true);
					setError(errorMessage);
					break;
			}
		}
	}, [onBoardingReducer]);

    if (crashed) {
		if (token) {
			sendEvents(
				{
					event_name: "ma_fail_dashboard",
					event_properties: {
						message: error ?? "N/A",
					},
				},
				token
			);
		}

		return <ErrorBoundary text={error} />;
	}
    
    const isAddonState = (addon_code) => {
        return isPurchasedAddOn(onBoardingReducer.accountDetails, onBoardingReducer.selectedSubs, addon_code, onBoardingReducer?.subscriptions,"dashboard")
    }

    const isPD = (addon_code) => {
        if(isAddonState(addon_code) === "active" ){
            return false
        }
        if(isAddonState(addon_code) === "past_due" || isAddonState(addon_code) === "paused" ){
            return true
        }
        else{
            return false
        }
    }

    const isAddonActive = (addon_code) => {
        if(isAddonState(addon_code) === "active" ){
            return true
        }
        else{
            return false
        }
    }


    const addonStatus = (isAddonPurchaseCall) => {
        let ctaText = t('subscription_addon_purchased');
        if(isAddonPurchaseCall==="expired"){
            ctaText = t('subscription_lable_status_expired')
        }
        if(isAddonPurchaseCall === constants.FreeRenew){
            ctaText= t('renew_now_text')
        }
        return ctaText
    }

    const isAddonPausedorPD = (addon_code) => {
        //if  add_on is NOT in past due or paused state
        return !isPD(addon_code) ?
        // if sub is past_due or paused then check is add_on state cardles (Expired) and show renew for add-on too else the addonStatus  
        isPD("purevpn") && constants.cardless_state.includes(addonStatus(isAddonState(addon_code))) ? t('renew_now_text') : addonStatus(isAddonState(addon_code))
        // if sub does not have username show pending else it is past due show renew tag
        : isVpnUsernamePD ? t('pending_text') : t('renew_now_text')
    }

    const isVpnNotPurchased = (addon_code) => {
        return addon_code==="purevpn" && isAddonState(addon_code) === constants.notPurchased 
    }

    const redirecToPurchasedorExpired = (addon_code) => {
        //if vpn sub is past due route to sub with renewal cart opened and has username
        return isPD(addon_code) && !isVpnUsernamePD ? `/dashboard/subscriptions?update_subscription=true&renewId=${onBoardingReducer.selectedSubs}` 
        // addon is vpn and and add on is expired route the user to subscription
        : (constants.cardless_state.includes(addonStatus(isAddonState(addon_code)).toLowerCase()) && addon_code==="purevpn") ? `/dashboard/subscriptions` 
        // if vpn sub is active route to add-ons onboarding and does nt has username
        : `/dashboard/onboarding/${addon_code}`
    }


    const redirectTo = (addon_code) => {
        if(isVpnNotPurchased(addon_code)){
            window.open("https://www.purevpn.com/order",'_blank');
        }
        // if sub is active and has purekeep route to purekeep's website
        if(addon_code === "purekeep" && isAddonActive(addon_code)){
            window.open(process.env.REACT_APP_PUREKEEP_APP_LINK ,'_blank');
        }
        let path = isVpnNotPurchased(addon_code) ? "/dashboard" : redirecToPurchasedorExpired(addon_code);
        /* if addon is Free and is expired and sub is past due to rout to renewal cart
            else route the user to sub page with addon upsell cart for that respective addon */
        if(addon_code != "purevpn" && isAddonState(addon_code) === constants.FreeRenew && isAddonState("purevpn") != "expired"){
            if(isPD("purevpn")){
                path =  `/dashboard/subscriptions?update_subscription=true&renewId=${onBoardingReducer.selectedSubs}`
            }
            else{
                path = `/dashboard/subscriptions/?renew_addon=${addon_code === "pureprivacy" ? "purescan" : addon_code}&renewId=${onBoardingReducer.selectedSubs}`
            }
        }
        history.push(path);
        sendEvents(
            {
                event_name: "ma_dashboard_click_cta",
                event_properties: {
                    cta: btnRef.current.innerHTML ?? "N/A",
                    addon: addon_code ?? "N/A",
                    addon_state: isAddonState(addon_code) ?? "N/A",
                    is_pending:isVpnUsernamePD ? true:false,
                },
            },
            token
        );
    }
    const Heading = (isAddon) => {
        return isAddon ? 
        <h1 className="heading">
            {t('dashboard_heading_with_isaddon_strong')}
        </h1> :
        <h1 className="heading">
            {t('dashboard_heading_without_isaddon')}
        </h1>
    }

    return (
        !isloading && onBoardingReducer?.onBoardings?.length > 0 ?
        <Dashboard>
            <span className="d-header">
                <span className="d-flex mx-auto justify-content-center align-items-center" >
                    <img src={PureSq}></img>
                    <h1 className="mx-2">pure<sup>2</sup></h1>
                </span>
                {Heading(isAddon)}
            </span>
            <div className="dashboardView">
                {addonsInfo.map((addon,key)=>(
                    <div key={key} className="addonBox" onClick={()=>{redirectTo(addon?.code)}}>
                        <span className="addon-header">
                            <span className="d-flex align-items-center">
                                <AddonImg dashboardView addonName={addon.code}/>
                                <h6><strong>pure</strong>{addon.name}</h6>
                            </span>
                            <span className={`purchaseTag ${isAddonState(addon.code)}`}>
                                {isAddonState(addon.code) != constants.notPurchased ? isAddonPausedorPD(addon.code) : addon.code === "purevpn" ? t('dashboard_get_new_subscription'): t('get_this_addon_text') }
                            </span>
                        </span>
                        <span className="addon-data">
                            <span>
                                <h1>{isAddonActive(addon?.code)? addon.post_heading : addon.pre_heading}</h1>
                                <p>{isAddonActive(addon?.code)? addon.post_desc : addon.pre_desc}</p>
                            </span>
                        </span>
                        {
                            (isAddonState(addon.code) === constants.notPurchased) 
                        }
                        <Link 
                            ref={btnRef}
                            className={`${isAddonActive(addon?.code) ? '':'notPurchased '}`}>
                            {!isAddonActive(addon?.code) || isAddonState(addon.code) === constants.FreeRenew ? addon.cta  : t('dashboard_open_app')}
                        </Link>
                    </div> 
                ))}
            </div>
        </Dashboard>
        :
        <Loader/>
    )
}
const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer,
    };
};

export default connect(mapStateToProps, null)(AddonsDashboard)

