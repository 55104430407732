import { constants } from "../../../tools/constants";
import { DownloadStyles } from "../../../styles/dashboard/security-tools/securityTools";
import { featureContent } from "../content";
import { PrimaryBtn } from "../../../styles/Generic";
import { useTranslation } from "react-i18next";
import AppStore from "../../../assets/onboarding-sprites/pureprivacy/ACSD-30868-appstore.svg";
import GooglePlay from "../../../assets/onboarding-sprites/pureprivacy/ACSD-30868-googleplay.svg";
import QRcodeGenerator from "../../dashboard/on-boarding/QRcodeGenerator";
import { sendEvents } from "../../../tools/mpEvents";
import { getTokenFromLocalStorage } from "../../../tools/tools";
import { userOs } from "../../../tools/genericMethods";

const Download = ({slug}) => {

    const { t } = useTranslation();
    const token = getTokenFromLocalStorage();

    const onDownloadLink = (payload) => {
        const { url, type, open_url_as } = payload;

        sendEvents({
            event_name:"ma_click_download_app",
            event_properties: {
                feature_name: slug ?? 'N/A',
                product: featureContent[slug]?.product ?? 'N/A',
                type: type ?? 'N/A'
            }
        }, token);

        window.open(url, open_url_as)
    }

    return <DownloadStyles isKeep={featureContent[slug]?.product == constants['purekeep']} isEcrypt={featureContent[slug]?.product == constants['purecrypt']} isPrivacy={featureContent[slug]?.product == constants['purescan']}>
        <img className="download-image" src={featureContent[slug]?.download?.image} alt={slug} />
        <div className="">
            <h3>{t(featureContent[slug]?.download?.heading)}</h3>
            {featureContent[slug]?.download?.description?.length && featureContent[slug]?.download?.description.map((des, index)=>{
               return <p className={`${index == 1 ? 'mb-3': 'mb-0'}`}>{t(des)}</p>
            })}
            {featureContent[slug]?.download?.store_links && <ul>
                <li><img src={GooglePlay} alt="google play" onClick={()=> onDownloadLink({url: featureContent[slug]?.download?.store_links?.playstore_link, type: 'play_store', open_url_as: '_blank'})}/></li>
                <li><img src={AppStore} alt="app store" onClick={()=> onDownloadLink({url: featureContent[slug]?.download?.store_links?.appstore_link, type: 'app_store', open_url_as: '_blank'})}/></li>
            </ul>}
            {featureContent[slug]?.download?.deeplink_url ? <div className="d-flex align-items-center gap-3">
                <QRcodeGenerator className="m-0" value={featureContent[slug]?.download?.deeplink_url} />
                <span>{t(featureContent[slug]?.download?.content)}</span>
            </div> : null}
            {featureContent[slug]?.download?.download_app ? <PrimaryBtn onClick={()=> onDownloadLink({url: featureContent[slug]?.download?.download_app, type: userOs(window) == 'windows' ? 'window_app' : 'mac_app', open_url_as: '_blank'})} className="mt-3 download-btn" isKeep={featureContent[slug]?.product == constants['purekeep']} isEcrypt={featureContent[slug]?.product == constants['purecrypt']} isPrivacy={featureContent[slug]?.product == constants['purescan']}>{t('downloads_download_applicatiion')}</PrimaryBtn> : null}
            {featureContent[slug]?.download?.web_app ? <PrimaryBtn onClick={()=> onDownloadLink({url: featureContent[slug]?.download?.web_app, type: 'web_app', open_url_as: '_blank'})} className="mt-3 white">{t('open_web_app')}</PrimaryBtn> : null}
        </div>
    </DownloadStyles>
}

export default Download;