export const puremax = {
  en:{
    heading_1: 'PureMax',
    para_1:     'Upgrade your existing subscription by switching to PureMax and experience a comprehensive all-in-one security solution that goes beyond traditional VPN services.',
    heading_2:     'What does Pure Max include?',
    para_2:     '',
    desc:[
      '<strong>High-quality VPN Service</strong> Experience uninterrupted usage of all PureVPN features while delighting in seamless streaming of your preferred channels.',
      '<strong>Remove My Data </strong> Safeguard your personal information by reclaiming control from data brokers and companies.',
      '<strong>Tracker Blocker </strong> Enhance the protection of your digital identity by preventing website trackers from gathering and exploiting your personal data.',
      '<strong>Social Privacy Manager</strong> Simplify complex social media account settings effortlessly and implement personalized privacy recommendations for added security.',
      '<strong>Secure Password Manager</strong> Safeguard all your passwords, financial details, and login information in secure vaults and access them from anywhere.',
      '<strong>End-to-end File Encryption </strong> Upload your local and cloud-based files, documents, images, and more in a secure vault to encrypt them and restrict access to unauthorized parties.'
    ],
    cta:"Upgrade to PureMax",
    cta_two:"Learn More",
  },
  de:{
    heading_1: 'PureMax',
    para_1: 'Aktualisieren Sie Ihr bestehendes Abonnement, indem Sie zu PureMax wechseln, und erleben Sie eine umfassende All-in-One-Sicherheitslösung, die über herkömmliche VPN-Dienste hinausgeht.',
    heading_2: 'Was beinhaltet Pure Max?',
    para_2: '',
    desc:[
    '<strong>Hochwertiger VPN-Dienst</strong> Erleben Sie die unterbrechungsfreie Nutzung aller PureVPN-Funktionen und genießen Sie nahtloses Streaming Ihrer bevorzugten Kanäle.',
    '<strong>Meine Daten entfernen </strong> Schützen Sie Ihre persönlichen Informationen, indem Sie die Kontrolle von Datenbrokern und Unternehmen zurückfordern.',
    '<strong>Tracker Blocker </strong> Erhöhen Sie den Schutz Ihrer digitalen Identität, indem Sie verhindern, dass Website-Tracker Ihre persönlichen Daten sammeln und ausnutzen.',
    '<strong>Social Privacy Manager</strong> Vereinfachen Sie mühelos komplexe Kontoeinstellungen in sozialen Medien und setzen Sie personalisierte Datenschutzempfehlungen für zusätzliche Sicherheit um.',
    '<strong>Sicherer Passwort-Manager</strong> Schützen Sie alle Ihre Passwörter, Finanzdaten und Anmeldeinformationen in sicheren Tresoren und greifen Sie von überall darauf zu.',
    '<strong>Vollständige Dateiverschlüsselung </strong> Laden Sie Ihre lokalen und cloud-basierten Dateien, Dokumente, Bilder und mehr in einen sicheren Tresor hoch, um sie zu verschlüsseln und den Zugriff für unbefugte Personen zu beschränken.'
    ],
    cta:"Auf PureMax upgraden",
    cta_two:"Mehr erfahren",
    },
    
  es:{
    heading_1: 'PureMax',
    para_1: 'Actualice su suscripción existente cambiando a PureMax y experimente una solución de seguridad integral todo en uno que va más allá de los servicios tradicionales de VPN.',
    heading_2: '¿Qué incluye Pure Max?',
    para_2: '',
    desc:[
    '<strong>Servicio VPN de alta calidad</strong> Experimente el uso ininterrumpido de todas las funciones de PureVPN mientras disfruta de una transmisión fluida de sus canales preferidos.',
    '<strong>Eliminar Mis Datos </strong> Proteja su información personal recuperando el control de los intermediarios de datos y las empresas.',
    '<strong>Bloqueador de Rastreadores </strong> Mejore la protección de su identidad digital evitando que los rastreadores de sitios web recopilen y exploten sus datos personales.',
    '<strong>Administrador de Privacidad Social</strong> Simplifique de manera fácil la configuración de la cuenta de sus redes sociales e implemente recomendaciones de privacidad personalizadas para una seguridad adicional.',
    '<strong>Administrador de Contraseñas Seguro</strong> Proteja todas sus contraseñas, detalles financieros e información de inicio de sesión en bóvedas seguras y acceda a ellas desde cualquier lugar.',
    '<strong>Encriptación de Archivos de Extremo a Extremo </strong> Cargue sus archivos locales y basados en la nube, documentos, imágenes y más en una bóveda segura para encriptarlos y restringir el acceso a partes no autorizadas.'
    ],
    cta:"Actualizar a PureMax",
    cta_two:"Más información",
  },
    
    fr:{
    heading_1: 'PureMax',
    para_1: 'Mettez à niveau votre abonnement existant en passant à PureMax et découvrez une solution de sécurité tout-en-un complète qui va au-delà des services VPN traditionnels.',
    heading_2: 'Que comprend Pure Max?',
    para_2: '',
    desc:[
    '<strong>Service VPN de haute qualité</strong> Profitez de l\'utilisation ininterrompue de toutes les fonctionnalités de PureVPN tout en profitant d\'une diffusion en continu sans problème de vos chaînes préférées.',
    '<strong>Supprimer Mes Données </strong> Protégez vos informations personnelles en reprenant le contrôle aux courtiers en données et aux entreprises.',
    '<strong>Bloqueur de Traceurs </strong> Améliorez la protection de votre identité numérique en empêchant les traceurs de sites web de collecter et d\'exploiter vos données personnelles.',
    '<strong>Gestionnaire de Confidentialité Sociale</strong> Simplifiez facilement les paramètres complexes de votre compte sur les réseaux sociaux et mettez en œuvre des recommandations de confidentialité personnalisées pour une sécurité accrue.',
    '<strong>Gestionnaire de Mots de Passe Sécurisé</strong> Protégez tous vos mots de passe, détails financiers et informations de connexion dans des coffres sécurisés et accédez-y de n\'importe où.',
    '<strong>Chiffrement de Fichiers de Bout en Bout </strong> Téléchargez vos fichiers locaux et basés sur le cloud, documents, images, et plus dans un coffre-fort sécurisé pour les chiffrer et restreindre l\'accès aux parties non autorisées.'
    ],
    cta:"Passer à PureMax",
    cta_two:"En savoir plus",
    },
  it:{
    heading_1: 'PureMax',
    para_1: 'Aggiorna la tua iscrizione esistente passando a PureMax e vivi un\'esperienza con una soluzione di sicurezza completa all-in-one che va oltre i tradizionali servizi VPN.',
    heading_2: 'Cosa include Pure Max?',
    para_2: '',
    desc:[
    '<strong>Servizio VPN di alta qualità</strong> Sperimenta l\'uso ininterrotto di tutte le funzioni di PureVPN godendo di uno streaming senza interruzioni dei tuoi canali preferiti.',
    '<strong>Rimuovi i Miei Dati </strong> Salvaguarda le tue informazioni personali riprendendo il controllo da parte di intermediari e aziende.',
    '<strong>Bloccatore di Tracker </strong> Migliora la protezione della tua identità digitale impedendo ai tracker dei siti web di raccogliere ed sfruttare i tuoi dati personali.',
    '<strong>Manager della Privacy Sociale</strong> Semplifica facilmente le complesse impostazioni dell\'account sui social media e implementa raccomandazioni personalizzate per la privacy per una maggiore sicurezza.',
    '<strong>Secure Password Manager</strong> Proteggi tutte le tue password, dettagli finanziari e informazioni di accesso in casseforti sicure e accedici da qualsiasi luogo.',
    '<strong>End-to-end File Encryption </strong> Carica i tuoi file locali e basati su cloud, documenti, immagini e altro in una cassaforte sicura per cifrarli e limitare l\'accesso a persone non autorizzate.'
    ],
    cta:"Passa a PureMax",
    cta_two:"Scopri di più",
  },
  ja:{
    heading_1: 'PureMax',
    para_1: 'PureMaxに切り替えて既存のサブスクリプションをアップグレードし、従来のVPNサービスを超える包括的なオールインワンセキュリティソリューションを体験してください。',
    heading_2: 'Pure Maxには何が含まれていますか？',
    para_2: '',
    desc:[
    '<strong>高品質なVPNサービス</strong> PureVPNのすべての機能を中断せずに利用し、お好みのチャンネルをシームレスにストリーミングできます。',
    '<strong>自分のデータを削除する </strong> データブローカーや企業からのコントロールを取り戻すことで、個人情報を保護します。',
    '<strong>トラッカーブロッカー </strong> ウェブサイトのトラッカーが個人データを収集し悪用するのを防ぎ、デジタルアイデンティティの保護を強化します。',
    '<strong>ソーシャルプライバシーマネージャー</strong> 複雑なソーシャルメディアアカウントの設定を簡単にし、追加のセキュリティのために個別のプライバシー推奨事項を実装します。',
    '<strong>セキュアパスワードマネージャー</strong> すべてのパスワード、金融詳細、およびログイン情報を安全なボルトに保護し、どこからでもアクセスできます。',
    '<strong>エンドツーエンドのファイル暗号化 </strong> ローカルおよびクラウドベースのファイル、ドキュメント、画像などを安全なボルトにアップロードして暗号化し、未承認の者へのアクセスを制限します。'
    ],
    cta:"PureMaxにアップグレード",
    cta_two:"詳細を確認",
  },
    
  ko:{
    heading_1: 'PureMax',
    para_1: '기존 구독을 PureMax로 전환하여 전통적인 VPN 서비스를 뛰어넘는 포괄적인 올인원 보안 솔루션을 경험해 보세요.',
    heading_2: 'Pure Max에는 무엇이 포함되어 있나요?',
    para_2: '',
    desc:[
    '<strong>고품질 VPN 서비스</strong> PureVPN 기능을 중단하지 않고 모든 기능을 사용하며 원하는 채널을 원활하게 스트리밍하세요.',
    '<strong>내 데이터 제거 </strong> 데이터 브로커 및 회사로부터 제어를 회복하여 개인 정보를 보호하세요.',
    '<strong>트래커 차단기 </strong> 웹 사이트 트래커가 개인 데이터를 수집하고 악용하는 것을 방지하여 디지털 신원을 더욱 안전하게 보호하세요.',
    '<strong>소셜 프라이버시 매니저</strong> 복잡한 소셜 미디어 계정 설정을 간편하게 만들고 추가 보안을 위한 맞춤형 개인 정보 권장 사항을 적용하세요.',
    '<strong>안전한 비밀번호 매니저</strong> 모든 비밀번호, 금융 세부 정보 및 로그인 정보를 안전한 금고에 보호하고 어디서나 액세스하세요.',
    '<strong>End-to-end 파일 암호화 </strong> 로컬 및 클라우드 기반 파일, 문서, 이미지 등을 안전한 금고에 업로드하여 암호화하고 무단 액세스를 제한하세요.'
    ],
    cta:"PureMax로 업그레이드",
    cta_two:"자세히 알아보기",
  },
  nl:{
    heading_1: 'PureMax',
    para_1:     'Upgrade uw bestaande abonnement door over te schakelen naar PureMax en ervaar een alles-in-één beveiligingsoplossing die verder gaat dan traditionele VPN-services.',
    heading_2:     'Wat omvat Pure Max?',
    para_2:     '',
    desc:[
      '<strong>Hoogwaardige VPN-service</strong> Ervaar ononderbroken gebruik van alle PureVPN-functies terwijl u geniet van naadloos streamen van uw favoriete kanalen.',
      '<strong>Verwijder Mijn Gegevens </strong> Bescherm uw persoonlijke informatie door controle terug te nemen van gegevensmakelaars en bedrijven.',
      '<strong>Tracker Blocker </strong> Verbeter de bescherming van uw digitale identiteit door te voorkomen dat website-trackers uw persoonlijke gegevens verzamelen en misbruiken.',
      '<strong>Social Privacy Manager</strong> Vereenvoudig moeiteloos complexe instellingen voor sociale media-accounts en implementeer gepersonaliseerde privacyaanbevelingen voor extra beveiliging.',
      '<strong>Veilige Wachtwoordmanager</strong> Beveilig al uw wachtwoorden, financiële gegevens en inloggegevens in beveiligde kluisjes en krijg er vanaf elke locatie toegang toe.',
      '<strong>Bestandsversleuteling van einde tot einde </strong> Upload uw lokale en cloudgebaseerde bestanden, documenten, afbeeldingen en meer in een beveiligde kluis om ze te versleutelen en de toegang tot onbevoegde partijen te beperken.'
    ],
    cta:"Upgrade naar PureMax",
    cta_two:"Meer informatie",
  },

  pt:{
      heading_1: 'PureMax',
      para_1:     'Atualize sua assinatura existente mudando para o PureMax e experimente uma solução de segurança abrangente que vai além dos serviços tradicionais de VPN.',
      heading_2:     'O que está incluído no Pure Max?',
      para_2:     '',
      desc:[
        '<strong>Serviço VPN de alta qualidade</strong> Experimente o uso ininterrupto de todos os recursos do PureVPN enquanto desfruta de streaming sem interrupções de seus canais preferidos.',
        '<strong>Remova Meus Dados </strong> Proteja suas informações pessoais recuperando o controle de corretores de dados e empresas.',
        '<strong>Bloqueador de Rastreadores </strong> Aprimore a proteção de sua identidade digital impedindo que rastreadores de sites coletem e explorem seus dados pessoais.',
        '<strong>Gerenciador de Privacidade Social</strong> Simplifique as configurações complexas de contas de mídia social sem esforço e implemente recomendações de privacidade personalizadas para maior segurança.',
        '<strong>Gerenciador de Senhas Seguro</strong> Proteja todas as suas senhas, detalhes financeiros e informações de login em cofres seguros e acesse-as de qualquer lugar.',
        '<strong>Criptografia de Arquivos de Ponta a Ponta </strong> Faça upload de seus arquivos locais e baseados em nuvem, documentos, imagens e muito mais em um cofre seguro para criptografá-los e restringir o acesso a partes não autorizadas.'
      ],
      cta:"Atualize para o PureMax",
      cta_two:"Saiba Mais",
  },

  ru:{
      heading_1: 'PureMax',
      para_1:     'Повысьте свою текущую подписку, перейдя на PureMax, и испытайте всестороннее все в одном решение безопасности, выходящее за рамки традиционных служб VPN.',
      heading_2:     'Что включает в себя Pure Max?',
      para_2:     '',
      desc:[
        '<strong>Высококачественный сервис VPN</strong> Переживайте бесперебойное использование всех функций PureVPN, наслаждаясь бесперебойным стримингом ваших предпочтительных каналов.',
        '<strong>Удаление моих данных </strong> Защитите свои личные данные, вернув контроль у брокеров данных и компаний.',
        '<strong>Блокировщик трекеров </strong> Повысьте защиту вашей цифровой личности, предотвращая сбор и использование ваших личных данных веб-трекерами.',
        '<strong>Менеджер социальной конфиденциальности</strong> Упростите сложные настройки учетной записи в социальных сетях легко и реализуйте персонализированные рекомендации по конфиденциальности для дополнительной безопасности.',
        '<strong>Безопасный менеджер паролей</strong> Обеспечьте безопасность всех ваших паролей, финансовых данных и информации для входа в безопасных хранилищах и получайте к ним доступ из любого места.',
        '<strong>Шифрование файлов с конца в конец </strong> Загружайте ваши локальные и облачные файлы, документы, изображения и многое другое в безопасный сейф для их шифрования и ограничения доступа для неавторизованных лиц.'
      ],
      cta:"Повысить до PureMax",
      cta_two:"Узнать больше",
  },
  tr:{
    heading_1: 'PureMax',
    para_1:     'Mevcut aboneliğinizi PureMax\'e geçerek güncelleyin ve geleneksel VPN hizmetlerinin ötesine geçen kapsamlı bir çoklu güvenlik çözümü deneyimleyin.',
    heading_2:     'Pure Max neleri içerir?',
    para_2:     '',
    desc:[
      '<strong>Yüksek Kaliteli VPN Hizmeti</strong> Tüm PureVPN özelliklerinin kesintisiz kullanımının tadını çıkarın ve tercih ettiğiniz kanalları kesintisiz olarak izleyin.',
      '<strong>Verilerimi Kaldır </strong> Kişisel bilgilerinizi, veri simsarlarından ve şirketlerden kontrolü geri alarak koruyun.',
      '<strong>Takipçi Engelleme </strong> Web sitesi takipçilerinin kişisel verilerinizi toplamasını ve istismar etmesini engelleyerek dijital kimliğinizin korumasını artırın.',
      '<strong>Sosyal Gizlilik Yöneticisi</strong> Sosyal medya hesap ayarlarını karmaşıklıksız bir şekilde basitleştirin ve ek güvenlik için kişiselleştirilmiş gizlilik önerilerini uygulayın.',
      '<strong>Güvenli Parola Yöneticisi</strong> Tüm şifrelerinizi, finansal detaylarınızı ve giriş bilgilerinizi güvenli kasalarda koruyun ve her yerden erişin.',
      '<strong>Uçtan Uca Dosya Şifreleme </strong> Yerel ve bulut tabanlı dosyalarınızı, belgelerinizi, resimlerinizi ve daha fazlasını güvenli bir kasada şifreleyerek yetkisiz kişilerin erişimini sınırlayın.'
    ],
    cta:"PureMax'e Yükselt",
    cta_two:"Daha Fazla Bilgi",
  },
  zh:{
      heading_1: 'PureMax',
      para_1:     '通过切换到PureMax来升级您的现有订阅，体验一种全面的一体化安全解决方案，超越传统的VPN服务。',
      heading_2:     'Pure Max包括什么?',
      para_2:     '',
      desc:[
        '<strong>高质量VPN服务</strong> 体验所有PureVPN功能的无间断使用，同时畅享您喜爱的频道的流畅播放。',
        '<strong>删除我的数据 </strong> 通过从数据经纪人和公司手中夺回控制，保护您的个人信息。',
        '<strong>追踪器阻止器 </strong> 通过阻止网站追踪器收集和利用您的个人数据，增强数字身份的保护。',
        '<strong>社交隐私管理器</strong> 轻松简化复杂的社交媒体账户设置，并实施个性化的隐私建议以增强安全性。',
        '<strong>安全密码管理器</strong> 在安全保险库中保护所有密码、财务细节和登录信息，并可以从任何地方访问它们。',
        '<strong>端到端文件加密 </strong> 将本地和基于云的文件、文档、图像等上传到安全保险库中进行加密，并限制未经授权的访问。'
      ],
      cta:"升级到PureMax",
      cta_two:"了解更多",
  },
}