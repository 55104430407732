import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { PrimaryBtn } from '../../../../styles/Generic';
import { PortForwarding, DedicatedIp, PureVpn, PureKeep, PureEncrypt, PurePrivacy, MultiLogin} from "../../../../tools/AppModalContent/checkoutContent";
import { InfoStyles } from "../../../../styles/dashboard/Info"
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";

const Info = ({content, backdrop, modal, toggle, modalWidth, widthCalculator, className}) => {

    return <Modal backdrop={backdrop} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
        <>
        <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
            <ModalBody className={`${className} pb-0`}>
                <PopupBody>
                    
                    <InfoStyles>
                        <div className="info-head mb-3">
                        {
                            content === 'purevpn' ? PureVpn() : content === 'purekeep' ? PureKeep() : content === 'purencrypt' ?  PureEncrypt() : content === 'pureprivacy' ?  PurePrivacy() : content === 'dedicatedip' ?  DedicatedIp() : content === 'portforwarding' ?  PortForwarding() : content === 'multilogins' ?  MultiLogin() : content
                        }
                        </div>
                    </InfoStyles>
                </PopupBody>
            </ModalBody>
        </>
    </Modal>
}

export default Info;