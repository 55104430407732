import { referralTierStatus } from "../../../../tools/constants"

export const getCurrentLevel = (progress) => {
  const currentLevel = progress?.filter((item) => item.status === referralTierStatus.progress)
  return currentLevel?.length ? currentLevel[0]?.level : progress && progress[0]?.status === referralTierStatus.locked ? referralTierStatus.locked : referralTierStatus.completed
}

export const getCurrentTier = (progress) => {
  const currentLevel = progress?.filter((item) => item.status === referralTierStatus.progress)
  return currentLevel?.length ? currentLevel[0]?.tier : progress && progress[0]?.status === referralTierStatus.locked ? referralTierStatus.locked : referralTierStatus.completed
}
