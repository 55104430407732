import React, { useEffect, useState } from "react";
import { PopupBody } from "../../../../styles/dashboard/Main";
import { DestructBtn, PrimaryBtn } from "../../../../styles/Generic";
import { Modal, ModalBody, ModalFooter, FormGroup } from "reactstrap";
import { getSubscriptionsRequest } from "../../../../redux/subscriptions/actions";
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED} from "../../../../tools/responseMessages";
import { setTicketsType } from "../../../../redux/tickets/actions";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { poster } from "../../../../tools/tools";
import { toast } from "react-toastify";
import { sendEvents } from "../../../../tools/mpEvents";
import { useTranslation } from 'react-i18next';

const RefundModal = ({backdrop, toggle, modal, modalWidth, widthCalculator, setnewAdded, authorId, subscriptionsReducer }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const accountEmail = useState({
    email: subscriptionsReducer?.subscriptions?.body?.[0]?.account?.email,
  });

  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [reasonOther, setReasonOther] = useState("");
  const [productMeetExpectations, setProductMeetExpectations] = useState(null);
  const [feedback, setFeedback] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleReasonOtherChange = (e) => {
    setReasonOther(e.target.value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: accountEmail[0]?.email,
      reason: reason === "Other" ? reasonOther : reason,
      productMeetExpectations,
      feedback,
    };

    sendEvents(
      {
        event_name: "ma_submit_refundform",
        event_properties: {
          refundreason: reason,
          comments: feedback,
        },
      },
      token
    );

    try {
      const formData = new FormData();
      formData.append("title", "Refund Form");
      const content = `
            Email : "${data.email}"
            Reason : "${data.reason}"
            Product met the expectations? : "${data.productMeetExpectations}"
            Feedback : "${data.feedback}"
            `;
      formData.append("content", content);
      formData.append("author_id", authorId);
      const create = await poster("tickets/create", formData, token);
      const { data: response } = create;
      if (response?.status) {
        dispatch(setTicketsType(response?.body));

        if (response?.body[0]?.author_id) {
          localStorage.setItem(
            "ticket_author_id",
            response?.body[0]?.author_id
          );
        }

        toast.success(
          `${t('your_ticket_text')} #${response?.body[0]?.id} ${t('has_been_created_text')}`
        );
        setnewAdded(true);
        toggle();
      } else {
        switch (response?.error_code) {
          case "token_expired":
            toast.error(t(TOKENEXPIRED));
            dispatch({ type: "LOGOUT" });

            break;
          case "token_invalid":
            toast.error(t(INVALIDTOKEN));
            dispatch({ type: "LOGOUT" });

            break;
          case "jwt_default_exception":
            toast.error(t(INVALIDTOKEN));
            dispatch({ type: "LOGOUT" });

            break;
          case "validation_exception":
            let err = "";
            Object.values(response?.errors).map((error) => {
              error.map((e) => (err += `${e} \n`));
            });
            toast.error(err);
            break;
          default:
            toast.error(t(APIFAILED));
            break;
        }
      }
    } catch (error) {
      toast.error(t(APIFAILED));
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    window.history.pushState({}, document.title, window.location.pathname);
    toggle();
  };

  useEffect(() => {
    sendEvents(
      {
        event_name: "ma_view_refundform",
        event_properties: {},
      },
      token
    );
  }, []);

  const ButtonText = !loading ? ( t('submit_text')) : ( <>{t('refundform_submitting')} <span className="spinner-border text-light"></span></> );

  return (
    <div>
      <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{ maxWidth: `${modalWidth[widthCalculator]}`, width: "100%" }} className="modal-wrapper">
        <ModalBody className="pb-0">
          <PopupBody className="pb-0">
            <div className="mod-head">
              <h3>{t('refundform_are_you_sure')}</h3>
            </div>
            <form onSubmit={handleSubmit} className="refund-form">
              <label>
                <strong>{t('refundform_what_your_registered_email')}</strong>
                <br />
                <input
                  className="emailBox mt-1"
                  type="email"
                  value={accountEmail[0]?.email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                />
              </label>

              <br />

              <label className="mt-2">
                <strong>{t('refundform_reason_to_refund')}</strong>
                <br />
                <div className="reasonsBox">
                    <input
                        type="radio"
                        value="Unable to connect"
                        checked={reason === "Unable to connect"}
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">{t('refundform_unable_to_connect')}</span>
                    <br />
                    <input
                        type="radio"
                        value="Unable to login"
                        checked={reason === "Unable to login"}
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">{t('refundform_unable_to_login')}</span>
                    <br />
                    <input
                        type="radio"
                        value="Unable to access desired website"
                        checked={reason === "Unable to access desired website"}
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">{t('refundform_unable_to_access')}</span>
                    <br />
                    <input
                        type="radio"
                        value="Slow Speed"
                        checked={reason === "Slow Speed"}
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">{t('refundform_slow_speed')}</span>
                    <br />
                    <input
                        type="radio"
                        value="Requesting refund for Auto-renewal payment"
                        checked={
                        reason === "Requesting refund for Auto-renewal payment"
                        }
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">
                        {t('refundform_auto_renewal')}
                    </span>
                    <br />
                    <input
                        type="radio"
                        value="Other"
                        checked={reason === "Other"}
                        onChange={handleReasonChange}
                    />
                    <span className="ms-2">{t('refundform_other')}</span>
                    <br />
                    <input
                        className="otherBox"
                        type="text"
                        placeholder={t('refundform_type_a_reason')}
                        value={reasonOther}
                        onChange={handleReasonOtherChange}
                        disabled={reason !== "Other"}
                    />
                </div>
              </label>

              <br />

              <label>
                <strong>
                {t('refundform_service_meet_expectation')}
                </strong>
                <br />
                <select
                  value={productMeetExpectations}
                  className="meetYourExpectations"
                  onChange={(event) =>
                    setProductMeetExpectations(event.target.value)
                  }
                >
                  <option value="">{t('refundform_select')}</option>
                  <option value="Yes">{t('refundform_yes')}</option>
                  <option value="No">{t('refundform_no')}</option>
                </select>
              </label>
              <br />

              <label>
                <strong>
                {t('refundform_additional_feedback')}
                </strong>
                <br />
                <textarea
                  className="feedback mt-2"
                  value={feedback}
                  onChange={handleFeedbackChange}
                  placeholder={t('refundform_enter_message')}
                ></textarea>
              </label>
              <br />
            </form>
          </PopupBody>
        </ModalBody>
        <ModalFooter className="pt-0">
          <DestructBtn color="secondary" onClick={closeModal}>{t('close_text')}</DestructBtn>
          <PrimaryBtn color="primary" type="submit" onClick={handleSubmit}>{ButtonText}</PrimaryBtn>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    familyplanReducer: state.familyplanReducer,
    subscriptionsReducer: state.subscriptionsReducer,
  };
};

export default connect(mapStateToProps, { getSubscriptionsRequest })(
  RefundModal
);
