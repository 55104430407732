import axios from 'axios';
import {takeLatest, put, cancelled, call} from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster } from '../../tools/tools';
import { useTranslation } from 'react-i18next';
import { errorsType, getTicketsType, loadingType, setTicketDetailsType } from './actions';
import i18n from "i18next";

function* ticketAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const tickets = yield poster("tickets", {}, action.payload)
        const {data: response} = tickets;
        if(response?.status){
            yield put(getTicketsType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (i18n.t(APIFAILED)) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchTicketsRequest(){
    yield takeLatest("GETTICKETREQUEST", ticketAsync)
}

function* ticketDetailsAsync(action){
    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const details = yield poster(`tickets/${action.payload.id}/details`, {}, action.payload.token)
        const {data: response} = details;
        if(response?.status){
            yield put(setTicketDetailsType(response))
        }else{
            yield put(errorsType({code: response?.error_code || "APIFAILED", message: response?.message }))
        }
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: (APIFAILED) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchTicketDetailsRequest(){
    yield takeLatest("GETTICKETDETAILSREQUEST", ticketDetailsAsync)
}