import React, { useState, useEffect, Suspense } from 'react'
import { PopupBody, InfoCard, OrderSummary, CardNumber, PurchaseBox } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { toast } from 'react-toastify';
import qs from 'qs'
import { dateFormat, getCurrentSubscription, poster, isWhmcsUser, getPaymentMethods, allowPurchaseForManualGateways, getSubPlanTypeToDisplay, getTotalAmount, itemUnitAmount, getProrataAmount, toNotDisplayRefreshCart, isRemovedGateway, checkTeamAddons, generateCouponEvent } from '../../../../tools/tools';
import { getSubscriptionsRequest, setSubscriptionType, resetSubscriptionRequest } from '../../../../redux/subscriptions/actions';
import { getFamilyPlanRequested, getFamilyPlanReset } from '../../../../redux/family-plan/actions';
import Skeleton from 'react-loading-skeleton';
import { useLocation, withRouter } from 'react-router-dom';
import classNames from 'classnames'
import { sendEvents } from '../../../../tools/mpEvents'
import RecurlyRoute from '../../../../tools/routes/RecurlyRoute';
import { useRecurly, ThreeDSecureAction, RecurlyProvider, Elements } from '@recurly/react-recurly';
import { getUpgradePlansRequested, getUpgradePlansReset } from '../../../../redux/upgrade/actions';
import paymentSuccess from '../../../../assets/payment-success.svg'
import paymentFailed from '../../../../assets/payment-failed.svg'
import Payment from '../recurly/Payment';
import PaypalBtn from '../recurly/PaypalBtn';
import { constants, redirectedFromPage, filter_coupons, featureMapping, billingCycles } from '../../../../tools/constants';
import { postMethod } from '../../../../tools/genericMethods';
import PopupPurchaseUtil from '../../../../utils/PopupPurchase';
import { EMPTYACCOUNT } from '../../../../redux/account/types';
import { EMPTYUPGRADEPLANS } from '../../../../redux/upgrade/types';
import { GETFAMILYPLANSRESET, LOADING, RESETFAMPLANPAYLOAD } from '../../../../redux/family-plan/types';
import { useTranslation } from 'react-i18next';
import { seti18Locale, checkLocale } from '../../../../tools/tools';
import { isMax, isPlus, isStandard } from '../../upgrade/upgrade-tools';
import PaymentIcon from '../../../../utils/PaymentIcon/PaymentIcon';
import { planTypeNaming } from '../../../../tools/constants';
import CouponField from './partials/CouponField';
import DiscountAndTotalField from './partials/TotalField';
import { isAndroid, isIOS } from 'react-device-detect';
import CouponView from '../utils/couponView';
import ErrorMsg from './partials/ErrorMsg';
import CloseIconSVG from "../../../../assets/svg/CloseIconSVG";

const BillingCycle = ({ history, backdrop, toggle, modal, modalWidth, widthCalculator, selectedSubscription, setorderSummary, orderSummary, subscriptionReducer, getUpgradePlansRequested, getUpgradePlansReset, getSubscriptionsRequest, resetSubscriptionRequest, getFamilyPlanRequested, getFamilyPlanReset, apiEndPoint, redirectedFrom, getDataFromChild, additionalAddons = [], currencyName, dataFetchedRef, setFreemiumModal = () => { }, isMonthlySub = false, showContentModalForApp, sendMemberAccountsRequest, setIsConverted}) => {
    // setIsConverted -> a flag to represent that a user has converted from standard to max
    const { search } = useLocation();
    const { plan_type, redirected_via, platform, redirect_uri, feature_name, plan} = Object.fromEntries(new URLSearchParams(search));
    const { t, i18n } = useTranslation();

    let billingEndPoint = apiEndPoint ?? "subscription/change"
    let popupHeading = {
        "familyPlan": "inapp_purchase_fp_cta_text",
        "default": "upgradeplan_title",
        "plantypeUpgrade": `${t('upgradeplan_upgradeto_cta_text')} ${planTypeNaming[selectedSubscription?.sub_plan_type]}`,
        "featureUpgrade": `${t('upgradeplan_upgradeto_cta_text')} ${planTypeNaming[selectedSubscription?.sub_plan_type]}`,
        "volumeLicensing": "Upgrade to Volume Licensing",
        "teams": "Upgrade to Teams",
        "upgrade": (isIOS || isAndroid) ? "Checkout" : "Upgrade Plan"
    }
    const get_locale = seti18Locale();
    const recurly = useRecurly();
    const [icon, seticon] = useState("card");
    const [planSelectedForPurchase, setplanSelectedForPurchase] = useState(false)
    const [declineState, setDeclineState] = useState(false)
    const [whmcsUser, setWhmcsUser] = useState(false)
    const [allowPurchaseForManualGateway, setAllowPurchaseForManualGateway] = useState(false)
    const [isAllowToUseParamMethod, setIsAllowToUseParamMethod] = useState(false)
    const [endPoint, setEndPoint] = useState("account/change-payment-method")
    const [afterTokenData, setAfterTokenData] = useState({})
    const [eventsDataAfterPurchase, setEventsDataAfterPurchase] = useState({})
    const [paymentData, setPaymentData] = useState({
        paymentStatus: false,
        image: paymentFailed,
        heading:t('payment_failed_text'),
        description:"",
        ctaText:t('payment_details_go_back_cta')
    })
    const [isPurchaseComplete, setPurchaseComplete] = useState(false)
    const [isCouponCodeApplied, setIsCouponCodeApplied] = useState(false);
    const [isCouponCodeAppliedLoading, setIsCouponCodeAppliedLoading] = useState(false);
    const [isError,setIsError] = useState(false)
    const [couponCode, setCouponCode] = useState("");
    const currentSub = getCurrentSubscription(subscriptionReducer, selectedSubscription?.subscription) ?? {}
    const [threedSecure, setthreedSecure] = useState({
        enabled: false,
        three_d_secure_action_token_id: "",
        oldToken: ""
    })

    useEffect(()=>{
        if(redirected_via && window.Intercom){
            window.Intercom('shutdown')
        }
    }, [])

    const currencies = {
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "$",
        CAD: "$",
        NOK: "kr"
    }

    const [isPurchased, setIsPurchased] = useState(false)

    const isPastdue = constants.past_due_state.includes(currentSub?.state ?? 'past_due');

    const [selected, setSelected] = useState({
        subscription_id: selectedSubscription?.subscription,
        plan_code: selectedSubscription?.plan,
        preview: 0,
        amount: 0,
        isPastDue: isPastdue ? true : false,
        plan_type: selectedSubscription?.sub_plan_type,
        hasStacked: currentSub?.stack_subs
    })

    const [invoiceData, setinvoiceData] = useState({
        orderSummary: {},
        loading: true
    })

    const [discountPrice, setDiscountPrice] = useState()

    const [state, setstate] = useState({
        plan_type: "",
        username: "",
        period: "",
        last_four: ""
    })

    const { pathname } = useLocation()

    const dispatch = useDispatch()

    const token = localStorage.getItem("token")

    const [loading, setloading] = useState(false)

    const [data, setdata] = useState({
        response: null,
        loading: true
    })

    const newAddonEventProp = { isnewproductadded: additionalAddons?.length > 0 ? true : false }

    const [totalAmountWithCreditAdjustment, setTotalAmountWithCreditAdjustment] = useState();

    const [showCouponView,setShowCouponView] = useState(false)

    const [isCouponApplied,setCouponApplied] = useState(null)

    const [oldInvoice, setOldInvoice] = useState({...invoiceData,isRemove:true})

    const [messagesForApps, setMessagesForApps] = useState({
        message: '',
        show: false
    })

    const getPreviewData = (payload, isPastDue = false) => {
        const formdata = {
            subscription_id:selectedSubscription?.subscription,
            plan_code:selectedSubscription?.plan,
            preview:1,
            isPastDue: isPastDue ? true : false,
            plan_type:selectedSubscription?.sub_plan_type,
            // added to cater refund for stack users
            hasStacked: currentSub?.stack_subs,
            ...payload
        }
        if(isCouponApplied){
            formdata['coupon_code'] = isCouponApplied["reward.coupon_code"]
        }

        changePlanAsync(formdata, "checkout")
        const subIndex = subscriptionReducer.subscriptions.body.findIndex(sub => sub.id === selectedSubscription.subscription)
        setstate({
            plan_type: `${selectedSubscription?.interval_length} ${selectedSubscription?.interval_length === 1 ? "Month" : "Months"}`,
            username: subscriptionReducer?.subscriptions?.body[subIndex]?.vpnusernames?.[0] ?? "-",
            email: subscriptionReducer?.subscriptions?.body[subIndex]?.account?.email ?? "-",
            period: `${dateFormat(selectedSubscription?.start_date)} - ${dateFormat(selectedSubscription?.expiry_date)}`,
            last_four: subscriptionReducer?.subscriptions?.body[subIndex]?.billing_info?.last_four,
            is_paused: subscriptionReducer?.subscriptions?.body[subIndex]?.is_paused,
            sub_type: subscriptionReducer?.subscriptions?.body[subIndex]?.plan_type,
        })
    }
    const couponEvent = {
        coupon_name: couponCode,
        coupon_discount: discountPrice,    
    }    
    useEffect(() => {
        if(oldInvoice.isRemove){
            try {
                setinvoiceData(prev=>({...prev,loading:true}))
                if (isPastdue && !selectedSubscription.isWhmcsUser) {
                    const formdata = qs.stringify({
                        subscription_id: selectedSubscription.subscription,
                        invoice_id: currentSub?.renew_invoice
                    })
                    setDeclineState(true)
                    postMethod("subscription/stop-collection", formdata, token,
                        // This function will call on success response
                        (response) => {
                            getPreviewData(isPastdue);
                        },
                        // Loading false once API completed
                        () => { },
                        // Logout function will dispatch if logout error occured
                        () => dispatch({ type: "LOGOUT" }),
                        // setting toaster message to hide
                        false,
                        (response) => {
                            if(response?.error_code === "invoice_exception"){
                                getPreviewData(isPastdue);
                            }else if(response?.error_code === "subscription_exception"){
                                getPreviewData(isPastdue);
                                sendEvents({
                                    event_name: "ma_manual_handle_subscription",
                                    event_properties: {

                                    }
                                }, token);
                            } else {
                                toast.error("Something went wrong, please try again!!!")
                                toggle()
                            }
                        }
                    )
                } else {
                    getPreviewData();
                }
            } catch (error) {
                toast.error("Something went wrong - PURE116")
                toggle()
            }
        }else{
            setinvoiceData({loading:false,...oldInvoice})
        }
    }, [isCouponApplied])

    const appEventHandler = (message, response) => {
        
        let _nativeHandler = () => window.chrome ? window.chrome.webview : (window.webkit ?? {messageHandlers: {}}).messageHandlers.addonEventHandler;

        _nativeHandler() && _nativeHandler().postMessage({
            'message': message,
            'response': response

        });
    }

    const dontCloseModalBit = (error_code="") => {
        return new Set(constants.dontCloseModalErrorCode).has(error_code)
    }

    const calculateMrr = (sub) => {
        return sub?.total_price ? (sub?.total_price/sub?.plan?.interval_length)?.toFixed(2) : 'N/A'
    }

    const changePlanAsync = async (data, step = "checkout") => {
        let payment_method = data?.change_pm ? data?.change_pm : (getBillinginfo()?.billing_info?.last_four ? "stripe" : "paypal")

        if (isAllowToUseParamMethod) {
            setAfterTokenData({ ...data });
            payment_method = data?.paymentMethodWhmcs;
        }


        // Payment for whmcs family-plan
        let selectSubscription = subscriptionReducer.subscriptions.body.find(subscription => subscription.id === data?.subscription_id);
        if (typeof data?.change_pm === undefined) {
            payment_method = getPaymentMethods(selectSubscription);
        }

        // in order to send events on apps
        if(redirected_via){
            appEventHandler("pay_now", {});
        }
        let threedSecure = false;
        if (step === "pay") {
            if (redirectedFrom === redirectedFromPage['familyPlan']) {
                sendEvents({
                    event_name: "ma_click_familyplan_checkout",
                    event_properties: {
                        productId: selectedSubscription?.plan ?? "N/A",
                        payment_method,
                        member_count: selectedSubscription?.plan_type
                    }
                }, token)
            }
            else if (redirectedFrom === redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade']) {
                sendEvents({
                    event_name: "ma_click_checkout_plan",
                    event_properties: {
                        current_plantype: selectedSubscription?.current_plan ?? "N/A",
                        billingcycle: selectedSubscription?.billingcycle ?? "N/A",
                        amount: getTotalAmount(invoiceData?.orderSummary?.body) ?? "N/A",
                        plan_type: selectedSubscription?.sub_plan_type ?? "N/A",
                        payment_method: payment_method ?? "N/A",
                        current_status: selectedSubscription?.state ?? "N/A",
                        account_type: selectedSubscription?.account_type,
                        platform_from: redirected_via ? 'Apps' : 'Member Area',
                        source: selectedSubscription?.source ?? "N/A",
                        feature_name: feature_name ?? selectedSubscription?.feature_name ?? "N/A"
                    }
                }, token)
            }
            else if(redirectedFrom === redirectedFromPage['volumeLicensing']){
                sendEvents({
                    event_name: "ma_click_volume_checkout",
                    event_properties: {
                        payment_method: payment_method ?? "N/A",
                        member_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                    }
                }, token)
            }
            else if(redirectedFrom === redirectedFromPage['teams']){
                sendEvents({
                    event_name: "ma_click_team_checkout",
                    event_properties: {
                        payment_method: payment_method ?? "N/A",
                                amount: totalAmountWithCreditAdjustment ?? "N/A",
                                plan_type: selectedSubscription?.current_plan ?? "N/A",
                                current_state: selectedSubscription?.state ?? "N/A",
                                account_type: selectedSubscription?.account_type ?? "N/A",
                                billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                                team_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                                team_addons: checkTeamAddons(additionalAddons) ?? 'N/A',
                                team_ip_count: additionalAddons.filter((item)=> item.code === 'dedicated_ip')[0]?.quantity,
                                team_server_count: additionalAddons.filter((item)=> item.code === 'team_server')[0]?.quantity,
                    }
                }, token)
            }
            else {
                sendEvents({
                    event_name: "ma_click_upgrade_checkout",
                    event_properties: {
                        selectedplan: invoiceData?.orderSummary?.body?.plan?.name ?? "N/A",
                        payment_method,
                        billing_cycle: selectedSubscription?.billing_cycle ?? "N/A",
                        line_items: additionalAddons,
                        new_product_addon_amount: selectedSubscription?.new_product_addon_amount ? selectedSubscription?.new_product_addon_amount?.toFixed(2) : '0',
                        addon_amount: selectedSubscription?.addon_amount ? selectedSubscription?.addon_amount?.toFixed(2) : '0',
                        currency: currencyName ?? "N/A",
                        ...newAddonEventProp,
                        ...couponEvent
                    }
                }, token)

                // updating payload for staking

                data = { ...data, amount: Object.keys(invoiceData.orderSummary).length !== 0 ? getTotalAmount(invoiceData?.orderSummary?.body) : 0 }
            }
        }
        try {
            if(additionalAddons.length>0){
                data['add_ons'] = additionalAddons;
            }
            if(isCouponApplied){
                data['coupon_code'] = isCouponApplied["reward.coupon_code"]
            }
            if(invoiceData?.orderSummary?.body?.discount_total > 0 && redirectedFrom === redirectedFromPage['upgrade']){
                data['coupon'] = couponCode;
            }
            const formdata = qs.stringify(data)
            const change =  await poster(billingEndPoint , formdata, token)
            const {data: response} = change;
            if(response?.status){
                setAllowPurchaseForManualGateway(allowPurchaseForManualGateways(currentSub?.payment_gateway));
                let selectedSubscriptionData = subscriptionReducer?.subscriptions?.body?.find(subscription => subscription.id === response?.body?.subscription_id)
                const eventsPropFamilyPlan = {
                    username: selectedSubscriptionData?.vpnusernames?.[0] ?? "N/A",
                    account_status:selectedSubscriptionData?.state ?? "N/A",    
                }
                // To fix date issue for WHMCS Users
                if(redirectedFrom !== redirectedFromPage['upgrade']  && (isWhmcsUser(response?.body?.account?.billingType))){
                    setstate(prevState=>({...prevState,period: `${dateFormat(selectedSubscription?.start_date)} - ${dateFormat(response?.body?.end_date)}`,}))
                }

                // read end date from response for all ss carts- no whmcs based
                if(redirectedFrom == redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade'] || redirectedFrom == redirectedFromPage['familyPlan'] || redirectedFrom == redirectedFromPage['volumeLicensing']){
                    setstate(prevState=>({...prevState,period: `${dateFormat(selectedSubscription?.start_date)} - ${dateFormat(response?.body?.end_date)}`,}))
                }
                setEventsDataAfterPurchase(eventsPropFamilyPlan)
                if(response?.body?.account){
                    if(isWhmcsUser(response?.body?.account?.billingType)){
                        setWhmcsUser(true)
                        setEndPoint("subscription/change")
                        setIsAllowToUseParamMethod(true)
                    }
                }
                if(step === "checkout"){
                    setinvoiceData({
                        orderSummary:response,
                        loading:false
                    });
                    setDiscountPrice(response?.body?.discount_total)
                    if(!isCouponApplied){
                        setOldInvoice({
                            orderSummary:response,
                            isRemove:true
                        })
                    }
                    if(!isCouponCodeApplied){
                        setIsCouponCodeAppliedLoading(false)
                    }
                    setTotalAmountWithCreditAdjustment(getTotalAmount(response?.body))
                    setplanSelectedForPurchase(true)
                    if(redirectedFrom === redirectedFromPage['familyPlan']){
                        
                        let getGatewayName = subscriptionReducer.subscriptions.body.find(subscription => subscription.id === response?.body?.subscription_id)?.billing_info?.last_four ? "stripe" : "paypal"
                        
                        if(isWhmcsUser(response?.body?.account?.billingType)){
                            getGatewayName = subscriptionReducer.subscriptions.body.find(subscription => subscription.id === response?.body?.subscription_id)?.payment_gateway
                        }
                        sendEvents({
                            event_name:"ma_familyplan_initiate",
                            event_properties:{
                                payment_method : getGatewayName,
                                amount: (getTotalAmount(response?.body) - totalPrice(response)?.tax)?.toFixed(2),
                                vat: totalPrice(response)?.tax.toFixed(2),
                                member_count: selectedSubscription?.plan_type,
                                ...eventsPropFamilyPlan
                            }
                        }, token)

                    }
                    else{
                        if (redirectedFrom !== redirectedFromPage['featureUpgrade'] && redirectedFrom !== redirectedFromPage['plantypeUpgrade'] && redirectedFrom !== redirectedFromPage['volumeLicensing'] && redirectedFrom !== redirectedFromPage['teams'] ) {
                            sendEvents({
                                event_name:"ma_initiate_upgrade",
                                event_properties:{
                                    amount: (getTotalAmount(response?.body) - totalPrice(response)?.tax)?.toFixed(2),
                                    vat: totalPrice(response)?.tax.toFixed(2),
                                    selectedplan: response?.body?.plan?.name ?? "N/A",
                                    payment_gateway:getPaymentMethods(selectedSubscriptionData),
                                    billing_cycle:selectedSubscriptionData?.plan?.type,
                                    current_plan:selectedSubscription?.billing_cycle,
                                    ...couponEvent
                                }
                            }, token)
                        }
                    }
                }else{
                    if(redirectedFrom === redirectedFromPage['familyPlan']){
                        toast.success(t('familyplan_packages_cardtwo_cta'))
                        if(redirected_via){
                            setMessagesForApps({
                                message: `${t('inapp_purchase_you_have_been_upgraded_to_text')} ${t('familyplan_title')}.`,
                                show: true
                            })

                            let AppEventsData = {
                                type: "purchase_family_plan",
                                message: `You have been upgraded to ${t('familyplan_title')} successfully`,
                                data:{
                                    isPurchasedSucessFully : true,
                                    
                                }
                            }
                            appEventHandler("purchase_family_plan", AppEventsData);
                        }
                        if(redirect_uri && redirect_uri != undefined){
                            let redirectURI = window.atob(redirect_uri)
                            setTimeout(() => {
                                window.location.href = redirectURI;
                            }, 5000);
                        }
                        sendEvents({
                            event_name:"ma_purchase_familyplan",
                            event_properties:{
                                payment_method,
                                amount: totalAmountWithCreditAdjustment ? (totalAmountWithCreditAdjustment - Number(response?.body?.tax)).toFixed(2) : (Number(response?.body?.total_price) - Number(response?.body?.tax)).toFixed(2),
                                vat: Number(response?.body?.tax).toFixed(2),
                                billing_cycle:response?.body?.plan?.type,
                                member_count: selectedSubscription?.plan_type,
                                previous_mrr: calculateMrr(currentSub),
                                new_mrr: calculateMrr(response?.body),
                                ...eventsDataAfterPurchase
                            }
                        }, token)
                        getDataFromChild(true)
                        dispatch({type:RESETFAMPLANPAYLOAD})
                        dispatch(getFamilyPlanReset())
                        const apiData = {
                            token:token,
                            data:{
                                plan: "current"
                            }
                        }
                        getFamilyPlanRequested(apiData)
                        dataFetchedRef.current=false;

                    }
                    else if (redirectedFrom === redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade']) {
                        !redirected_via && toast.success(`${t('inapp_purchase_you_have_been_upgraded_to_text')} ${planTypeNaming[selectedSubscription?.sub_plan_type]}.`)

                        if(redirected_via){
                            setMessagesForApps({
                                message: `${t('inapp_purchase_you_have_been_upgraded_to_text')} ${planTypeNaming[selectedSubscription?.sub_plan_type]}.`,
                                show: true
                            })

                            let AppEventsData = {
                                type: "purchase_plan",
                                message: `You have been upgraded to ${planTypeNaming[plan_type]} successfully`,
                                data:{
                                    isPurchasedSucessFully : true,
                                    
                                }
                            }
                            appEventHandler("purchase_plan", AppEventsData);
                        }
                        if(isAndroid && redirect_uri && redirect_uri != undefined){
                            let redirectURI = window.atob(redirect_uri)
                            setTimeout(() => {
                                window.location.href = redirectURI;
                            }, 5000);
                        }
                        
                        sendEvents({
                            event_name: "ma_purchase_plan",
                            event_properties: {
                                current_plantype: selectedSubscription?.current_plan ?? "N/A",
                                billingcycle: selectedSubscription?.billingcycle ?? "N/A",
                                amount: totalAmountWithCreditAdjustment ?? "N/A",
                                plan_type: selectedSubscription?.sub_plan_type ?? "N/A",
                                payment_method: payment_method ?? "N/A",
                                current_status: selectedSubscription?.state ?? "N/A",
                                account_type: selectedSubscription?.account_type,
                                platform_from: redirected_via ? 'Apps' : 'Member Area',
                                source: selectedSubscription?.source ?? "N/A",
                                feature_name: feature_name ?? selectedSubscription?.feature_name ?? "N/A",
                                previous_mrr: calculateMrr(currentSub),
                                new_mrr: calculateMrr(response?.body)
                            }
                        }, token)
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token });
                        
                        if(redirectedFrom === redirectedFromPage['featureUpgrade']){
                            setIsConverted(true);
                            toggle();
                            return;
                        }

                        if(!redirected_via){
                            history.push("/dashboard/security-tools");
                            return;
                        }
                    }
                    else if (redirectedFrom === redirectedFromPage['volumeLicensing']){
                        if(isWhmcsUser(response?.body?.account?.billingType)){
                            dispatch({ type: "RESETSUBSCRIPTIONPAYLOAD", token: token })
                            getSubscriptionsRequest(token)
                            sendMemberAccountsRequest(response?.body?.id);
                        }
                        else{
                            sendMemberAccountsRequest()
                        }
                        dispatch({ type: "UPDATESELECTEDSUBSCRIPTION", payload: response?.body?.id })
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token });
                        sendEvents({
                            event_name: "ma_purchase_volume",
                            event_properties: {
                                payment_method: payment_method ?? "N/A",
                                amount: totalAmountWithCreditAdjustment ?? "N/A",
                                plan_type: selectedSubscription?.current_plan ?? "N/A",
                                billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                                member_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                                previous_mrr: calculateMrr(currentSub),
                                new_mrr: calculateMrr(response?.body)
                            }
                        }, token);
                        toggle();
                        return;
                    }else if (redirectedFrom === redirectedFromPage['teams']){
                        dispatch({ type: "GETTEAMPLANSRESET", payload: token })
                        dispatch({ type: "RESETSUBSCRIPTIONPAYLOAD", token: token })
                        dispatch({ type: "UPDATESELECTEDSUBSCRIPTION", payload: response?.body?.id })
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token });
                        // since our process event driven it takes time to fetch the migrated team info
                        setTimeout(()=>{
                            dispatch({ type: "GETTEAMPLANSREQUEST", payload: token })
                        }, process.env.REACT_APP_TEAMS_PURCHASE_TIMEOUT);
                        sendEvents({
                            event_name: "ma_purchase_teams",
                            event_properties: {
                                payment_method: payment_method ?? "N/A",
                                amount: totalAmountWithCreditAdjustment ?? "N/A",
                                plan_type: selectedSubscription?.current_plan ?? "N/A",
                                current_state: selectedSubscription?.state ?? "N/A",
                                account_type: selectedSubscription?.account_type ?? "N/A",
                                billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                                team_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                                team_addons: checkTeamAddons(additionalAddons) ?? 'N/A',
                                team_ip_count: additionalAddons.filter((item)=> item.code === 'dedicated_ip')[0]?.quantity,
                                team_server_count: additionalAddons.filter((item)=> item.code === 'team_server')[0]?.quantity,
                                previous_mrr: calculateMrr(currentSub),
                                new_mrr: calculateMrr(response?.body)
                            }
                        }, token);
                        toggle();
                        return;
                    }
                    else {
                        toast.success(`Your plan has been upgraded to ${response?.body?.plan?.interval_length} Months`)
                        if(additionalAddons.length>0){
                            if(isWhmcsUser(response?.body?.account?.billingType) && !(redirectedFrom === redirectedFromPage['familyPlan'])){
                                //clear on boarding data for whmcs and route to purekeep
                                dispatch({ type: "RESETONBOARDINGDATA", token: token })
                            }
                        }
                        //BA-308
                        sendEvents({
                            event_name:"ma_upgradesubscription",
                            event_properties:{
                                selectedplan: response?.body?.plan?.name ?? "N/A",
                                payment_method,
                                billing_cycle:response?.body?.plan?.type,
                                amount: totalAmountWithCreditAdjustment ? (totalAmountWithCreditAdjustment - Number(response?.body?.tax)).toFixed(2) : (Number(response?.body?.total_price) - Number(response?.body?.tax)).toFixed(2),
                                vat: Number(response?.body?.tax).toFixed(2),
                                line_items: additionalAddons,
                                new_product_addon_amount: selectedSubscription?.new_product_addon_amount ? selectedSubscription?.new_product_addon_amount?.toFixed(2) : '0',
                                addon_amount: selectedSubscription?.addon_amount ? selectedSubscription?.addon_amount?.toFixed(2) : '0',
                                currency:currencyName ?? "N/A",
                                old_billing_cycle: selectedSubscription?.billing_cycle,
                                ...newAddonEventProp,
                                ...couponEvent

                            }
                        }, token)
                    }
                    setPurchaseComplete(true)
                    // Empty the planUpgrade, to handle if user click again on upgrade after upgrading.
                    dispatch({type:EMPTYUPGRADEPLANS})

                    // To empty the account, to handle screen jerk on referral page
                    //reseting data when user is not upgrading family plan
                    if(!(redirectedFrom === redirectedFromPage['familyPlan']) && !isMonthlySub){
                        dispatch(getUpgradePlansReset())
                    }
                    dispatch({type:EMPTYACCOUNT})
                    /*
                        if User sub is not monthly thene refresh the onboaring
                        As for monthly user with free add-on service the onboarding will refresh
                    */
                    if(!isMonthlySub){
                        dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
                    }
                    if(additionalAddons.length===0){
                        getUpgradePlansRequested(token)
                    }
                    getSubscriptionsRequest(token)
                    dispatch(setSubscriptionType(response?.body))
                    if(!redirected_via){
                        if(isMonthlySub){
                            setFreemiumModal(true)
                        }
                        else{
                            if(currentSub?.service_origin !== constants.purevpn){
                                history.push("/dashboard/security-tools")
                            } else {
                                history.push("/dashboard/family-plan?query=plan-upgrade")
                            }
                        }
                        toggle()
                    }
                }
            }else{
                if(redirectedFrom === redirectedFromPage['upgrade']){
                    setIsCouponCodeApplied(false)
                    setIsCouponCodeAppliedLoading(false)
                }
                switch (response?.error_code) {
                    case "token_expired":
                        setPaymentData({...paymentData, description : TOKENEXPIRED})
                        dispatch({type:"LOGOUT"})
                        break;
                    case "token_invalid":
                        setPaymentData({...paymentData, description : (t(INVALIDTOKEN))})
                        dispatch({type:"LOGOUT"})
                        break; 
                    case "declined":
                        setPaymentData({...paymentData, description : "Your transaction was declined. Please use a different card or contact your bank."})
                        setDeclineState(true)
                        break;
                    case "recurly_exception":
                        if(selectedSubscription?.isWhmcsUser){
                            toast.error(response?.message)
                            toggle()
                        }
                        setPaymentData({...paymentData, description : response?.message})
                        setDeclineState(true)
                        break;
                    case "subscription_exception":
                        setPaymentData({...paymentData, description : response?.message})
                        break;    
                    case "jwt_default_exception":
                        setPaymentData({...paymentData, description : (t(INVALIDTOKEN))})
                        dispatch({type:"LOGOUT"})
                        break;
                    case "three_d_secure_action_required":
                        setthreedSecure({
                            three_d_secure_action_token_id:response?.errors?.three_d_secure_action_token_id,
                            enabled:true,
                            oldToken:""
                        })
                        threedSecure = true;
                        setIsPurchased(false)
                        break; 
                    case "coupon_code_exception":
                        setIsError(response?.message)
                        sendEvents({
                            event_name:"ma_upgrade_coupon_exception",
                            event_properties:{
                                ...couponEvent
                            }
                        }, token)
                        return;     
                    default:
                        setPaymentData({...paymentData, description : response?.message})
                        break;
                }
                if(!threedSecure) setIsPurchased(true)

                if(redirectedFrom === redirectedFromPage['familyPlan']){
                    if(redirected_via){
                        let AppEventsData = {
                            type: "purchase_family_plan",
                            message: response?.error_code,
                            data:{
                                isPurchasedSucessFully : false,
                                dontCloseModal: dontCloseModalBit(response?.error_code)
                            }
                        }
                        appEventHandler("ma_purchase_familyplan", AppEventsData);
                    }
                    sendEvents({
                        event_name:"ma_fail_purchase_familyplan",
                        event_properties:{
                            reason: response?.message ?? "N/A",
                            payment_method,
                            productId: selectedSubscription?.plan,
                            subscription_id:selectSubscription.id ?? "N/A",
                            member_count: selectedSubscription?.plan_type
                        }
                    }, token)          
                }
                else if(redirectedFrom === redirectedFromPage['teams']){
                    sendEvents({
                        event_name:"ma_fail_purchase_teams",
                        event_properties:{
                            reason: response?.message ?? "N/A",
                            payment_method: payment_method ?? "N/A",
                            amount: totalAmountWithCreditAdjustment ?? "N/A",
                            plan_type: selectedSubscription?.current_plan ?? "N/A",
                            current_state: selectedSubscription?.state ?? "N/A",
                            account_type: selectedSubscription?.account_type ?? "N/A",
                            billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                            team_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                            team_addons: checkTeamAddons(additionalAddons) ?? 'N/A',
                            team_ip_count: additionalAddons.filter((item)=> item.code === 'dedicated_ip')[0]?.quantity,
                            team_server_count: additionalAddons.filter((item)=> item.code === 'team_server')[0]?.quantity,
                        }
                    }, token)
                }
                else if (redirectedFrom === redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade']) {
                    if(redirected_via){
                        let AppEventsData = {
                            type: "fail_purchaseplan",
                            message: response?.error_code,
                            data:{
                                isPurchasedSucessFully : false,
                                dontCloseModal: dontCloseModalBit(response?.error_code)
                            }
                        }
                        appEventHandler("fail_purchaseplan", AppEventsData);
                    }
                    sendEvents({
                        event_name: "ma_fail_purchase_plan",
                        event_properties: {
                            current_plantype: selectedSubscription?.current_plan ?? "N/A",
                            billingcycle: selectedSubscription?.billingcycle ?? "N/A",
                            amount: getTotalAmount(invoiceData?.orderSummary?.body) ?? "N/A",
                            plan_type: selectedSubscription?.sub_plan_type ?? "N/A",
                            payment_method: payment_method ?? "N/A",
                            current_status: selectedSubscription?.state ?? "N/A",
                            reason: response?.message,
                            account_type: selectedSubscription?.account_type,
                            platform_from: redirected_via ? 'Apps' : 'Member Area',
                            source: selectedSubscription?.source ?? "N/A",
                            feature_name: feature_name ?? selectedSubscription?.feature_name ?? "N/A"
                        }
                    }, token)
                }
                else if(redirectedFrom === redirectedFromPage['volumeLicensing']){
                    sendEvents({
                        event_name: "ma_fail_purchase_volume",
                        event_properties: {
                            reason: response?.message ?? "N/A",
                            payment_method: payment_method ?? "N/A",
                            current_status: selectedSubscription?.state ?? "N/A",
                            billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                            member_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                        }
                    }, token)
                }
                else if(redirectedFrom === redirectedFromPage['teams']){
                    sendEvents({
                        event_name: "ma_fail_purchase_teams",
                        event_properties: {
                            payment_method: payment_method ?? "N/A",
                            amount: totalAmountWithCreditAdjustment ?? "N/A",
                            plan_type: selectedSubscription?.current_plan ?? "N/A",
                            current_state: selectedSubscription?.state ?? "N/A",
                            account_type: selectedSubscription?.account_type ?? "N/A",
                            billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                            team_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                            team_addons: checkTeamAddons(additionalAddons) ?? 'N/A',
                            team_ip_count: additionalAddons.filter((item)=> item.code === 'dedicated_ip')[0]?.quantity,
                            team_server_count: additionalAddons.filter((item)=> item.code === 'team_server')[0]?.quantity,
                            reason: response?.message ?? "N/A",
                        }
                    }, token);
                }
                else {
                    sendEvents({
                        event_name: "ma_fail_upgradesubscription",
                        event_properties: {
                            reason: response?.message ?? "N/A"
                        }
                    }, token)
                }

            }
        } catch (error) {
            setPaymentData({...paymentData, description : (t(APIFAILED)) })

            if(redirectedFrom === redirectedFromPage['familyPlan']){
                sendEvents({
                    event_name:"ma_fail_purchase_familyplan",
                    event_properties:{
                        reason: t('errorscreen_oops_sth_text'),
                        payment_method,
                        productId: selectedSubscription?.plan,
                        subscription_id:selectSubscription.id ?? "N/A",
                        member_count: selectedSubscription?.plan_type
                    }
                }, token)
            }

            else if (redirectedFrom === redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade']) {
                sendEvents({
                    event_name: "ma_fail_purchase_plan",
                    event_properties: {
                        current_plantype: selectedSubscription?.current_plan ?? "N/A",
                        billingcycle: selectedSubscription?.billingcycle ?? "N/A",
                        amount: getTotalAmount(invoiceData?.orderSummary?.body) ?? "N/A",
                        plan_type: selectedSubscription?.sub_plan_type ?? "N/A",
                        payment_method: payment_method ?? "N/A",
                        current_status: selectedSubscription?.state ?? "N/A",
                        reason: t('errorscreen_oops_sth_text'),
                        account_type: selectedSubscription?.account_type,
                        platform_from: redirected_via ? 'Apps' : 'Member Area',
                        source: selectedSubscription?.source ?? "N/A",
                        feature_name: feature_name ?? selectedSubscription?.feature_name ?? "N/A"
                    }
                }, token)
            }

            else if(redirectedFrom === redirectedFromPage['volumeLicensing']){
                sendEvents({
                    event_name: "ma_fail_purchase_volume",
                    event_properties: {
                        reason: t('errorscreen_oops_sth_text'),
                        payment_method: payment_method ?? "N/A",
                        current_status: selectedSubscription?.state ?? "N/A",
                        billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                        member_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                    }
                }, token)
            }

            else if(redirectedFrom === redirectedFromPage['teams']){
                
                sendEvents({
                    event_name: "ma_fail_purchase_teams",
                    event_properties: {
                        payment_method: payment_method ?? "N/A",
                        amount: totalAmountWithCreditAdjustment ?? "N/A",
                        plan_type: selectedSubscription?.current_plan ?? "N/A",
                        current_state: selectedSubscription?.state ?? "N/A",
                        account_type: selectedSubscription?.account_type ?? "N/A",
                        billing_cycle: selectedSubscription?.billingcycle ?? "N/A",
                        team_count: additionalAddons.filter((item)=> item.code === 'purevpn')[0]?.quantity ?? 'N/A',
                        team_addons: checkTeamAddons(additionalAddons) ?? 'N/A',
                        team_ip_count: additionalAddons.filter((item)=> item.code === 'dedicated_ip')[0]?.quantity,
                        team_server_count: additionalAddons.filter((item)=> item.code === 'team_server')[0]?.quantity,
                        reason: t('errorscreen_oops_sth_text'),
                    }
                }, token);
            }

            else {
                sendEvents({
                    event_name:"ma_fail_upgradesubscription",
                    event_properties:{
                        reason: t('errorscreen_oops_sth_text')
                    }
                }, token)
            }
        }
        finally{
            if(!threedSecure){
                setloading(false)
            }
            // To enable it late for cancellation journey
            // clearPath()
        }
    }

    const changePlans = async (e) => {
        setloading(true)
        changePlanAsync(selected, "pay")
    }

    const clearPath = () => {
        history.replace(pathname, '')
    }

    const toggleOnCancel = () => {
        clearPath()
        toggle()
    }

    const toggleToPreview = () => {
        showContentModalForApp()
    }

    const totalPrice = (data) => {
        try {
            let price = {
                total:0,
                subTotal:0,
                tax:0
            };
            data?.body?.items?.forEach((item) => {
                price.total+=item?.amount;
                price.subTotal+=item?.sub_total;
                price.tax+=item?.tax
            })
            
            return price
        } catch (error) {
            toast.error("Something went wrong - PURE-113")
            toggle()
        }
    }

    const buttonText = (text) => {
        return !loading ? t('confirm_order_text') : ( <>{t('confirm_order_text')} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    const handle3dToken = (token) => {
        if(token){
            let formData = {};
            if(Object.keys(afterTokenData).length > 0 ){
                formData = {...afterTokenData, threed_secure_id: token.id};
            }else{
                formData = {
                    subscription_id:selected.subscription_id,
                    plan_code:selected.plan_code,
                    threed_secure_id: token.id
                }
            }
            changePlanAsync(formData, 'pay')
        }else{
            toast.error("Oops, something went wrong. Please try again.")
            setloading(false)
        }
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        // toast.error(error?.message || "Something went wrong!!!")
        setPaymentData({...paymentData, description : error?.message})
        setDeclineState(true)
        setIsPurchased(true)
        setloading(false)
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const getBillinginfo = () => {
        try {
            const subscription = subscriptionReducer?.subscriptions?.body?.find(subscription => subscription.id === invoiceData.orderSummary?.body?.subscription_id)
            return subscription
        } catch (error) {
            toast.error("Something went wrong - PURE114")
            toggle()   
        }
    }

    const continueToNextStep = () =>{
        
        sendEvents({
            event_name: "ma_click_go_back",
            event_properties: {
                platform: platform ?? 'Member area'
            }
        }, token)
        if(paymentData?.paymentStatus){
            dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
            history.push("/dashboard/refer")
            toggle()
        }else{
            setIsPurchased(!isPurchased)
        }   
    }

    const chargeAttempt = (data = {}) => {
        const response = {...data, ...selected}
        changePlanAsync(response, "pay")
    }

    const getFamilyPlanProRatedAdjustment = (currency) => {

        let sub_total = subtotalForProrata(invoiceData?.orderSummary?.body?.items);
        let unit_amount = unitAmountlForProrata(invoiceData?.orderSummary?.body?.items);
        
        let proratedPrice = sub_total - unit_amount;
        let arithmeticSymbol = sub_total > unit_amount ? '+' : '-';

        return `${arithmeticSymbol}${currency}${Math.abs(proratedPrice)?.toFixed(2)}`
    }

    const sumOfAdjustments = () => {
        let sub_total = subtotalForProrata(invoiceData?.orderSummary?.body?.items);
        let unit_amount = unitAmountlForProrata(invoiceData?.orderSummary?.body?.items);
        
        let proratedPrice = sub_total - unit_amount;

        let proration = +Math.abs(proratedPrice)?.toFixed(2);


        let credit = invoiceData.orderSummary?.body?.credit_amount ? +invoiceData.orderSummary?.body?.credit_amount?.toFixed(2) : 0.00 ;

        let total = proration+credit
        return `-${currencies[selectedSubscription.currency]}${total?.toFixed(2)}`
    }

    const subtotalForProrata = (addon={}) => {
        return addon.reduce((a, item) => a + item?.sub_total, 0)
    }

    const unitAmountlForProrata = (addon={}) => {
        return addon.reduce((a, item) => a + (item?.unit_amount * item?.quantity), 0)
    }

    const originItem = invoiceData?.orderSummary?.body?.items?.find((item, key) => {
        return item.code === (currentSub?.service_origin == "puresquare") ? 'purevpn' : currentSub?.service_origin;
    });

    const getCreditAdjustment = (currency) => {
        return `${invoiceData.orderSummary?.body?.credit_amount ? `-${currency}${invoiceData.orderSummary?.body?.credit_amount?.toFixed(2)}` : `${currency}0.00`}`
    }

    const showCouponFor = redirectedFrom ? (redirectedFrom === "plantypeUpgrade" ? selectedSubscription?.sub_plan_type : "familyPlan") : "upgrade"

    const showCredit = () => {
        if (redirectedFrom === redirectedFromPage['upgrade']  && !invoiceData.orderSummary?.body?.credit_amount){
            return false
        }
        else return true
    }
    const closeForMobile = () => {
        history.replace(pathname, '');
        toggle();
    }
    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={plan === 'teams' ? { maxWidth: `850px`, width: "100%" } : { maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className={`modal-wrapper new-cart ${redirected_via ? 'white-bg' : null}`} scrollable>
            {(redirected_via && (isAndroid || isIOS)) && <span className='close-icon' onClick={()=>closeForMobile()}><CloseIconSVG width={24} height={24}/></span>}
                <ModalBody style={redirected_via && !isIOS ? {height: 'calc(100vh - 40px)', overflowY: "auto"} : null}>
                    {
                    !invoiceData?.loading
                        ?
                        <>

                            {
                                redirected_via && messagesForApps.show ? 
                                <>
                                            <PopupBody className='text-center'>
                                                <div className="mod-head mb-0">
                                                    <h3>{messagesForApps.message}</h3>
                                                </div>
                                            </PopupBody>
                                    </>
                                : !isPurchased ?
                                !showCouponView ?
                                <>
                                        <PopupBody>
                                            <div className="mod-head">
                                                <h3>{t(popupHeading[redirectedFrom ?? "default"])}</h3>
                                                {(redirectedFrom === 'featureUpgrade' || feature_name) && <span className='feature_name'>{t('max_cart_text_for_security_tools', {feature_name: featureMapping[feature_name ?? selectedSubscription?.feature_name]})}</span>}
                                            </div>
                                            <div className={`${redirected_via ? 'no-scroll' : null} mod-body`}>
                                                <InfoCard hasborder noflex className="pt-0">
                                                    <div>
                                                        <label>{t('subscription_lable_billing_cycle')} <strong className="text-capitalize">&emsp;&ensp;&nbsp;{`${state.plan_type} - ${getSubPlanTypeToDisplay(state.sub_type)} ` || "-"}</strong></label>
                                                    </div>
                                                    {
                                                        (constants.applicationAddons.includes(selectedSubscription?.service_origin)) ? (
                                                            <div>
                                                                <label>Email Address: <strong>{state.email || "-"}</strong></label>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <label>{t('tbl_username')} <strong>&emsp;&emsp;&nbsp;{state.username || "-"}</strong></label>
                                                            </div>
                                                        )
                                                    }
                                                    <div>
                                                        <label>{t('service_period')} <strong>&nbsp;&nbsp;{state.period || "-"}</strong></label>
                                                    </div>
                                                </InfoCard>
                                                <InfoCard hasborder noflex>
                                                    <OrderSummary open={orderSummary}>
                                                        <label onClick={(e) => setorderSummary(!orderSummary)}><strong className="ms-0 summary">{t('addon_purchasemodal_order_summary')}</strong></label>
                                                        <ul className={classNames({ "show": orderSummary }, "list-unstyled m-0 p-0 mt-3")}>
                                                            {
                                                                invoiceData?.orderSummary?.body?.items.map((item, key) => (
                                                                    <li key={key}>
                                                                        {`${item?.description}`} {`${item?.quantity > 1 ? `x ${item?.quantity}`: ''}`} <strong className="text-end">{currencies[selectedSubscription.currency]}{itemUnitAmount(item)?.toFixed(2)}</strong>
                                                                    </li>
                                                                ))
                                                            }
                                                            {
                                                                ((redirectedFrom == redirectedFromPage['plantypeUpgrade'] || redirectedFrom === redirectedFromPage['featureUpgrade'] || redirectedFrom == redirectedFromPage['volumeLicensing'] || redirectedFrom == redirectedFromPage['teams']) && !whmcsUser) &&
                                                                <li>
                                                                    {/* Not shown on upgrade b/c there we take complete plan amount as user shifts to higher billing cycle */}
                                                                    {t('prorated_adjustment')}<strong className="text-end">{getFamilyPlanProRatedAdjustment(currencies[selectedSubscription.currency])}</strong>
                                                                </li>
                                                            }
                                                            {/* {!whmcsUser || (whmcsUser && redirectedFrom == "volumeLicensing") && <li> */}
                                                            {!whmcsUser && showCredit() && redirectedFrom !== redirectedFromPage['familyPlan'] && <li>
                                                                {t('credit_adjustment_text')} <strong className='text-end'>{getCreditAdjustment(currencies[selectedSubscription.currency])}</strong>
                                                            </li>}
                                                            {
                                                                redirectedFrom == redirectedFromPage['familyPlan'] && <li>
                                                                    {t('prorated_adjustment')}<strong>{sumOfAdjustments()}</strong>
                                                                </li>
                                                            }
                                                            {/* {redirectedFrom !== redirectedFromPage['upgrade'] && <li>
                                                                VAT <strong className="text-end">{currencies[selectedSubscription.currency]}{Object.keys(invoiceData.orderSummary).length !== 0 ? totalPrice(invoiceData.orderSummary).tax.toFixed(2) : "-"}</strong>
                                                            </li>} */}
                                                        </ul>
                                                    </OrderSummary>
                                                </InfoCard>
                                                    {
                                                        (redirectedFrom !== redirectedFromPage['upgrade'] || (redirectedFrom === redirectedFromPage['upgrade'] && currentSub?.plan?.interval_length === 60 || currentSub?.plan?.interval_length === 61)) && <>
                                                            <CouponField isCouponApplied={isCouponApplied} setCouponApplied={setCouponApplied} setShowCouponView={setShowCouponView} showCouponView={showCouponView} setOldInvoice={setOldInvoice} couponName={filter_coupons[showCouponFor]}/>
                                                            <DiscountAndTotalField invoiceData={invoiceData} currency={currencies[selectedSubscription.currency]} isCouponApplied={isCouponApplied} bCycle={true} totalAmtFunc={getTotalAmount}/>
                                                        </>
                                                    }
                                                    {
                                                        redirectedFrom == redirectedFromPage['upgrade'] && currentSub?.plan?.interval_length !== 60 && currentSub?.plan?.interval_length !== 61 && 
                                                        <>
                                                            {!isCouponCodeApplied && <CouponView couponCode={couponCode} setCouponCode={setCouponCode} getPreviewData={getPreviewData} setIsCouponCodeApplied={setIsCouponCodeApplied} setIsCouponCodeAppliedLoading={setIsCouponCodeAppliedLoading} />}
                                                            {isError && !isCouponCodeApplied && <ErrorMsg errorMsg={isError}/>}
                                                            {!isCouponCodeAppliedLoading ? 
                                                            <DiscountAndTotalField invoiceData={invoiceData} currency={currencies[selectedSubscription.currency]} isCouponApplied={isCouponApplied || isCouponCodeApplied} bCycle={true} totalAmtFunc={getTotalAmount}/>:
                                                            <div className="mod-body" style={{ minHeight: "60px", marginTop: "10px" }}>
                                                                <Skeleton className="d-block mb-2" width={240} />
                                                                <Skeleton className="d-block mb-2" width={100} />
                                                                <Skeleton className="d-block mb-2" width={50} />
                                                            </div>}
                                                        </>
                                                    }
                                                {
                                                    !(declineState || whmcsUser || allowPurchaseForManualGateway || isRemovedGateway(currentSub?.payment_gateway)) && (
                                                        <InfoCard>
                                                            <PaymentIcon lastFour={getBillinginfo()?.billing_info?.last_four} gateway={getBillinginfo()?.payment_gateway}/>
                                                        </InfoCard>
                                                    )
                                                }
                                            </div>
                                        </PopupBody>
                                    {
                                        !(declineState || whmcsUser || allowPurchaseForManualGateway || isRemovedGateway(currentSub?.payment_gateway)) ?
                                            <ModalFooter className='p-0 pt-2'>
                                                {
                                                    <PrimaryBtn onClick={changePlans} pointer={loading} color="primary">{buttonText("Confirm order")}</PrimaryBtn>
                                                }
                                                <DestructBtn color="secondary" pointer={loading} onClick={()=>redirected_via ? toggleToPreview() : toggleOnCancel()}>{t('cancel_text')}</DestructBtn>
                                            </ModalFooter>
                                            :
                                            <PopupPurchaseUtil isAllowToUseParamMethod={isAllowToUseParamMethod} endPoint={endPoint} selectedData={selected} currentSub={currentSub} chargeAttempt={chargeAttempt} toggle={ redirected_via ? toggleToPreview : toggle}/>
                                    }
                                </>
                                :
                                    <CouponField isCouponApplied={isCouponApplied} setCouponApplied={setCouponApplied} setShowCouponView={setShowCouponView} showCouponView={showCouponView} setOldInvoice={setOldInvoice} couponName={filter_coupons[showCouponFor]}/>
                                    :
                                <>
                                    <PopupBody className='text-center'>
                                        <div className="mod-head mb-0">
                                            <img src={paymentData.image} height="60px" width="60px" className="img-responsive mb-4" />
                                            <h3>{paymentData?.heading ?? t('errorscreen_oops_text')}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: paymentData?.description || t('renewal_plan_not_suppoesed_to_here') }}></p>
                                        </div>
                                    </PopupBody>
                                    {!toNotDisplayRefreshCart(platform) && <ModalFooter className='p-0 pt-2'>
                                        <DestructBtn className='mx-auto' color="secondary" onClick={continueToNextStep}>{paymentData.ctaText}</DestructBtn>
                                    </ModalFooter>}
                                </>
                            }
                        </>
                        :
                            <div className="mod-body" style={{ minHeight: "200px" }}>
                                {/* <span className="spinner-border"></span> */}
                                <Skeleton className="d-block mb-3" width={300} />
                                <Skeleton className="d-block mb-3" width={280} />
                                <Skeleton className="d-block mb-3" width={260} />
                                <Skeleton className="d-block mb-3" width={240} />
                                <Skeleton className="d-block mb-3" width={220} />
                                <Skeleton className="d-block mb-3" width={50} />
                            </div>
                }
                </ModalBody>
                
            </Modal>
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                    actionTokenId={threedSecure.three_d_secure_action_token_id}
                    onToken={handle3dToken}
                    onError={handle3dError}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, { getUpgradePlansRequested, getSubscriptionsRequest, getFamilyPlanRequested, getUpgradePlansReset, getFamilyPlanReset, resetSubscriptionRequest })(withRouter(RecurlyRoute(BillingCycle)))
