import styled, { css } from "styled-components";

export const BusinessContainer = styled.div`
    max-width: 910px;
    margin: 0 auto;
    padding: 30px 0;
`

export const BusinessHead = styled.div`
    text-align:center;
    max-width:675px;
    width:100%;
    margin:0 auto 90px;
`
export const BusinessHeading = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: #43225B;
    margin:0 0 30px;
    .mb-0{
        margin: 0px;
    }
    & @media (max-width:500px){
        font-size:25px;
        line-height:30px;
    }
`
export const BusinessLicencesContainer = styled.div`
    margin: 45px 0;
    display: inline-flex;
    gap: 30px;
    flex-direction: column;

   
`
export const BusinessLicence = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap:30px;
    row-gap:30px;
    &.reverse{
        flex-direction: row-reverse;
    }
    .w-full{
        width:"100%"
    }
    .flex-1{
        flex:1;
    }
    .text-left{
        text-align: left;
    }
    @media (max-width:500px){
        flex-direction: column !important;
        .text-left{
            text-align: center;
        }
    }
`

export const BusinessText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #7B648C;
    margin:0 0 30px;
    @media (max-width:500px){
        font-size:14px;
    }
`
export const BusinessDesc = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: #7B648C;
    margin-bottom: 30px;
`

export const BusinessSubHeading = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #43225B;

    .f24{
        font-size: 24px;
    }
`
export const PrimaryFaqHeading = styled.h6`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.25px;
    color: #43225B;
`
export const BusinessHeadLogo = styled.img`
    padding-bottom:30px;
`
export const BusinessCard = styled.div`
    padding: 30px 110px;
    background: #FBFBFC;
    border: 1px solid rgba(141, 149, 160, 0.25);
    border-radius: 24px;
    h3{
        text-align: center;
    }
    width: 100%;
    margin: 0 auto;
    margin-bottom:90px;

    @media (max-width:500px){
        padding: 30px 10px;
    }
`
export const BusinessButton = styled.button`
    background: #6536CC;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    border: none;
    line-height: 125%;
    margin-top: 20px;
`
export const BussinessImgContainer = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: Wrap;
`
export const BusinessImg = styled.img`

`

export const BusinessBtnFlex = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px ;
`
export const TrialBtnContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    & p{
        margin: 0 !important;
        color: black !important;
        font-size: 14px !important;
        width: fit-content !important;
    }

`
export const BusinessTabs = styled.div`
    .nav-tabs{
        border:none;
    }
    .nav-item{
        border-bottom: 1px solid #dee2e6;
        cursor:pointer;
    }
    .nav{
        justify-content: center;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
        color: #001219;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        border-color: transparent transparent transparent transparent ; 
        border-bottom: 3px solid #6536CC;
    }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
        border-color: transparent transparent transparent transparent ; 
        border-bottom: 3px solid #6536cc;
    }
    .nav-link{
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.25px;
        color: #637381;
        padding: 0.7rem 1rem;
        margin: 0rem 0.3rem; 

    }
`

export const VideoCard = styled.div`
    background: #FFFFFF;
    border: 1px solid #6536CC;
    border-radius: 12px;
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    box-shadow: 0 8px 14px -10px grey;
    height: fit-content;
`
export const VideoIframe = styled.iframe`
    border-radius: 8px;
    @media (max-width:500px){
        height: 300px;
    }
`

export const TabDetail = styled.div`
    display: flex;
    margin-top:4rem;
    gap: 30px;
`

export const BusinessLogoSection = styled.div`
    text-align: center;
    margin: 20px auto;
    h2{
        color: #000C0F;
        margin-bottom:15px;
    }
`
export const BusinessCta = styled.button`
    background: #00B4D8;
    padding: 13px 20px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border:0;
    font-weight:600;

    :hover{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #00B4D8;
    }
`
export const BusinessFAQSection = styled.div`
    margin: 90px auto 0;
    display:flex;
    flex-direction: column;
    gap:15px;
    & h1{
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        color: #000C0F;
    }
    & .faq-card{

        & p{
            font-weight: 400 !important;
            font-size: 16px !important;
            line-height: 24px !important;
            letter-spacing: 0.25px !important;
            color: #7D858F !important;

            & span , a{
                font-size: 16px !important;
                line-height: 24px;
                color: #0D85E8 !important;
                cursor: pointer;
                font-weight: 400;
                text-decoration: underline;
            }
            & .chat{
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }
        }
        .faq-answer {
            display: none;
            margin-top: 0px !important;
    
            p {
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 24px !important;
                letter-spacing: 0.5px !important;
                color: #7B648C !important;
            }
          }
          &.open {
            .icon {
              cursor: pointer;
              &:before{ transform: rotate(90deg); }
              &:after{ transform: rotate(180deg); }
            }
            .faq-answer {
              display: flex;
            }
          }
        border-radius: 12px;
        width:100%;
        margin: 0 auto;
        display:flex;
        flex-direction:column;
        gap:15px;
    }
`

export const TabDetailContent = styled.div`
    h2{
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #000C0F;
    }
    ul li{
        font-weight: 400;
        font-size: 16px !important;
        line-height: 25px;
        color: #001219 !important;
    }
`

export const Businesshr = styled.hr`
    margin: 45px auto 45px;
    border: 2px dashed #DDD4E2;
`

export const PrimarySec = styled.div`
    text-align:center;
    max-width:910px;
    width:100%;
    margin:auto;
    &.mt-7{
        margin:90px auto 0;
    }
`
export const PrimaryHead = styled.div`
    margin: 0 0 30px;
    & img{
      margin-top:30px;  
    }
`
export const PrimaryHeading = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #43225B;
    @media (max-width:500px){
        font-size:25px;
        line-height:30px;
    }
    &.mb-30{
        margin-bottom:30px;
    }
`
export const Primarytext = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #7D858F;
    margin:10px 0 0;
`

export const PrimaryBox = styled.div`
    background: #FFFFFF;
    border: 1px solid #EBEEF0;
    border-radius: 12px;
    padding:30px 30px 22px;
    max-width:440px;
    width:100%;
`
export const PrimarySubHeading = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: #000C0F;
    margin:15px 0 15px;
`
export const PrimarySubText = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7D858F;
    margin:0;
`
export const PrimaryBoxes = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    ${({ hasMiniBoxes }) => hasMiniBoxes && css`
        ${PrimarySubHeading}{
            font-size: 14px;
            margin-bottom:5px;
        }
        ${PrimaryBox}{
            max-width:283px;
            box-shadow: 0px 4px 10px rgba(67, 34, 91, 0.05), 0px 10px 30px rgba(67, 34, 91, 0.1);
            border-radius: 12px;
            padding-bottom:12px;
        }
    `}
`
export const TableSection = styled.section`
    margin: 60px auto;
    text-align: center;
    `

export const TableSec = styled.div`
    h2{
        color: #43225B;
        margin-bottom: 30px;
    }
    margin:90px auto 0;
    max-width:910px;
    width:100%;
    overflow-x:auto;
    .note-Section{
        color:red;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: flex-start;
        margin-top: 20px;
    }
    & table{
        max-width:910px;
        border: 1px solid #EEEDF0;
        border-radius: 16px;
        overflow: hidden;
        background:white;
        & thead{
            background: #EEFAFC;
            & th{
                padding:15px;
                text-align:center;
                border: 1px solid #EEEDF0;
                font-weight: 600;
                font-size: 16px;
                line-height: 27px;
                text-align: center;
                color: #43225B;
            }
        }
        & td{
            padding:15px 30px;
            border: 1px solid #EEEDF0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;
            letter-spacing: 0.5px;
            color: #43225B;
            text-align: start;
        }
        & td:not(:first-child){
            text-align:center;
        }
        & tr:nth-last-child(2) td:not(:first-child):nth-last-child(-n+2) {
            width: 250px;
            padding: 20px ;
            font-weight: 400;
            font-size: 12px;
            color: #43225B;
        }
        & tr:last-child td{
            padding:30px;
            border-radius: 16px;
        }

        & p{
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #43225B;
        }

    }
    @media (max-width: 560px) {
        & table {
          & td{
            padding:15px 10px;
          }
    
          & td:not(:first-child) {
            width: auto;
            text-align: left;
          }
        }
      }
    }
`
export const ModalButton = styled.button`
    background: #00B4D8;
    box-shadow: 0px 4px 12px rgba(0, 180, 216, 0.25);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
    border:none;
    padding:12px 30px;
    display:table;
    margin:30px auto 0;
`

export const ModalHeading = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000C0F;
    margin:0;
`

export const ModalText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #7D858F;
    margin:8px 0 30px;
`
export const ModalForm = styled.form`
    & .row{
        @media (max-width:500px){
            margin-bottom:0px !important;
            & .form-group{
                margin:0 0 15px;
            }
        }
    }
    & label{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #7B648C;
        margin:0 0 9px;
        & span{
            color:red !important;
            padding-left:5px;
            line-height:0;
        }
    }
    & .row{
        margin-bottom:10px;
    }
    & select, & input{
        width:100%;
        padding-left: 11px;
        color: #43225B !important;
        -webkit-appearance: none;
        @media (max-width:768px){
            font-size:12px !important;
            height:35px !important;
        }
    }
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    & input.error, & select.error{
        outline:1px solid red;
    }
    & select {
        background: #ffffff url(https://images.purevpn-tools.com/public/images/s-drop-carte.jpg) no-repeat;
        background-position:calc(100% - 10px) center;
        background-size:24px;
    }
    & select::placeholder{
        color:#43225B !important;
        opacity: 0.5;
    }
    & .btns{
        display:flex;
        gap:10px;
    }
    & .sub-text{
        font-size:10px !important;
        color:#7D858F !important;
        display:inline-block;
    }
    & .textArea{
        width: 100%;
        height: 150px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 1px solid #ECE9EE;
        border-radius: 4px;
        font-size: 16px;
        resize: none;
    }
    & .textArea:focus-visible{
        outline:none;
    }
    textarea.error{
        border:1px solid red;
    }
    & button{
        width:105px;
        height:40px;
        border-radius: 8px;
        border:0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.0025em;
        @media (max-width:768px){
            height:35px;
            font-size: 12px;
        }
    }
    & .submit{
        background: #00B4D8;
        color: #ffffff;
    }
    & button.loading{
        pointer-events:none;
    }
    & button.submit.loading{
        background: rgb(0 180 216 / 41%);
    }
`