export const addonContent = {
    port_forwarding: `addoncontent_portforwarding`,
    ddos_protection:`addoncontent_ddosprotection`,
    dedicated_ip:`addoncontent_dedicatedip`,
    port_forwarding_with_dedicated_ip:`addoncontent_port_forwarding_with_dedicated_ip`,
    purekeep:`addoncontent_purekeep`,
    purecrypt:`addoncontent_purecrypt`,
    purescan:`addoncontent_purescan`,
    familyplan:`addoncontent_familyplan`,
    puremax: `addoncontent_puremax`,
    multi_login: `addoncontent_multi_login`,
    dedicated_server:`addoncontent_portforwarding`,
    residential_network:  `addoncontent_residentialnetwork`
}   

