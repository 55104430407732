
import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import pureKeepLogo from '../../../assets/onboarding-sprites/purekeep/logo.svg'
import purePrivacyBg from '../../../assets/onboarding-sprites/pureprivacy/ACSD-30868-background.svg'
import purePrivacyLogo from '../../../assets/onboarding-sprites/pureprivacy/BA-700-PurePrivacy.png'
import appstoreIcon from '../../../assets/onboarding-sprites/pureprivacy/ACSD-30868-appstore.svg'
import googleplayIcon from '../../../assets/onboarding-sprites/pureprivacy/ACSD-30868-googleplay.svg'
import accordionImage1 from '../../../assets/onboarding-sprites/pureprivacy/BA-324-easilty-limit-your-old-posts.png'
import accordionImage2 from '../../../assets/onboarding-sprites/pureprivacy/BA-324-Stop-third-party-advertisers.png'
import accordionImage3 from '../../../assets/onboarding-sprites/pureprivacy/BA-324-Auto-delete-your-search-history.png'
import accordionImage4 from '../../../assets/onboarding-sprites/pureprivacy/BA-324-Connect-to-control.png'
import availableIcon from '../../../assets/onboarding-sprites/purekeep/available-icon.svg'
import crossIcon from '../../../assets/onboarding-sprites/purekeep/cross-icon.svg'
import purekeepTableLogo from '../../../assets/onboarding-sprites/purekeep/PUREKEEP-table-logo.svg'
import { NewSignupViewWrapper, UpgradeFAQ, LazyIframe } from '../../../styles/dashboard/Main'
import { LinkBtn, PrimaryBtn, RLink,  } from '../../../styles/Generic'
import { constants } from '../../../tools/constants';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const PurePrivacySignupView = ({ history, subscriptionData, buttonClickHandler }) => {

    const { t, i18n } = useTranslation();
    const [imgState,setImgState] = useState(0);
    const [faqs, setFaqs] = useState([
        {
            question: "pureprivacy_faqs_question_one",
            answer: "pureprivacy_faq_one_answer",
            open: false,
        },
        {
            question: "pureprivacy_faqs_question_two",
            answer: "pureprivacy_faq_two_answer",
            open: false,
        },
        {
            question: "pureprivacy_faq_three_heading",
            answer: "pureprivacy_faq_three_para",
            open: false,
        },
        {
            question: "pureprivacy_faq_four_heading",
            answer: "pureprivacy_faq_four_para",
            open: false,
        },
    ]);

    const [listItems, setListItems] = useState([
        {
            heading: "pureprivacy_accordion_five_heading",
            content: "pureprivacy_accordion_five_para",
            open: true,
            img:accordionImage1,
        },
        {
            heading: "pureprivacy_accordion_six_heading",
            content: "pureprivacy_accordion_six_para",
            open: false,
            img:accordionImage3,
        },
        {
            heading: "pureprivacy_accordion_seven_heading",
            content: "pureprivacy_accordion_seven_para",
            open: false,
            img:accordionImage2,
        },
        {
            heading: "pureprivacy_accordion_eight_heading",
            content: "pureprivacy_accordion_eight_para",
            open: false,
            img:accordionImage4, 
        },
    ]);

    const buttonDownloadAppFromStore = (device) => {     
        if (device === "ios") {
            window.open(`${constants.pureprivacyAppleDownloadLink}`);
        }
        if (device === "android") {
            window.open(`${constants.pureprivacyGoogleDownloadLink}`);
        }
    }

    const faqToggle = (index) => {

        let updatedFaqs = []

        faqs.map((faq, idx) => {
            if (idx === index) {
                updatedFaqs.push({
                    ...faq,
                    open: (!faq.open)
                })
            } else {
                updatedFaqs.push({...faq})
            }
        })

        setFaqs(updatedFaqs)
    }

    const listToggle = (index) => {

        let updatedListItems = []

        listItems.map((item, idx) => {
            if (idx === index) {
                setImgState(idx)
                updatedListItems.push({
                    ...item,
                    open: (!item.open)
                })
            } else {
                updatedListItems.push({
                    ...item,
                    open: false
                })
            }
        })

        setListItems(updatedListItems)
    }

    return (
        <NewSignupViewWrapper className='content' bgimg={purePrivacyBg} nocover accordionimg={listItems[imgState].img} accordionbgColour="privacy" bgColor="pureprivacy" pureprivacy>
            <section className='row section-seven variant-2'>
                <div className='wrapper'>
                    <div className='bg'>
                        <div className="content">
                            <img className="logo" src={purePrivacyLogo}/>
                            <h2 className='heading'></h2>
                            <p className='para'>{t('pureprivacy_banner_para')}</p>
                            <ul className='list'>
                                <li>{t('pureprivacy_reclaim_your_data')}</li>
                                <li>{t('pureprivacy_banner_protect')}</li>
                                <li>{t('pureprivacy_banner_blocked')}</li>
                            </ul>
                             <div className='button-wrapper'>
                                <PrimaryBtn className='primary-btn' onClick={(e) => buttonClickHandler(e)}>{t('get_started_text')}</PrimaryBtn>
                            </div>
                            {/* <ul className='social-list'>
                                <li className="facebook"></li>
                                <li className="twitter"></li>
                                <li className="instagram"></li>
                                <li className="linkdin"></li>
                                <li className="spotify"></li>
                                <li className="google"></li>
                                <li className="youtube"></li>
                                <li className="tiktok waiting"></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-nine variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('pureprivacy_complete_control')}</h2>
                    <div className='row'>
                        <div className="col accordion">
                            {
                                listItems.map((item, idx) => {
                                    return (
                                        <section key={idx}  className={`accordion-item ${ (item.open) ? "open" : "" }`} onClick={() => listToggle(idx)}>
                                            <h3>{t(item?.heading)}</h3>
                                            <div className="accordion-item-content">
                                                <p>{t(item?.content)}</p>
                                            </div>
                                        </section>
                                    )
                                })
                            }
                        </div>
                        <div className='col right-side'>
                            <div className='bg'></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-five variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('pureprivacy_how_to_protect_social_media')}</h2>
                    <p className='pureprivacy-para'>{t('pureprivacy_protect_text')}</p>
                    <div className='row grids'>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src="https://images.purevpn-tools.com/public/images/BA-700-step-1-icon.png" />
                                <h4>{t('pureprivacy_step_one_login')}</h4>
                                <p>{t('pureprivacy_connect_social_media_text')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src="https://images.purevpn-tools.com/public/images/BA-408-icon-2.png" />
                                <h4>{t('pureprivacy_step_two_create_master_pw')}</h4>
                                <p>{t('pureprivacy_step_two_create_master_pw_para')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src="https://images.purevpn-tools.com/public/images/BA-408-icon-3.png" />
                                <h4>{t('pureprivacy_step_three_create_vault')}</h4>
                                <p>{t('pureprivacy_step_three_create_vault_para')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-five variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('pureprivacy_reclaim_data_heading')}</h2>
                    <div className='row mt-4 reclaim-section'>
                        <div className='col-md-6'>
                        <img src="https://images.purevpn-tools.com/puresquare/BA-700-pureprivacy.gif" height="500" width="300" loading="lazy" />
                        </div>

                        <div className='col-md-6 reclaim-text-box'>
                            <div className='reclaim-text'>
                                <h3>{t('pureprivacy_reclaim_how_to_work')}</h3>
                                <p>{t('pureprivacy_reclaim_how_to_work_para')}</p>
                                <h3>{t('pureprivacy_reclaim_empower')}</h3>
                                <p>{t('pureprivacy_reclaim_empower_para')}</p>
                                <h3>{t('pureprivacy_reclaim_get_to_work')}</h3>
                                <p>{t('pureprivacy_reclaim_get_to_work_para')}</p>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-two variant-2'>
                <h2 className='heading'>{t('pureprivacy_table_heading')}</h2>
                <p className='para'>{t('pureprivacy_table_heading_para')}</p>
                <div className='pricing-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('pureprivacy_table_features')}</th>
                                <th className='text'>{t('pureprivacy_table_proplan')}</th>
                                <th>{t('pureprivacy_table_freeplan')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('onboarding_pureprivacy_signup_connect_facebook_account')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={availableIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_table_security_preference')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={availableIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_table_scan_account')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={availableIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_table_view_recommendation')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={availableIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_table_apply_recommendation')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_discover_potential')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_automatically_send')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_prevent_online')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_stop_getting')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                            <tr>
                                <td>{t('pureprivacy_table_complete_control')}</td>
                                <td><img src={availableIcon} /></td>
                                <td><img src={crossIcon} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='button-wrapper'>
                    <PrimaryBtn className='primary-btn' onClick={(e) => buttonClickHandler(e)}>{t('pureprivacy_get_cta_text')}</PrimaryBtn>
                </div>
            </section>
            <section className='row section-eight variant-2'>
                <div className="content">
                    <h5 className='heading'>{t('available_on')}</h5>
                    <div className='button-wrapper'>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('ios')} target="_blank"><img className="button" src={appstoreIcon} /></LinkBtn>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('android')} target="_blank"><img className="button" src={googleplayIcon} /></LinkBtn>
                    </div>
                </div>
            </section>            
            <section className='row faqs variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('faqs_heading')} <span>PurePrivacy</span></h2>
                    {
                        faqs.map((faq, idx) => {
                            return (
                                <div key={idx} className={`item ${ (faq.open) ? "open" : "" }`}>
                                    <div className='question' onClick={() => faqToggle(idx)}>
                                        <p>{t(faq.question)}</p>
                                        <span className='icon'></span>
                                    </div>
                                    <div className='answer'>
                                        <p>{t(faq.answer)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </NewSignupViewWrapper>
    )

}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, { getSubscriptionsRequest })(PurePrivacySignupView)
