import React, { useEffect, useState } from 'react'
import { CardBox, InfoCard, DownloadViewWrapper } from '../../../styles/dashboard/Main'
import { getDeviceRequested } from '../../../redux/downloads/actions'
import { sendEvents} from "../../../tools/mpEvents"
import { filterSlug } from '../../../tools/tools';
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import DownloadAppBox from '../download/DownloadAppBox';
import DownloadSidebarBox from '../download/DownloadSidebarBox';
import { constants } from '../../../tools/constants';
import { setActiveDevices } from '../../../tools/genericMethods';
import AllDevices from '../download/modal/AllDevices';
import macos from '../../../assets/device_mac.jpg';
import { osName } from 'react-device-detect';
import { seti18Locale } from '../../../tools/tools';
import { useTranslation } from 'react-i18next';

const DownloadView = ({history, key, appSlug, appName, getDeviceRequested, accountReducer, onBoardingReducer, download, selectedStep, step, selectStepCallback, purpose, isOnBoarding = false}) => {

    const token = localStorage.getItem("token");

    const get_locale = seti18Locale();

    const [loading, setLoading] = useState(true)

    const [devices, setDevices] = useState([])

    const [crashed, setCrashed] = useState(false)

    const [device, setDevice] = useState("device1")

    const [modal, setModal] = useState(false);

    const [apps, setApps] = useState([]);

    const toggle = () => setModal(!modal);

    const [selectedApp, setSelectedApp] = useState(0);

    const currentDevice = osName

    const [data, setData] = useState({
        devices:[{
            img:macos,
            heading: "",
            osx:"",
            hash:"",
            setup_guides:[],
            link:"",
            release_notes:"",
            abbreviation:"",
            versions:[]
        }],
        allDevices:[],
        extensionDevice: false,
        slug:false,
        loaded:false
    })

    const [showThankyouPage,setShowThankyouPage] = useState(false)

    const [formData,setFormData] = useState({})

    useEffect(() => {

        if (download.devices == null && get_locale !== 'en') { 
            getDeviceRequested(token)
        }

    }, [])

    useEffect(() => {

        const { errors } = download;

        if(download && typeof download.devices?.body !== undefined && download.devices?.body.length > 0){

            setApps(download.devices?.body)

            download.devices?.body.map((app, idx) => {

                if (app.slug == appSlug && typeof app?.devices != "undefined") {
                    setSelectedApp(idx)
                    setActiveDevices(download, app.devices, data, setData, appSlug, selectedApp, false, "", 0, null, selectedStep)
                    setDevice(currentDevice)
                    setTimeout(() => {
                        setLoading(false);
                    }, 600)
                }
            })
        } else {
            setCrashed(true)
            setLoading(false);
        }
        if(errors) {
            setCrashed(true)
            setLoading(false);
        }

    }, [download])

    const downloadDevice = (link, name, version, abbreviation) => {
        // Is Onboarding
        if (isOnBoarding) {
            sendEvents(
                {
                  event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                  event_properties: {
                    cta: "download_" + abbreviation,
                  }
        
                }, token
              );
        }
        if(abbreviation != "linux"){
            window.open(link, '_blank');
        }
        selectStepCallback(selectedStep + 1)
    }

    const sendReleaseNotesEvents = (name) => {

        // Is Onboarding
        if (isOnBoarding) {
            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: name,
                }
        
                }, token
            );
        }
    };

    const versionClickEvents = (name) => {

        // Is Onboarding
        if (isOnBoarding) {

            let slug = filterSlug(name)

            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: slug,
                }
        
                }, token
            );
        }
    };

    const sendOtherDevicesModalEvents = (name) => {

        // Is Onboarding
        if (isOnBoarding) {

            let slug = filterSlug(name)

            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: "other_devices_" + slug,
                }
        
                }, token
            );
        }
    };

    const sendOtherDevicesEvents = (name) => {
        // Is Onboarding
        if (isOnBoarding) {

            let slug = filterSlug(name)

            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: "other_devices_" + slug,
                }
        
                }, token
            );
        }

    }

    const sendGuidesEvents = (e) => {

        // Is Onboarding
        if (isOnBoarding) {

            let slug = filterSlug(e.target.innerHTML)

            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: slug,
                }
        
                }, token
            );
        }
    };

    const moreAppsCallback = (e) => {

        // Is Onboarding
        if (isOnBoarding) {

            let slug = filterSlug(e.target.innerHTML)

            sendEvents(
                {
                event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
                event_properties: {
                    cta: slug,
                }
        
                }, token
            );
        }
        toggle();
    };

    const nextCTACallback = (e) => {
        let slug = filterSlug(e.target.innerHTML)
        sendEvents(
            {
              event_name: `${constants.eventPrefixOnboarding}click_${appSlug}_${step.slug}`,
              event_properties: {
                cta: slug,
              }
    
            }, token
        );
        selectStepCallback(selectedStep + 1)
    }

    const skeleton = (html) => {
        return (loading && data?.devices?.length == 0) ? (
            <DownloadViewWrapper>
                <div className="row">
                    <div className="col-md-8">
                        <CardBox open={true}>
                            <InfoCard hasborder noflex className="mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard noflex className="pt-0">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                        </CardBox>
                    </div>
                    <div className="col-md-4">
                        <InfoCard hasborder noflex className="mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard hasborder noflex className="pt-0 mb-3">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                        <InfoCard noflex className="pt-0">
                            <Skeleton height={20} width={300}/>
                        </InfoCard>
                    </div>
                </div> 
            </DownloadViewWrapper>
        ) : html
    }
    return skeleton(
        <DownloadViewWrapper className={showThankyouPage && `pt-0`}>
            {
                crashed
                ? (
                    <div className="row">
                        <div className="col-md-8">
                            <h1>{appName} apps comming soon.</h1>
                        </div>
                    </div>
                ) : <div className="row">
                        <div className="col-md-8">
                            <DownloadAppBox selectedApp={selectedApp} isOnBoarding={true} download={download} nextCTACallback={nextCTACallback} purpose={purpose} data={data} setData={setData} devices={devices} appName={appName} setDevice={setDevice} downloadClickCallback={downloadDevice} releaseNotesClickCallback={sendReleaseNotesEvents} versionClickCallback={versionClickEvents} sendGuidesEvents={sendGuidesEvents} setShowThankyouPage={setShowThankyouPage} setFormData={setFormData}/>
                        </div>
                        <div className="col-md-4">
                            <DownloadSidebarBox selectedApp={selectedApp} data={data} download={download} setData={setData} appSlug={appSlug} device={device} setDevice={setDevice} devices={data.allDevices} sendGuidesEvents={sendGuidesEvents} moreAppsCallback={moreAppsCallback} sendOtherDevicesEvents={sendOtherDevicesEvents} />
                        </div>
                        {
                            modal && <AllDevices key={key} modal={modal} selectedApp={0} appSlug={appSlug} appName={appName} toggle={toggle} devices={data.allDevices} sendOtherDevicesModalEvents={sendOtherDevicesModalEvents} />
                        }
                    </div>                
            }
        </DownloadViewWrapper>
    )
}
const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer,
        download: state.devicesReducer
    };
};

export default connect(mapStateToProps, {getDeviceRequested})(DownloadView)
