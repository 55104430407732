import { GETINVOICEREQUESTED, ERRORS, LOADING, GETINVOICES } from "./types"

export const getInovicesRequested = (payload) => {
    return{
        type:GETINVOICEREQUESTED,
        payload
    }
}

export const getInvoicesType = (payload) => {
    return{
        type:GETINVOICES,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
