import notcheck from '../assets/notcheck.png'
import check from '../assets/check.png'
import payment from '../assets/payment_icons.png'
import cardicon from '../assets/payment-sprite.svg'
import aff_bg from '../assets/aff_bg.png';
import business_bg from '../assets/business_bg.png';
import navicons from '../assets/navigation_icons.svg'
import FlatOnBoardingIcons from '../assets/flat-icons.svg';
import GradientOnBoardingIcons from '../assets/ACSD-30300-onboaridng-icons-color.svg';
import OnBoardingLockIcons from '../assets/ACSD-30300-nav-onboarding-sprte.svg';
import PureAILogo from '../assets/pure-ai-logo.svg';



export const theme = {
    typography:{
        h1:"30px",
        h2:"24px",
        h3:"20px",
        body:"16px",
        smallBody:"14px",
        smallerBody:"12px",
        link:"12px",
        caption:"12px",
    },
    branding:{
        heading:"#43225B",
        body:"#7B648C",
        link:"#0D85E8",
        primaryCta:{bg:"#550CCB", color:"#fff", hover:"4d12ab", fontSize:"14px", padding:"16px 24px"},
        secondaryCta:{bg:"#FFFFFF", color:"#8E7A9D", fontSize:"14px", padding:"13px 20px"},
        closeCta:{bg:"#FFFFFF", color:"#550CCB", fontSize:"14px", padding:"13px 20px"},
        insetCta:{bg:"#FFFFFF", color:"#8E7A9D", fontSize:"12px", padding:"6px 10px"},
        cancelCta:{bg:"#FF1744", color:"#fff", fontSize:"14px", padding:"13px 20px"},
        lowVisibility:{bg:"#fff",color:"#a08989",fontSize:"12px",padding:"6px 10px"},
        danger: "#FF1744",
        grace: "#C46715",
        success: "#2CDC82"
    },
    passwordChecker:{
        notVerify:notcheck,
        verify:check
    },
    payment:{
        visa: `url(${payment}) no-repeat 0px 0px`,
        amex: `url(${payment}) no-repeat -48px 0px`,
        mc: `url(${payment}) no-repeat -24px 0px`,
        up: `url(${payment}) no-repeat -72px 0px`,
        applepay: `url(${cardicon}) no-repeat 0px -143px`,
        paypal: `url(${payment}) no-repeat -96px 0px`,
        coingate: `url(${cardicon}) no-repeat 0px -48px`,
        card: `url(${cardicon}) no-repeat 0px 0px`,
        paymentwall: `url(${cardicon}) no-repeat 0px -192px`,
        googlepay: `url(${cardicon}) no-repeat 0px -97px`,
    },
    backgrounds:{
        affiliate: `url(${aff_bg}) no-repeat right center`,
        business: `url(${business_bg}) no-repeat right center`,
        navIcons: navicons
    },
    onboarding:{
        sidebar_onboarding_icons: `url(${FlatOnBoardingIcons}) no-repeat 0px 0px`,
        sidebar_onboarding_gradient_icons: `url(${GradientOnBoardingIcons}) no-repeat 0px 0px`,
        sidebar_new_icon: `url(${OnBoardingLockIcons}) no-repeat 0px 0px`,
        sidebar_new_active_locked_icon: `url(${OnBoardingLockIcons}) no-repeat -16px 0px`,
        sidebar_new_active_icon: `url(${OnBoardingLockIcons}) no-repeat -64px 0px`,
        sidebar_lock_icon: `url(${OnBoardingLockIcons}) no-repeat -32px 0px`,
        sidebar_lock_active_icon: `url(${OnBoardingLockIcons}) no-repeat -48px 0px`,
        nav_last_step_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat -72px 0px`,
        nav_last_step_active_medium_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat 0px 0px`,
        nav_last_step_active_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat -120px 0px`,
        nav_media_object_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat 0px 0px`,
        nav_media_object_active_medium_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat -24px 0px`,
        nav_media_object_active_icon: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-onboarding-sprite.png) no-repeat -48px 0px`,
        // grid_icons: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-purpose-sprite.png) no-repeat -3px -3px`,
        grid_icons: `url(https://images.purevpn-tools.com/public/images/BA-270-ob-purpose-new-icons.png) no-repeat -3px -3px`,
        media_object_bg: `url(https://images.purevpn-tools.com/public/images/ACSD-30300-media-object-bg.png) no-repeat bottom right`,
    },
    pureai:{
        logo: `url(${PureAILogo}) no-repeat 0px 0px`,
    },
    teams:{
        colors: {
            primary: "#001219",
            secondary: "#637381",
            success: "#550CCB",
            background: "#F4F6F7",
            border: "#EBE6FF",
            white: "#FFFFFF",
            borderLight: "#BDABFF",
            links: "#7320FF",
            disabled: "#C4C4C4",
        },
        typography:{
            larger: "50px",
            h1:"30px",
            h2:"24px",
            h4:"20px",
            h5:"14px",
            body:"16px",
            p:"14px",
            smallerBody:"12px",
        }
    }
}; 