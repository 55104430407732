import ManualConnectionFlags from "../../assets/flagIcons.png";
import styled, { css } from "styled-components";

export const GatewayIcon = styled.span`
/* background-image: url(${ManualConnectionFlags}); */
background:${props => props.theme.payment[props.card] ?? props.theme.payment.card};
width:24px;
height: 24px;
display: inline-block;
margin-right: 5px;
margin-top: 0px !important;
&.card{
    height: 24px;
    border: none;
    broder-radius: 0;
}
&.applepay,&.googlepay{
    width: 50px;
}
&.coingate,&.paymentwall{
    width: 42px;
}

`