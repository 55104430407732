import React from 'react'
import { WalletCover } from '../../../../styles/dashboard/Main'
import RewardsCoverImg from '../../../../assets/pure-rewards-banner-bg.png'
import Coin  from '../../../../assets/currency.png'
import HistoryModal from '../modals/HistoryModal'
import { useState } from 'react'
import Currency from "./Currency";

const WalletBanner = ({customerHitory,isDataLoaded,pointsInfo,historyError,setTglCustHist}) => {
    const [isHistoryModal,showHistoryModal] = useState(false)
    const [historyData,setHistoryData] = useState([])
    const [isModal,setIsModal] = useState(false)
    const showHistory = (isEarned=false) => {
        showHistoryModal(true)
        if(isEarned){
            setIsModal(true)
            setHistoryData(customerHitory?.transactions)
        }else{
            setIsModal(false)
            setHistoryData(customerHitory?.transactions)
        }
    }
  return (
        <WalletCover bgImage={RewardsCoverImg} coinImg={Coin}>
            <div className='header'>
                    <div className='cta'>
                        <h1>Earn.</h1>
                        <h1>Redeem.</h1>
                        <h1>Repeat.</h1>
                    </div>
                    <span className='info-view'>
                        <Currency/>
                            <p className='points'>Available Points: <span className='coin-amt'><span className="earned">{pointsInfo?.points_earned}</span> / {pointsInfo?.points_spent}</span></p>
                            <p onClick={()=>showHistory()}>Check point history</p>                                    
                    </span>
                    {
                        historyError && 
                        <div className='error-view'>
                            <p>Unable to load points.</p>
                            <p onClick={()=>setTglCustHist(prev=>!prev)}>Try Again</p>
                        </div>
                    }
            </div>
            {isHistoryModal && <HistoryModal showHistoryModal={showHistoryModal} historyData={historyData} earnedData={isModal} historyError={historyError}/>}
        </WalletCover>
  )
}

export default WalletBanner