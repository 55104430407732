import React from 'react'
import { BusinessImg, BussinessImgContainer } from '../../../../styles/dashboard/Business'
import UserLoveBadge from '../../../../assets/user-love.svg';
import SmallSpringBadge from '../../../../assets/small-business-spring.svg';
import SmallWinterBadge from '../../../../assets/small-business-winter.svg';
import LeaderSpringBadge from '../../../../assets/spring.svg';
import LeaderWinterBadge from '../../../../assets/leader-winter.svg';
function BusinessBadges() {
  return (
    <BussinessImgContainer>
      <BusinessImg src={LeaderWinterBadge} alt='Leader Winter Badge' />
      <BusinessImg src={SmallWinterBadge} alt='Small Business Winter Badge' />
      <BusinessImg src={LeaderSpringBadge} alt='Leader Business Spring Badge' />
      <BusinessImg src={SmallSpringBadge} alt='Small Leader Business Spring Badge' />
      <BusinessImg src={UserLoveBadge} alt='User Love Badge' />
    </BussinessImgContainer>
  )
}

export default BusinessBadges