import React from "react";
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

const RecurlyRoute = Component => ({ ...props }) => (

    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
        <Elements>
            <Component {...props} />
        </Elements>
    </RecurlyProvider>
);

export default RecurlyRoute;