import { APIFAILED } from '../../../../tools/responseMessages'
import { connect, useDispatch } from "react-redux";
import { constants } from "../../../../tools/constants";
import { checkAccountType, dispatchError, getPaymentMethods, getTokenFromLocalStorage, patch, poster } from "../../../../tools/tools";
import { getRemoveMyDataRequested } from '../../../../redux/rmd/actions'
import { InfoCard, SubscriptionBox } from "../../../../styles/dashboard/Main";
import { SecurityToolsContent, featureContent} from "../../content";
import { toast } from 'react-toastify'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoForm from "./infoForm";
import PostPurchase from "./postPurchase";
import PrePurchase from "./prePurchase";
import qs from 'qs'
import Skeleton from "react-loading-skeleton";
import { sendEvents } from '../../../../tools/mpEvents';

const RemoveMyData = ({slug, onClickPurchaseCta, hasPurchasedAddon, removeMyDataReducer, getRemoveMyDataRequested, setCrashed, selectedSubscription, isConverted}) => {

    const [userScreen, setUserScreen] = useState({
        type: '',
    });

    const [loading, setLoading] = useState(true);

    const [userInfoLoading, setUserInfoLoading] = useState();

    const [btnloading, setBtnLoading] = useState();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [error,setError] = useState({
        error_type:"",
        error_message:""
    });

    const token = getTokenFromLocalStorage();

     useEffect(()=>{
            getRemoveMyDataRequested(token)
    }, [])

    useEffect(()=>{
        const  {accountinfo: response, loading: reduxLoading, errors, resetState,  errorType, errorMessage} = removeMyDataReducer;

        setUserInfoLoading(reduxLoading)

        if(reduxLoading || resetState){
            setLoading(true);
        }
        
        if(response?.status &&  !resetState){  
            
            setUserScreenWrtUserProfile(response?.body)
            setLoading(false)

        }else if(errors && !reduxLoading){

            setError({
                error_type:errorType ?? "N/A",
                error_message:errorMessage ?? "N/A"
            })
            dispatchError(errorType, dispatch);

            sendEvents({
                event_name: "ma_fail_load_user_details",
                event_properties: {
                    reason: errorMessage
                },
            }, token);
        }

    }, [removeMyDataReducer, removeMyDataReducer?.accountInfo, isConverted])

    const proceedToAccountInfo = () => {

        sendEvents({
            event_name:"ma_click_create_profile",
            event_properties: {
                account_type: checkAccountType(selectedSubscription) ?? 'N/A',
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle : selectedSubscription?.type ?? 'N/A',
                payment_gateway: getPaymentMethods(selectedSubscription) ?? 'N/A',
                current_status: selectedSubscription?.state ?? 'N/A',
                product: featureContent[slug]?.product ?? 'N/A',
                feature_name: slug ?? 'N/A',
            }
        }, token);

        // when the user proceeds to fill the info form after purchase
        setUserScreen({
            type: constants['step_one']
        })

    }

    const setUserInfoRequest = async (payload) => {
        setBtnLoading(true)

        const {firstname, lastname, email, country, consent } = payload;
        try{
            const formdata = qs.stringify({
                'first_name': firstname,
                'last_name': lastname,
                'email': email,
                'country': country
                
            })
            const {data: response} = await poster(`integrated-experience/user-details`, formdata, token);
            if(response?.status){
                // when the user proceeds to give consent after info form

                // ! UPDATE USER INFO HERE
                dispatch({ type: "UPDATEREMOVEMYDATA", payload: response })

                // Only send consent api when the user info is submitted successfully
                sendUserAcknowledgementRequest(consent)

            }
            else{
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code)

                sendEvents({
                    event_name: "ma_fail_submit_info_form",
                    event_properties: {
                        reason: 'API break or Server not responding'
                    },
                }, token);
                setBtnLoading(false);
            }
        }
        catch{
            toast.error(APIFAILED);
            throwErrorBoundary(APIFAILED);
            setBtnLoading(false);
        }
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED){
            setCrashed(true)
        }
    }

    // IF THE USER CLICKS ON SUBMIT INFO CTA FOR THE FIRST TIME
    const proceedToConsent = (payload) => {
        sendEvents({
            event_name: "ma_submit_info_form",
            event_properties: {
                email: selectedSubscription?.account?.email ?? 'N/A',
                new_email: payload?.email ?? 'N/A',
            },
        }, token);
        setUserInfoRequest(payload)
    }

    const proceedOnConsentFail = (payload) => {
        sendEvents({
            event_name: "ma_submit_info_form_on_fail",
            event_properties: {},
        }, token);
        sendUserAcknowledgementRequest(payload)
    }

    const setUserScreenWrtUserProfile = (accountInfo) => {

        if(accountInfo.length === 0 && !isConverted){
            setUserScreen({
                type: constants['pre_purchase'],
            })  
        }
        // if the user has purchased the addon but not filled the content info
        // if the user has just purchased the addon
        // if the user has filled the info but his consent api failed
        else if(!accountInfo?.user_details || !accountInfo?.user_details?.signedOn || isConverted) {
            setUserScreen({
                type: constants['step_one'],
            })
        }
        else{
            setUserScreen({
                type: constants['post_purchase'],
            })
        }
    }

    const sendUserAcknowledgementRequest = async (payload) => {

        setBtnLoading(true)

        try{
            const formdata = qs.stringify({'user_acknowledgement': payload ? 1 : 0})

            const {data: response} = await patch(`integrated-experience/attorney/user-acknowledgement`, formdata, token);

            if(response?.status){
                
                // when the user proceeds to give consent after info form

                // ! UPDATE USER INFO HERE
                dispatch({ type: "UPDATEREMOVEMYDATA", payload: response })
                setUserScreen({
                    type: constants['post_purchase'],
                })

            }
            else{
                dispatchError(response?.error_code, dispatch);
                throwErrorBoundary(response?.error_code)

                sendEvents({
                    event_name: "ma_fail_submit_rmd_consent",
                    event_properties: {
                        reason: response?.message ?? 'N/A'
                    },
                }, token);
            }
        }
        catch{
            toast.error(APIFAILED);
            throwErrorBoundary(APIFAILED)

            sendEvents({
                event_name: "ma_fail_submit_rmd_consent",
                event_properties: {
                    reason: 'API break or Server not responding'
                },
            }, token);
        }
        finally{
            setBtnLoading(false)
        }
    }

    return <>

        {/* PRE PURCHASE  */}
        {userScreen.type == constants['pre_purchase'] && <>

            <PrePurchase 
                slug={slug}
                onClickPurchaseCta={onClickPurchaseCta}
                proceedToAccountInfo={proceedToAccountInfo}
                hasPurchasedAddon={hasPurchasedAddon}
                setCrashed={setCrashed}
                userLoading={userInfoLoading}
                selectedSubscription={selectedSubscription}
            />
            
        </>}
    
        {/* POST PURCHASE  */}

        {!loading && userScreen.type == constants['post_purchase'] && <>
            
            <PostPurchase slug={slug} setCrashed={setCrashed}/>
        
        </>}

        {/* INFO FORM  */}
        {
            !loading && userScreen.type == constants['step_one'] ? 
                <InfoForm userDetails={removeMyDataReducer?.accountinfo?.body?.user_details} slug={slug} type={userScreen.type} proceedToConsent={proceedToConsent} proceedOnConsentFail={proceedOnConsentFail} selectedSubscription={selectedSubscription} btnloading={btnloading} setCrashed={setCrashed}/> 
            : null
        }

        {loading && userScreen.type != constants['pre_purchase'] && 
            <>
                    <SubscriptionBox open={true}>
                    <InfoCard hasborder noflex className="mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard hasborder noflex className="pt-0 mb-3">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                    <InfoCard noflex className="pt-0">
                        <Skeleton height={20} width={300}/>
                    </InfoCard>
                </SubscriptionBox> 
            </>
        }
    </>
}

const mapStateToProps = (state) => {
    return { 
        removeMyDataReducer: state.removeMyDataReducer
    };
};
export default connect(mapStateToProps, {getRemoveMyDataRequested})(RemoveMyData);