import { ERRORS, GETTEAMPLANSREQUEST, GETTEAMPLANS, LOADING } from "./types"

export const getTeamPlanRequested = (payload) => {
    return{
        type:GETTEAMPLANSREQUEST,
        payload
    }
}

export const getTeamPlans = (payload) => {
    return{
        type:GETTEAMPLANS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
