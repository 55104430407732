import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAccountRequested } from '../../../redux/account/actions';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages';
import ErrorBoundary from '../../ErrorBoundary';
import { CardBox, InfoCard } from '../../../styles/dashboard/Main'
import { SecondryBtn } from '../../../styles/Generic'
import PureAIWaitList from './utils/PureAIWaitList';
import { PureAIView } from '../../../styles/dashboard/PureAI';
import PureAIRecommendations from './utils/PureAIRecommendations';
import { dispatchError, fetcher, getTokenFromLocalStorage } from '../../../tools/tools';
import { ACTIONS } from '../../../redux/pureai/reducer';
import PureAIChat from './utils/PureAIChat';

const PureAI = ({getAccountRequested, pureAIStateReducer, accountReducer}) => {

    const token = getTokenFromLocalStorage();

    const dispatch = useDispatch();

    const { currentState, previousStates } = pureAIStateReducer;

    const [ ablyObject, setAblyObject ] = useState(null)
    const [ ablyChannel, setAblyChannel ] = useState(null)
    const [ evtSource, setEvtSource] = useState(null)

    const constants = {
        "DEFAULT_AUDIENCE_TYPE": "Normal",
        "TARGET_AUDIENCE_TYPE": "Alpha",
    };

    const [logoutLoading, setLogoutLoading] = useState(false)

    const getAudienceType = useCallback(() => {
        let audienceType = currentState.audienceType;
        if(!audienceType){
            dispatch({type:ACTIONS.CALL_API});
            fetcher('public/getAudienceType', getTokenFromLocalStorage()).then(response => {
                return response;
            })
            .then(data => {
                dispatch({type: ACTIONS.AUDIENCE_TYPE, audienceType: data?.data?.body?.type ?? "Normal"});
            })
            .catch(error => {
                dispatch({type: ACTIONS.AUDIENCE_TYPE, audienceType: "Normal"});
            })
        }
        return true;
    }, [currentState.audienceType, dispatch]);

    useEffect(() => {
        if(token){
            getAccountRequested(token);
            getAudienceType();
        }
    },[getAccountRequested, token, getAudienceType]);

    useEffect(() => {
        const { accounts, errors, errorType } = accountReducer??{};
        if(accounts?.body?.code && !currentState.channelId){
            dispatch({type: ACTIONS.ADD_CHANNEL_ID, channelId: accounts?.body?.code, email: accounts?.body?.email});
        }
        if(errors) {
            dispatchError(errorType, dispatch);
        }
    });

    const logoutSubmit = () => {
        setLogoutLoading(true)
        dispatch({type:"LOGOUT"})
    }

    const buttonText = () => {
        return !logoutLoading ? "Logout" : ( <>Logout <span className="spinner-border text-dark spinner-border-sm ms-1"></span></>);
    }

    if(currentState.crashed) {
        return <ErrorBoundary logoutSubmit={logoutSubmit}></ErrorBoundary>
    }

    if(currentState.audienceType !== constants.TARGET_AUDIENCE_TYPE){
        return (
            <PureAIView>
                <PureAIWaitList/>
            </PureAIView>
        )
    }

    if( currentState.prompt !== "" || previousStates.length){
        return (
            <PureAIView>
                <PureAIChat setAblyObject={setAblyObject} setAblyChannel={setAblyChannel} ablyChannel={ablyChannel} ablyObject={ablyObject} evtSource={evtSource} setEvtSource={setEvtSource} />
            </PureAIView>
        )
    }

    if( currentState.prompt === "" ) {
        return (
            <PureAIView>
                <PureAIRecommendations setAblyObject={setAblyObject} setAblyChannel={setAblyChannel} ablyChannel={ablyChannel} ablyObject={ablyObject} evtSource={evtSource} setEvtSource={setEvtSource} />
            </PureAIView>
        )
    }

    return (
        logoutLoading ? (
            <div>
            <CardBox>
                <InfoCard className="pb-0">
                    <div className="float-start">
                        <SecondryBtn pointer={logoutLoading} onClick={logoutSubmit}>{buttonText()}</SecondryBtn>
                    </div>
                </InfoCard>
            </CardBox>
            </div>
        ) : null
    )
}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps, {getAccountRequested})(PureAI)