import React, { useState, useEffect, Suspense, lazy } from 'react'
import {  CardNumber, InfoCard, PopupBody, OrderSummary,  PurchaseBox, ThankYouModalContainer } from '../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn, } from '../../../styles/Generic'
import Skeleton from 'react-loading-skeleton';
import { useLocation, Link } from 'react-router-dom';
import { sendEvents } from '../../../tools/mpEvents';
import classNames from 'classnames'
import { ModalBody, ModalFooter } from 'reactstrap';
import { Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import {addToData, dateFormat, get, getByParam , getCurrentSubscription, isWhmcsUser, goToPaymentLink,  isAddon,  isManualGateway,  poster, toDisplayComboBox, toNotDisplayRefreshCart, checkAccountType} from '../../../tools/tools'
import { toast } from 'react-toastify';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../tools/responseMessages';
import qs from 'qs'
import { ThreeDSecureAction, RecurlyProvider, Elements } from '@recurly/react-recurly';
import { addonState, constants } from '../../../tools/constants';
import CheckIconSVG from '../../../assets/svg/CheckIconSVG'
import WindowsIconSVG from '../../../assets/svg/WindowsIconSVG'
import WebAppIconSVG from '../../../assets/svg/WebAppIconSVG'
import MacIconSVG from '../../../assets/svg/MacIconSVG'
import ProductAppModal from '../../../tools/AppModalContent/ModalTemplate/ProductAppModal';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';
import { checkLocale, seti18Locale } from '../../../tools/tools';

import PaymentIcon from '../../../utils/PaymentIcon/PaymentIcon';
import PopupPurchaseUtil from '../../../utils/PopupPurchase';
import ComboBox from './utils/ComboBox';
import { planTypeNaming } from '../../../tools/constants';
import { puremax } from '../../../tools/AppModalContent/puremax';

const Payment = lazy(()=>import(/* webpackChunkName: "recurlycard" */"../subscriptions/recurly/Payment"))
const PaypalBtn = lazy(()=>import(/* webpackChunkName: "recurlypaypal" */"../subscriptions/recurly/PaypalBtn"))
const AppPurchase = ({history}) => {

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const { search, pathname } = useLocation();

    const [threedSecure, setthreedSecure] = useState({
        enabled:false,
        three_d_secure_action_token_id: "",
        oldToken:""
    })

    const {type, subscription_id, currency, redirect_uri,locale, redirect_from,platform, plan_type, billing_cycle, features_metric, feature_name} = Object.fromEntries(new URLSearchParams(search));

    const locale_code = locale?.split('-')[0]
    
    const [addonType, setaddonType] = useState({
        type: !constants.app_purchasing.includes(type) && !toDisplayComboBox({platform,type}) ? type : false,
        subscription: subscription_id ?? false,
        currency: currency ?? false
    })

    const [selectedSubscription, setselectedSubscription] = useState("")

    const [purchasing, setPurchasing] = useState({
        type: constants.app_purchasing.includes(type) ? type : false,
        subscription: subscription_id ?? false,
        currency: currency ?? false,
        plan_type: plan_type,
    })

    const dispatch = useDispatch()

    const [orderSummary, setorderSummary] = useState(true)
    
    const [stateSubs, setstateSubs] = useState({})
    const [subcriptionDetails, setSubscriptionDetails] = useState({})

    const [loading, setloading] = useState(true)

    const [billingCycle, setBillingCycle] = useState(false)

    const [purchaseLoading, setpurchaseLoading] = useState(false)

    const [icon, seticon] = useState("card");

    const currencies = {
        USD: "$",
        EUR: "€",
        GBP: "£",
        AUD: "$",
        CAD: "$",
        NOK: "kr"
    }
    const [infoModal, setinfoModal] = useState(true)
    const [plancode, setPlancode] = useState(null)
    const [maxAccount, setMaxAccount] = useState()
    const [isAddonAddedMessage, setisAddonAddedMessage] = useState(false)
    const [goBack,setGoBack] = useState(false)
    const [declineState,setDeclineState] = useState(false)
    const [thankyouMessage, setThankyouMessage] = useState(false)

    const [isSubResumeMesssage, setisSubResumeMesssage] = useState(false)
    const [isFamilyPlanMessage, setIsFamilyPlanMessage] = useState(false)
    const [isPlansMessage, setIsPlansMessage] = useState(false)

    const renewSubWithInvoice = ['past_due', 'expired']

    const [checkRenewSubWithInvoice, setcheckRenewSubWithInvoice] = useState(false)

    const [renewSubWithInvoiceData, setrenewSubWithInvoiceData] = useState(false)
    const [familyPlanInvoiceData, setFamilyPlanInvoiceData] = useState(false)
    const [getThisAddon,setGetThisAddon] = useState("")

    const token = localStorage.getItem("token")

    const [invoiceData, setinvoiceData] = useState({
        countries:[],
        orderSummary:[], 
        credit_amount: 0
    })
    const [data, setData] = useState({
        subscription_id: addonType?.subscription,
        add_ons: addonType?.type,
        country: addonType?.type === "port_forwarding" ? "" : "US",
        preview:0,
        validate:1
    })

    const [state, setstate] = useState({
        plan_type:"",
        username:"",
        period:"",
        last_four:"",
        is_paused:false,
        payment_gateway_offline:false,
        gateway:"",
        plan_code:"",
    })

    const [addonNames] = useState({
        port_forwarding:`${t('get_text')} Port Forwarding`,
        ddos_protection:`${t('get_text')} DDos Protection`,
        dedicated_ip:`${t('get_text')} Dedicated IP`,
        port_forwarding_with_dedicated_ip:`${t('get_text')} Dedicated IP With Portforwarding` ,
        purekeep: `${t('get_text')} Purekeep`,
        purecrypt:`${t('get_text')} PureEncrypt`,
        pureencrypt:`${t('get_text')} PureEncrypt`,
        pureprivacy:`${t('get_text')} PurePrivacy`,
        purescan:`${t('get_text')} PurePrivacy`,
        multi_login:`${t('get_text')} Multi Login`,
        residential_network:`${t('get_text')} Residential Network`,
    })

    const [renewAbleSubInfo, setrenewAbleSubInfo] = useState({})

    const [canRenew, setCanRenew] = useState(false)
    const [canPurchaseFamilyPlan, setCanPurchaseFamilyPlan] = useState(false)
    const [payment_method,setPaymentMethod] = useState(null)

    const [whmcsUser, setWhmcsUser] = useState(false)

    const [isAllowToUseParamMethod, setIsAllowToUseParamMethod] = useState(false)

    const [endPoint, setEndPoint] = useState("account/change-payment-method")
    const [isComboSelected,setIsComboSelected] = useState(null)

    const [totalAmountWithCreditAdjustment, setTotalAmountWithCreditAdjustment] = useState();

    const [pastDue, setPastDue] = useState();

    const validateIsPending = (preview_data,sub) => {
        preview_data['gateway'] = sub?.payment_gateway;
        data['gateway'] = sub?.payment_gateway;
        const _isAddon = isAddon(sub?.add_ons,addonType?.type)
        if(_isAddon?.state === addonState.pending){
            preview_data['invoice_id'] = _isAddon?.number ?? null
            setData(addToData(data,_isAddon?.number))
        }
        return preview_data
    }

    const routeToPlans = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const redirectURI = urlSearchParams.get('redirect_uri');
      
        if (redirectURI) {
          window.location.href = redirectURI;
        } else {
          appEventHandler("learn_more_on_plan_upgrade", {});
        }
      };

    const goBackAction = () => {
        setisAddonAddedMessage(false)
        setIsFamilyPlanMessage(false)
        setisSubResumeMesssage(false)
        setIsPlansMessage(false)
    }

    const dontCloseModalBit = (error_code="") => {
        return new Set(constants.dontCloseModalErrorCode).has(error_code)
    }

    const updateStateToDecline = () => {
        setGoBack(true)
        setDeclineState(true)
    }
    useEffect(() => {
        setTimeout(hideChat, 1000);
        if(purchasing.type === 'plans'){
            history.push({
                pathname: "/dashboard/plans",
                search: `?redirected_via=apps&id=${subscription_id}&billing_cycle=${billing_cycle}&type=${purchasing.type}&plan_type=${plan_type}&locale_code=${locale_code}&platform=${platform}&redirect_uri=${redirect_uri}&feature_name=${feature_name}`
            })
        }
        if(purchasing.type === 'teams'){
            history.push({
                pathname: "/dashboard/subscriptions",
                search: `?redirected_via=apps&id=${subscription_id}&plan=${purchasing.type}&locale_code=${locale_code}&platform=${platform}&features_metric=${features_metric}&redirect_uri=${redirect_uri}`
            })
        }
        if(purchasing.type === 'renew' && purchasing.subscription && purchasing.currency){
            async function renewSubApi(){
                const {data:userSubscriptionData} = await poster("subscription", "", token)
                if(userSubscriptionData?.status){
                    const renewAbleSub = userSubscriptionData?.body.find((data) => data.id === purchasing.subscription)
                    setrenewAbleSubInfo(renewAbleSub)
                    
                    let whmcsUserRenewInvoiceCheck = renewSubWithInvoice.includes(renewAbleSub?.state) && !renewAbleSub?.renew_invoice && isWhmcsUser(renewAbleSub?.account?.billingType);
                    setselectedSubscription({
                        subscription: renewAbleSub?.id,
                        invoice: whmcsUserRenewInvoiceCheck ? 1 : renewAbleSub?.renew_invoice
                    })

                    renewAbleSub?.state === "expired" && renewAbleSub?.renew_invoice === undefined ? setCanRenew(false) : setCanRenew(true); 

                    renewSubWithInvoice.includes(renewAbleSub?.state) ? setcheckRenewSubWithInvoice(true) : setcheckRenewSubWithInvoice(false)

                    if((renewSubWithInvoice.includes(renewAbleSub?.state) && renewAbleSub?.renew_invoice) || whmcsUserRenewInvoiceCheck){
                        
                        async function apifunc(){
                            try {
                                
                                const invoicedata = await get(`subscription/${renewAbleSub.id}/${whmcsUserRenewInvoiceCheck ? 1 : renewAbleSub.renew_invoice}` , token)
                                const {data: response} = invoicedata;
                                if(response?.status){
                                    setrenewSubWithInvoiceData(response?.body)
                                }else{
                                    switch (response?.error_code) {
                                        case "token_expired":
                                            setisSubResumeMesssage(TOKENEXPIRED);
                                            dispatch({type:"LOGOUT"})   
                                            break;
                                        case "token_invalid":
                                            setisSubResumeMesssage(INVALIDTOKEN)
                                            dispatch({type:"LOGOUT"})    
                                            break;    
                                        default:
                                            setisSubResumeMesssage(APIFAILED);
                                            break;
                                    }
                                }
                            } catch (error) {
                                setisSubResumeMesssage(APIFAILED)
                            }
                            finally{
                                
                                setloading(false)
                            }
                        }
                        apifunc()
                        setstate({
                            plan_type: renewAbleSub.type,
                            username: renewAbleSub.vpnusernames[0],
                            period: `${dateFormat(renewAbleSub.start_date)} - ${dateFormat(renewAbleSub.end_date)}`,
                            last_four:renewAbleSub?.billing_info?.last_four
                        })
                    }else{
                        const renewAbleSub = userSubscriptionData?.body.find((data) => data.id === purchasing.subscription)
                        if(renewAbleSub?.state == "active" && !renewAbleSub?.is_paused){
                            setisSubResumeMesssage(t('inapp_purchase_subscription_working'))
                        }
                        setloading(false)
                        
                    }

                }else{
                    switch (userSubscriptionData?.error_code) {
                        case "token_expired":
                            toast.error(t(TOKENEXPIRED));
                            dispatch({type:"LOGOUT"})     
                            break;
                        case "token_invalid":
                            toast.error(t(INVALIDTOKEN))
                            dispatch({type:"LOGOUT"})      
                            break;    
                        default:
                            toast.error(t(APIFAILED));
                            break;
                    }
                }
                
            }
            renewSubApi();
        }
        else if (purchasing.type === 'familyplan' && purchasing.subscription && purchasing.currency){
            async function apifunc(){
                const {data:subscriptionData} = await getByParam(`subscription/${purchasing.subscription}`, "", token)
                const requestData = qs.stringify({
                    plan: "current", 
                })
                const {data:familyPlanDetails} = await poster(`familyplan/get-family-plans`, requestData, token)
                let canPurcahseFP = subscriptionData?.body?.familyPlanEligibility && !subscriptionData?.body?.hasFamilyPlan;
                try{
                    const filteredFamilyPlan = familyPlanDetails?.body?.find(subscription=>subscription?.current_plan?.subscription_id ===  purchasing.subscription);
                    const family_plans = filteredFamilyPlan?.family_plans?.filter((fp)=> fp.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY) ?? []
                    if( !canPurcahseFP && family_plans.length <= 0  && subscriptionData?.body?.state !== 'past_due') {
                        sendEvents({
                            event_name: "ma_fail_load_familyplan",
                            event_properties: {
                              purchaseType: purchasing.type,
                              billing_cycle: subscriptionData?.body?.plan?.type ?? "N/A",
                              platform_from: 'Apps',
                              plan_type: family_plans?.[0]?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : family_plans?.[0]?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null
                            }
                          }, token); 
                        throw { name: 'Error', message: t('inapp_purchase_already_upgrade_fp')};
                    } else if (!canPurcahseFP && family_plans.length <= 0) {
                        sendEvents({
                            event_name: "ma_fail_load_familyplan",
                            event_properties: {
                              purchaseType: purchasing.type,
                              billing_cycle: subscriptionData?.body?.plan?.type ?? "N/A",
                              platform_from: 'Apps',
                              plan_type: family_plans?.[0]?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : family_plans?.[0]?.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null
                            }   
                          }, token); 
                        throw { name: 'Error', message: t('inapp_purchase_fp_unfortunately')};
                    }
                    setPaymentMethod(subscriptionData?.body?.payment_gateway)

                    // in order to send stop-collection call b/c we need to mark the last invoice invalid
                        if(constants.past_due_state.includes(filteredFamilyPlan?.current_plan?.state) && !isWhmcsUser(filteredFamilyPlan?.account?.billingType)){
                            const {data: response} = await stopCollectionRequest(subscriptionData?.body);
                            if(response?.status){
                                familyPlanPreview(family_plans, filteredFamilyPlan, subscriptionData, canPurcahseFP)
                            }
                            else{
                                // we ll delibrately be showing the preview on the below error as they are caused due to past due converted to active
                                if(response?.error_code === "invoice_exception" || response?.error_code === "subscription_exception"){
                                    familyPlanPreview(family_plans, filteredFamilyPlan, subscriptionData, canPurcahseFP);
                                    if(response?.error_code === "subscription_exception"){
                                        sendEvents({
                                            event_name:"ma_manual_handle_subscription",
                                            event_properties:{
                            
                                            }
                                        }, token);
                                    }
                                }
                                else{
                                    dispatch({type:"LOGOUT"})
                                    history.push("/login");   
                                }
                            }
                        }
                        else{
                            familyPlanPreview(family_plans, filteredFamilyPlan, subscriptionData, canPurcahseFP)
                        }
                     
                }
                catch(error) {
                    setIsFamilyPlanMessage(error.message)
                    setloading(false);
                }       
            }
            apifunc()
        }
        else if(addonType.type && addonType.subscription && addonType.currency ){
            history.push({
                pathname: "/dashboard/subscriptions",
                search: `?redirected_via=apps&id=${subscription_id}&type=${addonType.type}&locale_code=${locale_code}&platform=${platform}&features_metric=${features_metric}&redirect_uri=${redirect_uri}`
            })
        }
        else if(!type || !subscription_id || !currency ){
            history.push("/dashboard/account");
        }
    }, [isComboSelected])  

    useEffect(()=>{
        if(window.Intercom){
            window.Intercom('shutdown')
        }
    }, [])

    const familyPlanPreview = async (family_plans, filteredFamilyPlan, subscriptionData, canPurcahseFP) => {
        const previewData = qs.stringify({
            subscription_id: purchasing.subscription,
            plan_code: family_plans[0]?.code,
            preview:1,
            validate:0,
            isPastDue: constants.past_due_state.includes(filteredFamilyPlan?.current_plan?.state)
        });

        setPastDue(constants.past_due_state.includes(filteredFamilyPlan?.current_plan?.state))
        return sendFamilyPlanPreviewRequest(previewData, family_plans, subscriptionData, canPurcahseFP)
    }

    const sendFamilyPlanPreviewRequest = async (previewData,family_plans, subscriptionData, canPurcahseFP) => {
        const {data:response} = await poster("familyplan/change", previewData, token)
        try{
            setPlancode(family_plans[0]?.code)
            setMaxAccount(family_plans[0]?.max_account)
            setSubscriptionDetails(subscriptionData?.body)
            setCanPurchaseFamilyPlan(canPurcahseFP) 
            if(response?.status) {
                setTotalAmountWithCreditAdjustment(getTotalWithCredit(addonPrice(response?.body?.items), response?.body?.credit_amount))
                setFamilyPlanInvoiceData(response?.body);  
            } else {
                switch (response?.error_code) {
                case "token_expired":
                    setIsFamilyPlanMessage(TOKENEXPIRED);
                    dispatch({type:"LOGOUT"})   
                    break;
                case "token_invalid":
                    setIsFamilyPlanMessage(INVALIDTOKEN)
                    dispatch({type:"LOGOUT"})    
                    break;    
                default:
                    setIsFamilyPlanMessage(APIFAILED);
                    break;
            }
            }
            setinvoiceData({
                orderSummary:response?.body?.items ?? [],
                credit_amount: response?.body?.credit_amount
            })
            let todayDate = new Date().toJSON()
            setstate({
                plan_type: `${subscriptionData?.body.plan?.interval_length} Months` ,
                username: subscriptionData?.body?.vpnusernames[0] ?? "-",
                period: `${dateFormat(todayDate)} - ${dateFormat(response?.body?.end_date ?? subscriptionData.body?.end_date)}`,
                last_four:subscriptionData?.body?.billing_info?.last_four
            }) 
            if(subscriptionData?.body?.account){
                if(isWhmcsUser(subscriptionData?.body?.account?.billingType)){
                    setWhmcsUser(true)
                    setIsAllowToUseParamMethod(true)
    
                }
            } 
        }
        finally{
            setloading(false);
        }
    }
    
    const stopCollectionRequest = (data) => {
        const formdata = qs.stringify({
            subscription_id: data?.id,
            invoice_id:data?.renew_invoice
        })
        return poster("subscription/stop-collection", formdata, token)
    }

    const getTotalWithCredit = (total, credit) => {
        return Math.abs((total-credit)?.toFixed(2))
    }

    const addonPrice = (addon) => {

        let price = 0;
        addon.map(type=>price+=type.amount)
        return price.toFixed(2) ?? 0
    }

    const buttonText = (text) => {
        return !purchaseLoading ? `${text}` : ( <>{text} <span className="spinner-border text-light spinner-border-sm ms-1"></span></>);
    }

    const purchaseAddon = async (payload) => {
        if(isAllowToUseParamMethod){
            setselectedSubscription({...payload});
        }
        appEventHandler("pay_now", {});
        setpurchaseLoading(true)
        sendEvents(
            {
            event_name: "ma_click_addon_checkout",
            event_properties: {
              addon_name: addonType.type,
              amount: getSubTotal(invoiceData.orderSummary) ?? "N/A",
              vat: getVat(invoiceData.orderSummary) ?? "N/A",
              billing_cycle: stateSubs?.body?.plan?.type ?? "N/A",
              platform_from: 'Apps',
              payment_method: payload ? payload.gateway : payment_method
            }
          }, token);
        try {
            let formdata;
                if(whmcsUser){
                    formdata = qs.stringify(payload)
                }
                else{
                    formdata = qs.stringify(data)
                }
            const purchase = await poster("addon/upsell", formdata, token)
            const {data: response} = purchase;
            if(response?.status){
                if(isManualGateway(response?.body?.payment_gateway)){
                    goToPaymentLink(response?.body)
                }
                else{
                    sendEvents(
                        {
                        event_name: "ma_purchase_addon",
                        event_properties: {
                          addon_name: addonType.type,
                          amount: getSubTotal(invoiceData.orderSummary) ?? "N/A",
                          vat: getVat(invoiceData.orderSummary) ?? "N/A",
                          billing_cycle: stateSubs?.body?.plan?.type ?? "N/A",
                          platform_from: 'Apps',
                          payment_method: payload ? payload.gateway : payment_method
                        }
                      }, token);
                }
                let AppEventsData = {
                    type: "addon_purchase_success",
                    message: response?.error_code,
                    data:{
                        isPurchasedSucessFully : true,
                    }
                }
                appEventHandler("addon_purchase_success", {});
                dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale: checkLocale()})
                setisAddonAddedMessage(`${addonNames[addonType.type].replace("Get ","")} ${t('payment_successful_text_para')}`)
                setThankyouMessage(true);
                setGoBack(false)
            }else{
                let AppEventsData = {
                    type: response?.error_code,
                    message: "addon_purchase_error",
                    data:{
                        isPurchasedSucessFully : false,
                        dontCloseModal: dontCloseModalBit(response?.error_code)
                    }
                }
                setThankyouMessage(false);
                appEventHandler("addon_purchase_error", AppEventsData);
                switch (response?.error_code) {
                    case "token_expired":
                        setisAddonAddedMessage(TOKENEXPIRED);
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    case "token_invalid":
                        setisAddonAddedMessage(INVALIDTOKEN)
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;      
                    case "declined":
                        updateStateToDecline()
                        setisAddonAddedMessage(response?.message)
                        break;  
                    case "validation_exception":
                        response?.errors?.add_ons.map(error=>setisAddonAddedMessage(error))
                        break;  
                    case "three_d_secure_action_required":
                        updateStateToDecline()
                        setisAddonAddedMessage(response?.message)
                        break;   
                    case "add_on_up_sell_exception":
                        updateStateToDecline()
                        setisAddonAddedMessage(response?.message)
                        break; 
                    case "recurly_exception":
                        updateStateToDecline()
                        setisAddonAddedMessage(response?.message)
                        break;
                    case "jwt_default_exception":
                        setisAddonAddedMessage(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    default:
                        setisAddonAddedMessage(response?.message);
                        break;
                }
                sendEvents(
                    {
                    event_name: "ma_fail_purchase_addon",
                    event_properties: {
                      reason: response?.message,
                      platform_from: 'Apps',
                      source: (pathname) ? pathname : "",
                      addon_name: addonType?.type,
                      payment_method: payload ? payload.gateway : payment_method
                    }
                  }, token);
            }
        } 
        catch (error) {
            setisAddonAddedMessage(t(APIFAILED))
            sendEvents(
                {
                event_name: "ma_fail_purchase_addon",
                event_properties: {
                  reason: "API break or Server not responding",
                  platform_from: 'Apps',
                  source: (pathname) ? pathname : "",
                  addon_name: addonType?.type,
                  payment_method: payload ? payload.gateway : payment_method
                }
              }, token);
        }
        finally{
            setpurchaseLoading(false)
        }
    }
    const purchaseFamilyPlan = async (payload) => {
        let dataForPreview = {}

        if(isAllowToUseParamMethod){
            dataForPreview = {
                paymentMethodWhmcs: payload.paymentMethodWhmcs,
                three_d_secure: payload.three_d_secure,
                token_id: payload.token_id,
                type: payload.type,
            }
        }
        
        let payment_method = subcriptionDetails?.payment_gateway
        appEventHandler("pay_now", {});
        setpurchaseLoading(true)
        sendEvents(
            {
            event_name: "ma_click_familyplan_checkout",
            event_properties: {
                productId: plancode ?? "N/A",
                payment_method,
                platform_from: 'Apps',
                plan_type: maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null
            }
          }, token);

          try{
            const previewData = qs.stringify({
                subscription_id: purchasing.subscription,
                plan_code: plancode,
                preview:0,
                validate:0,
                isPastDue: pastDue,
                ...dataForPreview
            });
          const {data:response} = await poster("familyplan/change", previewData, token)
            if(response.status ){ 
                payment_method =  response?.body?.payment_gateway;
                let AppEventsData = {
                    type: "purchase_family_plan",
                    message: `You have been upgraded to Family Plan successfully`,
                    data:{
                        isPurchasedSucessFully : true,
                        
                    }
                }
                appEventHandler("", AppEventsData);
                dispatch({ type: "GETONBOARDINGREQUEST", token: token , locale: checkLocale() })
                setIsFamilyPlanMessage(t('inapp_purchase_upgraded_to_fp'))
                setGoBack(false)
                // BA-706
                if(redirect_from !== 'apps'){
                    setTimeout(familyPlanRedirectUri, 3000);
                }
                sendEvents(
                    {
                    event_name: "ma_purchase_familyplan",
                    event_properties: {
                        payment_method,
                        amount: response?.body?.tax ? totalAmountWithCreditAdjustment - Number(response?.body?.tax).toFixed(2) : totalAmountWithCreditAdjustment,
                        vat: Number(response?.body?.tax).toFixed(2),
                        billing_cycle:response?.body?.plan?.type,
                        platform_from: 'Apps',
                        plan_type: maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null
                    }
                  }, token);
            }else{
                let AppEventsData = {
                        type: "purchase_family_plan",
                        message: response?.error_code,
                        data:{
                            isPurchasedSucessFully : false,
                            dontCloseModal: dontCloseModalBit(response?.error_code)
                    }
                }
                    appEventHandler("", AppEventsData);
                        switch (response?.error_code) {
                            case "token_expired":
                                setIsFamilyPlanMessage(TOKENEXPIRED);
                                dispatch({type:"LOGOUT"})
                                history.push("/login");       
                                break;
                            case "token_invalid":
                                setIsFamilyPlanMessage(INVALIDTOKEN)
                                dispatch({type:"LOGOUT"})
                                history.push("/login");       
                                break;      
                            case "declined":
                                updateStateToDecline()
                                setIsFamilyPlanMessage(response?.message)
                                break;  
                            case "validation_exception":
                                response?.errors?.add_ons.map(error=>setIsFamilyPlanMessage(error))
                                break;  
                            case "three_d_secure_action_required":
                                updateStateToDecline()
                                setIsFamilyPlanMessage(response?.message)
                                break;   
                            case "add_on_up_sell_exception":
                                updateStateToDecline()
                                setIsFamilyPlanMessage(response?.message)
                                break; 
                            case "recurly_exception":
                                updateStateToDecline()
                                setIsFamilyPlanMessage(response?.message)
                                break;
                            case "jwt_default_exception":
                                setIsFamilyPlanMessage(t(INVALIDTOKEN))
                                dispatch({type:"LOGOUT"})
                                history.push("/login");       
                                break;
                            default:
                                setIsFamilyPlanMessage(response?.message);
                                break;
                        }
                       sendEvents(
                            {
                            event_name: "ma_fail_purchase_familyplan",
                            event_properties: {
                              reason: response?.message,
                              platform_from: 'Apps',
                              source: (pathname) ? pathname : "",
                              addon_name: addonType?.type,
                              plan_type: maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_DUO ? 'duo' : maxAccount == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null

                            }
                          }, token);
            }
        }
        catch(error){
            setIsFamilyPlanMessage(t(APIFAILED))
            sendEvents(
                {
                event_name: "ma_fail_purchase_familyplan",
                event_properties: {
                  reason: "API break or Server not responding",
                  platform_from: 'Apps',
                }
              }, token);
        }finally{
            setpurchaseLoading(false);
        } 

    }

    const purchasePlans = async (payload) => {
        if(state?.plan_code) {
            let dataForPreview = {}
            if(isAllowToUseParamMethod){
                dataForPreview = {
                    paymentMethodWhmcs: payload.paymentMethodWhmcs,
                    three_d_secure: payload.three_d_secure,
                    token_id: payload.token_id,
                    type: payload.type,
                }
            }
            
            let payment_method = subcriptionDetails?.payment_gateway
            appEventHandler("pay_now", {});
            setpurchaseLoading(true)
            sendEvents(
                {
                event_name: "ma_click_checkout_plan",
                event_properties: {
                    platform_from: 'Apps',
                    current_plantype: subcriptionDetails?.plan_type ?? "N/A",
                    billingcycle: subcriptionDetails?.type ?? "N/A",
                    amount: subcriptionDetails?.total_price ?? "N/A",
                    plan_type: plan_type ?? "N/A",
                    payment_method: subcriptionDetails?.payment_gateway ?? "N/A",
                    current_status: subcriptionDetails?.state ?? "N/A",
                    account_type: checkAccountType(subcriptionDetails)
                    
                }
              }, token);
              try{
                const isPastdue = constants.past_due_state.includes(subcriptionDetails?.state);
                    const previewData = qs.stringify({
                        subscription_id: subscription_id,
                        plan_code: state?.plan_code,
                        preview:0,
                        validate:0,
                        isPastDue: isPastdue,
                        plan_type: plan_type,
                        ...dataForPreview    
                    });
                    const {data:response} = await poster("subscription/change", previewData, token)
                if(response.status ){ 
                    payment_method =  response?.body?.payment_gateway;
                    let AppEventsData = {
                        type: "purchase_plan",
                        message: `You have been upgraded to ${planTypeNaming[plan_type]} successfully`,
                        data:{
                            isPurchasedSucessFully : true,
                            
                        }
                    }
                    appEventHandler("purchase_plan", AppEventsData);
                    dispatch({ type: "GETONBOARDINGREQUEST", token: token })
                    setIsPlansMessage(`You have been upgraded to ${planTypeNaming[plan_type]} successfully`)
                    setGoBack(false)
                    // BA-706
                    if(redirect_from !== 'apps'){
                        setTimeout(familyPlanRedirectUri, 3000);
                    }
                    sendEvents(
                        {
                        event_name: "ma_purchase_plan",
                        event_properties: {
                            platform_from: 'Apps',
                            current_plantype: subcriptionDetails?.plan_type ?? "N/A",
                            billingcycle: subcriptionDetails?.type ?? "N/A",
                            amount: totalAmountWithCreditAdjustment ?? "N/A",
                            plan_type: plan_type ?? "N/A",
                            payment_method: subcriptionDetails?.payment_gateway ?? "N/A",
                            current_status: subcriptionDetails?.state ?? "N/A",
                            account_type: checkAccountType(subcriptionDetails)
                        }
                      }, token);
                }else{
                    let AppEventsData = {
                            type: "fail_purchaseplan",
                            message: response?.error_code,
                            data:{
                                isPurchasedSucessFully : false,
                                dontCloseModal: dontCloseModalBit(response?.error_code)
                        }
                    }
                        appEventHandler("fail_purchaseplan", AppEventsData);
                            switch (response?.error_code) {
                                case "token_expired":
                                    setIsPlansMessage(TOKENEXPIRED);
                                    dispatch({type:"LOGOUT"})
                                    history.push("/login");       
                                    break;
                                case "token_invalid":
                                    setIsPlansMessage(INVALIDTOKEN)
                                    dispatch({type:"LOGOUT"})
                                    history.push("/login");       
                                    break;      
                                case "declined":
                                    updateStateToDecline()
                                    setIsPlansMessage(response?.message)
                                    break;  
                                case "validation_exception":
                                    response?.errors?.add_ons.map(error=>setIsPlansMessage(error))
                                    break;  
                                case "three_d_secure_action_required":
                                    setIsPlansMessage(response?.message)
                                    break;   
                                case "add_on_up_sell_exception":
                                    updateStateToDecline()
                                    setIsPlansMessage(response?.message)
                                    break; 
                                case "recurly_exception":
                                    updateStateToDecline()
                                    setIsPlansMessage(response?.message)
                                    break;
                                case "jwt_default_exception":
                                    setIsPlansMessage(INVALIDTOKEN)
                                    dispatch({type:"LOGOUT"})
                                    history.push("/login");       
                                    break;
                                default:
                                    setIsPlansMessage(response?.message);
                                    break;
                            }
                            sendEvents(
                                {
                                event_name: "ma_fail_purchase_plan",
                                event_properties: {
                                  reason: response?.message,
                                  platform_from: 'Apps',
                                  current_plantype: subcriptionDetails?.plan_type ?? "N/A",
                                  billingcycle: subcriptionDetails?.type ?? "N/A",
                                  amount: subcriptionDetails?.total_price ?? "N/A",
                                  plan_type: plan_type ?? "N/A",
                                  payment_method: subcriptionDetails?.payment_gateway ?? "N/A",
                                  current_status: subcriptionDetails?.state ?? "N/A",
                                  account_type: checkAccountType(subcriptionDetails)
                                }
                              }, token);
                }
            }
            catch(error){
                setIsPlansMessage(APIFAILED)
                sendEvents(
                    {
                    event_name: "ma_fail_purchase_plan",
                    event_properties: {
                      reason: "API break or Server not responding",
                      platform_from: 'Apps',
                      current_plantype: subcriptionDetails?.plan_type ?? "N/A",
                      billingcycle: subcriptionDetails?.type ?? "N/A",
                      amount: subcriptionDetails?.total_price ?? "N/A",
                      plan_type: plan_type ?? "N/A",
                      payment_method: subcriptionDetails?.payment_gateway ?? "N/A",
                      current_status: subcriptionDetails?.state ?? "N/A",
                      account_type: checkAccountType(subcriptionDetails)
                    }
                  }, token);
            }finally{
                setpurchaseLoading(false);
            } 
        }
        

    }


    const familyPlanRedirectUri = () => {
       let redirectURI = window.atob(redirect_uri)
        window.location.href = redirectURI
    }

    const resumeSelectedSubscription = async (selectedSubscriptionId, endPointName) => {

        setpurchaseLoading(true)
        
        sendEvents({
            event_name: renewAbleSubInfo.state === "paused" ? "ma_renew_subscription" : "ma_resume_subscription",
            event_properties:{
                gateway: renewAbleSubInfo?.payment_gateway ?? "N/A",
                biling_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                platform_from:'Apps'
            }
        }, token)

        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscriptionId
            })
            const cancel =  await poster(`subscription/${endPointName}`, formdata, token)
            const {data: response} = cancel;
            if(response?.status){
                setisSubResumeMesssage(t('inapp_purchase_subscription_resumed'))

                // APP Event handler 

                let AppEventsData = {
                    type: "renew_or_resume",
                    message: `Subscription renew or resume successfully`,
                    data:{
                        isPurchasedSucessFully : true
                    }
                }
                appEventHandler("", AppEventsData)

                sendEvents({
                    event_name:renewAbleSubInfo.state === "paused" ? "ma_renew_subscription" : "ma_resume_subscription",
                    event_properties:{
                        billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                        payment_method: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: "active",
                        platform_from:'Apps'
                    }
                }, token)
            }else{

                // APP Event handler 
                let AppEventsData = {
                    type: "renew_or_resume",
                    message: response?.error_code,
                    data:{
                        isPurchasedSucessFully : false,
                        dontCloseModal: dontCloseModalBit(response?.error_code)
                    }
                }
                appEventHandler("", AppEventsData)

                switch (response?.error_code) {
                    case "token_expired":
                        setisSubResumeMesssage(TOKENEXPIRED)
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;
                    case "token_invalid":
                        setisSubResumeMesssage(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;   
                    case "recurly_exception":
                        setisSubResumeMesssage(response?.message)
                        break;   
                    case "subscription_exception":
                        setisSubResumeMesssage(response?.message)
                        break;  
                    case "jwt_default_exception":
                        setisSubResumeMesssage(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break; 
                    default:
                        setisSubResumeMesssage(response?.message)
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_resume_subscription",
                    event_properties:{
                        reason: response?.message,
                        billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                        payment_method: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                        current_status: renewAbleSubInfo?.state ?? "N/A"            
                    }
                }, token)
            }
        } catch (error) {
            setisSubResumeMesssage(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_resume_subscription",
                event_properties:{
                    reason: "API break or Server not responding",
                    billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                    payment_method: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                    current_status: renewAbleSubInfo?.state ?? "N/A"                      
                }
            }, token)
        }
        finally{
            setpurchaseLoading(false)
        }


        
    }  


    const appEventHandler = (message, response) => {
        
        let _nativeHandler = () => window.chrome ? window.chrome.webview : (window.webkit ?? {messageHandlers: {}}).messageHandlers.addonEventHandler;

        _nativeHandler() && _nativeHandler().postMessage({
            'message': message,
            'response': response

        });
    }

    const openInvoiceSummary = () => {
        appEventHandler("get_this_addon", {});
        setinfoModal(!infoModal)
    }

    const renewNow = () => {
        setpurchaseLoading(true)
        const formdata = qs.stringify({
            subscription_id: renewAbleSubInfo.id,
            invoice_id: renewAbleSubInfo.renew_invoice
        })

        renewNowAsync(formdata)
    }

    const renewNowAsync = async (formdata) => {
        let threedSecure = false;
        sendEvents({
            event_name:"ma_checkout_renew_subscription",
            event_properties:{
                billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                payment_gateway: renewAbleSubInfo?.payment_gateway ?? "N/A",
                productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                current_status: "Past Due",
                invoice_id: renewAbleSubInfo.renew_invoice,
                platform_from:'Apps'
            }
        }, token)
        try {
            const purchase = await poster("subscription/renew", formdata, token)
            const {data: response} = purchase;
            if(response?.status){
                setisSubResumeMesssage(t(SUCCESS))
                sendEvents({
                    event_name:"ma_renew_subscription",
                    event_properties:{
                        billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                        payment_gateway: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                        productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                        current_status: "Active",
                        invoice_id: renewAbleSubInfo.renew_invoice,
                        platform_from:'Apps'
                    }
                }, token)


                // APP Event handler 
                let AppEventsData = {
                    type: "renew_or_resume",
                    message: `Subscription renew or resume successfully`,
                    data:{
                        isPurchasedSucessFully : true
                    }
                }
                appEventHandler("",AppEventsData)
            }else{
                
                // APP Event handler 

                let AppEventsData = {
                    type: "renew_or_resume",
                    message: response?.error_code,
                    data:{
                        isPurchasedSucessFully : false,
                        dontCloseModal: dontCloseModalBit(response?.error_code)
                    }
                }
                appEventHandler("",AppEventsData)

                switch (response?.error_code) {
                    case "token_expired":
                        setisSubResumeMesssage(TOKENEXPIRED);
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;
                    case "token_invalid":
                        setisSubResumeMesssage(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;   
                    case "validation_exception":
                        response?.errors?.add_ons.map(error=>setisSubResumeMesssage(error))
                        break;    
                    case "declined":
                        setisSubResumeMesssage(response?.message)
                        break;   
                    case "renew_exception":
                        setisSubResumeMesssage(response?.message)
                        break; 
                    case "three_d_secure_action_required":
                        setthreedSecure({
                            three_d_secure_action_token_id:response?.errors?.three_d_secure_action_token_id,
                            enabled:true,
                            oldToken:""
                        })
                        threedSecure = true;
                        break;   
                    case "recurly_exception":
                        setisSubResumeMesssage(response?.message)
                        break;  
                    case "jwt_default_exception":
                        setisSubResumeMesssage(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        setTimeout(() => { window.location.href = "https://my.purevpn.com/" }, 300);       
                        break;  
                    default:
                        setisSubResumeMesssage(response?.message);
                        break;
                }
                sendEvents({
                    event_name:"ma_fail_renew_subscription",
                    event_properties:{
                        reason: response?.message,
                        billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                        payment_gateway: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                        productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                        current_status:renewAbleSubInfo?.state,
                        invoice_id: renewAbleSubInfo.renew_invoice
                    }
                }, token)
            }
        } catch (error) {
            setisSubResumeMesssage(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_renew_subscription",
                event_properties:{
                    reason: "API break or Server not responding.",
                    billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                    payment_gateway: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                    productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                    current_status: renewAbleSubInfo?.state,
                    invoice_id: renewAbleSubInfo.renew_invoice
                }
            }, token)
        }
        finally{
            if(!threedSecure){
                setpurchaseLoading(false)
            }
        }
    }

    const handle3dToken = (token) => {
        if(token){
            const formdata = qs.stringify({
                subscription_id: renewAbleSubInfo.id,
                invoice_id: renewAbleSubInfo.renew_invoice,
                threed_secure_id: token.id
            })
            renewNowAsync(formdata)
        }else{
            setisSubResumeMesssage("Something went wrong!!!")
            setpurchaseLoading(false)
            sendEvents({
                event_name:"ma_fail_renew_subscription",
                event_properties:{
                    reason: "Something went wrong, while 3d Token Authorization",
                    billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                    payment_gateway: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                    productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                    current_status:renewAbleSubInfo?.state,
                    invoice_id: renewAbleSubInfo.renew_invoice
                }
            }, token)
        }
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
    }

    const handle3dError = (error) => {
        setisSubResumeMesssage(error?.message || "Something went wrong!!!")
        setpurchaseLoading(false)
        setthreedSecure({
            enabled:false,
            three_d_secure_action_token_id: "",
            oldToken:""
        })
        sendEvents({
            event_name:"ma_fail_renew_subscription",
            event_properties:{
                reason: error?.message,
                billing_cycle: renewAbleSubInfo?.plan?.type ?? "N/A",
                payment_gateway: renewAbleSubInfo?.billing_info?.last_four ? "stripe" : "Paypal",
                productid: renewAbleSubInfo?.plan?.id ?? "N/A",
                current_status:renewAbleSubInfo?.state,
                invoice_id: renewAbleSubInfo.renew_invoice
            }
        }, token)
    }
    const resetstate = () => {
        seticon("")
    }

    const sendPaymentMessage = ({message, isPurchasedSucessFully, isDecline=false}) => {
        setisSubResumeMesssage(message)
        setGoBack(false)
         // APP Event handler 
         let AppEventsData = {
            type: "renew_or_resume",
            message: message,
            data:{
                isPurchasedSucessFully : isPurchasedSucessFully,
                dontCloseModal: isDecline
            }
        }
        if(isDecline){
            setGoBack(true)
        }
        appEventHandler("",AppEventsData)
    }
    const getSubTotal = (items) => {
        try {
            let SubTotal = items.reduce((a, item) => a + item?.sub_total, 0)
            return SubTotal.toFixed(2) ?? 0
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
        }
    }
    const getVat = (items) => {
        try {
            if(items.length > 0 ){
                let VatPrice = items.reduce((previousVat, item) => previousVat + item.tax, 0)
                return VatPrice.toFixed(2) ?? 0
            }
        } catch (error) {
            toast.error("Something went wrong - PURE-115")
        }
    }
    const getFamilyPlanProRatedAdjustment = (currency) => {
        let proratedPrice = purchasing.type == 'plans' ? invoiceData?.orderSummary?.find(item => item?.origin === 'plan')?.sub_total : familyPlanInvoiceData?.items.find(item => item?.is_familyPlan)?.sub_total
        let proratedDifferance = familyPlanInvoiceData?.total
        let arithmeticSymbol = proratedPrice > proratedDifferance ? '+' : '-';
        proratedDifferance = proratedDifferance - proratedPrice;
        proratedDifferance = Math.abs(proratedDifferance)

        return `${arithmeticSymbol}${currency}${proratedDifferance.toFixed(2)}`     
    }
    
    const openChat = () => {
        if(window.Intercom){
            window.Intercom('show')
        }
    }
    const hideChat = () => {
            if(constants.applicationAddons.includes(addonType?.type) &&  document.querySelector(".intercom-launcher") != null ){
                document.querySelector(".intercom-launcher").remove();
            }
    }
    /*
        Render Product Modal if entered type in URL is valid
        Else render a fragment to avoid Error
    */
    const renderProductModal = (type) => {
        return constants.inAppModals.includes(type) ? 
        <ProductAppModal locale={locale_code} setGetThisAddon={setGetThisAddon} type={toDisplayComboBox({platform,type})?isComboSelected:type}/> :
        null
    }

    const chargeAttempt = (paramsData = {}) => {
        let response = isAllowToUseParamMethod > 0 ? {...paramsData, ...data} : data
        if(whmcsUser){
            response.gateway = response.paymentMethodWhmcs
            setData(response)
        }
        if(purchasing.type === 'familyplan'){
            purchaseFamilyPlan(response)
        }
        else if(addonType.type){
            purchaseAddon(response)
        }
        else if(purchasing.type === 'plans'){
            purchasePlans(response)
        }
    }

    const getCreditAdjustment = (currency) => {
        return `${invoiceData?.credit_amount ? `-${currency}${invoiceData?.credit_amount?.toFixed(2)}` : `${currency}0.00`}`
    }

    const totalPrice = (data) => {
        try {
            let price = {
                total:0,
                subTotal:0,
                tax:0
            };
            data?.forEach((item) => {
                price.total+=item?.amount;
                price.subTotal+=item?.sub_total;
                price.tax+=item?.tax
            })
            
            return price
        } catch (error) {
            toast.error("Something went wrong - PURE-113")
        }
    }
    
    const renderGoBackCta = () => {
        return !toNotDisplayRefreshCart(platform) && goBack && <PrimaryBtn onClick={goBackAction}>Go Back</PrimaryBtn>
    }

    return (
        <div>
            {
                
                purchasing.type === "renew" ? 
                <>
                    {
                    !loading ? 
                        <>
                            {
                                canRenew && purchasing.type ?
                                <>
                                    {
                                        checkRenewSubWithInvoice
                                        ?
                                        <>
                                            {
                                                !isSubResumeMesssage ?
                                                <>
                                                    <ModalBody>
                                                        <PopupBody>
                                                            <div className="mod-head">
                                                                <h3>{t('inapp_purchase_plan_renewal')}</h3>
                                                                <p>{t('inapp_purchase_renew_plan')}</p>
                                                            </div>
                                                            <div className="mod-body">
                                                                <InfoCard hasborder noflex>
                                                                    <div>
                                                                        <label>{t('plan_type_text')} <strong className="text-capitalize">{state.plan_type || "-"}</strong></label>
                                                                    </div>
                                                                    <div>
                                                                        <label>{t('tbl_username')} <strong>{state.username || "-"}</strong></label>
                                                                    </div>
                                                                    <div>
                                                                        <label>{t('service_period')} <strong>{isWhmcsUser(renewAbleSubInfo?.account?.billingType) ? `${dateFormat(renewSubWithInvoiceData?.items?.[0]?.start_date)} - ` + `${dateFormat(renewSubWithInvoiceData?.items?.[0]?.end_date)}` : state.period || "-"}</strong></label>
                                                                    </div>
                                                                </InfoCard>
                                                                <InfoCard hasborder noflex>
                                                                    <OrderSummary open={orderSummary}>
                                                                        <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">{t('addon_purchasemodal_order_summary')}</strong></label>
                                                                        <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                                                                            {
                                                                                renewSubWithInvoiceData?.items.map((item, key)=>(
                                                                                    <li key={key}>{item?.description || "-"} (${item?.amount.toFixed(2) || 0} x 01) <strong>{currencies[data?.currency]}{item?.unit_amount.toFixed(2) || "-"}</strong></li>
                                                                                ))
                                                                            }
                                                                            {/* <li>VAT <strong>{currencies[data?.currency]}{data?.invoice_tax?.toFixed(2) || "-"}</strong></li> */}
                                                                        </ul>
                                                                    </OrderSummary>
                                                                </InfoCard>
                                                                <InfoCard hasborder>
                                                                    <div className="float-start">
                                                                        <label><strong className="ms-0">{t('upgradeplan_total_amount')}</strong> </label>
                                                                    </div>
                                                                    <div className="float-end">
                                                                        <label><strong>{currencies[renewSubWithInvoiceData?.currency]}{renewSubWithInvoiceData?.paid.toFixed(2) || "-"}</strong> </label>
                                                                    </div>
                                                                    <div className="float-none"></div>
                                                                </InfoCard>
                                                            </div>
                                                        </PopupBody> 
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <InfoCard noflex className="p-0">
                                                            <PurchaseBox type="payment" icon={icon}>
                                                                <Input type="select" name="select" onChange={e=>seticon(e.target.value)}>
                                                                    <option selected={icon===""} value="">{t('payment_method_text')}</option>
                                                                    <option selected={icon==="card"} value="card">{t('credit_card_text')}</option>
                                                                    <option selected={icon==="paypal"} value="paypal">Paypal</option>
                                                                </Input>
                                                            </PurchaseBox>
                                                        </InfoCard>
                                                    
                                                        <InfoCard className="py-0">
                                                            <div className="recurly-wrapper">
                                                                <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
                                                                    <Elements>
                                                                        {
                                                                            icon === "card" 
                                                                            ?
                                                                            <>
                                                                                <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                                                    <Payment resetstate={resetstate} renewSubscription="true" renewFromApp="true" endPoint={"subscription/change-pm-and-renew"} selectedSubscription={selectedSubscription} currentSub={renewAbleSubInfo} seticon={seticon} sendPaymentMessage={sendPaymentMessage} />
                                                                                </Suspense>
                                                                            </>
                                                                            : icon === "paypal" ?
                                                                            <>
                                                                                <Suspense fallback={<div className="text-center"><span className="spinner-border"></span></div>}>
                                                                                    <PaypalBtn resetstate={resetstate} renewSubscription="true" renewFromApp="true" endPoint={"subscription/change-pm-and-renew"} currentSub={renewAbleSubInfo} selectedSubscription={selectedSubscription} seticon={seticon} sendPaymentMessage={sendPaymentMessage} />
                                                                                </Suspense>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </Elements>
                                                                </RecurlyProvider>
                                                            </div>
                                                        </InfoCard>
                                                    </ModalFooter>
                                                </>
                                                :
                                                <ModalBody>
                                                    <PopupBody>
                                                    <div className="mod-head">
                                                                <h3>{isSubResumeMesssage}</h3>
                                                                {renderGoBackCta()}
                                                            </div>
                                                    </PopupBody>
                                                </ModalBody>
                                            }
                                        </>      
                                        :
                                        <>
                                            {
                                                !isSubResumeMesssage ?
                                                <>
                                                    <ModalBody className="pb-0">
                                                         
                                                            <PopupBody>
                                                                <div className="mod-head">
                                                                {renewAbleSubInfo?.state === "canceled" || (renewAbleSubInfo?.state === "paused" && renewAbleSubInfo?.is_paused) ?
                                                                    <>
                                                                        <h3>{t('resume_subscription')}</h3>
                                                                        <p>{t('resume_subscription_text')}</p>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <h3>{t('freemodal_please_wait')}</h3>
                                                                        <p>Your account renewal is in process</p>
                                                                    </>
                                                                }
                                                                </div>
                                                            </PopupBody>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        {
                                                            renewAbleSubInfo?.state === "canceled" ? <PrimaryBtn pointer={purchaseLoading} data-qamarker="renewNow" onClick={e=>resumeSelectedSubscription(renewAbleSubInfo?.id, "reactivate")}>{t('resume_subscription')}</PrimaryBtn>
                                                            : (renewAbleSubInfo?.state === "paused" || renewAbleSubInfo?.is_paused)  ? <PrimaryBtn pointer={purchaseLoading} data-qamarker="renewNow" onClick={e=> resumeSelectedSubscription(renewAbleSubInfo?.id, "resume")}>{renewAbleSubInfo?.state === "paused" ? buttonText("Renew subscription") : buttonText("Resume subscription")}</PrimaryBtn>
                                                            :   null
                                                        }
                                                    </ModalFooter>
                                                </>
                                                :
                                                <ModalBody>
                                                    <PopupBody>
                                                    <div className="mod-head">
                                                                <h3>{isSubResumeMesssage}</h3>
                                                            </div>
                                                    </PopupBody>
                                                </ModalBody>
                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <h2>{t('inapp_purchase_unable_to_renew_message')}</h2>
                                </> 
                            }
                            
                        </>
                        :
                        <>
                            <ModalBody>
                                <div className="mod-body" style={{minHeight:"200px"}}>
                                    {/* <span className="spinner-border"></span> */}
                                    <Skeleton className="d-block mb-3" width={300}/>
                                    <Skeleton className="d-block mb-3" width={280}/>
                                    <Skeleton className="d-block mb-3" width={260}/>
                                    <Skeleton className="d-block mb-3" width={240}/>
                                    <Skeleton className="d-block mb-3" width={220}/>
                                    <Skeleton className="d-block mb-3" width={50}/>
                                </div>
                            </ModalBody>
                            
                        </>
                    }
                </>
                :
                <></>

                
            }
            {
                purchasing.type === "familyplan" ?
                 
                <>
               {
                    infoModal ?
                    <>
                    <div>
                        <ModalBody>
                            <PopupBody className='addonContent'>
                                {renderProductModal(type)}
                            </PopupBody>
                            
                        </ModalBody>
                        <ModalFooter>
                            <PrimaryBtn color="primary" data-qamarker="getAddon" onClick={e=>openInvoiceSummary()}>{getThisAddon}</PrimaryBtn>
                        </ModalFooter>
                        
                    </div>
                </>:
                <>
                {
                    !loading ? 
                    <>
                        {
                            canPurchaseFamilyPlan ?
                            <>
                            {
                                !isFamilyPlanMessage ?
                                    <>
                                        <ModalBody>
                                            <PopupBody className='addonContent'>
                                                <div className="mod-head">
                                                    <h3>{t('inapp_purchase_fp_cta_text')}</h3>
                                                </div>
                                                <div className="mod-body">
                                                    <InfoCard hasborder noflex className="pt-0">
                                                        <div>
                                                            <label>{t('plan_type_text')} <strong className="text-capitalize">{state.plan_type || "-"}</strong></label>
                                                        </div>
                                                        <div>
                                                            <label>{t('tbl_username')}<strong>{state.username || "-"}</strong></label>
                                                        </div>
                                                        <div>
                                                            <label>{t('service_period')}<strong>{state.period || "-"}</strong></label>
                                                        </div>
                                                    </InfoCard>
                                                    
                                                    <InfoCard hasborder noflex>
                                                        <OrderSummary open={orderSummary}>
                                                            <label onClick={(e)=>setorderSummary(!orderSummary)}><strong className="ms-0">{t('addon_purchasemodal_order_summary')}</strong></label>
                                                            <ul className={classNames({"show":orderSummary},"list-unstyled m-0 p-0 mt-3")}>
                                                                {
                                                                    invoiceData?.orderSummary.length > 0 && invoiceData?.orderSummary.map((summary, key)=>(
                                                                        <>
                                                                            {summary?.is_familyPlan || summary?.origin === "add_on" ? <li className={key}>
                                                                                {summary.description} <strong className="text-end">{currencies[addonType.currency]}{ summary?.is_familyPlan ? familyPlanInvoiceData?.total.toFixed(2) : summary?.sub_total.toFixed(2)}</strong>
                                                                            </li> : null}
                                                                            {/* <li key={key}>
                                                                                VAT <strong className="text-end">{currencies[addonType.currency]}{Object.keys(invoiceData.orderSummary).length !== 0 ? summary?.tax?.toFixed(2) : "-"}</strong>
                                                                            </li> */}
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    <>
                                                                        {!whmcsUser && <li>
                                                                            {t('prorated_adjustment')}<strong className="text-end">
                                                                            {getFamilyPlanProRatedAdjustment(currencies[familyPlanInvoiceData?.items[0]?.currency])}</strong>
                                                                        </li>}
                                                                        </>
                                                                }
                                                                    {!whmcsUser && <li>
                                                                        {t('credit_adjustment_text')}<strong className="text-end">{getCreditAdjustment(currencies[addonType.currency])}</strong>
                                                                     </li>}
                                                                    <li>
                                                                        VAT <strong className="text-end">{currencies[addonType.currency]}{getVat(invoiceData.orderSummary)}</strong>
                                                                     </li>
                                                                     
                                                            </ul>
                                                        </OrderSummary>
                                                    </InfoCard>
                                                    <InfoCard hasborder>
                                                        <div className="float-start">
                                                            <label><strong className="ms-0">{t('upgradeplan_total_amount')}</strong> </label>
                                                        </div>
                                                        <div className="float-end">
                                                            <label><strong>{currencies[addonType.currency]}{Object.keys(invoiceData.orderSummary).length !== 0 ? getTotalWithCredit(addonPrice(invoiceData.orderSummary), invoiceData?.credit_amount) : "-"}</strong></label>
                                                        </div>
                                                        <div className="float-none"></div>
                                                    </InfoCard>
                                                    <InfoCard>
                                                        {(whmcsUser || declineState || pastDue)
                                                            ?
                                                            <PopupPurchaseUtil isWhmcsUser={true} isAllowToUseParamMethod={isAllowToUseParamMethod} endPoint={endPoint} selectedData={data} currentSub={addonType.subscription} chargeAttempt={chargeAttempt} whmcsUser={whmcsUser} toggle={e=>setinfoModal(!infoModal)}/>
                                                            :
                                                            <div className="float-start">
                                                                <label>
                                                                    <strong className="ms-0 text-bold">
                                                                        {
                                                                            state.last_four
                                                                            ?
                                                                            <CardNumber card="up">**** **** **** {state.last_four || "****"}</CardNumber>
                                                                            :
                                                                            <CardNumber card="paypal">Paypal</CardNumber>
                                                                        }
                                                                    </strong> 
                                                                </label>
                                                            </div>
                                                        }
                                                    </InfoCard>
                                                </div>
                                            </PopupBody>
                                        </ModalBody>
                                        {(!whmcsUser && !goBack && !pastDue) && <ModalFooter className='gap-2'>
                                            <PrimaryBtn color="primary" data-qamarker="purchaseFamilyPlanFromApp" pointer={purchaseLoading} onClick={purchaseFamilyPlan}>{buttonText("Pay now")}</PrimaryBtn>
                                            <DestructBtn color="secondary" data-qamarker="cancle" onClick={e=>setinfoModal(!infoModal)}>{t('cancel_text')}</DestructBtn>
                                        </ModalFooter>}
                                    </> 
                                :
                                    <>
                                        <ModalBody>
                                            <PopupBody>
                                            <div className="mod-head">
                                                        <h3>{isFamilyPlanMessage}</h3>
                                                        {renderGoBackCta()}         
                                                    </div>
                                            </PopupBody>
                                        </ModalBody>
                                    </>
                            }   
                            </>
                            :
                            <>
                                <h2>{isFamilyPlanMessage}</h2>
                            </>
                        }
                    </>
                    :
                    <>
                        <ModalBody>
                            <div className="mod-body" style={{minHeight:"200px"}}>
                                {/* <span className="spinner-border"></span> */}
                                <Skeleton className="d-block mb-3" width={300}/>
                                <Skeleton className="d-block mb-3" width={280}/>
                                <Skeleton className="d-block mb-3" width={260}/>
                                <Skeleton className="d-block mb-3" width={240}/>
                                <Skeleton className="d-block mb-3" width={220}/>
                                <Skeleton className="d-block mb-3" width={50}/>
                            </div>
                        </ModalBody>
                        
                    </>
                }
                
                </>
               }</>
                :
                <></>
            }
            
            {
                threedSecure.enabled &&
                <ThreeDSecureAction
                actionTokenId={threedSecure.three_d_secure_action_token_id}
                onToken={handle3dToken}
                onError={handle3dError}
                />
            }
        </div>
    )
}

export default AppPurchase;
