import React, { useState } from 'react'
import noSearch from '../../../assets/noSearch.png'
import loginlogo from '../../../assets/loginlogo.png'
import { EmptySearch, InfoCard, PopupBody } from '../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../styles/Generic'
import { Modal, ModalBody, Input, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { sendEvents } from '../../../tools/mpEvents';
import { useTranslation } from 'react-i18next';

const NoSearchBox = ({showFilters,searchContent}) => {
    const { t, i18n } = useTranslation();

    const [modal,showModal] = useState(false)
    const [modalType, setmodalType] = useState("updatecountry-sm");

    const widthCalculator = modalType.slice(-2);

	const [modalWidth] = useState({
		lg: "675px",
		sm: "440px",
	});
    const toggle = () => showModal(!modal);


    const [comments, setComments] = useState("")

    const [cmntDetails, setCmntDetails] = useState('')

    const [error, setError] = useState(false)

    const setValue = (e) =>{
        setCmntDetails(e.target.value)
    }

    const handleSubmit = () =>{
        const token = localStorage.getItem("token");

		sendEvents(
			{
				event_name: "ma_submit_request_location",
				event_properties: {
					search_bar_content: searchContent ?? "N/A",
                    selected_filters: showFilters ??"All"
				},
			},
			token
		);
        if(cmntDetails){

            sendEvents(
                {
                    event_name: "ma_submit_request_location_comments",
                    event_properties: {
                        search_bar_content: searchContent ?? "N/A",
                        selected_filters: showFilters ??"All",
                        comments: cmntDetails
                    },
                },
                token
                );
        }
        toggle()

    }
  return (
    <>
        <EmptySearch className='col-lg-12 px-4'>
            <img className='my-4 
            img-fluid' src={noSearch}/>
            <div>
            <p className='text-center'>
                {t('manualconf_location_not_part')}
            </p>
            </div>
            <div className='my-3'>
                <PrimaryBtn onClick={()=>showModal(true)}>{t('manualconf_request_location_cta')}</PrimaryBtn>
            </div>
        </EmptySearch>
        {modal && <Modal 
        backdrop={false} 
        isOpen={modal}
         toggle={toggle} 
         style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} 
         className="modal-wrapper">
                <ModalBody>
                    <PopupBody>
                        <div className="searchHeader mod-head d-flex mb-2">
                            <img src={loginlogo} className='img-fluid'/>
                            <h3 className='mx-2'>{t('manualconf_location_request')}</h3>
                        </div>
                        <div className="mod-body">
                            <InfoCard noflex className="p-0">
                                <Input type="textarea" name="text" placeholder="Enter the desired location" className="textAreSearch mt-2" onChange={setValue}>
                                </Input>
                            </InfoCard>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <PrimaryBtn color="primary" onClick={handleSubmit}>{t('submit_text')}</PrimaryBtn>
                    <DestructBtn onClick={toggle}>{t('cancel_text')}</DestructBtn>
                </ModalFooter>
            </Modal>}
    </>
  )
}

export default NoSearchBox