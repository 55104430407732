import { Input } from "reactstrap";

const Email = ({email, setEmail}) => {


    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
          evt.preventDefault();
    
          var value = email.value.trim();
    
          if (value && isValid(value)) {
            setEmail({
                ...email,
              items: [...email.items, email.value],
              value: ""
            });
          }
        }
      };
    
    const handleChange = evt => {
        setEmail({
            ...email,
          value: evt.target.value,
          error: null
        });
      };
    
      const  handleDelete = item => {
        setEmail({
            ...email,
          items: email.items.filter(i => i !== item)
        });
      };

    const  handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !isInList(email));

            setEmail({
            ...email,
            items: [...email.items, ...toBeAdded]
            });
        }
    };

    const isValid = (email_check) => {
        let error = null;

        if (isInList(email_check)) {
            error = `${email_check} has already been added.`;
        }

        if (!isEmail(email_check)) {
            error = `${email_check} is not a valid email address.`;
        }

        if (error) {
            setEmail({...email, error: error });

            return false;
        }

        return true;
    }

    const  isInList = (email_check) => {
        return email.items ? email.items.includes(email_check) : false
    }

    const isEmail = (email) => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    return <>
        <Input className="w-100" value={email.value}
            placeholder="Enter one or more email addresses, separated by commas"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            // onPaste={handlePaste}
        >
        </Input>

        {email.error && <p className="error">{email.error}</p>}

        {email?.items && email.items.map(item => (
            <div className="tag-item" key={item}>
                {item}
            <button
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
                >
                &times;
            </button>
            </div>
        ))}
    </>
}
export default Email;