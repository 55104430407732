import React,{useState} from 'react'
import { BusinessBtnFlex, TableSec } from '../../../../styles/dashboard/Business'
import NotTick from './NotTick'
import Tick from './Tick'
import { PrimaryBtn, SecondryBtn } from '../../../../styles/Generic'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';

const Table = ({ children, getInTouchEvent, handleGetStarted, handleCTAEvents,loading, accountDataLoader=false }) => {

    const { t, i18n } = useTranslation();
    
    const buttonText = (text) => {
        return !loading ? `${text}` : ( <>{text} <span className="spinner-border text-dark spinner-border-sm ms-1"></span></>);
    }

    return (
        <TableSec>
            {children}
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>PureVPN</th>
                        <th>PureDome</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t('business_tablerowOne')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowtwo')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowthree')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowfour')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowfive')}</td>
                        <td><Tick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowsix')}</td>
                        <td><Tick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerowseven')}</td>
                        <td><Tick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tableroweight')}</td>
                        <td><Tick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tableroweleven')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td>{t('business_tablerownine')}</td>
                        <td>{t('business_tablerownine_col_two')}</td>
                        <td>{t('business_tablerownine_col_one')}</td>
                    </tr>
                    <tr>
                        <td>{t('business_number_dedicated_ips')}</td>
                        <td>6</td>
                        <td>30</td>
                    </tr>
                    <tr>
                        <td>{t('business_concurrent')}</td>
                        <td><NotTick /></td>
                        <td><Tick /></td>
                    </tr>
                    <tr>
                        <td colSpan={3}><p>{t('business_tablerowten')}</p>
                            <BusinessBtnFlex>
                                <SecondryBtn onClick={() => { getInTouchEvent(); }}>{t('business_get_in_touch_cta')}</SecondryBtn>
                                { loading ? <PrimaryBtn onClick={() => { handleGetStarted(); handleCTAEvents("Start 30-Day Free Trial") }} disabled>{buttonText(t('business_thirthydays_text'))}</PrimaryBtn> 
                                  : accountDataLoader ? <Skeleton width={200} height={45}/> : <PrimaryBtn onClick={() => { handleGetStarted(); handleCTAEvents("Start 30-Day Free Trial") }}>{buttonText(t('business_thirthydays_text'))}</PrimaryBtn>
                                }
                            </BusinessBtnFlex>
                            <div className='note-Section'>
                                <span className="note-para"><strong>{t('whmcs_note')} </strong>{t('business_note_vpn')}</span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </TableSec>
    )
}

export default Table