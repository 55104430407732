export const dedicatedServerContent = [
    {
      heading: "dedicated_server_modal_features_three",
      hasImage: false,
      provision: [0,7,30,30]
    },
    {
      heading: "dedicated_server_modal_features_four",
      hasImage: false,
      provision: ["All", "IKEV, OPENVPN", "IKEV, OPENVPN", "IKEV, OPENVPN"]
    },
    {
      heading: "dedicated_server_modal_features_one",
      hasImage: true,
      provision: [0,1,1,1]
    },
    {
      heading: "dedicated_server_modal_features_two",
      hasImage: true,
      provision: [0,0,1,1]
    },
    {
      heading: "dedicated_server_modal_features_five",
      hasImage: true,
      provision: [0,0,1,1]
    },
    {
      heading: "dedicated_server_modal_features_six",
      hasImage: true,
      provision: [0,0,0,1]
    },
    {
      heading: "dedicated_server_modal_features_seven",
      hasImage: true,
      provision: [0,0,0,1]
    },
    {
      heading: "dedicated_server_modal_features_eight",
      hasImage: true,
      provision: [0,0,0,1]
    },
    {
      heading: "dedicated_server_modal_features_nine",
      hasImage: true,
      provision: [0,0,0,1]
    },
    {
      heading: "dedicated_server_modal_features_ten",
      hasImage: true,
      provision: [0,0,0,1]
    },
]