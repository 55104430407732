import { ERRORS, GETONBOARDING, GETONBOARDINGREQUEST, SETONBOARDINGHISTORY, LOADING, RESETONBOARDINGDATA, UPDATELOCALEONBOARDINGDATA } from "./types"

export const getOnBoardingRequested = (payload) => {
    return{
        type:GETONBOARDINGREQUEST,
        payload
    }
}

export const getOnBoardingType = (payload) => {
    return{
        type:GETONBOARDING,
        payload
    }
}

export const setOnBoardingHistory = (payload) => {
    return{
        type:SETONBOARDINGHISTORY,
        payload
    }
}

export const getOnboardingReset = (payload) => {
    return{
        type:RESETONBOARDINGDATA,
        payload
    }
}

export const loadingType = (payload) => {
    return{
        type:LOADING
    }
}

export const updateOnboardingWithLocale = (payload) => {
    return {
        type:UPDATELOCALEONBOARDINGDATA,
        payload
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
