import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const PureKeepIconOnlySVG = ({ fillColorOne, fillColorTwo, fillColorThree, fillColorFour, width, height, style, className }) => (
    <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} className={className} viewBox="0 0 120 121" width={width}>
      <Path
          fillColor={fillColorOne}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.97 0.113281C26.8852 0.113281 0 26.986 0 60.0832C0 76.6617 6.73619 91.6241 17.5857 102.517C28.4351 113.41 43.4574 120.113 59.97 120.113C76.4826 120.113 91.5642 113.41 102.414 102.517C113.263 91.6241 120 76.6617 120 60.0832C120 43.5047 113.263 28.5422 102.414 17.6494C91.5642 6.75665 76.5423 0.113281 59.97 0.113281Z"
      />
      <Path
          fillColor={fillColorTwo}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.9105 12.9805C54.0685 12.9805 45.4843 14.2373 40.4769 16.4518C40.1789 16.5715 39.6424 16.7511 39.3443 16.8707C38.6885 17.17 37.6155 17.5291 37.0194 17.8882C33.3235 19.564 29.9852 22.1376 27.4814 25.1899C19.851 32.4917 15.082 48.6512 15.082 60.0826C15.082 71.1549 19.5529 87.0153 26.8257 94.2569C29.2698 97.3694 32.5484 100.003 36.1848 101.739C37.1386 102.277 38.6885 102.816 39.6424 103.234C44.8882 105.748 53.9493 107.125 60.149 107.125C69.8658 107.125 81.9673 103.654 88.8819 97.9681C89.8362 97.1893 91.2073 96.2922 92.1009 95.4542C95.2609 93.2997 97.705 89.7085 98.6584 86.3566C102.533 80.1923 100.268 67.8631 100.268 60.0826C99.851 57.5689 99.7314 54.8757 99.9108 52.4817C99.0761 47.0353 100.686 38.4767 97.9434 33.9879C97.0489 30.5764 94.6048 26.8058 91.3261 24.6513C83.9937 17.4692 70.9388 12.9805 59.9105 12.9805Z"
      />
      <Path
          fillColor={fillColorThree}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.7914 25.8496C56.8704 25.8496 47.9882 26.4481 45.4845 27.5852C45.3057 27.6451 44.7691 27.7049 44.6499 27.8246C44.2922 28.0041 43.2193 28.1239 42.8616 28.3034C40.0001 29.1414 37.556 31.2361 36.3041 33.7498C32.489 37.4006 30.1045 54.3382 30.1045 60.0839C30.1045 65.5901 32.3698 82.4076 35.9465 86.0588C37.1388 88.6328 39.6424 90.7273 42.4442 91.6253C42.9211 91.9238 44.5903 92.1639 45.0672 92.4032C47.6902 93.6597 57.1089 94.3776 60.2087 94.3776C65.0373 94.3776 76.006 92.6425 79.4635 89.7692C79.9404 89.3507 81.3115 88.9313 81.7288 88.5127C84.8883 87.4354 86.9147 83.7842 86.3782 80.9716C88.2858 77.9192 80.5366 64.1537 80.5366 60.2635C79.7019 58.2884 79.6423 55.8345 80.4173 53.9792C80 51.2859 86.0809 41.8296 84.7097 39.5553C85.4248 36.7423 83.3976 32.852 80.1192 31.7747C76.4829 28.1239 65.3354 25.8496 59.7914 25.8496Z"
      />
      <Path
          fillColor={fillColorFour}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.9794 75.4649L60.6858 60.2629C59.434 58.8265 59.4936 56.612 60.8646 55.2953L71.416 44.9413C73.7409 42.6669 72.1314 38.7168 68.9123 38.7168H59.6724H50.492H49.8959H48.7037C46.7365 38.7168 45.127 40.3328 45.127 42.3078V60.0235V77.7391C45.127 79.7142 46.7365 81.3301 48.7037 81.3301H50.492H60.2089H69.9257H71.2372C74.3966 81.4499 76.0658 77.799 73.9794 75.4649Z"
      />
    </SVG>
)
PureKeepIconOnlySVG.propTypes = {
  fill: PropTypes.string,
}
PureKeepIconOnlySVG.defaultProps = {
  width: '120',
  height: '121',
  fillColorOne: '#2EC4B6',
  fillColorTwo: '#74D8CE',
  fillColorThree: '#B9EBE7',
  fillColorFour: 'white',
  className: 'svg-icon'
}
export default PureKeepIconOnlySVG