import React from 'react'
import { NotSupportedBox, SecondryBtn } from '../styles/Generic'
import notsupported from '../assets/notsupported.png'

const NotSupported = () => {
    return (
        <NotSupportedBox>
            <div className="text-center" style={{maxWidth:"439px"}}>
                <img src={notsupported} className="img-fluid" height={214} alt="Not Supported"/>
                <h1>Browser not supported</h1>
                <p>Seems like you're using an old version of this browser that we no longer support. Please update your browser to the latest version to keep enjoying best experience of PureVPN</p>
                <SecondryBtn onClick={() => window.open("https://www.google.com/intl/en_pk/chrome/", "_blank")}>Update Browser</SecondryBtn>
            </div>
        </NotSupportedBox>
    )
}

export default NotSupported