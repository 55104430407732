import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main'
import { PrimaryBtn } from '../../../../styles/Generic'
import CircularLoader from '../../../generic/CircularLoader';
import { useTranslation } from 'react-i18next';

const WatchVideoPopup = ({backdrop, toggle, modal, modalWidth, widthCalculator}) => {
    const { t, i18n } = useTranslation();
    return <div>
        <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">

            {
                <>
                    <ModalBody className="pb-0">
                        <PopupBody>
                            
                            <div className="fp-head mb-3">
                                <h2 className='mb-2 text-center' style={{fontSize: '18px', color: '#001219', marginBottom: '20px'}}>{t('family_learn_how_family_plan_works')}</h2>
                                <p className="text-center" style={{fontSize: '18px', color: '#637381', marginBottom: '24px'}}>{t('family_learn_how_family_plan_works_para')}</p>

                                <div className='video-frame'>
                                    <CircularLoader />
                                    <iframe src='https://www.youtube.com/embed/xWNmcASbCug'
                                            frameborder='0'
                                            allow='autoplay; encrypted-media'
                                            allowfullscreen
                                            title='video'
                                            width='100%'
                                            height='354px'
                                    />
                                </div>

                            </div>
                        </PopupBody>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                        <PrimaryBtn color="primary" className='m-auto' onClick={toggle}>{t('gotit_text')}</PrimaryBtn>
                    </ModalFooter>
                </>
            }
            
            
        </Modal>
</div>
}

export default WatchVideoPopup;