import { constants } from "../constants";

export const multi_login =
{
  en: {
    heading_1: "Increase Multi-login",
    para_1: 'If you want to connect to PureVPN on more than 10 devices at the same time, then here’s a solution for you:',
    heading_2: `Get ${constants.multi_login_count} additional Multi-login:`,
    para_2: "",
    desc: [
      `You can now increase your multi-login limit by up to ${constants.multi_login_count} additional devices, ensuring that they all remain secure and protected online.`,
      'Want more multi-logins? <a href="/v2/dashboard/business" className="puredome-cta">Explore PureDome</a>, our dedicated solution for large teams and businesses',
    ],
    cta: "Get this Add-on",
  },
  fr: {
    heading_1: "Augmenter la multi-connexion",
    para_1: "Si vous souhaitez vous connecter à PureVPN sur plus de 10 appareils en même temps, voici une solution pour vous :",
    heading_2:`Obtenez ${constants.multi_login_count} connexions multiples supplémentaires.`,
    para_2: "",
    desc: [
      `Vous pouvez désormais augmenter votre limite de multi-connexion jusqu'à ${constants.multi_login_count} appareils supplémentaires, en veillant à ce qu'ils restent tous sécurisés et protégés en ligne.`,
      'Vous voulez plus de multi-connexions ? <a href="/v2/dashboard/business" className="puredome-cta">Découvrez PureDome</a>, notre solution dédiée aux grandes équipes et aux entreprises.',
    ],
    cta: "Obtenir cette extension",
  },
  de: {
    heading_1: "Mehrere Anmeldungen erhöhen",
    para_1: "Wenn Sie sich gleichzeitig mit mehr als 10 Geräten bei PureVPN verbinden möchten, haben wir hier eine Lösung für Sie:",
    heading_2:`Erhalten Sie ${constants.multi_login_count} zusätzliche Mehrfachanmeldungen.`,
    para_2: "",
    desc: [
      `Sie können jetzt Ihre Multi-Login-Limit um bis zu ${constants.multi_login_count} zusätzliche Geräte erhöhen und sicherstellen, dass alle online sicher und geschützt bleiben.`,
      'Sie möchten mehrere Anmeldungen? <a href="/v2/dashboard/business" className="puredome-cta">Entdecken Sie PureDome</a>, unsere dedizierte Lösung für große Teams und Unternehmen',
    ],
    cta: "Erhalten Sie dieses Add-on",
  },

  zh: {
    heading_1: "增加多重登录",
    para_1: "如果您想同时在超过10个设备上连接到PureVPN，那么这里有一个解决方案：",
    heading_2:`获取${constants.multi_login_count}个额外的多登录。`,
    para_2: "",
    desc: [
      `您现在可以通过增加多达${constants.multi_login_count}个附加设备来增加您的多重登录限制，确保它们都在线安全和受保护。`,
      '想要更多多重登录？ <a href="/v2/dashboard/business" className="puredome-cta">探索PureDome</a>，我们专门为大型团队和企业提供的解决方案',
    ],
    cta: "获取此附加组件",
  },

  es: {
    heading_1: "Aumentar el inicio de sesión múltiple",
    para_1: "Si desea conectarse a PureVPN en más de 10 dispositivos al mismo tiempo, aquí tiene una solución:",
    heading_2:`Obtén ${constants.multi_login_count} accesos múltiples adicionales.`,
    para_2: "",
    desc: [
      `Ahora puede aumentar su límite de inicio de sesión múltiple en hasta ${constants.multi_login_count} dispositivos adicionales, asegurándose de que todos permanezcan seguros y protegidos en línea.`,
      '¿Quiere más inicio de sesión múltiple? <a href="/v2/dashboard/business" className="puredome-cta">Explore PureDome</a>, nuestra solución dedicada para grandes equipos y empresas',
    ],
    cta: "Obtener esta extensión",
  },
  ru: {
    heading_1: "Увеличить мульти-логин",
    para_1: 'Если вы хотите подключиться к PureVPN на более чем 10 устройствах одновременно, то у нас есть для вас решение:',
    heading_2:`Получите ${constants.multi_login_count} дополнительных множественных входов.`,
    para_2: "",
    desc: [
      `Теперь вы можете увеличить свой лимит мульти-логина на дополнительные ${constants.multi_login_count} устройств, обеспечивая их безопасность и защиту в Интернете.`,
      'Хотите больше мульти-логинов? <a href="/v2/dashboard/business" className="puredome-cta">Исследуйте PureDome</a>, наше специализированное решение для больших команд и бизнеса',
    ],
    cta: "Получить это дополнение",
  },
  tr: {
    heading_1: "Çoklu Giriş Sayısını Artır",
    para_1: `PureVPN üzerinden aynı anda 10'dan fazla cihazda bağlantı kurmak istiyorsanız, işte size bir çözüm:`,
    heading_2:`${constants.multi_login_count} ekstra çoklu giriş alın.`,
    para_2: "",
    desc: [
      `Şimdi ek ${constants.multi_login_count} cihazla çoklu giriş limitinizi artırabilir, böylece hepsi çevrimiçi olarak güvende ve korunur.`,
      'Daha fazla çoklu giriş mi istiyorsunuz? Büyük takımlar ve işletmeler için özel bir çözüm olan <a href="/v2/dashboard/business" className="puredome-cta">PureDome</a>\'u keşfedin',
    ],
    cta: "Bu Eklentiyi Alın",
  },

  pt: {
    heading_1: "Aumente o Multi-login",
    para_1: 'Se você deseja se conectar ao PureVPN em mais de 10 dispositivos ao mesmo tempo, aqui está uma solução para você:',
    heading_2:`Obtenha ${constants.multi_login_count} acessos múltiplos adicionais.`,
    para_2: "",
    desc: [
      `Você pode agora aumentar seu limite de multi-login em até ${constants.multi_login_count} dispositivos adicionais, garantindo que todos permaneçam seguros e protegidos online.`,
      'Quer mais multi-logins? <a href="/v2/dashboard/business" className="puredome-cta">Explore o PureDome</a>, nossa solução dedicada para equipes grandes e empresas',
    ],
    cta: "Obtenha este Complemento",
  },
  ja: {
    heading_1: "マルチログイン数の増加",
    para_1: '10台以上のデバイスでPureVPNに接続したい場合、以下のソリューションがあります：',
    heading_2:`追加のマルチログイン${constants.multi_login_count}を取得してください。`,
    para_2: "",
    desc: [
      `最大${constants.multi_login_count}台の追加デバイスでマルチログインの制限を増やし、すべてのデバイスをオンラインで安全かつ保護された状態に保ちます。`,
      'より多くのマルチログインをご希望ですか？ <a href="/v2/dashboard/business" className="puredome-cta">大規模なチームやビジネス向けの専用ソリューション、PureDomeをご覧ください</a>',
    ],
    cta: "このアドオンを入手",
  },

  it: {
    heading_1: "Aumenta il Multi-login",
    para_1: "Se vuoi connetterti a PureVPN su più di 10 dispositivi contemporaneamente, ecco la soluzione per te:",
    heading_2:`Ottieni ${constants.multi_login_count} accessi multipli aggiuntivi.`,
    para_2: "",
    desc: [
      `Ora puoi aumentare il limite di multi-login di ${constants.multi_login_count} dispositivi aggiuntivi, garantendo che rimangano tutti sicuri e protetti online.`,
      'Vuoi più multi-login? <a href="/v2/dashboard/business" className="puredome-cta">Scopri PureDome</a>, la nostra soluzione dedicata per grandi team e aziende',
    ],
    cta: "Ottieni questo add-on",
  },

  nl: {
    heading_1: "Verhoog Multi-login",
    para_1: 'Als u op meer dan 10 apparaten tegelijkertijd verbinding wilt maken met PureVPN, dan is hier een oplossing voor u:',
    heading_2:`Ontvang ${constants.multi_login_count} extra multi-logins.`,
    para_2: "",
    desc: [
      `U kunt nu uw multi-login limiet verhogen met maximaal ${constants.multi_login_count} extra apparaten, zodat ze allemaal veilig en beschermd blijven online.`,
      'Meer multi-logins nodig? <a href="/v2/dashboard/business" className="puredome-cta">Ontdek PureDome</a>, onze toegewijde oplossing voor grote teams en bedrijven',
    ],
    cta: "Krijg deze Add-on",
  },

  ko: {
    heading_1: "멀티 로그인 증가",
    para_1: '10개 이상의 기기에서 동시에 PureVPN에 연결하려면 다음 솔루션을 사용하세요:',
    heading_2:`${constants.multi_login_count}개의 추가 다중 로그인을 얻으세요.`,
    para_2: "",
    desc: [
      `이제 최대 ${ constants.multi_login_count }개의 기기를 추가하여 멀티 로그인 제한을 증가시켜 모든 기기가 온라인에서 안전하고 보호받도록 보장할 수 있습니다.`,
      '더 많은 멀티 로그인이 필요하신가요? 대규모 팀 및 비즈니스용으로 만든 PureDome을 <a href="/v2/dashboard/business" className="puredome-cta">탐색하세요</a>.',
    ],
    cta: "이 애드온 받기",
  }
}






