import { uuid } from 'uuidv4';
import { sendEvents } from '../../../../tools/mpEvents';
import Skeleton from 'react-loading-skeleton';
import { getTokenFromLocalStorage } from '../../../../tools/tools';

export const generateChatId = () => {
    return uuid();
}

export const skeleton = (html, loading, height = 20, width = 300) => {
    return loading ? <Skeleton height={height} width={width} className="account-loading"/> : html
}

export const videoSkeleton = (html, loading, height = 20, width = "100%") => {
    return loading ? (
        <>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
            <Skeleton height={height} width={width} className="account-loading"/>
        </>
    ) : html
}

export const sendMPEvents = async (event_name, event_properties) => {
    const token = getTokenFromLocalStorage();
    let event_properties_obj = {};
    switch(event_name) {
        case "ma_pureai_send_prompt":
            event_properties_obj = {
                ...event_properties,
                prompt: event_properties.prompt ?? "N/A",
                promptUTCTime: event_properties.promptTime.toUTCString(),
                promptUnixTime: event_properties.promptTime.getTime()
            };
            break;
        case "ma_pureai_receive_response":
            event_properties_obj = {
                ...event_properties,
                prompt: event_properties.prompt ?? "N/A",
                response: event_properties.response,
                streaming: event_properties.streaming,
                responseStartTime: event_properties.responseStartTime,
                responseEndTime: event_properties.streamingCompleteTime,
            };
            break;
        case "ma_pureai_response_stream_start":
            event_properties_obj = {
                ...event_properties,
                prompt: event_properties.prompt ?? "N/A",
                responseTime: event_properties.responseTime
            };
            break;
        default:
            event_properties_obj = event_properties
            break;
    }

    sendEvents({
        event_name: event_name,
        event_properties: event_properties_obj
    }, token);
};