import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AutoLogin from './component/AutoLogin';
import OauthAuthorization from './component/OauthAuthorization';
import Dashboard from './component/dashboard/Index';
import ForgotPassword from './component/ForgotPassword';
import NotSupported from './component/NotSupported';
import ResetPassword from './component/ResetPassword';
import LoginRoutes from './tools/routes/LoginRoutes';
import TagManager from 'react-gtm-module'
import CaptchaRoute from './component/CaptchaRoute';
import Unauthorized from './component/Unauthorized';
import TestRedirection from './component/TestRedirection';
import FusionLogin from './component/FusionLogin';
import FusionLoginV2 from './component/FusionLoginV2';

const tagManagerArgs = {
  gtmId: 'GTM-W9S5QRL'
}
TagManager.initialize(tagManagerArgs)


function isIE() {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11
  
  return (msie > 0 || trident > 0);
}

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
      <Router basename="/v2">
      {
        isIE() ?
          <Switch>
            <Route exact path="/" component={NotSupported} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        :
          <Switch>
            <LoginRoutes exact path="/" component={FusionLogin} title="Login" />
            <LoginRoutes exact path="/login" component={FusionLogin} title="Login" />
            <LoginRoutes exact path="/beta-login" component={FusionLoginV2} title="Login" />
            <LoginRoutes exact path="/unauthorized" component={Unauthorized} title="Invalid Authorization" />
            <Route exact path="/autologin" component={AutoLogin} title="Login" />
            <Route exact path="/oauth_authorization" component={OauthAuthorization} title="Login" />
            <Route exact path="/testredirection" component={TestRedirection} title="Test Redirection" />
            <LoginRoutes exact path="/forgot-password" component={ForgotPassword} title="Forgot Password" />
            <LoginRoutes exact path="/reset-password" component={ResetPassword} title="Reset Password" />
            <LoginRoutes exact path="/captchaverification/:id" component={CaptchaRoute} title="Captcha Routes" />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
      }
      </Router>
    </>
  );
}

export default App;
