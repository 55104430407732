
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import pureEncryptLogo from '../../../assets/onboarding-sprites/pureencrypt/ACSD-30980-pureencrypt-icon.svg'
import pureEncryptBg from '../../../assets/onboarding-sprites/pureencrypt/BA-190-pureEncrypt-background.png'
import sectionOneIcon1 from '../../../assets/onboarding-sprites/pureencrypt/ACSD-30980-icon-1.svg'
import sectionOneIcon2 from '../../../assets/onboarding-sprites/pureencrypt/ACSD-30980-icon-2.svg'
import sectionOneIcon3 from '../../../assets/onboarding-sprites/pureencrypt/ACSD-30980-icon-3.svg'
import accordionImage1 from '../../../assets/onboarding-sprites/pureencrypt/purencrypt-secure_file_sharing_1.png'
import accordionImage2 from '../../../assets/onboarding-sprites/pureencrypt/purencrypt-rights_of_access_1.png'
import accordionImage3 from '../../../assets/onboarding-sprites/pureencrypt/purencrypt-social_media_management_1.png'
import accordionImage4 from '../../../assets/onboarding-sprites/pureencrypt/purencrypt-doxing_1.png'
import { NewSignupViewWrapper } from '../../../styles/dashboard/Main'
import { LinkBtn, PrimaryBtn    } from '../../../styles/Generic'
import { constants } from '../../../tools/constants';
import { useTranslation } from 'react-i18next';



const PureEncryptSignupView = ({ history, subscriptionData, buttonClickHandler }) => {

    const { t, i18n } = useTranslation();

    const [imgState,setImgState] = useState(0);

    const [faqs, setFaqs] = useState([
        {
            question: "pureencrypt_faqs_question_one",
            answer: "pureencrypt_faqs_question_one_para",
            open: false,
        },
        {
            question: "pureencrypt_faqs_question_two",
            answer: "pureencrypt_faqs_question_two_para",
            open: false,
        },
        {
            question: "pureencrypt_faqs_question_three",
            answer: "pureencrypt_faqs_question_three_para",
            open: false,
        },
        {
            question: "pureencrypt_faqs_question_four",
            answer: "pureencrypt_faqs_question_four_para",
            open: false,
        },
    ]);

    const [listItems, setListItems] = useState([
        {
            heading: "pureencrypt_accordion_one_heading",
            content: "pureencrypt_accordion_one_para",
            open: true,
            img:accordionImage1,
        },
        {
            heading: "pureencrypt_accordion_two_heading",
            content: "pureencrypt_accordion_two_para",
            open: false,
            img:accordionImage2,
        },
        {
            heading: "pureencrypt_accordion_three_heading",
            content: "pureencrypt_accordion_three_para",
            open: false,
            img:accordionImage3,
        },
        {
            heading: "pureencrypt_accordion_four_heading",
            content: "pureencrypt_accordion_four_para",
            open: false,
            img:accordionImage4,
        },
    ]);

    const buttonDownloadAppFromStore = (device) => {     
        if (device === "windows") {
            window.open(`${constants.pureencryptWindowsDownloadLink}`);
        }
        if (device === "macintel") {
            window.open(`${constants.pureencryptMacIntelSiliconDownloadLink}`);
        }
        if (device === "macapple") {
            window.open(`${constants.pureencryptMacAppleSiliconDownloadLink}`);
        }
    }

    const faqToggle = (index) => {

        let updatedFaqs = []

        faqs.map((faq, idx) => {
            if (idx === index) {
                updatedFaqs.push({
                    ...faq,
                    open: (!faq.open)
                })
            } else {
                updatedFaqs.push({...faq})
            }
        })

        setFaqs(updatedFaqs)
    }

    const listToggle = (index) => {

        let updatedListItems = []

        listItems.map((item, idx) => {
            if (idx === index) {
                setImgState(idx)
                updatedListItems.push({
                    ...item,
                    open: (!item.open),                    
                })
            } else {
                updatedListItems.push({
                    ...item,
                    open: false,
                })
            }
        })

        setListItems(updatedListItems)
    }

    return (
        <NewSignupViewWrapper className='content' bgimg={pureEncryptBg} accordionimg={listItems[imgState].img} nocover accordionbgColour="encrypt"  bgColor="pureencrypt">
            <section className='row section-seven variant-2'>
                <div className='wrapper'>
                    <div className='bg'>
                        <div className="content">
                            <img className="logo" src={pureEncryptLogo} />
                            <h2 className='heading t-white'>{t('pureencrypt_title')}</h2>
                            <p className='para t-white'>{t('pureencrypt_banner_para')}</p>
                            <p className='t-white banner-text'>
                            {t('pureencrypt_banner_para_two')}
                            </p>
                             <div className='button-wrapper'>
                                <PrimaryBtn className='primary-btn' onClick={(e) => buttonClickHandler(e)}>{t('get_started_text')}</PrimaryBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-nine variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('pureencrypt_state_of_art_encryption')}</h2>
                    <div className='row'>
                        <div className="col accordion">
                            {
                                listItems.map((item, idx) => {
                                    return (
                                        <section key={idx}  className={`accordion-item ${ (item.open) ? "open" : "" }`} onClick={() => listToggle(idx)}>
                                            <h3>{t(item?.heading)}</h3>
                                            <div className="accordion-item-content">
                                                <p>{t(item?.content)}</p>
                                            </div>
                                        </section>
                                    )
                                })
                            }
                        </div>
                        <div className='col right-side'>
                            <div className='bg' accordionimg={listItems[imgState].img}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-five variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('how_it_works')}</h2>
                    <p className='para'></p>
                    <div className='row grids'>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon loaded' src={sectionOneIcon1} />
                                <h4>{t('pureencrypt_step_one_login')}</h4>
                                <p>{t('pureencrypt_step_one_login_para')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src={sectionOneIcon2} />
                                <h4>{t('pureencrypt_step_two_create_master_pw')}</h4>
                                <p>{t('pureencrypt_step_two_create_master_pw_para')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src={sectionOneIcon3} />
                                <h4>{t('pureencrypt_step_three_create_vault')}</h4>
                                <p>{t('pureencrypt_step_three_create_vault_para')}</p>
                            </div>
                        </div>
                        <div>
                                <p className='mt-5'>{t('pureencrypt_sync_text')}</p>
                            </div>
                    </div>
                </div>
            </section>
            <section className='row section-eight variant-2'>
                <div className="content">
                    <h5 className='heading'>{t('available_on')}</h5>
                    <div className='button-wrapper'>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('macapple')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/purencrypt-mac-apple.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('macintel')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/purencrypt-mac-intel.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('windows')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/purencrypt-windows.png" /></LinkBtn>
                    </div>
                </div>
            </section>            
            <section className='row faqs variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('faqs_heading')} <span>PureEncrypt</span></h2>
                    {
                        faqs.map((faq, idx) => {
                            return (
                                <div key={idx} className={`item ${ (faq.open) ? "open" : "" }`}>
                                    <div className='question' onClick={() => faqToggle(idx)}>
                                        <p>{t(faq.question)}</p>
                                        <span className='icon'></span>
                                    </div>
                                    <div className='answer'>
                                        <p>{t(faq.answer)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </NewSignupViewWrapper>
    )

}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, { getSubscriptionsRequest })(PureEncryptSignupView)
