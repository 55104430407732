import React, {useEffect} from 'react'
import { AutoLoginWrapper } from '../styles/Generic'
import loginlogo from '../assets/loginlogo.png'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const TestRedirection = ({history}) => {

    const { search } = useLocation();

    const { t, i18n } = useTranslation();

    const {token} = Object.fromEntries(new URLSearchParams(search));

    useEffect(() => {
        localStorage.setItem("token", token)
        history.push("/dashboard/upgrade")
    }, [])

    return (
        <AutoLoginWrapper> 
            <div className="text-center">
                <img src={loginlogo} className="img-fluid" />
                <h1>{t('login_text_member_area')}</h1>
                <p>{t('login_text_opening_member_area_text')}</p>
                <p className="mt-5 mb-3">{t('login_text_please_wait')}</p>
                <span className="spinner-border"></span>
            </div>
        </AutoLoginWrapper>
    )
}

export default TestRedirection