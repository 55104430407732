import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Input, PopupBody } from '../../../styles/dashboard/Main';
import { DestructBtn, PrimaryBtn } from '../../../styles/Generic';
import CloseIconSVG from "../../../assets/svg/CloseIconSVG";
import { InviteStyles } from '../../../styles/dashboard/VolumeLicensing';
import Copy from "../../../assets/copy.png"
import Email from '../../../generic-components/email';
import { ReactComponent as Check } from "../../../assets/payment-success.svg"
import { useState } from 'react';
import { inEligibleUserOnPlanUpgrade } from '../../../tools/constants';
import { withRouter } from 'react-router-dom';

const Invite = ({modal, toggle, modalWidth, widthCalculator, backdrop, copyLink, email, setEmail, onClickInvite, hasSent, onInviteMoreUsers, inviteLink, selectedSubscription, history}) => {
    const [loading, setLoading] = useState(false);
    const renewStates = ['past_due', 'expired']

    const ctaText = loading ? <>Invite <span className="spinner-border text-light"></span></> : 'Invite' ;

    const inviteMembers = () => {
        setLoading(true);
        onClickInvite();
    }

    const onInviteMoreUsersClick = () => {
        setLoading(false);
        onInviteMoreUsers();
    }

    const onClickRenew = () => {
        history.push('/dashboard/subscriptions')
    }

    return <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
           <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>
           {!hasSent ? <>
                <ModalBody className="pb-0 mb-3">
                    <PopupBody>
                        <InviteStyles>
                            {!renewStates.includes(selectedSubscription.state) ? <>
                                <h2>Invite members to join your network</h2>
                                
                                <div className='copy-link link-content'>
                                    <h6>Share this link with your friends, family or colleagues</h6>
                                    <div className='copy-link-box'>
                                        <Input class="referLinkInput w-100" value={inviteLink} disabled/>
                                        <img src={Copy} alt="copy" id={inviteLink} onClick={copyLink} />
                                        
                                    </div>
                                </div>
                                <div className='email-link link-content'>
                                    <h6>Or invite members by email address</h6>
                                    
                                    <div className='email-link-box'>
                                    <Email email={email} setEmail={setEmail}/>
                                    <PrimaryBtn className='invite-btn' disabled={email.items.length > 0 && !email.value ? false : true} onClick={inviteMembers}>{ctaText}</PrimaryBtn>
                                    </div>
                                </div>
                            </> : <div className='m-auto text-center'>
                            <h3 className='mt-4'>{inEligibleUserOnPlanUpgrade['expired'].message}</h3>
                                <div className='mt-4 m-auto'>
                                    <PrimaryBtn onClick={()=>onClickRenew()}>{inEligibleUserOnPlanUpgrade['expired'].cta}</PrimaryBtn>
                                </div>
                            </div>}
                            
                        </InviteStyles>
                    </PopupBody>
                </ModalBody>
                 {/* <ModalFooter>
                    <div className='mt-3'>
                        <DestructBtn onClick={()=>toggle()}>Close</DestructBtn>
                    </div>
                </ModalFooter> */}
            </> : <div className='text-center'>
              <ModalBody className="pb-0">
                    <PopupBody>
                        <InviteStyles>
                          <Check />
                          <h3 className='mt-4'>Invite has been sent</h3>
                        </InviteStyles>
                    </PopupBody>
                </ModalBody>
                 <ModalFooter>
                    <div className='mt-2 m-auto d-flex gap-3'>
                        <PrimaryBtn onClick={()=>onInviteMoreUsersClick()}>Add More Members</PrimaryBtn>
                        {/* <DestructBtn onClick={()=>toggle()}>Close</DestructBtn> */}
                    </div>
                </ModalFooter>
            </div>}
        </Modal>
}
export default withRouter(Invite);