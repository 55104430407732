import { featureContent } from "../content/index";
import { HowItWorksStyles } from "../../../styles/dashboard/security-tools/securityTools";
import { useTranslation } from "react-i18next";

const HowItWorks = ({slug}) => {

    const { t } = useTranslation();

    return <HowItWorksStyles>
        <h3>{t(featureContent[slug]?.how_it_works?.heading)}</h3>
        <div className="step-boxes">
            {featureContent[slug]?.how_it_works?.steps.map((step, index)=>{
                return <div className={`works-step ${step?.count} ${slug}`}>
                    <h5>0{index+1}</h5>
                    <p>{t(step?.content)}</p>
                </div>
            })}
        </div>
    </HowItWorksStyles>
}

export default HowItWorks;