// TEAM MEMBER FUNCTIONS
export const minDisabledForTeamMember = (state) => {
    return !state?.current || state?.current <= state?.min
}
export const maxDisabledForTeamMember = (state) => {
    return state?.current >= state?.max
}
export const getMaximumForTeamMember = (state) => {
    return state.max
}
export const getMinimumForTeamMember = (state) => {
    return state.min
}
export const hasErrorForTeamMember = (state) => {
    return state?.current < state.min || state?.current > state.max
}
export const getValueForTeamMember = (state) => {
    return state?.current
}
export const onChangeCounterForTeamMember = (payload) => {
    let {type, dispatch, value} = payload;
    dispatch({ type: type, value: value });
}
export const isDisabledForTeamMember = (state) => {
    return !state?.current || state?.current < state.min ||
    state?.current > state.max
}
export const getTotalAmountForTeamMember = (state) => {
    return (
        (state?.current ? +state?.current * state?.price: 0)
    )
}