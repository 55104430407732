import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { InfoCard } from '../../../../../styles/dashboard/Main'
import { constants, currencies } from '../../../../../tools/constants'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CouponFieldView } from '../../../../../styles/dashboard/subscription/cancellation/CouponFieldView';
import check from '../../../../../assets/check.svg'
import CouponIcon from '../../../../../assets/coupon.png'
import CouponBlueIcon from '../../../../../assets/couponBlue.png'
import ErrorMsg from './ErrorMsg';
import Skeleton from 'react-loading-skeleton';
import { isManualGateway, isWhmcsUser } from '../../../../../tools/tools';
import { AppliedBox } from '../../../../../styles/AppliedCoupon';


const RenewalCouponField = ({setIsCouponApplied,data,setFirstTimeCouponEntered,isCouponApplied,isError,setIsCouponLoading,isCouponLoading, planType, currency=""}) => {

    const couponCodeRef = useRef();

    const { t } = useTranslation();

    const [isCouponFieldView,setIsCouponFieldVisible] = useState(false)

    const [isDisabled,setIsDisabled] = useState(true)
    
    const isCouponAppliesAndError = isCouponApplied && isError

    const redeemCoupon = () => {
        setIsCouponApplied(couponCodeRef?.current?.value)
        setFirstTimeCouponEntered(prevState=>!prevState)
        setIsCouponLoading(true)
    }

    const removeCoupon = () => {
        setIsCouponApplied(null)
    }

    const viewField = () => {
        setIsCouponFieldVisible(true)
    }
    
    const handleInputChange = () => {
        if(couponCodeRef.current.value.trim().length>0){
            setIsDisabled(false)
        }else{
            setIsDisabled(true)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsCouponApplied(couponCodeRef?.current?.value)
            setFirstTimeCouponEntered(prevState=>!prevState)
            setIsCouponLoading(true)
        }
      };

    const discountAndTotalField = () => {
        return(
            <>
                {isCouponApplied && 
                    <InfoCard hasborder>
                        <div className="float-start">
                            <label><strong className="ms-0">{t('payment_details_discount')}</strong> </label>
                        </div>
                        <div className="float-end">
                            <label><strong>{currency}{data?.discount?.toFixed(2) || "-"}</strong> </label>
                        </div>
                    </InfoCard>
                }
                <InfoCard hasborder>
                    <div className="float-start">
                        <label><strong className="ms-0">{t('upgradeplan_total_amount')}</strong> </label>
                    </div>
                    <div className="float-end">
                    <label className='d-flex'>
                        <strong className={isCouponApplied ? "strike-out me-1" : ""}>{currency}{planType === constants.vpn_max ? isWhmcsUser(data?.account?.billingType) ? data?.total : data?.total_amount_wo_credit?.toFixed(2) : data?.is_credit_applied ? data?.balance_amount : data?.paid?.toFixed(2)}</strong> 
                        {isCouponApplied && <strong className="after-discount">{currency}{ isCouponApplied ? (data?.paid - data?.discount)?.toFixed(2) : data?.paid?.toFixed(2) || "-"}</strong>} 
                        </label>
                    </div>
                </InfoCard>
            </>
        )
    }

    return (
        <>
        {(!constants.manual_gateways.includes(data?.payment_gateway) && planType !== constants.vpn_max && !data?.is_credit_applied) &&
            <InfoCard hasborder noflex>
                <CouponFieldView>                
                    {isCouponLoading ? 
                        <Skeleton height={20} width={300} />
                    : isCouponApplied && !isError? 
                    <AppliedBox>
                        <div>
                            <img src={CouponIcon} />
                        </div>
                        <div className='coupon-applied'>
                            <span>{isCouponApplied}</span>
                            <div>
                            {t('coupon_applied')} <img src={check} className='check-mark' />
                            </div>
                        </div>
                        <div>
                            <Link onClick={removeCoupon} to="#">{t('remove')}</Link>
                        </div>
                    </AppliedBox>
                    : isCouponFieldView || isCouponAppliesAndError ?               
                    <div className='couponField'>
                        <input type="text" ref={couponCodeRef} placeholder="Enter Coupon Code" onChange={handleInputChange} onKeyDown={handleKeyDown} className={isCouponAppliesAndError ? "redField" : ""}/>
                        <button className='addCoupon' onClick={redeemCoupon} disabled={isDisabled}>{t('apply_coupon')}</button>
                    </div> 
                    : <Link className='d-flex flex-grow-1 justify-content-end' onClick={viewField} to="#"><img src={CouponBlueIcon} className='mx-1' />{t('apply_coupon')}</Link>}                  
                </CouponFieldView>
                {isError && <ErrorMsg errorMsg={isError}/>}
            </InfoCard>
        }
        {!isCouponLoading && discountAndTotalField()}
        </>
    )
}

export default RenewalCouponField