import React, {useState, useEffect} from 'react'
import {CardBox, DashboardHead, HelpBox, InfoCard} from '../../../styles/dashboard/Main'
import {PrimaryBtn, Table} from '../../../styles/Generic'
import external from '../../../assets/external.png'
import social from '../../../assets/social.svg'
import CreateTicket from './modal/CreateTicket'
import classNames from 'classnames'
import {connect, useDispatch} from 'react-redux'
import {getTicketsRequested} from '../../../redux/tickets/actions'
import {APIFAILED, INVALIDTOKEN, TOKENEXPIRED} from '../../../tools/responseMessages'
import {toast} from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import ErrorBoundary from '../../ErrorBoundary'
import { useTranslation } from 'react-i18next';

const HelpDesk = ({history, tickets: reduxData, getTicketsRequested}) => {

    const { t, i18n } = useTranslation();

    const [crashed, setCrashed] = useState(false)

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)

    const [newAdded, setnewAdded] = useState(false)

    const token = localStorage.getItem("token")

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [data, setdata] = useState([])

    const [authorId, setAuthorId] = useState("")

    useEffect(() => {
        getTicketsRequested(token)
    }, [])

    useEffect(() => {
        const {tickets, errors, errorType, loading, errorMessage} = reduxData;
        if (tickets && !loading) {
            setdata(tickets?.body || [])
            setAuthorId(tickets?.body[0]?.author_id || "")
            setLoading(false)
        }
        if (errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type: "LOGOUT"})
                    break;
                case "intercom_exception":
                    toast.error(errorMessage);
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type: "LOGOUT"})
                    break;
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type: "LOGOUT"})
                    break;
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type: "LOGOUT"})
                    break;
                case "APIFAILED":
                    setCrashed(true)
                    break;
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [reduxData])

    if (crashed) {
        return <ErrorBoundary></ErrorBoundary>
    }

    const handleClick = (id) => {
        history.push(`/dashboard/ticket/${id}`)
    }
    
    const openChat = () => {
        if(window.Intercom){
            window.Intercom('show')
        }
    }

    const onHelpTileClick = (link) => {
        window.open(link, '_blank')
    }

    return (
        <>
            <DashboardHead>
                <h2>Help Desk</h2>
                <p>{t('helpdesk_title_para')}</p>
            </DashboardHead>
            <HelpBox>
                <div className="help-box text-center" onClick={()=>onHelpTileClick('https://purevpn.com/applinks/member-area/support')}>
                    <h3>{t('helpdesk_support_center')}</h3>
                    <div className="icon"></div>
                    <p>{t('helpdesk_support_center_para')}</p>
                    <img alt="external" src={external}/>
                </div>
                <div className="help-box text-center" onClick={openChat}>
                    <h3>{t('helpdesk_live_chat')}</h3>
                    <div className="icon"></div>
                    <p>{t('helpdesk_live_chat_para')}</p>
                    <img alt="external" src={external}/>
                </div>
                <div className="help-box text-center" onClick={()=>onHelpTileClick('https://purevpn.com/applinks/member-area/ideas')}>
                    <h3>{t('helpdesk_ideas')}</h3>
                    <div className="icon"></div>
                    <p>{t('helpdesk_ideas_para')}</p>
                    <img alt="external" src={external}/>
                </div>
                <div className="help-box text-center" onClick={()=>onHelpTileClick('https://purevpn.com/applinks/member-area/servers')}>
                    <h3>{t('helpdesk_server_location')}</h3>
                    <div className="icon"></div>
                    <p>{t('helpdesk_server_location_para')}</p>
                    <img alt="external" src={external}/>
                </div>
            </HelpBox>
            <div className="table-row">
                <div className="col-md-12 col-lg-9">

                    {
                        !loading
                            ?
                            <>
                                <div>
                                    <h3 className="fw-600">{t('helpdesk_support_tickets')}</h3>
                                    <p className="smallerBody my-3">{t('helpdesk_support_tickets_para')}</p>
                                    <PrimaryBtn onClick={toggle}>{t('helpdesk_create_new_ticket_cta')}</PrimaryBtn>
                                </div>
                                <div
                                    className={classNames({'hasScroll': data.length > 6}, 'table-wrapper', 'mt-5', 'mb-5')}>
                                    <Table className={`table ${!(data.length > 0) ? "no-tickets" : "has-tickets"}`} align="middle" new={newAdded}>
                                        <thead>
                                        <tr>
                                            <th>{t('helpdesk_table_ticket_no')}</th>
                                            <th>{t('helpdesk_table_date')}</th>
                                            <th>{t('helpdesk_table_subject')}</th>
                                            <th>{t('helpdesk_table_status')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.length
                                                ?
                                                data.map((ticket, key) => (
                                                    <tr key={key} className="linkTr"
                                                        onClick={(e) => handleClick(ticket?.id)}>
                                                        <td data-label="Ticket">#{ticket?.id || "-"}</td>
                                                        <td data-label="Date">{ticket?.created_at || "-"}</td>
                                                        <td data-label="Subject">{ticket?.title || "-"}</td>
                                                        <td data-label="Status"
                                                            className="text-capitalize">{ticket?.status}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan="4">{t('helpdesk_table_placeholder')}</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                            :
                            <CardBox>
                                <InfoCard hasborder noflex className="mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard noflex className="pt-0">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                            </CardBox>
                    }

                </div>
            </div>
            {
                modal && <CreateTicket modal={modal} toggle={toggle} setnewAdded={setnewAdded} authorId={authorId}></CreateTicket>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {tickets: state.ticketsReducer};
};

export default connect(mapStateToProps, {getTicketsRequested})(HelpDesk)