import { cancelled, put, takeLatest,} from "redux-saga/effects";
  import { GETWALLETDATA, LOADING, ERRORS, SUCCESSWALLETDATA } from "./types";
  import axios from "axios";
  import { errorsType, getSuccessWalletData, loadingType } from "./action";
  import { APIFAILED } from "../../tools/responseMessages";
  import { get } from "../../tools/tools";
  
  function* handleGetWalletData(action) {
    const cancelTokenSource = axios.CancelToken.source();
    try {
      const walletData = yield fetch("https://gaditek-f2357-default-rtdb.firebaseio.com/credits_svc.json");
      const wallet_data = yield walletData.json()
      const { body: response } = wallet_data;
      if (walletData?.status) {
        yield put(getSuccessWalletData({ walletData: response }));
      } else {
        yield put(
          errorsType({
            code: walletData?.data?.error_code || "APIFAILED",
            message: walletData?.data?.message,
          })
        );
      }
    } catch (err) {
      yield put(errorsType({ code: "APIFAILED", message: APIFAILED }));
    } finally {
      if (yield cancelled()) {
        cancelTokenSource.cancel();
      }
    }
  }
  
  function* watcherWalletDataSaga() {
    yield takeLatest("GETWALLETDATA", handleGetWalletData);
  }
  
  export default watcherWalletDataSaga;
  