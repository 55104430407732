import { DestructBtn, PrimaryBtn } from "../../../../styles/Generic";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PopupBody } from '../../../../styles/dashboard/Main'
import { useState } from "react";
import { useTranslation } from 'react-i18next';

const Removal = ({modal, toggle, modalWidth, widthCalculator, backdrop, removeChildRequest, theme}) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)

    const ctaText = loading ? <>{t('proceed_text')}<span className="spinner-border text-light"></span></> : t('proceed_text') ;

    const onProceed = () => {
        setLoading(true)
        removeChildRequest()
    }

    return <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}}>
            <ModalBody className="pb-0">
                <PopupBody>
                    <div className="mod-head mb-3">
                        <h3 className="text-center" style={{color: theme ? '#001219' : 'auto'}}>{t('family_remove_member')}</h3>
                    </div>
                </PopupBody>
            </ModalBody>
            <ModalFooter className="pt-0 m-auto">
                <PrimaryBtn onClick={onProceed}>{ctaText}</PrimaryBtn>
                <DestructBtn color="secondary" onClick={toggle}>Cancel</DestructBtn>
            </ModalFooter>
        </Modal>
}

export default Removal;