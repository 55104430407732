import { connect } from 'react-redux';
import StreamView from './StreamView';
import GameView from './GameView';

const ThirdPartyTools = ({ pureAIStateReducer, details }) => {
    return (
        details.source === "streaming" ? <StreamView details={details} /> : <GameView details={details} />
    )
}


const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(ThirdPartyTools);