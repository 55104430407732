export const GETONBOARDING = "GETONBOARDING"
export const LOADING = "ONBOARDINGLOADING"
export const ERRORS = "ONBOARDINGERRORS"
export const GETONBOARDINGREQUEST = "GETONBOARDINGREQUEST"
export const SETONBOARDINGDATA = "SETONBOARDINGDATA"
export const SETONBOARDINGHISTORY = "SETONBOARDINGHISTORY"
export const UPDATESELECTEDSUBSCRIPTION = "UPDATESELECTEDSUBSCRIPTION"
export const NEXTONBOARDING = "NEXTONBOARDING"
export const RESETONBOARDINGDATA = "RESETONBOARDINGDATA"
export const UPDATETOFETCH = "UPDATETOFETCH"
export const UPDATELOCALEONBOARDINGDATA = "UPDATELOCALEONBOARDINGDATA"