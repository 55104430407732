
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getSubscriptionsRequest } from '../../../redux/subscriptions/actions'
import purekeepLogo from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-icon.png'
import purekeepBg from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-background1.png'
import sectionOneIcon1 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-icon1.png'
import sectionOneIcon2 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-icon2.png'
import sectionOneIcon3 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-icon3.png'
import accordionImage1 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-never-lose.png'
import accordionImage2 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-generate-password.png'
import accordionImage3 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-organize-your-data.png'
import accordionImage4 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-sync-password.png'
import accordionImage5 from '../../../assets/onboarding-sprites/purekeep/ACSD-31405-purekeep-import.png'
import { NewSignupViewWrapper } from '../../../styles/dashboard/Main'
import { LinkBtn, PrimaryBtn    } from '../../../styles/Generic'
import { constants } from '../../../tools/constants';
import { useTranslation } from 'react-i18next';


const PureKeepSignupView = ({ history, subscriptionData, buttonClickHandler }) => { 

    const { t, i18n } = useTranslation();

    const [imgState,setImgState] = useState(0);

    const [faqs, setFaqs] = useState([
        {
            question: "purekeep_faqs_question_one",
            answer: "purekeep_faqs_question_one_para",
            open: false,
        },
        {
            question: "purekeep_faqs_question_two",
            answer: "purekeep_faqs_question_two_para",
            open: false,
        },
        {
            question: "purekeep_faqs_question_three",
            answer: "purekeep_faqs_question_three_para",
            open: false,
        },
        {
            question: "purekeep_faqs_question_four",
            answer: "purekeep_faqs_question_four_para",
            open: false,
        },
        {
            question: "purekeep_faqs_question_five",
            answer: "purekeep_faqs_question_five_para",
            open: false,
        },
    ]);

    const [listItems, setListItems] = useState([
        {
            heading: "purekeep_accordion_one_heading",
            content: "purekeep_accordion_one_para",
            open: true,
            img:accordionImage1,
        },
        {
            heading: "purekeep_accordion_two_heading",
            content: "purekeep_accordion_two_para",
            open: false,
            img:accordionImage2,
        },
        {
            heading: "purekeep_accordion_three_heading",
            content: "purekeep_accordion_three_para",
            open: false,
            img:accordionImage3,
        },
        {
            heading: "purekeep_accordion_four_heading",
            content: "purekeep_accordion_four_para",
            open: false,
            img:accordionImage4,
        },
        {
            heading: "purekeep_accordion_five_heading",
            content: "purekeep_accordion_five_para",
            open: false,
            img:accordionImage5,
        },
    ]);

    const buttonDownloadAppFromStore = (device) => {     
        if (device === "windows") {
            window.open(`${constants.purekeepWindowsDownloadLink}`);
        }
        if (device === "mac") {
            window.open(`${constants.purekeepMacDownloadLink}`);
        }
        if (device === "web") {
            window.open(`${constants.purekeepWebDownloadLink}`);
        }
    }

    const faqToggle = (index) => {

        let updatedFaqs = []

        faqs.map((faq, idx) => {
            if (idx === index) {
                updatedFaqs.push({
                    ...faq,
                    open: (!faq.open)
                })
            } else {
                updatedFaqs.push({...faq})
            }
        })

        setFaqs(updatedFaqs)
    }

    const listToggle = (index) => {

        let updatedListItems = []

        listItems.map((item, idx) => {
            if (idx === index) {
                setImgState(idx)
                updatedListItems.push({
                    ...item,
                    open: (!item.open),                    
                })
            } else {
                updatedListItems.push({
                    ...item,
                    open: false,
                })
            }
        })

        setListItems(updatedListItems)
    }

    return (
        <NewSignupViewWrapper className='content' bgimg={purekeepBg} accordionimg={listItems[imgState].img} nocover accordionbgColour="keep" bgColor="purekeep">
            <section className='row section-seven variant-2'>
                <div className='wrapper'>
                    <div className='bg'>
                        <div className="content">
                            <img className="logo" src={purekeepLogo} />
                            <h2 className='heading'>{t('purekeep_title')}</h2>
                            <p className='para'>{t('purekeep_banner_para')}
                            </p>
                             <div className='button-wrapper'>
                                <PrimaryBtn className='primary-btn button-white' onClick={(e) => buttonClickHandler(e)}>{t('get_started_text')}</PrimaryBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-nine variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('purekeep_more_than_a_password_manager_heading')}</h2>
                    <div className='row'>
                        <div className="col accordion">
                            {
                                listItems.map((item, idx) => {
                                    return (
                                        <section key={idx}  className={`accordion-item ${ (item.open) ? "open" : "" }`} onClick={() => listToggle(idx)}>
                                            <h3>{t(item?.heading)}</h3>
                                            <div className="accordion-item-content">
                                                <p>{t(item?.content)}</p>
                                            </div>
                                        </section>
                                    )
                                })
                            }
                        </div>
                        <div className='col right-side'>
                            <div className='bg' accordionimg={listItems[imgState].img}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-five variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('how_it_works')}</h2>
                    <p className='para'></p>
                    <div className='row grids'>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon loaded' src={sectionOneIcon1} />
                                <h4>{t('purekeep_step_one_login')}</h4>
                                <p>{t('purekeep_step_one_login_para')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src={sectionOneIcon2} />
                                <h4>{t('purekeep_step_two_create_master_pw')}</h4>
                                <p>{t('purekeep_step_two_create_master_pw_para')}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 grid'>
                            <div className='box'>
                                <img className='icon' src={sectionOneIcon3} />
                                <h4>{t('purekeep_step_three_create_vault')}</h4>
                                <p>{t('purekeep_step_three_create_vault_para')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='row section-eight variant-2'>
                <div className="content">
                    <h5 className='headingSecondary'>{t('purekeep_on_any_device')}</h5>
                    <div className='button-wrapper'>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('windows')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-windows.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('mac')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-macos.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' onClick={(e) => buttonDownloadAppFromStore('web')} target="_blank"><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-webapp.png" /></LinkBtn>
                    </div>
                    <div className='button-wrapper'>
                        <LinkBtn className='secondary-btn'><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-ios.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' ><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-chrome.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' ><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-android.png" /></LinkBtn>
                        <LinkBtn className='secondary-btn' ><img className="button" src="https://images.purevpn-tools.com/public/images/ACSD-31405-purekeep-firefox.png" /></LinkBtn>
                    </div>
                </div>
                <div className='button-wrapper'>
                                <PrimaryBtn className='primary-btn button2 mt-5' onClick={(e) => buttonClickHandler(e)}>{t('purekeep_get_purekeep_text')}</PrimaryBtn>
                            </div>
            </section>            
            <section className='row faqs variant-2'>
                <div className='content'>
                    <h2 className='heading'>{t('faqs_heading')}<span>PureKeep</span></h2>
                    {
                        faqs.map((faq, idx) => {
                            return (
                                <div key={idx} className={`item ${ (faq.open) ? "open" : "" }`}>
                                    <div className='question' onClick={() => faqToggle(idx)}>
                                        <p>{t(faq.question)}</p>
                                        <span className='icon'></span>
                                    </div>
                                    <div className='answer'>
                                        <p>{t(faq.answer)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </NewSignupViewWrapper>
    )

}

const mapStateToProps = (state) => {
    return {
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, { getSubscriptionsRequest })(PureKeepSignupView)
