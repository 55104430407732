export const familyplan = {
    en:{
        heading_1: 'PureVPN’s Family Plan',
        para_1:     'Ensure that your entire family is protected online, just as you are, with only one VPN subscription.',
        heading_2:     'What’s so special about the Family Plan?',
        para_2:     'You can get up to six separate VPN accounts, providing you and your family with unlimited VPN access.',
        desc:[
          '<strong>Secure and personal VPN credential </strong> Every member of the family plan can create their own VPN credentials to ensure that their account is secure and is only used by them.',
          '<strong>Access PureVPN apps on all device </strong> Upgrading to the Family Plan lets you and your family set up their own VPN accounts on up to 20+ devices, at the same time.',
          '<strong>Better value for money </strong> With the Family Plan, you get unlimited VPN access for five additional members at the price of three.'
        ],
        cta:"Upgrade to Family Plan",
    },
    de:{
      heading_1:'Familien-Tarif von PureVPN',
      para_1:'Stellen Sie mit nur einem VPN-Abonnement sicher, dass Ihre gesamte Familie genauso online geschützt ist wie Sie selbst.',
      heading_2:'Was ist das Besondere am Familientarif?',
      para_2:'Sie können bis zu sechs separate VPN-Konten einrichten, die Ihnen und Ihrer Familie unbegrenzten VPN-Zugang bieten.',
      desc:[
        '<strong> Sichere und persönliche VPN-Anmeldeinformationen </strong> Jedes Mitglied des Familienplans kann seine eigenen VPN-Anmeldeinformationen erstellen, um sicherzustellen, dass sein Konto sicher ist und nur von ihm verwendet wird.',
        '<strong> Zugriff auf PureVPN-Apps auf allen Geräten </strong> Mit einem Upgrade auf den Familienplan können Sie und Ihre Familie ihre eigenen VPN-Konten auf bis zu 20+ Geräten gleichzeitig einrichten.',
        '<strong> Besseres Preis-Leistungs-Verhältnis </strong> Mit dem Familienplan erhalten Sie unbegrenzten VPN-Zugang für fünf zusätzliche Mitglieder zum Preis von drei.'
      ],
      cta:"Upgrade auf den Familienplan",
    },
    fr:{
      heading_1:"Forfait familial de PureVPN",
      para_1:"Assurez-vous que toute votre famille est protégée en ligne, tout comme vous, avec un seul abonnement VPN.",
      heading_2:"Qu'y a-t-il de si spécial dans le Plan Famille ?",
      para_2:"Vous pouvez obtenir jusqu'à six comptes VPN distincts, vous offrant ainsi qu'à votre famille un accès VPN illimité.",
      desc:[
        `<strong>Identifiant VPN personnel et sécurisé  </strong>Chaque membre du plan familial peut créer ses propres identifiants VPN pour s'assurer que son compte est sécurisé et n'est utilisé que par lui.`,
        `<strong>Accédez aux applications PureVPN sur tous les appareils  </strong> La mise à niveau vers le plan familial vous permet, à vous et à votre famille, de configurer leurs propres comptes VPN sur un maximum de 20 appareils, en même temps.`,
        `<strong>Meilleur rapport qualité-prix </strong> Avec le forfait famille, vous bénéficiez d'un accès VPN illimité pour cinq membres supplémentaires au prix de trois.`,

      ],
      cta:"Passer au forfait familial",
    },
    zh:{
      heading_1:'PureVPN 的家庭方案',
      para_1:'確保您的整個家庭都像您一樣在線受到保護，只需訂閱一個 VPN。',
      heading_2:'家庭方案有什麼特殊？',
      para_2:'您可以獲得六個獨立的 VPN 賬號,為您和您的家人提供無限制的 VPN 訪問。',
      desc:[
        '<strong> 安全的個人 VPN 憑據 </strong> 家庭方案的每個成員都可以創建自己的 VPN 憑據，以確保他們的賬號安全且僅供他們使用。',
        '<strong> 在所有裝置上訪問 PureVPN 應用程式 </strong> 升級到家庭方案後，您和您的家人可以同時在多達 20 多台裝置上設置自己的 VPN 賬號。',
        '<strong> 更好的性價比 </strong> 使用家庭方案，您可以以三名成員的價格獲得五名額外成員的無限制 VPN 訪問。' 
      ],
      cta:"升级到家庭计划",
    },
    es:{
      heading_1:'Plan familiar de PureVPN',
      para_1:'Asegúrese de que toda su familia esté protegida en línea, tal como está, con solo una suscripción de VPN.',
      heading_2:'¿Qué tiene de especial el plan familiar?',
      para_2:'Puede obtener hasta seis cuentas VPN separadas, que le brinda a usted y a su familia acceso a VPN ilimitado.',
      desc:[
        '<strong> Credencial de VPN seguro y personal</strong> cada miembro del plan familiar puede crear sus propias credenciales de VPN para garantizar que su cuenta sea segura y que solo lo usen.',
        '<strong> Access PureVPN Aplicaciones en todos los dispositivos</strong> la actualización al plan familiar le permite a usted y a su familia configurar sus propias cuentas VPN en hasta 20+ dispositivos, al mismo tiempo.',
        '<strong> Mejor valor para Mone</strong> Con el plan familiar, obtienes acceso VPN ilimitado para cinco miembros adicionales al precio de tres.', 
      ],
      cta:"Actualizar al plan familiar",
    },
    ru:{
      heading_1: 'Семейный план Purevpn',
      para_1:'Убедитесь, что вся ваша семья защищена онлайн, как и вы, с одной подпиской VPN.',
      heading_2:'Что такого особенного в семейном плане?',
      para_2:'Вы можете получить до шести отдельных учетных записей VPN, предоставив вам и вашей семье неограниченный доступ VPN.',
      desc:[
        '<strong> Безопасные и личные учетные данные VPN</strong> каждый член семейного плана может создать свои собственные учетные данные VPN, чтобы гарантировать, что их учетная запись безопасна и используется только ими.',
        '<strong> Доступ к приложениям PureVPN на всех устройствах</strong> обновление до семейного плана позволяет вам и вашей семье настраивать свои собственные учетные записи VPN на устройствах до 20+.',
        '<strong> Лучшая ценность для mone</strong> с семейным планом вы получаете неограниченный доступ VPN для пяти дополнительных членов по цене трех.' 
      ],
      cta:"Обновление до семейного плана",
    },
    tr:{
      heading_1:'PureVPN’nin Aile Planı',
      para_1:'Sadece bir VPN aboneliği ile tüm ailenizin çevrimiçi olarak korunduğundan emin olun.',
      heading_2:'Aile planı hakkında bu kadar özel olan nedir?',
      para_2:'Size ve ailenize sınırsız VPN erişimi sağlayan altı ayrı VPN hesabı alabilirsiniz.',
      desc:[
        '<strong> Güvenli ve kişisel VPN kimlik bilgisi</strong> Aile planının her üyesi, hesaplarının güvenli olmasını ve yalnızca onlar tarafından kullanıldığından emin olmak için kendi VPN kimlik bilgilerini oluşturabilir.',
        '<strong> Tüm cihazdaki PureVPN uygulamalarına erişin</strong> Aile planına yükseltme, siz ve ailenizin aynı anda 20\'den fazla cihazda kendi VPN hesaplarını kurmasını sağlar.',
        '<strong> Mone için daha iyi değer</strong> Aile planı ile, üç fiyata beş ek üye için sınırsız VPN erişimi elde edersiniz.' 
      ],
      cta:"Aile Planına Yükseltme",
    },
    pt:{
      heading_1:'Plano de família de PureVPN',
      para_1:'Certifique-se de que toda a sua família esteja protegida on-line, assim como você é, com apenas uma assinatura da VPN.',
      heading_2:'O que há de tão especial no plano da família?',
      para_2:'Você pode obter até seis contas VPN separadas, fornecendo a você e sua família acesso ilimitado à VPN.',
      desc:[
        '<strong> Credencial VPN seguro e pessoal</strong> todos os membros do plano familiar podem criar suas próprias credenciais de VPN para garantir que sua conta seja segura e seja usada apenas por eles.',
        '<strong> Acesse os aplicativos PureVPN em todos os dispositivos</strong> a atualização para o Plano da Família permite que você e sua família configurem suas próprias contas VPN em até 20 dispositivos, ao mesmo tempo.',
        '<strong> Melhor valor para a MONE</strong> Com o plano familiar, você obtém acesso ilimitado à VPN para cinco membros adicionais ao preço de três.'
      ],
      cta:"Atualizar para o plano familiar",
    },
    ja:{
      heading_1:'PureVPNの家族計画',
      para_1:'ご家族全員がオンラインで保護されていることを確認してください。',
      heading_2:'家族計画の何がそんなに特別なのですか？',
      para_2:'最大6つの個別のVPNアカウントを取得し、あなたとご家族に無制限のVPNアクセスを提供できます。',
      desc:[
        '<strong> 安全で個人的なVPN資格情報 </strong> 家族計画のすべてのメンバーは、独自のVPN資格情報を作成して、アカウントが安全であることを確認できます。',
        '<strong> すべてのデバイスでPureVPNアプリにアクセスする </strong> ファミリープランにアップグレードすることで、あなたとあなたの家族は同時に最大20以上のデバイスで独自のVPNアカウントを設定することができます。',
        '<strong>  Moneのより良い価値 </strong> 家族計画では、3人の価格で5人の追加メンバーの無制限のVPNアクセスを取得します。'
      ],
      cta:"家族計画にアップグレードします",
    },
    it:{
      heading_1:'Piano familiare di PureVPN',
      para_1:'Assicurati che tutta la tua famiglia sia protetta online, proprio come sei, con un solo abbonamento VPN.',
      heading_2:'Cosa c\'è di così speciale nel piano familiare?',
      para_2:'Puoi ottenere fino a sei account VPN separati, fornendo a te e alla tua famiglia un accesso VPN illimitato.',
      desc:[
        '<strong> Credenziali VPN sicure e personali</strong> Ogni membro del piano familiare può creare le proprie credenziali VPN per garantire che il loro account sia sicuro e sia utilizzato solo da loro.',
        '<strong> Accedi alle app PureVPN su tutti i dispositivi</strong> L\'aggiornamento al piano familiare consente a te e alla tua famiglia di impostare i propri account VPN su un massimo di 20 più dispositivi, allo stesso tempo.',
        '<strong> Migliore valore per Mone</strong> Con il piano familiare, ottieni un accesso VPN illimitato per cinque membri aggiuntivi al prezzo di tre.'
      ],
      cta:"Aggiorna al piano familiare",
    },
    nl:{
      heading_1:'PureVPN\'s familieplan',
      para_1:'Zorg ervoor dat uw hele gezin online wordt beschermd, net zoals u bent, met slechts één VPN -abonnement.',
      heading_2:'Wat is er zo speciaal aan het familieplan?',
      para_2:'U kunt maximaal zes afzonderlijke VPN-accounts krijgen, waardoor u en uw gezin onbeperkt VPN -toegang krijgen.',
      desc:[
        '<strong> Secure and Personal VPN</strong>referentie- Elk lid van het familieplan kan zijn eigen VPN-referenties maken om ervoor te zorgen dat hun account veilig is en alleen door hen wordt gebruikt.',
        '<strong> Toegang tot PUREVPN</strong>apps op al het apparaat-Upgraden naar het familieplan kunt u en uw gezin tegelijkertijd hun eigen VPN-accounts opzetten op maximaal 20+ apparaten.',
        '<strong> Betere waarde voor Mone</strong> Met het familieplan krijgt u onbeperkte VPN-toegang voor vijf extra leden voor de prijs van drie.' 
      ],
      cta:"Upgrade naar familieplan",
    },
    ko:{
      heading_1:'PureVPN의 가족 계획',
      para_1:'VPN 구독이 하나만있는 것처럼 온라인 가족이 온라인으로 보호되는지 확인하십시오.',
      heading_2:'가족 계획의 특별한 점은 무엇입니까?',
      para_2:'최대 6 개의 개별 VPN 계정을 얻을 수있어 귀하와 귀하의 가족에게 무제한 VPN 액세스 권한을 제공합니다.',
      desc:[
        '<strong> 안전하고 개인적인 VPN 자격 증명</strong> 가족 계획의 모든 구성원은 자신의 VPN 자격 증명을 만들어 계정이 안전하고 그들에 의해서만 사용되도록 할 수 있습니다.',
        '<strong> 모든 장치에서 PureVPN 앱에 액세스</strong> 가족 계획으로 업그레이드하면 귀하와 귀하의 가족은 동시에 최대 20 개 이상의 장치에서 자체 VPN 계정을 설정할 수 있습니다.',
        '<strong>Mone의 가치가 향상</strong> 가족 계획을 사용하면 3 명의 가격으로 5 명의 추가 회원에 대한 무제한 VPN 액세스를받습니다.' 
      ],
      cta:"가족 계획으로 업그레이드",
    },
  }