import { ExperiencesStyles } from "../../../styles/dashboard/VolumeLicensing";

const Experiences = ({image, heading, paragraph}) => {
    return <ExperiencesStyles>
        <img src={image} alt={heading}></img>
        <h5>{heading}</h5>
        <p>{paragraph}</p>
    </ExperiencesStyles>
}

export default Experiences;