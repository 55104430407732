import React, { useState } from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { isAddon, selectSub } from '../../../../tools/tools';
import AddonPurchase from '../../subscriptions/modals/AddonPurchase';
import { checkAddOn } from '../../subscriptions/tools/SubscriptionTools';
import EarnRedeemLoader from '../partial/EarnRedeemLoader';
import { toast } from 'react-toastify';
import { addonName } from '../../../../tools/constants';

const AddonModal = ({setOpenModal=()=>{}, openModal=false, couponApplied=null,selectedSubscription,addon=""}) => {
    const toggle = () => setOpenModal(!addon)
    const [addonType, setaddonType] = useState({
        type:addon,
        subscription:selectedSubscription?.id,
        currency:selectedSubscription?.currency,
        plan:selectedSubscription?.plan?.code,
        gateway:selectedSubscription?.payment_gateway,
        number:isAddon(selectedSubscription?.add_ons)?.number ?? null
    })

    const [orderSummary, setorderSummary] = useState(true)

    return (
        addonType?.type?.length ? 
        <AddonPurchase backdrop={false} addonType={addonType} modalWidth={{lg: "675px",sm: "440px"}} 
            widthCalculator={"lg"} modal={addon} toggle={toggle} setorderSummary={setorderSummary} 
            orderSummary={orderSummary} onPageClose={true} getDataFromChild={()=>{}} couponApplied={couponApplied} />
        :
        <Modal backdrop={false} addonType={addonType} modalWidth={{lg: "675px",sm: "440px"}} widthCalculator={"lg"} modal={true}>
            <EarnRedeemLoader/>
        </Modal>
    )
}

export default AddonModal