import { Button, Textarea, QRcodeGenerator } from "./index"

const CancellationGenericOptions = ({options, onSelectingAnOption, onOptionButtonClick, showQrCode, showTextarea, emptyFieldError, commentChangeHandler}) => {
    
    return <ul>
        {options && options.map((option)=>{
            return <li>
                    <div 
                        className={`${option.image_url ? 'image-options ' : null} ${option.image_url && option.options_cta.length !== 0? 'no-hover ' : null} options ${option?.is_hide ? 'hide' : 'show'}`} 
                        onClick={option.options_cta.length === 0 ? ()=>onSelectingAnOption({name: option?.name, action: option?.action, relatedQuestionId: option?.related_question_id, optionId: option?.option_id, value: option?.value}) : null}
                    >
                        {option.image_url && <figure>
                            <img src={option.image_url ?? '/v2/static/media/logo_white_new.e621eef6.svg'} alt={option.text} width={100} height={40}/>
                        </figure>}

                        <div>
                            {option?.name ? <h6>{option?.name}</h6> : null}
                            {option?.description ? <p>{option?.description}</p> : null}
                            <div style={option?.description && option.options_cta.length ? {marginTop: '15px'}: {marginTop: '5px'}}>
                                {option.options_cta.length > 0 ? 
                                option.options_cta.map((btn)=>{
                                    return <>                                
                                        {showQrCode && btn?.generate_qr_code ? 
                                            <div className="qr-box">
                                                <span className="arrow"></span>
                                                <span className="arrow-outline"></span>
                                                <span className="qr-heading">Scan QR code</span>
                                                <span className="qr"><QRcodeGenerator value={btn?.redirection_url}/></span>
                                            </div> :
                                        null} 
                                        <Button className={`${btn?.style} primary-btn`} onClick={()=>onOptionButtonClick({btn, option})}>
                                            {btn?.text}
                                        </Button>
                                    </>
                                }) : 
                                null}
                            </div>
                        </div>
                    </div>
                    {option.name === showTextarea?.option && showTextarea?.show ? <Textarea className={emptyFieldError ? 'error' : null} placeholder={"Could you please specify the reason why you don't want to continue with us"} height={'102px'} onChange={commentChangeHandler}/> : null}
            </li>
        })}
    </ul>
}
export default CancellationGenericOptions;