import styled, { css } from "styled-components";

export const PureTeamsStyles = styled.div`
    button.teams{
        width: 220px;
        border-radius: 30px;
        border: 1px solid ${({theme:{teams:{colors: {success}}}})=>success}; 
        color: ${({theme:{teams:{colors: {white}}}})=>white};
        text-align: center;
        font-size: ${({theme:{teams:{typography: {body}}}})=>body};
        font-weight: 600;
        line-height: 16px;
        background: ${({theme:{teams:{colors: {success}}}})=>success};
        &.secondary{
            background: ${({theme:{teams:{colors: {white}}}})=>white};
            color: ${({theme:{teams:{colors: {success}}}})=>success};
        }
        &.fill:hover{
          background: ${({theme:{teams:{colors: {success}}}})=>success};
          color: ${({theme:{teams:{colors: {white}}}})=>white};
        }
    }
    .enterprise-section {
        display: flex;
        gap: 48px;
        margin: 32px auto auto auto;
        padding: 48px 48px;
        border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        border-radius: 24px;
        margin-top: 80px;

        &.has-width{
          max-width: 1200px;
        }

        @media only screen and (max-width: 587px) {
          padding: 28px 28px;
        }

        .enterprise-info {
            h4 {
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
                font-weight: 700;
                line-height: 20px;
            }
            p {
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: ${({theme:{teams:{typography: {p}}}})=>p};
                font-weight: 400;
                line-height: 20px;
            }
          display: flex;
          flex-direction: column;
          .enterprise-action {
            margin-top: auto
          }
        }
        @media only screen and (max-width: 587px) {
          flex-direction: column;
          text-align: center;
          img {
            position: relative;
            width: 200px !important;
            margin: auto;
          }
        }
      }
`