import { LOADING, GETTEAMPLANS, GETTEAMPLANSRESET, ERRORS } from "./types";

const initialState = {
    planInfo: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:"",
    resetState:false,
}

export const pureTeamsReducer = (state=initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case LOADING:
            return {...state, loading:true}
        case GETTEAMPLANS:
            return  {...state, planInfo:payload, loading:false, errors:false, resetState: false}
        case GETTEAMPLANSRESET:
            return  {...state, planInfo:null, loading:false, errors:false, resetState: true}
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false, resetState: false}    
        default:
            return  {...state}
    }
}