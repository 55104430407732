import { ERRORS, GETREFERS, GETREFERREQUEST, LOADING } from "./types"

export const getReferRequested = (payload) => {
    return{
        type:GETREFERREQUEST,
        payload
    }
}

export const getReferType = (payload) => {
    return{
        type:GETREFERS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}
