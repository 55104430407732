import { useEffect } from "react";
import { PrimaryBtn } from "../../../styles/Generic";
import { PlanTypeUpgradeCardStyles } from "../../../styles/dashboard/PlanTypeUpgrade";
import { planTypeCardContent, bundleTypeNaming, mapFeatureToProduct, bundleType } from "../../../tools/constants";
import { currencySymbol } from "../../../tools/tools";
import { useTranslation } from 'react-i18next'


const PlanTypeUpgradeCard = ({
    className,
    getProductType,
    planInfo,
    selectedPlanType,
    selectedSubscription,
    setSelectedPlanType,
    showPlanPreview,
    toggleInfoModal,
    type,
}) => {

    useEffect(()=>{
        setSelectedPlanType(getProductType()?.includes('vpn_max') ? bundleType['max'] : getProductType()?.includes('vpn_plus') ? bundleType['plus'] : 'all')
    },[selectedSubscription]);

    const { t, i18n } = useTranslation();
    
    let plantype = planInfo?.plan_type?.split('_')?.[1];

    const changePlanType = (plan) => {
        if(plan !== selectedPlanType && !planInfo?.current){
            setSelectedPlanType(plan)
        }
    }

    return <PlanTypeUpgradeCardStyles>
        <div className={`${selectedPlanType === type ? 'd-none' : planInfo?.current ? 'std' : null} overlay`} onClick={()=>changePlanType(type)}></div>

        <div className={`${className} ${selectedPlanType === type ? 'selected' : null} plan-card`}>
            <div className="head">
                {(planInfo?.current || plantype === bundleType['max']) && <span className="title">{planInfo?.current ? t('securitysuite_current_plan') : plantype === bundleType['max'] ? t('securitysuite_most_recommended') : null}</span>}

                <h2>{bundleTypeNaming[plantype]}</h2>

                <h5 className="text-capitalize">{`${planInfo?.interval_length} ${planInfo?.interval_length == 1 ? t('upgradeplan_month') : planInfo?.interval_unit } ${t('securitysuite_plan_text')}`}</h5>

                {plantype !== bundleType['standard'] && planInfo?.currency?.discount_percentage ? <h4>{`${planInfo?.currency?.discount_percentage}% ${t('upgradeplan_off_text')}`}</h4> : null}

                {plantype !== bundleType['standard'] && planInfo?.currency?.actual_price ? <span className="discounted">{`${currencySymbol[planInfo?.currency?.currency_code]} ${(planInfo?.currency?.actual_price/planInfo?.interval_length).toFixed(2)}`} /mo</span> : null}

                <h3 className={plantype === bundleType['standard'] || (!planInfo?.currency?.discount_percentage && !planInfo?.currency?.actual_price)? 'mt-5' : null}><span className="super">{currencySymbol[planInfo?.currency?.currency_code] ?? '-'}</span>{(planInfo?.currency && (planInfo?.currency?.price/planInfo?.interval_length).toFixed(2)) ?? '--'}<span className="sub">{t('securitysuite_per_month')}</span></h3>
            </div>

            {!planInfo?.current && <div className="cta">
                <PrimaryBtn type="button" className="primary-btn" disabled={selectedPlanType !== type} isPlanType={true} isSelected={selectedPlanType === type} onClick={()=>showPlanPreview(planInfo)}>{`${t('upgradeplan_upgradeto_cta_text')} ${bundleTypeNaming[plantype]}`}</PrimaryBtn>
            </div>}

            <div className="content">
                <h6>{`${t('securitysuite_whats_included')} ${bundleTypeNaming[plantype]}?`}</h6>
                <ul>
                   {planTypeCardContent[type].map((content)=>{
                        return <li>
                            {t(content)}
                            <span className="info" onClick={()=>toggleInfoModal(mapFeatureToProduct[content])}></span>
                        </li>
                   })}
                </ul>
                
            </div>
        </div>

    </PlanTypeUpgradeCardStyles>
};

export default PlanTypeUpgradeCard;