import React from 'react'
import { useState } from 'react'
import { lazy } from 'react'
import { Suspense } from 'react'
import { PrimaryBtn, SecHead } from '../styles/Generic'
import { LoginBody, LoginWrapper } from '../styles/login/LoginStyle'
import loginlogo from '../assets/loginlogo.png'
import { useParams } from 'react-router'
const V3Recaptcha = lazy(()=>import(/* webpackChunkName: "v3Recaptcha" */"../tools/v3Recaptcha"))
const V2Recaptcha = lazy(()=>import(/* webpackChunkName: "v2Recaptcha" */"../tools/v2Recaptcha"))

const CaptchaRoute = () => {

    const {id} = useParams

    const [data, setdata] = useState({
        type:"",
        token:""
    })

    const [resetCaptcha, setresetCaptcha] = useState(false)

    const [v3regenerate, setv3regenerate] = useState(false)

    const handleVerifyv3 = (token) => {
        setdata({
            type:"v3",
            token:token
        })
    }

    const handleVerify = (token) => {
        setdata({
            type:token ? "v2" : "",
            token:token ?? ""
        })
    }

    const generateV3 = async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_V3_SITE_KEY, { action: id })
        setdata({
            type:"v3",
            token:token
        })
    }

    const captchaReset = () => {
        setresetCaptcha(true)
        setdata({
            type:"",
            token:""
        })
    }

    return (
        <LoginWrapper>
            <LoginBody style={{maxWidth:"900px", width:"900px"}}>
                <SecHead className="text-center">
                    <img src={loginlogo} className="img-responsive" alt="PureVPN Logo"/>
                    <h1>Captcha Generator</h1>
                    <p>A few clicks away from customizing your VPN user experience.</p>
                </SecHead>
                <br/>
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <ul className="list-unstyled">
                            <li className="pb-3">
                                <PrimaryBtn onClick={generateV3}>Generate v3</PrimaryBtn>
                                <Suspense fallback="">
                                    <V3Recaptcha v3regenerate={v3regenerate} setv3regenerate={setv3regenerate} handleVerify={handleVerifyv3}></V3Recaptcha>
                                </Suspense>
                            </li>
                            <li>
                                <Suspense fallback={<div className="text-center p-2"><span className="spinner-border"></span></div>}>
                                    <V2Recaptcha setresetCaptcha={setresetCaptcha} reset={resetCaptcha} handleVerify={handleVerify}></V2Recaptcha>
                                </Suspense>
                                <PrimaryBtn className="mt-3" onClick={captchaReset}>Resst v2</PrimaryBtn>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <label className="pb-2">Token Generated for: {data.type}</label>
                        <textarea name="" id="" style={{height:"300px"}} className="form-control" readOnly value={data.token} handleChange={(e) => setdata({...data, token:e.target.value})}></textarea>
                    </div>    
                </div>
            </LoginBody>
        </LoginWrapper>
    )
}

export default CaptchaRoute