import styled from "styled-components";

export const CouponFieldView = styled.div`
color: #7B648C !important;
font-size: 12px !important;
.haveCouponText{
    font-size: 14px;
    cursor: pointer;
}
label{
    font-size: 14px;
}
strong{
    color:#43225B;
    margin-left: 5px;
}
span{
    text-decoration: underline;
}
input{
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
}
.addCoupon{
    border:1px solid #2CDC82;
    padding: 8px;
    border-radius: 10px;
    color: #fff;
    background-color: #2CDC82;
}
.addCoupon:hover{
    background-color: #1fb367;
    border-color: #1fb367;
    cursor: pointer;
    color: #fff;
}
.couponField{
    display: flex;
    input{
        flex: 1;
        margin-right: 10px;
        border-radius: 5px;
    }
    button{
        margin: 0px;
        border-radius: 5px;
    }
    button:disabled{
        box-shadow: none;
        cursor: not-allowed;
    }
    .redField{
        border-color: red;
    }
    .redField:focus{
        border-color: green;
    }
    a{
        flex: 0;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
`