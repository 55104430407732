import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { get, getTokenFromLocalStorage } from '../../../../tools/tools';

const useCustomerHistory = () => {
  const [customerHistory, setCustomerHistory] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [historyError, setHistoryError] = useState(null);
  const [tglCustHistory,setTglCustHist] = useState(false)
  const Loader = <Skeleton height={15} width={40} className="gradient-style"/>
  const [pointsInfo, setPointsInfo] = useState({
    points_earned:Loader,
    points_spent:Loader,
  });

  useEffect(() => {
    async function fetchCustomerHistory() {
      try {
        setPointsInfo({
          points_earned:Loader,
          points_spent:Loader
        });
        setHistoryLoading(true)
        const customerHistoryApi = await get("loyalty/customer", getTokenFromLocalStorage());
        const customerHistoryData  = customerHistoryApi?.data;

        if (customerHistoryData?.body?.transactions) {
          setCustomerHistory(customerHistoryData?.body);
        }
        if (customerHistoryData?.body?.balance) {
          setPointsInfo(
            {
              points_spent:  customerHistoryData?.body?.balance?.totalPositivePoints ? customerHistoryData?.body?.balance?.totalPositivePoints : 0,
              points_earned:  customerHistoryData?.body?.balance?.pointsBalance ? customerHistoryData?.body?.balance?.pointsBalance : 0 
            }
          )
        } else {
          setPointsInfo({
            points_earned: 0,
            points_spent: 0
          })
          if (customerHistoryData?.errors?.player !== 404) setHistoryError(customerHistoryData?.message);
        }
      } catch (error) {
        setHistoryError("An error occurred while fetching customer history.");
      }
      setHistoryLoading(false);
    }

    fetchCustomerHistory();
    // To re run the effect again when user faces an error and request for Try again
  }, [tglCustHistory]);
  return { customerHistory, historyLoading, historyError,pointsInfo,setTglCustHist };
};

export default useCustomerHistory;
