import { theme } from "../../../../styles/Theme";

export const customStyles = {
  noData: {
      style: {
          borderWidth: '0 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: theme.teams.colors.border,
          borderRadius: '0 0 16px 16px',
      },
  },
  progress: {
    style: {
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.teams.colors.border,
      borderRadius: '0 0 16px 16px'
    },
  },
};
