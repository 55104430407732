import styled from "styled-components";

export const FAQStyles = styled.div `
    max-width: 1146px;
    margin: 90px auto 40px;
    h2{
        font-weight: 700;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        color: #43225B !important;
        margin:0;
        margin-bottom: 30px;

        @media only screen and (max-width: 1280px){
            font-size: 28px;
            line-height: 36px;
        }

        @media only screen and (max-width: 600px){
            font-size: 20px !important;
            line-height: 24px;
        }
    }
    .faqs {
        justify-content: center;
    
        &.dark{
          h2{
            color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
          }
          .question{
            p{
              color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
            }
          }
          .answer{
            p{
              color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
            }
          }
          .item{
            border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border} !important;

          }
        }
        .content {
          max-width: 912px;
        }
    
        .heading {
          display: flex;
          flex-direction: column;
          font-size: 18px !important;
          font-weight: 600 !important;
          color: #43225B  !important;
          margin-bottom: 30px !important;
    
          span {
            font-size: 36px !important;
            font-weight: 700 !important;
            color: #43225B  !important;
          }
        }
    
        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 30px;
          gap: 10px;
          background: #FFFFFF;
          border: 1px solid #DDD4E2;
          border-radius: 12px;
          margin-bottom: 15px;
    
          &:last-child {
            margin-bottom: 0px;
          }
    
          &.open {
            .icon {
              cursor: pointer;
              &:before{ transform: rotate(90deg); }
              &:after{ transform: rotate(180deg); }
            }
            .answer {
              display: block;

              a{
                cursor:pointer;
                text-decoration:underline;
                font-size:14px !important;
              }
            }
          }

          .answer{
            p{
                margin: 0;
            }
            &.close{
                display: none;
            }
          }
    
          .question {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
    
            &:hover {
              cursor: pointer;
            }
    
            p {
              font-weight: 700;
              font-size: 16px !important;
              line-height: 125%;
              color: #43225B;
              margin-bottom:0;
              max-width:770px;
            }
    
            .icon {
              position: relative;
              width: 20px;
              height: 20px;
              flex-shrink: 0;
    
              &:before,
              &:after{
                content: "";
                position: absolute;
                background-color: #8A8398;
                transition: transform 0.25s ease-out;
              }
    
              &:before{
                top: 0;
                left: 50%;
                width: 2px;
                height: 100%;
                margin-left: -1px;
              }
    
              &:after{
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                margin-top: -1px;
              }
              
              &:hover{
                cursor: pointer;
              }
            }
        }
`