import { PrimaryBtn } from '../../../../styles/Generic';
import { CheckoutLicensesStyles } from '../../../../styles/dashboard/pure-teams/checkoutLicenses';
import { currencySymbol } from '../../../../tools/tools';
import { Input, PopupBody } from '../../../../styles/dashboard/Main';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Info from '../../../../assets/info.svg';
import { addonConstants, addonNames, pureTeamsPostPurchaseStates } from '../../../../tools/constants';
import Counter from '../layout/counter';
import HasDedicatedIpView from '../layout/hasDedicatedIpView';

const AddMoreAddons = ({
    type, 
    state, 
    dispatch, 
    selectedSubscriptionData, 
    toggle, 
    modal, 
    modalWidth, 
    openInfoModal, 
    proceedToAddonPurchase,
    minDisabled,
    maxDisabled,
    onChangeCounter,
    getMinimum,
    getMaximum,
    hasError,
    getValue,
    onTotalAmount,
    onDisableCta
}) => {


    const getHeaderForCounter = (state) => {
        return (
            <>
                <li>
                    <h5>{pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.heading}<img width={16} height={16} className='info-img' src={Info} alt="info" onClick={()=>{toggle(); openInfoModal(type)}}/></h5>
                    <strong><h5>{`${currencySymbol[selectedSubscriptionData?.currency]}${(state?.current ? state?.price*state?.current*selectedSubscriptionData?.plan?.interval_length : 0).toFixed(2)}`}</h5></strong>
                </li>
                <li>
                    <p>{pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.checkout_liner}</p>
                    {state?.price && <strong><p>{`${currencySymbol[selectedSubscriptionData?.currency]}${(state?.price)}/user/mo`}</p></strong>}
                </li>
            </>
        )
    }

    return (
        <Modal backdrop={false} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth['lg']}`, width: '100%'}} className={`modal-wrapper`} scrollable>
        <>
            <ModalBody className="p-2">
                <PopupBody>
                    <CheckoutLicensesStyles>
                        <PopupBody className='checkout-body'>
                            <h4>{`Add more ${addonNames[type]}`}</h4>
                            <section className='counter-box'>
                                
                                <div className='addon-box'>
                                    <ul>
                                        {getHeaderForCounter(state)}
                                    </ul>

                                    <Counter 
                                        minDisabled={minDisabled}
                                        maxDisabled={maxDisabled}
                                        onChangeCounter={onChangeCounter}
                                        getMinimum={getMinimum} 
                                        getMaximum={getMaximum}
                                        hasError={hasError}
                                        getValue={getValue}
                                        state={state}
                                        dispatch={dispatch}
                                        type={type}
                                        hideLiner={true}
                                    />

                                </div>

                                {/* TOTAL */}
                                <div className='total'>
                                    <h5>Total Amount</h5>
                                    <h5>{`${currencySymbol[selectedSubscriptionData?.currency]}${(onTotalAmount(state)*selectedSubscriptionData?.plan?.interval_length)?.toFixed(2)}`}</h5>
                                </div>
                            </section>
                        </PopupBody>
                        <ModalFooter className='p-0'>
                            <PrimaryBtn className='teams' disabled={onDisableCta(state)} onClick={()=>proceedToAddonPurchase()}>Upgrade Now</PrimaryBtn>
                            <PrimaryBtn className='teams secondary' onClick={()=>toggle()}>Close</PrimaryBtn>
                        </ModalFooter>     
                    </CheckoutLicensesStyles>
                    
                </PopupBody>
            </ModalBody>
        </>
    </Modal>
    )
}
export default AddMoreAddons;