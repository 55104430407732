/*
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { sendEvents } from '../../../../tools/mpEvents';
*/
import { useState, useRef } from 'react';
import MessageBox from './MessageBox';
import { PureAIRecommendationsView } from '../../../../styles/dashboard/PureAI'
import PureAILogo from '../../../../assets/pure-ai-logo-large.svg'
import YouTubeIcon from '../../../../assets/youtube.svg'
import DocumentIcon from '../../../../assets/document.svg'
import AccountIcon from '../../../../assets/account.svg'
import { suggestedPrompts } from './content';
import PluginModal from './PluginModal';
import { sendMPEvents } from './helper';

const PureAIRecommendations = (({ setAblyObject, setAblyChannel, ablyChannel, ablyObject, evtSource, setEvtSource }) => {

    const [plugin, setPlugin] = useState(null);

    const [pluginModal, setPluginModal] = useState(false);

    const inputRef = useRef(null);

    const handlePrompt = (prompt) => {
        inputRef.current.value = prompt;
        inputRef.current.focus();
        sendMPEvents("ma_pureai_click_prompt", {
            prompt_type: "recommended",
            selected_interface_screen: "recommendation-board",
            category: prompt.category,
            prompt: prompt ?? "N/A"
        })
    }

    const clickCardModal = (type) => {
        setPlugin(type);
        setPluginModal(true)
    }

    const openPluginModal = () => {
        return (
            <PluginModal pluginModal={pluginModal} setPluginModal={setPluginModal} currentTool={plugin} />
        );
    }

    return (
        <>
            <PureAIRecommendationsView>
                <div className='content-display'>
                    <div>
                        <div className='headingDiv'>
                            <span className='pure-ai-logo'><img src={PureAILogo} alt='Pure AI' /></span>
                            <span className='heading'><h1><strong>pure</strong>AI</h1><span>ALPHA</span></span>
                        </div>
                        <p className='tagLine'>Your ultimate discovery companion</p>
                        <h2>Recommendations</h2>

                        <div className="grid-container">
                            {suggestedPrompts.map((prompt, index) => {
                                return <span className="grid-item" key={index} onClick={() => handlePrompt(prompt)}>{prompt}</span>
                            })}
                        </div>
                    </div>
                    <MessageBox inputRef={inputRef} setAblyObject={setAblyObject} setAblyChannel={setAblyChannel} ablyChannel={ablyChannel} ablyObject={ablyObject} evtSource={evtSource} setEvtSource={setEvtSource} />
                </div>
                <div className='side-bar'>
                    <div className='puretools'>
                        <h2>Plugins</h2>
                    </div>
                    <div className="card-container">
                        <div className="card-item" onClick={() => clickCardModal('youtube')}>
                            <div className='firstdiv'>
                                <img src={YouTubeIcon} alt='card-icon' className='cardIcon'></img>
                                <h3 className='cardHeading'>Youtube summarizer</h3>
                            </div>
                            <div>
                                <p className='cardDesc'>Streamline your youtube experience with this free youtube plugin that provides intuitive summaries, note creation and allow follow up queries</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="card-item" onClick={() => clickCardModal('doc-editor')}>
                            <div className='firstdiv'>
                                <img src={DocumentIcon} alt='card-icon' className='cardIcon'></img>
                                <h3 className='cardHeading'>Chatgpt for your Data</h3>
                            </div>
                            <div>
                                <p className='cardDesc'>This plugin enables users to easily upload documents or files and train chatgpt on your data so you can interact with it, making it convenient to access and analyze your data.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="card-item" onClick={() => clickCardModal('link-account')}>
                            <div className='firstdiv'>
                                <img src={AccountIcon} alt='card-icon' className='cardIcon'></img>
                                <h3 className='cardHeading'>App Connector</h3>
                            </div>
                            <div>
                                <p className='cardDesc'>Advanced plugin, powered by chatgpt, that you can seamlesssly connect to your systems like Google drive, Dropbox and more. With this plugin, you can integrate your systems and automate your workflows!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PureAIRecommendationsView>
            { pluginModal ? openPluginModal() : null }
        </>
    );
})

export default PureAIRecommendations;