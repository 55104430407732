import { memo } from 'react'
import { InfoCard } from '../../../styles/dashboard/Main'
import { useTranslation } from 'react-i18next';

const SubmitConsent = () => {
    const { t, i18n } = useTranslation();
    return (
        <InfoCard className="p-0 mt-4"><p>{t('agree_to_terms_and_condition_text')} <a href="https://www.purevpn.com/applinks/member-area/term.php" target="_blank" rel="noreferrer">{t('terms_and_service_text')}</a> {t('and_text')} <a href="https://www.purevpn.com/applinks/member-area/privacy-policy" target="_blank" rel="noreferrer">{t('privacy_policy')}</a>.</p></InfoCard>
    )
}

export default memo(SubmitConsent)