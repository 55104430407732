import { ERRORS, GETCHANNELS, LOADING } from "./types";

const initialState = {
    channels: {},
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:""
}

export const channelsReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETCHANNELS:
            return  {...state, channels:payload, loading:false, errors:false}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false}    
        default:
            return  {...state}
            break;
    }
}