import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {  PopupBody } from '../../../../styles/dashboard/Main'
import { InputBox, MenuBody } from '../../../../styles/dashboard/PureBuddy'
import { DestructBtn } from '../../../../styles/Generic'
import PopularPrompts from './PopularPrompts'
import { useTranslation } from 'react-i18next'

const MenuModal = ({toggle, modal, modalWidth, widthCalculator,typePrompt,sendEventsCall,openModalView,newChatAction,previousStates,iscloseHelloBar,setFormStateDisbaled,setResIsPending, reviewPopupToggle}) => {
    const [activeTab, setActiveTab] = useState('popularPrompts');

    const { t, i18n } = useTranslation();

    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    const clickPrompt = (selectedPrompt,search=false) => {
        sendEventsCall("ma_click_prompt",{
            prompt_type:"recommended",
            selected_interface_screen: search ? "search" : "drawer",
            selected_interface: activeTab,
            category:selectedPrompt?.category ?? "N/A",
            prompt:selectedPrompt?.prompt ?? "N/A"
        })
        typePrompt(selectedPrompt?.prompt)
    }
    const clickNewChat = () => {
        newChatAction()
        if(openModalView){
            toggle()
        }
        setFormStateDisbaled(false)
        setResIsPending(false)
        sendEventsCall("ma_click_new_chat")
    }
    const redirectCta = (isFaq=false) => {
        sendEventsCall("ma_click_feedback_faq",{cta:isFaq?"faq":"feedback"})
        if(isFaq){
            window.open("/v2/dashboard/pure-ai-faqs?redirect_from=true", "_blank");
        }else{
            window.open("https://purevpn-beta.typeform.com/to/t0yXdlYD", "_blank");
        }
    }
    const MenuView = () => {
       return <MenuBody openModalView={!openModalView} toDisableCta={previousStates.length===0} iscloseHelloBar={iscloseHelloBar}>
        <div className='operationsBox'></div>
        <div className='tabView'>
            {openModalView && <button className='chatCta' onClick={clickNewChat} disabled={previousStates.length===0}>{t('pureai_newchat_text')}</button>}
        </div>
        {!openModalView && <button className='nav-cta' onClick={clickNewChat}>{t('pureai_newchat_text')}</button>}
        <PopularPrompts isPopularPrompts={activeTab === 'popularPrompts' ? true : false} typePrompt={typePrompt} clickPrompt={clickPrompt} redirectCta={redirectCta} reviewPopupToggle={reviewPopupToggle}/>
    </MenuBody>
    }

    return (
        openModalView ? 
            <Modal backdrop={true} isOpen={modal} toggle={toggle} style={{maxWidth: `800px`, width: '100%'}} className="modal-wrapper modal-purebuddy-transition">
                <ModalBody>
                    {MenuView()}
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>: MenuView()

    )
}

export default MenuModal