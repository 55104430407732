import styled, { css } from "styled-components";
import bgImage from '../../assets/asideMaBG23.png'

export const RightSideBar = styled.div`
    padding:20px 15px 30px;
    width:213px;
    height:450px;
    position:relative;
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size:contain;
    box-sizing: border-box;
    border-radius: 15px;
    overflow:hidden;
    left:30px;
    .loader{
        position:absolute;
        height:100%;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        top:0;
        left:0;
        background:white;
        opacity:0;
        visibility:hidden;
        ${({loading}) => loading == "true" && css`
            opacity:1;
            visibility:visible; 
        `}
        .spinner-border{
            height:15px; 
            width:15px;
            border-width:2px;
        }
    }
    h4{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22.14px;
        text-align: center;
        letter-spacing: 1%;
        color:#fff;
        margin:6px;
    }
    p{
        font-size: 12px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.02em;
        color:#FFFFFF;
        margin:15px 0 9px;
    }
    a{
        padding:6px 4px;
        font-weight:800;
        display:block;
        margin-top:10px;
        font-size: 12px;
        background:#FFBA24;
        border-radius: 6px;
        color:black;
        display: flex !important;
        align-items: center;
        justify-content: center;
        :hover,:focus{
            color:black;
        }   
    }
    .yellow-text{
        color: #FFBA24 !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    } 
    .yellow-text-small{
        font-weight: 700;
        font-size: 15px !important;
        color:#FFBA24 !important;
    }
    .arrow-right {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid black;
        margin-left:5px;
        font-size:1px !important;
      }
      .aside-hdg{
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%; 
        letter-spacing: -0.4px;
        background: linear-gradient(90deg, #FFEAB8 0%, #FFBC33 102.3%);
        background-clip: text;
        margin-top:15px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .aside-text{
        color: #FFF;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.32px;
      }
      small {
        font-weight: 500;
        font-size: 11px;
        line-height: 123%;
        color:#FFFFFF;
      }
      .asideImg{
        margin-top:20px;
        width:90px;
      }
      .upgradebf{
        margin-top:30px;
        font-size:14px;
        border-radius: 19.249px;
        padding: 7px 25px;
      }
      .asidecontent{
        margin-top:70px;
      }
`;

export const InviteBox = styled.div`
    background: rgba(236, 233, 239, 0.5);
    border-radius: 14px;
    padding:16px;
    h2{
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #43225B;
    }
    h5{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #7B648C;
    }
`;