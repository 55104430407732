import React, {Suspense, useState} from 'react'
import { WhmcsSubscriptionNote } from '../../styles/dashboard/Main'
import { constants } from '../../tools/constants'
import { isWhmcsUser } from '../../tools/tools'
import { useTranslation } from 'react-i18next';


const WhmcsSubscriptionNotes = ({ subscriptionDetails }) => {

    const { t, i18n } = useTranslation();


    return isWhmcsUser(subscriptionDetails?.account?.billingType) && constants.allow_mobile_gateway.includes(subscriptionDetails?.payment_gateway) ? (
         <WhmcsSubscriptionNote>
             <div className="whmcs-note p-1 mt-2">
                 <p className="note-para"><strong>{t('whmcs_note')} </strong>{t('whmcs_note_para')}</p>
             </div>
         </WhmcsSubscriptionNote>
     ) : <></>
 }


 export default WhmcsSubscriptionNotes;