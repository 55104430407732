import React, { useState } from 'react'
import {  FreemiumModal, PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn, PrimaryBtn } from '../../../../styles/Generic'
import { Input, Label, Modal, ModalBody, ModalFooter,ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { constants, freeAddonTenure } from '../../../../tools/constants';
import Privacy from '../../../../assets/Privacy.png'
import Encrypt from '../../../../assets/Encrypt.png'
import Keep from '../../../../assets/Keep.png'
import allProducts from '../../../../assets/allProducts.png'
import { useEffect } from 'react';
import { sendEvents } from '../../../../tools/mpEvents';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import {getUpgradePlansReset,getUpgradePlansRequested} from  '../../../../redux/upgrade/actions'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import qs from "qs"
import { isFreeAddonSignle, isWhmcsUser, poster, seti18Locale, checkLocale } from '../../../../tools/tools';
import paymentSuccess from '../../../../assets/payment-success.svg'
import { useHistory } from 'react-router-dom';
import FreeAddonModalInfo from './partials/FreeAddonModalInfo';
import { useTranslation } from 'react-i18next';
import {vsprintf} from 'sprintf-js';


const addOnData = {
    purekeep:{
        name:"PureKeep",
        image:Keep,
        price:"3.49",
        postdesc:"freemodal_purekeep_postdesc",
        postfree:" freemodal_purekeep_postfree"
    },
    pureprivacy:{
        name:"PurePrivacy",
        image:Privacy,
        price:"3.49",
        postdesc:"freemodal_pureprivacy_postdesc",
        postfree:"freemodal_pureprivacy_postfree"
        
    },
    purescan:{
        name:"PurePrivacy",
        image:Privacy,
        price:"6.59",
        postdesc:"freemodal_pureprivacy_postdesc",
        postfree:"freemodal_pureprivacy_postfree"

    },
    purecrypt:{
        name:"PureEncrypt",
        image:Encrypt,
        price:"1.69",
        postdesc:"freemodal_pureencrypt_postdesc",
        postfree:" freemodal_pureencrypt_postfree"

    },
    allProducts:{
        name:"freemodal_allnewaddon_name",
        image:allProducts,
        price:"",
        postdesc:{0:"Unlock your <span>30-day free trial</span> for our newly launched add-ons. Simply click on the button below to begin your trial",
        2: "free_trial_desc",
        4: "free_6mo_trial_desc",
        3:"Unlock your <span>1-year free trial</span> for our newly launched add-ons. Simply click on the button below to begin your trial"},
        postfree:"Enjoy complete protection for your digital life with our advanced security suite!"
    },
    isChangePsw:{
        name:"PureKeep",
        image:allProducts,
        price:"",
        postdesc:"End your password stress with PureKeep: Store all your passwords in secure vaults and sync them across devices.",
        postfree:" and start protecting your passwords right away."
    }
}
const FreeAddonModal = ({backdrop, toggle, modal, modalWidth, widthCalculator, slug,accountData,getUpgradePlansReset,getUpgradePlansRequested,onBoardingReducer,campaign,isUpgraded=false,modalSource="Email Campaign",interval=null}) => {

    const token = localStorage.getItem("token");

    const history = useHistory()

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const get_locale = seti18Locale();

    const [loading,setLoading] = useState(false)

    const [data,setData] = useState("")

    const [error,setError] = useState(false)

    const [errorMsg, setErrorMsg] = useState(false)

    const [checked,setChecked] = useState(!isWhmcsUser(accountData) && !isUpgraded)

    const [obLoading,setOBLoading] = useState(false)

    const [paymentData, setPaymentData] = useState({

    })

    const [isPurchased, setIsPurchased] = useState(false)

    const checkBox = () => setChecked(!checked)

    const addon_price = process.env.REACT_APP_ADDON_PRICE

    const addon_price_allProducts = process.env.REACT_APP_ALL_PRODUCT_ADDON_PRICE

    const eventDefaultProp = {
        billing_type:accountData ?? "N/A",
        product:slug ?? "N/A",
        utm_campaign:campaign ?? "N/A",
        utm_source: modalSource ?? "Email Campaign"
    }

    const sendEventCall = (eventName,eventProperties={}) => {
        sendEvents(
            {
            event_name: eventName,
            event_properties: {
                ...eventDefaultProp,
                ...eventProperties
            }
          }, token);
    }

    useEffect(()=>{
        if(modal){
            sendEventCall("ma_view_newproducts_popup")
        }
    },[])

    useEffect(()=>{
        if(onBoardingReducer.loading){
            setOBLoading(true)
        }
        else{
            setOBLoading(false)
        }
        if(!onBoardingReducer.loading && onBoardingReducer.errors){
            setOBLoading(false)
        }
    },[onBoardingReducer])

    const apiAsync = () => {
        sendEventCall("ma_click_newproducts",{isReccuring:checked})
        redeemFreeAddon()
    }

    const redirectTo = () => {
        if(slug===constants.allProducts){
            history.push(`/dashboard/security-tools`)
        }
        else{
            history.push(`/dashboard/onboarding/${slug}`)
        }
        toggle()
    }

    const modalClose = () => {
        if(modalSource === constants.isChangePsw){
            toggle()
        }else{
            redirectTo()
            sendEventCall("ma_close_newproducts")
        }
    }

    async function redeemFreeAddon(){
        setLoading(true)
        /*
         Passing data in an array as same component will be used for Upgrade Campaign 
         where we have to offer all free add-ons as FREE with plan upgrade.
        newSignupViewApps=>  ["purekeep", "pureprivacy", "purecrypt"], 
        */
        const slugArray = isFreeAddonSignle(slug) ? [slug] : constants.newSignupViewApps 
        let params = {
            name: slugArray, 
        }
        /*
            *Tenure bit is passed in to api as we need to give the add-ons for an year to the user
            *Also the tenure bit is only passed when the addons are assigned to user Upgrading plan
            ! WHEN THE INTERVAL (PROP) BIT IS NULL TENURE WILL BE CONSIDERED AS MONTHLY(2) BIT ON BACKEND
            ! 1=WEEKLY, 2=MONTHLY, 3=WEEKLY, 4=SEMIANNUALLY
        */
        if(interval){
            params['tenure'] = interval
        }
        const requestData = qs.stringify(params)
        const apiResponse =  await poster("campaign/addons", requestData,token)
        const {data: response} = apiResponse;
        if(response?.status){
            dispatch({ type: "GETONBOARDINGREQUEST", token: token, locale:checkLocale() })
            setOBLoading(true)
            getUpgradePlansReset()
            sendEventCall("ma_get_newproducts")
            setLoading(false)
            setIsPurchased(true);
            setPaymentData({
                paymentStatus : true,
                image: paymentSuccess,
                heading:`freemodal_trial_started`,
                description:`<span class="addonNameOnPopup">${addOnData[slug]?.name} </span> addon_sucessfully_added_text`,
                ctaText:"Continue",
                waitCta:"freemodal_loading_text"
            })
        }else{
            const errorMsg = response?.errors?.content?.message ?? response?.message ?? APIFAILED;
            switch (response?.error_code) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})                           
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})                           
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})               
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                    break; 
                case "APIFAILED":
                    toast.error(t(APIFAILED))
                    break;     
                default:
                    toast.error(errorMsg)
                    break;
            }
            redirectTo()
            sendEventCall("ma_fail_get_new_products",{error_reason:errorMsg ?? "N/A" })
            setLoading(false)
        }
    }

    const buttonText = () => {
		return !loading ? (
			isFreeAddonSignle(slug) ? slug === "changePswPureKeep" ? `Try free for 30-days` :  t('freemodal_redeem_trial') : t('freemodal_start_trial')  
		) : (
			<>
				{t('freemodal_assigning_service')}
				<span className="spinner-border text-light spinner-border-sm ms-1"></span>
			</>
		);
	};

    const continueToNextStep = () => {
        getUpgradePlansRequested(token);
        if(isPurchased && !obLoading){
            redirectTo()
        }
    }

    const waitLoader = (name=null) => {
        return (  
            <>
                <strong>{t('freemodal_please_wait')}</strong>
                <span className="spinner-border text-dark spinner-border-sm ms-1"></span>
            </>
        )
    }

    const labelText = (addonInfo) => {
        return isFreeAddonSignle(slug) ? 
        vsprintf(t('freemodal_one_addon_renew'),[addonInfo ?? "Add-on", addon_price ?? ""]):
        vsprintf(t('freemodal_all_addon_renew'),`${addon_price_allProducts ?? ""}`);
         
    }

    return (
        // BA-548 Design change
        <FreemiumModal>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${isUpgraded ? "900px" : modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper" contentClassName={isPurchased ?"":"noPad-modal"} scrollable>
            {!isPurchased ? <ModalBody className="p-0">
                    <PopupBody addon={slug} isChangePsw={modalSource === constants.isChangePsw}>
                        <>
                        <ModalHeader toggle={modalClose}></ModalHeader>
                            <div className={`mod-head freemium ${!isFreeAddonSignle(slug) && ` upgradeCampaign`}`}>
                                <FreeAddonModalInfo slug={slug} addOnData={addOnData} interval={interval} modalSource={modalSource} isUpgraded={isUpgraded}/>
                                <PrimaryBtn pointer={loading} onClick={apiAsync}>{buttonText()}</PrimaryBtn>
                                {(!isWhmcsUser(accountData) && !isUpgraded) &&  <div className='d-flex align-items-end inputField mt-3'>
                                    <Input type="checkbox" className="checkBox" checked={checked} onChange={checkBox} disabled={loading}/>
                                    {' '}
                                    <Label check>
                                        {labelText(addOnData[slug]?.name)}
                                    </Label>
                                </div>}
                            </div>
                        </>
                    </PopupBody>
                </ModalBody>
                 :
                    <>
                        <ModalBody className='text-center'>
                            <PopupBody>
                                <div className="mod-head mb-0">
                                    <img src={paymentData.image} height="60px" width="60px" className="img-responsive mb-4"/>
                                    <h3>{t(paymentData.heading) ?? "Ooopss!!!"}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: isFreeAddonSignle(slug) ? `${t('download_text')} <strong>${t(addOnData[slug]?.name)}</strong>
                                    ${t(addOnData[slug]?.postfree)}` : `${t(addOnData[slug]?.postfree)}`
                                     || "You are not supposed to be here." }}></p>
                                </div>
                            </PopupBody>
                        </ModalBody>
                        <ModalFooter className='pt-0 m-auto text-center'>
                            {obLoading ? waitLoader(addOnData[slug]?.name) :  
                                <DestructBtn className='mx-auto' color="secondary" onClick={continueToNextStep}>{paymentData.ctaText}</DestructBtn>
                            }
                        </ModalFooter>
                    </>
                }
            </Modal>
        </FreemiumModal>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, {getUpgradePlansReset,getUpgradePlansRequested})(withRouter(FreeAddonModal))