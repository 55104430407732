export const channelSports = [
    {
        "id": 11,
        "country": "GB",
        "name": "BBC",
        "slug": "BBC",
        "channel_url": "https%3A%2F%2Fwww.bbc.co.uk%2Fiplayer%2Fcategories%2Fsport%2Fhighlights",
        "orig_url": "https://www.bbc.co.uk/iplayer/categories/sport/highlights"
    
    },
    {
        "id": 13,
        "country": "GB",
        "name": "ITV",
        "slug": "ITV",
        "channel_url": "https%3A%2F%2Fwww.itv.com%2Fhub%2Fitv",
        "orig_url": "https://www.itv.com/hub/itv"
    },
    {
        "id": 15,
        "country": "AU",
        "name": "SBS",
        "slug": "SBS",
        "channel_url": "https%3A%2F%2Fwww.sbs.com.au%2F",
        "orig_url": "https://www.sbs.com.au/"
    }
]