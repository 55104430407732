import React, {Suspense, useState, lazy, useEffect} from 'react'
import { DestructBtn, PrimaryBtn, SecHead } from '../styles/Generic'
import { LoginWrapper, LoginBody, LoginForm, LoginInput } from '../styles/login/LoginStyle' 
import loginlogo from '../assets/loginlogo.png'
import { Link } from 'react-router-dom'
import { api, validateEmail } from '../tools/tools'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import {PopupBody} from '../styles/dashboard/Main'
import qs from 'qs'
import { toast } from 'react-toastify'
import { APIFAILED, CAPTCHAVALIDATION } from '../tools/responseMessages'
import { fireGAEvent } from '../tools/gaEvents'
import { useTranslation } from 'react-i18next';
const V3Recaptcha = lazy(()=>import(/* webpackChunkName: "v3Recaptcha" */"../tools/v3Recaptcha"))
const V2Recaptcha = lazy(()=>import(/* webpackChunkName: "v2Recaptcha" */"../tools/v2Recaptcha"))

const ForgotPassword = ({history}) => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        return () => {
            localStorage.removeItem("recaptchaForgotToken")
        }
    }, [])

    const [resetCaptcha, setresetCaptcha] = useState(false)

    const [v3regenerate, setv3regenerate] = useState(false)

    const [data, setData] = useState({
        email:"",
        token:"",
        captchaType:"v3"
    })

    const [errors, setErrors] = useState({
        email:false,
        token:false,
        captchaType:false
    })

    const [loading, setLoading] = useState(false)

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const handleBlur = (e) => {
        if(e.target.name === "email"){
            setErrors({...errors, [e.target.name]: validateEmail(e.target.value) ? false : true})
        }else{
            setErrors({...errors, [e.target.name]: e.target.value ? false : true})
        }
    }

    const handleVerify = (token) => {
        if(token){
            localStorage.setItem("recaptchaForgotToken", token)
            if(data.captchaType === "v2"){
                setErrors({
                    ...errors, token:false
                })
            }
        }else{
            localStorage.setItem("recaptchaForgotToken", "")
        }
    }

    const captchaReset = () => {
        if(data.captchaType === "v2"){
            setresetCaptcha(true)
        }else{
            setv3regenerate(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let err = {email:false, token:false, captchaType:false}
        for (const key in data) {
            key === 'email' ? 
            validateEmail(data[key]) ?  err[key] = false : err[key] = true 
            : key === 'token' ? 
            err[key] = localStorage.getItem("recaptchaForgotToken") ? false : true 
            :
            data[key] !== "" ?  err[key] = false : err[key] = true
        }
        if(err.email || err.token){
            setErrors(err)
            setLoading(false);
            return;
        }
        setErrors(err)
        if(data.captchaType === "v3"){
            const token = await window.grecaptcha.execute(process.env.REACT_APP_V3_SITE_KEY, { action: "forget_password" })
            localStorage.setItem("recaptchaForgotToken", token);
        }
        try {
            fireGAEvent("memberAreaForgotPasswordFormSubmit", data.email)
            const formdata = qs.stringify({
                "email": data.email,
                "token": localStorage.getItem("recaptchaForgotToken") ?? "",
                "captcha-type": data.captchaType
            });
            const forgot = await api("auth/forget-password", formdata);
            const {data:response} = forgot;
            if(response?.status){
                fireGAEvent("memberAreaForgotPasswordFormSuccess", "Success")
                setData({...data, captchaType:"v3", email:""})
                toggle()
            } 
            else{
                switch (response?.error_code) {
                    case "captcha_error":
                        setData({...data, captchaType:"v2"})
                        localStorage.setItem("recaptchaForgotToken", "")
                        toast.error(t(CAPTCHAVALIDATION));
                        captchaReset()
                        break; 
                    case "account_exception":
                        toast.error(response?.message);
                        captchaReset()
                        break; 
                    case "validation_exception":
                        Object.values(response?.errors).map(e=>toast.error(e[0]))
                        captchaReset()
                        break; 
                    default:
                        toast.error(t(APIFAILED));
                        captchaReset()
                        break;
                } 
                fireGAEvent("memberAreaForgotPasswordFormError", response?.message || "Something went wrong")
            }
        } 
        catch (error) {
            fireGAEvent("memberAreaForgotPasswordFormError", "Api Break or Server not responding")
            toast.error('Something went wrong please try again!');
            setLoading(false);
            captchaReset()
        }
        finally{
            setLoading(false);
        }
    }

    const ButtonText = !loading ? 'Submit' : ( <>Submitting <span className="spinner-border text-light"></span></>);

    return (
        <LoginWrapper>
            <LoginBody>
                <SecHead className="text-center">
                    <img src={loginlogo} className="img-responsive" alt="PureVPN Logo"/>
                    <h1>Forgot Password</h1>
                    <p>A few clicks away from customizing your VPN user experience.</p>
                </SecHead>
                <LoginForm autoComplete={0}>
                    <div className="form-group m-0 p-0">
                        <LoginInput validation={errors.email} autoComplete="off" email type="text" name="email" value={data.email} onChange={handleChange} onBlur={handleBlur} placeholder="Email" className="form-control"/>
                        {
                            errors.email && <p className="danger smallerBody mt-2 mb-3">Valid email is required.</p>
                        }
                    </div>
                    <div className="mt-2">
                        {
                            data.captchaType === "v3"
                            ?
                                <Suspense fallback="">
                                    <V3Recaptcha data={data} v3regenerate={v3regenerate} setv3regenerate={setv3regenerate} handleVerify={handleVerify} errors={errors.token}></V3Recaptcha>
                                </Suspense>
                            :
                                <Suspense fallback={<div className="text-center p-2"><span className="spinner-border"></span></div>}>
                                    <V2Recaptcha setresetCaptcha={setresetCaptcha} reset={resetCaptcha} handleVerify={handleVerify} errors={errors.token}></V2Recaptcha>
                                </Suspense>
                        }
                    </div>
                    <div className="text-center mt-4">
                        <PrimaryBtn onClick={handleSubmit}>{ButtonText}</PrimaryBtn>
                        <p className="mt-3">You have an account?  <Link to="/login">Login here</Link></p>
                    </div>
                </LoginForm>
            </LoginBody>

            <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: '440px', width: '100%'}} className="modal-wrapper">
                <ModalBody className="pb-0">
                    <PopupBody>
                        <div className="mod-head">
                            <h3>A email has been sent</h3>
                            <p>Check your email for instructions, don't forget to check Spam/Trash.</p>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <DestructBtn color="secondary" onClick={toggle}>Okay</DestructBtn>
                </ModalFooter>
            </Modal>

        </LoginWrapper>

        

    )
}

export default ForgotPassword