import Skeleton from "react-loading-skeleton";
import { sendEvents } from "../../../../tools/mpEvents";
import { checkAccountType, getPaymentMethods, getTokenFromLocalStorage } from "../../../../tools/tools";
import { pureTeamsPostPurchaseStates } from "../../../../tools/constants";

const MemberCount = ({isCounterLoading, teamsCounter, setActiveTab, selectedSubscriptionData, error}) => {
    
    const token = getTokenFromLocalStorage();

    const onClickTab = (name) => {
        if(!error && !isCounterLoading){
            sendEvents({
                event_name: "ma_click_team_section",
                event_properties: {
                    section: name,
                    plan_type: selectedSubscriptionData?.plan_type ?? 'N/A',
                    billingcycle: selectedSubscriptionData?.type ?? 'N/A',
                    payment_method: getPaymentMethods(selectedSubscriptionData) ?? 'N/A',
                    current_status: selectedSubscriptionData?.state ?? 'N/A',
                    account_type: checkAccountType(selectedSubscriptionData) ?? 'N/A',
                    team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                    team_server_count: teamsCounter?.team_server?.limit ?? 0,
                    team_count: teamsCounter?.team_members?.limit ?? 0,
                    is_child: selectedSubscriptionData?.is_child
                },
            }, token);
            setActiveTab(name)
        }
    }

    return <section className="grids">
        {
            pureTeamsPostPurchaseStates.map((grids, index)=>{
                return <div className="grid-box" onClick={()=>onClickTab(grids.name)} key={index}>
                <div className={`grid-content${grids.name !== 'team_members' ? ' arrow' : ''} ${grids.name}`}>
                    { !isCounterLoading ? 
                    <h4>
                        {`${teamsCounter?.[grids?.name]?.allocated ?? 0}/${teamsCounter?.[grids?.name]?.limit ?? 0}`}                        
                    </h4> 
                    : <Skeleton height={16} width={50} className="mb-2"/>}
                    <h5>{grids.heading}</h5>
                </div>
            </div>
            })
        }
    </section>
}
export default MemberCount;