import React, { useEffect } from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { DashboardHead } from '../../../styles/dashboard/Main'
import { get, getByParam } from '../../../tools/tools'
import useCustomerHistory from './custom-hooks/useCustomerHistory'
import useEarningInfo from './custom-hooks/useEarningInfo'
import ProgressBar from './partial/ProgressBar'
import WalletBanner from './partial/WalletBanner'
import WalletDashboard from './WalletDashboard'

const PureWallet = () => {

    const { customerHistory, historyLoading, historyError, pointsInfo,setTglCustHist } = useCustomerHistory();

    return (
        <>
            <DashboardHead>
                <h2>PureRewards</h2>
                <p style={{maxWidth:"675px"}}>Discover ways to earn and enjoy free credits</p>
            </DashboardHead>

            <WalletBanner isDataLoaded={historyLoading} customerHitory={customerHistory} pointsInfo={pointsInfo} historyError={historyError} setTglCustHist={setTglCustHist}/>
            <WalletDashboard isDataLoaded={historyLoading} customerHistory={customerHistory} pointsInfo={pointsInfo} setTglCustHist={setTglCustHist} historyError={historyError}/>
        </>
  )
}

export default PureWallet