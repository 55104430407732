import styled from "styled-components";
import OnBoardingNavIcons from '../../assets/ACSD-30300-onboaridng-icons-color.svg';
import addonIcons from "../../assets/addons-sprites.png";
import gridIcons from "../../assets/security-tools/grid_icons.svg";
import warninng from "../../assets/warning.svg";

export const InfoStyles = styled.div`
    .info-head{
        h2{
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            color: #001219;
            margin-bottom: 22px;
            padding-left: 32px;
            position: relative;

            &::before{
                position: absolute;
                background: url(${OnBoardingNavIcons}) no-repeat 0px 0px;
                content: '';
                height: 24px;
                width: 24px;
                left: 0px;
                transform: scale(100%);
                top: -2px;
            }

            &.purevpn{
                ::before{
                    background-position: -24px 0px !important;
                }
            }

            &.purekeep{
                ::before{
                    background-position: -48px 0px !important;
                }
            }

            &.purencrypt{
                ::before{
                    background-position: -72px 0px !important;
                }
            }

            &.pureprivacy{
                ::before{
                    background-position: -96px 0px !important;
                }
            }

            &.dedicatedip{
                :before{
                    transform: scale(75%);
                    height: 32px;
                    top: -5px;
                    width: 32px;
                    background: url(${addonIcons}) no-repeat 0px 0px;
                    background-position: 0px 0px !important;
                }
            }
            &.portforwarding{
                :before{
                    transform: scale(75%);
                    height: 32px;
                    top: -5px;
                    width: 32px;
                    background: url(${addonIcons}) no-repeat 0px 0px;
                    background-position: -32px 0px !important;
                }
            }
            &.multilogins{
                :before{
                    transform: scale(75%);
                    height: 32px;
                    top: -5px;
                    width: 32px;
                    background: url(${addonIcons}) no-repeat 0px 0px;
                    background-position: -64px 0px !important;
                }
            }
        }
        h1{
            color: #001219;
            font-size: 24px;    
        }
        h6{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #7B648C !important
        }
        h4{
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #001219;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        h5{
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #001219;
            margin-top: 10px;
            margin-bottom: 0px;
        }
        p{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            margin-bottom: 10px;
        }
        ul{
            padding: 0;
            list-style: none;
            li{
                color: #637381 !important;
                font-size: 12px !important;
                letter-spacing: 1px;
            }
        }
        .highlight{
            padding: 20px 20px;
            border-radius: 8px;
            text-align: center;
            color: #001219;
            &.purevpn{
                background: rgba(130,96,239,.2)
            }
            &.purekeep{
                background: rgba(185,235,231,.3)
            }
            &.purencrypt{
                background: rgba(192,202,249,.3)
            }
            &.pureprivacy{
                background: rgba(255,229,173,.3)
            }
        }
        ul.rmd{
            list-style: none;
            padding: 0;

            &.warning{
                li{
                    &::before{
                        background: url(${warninng}) no-repeat;
                        backround-size: 26px 26px !important;
                        top: 0px;
                    }
                }
            }
            li{
                display: inline-block;
                position: relative;
                color: #001219;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.5px;
                letter-spacing: 0.5px;
                padding-left: 38px;

                &::before{
                    position: absolute;
                    content: '';
                    top: -2px;
                    left: 0px;
                    width: 26px;
                    height: 26px;
                    background: url(${gridIcons}) no-repeat 0px 0px;
                }

                &.total{
                    ::before{
                        background: url(${gridIcons}) no-repeat -52px 0px;
                    }
                }
                &.in-progress{
                    ::before{
                        background: url(${gridIcons}) no-repeat -26px 0px;
                    }
                }
                h3{
                    color: #001219 !important;
                    margin-bottom: 4px;
                    font-size: 18px;
                }
            }
        }
        .progress-meter{
            position: relative;
            height: 40px;

            @media only screen and (max-width: 550px) {
                height: auto;
            }

            h5{
                padding-left: 70px;

                @media only screen and (max-width: 550px) {
                    padding-left: 0;
                }
            }

            .multigraph {
                position: absolute;
                width: 0px;
                height: 0px;
                content: '';
                top: 0px;
                left: 0px;
                margin: 0px;
                transform: scale(0.175);

                @media only screen and (max-width: 550px) {
                    display: none;
                }
                &::before {
                    position: absolute;
                    content: '';
                    width: 300px;
                    height: 150px;
                    border: 40px solid rgba(0,0,0,0.1);
                    border-radius: 300px 300px 0 0;
                    border-bottom: none;
                  }
                  .graph {
                    width: 300px;
                    height: 150px;
                    position: absolute;
                    overflow:hidden;

                    &::before, &::after {
                        width: 300px;
                        height: 150px;
                        position: absolute;
                        top: 100%;
                        border: 40px solid #E34D2F;
                        content: '';
                        border-radius:0 0 300px 300px;
                        border-top: none;
                        animation: demo 3s;
                        transform-origin: center top;
                    }
                    &::after {
                        transform: rotate(150deg);
                    }

                    &.three{
                        &::after {
                            border-color: #FFE300;
                            transform: rotate(50deg);
                        }
                    }
                    &.seven{
                        &::after {
                            border-color: #FF9B00;
                            transform: rotate(110deg);
                        }
                    }
                }
            }
        }
    }
    .form-for-consent{
        margin: 0 auto;

        h4, p, ol, h6, span{
            color: #001219 !important;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.24px;
            text-align: left;
            word-break: break-word;
            
            @media only screen and (max-width: 860px) {
                font-size: 12px !important;
            }
        }

        span{
            margin-right: 8px;
            &.inner-list{
                margin-left: 28px;
            }
        }

        .form-content{
            padding: 0px 20px 0px 0px;
            
        }

        .consent-signature{
            .signature{
                position: relative;
                text-align: left;
                padding-top: 30px;
                font-size: 24px;
                line-height: 40px;
                margin: 0;

                ::before{
                    position: absolute;
                    content: '';
                    top: 10px;
                    width: 100%;
                    height: 1px;
                    border-top: 1px solid #ECE7FD;
                    
                    
                }
            }
            h4{
                color: #001219;
                font-size: 12px;
                font-weight: 700;
                line-height: 15px;
                letter-spacing: 0.24px;
                margin-bottom: 5px;
            }

            h5{
                color: #001219;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.24px;
                margin-bottom: 15px;
            }
        }
    }
`