import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { creditsRouting } from '../../../../tools/constants';
import { useHistory } from 'react-router-dom';

function TooltipView(args,props) {
    const {name} = props
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const history = useHistory()
    return (
        <div className='tooltip-view'>
            <p>
            <Link
                to={history.push(creditsRouting[name])}
            //   target="_blank"
                rel="noreferrer"
                id="TooltipExample"
            >
                tooltip
            </Link>
            .
            </p>
            <Tooltip
            {...args}
            isOpen={tooltipOpen}
            target="TooltipExample"
            toggle={toggle}
            >
                You have already Redeemed the credits. Please proceed forward
            </Tooltip>
        </div>
    );
}

TooltipView.args = {
  autohide: true,
  flip: true,
};

TooltipView.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};

export default TooltipView;