import { PrimaryBtn } from '../../../../styles/Generic';
import { CheckoutLicensesStyles } from '../../../../styles/dashboard/pure-teams/checkoutLicenses';
import { currencySymbol } from '../../../../tools/tools';
import { PopupBody } from '../../../../styles/dashboard/Main';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useState } from 'react';
import Info from '../../../../assets/info.svg';
import { pureTeamsPostPurchaseStates } from '../../../../tools/constants';
import Counter from '../layout/counter';
import { minDisabledForTeamServer, maxDisabledForTeamServer, getMaximumForTeamServer, getMinimumForTeamServer, hasErrorForTeamServer, getValueForTeamServer, isDisabledForTeamServer, getTotalAmountForTeamServer, onChangeCounterForTeamServer } from "../tools/teamServer";
import { minDisabledForTeamMember, maxDisabledForTeamMember, getMaximumForTeamMember, getMinimumForTeamMember, hasErrorForTeamMember, getValueForTeamMember, isDisabledForTeamMember, getTotalAmountForTeamMember, onChangeCounterForTeamMember } from "../tools/teamMembers";
import { minDisabledForDedicatedIp, maxDisabledForDedicatedIp, getMaximumForDedicatedIp, getMinimumForDedicatedIp, hasErrorForDedicatedIp, getValueForDedicatedIp, getTotalAmountForDedicatedIp, onChangeCounterForDedicatedIp } from "../tools/dedicatedIp";
import HasDedicatedIpView from '../layout/hasDedicatedIpView';
import checkmark from '../../../../assets/AddonChecker.png';

const CheckoutLicenses = ({ dedicatedIpState, dispatchForDedicatedIp, teamServerState, dispatchForTeamServer, teamMemberState, dispatchForTeamMember, modal, toggle, selectedSubscriptionData, toggleLicensesModal, proceedAfterLicensingSelection, toggleInfoModal}) => {    
    
    const [modalWidth] = useState({
        lg: "737px",
        sm: "440px"
    });
    

    const getTotalAmount = () => {
        return (
            getTotalAmountForTeamMember(teamMemberState) +
            getTotalAmountForTeamServer(teamServerState) +
            getTotalAmountForDedicatedIp(dedicatedIpState)
        )
    }

    const isDisabled = () => {
        return isDisabledForTeamMember(teamMemberState) || isDisabledForTeamServer(teamServerState) ||  dedicatedIpState?.current < dedicatedIpState.min || dedicatedIpState?.current > teamMemberState?.current 
    }

    const getHeaderForCounter = (state, type) => {
        return (
            <>
                <li>
                    <h5>{pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.heading}<img width={16} height={16} className='info-img' src={Info} alt="info" onClick={()=>{toggleLicensesModal(); toggleInfoModal(pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.info)}}/></h5>
                    <strong><h5>{`${currencySymbol[selectedSubscriptionData?.currency]}${(state?.current ? state?.price*state?.current*selectedSubscriptionData?.plan?.interval_length : 0).toFixed(2)}`}</h5></strong>
                </li>
                <li>
                    <p>{pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.checkout_liner}</p>
                    {state?.price && <strong><p>{`${currencySymbol[selectedSubscriptionData?.currency]}${(state?.price)}/${type === 'team_server' ? 'server' : type === 'dedicated_ip' ? 'ip' : 'user'}/mo`}</p></strong>}
                </li>
            </>
        )
    }

    return (
        <Modal backdrop={false} isOpen={modal} toggle={()=>toggle()} style={{maxWidth: `${modalWidth['lg']}`, width: '100%'}} className={`modal-wrapper`} scrollable>
        <>
            <ModalBody className="p-2">
                <PopupBody>
                    <CheckoutLicensesStyles>
                        <PopupBody className='checkout-body'>
                            <h4>Upgrade to Teams</h4>
                            <section className='counter-box'>
                                {/* TEAMS */}
                                <div className='addon-box'>
                                    <ul>
                                        {getHeaderForCounter(teamMemberState, 'team_members')}
                                    </ul>
                                    <Counter
                                        minDisabled={minDisabledForTeamMember}
                                        maxDisabled={maxDisabledForTeamMember}
                                        onChangeCounter={onChangeCounterForTeamMember}
                                        getMinimum={getMinimumForTeamMember} 
                                        getMaximum={getMaximumForTeamMember}
                                        hasError={hasErrorForTeamMember}
                                        getValue={getValueForTeamMember}
                                        state={teamMemberState}
                                        dispatch={dispatchForTeamMember}
                                        type={'team_members'}
                                    />
                                    <div className='checkout-plan'>
                                        <img src={checkmark} alt="checkmark" />
                                        <p>Your {selectedSubscriptionData?.hasFamilyPlan ? 'Family' : selectedSubscriptionData?.hasVolumePlan ? 'Volume' : 'Individual'} plan will be upgraded to Teams.</p>
                                    </div>
                                    </div>

                                {/* TEAM SERVER */}
                                <div className='addon-box'>
                                    <ul>
                                        {getHeaderForCounter(teamServerState, 'team_server')}
                                    </ul>

                                    <Counter
                                        minDisabled={minDisabledForTeamServer}
                                        maxDisabled={maxDisabledForTeamServer}
                                        onChangeCounter={onChangeCounterForTeamServer}
                                        getMinimum={getMinimumForTeamServer} 
                                        getMaximum={getMaximumForTeamServer}
                                        hasError={hasErrorForTeamServer}
                                        getValue={getValueForTeamServer}
                                        state={teamServerState}
                                        dispatch={dispatchForTeamServer}
                                        type={'team_server'}
                                        hideLiner={true}
                                    />
                                </div>


                                {/* DEDICATED IP */}
                                <div className='addon-box'>
                                    <ul>
                                        {getHeaderForCounter(dedicatedIpState, 'dedicated_ip')}
                                    </ul>

                                    <Counter
                                        minDisabled={minDisabledForDedicatedIp}
                                        maxDisabled={maxDisabledForDedicatedIp}
                                        onChangeCounter={onChangeCounterForDedicatedIp}
                                        getMinimum={getMinimumForDedicatedIp} 
                                        getMaximum={getMaximumForDedicatedIp}
                                        hasError={hasErrorForDedicatedIp}
                                        getValue={getValueForDedicatedIp}
                                        state={dedicatedIpState}
                                        dispatch={dispatchForDedicatedIp}
                                        type={'dedicated_ip'}
                                        max={teamMemberState?.current}
                                        hideLiner={true}
                                    />
                                    {selectedSubscriptionData?.add_ons.find((addon) => addon.code === 'dedicated_ip' || addon.code === 'port_forwarding_with_dedicated_ip') ? <HasDedicatedIpView addon={selectedSubscriptionData?.add_ons.find((addon) => addon.code === 'dedicated_ip' || addon.code === 'port_forwarding_with_dedicated_ip')}/> : null}

                                </div>

                                {/* TOTAL */}
                                <div className='total'>
                                    <h5>Total Amount</h5>
                                    <h5>{`${currencySymbol[selectedSubscriptionData?.currency]}${(getTotalAmount()*selectedSubscriptionData?.plan?.interval_length)?.toFixed(2)}`}</h5>
                                </div>
                            </section>
                        </PopupBody>
                        <ModalFooter className='p-0'>
                            <PrimaryBtn className='teams' disabled={isDisabled()} onClick={()=>proceedAfterLicensingSelection()}>Upgrade Now</PrimaryBtn>
                            <PrimaryBtn className='teams secondary' onClick={()=>toggleLicensesModal()}>Close</PrimaryBtn>
                        </ModalFooter>     
                    </CheckoutLicensesStyles>
                    
                </PopupBody>
            </ModalBody>
        </>
    </Modal>
    )
}
export default CheckoutLicenses;