import React, { useEffect, useState } from 'react';
import { DashboadWrapper, DashboardContent, CampaignHelloBarWrapper, CampaignHelloBarContent } from '../../../styles/dashboard/Main'
import { sendEvents } from "../../../tools/mpEvents"
import { connect, useDispatch } from 'react-redux'
import { PrimaryBtn } from '../../../styles/Generic';
import closeIcon from '../../../assets/close-icon.svg';
import { getHelloBar } from '../../../redux/hello-bar/actions';
import { useHistory, useLocation } from "react-router-dom";
import { getSubscriptionType, isStandard } from '../upgrade/upgrade-tools';
import { constants } from '../../../tools/constants';
import { isExpired } from '../../../tools/tools';
import arrowRightSvg  from '../../../assets/arrow-rightupgrade.svg'

const HelloBar = ({helloBarReducer, subscriptions, helloBarStatusCheck}) => {

    const token = localStorage.getItem("token");

    const [helloBarData, setHelloBarData] = useState(null)

    const [helloBarStatus, setHelloBarStatus] = useState(false)

    const [helloBarType, setHelloBarType] = useState('upgrade')

    const [isRefer, setIsRefer] = useState(false)
    
    const dispatch = useDispatch()
    
    const history = useHistory();

    const { search } = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    useEffect(() => {

        dispatch(getHelloBar())

    }, [])

    useEffect(() => {
        
        if (!helloBarStatus && helloBarReducer.hello_bar && subscriptions?.subscriptions?.body) {

            //check user type from subsription 
            const userType = subscriptions?.subscriptions?.body?.[0]?.type;
            const planType = subscriptions?.subscriptions?.body?.[0]?.plan_type;
            let helloBarTemplate = "campaignBanner";
            setHelloBarType(helloBarTemplate)


            // Loog over each hellobar item and set data based on above subs condition
            helloBarReducer.hello_bar.map((item) => {
                if (item.type && item.type === helloBarTemplate) {
                    setHelloBarData(item);
                    setHelloBarStatus(true);
                    helloBarStatusCheck(true);
                }
            });
        }
    }, [helloBarReducer])

    const helloBarCTAClickHandle = (e) => {

        if (token) {
            sendEvents({
                event_name:"ma_click_hellobar",
                event_properties:{
                    ctaname: "Upgrade today",
                    type: helloBarType
                }
            }, token);
        }

        if (helloBarData?.cta_one_action && history) {
            if (isValidURL(helloBarData.cta_one_action)) {
                window.open(helloBarData.cta_one_action, '_blank');
            } else {
                history.push(`/dashboard${helloBarData.cta_one_action}`)
            }
        }
    }

    const toggleHelloBar = (status) => {
        setHelloBarStatus(status)
        helloBarStatusCheck(status);
    }

    const isValidURL = (string) => {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    }

    const closeHelloBar = () => {
        if (token) {
            sendEvents({
                event_name:"ma_click_hellobar",
                event_properties:{
                    ctaname: 'close',
                    type: helloBarType
                }
            }, token);
        }
        toggleHelloBar(false)
        localStorage.setItem("closeHelloBar", true)
    }

    return (
        <>
            {
                !redirected_via && helloBarData && helloBarStatus ?
                (
                    <CampaignHelloBarWrapper>
                        <CampaignHelloBarContent className='justify-content-center bg-image' isRefer={isRefer} 
                            bgColor={( helloBarData?.bg_color ) ? helloBarData?.bg_color : ""} 
                            bgImage={( helloBarData?.bg_image ) ? helloBarData?.bg_image : ""} 
                            bgImageTablet={( helloBarData?.bg_image_tablet ) ? helloBarData?.bg_image_tablet : ""} 
                            bgImageMobile={( helloBarData?.bg_image_mobile ) ? helloBarData?.bg_image_mobile : ""}>
                            {
                                ( helloBarData?.html_text ) && (
                                        <div className='d-flex justify-content-center align-items-center' dangerouslySetInnerHTML={{ __html: helloBarData?.html_text || "" }}></div>
                                ) 
                            }
                            {
                                ( helloBarData?.cta_one ) &&
                                <div className='d-flex flex-column align-items-center'>
                                 <PrimaryBtn className='banner-cta align-items-center' 
                                    isBF={true}
                                    isUpgrade={true} 
                                    
                                    onClick={helloBarCTAClickHandle}>
                                    {helloBarData?.cta_one}
                                    <img src={arrowRightSvg} height="18" width="18"/>
                                </PrimaryBtn>
                                 </div>

                            }
                            <div className='close-button' onClick={() => { closeHelloBar() }}>
                                <img src={closeIcon} />
                            </div>
                        </CampaignHelloBarContent>
                    </CampaignHelloBarWrapper>
                ) 
                : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return { 
        helloBarReducer: state.helloBarReducer,
        subscriptions: state.subscriptionsReducer,
    };
};

export default connect(mapStateToProps, null)(HelloBar)