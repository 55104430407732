import axios from 'axios';
import { takeLatest, put, cancelled, call } from 'redux-saga/effects'
import { APIFAILED } from '../../tools/responseMessages';
import { poster, fetcher } from '../../tools/tools';
import { errorsType, setHelloBar, loadingType } from './actions';
import i18n from "i18next";


function* helloBarAsync(){

    const cancelTokenSource = axios.CancelToken.source();
    yield put(loadingType())
    try{
        const response = [
            {
                type: "upgrade",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_upgrade_cta",
                cta_one_action: "/upgrade",
                cta_one_operation: "link",
                html_text: `hellobar_upgrade`
            },
            {
                type: "dedicated_server",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "dedicated_server_cta",
                cta_one_action: "/subscriptions?features_metric=1",
                cta_one_operation: "link",
                html_text: `hellobar_dedicated_server`
            },
            {
                type: "purekeep",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_purekeep_cta",
                cta_one_action: "https://www.purevpn.com/purecircle/keep?utm_source=new_member_area&utm_medium=hello_bar&utm_campaign=PureKeep",
                cta_one_operation: "link",
                html_text: `hellobar_purekeep`
            },
            {
                type: "referal",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_refer_cta",
                cta_one_action: "/refer",
                cta_one_operation: "link",
                html_text: `hellobar_refer`
            },
            {
                type: "past_due",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "renew_now_text",
                cta_one_action: "/subscriptions?update_subscription=true",
                cta_one_operation: "link",
                html_text: `hellobar_renew`
            },
            {
                type: "familyplan",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_familyplan_cta",
                cta_one_action: "/family-plan",
                cta_one_operation: "link",
                html_text: `hellobar_familyplan`
            },
            {
                type: "b2b",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_b2b_cta",
                cta_two: "hellobar_b2b_cta_two",
                cta_one_action: "/business",
                cta_one_operation: "link",
                html_text: `hellobar_b2b`
            },
            {
                type: "teams",
                bg_color: "#542BA8",
                bg_image: null,
                cta_one: "hellobar_teams_cta",
                cta_one_action: "/teams",
                cta_one_operation: "link",
                html_text: `hello_bar_teams`
            },
            {
                type: "brandlaunch",
                bg_color: "",
                bg_image: "https://images.purevpn-tools.com/public/images/BA-564-brandlaunch-banner.png",
                bg_image_mobile: "https://images.purevpn-tools.com/public/images/BA-564-brandlaunch-banner-small.png",
                bg_image_tablet: "https://images.purevpn-tools.com/public/images/BA-564-brandlaunch-banner-tablet.png",
                cta_one: "Visit our website",
                cta_one_action: "https://www.puresquare.com/",
                cta_one_operation: "link",
                html_text: `<div class="bf-textbox"><div class="bf-bannertext"><p class="bf-whitetext">Introducing<span class="yellow-text">PureSquare</span><p class="bf-whitetext">– for security beyond VPNs</p></p></div></div>`
            },
            {
                type: "familybf",
                bg_color: "",
                bg_image: "https://images.purevpn-tools.com/public/images/BA-418-christmas-banner.png",
                bg_image_mobile: "https://images.purevpn-tools.com/public/images/BA-418-christmas-banner-mobile1.png",
                bg_image_tablet: "https://images.purevpn-tools.com/public/images/BA-418-christmas-banner-tablet1.png",
                cta_one: "Upgrade my plan now",
                cta_one_action: "/family-plan",
                cta_one_operation: "link",
                html_text: `<div class="bf-textbox"><img class="bf-img img-fluid" src="https://images.purevpn-tools.com/public/images/Ba-418-holidays.png" alt="88% Off"><div class="bf-bannertext"><p class="bf-whitetext">Grab PureVPN’s Family Plan at the biggest discount and keep your loved ones secure online.<span class="yellow-text"></span></p></div></div>`
            },
            {
                type: "upgradeThreeYears",
                bg_color: "",
                bg_image: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background.png",
                bg_image_mobile: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background-mobile.png",
                bg_image_tablet: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background-mobile.png",
                cta_one: "Upgrade now",
                cta_one_action: "/upgrade",
                cta_one_operation: "link",
                html_text: `<div class="bf-textbox"><div class="anniversary-box"><img class="bf-img img-fluid" src="https://images.purevpn-tools.com/public/images/BA-825-celebration-icon.png" alt="88% Off"><span class="anniversary-text">Anniversary Dealss</span></div><div class="bf-bannertext"><p class="bf-whitetext"><span class="yellow-text">Save 84% + get 4 months extra</span>on our 3-Year Plan and celebrate with us!</p></div></div>`
            },
            {
                type: "upgradeFiveYears",
                bg_color: "",
                bg_image: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background.png",
                bg_image_mobile: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background-mobile.png",
                bg_image_tablet: "https://images.purevpn-tools.com/public/images/BA-825-upgrade-campaign-background-mobile.png",
                cta_one: "Upgrade now",
                cta_one_action: "/upgrade",
                cta_one_operation: "link",
                html_text: `<div class="bf-textbox"><div class="anniversary-box"><img class="bf-img img-fluid" src="https://images.purevpn-tools.com/public/images/BA-825-celebration-icon.png" alt="88% Off"><span class="anniversary-text">Anniversary Deal</span></div><div class="bf-bannertext"><p class="bf-whitetext"><span class="yellow-text">Save 85%</span>on our 5-Year Plan and celebrate with us!</p></div></div>`
            },
            {
                type: "campaignBanner",
                bg_color: "",
                bg_image: "https://images.purevpn-tools.com/wp-content/uploads/en/2023/11/campaigndesktopBG.png",
                bg_image_mobile: "https://images.purevpn-tools.com/wp-content/uploads/en/2023/11/campaignMobileBG.png",
                bg_image_tablet: "https://images.purevpn-tools.com/wp-content/uploads/en/2023/11/campaigntabletBG.png",
                cta_one: "Upgrade today",
                cta_one_action: "/upgrade",
                cta_one_operation: "link",
                html_text: `<div class="bf-textbox"><div class="anniversary-box"><img class="bf-img img-fluid" style="width: 130px;height: 85px;" src="https://images.purevpn-tools.com/wp-content/uploads/en/2023/11/campaignHelloBarPurPaw3x.png" alt="Pur paw"><span class="anniversary-text">Grab 85% discount</span></div><div class="whole-text"<div class="bf-bannertext"><p class="bf-whitetext">on PureVPN's 5-year plan for just <span class="yellow-text">$99.99</span></p></div></div></div>`
            }
        ]

        yield put(setHelloBar(response));
    }catch(err){
        yield put(errorsType({code: "APIFAILED", message: ((i18n.t(APIFAILED))) }))
    }
    finally{
        if(yield cancelled()){
            cancelTokenSource.cancel();
        }
    }
}

export function* watchHelloBarRequest(){
    yield takeLatest("GETHELLOBAR", helloBarAsync)
}