import styled, { css }  from "styled-components";

export const PrePurchaseStyle = styled.div`
    // width: 75.51%;
    margin: auto;

    .main-content{
        margin-bottom: 60px;

        @media only screen and (max-width: 560px){
            margin-bottom: 20px;
        }

        h2{
            max-width: 900px;
            width: auto;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            color: #001219;
            text-align: center;
            margin: auto;
            margin-bottom: 10px;

            @media only screen and (max-width: 860px){
                font-size: 32px;
                line-height: 40px;
            }

            @media only screen and (max-width: 560px){
                font-size: 24px;
                line-height: 32px;
            }
        }
        h6{
            max-width: 1000px;
            width: auto;
            color: #637381;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.5px;
            margin: auto;
            text-align: center;

            @media only screen and (max-width: 860px){
                font-size: 16px;
                line-height: 18px;
            }

            @media only screen and (max-width: 560px){
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    .features{
        margin: 90px auto 20px;
        h2{
            margin-bottom: 60px;
            font-size: 36px;
            font-weight: 700;
            color: #001219;
            text-align: center;

            @media only screen and (max-width: 860px){
                font-size: 32px;
                line-height: 40px;
            }

            @media only screen and (max-width: 560px){
                font-size: 24px;
                line-height: 32px;
            }
        }
        h3{
            color: #001219;
            font-size: 24px;
            font-weight: 700;
            @media only screen and (max-width: 860px){
                font-size: 20px;
            }
        }

        p{
            @media only screen and (max-width: 860px){
                font-size: 14px;
            }
        }
    }
    .interactive-content{
        font-size: 18px;
        font-weight: 400;
        line-height: 22.5px;
        h5{
            color: #001219;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            line-height: 22.5px;
            margin-bottom: 50px;

            @media only screen and (max-width: 560px){
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .experiences{
        margin: 60px auto 0px;
        h2{
            margin-bottom: 30px;
            font-size: 36px;
            font-weight: 700;
            color: #001219;
            text-align: center;

            @media only screen and (max-width: 860px){
                font-size: 32px;
                line-height: 40px;
            }

            @media only screen and (max-width: 560px){
                font-size: 24px;
                line-height: 32px;
            }
        }

        .exp-boxes{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 28px;

            @media only screen and (max-width: 820px){
                display: flex;
                flex-direction: column;
            }
        }
        
    }
    .previewHeader{
        display:flex;
        justify-content:end;
        gap:15px;
        width: 70%;
        margin: auto;
        margin-bottom:25px;
        text-align: center;
        
        @media only screen and (max-width: 400px){
            .previewHeaderImg{
                display:none !important;
            }
            justify-content:center;
        }
        
        .pHeader-cta{
            margin-right:25px;
            @media only screen and (max-width: 400px){
                margin-right:0px;
            }
        }
    }
    .preview-content{
        button{
            height: 60px;
            max-width: 361px;
            width: 100%;
            font-size: 16px !important;

            @media only screen and (max-width: 571px){
                font-size: 12px !important;
                max-width: 260px;
            }
        }
    }
    .error-plans{
        text-align: center;
        max-width: 600px;
        margin: auto;
        background: #C1B0F754;
        box-shadow: 0px 0px 36px rgba(67,34,91,0.1);
        border-radius: 10px;
        padding: 30px 20px;
        width: 100%;
        font-weight: 600;
        font-size: 15px;
    }
`

export const ExperiencesStyles = styled.div`
    padding: 23px 46px;
    border-radius: 12px;
    border: 1px solid DDD4E2;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(67, 34, 91, 0.10), 0px 4px 10px 0px rgba(67, 34, 91, 0.05);
    max-width: 362px;
    text-align: center;
    min-height: 290px;
    width: 100%;

    @media only screen and (max-width: 1310px){
        padding: 18px 16px;
    }

    @media only screen and (max-width: 890px){
        min-height: 250px;
        
    }

    img{
        margin-bottom: 15px;

        @media only screen and (max-width: 890px){
            max-width: 90px;
            width: 100%;
        }
    }

    h5{
        color: #001219;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.5px;
        margin-bottom: 5px;

        @media only screen and (max-width: 890px){
            font-size: 16px;
        }
        
    }
    p{
        font-size: 14px;
        color: #637381;
        margin: 0;

        @media only screen and (max-width: 310px){
            font-size: 12px;
        }
    }
`
export const PostPurchaseStyle = styled.div`

max-width: 79.47%;
margin: auto;

@media only screen and (max-width: 1020px){
    max-width: 100%;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #DDD4E2;
    margin-bottom: 30px;

    @media only screen and (max-width: 1020px){
        display: block;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    .invite-content{
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1020px){
            justify-content: end;
          }
        .users-added{
            display: flex;
            align-items: center;
            padding-right: 15px;
            border-right: 1px solid #EEEDF0; 
            margin-right: 15px;
            gap: 10px;

            &.max-added{
                margin: 0;
                padding: 0;
                border: none;
            }

            h5,p{
                margin: 0;
            }
            h5{
                color: #001219;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.25px; 
            }
            p{
                color: #637381;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.24px; 
            }
        }
    }
}

.member-data{
    margin-top: 30px;
    box-shadow: 0px 0px 36px rgba(67,34,91,0.1);
    border-radius: 10px;
}
`
export const CartStyle = styled.div`
    max-width: 324px;
    margin: auto;
    border-radius: 24px;
    background: #3B108E;
    box-shadow: 0px 12px 24px 0px rgba(67, 34, 91, 0.10);
    padding: 35px 30px;

    @media only screen and (max-width: 320px){
        padding: 30px 20px;
    }
    h4{
        color: #FFF;
        font-size: 22px;
        font-weight: 400;
        display: flex;
        align-items: center;
        margin: 0;
        @media only screen and (max-width: 560px){
            font-size: 20px;
            line-height: 16px;
        }
        @media only screen and (max-width: 320px){
            display: block;
        }
        span{
            color: #000 !important;
            font-size: 11px !important;
            font-weight: 750;
            text-transform: uppercase;
            border-radius: 6px;
            background: linear-gradient(0deg, #FFBA24 0%, #FFBA24 100%), linear-gradient(0deg, #F89862 0%, #F89862 100%), #28C675;
            padding: 4px 4px 2px;
            margin-left: 8px;
            @media only screen and (max-width: 560px){
                font-size: 10px !important;
            }
            @media only screen and (max-width: 320px){
                display: block;
                margin: 8px 0px 20px;
                width: max-content;
            }
        }
    }
    h5{
        color: rgba(255, 255, 255, 0.70) !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 20px !important;
        letter-spacing: 0.25px !important;
        margin: 0;
        margin-top: 8px;
        text-align: left !important;

        @media only screen and (max-width: 560px){
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
    h2{
        margin-top: 35px;
        color: #FFF;
        font-size: 50px;
        margin-bottom: 0px;
        font-weight: 700;

        @media only screen and (max-width: 560px){
            font-size: 46px !important;
        }

        @media only screen and (max-width: 320px){
            font-size: 32px !important;
        }
    }
    h6{
        color: rgba(255, 255, 255, 0.70);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-decoration: line-through;
        opacity: 0.5;
        margin: 0;
    }
    p{
        color: rgba(255, 255, 255, 0.50) !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.24px;
        margin: 0;
        margin-top: 15px;
    }
    button{
        margin-top: 35px !important;
        height: 60px;
        font-size: 16px;

        @media only screen and (max-width: 560px){
            height: 50px;
        }
    }
    .white{
        color: rgba(255,255,255,0.70) !important;
        font-size: 12px !important;
    }

`
export const FeatureStyles = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 45px;
    margin: auto;
    max-width: 900px;

    img{
        width: 240px;
        
        @media only screen and (max-width: 860px){
            width: 200px;
        }
    }

    @media only screen and (max-width: 860px){
        display: block;
        max-width: 500px;
        margin: auto;
        text-align: center;
        div{
            margin-bottom: 40px;
        }
    }

    p{
        margin: 0
    }

    ${props=>props.left && css`
        div{
            order: 2;
        }
    `}
    ${props=>props.right && css`
        img{
            order: 2;
        }
    `}

`
export const VolumeLicensingStyles = styled.div`
    .sub-content{
        margin-bottom: 45px;

        @media only screen and (max-width: 560px){
            margin-bottom: 40px;
        }
    }
`

export const InviteStyles = styled.div`

    h2{
        color: #001219;
        font-size: 30px;
        font-weight: 700;

        @media only screen and (max-width: 600px){
            font-size: 24px !important;
        }

        @media only screen and (max-width: 320px){
            font-size: 20px !important;
        }
    }

    h6{
        color: #637381;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0.24px;
        margin: 30px auto 5px;

        @media only screen and (max-width: 320px){
            font-size: 10px !important;
        }
    }

    .copy-link-box{
        position: relative;
        input{
            width: 100% !important;
            z-index: 0;
            border: 1px solid #DDD4E2 !important;
        }
        img{
            position: absolute;
            top: 4px;
            content: '';
            right: 1px;
            z-index: 1;
            cursor: pointer;
            background: #fff;
        }
    }
    .email-link-box{
        position: relative;
        input{
            border: 1px solid #DDD4E2 !important;
            @media only screen and (max-width: 480px){
                &::placeholder{
                    font-size: 10px;
                }
            }
        }
        button.invite-btn{
            position: absolute;
            top: 7px;
            content: '';
            right: 4px;
            z-index: 3;
            cursor: pointer;
            padding: 6px 10px;
            font-size: 10px;
            &:disabled{
                opacity: 0.2;
            }

            @media only screen and (max-width: 460px){
                top: 1px;
            }

            @media only screen and (max-width: 420px){
                position: relative;
                right: 0px;
                display: block;
            }
        }
    }

    .input {
        width: 100%;
        height: 45px;
        padding: 0 1rem;
        margin-top: 1rem;
        box-sizing: border-box;
        font: inherit;
        border-radius: 0.2rem;
        border: 2px solid #d4d5d6;
        color: #565656;
        -webkit-appearance: none;
      }
      
      .input:focus {
        border-color: cornflowerblue;
        outline: none;
      }
      
      .input.has-error {
        border-color: red;
      }
      
      .error {
        font-size: 11px;
        color: red;
        font-weight: 500;
        margin-top: 5px;
      }
      
      .tag-item {
        background-color: #9a75d147;
        display: inline-block;
        font-size: 11px;
        border-radius: 30px;
        height: 20px;
        padding: 0.3rem 0.2rem 0.3rem 0.5rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;
      }
      
      .tag-item > .button {
        background-color: white;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 5px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    
`