import React, { useEffect, useState } from 'react'
import {  PopupBody } from '../../../../styles/dashboard/Main'
import { DestructBtn } from '../../../../styles/Generic'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import paymentSuccess from '../../../../assets/payment-success.svg'
import paymentFailed from '../../../../assets/payment-failed.svg'
import { getSubscriptionsRequest } from '../../../../redux/subscriptions/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TransactionModal = ({backdrop, toggle, modal, modalWidth, widthCalculator, transactionStatus, getSubscriptionsRequest}) => {

    const token = localStorage.getItem('token')

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(transactionStatus){
            getSubscriptionsRequest(token)
        }
    }, [])

    const [data] = useState({
        true: {
            icon: paymentSuccess,
            heading: t('payment_successful_text'),
            description: t('payment_successful_text_para_two'),
            cta: t('continue_text')
        },
        false: {    
            icon: paymentFailed,
            heading:t('payment_failed_text'),
            description:t('payment_failed_text_para_two'),
            ctaText:t('payment_details_go_back_cta')
        }
    })

    const closeModal = () => {
        window.history.pushState({}, document.title, window.location.pathname);
        toggle()
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%', textAlign:'center'}} className="modal-wrapper">
                <ModalBody className="pb-0">
                    <PopupBody className='pb-0'>
                        <div className="mod-head">
                            <img src={data[transactionStatus]?.icon ?? data['false'].icon} className="img-responsive mb-4"/>
                            <h3>{data[transactionStatus]?.heading ?? "Ooopss!!!"}</h3>
                            <p>{
                                data[transactionStatus]?.description
                                ??
                                "You are not supposed to be here."
                            }</p>
                        </div>
                    </PopupBody>
                </ModalBody>
                <ModalFooter className='pt-0'>
                    <DestructBtn color="secondary" onClick={closeModal} center>{ data[transactionStatus]?.cta ?? "Close"}</DestructBtn>
                </ModalFooter>
            </Modal>
        </div>
    )
}


export default connect(null, {getSubscriptionsRequest})(TransactionModal)