import React, { useState, useEffect } from 'react'
import { useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages'
import { poster } from '../../../../tools/tools'
import qs from 'qs'
import { InviteFamilyMemberBox, FamilyPlanShareLink } from '../../../../styles/dashboard/Main'
import { sendEvents } from '../../../../tools/mpEvents';
import { SubscriptionBox, InfoCard} from '../../../../styles/dashboard/Main'
import Skeleton from 'react-loading-skeleton';
import Invite from "../../../../assets/invite.png"
import { useTranslation } from 'react-i18next';

const FamilyPlanInvite = ({disable, existingAccount, maxAccount, parentDetails, extractEventProp}) => {

    const { t, i18n } = useTranslation();

    const token = localStorage.getItem("token")

    const dispatch = useDispatch()

    const [loading, setloading] = useState(true)

    const [childOnboardingData, setChildOnboardingData] = useState({})

    useEffect(()=>{
        async function getFamilyPackage(){
            
            const formdata = qs.stringify({
                id: parentDetails?.current_plan?.subscription_id,
                plan_code: parentDetails?.current_plan?.code,
                plan_price: parentDetails?.current_plan?.unit_amount,
                current_plan_currency: parentDetails?.current_plan?.currency,
                current_plan_expiry_date: parentDetails?.current_plan?.expiry_date,
                max_account: maxAccount

            })
            const linkDetail =  await poster("familyplan/get-link", formdata, token)
            const {data: response} = linkDetail;
            if(response?.status){
                
                setChildOnboardingData(response?.body)

            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                                
                        break;
                    case "jwt_exception":
                        toast.error(t(APIFAILED))
                        dispatch({type:"LOGOUT"})
                                
                        break; 
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                                
                        break;    
                    case "jwt_default_exception":
                        toast.error(INVALIDTOKEN)
                        dispatch({type:"LOGOUT"})
                                
                        break; 
                    case "APIFAILED":
                        toast.error(t(APIFAILED))
                        break;     
                    default:
                        toast.error(t(APIFAILED))
                        break;
                }
            }
            setloading(false)
        }
        if(!disable){
            getFamilyPackage();
        }else{
            setloading(false)
        }
        
    }, [])

    const shareToSocial = (url, platform) => {
        let eventsProp = extractEventProp(parentDetails)
        switch (platform) {
            case "facebook":
                /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            ?
                window.location.href = `http://www.facebook.com/dialog/send?app_id=${process.env.REACT_APP_FB_ID}&link=${url}`
            :
                window.open(`http://www.facebook.com/dialog/send?app_id=5650212068326128&link=${url}&redirect_uri=https://my.purevpn.com/`,'popup','width=600,height=600')
                break;
            case "whatsapp":
                window.open(`https://wa.me/?text=${url}`, '_blank','width=600,height=600')
                break;  
            case "twitter":
                window.open(`https://twitter.com/intent/tweet?url=${url}`,'popup','width=600,height=600')
                break;
            default:
                break;
        }
        sendEvents(
            {
            event_name: "ma_send_child_invite",
            event_properties: {
              action:"share",  
              platform: platform,
              url:url,
              ...eventsProp
              
            }
          }, token);
    }

    const copyLink = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success("Copied to Clipboard!")
            let eventsProp = extractEventProp(parentDetails)
            sendEvents({
                event_name: "ma_send_child_invite",
                event_properties: {
                  action:"Copy",  
                  platform: "N/A",
                  url:e.target.id,
                  ...eventsProp
                }
            }, token);
        } catch (error) {
            toast.error("Unable to copy!")       
        }
    }


    return !loading ? 
    <section className='fp_invite'>
        <div className='invite-header'>
            <figure className='text-center'>
                <img src={Invite} alt="invite" />
            </figure>
            <article>
                <InviteFamilyMemberBox isInvite={true}>
                    <h3 className='text-center'>{disable ? t('subscription_status_your_subscription_has_been_expired') : existingAccount != maxAccount ? t('familyplan_copylink_invite') : t('family_family_is_protected')}</h3>
                    <p className={`text-center ${existingAccount != maxAccount && !disable ? null : 'mb-0'}`}>{disable ? t('notsupported_renew_to_add_family_member') : existingAccount != maxAccount ? t('familyplan_copylink_share_account') : t('family_enjoy_exclusive')}</p>
                    {existingAccount != maxAccount && !disable && <>
                        <div className='copyLinkBox'>  
                            <input value={`${childOnboardingData?.url}`} disabled className='referLinkInput'/>
                            <button disabled={disable} onClick={copyLink} id={childOnboardingData?.url} className="copyBtn"></button>
                        </div>
                        <div className="shareLinks">
                            <span className='d-block text-center'>{t('refer_friend_share_directly')}</span>
                            <ul className="list-unstyled list-inline m-0 p-0">
                                <FamilyPlanShareLink hasBg className="list-inline-item"><button className="copyBtn" onClick={() => shareToSocial(childOnboardingData?.url_encode, "facebook")}></button></FamilyPlanShareLink>
                                <FamilyPlanShareLink hasBg className="list-inline-item"><button className="copyBtn" onClick={() => shareToSocial(childOnboardingData?.url_encode, "twitter")}></button></FamilyPlanShareLink>
                                <FamilyPlanShareLink hasBg className="list-inline-item"><button className="copyBtn" onClick={() => shareToSocial(childOnboardingData?.url_encode, "whatsapp")}></button></FamilyPlanShareLink>
                            </ul>
                        </div>
                        </>
                    }
                </InviteFamilyMemberBox>
            </article>
        </div>
    </section>

    :

    <SubscriptionBox open={true}>
        <InfoCard hasborder noflex className="mb-3">
            <Skeleton height={20} width={300}/>
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
            <Skeleton hei   ght={20} width={300}/>
        </InfoCard>
        <InfoCard hasborder noflex className="pt-0 mb-3">
            <Skeleton height={20} width={300}/>
        </InfoCard>
    </SubscriptionBox> 
}

export default FamilyPlanInvite;