import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import { HistoryTable, PopupBody, TableModal } from '../../../../styles/dashboard/Main';
import { DestructBtn } from '../../../../styles/Generic';
import { APIFAILED } from '../../../../tools/responseMessages';
import { dateFormat } from '../../../../tools/tools';
import ErrorBoundary from '../../../ErrorBoundary';

const HistoryModal = ({showHistoryModal,historyData,earnedData,historyError}) => {

    const [modal, setModal] = useState(true);

    const [waitModal,showWaitModal] = useState(false)

    const [membersDetails, setMembersDetails] = useState({})

    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()

    const token = localStorage.getItem("token")

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })
    const widthCalculator = "lg"

    const toggle = () => showHistoryModal(false);


    const formatDate = () => {
        // To ask product for format
    }
    return (
    <Modal backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} modal={modal} toggle={toggle} isOpen={modal}>
        <ModalBody className="pb-0">
            <PopupBody>
                <HistoryTable>
                    <h3>Points History</h3>
                    {historyError ? <ErrorBoundary heading={historyError ?? APIFAILED}></ErrorBoundary>:
                    <Table responsive className='history-table'>
                        <thead>
                            <tr>
                            <th>
                                Date
                            </th>
                            <th>
                                History
                            </th>
                            <th>
                                Points
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                           {historyData?.length ? historyData?.map((data,index)=>{
                                return(
                                    <tr>
                                        <td>
                                            {dateFormat(data?.transactionTime)}
                                        </td>
                                        <td>
                                            {data?.achievementName || "Redeemed"}
                                        </td>
                                        <td>
                                            {data.direction}{data?.points} Points
                                        </td>
                                    </tr>
                                )
                            }):                         
                            <tr>
                                <td colspan="3">
                                    <div id="noDataDisplay">No history available to display</div>
                                </td>
                            </tr>}
                        </tbody>
                    </Table>}
                </HistoryTable>
            </PopupBody>
        </ModalBody>
        <ModalFooter className="pt-0">
            <DestructBtn color="secondary" onClick={toggle}>Close</DestructBtn>
        </ModalFooter>
    </Modal>
    )
}

export default HistoryModal