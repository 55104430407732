import { ERRORS, GETREMOVEMYDATA, LOADING, UPDATEREMOVEMYDATA } from "./types";

const initialState = {
    accountinfo: null,
    loading:false,
    errors:false,
    errorType:"",
    errorMessage:"",
    resetState:false,
}

export const removeMyDataReducer = (state=initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case LOADING:
            return {...state, loading:true}
            break;
        case GETREMOVEMYDATA:
            return  {...state, accountinfo:payload, loading:false, errors:false, resetState: false}
            break;
        case UPDATEREMOVEMYDATA:
            return  {...state, accountinfo:payload, loading:false, errors:false, resetState: false}
            break;
        case ERRORS:
            return {...state, errors: true, errorType: payload.code, errorMessage: payload.message, loading:false, resetState: false}    
        default:
            return  {...state}
            break;
    }
}