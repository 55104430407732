import React, { useEffect, useState } from 'react'
import { OnBoardingLoadingCenter } from '../../../styles/dashboard/Main'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import qs from 'qs';
import NotSupported from '../subscriptions/modals/NotSupported';
import FreeAddonModal from '../subscriptions/modals/FreeAddonModal';
import { Redirect } from 'react-router-dom';
import { useDispatchAsyncCall } from '../../../custom-hooks/useDispatchAsyncCall';
import { getAccountRequested } from '../../../redux/account/actions';
import { bundleOnboardingEligible, bundleType, constants } from '../../../tools/constants';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';

import BundleModal from '../subscriptions/modals/BundleModal';
import { selectSub } from '../../../tools/tools';

const OnBoardingWrapper = ({history, subscriptions, accountReducer, getAccountRequested, onBoardingReducer}) => {

    const { search } = useLocation();

    const { app, utm_campaign, slug, subscriptionId } = Object.fromEntries(new URLSearchParams(search));
    
    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();


    const [modal, setModal] = useState(false);

    const [subscription,setSubscription] = useState({})

    const [modalWidth] = useState({
        lg: "700px",
        sm: "440px"
    })

    const widthCalculator = "lg"

    
    const [modalBunddle, setModalBunddle] = useState(false);

    const [planType,setPlanType] = useState(null)

    const toggleBunddle = () => {
        setModalBunddle(!modalBunddle)
        redirectTo()
    };

    const [modalBunddleWidth] = useState({
        puremax: "710px",
        pureplus: "650px"
    })

    const modalBunddleWidthCalc = "lg"

    const toggle = () => setModal(!modal);
    
    const loading = onBoardingReducer?.onBoardings ? false : true;

    const {data} = useDispatchAsyncCall(getAccountRequested, accountReducer, 'accounts')
    
    const isModalVisible = process.env.REACT_APP_MODAL === "visible" ? true : false

    const redirectTo = (isDb=false) => {

        let appName = (constants.productNames.includes(app) && app != "") ? app : 'purevpn'

        if (appName) {
            let url  = constants.bunddleModal.includes(app) && isDb ? `/dashboard/security-tools` : `/dashboard/onboarding/${appName}`
            history.push(url)
        }
    }
    useEffect(() => {        
            if(data?.status && constants.modalApplicationAddons.includes(slug) && isModalVisible){
                setModal(true)
            }

            if (onBoardingReducer.onBoardings.length > 0 && !modal && !constants.bunddleModal.includes(slug) && (!constants.modalApplicationAddons.includes(slug) || !isModalVisible )) {
                // if sub id is not found route to else block where we redirect the user 
                if(subscriptionId){
                    const filterSubscription = selectSub(onBoardingReducer?.subscriptions,subscriptionId);
                    // if provided sub id doesnot exist in user subs then redirect the user
                    if(filterSubscription && filterSubscription?.hasOwnProperty('plan_type')
                    && bundleOnboardingEligible.includes(filterSubscription?.plan_type)
                    ){
                        setModalBunddle(true)
                        setSubscription(filterSubscription)
                        let plan_type = filterSubscription?.plan_type?.split('_');
                        setPlanType(bundleType[plan_type[plan_type?.length-1]])
                    }else{
                        redirectTo()
                    }
                }else{
                    redirectTo()
                }
            }


    }, [onBoardingReducer, subscriptions,modal,accountReducer])

    const skeleton = (html) => {
        return loading ? (
            <>
                <Skeleton height={20} width={300} className="onboarding-wrapper-loading"/>
                <Skeleton height={20} width={300} className="onboarding-wrapper-loading"/>
            </>
        ) : html
        
    }
    return skeleton(
        <OnBoardingLoadingCenter>
            <div>
            <div className='loader_cogs'>
                <div className='loader_cogs__top'>
                    <div className='top_part'></div>
                    <div className='top_part'></div>
                    <div className='top_part'></div>
                    <div className='top_hole'></div>
                </div>
                <div className='loader_cogs__left'>
                    <div className='left_part'></div>
                    <div className='left_part'></div>
                    <div className='left_part'></div>
                    <div className='left_hole'></div>
                </div>
                <div className='loader_cogs__bottom'>
                    <div className='bottom_part'></div>
                    <div className='bottom_part'></div>
                    <div className='bottom_part'></div>
                    <div className='bottom_hole'></div>
                </div>
            </div>
            <h2 className="mt-3 heading">Please wait</h2>
            <p className='para'>We’re configuring your experience</p>
            </div>        
            {
            modal && <FreeAddonModal backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={modal} toggle={toggle} slug={slug} campaign={utm_campaign} accountData={data?.body?.billing_type} onBoardingReducer={onBoardingReducer}/>
            }
            {
            modalBunddle && <BundleModal backdrop={false} modalWidth={modalBunddleWidth} widthCalculator={widthCalculator} modal={modalBunddle} toggle={toggleBunddle} plan_type={planType} campaign={utm_campaign} accountData={data?.body?.billing_type} onBoardingReducer={onBoardingReducer} subscription={subscription}/>
            }


        </OnBoardingLoadingCenter>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        subscriptions: state.subscriptionsReducer,
        onBoardingReducer: state.onBoardingReducer
    };
};

export default connect(mapStateToProps, {getAccountRequested})(OnBoardingWrapper)