import React from 'react'
import PropTypes from 'prop-types'
import { SVG, Path } from '../../styles/Generic'

const CheckIconSVG = ({ fillColor, width, height, style, className, onClick, strokeColor, strokeWidth }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" style={style} height={height} onClick={onClick} className={className} viewBox="0 0 48 49" width={width}>
    <Path
        fill={fillColor} stroke={strokeColor} stroke-width={strokeWidth}
        d="M22.1376 27.8431L35.1818 14.7965L37.5168 17.1298L21.6072 33.0393L12.4847 23.9168L14.8176 21.5839L21.0769 27.8431L21.6072 28.3735L22.1376 27.8431ZM24 47.75C11.159 47.75 0.75 37.341 0.75 24.5C0.75 11.659 11.159 1.25 24 1.25C36.841 1.25 47.25 11.659 47.25 24.5C47.25 37.341 36.841 47.75 24 47.75ZM24 44.45C29.2911 44.45 34.3654 42.3481 38.1068 38.6068C41.8481 34.8654 43.95 29.7911 43.95 24.5C43.95 19.2089 41.8481 14.1346 38.1068 10.3932C34.3654 6.65187 29.2911 4.55 24 4.55C18.7089 4.55 13.6346 6.65187 9.89322 10.3932C6.15187 14.1346 4.05 19.2089 4.05 24.5C4.05 29.7911 6.15187 34.8654 9.89322 38.6068C13.6346 42.3481 18.7089 44.45 24 44.45Z"
      />
  </SVG>
)
CheckIconSVG.propTypes = {
  fill: PropTypes.string,
}
CheckIconSVG.defaultProps = {
  width: '48',
  height: '49',
  fillColor: '#6536CC',
  strokeColor: '#FBFBFC',
  strokeWidth: '1.5',
  className: 'svg-icon check-icon'
}
export default CheckIconSVG