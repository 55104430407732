import styled from "styled-components";

export const CheckoutLicensesStyles = styled.div`
    button.teams{
        width: 220px;
        border-radius: 30px;
        border: 1px solid ${({theme:{teams:{colors: {success}}}})=>success}; 
        color: ${({theme:{teams:{colors: {white}}}})=>white};
        text-align: center;
        font-size: ${({theme:{teams:{typography: {body}}}})=>body};
        font-weight: 600;
        line-height: 16px;
        &:disabled{
            background: ${({theme:{teams:{colors: {disabled}}}})=>disabled};
            cursor: not-allowed;
            border: 1px solid ${({theme:{teams:{colors: {disabled}}}})=>disabled}; 
        }
        &.secondary{
            background: ${({theme:{teams:{colors: {white}}}})=>white};
            color: ${({theme:{teams:{colors: {success}}}})=>success};
        }
    }

    .error{
        color: ${({theme:{branding: {danger}}})=>danger};
        font-size: 11px;
        margin-top: 5px;
    }

    button.teams{
        @media only screen and (max-width: 490px){
            width: 140px !important;
        }
        @media only screen and (max-width: 340px){
            width: 130px !important;
        }
        @media only screen and (max-width: 340px){
            width: 123px !important;
        }
    }
    .checkout-body{
        padding: 9px;
        h4{
            color: ${({theme:{teams:{colors: {primary}}}})=>primary};
            font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 16px;
        }
        ul.header-list{
            list-style: none;
            padding: 0;
            margin-bottom: 24px;
            li{
                span{
                    width: 110px;
                    display: inline-block;
                    color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
                    font-size: 12px !important;
                    font-weight: 400;
                    line-height: 12px;
                }
                strong{
                    color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px; 
                    
                }
            }
        }
    }
    .counter-box{
        @media only screen and (max-width: 620px){
            margin-top: 20px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

            /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .extra-addon{
            border-radius: 16px;
            border: 2px dashed ${({theme:{teams:{colors: {border}}}})=>border};
            background: ${({theme:{teams:{colors: {white}}}})=>white};
            padding: 24px;
            .extra-addon-box{
                background: ${({theme:{teams:{colors: {background}}}})=>background};
                padding: 24px;
                border-radius: 20px;
                margin-top: 20px;
                opacity: 0.5;
                cursor: not-allowed;
                &.active{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
        .addon-box{
            padding: 24px;
            border-radius: 16px;
            border: 2px dashed ${({theme:{teams:{colors: {border}}}})=>border};
            background: ${({theme:{teams:{colors: {white}}}})=>white};

            .checkout-plan{
                display: flex;
                align-items: center;
                margin-top: 16px;
                gap: 6px;
                p{
                    color: #637381;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    margin: 0;
                }
            }

            @media only screen and (max-width: 380px){
                padding: 10px;
            }

            .info-img{
                margin-left: 5px;
                margin-top: -2px;
                cursor: pointer;
            }

            &.no-border{
                border: none;
            }
            margin-bottom: 8px;
            .links{
                color: ${({theme:{teams:{colors: {links}}}})=>links} !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 12px !important;

                @media only screen and (max-width: 620px){
                    display: block;
                    margin-top: 10px;
                }
            }
            ul{
                padding: 0px;
                list-style: none;
                margin-bottom: 8px;

                li{
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    gap: 10px;
                    h5{
                        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;
                        margin:0;
                        @media only screen and (max-width: 380px){
                            font-size: 14px;
                        }
                    }
                    b{
                        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                    }
                    p{
                        color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        margin: 0;
                    }
                    strong{
                        display: block;
                        width: 150px;
                        text-align: right;
                        @media only screen and (max-width: 380px){
                            width: 80px;
                        }
                    }
                }
            }
        }
        .checkbox-box{
            display: flex;
            align-items: center;
            input{
                width: 14px;
                height: 14px;
                accent-color: #550CCB;
                border-radius: 8px;
            }
            label{
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                margin-left: 8px;
            }
        }
        .counter-container{
            display: flex;
            align-items: center;
            gap: 6px;

            .seats{
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                margin: 0;
                margin-top: 16px;
            }
        }
        .counter{
            display: flex;
            align-items: center;
            gap: 6px;
            max-width: 130px;
            margin-top: 16px;
            input{
                width: 60px;
                height: 30px;
                border-radius: 8px;
                border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border}; 
                background: ${({theme:{teams:{colors: {white}}}})=>white};
                text-align: center;
            }
            .counter-btns{
                color: ${({theme:{teams:{colors: {white}}}})=>white};
                border: 2px solid ${({theme:{teams:{colors: {border}}}})=>border}; 
                background: ${({theme:{teams:{colors: {success}}}})=>success};
                height: 30px;
                width: 30px;
                border-radius: 8px;
                font-size: 16px;
                cursor: pointer;
                &:disabled{
                    background: ${({theme:{teams:{colors: {disabled}}}})=>disabled};
                    cursor: not-allowed;
                    border: 1px solid ${({theme:{teams:{colors: {disabled}}}})=>disabled}; 
                }
            }
        }
        .total{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 32px 0px;
            border-top: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
            border-bottom: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
            padding: 16px 0px;

            @media only screen and (max-width: 490px){
                margin: 20px 0px;
            }

            h5{
                font-size: ${({theme:{teams:{typography: {body}}}})=>body};
                font-weight: 600;
                line-height: 16px; 
                color: ${({theme:{teams:{colors: {primary}}}})=>primary};
                margin: 0;
            }
        }
    }
`

export const HasDedicatedIpViewStyles = styled.div`
    margin-top: 24px;
    p{
        color: #637381;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
    }
    span{
        color: ${({theme:{teams:{colors: {primary}}}})=>primary} !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
    }
    .seperator{
        background: ${({theme:{teams:{colors: {border}}}})=>border};
        width: 2px;
        height: 14px;
        margin: 0px 8px;

    }
    .addon-details{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
`