import React, { useState, Suspense, lazy } from 'react'
import {  InfoCard, PopupBody, CancelReasonOption, ErrorMessage } from '../../../../../styles/dashboard/Main'
import { Modal, ModalBody } from 'reactstrap';
import { DestructBtn, PrimaryBtn, LinkBtn } from '../../../../../styles/Generic';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../../tools/responseMessages';
import { getSubscriptionsRequest } from '../../../../../redux/subscriptions/actions';
import { getCurrentSubscription, poster } from '../../../../../tools/tools';
import qs from 'qs'
import { withRouter, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { sendEvents } from '../../../../../tools/mpEvents'
import successfullyPausedCheck from '../../../../../assets/successfully_paused.png'
import { useTranslation } from 'react-i18next'


const SubscriptionCancelConfirmationModal = ({backdrop, toggle, modal, history, modalWidth, widthCalculator, selectedSubscription, subscriptionsReducer, selectedReason, otherReason, selectedSubscriptionEndDate}) => {

    
    const dispatch = useDispatch()
    
    const [selectedOption, setSelectedOption] = useState(null);

    const { t, i18n } = useTranslation();
    
    const [loadingPauseSubMsg, setloadingPauseSubMsg] = useState(false)
    
    const [error, setError] = useState(false);
    
    const [hasSubmited, setHasSubmited] = useState(false);
    
    const [selectedSubExpiryDate, setSelectedSubExpiryDate] = useState("");
    
    const token = localStorage.getItem("token")
    
    
    const options = [{
            id: 1,
            text : 'No, I just want to stop my auto-recurring.',
            popupTopText: 'Auto-recurring turned off',
            successMessages : 'Turn auto-recurring back on anytime',
            popupPara :'',
            ctaText :'Turn auto-recurring on',
            popupBtmText :`Your subscription will end on ${selectedSubscriptionEndDate}. Make sure you make your payment before that to continue using PureVPN.`,
            toastMessages :'Your subscription auto-recurring has been stopped, you can reactivate it any time.',
        },{
            id: 2,
            text : 'Yes, I want to cancel my subscription',
            popupTopText: 'Your account has been cancelled',
            successMessages : 'Resume your subscription',
            popupPara :'To avoid losing your online freedom and security, turn your subscription on and go care-free on the internet.',
            ctaText :'Resume Subscription',
            popupBtmText :`Your subscription will end on ${selectedSubscriptionEndDate}. Make sure you make your payment before that to continue using PureVPN.`,
            toastMessages :'Your subscription has been canceled, you can reactivate it any time.',
        }
    ]
    const resetstate = () => {
        // seticon("")
        toggle()
    }

    const handleClickTakeBack = () => {
        toggle()
    }

    const handleClickSelectOption = (event) => {
        
        let selectedOptionId = event.target.getAttribute("data-option-number")

        if (selectedOptionId === null) {
            selectedOptionId = event.target.parentNode.getAttribute("data-option-number")

            if (selectedOptionId === null) {
                selectedOptionId = event.target.parentNode.parentNode.getAttribute("data-option-number")
            }
        }

        setError(false)

        setSelectedOption(parseInt(selectedOptionId))
    }

    const renderOptions = () => {
        

        return options.map( (option, k) => {
            let active = selectedOption === k ? "active" : ""

            let className = `p-0`

            if (k % 2 !== 0) {
                className = `p-0 my-3`
            }

            return (
                <InfoCard noflex className={className} key={option.id} >
                    <CancelReasonOption className={active} data-option-number={k} onClick={ (e) => { handleClickSelectOption(e) }}>
                        <p>{option.text}</p><i className='icon'></i>
                    </CancelReasonOption>
                </InfoCard>
            )
        });
    }

    const resumeSubscription = async () => {
        setloadingPauseSubMsg(true);
        const currentSub = getCurrentSubscription(subscriptionsReducer, selectedSubscription)
        
        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription
            })
            
            const reactivate =  await poster("subscription/resume", formdata, token)
            const {data: response} = reactivate;
            
            if(response?.status){
                dispatch(getSubscriptionsRequest(token))
                toast.success("Your subscription has been successfully resumed")
                toggle()
                history.goBack();
            }else{

                sendEvents({
                    event_name:"ma_fail_resume_subscription",
                    event_properties:{
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        biling_cycle: currentSub?.plan?.type ?? "N/A",
                        current_status: currentSub?.state ?? "N/A"
                    }
                }, token)
                
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    case "declined":
                        toast.error(response?.message)
                        break;
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;
                    case "recurly_exception":
                        toast.error(response?.message)
                        break;
                    case "three_d_secure_action_required":
                        toast.error(response?.message)
                        break; 
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;  
                    default:
                        toast.error(response?.message);
                        break;
                }
            }
        } catch (error) {
            toast.error(t(APIFAILED))
        }
        finally{
            setloadingPauseSubMsg(false)
        }
    }

    const pauseSubscription = async () => {
        setloadingPauseSubMsg(true);
        
        const currentSub = getCurrentSubscription(subscriptionsReducer, selectedSubscription)
        
        try {
            const formdata = qs.stringify({
                subscription_id: selectedSubscription.subscription
            })
            const pause =  await poster("subscription/pause", formdata, token)
            const {data: response} = pause;

            if(response?.status){
                toast.success(options[selectedOption].toastMessages)
                dispatch(getSubscriptionsRequest(token))

                setHasSubmited(true)


                if (otherReason !== "") {
                    sendEvents({
                        event_name:"ma_cancel_subscription",
                        event_properties:{
                            button_text: options[selectedOption].id,
                            reason: selectedReason,
                            other_reason: otherReason
                        }
                    }, token)
                } else {
                    sendEvents({
                        event_name:"ma_cancel_subscription",
                        event_properties:{
                            button_text: options[selectedOption].id,
                            reason: selectedReason
                        }
                    }, token)
                }
            } else {

                sendEvents({
                    event_name:"ma_fail_cancel_subscription",
                    event_properties:{
                        payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                        biling_cycle: currentSub?.plan?.type ?? "N/A",
                        current_status: currentSub?.state ?? "N/A",
                        reason:response?.message
                    }
                }, token)

                setHasSubmited(false)

                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;    
                    case "subscription_exception":
                        toast.error(response?.message)
                        break;      
                    case "recurly_exception":
                        toast.error(response?.message)
                        break; 
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                        history.push("/login");       
                        break;
                    default:
                        toast.error(response?.message);
                        break;
                }
            }
        } catch (error) {
            toast.error(t(APIFAILED))
            sendEvents({
                event_name:"ma_fail_cancel_subscription",
                event_properties:{
                    payment_method: currentSub?.billing_info?.last_four ? "stripe" : "Paypal",
                    biling_cycle: currentSub?.plan?.type ?? "N/A",
                    current_status: currentSub?.state ?? "N/A",
                    reason:"API Break or Server is not responding"
                }
            }, token)
            setHasSubmited(false)
        }
        finally{
            setloadingPauseSubMsg(false)
            
        }
    }

    const handleSubmit = () => {
        if (selectedOption !== null) {
            setError(false)
            pauseSubscription()
        } else {
            setError(true)
        }
    }

    return (
        <div>
            <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{maxWidth: `${modalWidth[widthCalculator]}`, width: '100%'}} className="modal-wrapper">
                {
                    !loadingPauseSubMsg
                        
                    ?
                        !hasSubmited
                        ?
                        (
                            
                            <ModalBody>
                                <PopupBody>
                                    <div className="mod-head">
                                        <h3>Are you sure you want to cancel?</h3>
                                    </div>
                                    <div className="mod-body">
                                        {
                                            renderOptions()
                                        }
                                        {
                                            (error)
                                            ?
                                            (<ErrorMessage className="py-0 mt-3"><p>Please select a option</p></ErrorMessage>)
                                            : (<></>)
                                        }
                                        <InfoCard className="py-0 mt-4 cancel-subs-btn">
                                            <PrimaryBtn onClick={() => handleClickTakeBack() }>Take me back</PrimaryBtn>
                                            <DestructBtn type="button" onClick={handleSubmit} className="mx-3 submit-btn">Submit</DestructBtn>
                                        </InfoCard>
                                    </div>
                                </PopupBody>
                            </ModalBody>
                        ) :
                        ( 
                            !loadingPauseSubMsg
                            ?
                            <ModalBody className='text-center'>
                                <PopupBody>
                                    <div className="mod-body">
                                        {
                                            <div>
                                                <img src={successfullyPausedCheck} className="mb-4"/>
                                                <h3 className='mb-4'>{options[selectedOption].popupTopText}</h3>
                                                <h2 key={selectedOption}><strong>{options[selectedOption].successMessages}</strong></h2>
                                                <span>{options[selectedOption].popupPara}</span>
                                                <InfoCard noflex className="py-0 mt-4">
                                                    <PrimaryBtn onClick={() => resumeSubscription() } className="text-center m-auto d-block">{options[selectedOption].ctaText}</PrimaryBtn>
                                                    <Link to="/dashboard/subscriptions" className='mx-3 text-center m-auto mt-3' style={{display: "inline-block"}}>No thanks  I'm okay for now.</Link>
                                                </InfoCard>
                                                <InfoCard className="py-0 mt-3">
                                                    <p dangerouslySetInnerHTML={{ __html: options[selectedOption].popupBtmText }} />
                                                </InfoCard>
                                            </div>
                                        }
                                        
                                    </div>
                                </PopupBody>
                            </ModalBody>
                            :
                            <ModalBody>
                                <div className="mod-body" style={{minHeight:"200px"}}>
                                    {/* <span className="spinner-border"></span> */}
                                    <Skeleton className="d-block mb-3" width={300}/>
                                    <Skeleton className="d-block mb-3" width={280}/>
                                    <Skeleton className="d-block mb-3" width={260}/>
                                    <Skeleton className="d-block mb-3" width={240}/>
                                    <Skeleton className="d-block mb-3" width={220}/>
                                    <Skeleton className="d-block mb-3" width={50}/>
                                </div>
                            </ModalBody>
                            
                        ) 
                    :
                    <ModalBody>
                        <div className="mod-body" style={{minHeight:"200px"}}>
                            {/* <span className="spinner-border"></span> */}
                            <Skeleton className="d-block mb-3" width={300}/>
                            <Skeleton className="d-block mb-3" width={280}/>
                            <Skeleton className="d-block mb-3" width={260}/>
                            <Skeleton className="d-block mb-3" width={240}/>
                            <Skeleton className="d-block mb-3" width={220}/>
                            <Skeleton className="d-block mb-3" width={50}/>
                        </div>
                    </ModalBody>

                }
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
    };
};

export default connect(mapStateToProps, null)(withRouter(SubscriptionCancelConfirmationModal))