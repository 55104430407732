import { constants } from '../../../../../tools/constants';
import { checkAccountType, currencySymbol, getPaymentMethods } from '../../../../../tools/tools';
import { get, isWhmcsUser } from "../../../../../tools/tools";
import { isAndroid, isIOS } from 'react-device-detect';
import { Mark, Cross } from "../../../../../styles/dashboard/pure-teams/prePurchase";
import { Modal, ModalBody } from "reactstrap";
import { PopupBody, InfoModalMobileWrapper } from '../../../../../styles/dashboard/Main';
import { PrimaryBtn } from '../../../../../styles/Generic';
import { sendEvents } from '../../../../../tools/mpEvents';
import { tableContentForApps } from '../../../teams/content';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import CloseIconSVG from "../../../../../assets/svg/CloseIconSVG";

const TeamsInfoModal = ({ redirected_via, currentSub, sendRequestToOpenFpCart, setselectedSubscription,  appEventHandler, backdrop, toggle, modal }) => {
  const { t } = useTranslation();

  const [btnText, setBtnText] = useState(t('upgrade_to_teams'));
  const [loading, setLoading] = useState(true); // State variable for loading
  const [planComparision, setPlanComparision] = useState(null); 
  const [selectedPlan, setSelectedPlan] = useState("teams");
  
  const history = useHistory();

  const plans = ["individual_plan", "family_plan", "teams"]
  const pricing = JSON.parse(process.env.REACT_APP_TEAMS_PRICING);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get(`pure-teams/plans-comparison?plan=current&type=upsell`, token);
      const responseData = response.data; 
      setPlanComparision(responseData?.body?.[currentSub?.id]); 
      setLoading(false); 
    } catch (error) {
      toast.error("Error fetching data");
    }
  }

  const  onClickMetricTeamsFP  = () => {
    let todayDate = new Date().toJSON();

    let plan = planComparision?.familyPlans?.filter((fp) => fp.max_account == process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY);
    setselectedSubscription({
      plan: plan?.[0]?.code, 
      subscription: currentSub?.id,
      currency: currentSub?.currency, 
      interval_length: currentSub?.plan?.interval_length,
      interval_unit: currentSub?.plan?.interval_unit,
      start_date: todayDate,
      expiry_date: currentSub?.end_date,
      isWhmcsUser: isWhmcsUser(currentSub?.account?.billingType), 
      familyPlanPrice: plan?.[0]?.currencies[0]?.unit_amount, 
      plan_type: plan?.[0]?.max_account === process.env.REACT_APP_MAX_ACCOUNT_FOR_FAMILY ? 'family' : null,
      sub_plan_type: currentSub?.plan_type
    });

    sendEvents({
      event_name: "ma_select_account_type",
      event_properties: {
        plan_type: currentSub?.plan_type ?? 'N/A',
        billingcycle: currentSub?.type ?? 'N/A',
        payment_method: getPaymentMethods(currentSub) ?? 'N/A',
        current_status: currentSub?.state ?? 'N/A',
        account_type: checkAccountType(currentSub) ?? 'N/A',
        selected_account: 'family',
        platform_from: redirected_via ? 'Apps' : 'Member Area'
      }
    }, token);

    sendRequestToOpenFpCart();

    if(!redirected_via){
      history.push(`/dashboard/family-plan`)
    }
  }

  const getTableData = (row) => {
    return [row.description[0], row.description[1], row.description[2]]
  }

  const handleMobileCtaClick = () => {
    if (selectedPlan === "family_plan") {
      onClickMetricTeamsFP();
    }
    if (selectedPlan === "teams") {
      teamCtaHandle();
    }
  };
  
  const teamCtaHandle = () => {
    sendEvents({
      event_name: "ma_select_account_type",
      event_properties: {
        plan_type: currentSub?.plan_type ?? 'N/A',
        billingcycle: currentSub?.type ?? 'N/A',
        payment_method: getPaymentMethods(currentSub) ?? 'N/A',
        current_status: currentSub?.state ?? 'N/A',
        account_type: checkAccountType(currentSub) ?? 'N/A',
        selected_account: 'teams',
        platform_from: redirected_via ? 'Apps' : 'Member Area'
      }
    }, token)
    let AppEventsData = {
      type: "show_teams_on_ma",
      data:{
        show_teams: true,
          
      }
    }
    appEventHandler("show_teams_on_ma", AppEventsData);

    if(!redirected_via || isAndroid || isIOS){
      history.push(`/dashboard/teams`)
    }
    
  }

  return (
    <div>
      <Modal backdrop={backdrop} isOpen={modal} toggle={toggle} style={{ maxWidth: `850px`, width: "100%" }} className={`modal-wrapper ${redirected_via ? 'white-bg' : null}`}>
      {!redirected_via && <span className='close-icon' onClick={()=>toggle()}><CloseIconSVG width={24} height={24}/></span>}
        {
          (isAndroid || isIOS) ?
          <ModalBody className="pb-0" style={{height: 'inherit !important'}}>
          {!loading ? ( 
            <PopupBody className='is-dedicated-info-modal'>
            <InfoModalMobileWrapper>
            <span className="d-header">
                <h1 className="heading">{t('teams_modal_heading')}</h1>
            </span>
            <div className="dashboardView ">
              {
                plans.map((plan, index)=> {
                  return (
                    <div
                      key={index}
                      className={`addonBox ${selectedPlan === plan ? "selected" : ""}`}
                      onClick={() => {
                        setSelectedPlan(plan);
                        const newBtnText = plan === "teams" ? t('upgrade_to_teams') : plan === "individual_plan" ? t('dedicated_server_cta') : t('upgrade_to_family');
                        setBtnText(newBtnText);
                    }}
                    >
                      <span className="addon-header">
                                <h6>{plan === "individual_plan" ? t('individual_plan') : plan === "family_plan" ? t('family_plan') : t('teams')}</h6>
                                {
                                  plan === constants.individual_plan  
                                  ? currentSub?.hasFamilyPlan ? <span><p className='priceTag'>--</p></span> : <span className={`purchaseTag text-capitalize`}>{t('current_plan')}</span>
                                  : null
                                }
                                {
                                  plan === 'family_plan'
                                  ? currentSub?.hasFamilyPlan ? <span className={`purchaseTag text-capitalize`}>{t('current_plan')}</span> : <span><p className="priceTag">{`${currencySymbol[currentSub?.currency]}5.99${t('per_mo_per_member')}`}</p></span>
                                  : null
                                }
                                {
                                  plan === 'teams' && <span><p className="priceTag">{`${currencySymbol[currentSub?.currency]}${pricing?.[currentSub?.plan?.interval_length]}${t('per_mo_per_member')}`}</p></span>
                                }
                                
                      </span>
                      <div className='addon-data'>
                        {
                          <ul>
                              {[...tableContentForApps.slice(0,6), {
                                title: "pre_purchase_table_heading_fifteen",
                                description: ['individuals', 'families', 'teams_head', 'enterprise']
                              }].map((content, index) => (
                                  <li key={index}>
                                      <span className='desc'>{t(content?.title)}</span>
                                      <span className='purpleText'>{t(content?.description[plan === 'individual_plan' ? 0 : plan === 'family_plan' ? 1 : 2])}</span>
                                  </li>
                              ))}
                          </ul>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="text-center addonCTA">
                <PrimaryBtn onClick={handleMobileCtaClick} disabled={selectedPlan === "individual_plan" || (selectedPlan === "family_plan" && currentSub?.hasFamilyPlan)} className={selectedPlan === "individual_plan" ||  (selectedPlan === "family_plan" && currentSub?.hasFamilyPlan) ? "disabled-cta" : ""} isDedicatedMobile > {btnText} </PrimaryBtn>
            </div>
        </InfoModalMobileWrapper>
          </PopupBody>
          ) : (
            <div className="mod-body">
            <Skeleton className="d-block mb-3" width={300} />
            <Skeleton className="d-block mb-3" width={280} />
            <Skeleton className="d-block mb-3" width={260} />
            <Skeleton className="d-block mb-3" width={240} />
            <Skeleton className="d-block mb-3" width={220} />
            <Skeleton className="d-block mb-3" width={50} />
          </div>
              
            )}
        </ModalBody>
        :
        <ModalBody className="pb-0" style={{height: 'inherit !important'}}>
        {!loading ? ( 
          <PopupBody className='is-team-comparison-modal'>
          <div className="mod-head">
            <h3 className="text-center heading-text">{t('teams_modal_heading')}</h3>
          </div>

          <div className="mod-body mt-4">
            <div className="table-container">
              <table>
                <tr>
                  {['dedicated_server_modal_table_heading_one',
                    "individual_plan", "family_plan", "teams"].map((addon, index) => {
                      return addon ? <th className={`${index === 0 ? 'featcolumn-padding' : null}`}>{t(addon)}</th> : null;
                    })}
                </tr>
                { 
                  [...tableContentForApps.slice(0,6), {
                    title: "pre_purchase_table_heading_fifteen",
                    description: ['individuals', 'families', 'teams_head', 'enterprise']
                  }].map((row, index) => {
                    return <>
                        <tr>
                        <td className="features-column" style={{fontWeight: 600}}>{t(row.title)}</td>
                            {getTableData(row).map((des, index) => {
                                return <>
                                    <td>{des === 'Yes' ? <><Mark /></> :  des === 'No' ? <><Cross /></> : <><div style={{ fontSize: '14px' }}>{t(des)}</div></>}</td>
                                </>
                            })}
                        </tr>
                    </>
                  })
                }
                <tr>
                  <td className={`features-column`}>{t('price')}</td>
                  <td className={`column-spacing`}><div style={{ fontSize: '14px' }}>$11.95/mo</div></td>
                  <td className={`column-spacing`}><div style={{ fontSize: '14px' }}>{`${currencySymbol[currentSub?.currency]}5.99${t('per_mo_per_member')}`}</div></td>
                  <td className={`column-spacing`}><div style={{ fontSize: '14px' }}>{`${currencySymbol[currentSub?.currency]}${pricing?.[currentSub?.plan?.interval_length]}${t('per_mo_per_member')}`}</div></td>
                </tr>

                <tr>
                    <td className={`features-column`}></td>
                    <td className={`column-spacing`}>{!currentSub?.hasFamilyPlan ? <span className='yellow-text'>{t('current_plan')}</span> : null}</td>
                    <td className={`column-spacing`}>
                      {!currentSub?.hasFamilyPlan ?
                        <PrimaryBtn onClick={() => onClickMetricTeamsFP("familyplan")} teamInfoModal>{t('upgrade_to_family')}</PrimaryBtn>
                      : <span className='yellow-text'>{t('current_plan')}</span>}
                    </td>
                    <td className={`column-spacing`}>
                      {<PrimaryBtn onClick={() => teamCtaHandle()} teamInfoModal>{t('upgrade_to_teams')}</PrimaryBtn>}
                    </td>
                </tr>
                
              </table>
            </div>
          </div>
        </PopupBody>
        ) : (
          <div className="mod-body" >
          <Skeleton className="d-block mb-3" width={300} />
          <Skeleton className="d-block mb-3" width={280} />
          <Skeleton className="d-block mb-3" width={260} />
          <Skeleton className="d-block mb-3" width={240} />
          <Skeleton className="d-block mb-3" width={220} />
          <Skeleton className="d-block mb-3" width={50} />
        </div>
            
          )}
      </ModalBody>
        } 
      </Modal>
    </div>

  );
};

export default TeamsInfoModal;
