import { PostPurchaseStyle } from "../../../styles/dashboard/VolumeLicensing";
import Features from "./features";
import Search from "../../../generic-components/search";
import Accounts from "../../../assets/family1.png";
import { PrimaryBtn } from "../../../styles/Generic";
import Listing from "../../../generic-components/listing";
import Pagination from "../../generic/Pagination";
import { useMemo,useState } from "react";
import { getTokenFromLocalStorage, poster } from "../../../tools/tools"
import { toast } from "react-toastify";
import { APIFAILED } from "../../../tools/responseMessages";
import qs from "qs"
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';


const PostPurchase = ({onAddMoreUsers, data, deleteSelectedMembers, postPurchaseData, setInviteLink, onBoardingReducer, inviteLink, selectedSubscription}) => {

    const [currentPage, setCurrentPage] = useState(1);

    const [listData, setListData] = useState(data);

    let items = postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0];
    let max_slots = items?.max_slots;
    let onboarded_slots = max_slots - items?.remaining_slots;
    let isMaxInvited = items?.remaining_slots == 0

     // ? THIS IS COMMENTED B/C WE MIGHT USE BULK DELETION LATER! 
    // const [checkedItems, setCheckedItems] = useState([]);

    let pageSize = 15;

    const headerList = ['Members', 'Date Added', 'Actions'];

    const token = getTokenFromLocalStorage();

    useEffect(()=>{

        setListData(data);
        // const { selectedSubs, subscriptions: subs } = onBoardingReducer;
        // let sub = subs?.filter((sub) => sub.id === selectedSubs)?.[0];
        if(!inviteLink) sendGetLinkRequest(selectedSubscription);
        
    }, [data])

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return listData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, listData]);

    const onSearch = (search) => {
        if(search.length === 1){setCurrentPage(1)}
        setListData(data.filter(obj=>obj?.email.includes(search)))
    }

    const sendGetLinkRequest = async (sub) => {

        try{
            const formdata = qs.stringify({
                id: sub?.id,
                plan_code: sub?.plan?.code,
                current_plan_currency: sub?.currency,
                current_plan_expiry_date: sub?.expires_at_timestamp,
                max_account: postPurchaseData.items.filter((item)=> item.item_code === 'purevpn')[0]?.max_slots
            })
            const {data: response} = await poster(`volume-licensing/get-link`, formdata, token);

            if(response?.status){
                setInviteLink(response?.body?.url)
            }
            else{
                toast.error(response?.message);
            }
        }
        catch{
            toast.error(APIFAILED);
        }
    
    }

    // ? THIS IS COMMENTED B/C WE MIGHT USE BULK DELETION LATER! 

    // const onSelectCheckbox = (ids) => {

    //     // same function to handle select/deselect
    //     ids.forEach(element => {
    //         if(!checkedItems.includes(element)){
    //             let selectedIds = [...checkedItems];
    //             selectedIds.push(element)
    //             setCheckedItems(selectedIds)
    //         }
    //         else{
    //             let selectedIds = [...checkedItems];
    //             selectedIds.pop(element)
    //             setCheckedItems(selectedIds)
    //         }  
    //     });
        
    // }

    // const onSelectAll = (ids) => {

    //     // if we already have a single selection, remove that and then
    //     if(checkedItems.length > 0){
    //         setCheckedItems([])
    //     }
    
    //     // add all the list for bulk selection
    //     else{
    //         ids.forEach(element => {
    //             let selectedIds = [...checkedItems];
    //             selectedIds.push(element)
    //             setCheckedItems(selectedIds)
    //         });
    //     }
    // }

    const btnText = !inviteLink ? <span className="spinner-border text-light"></span>: "Invite Members" ;

    
    return <PostPurchaseStyle>
        <div className="header">
            <Search showIcon={true} placeholder={'Search members'} onChange={(e)=>onSearch(e.target.value)}/>
            <div className="invite-content">  
                <div className={`users-added ${!isMaxInvited ? null : 'max-added'}`}>
                    <img src={Accounts} alt="total"/>
                    <div>
                        <h5>{`${onboarded_slots}/${max_slots}`}</h5>
                        <p>Members added</p>
                    </div>
                </div>
                {!isMaxInvited && <PrimaryBtn onClick={onAddMoreUsers}>{btnText}</PrimaryBtn>}
            </div>
        </div>

        <div className="member-data">
            <Listing headerList={headerList} currentTableData={currentTableData} onClickDelete={deleteSelectedMembers}/>
        </div>
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={listData.length}
            pageSize={pageSize}
            onPageChange={page => setCurrentPage(page)}
        />
    </PostPurchaseStyle>
    
}
const mapStateToProps = (state) => {
    return {
        onBoardingReducer: state.onBoardingReducer
    };
};
export default connect(mapStateToProps, null)(withRouter(PostPurchase));