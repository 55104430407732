import React, {useState, useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import {Helmet} from "react-helmet";

function ProtectedRoute({ component: Component, title, ...restOfProps }) {

  const token = localStorage.getItem("token");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        token
         ? 
         <>
          <Helmet>
              <title>Member Area | {title}</title>
              <meta name="description" content="PureVPN offers proprietary apps for all leading platforms so you can enjoy complete internet freedom on any device you want!" />
          </Helmet>
          <Component {...props} {...restOfProps} />
         </> 
         : 
         <Redirect to="/login" />
      }
    />
  );
  
}

export default ProtectedRoute;