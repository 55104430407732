import RemoteWork from "../../../assets/volume-licensing/remote-work.png";
import SmallBusiness from "../../../assets/volume-licensing/small-business.png";
import SoftwareTesting from "../../../assets/volume-licensing/software-testing.png";
import Credentials from "../../../assets/volume-licensing/exp_1.png";
import Accounts from "../../../assets/volume-licensing/exp_2.png";
import Flexibility from "../../../assets/volume-licensing/exp_3.png";


export const VolumeLicensingContent = {
    sub_heading: 'Volume Licensing',
    sub_para: 'Bulk purchasing of VPN accounts for multiple members.',
    main_heading: 'Ensure maximum security for your entire team',
    main_para: `A Volume Licensing subscription is the perfect solution for businesses in need of a significant number of licenses, anywhere between ${process.env.REACT_APP_VL_MINIMUM_SLIDER_VALUE} and ${process.env.REACT_APP_VL_MAXIMUM_SLIDER_VALUE}, all conveniently managed under one unified account.`,
    interactive_content: {
        heading: 'Select the number of members:',
        sub_heading: 'Volume Licensing'
    },
    experience: {
        heading: 'Elevate your team\'s experience to new heights!',
        content: [
            {
                heading: 'Individual credentials', 
                paragraph: 'As new members join the team, they can independently set-up their VPN credentials for added security.', 
                image: Credentials,
            },
            {
                heading: 'More accounts, low prices', 
                paragraph: 'With volume licensing, you can get your desired number of individual VPN accounts at the lowest price.', 
                image: Accounts,
            },
            {
                heading: 'Flexibility to choose', 
                paragraph: 'Choose and add your desired number of members in the team.', 
                image: Flexibility,
            },
        ]
    },
    features: {
        heading: 'Who can benefit from Volume Licensing?',
        content: [
            {
                heading: 'Remote Workforce:', 
                paragraph: 'With the rise of remote work, businesses are embracing VPN solutions to protect company resource access. Volume licensing simplifies VPN distribution to remote employees, boosting overall security and productivity.', 
                image: RemoteWork,
                align: 'left'
            },
            {
                heading: 'Small Businesses:', 
                paragraph: 'Small companies choose cloud solutions over complex IT due to cost and safety. However, unsecured networks can make clouds vulnerable. Volume-licensed VPNs boost security against data interception and login spoofing.', 
                image: SmallBusiness,
                align: 'right'
            },
            {
                heading: 'Software Development and Testing:', 
                paragraph: 'In software development, secure code sharing, testing, and server access are essential. Volume licensing VPNs enable secure and convenient access for all team members, ensuring efficient task execution.', 
                image: SoftwareTesting,
                align: 'left'
            },
        ]
    }
}