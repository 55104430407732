import { useTranslation } from "react-i18next";
import { addonNames, constants, featuresNameConstants, securityToolsPlans } from "../../tools/constants";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendEvents } from "../../tools/mpEvents";
import { checkAccountType, getTokenFromLocalStorage } from "../../tools/tools";
import { featureContent } from "./content";

const ToolsBox = ({heading, paragraph, app, slug, history, onBoardingReducer, removePoweredBy}) => {

    const { t } = useTranslation();

    const token = getTokenFromLocalStorage();

    const [selectedSubscription, setSelectedSubscription] = useState();

    useEffect(()=>{
        
        const { selectedSubs, subscriptions: subs, loading } = onBoardingReducer;

        if(!loading && subs?.length){
            setSelectedSubscription(subs.find((sub)=> sub?.id == selectedSubs) ?? subs?.[0])
        }

    }, [onBoardingReducer.selectedSubs, selectedSubscription, onBoardingReducer])

    const clickExploreFeature = () => {
        sendEvents({
            event_name: "ma_click_security_tools",
            event_properties: {
                account_type: checkAccountType(selectedSubscription) ?? 'N/A',
                current_plantype: selectedSubscription?.plan_type ?? 'N/A',
                billingcycle: selectedSubscription?.type ?? 'N/A',
                feature_name: slug ?? 'N/A',
            },
        }, token);

        if(slug === featuresNameConstants['password_manager'] && onBoardingReducer?.subscriptions.filter((sub)=> sub?.add_ons?.find((addon)=> addon?.code === featureContent[slug]?.product) || securityToolsPlans[sub?.plan_type].includes(featureContent[slug]?.product))?.length > 0){
            window.open(constants.purekeepWebDownloadLink);
        }else{
            history.push({
                pathname: `/dashboard/security-tools/${slug}`
            });
        }
    }

    return <div className={`box ${slug}`} onClick={clickExploreFeature}>
        <h4>{heading}</h4>
        <h6>{paragraph}</h6>
        {!removePoweredBy && <div className={`power-icon ${app}`}><span>{t('powered_by', {'app': addonNames[app]})}</span></div>}
    </div>
}

const mapStateToProps = (state) => {
    return { 
        onBoardingReducer: state.onBoardingReducer, 
    };
};


export default connect(mapStateToProps, null)(withRouter(ToolsBox));