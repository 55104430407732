import styled from "styled-components";
import selectcaret from '../../../assets/menu-down.png'

export const ConfigureStyles = styled.div`
    h2{
        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
        font-size: ${({theme:{teams:{typography: {h2}}}})=>h2};;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 8px;
        display: block;
    }
    h4{
        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
        font-size: ${({theme:{teams:{typography: {h4}}}})=>h4};;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 8px;
        display: block;
    }
    p{
        color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
        font-size: ${({theme:{teams:{typography: {h5}}}})=>h5};;
        font-weight: 400;
        line-height: 14px;
    }
    .links{
        color: ${({theme:{teams:{colors: {links}}}})=>links} !important;
        text-decoration: underline;
    }
    button{
        width: 176px;
        padding: 16px 24px;
        &:disabled{
            opacity: 0.2;
            cursor: not-allowed;
        }
    }
    .details-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            margin-bottom: 0px;
        }
    }
    .options-container{
        margin-top: 24px;
        margin-bottom: 4px;
        .red{
            color: #F8463B !important;
        }
        .option-item{
            margin-top: 16px;
            label{
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary};
                font-size:  ${({theme:{teams:{typography: {smallerBody}}}})=>smallerBody};
                font-weight: 400;
                line-height: 12px;
                margin-bottom: 8px;
            }

            select{
                display: flex;
                height: 48px;
                padding: 8px 4px 8px 16px;
                justify-content: space-between;
                align-items: center;
                border-radius: 12px;
                border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
                width: 100%;
                background:#FFFFFF url(${selectcaret}) no-repeat;
                background-position: 98% center;
                text-transform: capitalize;
                color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
                font-size: ${({theme:{teams:{typography: {p}}}})=>p};
                font-weight: 400;
                line-height: 30px;
            }
        }
    }

    .note{
        color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        margin-bottom: 10px;
        display: block;
    }

    .loader-line {
        width: 100%;
        height: 3px;
        position: relative;
        overflow: hidden;
        background-color:  ${({theme:{teams:{colors: {links}}}})=>links};
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        margin-top: 10px;
        margin-bottom: 32px;

        &:before{
            content: "";
            position: absolute;
            left: -50%;
            height: 3px;
            width: 40%;
            background-color:  ${({theme:{teams:{colors: {border}}}})=>border};
            -webkit-animation: lineAnim 2s linear infinite;
            -moz-animation: lineAnim 2s linear infinite;
            animation: lineAnim 2s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }
    }

    @keyframes lineAnim {
        0% {
            left: -40%;
        }
        50% {
            left: 20%;
            width: 80%;
        }
        100% {
            left: 100%;
            width: 100%;
        }
    }
    .dedicated-ip-btn{
        width: 200px;
    }

    // dropdown style
    .basic-single__control{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        width: 100%;
        background:#FFFFFF url(${selectcaret}) no-repeat;
        background-position: 98% center;
        text-transform: capitalize;
        color: ${({theme:{teams:{colors: {secondary}}}})=>secondary} !important;
        font-size: ${({theme:{teams:{typography: {p}}}})=>p};
        font-weight: 400;
        line-height: 30px;
        position: relative;
        .basic-single__input-container{
            height: 48px;
            padding: 5px 4px 8px 16px;
        }
        .basic-single__placeholder{
            height: 48px;
            padding: 8px 4px 8px 16px;
        }
        .basic-single__input{
            height: auto !important;
        }
    }
    .basic-single__menu{
        border-radius: 12px;
        border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        background: ${({theme:{teams:{colors: {white}}}})=>white};
        overflow: hidden;
        padding: 8px 0px;
    }
    .basic-single__option, .basic-single__single-value{
        border-bottom: 1px solid #F4F1FF;
        padding: 2px 16px;
        display: flex;
        align-items: center;
        gap: 0px;
        position: relative;
        p{
            margin: 0;
            color: ${({theme:{teams:{colors: {primary}}}})=>primary};
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }
        span{
            transform: scale(0.35);
            position: absolute;
            top: 6px;
        }
        &.basic-single__option--is-focused{
            background: #F4F1FF;
        }
    }

    .basic-single__single-value{
        span{
            top: 2px;
        }
    }
    .basic-single__value-container{
        .basic-single__single-value{
            border-bottom: none;
        }
    }
    .basic-single__indicators{
        display: none;
    }
    .basic-single__multi-value{
        border-radius: 32px;
        border: 1px solid ${({theme:{teams:{colors: {border}}}})=>border};
        background: ${({theme:{teams:{colors: {background}}}})=>background};
        padding: 9px 8px 8px 10px;
        color: ${({theme:{teams:{colors: {primary}}}})=>primary};
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-left: 4px;
        margin-bottom: 2px;
        margin-top: 2px;
        gap: 8px;
        text-transform: initial;
    }
    .basic-single__option{
        padding: 8px;
    }
    .multi-option{
        p{
            line-height: 20px;
        }
    }
    .flags{
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }
`