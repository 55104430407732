// TEAM SERVER FUNCTIONS
export const teamServerPricing = (selectedSubscriptionData, selectedSubscriptionPlans) => {
    const addon = selectedSubscriptionPlans?.items?.team_server;
    const price = addon?.unit_amount / selectedSubscriptionData?.plan?.interval_length;
    // Check if price is NaN and use static value from env if NaN
    const formattedPrice = isNaN(price) ? parseFloat(process.env.REACT_APP_TEAM_SERVER_PRICE)?.[selectedSubscriptionData?.plan?.interval_length] : price.toFixed(2);
    return formattedPrice;
}
export const minDisabledForTeamServer = (state) => {
    return !state?.current || state?.current <= state?.min
}
export const maxDisabledForTeamServer = (state) => {
    return state?.current >= state?.max
}
export const getMaximumForTeamServer = (state) => {
    return state.max
}
export const getMinimumForTeamServer = (state) => {
    return state.min
}
export const hasErrorForTeamServer = (state) => {
    return state?.current < state.min || state?.current > state.max
}
export const getValueForTeamServer = (state) => {
    return state?.current
}
export const onChangeCounterForTeamServer = (payload) => {
    let {type, dispatch, value} = payload;
    dispatch({ type: type, value: value });
}
export const isDisabledForTeamServer = (state) => {
    return state?.current < state.min ||
    state?.current > state.max
}
export const getTotalAmountForTeamServer = (state) => {
    return (
        (state?.current ? +state?.current * state?.price: 0)
    )
}
export const isNotEmptyForTeamServer = (state) => {
    return !state?.current
}