import React,{useEffect, useState} from 'react'
import { useRecurly } from '@recurly/react-recurly';
import {PrimaryBtn, DestructBtn} from '../../../../styles/Generic'
import { toast } from 'react-toastify';
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../../tools/responseMessages';
import { connect, useDispatch } from 'react-redux';
import { poster, getPaymentMethods, isWhmcsUser, generateCouponEvent } from '../../../../tools/tools';
import qs from 'qs'
import SubmitConsent from '../SubmitConsent';
import {getSubscriptionsRequest} from '../../../../redux/subscriptions/actions'
import { sendEvents } from '../../../../tools/mpEvents';
import WhmcsSubscriptionNotes from '../../../../utils/WhmcsSubscriptionNote';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaypalBtn = ({resetstate, subscriptionsReducer, isAllowToUseParamMethod, selectedData, endPoint, selectedSubscription, renewSubscription, currentSub, sendPaymentMessage, renewFromApp, popupPurchase, chargeAttempt, toggle, subscription_id,subData, canReqStopCollection, isCouponApplied=null, couponEvent}) => {
    const { search } = useLocation();

    const { redirected_via } = Object.fromEntries(new URLSearchParams(search));

    renewFromApp = renewFromApp === "true" ? true: false;

    renewSubscription = renewSubscription === "true" ? true : false

    selectedSubscription = selectedSubscription ?? {}
    subscription_id      = subscription_id ?? null

    const token = localStorage.getItem("token")

    const { t, i18n } = useTranslation();

    const apiEndPoint = endPoint ?? "account/change-payment-method"

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const recurly = useRecurly();

    const history = useHistory();

    const payPal = recurly.PayPal({
        display: {
          displayName: "PureVPN",
          description: "Change your payment Method"
        }
      });

      const changePaymentAsync = async (data) => {
        if(isAllowToUseParamMethod){
            chargeAttempt({...selectedData , ...data,paymentMethodWhmcs:"paypal"})
        }else{
            try {
                if (canReqStopCollection) {
                    const formdata = qs.stringify({
                        subscription_id:selectedSubscription?.subscription ?? '',
                        invoice_id:selectedSubscription?.invoice ?? '',
                    })
                    await poster("subscription/stop-collection", formdata, token)
                }
                if(subscription_id){
                    data['subscription_id'] = subscription_id;
                }
                const formdata = qs.stringify(data)
                const change = await poster(`${apiEndPoint}`, formdata, token)
                const {data: response} = change;
                if(response?.status){
                    dispatch(getSubscriptionsRequest(token))
                    if(!popupPurchase){
                        if(renewSubscription){
                            sendEvents({
                                event_name:"ma_renew_subscription",
                                event_properties:{
                                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                                    payment_gateway: "Paypal",
                                    productid: currentSub?.plan?.code  ,
                                    current_status: "active" ,
                                    invoice_id: currentSub?.renew_invoice,
                                    ...couponEvent,
                                }
                            }, token)
                        }
        
                        renewFromApp ? sendPaymentMessage({message: "Your subscription has been successfully renewed", isPurchasedSucessFully:true}) : toast.success(`${renewSubscription  ? "Your subscription has been successfully renewed" : "Payment Method has been successfully changed."}`)
                        resetstate()
                        setLoading(false);
                        if(apiEndPoint === "subscription/change-pm-and-renew"  && !renewFromApp){
                            history.push("/dashboard/security-tools");
                        }else{
                            // *IF user renewed from in-app modal (app purchase) dont route the user
                            return
                        }
                    }else{
                        chargeAttempt({change_pm:"paypal"})
                    }
                }else{
                    switch (response?.error_code) {
                      case "token_expired":
                          renewFromApp ? sendPaymentMessage({message:TOKENEXPIRED, isPurchasedSucessFully:false}) : toast.error(TOKENEXPIRED);
                          dispatch({type:"LOGOUT"})
                          break;
                      case "token_invalid":
                        renewFromApp ? sendPaymentMessage({message:(t(INVALIDTOKEN)), isPurchasedSucessFully:false}) : toast.error(t(INVALIDTOKEN))
                          dispatch({type:"LOGOUT"})
                          break;
                      case "account_exception":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message)
                          setLoading(false);
                      break;  
                      case "recurly_exception":
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message)
                          setLoading(false);
                      break;  
                      case "jwt_default_exception":
                            renewFromApp ? sendPaymentMessage({message:(t(INVALIDTOKEN)), isPurchasedSucessFully:false}) : toast.error(t(INVALIDTOKEN))
                            dispatch({type:"LOGOUT"})
                            break;
                      default:
                            renewFromApp ? sendPaymentMessage({message:response?.message, isPurchasedSucessFully:false}) : toast.error(response?.message);
                            setLoading(false);
                            break;
                    }
                    if(renewSubscription){
                        sendEvents({
                            event_name:"ma_fail_renew_subscription",
                            event_properties:{
                                billing_cycle: currentSub?.plan?.type ?? "N/A",
                                payment_gateway: "Paypal",
                                productid: currentSub?.plan?.code  ,
                                current_status: currentSub?.state ,
                                invoice_id: currentSub?.renew_invoice,
                                reason: response?.message,
                                ...couponEvent,
                            }
                        }, token)
                    }else{
                        sendEvents({
                            event_name:"ma_fail_changepaymentmethod",
                            event_properties:{
                                reason: response?.message,
                                ...couponEvent,
                            }
                        }, token)
                    }
                }
            } catch (error) {
                renewFromApp ? sendPaymentMessage({message:(t(APIFAILED)), isPurchasedSucessFully:false}) : toast.error(t(APIFAILED))
                if(renewSubscription){
                    sendEvents({
                        event_name:"ma_fail_renew_subscription",
                        event_properties:{
                            billing_cycle: currentSub?.plan?.type ?? "N/A",
                            payment_gateway: "Paypal",
                            productid: currentSub?.plan?.code  ,
                            current_status: currentSub?.state ,
                            invoice_id: currentSub?.renew_invoice,
                            reason: "API break or Server not responding",
                            ...couponEvent
                        }
                    }, token)
                }else{
                    sendEvents({
                        event_name:"ma_fail_changepaymentmethod",
                        event_properties:{
                            reason: "API break or Server not responding",
                            ...couponEvent
                        }
                    }, token)
                }
                setLoading(false);
            }
        }

    }  
    
    const ctaText = loading ? <>{`${renewSubscription || canReqStopCollection ? "Renew Now": popupPurchase ? "Confirm order" : "Change payment method"}`} <span className="spinner-border text-light"></span></> : `${renewSubscription || canReqStopCollection ? "Renew Now": popupPurchase ? "Confirm order" : "Change payment method"}` ;

    useEffect(() => {
        payPal.on('token', token => {
            if(Object.keys(selectedSubscription).length){
                changePaymentAsync({
                    subscription_id:selectedSubscription?.subscription ?? '',
                    invoice_id:selectedSubscription?.invoice ?? '',
                    token_id:token.id, 
                    type: "paypal", 
                    gateway: "paypal",
                    three_d_secure: ""
                })
            }else{
                changePaymentAsync({token_id:token.id, type: "paypal", gateway: "paypal", three_d_secure: ""})
            }
            
        });
    
        payPal.on('error', error => {
          toast.error(error);
          setLoading(false);
        });
    
        payPal.on('cancel', () => {
          toast.error('Canceled, please try again.');
          setLoading(false);
        });
    
        payPal.on('ready', () => {
          console.log('Ready');
        });
    }, [payPal]);

    const handleClick = () => {
        let old_payment_method = subscriptionsReducer?.subscriptions?.body[0].billing_info?.last_four ? "stripe" : "Paypal"

        if(renewSubscription){
            sendEvents({
                event_name:"ma_checkout_renew_subscription",
                event_properties:{
                    billing_cycle: currentSub?.plan?.type ?? "N/A",
                    payment_gateway: currentSub?.payment_gateway,
                    productid: currentSub?.plan?.code  ,
                    current_status: currentSub?.state ,
                    invoice_id: currentSub?.renew_invoice,
                    ...couponEvent
                }
            }, token)
        }else{
            let eventData = {};
            if (selectedData?.subscription_id || subscription_id) {
                const sub_id = selectedData?.subscription_id ?? subscription_id
                let subscription = subscriptionsReducer?.subscriptions?.body.find((subscription) => subscription.id === sub_id)
                old_payment_method = getPaymentMethods(subscription);
            }
            eventData = {
                event_name:"ma_update_paymentmethod",
                event_properties:{
                    old_payment_method,
                    new_payment_method: "Paypal",
                    ...couponEvent,
                }
            } 
            sendEvents(eventData, token) 
        }

        
        payPal.start();
        setLoading(true)
    };

    return (
        <div>
            {
                <WhmcsSubscriptionNotes subscriptionDetails={typeof currentSub === "string" ? subData : currentSub}/>
            }
            <div className="payment-footer">
                <PrimaryBtn className="me-2" pointer={loading} onClick={handleClick}>{ctaText}</PrimaryBtn>
                <DestructBtn pointer={loading} onClick={() => {
                    !popupPurchase ? resetstate() : toggle()
                }}>Cancel</DestructBtn>
                {!redirected_via && <SubmitConsent/>} 
            </div> 
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        subscriptionsReducer: state.subscriptionsReducer
     };
};

export default connect(mapStateToProps, null)(PaypalBtn)