import { PasswordGeneratorStyles } from "../../../../styles/dashboard/security-tools/securityTools";
import { getTokenFromLocalStorage } from "../../../../tools/tools";
import PasswordGenerator from "../../../dashboard/manage-account/PasswordGenerator";
import { useTranslation } from "react-i18next";
import { SecurityToolsContent } from "../../content/index";
import FeatureIntro from "../../featureTemplate/featureIntro";
import FeatureInfo from "../../featureTemplate/featureInfo";

const PasswordGenerators = ({slug}) => {

    const { t } = useTranslation();

    const token = getTokenFromLocalStorage();

    return  <>
    
        <FeatureIntro slug={slug} />

        <PasswordGeneratorStyles>
            <PasswordGenerator token={token}/>
        </PasswordGeneratorStyles>

        <FeatureInfo slug={slug} />
    </>
}
export default PasswordGenerators;