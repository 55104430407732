import React, { useState, useEffect } from 'react'
import { AccountWrapper, CardBox, DashboardHead, InfoCard, Input } from '../../../styles/dashboard/Main'
import { CheckBoxLabel, PrimaryBtn, SecondryBtn } from '../../../styles/Generic'
import { Link, NavLink } from 'react-router-dom'
import Aside from '../layout/Aside'
import { poster, getByParam } from '../../../tools/tools';
import {toast} from 'react-toastify'
import Skeleton from 'react-loading-skeleton';
import qs from 'qs';
import { APIFAILED, INVALIDTOKEN, SUCCESS, TOKENEXPIRED } from '../../../tools/responseMessages'
import { connect, useDispatch } from 'react-redux'
import { usernameRequested, getAccountRequested, subscriptionRequested } from '../../../redux/account/actions'
import { getReferRequested } from '../../../redux/refer/actions'
import ErrorBoundary from '../../ErrorBoundary'
import { sendEvents, waitForMixpanelEvent } from '../../../tools/mpEvents'
import DomeForm from '../layout/partials/DomeForm'
import { useTranslation } from 'react-i18next';
const ManageAccount = ({history, usernameRequested, getAccountRequested, accountReducer, subscriptionRequested, getReferRequested}) => {
    const [asideType] = useState("")

    const { t, i18n } = useTranslation();

    const [crashed, setCrashed] = useState(false)

    const [domeModal, setDomeModal] = useState(false)

    const [subscribeChecked, setSubscribedChecked] = useState(JSON.parse(localStorage.getItem('email_subscribed')));

    const dispatch = useDispatch()

    const [data, setData] = useState({
        response: {},
        loading: accountReducer.accounts ? false : true
    })
    
    const {response, loading} = data;

    const token = localStorage.getItem("token");

    const [cbLoading, setcbLoading] = useState(false)

    const [unLoading, setunLoading] = useState(false)

    const [logoutLoading, setLogoutLoading] = useState(false)

    const [tfaStatus, setTfaStatus] = useState(null);

    const [tfaLoading, setTfaLoading] = useState(+process.env.REACT_APP_SHOW_TWO_FA);
    
    const showMFAOnMemberArea = +process.env.REACT_APP_SHOW_TWO_FA;

    const updateUsername = (e) => {
        const fullName = e.target.value;
        setData({
            ...data,
            response:{...data.response, fullName: fullName}
        })
    }

    const handleBlur = async (e) => {
        if((e.target.value.trim() === `${data.response.firstName} ${data.response.lastName}` && e.target.value !== "") || e.target.value === "") {
            return
        }
        const regex = /^[A-Za-z\s]+$/
        if(!e.target.value.match(regex)){
            toast.error("Characters only")
            return;
        }
        setunLoading(true)
        const fullName = e.target.value;
        setData({
            ...data,
            response:{...data.response, fullName: fullName}
        })
        const [first, ...rest] = fullName.trim().split(" ").filter(name => name !== "");
        try{
            const formdata = qs.stringify({
                "first_name": first,
                "last_name": rest.join(" ").trim() || " "
            });
            const subscribe = await poster("account/update-name", formdata, token);
            const {data: response} = subscribe;
            if(response?.status){
                toast.success(t(SUCCESS))
                usernameRequested({fullName: `${first} ${rest.join(" ").trim() || " "}`, first:first, last: rest.join(" ").trim() || " "})
            }else{
                switch (response?.error_code) {
                    case "validation_exception":
                        Object.values(response?.errors).map(e=>toast.error(e[0]))
                        break;
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;    
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
        }catch(err){ 
            toast.error(t(APIFAILED));
        }
        finally{
            setunLoading(false)
        }
    }

    const getMultiFactorStatus = async (e) => {
        let uuid = accountReducer?.accounts?.body?.code;
        try {
            const mfStatus = await getByParam('account/multifactor-status', { uuid }, token);
            const { data: response } = mfStatus;
            
            if(response.body){
                if (Array.isArray(response.body) && response.body.length > 0) {
                    // TFA is enabled, as the body is an array with elements
                    setTfaStatus('Enabled');
                } else {
                    // TFA is disabled, as the body is an empty array
                    setTfaStatus('Disabled');
                }

            }else{
                    switch (response?.error_code) {
                    case 'token_expired':
                        toast.error(t(TOKENEXPIRED));
                        dispatch({ type: 'LOGOUT' });
                        break;
                    case 'token_invalid':
                        toast.error(t(INVALIDTOKEN));
                        dispatch({ type: 'LOGOUT' });
                        break;
                    case 'jwt_default_exception':
                        toast.error(t(INVALIDTOKEN));
                        dispatch({ type: 'LOGOUT' });
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }

            }
            // Set TFA loading to false when response is received
            setTfaLoading(false);
        } catch (err) {
            toast.error(t(APIFAILED)); 
        } finally {
            // Set TFA loading to false even in case of an error
            setTfaLoading(false);
        }
    };


    const updateSubscription = async (e) => {
        setcbLoading(true);
        try{
            const subscribe = await poster("campaign/email/resubscribe", '', token);
            const {data: response} = subscribe;
            if(response?.status){
                toast.success(t(SUCCESS))
                localStorage.setItem("email_subscribed", true)
                setSubscribedChecked(true)
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(t(TOKENEXPIRED));
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                          
                        break;    
                    case "jwt_default_exception":
                        toast.error(t(INVALIDTOKEN))
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
        }catch(err){ 
            toast.error(t(APIFAILED));
        }
        finally{
            setcbLoading(false);
        }
    }

    useEffect(() => {
        getAccountRequested(token)
    }, [])
    

    useEffect(() => {
        const { loading: reduxLoading, accounts, errors, errorType, errorMessage } = accountReducer;
        if(accounts){
            setData({
                ...data, 
                response:accounts.body,
                loading:false
            });
            if (showMFAOnMemberArea) {
                getMultiFactorStatus();
            }
        }
        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                           
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break;    
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN))
                    dispatch({type:"LOGOUT"})
                           
                    break; 
                case "APIFAILED":
                    // toast.error(APIFAILED)
                    setCrashed(true)
                    break;     
                default:
                    // toast.error(APIFAILED);
                    setCrashed(true)
                    break;
            }
        }
    }, [accountReducer])

    const skeleton = (html) => {
        return loading ? <Skeleton height={20} width={300} className="account-loading"/> : html
    }

    const buttonText = () => {
        return !logoutLoading ? t('manage_account_logout') : ( <>{t('manage_account_logout')} <span className="spinner-border text-dark spinner-border-sm ms-1"></span></>);
    }

    const logoutSubmit = () => {
        setLogoutLoading(true)
        dispatch({type:"LOGOUT"})
        // localStorage.clear();
        // history.push('/login')
    }

    const joinSlack = () => {
        sendEvents({
            event_name:"ma_account_joinslack",
            event_properties:{}
        }, token)
        window.open("https://join.slack.com/t/purevpnusers/shared_invite/zt-f93zt101-IxdEy7MyEgaDB59RyVZBcw")
    }

    const copyEmail = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success(t('copied_to_clipboard_text'))
        } catch (error) {
            toast.success(t('unable_to_copy_text'))
        }
    }

    if(crashed) {
        return <ErrorBoundary logoutSubmit={logoutSubmit}></ErrorBoundary>
    }

    const handleMfaEvents = async () => {
        if (tfaStatus === 'Disabled') {
            await waitForMixpanelEvent({
                event_name: "ma_click_enable_2fa",
                event_properties: {}
            }, token);
        } else {
            await waitForMixpanelEvent({
                event_name: "ma_click_disable_2fa",
                event_properties: {}
            }, token);
        }
        window.open(process.env.REACT_APP_TWOFA_LINK, "_self");
        
    };

    return (
        <div>
            <DashboardHead>
                <h2>Manage Account</h2>
                <p>{t('manage_account_para')}</p>
            </DashboardHead>
            {
                !tfaLoading ? 
                <AccountWrapper fullWidth={true}>
                <CardBox>
                    <InfoCard hasborder opacity={unLoading.toString()} className="pt-0">
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <label className="me-2">{t('tbl_name')}</label>
                                        <Input type="text" id="username" autoComplete="off" placeholder="Account Username" disabled={unLoading} value={response?.fullName || ""} onChange={updateUsername} onBlur={handleBlur}/>
                                    </div>
                                    <div className="float-end">
                                        {
                                            unLoading && <span className="spinner-border text-dark spinner-border-sm"></span>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </InfoCard>
                    <InfoCard hasborder>
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <label className="me-2">{t('tbl_email')} <strong className="emailCopyField" onDoubleClick={copyEmail} id={response?.email}>{response?.email || "-"}</strong></label>
                                    </div>
                                    <div className="float-none"></div>
                                </>
                            )
                        }
                    </InfoCard>
                    <InfoCard hasborder>
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <label className="me-2">{t('tbl_password')} <strong>***************</strong></label>
                                    </div>
                                    <div className="float-end">
                                        <NavLink exact to="account/change-password">{t('manage_account_change_password_cta')}</NavLink>
                                    </div>
                                    <div className="float-none"></div>
                                </>
                            )
                        }
                    </InfoCard>
                    <InfoCard hasborder>
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <label className="me-2">{t('manage_account_subscriptions')}<strong>{response?.total_subscriptions || "-"}</strong></label>
                                    </div>
                                    <div className="float-end">
                                        <Link to="/dashboard/subscriptions">{t('manage_account_see_all_subs')}</Link>
                                    </div>
                                    <div className="float-none"></div>
                                </>
                            )
                        }
                    </InfoCard>
                        {
                            showMFAOnMemberArea && tfaStatus ? (
                                <InfoCard hasborder>
                                {skeleton(
                                    <>
                                    <div className="float-start">
                                        <label className="me-2">
                                        {t('manage_account_2fa')}: <strong>{tfaStatus === 'Disabled' ? t('manage_account_2fa_disabled_text') : t('manage_account_2fa_enabled_text')}</strong>
                                        </label>
                                    </div>
                                    <div className="float-end">
                                        <Link onClick={handleMfaEvents}>
                                            {
                                                tfaStatus === 'Disabled' ? t('manage_account_2fa_enabled') : t('manage_account_2fa_disabled')
                                            }
                                        </Link>
                                    </div>
                                    <div className="float-none"></div>
                                    </>
                                )}
                                </InfoCard>
                            ) : null
                        }
                    <InfoCard hasborder>
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <p className="mb-0">{t('manage_account_slack_para')}</p>
                                    </div>
                                    <div className="float-end">
                                        <PrimaryBtn className="join" onClick={joinSlack}>{t('manage_account_slack_cta')}</PrimaryBtn>
                                    </div>
                                    <div className="float-none"></div>
                                </>
                            )
                        }
                    </InfoCard>
                    <InfoCard >
                        {
                            skeleton(
                                <>
                                    <div className="float-start">
                                        <div className="checkbox">
                                            <CheckBoxLabel loading={(cbLoading) ? 1 : 0}>
                                                <input type="checkbox" checked={subscribeChecked} className="me-2 form-check-input" onChange={updateSubscription} disabled={subscribeChecked}/> 
                                                <span>
                                                    {t('manage_account_terms_and_condition')}
                                                </span>
                                            </CheckBoxLabel>
                                        </div>
                                    </div>
                                    {
                                        cbLoading && 
                                        <div className="float-end">
                                            <span className="spinner-border text-dark spinner-border-sm"></span>
                                        </div>
                                    }
                                    <div className="float-none"></div>
                                </>
                            )
                        }
                    </InfoCard>
                    <InfoCard className="pb-0">
                        <div className="float-start">
                            <SecondryBtn pointer={logoutLoading} onClick={logoutSubmit}>{buttonText()}</SecondryBtn>
                        </div>
                    </InfoCard>
                </CardBox>
                <Aside type={asideType} setDomeModal={setDomeModal}/>
            </AccountWrapper>
            :
            <div className="row">
                    <div className="col-md-9">
                        <CardBox open={true}>
                            <InfoCard hasborder noflex className="mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard hasborder noflex className="pt-0 mb-3">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                            <InfoCard noflex className="pt-0">
                                <Skeleton height={20} width={300}/>
                            </InfoCard>
                        </CardBox>
                    </div>
                </div>
            }
            {
                domeModal && <DomeForm modal={domeModal} setDomeModal={setDomeModal}></DomeForm>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { accountReducer: state.accountReducer };
};


export default connect(mapStateToProps, {getAccountRequested, subscriptionRequested, usernameRequested, getReferRequested})(ManageAccount)