import React, { useEffect, useState } from 'react'
import { InfoCard, MediaObjectViewWrapper, SubscriptionCancelWrapper } from '../../../styles/dashboard/Main'
import { LinkBtn, PrimaryBtn, SecondryBtn } from '../../../styles/Generic'
import { connect, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { Input } from 'reactstrap';
import { useRef } from 'react';
import { countriesList } from '../../../tools/countries';
import QRcodeGenerator from './QRcodeGenerator';
import { sendEvents } from '../../../tools/mpEvents';
import ShortcutModal from './ShortcutModal';
import { channelSports } from './channelSport';
import { useTranslation } from 'react-i18next';
import { getLocaleFromUrl } from '../../../tools/tools';

const MediaObjectView = ({history, displayData,connectionType,channels,view, key, setFireChannelEvent, onBoardingReducer, step, selectedStep, selectedSubStep, subStepCallback, selectStepCallback, sendNextOrNoOrNoButtonEvents,countries_data,setSelectedDevice,purpose}) => {
    const token = localStorage.getItem("token");
    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();

    const [showQR , setShowQR] = useState(false);
    const [deepLinkURL , setDeepLink] = useState(false);

    const [myData,setMyData] = useState(displayData);
    const [unitStep,setUnitStep] = useState(0);

    const [state, setState] = useState({
        channelName: null,
        countryName: null
      })
    const [selectedShortcut , setSelectedShortcut] = useState(false);
    const [modal, setModal] = useState(false);


    const [firstFire , setfirstFire] = useState(true);

    const visible = useRef(true);
    const firstRun = useRef(true);

    const dispatch = useDispatch()

    const [channelList,setChannelList] = useState({});
    const [channelName,setChannelName] = useState([]);
    const [countriesList,setCountriesList] = useState([]);

    const [channelError,setChannelError] = useState(false);
    const [countryError,setCountryError] = useState(false);

    const [errorDescChannel,setErrorDescChannel] = useState('');
    const [selectedChannelSports,setSelectedChannelSports] = useState(false)

    const [doMessage , setDoMessage] = useState(false);
    const [pureDeepLink] = useState("purevpn://purelinks/shortcut/")

    const [idleState , setIdleState] = useState(false);

    const [counter , setCounter] = useState(0);
    
    useEffect(() => {
        setInterval(() => {
            setCounter(counter => counter + 1);
        }, 1000)
    }, [])

    useEffect(() => {
        if (counter >= 20) {
            setIdleState(true)
        }
    }, [counter])

    useEffect(()=>{
        if(Object.keys(channels?.channels).length !== 0){
            setChannelList(channels?.channels);
            setChannelName(Object.keys(channels?.channels));
            setChannelError(false);
        }
        if(channels.errors){
            setChannelError(true);
            setErrorDescChannel(channels.errorMessage);
        }

    },[channels])

    useEffect(()=>{
        if(countries_data?.country?.length){
            setCountriesList(countries_data?.country);
            setCountryError(false);
        }
        if(countries_data.errors){
            setCountryError(true);
            setErrorDescChannel(countries_data.errorMessage);
        }
    },[countries_data])
    
    const loading = myData?.length > 0 ? false : true;
    
    const skeleton = (html) => {
        return loading ? (
            <>
                <Skeleton height={20} width={300} className="onboarding-media-obj-view-wrapper-loading"/>
            </>
        ) : html
    }

    const openChat = () => {
        if(window.Intercom){
            window.Intercom('show')
        }
    }

    const deepLink = () => {
        window.open('https://app.adjust.com/95ck1js?campaign=Activation&adgroup=MA_onboarding&creative=Connect&redirect_windows=purevpn://purelinks/connect&redirect_macos=purevpn://purelinks/connect&redirect_android=purevpn://purelinks/connect&redirect_ios=purevpn://purelinks/connect', '_blank')
    }

    const buttonHandler = (index, operations, button_text, heading = "",shortcut) => {

        const DesiredOutcome = operations == "DOmessage" && !doMessage ;
        setCounter(0);
        if (operations.includes("support") ) {
            openChat(); 
            if(!operations.includes("skip")){
                return 
            }
            else{
                sendEvents(
                    {
                        event_name: `ma_onboarding_click_purevpn_connection`,
                        event_properties: {
                            question: doMessage ? 'Is your desired outcome achieved?' : heading,
                            cta: "no",
                        }
                    }, token
                    );
            }
        }
        if (operations.includes("connect")){
            deepLink();
        }
        let nextStep = true;
        if(view==="connection" && DesiredOutcome){
            nextStep = false
            sendEvents(
                {
                    event_name: `ma_onboarding_click_purevpn_connection`,
                    event_properties: {
                        question: heading,
                        cta: "Yes",
                    }
                }, token
                );
        }
        if(view==="connection" && nextStep){
            setMyData(myData.slice(1))
        }
        if(firstRun.current && operations!="skip" && operations!="popup"  && connectionType==="channel" && view==="connection"){
            hideSelect();
            connectShortcut()
        }
        if(nextStep){
            firstRun.current=false;
            setUnitStep(unitStep=>unitStep+1);
            operations = (operations) ? operations : "";
            sendNextOrNoOrNoButtonEvents(selectedStep, button_text, operations, heading, !unitStep ? purpose.includes('sports')?selectedChannelSports:shortcut: null)
        }
        if (operations.includes("next") || operations.includes("popup")) {
            let totalSubSteps = myData.length;

            if (operations.includes("chatbot")) {
                openChat()
            }
            if (index + 1 < totalSubSteps) {
                subStepCallback(index + 1)
            } else {
                selectStepCallback(selectedStep + 1)
            }
        } else if (operations.includes("skip")) {
            selectStepCallback(selectedStep + 1)
        } else {
            if( view === "connection" && DesiredOutcome){
                setDoMessage(true);
            }else{
                if(doMessage){
                    sendEvents(
                        {
                            event_name: `ma_onboarding_click_purevpn_connection`,
                            event_properties: {
                                question: "Is your desired outcome achieved?",
                                cta:"yes"
                            }
                        }, token
                        );
                }
                selectStepCallback(selectedStep + 1)
            }
        }
    }

    const hideSelect = () =>{
        if(visible.current){
        }
        visible.current = false;
    }

    const toggle = () => setModal(!modal);
    
    const secondBtn = () =>{ 
        if(firstFire){
            sendEvents(
                {
                    event_name: `ma_onboarding_click_purevpn_connection`,
                    event_properties: {
                        connect_via: "shortcut",
                        selected_shortcut: purpose.includes('sports')?selectedChannelSports:state.channelName,
                        selected_purpose: purpose,
                        selected_device: "Mobile",
                    }
                }, token
                );
            }
        setfirstFire(false)
        setShowQR(!showQR)
            return 
        
    }

    const shortcutModal = (index, operations, button_text, heading = "",shortcut,device) => {
        sendNextOrNoOrNoButtonEvents(selectedStep, button_text, operations, heading, !unitStep ? shortcut: null)
        setModal(true)
    }

    const generateButtons = (data, idx) => {

        let firstButton = null
        let secondButton = null
        let skipButton = null
        let DOtext = null
        if (data.button_one_text) {

            if (data.button_one_operations.includes("question")) {
                skipButton = (
                    connectionType === "channel" && view==="connection" ?
                    <LinkBtn className='btn-modal' modalButton={true} onClick={() => shortcutModal(idx, "skip", "I can’t find my shortcut", data?.heading)}>{t('onboarding_purevpn_connection_cant_find_my_shortcut')}</LinkBtn>
                    :
                    null
                )
            }
            if (data.button_one_operations.includes("DOmessage")) {
                    DOtext = (
                    view === "connection" && doMessage ?
                    <p className='DOtext'>{t('onboarding_purevpn_connection_do_message')}</p>
                    :
                    null
                )
            }

            firstButton = (
                connectionType === "channel" && view==="connection"?
                <PrimaryBtn className='btn-next' opaque={connectionType === "channel" && showConnectButton()} disabled={showConnectButton()}  onClick={() => {buttonHandler(idx, data.button_one_operations, data.button_one_text, data?.heading,state.channelName);}}>{data.button_one_text}</PrimaryBtn>
                :
                <PrimaryBtn className='btn-next' onClick={() => buttonHandler(idx, data.button_one_operations, data.button_one_text, data?.heading)}>{data.button_one_text}</PrimaryBtn>
            )
        }
        if (data.button_two_text) {
            if (data.button_two_operations.includes("question")) {
                skipButton = (
                    connectionType === "channel" && view==="connection"?
                    <LinkBtn className='btn-skip' onClick={() => buttonHandler(idx, "skip", "I haven't connected yet", data?.heading)}>I haven't connected yet</LinkBtn>
                    :
                    null
                )
            }

            secondButton = (
                connectionType === "channel" && view==="connection" && firstRun.current?
                <SecondryBtn className={`btn-next tooltipp ${showQR && "myclass"} d-none d-lg-block`}  opaque={connectionType === "channel" && showConnectButton()} disabled={showConnectButton()} onClick={() => secondBtn()}>{data.button_two_text}
                   {deepLinkURL &&  <span className="tooltipptext">
                    <p>Scan QR Code</p>
                    <div className='my-3'>

                        <QRcodeGenerator value={deepLinkURL}/>
                    </div>
                        </span>}
                </SecondryBtn> 
                :
                <LinkBtn className='btn-skip' onClick={() => buttonHandler(idx, data.button_two_operations, data.button_two_text, data?.heading)}>{data.button_two_text}</LinkBtn>
            )
        }

        if (firstButton || secondButton) {
            return (
                <>
                    {
                        (DOtext != null) ? (
                            <div className='button-inner-box'>
                                {DOtext}
                            </div>
                        ) : null
                    }
                    {
                        (skipButton != null) ? (
                            <div className='button-inner-box'>
                                {skipButton}
                            </div>
                        ) : null
                    }
                    <div className='button-box' >
                        {
                            (firstButton != null) ? firstButton : null
                        }
                        {
                            (secondButton != null) ? secondButton : null
                        }
                    </div>
                </>
                
            )
        } else  {
            return null
        }

        
    }
    const selectedChannel = (e) =>{
        showConnectButton()
        setfirstFire(true)
        const channelName = e.target[e.target.selectedIndex].getAttribute('data-isChannelName');
        
        setState({
                ...state,
                [e.target.name]: e.target.value
              });
        setSelectedChannelSports(channelName)


        if(purpose.includes('sports')){
            let deepLink = pureDeepLink+`${e.target.value}`
            setDeepLink(deepLink)
            return;
        }

        else
        {
            const countryName = {[e.target.name]: e.target.value}['countryName'];
            const stateVal = {...state,
                [e.target.name]: e.target.value}
            if(stateVal?.channelName && stateVal?.countryName){
                let channelName = stateVal?.channelName.replace(/\s+/g, '_')
                let channelInfoFound = channelList[channelName][channelName+"_"+stateVal?.countryName] 
                let channelInfoNotFound = channelList[channelName] 

                if(channelInfoFound){
                    let deepLink = pureDeepLink+`${channelInfoFound.id}`
                    setDeepLink(deepLink)
                }
                if(!channelInfoFound){
                    let defaultChannelInfo = Object.keys(channelInfoNotFound);
                    let channelInfo = channelInfoNotFound[defaultChannelInfo[0]];
                    let deepLink = pureDeepLink+`${channelInfo.slug.replace(/\s+/g, '')}/${channelInfo.channel_url}/${stateVal.countryName}`
                    setDeepLink(deepLink)
                }
            }
        }
    }
    const showConnectButton = () =>{
        if(purpose.includes('sports')){
            return !state.channelName 
        }
        else{
            return !state.channelName || !state.countryName
        }
    }

    const connectShortcut = () => { 
        setSelectedDevice("Desktop")
        setSelectedShortcut(state.channelName)
        window.location.href = deepLinkURL;
    }

    const sportsStream_sec = ['movies or TV shows','sports']
    const sportsStream_one = ['shortcut and location','shortcut']


    return skeleton(

        <MediaObjectViewWrapper className='row align-items-center'>
            {

            myData?.length > 0 ? (
                    <>
                        <div className='col-md-6 image-box'>
                            {
                                myData.map( (data, idx) => {
                                    return (
                                        view === "login" ?
                                        (<img onClick={deepLink} src={data.image} className={`${ ( idx == selectedSubStep ) ? "" : "d-none" }` } key={idx} />)
                                        :
                                        <img src={data.image} className={`${ ( idx == selectedSubStep ) ? "" : "d-none" }`} key={idx} />
                                    )
                                })
                            }
                        </div>
                        <div className='col-md-6'>
                            {
                                myData.map( (data, idx) => {
                                    if (idx == selectedSubStep) {
                                        return (
                                            <div key={idx} className={`box`}>
                                                {
                                                    myData.length > 0
                                                    ?
                                                    (
                                                    <span className={`bold ${ (myData.length == 1 && firstRun.current) ? "opacity-low" : "" }`}>{`< `}{unitStep + 1}/{ displayData.length }{` >`}</span>
                                                    ) : null
                                                }
                                                <h2 className='heading'>{data.heading}</h2>
                                                {data.text.split(".").length>2 ? 
                                                data.text.split(".").slice(0,(data.text.split(".").length-1)).map((para,item)=>(
                                                     <p key={item} className={`para my-${item===0?0:3}`}>{purpose.includes('sports')? para.replace('movies or TV shows','sports') : para}.</p>
                                                ))
                                                :
                                                <p className='para'>{purpose.includes('sports')? data.text.replace('shortcut and location','shortcut') : data.text}</p>}

                                                {connectionType === "channel" && view==="connection" && firstRun.current && 
                                                <div className='col-lg-6 col-sm-12'>

                                                {
                                                purpose.includes('sports')?
                                                <>
                                                <Input type='select' className='channeSelect mt-3' onChange={selectedChannel} name="channelName" value={state.channelName} placeholder="Select Channel" disabled={channelSports.length===0}>
                                                    <option value="" selected disabled hidden>Choose Your Shortcut</option>

                                                        {channelSports?.length && channelSports?.map((data,index)=>(
                                                            data && <option key={index} value={data.id}  data-isChannelName={data.slug}>{data.name}</option>
                                                            ))}
                                                </Input>  
                                                </>
                                                :
                                                <>
                                                <Input type='select' className='channeSelect mt-3' onChange={selectedChannel} name="channelName" value={state.channelName} placeholder="Select Channel" disabled={channelName.length===0 || channelError}>
                                                <option value="" selected disabled hidden>Choose Your Shortcut</option>

                                                    {channelName?.length && channelName?.map((data,index)=>(
                                                        data && <option key={index}>{data.replaceAll("_"," ")}</option>
                                                        ))}
                                                </Input>                                                

                                                <Input type='select' className='channeSelect mt-3' onChange={selectedChannel} name="countryName" value={state.countryName} disabled={countriesList.length===0 || countryError}>
                                                <option value="" selected disabled hidden>Choose Your Location</option>
                                                    {countriesList?.length && countriesList?.map((data,index)=>(
                                                        data && <option key={index} value={data.iso_code}>{data.name}</option>
                                                        ))}
                                                </Input>
                                                </>
                                                }

                                                {!purpose.includes('sports') && (channelError || countryError) &&
                                                <SubscriptionCancelWrapper coloredVal fullWidth>
                                                <InfoCard className="d-block py-0 pt-3">
                                                    <div className="warning-box">
                                                        <span className="warning-icon"></span>
                                                        <p className="warning-text">
                                                        {errorDescChannel}
                                                        </p>
                                                    </div>
                                                </InfoCard>
                                                </SubscriptionCancelWrapper>}

                                                </div>
                                                    }

                                                {
                                                   generateButtons(data, idx)
                                                }

                                                {
                                                    idleState && (
                                                        <div className='facing-issues-wrapper' onClick={ () => openChat() }>
                                                            <LinkBtn>
                                                            {t('mediaobj_facing_issues')}
                                                                <div className='tooltip-box'>
                                                                    <p>{t('mediaobj_facing_issues_stuck')}</p>
                                                                </div>
                                                            </LinkBtn>
                                                        </div>
                                                    )
                                                }

                                                {modal && <ShortcutModal modal={modal} toggle={toggle}/>}
                                                
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                    </>
                ) : null
                
            }
        </MediaObjectViewWrapper>
    )
}

const mapStateToProps = (state) => {
    return { 
        accountReducer: state.accountReducer,
        onBoardingReducer: state.onBoardingReducer,
        channels: state.channelsReducer,
        countries_data: state.country
    };
};

export default connect(mapStateToProps, null)(MediaObjectView)