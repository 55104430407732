import React from 'react'
import { InfoCard } from '../../../../styles/dashboard/Main'
import { LinkBtn } from '../../../../styles/Generic'
import { getSubPlanTypeToDisplay } from '../../../../tools/tools'
import { withRouter } from 'react-router-dom'
import { onClickGetSS } from '../../upgrade/upgrade-tools'
import { useTranslation } from 'react-i18next'

const BundleField = ({subscription, history}) => {
  const { t, i18n } = useTranslation();

  return (
    <InfoCard hasborder>
        <div className="float-start">
            <label className="me-2">{t('plan_type_text')} <strong>{getSubPlanTypeToDisplay(subscription?.plan_type) ?? "Standard"}</strong></label>
        </div>
        <div className="float-end">
        <LinkBtn onClick={(e)=> onClickGetSS('subscription page', history)}>{t('get_security_suite_text')}</LinkBtn>
        </div>
        <div className="float-none"></div>
    </InfoCard>
  )
}

export default withRouter(BundleField)