import React, { useEffect, useState } from 'react'
import { CardBox, DashboardHead, InfoCard, TicketBox, TicketWrapper } from '../../../styles/dashboard/Main'
import usericon from '../../../assets/usericon.jpg'
import pureicon from '../../../assets/pureicon.jpg'
import {PrimaryBtn} from '../../../styles/Generic'
import { poster } from '../../../tools/tools'
import { useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { getTicketDetailsRequested, setTicketDetailsType } from '../../../redux/tickets/actions'
import { APIFAILED, INVALIDTOKEN, TOKENEXPIRED } from '../../../tools/responseMessages'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import qs from 'qs'
import ErrorBoundary from '../../ErrorBoundary'
import { useTranslation } from 'react-i18next';

const TicketDetails = ({history, getTicketDetailsRequested, tickets: reduxData}) => {

    const [crashed, setCrashed] = useState(false)

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)

    const [submitLoading, setSubmitLoading] = useState(false)

    const [data, setdata] = useState([])

    const token = localStorage.getItem("token")

    const ticket_author_id = localStorage.getItem("ticket_author_id")

    const {id} = useParams()

    const [comment, setcomment] = useState({
        text:"",
        error:false
    })

    const handleBlur = (e) => {
        setcomment({
            ...comment,
            error: comment.text === "" ? true : false
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoading(true)
        try {
            if(comment.text === ""){
                setcomment({
                    ...comment,
                    error: true
                })
                return;
            }
            const formdata = qs.stringify({
                comment: comment.text,
                author_id: (reduxData?.details[id]?.body?.author_id !== "") ? reduxData?.details[id]?.body?.author_id : ticket_author_id
            })
            const commentAPI = await poster(`tickets/${id}/comment`, formdata, token)
            const {data: response} = commentAPI;
            if(response?.status){
                dispatch(setTicketDetailsType(response));
                setcomment({...comment, text:""})
            }else{
                switch (response?.error_code) {
                    case "token_expired":
                        toast.error(TOKENEXPIRED);
                        dispatch({type:"LOGOUT"})
                               
                        break;
                    case "token_invalid":
                        toast.error(t(INVALIDTOKEN)) 
                        dispatch({type:"LOGOUT"})
                               
                        break;  
                    case "validation_exception":
                        toast.error("The given data was invalid.")     
                        break;      
                    default:
                        toast.error(t(APIFAILED));
                        break;
                }
            }
        } catch (error) {
            toast.error(t(APIFAILED))
        }finally{
            setSubmitLoading(false)
        }
    }

    const ButtonText = !submitLoading ? t('submit_text') : ( <>{t('helpdesk_createticket_cta_text_load')} <span className="spinner-border text-light"></span></>);

    useEffect(() => {
        getTicketDetailsRequested({token, id})
    }, [])

    useEffect(() => {
        const { details, errors, errorType, errorMessage } = reduxData;
        if(details && details[id]){
            setdata(details[id]?.body || [])
            setLoading(false)
        }

        if(errors) {
            switch (errorType) {
                case "token_expired":
                    toast.error(t(TOKENEXPIRED));
                    dispatch({type:"LOGOUT"})
                    break;
                case "intercom_exception":
                    toast.error(errorMessage);
                    history.push("/dashboard/tickets");
                    break;
                case "jwt_exception":
                    toast.error(t(APIFAILED))
                    dispatch({type:"LOGOUT"})
                    break; 
                case "token_invalid":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type:"LOGOUT"})
                    break; 
                case "jwt_default_exception":
                    toast.error(t(INVALIDTOKEN)) 
                    dispatch({type:"LOGOUT"})
                    break;      
                case "APIFAILED":
                    setCrashed(true)
                    break;     
                default:
                    setCrashed(true)
                    break;
            }
        }
    }, [reduxData])

    if(crashed){
        return <ErrorBoundary></ErrorBoundary>
    }

    return (
        <>
            {
                !loading
                ?
                <TicketWrapper>
                    <DashboardHead hasBorder>
                        <div className="d-flex align-items-center">
                            <div className="me-auto">
                                <h2>Re: {data?.title || "-"}</h2>    
                                <p>{data?.created_at || "-"} <span className="badge text-capitalize">{data?.status || "-"}</span></p>
                            </div>
                        </div>
                    </DashboardHead>
                    <div className="row">
                        <div className="col-md-9">
                        
                        {
                            data.comments &&
                            data?.comments.map((comment, key)=>(
                                <TicketBox key={key}>
                                    <div className="me-3 flex-shrink-0">
                                        <img src={comment.author.email === "feedback@purevpn.com" ? pureicon : usericon} className="img-fluid"/>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h3>{comment?.author?.name || "PureVPN User"}</h3>
                                        <h5>{comment?.created_at || "-"}</h5>
                                        <p className="m-0 comment-box" dangerouslySetInnerHTML={{ __html: comment?.body.replace(/\n/g,"<br />")  || "-" }}></p>
                                    </div>
                                </TicketBox>
                            ))
                        }
                        {
                            data?.status !== "closed" &&
                            <TicketBox>
                                <div className="me-3 flex-shrink-0">
                                    <img src={usericon} className="img-fluid"/>
                                </div>
                                <div className="flex-grow-1">
                                    <InfoCard noflex className="p-0">
                                    <textarea className="form-control" row="20" placeholder="Enter your reply" onBlur={handleBlur} onChange={(e) => setcomment({...comment, text: e.target.value})} value={comment.text}></textarea>
                                    {
                                        comment.error && <p className="danger mt-2">{t('helpdesk_ticketdetails_validation_message')}</p>
                                    }
                                    </InfoCard>
                                    <PrimaryBtn onClick={handleSubmit} className="mt-5">{ButtonText}</PrimaryBtn>
                                </div>
                            </TicketBox>
                        }
                        </div>
                    </div>
                </TicketWrapper>
            :
            <CardBox>
                <InfoCard hasborder noflex className="mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard hasborder noflex className="pt-0 mb-3">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
                <InfoCard noflex className="pt-0">
                    <Skeleton height={20} width={300}/>
                </InfoCard>
            </CardBox>
            }
            
        </>
    )
}

const mapStateToProps = (state) => {
    return { tickets: state.ticketsReducer };
};

export default connect(mapStateToProps, {getTicketDetailsRequested})(TicketDetails)