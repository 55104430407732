import { ERRORS, GETSUBSCRIPTIONS, SETSUBSCRIPTIONVIAONBOARDING, GETSUBSCRIPTIONSREQUEST, LOADING, RESETSUBSCRIPTIONPAYLOAD, RESETSUBSCRIPTIONREQUEST, SETSUBSCRIPTIONBYPAYLOAD } from "./types"

export const getSubscriptionsRequest = (payload) => {

    return{
        type:GETSUBSCRIPTIONSREQUEST,
        payload
    }
}

export const resetSubscriptionRequest = () => {
    return{
        type:RESETSUBSCRIPTIONREQUEST
    }
}

export const resetSubscriptionsPayload = () => {
    return{
        type:RESETSUBSCRIPTIONPAYLOAD
    }
}

export const getSubscriptionsType = (payload) => {
    return{
        type:GETSUBSCRIPTIONS,
        payload
    }
}

export const loadingType = () => {
    return{
        type:LOADING
    }
}

export const errorsType = (payload) => {
    return{
        type:ERRORS,
        payload
    }
}

export const setSubscriptionType = (payload) => {
    return{
        type:SETSUBSCRIPTIONBYPAYLOAD,
        payload
    }
}

export const setSubscriptionViaOnBoarding = (payload) => {
    return{
        type:SETSUBSCRIPTIONVIAONBOARDING,
        payload
    }
}