import { addonConstants } from "../../../tools/constants";
import { DestructBtn, InsetBtn, PrimaryBtn } from "../../../styles/Generic";
import { getTokenFromLocalStorage, sendEvents } from "../subscriptions/cancellation";
import { SubscriptionBox } from "../../../styles/dashboard/Main";
import { useTranslation } from 'react-i18next';
import AddonPurchase from "../subscriptions/modals/AddonPurchase";
import CitiesBox from "./Cities";
import CountryFlag from "./Flag";
import DownloadModal from "./modal/DownloadConfigModal";
import Info from "../plan-type-upgrade/modals/info";
import React, { useState, useEffect } from "react";


const CountryBox = ({
	item,
	data,
	openVpnConfig,
	childFunc,
	openSub,
	handleClick,
	showFilters,
	pfAndActive,
	isPfSub,
	currentSubData
}) => {
	
	const token = getTokenFromLocalStorage();

	const [state, setState] = useState("");
	const [changeBtn , setChangeBtn] = useState(false);
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState("");
	const [configData, setConfigData] = useState("");
	const [availableProtocols,setAvailableProtocols] = useState([])
	const [serverType,setServerType] = useState([])
	const { t } = useTranslation();
	const [multiLoginErrorModal, setmultiLoginErrorModal] = useState({
		open: false,
		content: ''
	});
	const [orderSummary,setorderSummary] = useState(true);
	const [openAddonModal, setOpenAddonModal] = useState();
	const [modalType, setmodalType] = useState("sm");
	const [addonType, setaddonType] = useState({
        type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:"",
    });

	const showCities = ({ target: { id } }) => {
		const checkState = state !== id ? id : "";
		setState(checkState);
		setChangeBtn(!changeBtn);
	};

	useEffect(() => {
		childFunc.current = resetState;
	}, [item]);

	const resetState = () => {
		setState("");
	};

	const toggle = () => setModal(!modal);

	const openModal = (data, value) => {
		value === "country"
			? setModalData({ name: data.name, iso: data.iso })
			: setModalData({
					name: data.name,
					iso: data.iso,
					city: {
						id: data.id,
						name: data.cityName,
						countryName: data.countryName,
					},
			  });
		setServerType(data.feature)
		setConfigData({ protocols: data.protocols });
		setAvailableProtocols(data.protocols );
		toggle();
	};
	const isPFAppliedAndActive = () => {
		return (isPfSub && !pfAndActive) || (!pfAndActive) || isPfSub
	}
	const widthCalculator = isPFAppliedAndActive() ? "sm":"lg"

	const [modalWidth] = useState({
		lg: "675px",
		sm: "440px",
	});

	const showLocations = (country) =>{
		return country.popularity || country.popularity === 0
				? 
				!showFilters
				? 
				country?.cities?.length > 1
				? 
				`${country?.cities?.length} ${t('manualconf_locations')} `
				: 
				`${country?.cities?.length} ${t('manualconf_location')} `
				: 
				country?.filteredCities?.length > 0
				? 
				`${country?.filteredCities?.length} ${t('manualconf_locations')} `
				: 
				""
				:
				"";
	}

	const activateShowCities = (e,country) => {
		if((country?.popularity ||
			country?.popularity === 0) && 
			(country.cities.lenght>0 || country.filteredCities.lenght>0))
		{
			showCities(e)
		}

		if(showFilters && country?.filteredCities?.length>0){
			showCities(e);
		}

	}

	const openMultiLoginModal = (content) => {
		content ? setmultiLoginErrorModal({open: true, content: content}) : setmultiLoginErrorModal({open: false, content: ''})
	}

	const multiLoginErrorPopupContent = () => {

		const has_multi_login_addon = currentSubData?.add_ons.filter(addons => addons.code === addonConstants?.multi_login)?.length > 0 ;
		return (
			<>
				<div className="info-content mb-4">
					<div className="info-title text-center">
						<h3 className="mb-3">{t('manualconf_multi_login_error_title')}</h3>
					</div>
					<div className="info-body">
						<div className="info-body-text text-center">
							<h6>{has_multi_login_addon ? t('manualconf_multi_login_error_purchased') : t('manualconf_multi_login_error_notpurchased')}</h6>
						</div>
					</div>
				</div>
				<div className="info-btns m-auto d-flex gap-2 justify-content-center mt-3">
					<PrimaryBtn onClick={()=>has_multi_login_addon ? openIntercom() : redirectToMultiLoginCart()}>{has_multi_login_addon ? t('errorscreen_talk_to_support'): t('dedicated_server_cta')}</PrimaryBtn>
					<DestructBtn onClick={onCancelMultiLoginPopup}>{t('cancel_text')}</DestructBtn>
				</div>
			</>
		);
	}

	const onCancelMultiLoginPopup = () => {
		openMultiLoginModal();
		sendEvents({
			event_name: "ma_click_multilogin_pop _up",
			event_properties: {
				cta: 'close'
			},
		  }, token);
	}

	const redirectToMultiLoginCart = () => {
		onProceedToMultiLoginPopup('Buy Now')
		setaddonType({
			type: addonConstants?.multi_login,
			subscription: currentSubData?.id,
			currency: currentSubData?.currency,
			plan: currentSubData?.plan?.code,
			gateway:currentSubData?.payment_gateway,
		});
		setOpenAddonModal(true)
	}

	const openIntercom = () => {
		onProceedToMultiLoginPopup('Talk to support')
		if(window.Intercom){
            window.Intercom('show')
        }
	}

	const onProceedToMultiLoginPopup = (cta) => {
		openMultiLoginModal();
		sendEvents({
			event_name: "ma_click_multilogin_pop _up",
			event_properties: {
				cta
			},
		  }, token);
	}

	const toggleAddonModal = () => {
		setOpenAddonModal(!openAddonModal)
	}

	const multiLoginLimitExceededError = () => {
		sendEvents({
			  event_name: "ma_view_multilogin_popup",
			  event_properties: {},
			}, token);
		// toggle to close the configuration modal
		toggle();
		openMultiLoginModal(multiLoginErrorPopupContent())
	}

	return (
		<>

			{data?.map((country, key) => (
				<>
					{country && (
						<SubscriptionBox
							key={country?.iso_code}
							className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3"
							open={country?.cities && state === `sub${key}`}
							hover={true}
						>
						<span>
							<div className="subscription-head country">
								<span id={`sub${key}`} className={(country?.popularity ||country?.popularity === 0) && "flagPointer"}>
									<CountryFlag
										iso={country?.iso_code?.toLowerCase()}
									/>
								</span>
								<div
									className={
										item === "country"
											? "countryData"
											: "cityData"
									}
								>
									<div className="content">
										<div className="clickCountry">
											{showFilters ? (
												<h4
													id={`sub${key}`}
													onClick={(e) =>
														activateShowCities(e,country)
													}
												>
													{country?.name}
												</h4>
											) : country?.cities ? (
												<h3
												id={`sub${key}`}
													onClick={(e) =>
														showCities(e)
													}
												>
													{country?.name}
												</h3>
											) : (
												<h4>{country?.name}</h4>
											)}
											<p className="mt-1">
												{showLocations(country)}
												{/* FEATURE TAGS */}
												<span className="d-none">
												{country?.feature?.length >
												0 ? (
													<>
														{" "}
														{!openSub &&
														!showFilters ? (
															<span className="seperator"> | </span>
														) : (
															country
																?.filteredCities
																?.length >
																0 && (
																<span className="seperator"> | </span>
															)
														)}
														
														{[...new Set(country?.feature)].map((ft,index)=>(
														<span
															key={index}
															className="feature me-2"
															id={ft.toUpperCase()==="OVPN_OBF"? "OBF":ft.toUpperCase()}
															onClick={
																!openSub &&
																handleClick
															}
														>
															{ft.toUpperCase()==="OVPN_OBF"? "OBF":ft.toUpperCase()}
														</span>
														))}
													</>
												) : (
													(country?.feature[0]?.includes(
														"p2p"
													) ||
														country?.feature[0]?.includes(
															"QR"
														)) && (
														<span className="feature test">
															{country?.feature[0].toUpperCase()}
														</span>
													)
												)}
												</span>
											</p>
										</div>
										{/* <div> */}
											{showFilters ? (
												country?.filteredCities
													?.length > 0 ? (
													<div className="d-flex flex-row-reverse align-items-center">
													<InsetBtn
														id={`sub${key}`}
														onClick={showCities}
														className="countryButton"
														>
													</InsetBtn>
													{state === `sub${key}` && <InsetBtn
													onClick={() =>
														openModal(
															{
																name: country?.name,
																iso: country?.iso_code,
																protocols:
																	country?.protocols,
																feature:country?.feature
															},
															"country"
														)
													}
													>
														{t('download_text')}
													</InsetBtn>}
													</div>

												) : 
													!country.popularity ? (
														<InsetBtn
															onClick={() =>
																openModal(
																	{
																		cityName:
																			country.name,
																		name: country.countryName,
																		id: country.id,
																		iso: country.iso_code,
																		protocols:
																			country.protocols,
																		feature:country?.feature
																	},
																	"city"
																)
															}
														>
															{t('download_text')}
														</InsetBtn>
													):
													<InsetBtn
													onClick={() =>
														openModal(
															{
																name: country?.name,
																iso: country?.iso_code,
																protocols:
																	country?.protocols,
																feature:country?.feature
															},
															"country"
														)
													}
													>
														{t('download_text')}
													</InsetBtn>
												
											) : country?.cities ? (
												<div className="d-flex flex-row-reverse align-items-center">
												<InsetBtn
													id={`sub${key}`}
													onClick={showCities}
													className="countryButton"
												>
												</InsetBtn>
												{state === `sub${key}` && <InsetBtn
													onClick={() =>
														openModal(
															{
																name: country?.name,
																iso: country?.iso_code,
																protocols:
																	country?.protocols,
																	feature:country?.feature
															},
															"country"
														)
													}
													>
														{t('download_text')}
													</InsetBtn>}
												</div>
											) : (
												<InsetBtn
													onClick={() =>
														openModal(
															{
																name: country.countryName,
																cityName:
																	country.name,
																id: country.id,
																iso: country.iso_code,
																protocols:
																	country.protocols,
																	feature:country?.feature
															},
															"city"
														)
													}
												>
													{t('download_text')}
												</InsetBtn>
											)}
									</div>
								</div>
							</div>
							</span>
							{country?.cities && (
								<div
									className={
										state === `sub${key}`
											? "subscription-body show"
											: "subscription-body"
									}
									id={`sub${key}`}
								>
									{showFilters
										? country?.filteredCities.length > 0 &&
										  country?.filteredCities?.map(
												(city, index) =>
													city && (
														<CitiesBox
															key={index}
															data={city}
															country={
																country?.name
															}
															iso={
																country?.iso_code
															}
															openVpnConfig={
																openVpnConfig
															}
															getCityInfo={
																openModal
															}
															openSub={openSub}
															handleClick={handleClick}
														/>
													)
										  )
										: country?.cities &&
										  country?.cities?.map(
												(city, index) =>
													city && (
														<CitiesBox
															key={index}
															data={city}
															country={
																country?.name
															}
															iso={
																country?.iso_code
															}
															openVpnConfig={
																openVpnConfig
															}
															getCityInfo={
																openModal
															}
															openSub={openSub}
															handleClick={handleClick}
														/>
													)
										  )}
								</div>
							)}
						</SubscriptionBox>
					)}
				</>
			))}

			{ modal && modalData && (
				<DownloadModal
					backdrop={false}
					modalWidth={modalWidth}
					widthCalculator={widthCalculator}
					modalType={modalType}
					modal={modal}
					toggle={toggle}
					data={modalData}
					fileData={configData.protocols}
					iso={modalData.iso}
					openVpnConfig={openVpnConfig}
					availableProtocols={availableProtocols}
					serverType={serverType}
					getServer={showFilters}
					pfAndActive={pfAndActive}
					isPfSub={isPfSub}
					isPFAppliedAndActive={isPFAppliedAndActive}
					currentSubData={currentSubData}
					multiLoginLimitExceededError={multiLoginLimitExceededError}
				/>
			)}

			{multiLoginErrorModal.open && <Info content={multiLoginErrorModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={"lg"} modal={multiLoginErrorModal.open} toggle={openMultiLoginModal} /> }
			{openAddonModal && <AddonPurchase showInfo={true} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={"lg"} modalType={modalType} modal={openAddonModal} toggle={toggleAddonModal} setorderSummary={setorderSummary} orderSummary={orderSummary} redirectedFrom={"manualConfig"}/>}
		</>
	);
};

export default CountryBox;
