import { connect } from 'react-redux';
import { Game } from '../../../../styles/dashboard/PureAI';

const GameView = ({ pureAIStateReducer, details }) => {
    const gameDetails = details.message;
    return (
        <Game>
            {
                gameDetails.map((details) => {
                    return (
                        <div className="container-main">
                            <div className="image-wrapper">
                                <img src={ details.thumbnail } alt={ details.title } />
                            </div>
                            <div className="content-wrapper">
                                <table className="details">
                                    <tbody>
                                        <tr className="details-tr" data-game-id={ details.gameID }>
                                            <td>Name</td>
                                            <td>{ details.title }</td>
                                        </tr>
                                        <tr className="details-tr">
                                            <td>Publisher</td>
                                            <td>{ details.publisher }</td>
                                        </tr>
                                        <tr className="details-tr">
                                            <td>Release Date</td>
                                            <td>{ details.release_date }</td>
                                        </tr>
                                         {
                                            details.gameInfo &&
                                            <tr className="details-tr">
                                                <td>Steam Rating Text</td>
                                                <td>{ details.steamRatingPercent }</td>
                                            </tr>
                                         }
                                    </tbody>
                                </table>
                            </div>
                            <div className="deal-wrapper">
                                <div className="pricing">
                                    <b className="new-price">{ details.sale_price }</b>
                                    {
                                        details.sale_price !== details.price &&
                                        <b className="old-price">{ details.price }</b>
                                    }
                                </div>
                                <a className="deal-btn" target="_blank" rel="noreferrer" href={ details.link }>Get This Deal</a>
                            </div>
                        </div>
                    )
                })
            }
        </Game>
    )
}


const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(GameView);