import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { LazyImg, PopupBody,LazyIframe } from '../../../styles/dashboard/Main'
import { DestructBtn, LinkBtn, PrimaryBtn } from '../../../styles/Generic'
import { imageLoaded } from '../../../tools/genericMethods'
import inviteBanner from '../../../assets/invite_members_popup_banner.svg'
import { getLocaleFromUrl } from '../../../tools/tools';
import { useTranslation } from 'react-i18next';

const ShortcutModal = ({modal,toggle }) => {

    const { t, i18n } = useTranslation();

    const locale = getLocaleFromUrl();
    
    const [showVideo, setShowVideo] = useState(true);

    const buttonVideoClickHandler = (status) => {
        setShowVideo(status)
    }

    return (
        <div>
            <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: '675px', width: '100%'}} className="modal-wrapper">
                {

                    <>
                        <ModalBody className='pb-0' closeButton>
                            <PopupBody>
                                
                                    <>
                                        <div className="mod-head mb-0 text-center">

                                            <h2>Create your own shortcut</h2>
                                            <p className='mb-1'>Simply follow the steps shown below.</p>
                                        </div>
                                        <div className='mod-body py-0'>
                                        <div className='video-bg d-flex justify-content-center'>
                                            {
                                                <LazyIframe
                                                width="560" 
                                                height="315" 
                                                src="https://www.youtube.com/embed/ZeDyf6dAllM?autoplay=1&controls=0&showinfo=0" 
                                                title="YouTube video player" 
                                                frameBorder="0" 
                                                onLoad={(e) => imageLoaded(e)}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" 
                                                allowFullScreen/>

                                            }
                                        </div>


                                        </div>
                                        
                                    </>
                            </PopupBody>
                        </ModalBody>
                        <ModalFooter className="py-0 justify-content-center">
                            {
                                    <PrimaryBtn className='mt-2' onClick={toggle} type="button">Got it</PrimaryBtn>
                            }
                        </ModalFooter>
                    </>
                }
            </Modal>
        </div>
    )
}

export default ShortcutModal