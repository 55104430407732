import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { generateChatId } from './helper';
import { ACTIONS } from '../../../../redux/pureai/reducer';
import { PopularPrompts } from './content';
import { Link } from 'react-router-dom';
import { SuggestionsBarView, InputBox } from '../../../../styles/dashboard/PureAI';
import RatingStars from './RatingStars'
import CloseIconSVG from '../../../../assets/svg/CloseIconSVG'
import faqs_buddy from '../../../../assets/faqs_buddy.svg'
import feedback_buddy from '../../../../assets/feedback_buddy.svg'
import star2 from '../../../../assets/star2.svg'
import streamingImg from '../../../../assets/streaming.svg'
import gamingImg from '../../../../assets/gaming.svg'
import securityImg from '../../../../assets/security.svg'
import securityImgActive from '../../../../assets/security-white.svg'
import gamingImgActive from '../../../../assets/gaming-white.svg'
import streamingImgActive from '../../../../assets/streaming-white.svg'
import { getCookie, setCookie } from '../../../../tools/tools';
import { sendMPEvents } from './helper';

const SuggestionsBar = ({ pureAIStateReducer, inputRef, modal = false, showMenuAction = () => {} }) => {

    const dispatch = useDispatch();

    const [ showFeedbackBox, setShowFeedbackBox ] = useState(false);

    const { currentState } = pureAIStateReducer

    const searchTerm = useRef(null);

    const feedbackRatingRef = useRef(0);

    const [feedbackRatingErr, setFeedbackRatingErr] = useState(null);

    const [filteredCategory, setFilteredCategory] = useState(null);

    const feedbackRef = useRef(null);

    const [ filteredPrompts, setFilteredPrompts ] = useState(PopularPrompts);

    const newChatAction = () => {
        dispatch({type: ACTIONS.NEW_CHAT, chatId: generateChatId()});
    }

    const handleSearch = () => {
        const filtered = PopularPrompts.filter(
            (prompt) => prompt.prompt.toLowerCase().includes(searchTerm?.current?.value.toLowerCase())
        );
        setFilteredPrompts(filtered);
    }

    const filterPrompts = (category) => {
        if(category !== filteredCategory) {
            const filtered = PopularPrompts.filter(
                (prompt) => prompt.category.toLocaleLowerCase() === category.toLocaleLowerCase()
            );
            setFilteredPrompts(filtered);
            setFilteredCategory(category);
        } else {
            setFilteredPrompts(PopularPrompts);
            setFilteredCategory(null);
        }
    }

    const getModulus = () => {
        let inc = localStorage.getItem('incrementer');
        if (inc === null) {
            localStorage.setItem('incrementer', 1);
            return true;
        } else {
            var count = parseInt(inc,10);
            localStorage.setItem('incrementer', count+1);
            return count % 5 === 0 ? true : false;
        }
    }

    const clickPrompt = (prompt) => {
        inputRef.current.value = prompt.prompt;
        inputRef.current.focus();
        showMenuAction();
        sendMPEvents("ma_pureai_click_prompt", {
            prompt_type: "recommended",
            selected_interface_screen: "chat-board",
            category: prompt.category,
            prompt: prompt ?? "N/A"
        })
    }

    const fireClick = (searchTerm) => {
        searchTerm.current.value = "";
        handleSearch();
    }

    const noDisplay = () => {
        return (
            <tr className="tr-error">
                <td colSpan="2">
                    <div className='no-search-result'>
                        <span>No Result Found for:</span>
                        <strong>{searchTerm?.current?.value}</strong>
                        <Link className='mt-2' onClick={()=>fireClick(searchTerm)}>Clear Search</Link>
                    </div>
                </td>
            </tr>
        );
    }

    const redirectCta = (isFaq = false) => {
        sendMPEvents("ma_pureai_cta_click",{
            cta:isFaq?"faq":"feedback"
        });
        if(isFaq){
            window.open("/v2/dashboard/pure-ai-faqs?redirect_from=true", "_blank");
        }else{
            window.open("https://purevpn-beta.typeform.com/to/t0yXdlYD", "_blank");
        }
    }

    const submitFeedback = (e) => {
        e.preventDefault();
        if(parseInt(feedbackRatingRef?.current, 10) === 0){
            setFeedbackRatingErr('Please rate your experience');
            setShowFeedbackBox(true);
        } else {
            sendMPEvents('ma_pureai_rating_popup', {
                rating: feedbackRatingRef.current,
                feedback: feedbackRef.current.value
            });
            setCookie('ratingPopupShown', "true");
            if (feedbackRatingErr) setFeedbackRatingErr(null);
            setShowFeedbackBox(false);
            dispatch({type:ACTIONS.FEEDBACK_SUBMITTED})
        }
    }
    const feedbackBoxVisible = () => {
        const popupShown = getCookie("ratingPopupShown") === "true";
        return showFeedbackBox || ((currentState.action === ACTIONS.RECV_RESPONSE || currentState.action === ACTIONS.STREAM_COMPLETE) && getModulus() && !popupShown)
    }

    return (
        <SuggestionsBarView className={`suggestions-bar ${modal ? "on-modal" : ""}`}>
            <button className='nav-cta' onClick={newChatAction}>New Chat + </button>
            <div className="input-search">
                <InputBox placeholder='Search' ref={searchTerm} value={searchTerm?.current?.value ?? ""} inputWidth onChange={handleSearch} />
            </div>
            <div className='filterDiv'>
                <span className="filters">Filters</span>
                <div className='filterBtnDiv'>
                    <button className={filteredCategory === "streaming" ? "promptFilterBtnActive" : "promptFilterBtn"} 
                        value="streaming" onClick={() => filterPrompts("streaming") }>
                        <img src={filteredCategory === "streaming" ? streamingImgActive : streamingImg} alt="streaming-icon" className="fliterBtnIcon"></img>Streaming
                    </button>
                    <button className={filteredCategory === "gaming" ? "promptFilterBtnActive" : "promptFilterBtn"} 
                        value="gaming" onClick={() => filterPrompts("gaming") }>
                        <img src={filteredCategory === "gaming" ? gamingImgActive : gamingImg} alt="gaming-icon" className="fliterBtnIcon"></img>Gaming
                    </button>
                    <button className={filteredCategory === "security" ? "promptFilterBtnActive" : "promptFilterBtn"} 
                        value="security" onClick={() => filterPrompts("security") }>
                        <img src={filteredCategory === "security" ? securityImgActive : securityImg} alt="security-icon" className="fliterBtnIcon"></img>Security
                    </button>
                </div>
            </div>
            <div className='popular-prompt-view'>
                <table className={filteredPrompts?.length > 0 ? ` ` : `layout-fixed`}>
                    <thead>
                        <tr>
                            <th className='prompt'>Prompt</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        filteredPrompts?.length > 0 ? filteredPrompts?.map((prompt,item)=>(
                            <tr className='hoverable' onClick={()=>clickPrompt(prompt)} key={item}>
                                <td>{prompt.prompt}</td>
                            </tr>
                        )) : noDisplay()
                    }
                    </tbody>
                </table>
            </div>
            <div className="bottom-section">
                <hr className={`mt-2 d-none`}/>
                <span className='faq-button' onClick={()=>redirectCta(true)}>
                    <img src={faqs_buddy} alt='FAQs'/>
                    FAQs 
                </span>
                <span className='feedback-btn' onClick={()=>redirectCta()}>
                    <img src={feedback_buddy} alt='Feedback Form'/>
                    Help us improve
                </span>
                <span className='review-btn' onClick={()=>setShowFeedbackBox(true)}>
                    <img src={star2} alt='Ratings'/>
                    Rate your experience
                </span>
                {
                    (feedbackBoxVisible() === true) && 
                    <div className='review-box'>
                        <div className='top-bar'>
                            <h6 className='hed'>Is PureAI working for you?</h6>
                            <CloseIconSVG onClick={()=>{
                                setShowFeedbackBox(false)
                                dispatch({type:"pureai-rating-popup-closed"})
                            }} className='close-icon' fillColor="#fff" width={24} height={24}/>
                        </div>
                        <RatingStars ratingGetter={(rating) => {
                            feedbackRatingRef.current = rating
                        }}/>
                        <textarea ref={feedbackRef} className='textarea' placeholder='Give your feedback'/>
                        {feedbackRatingErr && (<p className='feedBackErr'>{feedbackRatingErr}</p>)}
                        <button className='review-submit' onClick={submitFeedback}>Submit feedback</button>
                    </div>
                }
            </div>
        </SuggestionsBarView>
    );

}

const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(SuggestionsBar);