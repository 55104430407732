import axios from "axios";
import qs from 'qs';
import { toast } from "react-toastify";
import { addonState, bundleModal, bundleType, bundleTypeNaming, comboEligible, constants, filter_coupons, addonConstants, signupFrom } from "./constants";
import { APIFAILED, INVALIDTOKEN, INVALIDDATA, TOKENEXPIRED, INVALIDREQUEST, CANCELLATIONEXCEPTION, INTEGRATED_USER_EXPERIENCE_EXCEPTION} from '../tools/responseMessages'
import { isStandard } from "../component/dashboard/upgrade/upgrade-tools";
import { checkBundleEligibility } from "../component/dashboard/subscriptions/tools/SubscriptionTools";
import { onBoardingMultiLang } from "../assets/lang/onboarding_lang"

import i18n from "i18next";

const url = process.env.REACT_APP_ENVIRONMENT_URL;

const get_locale = localStorage.getItem("locale");

export const validateEmail = (email="") => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(email)
}

export const dateFormat = (date) => {
    if(date === undefined){
        return;
    }
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    const d = new Date(date);
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}

export const getCurrentSubscription = (data, id) => {
    return data?.subscriptions?.body?.find(sub=>sub.id === id)
}

export const isManualGateway = (gateway) => {
    const manualGateway = constants.manual_gateways.includes(gateway);
    return manualGateway;
}

export const isOfflineGateway = (gateway) => {
    const offlineGateway = constants.offline_gateways.includes(gateway);
    return offlineGateway;
}

export const api = async (endpoint, data) => {
    return await axios.post(`${url}${endpoint}`,
        data,
        {
            headers:{
                'content-type':'application/x-www-form-urlencoded',
                'accept':'application/json'
            }
        }
    );
}

export const checkEvenOdd = ()=>{
    let random = Math.random();
    let base = 10;
    let total = Math.floor(random*base);

    if (total % 2 === 0) {
       return process.env.REACT_APP_DOME_SHARE_FORM_LINK;
    }
    return process.env.REACT_APP_DOME_HUBSPOT_MEETING_LINK;
}

export const AsideConditionalRendering = () => {
    let randomNum = Math.floor(Math.random() * 100)
    let aside = `refer`
    if(randomNum < 70){
        aside = `fp`
    }
    localStorage.setItem("aside",aside)
}

export const RandomNumGenarator = () => {
    let RandomNum = Math.floor(Math.random() * 100)
    localStorage.setItem("RandomNum", RandomNum)
}

export const scrollToTop = () => {
    window.scrollTo(0, 0)
}

export const getLocaleFromUrl = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const localeParam = urlParams.get('locale');

    if (localeParam) {
    const supportedLocales = ['en', 'fr', 'es', 'de', 'it', 'pt', 'ja', 'ko', 'zh', 'ru', 'nl', 'tr'];

    if (supportedLocales.includes(localeParam)) {
        return localeParam;
    }
    }

    // Default to English if no locale param is found
    return 'en';
}

export const checkLocale = () => {
    return get_locale in onBoardingMultiLang ? get_locale : 'en';
}

export const seti18Locale = () => {
    let lang = i18n.language;
    return lang in onBoardingMultiLang ? lang : 'en';
}


export const currencySymbol = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    AUD: "$",
    CAD: "$",
    NOK: "kr"

}

export const getPriceByCurrency = (currency, data) => {

    let newPrice = data?.find(res => res.currency === currency)
    return newPrice?.price;

}

export const isFreeAddonSignle = (slug) => {
    return constants.applicationAddons.includes(slug)
}

export const selectSub = (subscriptions=[],subId,plan=null) => {
    /*In family plan the sub id is nected inside current_plan object for others api the id is present at the parent node*/

        if(plan==="fp"){
            return subscriptions?.find(sub => sub?.current_plan?.subscription_id===subId) ?? subscriptions[0]
        }
        else{
            return subscriptions?.find(sub => sub?.id===subId) ?? subscriptions[0]
        }

}

export const fetcher = async (endpoint, token, cancelToken = 0) => {
    return await axios.post(`${url}${endpoint}`,{},
        {
            headers:{
                'content-type':'application/x-www-form-urlencoded',
                'accept':'application/json',
                'Authorization': `Bearer ${token}`
            },
            cancelToken: cancelToken.token
        }
    );
}

export const poster = async (endpoint, data, token, cancelToken = 0) => {
    return await axios.post(`${url}${endpoint}` ,data,
        {
            headers:{
                'content-type':'application/x-www-form-urlencoded',
                'accept':'application/json',
                'Authorization': `Bearer ${token}`
            },
            cancelToken: cancelToken.token
        }
    );
}

export const patch = async (endpoint, data, token, cancelToken = 0) => {
    return await axios.patch(`${url}${endpoint}` ,data,
        {
            headers:{
                'content-type':'application/x-www-form-urlencoded',
                'accept':'application/json',
                'Authorization': `Bearer ${token}`
            },
            cancelToken: cancelToken.token
        }
    );
}

export const deletion = async (endpoint, data, token) => {
    return await axios.delete(`${url}${endpoint}`,
        {       
                data,
                headers:{
                'content-type':'application/x-www-form-urlencoded',
                'accept':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
    );
}

export const getter = async (endpoint, token, cancelToken = 0) => {
    return await axios(`${url}${endpoint}`, {
        method: 'GET',
        headers:{
            'Authorization': `Bearer ${token}`,
        }, 
        responseType: 'arraybuffer'
    })
}

export const get = async (endpoint, token, cancelToken = 0) => {
    return await axios(`${url}${endpoint}`, {
        method: 'GET',
        headers:{
            'Authorization': `Bearer ${token}`,
        },
        cancelToken: cancelToken.token
    })
}

export const getByParam = async (endpoint, params, token, cancelToken = 0) => {
    return await axios(`${url}${endpoint}`, {
        method: 'GET',
        headers:{
            'Authorization': `Bearer ${token}`,
        },
        params,
        cancelToken: cancelToken.token
    })
}

export const logoutApi = async () => {
    const token = localStorage.getItem('token');
    const formdata = qs.stringify({token:token});
    const logOutResponse = await poster("fusionauth/logout", formdata, token);
    const {data: response} = logOutResponse;

    clearLocalStorage();
    window.location.href = process.env.REACT_APP_FA_LOGOUT ?? "";
}

export const getCurrentDeviceType = () => {
    const device = navigator.platform.toUpperCase().indexOf('MAC')>=0 && navigator.maxTouchPoints < 1?
    "MAC" :
    (navigator.platform.toUpperCase().indexOf('LINUX') >= 0 && navigator.userAgent.toUpperCase().indexOf('ANDROID') === -1)
    ?
    "LINUX" :
    navigator.platform.toUpperCase().indexOf('WIN')>=0 ?
    "WINDOWS" :
    navigator.platform.toUpperCase().indexOf('IPHONE')>=0 ?
    "IPHONE" : 
    (navigator.platform.toUpperCase().indexOf('MAC')>=0 && navigator.maxTouchPoints > 1) ?
    "IPAD" :  
    navigator.platform.toUpperCase().indexOf('iPAD')>=0 ?
    "IPAD" : 
    (navigator.platform.toUpperCase().indexOf('ANDROID')>=0 || navigator.userAgent.toUpperCase().indexOf('ANDROID') >= 0)
    ?
    "ANDROID" :
    "WINDOWS" 
    return device
}

export const isPurchasedAddOn = ( accountDetails, subscriptionId, slug, subs = [], view = null) => {
    // This function basically filter all the onboarding and check if user has a item(add-on) purchased or not.
    // View of on-boarding changes based on this condition.
    let isPurchased = false;
    let isState = constants.notPurchased;

    Object.values(accountDetails).map((subsAddOns => {
        subsAddOns.map((item) => {

            let addonCode = item.code;

            if (addonCode === "purescan" && slug == "pureprivacy") {
                addonCode = "pureprivacy";
            }
            if (addonCode === "purevpn" && slug === addonCode && item.subscription_id === subscriptionId) {
                // ADD-ONS DASHBOARD (ADD-ONS STATUS)
                if(view==="dashboard"){
                    return isState = item.subscription_state
                }
                else if ((item.subscription_state === "active" || item.subscription_state === "past_due" || item.subscription_state === "expired" || item.subscription_state === "paused" || item.subscription_state === "canceled") && checkIsAddonPaid(item)){
                    return isPurchased = true;
                }
            }

            if (addonCode !== "purevpn" && slug === addonCode) {
                if(!item?.is_purchased){
                    if(item?.status === "expired"){
                        return isPurchased =false,isState="FreeRenew";
                    }
                    else{
                        return isPurchased=true ,isState = item?.status
                    }
                }
                else{
                    if(view==="dashboard" && !(isState === "active")){
                        return isState = item.subscription_state
                    }
                    else if ((item.subscription_state === "active" || item.subscription_state === "paused" || item.subscription_state === "canceled") && checkIsAddonPaid(item)) {
                        return isPurchased = true;
                    }
                }
            }
        });
    }));
    if(view==="dashboard"){
        return isState
    }
    else{
        if (slug == "purevpn" && isPurchased === false && subs) {
            isPurchased = hasPureVPNAddOn(subs);
        }
    }

    return isPurchased

}

export const hasPureVPNAddOn = (subs) => {

    let status = false;

    // Loop through every subscription
    subs.map((s) => {
        // Validate subscription is active/paused/canceled
        if (s?.state === "active" || s?.state === "past_due" || s?.state === "paused" || s?.state === "canceled") {
            // Subscription do not have any service origin
            if (s?.service_origin === "" || s?.service_origin === "purevpn") {
                return status = true;
            } else if (s?.service_origin && s?.service_origin !== "") { // Subscription have service origin
                // Validate subscription has add-ons
                if (s?.add_ons) {
                    // Loop over subscription add-ons
                    s.add_ons.map((a) => {
                        // Check if subscription add-on has purevpn as an add-on
                        if (a?.code === "purevpn") {
                            return status = true;
                        }
                    })
                }
            }
        }
    })

    return status;
}

export const userVisitCountUpdate = () => {
    let userVisitCount = localStorage.getItem('userVisitCount');

    if (userVisitCount === null) {
        localStorage.setItem('userVisitCount', 1);  
     }
     else {
        var count = parseInt(userVisitCount,10);
        localStorage.setItem('userVisitCount', count + 1);  
    }
}

export const filterSlug = (slug) => {
    let value = slug

    if (value !== "") {
        value = value.toLowerCase()
        value = value.replace(/ /g,"_")
        value = value.replace("'", "")
        value = value.replace("\"", "")
        value = value.replace("_tag", "")
    } else {
        slug = "";
    }

    return value;
}

export const clearLocalStorage = () => {

    // This function clears the localStroage here you can also exclude keys that you don't want to clear.

    // Get onBoardingHistory data before clearing all the localStroage.
    var userOnboardingHistory = JSON.parse(localStorage.getItem('userOnboardingHistory'));
    var userVisitCount = localStorage.getItem('userVisitCount');

    // Clear All LocalStroage
    localStorage.clear();

    // Set onBoardingHistory into localStroage.
    if (userOnboardingHistory) {
        localStorage.setItem('userOnboardingHistory', JSON.stringify(userOnboardingHistory));
    }
    if (userVisitCount) {
        localStorage.setItem('userVisitCount', userVisitCount);
    }
}

export const isWhmcsUser = (billingType) => {
    return billingType === "whmcs";
}
export const isResellerUser = (payment_gateway) => {
    return payment_gateway ? (payment_gateway.toLowerCase() === "reseller") : false;
}

export const getPaymentMethods = (subscription = null) => {
    return subscription?.billing_info?.last_four && constants.stripe_payment_methods.includes(subscription?.payment_gateway)
            ? "Stripe" 
            :   ( 
                    constants.paypal_payment_methods.includes(subscription?.payment_gateway) 
                    ? "paypal"  
                    : subscription?.payment_gateway
                );

}

export const isAddonPending = (addons = [],slug=constants.port_forwarding) => {
    const addon = isAddon(addons)
    return addon?.state === addonState?.pending
} 
export const isAddon = (add_ons=[],slug=constants.port_forwarding) =>{ 
    // allow multi login purchase less than 50
    if(slug === addonConstants?.multi_login && add_ons?.find((addon) => addon?.code === slug)?.quantity <= 40){
        return false
    }else{
        return add_ons?.find(addon=> Array.isArray(slug) ?  slug.includes(addon?.code) : slug === addon?.code)
    }
}
export const addToData = (previewData,invoice_id) => {
    if(invoice_id?.length>0){
        previewData["invoice_id"] = invoice_id
    }
    return previewData
}
export const goToPaymentLink = (response,toggle=()=>{}) => {
    let url = response?.external_payment_url?.url;
    let allowRelocate = true;

    toast.success(`Redirecting you to ${response?.payment_gateway} for checkout`)

    if(allowRelocate){
        setTimeout(() => {
            window.location.href = url
            toggle();
        }, 2000);
    }
}

export const checkIsAddonPaid = (addon={}) => {
    return !addon.hasOwnProperty('state') || [addonState?.paid, addonState?.past_due].includes(addon?.state)
}
export const toDisplayComboBox = (params) => {
    const {platform,type} = params
    const platform_allowed = JSON.parse(process.env.REACT_APP_ALLOW_COMBO_IN_APP??"{}");
    if(platform_allowed?.[platform] && comboEligible.type.includes(type)){
        return true
    }else{
        return false
    }
}
export const isPurpose = (purposeToCheck="") => {
    const hide_security_purpose = JSON.parse(process.env.REACT_APP_HIDE_EXTENSION_PURPOSE??"{}");
    return hide_security_purpose?.hasOwnProperty(purposeToCheck)
}

export const getEligibleSubToUpsell = (subs,addon_name) => {
    return subs?.find(sub=>isAddon(sub.add_ons,addon_name)?false:sub)
}

export const getTokenFromLocalStorage = () => {
    return localStorage.getItem('token')
}

export const dispatchError = (error, dispatch) => {

    switch (error) {
        case "token_expired":
            toast.error(i18n.t(TOKENEXPIRED));
            dispatch({type:"LOGOUT"})     
            break;
        case "invalid_request": 
            toast.error(i18n.t(INVALIDREQUEST));
            break;
        case "jwt_exception":
            toast.error(i18n.t(APIFAILED))
            dispatch({type:"LOGOUT"})     
            break; 
        case "validation_exception":
            toast.error(i18n.t(INVALIDDATA)) 
            break; 
        case "token_invalid":
            toast.error(i18n.t(INVALIDTOKEN))
            dispatch({type:"LOGOUT"})
            break;    
        case "jwt_default_exception":
            toast.error(i18n.t(INVALIDTOKEN))
            dispatch({type:"LOGOUT"})  
            break; 
        case 'cancellation_exception': 
            toast.error(i18n.t(CANCELLATIONEXCEPTION))
            break;
        case 'integrated_experience_user_exception':
            toast.error(i18n.t(INTEGRATED_USER_EXPERIENCE_EXCEPTION))
            break;
        case "APIFAILED":
            toast.error(i18n.t(APIFAILED))
            break;     
        default:
            toast.error(i18n.t(APIFAILED))
            break;
    }
}

export const displayError = (error) => {
    toast.error(error);
}

export const getUserOperatingSystem = () => {
    let userAgent = navigator.userAgent.toLowerCase();
    let osName;
    if (userAgent.indexOf("android tv") != -1) 
        osName = "adnroid tv";
    else if (userAgent.indexOf("android") != -1) 
        osName = "android";
    else if (userAgent.indexOf("win") != -1) 
        osName = "windows";
    else if (userAgent.indexOf("like mac") != -1) 
        osName = "ios";
    else if (userAgent.indexOf("mac") != -1) 
        osName = "mac";
    else if (userAgent.indexOf("linux") != -1) 
        osName = "linux";
    else osName= userAgent
    return osName
}

export const allowPurchaseForManualGateways = (payment_gateway) => {
    return payment_gateway ? constants.manual_gateways.includes(payment_gateway) : false;
}

export const allowPurchaseForMobileGateways = (payment_gateway) => {
    return payment_gateway ? constants.payment_not_support_gateways.includes(payment_gateway) : false;
}

export const isRemovedGateway = (payment_gateway) => {
    return constants.removed_gateways.includes(payment_gateway);
}

export const getSubPlanTypeToDisplay = (plan_type) => {
    if (bundleModal.includes(plan_type)) {
        const parts = plan_type.split('_');
        const lastPart = parts[parts.length - 1];
        if (bundleTypeNaming[lastPart]) {
          return bundleTypeNaming[lastPart];
        }
      }
      return "Standard";
} 

export const isBundleHasFollowing = (addon="pureprivacy",bundle="plus",) => {
    const bundlesInfo = JSON.parse(process.env.REACT_APP_BUNDLE_ADDONS_VIEW??"{}");
    return bundlesInfo[bundle]?.hasOwnProperty(addon)
}

export const isServiceOriginPurevpn = (origin) => {
    let isVpn = false
    if([constants?.purevpn,constants.puresquare]?.includes(origin) || origin === ""){
        isVpn = true
    }
    return isVpn
}

export const setCookie = (cookieName, cookieValue, exDays = 100) => {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const getCookie = (cookieName) =>{
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return false;
}

export const toNotDisplayRefreshCart = (platform=null) => {
    const platform_not_allowed = JSON.parse(process.env.REACT_APP_DONT_ALLOW_APP_REFRESH_CART??"{}");
    if(platform && platform_not_allowed?.[platform]){
        return true
    }else{
        return false
    }
}
export const generateCouponEvent = (isCouponApplied=null, data) => {
    if(isCouponApplied){
        return {
            coupon_name: isCouponApplied,
            coupon_discount: data?.discount,    
        }
    }
    return {}
}
export const isExpired = (subs) => {
    return subs?.find(sub=>  sub?.state === 'past_due')
}

export const parsedJson = (str) => {
    try {
        return JSON.parse(str);
      } catch (error) {
        return null;
      }
}


export const itemUnitAmount = (item={}) => {
    return item?.unit_amount?.toFixed(4) * item?.quantity
}
export const getTotalAmount = (data={},isCouponApplied=false) => {
    return isCouponApplied ? data?.amount_total?.toFixed(2) : data?.sub_total?.toFixed(2) 
}

export const getDiscount = (data) => {
    return data?.discount_total?.toFixed(2)
}

export const engagementLoyaltyApi = async (activity) => {
    let formdata = qs.stringify({
        action_name:activity
    })
    return await poster("loyalty/activity", formdata, getTokenFromLocalStorage());
}

export const fetchEligibleVoucher = (voucher,couponName) => {
    return (parsedJson(voucher)?.code === couponName || parsedJson(voucher)?.code === "all")
} 

export const renewalChecker = (subscription) => {
    // To check for past due 
    return ((subscription?.state === 'past_due') || (subscription?.state === 'expired' && typeof subscription?.renew_invoice !== 'undefined')) ||
    // To check for cancel refunded
    (subscription?.state === "expired" && subscription?.renew_invoice === undefined &&  constants.recurring_gateways.includes(subscription?.payment_gateway) 
    && !subscription?.trial_ends_at?.length>0 && !subscription?.hasFamilyPlan &&  !isWhmcsUser(subscription?.account?.billingType))
}

export const getProrataAmount = (addon={}) => {
    return addon.reduce((a, item) => a + ((item?.unit_amount * item?.quantity) - item?.sub_total), 0)
}

export const checkAccountType = (sub) => {
    return sub?.hasFamilyPlan ? 'family' : sub?.hasVolumePlan ? 'volume' : sub?.hasPureTeams ? 'teams' : 'single'
}

export const checkAddOn = (sub, type) => {
    const { add_ons } = sub
    const check = Array.isArray(add_ons) ? add_ons.some((addon) => addon.code === type && checkIsAddonPaid(addon)) : false;
    return check
}

export const canShowRule = (sub, code) => {
    let showRule = true
    switch (code) {
      case addonConstants.dedicated_ip:
        showRule = !checkAddOn(sub, code)
        break;
      case addonConstants.port_forwarding:
        showRule = !checkAddOn(sub, code)
        break;
      case addonConstants.port_forwarding_with_dedicated_ip:
        showRule = (!checkAddOn(sub, addonConstants.dedicated_ip) && !checkAddOn(sub, addonConstants.port_forwarding)) && !checkAddOn(sub, addonConstants.port_forwarding_with_dedicated_ip)
        break;
      case addonConstants.dedicated_server:
        showRule = !checkAddOn(sub, code); 
        break;
      case filter_coupons.vpn_plus:
        showRule = !(sub.plan_type === filter_coupons.vpn_plus || sub.plan_type === filter_coupons.vpn_max)
        break;
      case filter_coupons.vpn_max:
        showRule = !(sub.plan_type === filter_coupons.vpn_max)
        break;
      case filter_coupons.familyPlan:
        showRule = !sub.hasFamilyPlan
        break;
      case filter_coupons.upgrade:
        showRule = !(sub.plan?.type?.toLowerCase().replace(' ', '') === '5year') //confirm this
        break;
      case filter_coupons.renew:
        showRule = !canRenewSubscription()
        break;
      default:
        break;
    }
    return showRule
}

export const canRenewSubscription = (subscription) => {
    return subscription?.state === "expired" && subscription?.renew_invoice === undefined && constants.recurring_gateways.includes(subscription?.payment_gateway) && !subscription?.trial_ends_at?.length>0 && !subscription?.hasFamilyPlan && !isWhmcsUser(subscription?.account?.billingType) ? true
        : subscription?.state === "expired" && subscription?.renew_invoice === undefined && subscription?.payment_gateway !== "cardless" ? null
        : subscription?.state === "expired" && subscription?.renew_invoice === undefined && subscription?.payment_gateway === "cardless" ? true
        : subscription?.state === "canceled" && !isWhmcsUser(subscription?.account?.billingType) ? true
        : (subscription?.state === "expired" && subscription?.renew_invoice)  ? true
        : (subscription?.state === "past_due" && subscription?.renew_invoice && !isWhmcsUser(subscription?.account?.billingType) && !isOfflineGateway(subscription?.payment_gateway))  ? true
        : (subscription?.state === "paused" || subscription?.is_paused) && !isWhmcsUser(subscription?.account?.billingType) ? subscription?.state === "paused" ? true : false
        : ( ( signupFrom.includes(subscription?.account?.signup_from)  ) && isWhmcsUser(subscription?.account?.billingType) && (subscription?.state === "past_due" || subscription?.is_about_to_churn) )  ? true
        : ((subscription?.state === "past_due" || subscription?.is_about_to_churn || subscription?.state === "expired") && isWhmcsUser(subscription?.account?.billingType) )  ? true
        : null
}

export const getPlanProRatedAdjustment = (currency, invoiceData) => {

    let sub_total = subtotalForProrata(invoiceData?.items);
    let unit_amount = unitAmountlForProrata(invoiceData?.items);
    
    let proratedPrice = sub_total - unit_amount;
    let arithmeticSymbol = sub_total > unit_amount ? '+' : '-';

    return `${arithmeticSymbol}${currency}${Math.abs(proratedPrice)?.toFixed(2)}`
}

const subtotalForProrata = (addon={}) => {
    return addon.reduce((a, item) => a + item?.sub_total, 0)
}

const unitAmountlForProrata = (addon={}) => {
    return addon.reduce((a, item) => a + (item?.unit_amount * item?.quantity), 0)
}

export const getCreditAdjustment = (currency, invoiceData) => {
    return `${invoiceData?.credit_amount ? `-${currency}${invoiceData?.credit_amount?.toFixed(2)}` : `${currency}0.00`}`
}

export const toTitleCase = (word) => {
    return word?.replace(/\b\w/g, match => match.toUpperCase());
  }

export const checkTeamAddons = (additionalAddons) => {
    let addons = [];
    additionalAddons.forEach(item => {
        if (item.code === 'team_server' && item.quantity) {
            addons.push('team_server');
        } else if (item.code === 'dedicated_ip' && item.quantity) {
            addons.push('dedicated_ip');
        }
    });
    return addons;
    
}

export const donotShowTeamsToTeamUser = (subscription) => {
    return subscription?.hasPureTeams && subscription?.add_ons?.find((addon) => addon?.code === addonConstants?.multi_login) && subscription?.add_ons?.find((addon) => addon?.code === addonConstants?.multi_login)?.quantity > 40
}