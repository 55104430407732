import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SuggestionsBar from './SuggestionsBar';

const SuggestionsModal = ({ pureAIStateReducer, inputRef, showModal, showMenuAction }) => {

    return (
        <Modal backdrop={showModal} isOpen={showModal} toggle={showMenuAction} wrapClassName="suggesstionsBarModal">
            <ModalHeader className='modal-header' toggle={showMenuAction}></ModalHeader>
            <ModalBody>
                <SuggestionsBar modal={true} inputRef={inputRef} showMenuAction={showMenuAction} />
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        pureAIStateReducer: state.pureAIReducer
    };
};

export default connect(mapStateToProps)(SuggestionsModal);