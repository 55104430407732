import { useReducer } from 'react';
import { DedicatedIpReducer } from '../reducer/dedicatedIpCounter';
import { dedicatedIpPricing, minDisabledForDedicatedIp, maxDisabledForDedicatedIp, getMaximumForDedicatedIp, getMinimumForDedicatedIp, hasErrorForDedicatedIp, getValueForDedicatedIp, isDisabledForDedicatedIp, getTotalAmountForDedicatedIp, onChangeCounterForDedicatedIp } from "../tools/dedicatedIp";
import { Input } from "reactstrap";
import { PrimaryBtn } from "../../../../styles/Generic";
import { useState, useEffect } from "react";
import ServersTable from "../tables/serversTable";
import useTeamsServers from "../custom-hooks/useTeamServers";
import useDebounce from "../../../../custom-hooks/useDebounce";
import DedicatedIpTable from "../tables/dedicatedIpTable";
import ConfigureIp from "../modals/configureIp";
import { pureTeamsPostPurchaseStates } from '../../../../tools/constants';
import AddMoreAddons from '../modals/addMoreAddons';
import Info from '../modals/info';
import AddonPurchase from '../../subscriptions/modals/AddonPurchase';
import useDedicatedIps from '../custom-hooks/useDedicatedIp';
import { getTokenFromLocalStorage } from '../../subscriptions/cancellation';
import { sendEvents } from '../../../../tools/mpEvents';

const DedicatedIp = ({ selectedSubscriptionData, teamsCounter, fetchCountAndMembers, counterErrorMsg, plansInfo }) => {
    const token = getTokenFromLocalStorage();
    const [dedicatedIpState, dispatchForDedicatedIp] = useReducer(DedicatedIpReducer, {
        min: 1,
        max: selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip' || add.code === 'port_forwarding_with_dedicated_ip') ? teamsCounter?.team_members?.limit - selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip' || add.code === 'port_forwarding_with_dedicated_ip')?.quantity : teamsCounter?.team_members?.limit,
        current: 1,
        price: dedicatedIpPricing(selectedSubscriptionData, plansInfo),
        previous: 0
    });

    // listing states
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [debouncedSearch, setDebouncedSearch] = useDebounce('', 1000);
    const { dedicatedIps, totalRows, isDedicatedIpsLoaded, dedicatedIpsErrorMsg, setDedicatedIpsAgain } = useDedicatedIps(page, perPage, debouncedSearch, selectedSubscriptionData, teamsCounter);

	const [modalType, setmodalType] = useState("sm");
    const [orderSummary, setorderSummary] = useState(true);
    const [configureModal, setConfigureModal] = useState();
    const [modalWidth] = useState({
        lg: "684px",
        sm: "440px"
    });
    const [infoModal, setInfoModal] = useState({
        show: '',
        open: ''
    });
    const [addonType, setAddonType] = useState({
    type:"",
        subscription:"",
        currency:"",
        plan:"",
        gateway:"",
    });

    // add more states
    const [addMoreAddonModal, setAddMoreAddonModal] = useState();
	const [openAddonModal, setOpenAddonModal] = useState();

    useEffect(() => {
        setDebouncedSearch(search);
    }, [search]);

    const toggleConfigureModal = () => {
        setConfigureModal(!configureModal)
    }
    const openConfigureModal = () => {
        sendEvents({
            event_name:"ma_click_configure_dedicated_ip",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0
            }
        }, token)
        toggleConfigureModal();
    }
    const toggleAddMoreAddonModal = () => {
        setAddMoreAddonModal(!addMoreAddonModal)
    }
    const toggleAddonModal = () => {
		setOpenAddonModal(!openAddonModal)
	}
    const proceedToAddonPurchase = () => {
        toggleAddMoreAddonModal();
        setAddonType({
            subscription: selectedSubscriptionData?.id,
			currency: selectedSubscriptionData?.currency,
			plan: selectedSubscriptionData?.plan?.code,
			gateway:selectedSubscriptionData?.payment_gateway,
            type: [
                {
                    code: 'dedicated_ip',
                    quantity: selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip')?.quantity ? dedicatedIpState.current + selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip')?.quantity : dedicatedIpState.current
                }
            ], 
            source: 'teams'
        });
        sendEvents({
            event_name:"ma_click_get_dedicated_ip",
            event_properties:{
                current_team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
                team_ip_count: dedicatedIpState?.current
            }
        }, token)
        toggleAddonModal();
    }
    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }
    const openInfoModal = (type) => {
        toggleInfoModal(pureTeamsPostPurchaseStates.find((state)=> state.name  === type)?.info)
    }
    const openAddMoreModal = () => {
        sendEvents({
            event_name:"ma_click_add_dedicated_ip",
            event_properties:{
                team_ip_count: teamsCounter?.dedicated_ip?.limit ?? 0,
                team_server_count: teamsCounter?.team_server?.limit ?? 0,
                team_count: teamsCounter?.team_members?.limit ?? 0,
            }
        }, token)
        dispatchForDedicatedIp({ type: 'clear', current: 1, previous: 0, max: selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip' || add.code === 'port_forwarding_with_dedicated_ip') ? teamsCounter?.team_members?.limit - selectedSubscriptionData?.add_ons.find((add) => add.code === 'dedicated_ip' || add.code === 'port_forwarding_with_dedicated_ip')?.quantity : teamsCounter?.team_members?.limit });
        toggleAddMoreAddonModal()
    }

    const refreshAllApis = () => {
        fetchCountAndMembers()
        setDedicatedIpsAgain(prev=>!prev)
    }

    return <>
        {/* ---LISTING HEADER--- */}
        <section className="listing-header">
            
            {!counterErrorMsg && !selectedSubscriptionData?.is_child && <div className={`listing-btns d-block w-100 text-align-right`}>
                {/* Only show configure if the user has dedicated ips purchased and less than the dedicated ip limit */}
                {teamsCounter?.dedicated_ip && teamsCounter?.dedicated_ip?.allocated < teamsCounter?.dedicated_ip?.limit && teamsCounter?.dedicated_ip?.allocated >= 1 && <PrimaryBtn className="secondary teams" onClick={()=>toggleConfigureModal()}>Assign Dedicated IP</PrimaryBtn>}
                {/* Only show add more option if user has dedicated ips purchased and less than the member count limit */}
                {teamsCounter?.dedicated_ip && teamsCounter?.dedicated_ip?.limit < teamsCounter?.team_members?.limit && <PrimaryBtn className={`ml-2 primary teams`} onClick={()=>openAddMoreModal()}>Get New Dedicated IP</PrimaryBtn>}
            </div>}
        </section>

        {/* ---TABLE--- */}
        <section className="team-table">
            <DedicatedIpTable
                fetchCountAndMembers={refreshAllApis}
                isDedicatedIpsLoaded={isDedicatedIpsLoaded}
                perPage={perPage}
                selectedSubscriptionData={selectedSubscriptionData}
                setPage={setPage}
                setPerPage={setPerPage}
                dedicatedIps={dedicatedIps}
                dedicatedIpsErrorMsg={dedicatedIpsErrorMsg}
                totalRows={totalRows}
                openConfigureModal={openConfigureModal}
                openAddMoreModal={openAddMoreModal}
                setDedicatedIpsAgain={setDedicatedIpsAgain}
            />
        </section>

        {configureModal && <ConfigureIp toggle={toggleConfigureModal} modal={configureModal} modalWidth={modalWidth} widthCalculator={'lg'} selectedSubscriptionData={selectedSubscriptionData} refreshAllApis={refreshAllApis} teamsCounter={teamsCounter}/>}
        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={infoModal.open} toggle={toggleInfoModal} toggleLicensesModal={toggleAddMoreAddonModal}/>}
        {openAddonModal && <AddonPurchase showInfo={false} hasCountry={false} backdrop={false} addonType={addonType} modalWidth={modalWidth} widthCalculator={"lg"} modalType={modalType} modal={openAddonModal} toggle={toggleAddonModal} setorderSummary={setorderSummary} orderSummary={orderSummary} endpoint={'pure-teams/addon-upsell'} redirectedFrom={'teams'} setIsConverted={refreshAllApis}/>}

        {addMoreAddonModal && <AddMoreAddons 
            type={'dedicated_ip'}
            state={dedicatedIpState}
            dispatch={dispatchForDedicatedIp}
            selectedSubscriptionData={selectedSubscriptionData}
            toggle={toggleAddMoreAddonModal}
            modal={addMoreAddonModal}
            modalWidth={modalWidth}
            openInfoModal={openInfoModal}
            proceedToAddonPurchase={proceedToAddonPurchase}
            onTotalAmount={getTotalAmountForDedicatedIp}
            onDisableCta={isDisabledForDedicatedIp}
            minDisabled={minDisabledForDedicatedIp}
            maxDisabled={maxDisabledForDedicatedIp}
            onChangeCounter={onChangeCounterForDedicatedIp}
            getMinimum={getMinimumForDedicatedIp} 
            getMaximum={getMaximumForDedicatedIp}
            hasError={hasErrorForDedicatedIp}
            getValue={getValueForDedicatedIp}>
        </AddMoreAddons>}
    </>
}
export default DedicatedIp;