import { APIFAILED } from '../../../tools/responseMessages'
import { basePrice, bundleType, constants, freeAddonTenure, signupFrom } from '../../../tools/constants'
import { checkErrorEligibilityAndErrorForUpgrade, getVPNplanType, isStandard } from './upgrade-tools'
import { connect, useDispatch } from 'react-redux'
import { currencySymbol, getPriceByCurrency, isWhmcsUser, isResellerUser, allowPurchaseForManualGateways, selectSub, isRemovedGateway, dispatchError } from '../../../tools/tools'
import { DashboardHead, UpgradeContainer, UpgradePlanContainer, UpgradeNA, SubscriptionBox, InfoCard } from '../../../styles/dashboard/Main'
import { getUpgradePlansRequested, getUpgradePlansReset } from '../../../redux/upgrade/actions'
import { sendEvents } from '../../../tools/mpEvents'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BillingCycle from '../subscriptions/modals/BillingCycle'
import ErrorBoundary from '../../ErrorBoundary'
import FreeAddonModal from '../subscriptions/modals/FreeAddonModal'
import React, { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import UpgradeHeaderContainer from './child/UpgradeHeaderContainer'
import UpgradeHeading from './upgrade-heading'
import UpgradeInfoSection from './child/UpgradeInfoSection'
import UpgradeMaxAddons from './upgrade-addons'
import UpgradeOrderSummary from './upgrade-order-summary'
import UpgradePlansBox from './upgrade-plans-box'
import logoWhite from '../../../assets/loginlogo.png';
import { isAndroid, isIOS } from 'react-device-detect'



const UpgradeSubscription = ({history, getUpgradePlansRequested, upgradeReducer, onBoardingReducer, subscriptionsReducer}) => {

    const [crashed, setCrashed] = useState(false);

    const token = localStorage.getItem("token");

    const { t } = useTranslation();

    const [modal, setModal] = useState(false);

    const [modalType, setmodalType] = useState('billing-lg')

    const [billingData, setBillingData] = useState("");

    const [modalWidth] = useState({
        lg: "675px",
        sm: "440px"
    })

    const [isFreemiumModal,setFreemiumModal] = useState(false)

    const widthCalculator = modalType.slice(-2)

    const [orderSummary, setorderSummary] = useState((isAndroid || isIOS) ? false : true)

    const [subscriptionsData, setsubscriptionsData] = useState({
        subs: {},
        subLoading: true
    })

    const [plansData, setPlansData] = useState({
        plans: [],
        planLoading: true
    })

    const [hasUpgradePlans, sethasUpgradePlans] = useState({
        statue: true,
        message:''
    })

    const {search} = useLocation();   

    const [selectedPlanInfo, setSelectedPlanInfo] = useState(false);

    const [selectedSubscriptionPlans, setSelectedSubscriptionPlans] = useState("0");

    const [selectedSubscriptionData, setSelectedSubscriptionData] = useState(false);

    const [hasAddonsArray, sethasAddonsArray] = useState([]);

    const dispatch = useDispatch()

    const {utm_source, utm_medium, utm_campaign, query, id, plan_code} = Object.fromEntries(new URLSearchParams(search));

    const scrollToDiv = (ref) => window.scrollTo((0, 50), ref.current.offsetTop);

    const scrollOrderConfirmation = useRef(null);

    const showExitPopupRef = useRef(true);

    const GotoDiv = () => scrollToDiv(scrollOrderConfirmation);

    // GET AND SET SUBSCRITPIONS DATA
    useEffect(()=>{
        const { loading: reduxLoading, subscriptions: reduxData, errorType, errors} = subscriptionsReducer;

        if(reduxData?.body?.length){
            setsubscriptionsData({
                ...subscriptionsData,
                subs:reduxData.body,
                subLoading: subscriptionsData?.subs ? false : true
            }); 
        }
        if(!reduxLoading){
            if(errors) {
                dispatchError(errorType, dispatch);
                throwErrorBoundary(errorType)
                setsubscriptionsData({
                    subs: [],
                    subLoading: false
                }); 
            }
        }
        
    }, [subscriptionsReducer])

    // UPGRADE REQUEST
    useEffect(() => {
        getUpgradePlansRequested(token)
        sendEvents({
            event_name:"ma_pagevisited",
            event_properties:{
                visitedPageURL: window.location.href ?? "N/A",
                utm_source: (query ? query : utm_source) ?? "Upgrade",
                utm_medium: utm_medium ?? "Member Area",
                utm_campaign: utm_campaign ?? "page"
            }
        }, token)
        const queryParams = new URLSearchParams(search)
        if (queryParams.has('query')) {
            queryParams.delete('query')
            history.replace({
                search: queryParams.toString(),
            })
        }
    }, [])

    // GET AND SET UPGRADE DATA
    useEffect(() => {
        const { loading: reduxLoading, subscriptions:reduxData, errors, errorType, errorMessage} = upgradeReducer;

        if(reduxData){
            setPlansData({
                ...plansData,
                plans:reduxData?.body,
            })
        }

        if(!reduxLoading){
            if(errors) {
                dispatchError(errorType, dispatch);
                throwErrorBoundary(errorType)
                setPlansData({
                    plans: [],
                    planLoading: false
                })
            }
        }
        
        
    }, [upgradeReducer, subscriptionsReducer])


    useEffect(()=>{
        if(upgradeReducer?.subscriptions && subscriptionsData?.subs?.length > 0){

            // set plans data with all subscritpions
            setPlansData({
                ...plansData,
                plans:upgradeReducer?.subscriptions?.body,
                planLoading: false
            })

            // set selection subscription data
            let currentSubscription = selectSub(subscriptionsData?.subs ?? [],onBoardingReducer.selectedSubs)
            setSelectedSubscriptionData(currentSubscription ?? false);

            setDataAccordingToSelectedSubscriptionAndPlans({currentSubscription: currentSubscription, allPlans: upgradeReducer?.subscriptions?.body})
               
        }
    }, [upgradeReducer, onBoardingReducer.selectedSubs])

    // * In order to open a upgrade cart summary via id(subscription id) and plan_code in the URL
    useEffect(()=>{
        if(!subscriptionsData?.subLoading && id && plan_code && subscriptionsData?.subs && selectedPlanInfo){
            setSelectedSubscriptionData(...subscriptionsData?.subs.filter((subs)=>subs.id === id))
            changePlans()
        }
    }, [subscriptionsData?.subLoading, selectedPlanInfo, id, plan_code])

    const wisepopEvent = () => {
        if(window.wisepops){
            const email = subscriptionsReducer?.subscriptions?.body?.[0]?.account?.email
            window.wisepops('properties', {email});
            window.wisepops('event', 'upgrade-event');
            hideExitPopup();
        }
    }

    const hideExitPopup = () => {
        showExitPopupRef.current = false;
    }

    const setDataAccordingToSelectedSubscriptionAndPlans = (payload) => {

        const {currentSubscription, allPlans} = payload;
        let plans = allPlans?.find(sub => sub?.id === currentSubscription?.id)?.plans;

            setSelectedSubscriptionPlans(plans)

            if (checkIfUpgradePlanIsELigible(currentSubscription, plans)) {

                let selected_plan = plans?.find((plan) => plan?.selected) ?? plans?.[0]

                setSelectedPlanWithItsInfo(selected_plan);

                sethasUpgradePlans({
                    status:true,
                    message: ``
                })

                showExitPopupRef.current && wisepopEvent();

            } 
            else{
                checkErrorEligibilityAndErrorForUpgrade(currentSubscription, setInEligibleMessage, subscriptionsData)
            }
    }

    const setInEligibleMessage = (message, translatedMessage) => {
        sethasUpgradePlans({
            status:false,
            message: t(translatedMessage)
        });
        sendEvents({
            event_name:"ma_fail_load_upgrade",
            event_properties:{
                reason: message
            }
        }, token)
    }

    const setSelectedPlanWithItsInfo = (plan) => {
        setSelectedPlanInfo(plan)
    }

    const checkIfUpgradePlanIsELigible = (sub, plans) => {

        return plans?.length > 0
        && sub?.state !== "expired"
        && !sub?.is_trial
        && (allowPurchaseForManualGateways(sub?.payment_gateway) || !sub?.payment_gateway_offline || isRemovedGateway(sub?.payment_gateway))
        && !sub?.hasFamilyPlan
        && !sub?.hasVolumePlan
        && !sub?.hasPureTeams
        && !sub?.is_child
        && !signupFrom.includes(sub?.account?.signup_from)
        && subscriptionsData?.subs?.[0]?.vpnusername != ""
        // && sub?.is_eligable_for_upgrade
        && !isResellerUser(sub?.payment_gateway)
    }

    const onClickUpgradeBox = (upgradePlan, index) => {
        setSelectedPlanInfo(upgradePlan ?? false)
    };

    const calcBasePrice = () => {
        return basePrice[getVPNplanType(selectedSubscriptionData?.plan_type ?? bundleType.standard)][selectedSubscriptionData?.currency]
    }

    const throwErrorBoundary = (error) => {
        if(error === APIFAILED){
            setCrashed(true)
        }
    }

    const getDiscountedText = (plan, price, originalPrice) => {
        return plan?.discount_type == 'FIXED' ? `${currencySymbol[selectedSubscriptionData?.currency]}${plan?.discount} ${t('upgradeplan_off_text')}`
        : plan?.discount_type == 'PERCENTAGE' ? `${100 - (((price / originalPrice) * 100).toFixed(0))}% ${t('upgradeplan_off_text')}`
        :'NO SAVING'
    }

    const changePlans = async (e) => {

        const checkSameSubsInterval = subscriptionsData?.subs.some(sub => sub?.state != "expired" && sub?.plan?.interval_length === selectedPlanInfo?.interval_length && !sub?.is_trial && sub?.plan?.interval_length !== 60)
        let todayDate = new Date().toJSON()
        setBillingData({
            plan: selectedPlanInfo?.code,
            subscription:selectedSubscriptionData?.id,
            service_origin:selectedSubscriptionData?.service_origin,
            isWhmcsUser:isWhmcsUser(selectedSubscriptionData?.account?.billingType),
            currency:selectedSubscriptionData?.currency,
            interval_length: selectedPlanInfo?.interval_length,
            interval_unit: selectedPlanInfo?.interval_unit,
            start_date: todayDate,
            expiry_date: selectedPlanInfo?.expiry_date,
            sub_plan_type:  selectedSubscriptionData?.plan_type,
            addon_amount: getTotalAmountForVpnAddons(),
            new_product_addon_amount: getTotalAmountForNewProduct(), 
            billing_cycle: selectedSubscriptionData?.plan?.type,
        })
        
        if(checkSameSubsInterval){
            toast.error("You already have active subscription to this plan, please select another one");
            sendEvents({
                event_name:"ma_fail_upgradesubscription",
                event_properties:{
                    reason: "You already have active subscription to this plan, please select another one"
                }
            }, token)
        }else{
            setModal(!modal)
        }
    }

    const getGrabDiscountValue = (upgradePlan) => {
        if(!isStandard(selectedSubscriptionData?.plan_type)){
            const currencyVal = upgradePlan?.currency?.price
            const origPrice = calcBasePrice()*upgradePlan?.interval_length
            if(currencyVal>0){
                return 100 - (((currencyVal / origPrice) * 100).toFixed(0))
            }
        }else{
            return upgradePlan?.discount
        }

    }

    const getDiscountValue = (upgradePlan , offText) =>{
        return upgradePlan?.discount_type == 'FIXED' ? `${currencySymbol[selectedSubscriptionData?.currency]}${upgradePlan?.discount} ${offText}` 
        : upgradePlan?.discount_type == 'PERCENTAGE' ? `${getGrabDiscountValue(upgradePlan) ?? ""}% ${offText}`
        :'NO SAVING'
    }

    const toggle = () => setModal(!modal);
    const toggleFreemium = () => setFreemiumModal(!isFreemiumModal)

    // calculate new products price for mp events
    const getTotalAmountForNewProduct = () => {
        
        let new_product_addons_amount = 0;
        hasAddonsArray.filter((addon) => addon !== 'purevpn').map((addon)=>{
            new_product_addons_amount += getPriceByCurrency(selectedSubscriptionData.currency , selectedPlanInfo?.add_ons.find(upgradeAddons => upgradeAddons.code === addon)?.currencies);
        });
        return new_product_addons_amount;
    }

    const getTotalAmountForVpnAddons = () => {

        let addons_amount = 0;
        let addons_array = [];
        
        selectedPlanInfo?.add_ons?.filter((purchasedAddons)=> selectedSubscriptionData?.add_ons?.find((addon)=> addon?.code === purchasedAddons?.code)).map((addon)=>{
            return addons_array.push(addon?.code)
        });

        addons_array.forEach((addon)=>{
            return addons_amount += getPriceByCurrency(selectedSubscriptionData.currency , selectedPlanInfo?.add_ons.find(upgradeAddons => upgradeAddons.code === addon)?.currencies);
        });

        return addons_amount;
    }

    return (
        crashed ?  
        <ErrorBoundary /> :
        <div>
            <DashboardHead>
                <h2>Upgrade Plan</h2>
            </DashboardHead>
            <UpgradeContainer>
                <div>
                    {
                        !subscriptionsData?.subLoading && !plansData?.planLoading ? (
                            <>
                            <UpgradeHeaderContainer selectedSubscriptionData={selectedSubscriptionData} subscriptions={subscriptionsData?.subs} />
                                {
                                    hasUpgradePlans?.status ? (
                                        <UpgradePlanContainer>

                                            {/* UPGRADE HEADING*/}
                                            {selectedSubscriptionPlans?.length ? <div className="mt-5 h-pbox d-flex flex-column align-items-center">
                                                <UpgradeHeading selectedSubscriptionPlans={selectedSubscriptionPlans} getDiscountValue={getDiscountValue}/>
                                            </div> : null}

                                            {/* UPGRADE PLANS - START */}
                                            {selectedSubscriptionPlans?.length > 0 && <div className="mt-4 d-flex align-items-center justify-content-center text-align-center mx-0 plan-box-responsive">
                                                {
                                                     selectedSubscriptionPlans?.map((upgradePlan, index) => {
                                            
                                                        return (
                                                          <UpgradePlansBox index={index} upgradePlan={upgradePlan} getDiscountedText={getDiscountedText} selectedSubscriptionData={selectedSubscriptionData} onClickUpgradeBox={onClickUpgradeBox} GotoDiv={GotoDiv} selectedPlanInfo={selectedPlanInfo}/>
                                                        );
                                                    })
                                                }
                                            </div>}
                                            
                                            <div ref={scrollOrderConfirmation}></div>

                                            {
                                                selectedPlanInfo ? <UpgradeOrderSummary 
                                                    getDiscountedText={getDiscountedText}
                                                    hasAddonsArray={hasAddonsArray}
                                                    selectedPlanInfo={selectedPlanInfo}
                                                    selectedSubscriptionData={selectedSubscriptionData}
                                                    changePlans={changePlans}
                                                /> : null
                                            }

                                        </UpgradePlanContainer>
                                    ) : (
                                        <UpgradeNA noborder>
                                            <div className="mt-5 h-pbox">
                                                <p className="upgrade-p4" dangerouslySetInnerHTML={{ __html: hasUpgradePlans.message }} />
                                            </div>
                                        </UpgradeNA>
                                    )
                                }
                            <UpgradeInfoSection GotoDiv={GotoDiv} selectedPlanInfo={selectedPlanInfo} hasUpgradePlans={hasUpgradePlans} plan_type={getVPNplanType(selectedSubscriptionData?.plan_type)}/>
                            </>
                        ) : (
                            <SubscriptionBox open={true}>
                                <InfoCard hasborder noflex className="mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton hei   ght={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard hasborder noflex className="pt-0 mb-3">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                                <InfoCard noflex className="pt-0">
                                    <Skeleton height={20} width={300}/>
                                </InfoCard>
                            </SubscriptionBox>
                        )
                    }
                </div>
            </UpgradeContainer>
            {
                modal  && <BillingCycle backdrop={false} modalWidth={modalWidth} selectedSubscription={billingData} widthCalculator={widthCalculator} modal={modal} toggle={toggle} setorderSummary={setorderSummary} orderSummary={orderSummary} additionalAddons={hasAddonsArray} currencyName={selectedSubscriptionData.currency} setFreemiumModal={setFreemiumModal} redirectedFrom={"upgrade"}/>
            }
            {
                isFreemiumModal &&  <FreeAddonModal backdrop={false} modalWidth={modalWidth} widthCalculator={widthCalculator} modal={isFreemiumModal} toggle={toggleFreemium} slug={constants.allProducts} campaign={null} accountData={isWhmcsUser(selectedSubscriptionData?.account?.billingType)?"whmcs":"recurly"} onBoardingReducer={onBoardingReducer} isUpgraded={true} modalSource={"Upgrade Plan"} interval={freeAddonTenure.semiannually}/>
            }

        </div>

    )
}

const mapStateToProps = (state) => {
    return { 
        upgradeReducer: state.upgradeReducer,
        onBoardingReducer: state.onBoardingReducer,
        subscriptionsReducer: state.subscriptionsReducer
    };
};

export default connect(mapStateToProps, {getUpgradePlansRequested, getUpgradePlansReset})(UpgradeSubscription)
