import styled, { css, keyframes } from "styled-components";
import inputcheck from '../assets/inputcheck.png'

export const SecHead = styled.div`
    margin-bottom:30px;
    img{
        margin:0 auto 34px;
    }
    h1{
        font-size:32px
    }
    h1, h2{
        margin:0 0 6px;
    }
    p{
        margin:0;
    }
`;

export const PrimaryBtn = styled.button`
    background:${({theme:{branding:{primaryCta}}}) => primaryCta.bg};
    color:${({theme:{branding:{primaryCta}}}) => primaryCta.color};
    padding:${({theme:{branding:{primaryCta}}}) => primaryCta.padding};
    font-size:${({theme:{branding:{primaryCta}}}) => primaryCta.fontSize};
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    -ms-border-radius: 500px;
    -o-border-radius: 500px;
    border:0;
    font-weight:600;
    &:hover {
        background: ${({theme:{branding:{primaryCta}}}) => primaryCta.hover};
    }

    ${props => props.isPrivacy && css`
        border-radius: 200px;
        background: #480CA8;
        border: 2px solid #480CA8;
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: #fff;
        text-align: center;

        &:hover{
            background: linear-gradient(0deg,#480CA854,#480CA899),#480CA8 !important;
            border: 2px solid #480CA8;
            transition: 0.2s all ease-in-out;
        }

    `}

    ${props => props.isKeep && css`
        border-radius: 200px;
        background: #2EC4B6;
        border: 2px solid #2EC4B6;
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: #FFF;
        text-align: center;

        &:hover{
            background: radial-gradient(circle, #2ec4b6, #47cabd, #5bd0c5, #6dd6cc, #7ddcd3) !important;
            border: 2px solid #2EC4B6;
            transition: 0.2s all ease-in-out;
        }

    `}

    ${props => props.isEcrypt && css`
        border-radius: 200px;
        background: #4361EE;
        border: 2px solid #4361EE;
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: #FFF;
        text-align: center;

        &:hover{
            background:  radial-gradient(circle, #4361ee, #4f6bf0, #5b75f2, #667ef4, #7188f6) !important;
            border: 2px solid #4361EE;
            transition: 0.2s all ease-in-out;
        }

    `}

    &.compare-btn{
        font-size: 16px;
        padding: 15px 38px;
        margin:0;

        @media only screen and (max-width: 360px){
            padding: 15px 20px;
            width: 100%;
        }
    }
    &.white{
        border-radius: 200px;
        background: #fff !important;
        color: #550CCB;
        &:hover{
            background: transparent !important;
            color: #fff;
            outline: 1px solid #fff;
            transition: 0.2s all ease-in-out;
        }
    }
    ${props => props.isPswValidationError && css`
        opacity: 0.5;
    `}

    ${props => props.isFPW2&& css`
        background: #6536CC;
        border-radius: 50px;
        font-size: 20px !important;
        line-height: 27px;
        font-weight: 700;
        padding: 24.5px 44px;

        @media only screen and (max-width: 1440px){
            font-size: 16px !important;
            padding: 14px 34px;
        }
        @media only screen and (max-width: 1440px){
            font-size: 14px !important;
            padding: 14px 24px;
        }
    `}
    ${props => props.isFPW4&& css`
        background: #3B108E;
        border-radius: 50px;
        font-size: 20px !important;
        line-height: 27px;
        font-weight: 700;
        padding: 24.5px 44px;

        @media only screen and (max-width: 1440px){
            font-size: 16px !important;
            padding: 14px 34px;
        }

        @media only screen and (max-width: 1440px){
            font-size: 14px !important;
            padding: 14px 24px;
        }
    `}
    ${props => props.isPlanType && css`
        border: 1px solid #A599AE;
        border-radius: 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #A599AE;
        padding: 20px 0px !mportant;
        width: 100%;
        background: none;
        pointer-events: none;

        @media only screen and (max-width: 930px){
            font-size: 12px !important;
        }

        
    `}
    ${props => props.isSelected && css`
        border-radius: 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        padding: 20px 0px !mportant;
        width: 100%;
        background: #2CDC82;
        color: #FFFFFF;
        border: 1px solid #2CDC82;
        pointer-events: auto;
        
    `}
    ${props => props.isBF && css`
        background: #28C675;
        border-radius: 100px;
        color:#FFF;
        padding:10px 30px;
        font-size:17px;
        font-weight:600;
        letter-spacing:0.01em;
    `}

    ${props => props.upgradeCampaign && css`
        padding-right: 37px !important;
        position: relative;
        background: #ECA337 !important;
        font-size: 14px !important;
        color: #000 !important;
        &:hover{
            color: #ECA337  !important;
            border: 1px solid #ECA337;
            background: transparent !important;
            transition: 0.3s all ease-in-out;
            &:after{
                border-left:5px solid #ECA337; 
            }
        }
        @media only screen and (max-width: 775px) {
            font-size: 12px !important;
            line-height: 16px !important;
            &:after{
                top: 10px !important;
            }
        }
        &:after{
            width: 0; 
            height: 0; 
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent; 
            border-left:5px solid black; 
            position: absolute;
            content: '';
            right: 20px;
            top: 12px;
        }
    `}
    ${props => props.isRefer && css`
        background:${({theme:{branding:{primaryCta}}}) => primaryCta.bg};
        color:${({theme:{branding:{primaryCta}}}) => primaryCta.color};
        padding:${({theme:{branding:{primaryCta}}}) => primaryCta.padding};
        font-size:${({theme:{branding:{primaryCta}}}) => primaryCta.fontSize};
        border-radius: 5px;
    `}
    ${props => props.isUpgrade && css`
        background:#FFBA24;
        color:black;
        padding:15px;
        font-weight:900;
        font-size:16px;
        border-radius: 22px;
    `}
    ${props => props.isFP && css`
        background:#28C675;
        color:${({theme:{branding:{primaryCta}}}) => primaryCta.color};
        padding:15px 30px;
        font-size:16px;
        border-radius: 8px;
        border:0;
        font-weight:600;
    `}
    ${props => props.dsInfoModal && css`
        background:#480CA8;
        color:#FFFFFF;
        padding: 6px 15px 7px 15px !important;
        font-size: 12px;
        border-radius: 4px;
        @media only screen and (max-width: 768px){
            padding: 5px 15px 7px 15px;
            font-size:8px !important;
        }
        @media only screen and (max-width: 420px){
            padding: 5px 8px 5px 8px;
            font-size:8px !important;
        }
    `}
    ${props => props.dsInfoModalteam && css`
        background:#480CA8;
        color:#FFFFFF;
        padding: 6px 8px 7px 8px !important;
        font-size: 11px;
        border-radius: 4px;
        @media only screen and (max-width: 768px){
            padding: 5px 15px 7px 15px;
            font-size:8px !important;
        }
        @media only screen and (max-width: 420px){
            padding: 5px 8px 5px 8px;
            font-size:8px !important;
        }
    `}
    ${props => props.teamInfoModal && css`
        background:#480CA8;
        color:#FFFFFF;
        padding: 6px 15px 7px 15px !important;
        font-size: 14px;
        border-radius: 4px;
        @media only screen and (max-width: 768px){
            padding: 5px 15px 7px 15px;
            font-size:8px !important;
        }
        @media only screen and (max-width: 420px){
            padding: 5px 8px 5px 8px;
            font-size:8px !important;
        }
    `}
    ${props => props.isDedicatedMobile && css`
        background:#550CCB;
        color:#FFFFFF;
        width:100%;
        border-radius: 30px;
        @media only screen and (max-width: 420px){
            font-size:14px !important;
        }
    `}
    ${props => props.isFPH && css`
        width: 361px;

        @media only screen and (max-width: 420px){
            width: 305px;
        }

        @media only screen and (max-width: 360px){
            width: 243px;
        }
    `}
    
    ${props => props.helloBarCta && css`
        background: transparent;
        border-radius: 100px;
        border: 1px solid #FFFFFF;
        color:#FFFFFF;
        padding:6px 16px;
        font-size:12px;
        font-weight:600 !important;
        letter-spacing:0.01em;
        &:hover{
            color: #001219;
            border: 1px solid #FFFFFF;
            background: #FFFFFF !important;
            transition: 0.3s all ease-in-out;
        }
        
    `}
    ${props => props.helloBarCtaPrimary && css`
        background: #FEB029;
        border-radius: 100px;
        border: none;
        color:#001219;
        padding:6px 16px;
        font-size:12px;
        border: 1px solid #FEB029;
        font-weight:600 !important;
        letter-spacing:0.01em;
        &:hover{
            color: #FEB029;
            border: 1px solid #FEB029;
            background: transparent !important;
            transition: 0.3s all ease-in-out;
        }

    `}
    ${props => props.campaignCta && css`
        background: #6324C6;
        border-radius: 10px;
        border: none;
        color:#fff;
        padding:6px 16px;
        font-size:12px;
        border: 1px solid #6324C6;
        font-weight:700 !important;
        letter-spacing:0.01em;
        &:hover{
            color: #6324C6;
            border: 1px solid #6324C6;
            background: transparent !important;
            transition: 0.3s all ease-in-out;
            font-weight:700 !important;
        }

    `}
    ${props => props.isB2Baside && css`
        background: #FEB029 !important;
        border-radius: 100px;
        color:#001219;
        padding:8px 16px;
        font-size:16px;
        font-weight:400 !important;
        letter-spacing:0.01em;
    `}

    ${props => props.isTeams1 && css`
        background: #550CCB !important;
        border-radius: 100px;
        color: #FFFFFF;
        padding: 18px 96px;
        font-size: 16px;
        font-weight: 600 !important;
        &:hover {
            background: #4b0ab5 !important;
        }
    `}

    ${props => props.isTeams2 && css`
        background: #550CCB !important;
        border-radius: 100px;
        color: #FFFFFF;
        padding: 16px 48px;
        font-size: 16px;
        font-weight: 600 !important;
        &:hover {
            background: #4b0ab5 !important;
        }
        @media only screen and (max-width: 587px) {
            padding: 8px 12px;
        }
    `}

    ${props => props.isTeams3 && css`
        background: #fff !important;
        border: 1px solid #550CCB;
        border-radius: 100px;
        color: #550CCB;
        padding: 16px 48px;
        font-size: 16px;
        font-weight: 600 !important;
        &:hover {
            background: #550CCB !important;
            color: #fff;
        }
    `}

    ${({pointer})=>pointer && css`
        pointer-events:none;
    `}
    .spinner-border {
        height: 15px;
        width: 15px;
        border-width: 2px;
        margin-left: 5px; 
        display: inline-block;
    }
    .arrow-right {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid black;
        margin-left:5px;
        display:inline-block;
        ${props => props.isRefer && css`
            display:none;
        `} 
      }
    ${({disable}) => disable && css`
        cursor: not-allowed !important;
    `}
    ${({opaque}) => opaque && css`
        opacity: 0.5;
        cursor: not-allowed !important;
    `}
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 460px) and (min-width: 270px) {
        margin-top: 5px;
        align-items: flex-end;
        margin-bottom: 5px;
    }
    /* Mobile Devices */
    @media only screen and (max-width: 570px) and (min-width: 270px) {
        font-size: 11px !important;
        /* margin-top: 15px !important; */
        ${props => props.isDedicatedMobile && css`
        font-size:16px !important;
    `}
    }
    :hover{
        background: #480AAD;
        ${props => props.isBF && css`
        background:#28C675;
        `}
        ${props => props.isRefer && css`
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #2CDC82;  
        `}
        ${props => props.isFP && css`
        background:#28C675;
        `}
        ${props => props.isB2B && css`
        background:rgba(255, 255, 255, 0.1);
        
        `}
        ${props => props.isB2Bform && css`
        background:linear-gradient(0deg, #FFC664, #FFC664), #FEB029;
        `}
        ${props => props.isUpgrade && css`
        background:#FFBA24;
        `}
        ${props => props.isFPW2 && css`
        background:#9077C8;
        `}
        ${props => props.isFPW4 && css`
        background:#573D8A;
        `}
        ${props => props.isPlanType && css`
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #2CDC82;
        `}
        
        ${props => props.isDedicatedMobile && css`
        background:#6536CC;
        `}
    }
`;

export const CancelBtn = styled.button`
    background:${({theme:{branding:{cancelCta}}}) => cancelCta.bg};
    color:${({theme:{branding:{cancelCta}}}) => cancelCta.color};
    padding:${({theme:{branding:{cancelCta}}}) => cancelCta.padding};
    font-size:${({theme:{branding:{cancelCta}}}) => cancelCta.fontSize};
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border:0;
    font-weight:600;
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 570px) and (min-width: 270px) {
        font-size: 11px !important;
    }
    ${({pointer})=>pointer && css`
        pointer-events:none;
    `}
`;


export const SecondryBtn = styled.button`
    background:${({theme:{branding:{secondaryCta}}}) => secondaryCta.bg};
    color:${({theme:{branding:{secondaryCta}}}) => secondaryCta.color};
    padding:${({theme:{branding:{secondaryCta}}}) => secondaryCta.padding};
    font-size:${({theme:{branding:{secondaryCta}}}) => secondaryCta.fontSize};
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border:0;
    font-weight:600;
    border: 1px solid #ECE9EE;
    ${({pointer})=>pointer && css`
        pointer-events:none;
    `}
    ${({opaque}) => opaque && css`
        opacity: 0.5;
        cursor: not-allowed !important;
        
    `}
    /* Mobile Devices */
    @media only screen and (max-width: 570px) and (min-width: 270px) {
        font-size: 11px !important;
    }
    :hover{
        background: linear-gradient(0deg, rgba(67, 34, 91, 0.05), rgba(67, 34, 91, 0.05)), #FFFFFF;
    }
`;

export const DestructBtn = styled.button`
    background:${({theme:{branding:{closeCta}}}) => closeCta.bg};
    color:${({theme:{branding:{closeCta}}}) => closeCta.color};
    padding:${({theme:{branding:{closeCta}}}) => closeCta.padding};
    font-size:${({theme:{branding:{closeCta}}}) => closeCta.fontSize};
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    -ms-border-radius: 500px;
    -o-border-radius: 500px;
    border: 1px solid ${({theme:{branding:{closeCta}}}) => closeCta.color};
    font-weight:600;
    ${({pointer})=>pointer && css`
        pointer-events:none;
    `}
    ${({center}) => center && css` margin:auto; `}
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 570px) and (min-width: 270px) {
        font-size: 11px !important;
    }
    :hover{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ECE9EF;
    }
    .cancel-btn{
        color:${({theme:{branding:{closeCta}}}) => closeCta.color};
        font-size:${({theme:{branding:{closeCta}}}) => closeCta.fontSize};
    }
    &.low-visiblity{
        border:2px solid #ECE9EF;
        background:${({theme:{branding:{lowVisibility}}}) => lowVisibility.bg};
        color:${({theme:{branding:{lowVisibility}}}) => lowVisibility.color};
        padding:${({theme:{branding:{lowVisibility}}}) => lowVisibility.padding};
        font-size:${({theme:{branding:{lowVisibility}}}) => lowVisibility.fontSize};
    }
    &.info-modal{
        border: none !important;
        color: #8E7A9D !important
    }
`;

export const InsetBtn = styled.button`
    background:${({theme:{branding:{insetCta}}}) => insetCta.bg};
    color:${({theme:{branding:{insetCta}}}) => insetCta.color};
    padding:${({theme:{branding:{insetCta}}}) => insetCta.padding};
    font-size:${({theme:{branding:{insetCta}}}) => insetCta.fontSize};
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border:0;
    font-weight:600;
    border: 1px solid #ECE9EE;
    ${({pointer})=>pointer && css`
        pointer-events:none;
    `}
    ${({renew})=>renew && css`
        background:${({theme:{branding:{primaryCta}}}) => primaryCta.bg};
        color:${({theme:{branding:{primaryCta}}}) => primaryCta.color};
    `}
    /* -- media query for all devices -- */
    /* Mobile Devices */
    @media only screen and (max-width: 570px) and (min-width: 270px) {
        font-size: 11px !important;
    }
    :hover{
        background: linear-gradient(0deg, rgba(67, 34, 91, 0.05), rgba(67, 34, 91, 0.05)), #FFFFFF;
        ${({renew})=>renew && css`background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #2CDC82;`}
    }
`;

export const LinkBtn = styled.button`
    font-size:${({theme: {typography}})=>typography.link};
    font-weight:600;
    color:#0D85E8;
    text-decoration: none;
    border:0;
    background:none;
    :hover{
        color:#0D85E8;
    }
    ${props => props.modalButton && css`
        color:#0D85E8 !important;
        font-size: 14px;
        text-decoration: none !important;
     `}
     ${props => props.getMoreSubscription && css`
        color:#0D85E8 !important;
        font-size: 16px;
        text-decoration: underline   !important;
        margin-bottom:8px;

        /* -- media query for all devices -- */
        /* Mobile Devices */
        @media only screen and (max-width: 570px) and (min-width: 270px) {
            font-size: 14px !important;
            margin-top:10px;
        }  
     `}

`;

export const RLink = styled.span`
    a{
        background:${({theme:{branding:{insetCta}}}) => insetCta.bg};
        color:${({theme:{branding:{insetCta}}}) => insetCta.color};
        padding:${({theme:{branding:{insetCta}}}) => insetCta.padding};
        font-size:${({theme:{branding:{insetCta}}}) => insetCta.fontSize};
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border:0;
        font-weight:600;
        border: 1px solid #ECE9EE;
        :hover,:focus{
            color:${({theme:{branding:{insetCta}}}) => insetCta.color};
        }
        /* -- media query for all devices -- */
        /* Small Tablets */
        @media only screen and (max-width: 450px) {
            font-size: 11px !important;
        }
        :hover{
            background: linear-gradient(0deg, rgba(67, 34, 91, 0.05), rgba(67, 34, 91, 0.05)), #FFFFFF;
        }
    }
`;

const addedAnimation = keyframes`
 0% { background: #CAF6E0; opacity:1;}
 50% {background:#CAF6E0; opacity:0.5;}
 50% {background:#CAF6E0; opacity:0;}
`

export const Table = styled.table`
    margin:0;
    @media (min-width:800px){
        max-height:334px;
    }
    overflow:auto;
    thead{
        background: #EEE9F4;
        padding:0;
        th{
            font-size: 12px;
            line-height: 125%;
            letter-spacing: 0.02em;
            color: #7B648C;
            font-weight:bold;
            padding:15px 30px; 
            border:0;
        }
    }
    tbody{
        background:white;
    }
    tr:last-child td{
        border:0;
    }
    td{
        padding:15px 30px;
        font-size: 12px;
        line-height: 125%;
        letter-spacing: 0.02em;
        font-weight:400;
        vertical-align:${({align})=>align === "middle" ? "middle" : "top"};
        color:#43225B;
        .card-icon{
            position: relative;
            font-size: 12px !important;
            display: block;
            width: 100%;
            padding-left: 30px;
            ::after{
                position:absolute;
                content:'';
                width:24px;
                height:24px;
                background:${({theme:{payment}}) => payment.card};
                top:-4px;
                left:0;
            }
        }
    }
    td.success{
        color:${({theme:{branding}}) => branding.success};
        font-weight:bold;
    }
    td.danger{
        color:${({theme:{branding}}) => branding.danger};
        font-weight:bold;
    }
    li{
        clear:both;
        width:100%;
        position:relative;
        line-height: 150%;
        padding-right:60px;
        .invoice-price{
            position:absolute;
            top:0;
            right:0;
        }
        :not(:last-child){
            margin-bottom:7px;
        }
    }
    .table-buttons *{
        margin:0 2px;
    }
    .invoice-price{
        font-weight:700;
    }
    .linkTr{
        cursor:pointer;
    }
    ${props => props.new && css`
        tbody tr:first-child{
            animation-name: ${addedAnimation};
            animation-duration: 1s;
            animation-timing-function:ease-in-out;
        }
    `}
    /* -- media query for all devices -- */

  /* Large Tablets and Screens */
  /* All Table Spacing */

  @media screen and (max-width: 1424px) and (min-width: 1001px) {
    th {
      padding: 15px 14px !important;
    }
    td {
      padding: 15px 14px;
    }

    .card-icon {
      ::after {
        top: -2px !important;
      }
    }
  }

  /* Mobile Devices and Small Tablets */

  @media screen and (max-width: 1000px) and (min-width: 270px) {
    width: 100% !important;
    height: auto !important;

    &.no-tickets {
        td {
            padding: 20px 15px !important;
            text-align: left;
        }
    }

    thead {
      display: none !important;
    }

    head {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr:not(:last-child){
      /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
      border-bottom: 1px solid #43225B;
    }
    
    td {
      border-bottom: 1px solid #ddd !important;
      display: block;
      position: relative;
      font-size: 11px !important;
      text-align: right;

      /* Description and Subject column adjust in table */
      padding: ${(props) =>
        props.tdescription
          ? "20px 15px 20px 3% !important"
          : "20px 15px 20px 36% !important"};
      font-size: 11px !important;
      width: 100%;

      .card-icon {
        font-size: 10px !important;

        ::after {
          left: auto !important;
          right: 30px !important;
        }
      }
    }

    /* Table Responsive - Data Attribute */

    td::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      left: 12px;
    }

    li,
    span {
      font-size: 12px !important;
    }

    h3 {
      font-size: 18px !important;
    }
  }
`;

export const InvoiceBox = styled.div`
    display: table;
    box-shadow: 0px 12px 24px rgb(67 34 91 / 5%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    margin: 0 0 30px;
    .invoiceHead {
        background: #EEE9F4;
        padding: 10px 20px;
    }
    .invoiceBody{
        padding:10px 20px;
    } 
`;

export const Path = styled.path`
    ${props => props?.fillColor && css`
        fill: ${props.fillColor}
    `}
`

export const SVG = styled.svg`
    z-index: 1;
    position: relative;
`

export const CheckBoxLabel = styled.label`
    span{
        letter-spacing: 0.02em;
        line-height:125%;
    }
    cursor:pointer;
    input:checked + span::before{
        background-color:#43225B;
    }
    span::before{
        position:absolute;
        content:"";
        height:16px;
        width:16px;
        top:5px;
        left:0;
        border:1px solid #43225B;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        background:url(${inputcheck}) no-repeat center center;
    }
    ${({loading}) => loading == 1 && css`
        pointer-events:none;
        opacity:0.5;
    `}
`;

export const AutoLoginWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    min-height:100%;
    background:white;
    h1{
        margin:15px 0 10px;
    }
    p{
        font-weight: normal;
        font-size: 14px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.5px;
        color: #7B648C;
        margin:0;
    }
    /* -- media query for all devices -- */
    /* Mobile Devices */
    /* Auto Login Screen */
    @media only screen and (max-width: 376px) and (min-width: 270px) {
        padding: 0px 15px;

        h1 {
        font-size: 18px;
        }

        p {
        font-size: 12px;
        }
    }
`;

export const NotSupportedBox = styled.div`
    padding:0 20px;
    min-height:100%;
    position:relative;
    div{
        position:absolute;
        top:50%;
        width:100%;
        left:50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
    }
    h1{
        margin:20px auto 10px;
    }
`;

export const ErrorBox = styled.div`
    text-align: center;
    max-width:800px;
    margin:auto;
    .fpErrorBox{
        margin-top: 80px;
        button{
            border:none;
            border-radius: 30px;
            padding: 20px 60px;
            background: #6536CC;
            color:#fff;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.5px;
        }
    }
`;

const blinkAnimdation = keyframes`
 0% { opacity: 0;}
 50% {opacity:0.5;}
 50% {opacity:0.5;}
`

export const Blinking = styled.span`
    animation: ${blinkAnimdation} 1s linear infinite;
    display:inline-block;
    height:3px;
    width:3px;
    border-radius:100%;
    background:#7B648C;
    margin-right:3px;
`;

export const InputSliderStyles = styled.div`
    max-width: 500px;
    margin: 30px auto 40px;

    @media only screen and (max-width: 560px){
        max-width: 320px;
    }

    .range-slider__tooltip__label{
        background: #8260EF !important;
    }
    .range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__caret::before{
        border-top-color: #8260EF !important;
    }
    .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label{
        margin-bottom: -1px;
        font-size: 1rem;
        font-weight: 600;
    }

    //Mozilla

    input[type="range"].range-slider.range-slider--primary::-moz-range-thumb{
        background: #3B108E;
        height: 15px;
        width: 15px;
    }
    input[type="range"].range-slider.range-slider--primary:not(.disabled):focus::-moz-range-thumb, input[type="range"].range-slider.range-slider--primary:not(.disabled):active::-moz-range-thumb,input[type="range"].range-slider.range-slider--primary:not(.disabled):hover::-moz-range-thumb{
        box-shadow: 0 0 0 0.4rem #DCD3E987;
    }
    input[type="range"].range-slider::-moz-range-track{
        border-radius: 15px;
        border: 1px solid rgba(130, 96, 239, 0.15);
        background: #C4B4F6; 
        height: 10px;
    }


    //chrome
    input[type="range"].range-slider.range-slider--primary::-webkit-slider-thumb{
        background: #3B108E;
        height: 15px;
        width: 15px;
        margin-top: -4px;
    }
    input[type="range"].range-slider.range-slider--primary:not(.disabled):focus::-webkit-slider-thumb, input[type="range"].range-slider.range-slider--primary:not(.disabled):active::-webkit-slider-thumb,input[type="range"].range-slider.range-slider--primary:not(.disabled):hover::-webkit-slider-thumb{
        box-shadow: 0 0 0 0.4rem #DCD3E987;
    }
    input[type="range"].range-slider::-webkit-slider-runnable-track{
        border-radius: 15px;
        border: 1px solid rgba(130, 96, 239, 0.15);
        background: #C4B4F6; 
        height: 10px;
    }

    
`