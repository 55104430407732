import { useState, useEffect } from "react";
import { APIFAILED } from "../../../tools/responseMessages";
import { PostPurchaseStyles } from "../../../styles/dashboard/pure-teams/postPurchase";
import { TabContent, TabPane } from "reactstrap";
import TeamServers from "./lisiting/teamServers";
import TeamListing from "./lisiting/teamListing";
import Removal from "../family-plan/modal/removal";
import qs from 'qs';
import { toast } from "react-toastify";
import { sendEvents } from "../../../tools/mpEvents";
import { getTokenFromLocalStorage, poster } from '../../../tools/tools';
import Invite from "./modals/invite";
import MemberCount from "./layout/memberCount";
import useTeamsCounter from "./custom-hooks/useTeamsCounter";
import Info from "./modals/info";
import { pureTeamsPostPurchaseStates } from "../../../tools/constants";
import DedicatedIp from "./lisiting/dedicatedIp";
import ExpiredView from "./layout/expiredView";

const PostPurchase = ({selectedSubscriptionData, setInviteLink, inviteLink, plansInfo}) => {
    const token = getTokenFromLocalStorage();

    const {teamsCounter, isCounterLoading, counterErrorMsg, setFetchTeamsCounterAgain} = useTeamsCounter(selectedSubscriptionData);
    const [deleteModalWidth] = useState({
        lg: "500px",sm: "340px"
    });
    const [deleteMember, setDeleteMember] = useState(false);
    const [hasSentWidth] = useState({
        lg: "460px",
        sm: "300px"
    });

    const [modalWidth] = useState({
        lg: "684px",
        sm: "440px"
    });
    
    const [triggerDelete, setTriggerDelete] = useState(false);
    const [activeTab , setActiveTab] = useState("team_members");
    const [inviteModal, setInviteModal] = useState();
    const [hasSent, setHasSent] = useState();
    
    const [infoModal, setInfoModal] = useState({
        show: '',
        open: ''
    });
    const [email, setEmail] = useState({
        items: [],
        value: "",
        error: null
    });
    
    const fetchCountAndMembers = () => {
        setFetchTeamsCounterAgain(prev=>!prev)
    }

    useEffect(() => {

        if(!inviteLink && !selectedSubscriptionData?.is_child && teamsCounter?.team_counter?.team_members && (teamsCounter?.team_counter?.team_members?.allocated !== teamsCounter?.team_counter?.team_members?.limit)){
            sendGetLinkRequest(selectedSubscriptionData);
        } 
            
    }, [teamsCounter])


    const toggleInviteModal = () => {
        setInviteModal(!inviteModal)
    }
    const toggleSentModal = () => {
        setHasSent(!hasSent)
    }
    const closeHasSentModal = () => {
        toggleInviteModal();
        toggleSentModal()
    }
    const copyLink = async (e) => {
        try {
            await navigator.clipboard.writeText(e.target.id)
            toast.success("Copied to Clipboard!")
            
            sendEvents({
                event_name: "ma_send_team_member_invite",
                event_properties: {
                    send_invite_via: "copy",  
                    recipient_email: ''
                }
            }, token);

        } catch (error) {
            toast.error("Unable to copy!")       
        }
    }
    const onAddMoreUsers = () => {
        sendEvents({
            event_name: "ma_click_add_team_member",
            event_properties: {
                team_count: teamsCounter?.team_counter?.team_members?.limit,
            },
        }, token);
        toggleInviteModal()
        setEmail({
            items: [],
            value: "",
            error: null
        })   
    }
    const onInviteMoreUsers = () => {
        setEmail({
            items: [],
            value: "",
            error: null
        });
        sendEvents({
            event_name: "ma_click_add_team_member",
            event_properties: {
                team_count: teamsCounter?.team_counter?.team_members?.limit,
            },
        }, token);
        toggleSentModal()
    }
    const onClickInvite = () => {
        sendInviteRequest()
    }
    const sendInviteRequest = async () => {

        try{
            const formdata = qs.stringify({
                emails: email.items.toString(), 
                id: selectedSubscriptionData?.id,
                plan_code: selectedSubscriptionData?.plan?.code,
                current_plan_currency: selectedSubscriptionData?.currency,
                current_plan_expiry_date: selectedSubscriptionData?.expires_at_timestamp,
                max_account: teamsCounter?.team_counter?.team_members?.limit
            })
            const inviteResponse = await poster(`pure-teams/send-email`, formdata, token);
            const {data: response} = inviteResponse;    

            if(response?.status){
                toggleSentModal();
                sendEvents({
                    event_name: "ma_send_team_member_invite",
                    event_properties: {
                        send_invite_via: "email", 
                        recipient_email:  email.items.toString(), 
                    }
                }, token);
            }
            else{
                toast.error(response?.message);
                toggleInviteModal();
                sendEvents({
                    event_name: "ma_fail_team_member_invite",
                    event_properties: {
                      send_invite_via: "email", 
                      reason: response?.message,
                      recipient_email:  email.items.toString(), 
                    }
                }, token);
            }
        }
        catch{
            toast.error(APIFAILED);
            toggleInviteModal();
            sendEvents({
                event_name: "ma_fail_team_member_invite",
                event_properties: {
                    send_invite_via: "email", 
                    reason: 'Oops! Something went wrong.',
                    recipient_email:  email.items.toString(), 
                }
            }, token);
        }
    }
    const sendGetLinkRequest = async (sub) => {
        try{
            const formdata = qs.stringify({
                id: sub?.id,
                plan_code: sub?.plan?.code,
                current_plan_currency: sub?.currency,
                current_plan_expiry_date: sub?.expires_at_timestamp,
                max_account: teamsCounter?.team_counter?.team_members?.limit
            })
            const {data: response} = await poster(`pure-teams/get-link`, formdata, token);
  
            if(response?.status){
                setInviteLink(response?.body?.url)
            }
            else{
                toast.error(response?.message);
            }
        }
        catch{
            toast.error(APIFAILED);
        }
    }
    const toggleInfoModal = (content) => {
        content ? setInfoModal({open: true, content: content}) : setInfoModal({open: false, content: ''})
    }
    const openInfoModal = () => {
        toggleInfoModal(pureTeamsPostPurchaseStates.find((state)=> state.name  === activeTab)?.info)
    }
    const toggleDeleteModal = () => {
        setDeleteMember(!deleteMember)
    }
    const onClickDelete = () => {
        setTriggerDelete(!triggerDelete)
    }


    return <>
        <PostPurchaseStyles activeTab={activeTab}>
            <section className="heading">
                {activeTab !== 'team_members' && <span className="back-arrow" onClick={() => setActiveTab("team_members")}></span>}
                <div>
                    <h1>{activeTab === 'team_members' ? 'Teams' : pureTeamsPostPurchaseStates.find((state)=> state.name  === activeTab)?.heading}</h1>
                    <p>{pureTeamsPostPurchaseStates.find((state)=> state.name  === activeTab)?.description}<span className="info" onClick={()=>openInfoModal()}>Learn more.</span></p>
                </div>
            </section>

            {
                selectedSubscriptionData?.state !== 'expired' ? <>
                    {/* ----GRID---- */}
                    {activeTab === 'team_members' && <MemberCount selectedSubscriptionData={selectedSubscriptionData} isCounterLoading={isCounterLoading} teamsCounter={teamsCounter?.team_counter} setActiveTab={setActiveTab} error={counterErrorMsg}/>}
                
                    <TabContent activeTab={`${activeTab}`}>
                    <TabPane tabId="team_members">
                        <TeamListing 
                            selectedSubscriptionData={selectedSubscriptionData} 
                            onAddMoreUsers={onAddMoreUsers} 
                            setTriggerDelete={setTriggerDelete}
                            triggerDelete={triggerDelete}
                            fetchCountAndMembers={fetchCountAndMembers}
                            inviteLink={inviteLink}
                            teamsCounter={teamsCounter?.team_counter}
                            toggleDeleteModal={toggleDeleteModal}
                            isCounterLoading={isCounterLoading}
                        />
                    </TabPane>
                    {(!isCounterLoading || !counterErrorMsg) && activeTab === 'team_server' && <TabPane tabId="team_server">
                        <TeamServers 
                            selectedSubscriptionData={selectedSubscriptionData}  
                            teamsCounter={teamsCounter?.team_counter}
                            fetchCountAndMembers={fetchCountAndMembers}
                            plansInfo={plansInfo}
                        />
                    </TabPane>}
                    {(!isCounterLoading || !counterErrorMsg) && activeTab === 'dedicated_ip' && <TabPane tabId="dedicated_ip">
                        <DedicatedIp 
                            selectedSubscriptionData={selectedSubscriptionData}  
                            teamsCounter={teamsCounter?.team_counter}
                            fetchCountAndMembers={fetchCountAndMembers}
                            counterErrorMsg={counterErrorMsg}
                            plansInfo={plansInfo}
                        />
                    </TabPane>}
                    </TabContent> 
                </>
                : <ExpiredView selectedSubscriptionData={selectedSubscriptionData}/>
            }
        </PostPurchaseStyles>

        {deleteMember && <Removal backdrop={false} modalWidth={deleteModalWidth} widthCalculator={'lg'} modal={deleteMember} toggle={toggleDeleteModal} removeChildRequest={onClickDelete} theme={'teams'}/>}
        {inviteModal && <Invite toggle={hasSent ? closeHasSentModal : toggleInviteModal} modal={inviteModal} modalWidth={hasSent ? hasSentWidth : modalWidth} widthCalculator={'lg'} copyLink={copyLink} setEmail={setEmail} email={email} onClickInvite={onClickInvite} hasSent={hasSent} onInviteMoreUsers={onInviteMoreUsers} inviteLink={inviteLink} selectedSubscriptionData={selectedSubscriptionData}/>}
        {infoModal.open && <Info content={infoModal.content} backdrop={false} modalWidth={modalWidth} widthCalculator={'lg'} modal={infoModal.open} toggle={toggleInfoModal}/>}
    </>
}
export default PostPurchase;