import React, { useState } from 'react'
import { PopupBody} from '../../../../styles/dashboard/Main'
import { Modal, ModalBody, Input, ModalFooter } from 'reactstrap';
import { PrimaryBtn , DestructBtn } from '../../../../styles/Generic';
import HubspotContactForm from './HubspotContactForm';

const DomeForm = ({toggle, modal, modalWidth, widthCalculator, setDomeModal, formId="5db42b49-7547-480d-9129-40471b404168", onSubmit=()=>{}}) => {
    

    return (
            <Modal backdrop={false} isOpen={modal} toggle={toggle} style={{maxWidth: `675px`, width: '100%'}} className="modal-wrapper">
                <ModalBody>
                    <PopupBody>
                        <HubspotContactForm region="na1" portalId="23528330" formId={formId} onSubmit={onSubmit} setDomeModal ={setDomeModal}></HubspotContactForm>
                    </PopupBody>
                </ModalBody>
                <ModalFooter>
                    <DestructBtn color="secondary" onClick={ () => setDomeModal(false)}>Close</DestructBtn>
                </ModalFooter>
            </Modal>
    )
}

export default DomeForm